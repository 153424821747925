import React from 'react'

import { Button } from './Button'
import { useAppSelector } from '~publish/legacy/store'
import { useContentGeneration } from '~publish/legacy/ai/hooks/useContentGeneration'
import { selectContentGenerationStatus } from '~publish/legacy/ai/state/slice'
import { ACTIONS } from '~publish/legacy/ai/components/AIAssistant/AIAssistant.constants'
import { AIAssistantContext } from '~publish/legacy/ai/components/AIAssistant/AIAssistant'

/**
 *
 */
function ActionButton({
  action,
  target,
  label,
  disabled,
  secondary,
  type,
  IconComponent,
  onClick,
  testId,
}: {
  action: string
  target: string | null
  label?: string
  disabled?: boolean
  secondary?: boolean
  type?: 'button' | 'submit' | 'reset'
  testId?: string
  IconComponent?: React.ReactNode | JSX.Element
  onClick?: (event: React.KeyboardEvent | React.MouseEvent) => void
}): JSX.Element | null {
  const { channel } = React.useContext(AIAssistantContext)

  const { generateContent } = useContentGeneration()

  const { contentGenerationInProgress } = useAppSelector((state) =>
    selectContentGenerationStatus(state),
  )

  const actionConfig = ACTIONS[action as keyof typeof ACTIONS]

  const onGenerate = (event: React.MouseEvent): void => {
    if (!actionConfig || !target) return
    event.preventDefault()

    generateContent({
      service: channel,
      prompt: target,
      action,
      tone: null,
    })
  }

  const Icon = IconComponent || actionConfig?.IconComponent

  return actionConfig ? (
    <Button
      onClick={onClick || onGenerate}
      disabled={disabled || contentGenerationInProgress}
      secondary={secondary}
      type={type}
      testId={testId}
    >
      {/* @ts-expect-error TS(2604) FIXME: JSX element type 'Icon' does not have any construc... Remove this comment to see the full error message */}
      <Icon />
      {label || actionConfig.label}
    </Button>
  ) : null
}

export { ActionButton }
