import { createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from '@buffer-mono/popcorn'

import callRpc from '~publish/legacy/utils/call-rpc'
import type { AppDispatch, RootState } from '~publish/legacy/store'

export const updateGroup = createAsyncThunk<
  boolean,
  { groupId: string; groupName: string; selectedChannels: string[] },
  { state: RootState; dispatch: AppDispatch }
>(
  'channelGroups/updateGroup',
  async ({ groupId, groupName, selectedChannels }, { rejectWithValue }) => {
    try {
      const response = await callRpc('updateProfileGroup', {
        groupId,
        name: groupName,
        profileIds: selectedChannels,
      })

      if (!response.success) {
        throw new Error(response.message)
      }

      toast.success('Channel group updated successfully')

      return true
    } catch (error) {
      const message = 'Error updating channel group'
      toast.error(message)

      return rejectWithValue(message)
    }
  },
)
