import styled, { css } from 'styled-components'
import { white } from '@bufferapp/ui/style/colors'
import LocationPinIcon from '@bufferapp/ui/Icon/Icons/LocationPin'
import MusicIcon from '@bufferapp/ui/Icon/Icons/Music'

import { LinkifiedText as LinkifiedTextCommon } from '~publish/legacy/shared-components/LinkifiedText'

import {
  Video as CommonVideo,
  PlayButton as CommonPlayButton,
} from '../../../Common/VideoPlayer/styles'
import { VideoPlayer as VideoPlayerCommon } from '../../../Common/VideoPlayer'
import {
  DotsIcon,
  PostWrapper as PostWrapperCommon,
} from '../../../Common/styles'
import { AvatarCss, fontCss } from '../../styles'

export const Video = css`
  ${CommonVideo}
`

export const PlayButton = css`
  ${CommonPlayButton}
  background-color: rgba(255, 255, 255, 0.8);

  svg {
    color: rgba(0, 0, 0, 0.8);
    fill: rgba(0, 0, 0, 0.8);
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
    svg {
      color: rgba(0, 0, 0, 0.9);
      fill: rgba(0, 0, 0, 0.9);
    }
  }
`

export const PostWrapper = styled(PostWrapperCommon)`
  border: none;
  border-radius: 0;
`

export const MetadataWrapper = styled.div`
  position: absolute;
  bottom: 18px;
  width: calc(100% - 62px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 12px;
`

export const User = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const AvatarSquare = styled.div<{ src: string; fallbackUrl: string }>`
  ${AvatarCss}
  background: url(${({ src }) => src}) center no-repeat,
    url(${({ fallbackUrl }) => fallbackUrl}) center no-repeat;
  border-radius: 4.3436px;
  border: 1.44787px solid ${white};
  background-size: 24px;
  margin-top: -21px;
`

export const Caption = styled.div`
  ${fontCss};
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 6px;
`

export const LinkifiedText = styled(LinkifiedTextCommon)`
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 14px !important;
  color: ${white} !important;
  overflow: hidden;
  white-space: nowrap !important;
  text-overflow: ellipsis;

  > a.is-reel {
    font-size: 10px !important;
    line-height: 14px !important;
    font-weight: 800 !important;
    color: ${white} !important;
    cursor: default !important;
  }
`

export const UsernameWrapper = styled.div`
  display: flex;
  flex-direction column;
  align-items: flex-start;
`

export const Username = styled.span`
  display: inline-block;
  ${fontCss};
  font-weight: 600;
  line-height: 12px;
  margin-left: 6px;
`

export const VideoWrapper = css`
  position: relative;
  width: 100%;
  height: 580px;

  video {
    ${Video}
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.2) 8.17%,
      rgba(0, 0, 0, 0) 20.79%,
      rgba(0, 0, 0, 0) 71.68%,
      rgba(0, 0, 0, 0.2) 83.07%
    );
    pointer-events: none;
  }

  button {
    ${PlayButton}
  }
`

export const VideoPlayer = styled(VideoPlayerCommon)`
  ${VideoWrapper}
`

export const LocationPin = styled(LocationPinIcon)`
  width: 10px;
  height: 10px;
  margin-right: 2px;
`

const textWrapCss = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 12px;
  color: ${white};
`

export const ExtraData = styled.div`
  display: flex;
  width: 100%;
  margin-top: 10px;
  align-items: center;
`

export const ExtraDataItem = styled.div`
  display: flex;
  align-items: center;
  background: rgba(16, 19, 24, 0.5);
  margin-right: 4px;
  padding: 4px 8px;
  border-radius: 100px;
  /* Display animation */

  @keyframes fadeInSlideRight {
    from {
      opacity: 0;
      transform: translateX(-4px);
    }

    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  animation: fadeInSlideRight 0.2s ease;

  svg {
    height: 9px;
    width: 9px;
    margin-right: 4px;
    fill: ${white};
  }
`

export const Location = styled.div`
  display: flex;
  align-items: center;
  ${textWrapCss};
`

export const WrappedText = styled.div`
  ${fontCss};
  display: block;
  ${textWrapCss};
`

export const Music = styled(MusicIcon)`
  width: 10px;
  height: 10px;
  margin-right: 4px;
`

export const IconsSideSection = styled.div`
  position: absolute;
  right: 12px;
  bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 29px;
  align-items: center;
`

export const Dots = styled(DotsIcon)`
  margin-left: inherit;
  color: ${white};
`

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 536px;
  top: 12px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
`
