import React, { useEffect, useMemo } from 'react'

import { Text, Button } from '@bufferapp/ui'
import { Checklist } from '~publish/legacy/shared-components'
import { actions as modalActions } from '~publish/legacy/modals/reducer'

import { type AppDispatch, useAppDispatch } from '~publish/legacy/store'
import { useAccount } from '../../../accountContext'
import { useTracking } from '~publish/legacy/tracking/useTracking'
import * as Styled from './styles'
import { navigateToMigrationHub } from '~publish/legacy/upgrade-paths/helpersUpgradePaths'

const ApprovalsUpsell = ({ feature }: { feature?: string }): JSX.Element => {
  const dispatch = useAppDispatch()
  const user = useAccount()
  const { trackUpgradePathViewed, trackCTAViewed, trackCTAClicked } =
    useTracking()

  const { isOneBufferOrganization } = user?.account?.currentOrganization || {}
  const canStartTrial =
    user?.account?.currentOrganization?.billing?.canStartTrial
  const trialOrUgrade = canStartTrial ? 'trial' : 'upgrade'
  /* feature is the place where the ApprovalsUpsell is called from: queueTab or tags */
  const ctaAttributes = useMemo(
    () => ({
      cta: `approvals-${feature}-${trialOrUgrade}-1`,
      upgradePathName: `approvals-${feature}-${trialOrUgrade}`,
    }),
    [feature, trialOrUgrade],
  )

  const ctaForTracking = useMemo(
    () => ({
      ...ctaAttributes,
      cta: `publish-${ctaAttributes.cta}`,
    }),
    [ctaAttributes],
  )

  const onUpsellClick = ({ dispatch }: { dispatch: AppDispatch }): void => {
    if (isOneBufferOrganization) {
      trackCTAClicked({ ...ctaForTracking })

      const { MODALS, actions } = window?.appshell || {}
      actions.openModal(MODALS.planSelector, ctaAttributes)
    } else {
      navigateToMigrationHub(ctaAttributes.cta)
    }

    /* Does we need this code? */
    dispatch(modalActions.hideTrialCollaborationModal())
  }

  useEffect(() => {
    trackUpgradePathViewed({ upgradePathName: ctaForTracking.upgradePathName })
    trackCTAViewed({ ...ctaForTracking })

    /** When we can start a trial, a special app-shell button is rendered to launch
     * the trial from a embeded modal
     */
    const renderStartTrialButton = (): void => {
      const { COMPONENTS, actions } = window?.appshell || {}
      actions.renderComponent({
        containerId: 'approvals-start-trial',
        componentKey: COMPONENTS.startTrialButton,
        options: ctaAttributes,
      })
    }

    /* Workaround to avoid this button not bein displayed when appshell hasn't been loaded */
    // This has been implemented to fix the Start Trial button
    // not rendering consistantly in Safari browsers
    // Check if the page has already loaded
    if (window.appshell !== undefined) {
      renderStartTrialButton()
    } else {
      window.addEventListener('load', renderStartTrialButton)
      // Remove the event listener when component unmounts
      return () => window.removeEventListener('load', renderStartTrialButton)
    }
  }, [
    canStartTrial,
    ctaAttributes,
    ctaForTracking,
    trackCTAViewed,
    trackUpgradePathViewed,
  ])

  return (
    <Styled.EmptyStateContainer>
      <Styled.Content data-testid="approvals-upgradepath">
        <Text type="h1">Collaboration made easy</Text>
        <Text type="p">
          Say goodbye to the hassle of managing multiple social media channels
          with multiple team mates. With our collaboration features, you can:
        </Text>
        <Checklist
          items={[
            'Choose who can post on each of your social media channels',
            'Review posts for quality and brand before hitting publish',
            'Collaborate on ideas',
            'Stay on top of performance with automated reports',
          ]}
        />
        <div style={{ alignSelf: 'flex-end', paddingTop: '30px' }}>
          {canStartTrial && (
            <>
              <Styled.CTAWrapper id="approvals-start-trial" />
              <Text type="p">No credit card required</Text>
            </>
          )}
          {!canStartTrial && (
            // @ts-expect-error TS(2740) FIXME: Type '{ "data-testid": string; type: string; label... Remove this comment to see the full error message
            <Button
              data-testid="approvals-upsell"
              type="primary"
              label="Discover the Team plan"
              onClick={(): void => {
                onUpsellClick({ dispatch })
              }}
            />
          )}
        </div>
      </Styled.Content>
      <Styled.Image
        src="https://buffer-ui.s3.amazonaws.com/upgrade-paths/publish/approvals-upsell-lite.png"
        alt="Example of the approvals premium feature."
      />
    </Styled.EmptyStateContainer>
  )
}

export default ApprovalsUpsell
