import React from 'react'
import { DropTarget } from 'react-dnd'

import PostEmptySlot from './index'

const slotTarget = {
  // @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
  drop(props, monitor) {
    const {
      postProps: { id: postId },
      onDropPost,
    } = monitor.getItem()
    onDropPost(postId, props.timestamp, props.day)
  },
}

/**
 * This is a simple High Order Component that wraps our slot component and makes it
 * something that we can 'drop' a post onto. This is done with `react-dnd`. Refer to the
 * `react-dnd` documentation for more details.
 */
// @ts-expect-error TS(7031) FIXME: Binding element 'connectDropTarget' implicitly has... Remove this comment to see the full error message
function Slot({ connectDropTarget, isOver, ...rest }) {
  return connectDropTarget(
    <div>
      {/* @ts-expect-error TS(2322) FIXME: Type '{ focus: any; }' is not assignable to type '... Remove this comment to see the full error message */}
      <PostEmptySlot focus={isOver} {...rest} />
    </div>,
  )
}

const SlotDropTarget = DropTarget('post', slotTarget, (connect, monitor) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
}))(Slot)

export default SlotDropTarget
