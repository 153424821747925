import { getPointAfter, wrapNodes } from '@udecode/plate'
import type { Range } from 'slate'
import type {
  BufferEditor,
  BufferInlineElement,
} from '~publish/legacy/editor/BufferEditor/types.plate'
import { editorDebug } from '~publish/legacy/editor/editorDebug'
import { findCharacterLimitPoint } from '../helpers/findCharacterLimitPoint'

const debug = editorDebug.extend('wrapNodeAtPosition')

export const wrapNodeAtPosition = (
  editor: BufferEditor,
  element: BufferInlineElement,
  position: {
    start: number
    length: number
  },
): Range | undefined => {
  const anchor = findCharacterLimitPoint(editor, position.start)
  const focus =
    anchor &&
    getPointAfter(editor, anchor, {
      distance: position.length,
    })

  if (!anchor || !focus) {
    debug('skipping: %s', element.type, position)
    return
  }

  debug('wrapping: %s', element.type, { anchor, focus })
  wrapNodes(editor, element, {
    at: { anchor, focus },
    split: true,
  })
}
