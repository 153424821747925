/**
 * NOTE: It is experimental setup of the GraphQL Tada.
 * We currently run both this and the codegen setup in parallel.
 * If we decide to move forward exclusively with Tada, we can
 * delete the codegen setup and move all of its types
 * into /gql folder.
 *
 * Give it a try and share your thoughts!
 */

import { initGraphQLTada } from 'gql.tada'
import type { introspection } from './graphql-env.d.ts'

export const graphql = initGraphQLTada<{
  introspection: introspection
  scalars: {
    Date: string
    DateTime: string
    PostId: string
    SubProfileId: string
    NoteId: string
    ChannelId: string
    AccountId: string
    TagId: string
    OrganizationId: string
    IdeaId: string
  }
}>()

export type { FragmentOf, ResultOf, VariablesOf } from 'gql.tada'
export { readFragment } from 'gql.tada'
