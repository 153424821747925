import { connect } from 'react-redux'
import { actions as campaignActions } from '~publish/legacy/campaign/reducer'
import type { Tag } from '../campaign/types'
import ListCampaigns from './components/ListCampaigns'

export default connect(
  (state) => {
    return {
      // @ts-expect-error TS(2339) FIXME: Property 'campaignsList' does not exist on type 'D... Remove this comment to see the full error message
      campaigns: state.campaignsList.campaigns,
      // @ts-expect-error TS(2339) FIXME: Property 'organizations' does not exist on type 'D... Remove this comment to see the full error message
      showCampaignActions: state.organizations.selected?.canModifyCampaigns,
      // @ts-expect-error TS(2339) FIXME: Property 'organizations' does not exist on type 'D... Remove this comment to see the full error message
      hideAnalyzeReport: !state.organizations.selected?.canSeeCampaignsReport,
      // @ts-expect-error TS(2339) FIXME: Property 'campaignsList' does not exist on type 'D... Remove this comment to see the full error message
      isLoading: state.campaignsList.isLoading,
      // @ts-expect-error TS(2339) FIXME: Property 'organizations' does not exist on type 'D... Remove this comment to see the full error message
      hasCampaignsFlip: state.organizations.selected?.hasCampaignsFeature,
      // @ts-expect-error TS(2339) FIXME: Property 'organizations' does not exist on type 'D... Remove this comment to see the full error message
      ownerEmail: state.organizations.selected?.ownerEmail,
      // @ts-expect-error TS(2339) FIXME: Property 'organizations' does not exist on type 'D... Remove this comment to see the full error message
      shouldDisplayLockedCopy: state.organizations.selected?.locked,
    }
  },
  (dispatch) => ({
    goToAnalyzeReport: (campaign: Tag): void => {
      dispatch(campaignActions.goToAnalyzeReport(campaign))
    },
  }),
)(ListCampaigns)

export { default as reducer } from './reducer'
export { default as middleware } from './middleware'
