import React from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import ArrowRightIcon from '@bufferapp/ui/Icon/Icons/ArrowRight'
import {
  selectApprovalEntitlement,
  selectCampaignCounts,
  selectCurrentCampaign,
} from '~publish/legacy/campaign/selectors'
import { CampaignTab } from '~publish/legacy/campaign/types'
import { tagCreate } from '~publish/legacy/routes'
import { EmptyState } from '~publish/legacy/shared-components'

import { actions as composerPopoverActions } from '~publish/legacy/composer-popover'
import { actions as campaignActions } from '../../../reducer'

import EmptyCampaignBanner from '../EmptyCampaignBanner'
import ExamplePost from '../ExamplePost'

import { PlusIcon } from '@buffer-mono/popcorn'
import { SEGMENT_NAMES } from '~publish/legacy/constants'
import ApprovalsUpsell from '~publish/legacy/upgrade-paths/components/ApprovalsUpsell'

export const EmptyCampaignWrapper = styled.div`
  margin-top: 26px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;

  > * {
    flex-basis: 100%;
  }
`

type EmptyStateCampaignProps = {
  hideAnalyzeReport?: boolean
}

const EmptyStateCampaign = ({
  hideAnalyzeReport,
}: EmptyStateCampaignProps): JSX.Element => {
  const dispatch = useDispatch()
  const campaign = useSelector(selectCurrentCampaign, shallowEqual)
  const { tab } = useParams() as { tab?: CampaignTab }
  const { sent, scheduled, draftsCount, approvalsCount } =
    useSelector(selectCampaignCounts)
  const hasApprovalEntitlement = useSelector(selectApprovalEntitlement)

  const displayEmptyCampaign =
    tab === CampaignTab.Scheduled && scheduled === 0 && sent === 0
  const displayEmptySentPosts = tab === CampaignTab.Sent && sent === 0
  const displayAllPostsSent =
    tab === CampaignTab.Scheduled && scheduled === 0 && sent > 0
  const displayEmptyDraftsTab = tab === CampaignTab.Drafts && draftsCount === 0
  const displayEmptyApprovalsTab =
    tab === CampaignTab.Approvals && approvalsCount === 0

  const { t } = useTranslation()

  const teamMemberPrimaryAction = {
    label: t('campaigns.viewCampaign.allPostsSent.createTag'),
    onClick: () => dispatch(tagCreate.goTo()),
  }
  const ownerPrimaryAction = {
    label: t('campaigns.viewCampaign.allPostsSent.viewReport'),
    onClick: () => dispatch(campaignActions.goToAnalyzeReport(campaign)),
    icon: <ArrowRightIcon />,
    iconEnd: true,
  }

  return (
    <main id="main">
      <EmptyCampaignWrapper>
        {displayEmptyCampaign && (
          <>
            <EmptyCampaignBanner
              onCreatePostClick={(): void => {
                dispatch(
                  composerPopoverActions.openComposer({
                    cta: SEGMENT_NAMES.CAMPAIGNS_CREATE_POST_EMPTY_STATE_SCHEDUAL,
                    preSelectedTags: campaign ? [campaign?.id] : [],
                  }),
                )
              }}
            />
            <ExamplePost />
            <ExamplePost />
          </>
        )}
        {displayEmptySentPosts && (
          <EmptyState
            height="100%"
            title={t('campaigns.viewCampaign.emptySentPosts.title')}
            subtitle={t('campaigns.viewCampaign.emptySentPosts.subtitle')}
            heroImg="https://buffer-publish.s3.amazonaws.com/images/campaign-not-sent.png"
          />
        )}
        {displayEmptyDraftsTab && (
          <>
            <EmptyState
              height="100%"
              title={t('campaigns.viewCampaign.emptyDrafts.tagTitle')}
              subtitle={t('campaigns.viewCampaign.emptyDrafts.subtitle')}
              heroImg="https://buffer-publish.s3.amazonaws.com/images/campaigns-drafts-1.png"
              primaryAction={{
                label: t('campaigns.viewCampaign.emptyDrafts.createDraft'),
                icon: <PlusIcon />,
                onClick: () =>
                  dispatch(
                    composerPopoverActions.openComposer({
                      cta: SEGMENT_NAMES.CAMPAIGNS_CREATE_DRAFT_TAB,
                      draftMode: true,
                      preSelectedTags: campaign ? [campaign?.id] : [],
                    }),
                  ),
              }}
            />
          </>
        )}

        {displayEmptyApprovalsTab &&
          (!hasApprovalEntitlement ? (
            <ApprovalsUpsell feature="tags" />
          ) : (
            <EmptyState
              height="100%"
              title={t('campaigns.viewCampaign.emptyApprovals.title')}
              subtitle={t('campaigns.viewCampaign.emptyApprovals.tagSubtitle')}
              heroImg="https://buffer-publish.s3.amazonaws.com/images/campaigns-drafts-1.png"
            />
          ))}

        {displayAllPostsSent && (
          <EmptyState
            height="100%"
            title={t('campaigns.viewCampaign.allPostsSent.title')}
            subtitle={t('campaigns.viewCampaign.allPostsSent.tagSubtitle')}
            heroImg="https://buffer-publish.s3.amazonaws.com/images/campaign-sent-1.png"
            secondaryAction={{
              label: t('campaigns.viewCampaign.allPostsSent.createPost'),
              icon: <PlusIcon />,
              onClick: () =>
                dispatch(
                  composerPopoverActions.openComposer({
                    cta: SEGMENT_NAMES.CAMPAIGNS_CREATE_POST_EMPTY_STATE_SCHEDUAL,
                    preSelectedTags: campaign ? [campaign?.id] : [],
                  }),
                ),
            }}
            primaryAction={
              hideAnalyzeReport ? teamMemberPrimaryAction : ownerPrimaryAction
            }
          />
        )}
      </EmptyCampaignWrapper>
    </main>
  )
}

EmptyStateCampaign.defaultProps = {
  hideAnalyzeReport: false,
}

export default EmptyStateCampaign
