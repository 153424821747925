import React, { type ReactNode, useCallback } from 'react'
import type { Event } from '@bugsnag/browser'
import type { BufferEditor } from '~publish/legacy/editor/BufferEditor/types.plate'
import { updateSlateErrorData } from '~publish/legacy/editor/error'
import getErrorBoundary from '~publish/legacy/web/components/ErrorBoundary'
import FittedFallbackComponent from '~publish/legacy/web/components/ErrorBoundary/fallbackComponent'

const Boundary = getErrorBoundary(true)

export const EditorErrorBoundary = ({
  children,
  editor,
}: {
  children: ReactNode
  editor: BufferEditor
}): JSX.Element => {
  const onError = useCallback(
    (evt: Event) => {
      updateSlateErrorData({
        context: 'EditorErrorBoundary',
        editor,
      })(evt)
    },
    [editor],
  )
  return (
    <Boundary fallbackComponent={FittedFallbackComponent} onError={onError}>
      {children}
    </Boundary>
  )
}
