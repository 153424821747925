import styled from 'styled-components'

// need to add isDragging/isDraggingOver prop to PostContainer to avoid TS error
export const PostContainer = styled.div<{
  isDragging?: boolean
  isDraggingOver?: boolean
}>``

export const PostContainerClone = styled(PostContainer)`
  opacity: 0.5;
  transform: none !important;
`
