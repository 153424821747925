import styled from 'styled-components'
import * as Tooltip from '@radix-ui/react-tooltip'
import { grayDark, grayDarker, gray, white } from '@bufferapp/ui/style/colors'
import { fontFamily, fontWeight } from '@bufferapp/ui/style/fonts'

export const Wrapper = styled.div`
  color: ${grayDark};
  padding: 4px 8px;
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const IconWrapper = styled.div<{ color: string }>`
  width: 16px;
  height: 16px;
  background-color: ${({ color }): string => color || gray};
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  svg {
    fill: ${white};
    color: ${white};
    width: 8px;
  }
`

export const StyledTooltipTrigger = styled(Tooltip.Trigger)`
  border: none;
  background: transparent;
  padding: 0;
`

export const StyledTooltipContent = styled(Tooltip.Content)`
  font-family: ${fontFamily};
  font-size: 12px;
  line-height: 14px;
  font-weight: ${fontWeight};
  border-radius: 4px;
  border: none;
  padding: 6px 10px;
  color: white;
  background-color: ${grayDarker};
  z-index: 5000;
  max-width: 200px;
  box-shadow: 2px 2px 10px hsl(0deg 0% 0% / 10%);
`
