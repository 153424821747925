import React from 'react'
import NotificationContainer from './NotificationContainer'
import { NotificationScopes } from '../AppConstants'
import A from './shared/A'
import Button from './shared/Button'
import NotificationActionCreators from '../action-creators/NotificationActionCreators'
import styles from './css/ProductRolloutTooltip.module.css'
import { visibleNotificationsPropType } from './ComposerPropTypes'

class ProductRolloutTooltip extends React.Component {
  static propTypes = {
    visibleNotifications: visibleNotificationsPropType.isRequired,
  }

  // Prevent side-effects on composers' expanded state when clicking in the tooltip
  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  onTooltipClick = (e) => e.stopPropagation()

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  onButtonClick = (e) => {
    e.stopPropagation()

    // There's only supposed to be one such notification at all times
    // @ts-expect-error TS(2339) FIXME: Property 'visibleNotifications' does not exist on ... Remove this comment to see the full error message
    const productRolloutNotification = this.props.visibleNotifications.find(
      // @ts-expect-error TS(7006) FIXME: Parameter 'notif' implicitly has an 'any' type.
      (notif) => notif.scope === NotificationScopes.MC_ROLLOUT_INFO,
    )

    NotificationActionCreators.removeNotification(productRolloutNotification.id)
  }

  render() {
    const notificationContainerClassNames = {
      // @ts-expect-error TS(2339) FIXME: Property 'isOmniboxEnabled' does not exist on type... Remove this comment to see the full error message
      container: this.props.isOmniboxEnabled
        ? styles.bottomPositionedContained
        : styles.container,
      notification: styles.tooltip,
    }

    return (
      <NotificationContainer
        // @ts-expect-error TS(2339) FIXME: Property 'visibleNotifications' does not exist on ... Remove this comment to see the full error message
        visibleNotifications={this.props.visibleNotifications}
        classNames={notificationContainerClassNames}
        scope={NotificationScopes.MC_ROLLOUT_INFO}
        onClick={this.onTooltipClick}
      >
        <Button className={styles.button} onClick={this.onButtonClick}>
          Awesome, got it!
        </Button>
        <A
          href="/new-buffer-composer"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn More
        </A>
      </NotificationContainer>
    )
  }
}

export default ProductRolloutTooltip
