// @ts-expect-error TS(7006) FIXME: Parameter 'locale' implicitly has an 'any' type.
const languageLabel = (locale) =>
  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  ({
    'en-US': 'English',
    'es-ES': 'Español',
    default: 'English',
  }[locale])

export default languageLabel
