import React from 'react'

import Avatar from '@bufferapp/ui/Avatar'
import MusicIcon from '@bufferapp/ui/Icon/Icons/Music'

import { SERVICE_INSTAGRAM } from '~publish/legacy/constants'
import type {
  Image,
  Video,
} from '~publish/legacy/composer/composer/entities/factories'
import { parseInstagramLinks } from '~publish/legacy/utils/channels/instagram/helpers'

import type { InstagramContent, ProfilePreview } from '../../../types'
import { VideoPlayer } from '../VideoPlayer'
import { DotsIcon, PostWrapper } from '../../../Common/styles'
import {
  DEFAULT_AVATAR,
  getMediaProperties,
  getPreviewCharLimit,
  textExceedsCharLimit,
  truncateText,
} from '../../../Common/utils'
import {
  draftContentPropType,
  profilePreviewPropType,
} from '../../../Common/propTypes'

import { ImagePreview } from '../ImagePreview'
import * as Styles from './styles'
import { PostFields } from '@buffer-mono/reminders-config'

const Ellipse = ({
  selected = false,
  size = 'medium',
}: {
  selected?: boolean
  size?: string
}): JSX.Element =>
  selected ? (
    <Styles.EllipseSelected data-testid="ellipse" />
  ) : (
    <Styles.Ellipse data-testid="ellipse" size={size} />
  )

Ellipse.defaultProps = {
  selected: false,
  size: 'medium',
}

const Ellipses = ({
  visible,
  totalImages,
}: {
  visible: boolean
  totalImages: number
}): JSX.Element => {
  const components = [] as JSX.Element[]
  if (totalImages <= 4) {
    for (let i = 1; i < totalImages; i += 1) {
      components.push(<Ellipse />)
    }
    return (
      <Styles.EllipsesWrapper
        key="ellipses"
        data-testid="ellipses"
        visible={visible}
      >
        <Ellipse selected />
        {components}
      </Styles.EllipsesWrapper>
    )
  }

  return (
    <Styles.EllipsesWrapper
      key="ellipses-full"
      data-testid="ellipses"
      visible={visible}
    >
      <Ellipse selected />
      <Ellipse />
      <Ellipse />
      <Ellipse size="small" />
      <Ellipse size="smaller" />
    </Styles.EllipsesWrapper>
  )
}

const maxLength = getPreviewCharLimit(SERVICE_INSTAGRAM)
const getTextToDisplay = (text?: string): string => {
  let textToDisplay = text
  textToDisplay = truncateText(textToDisplay, maxLength)

  return textToDisplay ?? ''
}

const FirstComment = (): JSX.Element => (
  <Styles.FirstComment>View 1 comment</Styles.FirstComment>
)

const InstagramPost = ({
  content,
  profile,
}: {
  content: InstagramContent
  profile: ProfilePreview
}): JSX.Element => {
  const { avatar, username } = profile
  const { text, images, gif, video, channelData } = content
  const comment = channelData?.instagram?.comment_text
  const locationName = channelData?.instagram?.service_geolocation_name

  const textToDisplay = getTextToDisplay(text)
  const hasCarousel = !!(images && images?.length > 1)
  const totalImages = images ? images.length : 0
  const allImages = images?.length ? images : ([{ ...gif }] as Image[])
  const { hasImages, hasVideo } = getMediaProperties(content, null)

  const stickerProducts =
    content.isReminder &&
    content.selectedStickers?.includes(PostFields.PRODUCTS)
      ? content?.channelData?.instagram?.products
      : ''
  const stickerMusic =
    content.isReminder && content.selectedStickers?.includes(PostFields.MUSIC)
      ? content?.channelData?.instagram?.music
      : ''

  return (
    <PostWrapper>
      <Styles.Header>
        <Avatar
          data-testid="avatar"
          src={avatar}
          alt={username}
          size="small"
          fallbackUrl={DEFAULT_AVATAR}
        />
        <Styles.UsernameWrapper>
          <Styles.Username>{username}</Styles.Username>
          <Styles.Location>{locationName || ''}</Styles.Location>
          {stickerMusic && (
            <Styles.Music>
              <MusicIcon />
              Music
            </Styles.Music>
          )}
        </Styles.UsernameWrapper>
        <DotsIcon />
      </Styles.Header>
      <Styles.Body>
        {hasVideo && <VideoPlayer video={video as Video} />}
        {hasImages && (
          <ImagePreview images={allImages} stickerProducts={stickerProducts} />
        )}
        <Styles.ActionsWrapper>
          <Styles.HeartIcon />
          <Styles.CommentIcon />
          <Styles.MessageIcon />
          <Ellipses totalImages={totalImages} visible={hasCarousel} />
          <Styles.BookmarkIcon />
        </Styles.ActionsWrapper>
        {(text || comment) && (
          <Styles.Caption>
            {text && (
              <>
                <Styles.CaptionUsername>{username}</Styles.CaptionUsername>
                <Styles.LinkifiedText
                  links={parseInstagramLinks('post', textToDisplay)}
                  size="mini"
                  whitespace="pre-wrap"
                  newTab
                >
                  {textToDisplay}
                </Styles.LinkifiedText>
                {textExceedsCharLimit(text, maxLength) && (
                  <Styles.SeeMore>... more</Styles.SeeMore>
                )}
              </>
            )}
            {comment && <FirstComment />}
          </Styles.Caption>
        )}
      </Styles.Body>
    </PostWrapper>
  )
}

InstagramPost.propTypes = {
  content: draftContentPropType.isRequired,
  profile: profilePreviewPropType.isRequired,
}

export default InstagramPost
