import { useCallback, useState } from 'react'
import { useAccount } from '~publish/legacy/accountContext'
import type { PublishingNote } from '~publish/gql/graphql'

export const useIsAuthor = (authorId: string): boolean => {
  const account = useAccount()
  const accountId = account?.account?.id
  return accountId === authorId
}

interface UseNoteActionsProps {
  note: PublishingNote
  onEdit: (noteId: string, text: string) => void
  onDelete: () => void
}

interface UseNoteActionsReturn {
  isEditing: boolean
  editedText: string
  setEditedText: (text: string) => void
  handleEditOrSave: () => void
  handleDeleteOrCancel: () => void
}

export const useNoteActions = ({
  note,
  onEdit,
  onDelete,
}: UseNoteActionsProps): UseNoteActionsReturn => {
  const { text } = note

  const [isEditing, setIsEditing] = useState(false)
  const [editedText, setEditedText] = useState(text)

  const handleEditOrSave = useCallback((): void => {
    if (isEditing) {
      setIsEditing(false)
      onEdit(note.id, editedText)
    } else setIsEditing(true)
  }, [isEditing, editedText, onEdit, note.id])

  const handleDeleteOrCancel = useCallback((): void => {
    if (isEditing) {
      setIsEditing(false)
      setEditedText(note.text)
    } else onDelete()
  }, [isEditing, onDelete, note.text])

  return {
    isEditing,
    editedText,
    setEditedText,
    handleEditOrSave,
    handleDeleteOrCancel,
  }
}
