import keyWrapper from '~publish/helpers/keywrapper'

export const actionTypes = keyWrapper('LANGUAGE_PREFERENCES', {
  SET_USER_LANGUAGE: 0,
})

export const initialState = {
  showStoryPreview: 'en-US',
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_USER_LANGUAGE:
      return {
        ...state,
        language: action.language,
      }
    default:
      return state
  }
}

export const actions = {
  // @ts-expect-error TS(7031) FIXME: Binding element 'language' implicitly has an 'any'... Remove this comment to see the full error message
  setUserLanguage: ({ language }) => ({
    type: actionTypes.SET_USER_LANGUAGE,
    language,
  }),
}
