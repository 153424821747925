import { connect } from 'react-redux'
import { actions as composerPopoverActions } from '~publish/legacy/composer-popover'
import { SEGMENT_NAMES } from '~publish/legacy/constants'
import ComposePage from './components/ComposePage'

export default connect(
  (state) => ({
    // @ts-expect-error TS(2339) FIXME: Property 'router' does not exist on type 'DefaultR... Remove this comment to see the full error message
    queryParams: state.router?.location?.search,
    // @ts-expect-error TS(2339) FIXME: Property 'campaignsList' does not exist on type 'D... Remove this comment to see the full error message
    isLoading: state.campaignsList.isLoading,
  }),

  (dispatch) => ({
    // @ts-expect-error TS(7031) FIXME: Binding element 'shouldResetComposerData' implicit... Remove this comment to see the full error message
    initComposer: ({ shouldResetComposerData, post }) => {
      dispatch(
        composerPopoverActions.handleCreatePostClick({
          shouldResetComposerData,
          post,
          cta: SEGMENT_NAMES.EXTENSION_CREATE_A_POST,
        }),
      )
    },
  }),
)(ComposePage)
