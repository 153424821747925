import React, { isValidElement } from 'react'
import {
  DropdownMenu,
  type DropdownMenuGroupProps,
  EllipsisVerticalIcon,
  IconButton,
} from '@buffer-mono/popcorn'

const isChildrenEmpty = (children: React.ReactNode): boolean => {
  if (!children) {
    return true
  }

  const childrenArray = React.Children.map(children, (child) => {
    if (isValidElement(child)) {
      const { type } = child

      if (typeof type === 'function') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (type as any)(child.props)
      }
    }

    return child
  })

  return !childrenArray || childrenArray.length === 0
}

const MoreActionsDropdown = ({
  children,
}: {
  children: React.ReactNode
}): JSX.Element | null => {
  if (isChildrenEmpty(children)) {
    return null
  }

  return (
    <DropdownMenu
      modal={false}
      trigger={
        <IconButton
          data-testid="more-actions-menu"
          label="More actions"
          tooltip="More actions"
        >
          <EllipsisVerticalIcon />
        </IconButton>
      }
    >
      {children}
    </DropdownMenu>
  )
}

const MoreActionsDropdownGroup = ({
  children,
  ...props
}: DropdownMenuGroupProps): JSX.Element | null => {
  if (isChildrenEmpty(children)) {
    return null
  }

  return (
    <>
      <DropdownMenu.Group {...props}>{children}</DropdownMenu.Group>
      <DropdownMenu.Separator />
    </>
  )
}

const MoreActionsDropdownItem = DropdownMenu.Item

const MoreActionsDropdownObject = Object.assign(MoreActionsDropdown, {
  Group: MoreActionsDropdownGroup,
  Item: MoreActionsDropdownItem,
})

export { MoreActionsDropdownObject as MoreActionsDropdown }
