import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { stringTokenizer } from '~publish/legacy/i18n'
import { Text, Modal } from '@bufferapp/ui'

const TextWithStyle = styled(Text)`
  margin: 90px 12px 20px;
  align-self: baseline;
`

const TrialCompleteModal = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'translations' implicitly has an '... Remove this comment to see the full error message
  translations,
  // @ts-expect-error TS(7031) FIXME: Binding element 'hasExpiredProTrial' implicitly ha... Remove this comment to see the full error message
  hasExpiredProTrial,
  // @ts-expect-error TS(7031) FIXME: Binding element 'hasExpiredBusinessTrial' implicit... Remove this comment to see the full error message
  hasExpiredBusinessTrial,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isPremiumBusiness' implicitly has... Remove this comment to see the full error message
  isPremiumBusiness,
  // @ts-expect-error TS(7031) FIXME: Binding element 'cancelTrial' implicitly has an 'a... Remove this comment to see the full error message
  cancelTrial,
  // @ts-expect-error TS(7031) FIXME: Binding element 'completeAndUpgrade' implicitly ha... Remove this comment to see the full error message
  completeAndUpgrade,
}) => {
  const currentTrial = () => {
    if (hasExpiredProTrial) return 'Pro'
    if (hasExpiredBusinessTrial) {
      if (isPremiumBusiness) {
        return 'Premium'
      }
      return 'Business'
    }
  }

  const titleMessage = stringTokenizer(
    translations.header,
    '{plan}',
    currentTrial(),
  )

  return (
    // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; background: string; act... Remove this comment to see the full error message
    <Modal
      background="url('https://s3.amazonaws.com/buffer-publish/images/b4b-welcome-modal-background.svg') no-repeat"
      action={{
        label: translations.completeAndUpgrade,
        callback: () => {
          completeAndUpgrade()
        },
      }}
      secondaryAction={{
        label: translations.cancelTrial,
        callback: () => {
          cancelTrial()
        },
      }}
    >
      <TextWithStyle type="h2">{titleMessage}</TextWithStyle>
    </Modal>
  )
}

TrialCompleteModal.propTypes = {
  hasExpiredProTrial: PropTypes.bool.isRequired,
  hasExpiredBusinessTrial: PropTypes.bool.isRequired,
  isPremiumBusiness: PropTypes.bool.isRequired,
  translations: PropTypes.object.isRequired, // eslint-disable-line
  cancelTrial: PropTypes.func.isRequired,
  completeAndUpgrade: PropTypes.func.isRequired,
}

export default TrialCompleteModal
