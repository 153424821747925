import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advanced from 'dayjs/plugin/advancedFormat'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advanced)
const getOrdinalSuffix = (dayNumber: any) => {
  const possibleSuffix = [false, 'st', 'nd', 'rd']
  const overTwentyOnesDigit =
    ((dayNumber = Math.abs(dayNumber % 100)) - 20) % 10
  return (
    possibleSuffix[overTwentyOnesDigit] || possibleSuffix[dayNumber] || 'th'
  )
}
const isToday = ({ date, profileTimezone }: any) => {
  const today = new Date()
  const todayAsMoment = dayjs.tz(today, profileTimezone)
  const inputDateAsMoment = dayjs.tz(date, profileTimezone)
  return (
    todayAsMoment.format('YYYY-MM-DD') ===
    inputDateAsMoment.format('YYYY-MM-DD')
  )
}
const daysSinceDate = ({ date, profileTimezone }: any) => {
  const today = new Date()
  const todayAsMoment = dayjs.tz(today, profileTimezone)
  const inputDateAsMoment = dayjs.tz(date, profileTimezone)
  const days = todayAsMoment.diff(inputDateAsMoment, 'days')
  return days
}
export const getDateString = (
  unconvertedDate: any,
  profileTimezone: any,
  options: any,
) => {
  options = options || {}
  const date = new Date(unconvertedDate * 1000)
  const scheduledAtMoment = dayjs.tz(date, profileTimezone)
  const monthName = scheduledAtMoment.format('MMMM')
  const day = scheduledAtMoment.date()
  const ordinalSuffix = getOrdinalSuffix(day)
  let time
  if (options.twentyFourHourTime) {
    time = scheduledAtMoment.format('HH:mm')
  } else {
    time = scheduledAtMoment.format('h:mm A')
  }
  const zone = scheduledAtMoment.format('z')
  const timeString = `at ${time} (${zone})`
  if (isToday({ date, profileTimezone })) {
    return `today ${timeString}`
  }
  if (options.createdAt && daysSinceDate({ date, profileTimezone }) >= 3) {
    return `on ${monthName} ${day}${ordinalSuffix}`
  }
  return `${monthName} ${day}${ordinalSuffix} ${timeString}`
}
export const isInThePast = (unconvertedDate: any) => {
  if (!unconvertedDate) return false
  const date = new Date(unconvertedDate * 1000)
  const now = new Date()
  return date < now
}
export const daysAgoTimestamp = (days: any) => {
  const date = new Date()
  date.setDate(date.getDate() - days)
  return Math.ceil(date.getTime() / 1000)
}
export default {
  getDateString,
  isInThePast,
  daysAgoTimestamp,
}
