const transform24hrTimeToString = (input: any, uses24hr: any) => {
  const hours = input.split(':')[0]
  const minutes = input.split(':')[1]
  if (uses24hr) {
    return `${hours.length === 1 ? `0${hours}` : hours}:${
      minutes.length === 1 ? `0${minutes}` : minutes
    }`
  }
  if (parseInt(hours, 10) === 0) {
    return `12:${minutes.length === 1 ? `0${minutes}` : minutes} am`
  }
  if (parseInt(hours, 10) === 12) {
    return `12:${minutes.length === 1 ? `0${minutes}` : minutes} pm`
  }
  if (parseInt(hours, 10) > 12) {
    const newHours = parseInt(hours, 10) - 12
    return `${(newHours as any).length > 1 ? `0${newHours}` : newHours}:${
      minutes.length === 1 ? `0${minutes}` : minutes
    } pm`
  }
  return `${hours.length === 1 ? `0${hours}` : hours}:${
    minutes.length === 1 ? `0${minutes}` : minutes
  } am`
}

const convertStringToBoolean = (input: any) => {
  return typeof input === 'boolean' ? input : input === 'true'
}

export const channelDataParser = (post: any) => {
  if (!post.channel_data) {
    return null
  }
  if (
    post.channel_data.googlebusiness?.start_time ||
    post.channel_data.googlebusiness?.end_time
  ) {
    const startTime = post.channel_data.googlebusiness.start_time
    const endTime = post.channel_data.googlebusiness.end_time
    if (startTime) {
      post.channel_data.googlebusiness.start_time = transform24hrTimeToString(
        startTime,
        true,
      )
      post.channel_data.googlebusiness.end_time = transform24hrTimeToString(
        endTime,
        true,
      )
    }
  }
  if (post.channel_data.youtube) {
    const notifySubscribers = post.channel_data.youtube?.notifySubscribers
    const allowEmbedding = post.channel_data.youtube?.embeddable
    const madeForKids = post.channel_data.youtube?.madeForKids
    if (notifySubscribers) {
      post.channel_data.youtube.notifySubscribers =
        convertStringToBoolean(notifySubscribers)
    }
    if (allowEmbedding) {
      post.channel_data.youtube.embeddable =
        convertStringToBoolean(allowEmbedding)
    }
    if (madeForKids) {
      post.channel_data.youtube.madeForKids =
        convertStringToBoolean(madeForKids)
    }
  }
  return post.channel_data
}

export default {
  channelDataParser,
}
