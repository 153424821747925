import styled from 'styled-components'
import { AttachmentCard, ParagraphText, TextWrapper } from '../styles'

export const AttachmentWrapper = styled(AttachmentCard)`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`

export const ChannelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const ChannelUsername = styled(ParagraphText)`
  padding-left: 8px;
  font-weight: 400;
`

export const RetweetContent = styled(TextWrapper)`
  margin-top: 8px;
`

export const RetweetHeader = styled.div`
  display: flex;
  align-items: center;
`
