import React from 'react'
import { InvisibleButton, MasonryUnsplashStyled } from './styles'

import { UNSPLASH_SERVICE } from '../../constants'
import { UnsplashLinks } from './UnsplashLinks'

import type { GraphImage, IntegrationsService } from '../../types'

// @ts-expect-error TS(7051) FIXME: Parameter has a name but no type. Did you mean 'ar... Remove this comment to see the full error message
export type HandleImageCallback = (GraphImage) => void

export const ImagesLayout = ({
  images,
  service,
}: {
  images: GraphImage[]
  service: IntegrationsService
}) => {
  return (
    <MasonryUnsplashStyled
      breakpointCols={{
        default: 3,
        880: 2,
        596: 1,
      }}
      className="unsplash-masonry-grid"
      columnClassName="unsplah-masonry-column"
    >
      {images &&
        images.length > 0 &&
        images.map((image) => (
          <div className="gallery-item" key={image.id}>
            <InvisibleButton
              data-testid="integrations-gallery-item-button"
              type="button"
              onClick={() => {
                if (service.onFileSelected !== undefined)
                  service?.onFileSelected(image)
              }}
            >
              <img
                data-testid="ideas-images-layout-image"
                key={image.id}
                src={image.thumbnailUrl}
                alt={image.description || ''}
              />
            </InvisibleButton>
            {service?.id === UNSPLASH_SERVICE && (
              <UnsplashLinks imageLinked={image} />
            )}
          </div>
        ))}
    </MasonryUnsplashStyled>
  )
}
