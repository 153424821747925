import { useCallback, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '../../store'
import { selectIsIntegrationUploading } from './integrationBarSelectors'
import {
  integrationUploadFinished,
  integrationUploadStarted,
} from './integrationBarSlice'

export const useIsIntegrationUploading = (): {
  isIntegrationUploading: boolean
  onUploadStarted: () => void
  onUploadFinished: () => void
} => {
  const dispatch = useAppDispatch()
  const isIntegrationUploading = useAppSelector(selectIsIntegrationUploading)

  const onUploadStarted = useCallback(() => {
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0
    dispatch(integrationUploadStarted())
  }, [dispatch])

  const onUploadFinished = useCallback(() => {
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0
    dispatch(integrationUploadFinished())
  }, [dispatch])

  return useMemo(() => {
    return {
      isIntegrationUploading,
      onUploadStarted,
      onUploadFinished,
    }
  }, [isIntegrationUploading, onUploadStarted, onUploadFinished])
}
