import React from 'react'
import SparklesIcon from '@bufferapp/ui/Icon/Icons/Sparkles'
import TextRephraseIcon from '@bufferapp/ui/Icon/Icons/TextRephrase'
import LinkBrokenIcon from '@bufferapp/ui/Icon/Icons/LinkBroken'
import PencilIcon from '@bufferapp/ui/Icon/Icons/Pencil'
import XIcon from '@bufferapp/ui/Icon/Icons/X'
import TikTokIcon from '@bufferapp/ui/Icon/Icons/Tiktok'
import InstagramIcon from '@bufferapp/ui/Icon/Icons/Instagram'
import WarningIcon from '@bufferapp/ui/Icon/Icons/Warning'

import * as Styled from './Onboarding.styles'

export function Slide1(): JSX.Element {
  return (
    <>
      <Styled.Title>Hi, I’m your assistant 👋</Styled.Title>
      <Styled.ContentWrapper>
        <Styled.Subtitle>I can help you:</Styled.Subtitle>
        <ul>
          <li>
            <PencilIcon className="list-icon" />
            Write about any topic
          </li>
          <li>
            <TextRephraseIcon className="list-icon" />
            Revise content
          </li>
          <li>
            <SparklesIcon className="list-icon" />
            Repurpose posts across networks
          </li>
        </ul>
      </Styled.ContentWrapper>
    </>
  )
}

export function Slide2(): JSX.Element {
  return (
    <>
      <Styled.Title>Useful things to know about me👇</Styled.Title>
      <Styled.ContentWrapper>
        <Styled.IconWrapper>
          <XIcon size="large" />
          <TikTokIcon size="large" />
          <InstagramIcon size="large" />
        </Styled.IconWrapper>
        <Styled.ContentText>
          I understand the channel you’re posting to and will tailor my
          suggestions for that audience.
        </Styled.ContentText>
      </Styled.ContentWrapper>
    </>
  )
}

export function Slide3(): JSX.Element {
  return (
    <>
      <Styled.Title>Useful things to know about me👇</Styled.Title>
      <Styled.ContentWrapper>
        <Styled.IconWrapper>
          <LinkBrokenIcon size="large" />
        </Styled.IconWrapper>
        <Styled.ContentText>
          I don’t have internet access and can’t view any URLs you share.
        </Styled.ContentText>
      </Styled.ContentWrapper>
    </>
  )
}

export function Slide4(): JSX.Element {
  return (
    <>
      <Styled.Title>Useful things to know about me👇</Styled.Title>
      <Styled.ContentWrapper>
        <Styled.IconWrapper>
          <WarningIcon size="large" />
        </Styled.IconWrapper>
        <Styled.ContentText>
          I sometimes, unintentionally, can share wrong or offensive information
          and have limited knowledge of events after Oct 2023.
        </Styled.ContentText>
      </Styled.ContentWrapper>
    </>
  )
}
