import {
  purple,
  pink,
  pinkLighter,
  orange,
  yellow,
  green,
  teal,
  blueDark,
  black,
  red,
  purpleLighter,
  redLighter,
  orangeLighter,
  yellowLighter,
  greenLighter,
  tealLighter,
  boxShadow,
  gray,
} from '@bufferapp/ui/style/colors'

export const colors = [
  { color: purple, name: 'Purple' },
  { color: pink, name: 'Pink' },
  { color: red, name: 'Red' },
  { color: orange, name: 'Orange' },
  { color: yellow, name: 'Yellow' },
  { color: green, name: 'Green' },
  { color: teal, name: 'Teal' },
  { color: blueDark, name: 'Blue' },
  { color: black, name: 'Black' },
  { color: purpleLighter, name: 'Purple light' },
  { color: pinkLighter, name: 'Pink light' },
  { color: redLighter, name: 'Red lighter' },
  { color: orangeLighter, name: 'Orange light' },
  { color: yellowLighter, name: 'Yellow light' },
  { color: greenLighter, name: 'Green light' },
  { color: tealLighter, name: 'Teal light' },
  { color: boxShadow, name: 'Blue light' },
  { color: gray, name: 'Gray' },
]

export function selectColor(usedColors: string[]): string {
  const unusedColor = colors.find(({ color }) => {
    return !usedColors.includes(color)
  })
  if (unusedColor) {
    return unusedColor.color
  }
  const randomIndex = Math.floor(Math.random() * colors.length)
  const randomColor = colors[randomIndex]
  return randomColor.color
}
