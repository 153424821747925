import type { BufferEditor } from '~publish/legacy/editor/BufferEditor/types.plate'
import { getRefineButtonWidth } from './getRefineButtonWidth'

const findRelativeParent = (element: Element | null): Element | null => {
  if (element) {
    let currentElement = element.parentElement

    while (currentElement) {
      const computedStyle = getComputedStyle(currentElement)
      if (computedStyle.position === 'relative') {
        return currentElement
      }
      currentElement = currentElement.parentElement
    }
  }

  return null // Return null if no relative parent is found
}

const getNewPosition = (
  editor: BufferEditor,
): { top: number; left: number } => {
  /* get top and left of the editor´s parent */
  const selector = `[data-draftid="${editor.id}"]`
  const composer = document.querySelector(selector)
  const parent = findRelativeParent(composer)
  const parentBounds = parent?.getBoundingClientRect()
  const {
    top: parentTop = 0,
    left: parentLeft = 0,
    width: parentWidth = 0,
  } = parentBounds || {}

  /* get bottom and left of the selection */
  const selection = document.getSelection?.()
  const bounds = selection?.getRangeAt(0).getBoundingClientRect()
  const { bottom = 0, left = 0 } = bounds || {}

  /* get the parent´s margin */
  const parentStyle = window.getComputedStyle(parent as Element)
  const marginTop = parseInt(parentStyle.marginTop) || 0
  const marginLeft = parseInt(parentStyle.marginLeft) || 0

  /* calculate the new top and left */
  let newTop = Math.round(bottom - parentTop + marginTop + 4)
  /* to display the button below the selected text */
  let newLeft = Math.round(left - parentLeft + marginLeft)

  /* Left and top should be at least 0 */
  if (newTop < 0) {
    newTop = 0
  }

  if (newLeft < 0) {
    newLeft = 0
  }

  /* left + buttonWidth should be at most the width of the parent */
  const buttonWidth = getRefineButtonWidth()
  if (newLeft + buttonWidth > parentWidth) {
    newLeft = parentWidth - buttonWidth
  }

  return {
    top: newTop,
    left: newLeft,
  }
}

export default getNewPosition
