import React from 'react'

type QrCodeParams = {
  data: string
}

const QrCode = ({ data }: QrCodeParams): JSX.Element => (
  <img
    alt="QR Code"
    src={`${data}`}
    style={{
      imageRendering: 'pixelated',
      width: '200px',
      height: '200px',
    }}
  />
)

export default QrCode
