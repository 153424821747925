import styled from 'styled-components'
import { grayLighter } from '@bufferapp/ui/style/colors'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: #fff;
  border-radius: 5px;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.12);
  border: 1px solid rgb(230, 235, 239);
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 16px;
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;
  border-top: 1px solid rgb(230, 235, 239);
  position: relative;
`

export const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 12px;
  font-weight: 500;
  font-size: 14px;

  &:hover {
    cursor: pointer;
  }

  &:nth-child(2) {
    color: rgb(44, 75, 255);
    border-left: 1px solid rgb(230, 235, 239);
  }
`

export const ContentWrapper = styled.div`
  padding: 16px 16px 0;
  display: flex;
  width: 100%;
  box-sizing: border-box;
`

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
`

export const TextWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Title = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 600;
`

export const Summary = styled.span`
  display: block;
  font-size: 14px;
  margin-top: 8px;
`

export const Dropdown = styled.div`
  position: absolute;
  right: 0;
  top: 41px;
  background: white;
  width: 50%;
  z-index: 2;
  box-shadow: 2px 2px 6px 0px rgba(0, 0, 0, 0.4);
  border: 1px solid rgb(230, 235, 239);
`

export const DropdownItem = styled.div`
  border-bottom: 1px solid rgb(230, 235, 239);
  padding: 6px 12px;
  display: flex;
  align-content: center;

  &:hover {
    cursor: pointer;
    background: ${grayLighter};
  }

  &:last-child {
    border-bottom: none;
  }
`

export const ChannelItem = styled.span`
  margin-left: 8px;
`

export const BadgeWrapper = styled.div`
  display: flex;
  align-items: center;
`
