/**
 * Component that shows the list of available profiles
 */
import React from 'react'

import { Flex, VisuallyHidden, Button } from '@buffer-mono/popcorn'

import AppActionCreators from '../action-creators/AppActionCreators'
import type { Notification } from '../values/Notification'
import ProfileGroups from './ProfileGroups'
import Profile from './Profile'

import styles from './css/ProfileSection.module.css'
import { useChannelGroupsAccess } from '~publish/pages/ChannelGroups/hooks/useChannelGroupAccess'
import AppStore from '../stores/AppStore'

type ProfileType = {
  id: string
  isSelected: boolean
  service: { name: string }
}

type UserData = {
  profileGroups: {
    id: string
    name: string
    profileIds: string[]
  }[]
}

type ProfileSectionProps = {
  expandedProfileSubprofileDropdownId: string | null
  profiles: ProfileType[]
  userData: UserData
  visibleNotifications: Notification[]
  organizations?: {
    selected?: {
      id: string
      hasProfileGroupsFeature: boolean
    }
  }
}

type ScrollHandler = (
  e: React.UIEvent<HTMLDivElement>,
  scrollTop: number,
) => void

export const ProfileSection = ({
  expandedProfileSubprofileDropdownId,
  profiles,
  userData,
  visibleNotifications,
  organizations,
}: ProfileSectionProps): JSX.Element => {
  const profilesScrollContainer = React.useRef(null)
  const [scrollHandlers] = React.useState<Set<ScrollHandler>>(new Set())
  const isPreviewMode = AppStore.getIsPreviewMode()

  const onScroll = React.useCallback(
    (e: React.UIEvent<HTMLDivElement>) => {
      if (e.target !== profilesScrollContainer.current) return
      const { scrollTop } = e.target as HTMLDivElement
      scrollHandlers.forEach((handler) => handler(e, scrollTop))
    },
    [scrollHandlers],
  )

  const onProfilesToggleClick = (): void =>
    AppActionCreators.toggleAllProfiles()

  const addContainerScrollHandler = React.useCallback(
    (handler: ScrollHandler) => {
      scrollHandlers.add(handler)
    },
    [scrollHandlers],
  )

  const removeContainerScrollHandler = React.useCallback(
    (handler: ScrollHandler) => {
      scrollHandlers.delete(handler)
    },
    [scrollHandlers],
  )

  // TODO: replace profileGroups with channelGroups once we sync them with pusher
  const { profileGroups } = userData
  const { canAccessChannelGroups, hasEnoughChannels } = useChannelGroupsAccess()

  const selectedProfilesIds = profiles
    .filter((profile) => profile.isSelected)
    .map((profile) => profile.id)

  const hasNoProfilesSelected = selectedProfilesIds.length === 0

  return (
    <section className={styles.profileSection}>
      <Flex direction="column">
        {hasEnoughChannels && (
          <div style={{ alignSelf: 'flex-start' }}>
            <Button onClick={onProfilesToggleClick} variant="secondary">
              {hasNoProfilesSelected
                ? 'Select All Channels'
                : 'Deselect All Channels'}
            </Button>
          </div>
        )}

        {canAccessChannelGroups && (
          <ProfileGroups
            groups={profileGroups}
            selectedProfilesIds={selectedProfilesIds}
          />
        )}
      </Flex>

      <div className={styles.profilesContainer}>
        <div
          role="group"
          aria-labelledby="selectProfilesLabel"
          onScroll={onScroll}
          className={[
            styles.profilesScrollContainer,
            isPreviewMode ? styles.profilesScrollContainerPreview : '',
          ].join(' ')}
          ref={profilesScrollContainer}
        >
          {/* it's hidden from visual UI but will still be used as an accessible name */}
          <VisuallyHidden>Select channels</VisuallyHidden>

          {profiles.map((profile) => (
            <Profile
              profile={profile}
              organizationId={organizations?.selected?.id}
              expandedProfileSubprofileDropdownId={
                expandedProfileSubprofileDropdownId
              }
              addContainerScrollHandler={addContainerScrollHandler}
              removeContainerScrollHandler={removeContainerScrollHandler}
              visibleNotifications={visibleNotifications}
              className={styles.profile}
              key={profile.id}
            />
          ))}
        </div>
      </div>
    </section>
  )
}
