import { useSplitEnabled } from '@buffer-mono/features'
import React, { useEffect, useState } from 'react'
import { Redirect, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import type { RpcUpdate } from '~publish/legacy/post/types'
import { generic, profileTabPages } from '~publish/legacy/routes'
import { BufferLoading } from '~publish/legacy/shared-components'
import callRpc from '~publish/legacy/utils/call-rpc'
import { logError } from '~publish/legacy/utils/logError'
import { mapStatusToTab } from './mapStatusToTab'

const LoadingGateLoader = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  height: inherit;
  align-items: center;
`

/**
 * Route helper used for deep linking to a specific post.
 * It relies on profileId and postId query params to be provided.
 * It uses the postId to fetch the post to be able to check its status.
 *
 * It will then redirect to the correct tab based on the post status,
 * preserving query arguments provided.
 *
 * If profileId or postId is not provided,
 * it will redirect to the generic route
 */
export const PostDeepLinkResolver = (): JSX.Element => {
  const location = useLocation()
  const { isEnabled: isRemindersEnabled } = useSplitEnabled('CORE-reminders')

  // The fetched post used to redirect to the correct tab by status
  const [post, setPost] = useState<RpcUpdate | undefined>()
  const [postFetchError, setPostFetchError] = useState<boolean>(false)

  // Get the desired query params from the URL
  const query = new URLSearchParams(location?.search)
  const profileId = query.get('profileId') ?? ''
  const postId = query.get('postId') ?? ''

  // Fetch the post by ID to determine the correct tab using its status
  useEffect(() => {
    // Check for both as we don't want the request to happen
    // if profileId is not provided
    if (profileId && postId) {
      callRpc('getPost', { updateId: postId })
        .then((post: RpcUpdate) => {
          // If post is found, store it to state
          setPost(post)
        })
        .catch((error: Error) => {
          // If request fails, log the error and set error state to true
          // If error is due to 404, don't log to bugsnag
          setPostFetchError(true)
          if (!error.message.includes('404'))
            logError(error, {
              context: 'PostDeepLinkResolver route helper',
              metaData: { postId, profileId },
            })
        })
    }
  }, [postId, profileId])

  // If desired query params aren't provided, redirect to the generic route
  // while preserving other query params
  if (!profileId || !postId || postFetchError) {
    return <Redirect to={{ ...location, pathname: generic.route }} />
  }

  // If desired query params are provided, but no post is found yet, show a loader
  if (!post)
    return (
      <LoadingGateLoader>
        <BufferLoading size={64} />
      </LoadingGateLoader>
    )

  // Once the post is fetched, redirect to the correct tab by status
  const redirectRoute = profileTabPages.getRoute({
    profileId,
    tabId: mapStatusToTab(post, isRemindersEnabled),
  })
  return <Redirect to={{ ...location, pathname: redirectRoute }} />
}
