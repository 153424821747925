import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFilterIdeasByTag } from '~publish/legacy/campaign/hooks/useFilterIdeasByTag'
import { useDeleteIdea } from '~publish/pages/Create/hooks/useDeleteIdea'

import { PlusIcon } from '@buffer-mono/popcorn'
import { useIdeaComposer } from '~publish/components/IdeaManagementRouter'
import { EmptyState } from '~publish/legacy/shared-components'
import type { Idea as IdeaType } from '~publish/pages/Create/types'
import { EmptyCampaignWrapper } from '../EmptyState'
import { IdeasList } from '../IdeasList'

export const IdeasListContent = ({
  campaignId,
}: {
  campaignId: string
}): JSX.Element => {
  const { t } = useTranslation()
  const { ideas, loadMore, resetIdeasAndFetch } =
    useFilterIdeasByTag(campaignId)

  const { createIdeaWithComposer } = useIdeaComposer()

  const { deleteIdea } = useDeleteIdea()

  const onDelete = async (idea: IdeaType): Promise<void> => {
    try {
      await deleteIdea(idea)
      resetIdeasAndFetch()
    } catch (error) {
      throw new Error('The idea could not be deleted. Please try again!')
    }
  }

  useEffect(() => {
    loadMore(campaignId)
  }, [campaignId, loadMore])

  return (
    <>
      {ideas.length === 0 && (
        <EmptyCampaignWrapper>
          <EmptyState
            height="100%"
            title={t('campaigns.viewCampaign.emptyIdeas.title')}
            subtitle={t('campaigns.viewCampaign.emptyIdeas.subtitle')}
            heroImg="https://buffer-publish.s3.amazonaws.com/images/empty-ideas-tag.png"
            primaryAction={{
              label: t('campaigns.viewCampaign.emptyIdeas.createIdea'),
              icon: <PlusIcon />,
              onClick: () =>
                createIdeaWithComposer({
                  source: 'publish-tagsPage-ideas-emptyStateButton-1',
                  tags: [campaignId],
                }),
            }}
          />
        </EmptyCampaignWrapper>
      )}
      {ideas.length > 0 && <IdeasList ideas={ideas} onDelete={onDelete} />}
    </>
  )
}
