import { Badge, Button, CoachMark, PlusIcon } from '@buffer-mono/popcorn'
import { Button as LegacyButton, Text } from '@bufferapp/ui'
import { Gear } from '@bufferapp/ui/Icon'
import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { actions as composerPopoverActions } from '~publish/legacy/composer-popover'
import { SEGMENT_NAMES } from '~publish/legacy/constants'
import {
  selectCurrentProfileId,
  selectProfileTimezone,
} from '~publish/legacy/profile-sidebar/selectors'
import {
  calendarMonth,
  calendarWeek,
  profileChildTabPages,
} from '~publish/legacy/routes'
import { useAppSelector } from '~publish/legacy/store'
import { formatTimezone } from '~publish/legacy/utils/timezone'
import CalendarButtons from '../../CalendarButtons'
import QueueHeader from '../../QueueHeader'
import { useSplitEnabled } from '@buffer-mono/features'
import { useDismissableBanner } from '~publish/hooks/useDismissableBanner'

import styles from '~publish/pages/Channel/SettingsCoachMark.module.css'

const SettingsCoachMark = ({
  children,
}: {
  children: React.ReactNode
}): JSX.Element => {
  const postingScheduleConfigCoachMark = useDismissableBanner(
    'settings-button-coachmark-posting-schedule-config',
  )
  const [showCoachMarkPostingSchedule, setShowCoachMarkPostingSchedule] =
    React.useState(false)
  const [isCoachMarkOpen, setIsCoachMarkOpen] = React.useState(true)

  const { isEnabled: isPostingScheduleModalEnabled } = useSplitEnabled(
    'geid-posting-plan-modal',
  )

  const handleCheckParamsForPostingPlanConfigHelper = React.useCallback(() => {
    const urlParams = new URLSearchParams(window.location.search)
    const hasComeFromPostingPlanConfigModal = urlParams.get(
      'postingPlanConfigHelper',
    )
    const hasSeenCoachMark = !postingScheduleConfigCoachMark.isActive

    if (
      hasComeFromPostingPlanConfigModal &&
      !hasSeenCoachMark &&
      isPostingScheduleModalEnabled
    ) {
      setShowCoachMarkPostingSchedule(true)
    }
  }, [postingScheduleConfigCoachMark, isPostingScheduleModalEnabled])

  React.useEffect(() => {
    handleCheckParamsForPostingPlanConfigHelper()
  }, [handleCheckParamsForPostingPlanConfigHelper])

  if (!showCoachMarkPostingSchedule) {
    return <>{children}</>
  }

  return (
    <CoachMark
      open={isCoachMarkOpen}
      onDismiss={postingScheduleConfigCoachMark.dismiss}
      onOpenChange={(open): void => {
        setIsCoachMarkOpen(open)
      }}
    >
      <CoachMark.Overlay>
        {children}
        <CoachMark.Spotlight />
      </CoachMark.Overlay>
      <CoachMark.Content showCloseIcon={false}>
        <CoachMark.Title>
          <Badge size="small" className={styles.tip}>
            Tip
          </Badge>{' '}
          Customize your posting times
        </CoachMark.Title>
        <CoachMark.Description>
          You can always adjust you posting times from here
        </CoachMark.Description>
        <CoachMark.Footer>
          <CoachMark.Dismiss>
            <Button>Got It</Button>
          </CoachMark.Dismiss>
        </CoachMark.Footer>
      </CoachMark.Content>
    </CoachMark>
  )
}

interface HeaderWrapperProps {
  isFirstItem: boolean
  renderCalendarButtons: boolean
}

const HeaderWrapper = styled.div`
  margin-top: ${(props: HeaderWrapperProps): string =>
    props.isFirstItem && !props.renderCalendarButtons ? '0.5rem' : '1rem'};
  margin-bottom: ${(props: HeaderWrapperProps): string =>
    props.renderCalendarButtons ? '0.5rem' : ''};
  display: flex;
  align-items: center;
`

const HeaderButtons = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
`

const CreatePostButtonWrapper = styled.div`
  display: flex;
  margin-left: 0.5rem;
`

interface HeaderProps {
  item: {
    text: string
    dayOfWeek?: string
    date?: string
    id: string
    queueItemType: string
  }
  isFirstItem: boolean
  shouldRenderCalendarButtons?: boolean
  shouldHideCreatePostButton?: boolean
  shouldDisplayTimezone?: boolean
}

const Header = ({
  item,
  isFirstItem,
  shouldRenderCalendarButtons = false,
  shouldHideCreatePostButton = false,
  shouldDisplayTimezone = false,
}: HeaderProps): JSX.Element => {
  const dispatch = useDispatch()

  const { isEnabled: isSecondaryButtonTreatmentEnabled } = useSplitEnabled(
    'geid-secondary-button-treatment-with-global-action',
  )

  const { text, dayOfWeek, date, id } = item
  const renderCalendarButtons = shouldRenderCalendarButtons && isFirstItem
  const profileId = useAppSelector((state) => selectCurrentProfileId(state))

  const onCreatePostClick = (): void => {
    dispatch(
      composerPopoverActions.handleCreatePostClick({
        cta: SEGMENT_NAMES.CREATE_A_POST,
        selectedProfileId: profileId,
        shouldResetComposerData: true,
      }),
    )
  }

  const onCalendarClick = (weekOrMonth: string): void => {
    if (weekOrMonth === 'week') {
      dispatch(calendarWeek.goToWithChannel({ channelId: profileId }))
    }
    if (weekOrMonth === 'month') {
      dispatch(calendarMonth.goToWithChannel({ channelId: profileId }))
    }
  }

  const profileTimezone = useAppSelector((state) =>
    selectProfileTimezone(state, profileId),
  )
  const formattedTimezone = formatTimezone(profileTimezone)

  const onTimezoneClick = (): void => {
    dispatch(
      profileChildTabPages.goTo({
        profileId,
        tabId: 'settings',
        childTabId: 'postingSchedule',
      }),
    )
  }

  return (
    <HeaderWrapper
      key={id}
      renderCalendarButtons={renderCalendarButtons}
      isFirstItem={isFirstItem}
    >
      <QueueHeader id={id} text={text} dayOfWeek={dayOfWeek} date={date} />
      {isFirstItem && (
        <HeaderButtons>
          {shouldDisplayTimezone && (
            <>
              <Text type="p" color="grayDark">
                {formattedTimezone}
              </Text>
              <SettingsCoachMark>
                {/* @ts-expect-error TS(2740) FIXME: Type '{ as: string; type: string; onClick: any; ic... Remove this comment to see the full error message */}
                <LegacyButton
                  as="a"
                  type="text"
                  onClick={onTimezoneClick}
                  icon={<Gear />}
                  hasIconOnly
                  label="Change Timezone"
                  size="small"
                />
              </SettingsCoachMark>
            </>
          )}

          {renderCalendarButtons && (
            <CalendarButtons onCalendarClick={onCalendarClick} />
          )}

          {!shouldHideCreatePostButton && (
            <CreatePostButtonWrapper>
              <Button
                variant={
                  isSecondaryButtonTreatmentEnabled ? 'secondary' : 'primary'
                }
                onClick={onCreatePostClick}
                size="large"
                data-testid="queue-header-create-post"
              >
                <PlusIcon />
                New Post
              </Button>
            </CreatePostButtonWrapper>
          )}
        </HeaderButtons>
      )}
    </HeaderWrapper>
  )
}

export default Header
