import styled, { type FlattenSimpleInterpolation } from 'styled-components'
import { Button } from '@bufferapp/ui'

type CSSRule = string | number | false | FlattenSimpleInterpolation | undefined

export const DateNavigation = styled.div`
  display: flex;
  align-items: center;
`

export const IconButton = styled(Button)`
  height: 24px;
  width: 24px;
  button {
    padding: 4px;
    height: 24px;
  }
`

export const NavigationButtons = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
`

export const DateRangeText = styled.p<{ isWeekly: boolean }>`
  min-width: ${(props): CSSRule => (props.isWeekly ? '230px' : '110px')};
`
