import React from 'react'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { DateTimeInput } from '../styles'
import styles from '../../css/GoogleBusiness.module.css'

const TimePicker = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'timeToRender' implicitly has an '... Remove this comment to see the full error message
  timeToRender,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onTimeChange' implicitly has an '... Remove this comment to see the full error message
  onTimeChange,
  // @ts-expect-error TS(7031) FIXME: Binding element 'uses24hTime' implicitly has an 'a... Remove this comment to see the full error message
  uses24hTime,
  // @ts-expect-error TS(7031) FIXME: Binding element 'placeholder' implicitly has an 'a... Remove this comment to see the full error message
  placeholder,
}) => {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <DateTimeInput
          onChange={(e): void => onTimeChange(e.target.value)}
          value={timeToRender ?? ''}
          placeholder={placeholder}
          type="text"
        />
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content className={styles.timeDropdown}>
          {[...Array(48).keys()]
            .map((id) => ({
              id: `${id}`,
              time: dayjs()
                .startOf('day')
                .add(30 * id, 'minute')
                .format(uses24hTime ? 'H:mm' : 'h:mm A'),
            }))
            .map((item) => (
              <DropdownMenu.Item
                key={item.id}
                onClick={() => {
                  onTimeChange(item.time)
                }}
                className={styles.timePickerButton}
              >
                {item.time}
              </DropdownMenu.Item>
            ))}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}
TimePicker.propTypes = {
  timeToRender: PropTypes.string,
  onTimeChange: PropTypes.func.isRequired,
  uses24hTime: PropTypes.bool.isRequired,
  placeholder: PropTypes.string.isRequired,
}

TimePicker.defaultProps = {
  timeToRender: null,
}

export default TimePicker
