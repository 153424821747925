import { createPluginFactory } from '@udecode/plate'

import type {
  BufferEditor,
  BufferValue,
} from '~publish/legacy/editor/BufferEditor/types.plate'
import { normalizeMention } from './commands/normalizeMention'
import { autocompleteOnKeyDownHandler } from './handlers'
import { AutocompleteInputElement } from './nodes'
import type { AutocompletePluginOptions } from './types'
import { AutocompleteInputElementUI } from './ui'
import { withAutocomplete } from './withAutocomplete'

/**
 * Autocomplete plugin factory
 *
 * Creates a base Autocomplete plugin that can be used
 * as is, or configured for more specific functionality.
 */
export const createAutocompletePlugin = createPluginFactory<
  AutocompletePluginOptions<AutocompleteInputElement>,
  BufferValue,
  BufferEditor
>({
  key: AutocompleteInputElement.type,
  isElement: true,
  isInline: true,
  withOverrides: withAutocomplete(AutocompleteInputElement),

  component: AutocompleteInputElementUI,

  // Plugin option descriptions can be find in the
  // AutocompletePluginOptions interface
  options: {
    insertSpaceAfterMention: true,
    normalizeNode: (editor, nodeEntry) =>
      normalizeMention(editor, AutocompleteInputElement, nodeEntry),
  },

  handlers: {
    onKeyDown: autocompleteOnKeyDownHandler({
      type: AutocompleteInputElement.type,
      exitOnWhitespace: true,
    }),
  },
})
