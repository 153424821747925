import {
  findNode,
  getEditorString,
  getEndPoint,
  type GetNodeEntriesOptions,
} from '@udecode/plate'
import type { Range } from 'slate'
import type {
  BufferEditor,
  BufferValue,
} from '~publish/legacy/editor/BufferEditor/types.plate'
import type {
  AutocompleteInputElement,
  AutocompleteInputElementInterface,
} from '../nodes'

export interface getMentionTextRangeOptions
  extends Pick<GetNodeEntriesOptions<BufferValue>, 'at' | 'match'> {
  includeTrigger?: boolean
}

/**
 * Get the range of a mention's text at a given location.
 * Can be configured to include or exclude the trigger.
 * The location defaults to the current editor selection if not provided.
 */
export const getMentionTextRange = <E extends AutocompleteInputElement>(
  editor: BufferEditor,
  elementInterface: AutocompleteInputElementInterface<E>,
  options?: getMentionTextRangeOptions,
): Range | null => {
  // Set at to editor selection if not provided
  const at = options?.at || editor.selection
  const includeTrigger = !!options?.includeTrigger
  if (!at) return null

  // Get the current mention found at the 'at' location
  const currentMention = findNode(editor, {
    at,
    match: (n) => elementInterface.is(n),
  })
  if (!currentMention) return null
  const [, path] = currentMention

  // Determine the start point offset
  const mentionText = getEditorString(editor, path)
  const startsWithTrigger = mentionText?.startsWith(elementInterface.trigger)
  let startOffset = 0
  if (startsWithTrigger && !includeTrigger) startOffset = 1

  // Target first child of the mention node to get leaf node
  const anchor = { path: [...path, 0], offset: startOffset }
  const focus = getEndPoint(editor, path)

  if (anchor && focus) return { anchor, focus }
  return null
}
