import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { grayDarker, white } from '@bufferapp/ui/style/colors'
import { CloseIcon } from '@buffer-mono/popcorn'

const commonCss = `
  text-align: center;
  border-radius: 50%;
  text-decoration: none;
  border: none;
  padding: 0;
  box-sizing: content-box;
  display: flex;
  align-items: center;
  justify-content: center;
  :before {
    vertical-align: baseline;
  }
  &:hover {
    cursor: pointer;
  }
  :hover {
    transform: scale(1.15);
  }
`

const LightButton = styled.button`
  ${commonCss}
  background: ${white};
  color: ${grayDarker};
`

const ButtonDefault = styled.button`
  ${commonCss}
  background: ${grayDarker};
  color: ${white};
`

const CloseButton = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'onClick' implicitly has an 'any' ... Remove this comment to see the full error message
  onClick,
  label = 'Click to close',
  className = '',
  light = false,
}) => {
  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  const onHandleClick = (e) => {
    e.preventDefault()
    onClick(e)
  }

  return light ? (
    <LightButton
      className={[className, 'closeButtonLight'].join(' ')}
      onClick={onHandleClick}
      aria-label={label}
    >
      <CloseIcon />
    </LightButton>
  ) : (
    <ButtonDefault
      className={className}
      onClick={onHandleClick}
      aria-label={label}
    >
      <CloseIcon />
    </ButtonDefault>
  )
}

CloseButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string,
  title: PropTypes.string,
  className: PropTypes.string,
  light: PropTypes.bool,
}

export default CloseButton
