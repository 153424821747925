import type { PostStatus } from '~publish/gql/graphql'

type TagId = string
type ChannelId = string
type PostFilterViolation = 'channelIds' | 'status' | 'tagIds'

type RelevantPostSubset = {
  channel?: { id: ChannelId }
  channelId?: ChannelId
  tags?: ({ id: TagId } | TagId)[]
  status: PostStatus
}
type PostFilters = {
  channelIds?: ChannelId[] | null
  status?: PostStatus[] | null
  tagIds?: TagId[] | null
}

export const isPostSatisfyingFilters = (
  post: RelevantPostSubset,
  filters: PostFilters,
): { satisfies: boolean; filterViolations: PostFilterViolation[] } => {
  const violations: PostFilterViolation[] = []
  const postChannelId = post.channelId ?? post.channel?.id
  if (
    filters.channelIds &&
    filters.channelIds.length > 0 &&
    !filters.channelIds.includes(postChannelId ?? 'invalid')
  ) {
    violations.push('channelIds')
  }

  if (
    filters.status &&
    filters.status.length !== 0 &&
    !filters.status.includes(post.status)
  ) {
    violations.push('status')
  }

  const postTagIds =
    post.tags?.map((tag) => (typeof tag === 'string' ? tag : tag.id)) ?? []
  if (
    filters.tagIds &&
    filters.tagIds.length > 0 &&
    !filters.tagIds.some((tagId) => postTagIds.includes(tagId))
  ) {
    violations.push('tagIds')
  }

  return {
    satisfies: violations.length === 0,
    filterViolations: violations,
  }
}
