import React from 'react'
import type { Draft } from '~publish/legacy/composer/composer/entities/Draft'
import {
  RemindersComposerBox,
  RemindersWrapper,
  WrapperOnlyReminders,
} from '~publish/legacy/reminders/components/new-reminders/components/forms/header-group/styles'
import { Select } from '~publish/legacy/reminders/components/new-reminders/components/select'

export function OnlyRemindersBar({
  draft,
  disabledAutomaticDescription,
}: {
  draft: Draft
  disabledAutomaticDescription: string
}): React.JSX.Element | null {
  return (
    <RemindersComposerBox>
      <WrapperOnlyReminders>
        <RemindersWrapper>
          <Select
            id="posting-method-trigger"
            draft={draft}
            isAutomaticPostingPossible={false}
            disableOptionDescription={disabledAutomaticDescription}
          />
        </RemindersWrapper>
      </WrapperOnlyReminders>
    </RemindersComposerBox>
  )
}
