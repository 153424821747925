import { isElement } from '@udecode/plate'
import type {
  BufferBlockElement,
  BufferParagraphChildren,
} from '~publish/legacy/editor/BufferEditor/types.plate'
import { NEW_LINE_REGEX } from '../constants'

export const PARAGRAPH_ELEMENT_TYPE = 'p'

export interface ParagraphElement extends BufferBlockElement {
  type: typeof PARAGRAPH_ELEMENT_TYPE
  children: BufferParagraphChildren
}

export const ParagraphElement = {
  type: PARAGRAPH_ELEMENT_TYPE,
  is: (node: unknown): node is ParagraphElement => {
    return isElement(node) && node.type === ParagraphElement.type
  },
  new: (children: BufferParagraphChildren): ParagraphElement => {
    return {
      type: 'p',
      children,
    }
  },
  paragraphsFromText: (text: string): ParagraphElement[] => {
    return text
      .split(NEW_LINE_REGEX)
      .map((line) => ParagraphElement.new([{ text: line }]))
  },
}
