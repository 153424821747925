import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'

import { useAccount } from '~publish/legacy/accountContext'
import { useNotificationsEnabled } from '~publish/legacy/hooks/useNotificationsEnabled'
import { CLIENT_NAMES } from '~publish/legacy/constants'

export function useTriggerRemindersSetupWizard({
  placement,
  cta,
}: {
  placement?: string
  cta: string
}): () => void {
  const channelHasNotificationsEnabled = useNotificationsEnabled()
  const user = useAccount()

  const handleTriggerSetupWizard = (): void => {
    if (channelHasNotificationsEnabled) {
      return
    }

    const { actions, MODALS } = window.appshell || {}

    actions?.openModal(MODALS?.setupNotifications, {
      source: placement,
    })

    BufferTracker.remindersSetupCTAClicked({
      organizationId: user?.account?.currentOrganization?.id || null,
      clientName: CLIENT_NAMES.web,
      cta,
      placement,
    })
  }

  return handleTriggerSetupWizard
}
