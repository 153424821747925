import { parseRSS } from '../util/xml/parseRSS'
import type { FeedGroup, RSSFeedItem } from '../types'
import { mockFeedGroups } from './mockFeedData'

let feedGroups = mockFeedGroups
if (localStorage.getItem('buffer.mockFeedGroups')) {
  feedGroups = JSON.parse(
    localStorage.getItem('buffer.mockFeedGroups') as string,
  )
}
const saveFeedGroups = (groups: FeedGroup[]): void => {
  feedGroups = groups
  localStorage.setItem('buffer.mockFeedGroups', JSON.stringify(groups))
}
type FeedGroupSubscription = (groups: FeedGroup[]) => void
const feedGroupSubscriptions: FeedGroupSubscription[] = []
const updateFeedGroupSubscriptions = (): void => {
  feedGroupSubscriptions.forEach((sub) => sub(feedGroups))
}

export const onFeedGroups = (sub: FeedGroupSubscription): (() => void) => {
  feedGroupSubscriptions.push(sub)
  return () => {
    feedGroupSubscriptions.splice(feedGroupSubscriptions.indexOf(sub), 1)
  }
}

export const getFeedGroups = (): FeedGroup[] => {
  return feedGroups
}

export const setFeedGroups = (groups: FeedGroup[]): void => {
  saveFeedGroups(groups)
  updateFeedGroupSubscriptions()
}

export const deleteFeedGroup = (id: string): void => {
  setFeedGroups(feedGroups.filter((g) => g.id !== id))
}

export const updateFeedGroup = (group: FeedGroup): void => {
  const updated = feedGroups.map((g) => {
    if (g.id === group.id) {
      return group
    }
    return g
  })
  setFeedGroups(updated)
}

export const addFeedGroup = (group: FeedGroup): void => {
  setFeedGroups([...feedGroups, group])
}

export const getFeedGroupById = (id: string): FeedGroup | undefined => {
  return feedGroups.find((group) => group.id === id)
}

const loadFeedItemsByUrl = async (url: string): Promise<RSSFeedItem[]> => {
  try {
    const feedContent = await fetch(`https://corsproxy.io/?${url}`)
    const feedXml = await feedContent.text()
    const parsed = parseRSS(feedXml)
    if (parsed.kind === 'error') {
      console.error(`RSS:: Error parsing feed from ${url}`, parsed, {
        raw: feedXml,
      })
      return []
    }
    console.log(`RSS:: Parsed feed items from ${url}`, parsed.items)
    return parsed.items
  } catch (e) {
    console.error(`RSS:: Error loading feed items from ${url}`, e)
    return []
  }
}

export const loadFeedsByGroupId = async (
  feedGroupId: string,
): Promise<RSSFeedItem[]> => {
  const feeds = getFeedGroupById(feedGroupId)?.feeds ?? []

  let unsortedOutput: RSSFeedItem[] = []
  const unflattened = await Promise.all(
    feeds.map((feed) => loadFeedItemsByUrl(feed.url)),
  )
  for (const feed of unflattened) {
    unsortedOutput = [...unsortedOutput, ...feed]
  }

  return unsortedOutput.sort(
    (a, b) => b.pubDate.getTime() - a.pubDate.getTime(),
  )
}
