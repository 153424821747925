import React from 'react'
import { Text } from '@bufferapp/ui'

import type { Campaign, SelectedTag } from '~publish/legacy/campaign/types'
import { Section } from './styles'
import { TagsSelector } from '~publish/legacy/shared-components'
import ComposerActionCreators from '~publish/legacy/composer/composer/action-creators/ComposerActionCreators'

type HeaderProps = {
  editMode: boolean
  draftMode: boolean
  duplicateMode: boolean
  showCampaignSelector: boolean
  // eslint-disable-next-line react/require-default-props
  campaigns?: Campaign[] | []
  // eslint-disable-next-line react/require-default-props
  campaignId?: string | null
  preSelectedTags?: SelectedTag[]
}

export const Header = ({
  editMode = false,
  draftMode = false,
  duplicateMode = false,
  preSelectedTags = [],
}: HeaderProps): JSX.Element => {
  const onSelectTags = (selectedTags: SelectedTag[]): void => {
    ComposerActionCreators.updateDraftsTags(selectedTags)
  }
  const action = duplicateMode ? 'Duplicate' : editMode ? 'Edit' : 'Create'
  const type = draftMode ? 'Draft' : 'Post'

  return (
    <Section>
      <Text role="heading" type="label">
        {action} {type}
      </Text>
      <TagsSelector
        onSelectTags={onSelectTags}
        preSelectedTags={preSelectedTags}
        source="postsComposer"
      />
    </Section>
  )
}
