import { VALIDATION_CODE } from '~publish/legacy/validation/constants'
import ValidationFail from '~publish/legacy/validation/ValidationFail'
import ValidationSuccess from '~publish/legacy/validation/ValidationSuccess'
import { type MaxSizeRule, ValidationRuleName } from '../types'
import { createRuleHandler } from './createRuleHandler'
import { getRuleMessages } from './getRuleMessages'

export const maxSize = createRuleHandler<MaxSizeRule>({
  ruleName: ValidationRuleName.MaxSize,

  messages: {
    specific: 'File size exceeds the maximum limit ({max} KB)',
    generic: 'One or more images exceed the maximum limit ({max} KB)',
  },

  validate: (rule, media) => {
    const { max } = rule.ruleConfig

    if (media.size && Number(media.size) > Number(max)) {
      const messages = getRuleMessages(rule)
      return new ValidationFail(
        messages.generic,
        VALIDATION_CODE.FILE_SIZE,
        messages,
        {},
      )
    }

    return new ValidationSuccess()
  },
})
