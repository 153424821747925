import type ValidationFail from '~publish/legacy/validation/ValidationFail'
import type ValidationSuccess from '~publish/legacy/validation/ValidationSuccess'
import type { Media } from '../types'
import { aspectRatioMatch } from './rules/aspectRatioMatch'
import { aspectRatioRange } from './rules/aspectRatioRange'
import { maxSize } from './rules/maxSize'
import { minSize } from './rules/minSize'
import type {
  AnyValidationRule,
  ValidationRule,
  ValidationRuleModule,
} from './types'

export const handlers: ValidationRuleModule<AnyValidationRule>[] = [
  aspectRatioMatch,
  aspectRatioRange,
  minSize,
  maxSize,
]

export function validateMedia(
  rules: ValidationRule[],
  media: Media | null,
): ValidationFail | ValidationSuccess | null {
  if (Array.isArray(rules) && rules.length === 0) return null
  if (!media) return null

  for (const ruleDef of rules) {
    const handler = handlers.find(
      (handler) => handler.ruleName === ruleDef.ruleName,
    )

    if (handler) {
      return handler.validate(ruleDef, media)
    }
  }

  return null
}
