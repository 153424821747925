import React, { useCallback } from 'react'
import { NavLink } from 'react-router-dom'
import { clsx } from 'clsx'
import { TagIcon, SettingsIcon, Tooltip } from '@buffer-mono/popcorn'

import { useAppSelector } from '~publish/legacy/store'
import { useAccount } from '~publish/legacy/accountContext'
import UserEntity from '~publish/legacy/user/UserEntity'
import { getAccountURL } from '~publish/legacy/utils/formatters/getURL'
import { tagsPage } from '~publish/legacy/routes'

import { SideNavItem } from './SideNavItem'

import styles from './PageSidebar.module.css'

export const ManagementLinks: React.FC = () => {
  const account = useAccount()
  const isAdmin = UserEntity.isAdmin(account)

  const onClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>): void => {
      if (!isAdmin) {
        e.preventDefault()
      }
    },
    [isAdmin],
  )

  return (
    <ul className={styles.bottomLinks}>
      <SideNavItem>
        <NavLink
          to={tagsPage.route}
          className={(isActive: boolean): string =>
            clsx(isActive && styles.selected)
          }
        >
          <TagIcon />
          {isAdmin ? 'Manage Tags' : 'View Tags'}
        </NavLink>
      </SideNavItem>
      <ManageChannelsTooltip isAdmin={isAdmin}>
        <SideNavItem disabled={!isAdmin}>
          <a
            href={`${getAccountURL()}/channels`}
            data-testid="profile-sidebar-channels-action"
            onClick={onClick}
          >
            <SettingsIcon />
            Manage Channels
          </a>
        </SideNavItem>
      </ManageChannelsTooltip>
    </ul>
  )
}

function ManageChannelsTooltip({
  children,
  isAdmin,
}: {
  children: React.ReactNode
  isAdmin: boolean
}): JSX.Element {
  const ownerEmail = useAppSelector(
    (state) => state.organizations.selected?.ownerEmail ?? 'the owner',
  )
  if (isAdmin) {
    return <>{children}</>
  }
  return (
    <Tooltip
      content={`It looks like you haven't been given permission to add/manage channels. Request access from ${ownerEmail} or any other Admin.`}
    >
      {children}
    </Tooltip>
  )
}
