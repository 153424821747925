import { useCallback, useMemo } from 'react'
import {
  type Draft,
  DraftMethods,
} from '~publish/legacy/composer/composer/entities/Draft'
import { handleUploadFinished } from '~publish/legacy/composer/composer/action-creators/handleUploadFinished'
import { handleUploadStarted } from '~publish/legacy/composer/composer/action-creators/handleUploadStarted'
import { useSplitEnabled } from '@buffer-mono/features'
import { useUploader } from './useUploader'
import { handleUploadError } from '~publish/legacy/composer/composer/action-creators/handleUpdloadError'
import { handleUploadAttempted } from '~publish/legacy/composer/composer/action-creators/handleUploadAttempted'
import type { UploaderEvents } from '~publish/legacy/uploads/lib/Uploader'

export const useComposerUploader = ({
  draft,
}: {
  draft: Draft
}): ReturnType<typeof useUploader> => {
  const { isEnabled: isLinkedInCarouselEnabled } = useSplitEnabled(
    'CORE-linkedin-carousels',
  )

  const fileRestrictions = useMemo(() => {
    return DraftMethods.getUploaderRestrictions(draft, {
      isLinkedInCarouselEnabled,
    })
  }, [draft, isLinkedInCarouselEnabled])

  const eventHandlers = useMemo(() => {
    return {
      'upload-started': (upload) =>
        handleUploadStarted({ upload, draftId: draft.id }),
      'upload-finished': (upload) =>
        handleUploadFinished({ upload, draftId: draft.id }),
      'file-validation-failed': (_file, error) => handleUploadError(error),
      'upload-failed': (_, error) => handleUploadError(error),
      'upload-prevented': (error) => handleUploadError(error),
      'file-fetch-failed': () =>
        handleUploadError({
          message: `We are really sorry but we cannot get the file at this moment. Please, try it later.`,
        }),
      'file-upload-attempted': handleUploadAttempted,
    } as Partial<UploaderEvents>
  }, [draft.id])

  const getCustomCount = useCallback(() => {
    return DraftMethods.getFileAttachmentCount(draft)
  }, [draft])

  return useUploader({
    id: DraftMethods.getUploaderId(draft),
    fileRestrictions,
    eventHandlers,
    getCustomCount,
  })
}
