import React, { useState } from 'react'
import { Sidebar, PlusIcon, HashIcon } from '@buffer-mono/popcorn'
import { useParams } from 'react-router-dom'

import { useFeedGroups } from '../../hooks/useFeedGroups'
import { SidebarLink } from '../../../components/SidebarLink/SidebarLink'
import { feedGroupPage } from '~publish/legacy/routes'
import { EditFeedGroup } from '../EditFeedGroup/EditFeedGroup'

export function FeedGroupsNav(): JSX.Element {
  const { feedGroupId } = useParams<{ feedGroupId?: string }>()
  const groups = useFeedGroups()
  const [creatingFeedGroup, setCreatingFeedGroup] = useState(false)

  return (
    <>
      {creatingFeedGroup && (
        <EditFeedGroup
          open={creatingFeedGroup}
          onClose={(): void => setCreatingFeedGroup(false)}
        />
      )}
      <Sidebar.Button
        prefix={<PlusIcon />}
        onClick={(): void => setCreatingFeedGroup(true)}
      >
        Create Feed Group
      </Sidebar.Button>
      {groups.map((group) => (
        <SidebarLink
          key={group.id}
          icon={<HashIcon />}
          selected={feedGroupId === group.id}
          to={feedGroupPage.linkTo(group.id)}
        >
          {group.name}
        </SidebarLink>
      ))}
    </>
  )
}
