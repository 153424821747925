import styled from 'styled-components'
import { yellowDarker } from '@bufferapp/ui/style/colors'
import { fontFamily } from '@bufferapp/ui/style/fonts'

export const Error = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
  margin: 0;
  font-family: ${fontFamily};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${yellowDarker};

  svg {
    fill: ${yellowDarker};
    color: ${yellowDarker};
    margin-right: 8px;
  }
`
