import styled from 'styled-components'
import { fontFamily } from '@bufferapp/ui/style/fonts'
import { purpleLightest, grayDarker, gray } from '@bufferapp/ui/style/colors'

import { VerticalWrapperStyles } from '~publish/legacy/ai/styles/VerticalWrapper'

export const VerticalWrapper = styled.div`
  ${VerticalWrapperStyles}
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-height: 450px;
`

export const ContentWrapper = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 8px;
  background: ${(props): string =>
    props.disabled
      ? `
    linear-gradient(90deg, #fdfbff 0%, #f8f9ff 100%)
  `
      : `
    linear-gradient(90deg, #faf5ff 0%, #edefff 100%)
  `};
  border: 1px solid
    ${(props): string => (props.disabled ? '#f8eeff' : purpleLightest)};
  border-radius: 4px;
`

export const Content = styled.pre<{ disabled?: boolean }>`
  font-family: ${fontFamily};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  margin: 0;
  max-width: 100%;
  width: 100%;
  max-height: 250px;
  overflow-y: ${(props): string => (props.disabled ? 'hidden' : 'auto')};
  color: ${(props): string => (props.disabled ? gray : grayDarker)};
  user-select: ${(props): string => (props.disabled ? 'none' : 'text')};
`

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`

export const PrimaryButtonsWrapper = styled.div`
  display: flex;
  align-items: center;

  button {
    &:not(:last-of-type) {
      margin-right: 8px;
    }
  }
`

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 12px;
  flex-wrap: wrap;
`

export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 32px;
  height: 100%;
`
