import { isElement, type TElement, type TNode } from '@udecode/plate'
import type { PlainText } from '~publish/legacy/editor/BufferEditor/types.plate'

export const GENERATE_CONTENT_ELEMENT_TYPE = 'generate-content-element'

export interface GenerateContentElement extends TElement {
  type: string
  children: [PlainText]
}

/**
 * Element interface responsible for interacting
 * with generate content elements
 */
export const GenerateContentElement = {
  type: GENERATE_CONTENT_ELEMENT_TYPE,
  is(node: TNode): node is GenerateContentElement {
    return isElement(node) && node.type === GenerateContentElement.type
  },
  new(item: { text: string }, type: string): GenerateContentElement {
    return {
      children: [{ text: item.text }],
      type,
    }
  },
}
