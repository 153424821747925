import styled from 'styled-components'
import { blue, grayDark, yellowDarker } from '@bufferapp/ui/style/colors'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 12px;
  color: ${grayDark};
`

export const UpgradeButton = styled.button<{ highlighted: boolean }>`
  background: none;
  border: none;
  cursor: pointer;
  color: ${(props) => (props.highlighted ? blue : grayDark)};
  padding: 0;
  margin: 0 4px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 12px;
  text-decoration: underline;
`

export const NoCreditsLeft = styled.div`
  color: ${yellowDarker};
  display: flex;
  align-items: center;

  svg {
    height: 12px;
    width: 12px;
    margin-right: 8px;
  }
`
