import styled from 'styled-components'
import { ImageOrientation } from '../../../Common/utils'

import { previewBlack } from '../../../Common/styles'

export const ImageWrapper = styled.div<{ orientation: string }>`
  margin-top: 16px;
  height: ${({ orientation }) => {
    if (orientation === ImageOrientation.Landscape) {
      return 'auto'
    }
    return '300px'
  }};
  width: 100%;
  background-color: ${previewBlack};
`

export const Image = styled.img<{ orientation: string }>`
  display: block;
  height: 100%;
  width: ${({ orientation }) => {
    if (orientation === ImageOrientation.Portrait) {
      return 'auto'
    }
    return '100%'
  }};
  margin: auto;
`
