import { css } from 'styled-components'

import {
  Video as CommonVideo,
  PlayButton as CommonPlayButton,
} from '../../../Common/VideoPlayer/styles'
import { ImageOrientation } from '../../../Common/utils'

export const Video = css`
  ${CommonVideo}
`

export const PlayButton = css`
  ${CommonPlayButton}
  background-color: rgba(255, 255, 255, 0.8);

  svg {
    color: rgba(0, 0, 0, 0.8);
    fill: rgba(0, 0, 0, 0.8);
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
    svg {
      color: rgba(0, 0, 0, 0.9);
      fill: rgba(0, 0, 0, 0.9);
    }
  }
`

export const Wrapper = css<{ orientation: string }>`
  position: relative;
  width: 100%;

  height: ${({ orientation }) => {
    if (orientation === ImageOrientation.Portrait) {
      return '350px'
    }
    if (orientation === ImageOrientation.Landscape) {
      return '161px'
    }
    return '300px'
  }};

  video {
    ${Video}
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  button {
    ${PlayButton}
  }
`
