import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'

import type {
  ComposerUserData,
  Subprofile,
} from '~publish/legacy/composer/composer/stores/types'
import { useQueryParam } from '~publish/hooks/useQueryParam'
import type { RpcUpdate } from '~publish/legacy/post/types'
import EmptySlot from './EmptySlot'
import Header from './Header'
import PostContent from './PostContent'
import type { QueueItem } from './types'

interface PropsForPosts {
  subprofiles?: Subprofile[]
  userData?: ComposerUserData
  isManager?: boolean
  draggable?: boolean
  hasFirstCommentFlip?: boolean
  hasPushNotifications?: boolean
  hasCampaignsFeature?: boolean
  profileServiceType?: string
  profileService?: string
  hasAnalyticsOnPosts?: boolean
  serviceId?: string
  onCampaignTagClick?: (campaignId: string) => void
  onApproveClick?: (draft: RpcUpdate) => void
  onRevertApprovalClick?: (draft: RpcUpdate) => void
  onRequestApprovalClick?: (draft: RpcUpdate) => void
  onRescheduleClick?: (draft: RpcUpdate) => void
  onDeleteConfirmClick?: (post: RpcUpdate) => void
  onDropPost?: (id: string, timestamp: number, day: string) => void
  onEditClick?: ({ postId }: { postId: string }) => void
  onRequeueClick?: (post: RpcUpdate) => void
  onSetRemindersClick?: (args: { type: string }) => void
  onShareNowClick?: (post: RpcUpdate) => void
  onMovePostToDraftsClick?: (post: RpcUpdate) => void
  onShareAgainClick?:
    | ((args: { post: RpcUpdate }) => void)
    | ((post: RpcUpdate, viewType?: string) => void)
  onShareLinkClick?: (args: {
    post: RpcUpdate
    selectedProfileIds: string[]
  }) => void
  onPreviewClick?: (post: RpcUpdate) => void
  onMobileClick?: (post: RpcUpdate) => void
  onSwapPosts?: (postSource: unknown, postTarget: unknown) => void
}

interface QueueItemsComponentProps {
  items: QueueItem[]
  pinned: boolean
  type: string
  shouldRenderCalendarButtons?: boolean
  onEmptySlotClick?: (args: {
    profile_service: string
    scheduled_at: number
    scheduledAt: number
    due_at: number
    pinned: boolean
  }) => void
  shouldHideCreatePostButton: boolean
  shouldDisplayTimezone?: boolean
  isCampaign: boolean
}

type QueueItemsProps = QueueItemsComponentProps & PropsForPosts

const QueueItems = ({
  items,
  type,
  pinned,
  onEmptySlotClick,
  shouldRenderCalendarButtons,
  shouldHideCreatePostButton,
  shouldDisplayTimezone,
  isCampaign,
  ...propsForPosts
}: QueueItemsProps): JSX.Element => {
  const scrollToPostRef = useRef<HTMLDivElement>()
  const [postIdFromQuery] = useQueryParam<string>('postId')

  useEffect(() => {
    if (scrollToPostRef.current) {
      // Scroll the page to the post from query params
      scrollToPostRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [scrollToPostRef])

  if (items.length === 0) {
    const item = {
      text: '',
      dayOfWeek: '',
      date: '',
      id: '',
      queueItemType: '',
    }
    return (
      <Header
        item={item}
        isFirstItem
        shouldRenderCalendarButtons={shouldRenderCalendarButtons}
        shouldHideCreatePostButton={shouldHideCreatePostButton}
        shouldDisplayTimezone={shouldDisplayTimezone}
      />
    )
  }

  return (
    <>
      {items.map((item, index) => {
        const { queueItemType, ...rest } = item

        let QueueSection = null

        switch (queueItemType) {
          case 'header':
            QueueSection = (
              <Header
                key={`header-${item.id}`}
                item={item}
                isFirstItem={index === 0}
                shouldRenderCalendarButtons={shouldRenderCalendarButtons}
                shouldHideCreatePostButton={shouldHideCreatePostButton}
                shouldDisplayTimezone={shouldDisplayTimezone}
              />
            )
            break
          case 'post':
            QueueSection = (
              <PostContent
                key={`post-content-${item.id}`}
                index={index}
                post={rest}
                queueType={type}
                isCampaign={isCampaign}
                // Ref is used to scroll to element
                // @ts-expect-error TS(2322) FIXME: Type 'MutableRefObject<HTMLDivElement | undefined>... Remove this comment to see the full error message
                postRef={item.id === postIdFromQuery ? scrollToPostRef : null}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...propsForPosts}
              />
            )
            break
          case 'slot': {
            const isStories = type === 'stories'
            QueueSection = (
              <EmptySlot
                key={`empty-slot-${item.id}`}
                item={item}
                pinned={pinned}
                customLabel={isStories ? 'Add to Story' : null}
                customHoverMessage={isStories ? 'Schedule a Story' : null}
                onEmptySlotClick={onEmptySlotClick}
              />
            )
            break
          }
          default:
            break
        }

        return QueueSection
      })}
    </>
  )
}

QueueItems.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
  pinned: PropTypes.bool,
  type: PropTypes.string,
  shouldRenderCalendarButtons: PropTypes.bool,
  onEmptySlotClick: PropTypes.func,
  shouldHideCreatePostButton: PropTypes.bool,
  isCampaign: PropTypes.bool,
  timezoneItems: PropTypes.shape({
    onTimezoneClick: PropTypes.func,
    profileTimezone: PropTypes.string,
    shouldDisplayTimezone: PropTypes.bool,
  }),
}

QueueItems.defaultProps = {
  items: [],
  shouldHideCreatePostButton: false,
  type: 'post',
  onEmptySlotClick: (): void => undefined,
  pinned: false,
  isCampaign: false,
  timezoneItems: {},
}

export default QueueItems
