import { useState, useEffect, useCallback } from 'react'
import type {
  Idea,
  IdeasData,
  IdeasDataEdges,
  Tag,
} from '~publish/pages/Create/types'
import { gql, useApolloClient, useLazyQuery } from '@apollo/client'
import { useAccount } from '~publish/legacy/accountContext'

const QUERY_IDEAS = gql`
  query FilteredIdeasByTag($input: IdeasListInput!) {
    ideas(input: $input) {
      ... on PaginatedIdeasList {
        edges {
          node {
            id
            content {
              title
              text
              aiAssisted
              media {
                id
                url
                alt
                thumbnailUrl
                type
                size
                source {
                  name
                  id
                }
              }
              tags {
                id
                name
                color
              }
            }
            organizationId
            groupId
            createdAt
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`

function useFilterIdeasByTag(tagId: string): {
  ideas: Idea[]
  loading: boolean
  loadMore: (tagId: string) => void
  resetIdeasAndFetch: () => void
} {
  const numIdeas = 100
  const [ideas, setIdeas] = useState<Idea[]>([])
  const [pageInfo, setPageInfo] = useState<{
    hasNextPage?: boolean
    endCursor?: string
  }>({})
  const [loading, setLoading] = useState<boolean>(false)
  const { account } = useAccount() as any
  const organizationId = account?.currentOrganization?.id

  const client = useApolloClient()
  const [getIdeas, { loading: queryLoading, fetchMore }] = useLazyQuery(
    QUERY_IDEAS,
    {
      fetchPolicy: 'network-only',
    },
  )

  const removeTypenameFromTags = (
    ideas: IdeasDataEdges[],
  ): IdeasDataEdges[] => {
    return ideas.map((idea: IdeasDataEdges) => {
      const { tags, ...rest } = idea.node.content
      const updatedTags =
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        tags?.map(({ __typename, ...tagData }: Tag) => tagData) ?? []
      return {
        node: {
          ...idea.node,
          content: {
            ...rest,
            tags: updatedTags,
          },
        },
      }
    })
  }

  function onFetchComplete(res: IdeasData): void {
    const pageInfoRes = res.ideas?.pageInfo || {}
    setPageInfo(pageInfoRes)

    const resIdeas = res.ideas?.edges || []
    const ideasWithoutTypename = removeTypenameFromTags(resIdeas)

    setIdeas(ideasWithoutTypename.map((i) => i.node))
    setLoading(false)
  }

  const getIdeasAsync = async (tagId: string): Promise<void> => {
    const result = await getIdeas({
      variables: {
        input: {
          organizationId: `${organizationId}`,
          first: numIdeas,
          filter: {
            by: 'tags',
            value: tagId,
          },
        },
      },
    })
    if (result.data) {
      onFetchComplete(result.data)
    }
  }

  const resetIdeasAndFetch = (): void => {
    setIdeas([])
    client.cache.evict({
      fieldName: 'ideas',
      broadcast: false,
    })
    if (account?.currentOrganization?.id && tagId) {
      getIdeasAsync(tagId)
    }
  }

  useEffect(() => {
    setLoading(true)
    resetIdeasAndFetch()
  }, [account, tagId])

  let debounceTimer: number

  function debounce(callback: () => void, time: number): void {
    window.clearTimeout(debounceTimer)
    debounceTimer = window.setTimeout(callback, time)
  }

  function onFetchMoreComplete(res: IdeasData, existingIdeas: Idea[]): void {
    const pageInfoRes = res.ideas?.pageInfo || {}
    setPageInfo(pageInfoRes)

    const resIdeas = res.ideas?.edges || []
    const ideasWithoutTypename = removeTypenameFromTags(resIdeas)
    const moreIdeas = ideasWithoutTypename.map((i) => i.node)

    setIdeas([...existingIdeas, ...moreIdeas])
    setLoading(false)
  }

  const loadMoreAsync = async (
    tagId: string,
    existingIdeas: Idea[],
  ): Promise<void> => {
    const result = await fetchMore({
      variables: {
        input: {
          organizationId: `${organizationId}`,
          first: numIdeas,
          after: pageInfo.endCursor,
          filter: {
            by: 'tags',
            value: tagId,
          },
        },
      },
    })
    if (result.data) {
      onFetchMoreComplete(result.data, existingIdeas)
    }
  }

  const loadMore = useCallback(
    (tagId: string) => {
      if (pageInfo.hasNextPage && !queryLoading) {
        debounce(() => {
          loadMoreAsync(tagId, ideas)
        }, 250)
      }
    },
    [pageInfo, ideas],
  )

  return {
    ideas,
    loadMore,
    loading,
    resetIdeasAndFetch,
  }
}

export { useFilterIdeasByTag }
