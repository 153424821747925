import { connect } from 'react-redux'

import { actions as analyticsActions } from '~publish/legacy/analytics-middleware/actions'
import {
  blueprintsPage,
  calendarPage,
  campaignsPage,
  channel,
  getMatch,
  newPost,
  profileTabPages,
} from '~publish/legacy/routes'
import { getURL } from '~publish/legacy/utils/formatters'

import ProfileSidebar from './components/ProfileSidebar'
import { actions } from './reducer'
import type { Profile } from './types'

export default connect(
  (state, ownProps) => {
    const accountChannelsURL = getURL.getAccountChannelsURL()

    // @ts-expect-error TS(2339) FIXME: Property 'profileSidebar' does not exist on type '... Remove this comment to see the full error message
    const { profiles } = state.profileSidebar

    const reachedChannelLimit =
      // @ts-expect-error TS(2339) FIXME: Property 'organizations' does not exist on type 'D... Remove this comment to see the full error message
      state.organizations.selected?.profileLimit <=
      // @ts-expect-error TS(2339) FIXME: Property 'organizations' does not exist on type 'D... Remove this comment to see the full error message
      state.organizations.selected?.profilesCount

    return {
      // @ts-expect-error TS(2339) FIXME: Property 'profileSidebar' does not exist on type '... Remove this comment to see the full error message
      loading: state.profileSidebar.loading,
      // @ts-expect-error TS(2339) FIXME: Property 'profileSidebar' does not exist on type '... Remove this comment to see the full error message
      selectedProfile: state.profileSidebar.selectedProfile,
      // @ts-expect-error TS(2339) FIXME: Property 'profileId' does not exist on type '{}'.
      selectedProfileId: ownProps.profileId,
      profiles,
      // @ts-expect-error TS(2339) FIXME: Property 'profileSidebar' does not exist on type '... Remove this comment to see the full error message
      searchText: state.profileSidebar.searchText,
      // @ts-expect-error TS(2339) FIXME: Property 'i18n' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
      translations: state.i18n.translations['profile-sidebar'],
      // @ts-expect-error TS(2339) FIXME: Property 'organizations' does not exist on type 'D... Remove this comment to see the full error message
      profileLimit: state.organizations.selected?.profileLimit,
      // @ts-expect-error TS(2339) FIXME: Property 'organizations' does not exist on type 'D... Remove this comment to see the full error message
      hasCampaignsFlip: state.organizations.selected?.hasCampaignsFeature,
      canManageSocialAccounts:
        // @ts-expect-error TS(2339) FIXME: Property 'organizations' does not exist on type 'D... Remove this comment to see the full error message
        state.organizations.selected?.canManageSocialAccounts,
      // @ts-expect-error TS(2339) FIXME: Property 'organizations' does not exist on type 'D... Remove this comment to see the full error message
      ownerEmail: state.organizations.selected?.ownerEmail,
      isCampaignsSelected: !!getMatch({
        // @ts-expect-error TS(2339) FIXME: Property 'router' does not exist on type 'DefaultR... Remove this comment to see the full error message
        pathname: state.router?.location?.pathname,
        route: campaignsPage.route,
      }),
      isBlueprintsSelected: !!getMatch({
        // @ts-expect-error TS(2339) FIXME: Property 'router' does not exist on type 'DefaultR... Remove this comment to see the full error message
        pathname: state.router?.location?.pathname,
        route: blueprintsPage.route,
      }),
      reachedChannelLimit,
      manageChannelsURL:
        accountChannelsURL || getURL.getManageSocialAccountURL(),
      isCalendarSelected:
        !!getMatch({
          // @ts-expect-error TS(2339) FIXME: Property 'router' does not exist on type 'DefaultR... Remove this comment to see the full error message
          pathname: state.router?.location?.pathname,
          route: calendarPage.route,
        }) ||
        !!getMatch({
          // @ts-expect-error TS(2339) FIXME: Property 'router' does not exist on type 'DefaultR... Remove this comment to see the full error message
          pathname: state.router?.location?.pathname,
          route: newPost.route,
        }),
    }
  },
  (dispatch) => ({
    onProfileClick: (
      profile: Profile,
      options: { shouldRedirectToChannelPage?: boolean } = {},
    ): void => {
      // TODO: this needs to be refactored to use <Link> components
      // if the preview is enabled, open it instead
      if (options?.shouldRedirectToChannelPage) {
        dispatch(
          channel.goTo({
            channelId: profile.id,
            tab: 'queue',
          }),
        )
      } else {
        dispatch(
          profileTabPages.goTo({
            profileId: profile.id,
            tabId: 'queue',
          }),
        )
      }
    },
    onDropProfile: ({
      // @ts-expect-error TS(7031) FIXME: Binding element 'commit' implicitly has an 'any' t... Remove this comment to see the full error message
      commit,
      // @ts-expect-error TS(7031) FIXME: Binding element 'organizationId' implicitly has an... Remove this comment to see the full error message
      organizationId,
      // @ts-expect-error TS(7031) FIXME: Binding element 'profileLimit' implicitly has an '... Remove this comment to see the full error message
      profileLimit,
      // @ts-expect-error TS(7031) FIXME: Binding element 'dragIndex' implicitly has an 'any... Remove this comment to see the full error message
      dragIndex,
      // @ts-expect-error TS(7031) FIXME: Binding element 'hoverIndex' implicitly has an 'an... Remove this comment to see the full error message
      hoverIndex,
    }) => {
      // Sometimes commit can be false. We don't want to track this.
      if (commit) {
        const metadata = {
          product: 'publish',
          organizationId,
          clientName: 'publishWeb',
        }
        dispatch(analyticsActions.trackEvent('Channels Reordered', metadata))
      }
      dispatch(
        actions.onDropProfile({
          commit,
          profileLimit,
          dragIndex,
          hoverIndex,
        }),
      )
    },
    // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
    onSearchProfileChange: (value) => {
      dispatch(actions.handleSearchProfileChange({ value }))
    },
    onCampaignsButtonClick: () => {
      dispatch(campaignsPage.goTo())
    },
    onBlueprintsButtonClick: () => {
      dispatch(blueprintsPage.goTo())
    },
    onCalendarButtonClick: () => {
      const metadata = {
        source: 'sidebar',
        calendarVersion: 'new',
      }
      dispatch(analyticsActions.trackEvent('Calendar Opened', metadata))
      dispatch(calendarPage.goTo())
    },
  }),
)(ProfileSidebar)

export { default as middleware } from './middleware'
export { actions, actionTypes, default as reducer } from './reducer'
