import React from 'react'

type BoundaryProps = React.PropsWithChildren<{
  fallbackComponent?: typeof React.Component | React.FunctionComponent
  defaultFallbackComponent?: typeof React.Component | React.FunctionComponent
}>

type BoundaryState = {
  hasError: boolean
  error?: Error
  info?: React.ErrorInfo
}
class ErrorBoundary extends React.Component<BoundaryProps, BoundaryState> {
  constructor(props: BoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error: Error, info: React.ErrorInfo): void {
    this.setState({
      hasError: true,
      error,
      info,
    })
    if (typeof console !== 'undefined' && typeof console.log !== 'undefined') {
      console.log({ error, info })
    }
  }

  render(): React.ReactNode {
    if (this.state.hasError) {
      if (this.props.fallbackComponent) {
        const FallbackComponent = this.props.fallbackComponent
        return <FallbackComponent {...this.state} />
      }

      if (this.props.defaultFallbackComponent) {
        const FallbackComponent = this.props.defaultFallbackComponent
        return <FallbackComponent {...this.state} />
      }
      return (
        <h1>
          Something has gone wrong. Our team has been informed of the error.
        </h1>
      )
    }

    return this.props.children ? this.props.children : null
  }
}

export default ErrorBoundary
