import { createPluginFactory } from '@udecode/plate'
import type { BufferValue } from '~publish/legacy/editor/BufferEditor/types.plate'
import type { BufferPluginOptions } from '../types'
import { findAndWrapLinkedIndAnnotations } from './commands/findAndWrapLinkedIndAnnotations'
import { LinkedInAnnotationUI } from './ui/LinkedInAnnotationUI'
import { withLinkedInAnnotations } from './withLinkedInAnnotations'

export const createLinkedInAnnotationsPlugin = createPluginFactory<
  BufferPluginOptions,
  BufferValue
>({
  key: 'linkedin-annotation',
  isElement: true,
  isInline: true,
  isVoid: true,
  component: LinkedInAnnotationUI,
  withOverrides: withLinkedInAnnotations,
  options: {
    textToNodes: findAndWrapLinkedIndAnnotations,
  },
})
