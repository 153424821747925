import React from 'react'

import Avatar from '@bufferapp/ui/Avatar'
import {
  getPostDate,
  getCtaText,
} from '~publish/legacy/utils/channels/googlebusiness/helpers'
import {
  draftContentPropType,
  profilePreviewPropType,
} from '../../../Common/propTypes'
import type { GoogleBusinessContent, ProfilePreview } from '../../../types'
import { PostWrapper } from '../../../Common/styles'
import * as Styles from './styles'
import { DEFAULT_AVATAR } from '../../../Common/utils'

import { PostBody } from '../PostBody'

const verificationBadge =
  'https://buffer-publish.s3.amazonaws.com/images/googlebusiness-verification-badge.svg'

const GoogleBusinessPost = ({
  content,
  profile,
}: {
  content: GoogleBusinessContent
  profile: ProfilePreview
}): JSX.Element => {
  const { avatar, username } = profile
  const { channelData, scheduledAt, updateType } = content
  const googlebusiness = channelData?.googlebusiness

  const isWhatsNew = updateType === 'whats_new'
  const isEvent = updateType === 'event'
  const isOffer = updateType === 'offer'

  const shouldShowOfferLink =
    isOffer && googlebusiness?.link && !googlebusiness?.terms
  const shouldShowTermsAndConditions = isOffer && googlebusiness?.terms
  const shouldShowCtaLink =
    (isWhatsNew || isEvent) && googlebusiness?.cta && googlebusiness?.link

  return (
    <PostWrapper>
      <Styles.Header>
        <Styles.AvatarWrapper>
          <Avatar
            data-testid="avatar"
            src={avatar}
            alt={username}
            size="small"
            fallbackUrl={DEFAULT_AVATAR}
          />
          <Styles.VerifiedBadge
            src={verificationBadge}
            alt="verification badge"
          />
        </Styles.AvatarWrapper>
        <Styles.UsernameWrapper>
          <Styles.Username>{username}</Styles.Username>
          <Styles.PostDate>{getPostDate(scheduledAt)}</Styles.PostDate>
        </Styles.UsernameWrapper>
        <Styles.DotsIcon />
      </Styles.Header>
      <PostBody content={content} />
      <Styles.Footer>
        {shouldShowOfferLink && <Styles.Cta>Redeem offer</Styles.Cta>}
        {shouldShowTermsAndConditions && (
          <Styles.TermsAndConditions>
            Terms and Conditions
          </Styles.TermsAndConditions>
        )}
        {shouldShowCtaLink && (
          <Styles.Cta>{getCtaText(googlebusiness?.cta)}</Styles.Cta>
        )}
        <Styles.Share />
      </Styles.Footer>
    </PostWrapper>
  )
}

GoogleBusinessPost.propTypes = {
  content: draftContentPropType.isRequired,
  profile: profilePreviewPropType.isRequired,
}

export default GoogleBusinessPost
