import React from 'react'
import styled from 'styled-components'
import { black, white, grayDarker } from '@bufferapp/ui/style/colors'
import { getColorContrast } from '~publish/legacy/shared-components/ColorPicker/utils/HexValidations'
import { CheckIcon } from '@buffer-mono/popcorn'
// import { Checkmark } from '@bufferapp/ui/Icon'

const colorContrastIsBlack = (color: string): boolean =>
  color ? getColorContrast(color) === black : false

const Circle = styled.span<{ color: string; selected?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  padding: 0px;
  border-radius: 50%;
  background-color: ${(props): string => props.color};
  color: ${(props): string =>
    colorContrastIsBlack(props.color) ? grayDarker : white};
  border: ${(props): string => `1px solid ${props.color}`};
  box-sizing: border-box;
  cursor: pointer;
`

const Label = styled.label`
  height: 32px;
`

const ColorInput = styled.input`
  opacity: 0;
  height: 0;
  width: 0;
  margin: 0;
  display: block;
  :focus ~ ${Circle} {
    outline: 2px solid var(--focus-color);
    outline-offset: 2px;
  }
`

const CheckmarkWrapper = styled.div`
  position: absolute;
  display: flex;
`

export interface SimpleColorSwatchProps
  extends React.HTMLAttributes<HTMLInputElement> {
  color: string
  colorName: string
  selected?: boolean
  onColorClick: (evt: React.ChangeEvent<HTMLInputElement>) => void
}
const SimpleColorSwatch = ({
  color,
  colorName,
  selected = false,
  onColorClick,
  ...props
}: SimpleColorSwatchProps): JSX.Element => (
  <Label htmlFor={colorName}>
    <ColorInput
      {...props}
      type="radio"
      id={colorName}
      name="colorPicker"
      value={color}
      aria-label={`color ${colorName}`}
      checked={selected}
      onChange={onColorClick}
    />
    <Circle color={color} selected={selected}>
      {selected && (
        <CheckmarkWrapper>
          <CheckIcon color={'currentColor'} />
        </CheckmarkWrapper>
      )}
    </Circle>
  </Label>
)

export default SimpleColorSwatch
