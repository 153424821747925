import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import type { UniqueIdentifier } from '@dnd-kit/core'

import { graphql } from '~publish/gql'
import type { IdeaCard_IdeaFragment as Idea } from '~publish/gql/graphql'
import { useAppSelector } from '~publish/legacy/store'
import { selectCurrentOrganizationId } from '~publish/legacy/organizations/selectors'
import { useQueryParam } from '~publish/hooks/useQueryParam'

import { buildItemsObject } from '../helpers/helpers'

export const GetIdeasBoard = graphql(/* GraphQL */ `
  query GetIdeasBoard($organizationId: ID!) {
    ideaGroups(input: { organizationId: $organizationId }) {
      id
      name
      isLocked
    }
    ideasV2(input: { organizationId: $organizationId }, first: 1000) {
      edges {
        node {
          id
          ...IdeaCard_Idea
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`)

type ItemsMap = Record<UniqueIdentifier, UniqueIdentifier[]>
type BoardData = {
  data: {
    ideas: Idea[]
    groups: { id: string; name: string; isLocked: boolean }[]
    itemsMap: ItemsMap
  }
  loading: boolean
  error: Error | undefined
}

export function useBoardData(): BoardData {
  const organizationId: string = useAppSelector(selectCurrentOrganizationId)
  const [selectedTagIds] = useQueryParam<string[]>('tagIds')

  const { data, loading, error } = useQuery(GetIdeasBoard, {
    variables: { organizationId },
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  const { ideas, itemsMap } = useMemo(() => {
    if (!loading && !error && data) {
      return buildItemsObject(data, selectedTagIds ?? [])
    }

    return { ideas: [], itemsMap: {} }
  }, [data, loading, error, selectedTagIds])

  return {
    data: {
      itemsMap,
      ideas,
      groups: data?.ideaGroups ?? [],
    },
    loading,
    error,
  }
}
