import React from 'react'

import * as StyledInput from '~publish/legacy/reminders/components/new-reminders/components/forms/field/styles'
import * as StyledToggle from '~publish/legacy/reminders/components/new-reminders/components/forms/toggle/styles'

export function Toggle({
  labelText,
  id,
  name,
  onChange,
  isOn,
  disabled = false,
}: {
  labelText: string
  id: string
  name: string
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.MouseEvent<HTMLSpanElement, MouseEvent>,
  ) => void
  isOn: boolean
  disabled: boolean
}): React.JSX.Element {
  return (
    <StyledInput.Wrapper>
      <StyledInput.LabelWrapper>
        <StyledInput.Label htmlFor={id}>{labelText}</StyledInput.Label>
      </StyledInput.LabelWrapper>
      <StyledToggle.ToggleWrapper>
        <StyledToggle.Input
          checked={isOn}
          name={name}
          onChange={onChange}
          id={id}
          type="checkbox"
          role="switch"
          aria-checked={isOn}
          aria-readonly={disabled}
          disabled={disabled}
        />
        <StyledToggle.Span aria-hidden="true" onClick={onChange} />
      </StyledToggle.ToggleWrapper>
    </StyledInput.Wrapper>
  )
}

export default Toggle
