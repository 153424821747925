import { findNode } from '@udecode/plate'
import type { BufferEditor } from '~publish/legacy/editor/BufferEditor/types.plate'
import { isSelectionAtEndOfCurrentNode } from '~publish/legacy/editor/queries/isSelectionAtEndOfNode'
import { FacebookMentionInputElement } from '../nodes'

export const shouldExitOnWhitespace = (editor: BufferEditor): boolean => {
  const mentionNodeEntry = findNode<FacebookMentionInputElement>(editor, {
    match: (n) => FacebookMentionInputElement.is(n),
  })

  if (!mentionNodeEntry) return false
  const { autocompleted, valid } = mentionNodeEntry[0]
  const selectionAtEnd = isSelectionAtEndOfCurrentNode(editor, mentionNodeEntry)
  return autocompleted && valid && selectionAtEnd
}
