import React from 'react'
import * as Styled from './styles'
import { PostFields } from '@buffer-mono/reminders-config'
import LinkIcon from '@bufferapp/ui/Icon/Icons/Link'
import MusicIcon from '@bufferapp/ui/Icon/Icons/Music'
import IgTextIcon from '@bufferapp/ui/Icon/Icons/IgText'
import HashtagIcon from '@bufferapp/ui/Icon/Icons/Hashtag'
import IgProductTagIcon from '@bufferapp/ui/Icon/Icons/IgProductTag'
import { CheckIcon } from '@buffer-mono/popcorn'
import AddIcon from '@bufferapp/ui/Icon/Icons/Add'

function getIcon(icon: PostFields): JSX.Element {
  switch (icon) {
    case PostFields.MUSIC:
      return <MusicIcon />
    case PostFields.LINK:
      return <LinkIcon />
    case PostFields.OTHER:
      return <AddIcon />
    case PostFields.TEXT:
      return <IgTextIcon />
    case PostFields.TOPICS:
      return <HashtagIcon />
    case PostFields.PRODUCTS:
      return <IgProductTagIcon />
  }

  return <MusicIcon />
}

export function StickerSelect({
  name,
  text,
  onChange,
  checked,
  icon,
}: {
  name: string
  text: string
  onChange: (e: { target: HTMLInputElement }) => void
  checked: boolean
  icon?: PostFields
}): JSX.Element {
  return (
    <Styled.Label htmlFor={name}>
      {icon && (checked ? <CheckIcon /> : getIcon(icon))}
      {text}
      <Styled.Input
        type="checkbox"
        name={name}
        id={name}
        onChange={onChange}
        checked={checked}
      ></Styled.Input>
    </Styled.Label>
  )
}
