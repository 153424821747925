import { createSlice } from '@reduxjs/toolkit'
import { env } from '~publish/env'
import type { RootState } from '../index'

export const environmentSlice = createSlice({
  name: 'environment',
  initialState: env.VITE_NODE_ENV || 'development',
  reducers: {},
})

export const getEnvironment = (state: RootState): string =>
  state[environmentSlice.name]
