import BaseValidator from '~publish/legacy/composer/composer/lib/validation/BaseValidator'
import { PostTypeCarousel } from '~publish/legacy/post/constants'
import { VALIDATION_CODE } from '~publish/legacy/validation/constants'
import ValidationFail from '~publish/legacy/validation/ValidationFail'
import ValidationResults from '~publish/legacy/validation/ValidationResults'
import { Document } from '../../entities/Document'
import { DraftMethods } from '../../entities/Draft'

const MAX_DOCUMENT_TITLE_LENGTH = 400
const MAX_DOCUMENT_PAGES = 300

export default class LinkedInValidator extends BaseValidator {
  validate(): ValidationResults {
    const result = super.validate()

    if (result.isInvalid()) {
      return result
    }

    if (this.draft.updateType === PostTypeCarousel) {
      const document = DraftMethods.getDocumentAttachment(this.draft)
      if (!Document.isDocument(document)) {
        return new ValidationResults([
          this.getValidationErrorForInvalidDocument(),
        ])
      }

      const documentTitle = document?.title?.trim()
      if (!documentTitle) {
        return new ValidationResults([
          this.getValidationErrorForNonPresentTitle(),
        ])
      }

      if (documentTitle.length > MAX_DOCUMENT_TITLE_LENGTH) {
        return new ValidationResults([this.getValidationErrorForTitleTooLong()])
      }

      if (document?.pageCount && document.pageCount > MAX_DOCUMENT_PAGES) {
        return new ValidationResults([this.getValidationErrorForTooManyPages()])
      }
    }

    return new ValidationResults([])
  }

  private getValidationErrorForInvalidDocument(): ValidationFail {
    return new ValidationFail(
      'Document is missing',
      VALIDATION_CODE.INVALID_VALUE,
    )
  }

  private getValidationErrorForNonPresentTitle(): ValidationFail {
    return new ValidationFail(
      'Please add a document title',
      VALIDATION_CODE.MISSING_VALUE,
    )
  }

  private getValidationErrorForTitleTooLong(): ValidationFail {
    return new ValidationFail(
      `Sorry! The title cannot be longer than ${MAX_DOCUMENT_TITLE_LENGTH} characters`,
      VALIDATION_CODE.INVALID_VALUE,
    )
  }

  private getValidationErrorForTooManyPages(): ValidationFail {
    return new ValidationFail(
      `Sorry! The document cannot have more than ${MAX_DOCUMENT_PAGES} pages`,
      VALIDATION_CODE.INVALID_VALUE,
    )
  }
}
