const isValidHttpUrl = (url: string): boolean => {
  let urlString = url

  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    urlString = `http://${url}`
  }

  try {
    // eslint-disable-next-line no-new
    new URL(urlString)
  } catch (_) {
    return false
  }

  if (!urlString.match(/.*\..+/)) {
    return false
  }

  if (urlString.match(/\s/)) {
    return false
  }

  return urlString.startsWith('http://') || urlString.startsWith('https://')
}

export { isValidHttpUrl }
