import styled, {
  type DefaultTheme,
  type FlattenInterpolation,
  type ThemeProps,
} from 'styled-components'
import { white } from '@bufferapp/ui/style/colors'
import { Button } from '@bufferapp/ui'
import {
  TextWithSkeleton,
  skeletonStyles,
} from '~publish/legacy/shared-components'

export const Container = styled.header<{ $tagsLayout: boolean }>`
  display: flex;
  align-items: baseline;
  padding: ${({ $tagsLayout }): string =>
    $tagsLayout ? '14px 40px 30px' : '14px 0px 30px'};
  background: ${white};
  flex-direction: column;
`

export const Name = styled(TextWithSkeleton)`
  flex: 1;
  margin: 0;
`

type ColorProps = {
  color: string
  displaySkeleton: boolean
}

export const Color = styled.div`
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: ${(props: ColorProps): string => props.color};
  margin-right: 10px;
  ${(
    props: ColorProps,
  ): false | FlattenInterpolation<ThemeProps<DefaultTheme>> =>
    props.displaySkeleton && skeletonStyles}
`

export const ButtonWrapper = styled.div`
  display: flex;
  margin-left: auto;
`

export const Title = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: center;
  flex-direction: row;
`
export const CampaignDetailsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  align-items: center;
`

export const CampaignDetails = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`

export const Breadcrumb = styled.nav`
  width: 100%;
  ol {
    list-style: none;
    padding: 0;
  }
`

export const BreadcrumbLink = styled(Button)`
  button {
    padding: 0;
  }
`
