import styled from 'styled-components'
import { grayDarker } from '@bufferapp/ui/style/colors'

export const Title = styled.h4`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${grayDarker};
  margin: 0 32px 24px;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 32px;
  margin-bottom: 16px;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  align-self: flex-end;
  margin: 0 32px;
`

export const CancelButtonWrapper = styled.div`
  margin: 0 10px;
`

export const TextArea = styled.textarea`
  resize: none;
  outline: none;
  font-size: 14px;
  line-height: 20px;
  font-family: 'Roboto';
  width: 100%;
  height: 100%;
  color: ${grayDarker};
  border: none;
`

export const LabelWrapper = styled.div`
  margin: 16px 0 8px;
`
