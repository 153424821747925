import React from 'react'
import { useSplitEnabled } from '@buffer-mono/features'
import { Sidebar, Flex, Heading, IdeasIcon } from '@buffer-mono/popcorn'
import { useLocation } from 'react-router-dom'
import styles from './CreatePage.module.css'

import { IdeasPage } from './IdeasPage'
import { FeedsPage } from './Feed/FeedsPage'
import { FeedGroupsNav } from './Feed/components/FeedGroupsNav/FeedGroupsNav'
import { SidebarLink } from './components/SidebarLink/SidebarLink'

export function CreatePage(): JSX.Element {
  const { isEnabled: isContentFeedEnabled } =
    useSplitEnabled('content_feed_alpha')
  const location = useLocation()

  if (isContentFeedEnabled) {
    const isFeed = location.pathname.startsWith('/create/feeds')
    const isIdeas = location.pathname.startsWith('/create') && !isFeed

    return (
      <Sidebar.Provider
        style={{
          border: '1px solid var(--color-border-subtle)',
          minWidth: '70vw',
          width: '100%',
          borderRadius: 4,
          overflow: 'hidden',
        }}
      >
        <Sidebar collapsible="icon" className={styles.sidebar}>
          <Sidebar.Header data-nohover>
            <Flex direction="row" gap="xs" align="center" justify="start">
              <Sidebar.Trigger />
              <Heading align="center" className={styles.createHeadingTitle}>
                Create
              </Heading>
            </Flex>
          </Sidebar.Header>
          <Sidebar.Content>
            <Sidebar.Group>
              <Sidebar.GroupContent>
                <Sidebar.List>
                  <SidebarLink
                    icon={<IdeasIcon />}
                    selected={isIdeas}
                    to="/create"
                  >
                    Ideas
                  </SidebarLink>
                </Sidebar.List>
              </Sidebar.GroupContent>
            </Sidebar.Group>
            <Sidebar.Group>
              <Sidebar.GroupLabel>Feeds</Sidebar.GroupLabel>
              <Sidebar.GroupContent>
                <Sidebar.List>
                  <FeedGroupsNav />
                </Sidebar.List>
              </Sidebar.GroupContent>
            </Sidebar.Group>
          </Sidebar.Content>
          <Sidebar.Rail />
        </Sidebar>
        <Flex
          direction="column"
          gap="md"
          style={{
            padding: 'var(--space-md)',
            minHeight: '500px',
            width: '100%',
          }}
        >
          {isFeed && <FeedsPage />}
          {!isFeed && <IdeasPage hidePageTitle />}
        </Flex>
      </Sidebar.Provider>
    )
  }

  return <IdeasPage />
}
