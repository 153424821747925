import type { Draft } from '~publish/legacy/composer/composer/entities/Draft'
import { MediaTypes } from '~publish/legacy/constants'

export const getMixedMediaError = (draft: Draft): string => {
  let message = 'We can only attach one type of file at the same time: either '
  message +=
    draft.service.maxAttachableImagesCount(draft) > 1 ? 'images' : 'an image'
  message += draft.service.canHaveMediaAttachmentType(MediaTypes.GIF)
    ? ', or a gif,'
    : ''
  message += ' or a video.<br/>'
  message += 'Could you try with only one of those?'

  return message
}
