import { createAsyncThunk } from '@reduxjs/toolkit'
import type { RootState } from '~publish/legacy/store'
import callRpc from '~publish/legacy/utils/call-rpc'

export const emptyQueue = createAsyncThunk<
  {
    success: boolean
    message: string
  },
  { profileId: string },
  { state: RootState }
>('generalSettings/emptyQueue', async ({ profileId }) => {
  const response = await callRpc('emptyQueue', {
    profileId,
  })

  if (!response.success) {
    throw new Error(response.message)
  }

  return response
})
