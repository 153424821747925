import Bugsnag, { type Client } from '@bugsnag/browser'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import React from 'react'
import { env } from '~publish/env'

const getReleaseStage = (): string => {
  if (window.location.href.match(/local\.buffer/)) {
    return 'development'
  }
  if (window.location.href.match(/dev\.buffer/)) {
    return 'staging'
  }
  return 'production'
}

const BUGSNAG_KEY = '6d235b284e8baf3c8d669a1991844969'
const appVersion =
  env.VITE_NODE_ENV === 'development' ? 'local' : env.VITE_COMMIT_HASH

const PublishBugsnagClient: Client = Bugsnag.createClient({
  apiKey: BUGSNAG_KEY,
  releaseStage: getReleaseStage(),
  enabledReleaseStages: [
    'production',
    'staging',
    // uncomment to test bugsnag reports in local development
    // 'development',
  ],
  appVersion,
  appType: 'frontend',
  plugins: env.VITE_NODE_ENV !== 'test' ? [new BugsnagPluginReact(React)] : [],
})

export default PublishBugsnagClient
