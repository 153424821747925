import BaseValidator from '~publish/legacy/composer/composer/lib/validation/BaseValidator'
import type ValidationResult from '~publish/legacy/validation/ValidationResult'
import twitterText from 'twitter-text'
import ValidationFail from '~publish/legacy/validation/ValidationFail'
import { STARTPAGE_INVALID_LINK } from '~publish/legacy/composer/composer/lib/validation/utils/validationErrorMessages'
import { VALIDATION_CODE } from '~publish/legacy/validation/constants'
import ValidationSuccess from '~publish/legacy/validation/ValidationSuccess'

export default class StartpageValidator extends BaseValidator {
  protected isValidLink(): ValidationResult {
    const hasLink =
      this.draft?.channelData?.startPage?.link &&
      this.draft.channelData.startPage.link !== ''
    const isValid =
      !hasLink ||
      twitterText.isValidUrl(
        this.draft?.channelData?.startPage?.link ?? '',
        true,
        false,
      )

    if (!isValid) {
      return new ValidationFail(
        STARTPAGE_INVALID_LINK,
        VALIDATION_CODE.INVALID_VALUE,
      )
    }
    return new ValidationSuccess()
  }

  protected validateForChannel(): ValidationResult[] {
    return [this.isValidLink()]
  }
}
