import React from 'react'
import PropTypes from 'prop-types'
import PostMetaBar from '../../PostMetaBar'
import getFormattedSourceUrl from '../sourceUrl'

const PinterestPostMetaBar = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'dragging' implicitly has an 'any'... Remove this comment to see the full error message
  dragging,
  // @ts-expect-error TS(7031) FIXME: Binding element 'sourceUrl' implicitly has an 'any... Remove this comment to see the full error message
  sourceUrl,
  // @ts-expect-error TS(7031) FIXME: Binding element 'boardName' implicitly has an 'any... Remove this comment to see the full error message
  boardName,
  // @ts-expect-error TS(7031) FIXME: Binding element 'boardAvatarUrl' implicitly has an... Remove this comment to see the full error message
  boardAvatarUrl,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isSent' implicitly has an 'any' t... Remove this comment to see the full error message
  isSent,
}) => {
  const leftContent = {
    title: 'Pinned to:',
    text: boardName,
    avatarUrl: boardAvatarUrl,
  }
  const rightContent = sourceUrl
    ? { title: 'Source:', text: getFormattedSourceUrl(sourceUrl) }
    : null

  return (
    <PostMetaBar
      dragging={dragging}
      leftContent={leftContent}
      rightContent={rightContent}
      isSent={isSent}
    />
  )
}

PinterestPostMetaBar.propTypes = {
  dragging: PropTypes.bool,
  sourceUrl: PropTypes.string,
  boardName: PropTypes.string,
  boardAvatarUrl: PropTypes.string,
  isSent: PropTypes.bool,
}

export default PinterestPostMetaBar
