import type { UniqueIdentifier } from '@dnd-kit/core'
// rename to ensure react does not think it's a react hook
import { getFragmentData } from '~publish/gql'

import {
  type GetIdeasBoardQuery,
  type IdeaCard_IdeaFragment as Idea,
  IdeaCard_IdeaFragmentDoc as IdeaFragmentDoc,
} from '~publish/gql/graphql'

export const UNASSIGNED_GROUP_NAME = 'Unassigned'
export const UNASSIGNED_GROUP_ID = 'unassigned'

export function compareIdeas(aIdea: Idea, bIdea: Idea): number {
  // Handle potential null positions
  const aPosition = aIdea.position ?? Number.MAX_SAFE_INTEGER
  const bPosition = bIdea.position ?? Number.MAX_SAFE_INTEGER

  // 1. Primary sort by position (ascending)
  if (aPosition < bPosition) {
    return -1
  }
  if (aPosition > bPosition) {
    return 1
  }

  // 2. Secondary sort: If positions are equal, sort by createdAt (newest first)
  return bIdea.createdAt - aIdea.createdAt
}

export function buildItemsObject(
  data: GetIdeasBoardQuery,
  selectedTagIds: string[],
): {
  ideas: Idea[]
  itemsMap: Record<UniqueIdentifier, UniqueIdentifier[]>
} {
  const ideas = data.ideasV2.edges?.map((edge) =>
    getFragmentData(IdeaFragmentDoc, edge?.node),
  )

  // Filter displayed ideas based on selected tags, or show all if no tags selected
  const displayedIdeas = ideas
    .sort(compareIdeas)
    .filter(
      (idea) =>
        selectedTagIds.length === 0 ||
        idea.content.tags?.some((tag) => selectedTagIds.includes(tag.id)),
    )

  // Construct items with ideas belonging to specific groups
  const itemsMap = data.ideaGroups.reduce<
    Record<UniqueIdentifier, UniqueIdentifier[]>
  >(
    (acc, group) => {
      acc[group.id] = displayedIdeas
        .filter((idea) => idea.groupId === group.id)
        .map((idea) => idea.id)
      return acc
    },
    { [UNASSIGNED_GROUP_ID]: [] },
  )

  // Populate items with unassigned ideas
  itemsMap[UNASSIGNED_GROUP_ID] = displayedIdeas
    .filter((idea) => !idea.groupId)
    .map((idea) => idea.id)

  return { ideas, itemsMap }
}

export function fromNullable<T>(value: T | null | undefined): T | undefined {
  return value ?? undefined
}
