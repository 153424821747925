import { type ApolloError, useQuery } from '@apollo/client'

import {
  useFilterByChannelFragment,
  type FilterByChannelFragment,
} from '~publish/components/FilterByChannel'
import {
  useFilterByTagFragment,
  type FilterByTagFragment,
} from '~publish/components/FilterByTag'
import { sanitizeNullableArray } from '~publish/helpers/typeGuards'
import { useOrganizationId } from '~publish/legacy/accountContext'
import {
  useMultiSelectFilter,
  type MultiSelectFilterState,
} from '~publish/pages/Calendar/hooks/useMultiSelectFilter'

import { graphql } from '~publish/gql'
import type { GetAllChannelsInfoForCalendarQuery } from '~publish/gql/graphql'
import { useMemo } from 'react'
import { getMatch, newCalendarSingleChannel } from '~publish/legacy/routes'
import { useParams } from 'react-router-dom'

export const GetAllChannelsInfoForCalendar = graphql(/* GraphQL */ `
  query GetAllChannelsInfoForCalendar($organizationId: OrganizationId!) {
    # TODO: channels should be a top-level query
    account {
      currentOrganization {
        channels(product: publish) {
          id
          accessLevel
          isQueuePaused
          ...FilterByChannel_Channel
        }
      }
    }

    tags(input: { organizationId: $organizationId }) {
      ...FilterByTag_Tag
    }
  }
`)

type Channel = NonNullable<
  NonNullable<
    NonNullable<
      GetAllChannelsInfoForCalendarQuery['account']
    >['currentOrganization']
  >['channels'][number]
>

export type CalendarFilters = {
  error: ApolloError | undefined
  tags: GetAllChannelsInfoForCalendarQuery['tags']
  tagsFilter: MultiSelectFilterState<FilterByTagFragment>
  channels: Channel[]
  channelsFilter: MultiSelectFilterState<FilterByChannelFragment>
  isSingleChannel: boolean
}

export const useCalendarFilters = (): CalendarFilters => {
  const channelId = useParams<{ id?: string }>().id
  const singleChannelMatch = getMatch({
    pathname: window.location.pathname,
    route: newCalendarSingleChannel.route,
  })
  const isSingleChannel = !!singleChannelMatch
  const organizationId = useOrganizationId() ?? ''
  const { data, error } = useQuery(GetAllChannelsInfoForCalendar, {
    variables: { organizationId },
  })
  const channels = sanitizeNullableArray(
    data?.account?.currentOrganization?.channels,
  )
  const channelFilterFragments = useFilterByChannelFragment(channels)
  const channelsFilter = useMultiSelectFilter(
    'channels',
    channelFilterFragments,
  )
  const mergedChannelsFilter = useMemo(() => {
    if (!isSingleChannel || channelId === undefined) {
      return channelsFilter
    }
    return {
      ...channelsFilter,
      sanitized: [channelId],
      queryParams: [channelId],
      selected: channelsFilter.selected.filter(
        (channel) => channel.id === channelId,
      ),
    }
  }, [channelsFilter, isSingleChannel, channelId])

  const tags = sanitizeNullableArray(data?.tags)
  const tagsFilterFragments = useFilterByTagFragment(tags)
  const tagsFilter = useMultiSelectFilter('tags', tagsFilterFragments)

  return useMemo(
    () => ({
      error,
      tags,
      tagsFilter,
      channels,
      channelsFilter: mergedChannelsFilter,
      isSingleChannel,
    }),
    [tags, tagsFilter, channels, mergedChannelsFilter, error, isSingleChannel],
  )
}
