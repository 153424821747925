import AppDispatcher from '../dispatcher'
import { ActionTypes } from '../state/ActionTypes'
import AppStore from '../stores/AppStore'
import ComposerStore from '../stores/ComposerStore'
import Scraper from '../utils/Scraper'
import type { SelectedTag } from '~publish/legacy/campaign/types'
import type { Document } from '../entities/Document'

const ComposerActionCreators = {
  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  enable: (id, markAppAsLoadedWhenDone = false): void => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_ENABLE,
      id,
      markAppAsLoadedWhenDone,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  disable: (id): void => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_DISABLE,
      id,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  expand: (id): void => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_EXPAND,
      id,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  collapse: (id): void => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_COLLAPSE,
      id,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  updateDraftHasSavingError: (id, hasSavingError): void => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.UPDATE_DRAFT_HAS_SAVING_ERROR,
      id,
      hasSavingError,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  updateDraftIsSaved: (id): void => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.UPDATE_DRAFT_IS_SAVED,
      id,
    })
  },

  setIsTwitterPremium: (id: string, isTwitterPremium: boolean): void => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_SET_IS_TWITTER_PREMIUM,
      id,
      isTwitterPremium,
    })
  },

  /**
   * Note: we're using a synchronous "dispatch" here, despite it not being
   * idiomatic, to prevent race conditions when updating the editor state:
   * if the state is updated in the editor component and takes some time to
   * reach the store because of an async dispatch, then any change made to
   * the editor state within the store during that time period will be lost.
   * This is the reason why this should be done synchronously.
   */
  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  updateDraftEditorState: (id, editorState) => {
    ComposerStore._syncDispatch({
      action: {
        actionType: ActionTypes.COMPOSER_UPDATE_DRAFT_EDITOR_STATE,
        id,
        editorState,
      },
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  updateDraftSourceLink: (id, url) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_UPDATE_DRAFT_SOURCE_LINK,
      id,
      url,
    })
  },

  updateDraftSourceLinkData: (() => {
    // @ts-expect-error TS(7034) FIXME: Variable 'currentCanonicalUrl' implicitly has type... Remove this comment to see the full error message
    let currentCanonicalUrl

    // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
    return (id, url) => {
      const { environment } = AppStore.getMetaData()
      const canonicalUrl = ComposerStore.getCanonicalUrl(url)

      currentCanonicalUrl = canonicalUrl

      // @ts-expect-error TS(7006) FIXME: Parameter 'scrapedData' implicitly has an 'any' ty... Remove this comment to see the full error message
      Scraper.scrape(canonicalUrl, environment).then((scrapedData) => {
        const doesScrapedDataStillMatchSourceUrl =
          // @ts-expect-error TS(7005) FIXME: Variable 'currentCanonicalUrl' implicitly has an '... Remove this comment to see the full error message
          currentCanonicalUrl === canonicalUrl
        if (!doesScrapedDataStillMatchSourceUrl) return

        const linkData = { ...scrapedData, url }
        AppDispatcher.handleViewAction({
          actionType: ActionTypes.COMPOSER_UPDATE_DRAFT_SOURCE_LINK_DATA,
          id,
          linkData,
        })
      })
    }
  })(),

  draftUpdated: (): void => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_DRAFT_UPDATED,
    })
  },

  updateDraftCommentCharacterCount: (
    // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
    id,
    { didEditorStateChange = true } = {},
  ): void => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_UPDATE_DRAFT_COMMENT_CHARACTER_COUNT,
      id,
      didEditorStateChange,
    })
  },

  updateDraftTitleCharacterCount: (
    // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
    id,
    { didEditorStateChange = true } = {},
  ): void => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_UPDATE_DRAFT_TITLE_CHARACTER_COUNT,
      id,
      didEditorStateChange,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  updateDraftUpdateType: (id, updateType): void => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_UPDATE_DRAFT_UPDATE_TYPE,
      id,
      updateType,
    })
  },

  updateDraftAddPostToThread: (id: string, offset?: number): void => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_UPDATE_DRAFT_ADD_POST_TO_THREAD,
      id,
      offset,
    })
  },

  updatePostText: (id: string, text: string): void => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_UPDATE_POST_TEXT,
      id,
      text,
    })
  },

  setActiveThreadId: (threadId: number): void => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_SET_ACTIVE_THREAD_ID,
      threadId,
    })
  },

  switchActiveThreadEditor: (id: string, threadId: number): void => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_SWITCH_ACTIVE_THREAD_EDITOR,
      id,
      threadId,
    })
  },

  deleteThreadedDraft: (
    id: string,
    threadId?: number,
    mergeThreads?: boolean,
  ): void => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_DELETE_THREADED_DRAFT,
      id,
      threadId,
      mergeThreads,
    })
  },

  updateDraftMediaOrder: (
    id: string,
    dragIndex: number,
    hoverIndex: number,
  ): void => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_UPDATE_DRAFT_MEDIA_ORDER,
      id,
      dragIndex,
      hoverIndex,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'googleBusinessData' implicitly has an '... Remove this comment to see the full error message
  updateDraftGoogleBusinessData: (googleBusinessData): void => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_UPDATE_DRAFT_GOOGLE_BUSINESS_DATA,
      googleBusinessData,
    })
  },

  updateDraftMastodonData: (mastodonData: { spoilerText?: string }): void => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_UPDATE_DRAFT_MASTODON_DATA,
      mastodonData,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'startPageData' implicitly has an 'any' ... Remove this comment to see the full error message
  updateDraftStartPageData: (startPageData) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_UPDATE_DRAFT_START_PAGE_DATA,
      startPageData,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'instagramData' implicitly has an 'any' ... Remove this comment to see the full error message
  updateDraftInstagramData: (instagramData) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_UPDATE_DRAFT_INSTAGRAM_DATA,
      instagramData,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'linkedinData' implicitly has an 'any' ... Remove this comment to see the full error message
  updateDraftLinkedinData: (linkedinData): void => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_UPDATE_DRAFT_LINKEDIN_DATA,
      linkedinData,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'isReminder' implicitly has an 'any' typ... Remove this comment to see the full error message
  updateDraftsIsReminder: (isReminder) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_UPDATE_DRAFTS_IS_REMINDER,
      isReminder,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'draftId' implicitly has an 'any' type.
  updateDraftIsReminder: (draftId, isReminder) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_UPDATE_DRAFT_IS_REMINDER,
      draftId,
      isReminder,
    })
  },

  updateDraftSelectedStickers: (
    id: string,
    selectedStickers: string[],
  ): void => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_UPDATE_DRAFT_SELECTED_STICKERS,
      id,
      selectedStickers,
    })
  },

  updateDraftInitialStickers: (): void => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_UPDATE_DRAFTS_INITIAL_STICKERS,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'youtubeData' implicitly has an 'any' ty... Remove this comment to see the full error message
  updateDraftYoutubeData: (youtubeData) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_UPDATE_DRAFT_YOUTUBE_DATA,
      youtubeData,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'tiktokData' implicitly has an 'any' ty... Remove this comment to see the full error message
  updateDraftTiktokData: (tiktokData) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_UPDATE_DRAFT_TIKTOK_DATA,
      tiktokData,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  updateToggleSidebarVisibility: (id, composerSidebarVisible) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_UPDATE_TOGGLE_SIDEBAR,
      id,
      composerSidebarVisible,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  updateSidebarOnTop: (id, sidebarOnTop): void => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_UPDATE_SIDEPANEL_ONTOP,
      id,
      sidebarOnTop,
    })
  },

  updateShouldShortenLinks: (id: string, shouldShortenLinks: boolean): void => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_UPDATE_SHOULD_SHORTEN_LINKS,
      id,
      shouldShortenLinks,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  updateTogglePostPreviewVisibility: (id, composerPostPreviewVisible) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_UPDATE_TOGGLE_POST_PREVIEW,
      id,
      composerPostPreviewVisible,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  updateConnectChannelPopoverVisible: (id, connectChannelPopoverVisible) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.UPDATE_CONNECT_CHANNEL_POPOVER_VISIBLE,
      id,
      connectChannelPopoverVisible,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  parseDraftTextLinks: (id) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_PARSE_DRAFT_TEXT_LINKS,
      id,
    })
  },

  parseMastodonText: () => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_PARSE_MASTODON_TEXT,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  updateDraftLink: (id, url) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_UPDATE_DRAFT_LINK_DATA,
      id,
      linkData: { url },
      meta: {
        isNewLinkAttachment: true,
        comesFromDirectUserAction: false,
      },
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  updateDraftLinkTitle: (id, title) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_UPDATE_DRAFT_LINK_DATA,
      id,
      linkData: { title },
      meta: {
        isNewLinkAttachment: false,
        comesFromDirectUserAction: true,
      },
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  updateDraftLinkDescription: (id, description) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_UPDATE_DRAFT_LINK_DATA,
      id,
      linkData: { description },
      meta: {
        isNewLinkAttachment: false,
        comesFromDirectUserAction: true,
      },
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  scrapeDraftLinkData: (id, url, originalThumbnailUrl = null) => {
    const { environment } = AppStore.getMetaData()
    const canonicalUrl = ComposerStore.getCanonicalUrl(url)

    Scraper.scrape(canonicalUrl, environment)
      // @ts-expect-error TS(7006) FIXME: Parameter 'scrapedData' implicitly has an 'any' ty... Remove this comment to see the full error message
      .then((scrapedData) => ({
        url,
        title: scrapedData.title || '',
        description: scrapedData.description || '',
        availableThumbnails: scrapedData.images || [],
        originalThumbnailUrl,
      }))
      // @ts-expect-error TS(7006) FIXME: Parameter 'linkData' implicitly has an 'any' type.
      .then((linkData) => {
        AppDispatcher.handleViewAction({
          actionType: ActionTypes.COMPOSER_UPDATE_DRAFT_LINK_DATA,
          id,
          linkData,
          meta: {
            isNewLinkAttachment: false,
            comesFromDirectUserAction: false,
          },
        })
      })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'draftId' implicitly has an 'any' type.
  selectNextLinkThumbnail: (draftId) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_UPDATE_NEXT_LINK_THUMBNAIL,
      draftId,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'draftId' implicitly has an 'any' type.
  selectPreviousLinkThumbnail: (draftId) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_UPDATE_PREVIOUS_LINK_THUMBNAIL,
      draftId,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  draftTextLinkUnshortened: (id, unshortenedLink) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_DRAFT_LINK_UNSHORTENED,
      id,
      unshortenedLink,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  draftTextLinkShortened: (id, unshortenedLink, shortLink) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_DRAFT_LINK_SHORTENED,
      id,
      link: unshortenedLink,
      shortLink,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  draftTextLinkReshortened: (id, unshortenedLink, shortLink) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_DRAFT_LINK_RESHORTENED,
      id,
      shortLink,
      link: unshortenedLink,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  updateDraftLinkAvailableImages: (id, url) => {
    const { environment } = AppStore.getMetaData()
    const canonicalUrl = ComposerStore.getCanonicalUrl(url)

    Scraper.scrape(canonicalUrl, environment).then(({ images = [] }) => {
      if (images.length === 0) return

      AppDispatcher.handleViewAction({
        actionType: ActionTypes.COMPOSER_ADD_DRAFT_AVAILABLE_IMAGES,
        id,
        images,
        sourceLink: url,
      })
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  removeDraftLinkAvailableImages: (id, sourceLink) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_REMOVE_DRAFT_AVAILABLE_IMAGES,
      id,
      sourceLink,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  toggleAttachment: (id, attachmentType) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_TOGGLE_DRAFT_ATTACHMENT,
      id,
      attachmentType,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  disableAttachment: (id, attachmentType) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_DISABLE_DRAFT_ATTACHMENT,
      id,
      attachmentType,
      didEditorStateChange: false,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  attachmentToggled: (id) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_DRAFT_ATTACHMENT_TOGGLED,
      id,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  addDraftImage: (id, image) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_ADD_DRAFT_IMAGE,
      id,
      image,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  draftImageAdded: (id, url) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_DRAFT_IMAGE_ADDED,
      id,
      url,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  addDraftVideo: (id, video) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_ADD_DRAFT_VIDEO,
      id,
      video,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  addDraftGif: (id, gif) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_ADD_DRAFT_GIF,
      id,
      gif,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  updateDraftImageUserTags: (id, userTags, mediaUrl) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_UPDATE_DRAFT_IMAGE_USER_TAGS,
      id,
      userTags,
      mediaUrl,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  updateDraftCampaignId: (id, campaignId) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_UPDATE_DRAFT_CAMPAIGN_ID,
      id,
      campaignId,
    })
  },

  updateDraftsCampaignId: (campaignId: string): void => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_UPDATE_DRAFTS_CAMPAIGN_ID,
      campaignId,
    })
  },

  updateDraftsTags: (tags: SelectedTag[]): void => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_UPDATE_DRAFTS_TAGS,
      tags,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  updateDraftTitle: (id, title) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_UPDATE_DRAFT_TITLE,
      id,
      title,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  updateDraftLinkThumbnail: (id, thumbnail) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_UPDATE_DRAFT_LINK_THUMBNAIL,
      id,
      thumbnail,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  updateDraftVideoThumbnail: (id, thumbnail) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_UPDATE_DRAFT_VIDEO_THUMBNAIL,
      id,
      thumbnail,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  updateDraftVideoTitle: (id, title) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_UPDATE_DRAFT_VIDEO_TITLE,
      id,
      title,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  draftVideoAdded: (id, video) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_DRAFT_VIDEO_ADDED,
      id,
      video,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  draftGifAdded: (id, url) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_DRAFT_GIF_ADDED,
      id,
      url,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  removeDraftImage: (id, image) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_REMOVE_DRAFT_IMAGE,
      id,
      image,
    })
  },

  setLinkedInCarouselTitle: (title = ''): void => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_SET_LINKEDIN_CAROUSEL_TITLE,
      title,
    })
  },

  addDraftDocument: (document: Document, draftId?: string): void => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_ADD_DRAFT_UPLOADED_DOCUMENT,
      draftId: draftId ?? null,
      document,
    })
  },

  removeDraftDocument: (id: string): void => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_REMOVE_DRAFT_DOCUMENT,
      id,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  removeDraftVideo: (id) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_REMOVE_DRAFT_VIDEO,
      id,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  removeDraftGif: (id) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_REMOVE_DRAFT_GIF,
      id,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  updateDraftTempImage: (id, url) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_UPDATE_DRAFT_TEMP_IMAGE,
      id,
      url,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  removeDraftTempImage: (id) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_REMOVE_DRAFT_TEMP_IMAGE,
      id,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'image' implicitly has an 'any' type.
  updateImageAltText: (image, altText) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_UPDATE_IMAGE_ALT_TEXT,
      image,
      altText,
    })
  },

  applyOmniUpdate: () => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_APPLY_OMNI_UPDATE,
    })
  },

  hideSuggestedMedia: () => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_HIDE_SUGGESTED_MEDIA,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'scheduledAt' implicitly has an 'any' ty... Remove this comment to see the full error message
  updateDraftsScheduledAt: (scheduledAt, isPinnedToSlot) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_UPDATE_DRAFTS_SCHEDULED_AT,
      scheduledAt,
      isPinnedToSlot,
    })
  },

  updateInstagramState: () => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_UPDATE_INSTAGRAM_STATE,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  updateDraftListPlaces: (id, places) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_UPDATE_DRAFT_LIST_PLACES,
      id,
      places,
    })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'text' implicitly has an 'any' type.
  insertHashtagInDraftCaption: (text) => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_INSERT_HASHTAG_TO_CAPTION,
      text,
    })
  },

  updateInstagramDraftsFeedback: () => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_UPDATE_INSTAGRAM_DRAFTS_FEEDBACK,
    })
  },

  updateAiAssist: () => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_UPDATE_AI_ASSISTED,
    })
  },
}

export default ComposerActionCreators
