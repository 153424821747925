import type { BufferEditor } from '~publish/legacy/editor/BufferEditor/types.plate'
import {
  getNodeString,
  insertText as insertTextTransform,
  withoutSavingHistory,
} from '@udecode/plate'
import { withoutLosingSelection } from '~publish/legacy/editor/helpers/withoutLosingSelection'
import { LinkedInAnnotationElement } from './nodes/LinkedInAnnotationElement'
import { insertLinkedInAnnotation } from './commands/insertLinkedInAnnotation'
import { getAllLinkedInAnnotations } from './commands/getAllLinkedInAnnotations'

export const withLinkedInAnnotations = (editor: BufferEditor): BufferEditor => {
  const { normalizeNode } = editor
  editor.insertLinkedInAnnotation = insertLinkedInAnnotation
  editor.getAllLinkedInAnnotations = getAllLinkedInAnnotations

  editor.normalizeNode = ([node, path]) => {
    if (
      LinkedInAnnotationElement.is(node) &&
      getNodeString(node) !== node.localizedName
    ) {
      withoutSavingHistory(editor, () => {
        withoutLosingSelection(editor, () => {
          insertTextTransform(editor, node.localizedName, {
            at: path,
            voids: true,
          })
        })
      })

      return
    }

    normalizeNode([node, path])
  }

  return editor
}
