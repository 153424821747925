import { createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from '@buffer-mono/popcorn'

import callRpc from '~publish/legacy/utils/call-rpc'
import type { AppDispatch, RootState } from '~publish/legacy/store'

import type { ChannelGroup } from '../ChannelGroups'

export const getGroups = createAsyncThunk<
  ChannelGroup[],
  void,
  { state: RootState; dispatch: AppDispatch }
>('channelGroups/getGroups', async (_, { rejectWithValue }) => {
  try {
    const response = await callRpc('getProfileGroups', {})

    if (!response.success) {
      throw new Error(response.message)
    }

    return response.profile_groups || []
  } catch (error) {
    const message = 'Error deleting channel group'
    toast.error(message)

    return rejectWithValue(message)
  }
})
