import keyWrapper from '~publish/helpers/keywrapper'

type CoachmarkState = {
  showRemindersConfirmation: boolean
  remindersConfirmationViewed: boolean
}

export const initialState: CoachmarkState = {
  showRemindersConfirmation: false,
  remindersConfirmationViewed: false,
}

export const actionTypes = keyWrapper('COACHMARKS', {
  SHOW_REMINDERS_CONFIRMATION: 0,
  HIDE_REMINDERS_CONFIRMATION: 0,
  SET_REMINDERS_CONFIRMATION_VIEWED: 0,
})

type CoachmarkAction = {
  type: (typeof actionTypes)[keyof typeof actionTypes]
}

export default (
  state: CoachmarkState = initialState,
  action: CoachmarkAction,
) => {
  switch (action.type) {
    case actionTypes.SHOW_REMINDERS_CONFIRMATION:
      return {
        ...state,
        showRemindersConfirmation: true,
      }
    case actionTypes.HIDE_REMINDERS_CONFIRMATION:
      return {
        ...state,
        showRemindersConfirmation: false,
      }
    case actionTypes.SET_REMINDERS_CONFIRMATION_VIEWED:
      return {
        ...state,
        remindersConfirmationViewed: true,
      }
    default:
      return state
  }
}

export const actions = {
  showRemindersConfirmation: () => ({
    type: actionTypes.SHOW_REMINDERS_CONFIRMATION,
  }),
  hideRemindersConfirmation: () => ({
    type: actionTypes.HIDE_REMINDERS_CONFIRMATION,
  }),
  setRemindersConfirmationViewed: () => ({
    type: actionTypes.SET_REMINDERS_CONFIRMATION_VIEWED,
  }),
}
