import { useCallback, useEffect, useRef, type DependencyList } from 'react'

export const useOnChange = <T>(
  value: T,
  onTransition: (last: T, current: T) => void,
  deps: DependencyList,
): void => {
  const previous = useRef(value)

  // deps provided by the caller
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cb = useCallback(onTransition, deps)

  useEffect(() => {
    if (previous.current !== value) {
      cb(previous.current, value)
      previous.current = value
    }
  }, [value, cb])
}
