import React from 'react'
import PropTypes from 'prop-types'

import { parseTwitterLinks } from '~publish/legacy/utils/channels/twitter/helpers'
import * as Styles from './styles'

// @ts-expect-error TS(7031) FIXME: Binding element 'data' implicitly has an 'any' typ... Remove this comment to see the full error message
const AttachmentRetweet = ({ data }) => {
  const { avatarUrl, userName, userDisplayName, text } = data
  return (
    <Styles.Wrapper data-testid="twitter-preview-retweet">
      <Styles.UserWrapper>
        <Styles.Avatar src={avatarUrl} alt={`${userName}'s avatar`} />
        <Styles.DisplayName>{userDisplayName}</Styles.DisplayName>
        <Styles.Username>@{userName}</Styles.Username>
      </Styles.UserWrapper>
      <Styles.LinkifiedText
        links={parseTwitterLinks(text)}
        size="mini"
        whitespace="pre-wrap"
        newTab
      >
        {text}
      </Styles.LinkifiedText>
    </Styles.Wrapper>
  )
}

AttachmentRetweet.propTypes = {
  data: PropTypes.shape({
    avatarUrl: PropTypes.string,
    userName: PropTypes.string,
    userDisplayName: PropTypes.string,
    text: PropTypes.string,
  }).isRequired,
}

export { AttachmentRetweet }
