/**
 * Simple slot picker. Works with 12/24h formats.
 *
 * This component is controlled through the slot prop, and uses the onChange
 * callback in props to let its parent know of any change.
 */

import dayjs, { type Dayjs } from 'dayjs'
import React, { useState } from 'react'
import type { Timeslot } from '../stores/types'
import { PickerSelect, SelectChevron, SelectWrapper } from './TimePicker'

type SlotPickerPropType = {
  shouldUse24hTime: boolean
  onChange: (timestamp: number) => void
  slots: Timeslot[]
  slot: Dayjs
  emptyByDefault?: boolean
  timezone?: string
  metaData?: {
    updateId?: string
    scheduledAt?: string | number
  }
}

const SlotPicker = ({
  metaData,
  shouldUse24hTime,
  onChange,
  slots,
  slot,
  timezone,
  emptyByDefault = false,
}: SlotPickerPropType): JSX.Element => {
  const [selectedSlot, setSelectedSlot] = useState<number | null>(null)

  const EMPTY_OPTION_VALUE = 'empty'
  const slotTimestamp = slot.unix()
  const currentTimestampSeconds = Math.floor(Date.now() / 1000)
  const isSlotTimeInFuture = slotTimestamp > currentTimestampSeconds
  const hasSlots = slots.length > 0

  // Returns true if we're editing an existing update and the timestamp passed
  // matches the original scheduled time of that update (i.e. the scheduled time
  // before any changes were made to it during this MC session)
  const isOriginalTimeOfEditedUpdate = (timestamp: number): boolean => {
    if (!metaData || !metaData.updateId || !metaData.scheduledAt) {
      return false
    }
    return timestamp === parseInt(metaData.scheduledAt.toString(), 10)
  }

  const slotsWithDisabledInfo = slots.map(({ timestamp, isSlotFree }) => {
    const isTimeInFuture = timestamp > currentTimestampSeconds
    const isDisabled =
      (!isSlotFree || !isTimeInFuture) &&
      !isOriginalTimeOfEditedUpdate(timestamp)

    return { timestamp, isSlotFree, isDisabled }
  })

  const isSlotTimestampAvailable =
    isOriginalTimeOfEditedUpdate(slotTimestamp) ||
    (isSlotTimeInFuture &&
      slots.some(
        ({ timestamp, isSlotFree }) =>
          timestamp === slotTimestamp && isSlotFree,
      ))
  const initialSelectedSlot =
    isSlotTimestampAvailable && !emptyByDefault
      ? slotTimestamp
      : EMPTY_OPTION_VALUE

  const onSlotChange = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    const selectedSlotTimestamp = parseInt(e.target.value, 10)
    setSelectedSlot(selectedSlotTimestamp)
    onChange(selectedSlotTimestamp)
  }

  const getHumanReadableTimeSlotLabel = (
    timestamp: number,
    isSlotFree: boolean,
  ): string => {
    let slotMoment = dayjs.unix(timestamp)
    if (timezone) slotMoment = slotMoment.tz(timezone)

    const humanReadableFormat = shouldUse24hTime ? 'H:mm' : 'h:mm A'
    const humanReadableTime = slotMoment.format(humanReadableFormat)

    const isTimeInFuture = slotMoment.isAfter(new Date())
    const slotPastLabel = isTimeInFuture ? '' : ' (past)'
    const slotOccupancyLabel = isSlotFree
      ? ''
      : isOriginalTimeOfEditedUpdate(timestamp)
      ? ' (occupied by this post)'
      : ' (occupied)'

    return `${humanReadableTime}${slotOccupancyLabel || slotPastLabel}`
  }

  return (
    <>
      {hasSlots && (
        <SelectWrapper>
          <PickerSelect
            value={selectedSlot || initialSelectedSlot}
            onChange={onSlotChange}
          >
            <option value={EMPTY_OPTION_VALUE} disabled>
              Select a time slot
            </option>
            {slotsWithDisabledInfo.map(
              ({ timestamp, isSlotFree, isDisabled }) => (
                <option value={timestamp} disabled={isDisabled} key={timestamp}>
                  {getHumanReadableTimeSlotLabel(timestamp, isSlotFree)}
                </option>
              ),
            )}
          </PickerSelect>
          <SelectChevron />
        </SelectWrapper>
      )}

      {!hasSlots && (
        <PickerSelect disabled value="">
          <option value="">No slots this day</option>
        </PickerSelect>
      )}
    </>
  )
}

export default SlotPicker
