import { createAsyncThunk } from '@reduxjs/toolkit'
import type { RootState } from '~publish/legacy/store'
import callRpc from '~publish/legacy/utils/call-rpc'

export const pauseQueue = createAsyncThunk<
  {
    message: string
    success: boolean
  },
  { profileId: string; paused: boolean },
  { state: RootState }
>('generalSettings/pauseQueue', async ({ profileId, paused }) => {
  const response = await callRpc('pauseQueue', {
    profileId,
    paused,
  })

  if (!response.success) {
    throw new Error(response.message)
  }
  return response
})
