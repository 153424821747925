import { createSlice } from '@reduxjs/toolkit'
import type { RootState } from '~publish/legacy/store'

import { queryLimits } from '~publish/legacy/ai/state/aiLimits/thunks'

const aiLimitsSlice = createSlice({
  name: 'aiLimits',
  initialState: {
    limitsLoaded: false,
    canGenerateContent: false,
    generateContentLimit: 0,
    generateContentTokensLeft: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(queryLimits.fulfilled, (state, action) => {
      const { currentOrganization } = action.payload
      const { billing, limits } = currentOrganization || {}

      state.limitsLoaded = true
      state.canGenerateContent = billing?.canGenerateContent
      state.generateContentLimit = limits?.generateContent
      state.generateContentTokensLeft = billing?.generateContentTokensLeft
    })
  },
})

const selectGenerateContentLimits = (state: RootState) => {
  const {
    canGenerateContent,
    generateContentLimit,
    generateContentTokensLeft,
    limitsLoaded,
  } = state[aiLimitsSlice.name]

  return {
    canGenerateContent,
    generateContentLimit,
    generateContentTokensLeft,
    limitsLoaded,
  }
}

export { aiLimitsSlice, selectGenerateContentLimits }
