/**
 * Recursively remove the __typename field from a GraphQL response object.
 */
export function stripGqlTypenames<T>(obj: T): T {
  if (Array.isArray(obj)) {
    return obj.map(stripGqlTypenames) as T
  }

  // Check Date objects before the regular object check
  if (obj instanceof Date) {
    return obj
  }

  if (obj !== null && typeof obj === 'object') {
    const newObj: { [key: string]: unknown } = {}

    for (const [key, value] of Object.entries(obj)) {
      if (key !== '__typename') {
        newObj[key] = stripGqlTypenames(value)
      }
    }

    return newObj as T
  }

  return obj
}
