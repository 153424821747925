// @ts-expect-error TS(7031) FIXME: Binding element 'profile' implicitly has an 'any' ... Remove this comment to see the full error message
const generateProfileTabs = ({ profile, organization }) => {
  const { hasGridFeature, hasStoriesFeature } = organization || {}
  const { service } = profile || {}
  const isInstagramChannel = service === 'instagram'
  const isTiktokChannel = service === 'tiktok'

  const tabsList = ['queue', 'sentPosts', 'settings', 'approvals', 'drafts']
  if (isInstagramChannel) {
    tabsList.push('pastReminders')
  }
  if (hasGridFeature && isInstagramChannel) {
    tabsList.push('grid')
  }
  if (hasStoriesFeature && isInstagramChannel) {
    tabsList.push('stories')
  }

  return tabsList
}

export default generateProfileTabs
