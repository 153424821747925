import {
  type ComboboxOnSelectItem,
  getEditorString,
  getPlugin,
  getPointAfter,
  getRange,
  insertText,
  moveSelection,
  setNodes,
  withoutNormalizing,
  comboboxActions,
} from '@udecode/plate'
import type {
  BufferEditor,
  BufferValue,
} from '~publish/legacy/editor/BufferEditor/types.plate'
import type {
  AutocompleteInputElement,
  AutocompleteInputElementInterface,
} from '../nodes'
import { getMentionTextRange } from '../queries'
import type { AutocompleteData, AutocompletePluginOptions } from '../types'

export interface CreateMentionNodeMeta {
  search: string
}

export const selectAndInsertSuggestion =
  <E extends AutocompleteInputElement>(
    elementInterface: AutocompleteInputElementInterface<E>,
    includeTrigger = false,
  ): ComboboxOnSelectItem<AutocompleteData> =>
  (plateEditor, item) => {
    const editor = plateEditor as unknown as BufferEditor

    if (!editor.selection) return
    const {
      options: { insertSpaceAfterMention = true },
      // ComboboxOnSelectItem complicates the editor type here
      // by hardcoding PlateEditor<V> without allowing you to pass a value,
      // so we're casting it to deal with that
    } = getPlugin<AutocompletePluginOptions<E>, BufferValue>(
      editor,
      elementInterface.type,
    )

    // Get mention text range without trigger to update
    // existing text to selected suggestion text
    const mentionRange = getMentionTextRange(editor, elementInterface, {
      includeTrigger,
    })

    withoutNormalizing(editor, () => {
      insertText(editor, item.text, {
        at: mentionRange || undefined,
      })

      setNodes(
        editor,
        {
          valid: true,
          autocompleted: true,
          data: item?.data,
        },
        { match: (n) => elementInterface.is(n) },
      )
    })

    comboboxActions.reset()

    // Move selection outside of mention element
    moveSelection(editor, { distance: 1, unit: 'offset' })

    // Make sure the next character isn't already a whitespace character
    // before inserting another
    const nextChar = getEditorString(
      editor,
      getRange(
        editor,
        editor.selection,
        getPointAfter(editor, editor.selection),
      ),
    )

    if (nextChar !== ' ' && insertSpaceAfterMention) {
      insertText(editor, ' ')
    }
    editor?.areMentionsSupported?.(editor)
  }
