import type { Draft } from '~publish/legacy/composer/composer/entities/Draft'

export function hasOnlyFacebookGroupsSelected(draft: Draft): boolean {
  const editorComposerData = draft.editorState.getEditorComposerData?.(
    draft.editorState,
  )
  const selectedProfiles = editorComposerData?.selectedProfilesForService

  let hasOnlyFacebookGroupsSelected = false

  if (selectedProfiles && selectedProfiles.length) {
    hasOnlyFacebookGroupsSelected = selectedProfiles.every(
      (profile) => profile.serviceType === 'group',
    )
  }
  return hasOnlyFacebookGroupsSelected
}
