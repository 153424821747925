/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions,@typescript-eslint/ban-ts-comment */
import React, { useState } from 'react'
import Loader from '@bufferapp/ui/Loader'
import { BlueprintModal } from '~publish/legacy/blueprints/components/Blueprint/BlueprintModal'
import { createDrafts } from '~publish/legacy/blueprints/utils/createDrafts'
import Tag from '@bufferapp/ui/Tag'
import { ChannelIcon } from '~publish/legacy/blueprints/components/Blueprint/ChannelIcon'
import type { Blueprint as BlueprintType } from '../../types'
import {
  BadgeWrapper,
  Button,
  Buttons,
  ChannelItem,
  ContentWrapper,
  Dropdown,
  DropdownItem,
  IconWrapper,
  Summary,
  TextWrapper,
  Title,
  Wrapper,
} from './styles'

// @ts-expect-error TS(7006) FIXME: Parameter 'badge' implicitly has an 'any' type.
const Badge = (badge) => {
  if (badge === 'new') {
    return <Tag color="green">New</Tag>
  }

  if (badge === 'coming_soon') {
    return <Tag color="gray">Coming Soon</Tag>
  }
}

const getChannels = (blueprint: BlueprintType) => {
  if (Array.isArray(blueprint.channels)) {
    // @ts-expect-error TS(2339) FIXME: Property '__userData' does not exist on type 'Wind... Remove this comment to see the full error message
    return window.__userData.data.account.currentOrganization.channels.filter(
      // @ts-expect-error TS(7006) FIXME: Parameter 'channel' implicitly has an 'any' type.
      (channel) => blueprint?.channels?.includes(channel.service),
    )
  }

  // @ts-expect-error TS(2339) FIXME: Property '__userData' does not exist on type 'Wind... Remove this comment to see the full error message
  return window.__userData.data.account.currentOrganization.channels
}

export const Blueprint: React.FC<{
  blueprint: BlueprintType
  setBlueprintNotice: (notice: string) => void
}> = ({ blueprint, setBlueprintNotice }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isCreating, setIsCreating] = useState(false)
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const channels = getChannels(blueprint)

  return (
    <>
      <Wrapper>
        <ContentWrapper>
          <IconWrapper>
            <img
              src={blueprint.icon}
              alt={blueprint.title}
              height={36}
              width={36}
            />
          </IconWrapper>
          <TextWrapper>
            <Title>
              {blueprint.title}
              <BadgeWrapper>
                {blueprint.channels &&
                  blueprint.channels.map((channel) => (
                    <span style={{ marginRight: '4px', display: 'flex' }}>
                      {ChannelIcon(channel)}
                    </span>
                  ))}
                {Badge(blueprint.badge ?? null)}
              </BadgeWrapper>
            </Title>
            <Summary>{blueprint.summary}</Summary>
          </TextWrapper>
        </ContentWrapper>
        <Buttons>
          {!isCreating && (
            <>
              <Button
                onClick={() => {
                  setIsModalOpen(true)
                }}
              >
                Preview
              </Button>
              {blueprint?.badge !== 'coming_soon' && (
                <>
                  <Button
                    onClick={() => {
                      setIsDropdownOpen(!isDropdownOpen)
                    }}
                  >
                    Create
                  </Button>
                  {isDropdownOpen && (
                    <Dropdown>
                      {/* @ts-expect-error TS(7006) FIXME: Parameter 'channel' implicitly has an 'any' type. */}
                      {channels.map((channel) => (
                        <DropdownItem
                          key={channel.id}
                          onClick={() => {
                            setIsCreating(true)
                            setIsDropdownOpen(false)
                            createDrafts({
                              channelId: channel.id,
                              channelName: channel.name,
                              blueprint,
                              draftCreationCallback: (response) => {
                                setIsCreating(false)
                                if (response.success) {
                                  setBlueprintNotice(
                                    `Drafts for your channel were created! Check them out <a href="/profile/${channel.id}/tab/drafts">here</a>`,
                                  )
                                } else {
                                  setBlueprintNotice(
                                    `There was some problem creating your drafts: ${response.message}`,
                                  )
                                }
                              },
                            })
                          }}
                        >
                          {ChannelIcon(channel.service)}{' '}
                          <ChannelItem>{channel.name}</ChannelItem>
                        </DropdownItem>
                      ))}
                      {channels.length === 0 && (
                        <DropdownItem>
                          You don&apos;t have any matching channels connected
                        </DropdownItem>
                      )}
                    </Dropdown>
                  )}
                </>
              )}
            </>
          )}
          {isCreating && <Loader />}
        </Buttons>
      </Wrapper>
      <BlueprintModal
        blueprint={blueprint}
        closeModal={() => {
          setIsModalOpen(false)
        }}
        channels={channels}
        isOpen={isModalOpen}
      />
    </>
  )
}
