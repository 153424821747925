import React from 'react'
import PropTypes from 'prop-types'
import Input from '@bufferapp/ui/Input'
import SearchIcon from '@bufferapp/ui/Icon/Icons/Search'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const Wrapper = styled.div`
  width: 100%;
`

// @ts-expect-error TS(7031) FIXME: Binding element 'onSearchProfileChange' implicitly... Remove this comment to see the full error message
const ProfileSearch = ({ onSearchProfileChange, searchText }) => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <Input
        // @ts-expect-error TS(2322) FIXME: Type '{ type: string; size: string; icon: Element;... Remove this comment to see the full error message
        type="input"
        size="tall"
        icon={<SearchIcon color="gray" />}
        // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
        onChange={(event) => {
          onSearchProfileChange(event.target.value)
        }}
        value={searchText || ''}
        name="profile-sidebar-search-profiles"
        placeholder={t('profile-sidebar.searchProfiles')}
      />
    </Wrapper>
  )
}

ProfileSearch.propTypes = {
  onSearchProfileChange: PropTypes.func.isRequired,
  searchText: PropTypes.string,
}

ProfileSearch.defaultProps = {
  searchText: '',
}

export default ProfileSearch
