import type { Image } from '~publish/legacy/composer/composer/entities/factories'

import { FormatLayout, isImagePortrait, isImageSquare } from '../Common/utils'

export const getImageLayout = (images: Image[]): 'row' | 'column' => {
  const total = images.length
  const firstImageIsPortrait = isImagePortrait(images[0])
  const firstImageIsSquare = isImageSquare(images[0])

  if (total <= 4 && (firstImageIsPortrait || firstImageIsSquare)) {
    return FormatLayout.Column
  }

  const secondImageIsPortrait = total >= 5 && isImagePortrait(images[1])
  if (firstImageIsPortrait && secondImageIsPortrait) {
    return FormatLayout.Column
  }

  return FormatLayout.Row
}

export const formatImagesInRows = (
  images: Image[],
): {
  firstRowCol: Image[]
  secondRowCol: Image[]
} => {
  const total = images.length
  let firstRowCol = [] as Image[]
  let secondRowCol = [] as Image[]

  const firstImageIsSquare = isImageSquare(images[0])
  if (total >= 5 || (firstImageIsSquare && total === 4)) {
    firstRowCol = images.slice(0, 2)
    secondRowCol = images.slice(2, Math.min(images.length, 5))

    return { firstRowCol, secondRowCol }
  }

  if (total > 0 && total <= 4) {
    firstRowCol = images.slice(0, 1)
    secondRowCol = images.slice(1)
  }

  return { firstRowCol, secondRowCol }
}
