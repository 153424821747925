import React from 'react'
import {
  CloseIcon,
  IconButton,
  Input,
  SearchIcon,
  VisuallyHidden,
} from '@buffer-mono/popcorn'

interface ChannelSearchInputProps {
  value: string
  onValueChange: (value: string) => void
}

export function ChannelSearchInput({
  value,
  onValueChange,
}: ChannelSearchInputProps): JSX.Element {
  return (
    <>
      <VisuallyHidden>
        <label htmlFor="search-channels">Search channels</label>
      </VisuallyHidden>
      <Input
        type="search"
        id="search-channels"
        prefix={<SearchIcon />}
        value={value}
        suffix={
          value !== '' ? (
            <IconButton
              label="Clear search"
              variant="tertiary"
              tooltip="Clear search"
              size="small"
              onClick={(): void => onValueChange('')}
            >
              <CloseIcon />
            </IconButton>
          ) : null
        }
        placeholder="Search channels..."
        onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
          onValueChange(e.target.value)
        }
      />
    </>
  )
}
