import type { MessageOptions } from '~publish/legacy/validation/types'

export const getMaxHashtagMessage = (maxHashtags: number): string =>
  `At most ${maxHashtags} hashtags can be used for caption and comment`

export const getMaxMentionsMessage = (maxMentions: number): string =>
  `At most ${maxMentions} mentions can be used for caption and comment`

export const getMaxCharactersForCommentMessage = (
  maxCharacters: number,
): string => `We can only fit ${maxCharacters} characters for comments`

export const getMaxCharactersForTitleMessage = (
  maxCharacters: number,
): string => `We can only fit ${maxCharacters} characters for the title`

export const getVideoTooShortMessage = (
  minDuration: number,
): MessageOptions => {
  const message = `Your video must be longer than ${minDuration} second${
    minDuration === 1 ? '' : 's'
  }`
  return {
    generic: message,
    specific: message,
  }
}

export const getVideoTooLongMessage = (maxDuration: number): MessageOptions => {
  const message = `Your video must be shorter than ${maxDuration} second${
    maxDuration === 1 ? '' : 's'
  }`
  return { generic: message, specific: message }
}

export const getVideoTooWideMessage = (
  maxWidth: number,
  currentWidth: number,
): MessageOptions => {
  const message = `Your video is ${currentWidth}px wide. The maximum supported width is ${maxWidth}px`
  return {
    generic: message,
    specific: message,
  }
}

export const getVideoFrameRateMessage = (
  minFrameRate: number,
  maxFrameRate: number,
  currentFrameRate: number,
): MessageOptions => {
  const message = `Your video has a frame rate of ${currentFrameRate} fps. The frame rate must be between ${minFrameRate} fps and ${maxFrameRate} fps`
  return {
    generic: message,
    specific: message,
  }
}

export const getVideoTooLargeMessage = (maxSize: number): MessageOptions => {
  const message = `Your video is too large. The maximum size is ${maxSize}MB`
  return {
    generic: message,
    specific: message,
  }
}

export const getVideoResolutionTooSmallMessage = (
  minResolution: number,
): string =>
  `The resolution of this video is too small. ${minResolution}p is the minimum resolution supported`

export const getMissingAttachmentMessage = (
  requiredAttachment: string,
): string => `Please include ${requiredAttachment}`

export const getMissingAttachmentAndTextMessage = (
  requiredAttachmentType: string,
): string => `Please include ${requiredAttachmentType} and some text`

export const REQUIRES_TEXT = 'Please include some text'
export const REQUIRES_TEXT_OR_ATTACHMENT =
  'Please include at least some text or an attachment'
export const INVALID_LINK = 'The link URL format is invalid'

// Instagram Validation Errors
export const getReelsVideoTooShortMessage = (
  minDuration: number,
): MessageOptions => {
  const message = `Your video must be longer than ${minDuration} second${
    minDuration === 1 ? 's' : ''
  }`
  return {
    generic: message,
    specific: message,
  }
}

export const getReelsVideoTooLongMessage = (
  maxDuration: number,
): MessageOptions => {
  const message = `Your video must be shorter than ${maxDuration} seconds`
  return {
    generic: message,
    specific: message,
  }
}

export const getReelsVideoTooSmallDimensionsMessage = (
  minWidth: number,
  minHeight: number,
): MessageOptions => {
  const message = `Your video must be minimum ${minWidth}x${minHeight}px in dimensions`
  return {
    generic: message,
    specific: message,
  }
}

export const getReelsVideoAspectRatioMessage = (
  minAspectRatio: string,
  maxAspectRatio: string,
): MessageOptions => {
  const message = `Your video must be between ${minAspectRatio} to ${maxAspectRatio} aspect ratio`
  return {
    generic: message,
    specific: message,
  }
}

export const getMaxImageCountExceededMessage = (
  maxAttachableImagesCount: number,
  updateType: string | null = 'post',
): string =>
  `Posts of type '${
    updateType ?? 'post'
  }' can only have ${maxAttachableImagesCount} ${
    maxAttachableImagesCount === 1 ? 'image' : 'images'
  } attached`

// Pinterest Validation Errors
export const PINTEREST_INVALID_DESTINATION_LINK =
  'Please include a valid destination link'

export const STARTPAGE_INVALID_LINK = 'Please include a valid link'
export const PINTEREST_SHORTENED_LINK =
  'Please include a destination link without link shortener'

// GBP Validation Errors
export const GBP_MISSING_OFFER_TITLE = 'Add the Offer Title'
export const GBP_MISSING_EVENT_TITLE = 'Add the Event Title'
export const GBP_MAX_CHAR_TITLE = 'Title can have a maximum of 58 characters'
export const GBP_MAX_CHAR_COUPON_CODE =
  'Coupon Code can have a maximum of 58 characters'
export const GBP_MISSING_START_END_DATE =
  'Start date and end date have to be set'
export const GBP_START_DATE_AFTER_END_DATE =
  'Whoops! The start date must be before the end date'
export const GBP_MISSING_START_END_TIME = 'Set a start and end time'
export const GBP_START_DATE_IN_PAST =
  'Whoops! The start date cannot be in the past'
export const GBP_END_DATE_IN_PAST = 'Whoops! The end date cannot be in the past'
export const GBP_INVALID_FORMAT_START_END_TIME =
  'Enter the time in this format: "01:00 pm" or "13:00"'
export const GBP_START_TIME_BEFORE_END_TIME =
  'Whoops! The start time must be before the end time'
export const GBP_INVALID_HOUR = 'Hours should be between 0 and 23'
export const GBP_INVALID_MINUTES = 'Minutes should be between 0 and 59'
export const GBP_MISSING_CTA_LINK = 'Please include a button link'
export const GBP_INVALID_FORMAT_CTA_LINK =
  'Enter the link in this format: https://example.com'
export const GBP_INVALID_FORMAT_REDEEM_LINK =
  'Enter the link in this format: https://example.com for the Offer Link field'
export const GBP_MAX_CHAR_TERMS_CONDITIONS =
  'T&C can contain maximum of 5000 characters'
export const GBP_PHONE_NUMBER_RESTRICTED =
  'Phone numbers are not allowed in posts'

export const YT_MAX_CHAR_TITLE = 'Title can have a maximum of 100 characters'
export const YT_MISSING_TITLE = 'Add the Video Title'
export const YT_FORBIDDEN_CHARACTERS =
  'Title and description cannot include < and > characters'

export const YT_MISSING_LICENSE = 'You have to select a license'
export const YT_MISSING_CATEGORY = 'You have to select a category'
export const YT_MISSING_PRIVACY_STATUS = 'You have to select a privacy status'

export const getYoutubeVideoAspectRatioMessage = (): MessageOptions => ({
  generic:
    'Video dimensions are invalid. Only square (1:1) and portrait (9:16) aspect ratios are accepted',
  specific:
    'Video dimensions are invalid. Only square (1:1) and portrait (9:16) aspect ratios are accepted',
})
