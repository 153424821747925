import React from 'react'

import { useAppSelector } from '~publish/legacy/store'
import { selectContentGenerationStatus } from '~publish/legacy/ai/state/slice'
import { useContentGeneration } from '~publish/legacy/ai/hooks/useContentGeneration'
import { Button } from '~publish/legacy/ai/components/AIAssistant/components/Button'
import { TONES } from '~publish/legacy/ai/components/AIAssistant/AIAssistant.constants'
import { AIAssistantContext } from '~publish/legacy/ai/components/AIAssistant/AIAssistant'

/**
 *
 */
function ToneButton({
  tone,
  disabled,
  testId,
}: {
  tone: string
  disabled?: boolean
  testId?: string
}): JSX.Element {
  const { channel } = React.useContext(AIAssistantContext)

  const { generateContent } = useContentGeneration()

  const { contentGenerationResults } = useAppSelector((state) =>
    selectContentGenerationStatus(state),
  )

  const toneConfig = TONES[tone as keyof typeof TONES]
  const { label, IconComponent } = toneConfig || {}

  const onClick = (event: React.MouseEvent): void => {
    const prompt = contentGenerationResults.length
      ? contentGenerationResults[0]
      : null

    if (!tone || !prompt) return

    event.preventDefault()

    generateContent({
      prompt,
      tone,
      service: channel,
      action: 'rephrase',
    })
  }

  return (
    <Button disabled={disabled} onClick={onClick} secondary testId={testId}>
      <IconComponent />
      {label}
    </Button>
  )
}

export { ToneButton }
