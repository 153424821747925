import React from 'react'
import PropTypes from 'prop-types'
import styled, { type FlattenSimpleInterpolation, css } from 'styled-components'
import { SERVICE_NAMES } from '~publish/legacy/constants'
import { networkIconMap } from '~publish/legacy/shared-components'

const Background = styled.div<{
  isActive: boolean
  color: string
  size: string
}>`
  height: 16px;
  background: ${(props): string => (props.isActive ? props.color : 'grey')};
  border-radius: 50%;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props): FlattenSimpleInterpolation =>
    props.size === 'small'
      ? css`
          height: 16px;
          width: 16px;
        `
      : css``}

  ${(props): FlattenSimpleInterpolation =>
    props.size === 'medium' || props.size === 'smedium'
      ? css`
          height: 24px;
          width: 24px;
        `
      : css``}
`

type NetworkIconProps = {
  type: string
  isActive?: boolean
  className?: string
  size?: 'small' | 'medium' | 'smedium'
  numSelectedProfiles?: number
}

const NetworkIcon = ({
  type,
  isActive = false,
  className,
  numSelectedProfiles,
  size = 'small',
}: NetworkIconProps): JSX.Element | null => {
  if (type === 'omni') {
    return null
  }

  const icon = networkIconMap.get(type)

  if (icon) {
    const { component: IconComponent, color } = icon
    return (
      <div className={className} data-profile-count={numSelectedProfiles}>
        <Background color={color} isActive={isActive} size={size}>
          <IconComponent color="white" size={size} />
        </Background>
      </div>
    )
  }
  return null
}

NetworkIcon.propTypes = {
  type: PropTypes.oneOf([...SERVICE_NAMES, 'omni']).isRequired,
  isActive: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'smedium', 'medium', 'large']),
  numSelectedProfiles: PropTypes.number,
}

NetworkIcon.defaultProps = {
  isActive: true,
  className: '',
  size: 'small',
  numSelectedProfiles: 0,
}

export default NetworkIcon
