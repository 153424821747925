import styled from 'styled-components'

import { borderRadius } from '@bufferapp/ui/style/borders'
import { blue, blueLightest } from '@bufferapp/ui/style/colors'

export const Wrapper = styled.span<{ $selected: boolean; $focused: boolean }>`
  color: ${blue};
  vertical-align: baseline;
  border-radius: ${borderRadius};
  box-shadow: ${({ $selected, $focused }) =>
    $selected && $focused ? `0 0 0 2px ${blueLightest}` : 'none'};
  position: relative;
`
