import type { Image } from '~publish/legacy/composer/composer/entities/factories'

export const formatImagesInRows = (
  images: Image[],
): {
  firstRowCol: Image[]
  secondRowCol: Image[]
} => {
  const total = images.length
  let firstRowCol = [] as Image[]
  let secondRowCol = [] as Image[]

  if (total >= 5) {
    firstRowCol = images.slice(0, 2)
    secondRowCol = images.slice(2, Math.min(images.length, 5))

    return { firstRowCol, secondRowCol }
  }

  if (total > 0) {
    firstRowCol = images.slice(0, 1)
    secondRowCol = images.slice(1)
  }

  return { firstRowCol, secondRowCol }
}
