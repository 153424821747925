import type { PayloadAction } from '@reduxjs/toolkit'
import keyWrapper from '~publish/helpers/keywrapper'
import { actionTypes as queueActionTypes } from '~publish/legacy/queue/actionTypes'
import { IdeaEvents as ideaActionTypes } from '~publish/pages/Create/types'

type WelcomeChecklistState = {
  shouldRefetch: boolean
}

export const actionTypes = keyWrapper('WELCOME_CHECKLIST', {
  SHOULD_REFETCH: 0,
  CLEAR_REFETCH: 0,
})

export const initialState: WelcomeChecklistState = {
  shouldRefetch: false,
}

const welcomeChecklistReducer = (
  state = initialState,
  action: PayloadAction,
): WelcomeChecklistState => {
  switch (action.type) {
    case actionTypes.CLEAR_REFETCH: {
      return { shouldRefetch: false }
    }
    case queueActionTypes.POST_SENT: {
      return { shouldRefetch: true }
    }
    case queueActionTypes.POST_CREATED: {
      return { shouldRefetch: true }
    }
    case `PUSHER_EVENT/${ideaActionTypes.IDEA_CREATED}`: {
      return { shouldRefetch: true }
    }
    default:
      return state
  }
}

const actions = {
  shouldRefetch: () => ({
    type: actionTypes.SHOULD_REFETCH,
  }),
  clearShouldRefetch: () => ({
    type: actionTypes.CLEAR_REFETCH,
  }),
}

export { welcomeChecklistReducer, actions }
