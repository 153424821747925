import React from 'react'
import PropTypes from 'prop-types'
import insertIntoTranslation from '../../helper'

// @ts-expect-error TS(7031) FIXME: Binding element 'translation' implicitly has an 'a... Remove this comment to see the full error message
const TranslationReplacer = ({ translation, replacementStrings }) => {
  const replacedNode = insertIntoTranslation(translation, replacementStrings)

  return typeof replacedNode === 'string'
    ? replacedNode
    : // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
      replacedNode.map((e, i) => (
        <React.Fragment key={String(i)}>{e}</React.Fragment>
      ))
}

TranslationReplacer.propTypes = {
  replacementStrings: PropTypes.arrayOf(
    PropTypes.shape({
      replaceString: PropTypes.string,
      replaceWith: PropTypes.node,
    }),
  ),
  translation: PropTypes.string.isRequired,
}

TranslationReplacer.defaultProps = {
  loggedIn: false,
}

export default TranslationReplacer
