import AppDispatcher from '../dispatcher'
import { ActionTypes } from '../state/ActionTypes'
import Shortener from '../utils/Shortener'

/**
 * Action dispatches for link shortening
 *
 * This action creator used to always return undefined, we are now returning a promise
 * with the shortUrl so that the new Slate link plugin can add the information to the
 * corresponding node in the editor state.
 *
 * @param {*} id
 * @param {*} url
 * @returns
 */
export const shortenDraftLink = (
  id: string,
  url: string,
  profile: { id: string },
): Promise<{ shortUrl: string } | undefined> => {
  if (!id || !url || !profile?.id) return Promise.resolve(undefined)

  // @ts-expect-error TS(7006) FIXME: Parameter 'shortLink' implicitly has an 'any' type... Remove this comment to see the full error message
  return Shortener.shorten(profile.id, url).then((shortLink) => {
    // Don't create action when no new shortlink generated (can happen when url is already
    // a short link, or when link shortening is disabled for that profile)
    // we return an empty object to let Slate's link plugin know that this new link was processed
    if (url === shortLink) return {}

    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_DRAFT_LINK_SHORTENED,
      id,
      link: url,
      shortLink,
    })

    return {
      url,
      shortUrl: shortLink,
    }
  })
}
