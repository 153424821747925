import type { ServiceDefinition } from './types'
import {
  ServiceBluesky,
  ServiceFacebook,
  ServiceGoogleBusiness,
  ServiceInstagram,
  ServiceLinkedIn,
  ServiceMastodon,
  ServiceOmni,
  ServicePinterest,
  ServiceStartPage,
  ServiceThreads,
  ServiceTiktok,
  ServiceTwitter,
  ServiceYoutube,
} from './ServiceDefinitions'

class ServiceList extends Array<ServiceDefinition> {
  get(serviceName: string): ServiceDefinition | undefined {
    return this.find((service) => service.name === serviceName)
  }
}

export const Services = new ServiceList(
  ServiceOmni,
  ServiceTwitter,
  ServiceFacebook,
  ServiceInstagram,
  ServiceLinkedIn,
  ServiceGoogleBusiness,
  ServicePinterest,
  ServiceTiktok,
  ServiceStartPage,
  ServiceMastodon,
  ServiceYoutube,
  ServiceThreads,
  ServiceBluesky,
)
