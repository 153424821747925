import { createPluginFactory } from '@udecode/plate'
import { Editor } from 'slate'
import { GenerateContentElement } from './nodes/GenerateContentElement'
import { GenerateContentElementUI } from './ui/GenerateContentElementUI'
import { withGeneratedContent } from './withGeneratedContent'
import { unwrapGeneratedContent } from './commands/unwrapGeneratedContent'

// @ts-expect-error TS(7006) FIXME: Parameter 'editor' implicitly has an 'any' type.
export const checkSelection = (editor) => () => {
  editor.highlighted = editor.selection
    ? Editor.string(editor, editor.selection)
    : null
}

// @ts-expect-error TS(7006) FIXME: Parameter 'editor' implicitly has an 'any' type.
export const processGeneratedContent = (editor) => () => {
  unwrapGeneratedContent({ editor })
}

// NOTE: This plugin will be removed once the current AI Assistant is
// removed in favor of AI Assistant 2.0
export const createGenerateContentPlugin = createPluginFactory({
  key: GenerateContentElement.type,
  isElement: true,
  component: GenerateContentElementUI,
  withOverrides: withGeneratedContent,
  handlers: {
    onKeyDown: processGeneratedContent,
    onMouseUp: processGeneratedContent,
    onChange: checkSelection,
    onFocus: checkSelection,
  },
})
