import React from 'react'
import styled from 'styled-components'
import { Text } from '@bufferapp/ui'
import { grayLight } from '@bufferapp/ui/style/colors'

const MetadataWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const ParagraphText = styled(Text)`
  margin: 0 0 0 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const IconCircle = styled.div`
  border-radius: 50%;
  background-color: ${grayLight};
  display: block;
  height: 32px;
  min-width: 32px;
  line-height: 40px;
  text-align: center;
`

const MetadataItem = ({
  icon,
  text,
}: {
  icon: JSX.Element
  text: string
}): JSX.Element => {
  return (
    <MetadataWrapper>
      <IconCircle>{icon}</IconCircle>
      <ParagraphText type="p">{text}</ParagraphText>
    </MetadataWrapper>
  )
}

export default MetadataItem
