import type { PlateRenderNodeProps, TRenderElementProps } from '@udecode/plate'
import React, { useCallback } from 'react'
import type {
  BufferEditor,
  BufferValue,
} from '~publish/legacy/editor/BufferEditor/types.plate'
import {
  EditorTooltip,
  TooltipButton,
} from '~publish/legacy/editor/components/EditorTooltip'
import { LinkElement } from '../nodes/LinkElement'
import { Wrapper } from './styles'

type LinkElementUIProps = PlateRenderNodeProps<BufferValue, BufferEditor> &
  TRenderElementProps<BufferValue, LinkElement>

export const LinkElementUI = (props: LinkElementUIProps) => {
  const { attributes, children, element } = props

  const href = LinkElement.getHref(element)
  const onOpenClick = useCallback(() => {
    window.open(href, '_blank')
  }, [href])

  return (
    <Wrapper
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...attributes}
    >
      <EditorTooltip trigger={<span>{children}</span>}>
        <TooltipButton onClick={onOpenClick}>Open Link</TooltipButton>
      </EditorTooltip>
    </Wrapper>
  )
}
