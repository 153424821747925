import { Notice } from '@buffer-mono/popcorn'
import React from 'react'

type AllChannelsPausedQueueNoticeProps = Omit<
  React.ComponentPropsWithoutRef<typeof Notice>,
  'variant' | 'children'
>

export const AllChannelsPausedQueueNotice = (
  props: AllChannelsPausedQueueNoticeProps,
): JSX.Element | null => {
  return (
    <Notice {...props} variant="warning">
      <Notice.Heading>Some of your channel queues are paused</Notice.Heading>
      <Notice.Text>
        Posts for these channels will only go out once the queue is resumed.
      </Notice.Text>
    </Notice>
  )
}
