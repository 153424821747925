import dayjs, { type Dayjs } from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'

import advanced from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import type { RpcUpdate } from '~publish/legacy/post/types'
import type { Day, Schedule } from '~publish/legacy/profile-sidebar/types'
import {
  type DailySlots,
  type QueueHeaderItem,
  type QueueItem,
  QueueItemType,
  type QueuePostItem,
  type QueueSlotItem,
  type SlotDay,
  type SlotItem,
} from '~publish/legacy/shared-components/QueueItems/types'
import { isNotNull } from '~publish/legacy/utils/isNotNull'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advanced)
dayjs.extend(customParseFormat)

export const noScheduledDate = 'No scheduled days or times'

/**
 * Return an object containing details about daily slots based on the
 * profile's schedules.
 *
 * @param {array} schedules
 */
export const getDailySlotsFromProfileSchedules = (
  schedules: Schedule[],
): DailySlots[] => {
  // todo: consider pausedSchedules

  const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']

  // Map day values from schedule to their integer identity
  const dayMap = days.reduce<Record<string, number>>((obj, day, index) => {
    obj[day] = index
    return obj
  }, {})

  // Create an empty array for each day of the week that we
  // will fill with the schedule data later
  const empty = days.reduce<Array<string[]>>((obj, day, index) => {
    obj[index] = []
    return obj
  }, [])

  // Simplify the schedule structure, filling in our `empty` array
  const combinedSchedules = schedules.reduce((combined, schedule) => {
    schedule.days.forEach((day) => {
      const dayIndex = dayMap[day]
      const combinedTimes = combined[dayIndex].concat(schedule.times)
      const uniqueTimes = [...new Set(combinedTimes)] // removes duplicates
      combined[dayIndex] = uniqueTimes
    })
    return combined
  }, empty)

  return combinedSchedules
}

interface GetDayStringReturn {
  dayOfWeek: string
  date: string
  text: string
}
/**
 * Matches `getDay()` logic in `buffer-web/blob/master/shared/models/update_model.php`.
 */
export const getDayString = (
  dateMoment: Dayjs,
  now: Dayjs,
): GetDayStringReturn => {
  const todayRange = [dayjs(now).startOf('day'), dayjs(now).endOf('day')]
  const tomorrowRange = [
    dayjs(now).clone().add(1, 'day').startOf('day'),
    dayjs(now).clone().add(1, 'day').endOf('day'),
  ]
  const isSameYear = dateMoment.format('YYYY') === now.format('YYYY')

  let dayOfWeek = ''
  let text = null
  if (dateMoment >= todayRange[0] && dateMoment < todayRange[1]) {
    dayOfWeek = 'Today'
    text = dayOfWeek
  } else if (dateMoment >= tomorrowRange[0] && dateMoment < tomorrowRange[1]) {
    dayOfWeek = 'Tomorrow'
    text = dayOfWeek
  } else {
    dayOfWeek = dateMoment.format('dddd')
  }

  return {
    dayOfWeek,
    date: dateMoment.format(`MMMM D${isSameYear ? '' : ' YYYY'}`),
    text: text || dateMoment.format(`dddd Do MMMM${isSameYear ? '' : ' YYYY'}`),
  }
}

interface GetDaysForUpcomingWeeksInput {
  profileTimezone: string
  weekStartsOnMonday: boolean
  numWeeks: number
}
/**
 * Returns a list of days to show in the queue based on the users settings.
 */
export const getDaysForUpcomingWeeks = ({
  profileTimezone,
  weekStartsOnMonday,
  numWeeks,
}: GetDaysForUpcomingWeeksInput): SlotDay[] => {
  const now = dayjs().tz(profileTimezone)

  const currentDay = now.day()
  let daysUntilEndOfWeek = 7 - currentDay
  if (weekStartsOnMonday) {
    daysUntilEndOfWeek += 1
  }
  const daysToShow = daysUntilEndOfWeek + numWeeks * 7
  const rangeOfDays = [...Array(daysToShow).keys()]

  const days = rangeOfDays.map((increment) => {
    const dateMoment = now.clone().add(increment, 'days').hour(12)
    const { text, date, dayOfWeek } = getDayString(dateMoment, now)
    const dayIndex = dateMoment.day()
    return { text, date, dayOfWeek, dayIndex, dayUnixTime: dateMoment.unix() }
  })

  return days
}

interface GetSlotsWithTimestampsForDayInput {
  profileTimezone: string
  hasTwentyFourHourTimeFormat: boolean
  dailySlots: DailySlots[]
  day: SlotDay
}

/**
 * Returns slots with unix timestamps and labels for the given day
 */
export const getSlotsWithTimestampsForDay = ({
  profileTimezone,
  hasTwentyFourHourTimeFormat,
  dailySlots,
  day: { text: dayText, dayIndex, dayUnixTime },
}: GetSlotsWithTimestampsForDayInput): SlotItem[] => {
  const now = dayjs().tz(profileTimezone)
  if (dayIndex === -1) {
    return []
  }
  const slotsForTheDay = dailySlots[dayIndex]
  const dayMoment = dayjs.tz(new Date(dayUnixTime * 1000), profileTimezone)

  return slotsForTheDay
    .map((slot) => {
      const slotMoment = dayjs.tz(
        `${dayMoment.format('DD/MM/YYYY')} ${slot}`,
        'DD/MM/YYYY h:mm',
        profileTimezone,
      )
      if (slotMoment.isBefore(now)) {
        return null
      }
      return {
        name: slot,
        label: slotMoment.format(
          hasTwentyFourHourTimeFormat ? 'HH:mm' : 'h:mm A',
        ),
        timestamp: slotMoment.unix(),
        dayText,
      }
    })
    .filter(isNotNull) // gets rid of `null` slots (i.e., in the past)
}

const getFutureTime = (inputTz: string): string => {
  const todayDate = new Date().setSeconds(0) // Seconds must be 0 for precise scheduling
  const isTimezoneSet = !!inputTz
  const today = isTimezoneSet ? dayjs.tz(todayDate, inputTz) : dayjs(todayDate)
  today.add(1, 'hours')

  return today.format('HH:mm')
}

interface GetSlotsWithTimestampsAndTimeForDayInput {
  profileTimezone: string
  hasTwentyFourHourTimeFormat: boolean
  day: SlotDay
}
/**
 * Returns slots with timestamps and labels for the given day
 * This method doesn't take into account times, only days of the week
 */
export const getSlotsWithTimestampsAndNoTimeForDay = ({
  profileTimezone,
  hasTwentyFourHourTimeFormat,
  day: { text: dayText, dayIndex, dayUnixTime },
}: GetSlotsWithTimestampsAndTimeForDayInput): SlotItem[] => {
  const now = dayjs().tz(profileTimezone)

  if (dayIndex === -1) {
    return []
  }

  const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']
  const slot = '10:00'
  const dayMoment = dayjs.tz(new Date(dayUnixTime * 1000), profileTimezone)
  let slotMoment = dayMoment.clone()
  const [hour, minute] = slot.split(':')
  slotMoment = slotMoment
    .set('hour', parseInt(hour, 10))
    .set('minute', parseInt(minute, 10))

  if (slotMoment.isBefore(now)) {
    const anHourFromNow = getFutureTime(profileTimezone)
    const [hourNow, minuteNow] = anHourFromNow.split(':')
    slotMoment.set('hour', parseInt(hourNow, 10))
    slotMoment.set('minute', parseInt(minuteNow, 10))
  }
  return [
    {
      name: days[dayIndex],
      label: slotMoment.format(
        hasTwentyFourHourTimeFormat ? 'HH:mm' : 'h:mm A',
      ),
      timestamp: slotMoment.unix(),
      dayText,
    },
  ]
}

/**
 * Convenience method for generating a header item for the `QueueItems` component
 */
export const getDayHeaderItem = ({
  text,
  dayOfWeek,
  date,
}: SlotDay): QueueHeaderItem => ({
  queueItemType: QueueItemType.Header,
  id: text,
  text,
  dayOfWeek,
  date,
})

const servicesWithCommentFeature = ['instagram', 'linkedin']
/**
 * Convenience method for generating a post item for the `QueueItems` component
 */
export const getPostItem = ({
  isManager,
  post,
}: {
  isManager: boolean
  post: RpcUpdate
}): QueuePostItem => {
  const hasCommentEnabled = postHasCommentEnabled(post)

  return {
    queueItemType: QueueItemType.Post,
    ...post,
    isManager,
    draggable: false,
    hasCommentEnabled,
  }
}

/**
 * Convenience method for generating a slot item for the `QueueItems` component
 */
export const getSlotItem = ({
  slot,
  profileService,
}: {
  slot: SlotItem
  profileService: string
}): QueueSlotItem => ({
  queueItemType: QueueItemType.Slot,
  id: `${slot.timestamp}-${slot.name}`,
  slot,
  profileService,
})

/**
 * Given a `daySlot` and array of `posts` this method will return either a post item or
 * and empty slot item if no post is currently occupying that slot.
 */
export const getSlotOrPostItem = (args: {
  daySlot: SlotItem
  posts: Array<RpcUpdate>
  isManager: boolean
  profileService: string
}): QueuePostItem | QueueSlotItem => {
  const { daySlot, posts, isManager, profileService } = args
  const postInSlot = posts.find((post) => {
    const isAtSlotTime = post.due_at === daySlot.timestamp
    const isPostPinned = 'pinned' in post && post.pinned
    const isCustomScheduled = !!post.scheduled_at && !isPostPinned
    return isAtSlotTime && !isCustomScheduled
  })
  if (postInSlot) {
    return getPostItem({ isManager, post: postInSlot })
  }
  return getSlotItem({
    slot: daySlot,
    profileService,
  })
}

const isSlotItem = (item: QueueItem): item is QueueSlotItem => {
  return item.queueItemType === QueueItemType.Slot
}

interface GetItemsForDayInput {
  daySlots: SlotItem[]
  posts: Array<RpcUpdate>
  isManager: boolean
  profileService: string
  orderBy: 'due_at'
}
/**
 * Returns a list of queue items for a given set of `daySlots` that were
 * obtained from `getSlotsWithTimestampsForDay`.
 */
export const getQueueItemsForDay = ({
  daySlots,
  posts,
  isManager,
  profileService,
}: Omit<GetItemsForDayInput, 'orderBy'>): QueueItem[] => {
  const postsCollected: string[] = []

  const pinnedAndQueuedPosts = daySlots.map((daySlot) => {
    const item = getSlotOrPostItem({
      daySlot,
      posts,
      isManager,
      profileService,
    })
    if (item.queueItemType === QueueItemType.Post) {
      postsCollected.push(item.id)
    }
    return item
  })

  const remainingPosts = posts
    .filter((post) => !postsCollected.includes(post.id))
    .map((post) => getPostItem({ isManager, post }))

  const items = pinnedAndQueuedPosts.concat(remainingPosts).sort((a, b) => {
    const aField = isSlotItem(a) ? a.slot.timestamp : a.due_at
    const bField = isSlotItem(b) ? b.slot.timestamp : b.due_at
    return aField - bField
  })

  return items
}

/**
 * Returns a list of posts by day and a single slot for a given set of `daySlots` that were
 * obtained from `getSlotsWithTimestampsAndNoTimeForDay`.
 */
export const getItemsForDay = ({
  daySlots,
  posts,
  isManager,
  profileService,
  orderBy = 'due_at',
}: GetItemsForDayInput): QueueItem[] => {
  const slotsItems = daySlots.map((daySlot) => {
    const item = getSlotItem({ slot: daySlot, profileService })
    return item
  })

  const postsItems = posts.map((post) => getPostItem({ isManager, post }))

  /**
   * Sort posts first and then concant the slots items
   * to view at the end
   */
  const sortedPostItems = postsItems.sort((a, b) => {
    return a[orderBy] - b[orderBy]
  })

  return [...sortedPostItems, ...slotsItems]
}

type PostsByDay = Record<string, Array<RpcUpdate>>
export const groupPostsByDay = (posts: Array<RpcUpdate>): PostsByDay =>
  posts.reduce<PostsByDay>((finalPosts, post) => {
    // I have to cast the type here because Typescript is dumb
    const posts = [...(finalPosts[post.day] || []), post] as RpcUpdate[]
    finalPosts[post.day] = posts
    return finalPosts
  }, {})

interface GetDaysToAddForPastPostsArgs {
  posts: Array<RpcUpdate>
  profileTimezone: string
}
export const getDaysToAddForPastPosts = ({
  posts,
  profileTimezone,
}: GetDaysToAddForPastPostsArgs): SlotDay[] => {
  const now = dayjs().tz(profileTimezone)

  const startOfToday = dayjs(now).startOf('day')
  const pastPosts = posts.filter((post) => post.due_at < startOfToday.unix())
  const pastPostsDays = pastPosts.map((post) => post.day)
  const uniqueDays: string[] = [...new Set(pastPostsDays)] // removes duplicates

  return uniqueDays.map((day) => {
    const [dayOfWeek, ...rest] = day.split(' ')
    const date = rest.join(' ')
    return {
      text: day,
      date,
      dayOfWeek,
      dayIndex: -1,
      dayUnixTime: 0,
    }
  })
}

export const postHasCommentEnabled = (post: RpcUpdate): boolean => {
  return 'profile_service' in post
    ? servicesWithCommentFeature.indexOf(post?.profile_service) !== -1
    : false
}

function orderPosts(
  posts: Record<string, RpcUpdate> | RpcUpdate[],
  orderBy: 'due_at',
  sortOrder: 'asc' | 'desc',
): Array<RpcUpdate> {
  if (!posts) return []
  if (!orderBy && Array.isArray(posts)) return posts
  return Object.values(posts).sort((a, b) =>
    sortOrder === 'asc' ? a[orderBy] - b[orderBy] : b[orderBy] - a[orderBy],
  )
}

interface BaseFormatPostLists {
  isManager: boolean
  posts: Record<string, RpcUpdate> | RpcUpdate[]
  scheduleSlotsEnabled: boolean
  orderBy?: 'due_at'
  sortOrder?: 'asc' | 'desc'
}

interface FormatPostListsWithoutScheduleSlots extends BaseFormatPostLists {
  scheduleSlotsEnabled: false
}

interface FormatPostListsWithScheduleSlots extends BaseFormatPostLists {
  scheduleSlotsEnabled: true
  schedules?: Schedule[]
  profileTimezone: string
  weekStartsOnMonday: boolean
  weeksToShow: number
  hasTwentyFourHourTimeFormat?: boolean
  profileService: string
  isStoriesSlot?: boolean
  pausedDays?: string[]
  shouldDisplaySingleSlots?: boolean
}

export type FormatPostListsArgs =
  | FormatPostListsWithoutScheduleSlots
  | FormatPostListsWithScheduleSlots

/**
 * This method formats a list of posts into a list that contains day headings,
 * posts and optionally queue slots (if supported by the plan.)
 */
export const formatPostLists = (args: FormatPostListsArgs): QueueItem[] => {
  const {
    isManager,
    posts,
    scheduleSlotsEnabled,
    orderBy = 'due_at',
    sortOrder = 'asc',
  } = args

  const orderedPosts = orderPosts(posts, orderBy, sortOrder) ?? []

  /**
   * CASE 1: Schedule Slots Enabled
   */
  if (scheduleSlotsEnabled) {
    const {
      isManager,
      schedules,
      profileTimezone,
      weekStartsOnMonday,
      weeksToShow,
      hasTwentyFourHourTimeFormat,
      profileService,
      isStoriesSlot,
      orderBy = 'due_at',
      pausedDays = [],
      shouldDisplaySingleSlots,
    } = args

    // Get the schedule slots for each day
    const fullDays: Day[] = [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ]

    let dailySlots: DailySlots[] = []
    if (schedules) {
      dailySlots = getDailySlotsFromProfileSchedules(schedules)
    }

    // Now get the weeks/days we'll show in the queue
    let days = getDaysForUpcomingWeeks({
      profileTimezone,
      weekStartsOnMonday,
      numWeeks: weeksToShow,
    })

    // Let's group posts by their 'day' field to make grabbing them easier
    const postsByDay = groupPostsByDay(orderedPosts)

    // Now we'll start composing the list that will be passed to
    // our `QueueItems` component
    let finalList: QueueItem[] = []

    /**
     * First thing we need to do is add posts that are from the past days to the top of the list.
     * These must be posts that failed to send for some reason or another, since otherwise
     * they'd be in the Sent Posts tab.
     */
    const pastPostDays = getDaysToAddForPastPosts({
      posts: orderedPosts,
      profileTimezone,
    })
    days = [...pastPostDays, ...days]

    // Now let's add the posts for the Daily View weeks
    days.forEach((day) => {
      const dayHeader = getDayHeaderItem(day)
      if (dayHeader.id !== noScheduledDate) {
        let daySlots: SlotItem[]
        let queueItemsForDay: QueueItem[]
        const postsForDay = postsByDay[day.text] || []
        const dayOfTheWeek = fullDays[day.dayIndex]
        const dayPaused = pausedDays.includes(dayOfTheWeek)
        // only show slot if all unpaused days don't have times set
        const isQueueSingleSlot = shouldDisplaySingleSlots && !dayPaused
        // For Stories tabs, we only need to load one slot per day
        // which should be visible at all times
        if (isStoriesSlot || isQueueSingleSlot) {
          daySlots = getSlotsWithTimestampsAndNoTimeForDay({
            profileTimezone,
            hasTwentyFourHourTimeFormat: !!hasTwentyFourHourTimeFormat,
            day,
          })
          queueItemsForDay = getItemsForDay({
            daySlots,
            posts: postsForDay,
            isManager,
            profileService,
            orderBy,
          })
        } else {
          daySlots = getSlotsWithTimestampsForDay({
            profileTimezone,
            hasTwentyFourHourTimeFormat: !!hasTwentyFourHourTimeFormat,
            dailySlots,
            day,
          })
          queueItemsForDay = getQueueItemsForDay({
            daySlots,
            posts: postsForDay,
            isManager,
            profileService,
          })
        }

        // Check for length here so we don't add a dayHeader when there are no slots or posts
        if (queueItemsForDay.length) {
          finalList = [...finalList, dayHeader, ...queueItemsForDay]
        }
      }
    })
    /**
     * Sometimes posts will have 'No scheduled days or times' set as their day
     * field. This means their `due_at` time is `0`.
     * This will happen when either
     *   a) The post was going to be sent, but the profile was paused (so we set the time to `0`) OR
     *   b) The user has no times set in their posting schedule.
     */
    if (postsByDay[noScheduledDate]) {
      const isPaused = schedules?.some((item) => item.times.length > 0)
      const headerText = isPaused ? 'Paused posts' : noScheduledDate

      finalList = [
        ...postsByDay[noScheduledDate].map((post) =>
          getPostItem({ isManager, post }),
        ),
        ...finalList,
      ]

      finalList.unshift({
        queueItemType: QueueItemType.Header,
        id: headerText,
        text: headerText,
      })
    }

    return finalList
  }

  /**
   * CASE 2: Schedule Slots Disabled
   * If schedule slots aren't enabled, the queue logic is much more simple
   */
  let lastHeader: string | null = null
  const result = orderedPosts.reduce<Array<QueueItem>>(
    (finalList: QueueItem[], post: RpcUpdate, index: number) => {
      const hasCommentEnabled = postHasCommentEnabled(post)

      if (lastHeader !== post.day) {
        // post.day is coming as a string of dayOfWeek, day and Month (e.g Tomorrow 3rd March)
        // we want to separate the dayOfWeek from the rest of the date
        const dayElementsInArray = post?.day?.split(' ')
        const date =
          dayElementsInArray?.length === 3
            ? `${dayElementsInArray[1]} ${dayElementsInArray[2]}`
            : undefined
        lastHeader = post.day
        finalList.push({
          queueItemType: QueueItemType.Header,
          text: post.day,
          dayOfWeek: dayElementsInArray && dayElementsInArray[0],
          date,
          id: `header-${index}`,
        })
      }
      finalList.push({
        queueItemType: QueueItemType.Post,
        index,
        ...post,
        isManager,
        hasCommentEnabled,
      })
      return finalList
    },
    [],
  )
  return result
}

const getBaseURL = (): string =>
  window.location.hostname === 'publish.local.buffer.com'
    ? 'https://local.buffer.com'
    : 'https://buffer.com'

export const openCalendarWindow = (
  profileId: string,
  weekOrMonth: 'week' | 'month',
): void => {
  window.open(
    `${getBaseURL()}/app/profile/${profileId}/buffer/queue/calendar/${weekOrMonth}/?content_only=true`,
    '_blank',
  )
}

export const isScheduleSlotsAvailable = (schedules: Schedule[]): boolean =>
  schedules.some((item) => item.times.length > 0)
