import type { RootState } from '~publish/legacy/store'
import type { GeneralSettingsState, TrackingSettings } from './reducer'
import type { LinkShortener } from './types'

export const selectGeneralSettings = (
  state: RootState,
): GeneralSettingsState => {
  return state?.generalSettings
}

export const selectIsLinkShorteningEnabled = (state: RootState): boolean => {
  return state?.generalSettings?.linkShortening.linkShorteners.some(
    (linkShortener) =>
      linkShortener.selected && linkShortener.domain !== 'No Shortening',
  )
}

export const selectIsBitlyConnected = (state: RootState): boolean => {
  return state?.generalSettings?.linkShortening.linkShorteners.some(
    (linkShortener) =>
      linkShortener.selected && linkShortener.domain === 'bit.ly',
  )
}

export const selectLinkShorteners = (state: RootState): LinkShortener[] => {
  return state?.generalSettings.linkShortening.linkShorteners
}

export const selectTrackingSettings = (
  state: RootState,
): TrackingSettings | undefined => {
  return state?.generalSettings?.trackingSettings
}

export const selectRemindersAreEnabled = (state: RootState): boolean => {
  return state?.generalSettings?.remindersAreEnabled
}
