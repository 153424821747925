import twitterText from 'twitter-text'

import {
  isValidHttpUrl,
  removeUrlProtocol,
} from '~publish/legacy/composer/composer/utils/StringUtils'
import { concatMentionsToEntities } from '../common'

const MAX_URL_LENGTH = 30

const getMastodonMentions = (text: string) => {
  const regex =
    /@([a-zA-Z0-9]+(?:@[a-zA-Z0-9]+\.[a-zA-Z0-9]+\.[a-zA-Z0-9]+)?(?:@[a-zA-Z0-9]+\.[a-zA-Z0-9]+)?)/g
  const matches = text.match(regex)

  if (!matches) {
    return null
  }

  return matches.map((match) => {
    const parts = match.split('@')
    const mention = parts[1]
    const displayString = `@${mention}`
    const start = text.indexOf(match)

    const entity = {
      displayString,
      indices: [start, start + match.length],
      rawString: match,
    }

    return entity
  })
}

// @ts-expect-error TS(7006) FIXME: Parameter 'text' implicitly has an 'any' type.
const getEntities = (text, entities) => {
  const mentions = getMastodonMentions(text)

  return concatMentionsToEntities(mentions, entities)
}

// @ts-expect-error TS(7006) FIXME: Parameter 'url' implicitly has an 'any' type.
const parseUrl = (url) => {
  let result = removeUrlProtocol(url)
  const isUrlTooLong = result && result.length > MAX_URL_LENGTH
  if (isUrlTooLong) {
    result = `${result.substring(0, MAX_URL_LENGTH)}...`
  }

  return result
}

// @ts-expect-error TS(7006) FIXME: Parameter 'text' implicitly has an 'any' type.
const parseMastodonLinks = (text) => {
  const entities = text
    ? twitterText
        .extractEntitiesWithIndices(text)
        .map((entity) => {
          if ('url' in entity && entity.url && isValidHttpUrl(entity.url)) {
            return {
              displayString: parseUrl(entity.url),
              indices: entity.indices,
              rawString: entity.url,
              url: entity.url,
            }
          }
          if ('hashtag' in entity && entity.hashtag) {
            return {
              displayString: `#${entity.hashtag}`,
              indices: entity.indices,
              rawString: `#${entity.hashtag}`,
              className: 'hashtag',
            }
          }
          return null
        })
        .filter((entity) => entity !== null)
    : []

  return text ? getEntities(text, entities) : []
}

export { parseMastodonLinks, getMastodonMentions }
