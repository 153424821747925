import { createParagraphsFromText } from '~publish/legacy/editor/nodes'
import { insertNodes, removeNodes, findNode } from '@udecode/plate'
import { GenerateContentElement } from '~publish/legacy/editor/plugins/generate-content/nodes/GenerateContentElement'
import { getGeneratedText } from '../api/getGeneratedText'

type TGenerateTextParams = {
  editor: any
  organizationId: string
  action: string
  service?: string
  annotation: string | null
  metadata: {
    placement: string
    retry: boolean
  }
}

export async function generateText({
  editor,
  organizationId,
  action,
  service,
  annotation,
  metadata,
}: TGenerateTextParams): Promise<void | boolean> {
  const { prompt, isHighlighted } = editor.prompt?.() || {}
  const generatedContent = editor.getGeneratedContent?.()

  if (!prompt) {
    throw new Error('Prompt cannot be empty')
  }

  let fullPrompt = prompt
  if (annotation) {
    fullPrompt = fullPrompt.concat(`. ${annotation}`)
  }

  await getGeneratedText({
    organizationId,
    prompt: fullPrompt,
    previousGeneration: generatedContent,
    isHighlighted,
    action,
    service,
    metadata,
  })
    .then((response) => {
      const suggestion = findNode(editor, {
        at: [],
        match: (n) => GenerateContentElement.is(n),
      })

      if (suggestion) {
        removeNodes(editor, {
          at: suggestion[1],
        })
      }

      const paragraphs = createParagraphsFromText(response)
      const position = suggestion ? suggestion[1] : editor.children.length

      insertNodes(
        editor,
        { type: 'generate-content-element', children: paragraphs },
        { at: [position] },
      )

      editor.hasGeneratedContent = true

      return true
    })
    .catch((errorMessage) => {
      throw new Error(errorMessage.message)
    })
}
