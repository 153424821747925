import React from 'react'
import styled from 'styled-components'
import { fontWeightMedium } from '@bufferapp/ui/style/fonts'
import { HC_UTM_PARAMS } from '~publish/legacy/utils/contants'

export const URLS = {
  SIGN_UP_URL: 'https://buffer.com/pricing/publish',
  SUPPORT_URL: `https://support.buffer.com/article/668-adding-buffer-tools-to-your-organization?${HC_UTM_PARAMS}`,
  MARKETING_URL: 'https://buffer.com/publish',
  CONTACT_SUPPORT_URL: `https://support.buffer.com/?openContact=true&${HC_UTM_PARAMS}`,
}

const BoldText = styled.span`
  font-weight: ${fontWeightMedium};
`

const getContent = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'switchOrganization' implicitly ha... Remove this comment to see the full error message
  switchOrganization,
  // @ts-expect-error TS(7031) FIXME: Binding element 'translations' implicitly has an '... Remove this comment to see the full error message
  translations,
  // @ts-expect-error TS(7031) FIXME: Binding element 'orgName' implicitly has an 'any' ... Remove this comment to see the full error message
  orgName,
  // @ts-expect-error TS(7031) FIXME: Binding element 'orgNameWithAccess' implicitly has... Remove this comment to see the full error message
  orgNameWithAccess,
  // @ts-expect-error TS(7031) FIXME: Binding element 'orgIdWithAccess' implicitly has a... Remove this comment to see the full error message
  orgIdWithAccess,
}) => {
  const { adminWithOrgAccess, nonAdminWithOrgAccess, admin, nonAdmin } =
    translations.accessTypes
  return {
    admin: {
      primaryAction: {
        label: admin.primaryLabel,
        onClick: () => window.location.assign(URLS.SIGN_UP_URL),
      },
      secondaryAction: {
        label: admin.secondaryLabel,
        onClick: () => window.location.assign(URLS.MARKETING_URL),
      },
      description: (
        <>
          {admin.description1}
          <BoldText>{` ${orgName} `}</BoldText>
          {admin.description2}
        </>
      ),
    },
    adminWithOrgAccess: {
      primaryAction: {
        label: `${adminWithOrgAccess.primaryLabel} ${orgNameWithAccess}`,
        onClick: () => switchOrganization(orgIdWithAccess),
      },
      secondaryAction: {
        label: adminWithOrgAccess.secondaryLabel,
        onClick: () => window.location.assign(URLS.SIGN_UP_URL),
      },
      description: (
        <>
          {adminWithOrgAccess.description1}
          <BoldText>{` ${orgName} `}</BoldText>
          {adminWithOrgAccess.description2}
          <BoldText>{` ${orgNameWithAccess} `}</BoldText>
          {adminWithOrgAccess.description3}
        </>
      ),
    },
    nonAdmin: {
      secondaryAction: {
        label: nonAdmin.secondaryLabel,
        onClick: () => window.location.assign(URLS.CONTACT_SUPPORT_URL),
      },
      description: (
        <>
          {nonAdmin.description1}
          <BoldText>{` ${orgName} `}</BoldText>
          {nonAdmin.description2}
        </>
      ),
    },
    nonAdminWithOrgAccess: {
      primaryAction: {
        label: `${nonAdminWithOrgAccess.primaryLabel} ${orgNameWithAccess}`,
        onClick: () => switchOrganization(orgIdWithAccess),
      },
      secondaryAction: {
        label: nonAdminWithOrgAccess.secondaryLabel,
        onClick: () => window.location.assign(URLS.SUPPORT_URL),
      },
      description: (
        <>
          {nonAdminWithOrgAccess.description1}
          <BoldText>{` ${orgName} `}</BoldText>
          {nonAdminWithOrgAccess.description2}
          <BoldText>{` ${orgNameWithAccess} `}</BoldText>
          {nonAdminWithOrgAccess.description3}
        </>
      ),
    },
  }
}

// @ts-expect-error TS(7031) FIXME: Binding element 'isAdmin' implicitly has an 'any' ... Remove this comment to see the full error message
const getAccessType = ({ isAdmin, hasOrgWithAccess }) => {
  if (isAdmin) {
    return hasOrgWithAccess ? 'adminWithOrgAccess' : 'admin'
  }
  return hasOrgWithAccess ? 'nonAdminWithOrgAccess' : 'nonAdmin'
}

export const getTypeContent = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'switchOrganization' implicitly ha... Remove this comment to see the full error message
  switchOrganization,
  // @ts-expect-error TS(7031) FIXME: Binding element 'translations' implicitly has an '... Remove this comment to see the full error message
  translations,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isAdmin' implicitly has an 'any' ... Remove this comment to see the full error message
  isAdmin,
  // @ts-expect-error TS(7031) FIXME: Binding element 'orgWithAccess' implicitly has an ... Remove this comment to see the full error message
  orgWithAccess,
  // @ts-expect-error TS(7031) FIXME: Binding element 'orgName' implicitly has an 'any' ... Remove this comment to see the full error message
  orgName,
}) => {
  const accessType = getAccessType({
    isAdmin,
    hasOrgWithAccess: !!orgWithAccess,
  })
  const content = getContent({
    switchOrganization,
    translations,
    orgName,
    orgNameWithAccess: orgWithAccess?.name,
    orgIdWithAccess: orgWithAccess?.id,
  })
  return content[accessType]
}
