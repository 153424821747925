import {
  findNode,
  getEditorString,
  getNodeString,
  insertNodes,
  insertText,
  isCollapsed,
  moveSelection,
  setSelection,
  type TText,
  type WithPlatePlugin,
  comboboxActions,
} from '@udecode/plate'
import { Range } from 'slate'
import type {
  BufferEditor,
  BufferValue,
} from '~publish/legacy/editor/BufferEditor/types.plate'
import {
  isNextCharacterText,
  isNextCharacterWhitespaceOrEnd,
  isPreviousCharacterWhitespaceOrStart,
} from '../../queries'
import type {
  AutocompleteInputElement,
  AutocompleteInputElementInterface,
} from './nodes/AutocompleteInputElement'
import { getMentionTextRange } from './queries'
import type { AutocompletePluginOptions } from './types'

/**
 * Autocomplete plugin with overrides.
 * Copied from plate's mention plugin and edited.
 */
export const withAutocomplete =
  <E extends AutocompleteInputElement>(
    elementInterface: AutocompleteInputElementInterface<E>,
  ) =>
  (
    editor: BufferEditor,
    {
      options: {
        preventMentionInsert,
        shouldExitOnWhitespace,
        onPastedText,
        fetchNewSuggestions,
        onInsertTriggerBeforeText,
        normalizeNode,
        onMentionUpdated,
        areMentionsSupported,
      },
    }: WithPlatePlugin<AutocompletePluginOptions<E>, BufferValue, BufferEditor>,
  ) => {
    const {
      apply,
      insertBreak,
      insertText: _insertText,
      insertData: _insertData,
      insertTextData: _insertTextData,
      insertFragment: _insertFragment,
      normalizeNode: _normalizeNode,
      deleteBackward: _deleteBackward,
    } = editor

    const stripNewLineAndTrim: (text: string) => string = (text) => {
      return text
        .split(/\r\n|\r|\n/)
        .map((line) => line.trim())
        .join('')
    }

    editor.areMentionsSupported = areMentionsSupported

    editor.insertFragment = (fragment) => {
      if (
        !findNode<E, BufferValue>(editor, {
          match: (node) => elementInterface.is(node),
        })
      ) {
        return _insertFragment(fragment)
      }

      return insertText(
        editor,
        fragment
          .map((node) => stripNewLineAndTrim(getNodeString(node)))
          .join(''),
      )
    }

    editor.insertTextData = (data) => {
      const mentionNodeEntry = findNode<E, BufferValue>(editor, {
        match: (node) => elementInterface.is(node),
      })
      if (!mentionNodeEntry) {
        return _insertTextData(data)
      }

      const text = data.getData('text/plain')
      if (!text) {
        return false
      }

      editor.insertText(stripNewLineAndTrim(text))

      return true
    }

    editor.insertData = (data) => {
      _insertData(data)
      // If text was pasted, run 'onPastedText' if defined
      const text = data.getData('text/plain')
      if (text) onPastedText?.(editor)
    }

    editor.insertBreak = () => {
      // When inserting a break while inside of an autocomplete input
      // do nothing as the key press is for selecting an option
      if (
        findNode<E, BufferValue>(editor, {
          match: (node) => elementInterface.is(node),
        })
      ) {
        return
      }

      insertBreak()
    }

    editor.normalizeNode = (nodeEntry) => {
      if (normalizeNode?.(editor, nodeEntry)) return
      _normalizeNode(nodeEntry)
    }

    editor.deleteBackward = (unit) => {
      _deleteBackward(unit)
      const mentionNodeEntry = findNode<E, BufferValue>(editor, {
        match: (node) => elementInterface.is(node),
      })

      if (mentionNodeEntry) {
        onMentionUpdated?.(editor, elementInterface)
      }
    }

    /**
     * When inserting text:
     * 1. If selection is inside a mention element, insert text normally
     * 2. If selection is preceded by a whitespace or start of line
     *    and followed by whitespace or end of line, insert a new mention
     * 3. If selection is preceded by a whitespace or start of line
     *    and followed by text, run onInsertTriggerBeforeText
     * 4. If none of the above are true, insert the text normally
     */
    editor.insertText = (text) => {
      // When inserting text, if there is no active selection,
      // the text does not match the plugin trigger, or selection
      // is inside an autocomplete input, insert the text as normal
      const mentionNodeEntry = findNode<E, BufferValue>(editor, {
        match: (node) => elementInterface.is(node),
      })

      if (mentionNodeEntry) {
        // NOTE: This works in conjunction with the autocompleteOnKeyDownHandler.
        // Don't ask me how, I can only tell you why. 15/02/20223
        if (
          text === ' ' &&
          shouldExitOnWhitespace?.(editor, mentionNodeEntry)
        ) {
          moveSelection(editor, { distance: 1, unit: 'offset' })
          _insertText(text)
          return
        }

        _insertText(text)
        onMentionUpdated?.(editor)
        return
      }

      if (
        text === elementInterface.trigger &&
        !preventMentionInsert?.(editor)
      ) {
        const pointBeforeIsValid = isPreviousCharacterWhitespaceOrStart(editor)
        const pointAfterIsValid = isNextCharacterWhitespaceOrEnd(editor)

        if (pointBeforeIsValid && pointAfterIsValid) {
          insertNodes(editor, elementInterface.new())
          return
        }

        const pointAfterIsText = isNextCharacterText(editor)
        if (
          pointBeforeIsValid &&
          pointAfterIsText &&
          onInsertTriggerBeforeText
        ) {
          onInsertTriggerBeforeText(editor, elementInterface)
          return
        }
      }

      _insertText(text)
    }

    editor.apply = (operation) => {
      apply(operation)

      // When inserting text while selection in inside an
      // autocomplete input, update the combobox text state
      if (
        operation.type === 'insert_text' ||
        operation.type === 'remove_text'
      ) {
        const mentionNodeEntry = findNode<E, BufferValue>(editor, {
          match: (node) => elementInterface.is(node),
        })

        if (mentionNodeEntry && !mentionNodeEntry[0]?.autocompleted) {
          comboboxActions.open({
            activeId: elementInterface.type,
            text: getEditorString(editor, mentionNodeEntry[1]),
            targetRange: getMentionTextRange(editor, elementInterface),
          })
          fetchNewSuggestions?.(editor)
        }
      } else if (operation.type === 'set_selection') {
        const previousAutocompleteInput = Range.isRange(operation.properties)
          ? findNode<E, BufferValue>(editor, {
              at: operation.properties,
              match: (node) => elementInterface.is(node),
            })
          : undefined

        const selectedMentionEntry = Range.isRange(operation.newProperties)
          ? findNode<E, BufferValue>(editor, {
              at: operation.newProperties,
              match: (node) => elementInterface.is(node),
            })
          : undefined

        const hasText = selectedMentionEntry?.[0]?.children?.[0]?.text

        if (previousAutocompleteInput && !selectedMentionEntry) {
          comboboxActions.reset()
        }

        if (
          !previousAutocompleteInput &&
          selectedMentionEntry &&
          hasText &&
          isCollapsed(editor.selection)
        ) {
          const { autocompleted } = selectedMentionEntry[0]
          comboboxActions.reset()

          if (!autocompleted) {
            const text = getEditorString(
              editor,
              selectedMentionEntry[1],
            ).replace(elementInterface.trigger, '')

            comboboxActions.open({
              activeId: elementInterface.type,
              text,
              targetRange: getMentionTextRange(editor, elementInterface),
            })
            fetchNewSuggestions?.(editor)
          }
        }
        // When inserting a node and the node is an autocomplete input
        // belonging to the current plugin, open the combobox for the
        // current plugin
      } else if (
        operation.type === 'insert_node' &&
        elementInterface.is(operation.node)
      ) {
        const text = (operation.node.children as TText[])[0]?.text ?? ''

        // Needed for undo - after an undo a mention insert we only receive
        // an insert_node with the mention input, i.e. nothing indicating that it
        // was an undo.
        setSelection(editor, {
          anchor: { path: operation.path.concat([0]), offset: text.length },
          focus: { path: operation.path.concat([0]), offset: text.length },
        })

        if (text) {
          comboboxActions.reset()
          comboboxActions.open({
            activeId: elementInterface.type,
            text,
            targetRange: getMentionTextRange(editor, elementInterface),
          })
        }

        // When removing a node and the node is an autocomplete input
        // belonging to the current plugin, reset the combobox state
      } else if (
        operation.type === 'remove_node' &&
        elementInterface.is(operation.node)
      ) {
        comboboxActions.reset()
      }
    }

    return editor
  }
