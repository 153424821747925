import { useMemo } from 'react'
import DOMPurify from 'dompurify'

const sanitizeAndHighlightLinks = (text: string | undefined | null): string => {
  const trimmedText = (text ?? '').trim()
  if (!trimmedText) {
    return ''
  }
  // sanitize and strip out tags
  const sanitizedText = DOMPurify.sanitize(trimmedText, { ALLOWED_TAGS: [] })
  return sanitizedText.replace(
    /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi,
    '<b>$&</b>',
  )
}

const useSanitizedAndHighlightedText = (
  text: string | undefined | null,
): string => {
  return useMemo(() => sanitizeAndHighlightLinks(text), [text])
}

export { sanitizeAndHighlightLinks, useSanitizedAndHighlightedText }
