// @ts-expect-error TS(7006) FIXME: Parameter 'splitCta' implicitly has an 'any' type.
const isValidCtaString = (splitCta) => splitCta.length === 5

const getCtaProperties = (cta = null) => {
  if (cta) {
    // @ts-expect-error TS(2339) FIXME: Property 'split' does not exist on type 'never'.
    const splitCta = cta.split('-')
    if (isValidCtaString(splitCta)) {
      const [ctaApp, ctaView, ctaLocation, ctaButton, ctaVersion] = splitCta
      return {
        cta,
        ctaApp,
        ctaView,
        ctaLocation,
        ctaButton,
        ctaVersion,
      }
    }
  }
  return { cta }
}

export default getCtaProperties
