import type { Event } from '@bugsnag/browser'
import { SlateJsProxy } from '~publish/legacy/composer/composer/entities/EditorStateProxy'
import type { BufferEditor } from '~publish/legacy/editor/BufferEditor/types.plate'

export const updateSlateErrorData =
  ({ context, editor }: { context: string; editor: BufferEditor }) =>
  (event: Event): Event => {
    const [firstTrace] = event.errors[0].stacktrace
    event.context = context
    event.groupingHash = `${context}/${firstTrace?.method || 'unknownMethod'}`
    event.addMetadata('editor', {
      id: editor?.id ?? 'No id found',
      children: editor?.children ?? 'No children found on editor',
      isSlateEditor: SlateJsProxy.isEditorOrState(editor),
    })
    return event
  }
