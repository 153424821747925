type Dimension = number

export type ImageDimensions = {
  width: Dimension
  height: Dimension
} | null

const isValidDimension = (n: unknown): n is Dimension =>
  typeof n === 'number' && Number.isInteger(n) && n > 0

export const ImageDimensions = {
  new({ width, height }: { width?: unknown; height?: unknown }) {
    if (!isValidDimension(width) || !isValidDimension(height)) {
      return null
    }

    return {
      width,
      height,
    }
  },
}
