import NoteBlank from '@bufferapp/ui/Icon/Icons/NoteBlank'
import Note from '@bufferapp/ui/Icon/Icons/Note'
import * as Popover from '@radix-ui/react-popover'

import { white, grayDark } from '@bufferapp/ui/style/colors'
import styled from 'styled-components'

type NotesFeedContainerProps = {
  $isMigrationPopover?: boolean
  $showUpgradePath?: boolean
}
export const NotesFeedContainer = styled.div<NotesFeedContainerProps>`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: ${white};
  box-shadow: 0px 2px 16px rgb(0 0 0 / 16%);
  padding: ${(props): string => (props.$isMigrationPopover ? '0' : '10px 0')};
  ${(props): string => (props.$showUpgradePath ? 'padding-bottom: 0;' : '')};
  width: ${(props): string => (props.$isMigrationPopover ? '340px' : '420px')};
  max-height: 630px;
`

export const NotesFeedScrollContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  overflow-y: auto;
`

export const NotesWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0;
  margin: 0;
`

export const NotesBadgeWrapper = styled.div`
  position: relative;
  width: 26px;
  height: 26px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const NotesIconOutlined = styled(NoteBlank).attrs({
  size: 'medium',
  color: grayDark,
})`
  margin-top: 1px;
`

export const NotesIconFilled = styled(Note).attrs({
  size: 'medium',
  color: grayDark,
})`
  margin-top: 1px;
`

export const PopoverArrow = styled(Popover.Arrow)`
  fill: ${white};
  width: 20px;
  height: 10px;
  filter: drop-shadow(0px 1px 5px rgb(0 0 0 / 16%));
  // Hides drop shadow from bleeding into Content
  clip-path: inset(0px -15px -15px -15px);
`
