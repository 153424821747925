import { PostTypeShort } from '~publish/legacy/post/constants'
import { CommonRatios } from '../aspect-ratio/CommonRatios'
import { aspectRatioMatch } from '../validation/rules/aspectRatioMatch'
import type { ServiceMediaRestrictions } from './types'

export const YoutubeMediaRestrictions: ServiceMediaRestrictions = {
  [PostTypeShort]: {
    videos: [
      aspectRatioMatch.define(
        {
          expected: [CommonRatios['1:1'], CommonRatios['9:16']],
        },
        {
          messages: {
            generic:
              'Video dimensions are invalid. Only square (1:1) and portrait (9:16) aspect ratios are accepted',
          },
        },
      ),
    ],
  },
}
