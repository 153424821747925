import React from 'react'
import CheckmarkIcon from '@bufferapp/ui/Icon/Icons/Checkmark'

import * as Styled from './styles'

import { orange, blue } from '@bufferapp/ui/style/colors'

export const SelectItem = React.forwardRef(
  (
    {
      children,
      icon,
      disabled,
      ...props
    }: {
      children: JSX.Element
      icon: JSX.Element
      value: string
      selected: boolean
      disabled?: boolean
    },
    forwardedRef,
  ): JSX.Element => {
    return (
      // @ts-expect-error
      <Styled.SelectOption {...props} ref={forwardedRef} disabled={disabled}>
        <Styled.SelectOptionContentWrapper>
          <Styled.SelectOptionIndicator className="item-indicator">
            {props.selected && <CheckmarkIcon />}
          </Styled.SelectOptionIndicator>
          {icon && (
            <Styled.SelectOptionIcon
              selected={props.selected}
              color={props.value === 'notifyMe' ? orange : blue}
            >
              {icon}
            </Styled.SelectOptionIcon>
          )}
          {children}
        </Styled.SelectOptionContentWrapper>
      </Styled.SelectOption>
    )
  },
)
