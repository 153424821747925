import { Button, Notice } from '@bufferapp/ui'
import { gray, grayDarker, grayLight } from '@bufferapp/ui/style/colors'
import styled from 'styled-components'

export const StyledNotice = styled(Notice)`
  border-radius: 8px;
  width: 100%;
  position: absolute;
  top: -8px;
  transform: translateY(-100%);
`

export const IdeatorHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  padding: 24px 40px 0 40px;
`

export const HeaderText = styled.div`
  color: ${grayDarker};
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
`

export const IdeaTitleInput = styled.input`
  all: unset;
  margin: 20px 36px 8px 36px;
  padding: 4px;
  font-size: 18px;
  line-height: 28px;
  font-weight: 600;
  border: none;
  border-radius: 4px;
  color: ${grayDarker};

  ::placeholder {
    color: ${grayLight};
  }
`

export const IdeatorContent = styled.div`
  margin: 8px 40px 40px 40px;
  flex-grow: 1;
  font-size: 16px;
  line-height: 28px;
  color: ${grayDarker};
  overflow-y: auto;
  position: relative;

  [data-slate-placeholder='true'] {
    color: ${gray};
    --composer-placeholder-top: 0px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(255, 255, 255);
  }
`

export const MediaManagerWrapper = styled.div`
  position: relative;

  #media-manager {
    padding: 0 40px;
  }
`

export const IntegrationsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 40px 12px 40px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 20px 40px;
  border-top: 1px solid ${grayLight};
`

export const StyledButton = styled(Button)`
  margin-left: 16px;
`
