import React, { useEffect, useMemo, useRef } from 'react'

import type { Temporal } from '@js-temporal/polyfill'

import { getZonedNow, isSameDay } from '~publish/helpers/temporal'

import { Hour } from './Hour'
import { useCalendarContext } from './context'

type WeekProps = {
  hours: Temporal.ZonedDateTime[]
}

const Week = ({ hours }: WeekProps): JSX.Element => {
  const { items, isFirstLoad } = useCalendarContext()
  const currentHourRef = useRef<HTMLTableCellElement>(null)
  const hasScrolledRef = useRef<boolean>(false)

  useEffect(() => {
    // Scroll into view only if items are loaded and we never scrolled into view before
    if (currentHourRef?.current && items && !hasScrolledRef.current) {
      // Schedule the scroll into view to be executed after react has finished rendering
      setTimeout(() => {
        currentHourRef.current?.scrollIntoView()
        hasScrolledRef.current = true
      }, 0)
    }
  }, [items])

  const hoursByDayHour = useMemo(() => groupHoursByDayHour(hours), [hours])

  return (
    <>
      {hoursByDayHour.map((hours) => {
        return (
          <tr
            key={`weekly-row-${hours[0].toString()}`}
            data-testid={`weekly-row-${hours[0].toString()}`}
          >
            {hours.map((hour, index) => (
              <Hour
                key={hour.toString()}
                isFirstLoad={isFirstLoad}
                hour={hour}
                showLabel={index === 0}
                ref={isCurrentHour(hour) ? currentHourRef : null}
              />
            ))}
          </tr>
        )
      })}
    </>
  )
}

function isCurrentHour(date: Temporal.ZonedDateTime): boolean {
  const now = getZonedNow(date.timeZoneId)
  return isSameDay(date, now) && date.hour === now.hour
}

// An array of arrays of hours grouped by hour of the day
// e.g. [[day1-00:00, day2-00:00], [day1-01:00, day2-01:00], [day1-02:00, day2-02:00], ...]
function groupHoursByDayHour(
  hours: Temporal.ZonedDateTime[],
): Temporal.ZonedDateTime[][] {
  const hoursByDayHour = new Map<number, Temporal.ZonedDateTime[]>()

  for (const hour of hours) {
    const hourKey = hour.hour
    const existingHours = hoursByDayHour.get(hourKey) || []

    // Check if the current hour is already in the existingHours array
    // This prevents duplicate entries for the same hour and day
    // (e.g. daylight saving time transitions where an hour might be repeated)
    if (
      !existingHours.some(
        (existingHour) =>
          existingHour.hour === hour.hour && existingHour.day === hour.day,
      )
    ) {
      existingHours.push(hour)
      hoursByDayHour.set(hourKey, existingHours)
    }
  }

  return Array.from(hoursByDayHour.entries()).map(([, hourHours]) => hourHours)
}

const memoWeek = React.memo(Week)
export { memoWeek as Week }
