import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { Dialog, EmptyState, WarningIcon, Text } from '@buffer-mono/popcorn'
import { createPage } from '~publish/legacy/routes'
import type { Location } from 'history'

/**
 * Dialog rendered when IdeaEditDialog or IdeaDetailsDialog fails to fetch the idea.
 * It will show an error message and return to the parent route when closed.
 *
 * @param parent - If no returnTo location can be found in location state,
 * use the provided parent route to return to when dialog is closed.
 */
export function IdeaErrorDialog({
  error,
  parent = createPage.route,
}: {
  error: { message: string }
  parent?: string
}): JSX.Element {
  const [isOpen, setIsOpen] = useState(true)
  const history = useHistory()
  const location = useLocation<{ returnTo: Location }>()

  const handleOpenChange = (open: boolean): void => {
    setIsOpen(open)
    if (!open) {
      history.push(location?.state?.returnTo ?? parent)
    }
  }
  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <Dialog.Content
        size={error ? 'medium' : 'large'}
        style={{ overflow: 'visible', padding: 0 }}
      >
        <EmptyState
          variant={error.message === 'Bad Request' ? 'warning' : 'critical'}
        >
          <EmptyState.Icon>
            <WarningIcon />
          </EmptyState.Icon>
          {error.message === 'Bad Request' && (
            <>
              <EmptyState.Heading>Idea not found</EmptyState.Heading>
              <EmptyState.Description>
                The idea you are trying to access does not exist.
              </EmptyState.Description>
            </>
          )}
          {error.message !== 'Bad Request' && (
            <>
              <EmptyState.Heading>Error happened</EmptyState.Heading>
              <EmptyState.Description>
                Error happened fetching the idea, please let our team know about
                it. <Text color="critical">{error.message}</Text>
              </EmptyState.Description>
            </>
          )}
        </EmptyState>
      </Dialog.Content>
    </Dialog>
  )
}
