import keyWrapper from '~publish/helpers/keywrapper'
import type { LeanTag } from '~publish/legacy/campaign/types'

export const initialState = {
  showProfilesDisconnectedModal: false,
  switchPlanModalSource: null,
  showInstagramFirstCommentModal: false,
  showFirstCommentPaywallModal: false,
  firstCommentPaywallService: '',
  showTrialCompleteModal: false,
  showCloseComposerConfirmationModal: false,
  modalToShowLater: null,
  showDeleteCampaignModal: false,
  showTrialCollaborationModal: false,
  page: 'queue',
  showInstagramRemindersSetupModal: false,
  showTagFormModal: false,
  showHashTagManagerPaywallModal: false,
  showTagsLimitUpgradeModal: false,
  showConnectAchannelModal: false,
  serviceToConnect: null,
}

export const actionTypes = keyWrapper('MODALS', {
  SHOW_PROFILES_DISCONNECTED_MODAL: 0,
  HIDE_PROFILES_DISCONNECTED_MODAL: 0,
  SHOW_TRIAL_COMPLETE_MODAL: 0,
  HIDE_TRIAL_COMPLETE_MODAL: 0,
  SHOW_INSTAGRAM_FIRST_COMMENT_MODAL: 0,
  HIDE_INSTAGRAM_FIRST_COMMENT_MODAL: 0,
  SHOW_FIRST_COMMENT_PAYWALL_MODAL: 0,
  HIDE_FIRST_COMMENT_PAYWALL_MODAL: 0,
  SHOW_INSTAGRAM_FIRST_COMMENT_PRO_TRIAL_MODAL: 0,
  HIDE_INSTAGRAM_FIRST_COMMENT_PRO_TRIAL_MODAL: 0,
  SAVE_MODAL_TO_SHOW_LATER: 0,
  HIDE_CLOSE_COMPOSER_CONFIRMATION_MODAL: 0,
  SHOW_CLOSE_COMPOSER_CONFIRMATION_MODAL: 0,
  SHOW_DELETE_CAMPAIGN_MODAL: 0,
  HIDE_DELETE_CAMPAIGN_MODAL: 0,
  HIDE_CONNECT_CHANNEL_MODAL: 0,
  SHOW_TRIAL_COLLABORATION_MODAL: 0,
  HIDE_TRIAL_COLLABORATION_MODAL: 0,
  SHOW_REMINDERS_SETUP_MODAL: 0,
  HIDE_REMINDERS_SETUP_MODAL: 0,
  SHOW_TAG_FORM_MODAL: 0,
  HIDE_TAG_FORM_MODAL: 0,
  SHOW_HASH_TAG_MANAGER_PAYWALL_MODAL: 0,
  HIDE_HASH_TAG_MANAGER_PAYWALL_MODAL: 0,
  SHOW_TAGS_LIMIT_UPGRADE_MODAL: 0,
  HIDE_TAGS_LIMIT_UPGRADE_MODAL: 0,
  SHOW_CONNECT_A_CHANNEL_MODAL: 0,
  HIDE_CONNECT_A_CHANNEL_MODAL: 0,
})

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_TRIAL_COMPLETE_MODAL:
      return {
        ...state,
        showTrialCompleteModal: true,
      }
    case actionTypes.HIDE_TRIAL_COMPLETE_MODAL:
      return {
        ...state,
        showTrialCompleteModal: false,
      }
    case actionTypes.SHOW_INSTAGRAM_FIRST_COMMENT_MODAL:
      return {
        ...state,
        showInstagramFirstCommentModal: true,
        firstCommentIds: action.ids,
      }
    case actionTypes.HIDE_INSTAGRAM_FIRST_COMMENT_MODAL:
      return {
        ...state,
        showInstagramFirstCommentModal: false,
        firstCommentIds: null,
      }
    case actionTypes.SHOW_FIRST_COMMENT_PAYWALL_MODAL:
      return {
        ...state,
        firstCommentPaywallService: action.service,
        showFirstCommentPaywallModal: true,
      }
    case actionTypes.HIDE_FIRST_COMMENT_PAYWALL_MODAL:
      return {
        ...state,
        showFirstCommentPaywallModal: false,
      }
    case actionTypes.SHOW_PROFILES_DISCONNECTED_MODAL:
      return {
        ...state,
        showProfilesDisconnectedModal: true,
      }
    case actionTypes.HIDE_PROFILES_DISCONNECTED_MODAL:
      return {
        ...state,
        showProfilesDisconnectedModal: false,
      }
    case actionTypes.SAVE_MODAL_TO_SHOW_LATER:
      return {
        ...state,
        modalToShowLater: {
          id: action.modalId,
          params: action.params,
        },
      }
    case actionTypes.HIDE_CLOSE_COMPOSER_CONFIRMATION_MODAL:
      return {
        ...state,
        showCloseComposerConfirmationModal: false,
      }
    case actionTypes.SHOW_CLOSE_COMPOSER_CONFIRMATION_MODAL:
      return {
        ...state,
        showCloseComposerConfirmationModal: true,
        page: action.page,
      }
    case actionTypes.HIDE_DELETE_CAMPAIGN_MODAL:
      return {
        ...state,
        showDeleteCampaignModal: false,
      }
    case actionTypes.SHOW_DELETE_CAMPAIGN_MODAL:
      return {
        ...state,
        showDeleteCampaignModal: true,
      }
    case actionTypes.SHOW_TRIAL_COLLABORATION_MODAL:
      return {
        ...state,
        showTrialCollaborationModal: true,
      }
    case actionTypes.HIDE_TRIAL_COLLABORATION_MODAL:
      return {
        ...state,
        showTrialCollaborationModal: false,
      }
    case actionTypes.SHOW_TAG_FORM_MODAL:
      return {
        ...state,
        showTagFormModal: true,
      }
    case actionTypes.HIDE_TAG_FORM_MODAL:
      return {
        ...state,
        showTagFormModal: false,
      }
    case actionTypes.SHOW_HASH_TAG_MANAGER_PAYWALL_MODAL:
      return {
        ...state,
        showHashTagManagerPaywallModal: true,
      }
    case actionTypes.HIDE_HASH_TAG_MANAGER_PAYWALL_MODAL:
      return {
        ...state,
        showHashTagManagerPaywallModal: false,
      }
    case actionTypes.SHOW_TAGS_LIMIT_UPGRADE_MODAL:
      return {
        ...state,
        showTagsLimitUpgradeModal: true,
      }
    case actionTypes.HIDE_TAGS_LIMIT_UPGRADE_MODAL:
      return {
        ...state,
        showTagsLimitUpgradeModal: false,
      }
    case actionTypes.SHOW_CONNECT_A_CHANNEL_MODAL:
      return {
        ...state,
        showConnectAChannelModal: true,
        serviceToConnect: action.service,
      }
    case actionTypes.HIDE_CONNECT_A_CHANNEL_MODAL:
      return {
        ...state,
        showConnectAChannelModal: false,
        serviceToConnect: null,
      }
    default:
      return state
  }
}

export const actions = {
  showTrialCompleteModal: () => ({
    type: actionTypes.SHOW_TRIAL_COMPLETE_MODAL,
  }),
  hideTrialCompleteModal: () => ({
    type: actionTypes.HIDE_TRIAL_COMPLETE_MODAL,
  }),
  // @ts-expect-error TS(7031) FIXME: Binding element 'ids' implicitly has an 'any' type... Remove this comment to see the full error message
  showInstagramFirstCommentModal: ({ ids }) => ({
    type: actionTypes.SHOW_INSTAGRAM_FIRST_COMMENT_MODAL,
    ids,
  }),
  hideInstagramFirstCommentModal: () => ({
    type: actionTypes.HIDE_INSTAGRAM_FIRST_COMMENT_MODAL,
  }),
  showProfilesDisconnectedModal: () => ({
    type: actionTypes.SHOW_PROFILES_DISCONNECTED_MODAL,
  }),
  // @ts-expect-error TS(7031) FIXME: Binding element 'service' implicitly ... Remove this comment to see the full error message
  showFirstCommentPaywallModal: ({ service }) => ({
    type: actionTypes.SHOW_FIRST_COMMENT_PAYWALL_MODAL,
    service,
  }),
  hideInstagramFirstCommentPaywallModal: () => ({
    type: actionTypes.HIDE_FIRST_COMMENT_PAYWALL_MODAL,
  }),
  showHashTagManagerPaywallModal: () => ({
    type: actionTypes.SHOW_HASH_TAG_MANAGER_PAYWALL_MODAL,
  }),
  hideHashTagManagerPaywallModal: () => ({
    type: actionTypes.HIDE_HASH_TAG_MANAGER_PAYWALL_MODAL,
  }),
  hideProfilesDisconnectedModal: () => ({
    type: actionTypes.HIDE_PROFILES_DISCONNECTED_MODAL,
  }),
  showTrialCollaborationModal: () => ({
    type: actionTypes.SHOW_TRIAL_COLLABORATION_MODAL,
  }),
  hideTrialCollaborationModal: () => ({
    type: actionTypes.HIDE_TRIAL_COLLABORATION_MODAL,
  }),
  // @ts-expect-error TS(7031) FIXME: Binding element 'modalId' implicitly has an 'any' ... Remove this comment to see the full error message
  saveModalToShowLater: ({ modalId, profileId }) => ({
    type: actionTypes.SAVE_MODAL_TO_SHOW_LATER,
    modalId,
    params: {
      profileId,
    },
  }),
  hideDeleteCampaignModal: () => ({
    type: actionTypes.HIDE_DELETE_CAMPAIGN_MODAL,
  }),
  // @ts-expect-error TS(7006) FIXME: Parameter 'campaign' implicitly has an 'any' type.
  showDeleteCampaignModal: (campaign) => ({
    type: actionTypes.SHOW_DELETE_CAMPAIGN_MODAL,
    campaign,
  }),
  hideConnectChannelModal: () => ({
    type: actionTypes.HIDE_CONNECT_CHANNEL_MODAL,
  }),
  // @ts-expect-error TS(7031) FIXME: Binding element 'modals' implicitly has an 'any' t... Remove this comment to see the full error message
  isShowingModals: ({ modals }) => {
    return (
      modals &&
      (modals.showProfilesDisconnectedModal ||
        modals.showInstagramFirstCommentModal ||
        modals.showTrialCompleteModal ||
        modals.showCloseComposerConfirmationModal ||
        modals.showTagFormModal ||
        modals.showFirstCommentPaywallModal ||
        modals.showHashTagManagerPaywallModal ||
        modals.showConnectAChannelModal ||
        false)
    )
  },
  openTagFormModal: ({
    editMode,
    isFromSelector = false,
    tag,
  }: {
    editMode: boolean
    isFromSelector?: boolean
    tag?: LeanTag
  }): {
    type: string
    editMode: boolean
    isFromSelector?: boolean
    tag?: LeanTag
  } => ({
    type: actionTypes.SHOW_TAG_FORM_MODAL,
    editMode,
    isFromSelector,
    tag,
  }),
  hideTagFormModal: (): { type: string } => ({
    type: actionTypes.HIDE_TAG_FORM_MODAL,
  }),
  showTagsLimitUpgradeModal: (): { type: string } => ({
    type: actionTypes.SHOW_TAGS_LIMIT_UPGRADE_MODAL,
  }),
  hideTagsLimitUpgradeModal: (): { type: string } => ({
    type: actionTypes.HIDE_TAGS_LIMIT_UPGRADE_MODAL,
  }),
  showConnectAChannelModal: (
    service: string,
  ): { type: string; service: string } => ({
    type: actionTypes.SHOW_CONNECT_A_CHANNEL_MODAL,
    service,
  }),
  hideConnectAChannelModal: (): { type: string } => ({
    type: actionTypes.HIDE_CONNECT_A_CHANNEL_MODAL,
  }),
}
