import { createSelector } from 'reselect'
import { PostEntity } from '~publish/legacy/post/PostEntity'
import type { RpcUpdate } from '~publish/legacy/post/types'
import { selectIsManagerInProfileById } from '~publish/legacy/profile-sidebar/selectors'
import { formatPostLists } from '~publish/legacy/queue/util'
import type { RootState } from '~publish/legacy/store'
import type { SentPostsProfileState } from './reducer'

export const selectProfileIdByPostId =
  (postId: string) =>
  (state: RootState): string | null => {
    const foundId = Object.keys(state.sent.byProfileId || {}).find(
      (profileId) => {
        // find the profileId that has this postId in it
        return Object.keys(state.sent.byProfileId[profileId].posts).some(
          (id) => {
            return id === postId
          },
        )
      },
    )

    return foundId || null
  }

export const selectPostById =
  (postId: string) =>
  (state: RootState): RpcUpdate | undefined => {
    const profileId = selectProfileIdByPostId(postId)(state)
    return profileId
      ? state.sent.byProfileId[profileId].posts[postId]
      : undefined
  }

export const selectLoading =
  (profileId: string) =>
  (state: RootState): boolean => {
    return state.sent.byProfileId?.[profileId]?.loading
  }

export const selectMoreToLoad =
  (profileId: string) =>
  (state: RootState): boolean => {
    return state.sent.byProfileId?.[profileId]?.moreToLoad
  }

export const selectPage =
  (profileId: string) =>
  (state: RootState): number => {
    return state.sent.byProfileId?.[profileId]?.page
  }

export const selectTotal =
  (profileId: string) =>
  (state: RootState): number => {
    return state.sent.byProfileId?.[profileId]?.total
  }

export const selectSentProfile = (
  state: RootState,
  profileId: string,
): SentPostsProfileState => {
  return state.sent.byProfileId?.[profileId]
}

export const selectSentPostsByProfileId = (
  state: RootState,
  profileId: string,
): Record<string, RpcUpdate> => {
  return state.sent.byProfileId?.[profileId]?.posts
}

export const selectHasSentReminderPost = createSelector(
  selectSentPostsByProfileId,
  (posts) => {
    return (
      !!posts &&
      Object.values(posts)?.some((post) => PostEntity.isPastReminder(post))
    )
  },
)

export const selectSentPostItems = createSelector(
  selectIsManagerInProfileById,
  selectSentPostsByProfileId,
  (isManager, posts) => {
    return formatPostLists({
      isManager,
      scheduleSlotsEnabled: false,
      posts,
      orderBy: 'due_at',
      sortOrder: 'desc',
    })
  },
)
