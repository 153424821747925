import React from 'react'
import { Services } from '../../AppConstants'
import type { ValidationFeedback } from '../../stores/types'

export const getFormattedWhatPreventsSavingMessages = ({
  warningMessages,
}: {
  warningMessages: ValidationFeedback[]
}): React.ReactNode | string => {
  if (warningMessages.length === 0) {
    return null
  }

  const messages = warningMessages.reduce<string[]>((finalMessages, what) => {
    let { message } = what

    // First make sure we can determine the composer service
    // before adding it to the error message
    const composerService = Services.get(what.composerId)
    if (composerService) {
      message = message.concat(` for ${composerService.formattedName}`)
    }

    // Make sure we don't show the same message twice
    if (!finalMessages.includes(message)) finalMessages.push(message)
    return finalMessages
  }, [])

  return messages.length === 1
    ? // If there is only one message, just return it as a string
      // to avoid list formatting
      messages
    : messages.map((message) => <p key={message}>{`• ${message}`}</p>)
}
