import {
  getNodeString,
  isElement,
  type TElement,
  type TNode,
} from '@udecode/plate'
import type { PlainText } from '~publish/legacy/editor/BufferEditor/types.plate'
import { Url } from '../../../helpers/Url'
import { ensureUrlProtocol } from '~publish/legacy/composer/composer/utils/StringUtils'

const LINK_ELEMENT_TYPE = 'a' as const

export type LinkEntity = {
  url: string
  shortUrl?: string
  shortLinkId?: string
  isShortened?: boolean
}

export interface LinkElement extends TElement, LinkEntity {
  type: typeof LINK_ELEMENT_TYPE
  children: [PlainText]
}

export const LinkElement = {
  type: LINK_ELEMENT_TYPE,
  is(node: TNode | undefined): node is LinkElement {
    return !!node && isElement(node) && node.type === LinkElement.type
  },

  serialize(node: LinkElement): { text: string; data: LinkEntity } {
    return {
      // link are non-void and the text should be taken from the child text node
      text: '',
      data: LinkElement.toEntity(node),
    }
  },

  new({
    url,
    ensureProtocol,
  }: {
    url: string
    ensureProtocol: boolean
  }): LinkElement | undefined {
    if (!url || !Url.isValid(url)) {
      return
    }

    const linkUrl = ensureProtocol ? ensureUrlProtocol(url) : url

    return {
      type: LINK_ELEMENT_TYPE,
      url: linkUrl,
      children: [{ text: linkUrl }],
    }
  },

  canBeUnshortened(node: LinkElement): boolean {
    return !!node.shortUrl && !!node.isShortened
  },

  hasBeenUnshortened(node: LinkElement): boolean {
    return !!node.shortUrl && !node.isShortened
  },

  getText(node: LinkElement | LinkEntity): string {
    const url = node.shortUrl && node.isShortened ? node.shortUrl : node.url

    return url
  },

  toEntity(node: LinkElement): LinkEntity {
    return {
      url: node.url,
      shortUrl: node.shortUrl,
      shortLinkId: node.shortLinkId,
      isShortened: node.isShortened,
    }
  },

  fromEntity(data: LinkEntity): LinkElement {
    return {
      type: LINK_ELEMENT_TYPE,
      url: data.url,
      shortUrl: data.shortUrl,
      shortLinkId: data.shortLinkId,
      isShortened: data.isShortened,
      children: [{ text: LinkElement.getText(data) }],
    }
  },

  isEmpty(node: LinkElement): boolean {
    return getNodeString(node) === ''
  },

  hasTextMismatch(node: LinkElement): boolean {
    return getNodeString(node) !== LinkElement.getText(node)
  },

  requiresTextUpdate(node: LinkElement): boolean {
    const text = getNodeString(node)
    const hasMatchingUrl =
      text === '' || text === node.shortUrl || text === node.url

    return LinkElement.hasTextMismatch(node) && hasMatchingUrl
  },

  getHref(node: LinkElement): string {
    // @todo: consider moving to Url helpers
    const hasProtocol = /^https?:\/\//.test(node.url)

    return hasProtocol ? node.url : `https://${node.url}`
  },
}
