export type Location = {
  city: string
  country: string
  state: string
  street: string
}

export class ServiceLocation {
  id: string
  name: string
  location: Location

  constructor(id: string, name: string, location: Location) {
    this.id = id
    this.name = name
    this.location = location
  }

  get formattedAddress(): string {
    if (this.location) {
      const { city, country, state, street } = this.location
      const formattedCity = city && (state || country) ? `${city},` : city
      return [street, formattedCity, state, country].filter(Boolean).join(' ')
    }

    return ''
  }
}
