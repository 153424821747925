import React from 'react'
import { Pinterest, Threads, Bluesky } from '@bufferapp/ui/Icon'

/*
 * SERVICE DATA CONFIGURATION FOR ConnectAChannelModal
 */

export interface ServiceInfo {
  id: string
  label: string
  banner_img_url: string
  title: string
  description: string
  icon: JSX.Element
  buttonLabel: string
}

export interface ServiceData {
  [key: string]: ServiceInfo // This line allows any string to be used as an index
}

export const connectAChannelServiceData: ServiceInfo[] = [
  {
    id: 'threads',
    label: 'Threads',
    banner_img_url:
      'https://s3.amazonaws.com/buffer-publish/images/Connect-Channel-Onboarding-Modal/threads-banner.svg',
    title: 'Connect your Threads account',
    description:
      'Begin by logging into your Threads account to start creating & scheduling your posts.',
    icon: <Threads />,
    buttonLabel: 'Connect to Threads',
  },
  {
    id: 'pinterest',
    label: 'Pinterest',
    banner_img_url:
      'https://s3.amazonaws.com/buffer-publish/images/Connect-Channel-Onboarding-Modal/threads-banner.svg',
    title: 'Connect your Pinterest account',
    description:
      'Begin by logging into your Pinterest account to start creating & scheduling your posts.',
    icon: <Pinterest />,
    buttonLabel: 'Connect to Pinterest',
  },
  {
    id: 'bluesky',
    label: 'Bluesky',
    banner_img_url:
      'https://s3.amazonaws.com/buffer-publish/images/Connect-Channel-Onboarding-Modal/bluesky-banner.svg',
    title: 'Connect your Bluesky account',
    description:
      'Begin by logging into your Bluesky account to start creating & scheduling your posts.',
    icon: <Bluesky />,
    buttonLabel: 'Connect to Bluesky',
  },
]
