import styled from 'styled-components'
import { purpleLight, grayDarker } from '@bufferapp/ui/style/colors'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;

  p {
    font-size: 16px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: ${grayDarker};
    margin: 0;
    padding: 0;
  }
`

export const ActiveItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;

  span {
    font-size: 16px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    background: -webkit-linear-gradient(left, #ba6bff, #0113ff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  svg {
    color: ${purpleLight};
    fill: ${purpleLight};
  }
`

export const BackButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;

  svg {
    color: ${grayDarker};
    fill: ${grayDarker};
  }
`
