import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import type { ApolloError } from '@apollo/client'

import {
  Button,
  CriticalIcon,
  EmptyState,
  LockIcon,
  Text,
} from '@buffer-mono/popcorn'
import { useChannels } from '~publish/components/PageSidebar/useChannels'

import * as routes from '~publish/legacy/routes'

export function ChannelError({ error }: { error: ApolloError }): JSX.Element {
  const { channels } = useChannels()
  const isSingleChannel = channels.length === 1
  const channelId = channels?.[0]?.id
  const match = useRouteMatch()

  let destinationGoBackUrl = isSingleChannel
    ? routes.channel.getRoute(channelId)
    : routes.allChannels.getRoute()

  if (routes.newCalendarSingleChannel.route === match.path) {
    const params = match.params as { granularity: 'week' | 'month' }
    destinationGoBackUrl = isSingleChannel
      ? routes.newCalendarSingleChannel.getRoute(channelId, params.granularity)
      : routes.newCalendarAllChannels.getRoute(params.granularity)
  }

  if (isPermissionError(error)) {
    return (
      <EmptyState size="xlarge" variant="neutral">
        <EmptyState.Icon>
          <LockIcon />
        </EmptyState.Icon>
        <EmptyState.Heading>Channel unavailable</EmptyState.Heading>
        <EmptyState.Description>
          You don’t have access to view this channel
        </EmptyState.Description>
        <EmptyState.Actions>
          <Button as={Link} to={destinationGoBackUrl} size="large">
            Go back to {isSingleChannel ? 'Channel' : 'All Channels'}
          </Button>
        </EmptyState.Actions>
      </EmptyState>
    )
  }
  if (isNotFoundError(error)) {
    return (
      <EmptyState size="xlarge" variant="neutral">
        <EmptyState.Icon>
          <CriticalIcon />
        </EmptyState.Icon>
        <EmptyState.Heading>Channel not found</EmptyState.Heading>
        <EmptyState.Description>
          This channel does not exist
        </EmptyState.Description>
        <EmptyState.Actions>
          <Button as={Link} to={destinationGoBackUrl} size="large">
            Go back to {isSingleChannel ? 'Channel' : 'All Channels'}
          </Button>
        </EmptyState.Actions>
      </EmptyState>
    )
  }
  return (
    <EmptyState size="xlarge" variant="critical">
      <EmptyState.Icon>
        <CriticalIcon />
      </EmptyState.Icon>
      <EmptyState.Heading>Failed to load</EmptyState.Heading>
      <EmptyState.Description>
        Error happened, please let our team know about it.{' '}
        <Text color="critical">{error.message}</Text>
      </EmptyState.Description>
    </EmptyState>
  )
}

// TODO This error codes should be improved on the backend so is less error prone
function isPermissionError(error: ApolloError): boolean {
  return error.message.startsWith('Failed to resolve hasActiveMemberDevice') // When the user does not have permissions on the channel
}

function isNotFoundError(error: ApolloError): boolean {
  return (
    error.message.startsWith('Bad Request') || // When the channel ID is malformed
    error.message.includes('Actor can not access this organization') // When the channel ID is from another organization
  )
}
