import React from 'react'
import styled from 'styled-components'
import { format } from 'date-fns'
import { Text, Tooltip } from '@bufferapp/ui'
import { gray } from '@bufferapp/ui/style/colors'
import { Info } from '@bufferapp/ui/Icon'
import { fontWeightMedium } from '@bufferapp/ui/style/fonts'
import { getFormattedTimezoneFromBrowser } from '~publish/legacy/utils/timezone'
import useMediaQuery from '../hooks/useMediaQuery'

export const Container = styled.div`
  display: flex;
  padding-right: 8px;
  align-items: center;
`

const TimezoneName = styled(Text)`
  margin: 0 8px 0 0;
  font-weight: ${fontWeightMedium};
`
const TimezoneWrapper = styled.span`
  div {
    height: 16px;
  }
`

const Timezone = (): JSX.Element => {
  const timezoneFromBrowser = getFormattedTimezoneFromBrowser()
  const useTimezoneAbbreviation = useMediaQuery('(max-width: 1500px)')
  const timezoneFromBrowserShorter = format(new Date(), 'zzz')

  return (
    <Container>
      <TimezoneName type="p" color="grayDark">
        {useTimezoneAbbreviation
          ? timezoneFromBrowserShorter
          : timezoneFromBrowser}
      </TimezoneName>
      <TimezoneWrapper>
        {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element; label: string; position... Remove this comment to see the full error message */}
        <Tooltip label="Time zone detected by your browser" position="top">
          <Info color={gray} />
        </Tooltip>
      </TimezoneWrapper>
    </Container>
  )
}

export default Timezone
