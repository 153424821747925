import styled from 'styled-components'
import { grayDark } from '@bufferapp/ui/style/colors'
import { fontFamily } from '@bufferapp/ui/style/fonts'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 24px 0px;
  gap: 16px;
`

export const Message = styled.p`
  color: ${grayDark};
  font-family: ${fontFamily};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  padding: 0;
  margin: 0;
`
