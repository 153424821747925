import type ValidationResults from '~publish/legacy/validation/ValidationResults'
import type { Draft } from '~publish/legacy/composer/composer/entities/Draft'
import BaseValidator from '~publish/legacy/composer/composer/lib/validation/BaseValidator'
import FacebookValidator from '~publish/legacy/composer/composer/lib/validation/FacebookValidator'
import GoogleBusinessValidator from '~publish/legacy/composer/composer/lib/validation/GoogleBusinessValidator'
import InstagramValidator from '~publish/legacy/composer/composer/lib/validation/InstagramValidator'
import LinkedInValidator from '~publish/legacy/composer/composer/lib/validation/LinkedInValidator'
import PinterestValidator from '~publish/legacy/composer/composer/lib/validation/PinterestValidator'
import TiktokValidator from '~publish/legacy/composer/composer/lib/validation/TiktokValidator'
import TwitterValidator from '~publish/legacy/composer/composer/lib/validation/TwitterValidator'
import YoutubeValidator from '~publish/legacy/composer/composer/lib/validation/YoutubeValidator'
import MastodonValidator from './MastodonValidator'
import StartpageValidator from '~publish/legacy/composer/composer/lib/validation/StartpageValidator'
import ThreadsValidator from '~publish/legacy/composer/composer/lib/validation/ThreadsValidator'
import BlueskyValidator from '~publish/legacy/composer/composer/lib/validation/BlueskyValidator'

const getValidator = (
  draft: Draft,
  isDraft: boolean,
  activeThreadId = 0,
): BaseValidator => {
  let validator: BaseValidator

  if (draft.service.isPinterest()) {
    validator = new PinterestValidator(draft, isDraft)
  } else if (draft.service.isInstagram()) {
    validator = new InstagramValidator(draft, isDraft)
  } else if (draft.service.isGoogleBusiness()) {
    validator = new GoogleBusinessValidator(draft, isDraft)
  } else if (draft.service.isTwitter()) {
    validator = new TwitterValidator(draft, isDraft, activeThreadId)
  } else if (draft.service.isFacebook()) {
    validator = new FacebookValidator(draft, isDraft)
  } else if (draft.service.isLinkedin()) {
    validator = new LinkedInValidator(draft, isDraft)
  } else if (draft.service.isTiktok()) {
    validator = new TiktokValidator(draft, isDraft)
  } else if (draft.service.isMastodon()) {
    validator = new MastodonValidator(draft, isDraft, activeThreadId)
  } else if (draft.service.isYoutube()) {
    validator = new YoutubeValidator(draft, isDraft)
  } else if (draft.service.isStartPage()) {
    validator = new StartpageValidator(draft, isDraft)
  } else if (draft.service.isThreads()) {
    validator = new ThreadsValidator(draft, isDraft, activeThreadId)
  } else if (draft.service.isBluesky()) {
    validator = new BlueskyValidator(draft, isDraft, activeThreadId)
  } else {
    validator = new BaseValidator(draft, isDraft)
  }

  return validator
}

const validate = (
  draft: Draft,
  isDraft: boolean,
  activeThreadId = 0,
): ValidationResults => {
  return getValidator(draft, isDraft, activeThreadId).validate()
}

export { validate, getValidator }
