import { Button } from '@buffer-mono/popcorn'
import React from 'react'
import { profileChildTabPages } from '~publish/legacy/routes'
import { usePostData } from './PostCardContext'
import { PostCardNotice } from './PostCardNotice'

export const PostCardPausedQueueNotice = (): JSX.Element | null => {
  const { channel, status } = usePostData()

  if (!channel.isQueuePaused || status === 'sent') {
    return null
  }

  return (
    <PostCardNotice variant="warning">
      This post&apos;s channel queue is paused. Adjust the channel settings to
      resume.
      <PostCardNotice.Action>
        <Button
          variant="warning"
          size="small"
          as="a"
          href={profileChildTabPages.getRoute({
            profileId: channel.id,
            tabId: 'settings',
            childTabId: 'postingSchedule',
          })}
          rel="noopener noreferrer"
          target="_blank"
        >
          Resume Queue
        </Button>
      </PostCardNotice.Action>
    </PostCardNotice>
  )
}

PostCardPausedQueueNotice.displayName = 'PostCardPausedQueueNotice'
