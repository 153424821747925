import { useDispatch } from 'react-redux'
import { CampaignTab } from '~publish/legacy/campaign/types'
import { tagTab } from '~publish/legacy/routes'
import PostTag from '~publish/legacy/shared-components/PostTag'
import { PostTagsWrapper } from './style'
import React from 'react'

type PostTagsProp = {
  tags: Array<{
    id: string
    name: string
    color: string
  }>
  dragging: boolean
  isSent: boolean
}

const PostTags = ({
  tags,
  dragging,
  isSent,
}: PostTagsProp): JSX.Element | null => {
  const dispatch = useDispatch()

  const handleTagClick = (id: string): void => {
    const tab = isSent ? CampaignTab.Sent : CampaignTab.Scheduled
    dispatch(tagTab.goTo({ tagId: id, tab }))
  }

  const postTags = tags.map((tag) => (
    <PostTag
      key={tag.id}
      name={tag.name}
      color={tag.color}
      dragging={dragging}
      onPostTagClick={(): void => handleTagClick(tag.id)}
      isTag
    />
  ))
  return <PostTagsWrapper>{postTags}</PostTagsWrapper>
}

export default PostTags
