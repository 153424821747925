import { isElement, type TNode } from '@udecode/plate'
import type { SerializedNode } from '~publish/legacy/editor/BufferEditor/types'
import type { PlainText } from '~publish/legacy/editor/BufferEditor/types.plate'
import type { WithIndices } from '~publish/legacy/editor/helpers/WithPosition'
import type { AutocompleteData } from '../../autocomplete'
import type {
  AutocompleteInputElement,
  AutocompleteInputElementInterface,
} from '../../autocomplete/nodes'

export const FACEBOOK_MENTION_INPUT_ELEMENT_TYPE = 'facebook-mention-input'
export const FACEBOOK_MENTION_INPUT_ELEMENT_TRIGGER = '@'
export const FACEBOOK_MENTION_INPUT_VALID_TEXT_REGEX = /([a-zA-Z0-9 ._-]+)/

/**
 * The detail properties of a facebook mention entity
 * as it's stored in the db and returned from our backend.
 * It is extended below to include the indices of the entity.
 */
export type FacebookMentionDbEntryDetails = {
  content: string
  text: string
  url: string
}

export type FacebookMentionDbEntry = WithIndices<FacebookMentionDbEntryDetails>

/**
 * The detail properties of a facebook mention entity
 * after it's been parsed when loading the composer.
 * It is extended below to include the indices of the entity.
 */
export interface FacebookMentionEntityDetails extends AutocompleteData {
  id: string
  name: string
  url: string
}

export type FacebookMentionEntity = WithIndices<FacebookMentionEntityDetails>

export interface FacebookMentionInputElement extends AutocompleteInputElement {
  type: typeof FACEBOOK_MENTION_INPUT_ELEMENT_TYPE
  trigger: typeof FACEBOOK_MENTION_INPUT_ELEMENT_TRIGGER
  children: [PlainText]
  valid: boolean
  autocompleted: boolean
  data?: FacebookMentionEntityDetails
}

export type FacebookMentionInputElementInterface = Required<
  AutocompleteInputElementInterface<FacebookMentionInputElement>
>

export const FacebookMentionInputElement: FacebookMentionInputElementInterface =
  {
    type: FACEBOOK_MENTION_INPUT_ELEMENT_TYPE,
    trigger: FACEBOOK_MENTION_INPUT_ELEMENT_TRIGGER,
    validTextRegex: FACEBOOK_MENTION_INPUT_VALID_TEXT_REGEX,
    validMentionRegex: RegExp(
      `(${FACEBOOK_MENTION_INPUT_ELEMENT_TRIGGER}|)${FACEBOOK_MENTION_INPUT_VALID_TEXT_REGEX.source}`,
    ),
    is(node: TNode): node is FacebookMentionInputElement {
      return isElement(node) && node.type === FacebookMentionInputElement.type
    },
    new: (text = '', data) => {
      return {
        type: FacebookMentionInputElement.type,
        children: [
          {
            text: data ? text : `${FacebookMentionInputElement.trigger}${text}`,
          },
        ],
        // If data is provided, the mention is autocompleted and valid
        // This is because all Facebook mentions are invalid until autocompleted
        valid: !!data,
        autocompleted: !!data,
        trigger: FacebookMentionInputElement.trigger,
        data,
      }
    },
    toEntity(
      node: FacebookMentionInputElement,
    ): FacebookMentionEntityDetails | undefined {
      if (node?.data) {
        const { id, name, url } = node.data
        return {
          id,
          name,
          url,
        }
      }
    },
    serialize(
      node: FacebookMentionInputElement,
    ): SerializedNode<FacebookMentionEntityDetails | undefined> {
      return {
        text: '',
        data: FacebookMentionInputElement.toEntity(node),
      }
    },
  }
