import React from 'react'

import type { Video } from '~publish/legacy/composer/composer/entities/factories'
import { SERVICE_TIKTOK } from '~publish/legacy/constants'
import { parseTikTokLinks } from '~publish/legacy/utils/channels/tiktok/helpers'
import { parseTextToElements } from '~publish/legacy/utils/parseTextToElements'

import {
  DEFAULT_AVATAR,
  getMediaProperties,
  getPreviewCharLimit,
  textExceedsCharLimit,
  truncateText,
} from '../../../Common/utils'
import type { Content, ProfilePreview } from '../../../types'

import * as Styles from './styles'
import { TikTokAvatar } from './TikTokAvatar'

// @ts-expect-error TS(7006) FIXME: Parameter 'text' implicitly has an 'any' type.
const getText = (text) => {
  const maxLength = getPreviewCharLimit(SERVICE_TIKTOK)

  const truncatedText = truncateText(text, maxLength)
  if (truncatedText) {
    return parseTextToElements(
      truncatedText,
      parseTikTokLinks(truncatedText),
      (element, key) => (
        <Styles.HashtagText key={key} data-testid="tiktok-preview-hashtag">
          {element.displayString}
        </Styles.HashtagText>
      ),
    )
  }
}

const Ellipse = ({
  selected = false,
  size = 'medium',
}: {
  selected?: boolean
  size?: string
}): JSX.Element =>
  selected ? (
    <Styles.EllipseSelected data-testid="ellipse" />
  ) : (
    <Styles.Ellipse data-testid="ellipse" size={size} />
  )

Ellipse.defaultProps = {
  selected: false,
  size: 'medium',
}

const Ellipses = ({
  visible,
  totalImages,
}: {
  visible: boolean
  totalImages: number
}): JSX.Element => {
  const components = [] as JSX.Element[]
  for (let i = 1; i < totalImages; i += 1) {
    components.push(<Ellipse />)
  }
  return (
    <Styles.EllipsesWrapper
      key="ellipses"
      data-testid="ellipses"
      visible={visible}
    >
      <Ellipse selected />
      {components}
    </Styles.EllipsesWrapper>
  )
}

export const TikTokPost = ({
  content,
  profile,
}: {
  content: Content
  profile: ProfilePreview
}): JSX.Element => {
  const { avatar, username } = profile

  const { text, images, video } = content

  const maxLength = getPreviewCharLimit(SERVICE_TIKTOK)
  const textToDisplay = getText(text)

  const { hasImages, hasVideo } = getMediaProperties(content, null)

  return (
    <Styles.PostWrapper data-testid="tiktok-post">
      {hasImages && images && (
        <Styles.ImageWrapper>
          <Styles.Image src={images[0].url} />
        </Styles.ImageWrapper>
      )}

      {hasVideo && <Styles.VideoPlayer video={video as Video} />}
      {hasImages && images && images.length >= 2 && (
        <Styles.EllipsesWrapper visible={true}>
          {/* <Styles.ImagesCarousel images={images} maxHeight="680px" /> */}
          <Ellipses totalImages={images.length} visible={true} />
        </Styles.EllipsesWrapper>
      )}
      <Styles.MetadataWrapper>
        {!hasImages && <Styles.CaptionIcon />}

        <Styles.UsernameWrapper>
          <Styles.Username>
            {username}{' '}
            {hasImages && (
              <Styles.IconImageWrapper>
                <Styles.ImageIcon />{' '}
                <Styles.PhotoLabel>Photo</Styles.PhotoLabel>
              </Styles.IconImageWrapper>
            )}
          </Styles.Username>
        </Styles.UsernameWrapper>
        {text && (
          <Styles.Caption>
            {textToDisplay}
            {text && textExceedsCharLimit(text, maxLength) && (
              <Styles.SeeMore>... more</Styles.SeeMore>
            )}
          </Styles.Caption>
        )}

        <Styles.ExtraData>
          {!hasImages && (
            <Styles.Audio>
              <Styles.Music />
              <Styles.WrappedText>
                {`original sound - ${username}`}
              </Styles.WrappedText>
            </Styles.Audio>
          )}
        </Styles.ExtraData>
      </Styles.MetadataWrapper>
      <Styles.TopBarText>
        <Styles.FollowingText>Following</Styles.FollowingText>
        <Styles.ForYouText>For You</Styles.ForYouText>
        <Styles.SelectedTextBar />
      </Styles.TopBarText>
      <Styles.SearchIcon />
      <Styles.IconsSideSection>
        <TikTokAvatar avatar={avatar} />
        <Styles.HeartIcon />
        <Styles.CommentIcon />
        <Styles.BookmarkIcon />
        <Styles.ShareIcon />
        <Styles.AudioAvatarWrapper>
          <Styles.AudioAvatarBackground />
          <Styles.AudioAvatar
            data-testid="avatar"
            src={avatar}
            fallbackUrl={DEFAULT_AVATAR}
          />
        </Styles.AudioAvatarWrapper>
      </Styles.IconsSideSection>
    </Styles.PostWrapper>
  )
}
