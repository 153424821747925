import type { DocumentNode } from 'graphql'
import { gql } from '~publish/legacy/apollo-client'

type TContentGenerationMutationInput = {
  organizationId: string
  config: {
    prompt: string
    cta: string
    service?: string
    tone?: string
    previousGeneration?: string
  }
}

type TContentGenerationMutationResult = {
  suggestions?: { content: string }[]
  code?: string
}

type TContentGenerationMutationError = {
  code?: string
  success?: boolean
  message?: string
  error?: string
}

type TContentGenerationMutationResponse =
  | TContentGenerationMutationResult
  | TContentGenerationMutationError

/**
 *
 */
function getContentGenerationMutation(mutationName: string): DocumentNode {
  return gql(`
    mutation ${mutationName}(
      $organizationId: String!
      $config: ContentGenerationInput!
    ) {
      ${mutationName}(organizationId: $organizationId, config: $config) {
        ... on TextGenerated {
          suggestions {
            content
          }
        }
        ... on CommonError {
          code
          success
          message
          error
        }
      }
    }
  `)
}

export {
  TContentGenerationMutationInput,
  TContentGenerationMutationResult,
  TContentGenerationMutationError,
  TContentGenerationMutationResponse,
  getContentGenerationMutation,
}
