import type { VALIDATION_CODE } from './constants'
import type { MessageOptions } from './types'
import type ValidationFail from './ValidationFail'

// FIXME: duplicated from src/composer/composer/stores/types
export interface ValidationFeedback {
  message: string
  code: keyof typeof VALIDATION_CODE
  messageOptions?: MessageOptions
  composerId: string
  metadata?: Record<string, unknown>
}

class ValidationResults {
  results: ValidationFail[]
  constructor(results: ValidationFail[]) {
    this.results = results
  }

  getErrorMessages(): string[] {
    return this.results.map((validation) => validation.message)
  }

  toObject(): ValidationFeedback[] {
    return this.results.map((validation) => {
      return {
        ...validation,
        composerId: validation.composerId ?? '',
      }
    }, [])
  }

  isInvalid(): boolean {
    return !this.isValid()
  }

  isValid(): boolean {
    return this.results.length === 0
  }
}

export default ValidationResults
