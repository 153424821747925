import styled from 'styled-components'

const Select = styled.select`
  color: inherit;
  font: inherit;
  margin: 0;
  text-transform: none;
`

export default Select
