import { blue, blueDark, grayLight } from '@bufferapp/ui/style/colors'
import { fontFamily } from '@bufferapp/ui/style/fonts'
import styled from 'styled-components'

export const UpgradePathNotesContainer = styled.div`
  background-color: rgba(213, 219, 255, 0.2);
  border-top: 1px solid ${grayLight};
  padding: 0px 16px;
  margin-top: 10px;
  border-radius: 0 0 8px 8px;
  display: flex;
  gap: 8px;
`

export const Button = styled.button`
  cursor: pointer;
  border: 0;
  background: none;
  color: ${blue};
  margin-right: -2px;
  padding-left: 0px;
  font-family: ${fontFamily};
  font-size: 14px;

  &:hover {
    color: ${blueDark};
  }

  &:focus {
    outline: none;
  }
`
