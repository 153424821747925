import React from 'react'

import type {
  Image,
  Video,
} from '~publish/legacy/composer/composer/entities/factories'

import type { InstagramContent, ProfilePreview } from '../../../types'
import { DEFAULT_AVATAR } from '../../../Common/utils'

import * as Styles from './styles'
import * as StylesInstagram from '../../styles'
import { PostFields } from '@buffer-mono/reminders-config'

import { MusicStickerIcon } from './components/MusicStickerIcon'

const ImagePreview = ({ image }: { image: Image }): JSX.Element => (
  <Styles.ImageWrapper data-testid="preview-images">
    <Styles.Image src={image.url} />
  </Styles.ImageWrapper>
)

const Story = ({
  content,
  profile,
}: {
  content: InstagramContent
  profile: ProfilePreview
}): JSX.Element => {
  const { avatar, username } = profile
  const { video, images, gif } = content
  const gifs = gif ? ([{ ...gif }] as Image[]) : []
  const allImages = images?.length ? images : gifs
  const hasImage = allImages && allImages.length > 0

  const stickerLink =
    content.isReminder && content.selectedStickers?.includes(PostFields.LINK)
      ? content?.channelData?.instagram?.link
      : ''
  const stickerMusic =
    content.isReminder && content.selectedStickers?.includes(PostFields.MUSIC)
      ? content?.channelData?.instagram?.music
      : ''
  const stickerText =
    content.isReminder && content.selectedStickers?.includes(PostFields.TEXT)
      ? content?.channelData?.instagram?.text
      : ''

  return (
    <Styles.PostWrapper data-testid="story">
      {stickerText && (
        <StylesInstagram.TextStickerWrapper>
          <StylesInstagram.TextStickerContent>
            {stickerText}
          </StylesInstagram.TextStickerContent>
        </StylesInstagram.TextStickerWrapper>
      )}

      {stickerLink && (
        <Styles.StickerLinkWrapper>
          <img
            src="https://buffer-publish.s3.amazonaws.com/images/instagram_link_sticker.png"
            alt="Instagram link sticker preview"
          />
        </Styles.StickerLinkWrapper>
      )}

      {video && <Styles.VideoPlayer video={video as Video} />}
      {hasImage && <ImagePreview image={allImages[0] as Image} />}
      <Styles.Header>
        <Styles.BarsWrapper>
          <Styles.Bar />
          <Styles.BarTransparent />
        </Styles.BarsWrapper>
        <Styles.PreviewHeaderWrapper>
          <Styles.HeaderDetailsWrapper>
            <StylesInstagram.Avatar
              data-testid="avatar"
              src={avatar}
              fallbackUrl={DEFAULT_AVATAR}
            />
            <Styles.DetailsWrapper>
              <Styles.UsernameTimeWrapper>
                <Styles.Username>{username}</Styles.Username>
                <Styles.Time>21h</Styles.Time>
              </Styles.UsernameTimeWrapper>
              {stickerMusic && (
                <Styles.Music>
                  <MusicStickerIcon /> Music
                </Styles.Music>
              )}
            </Styles.DetailsWrapper>
            <Styles.HeaderVolumeWrapper>
              <Styles.VolumeOff visible={!!video} />
            </Styles.HeaderVolumeWrapper>
          </Styles.HeaderDetailsWrapper>
        </Styles.PreviewHeaderWrapper>
      </Styles.Header>
      <Styles.Footer>
        <Styles.Field>Send message</Styles.Field>
        <StylesInstagram.HeartIcon />
        <StylesInstagram.MessageIcon />
      </Styles.Footer>
    </Styles.PostWrapper>
  )
}

export default Story
