import { PostValidationErrorMessage } from '~publish/legacy/constants'
import { getDateString } from '~publish/legacy/duplicate-server/formatters'
import { PostEntity } from './PostEntity'
import type { RpcUpdate } from './types'
import type { PublishingPost } from '~publish/gql/graphql'

export const getPostValidationMessage = ({
  post,
  userNeedsApproval,
}: {
  post: RpcUpdate | PublishingPost
  userNeedsApproval: boolean
}): string | undefined => {
  const validationError = PostEntity.validatePost(post)
  if (!validationError) return

  const baseMessageSuffix = `before you ${
    userNeedsApproval ? 'request approval for' : 'schedule'
  } this post`
  const errorMessage = PostValidationErrorMessage[validationError]
  if (!errorMessage) {
    return validationError
  }
  return `${errorMessage} ${baseMessageSuffix}`
}

const getPostActionStringForSentPost = (
  post: RpcUpdate | PublishingPost,
  profileTimezone: string | undefined,
  twentyFourHourTime: boolean,
): string | undefined => {
  const dueAt = PostEntity.getDueAt(post)
  // We don't have an effective way to determine if an old post is a reminder
  // For new ones, we can use is_reminder field. For the old ones, if they were sent, their status is 'notified (isPastReminder)'
  const isReminder = PostEntity.isPastReminder(post)
  const isRepin = PostEntity.isRepin(post)
  if (dueAt) {
    const dateString = getDateString(dueAt, profileTimezone, {
      twentyFourHourTime,
    })

    if (isReminder) {
      return dateString.startsWith('today')
        ? `Notification sent ${dateString}.`
        : `Notification sent on ${dateString}.`
    }
    if (post.via) {
      let via = post.via
      if (via === 'Twitter') {
        via = 'Twitter / X'
      }
      const dateString = getDateString(dueAt, profileTimezone, {
        twentyFourHourTime,
      })
      const verb = isRepin ? 'Pinned' : 'Shared'

      if (dateString.includes('today')) {
        return `${verb} via ${via} ${dateString}`
      } else {
        return `${verb} via ${via} on ${dateString}`
      }
    }
    return `Shared on ${dateString}`
  }
}

const getPostType = (isDraft: boolean, isReminder: boolean): string => {
  if (isReminder) {
    return 'Notification'
  }
  if (isDraft) {
    return 'Draft'
  }
  return 'Post'
}

const getPostActionStringForUnsentPost = (
  post: RpcUpdate | PublishingPost,
  profileTimezone: string | undefined,
  twentyFourHourTime: boolean,
  isReminder: boolean,
): string | undefined => {
  const isPastDue = PostEntity.isPastDue(post)
  const isDraft = PostEntity.isDraft(post)
  const dueAt = PostEntity.getDueAt(post)

  if (dueAt) {
    const dateString = getDateString(dueAt, profileTimezone, {
      isPastDue,
      twentyFourHourTime,
    })
    const postType = getPostType(isDraft, isReminder)
    return `${postType} scheduled for ${dateString}.`
  }
}

interface GetPostActionStringInput {
  post: RpcUpdate | PublishingPost | undefined
  profileTimezone: string | undefined
  twentyFourHourTime: boolean
  isReminder: boolean
}
export const getPostActionString = ({
  post,
  profileTimezone,
  twentyFourHourTime,
  isReminder,
}: GetPostActionStringInput): string | undefined => {
  if (!post) {
    return
  }
  /* isSent emthod only can be used for posts, to know if a reminder was sent we have to use isPastReminder */
  if (PostEntity.isSent(post) || PostEntity.isPastReminder(post)) {
    return getPostActionStringForSentPost(
      post,
      profileTimezone,
      twentyFourHourTime,
    )
  } else {
    return getPostActionStringForUnsentPost(
      post,
      profileTimezone,
      twentyFourHourTime,
      isReminder,
    )
  }
}
