import styled from 'styled-components'

import { grayDark } from '@bufferapp/ui/style/colors'

export const HeadingWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  color: ${grayDark};

  svg {
    margin-right: 6px;
  }
`

export const Label = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  font-size: 14px;
  margin: 16px 0;
  color: ${grayDark};

  input {
    margin-right: 6px;
  }
`

export const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`
