import {
  createComboboxPlugin,
  createPlateEditor,
  type CreatePlateEditorOptions,
  createPlugins as createPlatePlugins,
  type PlatePlugin,
} from '@udecode/plate'
import {
  SERVICE_BLUESKY,
  SERVICE_FACEBOOK,
  SERVICE_GOOGLEBUSINESS,
  SERVICE_INSTAGRAM,
  SERVICE_LINKEDIN,
  SERVICE_MASTODON,
  SERVICE_PINTEREST,
  SERVICE_STARTPAGE,
  SERVICE_THREADS,
  SERVICE_TIKTOK,
  SERVICE_TWITTER,
  SERVICE_YOUTUBE,
} from '~publish/legacy/constants'
import type { ServiceDefinition } from '~publish/legacy/constants/services/types'
import type { BufferEditorOptions } from '~publish/legacy/editor/BufferEditor/types'
import type {
  BufferEditor,
  BufferValue,
} from '~publish/legacy/editor/BufferEditor/types.plate'
import { createCharacterLimitHighlightPlugin } from '~publish/legacy/editor/plugins/character-limit-highlight'
import { createEmojiSearchPlugin } from '~publish/legacy/editor/plugins/emoji'
import { createErrorReportingPlugin } from '~publish/legacy/editor/plugins/error-reporting/createErrorReportingPlugin'
import { createFacebookMentionPlugin } from '~publish/legacy/editor/plugins/facebook-mention'
import { createLinkPlugin } from '~publish/legacy/editor/plugins/link'
import { createLinkedInAnnotationsPlugin } from '~publish/legacy/editor/plugins/linkedin-annotations/createLinkedInAnnotationsPlugin'
import { createContentGenerationPlugin } from '~publish/legacy/ai/plugins/content-generation'
import { createGenerateContentPlugin } from '~publish/legacy/editor/plugins/generate-content'
import { createNewThreadPostPlugin } from '~publish/legacy/editor/plugins/createNewThreadPlugin'
import { createTextSyncPlugin } from '~publish/legacy/editor/plugins/createTextSyncPlugin'
import type { BufferPluginOptions } from '~publish/legacy/editor/plugins/types'

const disableCorePlugins = {
  deserializeHtml: true,
}

const createPlugins = (
  service: ServiceDefinition,
  plugins: PlatePlugin<BufferPluginOptions, BufferValue, BufferEditor>[] = [],
): PlatePlugin<BufferPluginOptions, BufferValue, BufferEditor>[] => {
  return createPlatePlugins<BufferValue, BufferEditor>([
    createComboboxPlugin(),
    createEmojiSearchPlugin(),
    createCharacterLimitHighlightPlugin(),
    createGenerateContentPlugin(),
    createContentGenerationPlugin(),
    createLinkPlugin(),
    ...plugins,
    createErrorReportingPlugin(),
    createTextSyncPlugin(),
    ...(service.supportsThread ? [createNewThreadPostPlugin()] : []),
  ])
}

export const createTwitterEditor = (
  service: ServiceDefinition,
  options: CreatePlateEditorOptions<BufferValue, BufferEditor> = {},
): BufferEditor => {
  return createPlateEditor<BufferValue, BufferEditor>({
    id: SERVICE_TWITTER,
    disableCorePlugins,
    plugins: createPlugins(service),
    ...options,
  })
}

export const createInstagramEditor = (
  service: ServiceDefinition,
  options: CreatePlateEditorOptions<BufferValue, BufferEditor> = {},
): BufferEditor => {
  return createPlateEditor<BufferValue, BufferEditor>({
    id: SERVICE_INSTAGRAM,
    disableCorePlugins,
    plugins: createPlugins(service),
    ...options,
  })
}

export const createLinkedInEditor = (
  service: ServiceDefinition,
  options: CreatePlateEditorOptions<BufferValue, BufferEditor> = {},
): BufferEditor => {
  return createPlateEditor<BufferValue, BufferEditor>({
    id: SERVICE_LINKEDIN,
    disableCorePlugins,
    plugins: createPlugins(service, [createLinkedInAnnotationsPlugin()]),
    ...options,
  })
}

export const createFacebookEditor = (
  service: ServiceDefinition,
  options: CreatePlateEditorOptions<BufferValue, BufferEditor> = {},
): BufferEditor => {
  return createPlateEditor<BufferValue, BufferEditor>({
    id: SERVICE_FACEBOOK,
    disableCorePlugins,
    plugins: createPlugins(service, [createFacebookMentionPlugin()]),
    ...options,
  })
}

export const createPinterestEditor = (
  service: ServiceDefinition,
  options: CreatePlateEditorOptions<BufferValue, BufferEditor> = {},
): BufferEditor => {
  return createPlateEditor<BufferValue, BufferEditor>({
    id: SERVICE_PINTEREST,
    disableCorePlugins,
    plugins: createPlugins(service),
    ...options,
  })
}

export const createMastodonEditor = (
  service: ServiceDefinition,
  options: CreatePlateEditorOptions<BufferValue, BufferEditor> = {},
): BufferEditor => {
  return createPlateEditor<BufferValue, BufferEditor>({
    id: SERVICE_MASTODON,
    disableCorePlugins,
    plugins: createPlugins(service),
    ...options,
  })
}

export const createYoutubeEditor = (
  service: ServiceDefinition,
  options: CreatePlateEditorOptions<BufferValue, BufferEditor> = {},
): BufferEditor => {
  return createPlateEditor<BufferValue, BufferEditor>({
    id: SERVICE_YOUTUBE,
    disableCorePlugins,
    plugins: createPlugins(service),
    ...options,
  })
}

export const createThreadsEditor = (
  service: ServiceDefinition,
  options: CreatePlateEditorOptions<BufferValue, BufferEditor> = {},
): BufferEditor => {
  return createPlateEditor<BufferValue, BufferEditor>({
    id: SERVICE_THREADS,
    disableCorePlugins,
    plugins: createPlugins(service),
    ...options,
  })
}

export const createBlueskyEditor = (
  service: ServiceDefinition,
  options: CreatePlateEditorOptions<BufferValue, BufferEditor> = {},
): BufferEditor => {
  return createPlateEditor<BufferValue, BufferEditor>({
    id: SERVICE_BLUESKY,
    disableCorePlugins,
    plugins: createPlugins(service),
    ...options,
  })
}

export const createTiktokEditor = (
  service: ServiceDefinition,
  options: CreatePlateEditorOptions<BufferValue, BufferEditor> = {},
): BufferEditor => {
  return createPlateEditor<BufferValue, BufferEditor>({
    id: SERVICE_TIKTOK,
    disableCorePlugins,
    plugins: createPlugins(service),
    ...options,
  })
}

export const createGooglebusinessEditor = (
  service: ServiceDefinition,
  options: CreatePlateEditorOptions<BufferValue, BufferEditor> = {},
): BufferEditor => {
  return createPlateEditor<BufferValue, BufferEditor>({
    id: SERVICE_GOOGLEBUSINESS,
    disableCorePlugins,
    plugins: createPlugins(service),
    ...options,
  })
}

export const createStartPageEditor = (
  service: ServiceDefinition,
  options: CreatePlateEditorOptions<BufferValue, BufferEditor> = {},
): BufferEditor => {
  return createPlateEditor<BufferValue, BufferEditor>({
    id: SERVICE_STARTPAGE,
    disableCorePlugins,
    plugins: createPlugins(service),
    ...options,
  })
}

export const createOmniEditor = (
  service: ServiceDefinition,
  options: CreatePlateEditorOptions<BufferValue, BufferEditor> = {},
): BufferEditor => {
  return createPlateEditor<BufferValue, BufferEditor>({
    id: 'omni',
    disableCorePlugins,
    plugins: createPlugins(service),
    ...options,
  })
}

export const createEditorForService = (
  service: ServiceDefinition,
  options?: BufferEditorOptions,
): BufferEditor => {
  switch (service.name) {
    case SERVICE_TWITTER:
      return createTwitterEditor(service, options)
    case SERVICE_INSTAGRAM:
      return createInstagramEditor(service, options)
    case SERVICE_LINKEDIN:
      return createLinkedInEditor(service, options)
    case SERVICE_FACEBOOK:
      return createFacebookEditor(service, options)
    case SERVICE_PINTEREST:
      return createPinterestEditor(service, options)
    case SERVICE_TIKTOK:
      return createTiktokEditor(service, options)
    case SERVICE_GOOGLEBUSINESS:
      return createGooglebusinessEditor(service, options)
    case SERVICE_STARTPAGE:
      return createStartPageEditor(service, options)
    case SERVICE_MASTODON:
      return createMastodonEditor(service, options)
    case SERVICE_YOUTUBE:
      return createYoutubeEditor(service, options)
    case SERVICE_THREADS:
      return createThreadsEditor(service, options)
    case SERVICE_BLUESKY:
      return createBlueskyEditor(service, options)
    case 'omni':
      return createOmniEditor(service, options)
    default:
      throw new Error(
        `Invalid service: Could not create editor for service: ${service?.name}`,
      )
  }
}

export const createNewEditor = (
  service: ServiceDefinition,
  options?: BufferEditorOptions,
): BufferEditor => {
  return createEditorForService(service, options)
}
