import { selectHasApprovalFeature } from '~publish/legacy/organizations/selectors'

import { useAppSelector } from '~publish/legacy/store'
import type { PublishingPost } from '~publish/gql/graphql'
import {
  type RpcUpdate,
  type PostAction,
  PostActionButtonType,
  PostActionLabel,
  PostActionType,
  PostActionTooltipMessage,
  type UncheckedPostAction,
} from '~publish/legacy/post/types'
import { getPostValidationMessage } from '../../helpers'
import { usePostPermissions } from '../usePostPermissions'
import { PostEntity } from '../../PostEntity'
import { isValidPostAction, reducePostActionsByType } from './utils'
import { SERVICES_WITHOUT_SHARE_AGAIN_ACTION } from '~publish/legacy/constants'

export type ExecutePostActionById = (args: { id: string }) => void

export type usePostActionsProps = {
  post: PublishingPost | RpcUpdate | undefined
  onRescheduleClick?: () => void
  onRequestApprovalClick?: () => void
  onRevertApprovalClick?: () => void
  onApproveClick?: () => void
  onRequeueClick?: () => void
  onMovePostToDraftsClick?: () => void
  onSharePostNowClick?: () => void
  onShareLinkClick?: () => void
  onShareAgainClick?: () => void
  onViewPostClick?: () => void
}

export type usePostActionsResponse = {
  singleActions: PostAction[]
  stackedActions: PostAction[]
  executePostActionById: ExecutePostActionById
}

/**
 * Return an object containing arrays of valid post actions
 * that can be performed in the given context.
 *
 * It will exclude actions based on the conditions provided below per action,
 * as well as whether a callback for the given action has been provided.
 *
 * The callback filter will likely be deprecated, but is currently still
 * useful while we work with different post types that have different
 * state management.
 *
 * The provided post is validated and the validation is used when calculating
 * valid posts.
 * It's important to note whether an action should be disabled by validation or not.
 * eg:
 * - Approval should be blocked by validation as it deals with scheduling posts.
 * - Moving a post to drafts does not have to be blocked by validation.
 *
 * @returns {
 *    singleActions: An array of PostActions that will be rendered separately
 *    stackedActions: An array of PostActions that will be rendered in a single, stacked Select Button
 * }
 */
export const usePostActions = ({
  post,
  onRescheduleClick,
  onRequestApprovalClick,
  onRevertApprovalClick,
  onApproveClick,
  onRequeueClick,
  onMovePostToDraftsClick,
  onSharePostNowClick,
  onShareLinkClick,
  onViewPostClick,
}: usePostActionsProps): usePostActionsResponse => {
  const hasApprovalFeature = useAppSelector(selectHasApprovalFeature)
  const { isManager, isPostOwner } = usePostPermissions(post)

  if (!post)
    return {
      singleActions: [],
      stackedActions: [],
      executePostActionById: () => undefined,
    }

  const validationMessage = getPostValidationMessage({
    post,
    userNeedsApproval: hasApprovalFeature && !isManager && isPostOwner,
  })

  const isScheduled = PostEntity.isScheduled(post)
  const isPastDue = PostEntity.isPastDue(post)
  const isDraft = PostEntity.isDraft(post)
  const isSent = PostEntity.isSent(post)
  const needsApproval = PostEntity.needsApproval(post)
  const channel = PostEntity.getChannel(post)
  const hasLink = PostEntity.hasServiceLink(post)
  const hasError = PostEntity.hasError(post)

  const shareAgainSupported = PostEntity.isShareAgainActionSupported({
    post,
    channelName: channel?.name,
  })
  const shareLinkSupported =
    !!channel?.name &&
    SERVICES_WITHOUT_SHARE_AGAIN_ACTION.includes(channel.name)

  const postActions: UncheckedPostAction[] = [
    {
      id: PostActionType.RESCHEDULE,
      title: PostActionLabel.RESCHEDULE,
      type: PostActionButtonType.SINGLE,
      tooltip: PostActionTooltipMessage.RESCHEDULE,
      callback: onRescheduleClick,
      condition:
        (isManager || isPostOwner) && isScheduled && isPastDue && !isSent,
      disabled: false,
    },
    {
      id: PostActionType.ADD_TO_QUEUE,
      title: PostActionLabel.ADD_TO_QUEUE,
      type: PostActionButtonType.SINGLE,
      tooltip: validationMessage || PostActionTooltipMessage.ADD_TO_QUEUE,
      callback: onApproveClick,
      condition:
        isManager && isDraft && !isScheduled && !needsApproval && !isSent,
      disabled: !!validationMessage,
    },
    {
      id: PostActionType.SCHEDULE_POST,
      title: PostActionLabel.SCHEDULE_POST,
      type: PostActionButtonType.SINGLE,
      tooltip: validationMessage || PostActionTooltipMessage.SCHEDULE_POST,
      callback: onApproveClick,
      condition:
        isManager &&
        isScheduled &&
        isDraft &&
        !needsApproval &&
        !isPastDue &&
        !isSent,
      disabled: !!validationMessage,
    },
    {
      id: PostActionType.REQUEST_APPROVAL,
      title: PostActionLabel.REQUEST_APPROVAL,
      type: PostActionButtonType.SINGLE,
      tooltip: validationMessage || PostActionTooltipMessage.REQUEST_APPROVAL,
      callback: onRequestApprovalClick,
      condition:
        !isManager &&
        isPostOwner &&
        hasApprovalFeature &&
        isDraft &&
        !needsApproval &&
        !isPastDue &&
        !isSent,
      disabled: !!validationMessage,
    },
    {
      id: PostActionType.APPROVE,
      title: PostActionLabel.APPROVE,
      type: PostActionButtonType.SINGLE,
      tooltip: validationMessage || PostActionTooltipMessage.APPROVE,
      callback: onApproveClick,
      condition:
        isManager &&
        hasApprovalFeature &&
        isDraft &&
        needsApproval &&
        !isPastDue &&
        !isSent,
      disabled: !!validationMessage,
    },
    {
      id: PostActionType.REJECT,
      title: PostActionLabel.REJECT,
      type: PostActionButtonType.SINGLE,
      tooltip: PostActionTooltipMessage.REJECT,
      callback: onRevertApprovalClick,
      condition:
        isManager && hasApprovalFeature && isDraft && needsApproval && !isSent,
      disabled: false,
    },
    {
      id: PostActionType.REVERT_TO_DRAFT,
      title: PostActionLabel.REVERT_TO_DRAFT,
      type: PostActionButtonType.SINGLE,
      tooltip: validationMessage || PostActionTooltipMessage.REVERT_TO_DRAFT,
      callback: onRevertApprovalClick,
      condition:
        !isManager &&
        isPostOwner &&
        hasApprovalFeature &&
        isDraft &&
        needsApproval &&
        !isSent,
      disabled: !!validationMessage,
    },
    {
      id: PostActionType.SHARE_NOW,
      title: hasError ? PostActionLabel.RETRY_NOW : PostActionLabel.SHARE_NOW,
      type: PostActionButtonType.STACKED,
      tooltip: validationMessage || PostActionTooltipMessage.SHARE_NOW,
      callback: onSharePostNowClick,
      condition: isManager && !isDraft && !isSent,
      disabled: !!validationMessage,
    },
    {
      id: PostActionType.REQUEUE,
      title: PostActionLabel.REQUEUE,
      type: PostActionButtonType.STACKED,
      tooltip: validationMessage || PostActionTooltipMessage.REQUEUE,
      callback: onRequeueClick,
      condition: isManager && !isDraft && !isSent && hasError,
      disabled: false,
    },
    {
      id: PostActionType.MOVE_POST_TO_DRAFTS,
      title: PostActionLabel.MOVE_POST_TO_DRAFTS,
      type: PostActionButtonType.STACKED,
      tooltip: PostActionTooltipMessage.MOVE_POST_TO_DRAFTS,
      callback: onMovePostToDraftsClick,
      condition: isManager && !isDraft && !isSent,
      disabled: false,
    },
    {
      id: PostActionType.SHARE_LINK,
      title: PostActionLabel.SHARE_LINK,
      type: PostActionButtonType.STACKED,
      tooltip: PostActionTooltipMessage.SHARE_LINK,
      callback: onShareLinkClick,
      condition: isSent && hasLink && shareLinkSupported,
      disabled: false,
    },
    {
      id: PostActionType.VIEW_POST,
      title: PostActionLabel.VIEW_POST,
      type: PostActionButtonType.STACKED,
      tooltip: PostActionTooltipMessage.VIEW_POST,
      callback: onViewPostClick,
      condition: isSent && !shareAgainSupported,
      disabled: false,
    },
  ]

  const validPostActions = postActions.filter(isValidPostAction)

  const executePostActionById: ExecutePostActionById = ({ id }) => {
    const selectedAction = validPostActions.find((action) => action.id === id)
    if (selectedAction) selectedAction.callback()
  }

  const sortedPostActions = validPostActions.reduce(reducePostActionsByType, {
    singleActions: [] as PostAction[],
    stackedActions: [] as PostAction[],
  })

  // Filter out actions with failed conditions
  // and reduce actions to expected structure
  return {
    ...sortedPostActions,
    executePostActionById,
  }
}
