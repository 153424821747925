import { insertText, setNodes, withoutNormalizing } from '@udecode/plate'
import type { NodeEntry } from 'slate'
import { withoutLosingSelection } from '~publish/legacy/editor/helpers/withoutLosingSelection'
import { LinkElement } from '../nodes/LinkElement'

export const toggleShortening = (
  // @ts-expect-error TS(7006) FIXME: Parameter 'editor' implicitly has an 'any' type.
  editor,
  [node, path]: NodeEntry<LinkElement>,
) => {
  withoutLosingSelection(editor, () => {
    withoutNormalizing(editor, () => {
      const newNode = {
        ...node,
        isShortened: node.shortUrl ? !node.isShortened : false,
      }
      setNodes(editor, newNode, { at: path })
      insertText(editor, LinkElement.getText(newNode), {
        at: path,
      })
    })
  })
}
