import { useState } from 'react'
import { type ApolloError, useMutation } from '@apollo/client'
import DELETE_POST from '../graphql/deletePost'
import type { DeletePostMutation } from '~publish/gql/graphql'

type DeletePostArgs = {
  postId: string
  onDeleteSuccess: () => void
  onDeleteError: (error: ApolloError) => void
}

type DeletePostResult = {
  processing: boolean
  result: DeletePostMutation | null | undefined
  deletePost: () => void
}
export const useDeletePost = ({
  postId,
  onDeleteSuccess,
  onDeleteError,
}: DeletePostArgs): DeletePostResult => {
  const [processing, setProcessing] = useState(false)
  const [result, setResult] = useState()

  const [deletePost] = useMutation(DELETE_POST, {
    onCompleted: (data) => {
      if (data?.deletePost?.success) {
        setProcessing(false)
        setResult(data)
        onDeleteSuccess()
      }
      if (data?.deletePost.userFriendlyMessage) {
        setProcessing(false)
        setResult(data)
        onDeleteError(data?.deletePost.userFriendlyMessage)
      }
    },
    onError: (err) => {
      setProcessing(false)
      onDeleteError(err)
    },
  })

  return {
    processing,
    result,
    deletePost: (): void => {
      setProcessing(true)
      deletePost({
        variables: { input: { postId } },
      }).catch((e) => console.error(e))
    },
  }
}
