import { ImagePlusIcon } from '@buffer-mono/popcorn'
import React from 'react'
import * as UploadDropzone from '~publish/components/UploadDropzone'

type CreateUploadDropzoneProps = UploadDropzone.RootProps
export const CreateUploadDropzone = ({
  children,
  disabled,
  ...props
}: CreateUploadDropzoneProps): JSX.Element => {
  return (
    <UploadDropzone.Root disabled={disabled} {...props}>
      <UploadDropzone.Overlay>
        <UploadDropzone.Content>
          <UploadDropzone.Icon>
            <ImagePlusIcon size="large" />
          </UploadDropzone.Icon>
          <UploadDropzone.Description size="md" weight="bold">
            Drop up to 10 images or videos
          </UploadDropzone.Description>
        </UploadDropzone.Content>
      </UploadDropzone.Overlay>
      {children}
    </UploadDropzone.Root>
  )
}
