/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react'
import MagicWandIcon from '@bufferapp/ui/Icon/Icons/MagicWand'

import { store, useAppDispatch } from '~publish/legacy/store'
import { selectShouldShowNBMigration } from '~publish/legacy/organizations/selectors'
import ModalActionCreators from '~publish/legacy/composer/composer/shared-components/modal/actionCreators'
import {
  toggleAIAssistant,
  setAIAssistantPlacement,
} from '~publish/legacy/ai/state/slice'

import * as Styled from './AIPlaceholderButton.styles'
import ComposerActionCreators from '~publish/legacy/composer/composer/action-creators/ComposerActionCreators'

/**
 * This buttons is shown only as a placeholder when Editor is empty
 */
const AIPlaceholderButton = ({
  placement,
}: {
  placement?: 'publishComposer' | 'ideasEditor'
}): JSX.Element => {
  const dispatch = useAppDispatch()

  const onToggle = (): void => {
    // Sets the placement (source) for tracking
    // Must be set before the upgrade modal is triggered as the modal
    // relies on the placement for tracking.
    dispatch(setAIAssistantPlacement({ placement }))

    // Display a upgrade flow for multi-product users
    // Restrict AI Assistant feature to New Buffer users only
    if (selectShouldShowNBMigration(store.getState())) {
      ModalActionCreators.openModal('AIAssistantMPUpgradePlan', {
        ctaButton: 'placeholderButton',
      })
      return
    }

    dispatch(toggleAIAssistant(true))

    ComposerActionCreators.updateSidebarOnTop(null, 'aiassistant')
  }

  return (
    <Styled.PlaceholderButton
      secondary
      onClick={onToggle}
      id="ai-assistant-placeholder-button"
      data-testid="ai-assistant-placeholder-button"
    >
      <MagicWandIcon size="smedium" />
      Use the AI Assistant
    </Styled.PlaceholderButton>
  )
}

export { AIPlaceholderButton }
