import React from 'react'
import { Route, Switch } from 'react-router-dom'

import {
  createPage,
  ideaDetailsRoute,
  ideaEditRoute,
  ideaNewRoute,
} from '~publish/legacy/routes'

import { IdeaCreationDialog } from './IdeaCreationDialog'
import { IdeaDetailsDialog } from './IdeaDetailsDialog'
import { IdeaEditDialog } from './IdeaEditDialog'

/**
 * Router component used to manage IdeaCreationDialog, IdeaEditDialog, and IdeaDetailsDialog.
 * Accepts a parent route that is prefixed to the dialog routes.
 * This allows selectively rendering the dialogs where necessary
 * without needing them to be completely global.
 */
export function IdeaManagementRouter({
  parent = createPage.route,
}: {
  parent?: string
}): JSX.Element {
  return (
    <Switch>
      <Route path={`${parent}${ideaNewRoute.route}`} key="idea-creation-dialog">
        <IdeaCreationDialog parent={parent} />
      </Route>
      <Route path={`${parent}${ideaEditRoute.route}`} key="idea-edit-dialog">
        <IdeaEditDialog parent={parent} />
      </Route>
      <Route
        path={`${parent}${ideaDetailsRoute.route}`}
        key="idea-details-dialog"
      >
        <IdeaDetailsDialog parent={parent} />
      </Route>
    </Switch>
  )
}
