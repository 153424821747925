import React from 'react'
import type {
  ComposerProfile,
  Subprofile,
} from '~publish/legacy/composer/composer/stores/types'
import AppActionCreators from '../../../action-creators/AppActionCreators'
import {
  BoardButton,
  BoardThumbnail,
  IconsContainer,
  SharedBoardIcon,
  BoardName,
  BoardIcon,
} from './styles'

type BoardSelectorBoardItemProps = {
  profile: ComposerProfile
  board: Subprofile
  canUnselectSubprofiles: boolean
  onChange: () => void
  trackChannelSelected: () => void
}

const BoardSelectorBoardItem = ({
  profile,
  board,
  canUnselectSubprofiles,
  onChange,
  trackChannelSelected,
}: BoardSelectorBoardItemProps) => {
  const onSelectedBoardClick = () => {
    if (canUnselectSubprofiles) {
      AppActionCreators.unselectSubprofile(profile.id, board.id)
      onChange()
    }
  }

  const onUnselectedBoardClick = () => {
    AppActionCreators.selectSubprofile(profile.id, board.id)
    onChange()
    trackChannelSelected()
  }

  const isBoardSelected = profile.selectedSubprofileId === board.id

  const onClick = () =>
    isBoardSelected ? onSelectedBoardClick() : onUnselectedBoardClick()

  const defaultAvatarToOverride =
    'https://static.bufferapp.com/images/app/pin_2x.png'
  const defaultAvatar = 'https://static.bufferapp.com/images/app/img_pin@2x.png'
  const avatar =
    board.avatar !== defaultAvatarToOverride ? board.avatar : defaultAvatar

  const boardIconClassName =
    isBoardSelected && canUnselectSubprofiles
      ? 'bi bi-circle-x'
      : 'bi bi-circle-checkmark'

  return (
    <BoardButton
      onClick={onClick}
      title={board.name}
      aria-selected={isBoardSelected}
      $isBoardSelected={isBoardSelected}
      $canUnselectSubprofiles={canUnselectSubprofiles}
    >
      <BoardThumbnail src={avatar} alt="" />

      <IconsContainer>
        {board.isShared && <SharedBoardIcon className="bi bi-persons" />}

        <BoardIcon
          className={boardIconClassName}
          $isBoardSelected={isBoardSelected}
        />
      </IconsContainer>

      <BoardName>{board.name}</BoardName>
    </BoardButton>
  )
}

export default BoardSelectorBoardItem
