import React, { useRef } from 'react'
import { useSplitEnabled } from '@buffer-mono/features'
import type { Draft } from '../../../entities/Draft'
import { Field } from '~publish/legacy/reminders/components/new-reminders/components/forms/field/Field'
import ComposerActionCreators from '../../../action-creators/ComposerActionCreators'

import styles from './MastodonChannelFields.module.css'

export const MastodonChannelFields = ({
  draft,
}: {
  draft: Draft
}): JSX.Element => {
  const { isEnabled: isMastodonContentWarningEnabled } = useSplitEnabled(
    'mastodon-content-warning',
  )
  const spoilerText = draft?.channelData?.mastodon?.spoilerText
  const hadContentRef = useRef<boolean>(!!spoilerText)

  if (
    !draft.service.isMastodon() ||
    // Don't show the content warning field if the feature is disabled
    (!isMastodonContentWarningEnabled &&
      // but if it has been disabled but post contains content warning, we should show it
      !hadContentRef.current)
  ) {
    return <></>
  }

  return (
    <div className={styles.wrapper}>
      <Field
        id="mastodon-content-warning"
        name="mastodon-content-warning"
        labelText="Content Warning"
        placeholder="Add a warning message to your post"
        onChange={(e): void => {
          ComposerActionCreators.updateDraftMastodonData({
            spoilerText: e.target.value,
          })
        }}
        value={spoilerText ?? ''}
      />
    </div>
  )
}
