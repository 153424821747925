/**
 * Component that displays a thumbnail that fits the width of its container
 */

import React from 'react'
import PropTypes from 'prop-types'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'reac... Remove this comment to see the full error message
import Textarea from 'react-textarea-autosize'
import ComposerActionCreators from '../action-creators/ComposerActionCreators'
import { LinkAttachmentTextFieldTypes } from '../AppConstants'
import styles from './css/LinkAttachmentTextEditor.module.css'

class LinkAttachmentTextEditor extends React.Component {
  static propTypes = {
    draftId: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    canBeEdited: PropTypes.bool.isRequired,
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  onLinkFieldKeyDown = (e) => {
    // Prevent inserting line breaks in link title
    // @ts-expect-error TS(2339) FIXME: Property 'type' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    if (this.props.type === LinkAttachmentTextFieldTypes.TITLE) {
      if (e.key === 'Enter') e.preventDefault()
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  onLinkFieldChange = (e) => {
    // @ts-expect-error TS(2339) FIXME: Property 'type' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    if (this.props.type === LinkAttachmentTextFieldTypes.TITLE) {
      ComposerActionCreators.updateDraftLinkTitle(
        // @ts-expect-error TS(2339) FIXME: Property 'draftId' does not exist on type 'Readonl... Remove this comment to see the full error message
        this.props.draftId,
        e.target.value,
      )
      // @ts-expect-error TS(2339) FIXME: Property 'type' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    } else if (this.props.type === LinkAttachmentTextFieldTypes.DESCRIPTION) {
      ComposerActionCreators.updateDraftLinkDescription(
        // @ts-expect-error TS(2339) FIXME: Property 'draftId' does not exist on type 'Readonl... Remove this comment to see the full error message
        this.props.draftId,
        e.target.value,
      )
    }
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'value' does not exist on type 'Readonly<... Remove this comment to see the full error message
    const { value, type, canBeEdited } = this.props

    let placeholder
    if (type === LinkAttachmentTextFieldTypes.TITLE) {
      placeholder = 'No title'
    } else if (type === LinkAttachmentTextFieldTypes.DESCRIPTION) {
      placeholder = 'No description'
    }

    const textFieldClassName =
      type === LinkAttachmentTextFieldTypes.TITLE
        ? canBeEdited
          ? styles.editableTitle
          : styles.title
        : type === LinkAttachmentTextFieldTypes.DESCRIPTION
        ? canBeEdited
          ? styles.editableDescription
          : styles.description
        : undefined

    return (
      <div>
        {canBeEdited ? (
          <Textarea
            className={textFieldClassName}
            onKeyDown={this.onLinkFieldKeyDown}
            onChange={this.onLinkFieldChange}
            value={value}
            placeholder={placeholder}
          />
        ) : (
          <p className={textFieldClassName}>{value}</p>
        )}
      </div>
    )
  }
}

export default LinkAttachmentTextEditor
