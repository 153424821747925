/* eslint-disable react/no-array-index-key */
import React from 'react'
import { BlueskyPost } from './components/BlueskyPost/BlueskyPost'
import { Empty } from '../Common/Empty'
import * as Styles from './styles'
import type {
  ProfilePreview,
  BaseDraftWithThread,
  ThreadsContent,
} from '../types'

import { useScrollToThreadedPost } from '../Common/hooks/useScrollToThreadedPost'

const getPosts = (draft: BaseDraftWithThread): ThreadsContent[] => {
  if (draft.thread) {
    const { activeThreadId } = draft

    return draft.thread.map((threadedDraft, i) =>
      activeThreadId === i ? draft : threadedDraft,
    )
  } else {
    return [draft]
  }
}

export const BlueskyPreview = ({
  draft,
  profile,
}: {
  draft: BaseDraftWithThread
  profile: ProfilePreview
}): JSX.Element => {
  const { isEmpty, hasThread, activeThreadId } = draft

  const posts = getPosts(draft)

  useScrollToThreadedPost(activeThreadId)

  if (isEmpty && !hasThread) {
    return <Empty />
  }

  return (
    <Styles.Wrapper data-testid="bluesky-preview">
      {posts.map((post, index) => (
        <BlueskyPost
          key={index}
          content={post}
          profile={profile}
          index={index}
        />
      ))}
    </Styles.Wrapper>
  )
}
