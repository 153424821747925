import mime from 'mime/lite'
import { MediaType } from '../constants'
import { getFileExtension } from './getFileExtension'
import { getMediaTypeFromMime } from './getMediaTypeFromMime'

export const getMimeAndExtensionFromName = (
  name = '',
): {
  mimeType?: string
  extension: string
  mediaType: MediaType
} => {
  if (!name) {
    return {
      mimeType: undefined,
      extension: '',
      mediaType: MediaType.UNSUPPORTED,
    }
  }

  const mimeType = mime.getType(name) ?? undefined

  return {
    mimeType,
    extension: getFileExtension(name) ?? '',
    mediaType: getMediaTypeFromMime(mimeType),
  }
}
