/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import { useSplitEnabled } from '@buffer-mono/features'
import PropTypes from 'prop-types'
import React from 'react'
import { PostEntity } from '~publish/legacy/post/PostEntity'
import { ReminderBadge } from '~publish/legacy/reminders/components/ReminderBadge'
import getErrorBoundary from '~publish/legacy/web/components/ErrorBoundary'
import FailedPostComponent from '~publish/legacy/web/components/ErrorBoundary/failedPostComponent'
import { ProfilesDraft } from '../../../drafts/components/ProfilesDraft'
import {
  PostTypeFacebookReel,
  PostTypeReel,
  PostTypeStory,
} from '../../../post/constants'
import Post from '../../Post'
import PostDragWrapper from '../../PostDragWrapper'
import { PostWrapper } from './styles'
import { ProcessingBadge } from '~publish/legacy/shared-components/QueueItems/Header/ProcessingBadge'

const ErrorBoundary = getErrorBoundary(true)

const PostContentProps = {
  indexType: PropTypes.number.isRequired,
  queueType: PropTypes.string,
  postType: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    isDeleting: PropTypes.bool,
  }).isRequired,
  postPropsType: PropTypes.shape({
    index: PropTypes.number,
    basic: PropTypes.bool,
    draggable: PropTypes.bool,
    onDeleteConfirmClick: PropTypes.func,
    onEditClick: PropTypes.func,
    onShareNowClick: PropTypes.func,
    onRequeueClick: PropTypes.func,
    onDropPost: PropTypes.func,
    onSwapPosts: PropTypes.func,
  }),
  postComponentType: PropTypes.oneOf([Post, ProfilesDraft]).isRequired,
}

// @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
const postClassName = (item) => {
  if (!item) return ''

  return [
    'update',
    `post_${item.profile_service}`,
    item.postDetails?.isRetweet ? 'is_retweet' : 'not_retweet',
  ].join(' ')
}

// @ts-expect-error TS(7031) FIXME: Binding element 'queueType' implicitly has an 'any... Remove this comment to see the full error message
const getPostComponent = ({ queueType, postType }) => {
  if (queueType === 'drafts') return ProfilesDraft

  return Post
}

// @ts-expect-error TS(7031) FIXME: Binding element 'draft' implicitly has an 'any' ty... Remove this comment to see the full error message
const getDraftProps = ({ draft, postProps }) => {
  return {
    draftDetails: draft.draftDetails,
    profileService: draft.profile_service,
    geolocationName: draft.service_geolocation_name,
    onDeleteConfirmClick: () => postProps.onDeleteConfirmClick({ post: draft }),
    onEditClick: () => postProps.onEditClick({ postId: draft.id }),
    onApproveClick: () => postProps.onApproveClick({ draft }),
    onRevertApprovalClick: () => postProps.onRevertApprovalClick({ draft }),
    onRequestApprovalClick: () => postProps.onRequestApprovalClick({ draft }),
    onRescheduleClick: () => postProps.onRescheduleClick({ postId: draft.id }),
  }
}

// @ts-expect-error TS(7031) FIXME: Binding element 'post' implicitly has an 'any' typ... Remove this comment to see the full error message
const getPostProps = ({ post, postProps }) => {
  const campaignId = post.campaignDetails?.id ?? null

  return {
    service_geolocation_name:
      post?.channelData?.[post.profile_service]?.service_geolocation_name,
    source_url: post.sourceUrl,
    subprofile_id: post.subprofileID,
    service_user_tags: post.userTags,
    shouldShowEditButton: post.retweet && !!post.retweetComment,
    profileService: post.profile_service,
    channelData: post.channelData,
    isReelsPost: post.updateType === PostTypeReel,
    isFacebookReelPost: post.updateType === PostTypeFacebookReel,
    isStoryPost: post.updateType === PostTypeStory,
    onDeleteConfirmClick: () => postProps.onDeleteConfirmClick({ post }),
    onEditClick: () => postProps.onEditClick({ postId: post.id }),
    onShareNowClick: () => postProps.onShareNowClick({ post }),
    onRequeueClick: () => postProps.onRequeueClick({ post }),
    onCampaignTagClick: () => postProps.onCampaignTagClick(campaignId),
    // @ts-expect-error TS(7031) FIXME: Binding element 'type' implicitly has an 'any' typ... Remove this comment to see the full error message
    onSetRemindersClick: ({ type }) =>
      postProps.onSetRemindersClick({ type, post }),
  }
}

// @ts-expect-error TS(7031) FIXME: Binding element 'post' implicitly has an 'any' typ... Remove this comment to see the full error message
const ErrorBoundaryWrapper = ({ post, fallbackComponent, children }) => (
  <ErrorBoundary
    fallbackComponent={(): React.JSX.Element => (
      <ErrorBoundary
        fallbackComponent={(): JSX.Element => (
          <FailedPostComponent key={post.id} post={post} postId={post.id} />
        )}
      >
        {fallbackComponent}
      </ErrorBoundary>
    )}
  >
    {children}
  </ErrorBoundary>
)

ErrorBoundaryWrapper.propTypes = {
  post: PostContentProps.postType,
  fallbackComponent: PropTypes.elementType,
  children: PropTypes.element.isRequired,
}

const DraggablePost = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'index' implicitly has an 'any' ty... Remove this comment to see the full error message
  index,
  // @ts-expect-error TS(7031) FIXME: Binding element 'post' implicitly has an 'any' typ... Remove this comment to see the full error message
  post,
  // @ts-expect-error TS(7031) FIXME: Binding element 'PostComponent' implicitly has an ... Remove this comment to see the full error message
  postComponent: PostComponent,
  // @ts-expect-error TS(7031) FIXME: Binding element 'postProps' implicitly has an 'any... Remove this comment to see the full error message
  postProps,
}): JSX.Element => {
  const userCanEdit = post.isManager
  return (
    <ErrorBoundaryWrapper
      post={post}
      fallbackComponent={(): JSX.Element => (
        <PostDragWrapper
          // @ts-expect-error TS(2322) FIXME: Type 'any' is not assignable to type 'never'.
          id={post.id}
          index={index}
          postComponent={PostComponent}
          postProps={postProps}
          basic
          userCanEdit={userCanEdit}
        />
      )}
    >
      <PostDragWrapper
        // @ts-expect-error TS(2322) FIXME: Type 'any' is not assignable to type 'never'.
        id={post.id}
        post={post}
        index={index}
        postComponent={PostComponent}
        postProps={postProps}
        userCanEdit={userCanEdit}
      />
    </ErrorBoundaryWrapper>
  )
}

DraggablePost.propTypes = {
  index: PostContentProps.indexType,
  post: PostContentProps.postType,
  postComponent: PostContentProps.postComponentType,
  postProps: PostContentProps.postPropsType,
}

const NonDraggablePost = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'post' implicitly has an 'any' typ... Remove this comment to see the full error message
  post,
  // @ts-expect-error TS(7031) FIXME: Binding element 'PostComponent' implicitly has an ... Remove this comment to see the full error message
  postComponent: PostComponent,
  // @ts-expect-error TS(7031) FIXME: Binding element 'postProps' implicitly has an 'any... Remove this comment to see the full error message
  postProps,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isCampaign' implicitly has an 'an... Remove this comment to see the full error message
  isCampaign,
}) => (
  <ErrorBoundaryWrapper
    post={post}
    fallbackComponent={() => (
      <PostComponent
        postId={post.id}
        isCampaign={isCampaign}
        post={post}
        {...postProps}
        basic
      />
    )}
  >
    <PostComponent
      postId={post.id}
      post={post}
      isCampaign={isCampaign}
      {...postProps}
    />
  </ErrorBoundaryWrapper>
)

NonDraggablePost.propTypes = {
  post: PostContentProps.postType,
  postComponent: PostContentProps.postComponentType,
  postProps: PostContentProps.postPropsType,
  isCampaign: PropTypes.bool,
}

const Content = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'post' implicitly has an 'any' typ... Remove this comment to see the full error message
  post,
  // @ts-expect-error TS(7031) FIXME: Binding element 'queueType' implicitly has an 'any... Remove this comment to see the full error message
  queueType,
  // @ts-expect-error TS(7031) FIXME: Binding element 'PostComponent' implicitly has an ... Remove this comment to see the full error message
  postComponent: PostComponent,
  // @ts-expect-error TS(7031) FIXME: Binding element 'postProps' implicitly has an 'any... Remove this comment to see the full error message
  postProps,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isCampaign' implicitly has an 'an... Remove this comment to see the full error message
  isCampaign,
}) => {
  const itemProps =
    queueType === 'drafts'
      ? getDraftProps({ draft: post, postProps })
      : getPostProps({ post, postProps })

  const postWithEventHandlers = {
    key: post.id,
    ...post,
    ...postProps,
    ...itemProps,
  }

  const { index, basic, draggable } = postProps

  if (draggable) {
    return (
      <DraggablePost
        index={index}
        post={post}
        postComponent={PostComponent}
        postProps={postWithEventHandlers}
        // @ts-expect-error TS(2322) FIXME: Type '{ index: any; post: any; postComponent: any;... Remove this comment to see the full error message
        basic={basic}
      />
    )
  }

  return (
    <NonDraggablePost
      post={post}
      postComponent={PostComponent}
      postProps={postWithEventHandlers}
      isCampaign={isCampaign}
    />
  )
}

Content.propTypes = {
  post: PostContentProps.postType,
  postComponent: PostContentProps.postComponentType,
  queueType: PostContentProps.queueType,
  postProps: PostContentProps.postPropsType,
  isCampaign: PropTypes.bool,
}

const PostContent = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'post' implicitly has an 'any' typ... Remove this comment to see the full error message
  post,
  queueType = 'post',
  // @ts-expect-error TS(7031) FIXME: Binding element 'isCampaign' implicitly has an 'an... Remove this comment to see the full error message
  isCampaign,
  // Using postRef prop instead of forwardRef to avoid typing the PostContent component
  // as it relies on unknown prop spreading and drilling
  postRef = null,
  ...postProps
}) => {
  const { isEnabled: isRemindersEnabled } = useSplitEnabled('CORE-reminders')
  const PostComponent = getPostComponent({ queueType, postType: post.type })
  const isReminder =
    (!!PostEntity.isReminder(post) ||
      PostEntity.isInstagramReminder(post) ||
      PostEntity.isPastReminder(post)) &&
    post?.via?.toLowerCase() !== post.profile_service
  const displayReminderBadge = isRemindersEnabled && isReminder
  const displayTiktokPendingBadge = PostEntity.isPendingTiktok(post)

  return (
    <>
      <PostWrapper
        id={`update-${post.id}`}
        className={postClassName(post)}
        hidden={post.isDeleting}
        data-testid="post-wrapper"
        ref={postRef}
      >
        {displayReminderBadge && (
          <ReminderBadge isSent={PostEntity.isPastReminder(post)} />
        )}
        {displayTiktokPendingBadge && <ProcessingBadge />}
        <Content
          post={post}
          queueType={queueType}
          postComponent={PostComponent}
          isCampaign={isCampaign}
          postProps={postProps}
        />
      </PostWrapper>
    </>
  )
}

PostContent.propTypes = {
  post: PostContentProps.postType,
  queueType: PostContentProps.queueType,
  onShareAgainClick: PropTypes.func,
  postProps: PostContentProps.postPropsType,
  isCampaign: PropTypes.bool,
}

PostContent.defaultProps = {
  queueType: 'post',
  post: {},
  onShareAgainClick: () => {},
  isCampaign: false,
  postProps: {
    index: 0,
    draggable: false,
    onDeleteConfirmClick: () => {},
    onEditClick: () => {},
    onShareNowClick: () => {},
    onRequeueClick: () => {},
    onDropPost: () => {},
    onSwapPosts: () => {},
  },
}

export default PostContent
