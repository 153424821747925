import React from 'react'
import { Carousel, Play } from '@bufferapp/ui/Icon'
import {
  Media,
  MediaIconWrapper,
  MediaThumbnailWrapper,
  TagWrapper,
} from './styles'

type UpdateType = 'story' | 'reels' | 'facebook_story' | 'facebook_reel'

function getMediaTag({
  firstMedia,
  isGallery,
  updateType,
  size,
}: {
  firstMedia: {
    type?: string
    thumbnail?: string | null
  }
  isGallery: boolean
  updateType?: UpdateType
  size: 'extraSmall' | 'small' | 'medium' | 'large'
}): JSX.Element | null {
  if (updateType) {
    if (['reels', 'facebook_reel'].includes(updateType)) {
      return <TagWrapper>Reels</TagWrapper>
    }

    if (['story', 'facebook_story'].includes(updateType)) {
      return <TagWrapper>Story</TagWrapper>
    }
  }

  if (isGallery) {
    return (
      <MediaIconWrapper size={size}>
        <Carousel color="white" />
      </MediaIconWrapper>
    )
  }

  if (firstMedia.type === 'video') {
    return (
      <MediaIconWrapper size={size}>
        <Play color="white" />
      </MediaIconWrapper>
    )
  }

  return null
}

export const toUpdateType = (
  input: string | null | undefined,
): UpdateType | undefined => {
  if (!input) return undefined
  if (['story', 'reels', 'facebook_story', 'facebook_reel'].includes(input)) {
    return input as UpdateType
  }

  return undefined
}

const MediaThumbnail = ({
  size,
  media,
  updateType,
}: {
  size: 'extraSmall' | 'small' | 'medium' | 'large'
  media: Array<{
    type?: string
    thumbnail?: string | null
  }>
  updateType?: UpdateType
}): JSX.Element => {
  const isGallery = media.length > 1
  const firstMedia = media[0]
  const hasMediaThumbnail = firstMedia?.type === 'video' || isGallery

  return (
    <MediaThumbnailWrapper size={size}>
      <Media
        size={size}
        hasMediaThumbnail={hasMediaThumbnail}
        height="100%"
        width="100%"
        src={firstMedia.thumbnail ?? ''}
      />
      {getMediaTag({
        firstMedia,
        isGallery,
        updateType,
        size,
      })}
    </MediaThumbnailWrapper>
  )
}

export default MediaThumbnail
