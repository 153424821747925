import React from 'react'
import LinkBar from './LinkBar/LinkBar'
import type Draft from '../../entities/Draft/Draft'
import type { StartPageChannelData } from '../../entities/Draft/types'

function isStartPage(
  draft: Draft,
): draft is Draft & { channelData: { startPage: StartPageChannelData } } {
  return draft.service.isStartPage()
}

const StartPageComposerBar = ({ draft }: { draft: Draft }): JSX.Element => {
  if (!isStartPage(draft)) {
    return <></>
  }

  const data = draft.channelData.startPage

  return <LinkBar data={data} />
}

export default StartPageComposerBar
