import React from 'react'
import type { RenderPlaceholderProps } from 'slate-react'
import { AIPlaceholderButton } from '~publish/legacy/ai/components/Triggers/AIPlaceholderButton'

import styles from './renderPlaceholderWithAIButton.module.css'

/*
 * This method renders the button Use the AI Assistant along with
 * the selected placeholder in the editor
 * */
type RenderPlaceholderWithAIButtonReturnType = (
  props: RenderPlaceholderProps,
) => JSX.Element

export const renderPlaceholderWithAIButton = ({
  placement,
}: {
  placement?: 'publishComposer' | 'ideasEditor'
}): RenderPlaceholderWithAIButtonReturnType => {
  const renderFunction = (props: RenderPlaceholderProps): JSX.Element => {
    const updatedAttributes = {
      ...props.attributes,
      style: undefined,
    }

    return (
      <span {...updatedAttributes} className={styles.wrapper}>
        <span className={styles.text}>{props.children}</span>
        <AIPlaceholderButton placement={placement} />
      </span>
    )
  }

  return renderFunction
}
