// ensure we round ratios to 2 decimal places
export const rountToTwo = (ratio: number): number =>
  Math.round((ratio + Number.EPSILON) * 100) / 100

// greatest common denominator
export const gcd = (a: number, b: number): number =>
  b === 0 ? a : gcd(b, a % b)

// calculate the ratio string via greatest common denominator
export const calculateAspectRatioString = (
  width: number,
  height: number,
): string => {
  const denominator = gcd(width, height)
  return `${width / denominator}:${height / denominator}`
}
