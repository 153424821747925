import React from 'react'
import TextArea from '@bufferapp/ui/TextArea'

import { useAppSelector, useAppDispatch } from '~publish/legacy/store'
import { selectGeneratedIdeas } from '~publish/legacy/ai/state/contentIdeation/slice'
import { generateIdeas } from '~publish/legacy/ai/state/contentIdeation/thunks'
import { ErrorMessage } from '~publish/legacy/ai/components/GenerateIdeasButton/ErrorMessage'
import {
  getPrompt,
  setPromptSettings,
  getPromptSettings,
} from '~publish/legacy/ai/helpers/localStorage'

import * as Styled from './styles'
import { IdeaWrapper } from '../GeneratedIdea/styles'

/**
 *
 */
const Questions = () => {
  const [about, setAbout] = React.useState<string>('')
  const [targetAudience, setTargetAudience] = React.useState<string>('')

  const dispatch = useAppDispatch()

  const { generateIdeasLoading, generateIdeasError } = useAppSelector((state) =>
    selectGeneratedIdeas(state),
  )

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  const onChangeAbout = (event) => {
    setAbout(event.target.value)
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  const onChangeTargetAudience = (event) => {
    setTargetAudience(event.target.value)
  }

  const onGenerateIdea = () => {
    dispatch(
      generateIdeas({
        prompt: getPrompt(),
        cta: 'publish-ideas-contentIdeationWidget-generate-1',
      }),
    )
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'event' implicitly has an 'any' type.
  const onSubmit = (event) => {
    event.preventDefault()

    if (!about || !targetAudience) return
    setPromptSettings({ about, targetAudience })
    onGenerateIdea()
  }

  React.useEffect(() => {
    const { about: savedAbout, targetAudience: savedTargetAudience } =
      getPromptSettings()

    if (!savedAbout || !savedTargetAudience) return

    setAbout(savedAbout)
    setTargetAudience(savedTargetAudience)
  }, [])

  return (
    <>
      <Styled.Header>Let’s begin with a few questions 🪄</Styled.Header>
      <IdeaWrapper>
        <Styled.Form onSubmit={onSubmit}>
          <TextArea
            // @ts-expect-error TS(2769) FIXME: No overload matches this call.
            label="What is your business about?"
            value={about}
            placeholder="A training and fitness brand"
            onChange={onChangeAbout}
            id="business-about"
          />
          <TextArea
            // @ts-expect-error TS(2769) FIXME: No overload matches this call.
            label="What is your target audience?"
            value={targetAudience}
            placeholder="Busy working professionals worldwide"
            onChange={onChangeTargetAudience}
            id="business-target"
          />
          <Styled.Button
            type="submit"
            disabled={!about || !targetAudience}
            loading={generateIdeasLoading}
          >
            {generateIdeasLoading ? 'Generating…' : 'Generate Ideas'}
          </Styled.Button>
          {generateIdeasError && <ErrorMessage message={generateIdeasError} />}
        </Styled.Form>
      </IdeaWrapper>
    </>
  )
}

export { Questions }
