import type { PlateEditor } from '@udecode/plate'
import { generateText } from './commands/generateText'
import { getPrompt, type TPrompt } from './commands/getPrompt'
import { unwrapGeneratedContent } from './commands/unwrapGeneratedContent'
import { removeGeneratedContent } from './commands/removeGeneratedContent'
import { getGeneratedContent } from './commands/getGeneratedContent'

type TGenerateTextParams = {
  organizationId: string
  action: string
  service?: string
  annotation: string | null
  previousGeneration?: string
  metadata: {
    placement: string
    retry: boolean
  }
}

/**
 *
 */
export const withGeneratedContent = (editor: PlateEditor): PlateEditor => {
  editor.generateText = ({
    organizationId,
    action,
    service,
    annotation,
    metadata,
  }: TGenerateTextParams): Promise<void | boolean> =>
    generateText({
      editor,
      organizationId,
      action,
      service,
      annotation,
      metadata,
    })

  editor.prompt = (): TPrompt => getPrompt({ editor })

  editor.getGeneratedContent = (): string | null =>
    getGeneratedContent({ editor })
  editor.keepGeneratedContent = (): void => unwrapGeneratedContent({ editor })
  editor.removeGeneratedContent = (): void => removeGeneratedContent({ editor })

  editor.highlighted = null
  editor.hasGeneratedContent = false

  return editor
}

export { TGenerateTextParams }
