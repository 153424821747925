import React from 'react'
import styles from './PinterestSelectedBoards.module.css'
import type { Subprofile } from '~publish/legacy/profile-sidebar/types'

export interface Profile {
  isSelected: boolean
  service: { name: string }
  selectedSubprofileId: string | null
  subprofiles: Subprofile[]
}

const PinterestSelectedBoards = ({
  profiles,
}: {
  profiles: Profile[]
}): React.JSX.Element => {
  const getSelectedBoard = (): Subprofile[] => {
    const selectedPinterestProfiles = profiles.filter(
      (profile) => profile.isSelected && profile.service.name === 'pinterest',
    )

    return selectedPinterestProfiles.reduce<Subprofile[]>(
      (reducedSelectedSubprofiles, profile) => {
        if (profile.selectedSubprofileId === null)
          return reducedSelectedSubprofiles
        const selectedSubprofileForProfile = profile.subprofiles.find(
          (subprofile) => subprofile.id === profile.selectedSubprofileId,
        )
        if (selectedSubprofileForProfile) {
          reducedSelectedSubprofiles.push(selectedSubprofileForProfile)
        }
        return reducedSelectedSubprofiles
      },
      [],
    )
  }

  const getSubprofiles = (): Subprofile[] =>
    profiles
      .filter(
        (profile) => profile.isSelected && profile.service.name === 'pinterest',
      )
      .reduce<Subprofile[]>(
        (reducedSelectedSubprofiles, profile) => [
          ...profile.subprofiles,
          ...reducedSelectedSubprofiles,
        ],
        [],
      )

  const selectedBoards = getSelectedBoard()

  if (getSubprofiles().length === 0) {
    return (
      <div className={styles.noBoardsNotice}>
        You need to have a board created and selected to schedule this pin.
      </div>
    )
  }

  const firstBoard = selectedBoards[0]

  const hasMultipleBoardsSelected = selectedBoards.length > 1

  const getAvatar = (avatar: string): string => {
    const defaultAvatarToOverride =
      'https://static.bufferapp.com/images/app/pin_2x.png'
    const defaultAvatar =
      'https://static.bufferapp.com/images/app/img_pin@2x.png'
    return avatar === defaultAvatarToOverride ? defaultAvatar : avatar
  }

  const firstBoardAvatar = firstBoard ? getAvatar(firstBoard.avatar) : null

  return (
    <div className={styles.selectedBoardsContainer}>
      {firstBoardAvatar && (
        <img
          alt={`${firstBoard.name} avatar`}
          className={styles.selectedBoardThumbnail}
          src={firstBoardAvatar}
          role="presentation"
        />
      )}
      {firstBoard && (
        <div className={styles.selectedBoardName}>{firstBoard.name}</div>
      )}

      {hasMultipleBoardsSelected && (
        <div className={styles.additionalBoard}>
          + {selectedBoards.length - 1} more
        </div>
      )}
    </div>
  )
}

export default PinterestSelectedBoards
