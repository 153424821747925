import { Button, Notice } from '@buffer-mono/popcorn'
import React from 'react'
import { CtaButtonWrapper } from '~publish/components/CtaButtonWrapper'
import { useDismissableBanner } from '~publish/hooks/useDismissableBanner'

import { useSelectedChannel } from '~publish/pages/Channel/ChannelContext'
import { RegisteredBanner } from '../../components/RegisteredBanner'
import isMatch from 'lodash/isMatch'
import { Service } from '~publish/legacy/constants/services/ServiceDefinitions'

export const InstagramProfessionalAccountBanner = (): JSX.Element | null => {
  const bannerId = 'instagramProfessionalAccountBanner'
  const selectedChannel = useSelectedChannel()

  const banner = useDismissableBanner(bannerId, {
    trackDismiss: true,
  })

  const ctaName = 'publish-queue-banner-connectInstagramProfessional-1'
  const canRenderBanner =
    banner.isActive &&
    selectedChannel &&
    isMatch(selectedChannel, { service: 'instagram', type: 'profile' })

  return (
    <RegisteredBanner id={bannerId} canRender={canRenderBanner}>
      <Notice variant="info" onDismiss={banner.dismiss}>
        <Notice.Heading>Unlock Automatic Publishing for Free</Notice.Heading>
        <Notice.Text>
          Meta doesn&apos;t support automatic publishing for Personal accounts.
          Switch to a free Professional (Business or Creator) account to unlock
          this and more. Instagram will guide you through the process.
        </Notice.Text>

        <Notice.Actions>
          <CtaButtonWrapper
            cta={ctaName}
            options={{ upgradePathName: 'accountChannels-upgrade' }}
          >
            <Button
              variant="primary"
              onClick={(): void => {
                const { actions } = window.appshell

                actions.connectChannel({
                  selectedService: Service.Instagram,
                  customChannelIdToConvert: selectedChannel.id,
                  authMethod: 'instagramLogin',
                  cta: 'publish-banner-switchToAProfessionalAccount-1',
                })
              }}
            >
              Switch to a Professional Account
            </Button>
          </CtaButtonWrapper>
          <Button
            variant="tertiary"
            as="a"
            href="https://support.buffer.com/article/554-using-instagram-with-buffer#Converting-your-Instagram-account-to-a-Professional-account-Creator-o-pCMTs"
            target="_blank"
          >
            Learn More
          </Button>
        </Notice.Actions>
      </Notice>
    </RegisteredBanner>
  )
}
