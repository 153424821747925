import React from 'react'

export function MusicStickerIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="8"
      viewBox="0 0 9 8"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.21875 0.393555C4.77103 0.393555 5.21875 0.785306 5.21875 1.26855L5.21875 6.51855C5.21875 7.0018 4.77103 7.39355 4.21875 7.39355C3.66647 7.39355 3.21875 7.0018 3.21875 6.51855L3.21875 1.26855C3.21875 0.785305 3.66647 0.393555 4.21875 0.393555Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.21875 0.893555C7.77103 0.893555 8.21875 1.27731 8.21875 1.7507L8.21875 6.03641C8.21875 6.5098 7.77103 6.89355 7.21875 6.89355C6.66647 6.89355 6.21875 6.5098 6.21875 6.03641L6.21875 1.7507C6.21875 1.27731 6.66647 0.893555 7.21875 0.893555Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.21875 0.893555C1.77103 0.893555 2.21875 1.27731 2.21875 1.7507L2.21875 6.03641C2.21875 6.5098 1.77103 6.89355 1.21875 6.89355C0.666465 6.89355 0.21875 6.5098 0.21875 6.03641L0.21875 1.7507C0.21875 1.27731 0.666465 0.893555 1.21875 0.893555Z"
        fill="white"
      />
    </svg>
  )
}
