import React from 'react'
import { useSelector } from 'react-redux'

import { actions as modalActions } from '~publish/legacy/modals/reducer'
import {
  selectTagFormProperties,
  selectTagInfo,
} from '~publish/legacy/tag-form-modal/selectors'
import { useAppDispatch, useAppSelector } from '~publish/legacy/store'
import { updateTag } from '~publish/legacy/tag-form-modal/thunks/updateTag'
import { selectCurrentOrganizationId } from '~publish/legacy/organizations/selectors'
import { TagEditDialog } from '~publish/components/TagEditDialog'

import { createTag } from '../thunks/createTag'

export const TagFormModal = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const { editMode } = useSelector(selectTagFormProperties)
  const tagInfo = useSelector(selectTagInfo)
  const orgId = useAppSelector(selectCurrentOrganizationId)

  const handleCloseModal = (): void => {
    dispatch(modalActions.hideTagFormModal())
  }

  const handleSaveTag = ({
    name,
    color,
  }: {
    name: string
    color: string
  }): void => {
    const data = {
      id: tagInfo?.id as string,
      name,
      color,
      organizationId: orgId,
    }
    if (editMode) {
      dispatch(updateTag(data))
    } else {
      dispatch(createTag(data))
    }
  }

  return (
    <TagEditDialog
      onOpenChange={handleCloseModal}
      open
      tag={tagInfo ?? undefined}
      onSubmit={handleSaveTag}
    />
  )
}
