import styled from 'styled-components'

import { purpleLightest, grayDark } from '@bufferapp/ui/style/colors'
import { fontSize } from '@bufferapp/ui/style/fonts'

export const Wrapper = styled.div`
  border-radius: 4px;
  border: 1px solid ${purpleLightest};
  font-size: ${fontSize};
  padding: 16px;
  color: ${grayDark};
  background-image: linear-gradient(
    270deg,
    rgba(237, 239, 255, 1) 0%,
    rgba(250, 245, 255, 1) 100%
  );
`
