import React, { useImperativeHandle, useRef } from 'react'
import PropTypes from 'prop-types'
import { DragSource, DropTarget, DragPreviewImage } from 'react-dnd'
import SwapIcon from '@bufferapp/ui/Icon/Icons/Swap'
import styled from 'styled-components'

import LinkPreview from '../LinkPreview'

const customLinkSource = {
  // @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
  beginDrag(props) {
    return {
      id: props.item._id,
      item: props.item,
      onSwapCustomLinks: props.onSwapCustomLinks,
    }
  },
}

const customLinkTarget = {
  // @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
  drop(props, monitor) {
    const { onSwapCustomLinks } = monitor.getItem()
    const linkSource = monitor.getItem().item
    const linkTarget = props.item

    onSwapCustomLinks({
      customLinkSource: linkSource,
      customLinkTarget: linkTarget,
    })
  },
}

const SwapWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 999;
`

const SwapIconStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 19px;
  background: #2c4bff;
  border-radius: 24px;
  color: #ffffff;
`

const SwapText = styled.span`
  margin-left: 8px;
  font-size: 14px;
  font-weight: bold;
`

const SwapIconStyled = () => (
  <SwapWrapper>
    <SwapIconStyle>
      <SwapIcon size="medium" color="#FFFFFF" />
      <SwapText>Swap Links</SwapText>
    </SwapIconStyle>
  </SwapWrapper>
)

// @ts-expect-error TS(7031) FIXME: Binding element 'isOver' implicitly has an 'any' t... Remove this comment to see the full error message
const wrapperStyle = ({ isOver, isDragging }) => {
  const transition = 'all 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275)'
  const hideOutline = { outline: 'none' }

  return { transition, ...hideOutline }
}

const DragWrapper = styled.div`
  position: relative;
  :focus {
    outline: none;
  }
`

const LinkDragWrapper = React.forwardRef(
  (
    {
      // @ts-expect-error TS(2339) FIXME: Property 'connectDragSource' does not exist on typ... Remove this comment to see the full error message
      connectDragSource,
      // @ts-expect-error TS(2339) FIXME: Property 'connectDropTarget' does not exist on typ... Remove this comment to see the full error message
      connectDropTarget,
      // @ts-expect-error TS(2339) FIXME: Property 'connectDragPreview' does not exist on ty... Remove this comment to see the full error message
      connectDragPreview,
      ...customLinkProps
    },
    ref,
  ) => {
    const elementRef = useRef(null)
    connectDragSource(elementRef)
    connectDropTarget(elementRef)
    useImperativeHandle(ref, () => ({
      getNode: () => elementRef.current,
    }))
    // @ts-expect-error TS(2339) FIXME: Property 'isOver' does not exist on type '{ childr... Remove this comment to see the full error message
    const { isOver, isDragging, hasWriteAccess } = customLinkProps

    return (
      <>
        <DragPreviewImage
          src="https://buffer-publish.s3.amazonaws.com/images/drag-link-placeholder.png"
          connect={connectDragPreview}
        />
        <DragWrapper
          aria-dropeffect="move"
          ref={elementRef}
          draggable
          tabIndex={0}
          style={wrapperStyle({ isOver, isDragging })}
          onDragStart={(e) => {
            if (!hasWriteAccess) {
              e.preventDefault()
              e.stopPropagation()
            }
          }}
        >
          {!isDragging && isOver && <SwapIconStyled />}
          {/* @ts-expect-error TS(2741) FIXME: Property 'hasWriteAccess' is missing in type '{ ch... Remove this comment to see the full error message */}
          <LinkPreview isTarget={isOver} {...customLinkProps} />
        </DragWrapper>
      </>
    )
  },
)

LinkDragWrapper.propTypes = {
  // @ts-expect-error TS(2322) FIXME: Type '{ connectDragSource: PropTypes.Validator<(..... Remove this comment to see the full error message
  connectDragSource: PropTypes.func.isRequired,
  connectDropTarget: PropTypes.func.isRequired,
  connectDragPreview: PropTypes.func.isRequired,
}

export default DropTarget(
  'customLink',
  customLinkTarget,
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
  }),
)(
  DragSource('customLink', customLinkSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    connectDragPreview: connect.dragPreview(),
    isDragging: monitor.isDragging(),
  }))(LinkDragWrapper),
)
