import styled, { css, type FlattenSimpleInterpolation } from 'styled-components'
import {
  grayLight,
  grayLighter,
  gray,
  white,
  blueLightest,
} from '@bufferapp/ui/style/colors'
import { fontSizeSmall, fontWeightMedium } from '@bufferapp/ui/style/fonts'
import { AddUpdateButton } from '../AddButton/style'

type CSSRule = string | number | false | FlattenSimpleInterpolation | undefined

const Hour = styled.td<{
  isActive: boolean
  isPast: boolean
  showTime: boolean
  hourLabel: string | number
  isDraggingOver: boolean
}>`
  height: 40px;
  vertical-align: top;
  padding: 8px 11px;
  border-bottom: 1px solid ${grayLight};
  border-right: 1px solid ${grayLight};
  position: relative;
  background-color: ${(props): CSSRule =>
    !props.isActive && props.isPast ? grayLighter : white};

  &:first-child {
    border-left: 1px solid ${grayLight};
  }

  &:before {
    ${(props): CSSRule =>
      props.showTime &&
      css`
        content: '${props.hourLabel}';
        font-size: ${fontSizeSmall};
        font-weight: ${fontWeightMedium};
        color: ${gray};
        position: absolute;
        top: -8px;
        left: 0;
        padding: 0 8px;
        background-color: ${!props.isActive && props.isPast
          ? grayLighter
          : white};
      `}
    }
  }
  &:hover {
    ${AddUpdateButton} {
      opacity: 1;
    }
  }
  ${(props): CSSRule =>
    props.isDraggingOver &&
    css`
      background-color: ${blueLightest};
      opacity: 50%;
    `}
`

export default Hour
