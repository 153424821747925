export const initialButtonOption = {
  title: 'None',
  value: 'none',
}

export const buttonOptions = [
  {
    title: 'None',
    value: 'none',
  },
  {
    title: 'Book',
    value: 'book',
  },
  {
    title: 'Order Online',
    value: 'order',
  },
  {
    title: 'Buy',
    value: 'shop',
  },
  {
    title: 'Learn More',
    value: 'learn_more',
  },
  {
    title: 'Sign Up',
    value: 'signup',
  },
]
