import React from 'react'
import styled from 'styled-components'

const TempImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: 125px;
  border-radius: 3px;
  overflow: hidden;
`

const TempImageContent = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 3px;
`

export const TempImage = ({ imageUrl }: { imageUrl: string }) => (
  <TempImageWrapper>
    <TempImageContent alt="Uploaded media" src={imageUrl} />
  </TempImageWrapper>
)
