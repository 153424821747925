import { gql } from '@apollo/client'

export const SEARCH_IMAGES_QUERY = gql`
  query SearchImages(
    $searchTerm: String
    $page: Int
    $orientation: String
    $source: String
  ) {
    searchImages(
      searchTerm: $searchTerm
      page: $page
      orientation: $orientation
      source: $source
    ) {
      ... on ImageSearchResponse {
        images {
          id
          regularUrl
          thumbnailUrl
          author
          authorUrl
          description
          downloadUrl
        }
        info {
          count
          currentPage
        }
      }

      ... on ImageSearchError {
        userFriendlyMessage
        cause
      }
    }
  }
`

export const TRACK_IMAGES_QUERY = gql`
  query imagesTrackDownload($trackUrl: String) {
    imagesTrackDownload(trackUrl: $trackUrl) {
      ... on MutationBasicResponse {
        success
      }

      ... on BasicError {
        userFriendlyMessage
        cause
      }
    }
  }
`
