import React from 'react'

import { white } from '@bufferapp/ui/style/colors'

export function Star({ className }: { className: string }): JSX.Element {
  return (
    <svg
      width="27"
      height="28"
      viewBox="0 0 27 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`star ${className}`}
    >
      <path
        d="M16.7962 16.8578L13.8296 27.0823L10.8629 16.8578L0.850586 13.8283L10.8629 10.7988L13.8296 0.574219L16.7962 10.7988L26.8085 13.8283L16.7962 16.8578Z"
        fill={white}
      />
    </svg>
  )
}
