import React from 'react'
import {
  type AnimateLayoutChanges,
  defaultAnimateLayoutChanges,
  useSortable,
} from '@dnd-kit/sortable'
import type { UniqueIdentifier } from '@dnd-kit/core'

import useDraggingCursor from '~publish/hooks/useDraggingCursor'
import {
  ColumnDragContext,
  type ColumnDragContextType,
} from './hooks/useDraggableColumn'

import styles from './SortableColumn.module.css'

const animateLayoutChanges: AnimateLayoutChanges = (args) =>
  defaultAnimateLayoutChanges({ ...args, wasDragging: true })

type SortableColumnProps = {
  id: UniqueIdentifier
  disabled?: boolean
  dragOverlay?: boolean
  children: React.ReactNode
}

const SortableColumn = ({
  id,
  disabled = false,
  dragOverlay = false,
  children,
}: SortableColumnProps): JSX.Element => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id,
    animateLayoutChanges,
    disabled,
    data: {
      type: 'container',
    },
  })

  useDraggingCursor(dragOverlay)

  const style = {
    transition,
    '--col-translate-x': transform ? `${Math.round(transform.x)}px` : undefined,
    '--col-translate-y': transform ? `${Math.round(transform.y)}px` : undefined,
    '--col-scale-x': transform?.scaleX ? `${transform.scaleX}` : undefined,
    '--col-scale-y': transform?.scaleY ? `${transform.scaleY}` : undefined,
  }

  if (!dragOverlay && isDragging) {
    return (
      <section
        className={styles.destination}
        style={style}
        id={id as string}
        ref={disabled ? null : setNodeRef}
      />
    )
  }

  const dragHandleProps: ColumnDragContextType = {
    setActivatorNodeRef,
    setNodeRef,
    attributes,
    listeners: listeners as ColumnDragContextType['listeners'],
    style,
    dragging: dragOverlay,
    draggable: !disabled,
  }

  return (
    <ColumnDragContext.Provider value={dragHandleProps}>
      {children}
    </ColumnDragContext.Provider>
  )
}

export { SortableColumn }
