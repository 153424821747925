import twitter from 'twitter-text'
import { PostTypeReel } from '~publish/legacy/post/constants'

const parseInstagramLinks = (postType: string, text?: string | null) =>
  text
    ? twitter
        .extractEntitiesWithIndices(text)
        .map((entity) => {
          if ('hashtag' in entity && entity.hashtag) {
            return {
              displayString: `#${entity.hashtag}`,
              indices: entity.indices,
              rawString: `#${entity.hashtag}`,
              className: postType === PostTypeReel ? 'is-reel' : '',
            }
          }
          if ('screenName' in entity && entity.screenName) {
            return {
              displayString: `@${entity.screenName}`,
              indices: entity.indices,
              rawString: `@${entity.screenName}`,
              className: postType === PostTypeReel ? 'is-reel' : '',
            }
          }
          return null
        })
        .filter((entity) => entity !== null)
    : []

export { parseInstagramLinks }
