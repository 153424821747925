// @ts-expect-error TS(7006) FIXME: Parameter 'profiles' implicitly has an 'any' type.
export const filterProfilesByOrg = (profiles, organization) => {
  if (!organization) {
    return profiles
  }
  return profiles?.filter(
    // @ts-expect-error TS(7006) FIXME: Parameter 'profile' implicitly has an 'any' type.
    (profile) => profile.organizationId === organization.id,
  )
}
