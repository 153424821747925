import { css } from 'styled-components'

export const CustomComboboxRoot = css`
  padding: 3px 0;
  border: 1px solid #ced7df;
  border-radius: 2px;
  white-space: nowrap;
  font-size: 12px;
  color: #627785;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08);
`

export const CustomComboboxItem = css`
  :hover {
    background: #f4f7f9;
  }
`

export const CustomComboboxHighlightedItem = css`
  background: #f4f7f9;
  :hover {
    background: #f4f7f9;
  }
`
