import { EmptyState, Flex } from '@buffer-mono/popcorn'
import React from 'react'
import type { Day } from '~publish/legacy/posting-schedule/types'
import styles from './ScheduleTable.module.css'
import { ScheduleTableColumn } from './ScheduleTableColumn'
import { useAppSelector } from '~publish/legacy/store'
import { selectCurrentProfilePostingDaysIsEmpty } from '~publish/legacy/profile-sidebar/selectors'

type ScheduleTableProps = {
  days: Day[]
  disabled: boolean
}

const ScheduleTable = ({ days, disabled }: ScheduleTableProps): JSX.Element => {
  const emptySchedule = useAppSelector(selectCurrentProfilePostingDaysIsEmpty)
  return (
    <Flex direction="column" className={styles.tableWrapper}>
      <Flex className={styles.table} align="stretch">
        {days.map(({ dayName, times, paused, key }) => (
          <Flex
            align="stretch"
            data-testid="posting-schedule-column"
            key={key}
            className={styles.column}
          >
            <ScheduleTableColumn
              dayName={dayName.toLowerCase()}
              paused={paused}
              times={times}
            />
          </Flex>
        ))}
      </Flex>
      {emptySchedule && (
        <EmptyState
          className={styles.emptyState}
          data-testid="posting-schedule-empty-state"
        >
          <EmptyState.Description>
            You don&apos;t have any posting times set!
            <br />
            Add a new posting time to start publishing posts from your queue.
          </EmptyState.Description>
        </EmptyState>
      )}
    </Flex>
  )
}

export { ScheduleTable }
