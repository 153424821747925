const SUPPORTED_TYPES_TO_PASTE = ['image', 'video', 'application/pdf']

export function getFilesFromClipboardItems(
  clipboardItems: DataTransferItem[],
): File[] {
  if (clipboardItemsIncludeText(clipboardItems)) {
    return []
  }

  return getSupportedFiles(clipboardItems)
}

function getSupportedFiles(clipboardItems: DataTransferItem[]): File[] {
  const items = clipboardItems.filter((item) => isSupported(item))
  return getFiles(items)
}

function isSupported(item: DataTransferItem): boolean {
  return SUPPORTED_TYPES_TO_PASTE.some(
    (supportedType) => item.type.indexOf(supportedType) !== -1,
  )
}

function getFiles(items: DataTransferItem[]): File[] {
  if (items.length === 0) {
    return []
  }

  return items.reduce((acc: File[], item) => {
    const itemAsFile = item.getAsFile()
    if (itemAsFile) {
      acc.push(itemAsFile)
    }
    return acc
  }, [])
}

/**
 * Certain browsers will include a string in the clipboardItems array
 * when text is copied. We don't want to return any files in this case.
 * For example, if you copy text from a Google Doc and paste it into
 * the editor, we don't want to return any files.
 */
function clipboardItemsIncludeText(items: DataTransferItem[]): boolean {
  return items.some(
    (item) => item.kind === 'string' && item.type === 'text/plain',
  )
}
