export * from './FileUploadConfig'

export enum MediaType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  GIF = 'GIF',
  DOCUMENT = 'DOCUMENT',
  UNSUPPORTED = 'UNSUPPORTED',
}

export const SUPPORTED_TYPES = [
  'image/gif',
  'image/heic',
  'image/heif',
  'image/jpeg',
  'image/jpeg',
  'image/png',
  'video/avi',
  'video/x-msvideo',
  'image/webp',
  'video/x-matroska',
  'video/x-matroska',
  'video/quicktime',
  'video/mp4',
  'video/mpeg',
  'video/ogg',
  'video/webm',
  'application/pdf',
]

export enum UploadStatus {
  Added = 'Added',
  Uploading = 'Uploading',
  UploadedToS3 = 'UploadedToS3',
  Completed = 'Completed',
  Failed = 'Failed',
  Preloaded = 'Preloaded',
}
