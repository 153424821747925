import React from 'react'
import { useDispatch } from 'react-redux'
import { actions as modalsActions } from '~publish/legacy/modals'
import {
  SEGMENT_NAMES,
  SERVICE_INSTAGRAM,
  SERVICE_LINKEDIN,
} from '~publish/legacy/constants'
import PaywallModal from '~publish/legacy/shared-components/PaywallModal/PaywallModal'
import { ContentForSimplePaywall } from '~publish/legacy/shared-components/PaywallModal/ContentForSimplePaywall'
import PropTypes from 'prop-types'

export const FirstCommentPaywallModal = ({
  service,
}: {
  service: string
}): JSX.Element => {
  const dispatch = useDispatch()

  const closeModal = (): any => {
    dispatch(modalsActions.hideInstagramFirstCommentPaywallModal())
  }

  let ctaForUgradePath = ''
  let ctaMPMigration = ''
  if (service === SERVICE_INSTAGRAM) {
    ctaForUgradePath = SEGMENT_NAMES.FIRST_COMMENT_PAYWALL_UPGRADE_INSTAGRAM
    ctaMPMigration =
      SEGMENT_NAMES.FIRST_COMMENT_PAYWALL_UPGRADE_TO_MIGRATION_HUB
  }
  if (service === SERVICE_LINKEDIN) {
    ctaForUgradePath = SEGMENT_NAMES.FIRST_COMMENT_PAYWALL_UPGRADE_LINKEDIN
    ctaMPMigration =
      SEGMENT_NAMES.FIRST_COMMENT_LINKEDIN_PAYWALL_UPGRADE_TO_MIGRATION_HUB
  }

  return (
    <PaywallModal
      onClosePaywall={closeModal}
      cta={`publish-firstComment${
        service === SERVICE_LINKEDIN ? 'linkedin' : ''
      }-composer-seeUpgradeOptions`}
      upgradePathName={`firstComment${
        service === SERVICE_LINKEDIN ? 'linkedin' : ''
      }-upgrade`}
      ctaForUgradePath={ctaForUgradePath}
      ctaMPMigration={ctaMPMigration}
    >
      <ContentForSimplePaywall
        keyForTexts="first-comment-paywall"
        paywalImage="https://buffer-publish.s3.amazonaws.com/images/first-comment-paywall-v2.png"
      />
    </PaywallModal>
  )
}

FirstCommentPaywallModal.propTypes = {
  service: PropTypes.string.isRequired,
}
