import React, { useEffect, useState } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { useQuery } from '@apollo/client'

import { Dialog } from '@buffer-mono/popcorn'

import { getFragmentData, graphql } from '~publish/gql'
import {
  IdeaCard,
  IdeaCard_Idea,
} from '~publish/pages/Create/components/IdeaCard'
import { IdeaErrorDialog } from './IdeaErrorDialog'
import { createPage } from '~publish/legacy/routes'

export const GetIdea = graphql(/* GraphQL */ `
  query GetIdea($input: IdeaIdInput!) {
    idea(input: $input) {
      ... on Idea {
        id
        ...IdeaCard_Idea
      }
      ... on CoreWebAppCommonError {
        message
      }
    }
  }
`)

/**
 * Component used to open an idea by ID, rendering an IdeaCard as a detail dialog.
 * Renders when corresponding route is matched.
 *
 * It will attempt to fetch the idea with the provided ideaId, returning it from cache first if available.
 *
 * @param parent - If no returnTo location can be found in location state,
 * use the provided parent route to return to when dialog is closed.
 */
export function IdeaDetailsDialog({
  parent = createPage.route,
}: {
  parent?: string
}): JSX.Element | null {
  const { ideaId } = useParams<{ ideaId: string; id: string }>()
  const history = useHistory()
  const location = useLocation<{ returnTo: Location }>()
  const { data, loading } = useQuery(GetIdea, {
    variables: {
      input: {
        id: ideaId,
      },
    },
    fetchPolicy: 'cache-first',
  })

  const [isOpen, setIsOpen] = useState(false)

  const handleOpenChange = (open: boolean): void => {
    setIsOpen(open)
    if (!open) {
      history.push(location?.state?.returnTo ?? parent)
    }
  }

  useEffect(() => {
    if (data?.idea) {
      setIsOpen(true)
    }
  }, [data?.idea])

  if (loading) {
    return <></>
  }

  if (data?.idea?.__typename === 'CoreWebAppCommonError') {
    return <IdeaErrorDialog error={data?.idea} />
  }

  if (data?.idea?.__typename !== 'Idea' || !data?.idea) {
    handleOpenChange(false)
    return null
  }

  const idea = getFragmentData(IdeaCard_Idea, data.idea)

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <Dialog.Content style={{ padding: 0 }} size="large">
        <IdeaCard idea={idea} />
      </Dialog.Content>
    </Dialog>
  )
}
