import { connect } from 'react-redux'
import LanguagePreferences from './components/LanguagePreferences'
import { actions } from './reducer'

export default connect(
  (state) => ({
    hasTranslationsFlip:
      // @ts-expect-error TS(2339) FIXME: Property 'user' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
      state.user.features?.includes('publish-translations') ?? false,
  }),
  (dispatch) => ({
    // @ts-expect-error TS(7006) FIXME: Parameter 'language' implicitly has an 'any' type.
    onSelectLanguage: (language) => {
      dispatch(actions.setUserLanguage({ language }))
    },
  }),
)(LanguagePreferences)

// export reducer
export { default as reducer, actions, actionTypes } from './reducer'
export { default as middleware } from './middleware'
