import { useCallback } from 'react'
import { useMutation } from '@apollo/client'

import { logError } from '~publish/legacy/utils/logError'
import { graphql } from '~publish/gql'

export const MOVE_IDEA_GROUP_MUTATION = graphql(/* GraphQL */ `
  mutation MoveIdeaGroup($id: ID!, $placeAfterId: ID) {
    moveIdeaGroup(input: { id: $id, placeAfterId: $placeAfterId }) {
      ... on MoveIdeaGroupResponse {
        ideaGroups {
          id
          name
        }
      }
      ... on UnexpectedError {
        message
      }
    }
  }
`)

type MoveIdeaGroup = (id: string, placeAfterId?: string) => Promise<void>

/**
 * Custom hook to perform the move idea group mutation.
 * @returns {MoveIdeaGroup} The function to invoke moving an idea group.
 */
export const useMoveIdeaGroup = (): MoveIdeaGroup => {
  const [executeMoveIdeaGroup] = useMutation(MOVE_IDEA_GROUP_MUTATION, {
    refetchQueries: ['GetIdeasBoard'],
  })

  const moveIdeaGroup = useCallback(
    async (id: string, placeAfterId?: string) => {
      try {
        const { data, errors } = await executeMoveIdeaGroup({
          variables: {
            id,
            placeAfterId,
          },
        })

        if (data?.moveIdeaGroup?.__typename === 'UnexpectedError') {
          throw new Error(data.moveIdeaGroup.message)
        }

        // Handle GraphQL errors if any
        if (errors?.length) {
          const unexpectedError = new Error(
            errors?.[0]?.message || 'Unexpected API response',
          )
          logError(unexpectedError, { metaData: { data, errors } })
          throw unexpectedError
        }
      } catch (error) {
        logError(error as Error, {
          metaData: { variables: { id, placeAfterId } },
        })
        throw error
      }
    },
    [executeMoveIdeaGroup],
  )

  return moveIdeaGroup
}
