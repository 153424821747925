import React from 'react'
import WarningIcon from '@bufferapp/ui/Icon/Icons/Warning'
import CrossIcon from '@bufferapp/ui/Icon/Icons/Cross'

import Notice from '@bufferapp/ui/Notice'
import Text from '@bufferapp/ui/Text'
import PropType from 'prop-types'
import {
  ClickableIconContainer,
  IconContainer,
  NoticeContainer,
} from './styles'
import { draftPropType } from '../ComposerPropTypes'
import AppStore from '../../stores/AppStore'
import ComposerStore from '../../stores/ComposerStore'

// @ts-expect-error TS(7031) FIXME: Binding element 'text' implicitly has an 'any' typ... Remove this comment to see the full error message
const NoticeWrapper = ({ text, callback }) => {
  return (
    <NoticeContainer>
      {/* @ts-expect-error TS(2741) FIXME: Property 'className' is missing in type '{ childre... Remove this comment to see the full error message */}
      <Notice type="note" disableAnimation>
        <IconContainer>
          <WarningIcon size="medium" />
        </IconContainer>
        {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: an... Remove this comment to see the full error message */}
        <Text>{text}</Text>
        <ClickableIconContainer onClick={callback}>
          <CrossIcon size="medium" />
        </ClickableIconContainer>
      </Notice>
    </NoticeContainer>
  )
}

NoticeWrapper.propTypes = {
  text: PropType.string.isRequired,
  callback: PropType.func.isRequired,
}

// @ts-expect-error TS(7031) FIXME: Binding element 'draft' implicitly has an 'any' ty... Remove this comment to see the full error message
const MastodonNotice = ({ draft }) => {
  const [isMentionNoticeDismissed, setIsMentionNoticeDismissed] =
    React.useState(!!localStorage.getItem('isMastodonMentionNoticeDismissed'))

  React.useEffect(() => {
    if (isMentionNoticeDismissed) {
      localStorage.setItem('isMastodonMentionNoticeDismissed', 'true')
    }
  }, [isMentionNoticeDismissed])

  if (
    AppStore.getExpandedComposerId() !== draft.id ||
    !draft.service.isMastodon() ||
    !ComposerStore.getMeta().hasMentionsWithoutServer
  ) {
    return <></>
  }

  return (
    <>
      {ComposerStore.getMeta().hasMentionsWithoutServer &&
        !isMentionNoticeDismissed && (
          <NoticeWrapper
            text="We suggest using the full address (@example@server.com) when
          mentioning a user to ensure it links to the right account—otherwise
          the local user (@example) will be mentioned instead."
            callback={() => setIsMentionNoticeDismissed(true)}
          />
        )}
    </>
  )
}

MastodonNotice.propTypes = {
  draft: draftPropType.isRequired,
}

export default MastodonNotice
