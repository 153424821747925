import React from 'react'

import { PostTypeReel, PostTypeStory } from '~publish/legacy/post/constants'
import { Empty } from '../Common/Empty'
import { InstagramPost } from './components/Post'
import {
  draftPreviewPropType,
  profilePreviewPropType,
} from '../Common/propTypes'
import type { DraftPreview, InstagramPostType, ProfilePreview } from '../types'

import { getMediaProperties } from '../Common/utils'
import { Reel } from './components/Reel'
import { Story } from './components/Story'

import * as Styles from './styles'

const Content = ({
  draftInstagram,
  profile,
  updateType,
}: {
  draftInstagram: InstagramPostType
  profile: ProfilePreview
  updateType: string | null
}): JSX.Element => {
  if (updateType === PostTypeReel) {
    return <Reel content={draftInstagram.content} profile={profile} />
  }

  if (updateType === PostTypeStory) {
    return <Story content={draftInstagram.content} profile={profile} />
  }

  return <InstagramPost content={draftInstagram.content} profile={profile} />
}

export const InstagramPreview = ({
  draft,
  profile,
}: {
  draft: DraftPreview
  profile: ProfilePreview
}): JSX.Element => {
  const containerRef = React.useRef<HTMLDivElement | null>(null)

  const draftInstagram = {
    content: {
      ...draft,
      text: draft.text,
      isReminder: draft.isReminder,
    },
  } as InstagramPostType

  const { hasImages, hasVideo } = getMediaProperties(
    draftInstagram.content,
    null,
  )
  const hasMedia = hasImages || hasVideo

  if (draft.isEmpty || !hasMedia) return <Empty />

  return (
    <Styles.Wrapper data-testid="instagram-preview" ref={containerRef}>
      <Content
        draftInstagram={draftInstagram}
        profile={profile}
        updateType={draft.updateType}
      />
    </Styles.Wrapper>
  )
}

InstagramPreview.propTypes = {
  draft: draftPreviewPropType.isRequired,
  profile: profilePreviewPropType.isRequired,
}
