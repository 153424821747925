import styled, { css } from 'styled-components'
import { grayDarker, green, orange, white } from '@bufferapp/ui/style/colors'

const baseChevronStyles = css`
  content: '';
  position: absolute;
  height: 18px;
  width: 18px;
  rotate: 45deg;
  background: ${white};
  border-radius: 3px;
`

export const Wrapper = styled.div<{ placement: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${white};
  position: relative;
  width: 266px;
  padding: 24px 32px;
  border-radius: 8px;
  box-shadow: 0px 8px 24px 0px rgba (0, 0, 0, 0.24);

  ${({ placement }) =>
    placement === 'left' &&
    css`
      transform: translateX(28px);

      &::after {
        ${baseChevronStyles}
        right: -8px;
      }
    `}

  ${({ placement }) =>
    placement === 'top' &&
    css`
      transform: translateY(20px);

      &::after {
        ${baseChevronStyles}
        bottom: -8px;
      }
    `}

  .badge {
    color: ${white};
    background-color: ${green};
  }

  .title,
  .text {
    text-align: center;
  }

  .title {
    color: ${grayDarker};
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
  }

  .section {
    margin-bottom: 16px;
  }
`

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  button {
    &:first-of-type {
      margin-bottom: 16px;
    }
  }
`

export const Beacon = styled.div<{ placement: string }>`
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: ${orange};

  /* Ripple animation */
  &::before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: -5px;
    top: -5px;
    background-color: ${orange};
    border-radius: 50%;
    animation: radiate 1.7s infinite linear;
  }

  ${({ placement }) =>
    placement === 'top' &&
    css`
      bottom: -30px;
      left: 0;
      right: 0;
      margin-left: auto;
      margin-right: auto;
    `}

  ${({ placement }) =>
    placement === 'left' &&
    css`
      right: -36px;
      top: 0;
      bottom: 0;
      margin-top: auto;
      margin-bottom: auto;
    `}

  @keyframes radiate {
    0% {
      transform: scale(0.1);
      opacity: 0;
    }

    50% {
      opacity: 0.3;
    }

    100% {
      opacity: 0;
      transform: scale(1.5);
    }
  }
`
