import {
  comboboxActions,
  getEditorString,
  getNodeString,
  getPlugin,
  getPointAfter,
  getPointBefore,
  getRange,
  insertNodes,
  setSelection,
  type TText,
  type WithPlatePlugin,
} from '@udecode/plate'
import { SearchIndex } from 'emoji-mart'
import { Range } from 'slate'
import type {
  BufferEditor,
  BufferValue,
} from '~publish/legacy/editor/BufferEditor/types.plate'

import { EmojiSearchElement } from './nodes'
import { createEmojiOnSelectItem, removeEmojiSearchNode } from './transforms'
import type { EmojiPluginOptions, EmojiSearchData } from './types'

export const withEmoji = (
  editor: BufferEditor,
  { options }: WithPlatePlugin<EmojiPluginOptions, BufferValue>,
) => {
  const { id, inputCreation } = options
  const trigger = options?.trigger ?? EmojiSearchElement.trigger
  const { type } = getPlugin<EmojiPluginOptions, BufferValue>(
    editor,
    EmojiSearchElement.type,
  )

  const { apply, insertText: _insertText, deleteBackward } = editor

  editor.deleteBackward = (unit) => {
    const currentMentionInput = EmojiSearchElement.findEmojiSearch(editor)
    if (currentMentionInput && getNodeString(currentMentionInput[0]) === '') {
      return removeEmojiSearchNode(editor, currentMentionInput[1])
    }

    deleteBackward(unit)
  }

  editor.insertText = (text) => {
    // , the text does not match the plugin trigger,
    // or the active selection is inside an existing emoji search node:
    // 1. If the inserted text does not match the emoji search trigger,
    //    simply insert the text as normal which will update the search value
    // 2. If it does match the emoji search trigger,
    // 3. If no search text is present, it means the user is inserting
    //    a double colon, ::, in which case, we should just insert as normal
    const selection = editor?.selection

    // If there is no active selection, simply insert the text
    if (!selection) return _insertText(text)

    if (EmojiSearchElement.isSelectionInEmojiSearch(editor)) {
      // If we're already in an emoji search node
      // and the text doesn't match the plugin's trigger
      // simply insert the text
      if (text !== trigger) return _insertText(text)

      const currentSearchNode = EmojiSearchElement.findEmojiSearch(editor)
      const searchText =
        (currentSearchNode?.[0].children[0] as TText).text ?? ''

      // If we're already in an emoji search node,
      // the text does match the plugin's trgger,
      // and no search text is present,
      // it means the user is inserting a double colon, ::,
      // in which case, we should just insert as normal
      if (!searchText) return _insertText(text)

      // If we're already in an emoji search node,
      // the text does match the plugin's trigger,
      // and there is search text already entered,
      // it means that the user is closing the search
      // by repeating the trigger, eg :smile:
      // Do a search with everything between the two : characters
      // and if an exact match is found, insert that emoji
      SearchIndex.search(searchText).then(
        (results: EmojiSearchData[] | null | undefined): void => {
          const firstItem = results?.[0]
          if (searchText === firstItem?.id)
            createEmojiOnSelectItem()(editor, {
              key: firstItem.id,
              text: firstItem.id,
              data: firstItem,
            })
        },
      )
    }

    // If we're not inside an emoji search node and
    // the text does not match the plugin's trigger,
    // simply insert the text
    if (text !== trigger) return _insertText(text)

    // Make sure an emoji search node is created at the beginning of line or after a whitespace
    const previousChar = getEditorString(
      editor,
      getRange(editor, selection, getPointBefore(editor, selection)),
    )

    const nextChar = getEditorString(
      editor,
      getRange(editor, selection, getPointAfter(editor, selection)),
    )

    const beginningOfLine = previousChar === ''
    const endOfLine = nextChar === ''
    const precededByWhitespace = previousChar === ' '
    const followedByWhitespace = nextChar === ' '

    if (
      (beginningOfLine || precededByWhitespace) &&
      (endOfLine || followedByWhitespace)
    ) {
      const data: EmojiSearchElement = {
        type,
        children: [{ text: '' }],
        trigger,
      }
      if (inputCreation) {
        data[inputCreation.key] = inputCreation.value
      }
      return insertNodes<EmojiSearchElement>(editor, data)
    }

    return _insertText(text)
  }

  editor.apply = (operation) => {
    apply(operation)

    if (operation.type === 'insert_text' || operation.type === 'remove_text') {
      const currentEmojiSearchNode = EmojiSearchElement.findEmojiSearch(editor)
      if (currentEmojiSearchNode) {
        const currentComboboxText = getNodeString(currentEmojiSearchNode[0])
        if (currentComboboxText) comboboxActions.text(currentComboboxText)
        else comboboxActions.items([])
      }
    } else if (operation.type === 'set_selection') {
      const previousEmojiSearchNodePath = Range.isRange(operation.properties)
        ? EmojiSearchElement.findEmojiSearch(editor, {
            at: operation.properties,
          })?.[1]
        : undefined

      const currentEmojiSearchNodePath = Range.isRange(operation.newProperties)
        ? EmojiSearchElement.findEmojiSearch(editor, {
            at: operation.newProperties,
          })?.[1]
        : undefined

      if (previousEmojiSearchNodePath && !currentEmojiSearchNodePath) {
        removeEmojiSearchNode(editor, previousEmojiSearchNodePath)
      }

      if (currentEmojiSearchNodePath) {
        comboboxActions.targetRange(editor.selection)
      }
    } else if (
      operation.type === 'insert_node' &&
      EmojiSearchElement.is(operation.node)
    ) {
      if ((operation.node as EmojiSearchElement).trigger !== trigger) {
        return
      }

      const text =
        ((operation.node as EmojiSearchElement).children as TText[])[0]?.text ??
        ''

      if (
        (inputCreation === undefined ||
          operation.node[inputCreation.key] === inputCreation.value) &&
        id
      ) {
        // Needed for undo - after an undo a mention insert we only receive
        // an insert_node with the mention input, i.e. nothing indicating that it
        // was an undo.
        setSelection(editor, {
          anchor: { path: operation.path.concat([0]), offset: text.length },
          focus: { path: operation.path.concat([0]), offset: text.length },
        })

        comboboxActions.reset()
        comboboxActions.open({
          activeId: id,
          text,
          targetRange: editor.selection,
        })
      }
    } else if (
      operation.type === 'remove_node' &&
      EmojiSearchElement.is(operation.node)
    ) {
      if ((operation.node as EmojiSearchElement).trigger !== trigger) {
        return
      }

      comboboxActions.reset()
    }
  }

  return editor
}
