/**
 * There are different ways in which we define entity positions in the editor
 *
 * It is sometimes difficult to model those so this types and helpers try to help
 * with that
 */
export type WithIndices<T> = T & {
  indices: [number, number]
}

export type WithPosition<T> = T & {
  start: number
  length: number
}

export const WithPosition = {
  toWithIndices: <T>(value: WithPosition<T>): WithIndices<T> => {
    const { start, length } = value

    return {
      ...value,
      indices: [start, start + length],
    }
  },

  fromWithIndices: <T>(value: WithIndices<T>): WithPosition<T> => {
    const { indices } = value

    return {
      ...value,
      start: indices[0],
      length: indices[1] - indices[0],
    }
  },
}
