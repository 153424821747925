import type {
  ChannelData,
  Draft,
  GoogleBusinessChannelData,
} from '../entities/Draft'
import { transformStringTo24HrTime } from './StringTimeTransformer'
import {
  ChannelFieldConfiguration,
  type PostFields,
} from '@buffer-mono/reminders-config'
import { getFieldsToDisplay } from '~publish/legacy/reminders/components/new-reminders/components/channel-fields/helpers'

const transformGBPWhatsNew = (
  googleBusinessData: GoogleBusinessChannelData,
): GoogleBusinessChannelData => {
  if (googleBusinessData.cta === 'none' || !googleBusinessData.cta) {
    googleBusinessData.link = undefined
  }

  googleBusinessData.title = undefined
  googleBusinessData.start_date = undefined
  googleBusinessData.end_date = undefined
  googleBusinessData.start_time = undefined
  googleBusinessData.end_time = undefined
  googleBusinessData.code = undefined
  googleBusinessData.terms = undefined

  return googleBusinessData
}

const transformGBPEvent = (
  googleBusinessData: GoogleBusinessChannelData,
): GoogleBusinessChannelData => {
  if (googleBusinessData.cta === 'none' || !googleBusinessData.cta) {
    googleBusinessData.link = undefined
  }

  googleBusinessData.code = undefined
  googleBusinessData.terms = undefined

  return googleBusinessData
}

const transformGBPOffer = (
  googleBusinessData: GoogleBusinessChannelData,
): GoogleBusinessChannelData => {
  googleBusinessData.cta = undefined

  return googleBusinessData
}

const transformGoogleBusinessData = (
  updateType: string,
  googleBusinessData: GoogleBusinessChannelData,
): GoogleBusinessChannelData => {
  let transformedData: GoogleBusinessChannelData = {}
  if (updateType === 'whats_new') {
    transformedData = transformGBPWhatsNew(googleBusinessData)
  }

  if (updateType === 'offer') {
    transformedData = transformGBPOffer(googleBusinessData)
  }

  if (updateType === 'event') {
    transformedData = transformGBPEvent(googleBusinessData)
  }

  // remove empty keys
  transformedData = Object.fromEntries(
    Object.entries(transformedData).filter(
      ([, v]) => v !== null && v !== '' && v !== undefined,
    ),
  )

  if (transformedData.start_time && transformedData.end_time) {
    const transformedStartTime = transformStringTo24HrTime(
      transformedData.start_time,
    )
    const transformedEndTime = transformStringTo24HrTime(
      transformedData.end_time,
    )
    transformedData.start_time = `${transformedStartTime.hours}:${transformedStartTime.minutes}`
    transformedData.end_time = `${transformedEndTime.hours}:${transformedEndTime.minutes}`
  }

  return transformedData
}

const filterChannelDataBasedOnFieldsConfig = (args: {
  draft: Draft
  channelData: ChannelData
}): ChannelData => {
  const { draft, channelData } = args
  const service = draft.service.name
  const updateType = draft.updateType ?? 'post'

  if (
    !ChannelFieldConfiguration[service] ||
    !ChannelFieldConfiguration[service].enabled ||
    !ChannelFieldConfiguration[service].postTypes[updateType]
  ) {
    return channelData
  }

  const serviceChannelData = channelData[service as keyof ChannelData]
  if (!serviceChannelData) {
    return channelData
  }

  const filteredChannelData = Object.entries(serviceChannelData).reduce(
    (acc, [key, value]) => {
      if (getFieldsToDisplay({ draft }).includes(key as PostFields)) {
        acc[key] = value
      }
      return acc
    },
    {} as Record<string, unknown>,
  )

  return {
    ...channelData,
    [service]: filteredChannelData,
  }
}

const transformChannelData = (args: { draft: Draft }): ChannelData | null => {
  const { draft } = args
  const channelData = draft.channelData
  const updateType = draft.updateType ?? 'post'

  if (!channelData) {
    return null
  }

  const transformedChannelData = { ...channelData }

  if (channelData.googlebusiness) {
    transformedChannelData.googlebusiness = transformGoogleBusinessData(
      updateType,
      channelData.googlebusiness,
    )
  }

  return filterChannelDataBasedOnFieldsConfig({
    draft,
    channelData: transformedChannelData,
  })
}

export default transformChannelData
