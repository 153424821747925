import { Flex, Paragraph, type ParagraphProps } from '@buffer-mono/popcorn'
import clsx from 'clsx'
import React from 'react'
import { isFileTransfer } from '~publish/legacy/uploads/utils/isFileTransfer'
import styles from './UploadDropzone.module.css'

interface UploadDropzoneContextType {
  active: boolean
  disabled: boolean
}
const UploadDropzoneContext =
  React.createContext<UploadDropzoneContextType | null>(null)

function useUploadDropzoneContext(): UploadDropzoneContextType {
  const context = React.useContext(UploadDropzoneContext)

  if (!context) {
    throw new Error(
      'UploadDropzone compound components cannot be rendered outside the UploadDropzone component',
    )
  }

  return context
}

/**
 * UploadDropzone Root
 *
 * [data-state]: open | closed
 * [data-disabled]: true | false
 */

interface UploadDropzoneRootProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, 'onDrop'> {
  className?: string
  /**
   * Whether the dropzone is disabled
   * @default false
   */
  disabled?: boolean
  /**
   * onDrop callback to run when files are successfully dropped
   */
  onDrop?: (files: File[]) => void
}

const UploadDropzoneRoot = React.forwardRef<
  HTMLDivElement,
  UploadDropzoneRootProps
>(
  (
    { className, disabled = false, onDrop, children, ...props },
    forwardedRef,
  ) => {
    const [active, setActive] = React.useState(false)
    const handleDragOver = React.useCallback(
      (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault()
        event.stopPropagation()

        // If 'disabled' is true, don't allow any dropEffect
        // Otherwise, correctly indicate that the file is being copied
        event.dataTransfer.dropEffect = disabled ? 'none' : 'copy'

        if (disabled || !isFileTransfer(event)) return
        setActive(true)
      },
      [disabled],
    )

    const handleDragLeave = React.useCallback(
      (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault()
        event.stopPropagation()

        if (disabled || !isFileTransfer(event)) return
        setActive(false)
      },
      [disabled],
    )

    const handleDrop = React.useCallback(
      (event: React.DragEvent<HTMLDivElement>) => {
        event.preventDefault()
        event.stopPropagation()

        if (disabled || !isFileTransfer(event)) return
        setActive(false)

        // @TODO: consider using getDroppedFiles approach
        // @see https://github.com/transloadit/uppy/tree/main/packages/%40uppy/utils/src/getDroppedFiles
        const files: File[] = Array.from(event.dataTransfer.files)
        onDrop?.(files)
      },
      [disabled, onDrop],
    )

    return (
      <UploadDropzoneContext.Provider value={{ active, disabled }}>
        <div
          ref={forwardedRef}
          className={clsx(styles.wrapper, className)}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          data-state={active && !disabled ? 'open' : 'closed'}
          data-disabled={disabled}
          {...props}
        >
          {children}
        </div>
      </UploadDropzoneContext.Provider>
    )
  },
)

UploadDropzoneRoot.displayName = 'UploadDropzone.Root'

/**
 * Overlay for the UploadDropzone
 *
 * [data-state]: open | closed
 * [data-disabled]: true | false
 */

interface UploadDropzoneOverlayProps
  extends React.HTMLAttributes<HTMLDivElement> {
  className?: string
}

const UploadDropzoneOverlay = React.forwardRef<
  HTMLDivElement,
  UploadDropzoneOverlayProps
>(({ className, children, ...props }, forwardedRef) => {
  const { active, disabled } = useUploadDropzoneContext()

  return (
    <Flex
      ref={forwardedRef}
      className={clsx(styles.overlay, className)}
      align="center"
      justify="center"
      data-state={active && !disabled ? 'open' : 'closed'}
      data-disabled={disabled}
      {...props}
    >
      <Flex
        className={styles.overlayContentWrapper}
        align="center"
        justify="center"
        direction="column"
      >
        {children}
      </Flex>
    </Flex>
  )
})

UploadDropzoneOverlay.displayName = 'UploadDropzone.Overlay'

/**
 * Content to be displayed in the UploadDropzone's Overlay
 */

interface UploadDropzoneContentProps
  extends React.HTMLAttributes<HTMLDivElement> {
  className?: string
}
const UploadDropzoneContent = React.forwardRef<
  HTMLDivElement,
  UploadDropzoneContentProps
>(({ className, children, ...props }, forwardedRef) => {
  return (
    <Flex
      ref={forwardedRef}
      className={clsx(styles.content, className)}
      align="center"
      justify="center"
      direction="column"
      {...props}
    >
      {children}
    </Flex>
  )
})

UploadDropzoneContent.displayName = 'UploadDropzone.Content'

/**
 * Description for the UploadDropzone's Content
 */

type UploadDropzoneDescriptionProps = ParagraphProps

const UploadDropzoneDescription = React.forwardRef<
  HTMLHeadingElement,
  UploadDropzoneDescriptionProps
>(({ className, ...props }, forwardedRef) => (
  <Paragraph
    ref={forwardedRef}
    className={clsx(styles.description, className)}
    weight="bold"
    size="md"
    {...props}
  />
))

UploadDropzoneDescription.displayName = 'UploadDropzone.Description'

/**
 * Icon for the UploadDropzone's Content
 */

type UploadDropzoneIconProps = React.HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode
  className?: string
}

const UploadDropzoneIcon = React.forwardRef<
  HTMLImageElement,
  UploadDropzoneIconProps
>(({ className, children }, forwardedRef) => (
  <div className={clsx(styles.icon, className)} ref={forwardedRef} aria-hidden>
    {children}
  </div>
))

UploadDropzoneIcon.displayName = 'OverlayContent.Icon'

export {
  UploadDropzoneRoot as Root,
  UploadDropzoneOverlay as Overlay,
  UploadDropzoneContent as Content,
  UploadDropzoneDescription as Description,
  UploadDropzoneIcon as Icon,
}
export type {
  UploadDropzoneRootProps as RootProps,
  UploadDropzoneOverlayProps as OverlayProps,
  UploadDropzoneContentProps as OverlayContentProps,
}
