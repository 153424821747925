import { getNodeEntries, type GetNodeEntriesOptions } from '@udecode/plate'
import type { NodeEntry } from 'slate'
import type { BufferEditor } from '~publish/legacy/editor/BufferEditor/types.plate'
import { LinkElement } from './LinkElement'

export const getAllLinkNodes = (
  editor: BufferEditor,
  options: GetNodeEntriesOptions = {},
): NodeEntry<LinkElement>[] => {
  const linkNodes = getNodeEntries<LinkElement>(editor, {
    at: [],
    ...options,
    match: (n) => LinkElement.is(n),
    mode: 'all',
  })

  return Array.from(linkNodes)
}
