import { fontFamily } from '@bufferapp/ui/style/fonts'
import { blueLightest, grayLight } from '@bufferapp/ui/style/colors'
import styled from 'styled-components'

export const SelectWrapper = styled.div`
  display: flex;
  max-width: 295px;
  justify-content: center;
  margin-left: auto;
  input[type='text'] {
    font-family: ${fontFamily};
  }
  button {
    padding: 6px 8px 8px 10px;
  }

  div[class*="SelectStyled"]{
    width: 295px;
    right: 0;
    left: unset;

    div[class*="CustomItemContainer"]{
      border-radius: 0 0 4px 4px;
    }
  }

  #searchInput{
    padding: 16px 16px 8px 16px;
    border: none;

    svg{
      display: none;
    }

    div[class*='SearchWrapper']{
      margin: 0;
      position: relative;

      input{
        height: unset;
        padding: 8px;
        border: 1px solid ${grayLight};
        transition: all 0.1s ease-out;
        
        &:focus {
          box-shadow: 0px 0px 0px 4px ${blueLightest};
          border: 1px solid #2C4BFF;
        }
      }
    }
  }
  
  ul{
    padding: 0 12px 12px;
    
    li{
      padding 8px;
      min-height: unset;
      margin: 0;

      > div{
        padding: 0;
      }
      
      p {
        margin-left: 0 !important;
      }

      input[type='checkbox'] {
        height: 16px;
        width: 16px;
        margin: 0 12px 0 0;
      }

      span[class*='IconWrapper']{
        margin-right: 6px;
      }

      div[class*='Color']{
        margin: 0;
      }
    }
  }
`

export const Color = styled.div<{ $smallMargin?: boolean; color: string }>`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: ${(props): string => props.color};
  margin-right: ${(props): string => (props.$smallMargin ? '6px' : '10px')};
  display: flex;
  flex-shrink: 0;
`

export const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  p {
    margin-left: 0;
  }

  span {
    margin-right: 8px;
  }
`

export const TagLabelWrapper = styled.div`
  display: flex;
`

export const TagCheckboxWrapper = styled.input<{ disabled: boolean }>`
  margin-right: 12px;
  cursor: pointer;
  pointer-events: ${({ disabled }): string => (disabled ? 'none' : 'auto')};
`
