import React, { useEffect } from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { actions as profileSidebarActions } from '~publish/legacy/profile-sidebar/reducer'

import ProfilePage from '~publish/legacy/profile-page'
import type { Profile } from '~publish/legacy/profile-sidebar/types'
import {
  DEFAULT_TAB_ID,
  generic,
  getProfilesParams,
  isServiceRoute,
  profileChildTabPages,
  profilePages,
  profileTabPages,
} from '~publish/legacy/routes'
import { useAppDispatch, useAppSelector } from '~publish/legacy/store'
import { selectCurrentProfileId } from '~publish/legacy/profile-sidebar/selectors'

const ProfilePages = (args: { profiles: Profile[] }): JSX.Element => {
  const { profiles } = args
  const location = useLocation()
  const dispatch = useAppDispatch()
  const selectedProfileId = useAppSelector(selectCurrentProfileId)
  const { pathname } = location
  let profile: Profile | undefined
  let tabId = DEFAULT_TAB_ID

  const serviceRouteParams = isServiceRoute({ pathname })
  const profileRouteParams = getProfilesParams({ pathname })

  // Check if the route is a service route (e.g. /profile/facebook)
  if (serviceRouteParams?.service) {
    profile =
      profiles &&
      [...profiles].find((p) => p.service === serviceRouteParams.service)
    tabId = serviceRouteParams.tabId || DEFAULT_TAB_ID
  }
  // The rest of the routes are profile routes (e.g. /profile/123)
  else if (profileRouteParams?.profileId) {
    profile =
      profiles &&
      [...profiles].find((p) => p.id === profileRouteParams?.profileId)
    tabId = profileRouteParams?.tabId || DEFAULT_TAB_ID
  }

  useEffect(() => {
    if (profile && profile?.id !== selectedProfileId) {
      dispatch(
        profileSidebarActions.selectProfile({
          profile,
        }),
      )
    }
  }, [selectedProfileId, profile, dispatch])

  return (
    <Switch>
      {profile && serviceRouteParams && (
        <Redirect
          to={profileTabPages.getRoute({
            profileId: profile.id,
            tabId,
          })}
        />
      )}
      {profile && (
        <Route
          path={[profileChildTabPages.route, profileTabPages.route]}
          render={(props): JSX.Element => <ProfilePage {...props} />}
        />
      )}
      {profile && (
        <Redirect from={profilePages.route} to={profileTabPages.defaultRoute} />
      )}
      <Redirect to={generic.route} />
    </Switch>
  )
}

export default ProfilePages
