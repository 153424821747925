import React from 'react'
import AppStore from '../stores/AppStore'
import NetworkIcon from './NetworkIcon'

import styles from './css/Composer.module.css'
import { ThreadsHashtagCoachmark } from './threads-hashtag-coachmark/ThreadsHashtagCoachmark'

interface ComposerNetworkIconProps {
  draft: any
}

const ComposerNetworkIcon = ({
  draft,
}: ComposerNetworkIconProps): JSX.Element => {
  const type = draft.service.name
  const numSelectedProfiles = AppStore.getSelectedProfilesForService(
    draft.id,
  ).length

  const isExpanded = AppStore.getExpandedComposerId() === draft.id

  const socialNetworkIconClassNames = []
  socialNetworkIconClassNames.push(
    draft.isSaved
      ? styles.lockedNetworkIcon
      : styles[`${draft.service.name}Icon`],
  )
  if (numSelectedProfiles > 1) {
    socialNetworkIconClassNames.push(styles.iconWithProfileCount)
  }
  if (!isExpanded) {
    socialNetworkIconClassNames.push(styles.colapsedNetworkIcon)
  }
  const socialNetworkIconClassName = socialNetworkIconClassNames.join(' ')

  return (
    <ThreadsHashtagCoachmark serviceName={type}>
      <NetworkIcon
        type={type}
        isActive
        size="smedium"
        className={socialNetworkIconClassName}
        numSelectedProfiles={numSelectedProfiles}
      />
    </ThreadsHashtagCoachmark>
  )
}

export default ComposerNetworkIcon
