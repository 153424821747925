export type Arguments<T> = [T] extends [(...args: infer U) => unknown]
  ? U
  : [T] extends [void]
  ? []
  : [T]

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ListenerFunction = (...args: any[]) => void

type BaseEventMap = Record<string, ListenerFunction>

type InternalEventMap<EVENTS extends BaseEventMap> = {
  [K in keyof EVENTS]?: ListenerFunction[]
}

export class WebEventEmitter<Events extends BaseEventMap> {
  private eventMap: InternalEventMap<Events> = {}

  /**
   * Emits an event
   * @param event Event name
   * @param args Arguments to emit with
   */
  public emit<E extends keyof Events>(
    event: E,
    ...args: Arguments<Events[E]>
  ): boolean {
    const listeners = this.eventMap[event]
    if (!listeners) return false
    listeners.forEach((callback) => {
      callback(...args)
    })
    return true
  }

  /**
   * Subscribes to an event
   * @param event Event name
   * @param listener Callback function to execute when event is emitted
   * @returns
   */
  public on<E extends keyof Events>(event: E, listener: Events[E]): this {
    if (!this.eventMap[event]) this.eventMap[event] = []
    // this.eventMap[event] = this.eventMap[event] ?? [];
    this.eventMap[event]?.push(listener)

    return this
  }

  /**
   * Subscribes to event once
   * @param event Event name
   * @param listener Callback function to execute when event is emitted
   */
  public once<E extends keyof Events>(event: E, listener: Events[E]): this {
    // FIX this will not be cleaned up if offAll is used
    // @ts-expect-error TS(7019) FIXME: Rest parameter 'args' implicitly has an 'any[]' ty... Remove this comment to see the full error message
    const sub = (...args) => {
      this.off(event, sub as Events[E])

      // eslint-disable-next-line prefer-rest-params
      // listener.apply(this, args);
      listener(...args)
    }

    this.on(event, sub as Events[E])

    return this
  }

  /**
   * Unsubscribes from event
   * @param event Event name
   * @param listener Callback function used when subscribed
   */
  public off<E extends keyof Events>(event: E, listener: Events[E]): this {
    const listeners = this.eventMap[event]
    if (!listeners) return this

    for (let i = listeners.length - 1; i >= 0; i -= 1) {
      if (listeners[i] === listener) {
        listeners.splice(i, 1)
        break
      }
    }

    if (listeners.length === 0) {
      delete this.eventMap[event]
    }

    return this
  }

  /**
   * Unsubscribes all from event. If no arguments are passed,
   * all events with given name are removed
   * @param event Event name
   * @returns
   */
  public offAll<E extends keyof Events>(event?: E): this {
    if (event) {
      const listeners = this.eventMap[event]
      if (!listeners) return this
      delete this.eventMap[event]
    } else {
      this.eventMap = {}
    }
    return this
  }

  public hasListeners<E extends keyof Events>(event?: E): boolean {
    if (event) {
      return !!this.eventMap[event]
    }
    return Object.keys(this.eventMap).length > 0
  }
}
