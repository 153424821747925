import React from 'react'
import styled, { css, type FlattenSimpleInterpolation } from 'styled-components'
import { addDays, format, isSameDay } from 'date-fns'
import { grayLight, grayDark, blue, white } from '@bufferapp/ui/style/colors'
import { fontSize, fontWeightMedium } from '@bufferapp/ui/style/fonts'
import { useWeeklyDates } from '~publish/pages/Calendar/hooks/useWeeklyDates'

type CSSRule = string | number | false | FlattenSimpleInterpolation | undefined

const Day = styled.th<{
  isActive: boolean
  headerShadow: boolean
  key: string
}>`
  padding: 10px;
  font-weight: ${fontWeightMedium};
  font-size: ${fontSize};
  word-spacing: 8px;
  color: ${(props): CSSRule => (props.isActive ? blue : grayDark)};
  background-color: ${white};
  text-align: center;
  border-top: 1px solid ${grayLight};
  border-bottom: 1px solid ${grayLight};
  border-right: 1px solid ${grayLight};
  position: sticky;
  top: 0;
  ${(props): CSSRule =>
    props.headerShadow &&
    css`
      box-shadow: 0 5px 8px -7px rgba(0, 0, 0, 0.16);
    `}
  }
  &:first-child {
    border-left: 1px solid ${grayLight};
  }
`

const HeaderRow = styled.tr`
  // Note Firefox will not fully respect the heights when dealing with table rows
  height: 32px;
`

type DayOfWeekHeaderProps = {
  currentDate: Date
  withDay: boolean
  headerShadow: boolean
}

const DayOfWeekHeader = ({
  currentDate,
  withDay,
  headerShadow,
}: DayOfWeekHeaderProps): JSX.Element => {
  const daysOfWeek = (): JSX.Element => {
    // Sunday 1 or Sunday format
    const dateFormat = withDay ? 'EEEE d' : 'EEEE'
    const days: JSX.Element[] = []
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { weekStart } = useWeeklyDates(currentDate)
    // Generates 7 days after start of the week date
    ;[...Array(7)].forEach((_, i) => {
      const day = addDays(weekStart, i)
      days.push(
        <Day
          key={day.toString()}
          headerShadow={headerShadow}
          isActive={isSameDay(new Date(), day) && withDay}
        >
          {format(day, dateFormat)}
        </Day>,
      )
    })
    return <HeaderRow>{days}</HeaderRow>
  }

  return <thead>{daysOfWeek()}</thead>
}

export default DayOfWeekHeader
