import styled, { css } from 'styled-components'

import CommentSquareOutlineIcon from '@bufferapp/ui/Icon/Icons/CommentSquareOutline'
import ShareArrowFilledIcon from '@bufferapp/ui/Icon/Icons/ShareArrowFilled'
import ThumbsupFilledIcon from '@bufferapp/ui/Icon/Icons/ThumbsupFilled'
import { white } from '@bufferapp/ui/style/colors'
import { LinkifiedText as LinkifiedTextCommon } from '~publish/legacy/shared-components/LinkifiedText'
import {
  Video as CommonVideo,
  PlayButton as CommonPlayButton,
} from '../../../Common/VideoPlayer/styles'
import { VideoPlayer as VideoPlayerCommon } from '../../../Common/VideoPlayer'
import { PostWrapper as PostWrapperCommon } from '../../../Common/styles'
import { ImageOrientation } from '../../../Common/utils'

export const PostWrapper = styled(PostWrapperCommon)`
  border: none;
  border-radius: 4px;
`

export const Video = css`
  ${CommonVideo}
`

export const PlayButton = css`
  ${CommonPlayButton}
  background-color: rgba(255, 255, 255, 0.8);

  svg {
    color: rgba(0, 0, 0, 0.8);
    fill: rgba(0, 0, 0, 0.8);
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
    svg {
      color: rgba(0, 0, 0, 0.9);
      fill: rgba(0, 0, 0, 0.9);
    }
  }
`

export const VideoWrapper = css`
  position: relative;
  width: 100%;

  video {
    ${Video}
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.2) 8.17%,
      rgba(0, 0, 0, 0) 20.79%,
      rgba(0, 0, 0, 0) 71.68%,
      rgba(0, 0, 0, 0.2) 83.07%
    );
    pointer-events: none;
  }

  button {
    ${PlayButton}
  }
`

export const VideoPlayer = styled(VideoPlayerCommon)<{ orientation: string }>`
  ${VideoWrapper}
  height: ${({ orientation }) =>
    orientation === ImageOrientation.Square ? '300px' : '536px'};
`

export const MetadataWrapper = styled.div`
  position: absolute;
  bottom: 16px;
  width: 228px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 12px;
`

export const LinkifiedText = styled(LinkifiedTextCommon)`
  font-weight: 400 !important;
  line-height: 16px; !important;
  color: ${white} !important;
  overflow-wrap: break-word;
  margin-bottom: 20px;
  width: 100%;

  > a {
    font-size: 12px !important;
    font-weight: 700 !important;
    line-height: 16px; !important;
    color: ${white} !important;
    cursor: default !important;
  }
`

export const UserDataWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Avatar = styled.div<{ src: string; fallbackUrl: string }>`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background:
    url(${({ src }) => src}) center no-repeat,
    url(${({ fallbackUrl }) => fallbackUrl}) center no-repeat;
  background-size: cover;
  background-position: center;
`

export const Username = styled.span`
  margin-left: 6px;
  display: inline-block;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  color: ${white};
`

export const SubscribeButton = styled.div`
  display: flex;
  padding: 4px;
  gap: 8px;
  background: #b80000;
  border-radius: 2px;
  color: ${white};
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  align-items: center;
  margin-left: 6px;
`

export const IconsSideSection = styled.div<{ orientation: string }>`
  position: absolute;
  right: 12px;
  bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: ${({ orientation }) => {
    if (orientation === ImageOrientation.Square) {
      return '8px'
    }
    return '28px'
  }};
  align-items: center;
`

export const IconCss = css`
  width: 20px;
  height: 20px;
  color: ${white};
`

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`

export const ThumbsUpIcon = styled(ThumbsupFilledIcon)`
  ${IconCss};
`

export const ThumbsDownIcon = styled(ThumbsupFilledIcon)`
  ${IconCss};
  transform: rotate(180deg);
`

export const CommentIcon = styled(CommentSquareOutlineIcon)`
  ${IconCss};
`

export const ShareIcon = styled(ShareArrowFilledIcon)`
  ${IconCss};
`

export const IconLabel = styled.span`
  font-weight: 500;
  font-size: 8px;
  line-height: 9.38px;
  color: ${white};
`

export const AudioAvatar = styled.div<{ src: string; fallbackUrl: string }>`
  display: flex;
  width: 34px;
  height: 34px;
  border: 1px solid #fff;
  border-radius: 4px;
  background:
    url(${({ src }) => src}) center no-repeat,
    url(${({ fallbackUrl }) => fallbackUrl}) center no-repeat;
  background-size: cover;
  background-position: center;
  margin-left: auto;
`
