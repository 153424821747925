import keyWrapper from '~publish/helpers/keywrapper'

type GuideState = {
  instagramNotificationsComposerGuideStep: number
}

export const initialState: GuideState = {
  // Users who have completed the guide should always see
  // the example notification screen (2nd step)
  instagramNotificationsComposerGuideStep: 1,
}

export const actionTypes = keyWrapper('GUIDES', {
  SET_INSTAGRAM_NOTIFICATIONS_COMPOSER_GUIDE_STEP: 0,
  SET_INSTAGRAM_NOTIFICATIONS_COMPOSER_GUIDE_STEP_AFTER_VIEWED: 0,
})

type GuideAction = {
  type: (typeof actionTypes)[keyof typeof actionTypes]
  step: number
}

export default (state: GuideState = initialState, action: GuideAction) => {
  switch (action.type) {
    case actionTypes.SET_INSTAGRAM_NOTIFICATIONS_COMPOSER_GUIDE_STEP:
      return {
        ...state,
        instagramNotificationsComposerGuideStep: action.step,
      }
    case actionTypes.SET_INSTAGRAM_NOTIFICATIONS_COMPOSER_GUIDE_STEP_AFTER_VIEWED:
      return {
        ...state,
        instagramNotificationsComposerGuideStep: 1,
      }
    default:
      return state
  }
}

export const actions = {
  setInstagramNotificationsComposerGuideStep: ({ step }: { step: number }) => ({
    type: actionTypes.SET_INSTAGRAM_NOTIFICATIONS_COMPOSER_GUIDE_STEP,
    step,
  }),

  setInstagramNotificationsComposerGuideStepAfterViewed: () => ({
    type: actionTypes.SET_INSTAGRAM_NOTIFICATIONS_COMPOSER_GUIDE_STEP_AFTER_VIEWED,
  }),
}
