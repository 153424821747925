/* eslint-disable react/no-array-index-key */
import React from 'react'
import { Tweet } from './components/Tweet'
import { Empty } from '../Common/Empty'
import * as Styles from './styles'
import type { TweetType, TwitterDraft, TwitterProfile } from '../types'
import {
  draftPreviewPropType,
  profilePreviewPropType,
} from '../Common/propTypes'
import { useScrollToThreadedPost } from '../Common/hooks/useScrollToThreadedPost'

export const TwitterPreview = ({
  draft,
  profile,
}: {
  draft: TwitterDraft
  profile: TwitterProfile
}): JSX.Element => {
  const containerRef = React.useRef<HTMLDivElement | null>(null)

  const { isEmpty, hasThread, activeThreadId } = draft

  const getTweets = () => {
    const drafts = [] as TweetType[]

    if (hasThread) {
      const { activeThreadId } = draft

      draft.thread?.forEach((threadedDraft, i) => {
        drafts.push({
          content:
            activeThreadId === i
              ? {
                  text: draft.text,
                  enabledAttachmentType: draft.enabledAttachmentType,
                  images: draft.images,
                  video: draft.video,
                  gif: draft.gif,
                  link: draft.link,
                  retweet: draft.retweet,
                  inThread: true,
                }
              : {
                  ...threadedDraft,
                  inThread: true,
                },
        })
      })
    } else {
      drafts.push({
        content: {
          ...draft,
          text: draft.text,
          inThread: false,
        },
      })
    }

    return drafts
  }

  const tweets = getTweets()

  useScrollToThreadedPost(activeThreadId)

  if (isEmpty && !hasThread) return <Empty />

  return (
    <Styles.Wrapper data-testid="twitter-preview" ref={containerRef}>
      {tweets.map((tweet, index) => (
        <Tweet
          key={index}
          content={tweet.content}
          profile={profile}
          index={index}
        />
      ))}
    </Styles.Wrapper>
  )
}

TwitterPreview.propTypes = {
  draft: draftPreviewPropType.isRequired,
  profile: profilePreviewPropType.isRequired,
}
