import styled from 'styled-components'
import { white, blue, blueDark, grayDarker } from '@bufferapp/ui/style/colors'

export const Header = styled.p`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  color: ${grayDarker};
  margin: 0 0 16px 0;
  padding: 0;
`

export const Form = styled.form`
  width: 90%;
  padding: 16px;

  textarea {
    margin-bottom: 16px;
  }

  p {
    margin-top: 16px;
  }
`

export const Button = styled.button<{ disabled: boolean; loading: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px 11px;
  gap: 8px;
  width: 100%;
  background: ${(props) => {
    if (props.disabled) return '#C6CBE4'
    if (props.loading) return blueDark

    return blue
  }};
  border-radius: 4px;
  color: ${white};
  border: none;
  cursor: pointer;
  opacity:

  &:hover {
    background: ${blueDark};
  }
`
