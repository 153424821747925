import { Node } from 'slate'
import * as Plate from '@udecode/plate'
import { GenerateContentElement } from '../nodes/GenerateContentElement'

export function getGeneratedContent({
  editor,
}: {
  editor: any
}): string | null {
  const suggestion = Plate.findNode(editor, {
    at: [],
    match: (n) => GenerateContentElement.is(n),
  })

  return suggestion ? Node.string(suggestion[0]) : null
}
