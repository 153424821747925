import { gql } from '@apollo/client'

const DELETE_POST = gql`
  mutation deletePost($input: DeletePostInput!) {
    deletePost(input: $input) {
      ... on PostDeletionResponse {
        success
        postId
      }
      ... on PublishingError {
        userFriendlyMessage
        cause
      }
    }
  }
`

export default DELETE_POST
