import query from 'query-string'

/**
 * Retrieves the first value of a specific query parameter from the current URL's query string.
 * If the parameter appears multiple times, only the first occurrence is returned.
 * Returns `null` if the specified parameter does not exist.
 *
 * @param {string} param - The name of the query parameter to retrieve the value for.
 * @returns {string | null} - TThe value, the first value of the query parameter if it is an array, or `null` if it does not exist.
 */
export const getQueryParamFromURL = (param: string): string | null => {
  const queryParams = query.parse(location.search)

  const value = queryParams[param]

  // Check if the parameter exists
  if (value === undefined) {
    return null
  } else if (Array.isArray(value)) {
    // If it's an array, return the first value
    return value[0] ?? null // Using nullish coalescing operator to ensure a string or null return
  } else {
    // If it's a string, return it directly
    return value
  }
}

/**
 * Removes specified query parameters from the current URL.
 * @param {Array<string>} paramsToRemove - The names of the query parameters to remove.
 */
export const removeQueryParamsFromUrl = (paramsToRemove: string[]): void => {
  // Create a URL object based on the current location
  const url = new URL(window.location.href)

  // Remove specified query parameters
  paramsToRemove.forEach((param) => url.searchParams.delete(param))

  // Replace the current history entry with the new URL without the removed parameters
  window.history.replaceState(null, '', url.toString())
}

/**
 * Retrieves the values of a specific query parameter from the current URL's query string.
 * Returns `null` if the specified parameter does not exist.
 *
 * @param {string} param - The name of the query parameter to retrieve the value for.
 * @returns {string[] | null} - The value or values of the query parameter or `null` if it does not exist.
 */
export const getQueryParamsFromURL = (param: string): string[] | null => {
  const query = new URLSearchParams(location.search)
  const values = query.getAll(param)

  // Check if the parameter exists and if there are any channel ids
  if (values === undefined || values?.length === 0) {
    return null
  }

  return values
}
