import styled from 'styled-components'
import RetweetIcon from '@bufferapp/ui/Icon/Icons/Retweet'

import { ImagesGrid as ImagesGridCommon } from '../../../Common/ImagesGrid'

import {
  previewBlack,
  previewGrayLight,
  previewGrayMedium,
  grayMedium,
} from '../../../Common/styles'

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 12px;
`

export const UsernameWrapper = styled.div`
  display: flex;
  flex-direction column;
  align-items: flex-start;
  margin-left: 12px;
`

export const Username = styled.span`
  display: inline-block;
  color: ${previewBlack};
  max-width: 185px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
`

export const FormattedUsername = styled.span`
  font-size: 12px;
  display: flex;
  align-items: center;
  color: ${previewGrayMedium};
  font-weight: 400;
  max-width: 185px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  svg {
    height: 10px;
    width: 10px;
    margin-left: 4px;
  }
`

export const Body = styled.div`
  margin: 0 12px;
`

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 12px;
`

export const Content = styled.div`
  img {
    width: 100%;
    border-radius: 12px;
  }

  span {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: ${previewBlack} !important;
    white-space: pre-wrap !important;
    overflow-wrap: break-word !important;
    hyphens: manual !important;
  }

  p {
    margin-top: 0;
  }

  a {
    cursor: default !important;
  }
`

export const Separator = styled.div`
  width: calc(100% - 16px);
  background-color: ${previewGrayLight};
  height: 1px;
  margin-left: 8px;
  margin-top: 12px;
`

export const Action = styled.div`
  display: flex;
  align-items: center;
  line-height: 16px;

  svg {
    margin-right: 8px;
  }
`

export const ReshareIcon = styled(RetweetIcon)`
  rotate: 90deg;
`

export const SeeMore = styled.div`
  display: inline;
  color: ${grayMedium};
`

export const ImagesGrid = styled(ImagesGridCommon)`
  div.show-more {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
  }
`
