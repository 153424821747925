import styled, { css } from 'styled-components'

import {
  gray,
  grayLight,
  grayDark,
  grayDarker,
  orange,
  orangeLightest,
  white,
} from '@bufferapp/ui/style/colors'

const checkedStyles = css`
  background: rgba(255, 226, 213, 0.5);
  color: ${grayDarker};
  border: 1px solid ${orangeLightest};

  path {
    fill: ${orange};
  }

  &:hover {
    border: 1px solid ${orange};
    transition: border 0.2s ease;
  }
`

export const Label = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  cursor: pointer;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid ${grayLight};
  background: ${white};
  color: ${grayDark};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: border 0.3s ease;
  user-select: none;
  margin-right: 8px;

  &:last-of-type {
    margin-right: 0;
  }

  svg {
    margin-right: 6px;
    max-height: 12px;
    max-width: 10px;

    path {
      fill: ${gray};
    }
  }

  &:hover {
    border: 1px solid ${gray};
    transition: border 0.2s ease;
    color: ${grayDarker};
  }

  &:has(input[type='checkbox']:checked) {
    ${checkedStyles}
  }
`

export const Input = styled.input`
  display: none;
`
