import type { AnyValidationRule, ValidationRule } from '../types'

type Options = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toString: (value: any) => string
}

export function getRuleMessages(
  rule: AnyValidationRule,
  { toString }: Options = { toString: String },
): Required<ValidationRule['messages']> {
  const replaceTokens = (message: string): string => {
    Object.entries(rule.ruleConfig).forEach(([key, value]) => {
      message = message.replaceAll(`{${key}}`, toString(value))
    })

    return message
  }
  const { generic, specific } = rule.messages

  return {
    generic: replaceTokens(generic),
    specific: replaceTokens(specific || generic),
  }
}
