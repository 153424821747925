import { white } from '@bufferapp/ui/style/colors'
import styled from 'styled-components'

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding: 6px 24px 24px;
`

export const Tip = styled.div`
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;

  border-right: 10px solid ${white};
  position: absolute;
  left: -10px;
  top: 50%;
  transform: translateY(-50%);
`
