import Uppy from '@uppy/core'
import AwsS3 from '@uppy/aws-s3'
import { generateKeyName } from '../utils'
import { uploadsLogger } from '../loggers'

const uppyLogger = {
  debug: uploadsLogger.extend('uppy').extend('debug'),
  warn: uploadsLogger.extend('uppy').extend('warn'),
  error: uploadsLogger.extend('uppy').extend('error'),
}

export function createUppy({
  id,
  userId,
  getS3Signature,
}: {
  id: string
  userId: string
  getS3Signature: () => Promise<any>
}): Uppy {
  const uppyInstance = new Uppy({
    id,
    meta: { uploaderId: id },
    autoProceed: false,
    logger: uppyLogger,
    locale: {
      strings: {
        noDuplicates: "Cannot add '%{fileName}', it already exists",
      },
    },
  })

  type UploadParameters = {
    method?: string
    url: string
    fields?: { [type: string]: string }
    headers?: { [type: string]: string }
  }

  const aws3Config = {
    timeout: 60 * 1000,
    limit: 10,
    allowedMetaFields: [
      'ogranizationId',
      'accountId',
      'uploaderId',
      'trackingId',
    ],
    // @ts-expect-error TS(7006) FIXME: Parameter 'file' implicitly has an 'any' type.
    async getUploadParameters(file): UploadParameters {
      const s3UploadSignature = await getS3Signature()

      const url = `https://${s3UploadSignature.bucket}.s3.amazonaws.com`
      const fields = {
        key: generateKeyName({ file, userId }),
        'Content-Type': file.type || 'application/unknown',
        acl: 'public-read',
        success_action_status: s3UploadSignature.actionStatus,
        policy: s3UploadSignature.base64policy,
        'X-amz-algorithm': s3UploadSignature.algorithm,
        'X-amz-credential': s3UploadSignature.credentials,
        'X-amz-date': s3UploadSignature.date,
        'X-amz-expires': s3UploadSignature.expires,
        'X-amz-signature': s3UploadSignature.signature,
        'X-amz-security-token': s3UploadSignature.temporarySecurityToken,
      }

      return {
        method: 'POST',
        url,
        fields,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
        },
      }
    },
  }

  uppyInstance.use(AwsS3, aws3Config)

  return uppyInstance
}
