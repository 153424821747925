import PropTypes from 'prop-types'

export const draftContentPropType = PropTypes.shape({
  text: PropTypes.string,
  enabledAttachmentType: PropTypes.string,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
    }),
  ),
  video: PropTypes.shape({
    url: PropTypes.string,
    thumbnail: PropTypes.string,
  }),
  gif: PropTypes.shape({
    url: PropTypes.string,
    stillGifUrl: PropTypes.string,
  }),
  link: PropTypes.shape({
    availableThumbnails: PropTypes.array,
    thumbnail: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
})

export const draftPreviewPropType = PropTypes.shape({
  isEmpty: PropTypes.bool.isRequired,
  text: PropTypes.string,
  service: PropTypes.shape({
    isOmni: PropTypes.bool.isRequired,
  }).isRequired,
  enabledAttachmentType: PropTypes.string,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
    }),
  ),
  video: PropTypes.shape({
    url: PropTypes.string,
    thumbnail: PropTypes.string,
  }),
  gif: PropTypes.shape({
    url: PropTypes.string,
    stillGifUrl: PropTypes.string,
  }),
  link: PropTypes.shape({
    availableThumbnails: PropTypes.array,
    thumbnail: PropTypes.shape({
      url: PropTypes.string,
    }),
  }),
  retweet: PropTypes.shape({
    avatarUrl: PropTypes.string,
  }),
  hasThread: PropTypes.bool,
  activeThreadId: PropTypes.number,
})

export const profilePreviewPropType = PropTypes.shape({
  name: PropTypes.string,
  avatar: PropTypes.string,
  username: PropTypes.string,
  formattedUsername: PropTypes.string,
  displayName: PropTypes.string,
  serverUrl: PropTypes.string,
})

export const attachmentLinkPropType = PropTypes.shape({
  url: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  thumbnail: PropTypes.shape({
    url: PropTypes.string.isRequired,
    altText: PropTypes.string,
  }),
})
