import React, { useReducer } from 'react'
import {
  Button,
  Checkbox,
  ChevronDownIcon,
  Input,
  Select,
  Text,
} from '@buffer-mono/popcorn'

import ComposerActionCreators from '~publish/legacy/composer/composer/action-creators/ComposerActionCreators'
import type {
  YoutubeCategory,
  YoutubeChannelData,
} from '~publish/legacy/composer/composer/entities/Draft'

import {
  YOUTUBE_CATEGORIES_US_REGION,
  YOUTUBE_LICENSE_MAP,
  YOUTUBE_PRIVACY_STATUS_MAP,
} from '../constants'

import {
  CheckboxGroupContainer,
  FormColumnContainer,
  InputCharacterCount,
  Label,
  Wrapper,
} from '../styles'
import styles from './ShortBar.module.css'

type SelectOpenState = {
  category: boolean
  privacyStatus: boolean
  license: boolean
}

const SelectInitialOpenState: SelectOpenState = {
  category: false,
  privacyStatus: false,
  license: false,
}

const reducer = (
  state: SelectOpenState,
  action: { type: 'category' | 'privacyStatus' | 'license'; payload: boolean },
): SelectOpenState => {
  return {
    ...SelectInitialOpenState,
    [action.type]: action.payload,
  }
}

const ShortBar = ({ data }: { data: YoutubeChannelData }): JSX.Element => {
  const [state, dispatch] = useReducer(reducer, SelectInitialOpenState)
  const onInputChange = (
    val: string | boolean | YoutubeCategory,
    attr: keyof YoutubeChannelData,
  ): void => {
    ComposerActionCreators.updateDraftYoutubeData({
      ...data,
      [attr]: val,
    })
  }

  return (
    <>
      <Wrapper>
        <Label className="outer-label">Title</Label>
        <Input
          onChange={(e): void => onInputChange(e.target.value, 'title')}
          value={data.title ?? ''}
          aria-label="Video title"
          className={'input ' + styles.input}
        />
        {data.title?.length > 90 && (
          <InputCharacterCount count={data.title?.length ?? 0} maxCount={100} />
        )}
      </Wrapper>

      <Wrapper>
        <FormColumnContainer data-testid="categoryFormContainer">
          <Label className="outer-label">Category</Label>
          <Select
            value={data.category?.id}
            onValueChange={(id): void => {
              if (id) {
                const category = YOUTUBE_CATEGORIES_US_REGION.find(
                  (region) => region.id === id,
                )

                if (category) {
                  onInputChange(category, 'category')
                  dispatch({ type: 'category', payload: false })
                }
              }
            }}
            open={state.category}
            onOpenChange={(open): void => {
              dispatch({ type: 'category', payload: open })
            }}
          >
            <Select.Trigger>
              <Button variant="secondary" className={styles.trigger}>
                <Text size="sm">{data.category?.title || 'None'}</Text>
                <ChevronDownIcon />
              </Button>
            </Select.Trigger>

            <Select.Content align="start">
              {YOUTUBE_CATEGORIES_US_REGION.map((region) => (
                <Select.Item
                  key={region.id}
                  value={region.id}
                  data-testid="category-select-item"
                >
                  {region.title}
                </Select.Item>
              ))}
            </Select.Content>
          </Select>
        </FormColumnContainer>
        <FormColumnContainer>
          <Label width="initial">Visibility</Label>

          <Select
            value={data.privacyStatus}
            onValueChange={(id): void => {
              if (id) {
                onInputChange(id, 'privacyStatus')
                dispatch({ type: 'privacyStatus', payload: false })
              }
            }}
            open={state.privacyStatus}
            onOpenChange={(open): void => {
              dispatch({ type: 'privacyStatus', payload: open })
            }}
          >
            <Select.Trigger>
              <Button variant="secondary" className={styles.trigger}>
                <Text size="sm">
                  {YOUTUBE_PRIVACY_STATUS_MAP[data.privacyStatus]}
                </Text>
                <ChevronDownIcon />
              </Button>
            </Select.Trigger>

            <Select.Content
              align="start"
              onEscapeKeyDown={(event: KeyboardEvent): void => {
                event.stopPropagation()
              }}
            >
              {Object.keys(YOUTUBE_PRIVACY_STATUS_MAP).map(
                (privacyStatusKey) => (
                  <Select.Item key={privacyStatusKey} value={privacyStatusKey}>
                    {
                      YOUTUBE_PRIVACY_STATUS_MAP[
                        privacyStatusKey as keyof typeof YOUTUBE_PRIVACY_STATUS_MAP
                      ]
                    }
                  </Select.Item>
                ),
              )}
            </Select.Content>
          </Select>
        </FormColumnContainer>
      </Wrapper>

      <Wrapper>
        <Label className="outer-label">License</Label>

        <Select
          value={data.license}
          onValueChange={(id): void => {
            if (id) {
              onInputChange(id, 'license')
              dispatch({ type: 'license', payload: false })
            }
          }}
          open={state.license}
          onOpenChange={(open): void => {
            dispatch({ type: 'license', payload: open })
          }}
        >
          <Select.Trigger>
            <Button variant="secondary" className={styles.trigger}>
              <Text size="sm">{YOUTUBE_LICENSE_MAP[data.license]}</Text>
              <ChevronDownIcon />
            </Button>
          </Select.Trigger>

          <Select.Content
            align="start"
            onEscapeKeyDown={(event: KeyboardEvent): void => {
              event.stopPropagation()
            }}
          >
            {Object.keys(YOUTUBE_LICENSE_MAP).map((license) => (
              <Select.Item key={license} value={license}>
                {
                  YOUTUBE_LICENSE_MAP[
                    license as keyof typeof YOUTUBE_LICENSE_MAP
                  ]
                }
              </Select.Item>
            ))}
          </Select.Content>
        </Select>
      </Wrapper>

      <CheckboxGroupContainer>
        <Checkbox
          id="notifySubscribers"
          name="notifySubscribers"
          onChange={(checked): void =>
            onInputChange(checked, 'notifySubscribers')
          }
          checked={data.notifySubscribers}
        >
          Notify Subscribers
        </Checkbox>

        <Checkbox
          id="embeddable"
          name="embeddable"
          onChange={(checked): void => onInputChange(checked, 'embeddable')}
          checked={data.embeddable}
        >
          Allow Embedding
        </Checkbox>

        <Checkbox
          id="madeForKids"
          name="madeForKids"
          onChange={(checked): void => onInputChange(checked, 'madeForKids')}
          checked={data.madeForKids}
        >
          Made for Kids
        </Checkbox>
      </CheckboxGroupContainer>
    </>
  )
}

export { ShortBar }
