import React from 'react'
import styled from 'styled-components'
import Text from '@bufferapp/ui/Text'

const TitleStyled = styled(Text)`
  margin: 0 0 8px;
`

const HeaderStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  padding: 0 16px;
`

const Header: React.FC = () => {
  return (
    <HeaderStyled>
      <TitleStyled type="h2">Recipes</TitleStyled>
      <TitleStyled type="h3">
        Remove the guesswork & writer’s block from your content planning
      </TitleStyled>
    </HeaderStyled>
  )
}

export default Header
