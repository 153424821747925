import React from 'react'
import { Message } from '../../CardFooter/styles'
import { Link } from '@bufferapp/ui'

type PropType = {
  message: string
  messageColor: string
  isSent: boolean
  serviceLink: string
}

export const SentPostFooterText = ({
  message,
  messageColor,
  serviceLink,
  isSent,
}: PropType): JSX.Element | null => {
  if (isSent && serviceLink) {
    return (
      // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; href: string; unstyled:... Remove this comment to see the full error message
      <Link href={serviceLink} unstyled newTab>
        <Message type="p" textColor={messageColor}>
          {message}
        </Message>
      </Link>
    )
  } else if (message) {
    return (
      <>
        <Message type="p" textColor={messageColor}>
          {message}
        </Message>
      </>
    )
  }
  return null
}
