import { EditorEntityData } from '~publish/legacy/editor/helpers/EditorEntityData'
import type { BufferEditor } from '~publish/legacy/editor/BufferEditor/types.plate'
import { wrapLinkedInAnnotation } from '../transforms/wrapLinkedInAnnotation'

export const findAndWrapLinkedIndAnnotations = (
  editor: BufferEditor,
  { entities }: { entities?: EditorEntityData } = {},
) => {
  if (entities) {
    EditorEntityData.getLinkedInAnnotations(entities).forEach((annotation) => {
      wrapLinkedInAnnotation(editor, annotation)
    })
  }
}
