import React from 'react'

import FeedIcon from '@bufferapp/ui/Icon/Icons/Feed'

import type {
  Image,
  Link,
} from '~publish/legacy/composer/composer/entities/factories'
import { cleanUrl } from '~publish/legacy/composer/composer/utils/StringUtils'
import * as Styles from './styles'

const Url = ({
  url,
  clickable,
}: {
  url: string
  clickable: boolean
}): JSX.Element => (
  <Styles.Url
    href={url}
    target="_blank"
    rel="noopener noreferrer"
    clickable={clickable}
  >
    {cleanUrl(url)}
  </Styles.Url>
)

const LinkSection = ({
  url,
  title,
  titleOnTop = false,
  clickable,
}: {
  url: string
  title: string | null
  titleOnTop: boolean
  clickable: boolean
}): JSX.Element => {
  if (titleOnTop) {
    return (
      <>
        <Styles.Title className="title">{title}</Styles.Title>
        <Url url={url} clickable={clickable} />
      </>
    )
  }

  return (
    <>
      <Url url={url} clickable={clickable} />
      <Styles.Title className="title">{title}</Styles.Title>
    </>
  )
}

const CompactView = ({
  url,
  title,
  titleOnTop = false,
  clickable,
  description,
}: {
  url: string
  title: string | null
  titleOnTop: boolean
  clickable: boolean
  description?: string | null
}): JSX.Element => (
  <Styles.CompactBox className="compact-box">
    <LinkSection
      url={url}
      title={title}
      titleOnTop={titleOnTop}
      clickable={clickable}
    />
    {description && <Styles.Description>{description}</Styles.Description>}
  </Styles.CompactBox>
)

const FullView = ({
  thumbnail,
  url,
  title,
  titleOnTop = false,
  clickable,
  imageCover,
  showEmptyThumbnail,
  description,
}: {
  thumbnail: Image | null
  url: string
  title: string | null
  titleOnTop: boolean
  clickable: boolean
  imageCover: boolean
  showEmptyThumbnail: boolean
  description?: string | null
}): JSX.Element => (
  <>
    {thumbnail && (
      <Styles.Image
        src={thumbnail.url}
        alt={thumbnail.altText || ''}
        imageCover={imageCover}
      />
    )}
    {!thumbnail && showEmptyThumbnail && (
      <Styles.EmptyImage>
        <FeedIcon size="large" />
      </Styles.EmptyImage>
    )}
    <Styles.Content className="content">
      <LinkSection
        url={url}
        title={title}
        titleOnTop={titleOnTop}
        clickable={clickable}
      />
      {description && <Styles.Description>{description}</Styles.Description>}
    </Styles.Content>
  </>
)

const LinkPreview = ({
  link,
  titleOnTop = false,
  imageCover = false,
  showEmptyThumbnail = false,
  showDescription = false,
  clickable = true,
  className,
  showCompact = false,
}: {
  link: Link
  titleOnTop?: boolean
  imageCover?: boolean
  showEmptyThumbnail?: boolean
  showDescription?: boolean
  clickable?: boolean
  className?: string
  showCompact?: boolean
}): JSX.Element => {
  const { url: originalUrl, expandedUrl, title, thumbnail, description } = link
  const url = expandedUrl || originalUrl

  return (
    <Styles.Wrapper
      data-testid="link-preview"
      sideThumbnail={!thumbnail}
      className={className}
    >
      {showCompact && (
        <CompactView
          url={url}
          title={title}
          titleOnTop={titleOnTop}
          clickable={clickable}
          description={showDescription ? description : undefined}
        />
      )}

      {!showCompact && (
        <FullView
          thumbnail={thumbnail}
          url={url}
          title={title}
          titleOnTop={titleOnTop}
          clickable={clickable}
          imageCover={imageCover}
          showEmptyThumbnail={showEmptyThumbnail}
          description={showDescription ? description : undefined}
        />
      )}
    </Styles.Wrapper>
  )
}

export { LinkPreview }
