import React from 'react'

import * as Styled from './Onboarding.styles'

function Progress({ currentSlide }: { currentSlide: number }): JSX.Element {
  return (
    <Styled.ProgressWrapper>
      <Styled.ProgressCircle empty={currentSlide === 1} />
      <Styled.ProgressCircle empty={currentSlide === 2} />
      <Styled.ProgressCircle empty={currentSlide === 3} />
      <Styled.ProgressCircle empty={currentSlide === 4} />
    </Styled.ProgressWrapper>
  )
}

export { Progress }
