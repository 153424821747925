import { useState, useCallback } from 'react'
import { useOnChange } from '~publish/hooks/useOnChange'
import { usePostComposer } from '~publish/hooks/usePostComposer'

const HOVER_DISMISS_TIMEOUT = 1000

type UseIsPostHoveredReturn = {
  isHovered: boolean
  onHoverChange: (hovered: boolean) => void
}

/**
 * Utility that allows us to manually remove hover temporarily to
 * allow a hover card to close- giving it time to lose hover
 * state so it doesn't immediately re-open
 */
export const useIsPostHovered = (): UseIsPostHoveredReturn => {
  const [isHovered, setIsHovered] = useState(false)
  const [lastDismissedHover, setLastDismissedHover] = useState(0)
  const onHoverChange = useCallback(
    (hovered: boolean) => {
      const timeSinceLastDismiss = Date.now() - lastDismissedHover
      setIsHovered(hovered && timeSinceLastDismiss > HOVER_DISMISS_TIMEOUT)
    },
    [lastDismissedHover, setIsHovered],
  )

  const { isOpen: isComposerOpen } = usePostComposer()
  useOnChange(
    isComposerOpen,
    (wasOpen, isOpen) => {
      if (!wasOpen && isOpen) {
        // override close the hover card when the composer opens
        setIsHovered(false)
        setLastDismissedHover(Date.now())
      }
    },
    [setLastDismissedHover, setIsHovered],
  )

  return {
    isHovered,
    onHoverChange,
  }
}
