import PropTypes from 'prop-types'

export const googleBusinessDataPropType = PropTypes.shape({
  cta: PropTypes.oneOf([
    'none',
    'book',
    'order',
    'shop',
    'learn_more',
    'signup',
  ]),
  link: PropTypes.string,
  title: PropTypes.string,
  start_date: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  end_date: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  start_time: PropTypes.string,
  end_time: PropTypes.string,
  code: PropTypes.string,
  terms: PropTypes.string,
})
