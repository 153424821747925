import API from '../../../utils/API'
import { type Location, ServiceLocation } from '../entities/ServiceLocation'

class LocationFinder {
  static url = 'profiles/get_locations.json'

  static async findLocations(
    profileId: string,
    query: string,
  ): Promise<ServiceLocation[]> {
    const params = {
      profile_id: profileId,
      place: query,
    }

    return (
      // @ts-expect-error TS(2339) FIXME: Property 'post' does not exist on type 'typeof API... Remove this comment to see the full error message
      API.post(LocationFinder.url, params)
        .catch(() => [])
        .then(
          (response: {
            locations: {
              data: { id: string; name: string; location: Location }[]
            }
          }) => {
            const locations = response.locations.data
            return locations.map(
              (l) => new ServiceLocation(l.id, l.name, l.location),
            )
          },
        )
    )
  }
}
export default LocationFinder
