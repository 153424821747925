// @ts-expect-error TS(7006) FIXME: Parameter 'responses' implicitly has an 'any' type... Remove this comment to see the full error message
const extractSavedUpdatesIdsFromResponses = (responses) =>
  Array.prototype.concat.apply(
    [],
    // @ts-expect-error TS(7006) FIXME: Parameter 'response' implicitly has an 'any' type.
    responses.map((response) =>
      Array.isArray(response.updates)
        ? // @ts-expect-error TS(7006) FIXME: Parameter 'update' implicitly has an 'any' type.
          response.updates.map((update) => update.id)
        : [response.update?.id].filter((id) => !!id),
    ),
  )

export { extractSavedUpdatesIdsFromResponses }
