/**
 * Scrape urls to retrieve information about them.
 *
 * Returns a Promise that resolves with the JSON response from the appropriate
 * Scraper.urls entry as an object literal.
 *
 * Results are cached, and a same request done simultaneously will be
 * queued to return the same info as the already-running request.
 */

// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import Request from '@bufferapp/buffer-js-request'
import Bugsnag from '@bugsnag/browser'

class Scraper {
  static urls = new Map([
    ['local', 'https://scraper.local.buffer.com'],
    ['production', 'https://scraper.buffer.com'],
  ])

  static pendingRequests = new Map() // Stores pending scraper promises

  static cache = new Map() // Stores results

  // @ts-expect-error TS(7006) FIXME: Parameter 'url' implicitly has an 'any' type.
  static encodeUrl(url) {
    // Ensure the url is encoded, but not double encoded
    try {
      return encodeURI(decodeURI(url))
    } catch (err) {
      return url
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'url' implicitly has an 'any' type.
  static scrape(url, environment = 'production', isTwitterLinkPreview = false) {
    // If the result is cached, return it right away
    const cacheKey = isTwitterLinkPreview ? `${url}twitter` : url
    if (this.cache.has(cacheKey))
      return Promise.resolve(this.cache.get(cacheKey))

    // If a request about the same url is already running, return a
    // Promise that'll resolve with that request's data
    if (this.pendingRequests.has(cacheKey)) {
      return Promise.resolve(this.pendingRequests.get(cacheKey))
    }

    const data = {
      url: this.encodeUrl(url),
    }

    // if Twitter, add flag
    if (isTwitterLinkPreview) {
      // @ts-expect-error TS(2339) FIXME: Property 'is_twitter_preview' does not exist on ty... Remove this comment to see the full error message
      data.is_twitter_preview = true
    }

    const request = Request.get(Scraper.urls.get(environment), data)
      // @ts-expect-error TS(7006) FIXME: Parameter 'response' implicitly has an 'any' type.
      .then((response) => {
        if (response.status >= 500 && environment === 'production') {
          Bugsnag.notify(
            new Error('Scraper returned 5xx error code'),
            (event) => {
              event.addMetadata('Scraper', {
                url: data.url,
                responseCode: response.status,
              })
            },
          )
        }
        return response
      })
      // @ts-expect-error TS(7006) FIXME: Parameter 'response' implicitly has an 'any' type.
      .then((response) => response.json())
      // @ts-expect-error TS(7006) FIXME: Parameter 'urlData' implicitly has an 'any' type.
      .then((urlData) => {
        this.cache.set(cacheKey, urlData)
        this.pendingRequests.delete(cacheKey)

        return urlData
      })

    this.pendingRequests.set(cacheKey, request)

    return request
  }
}

export default Scraper
