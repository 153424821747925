import RefreshIcon from '@bufferapp/ui/Icon/Icons/Refresh'
import React from 'react'
import { ActionButton } from '~publish/legacy/ai/components/AIAssistant/components/ActionButton'
import { Button } from '~publish/legacy/ai/components/AIAssistant/components/Button'
import { CopyButton } from '~publish/legacy/ai/components/AIAssistant/components/CopyButton'
import { Error } from '~publish/legacy/ai/components/AIAssistant/components/Error'
import { FunFact } from '~publish/legacy/ai/components/AIAssistant/components/FunFact'
import { Loader } from '~publish/legacy/ai/components/AIAssistant/components/Loader'
import { RetryButton } from '~publish/legacy/ai/components/AIAssistant/components/RetryButton'
import { ToneButton } from '~publish/legacy/ai/components/AIAssistant/components/ToneButton'
import ComposerActionCreators from '~publish/legacy/composer/composer/action-creators/ComposerActionCreators'
import { useAppDispatch, useAppSelector } from '~publish/legacy/store'
import { AIAssistantContext } from '../AIAssistant'

import {
  resetContentGeneration,
  resetSelection,
  selectContentGenerationStatus,
} from '~publish/legacy/ai/state/slice'

import { useQueryParam } from '../../../../../hooks/useQueryParam'
import * as Styled from './GenerationResult.styles'

/**
 *
 */
function GenerationResult({ disabled }: { disabled?: boolean }): JSX.Element {
  const dispatch = useAppDispatch()
  const [, setAiAssisted] = useQueryParam('aiAssisted')

  const { editor } = React.useContext(AIAssistantContext)

  const {
    contentGenerationInProgress,
    contentGenerationError,
    contentGenerationResults,
  } = useAppSelector((state) => selectContentGenerationStatus(state))

  const onAddContent = (): void => {
    editor?.addGeneratedContent?.({ content })
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0
    dispatch(resetContentGeneration())
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0
    dispatch(resetSelection())

    // For Composer: handles setting all drafts as AI assisted.
    ComposerActionCreators.updateAiAssist()

    // For Ideas Editor: handles setting current idea as AI assisted.
    // When content is inserted/replaced, we add 'aiAssisted' query param to the URL
    // with 'replace' to ensure back navigation still operates.
    setAiAssisted('true', { replace: true })
  }

  const content = contentGenerationResults[0]

  const showLoader = contentGenerationInProgress
  const showError = contentGenerationError
  const showContent = content && !showLoader && !showError

  const shouldReplace = editor && editor?.getSelection?.()

  return (
    <>
      <Styled.VerticalWrapper>
        {contentGenerationError && <Error />}

        {showContent && (
          <Styled.Wrapper>
            <Styled.ContentWrapper disabled={disabled}>
              <Styled.Content
                disabled={disabled}
                data-testid="ai-assistant-content-generated"
              >
                {content.trim()}
              </Styled.Content>
              <Styled.ButtonWrapper>
                <CopyButton disabled={disabled} />
                <Styled.PrimaryButtonsWrapper>
                  <RetryButton disabled={disabled}>
                    <RefreshIcon />
                    Retry
                  </RetryButton>
                  <Button onClick={onAddContent} disabled={disabled}>
                    {shouldReplace ? 'Replace' : 'Insert'}
                  </Button>
                </Styled.PrimaryButtonsWrapper>
              </Styled.ButtonWrapper>
            </Styled.ContentWrapper>
            <Styled.ActionsWrapper id="ai-assistant-fine-tuning-container">
              {['rephrase', 'shorten', 'expand'].map((action, index) => (
                <ActionButton
                  target={content}
                  action={action}
                  disabled={disabled}
                  key={index}
                  testId={`ai-assistant-fine-tuning-${action}-button`}
                  secondary
                />
              ))}
              {['casual', 'formal'].map((tone, index) => (
                <ToneButton
                  tone={tone}
                  disabled={disabled}
                  key={index}
                  testId={`ai-assistant-fine-tuning-${tone}-button`}
                />
              ))}
            </Styled.ActionsWrapper>
          </Styled.Wrapper>
        )}
      </Styled.VerticalWrapper>
      {contentGenerationInProgress && (
        <Styled.LoadingWrapper>
          <Loader />
          <FunFact />
        </Styled.LoadingWrapper>
      )}
    </>
  )
}

export { GenerationResult }
