import { useState } from 'react'

interface UseExpandableTextReturn {
  expandedText: string
  isExpanded: boolean
  toggleExpansion: () => void
}

export const useExpandableText = (
  text: string,
  limit = 250,
): UseExpandableTextReturn => {
  const [isExpanded, setIsExpanded] = useState(false)

  const shouldTruncate = !isExpanded && text.length > limit
  const expandedText = shouldTruncate ? `${text.slice(0, limit)}...` : text

  const toggleExpansion = (): void => {
    setIsExpanded(!isExpanded)
  }

  return { expandedText, isExpanded, toggleExpansion }
}
