import styled, { keyframes } from 'styled-components'

const slideInLeft = keyframes({
  from: { transform: 'translateX(100%)', opacity: 0 },
  to: { transform: 'translateX(0)', opacity: 1 },
})

const slideOutLeft = keyframes({
  from: { transform: 'translateX(0)', opacity: 1 },
  to: { transform: 'translateX(100%)', opacity: 0 },
})

const slideInRight = keyframes({
  from: { transform: 'translateX(-100%)', opacity: 0 },
  to: { transform: 'translateX(0)', opacity: 1 },
})

const slideOutRight = keyframes({
  from: { transform: 'translateX(0)', opacity: 1 },
  to: { transform: ` translateX(-100%)`, opacity: 0 },
})

export const Wrapper = styled.div`
  width: 364px;
  max-width: 0;
  background-color: white;
  border-radius: 8px;
  position: relative;
  overflow-y: auto;
  height: 100%;
  transition: max-width 0.3s ease-in-out;

  &.open.right {
    max-width: 364px;
    animation: ${slideInRight} 0.3s ease-in-out;
  }

  &.closed.right {
    max-width: 0;
    animation: ${slideOutRight} 0.3s ease-in-out;
  }

  &.open.left {
    max-width: 364px;
    animation: ${slideInLeft} 0.3s ease-in-out;
  }

  &.closed.left {
    max-width: 0;
    animation: ${slideOutLeft} 0.3s ease-in-out;
  }
`

export const CloseButton = styled.div`
  position: absolute;
  cursor: pointer;
  top: 28px;
  right: 28px;
`
