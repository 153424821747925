import { createPluginFactory } from '@udecode/plate'
import type { BufferValue } from '~publish/legacy/editor/BufferEditor/types.plate'
import type { BufferPluginOptions } from '~publish/legacy/editor/plugins/types'
import { LinkElement } from './nodes/LinkElement'
import { textToLinkNodes } from './transforms/textToLinkNodes'
import { LinkElementUI } from './ui/LinkElementUI'
import { withLinks } from './withLinks'

export type LinkPluginOptions = BufferPluginOptions & {
  enableLinkShortening?: boolean
}

export const LinkPluginKey = LinkElement.type

export const createLinkPlugin = createPluginFactory<
  LinkPluginOptions,
  BufferValue
>({
  key: LinkElement.type,
  isElement: true,
  isInline: true,
  withOverrides: withLinks,
  component: LinkElementUI,
  options: {
    textToNodes: textToLinkNodes,
  },
})
