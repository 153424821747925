import React from 'react'
import CaretLeftIcon from '@bufferapp/ui/Icon/Icons/CaretLeft'
import MagicWandIcon from '@bufferapp/ui/Icon/Icons/MagicWand'

import * as Styled from './Breadcrumb.styles'

/**
 *
 */
function Breadcrumb({
  onGoBack,
}: {
  onGoBack: (() => void) | null
}): JSX.Element {
  return (
    <Styled.Wrapper>
      {onGoBack && (
        <Styled.BackButton
          aria-label="Go back"
          data-testid="ai-assistant-back-button"
          onClick={onGoBack}
        >
          <CaretLeftIcon />
        </Styled.BackButton>
      )}
      <Styled.ActiveItem>
        <MagicWandIcon />
        <span>AI Assistant</span>
      </Styled.ActiveItem>
    </Styled.Wrapper>
  )
}

export { Breadcrumb }
