import styled from 'styled-components'
import { grayDark } from '@bufferapp/ui/style/colors'

export const TitleWrapper = styled.div`
  p {
    margin-top: 0;
    color: ${grayDark};
    font-size: 14px;
    font-weight: 700;
  }
`
