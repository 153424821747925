/**
 * Logs provided value only in development mode and skips in production mode
 */

declare global {
  interface Window {
    DEBUG?: boolean
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function debugLog(...values: any[]): void {
  if (process.env.NODE_ENV === 'development' || window.DEBUG === true) {
    console.log(...values)
  }
}
