import React, { type ChangeEvent, useState } from 'react'
import ButtonSelect from '../Common/ButtonSelect'

import { Label, SelectLabel, Wrapper } from '../styles'

import ComposerActionCreators from '../../../action-creators/ComposerActionCreators'
import type { GoogleBusinessChannelData } from '../../../entities/Draft/types'
import { Input } from '../../channel-fields/styles'

const WhatsNewBar = ({
  data,
}: {
  data: GoogleBusinessChannelData
}): JSX.Element => {
  const [shouldShowLink, setShouldShowLink] = useState(
    data.cta && data.cta !== 'none',
  )

  const onURLChange = (event: ChangeEvent<HTMLInputElement>): void => {
    ComposerActionCreators.updateDraftGoogleBusinessData({
      ...data,
      link: event.target.value,
    })
  }

  return (
    <>
      <Wrapper>
        <SelectLabel>Button</SelectLabel>
        <ButtonSelect data={data} onSetShouldShowLink={setShouldShowLink} />
      </Wrapper>
      {shouldShowLink && (
        <Wrapper>
          <Label>Button Link</Label>
          <Input
            placeholder="Example: https://buffer.com"
            onChange={onURLChange}
            value={data.link ?? ''}
          />
        </Wrapper>
      )}
    </>
  )
}

export default WhatsNewBar
