import { blue, gray, grayLighter, white } from '@bufferapp/ui/style/colors'
import styled from 'styled-components'

import Button from '../shared/Button'

export const Container = styled.div`
  position: absolute;
  width: ${({ theme }) =>
    theme.composer.constants.linkAttachmentThumbnailWidth};
  height: ${({ theme }) =>
    theme.composer.constants.linkAttachmentThumbnailHeight};
  top: 0;
  left: 0;
  border-radius: 4px;
  background: ${grayLighter};
  margin: 12px 16px;
  box-sizing: border-box;
  overflow: hidden;
  text-align: center;
`

export const Thumbnail = styled.div`
  height: ${({ theme }) =>
    theme.composer.constants.linkAttachmentThumbnailHeight};
  background-position: center;
  background-size: cover;
  width: ${({ theme }) =>
    theme.composer.constants.linkAttachmentThumbnailWidth};
  position: relative;
`

export const ScrollButton = styled(Button)`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  width: 25px;
  top: 46px;
  cursor: pointer;
  color: ${gray};
  font-size: 14px;
  text-decoration: none;
  text-align: center;
  z-index: 10;
  background-color: ${white};
  box-sizing: border-box;
  border: solid 1px #e6ebef;
  border-radius: 0;

  &::before {
    vertical-align: baseline;
  }

  &:hover {
    color: ${blue};
  }

  &:focus {
    outline: none;
  }
`

export const ScrollLeftButton = styled(ScrollButton)`
  /* ${ScrollButton}; */
  height: 28px;
  width: 22px;
  border-left: none;
  border-radius: 0px 4px 4px 0px;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto 0;
`

export const ScrollRightButton = styled(ScrollButton)`
  /* ${ScrollButton}; */
  height: 28px;
  width: 22px;
  right: 0px;
  border-right: none;
  border-radius: 4px 0px 0px 4px;
  top: 0;
  bottom: 0;
  margin: auto 0;

  &::before {
    right: 2px;
    position: relative;
  }
`
