import { PostActionButtonType } from '~publish/legacy/post/types'
import type { UncheckedPostAction, ValidPostAction } from './types'
import type { usePostActionsResponse } from '.'

export const isValidPostAction = (
  action: UncheckedPostAction,
): action is ValidPostAction => {
  return action.condition && !!action.callback
}

type ReducePostActionsByTypeReturn = Pick<
  usePostActionsResponse,
  'singleActions' | 'stackedActions'
>
// Reduce post actions into single and stacked actions
export const reducePostActionsByType = (
  result: ReducePostActionsByTypeReturn,
  action: ValidPostAction,
): ReducePostActionsByTypeReturn => {
  // Remove the condition property as all false conditions
  // will have been filtered out
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { condition, ...newAction } = action
  if (action.type === PostActionButtonType.SINGLE)
    return {
      ...result,
      singleActions: [...result.singleActions, newAction],
    }
  if (action.type === PostActionButtonType.STACKED)
    return {
      ...result,
      stackedActions: [...result.stackedActions, newAction],
    }
  return result
}
