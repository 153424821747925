import React, { useState } from 'react'
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  type DragCancelEvent,
  type DragEndEvent,
  type DragOverEvent,
  type DragStartEvent,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers'

import type { ChannelNavChannel } from '../useChannels'
import { ChannelNavItem } from './ChannelNavItem'

export const ChannelsDndProvider = ({
  disabled,
  children,
  channels,
  onReorder,
}: {
  disabled: boolean
  children: React.ReactNode
  channels: ChannelNavChannel[]
  onReorder: (dragIndex: number, hoverIndex: number) => void
}): JSX.Element => {
  const [draggedChannel, setDraggedChannel] = useState<
    ChannelNavChannel | undefined
  >(undefined)

  const handleDragStart = (event: DragStartEvent): void => {
    const { active } = event
    if (!active.id) return
    setDraggedChannel(channels.find((channel) => channel.id === active.id))
  }

  const handleDragEnd = ({ active, over }: DragEndEvent): void => {
    if (!active.id || !over?.id) return
    const dragIndex = channels.findIndex((channel) => channel.id === active.id)
    const hoverIndex = channels.findIndex((channel) => channel.id === over.id)

    onReorder(dragIndex, hoverIndex)
  }

  const sensors = useSensors(
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(PointerSensor),
  )

  const getPosition = (id: string): number =>
    channels.findIndex((channel) => channel.id === id) + 1
  const channelCount = channels.length

  const getChannelInfo = (id: string): string => {
    const channel = channels.find((c) => c.id === id)
    return channel
      ? `${channel.name} from ${channel.service}`
      : 'Unknown channel'
  }

  const announcements = {
    onDragStart({ active }: DragStartEvent): string {
      const channelInfo = getChannelInfo(active.id as string)
      return `Picked up channel ${channelInfo}. Channel is in position ${getPosition(
        active.id as string,
      )} of ${channelCount}`
    },
    onDragOver({ active, over }: DragOverEvent): string {
      if (over) {
        const channelInfo = getChannelInfo(active.id as string)
        return `Channel ${channelInfo} was moved into position ${getPosition(
          over.id as string,
        )} of ${channelCount}`
      }
      return `Channel ${getChannelInfo(
        active.id as string,
      )} is no longer over a droppable area`
    },
    onDragEnd({ active, over }: DragEndEvent): string {
      if (over) {
        const channelInfo = getChannelInfo(active.id as string)
        return `Channel ${channelInfo} was dropped at position ${getPosition(
          over.id as string,
        )} of ${channelCount}`
      }
      return `Channel ${getChannelInfo(active.id as string)} was dropped`
    },
    onDragCancel({ active }: DragCancelEvent): string {
      return `Dragging was cancelled. Channel ${getChannelInfo(
        active.id as string,
      )} was dropped.`
    },
  }

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      sensors={sensors}
      modifiers={[restrictToFirstScrollableAncestor]}
      accessibility={{
        announcements,
        screenReaderInstructions: {
          draggable: `
            To pick up a channel, press space or enter.
            Use the up and down arrow keys to change the channel's position in the list.
            Press space or enter again to drop the channel in its new position, or press escape to cancel.
          `,
        },
      }}
    >
      <SortableContext
        items={channels}
        strategy={verticalListSortingStrategy}
        disabled={disabled}
      >
        {children}
      </SortableContext>
      <DragOverlay>
        {draggedChannel && <ChannelNavItem channel={draggedChannel} dragged />}
      </DragOverlay>
    </DndContext>
  )
}
