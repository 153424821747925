import React, { useImperativeHandle, useRef } from 'react'

import {
  DragSource,
  DropTarget,
  type ConnectDragSource,
  type ConnectDropTarget,
} from 'react-dnd'
import ProfileListItem, { type ProfileListItemProps } from '../ProfileListItem'
import type { DropProfileProps } from '../../types'

const profileSource = {
  // @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
  beginDrag(props) {
    return {
      id: props.id,
      index: props.index,
    }
  },
}

const profileTarget = {
  // @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
  drop(props) {
    props.onDropProfile({ commit: true, organizationId: props.organizationId })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
  hover(props, monitor, component) {
    if (!component) {
      return null
    }
    // node = HTML Div element from imperative API
    const node = component.getNode()
    if (!node) {
      return null
    }
    const { index: dragIndex } = monitor.getItem()
    const { index: hoverIndex, onDropProfile, profileLimit } = props

    // Don't replace profile with itself...
    if (dragIndex === hoverIndex) {
      return
    }

    // Determine rectangle on screen
    const hoverBoundingRect = node.getBoundingClientRect()

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

    // Determine mouse position
    const clientOffset = monitor.getClientOffset()

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return
    }

    // Drop!
    onDropProfile({ dragIndex, hoverIndex, profileLimit })

    // We need to directly mutate the monitor state here
    // to ensure the currently dragged item index is updated.
    monitor.getItem().index = hoverIndex
  },
}

type ProfileDragWrapperProps = ProfileListItemProps & {
  connectDragSource: ConnectDragSource
  connectDropTarget: ConnectDropTarget
  onDropProfile: (args: DropProfileProps) => void
  profileLimit: number
  organizationId: string
  index: number
}

const ProfileDragWrapper = React.forwardRef(
  (
    {
      connectDragSource,
      connectDropTarget,
      ...profileProps
    }: ProfileDragWrapperProps,
    ref,
  ) => {
    const elementRef = useRef<HTMLDivElement>(null)

    connectDragSource(elementRef)
    connectDropTarget(elementRef)
    useImperativeHandle(ref, () => ({
      getNode: (): HTMLDivElement | null => elementRef.current,
    }))

    return (
      <div
        aria-dropeffect="move"
        ref={elementRef}
        draggable
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        style={{ outline: 'none' }}
      >
        <ProfileListItem {...profileProps} />
      </div>
    )
  },
)

ProfileDragWrapper.displayName = 'ProfileDragWrapper'

export default DropTarget('profile', profileTarget, (connect) => ({
  connectDropTarget: connect.dropTarget(),
}))(
  DragSource('profile', profileSource, (connect) => ({
    connectDragSource: connect.dragSource(),
  }))(ProfileDragWrapper),
)
