import { getNodeEntries } from '@udecode/plate'
import type { Point } from 'slate'
import {
  getPlainText,
  getNodePlainText,
} from '~publish/legacy/editor/BufferEditor'
import type { BufferEditor } from '~publish/legacy/editor/BufferEditor/types.plate'

type findCharacterLimitPoint = (
  /**
   * A Plate editor instance
   */
  editor: BufferEditor,

  /**
   * The total amount of allowed characters in the editor
   */
  characterLimit: number | null,

  /**
   * A callback to calculate the current character count of
   * a given text string.
   * Defaults to (text) => text.length
   */
  getTextCharacterCount?: (text: string) => number,

  /**
   * An optional callback to calculate any additional characters
   * to be added, for example, if attachments change the character count
   */
  getAdditionalCharacterCount?: (text: string) => number,
) => Point | undefined

/**
 * Find the point in an editor's node tree where
 * the text reaches the provided character limit.
 */
export const findCharacterLimitPoint: findCharacterLimitPoint = (
  editor,
  characterLimit,
  getTextCharacterCount = (str) => str.length,
  getAdditionalCharacterCount,
): Point | undefined => {
  // Check against undefined and null instead of just NOT
  // to prevent returning early when given a characterLimit of 0

  // TODO: Rename function to something more suitable to finding
  // a point in the node tree at a given index, instead of a character
  // limit as providing 0 for character limit implies the need for early return
  if (characterLimit === undefined || characterLimit === null) return

  // If there are any additional characters that need
  // to be added to the count, use that count as
  // the initial character count value
  const editorText = getPlainText(editor)
  let characterCount = getAdditionalCharacterCount?.(editorText) || 0
  let characterLimitPoint: Point | undefined

  const nodeEntries = getNodeEntries(editor, {
    at: [],
    voids: false,
  })
  Array.from(nodeEntries).every(([node, path]) => {
    const nodeText = getNodePlainText(editor, [node, path]) || ''
    const currentNodeCount = getTextCharacterCount(nodeText)
    characterCount += currentNodeCount

    // If the current node text doesn't exceed the limit,
    // continue looping over the nodes
    const charsAboveLimit = characterCount - characterLimit
    if (charsAboveLimit <= 0) return true

    // If the current node text does exceed the limit,
    // subtract the amount exceeded from the node text length
    // to find the correct point offset value
    const blockLength = nodeText.length
    const startOffset = blockLength - charsAboveLimit

    characterLimitPoint = {
      path,
      offset: startOffset,
    }
    return false
  })

  return characterLimitPoint
}
