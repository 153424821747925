import styled from 'styled-components'
import { grayDark } from '@bufferapp/ui/style/colors'
import { fontFamily } from '@bufferapp/ui/style/fonts'

export const BulletPointItem = styled.li`
  margin: 0 0 8px 0;
`

export const BulletPointText = styled.span`
  color: ${grayDark};
  font-family: ${fontFamily};
  font-size: 14px;
  font-weight: 400;
  margin-left: 5px;
  line-height: 20px;
`

export const StyledList = styled.ul`
  list-style: none;
  padding: 2px;
  margin: 0 0 12px;
`
