/**
 * Copy the current search params from the browser URL to the new route
 * if they exist in the provided list of keys
 * @param route target route
 * @param keys allowList of keys to copy
 * @returns
 */
export const withCopiedSearchParams = (
  route: string,
  keys: string[],
  mergeParams: (false | [string, string])[] = [],
  currentRoute: string = window.location.search,
): string => {
  const searchParams = new URLSearchParams(currentRoute)
  const newParams = new URLSearchParams()
  searchParams.forEach((value, key) => {
    if (keys.includes(key)) {
      newParams.append(key, value)
    }
  })
  const filteredMergeParams = mergeParams.filter(
    (param) => param !== false,
  ) as [string, string][]
  for (const [key, value] of filteredMergeParams) {
    newParams.append(key, value)
  }
  const joiner = route.includes('?') ? '&' : '?'
  const newSearchParams = newParams.toString()
  return newSearchParams.length > 0
    ? `${route}${joiner}${newSearchParams}`
    : route
}
