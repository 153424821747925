import React from 'react'

import {
  PostTypeFacebookReel,
  PostTypeFacebookStory,
} from '~publish/legacy/post/constants'
import { Empty } from '../Common/Empty'
import type { PostType, FacebookDraft, ProfilePreview } from '../types'
import {
  draftPreviewPropType,
  profilePreviewPropType,
} from '../Common/propTypes'
import { FacebookPost } from './components/Post'
import { Reel } from './components/Reel'
import { Story } from './components/Story/index'

import * as Styles from '../Common/styles'
import { getMediaProperties } from '../Common/utils'

const Content = ({
  draftFacebook,
  profile,
  updateType,
}: {
  draftFacebook: PostType
  profile: ProfilePreview
  updateType: string | null
}): JSX.Element => {
  switch (updateType) {
    case PostTypeFacebookReel:
      return <Reel content={draftFacebook.content} profile={profile} />
    case PostTypeFacebookStory:
      return <Story content={draftFacebook.content} profile={profile} />
    default:
      return <FacebookPost content={draftFacebook.content} profile={profile} />
  }
}

export const FacebookPreview = ({
  draft,
  profile,
}: {
  draft: FacebookDraft
  profile: ProfilePreview
}): JSX.Element => {
  const containerRef = React.useRef<HTMLDivElement | null>(null)

  if (draft.isEmpty) return <Empty />

  const draftFacebook = {
    content: {
      ...draft,
      text: draft.text,
    },
  } as PostType

  const { hasVideo } = getMediaProperties(draftFacebook.content, null)
  if (draft.updateType === PostTypeFacebookReel && !hasVideo) {
    return <Empty />
  }

  return (
    <Styles.Wrapper data-testid="facebook-preview" ref={containerRef}>
      <Content
        draftFacebook={draftFacebook}
        profile={profile}
        updateType={draft.updateType}
      />
    </Styles.Wrapper>
  )
}

FacebookPreview.propTypes = {
  draft: draftPreviewPropType.isRequired,
  profile: profilePreviewPropType.isRequired,
}
