import styled from 'styled-components'
import { blue, grayDarker } from '@bufferapp/ui/style/colors'

export const UpgradeLinkButton = styled.button`
  background: none;
  border: none;
  color: ${blue};
  text-decoration: none;
  font-weight: bold;
  padding-left: 5px;
  cursor: pointer;

  &:hover {
    color: ${grayDarker};
  }
`
