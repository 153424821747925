import { useMutation } from '@apollo/client'
import type { GraphQLError } from 'graphql'
import { useCallback } from 'react'
import { graphql } from '~publish/gql'
import type {
  MoveIdeaInput,
  MoveIdeaMutation,
  PreviousIdeaFragment,
} from '~publish/gql/graphql'
import { client } from '~publish/legacy/apollo-client'
import { logError } from '~publish/legacy/utils/logError'

/**
 * GraphQL fragment for fetching previous idea details.
 */
const FRAGMENT_PREVIOUS_IDEA = graphql(/* GraphQL */ `
  fragment previousIdea on Idea {
    id
    groupId
    position
  }
`)

/**
 * GraphQL mutation for moving an idea to a new position.
 */
const MOVE_IDEA_MUTATION = graphql(/* GraphQL */ `
  mutation MoveIdea($input: MoveIdeaInput!) {
    moveIdea(input: $input) {
      ... on MoveIdeaResponse {
        updatedIdeas {
          id
          groupId
          position
        }
      }
      ... on UnexpectedError {
        message
      }
    }
  }
`)

type MoveIdea = (input: MoveIdeaInput) => Promise<void>

/**
 * Custom hook to perform the move idea mutation.
 * @returns {MoveIdea} The function to invoke moving an idea.
 */
export const useMoveIdea = (): MoveIdea => {
  const [executeMoveIdea] = useMutation(MOVE_IDEA_MUTATION)

  const moveIdea = useCallback(
    async ({ ideaId, groupId, placeAfterId }: MoveIdeaInput) => {
      const { data, errors } = await executeMoveIdea({
        variables: {
          input: { ideaId, groupId, placeAfterId },
        },
        optimisticResponse: buildOptimisticResponse(
          ideaId,
          placeAfterId,
          groupId,
        ),
      })

      handleErrors(data, errors)
    },
    [executeMoveIdea],
  )

  return moveIdea
}

/**
 * Handles errors from GraphQL mutation response.
 * @param {Object} data - The response data from the mutation.
 * @param {readonly GraphQLError[] | undefined} errors - Array of GraphQL errors.
 * @throws {Error} if the API response contains an error.
 */
function handleErrors(
  data: MoveIdeaMutation | null | undefined,
  errors: readonly GraphQLError[] | undefined,
): void {
  if (data?.moveIdea?.__typename === 'UnexpectedError') {
    throw new Error(data.moveIdea.message)
  }

  if (errors?.length) {
    const unexpectedError = new Error(
      errors?.[0]?.message || 'Unexpected API response',
    )

    logError(unexpectedError, { metaData: { data, errors } })
    throw unexpectedError
  }
}

/**
 * Generates an optimistic response for the moveIdea mutation.
 * @param {Idea} idea - The idea to move.
 * @param {string | null} placeAfterId - The idea ID after which to place the current idea.
 * @param {string | null} groupId - The group ID to associate the idea with, if any.
 * @returns An optimistic response object for the mutation.
 */
function buildOptimisticResponse(
  ideaId: string,
  placeAfterId?: string | null,
  groupId?: string | null,
): {
  moveIdea: {
    __typename: 'MoveIdeaResponse'
    updatedIdeas: {
      __typename: 'Idea'
      id: string
      groupId: string | null
      position: number | null
    }[]
  }
} {
  const after: PreviousIdeaFragment | null = placeAfterId
    ? client.cache.readFragment({
        id: client.cache.identify({
          __typename: 'Idea',
          id: placeAfterId,
        }),
        fragment: FRAGMENT_PREVIOUS_IDEA,
      })
    : null

  return {
    moveIdea: {
      __typename: 'MoveIdeaResponse',
      updatedIdeas: [
        {
          __typename: 'Idea',
          id: ideaId,
          groupId: groupId ?? null,
          position: after?.position ? after.position + 1 : -1, // if no after, place at the start of the list
        },
      ],
    },
  }
}
