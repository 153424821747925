import styled from 'styled-components'
import { Button } from '@bufferapp/ui'
import {
  blue,
  gray,
  grayDarker,
  grayLighter,
  green,
  white,
} from '@bufferapp/ui/style/colors'

export const ManageSocialAccountsStyle = styled.div`
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  flex-flow: column nowrap;
  position: sticky;
  bottom: 15px;
  background-color: #fcfcfc;
`

export const SocialButtonsWrapperStyle = styled.div`
  display: flex;
  flex: 1;
  flex-flow: column nowrap;
  margin-top: auto;
`

export const ButtonDividerStyle = styled.div`
  margin-bottom: 0.5rem;
`

export const BottomSectionStyle = styled.div`
  margin-top: auto;
  display: flex;
  flex-flow: column nowrap;
`

export const ButtonWrapper = styled.div`
  display: flex;
  position: relative;
`

export const ButtonStyled = styled(Button)<{ active?: boolean }>`
  display: flex;
  height: 32px;
  padding: 8px 12px;
  align-items: center;
  justify-items: start;
  align-self: stretch;
  border-radius: 6px;
  border-color: transparent !important;
  background-color: transparent !important;
  color: ${grayDarker};

  button {
    justify-content: start;
    padding: 0;
    height: auto;
  }

  svg {
    color: ${gray};
  }

  &:hover {
    background-color: ${grayLighter}!important;
    border-color: ${grayLighter}!important;
  }

  ${({ active }) =>
    active &&
    `
    color: ${blue} !important;
    border-color: #EFF1FF !important;
    background-color: #EFF1FF !important;

    &:hover{
      background-color: #EFF1FF !important;
    }

    svg {
      color: ${blue};
    }
  `}
`

export const NewTag = styled.span`
  display: flex;
  height: 16px;
  padding: 4px 6px;
  align-items: center;
  gap: 8px;
  border-radius: 17px;
  background: ${green};
  color: ${white};
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  position: absolute;
  right: 12px;
  top: 8px;
`
