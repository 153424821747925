// TODO: publish-revamp-refactor: Remove this file.
// This file provides a way to manually set the last visited page in local storage.
// This functionality is not useful as forgetting to call this hidden hook on a page load would mean the history is now wrong.
// There are far better ways to achieve this and our localstorage items for List vs Calendar are already better suited.

export const getLastVisitedPage = () => {
  return localStorage.getItem('last_visited')
}

// @ts-expect-error TS(7006) FIXME: Parameter 'page' implicitly has an 'any' type.
export const setLastVisitedPage = (page) => {
  localStorage.setItem('last_visited', page)
}
