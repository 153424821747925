/* eslint-disable @typescript-eslint/naming-convention */

import type {
  RpcUpdate,
  RpcUpdateImage,
  RpcUpdateVideo,
} from '~publish/legacy/post/types'
import type { Draft } from '../entities/Draft'
import type { Gif, Image, Video } from '../entities/factories'

const LOCAL_STORAGE_KEY = 'previewDraftData'

type DraftData = Pick<
  Draft,
  | 'text'
  | 'updateType'
  | 'images'
  | 'video'
  | 'urls'
  | 'gif'
  | 'document'
  | 'title'
  | 'channelData'
  | 'unshortenedUrls'
  | 'places'
  | 'thread'
  | 'tags'
>

export const saveDraftDataInLocalStorage = (draft: Draft): void => {
  const data: DraftData = {
    text: draft.text,
    updateType: draft.updateType,
    images: draft.images,
    video: draft.video,
    urls: draft.urls,
    gif: draft.gif,
    document: draft.document,
    title: draft.title,
    channelData: draft.channelData,
    unshortenedUrls: draft.unshortenedUrls,
    places: draft.places,
    thread: draft.thread,
    tags: draft.tags,
  }
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data))
}

export const getDraftDataFromLocalStorage = (): DraftData | null => {
  // Check if there's a local version of a draft we want to recover
  const localData = localStorage.getItem(LOCAL_STORAGE_KEY)
  if (!localData) return null
  return JSON.parse(localData) as DraftData
}

const imageToRpcUpdateImage = (image: Image): RpcUpdateImage => {
  return {
    progress: '100',
    uploaded: 'true',
    thumbnail: image.url,
    alt_text: image.altText ? image.altText : undefined,
    source: image.source ? image.source.name : undefined,
    width: image.width ? image.width.toString() : '',
    height: image.height ? image.height.toString() : '',
    picture: image.url,
  }
}

const gifToRpcUpdateImage = (gif: Gif): RpcUpdateImage => {
  return {
    progress: '100',
    uploaded: 'true',
    thumbnail: gif?.stillGifUrl,
    width: gif.width ? gif.width.toString() : '',
    height: gif.height ? gif.height.toString() : '',
    picture: gif.url,
  }
}

const videoToRpcUpdateVideo = (video: Video): RpcUpdateVideo => {
  return {
    progress: '100',
    uploaded: 'true',
    thumbnail: video.thumbnail,
    uploading_type: 'video',
    video: {
      id: video.uploadId || '',
      details: {
        duration: video.duration,
        duration_millis: video.durationMs,
        file_size: video.size,
        width: video.width,
        height: video.height,
        transcoded_location: video.url,
        location: video.originalUrl,
      },
      thumbnails: video.availableThumbnails,
      title: video.name,
    },
  }
}

const mediaToRpcUpdateMedia = (draft: DraftData): RpcUpdate['media'] => {
  if (draft.video) return videoToRpcUpdateVideo(draft.video)
  if (draft.gif) return gifToRpcUpdateImage(draft.gif)
  if (draft.images[0]) return imageToRpcUpdateImage(draft.images[0])
  return undefined
}

export const draftDataToRpcUpdate = (draft: DraftData): Partial<RpcUpdate> => {
  return {
    text: draft.text,
    updateType: draft.updateType || 'post',
    media: mediaToRpcUpdateMedia(draft),
    extra_media: draft.images.slice(1).map(imageToRpcUpdateImage),
    links: draft.urls,
    channelData: draft.channelData ? draft.channelData : undefined,
    tags: draft.tags,
  }
}
