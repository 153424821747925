import {
  type Slice,
  createSlice,
  type SliceCaseReducers,
} from '@reduxjs/toolkit'
import type { RootState } from '~publish/legacy/store'

import {
  getIdea,
  setIdea,
  deleteIdea,
} from '~publish/legacy/ai/helpers/localStorage'
import {
  generateIdeas,
  type TResponse,
} from '~publish/legacy/ai/state/contentIdeation/thunks'

function getErrorMessage({
  code,
  error,
}: {
  code?: string
  error?: boolean
}): null | string {
  if (!error && !code) return null

  if (code === 'InsuffiecientTokens') {
    return "Whoops! Looks like you don't have any tokens left."
  }

  return 'Whoops! That didn’t work. Please try again.'
}

const savedIdea = getIdea()

type InitialState = {
  generatedIdeas: string[]
  generateIdeasLoading: boolean
  generateIdeasError: string | null
}

const initialState: InitialState = {
  generatedIdeas: savedIdea ? [savedIdea] : [],
  generateIdeasLoading: false,
  generateIdeasError: null,
}

const contentIdeationSlice: Slice<
  InitialState,
  SliceCaseReducers<InitialState>,
  'contentIdeation'
> = createSlice({
  name: 'contentIdeation',
  initialState,
  reducers: {
    clearIdeas: (state) => {
      state.generatedIdeas = []
      deleteIdea()
    },
  },
  extraReducers: (builder) => {
    builder.addCase(generateIdeas.pending, (state) => {
      state.generateIdeasLoading = true
      state.generateIdeasError = null
    })

    builder.addCase(generateIdeas.fulfilled, (state, action) => {
      const { suggestions, code } = action.payload as TResponse
      const errorMessage = getErrorMessage({ code, error: false })

      if (suggestions && suggestions.length) {
        const firstIdea = suggestions[0].content
        setIdea({ idea: firstIdea })
      }

      state.generatedIdeas = suggestions
        ? suggestions.map((value) => value.content)
        : []
      state.generateIdeasLoading = false
      state.generateIdeasError = errorMessage
    })

    builder.addCase(generateIdeas.rejected, (state) => {
      const errorMessage = getErrorMessage({ error: true })

      state.generateIdeasLoading = false
      state.generateIdeasError = errorMessage
    })
  },
})

const selectGeneratedIdeas = (state: RootState): InitialState => {
  const { generatedIdeas, generateIdeasLoading, generateIdeasError } =
    state[contentIdeationSlice.name]

  return {
    generatedIdeas,
    generateIdeasLoading,
    generateIdeasError,
  }
}

const { clearIdeas } = contentIdeationSlice.actions

export { contentIdeationSlice, selectGeneratedIdeas, clearIdeas }
