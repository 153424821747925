import {
  Button,
  Dialog,
  Flex,
  Heading,
  Input,
  Label,
  Paragraph,
  Switch,
  toast,
} from '@buffer-mono/popcorn'
import React, { useId } from 'react'
import { useAccount } from '~publish/legacy/accountContext'
import { CUSTOMIZE_UTM_PARAM } from '~publish/legacy/duplicate-server/formatters/entitlements'
import { selectCurrentProfile } from '~publish/legacy/profile-sidebar/selectors'
import { useAppDispatch, useAppSelector } from '~publish/legacy/store'
import commonStyles from '../../GeneralSettings.module.css'
import {
  selectIsLinkShorteningEnabled,
  selectTrackingSettings,
} from '../../selectors'
import { getGoogleAnalyticsTrackingSettings } from '../../thunks/getGoogleAnalyticsTrackingSettings'
import { saveGoogleAnalyticsTrackingSettings } from '../../thunks/saveGoogleAnalyticsTrackingSettings'
import { toggleGoogleAnalyticsTrackingSettings } from '../../thunks/toggleGoogleAnalyticsTrackingSettings'
import { SHORTENER_DISABLED_SERVICES } from '../LinkShortening'

const GoogleAnalytics = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const profile = useAppSelector(selectCurrentProfile)
  const user = useAccount()
  const organization = user.account.currentOrganization
  const linkShorteningEnabled = useAppSelector(selectIsLinkShorteningEnabled)
  const trackingSettings = useAppSelector(selectTrackingSettings)

  const [loading, setLoading] = React.useState(false)
  const [showForm, setShowForm] = React.useState(false)
  const [googleAnalyticsIsEnabled, setGoogleAnalyticsIsEnabled] =
    React.useState(profile?.googleAnalyticsEnabled === 'enabled')
  const [utmCampaign, setUtmCampaign] = React.useState(
    trackingSettings?.utmCampaign,
  )
  const [utmSource, setUtmSource] = React.useState(trackingSettings?.utmSource)
  const [utmMedium, setUtmMedium] = React.useState(trackingSettings?.utmMedium)

  // Form IDs
  const utmCampaignId = useId()
  const utmSourceId = useId()
  const utmMediumId = useId()

  // Do not render if profile is not available
  if (!profile) return <></>

  // Do not render if the service is disabled
  if (SHORTENER_DISABLED_SERVICES.includes(profile.service)) return <></>

  const hasCustomizingUtmParamsFeature =
    organization.entitlements.includes(CUSTOMIZE_UTM_PARAM) && profile.isManager

  const onFormClick = async (): Promise<void> => {
    await loadTrackingSettings(!showForm)
  }

  const onCloseModal = async (): Promise<void> => {
    setShowForm(false)
    await loadTrackingSettings(!showForm)
  }

  const toggleTrackingSettings = async (
    googleAnalyticsIsEnabled: boolean,
    linkShorteningEnabled: boolean,
  ): Promise<void> => {
    if (!linkShorteningEnabled) return
    setLoading(true)
    const result = await dispatch(
      toggleGoogleAnalyticsTrackingSettings({
        profileId: profile.id,
        utmTrackingChoice: googleAnalyticsIsEnabled ? 'enabled' : 'disabled',
      }),
    )
    setGoogleAnalyticsIsEnabled(googleAnalyticsIsEnabled)
    if (toggleGoogleAnalyticsTrackingSettings.fulfilled.match(result)) {
      toast.success(
        `Your Google Analytics settings are now ${
          googleAnalyticsIsEnabled ? 'enabled' : 'disabled'
        }!`,
      )
    } else {
      toast.error(
        `Sorry! Something went wrong while fetching your Google Analytics settings: ${result.error.message}. Would you be up for trying again?`,
      )
    }
    setLoading(false)
  }

  const loadTrackingSettings = async (showForm: boolean): Promise<void> => {
    setLoading(true)
    const result = await dispatch(
      getGoogleAnalyticsTrackingSettings({ profileId: profile.id }),
    )
    if (getGoogleAnalyticsTrackingSettings.fulfilled.match(result)) {
      setUtmCampaign(result.payload.utmCampaign)
      setUtmSource(result.payload.utmSource)
      setUtmMedium(result.payload.utmMedium)
    } else {
      toast.error(
        `Sorry! Something went wrong while fetching your Google Analytics settings: ${result.error.message}. Would you be up for trying again?`,
      )
    }
    setLoading(false)
    setShowForm(showForm)
  }

  const updateTrackingSettingsClick = async (): Promise<void> => {
    setLoading(true)
    const result = await dispatch(
      saveGoogleAnalyticsTrackingSettings({
        profileId: profile.id,
        utmCampaign: utmCampaign ?? '',
        utmSource: utmSource ?? '',
        utmMedium: utmMedium ?? '',
      }),
    )
    if (saveGoogleAnalyticsTrackingSettings.fulfilled.match(result)) {
      toast.success(result.payload.message)
    } else {
      toast.error(
        `Sorry! Something went wrong while updating your Google Analytics settings: ${result.error.message}. Would you be up for trying again?`,
      )
    }
    setLoading(false)
    setShowForm(!showForm)
  }

  const onChangeUtmCampaign = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setUtmCampaign(event.target.value)
  }

  const onChangeUtmSource = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setUtmSource(event.target.value)
  }
  const onChangeUtmMedium = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setUtmMedium(event.target.value)
  }

  return (
    <Flex
      direction="column"
      gap="md"
      className={commonStyles.fullWidth}
      data-testid="google-analytics-wrapper"
    >
      <Flex gap="xl" className={commonStyles.fullWidth}>
        <Flex direction="column">
          <Heading size="small" as="h3">
            Google Analytics Campaign Tracking
          </Heading>
          <Paragraph className={commonStyles.settingsParagraph}>
            With campaign tracking enabled in Buffer, you will be able to see
            how much traffic you receive from social media posts directly inside
            your Google Analytics dashboard. You can disable this below if
            you&rsquo;d like.
          </Paragraph>
        </Flex>
        <Flex className={commonStyles.actionsWrapper} />
      </Flex>
      {!linkShorteningEnabled && (
        <Paragraph className={commonStyles.settingsParagraph}>
          <em>
            Please choose a link shortener above if you&apos;d like to enable
            campaign tracking.
          </em>
        </Paragraph>
      )}
      {linkShorteningEnabled && (
        <Flex
          justify="between"
          align="center"
          className={commonStyles.fullWidth}
          gap="xl"
        >
          <Flex direction="column" gap="xs">
            <Label>Enable Campaign Tracking</Label>
            <Paragraph className={commonStyles.settingsParagraph}>
              This enables Google Analytics Tracking via UTM parameters added to
              links you share.
              <br />
              (This will override any existing UTM parameters)
            </Paragraph>
          </Flex>
          <Flex
            direction="column"
            align="end"
            gap="xs"
            className={commonStyles.actionsWrapper}
          >
            <Flex align="center" gap="sm">
              <Label>
                {linkShorteningEnabled && googleAnalyticsIsEnabled
                  ? 'Enabled'
                  : 'Disabled'}
              </Label>
              <Switch
                onCheckedChange={(): Promise<void> =>
                  toggleTrackingSettings(
                    !googleAnalyticsIsEnabled,
                    linkShorteningEnabled,
                  )
                }
                checked={googleAnalyticsIsEnabled && linkShorteningEnabled}
                data-testid="google-analytics-switch"
              />
            </Flex>
            {linkShorteningEnabled &&
              googleAnalyticsIsEnabled &&
              hasCustomizingUtmParamsFeature && (
                <Button
                  disabled={!googleAnalyticsIsEnabled}
                  variant="secondary"
                  size="large"
                  onClick={onFormClick}
                  loading={loading}
                  data-testid="google-analytics-customize-button"
                >
                  Customize Campaign Tracking
                </Button>
              )}
          </Flex>
        </Flex>
      )}
      <Dialog
        open={showForm && googleAnalyticsIsEnabled}
        onOpenChange={onCloseModal}
      >
        <Dialog.Content
          size="medium"
          data-testid="google-analytics-customize-form"
        >
          <Dialog.Header>
            <Heading size="medium">Customize Campaign Tracking</Heading>
          </Dialog.Header>
          <Dialog.Body>
            <Flex direction="column" gap="md">
              <Flex
                direction="column"
                gap="2xs"
                className={commonStyles.fullWidth}
              >
                <Label htmlFor={utmCampaignId} size="small">
                  Campaign Name
                </Label>
                <Input
                  id={utmCampaignId}
                  name="utmCampaign"
                  type="text"
                  placeholder="buffer"
                  value={utmCampaign}
                  onChange={onChangeUtmCampaign}
                  className={commonStyles.fullWidth}
                  data-testid="google-analytics-customize-form-campaign"
                />
              </Flex>
              <Flex
                direction="column"
                gap="2xs"
                className={commonStyles.fullWidth}
              >
                <Label htmlFor={utmSourceId} size="small">
                  Campaign Source
                </Label>
                <Input
                  id={utmSourceId}
                  type="text"
                  value={utmSource}
                  onChange={onChangeUtmSource}
                  name="utmSource"
                  placeholder="bufferapp.com"
                  className={commonStyles.fullWidth}
                  data-testid="google-analytics-customize-form-source"
                />
              </Flex>
              <Flex
                direction="column"
                gap="2xs"
                className={commonStyles.fullWidth}
              >
                <Label htmlFor={utmMediumId} size="small">
                  Campaign Medium
                </Label>
                <Input
                  id={utmMediumId}
                  type="text"
                  value={utmMedium}
                  onChange={onChangeUtmMedium}
                  name="utmMedium"
                  placeholder="social"
                  disabled={!googleAnalyticsIsEnabled}
                  className={commonStyles.fullWidth}
                  data-testid="google-analytics-customize-form-medium"
                />
              </Flex>
            </Flex>
          </Dialog.Body>
          <Dialog.Separator />
          <Dialog.Footer>
            <Button
              data-testid="google-analytics-customize-form-cancel"
              size="large"
              onClick={onCloseModal}
              variant="tertiary"
            >
              Cancel
            </Button>
            <Button
              data-testid="google-analytics-customize-form-submit"
              size="large"
              onClick={updateTrackingSettingsClick}
              loading={loading}
              variant="primary"
            >
              Update Tracking
            </Button>
          </Dialog.Footer>
        </Dialog.Content>
      </Dialog>
    </Flex>
  )
}

export { GoogleAnalytics }
