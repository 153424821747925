import type { Service } from '~publish/gql/graphql'
import { BaseService } from './BaseService'
import type { ServiceDefinition } from './types'

const capitalize = (s: string): string => s[0].toUpperCase() + s.slice(1)

export const createService = (
  name: Service | 'omni',
  config: Partial<ServiceDefinition>,
): ServiceDefinition => {
  return {
    name,
    formattedName: config.formattedName || capitalize(name),
    ...BaseService,
    ...config,
  }
}
