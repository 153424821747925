import React, { useEffect, useState } from 'react'
import clsx from 'clsx'

import {
  Button,
  Dialog,
  Flex,
  Input,
  Label,
  Link,
  Notice,
  SwatchGroup,
  Text,
  useControllableState,
} from '@buffer-mono/popcorn'

import { HC_UTM_PARAMS } from '~publish/legacy/utils/contants'
import type { Tag } from '~publish/gql/graphql'
import { useTags } from '~publish/hooks/useTags'

import { colors, selectColor } from './colors'

import styles from './TagEditDialog.module.css'

export interface TagEditDialogProps {
  children?: React.ReactNode
  tag?: Tag | { name: string; color?: string }
  onSubmit: (tag: Tag) => void | Promise<void>
  open?: boolean
  onOpenChange?: (open: boolean) => void
}

export function TagEditDialog({
  tag,
  onSubmit,
  onOpenChange: _onOpenChange,
  open: _open,
  children,
}: TagEditDialogProps): JSX.Element {
  const editMode = !!(tag as Tag)?.id
  const [name, setName] = useState<string>(tag?.name ?? '')
  const { tags } = useTags()
  const [color, setColor] = useState(
    tag?.color ?? selectColor(tags.map((tag) => tag.color)),
  )
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const [open, setOpen] = useControllableState({
    prop: _open,
    onChange: _onOpenChange,
  })
  const [saving, setSaving] = useState(false)

  useEffect(() => {
    setName(tag?.name ?? '')
    if (tag?.color) setColor(tag?.color)
  }, [tag])

  useEffect(() => {
    if (
      !editMode &&
      !isUnique(
        name,
        tags.map((tag) => tag.name),
      )
    ) {
      setErrorMessage(`This tag already exists.`)
    } else if (name && name.length > 100) {
      setErrorMessage('Name must be less than 100 characters.')
    } else {
      setErrorMessage(null)
    }
  }, [editMode, name, tags])

  const hasError = Boolean(errorMessage)
  const unChanged = tag?.name === name && tag?.color === color && editMode

  const closeDialog = (): void => setOpen(false)

  const handleSubmit = async (
    evt: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    evt.preventDefault()
    if (hasError) return

    if (unChanged) {
      closeDialog()
      return
    }

    if (!name.trim()) {
      setErrorMessage('Name is required.')
      return
    }

    setSaving(true)
    await onSubmit({
      ...tag,
      name,
      color,
    } as Tag)
    setSaving(false)
    closeDialog()
  }

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      {children && <Dialog.Trigger>{children}</Dialog.Trigger>}
      <Dialog.Content size="medium">
        <Dialog.Header>
          <Dialog.Title id="tag-form-title">
            {editMode ? 'Edit Tag' : 'New Tag'}
          </Dialog.Title>
        </Dialog.Header>
        <Dialog.Body>
          <form onSubmit={handleSubmit}>
            <Notice>
              Tags are visible to everyone in your organization.{' '}
              <Link
                href={`https://support.buffer.com/article/585-creating-and-managing-campaigns?${HC_UTM_PARAMS}`}
              >
                Learn more
              </Link>
            </Notice>
            <Label htmlFor="tagName" className={styles.label}>
              Name
            </Label>
            <Input
              id="tagName"
              value={name || ''}
              required
              onChange={(evt): void => setName(evt.target.value)}
              aria-invalid={hasError}
              aria-describedby={hasError ? 'tagNameError' : undefined}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
            />
            {hasError && (
              <Text
                id="tagNameError"
                color="critical"
                role="alert"
                className={styles.errorLabel}
              >
                {errorMessage}
              </Text>
            )}
            <Label htmlFor="colorPicker" className={styles.label}>
              Color
            </Label>
            <SwatchGroup
              id="colorPicker"
              onChange={(evt): void => setColor(evt.target.value)}
              className={clsx(styles.swatch)}
              value={color}
            >
              {colors.map(({ color, name }) => (
                <SwatchGroup.Swatch
                  key={color}
                  color={color}
                  name={name}
                  aria-label={`color ${color}`}
                />
              ))}
            </SwatchGroup>
            <Flex
              gap="xs"
              justify="start"
              direction="row-reverse"
              className={styles.formButtons}
            >
              <Button
                variant="primary"
                size="large"
                type="submit"
                loading={saving}
              >
                Save Tag
              </Button>
              <Button
                variant="tertiary"
                size="large"
                type="button"
                onClick={closeDialog}
              >
                Cancel
              </Button>
            </Flex>
          </form>
        </Dialog.Body>
      </Dialog.Content>
    </Dialog>
  )
}

function isUnique(name: string, list: string[]): boolean {
  return list.every((item) => {
    return item.toLowerCase() !== name.toLowerCase()
  })
}
