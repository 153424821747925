import { FileUploadConfig } from '../constants'

export type UploaderRestrictions = {
  maxNumberOfFiles: number
  allowedFileTypes: null | string[]
  mixedMediaError?: string
  uploadConfig: FileUploadConfig
}

export const UploaderRestrictions = {
  new(
    fileRestrictions: Partial<UploaderRestrictions> = {},
  ): UploaderRestrictions {
    return {
      ...defaultRestrictions(),
      ...fileRestrictions,
    }
  },

  forIdeas(): UploaderRestrictions {
    return UploaderRestrictions.new({
      uploadConfig: FileUploadConfig.IDEAS,
    })
  },
}

function defaultRestrictions(): UploaderRestrictions {
  return {
    maxNumberOfFiles: 10,
    allowedFileTypes: allowedFileTypes(),
    uploadConfig: FileUploadConfig.MEDIA,
  }
}

/**
 * Allowed in the backend
 * @see https://github.com/bufferapp/buffer-web/blob/main/shared/helpers/utils_helper.php#L983
 */
function allowedFileTypes(): string[] {
  return [
    'm4v',
    'mov',
    'mp4',
    'avi',
    'webm',
    'jpg',
    'jpeg',
    'png',
    'gif',
    'webp',
  ].map((ext) => `.${ext}`)
}
