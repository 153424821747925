import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { grayLight } from '@bufferapp/ui/style/colors'

const NavSubMenuList = styled.ul`
  margin: 0;
  padding: 0;
  float: left;
  width: 100%;
  border-bottom: 1px solid ${grayLight};
  box-sizing: border-box;
`

// @ts-expect-error TS(7031) FIXME: Binding element 'type' implicitly has an 'any' typ... Remove this comment to see the full error message
const NavSubMenu = ({ type, children }) => (
  <NavSubMenuList aria-label={`${type} submenu`}>{children}</NavSubMenuList>
)

NavSubMenu.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.string.isRequired,
}

export default NavSubMenu
