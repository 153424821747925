import {
  getEditorString,
  setNodes,
  type TNodeEntry,
  unwrapNodes,
} from '@udecode/plate'
import type { BufferEditor } from '~publish/legacy/editor/BufferEditor/types.plate'
import { mentionContainsInvalidCharacter } from '../../autocomplete/transforms/mentionContainsInvalidCharacter'
import { FacebookMentionInputElement } from '../nodes'

export const normalizeFacebookMentionNode = (
  editor: BufferEditor,
  nodeEntry: TNodeEntry,
): boolean => {
  const [node, path] = nodeEntry

  if (FacebookMentionInputElement.is(node)) {
    const { autocompleted, valid } = node
    const currentMentionText = getEditorString(editor, path)

    const triggerRemovedFromMention =
      !autocompleted &&
      !currentMentionText.startsWith(FacebookMentionInputElement.trigger)

    if (triggerRemovedFromMention) {
      unwrapNodes(editor, {
        at: path,
        match: (n) => FacebookMentionInputElement.is(n),
      })
      return true
    }

    if (
      !autocompleted &&
      !valid &&
      mentionContainsInvalidCharacter(
        editor,
        FacebookMentionInputElement,
        nodeEntry,
      )
    ) {
      setNodes(
        editor,
        { valid: false },
        { at: path, match: (n) => FacebookMentionInputElement.is(n) },
      )
      return true
    }
  }
  return false
}
