import styled, { css } from 'styled-components'
import MoreIcon from '@bufferapp/ui/Icon/Icons/More'

export const previewBlack = '#101318'
export const previewGrayDark = '#585A5D'
export const previewGrayMedium = '#8B8B8F'
export const previewGrayLight = '#DADCE0'
export const previewGrayLighter = '#F7F8FA'
export const grayMedium = '#B9BBC0'
export const previewBlue = '#1E4E7C'
export const previewBlueDark = '#16395b'
export const previewHighlightLight = '#0D86D3'

export const StoryContainer = styled.div`
  width: 364px;
`

export const Wrapper = styled.div`
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
  padding: 0 24px;
`

export const PostWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  border: solid 1px ${previewGrayLight};
  border-radius: 4px;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-12px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  animation: fadeIn 0.3s;
`

export type ImagesWrapperProps = {
  reverse: boolean
  fullHeight: boolean
}

export const WrapperBasicCss = css`
  display: flex;
  overflow: hidden;
  align-items: stretch;
  flex-direction: column;
`

export const ImagesWrapperCss = css<ImagesWrapperProps>`
  ${WrapperBasicCss};
  flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
  height: ${(props) => (props.fullHeight ? '100%' : '200px')};
  width: 100%;
  gap: 3px;
`

export const ImagesWrapper = styled.div<ImagesWrapperProps>`
  ${ImagesWrapperCss};
`

export type ImagesRowWrapperProps = {
  fullSize: boolean
  singleChild: boolean
}

export const ImagesRowWrapperCss = css<ImagesRowWrapperProps>`
  ${WrapperBasicCss};
  width: ${(props) => (props.fullSize ? '100%' : '50%')};
  justify-content: space-between;

  & > div {
    width: 100%;
    height: ${(props) => (props.singleChild ? '100%' : '98px')};
  }
`

export const ImagesRowWrapper = styled.div<ImagesRowWrapperProps>`
  ${ImagesRowWrapperCss};
`

export const DotsIcon = styled(MoreIcon)<{ color?: string }>`
  margin-left: auto;
  rotate: 90deg;
  color: ${({ color = previewGrayDark }): string => color};
  width: 12px;
  height: 12px;
`

export const DotsIconSmall = styled(DotsIcon)`
  width: 10px !important;
  height: 10px !important;
`

export const AvatarSquare = styled.img<{ src: string; fallbackUrl: string }>`
  width: 48px;
  height: 48px;
  border-radius: 0;
  -webkit-mask-size: cover;
  background: url(${({ src }) => src}) center no-repeat,
    url(${({ fallbackUrl }) => fallbackUrl}) center no-repeat;
  background-size: 48px;
`
