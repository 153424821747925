import { getEditorString, getPointAfter, getRange } from '@udecode/plate'

// @ts-expect-error TS(7006) FIXME: Parameter 'editor' implicitly has an 'any' type.
export const isNextCharacterText = (editor) => {
  if (!editor.selection) return false

  const nextChar = getEditorString(
    editor,
    getRange(editor, editor.selection, getPointAfter(editor, editor.selection)),
  )

  return nextChar !== '' && !/\s/.test(nextChar)
}
