import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  parseQueryParams,
  serializeQueryParams,
} from '../../hooks/useQueryParam'
import { ideaEditRoute, ideaNewRoute } from '../../legacy/routes'
import { BufferTrackerReact } from '@buffer-mono/tracking-plan'
import { useOrganizationId } from '~publish/legacy/accountContext'

export type UseIdeaComposerQueryData = {
  source: CTA
  title?: string
  text?: string
  media?: string[]
  tags?: string[]
  groupId?: string
  placeAfterId?: string
  aiAssisted?: 'true' | 'false'
}

export type UseIdeaComposerReturn = {
  createIdeaWithComposer: (input: UseIdeaComposerQueryData) => void
  editIdeaWithComposer: (input: { source: CTA; ideaId: string }) => void
}

export type CreateIdeaWithComposer = (input: UseIdeaComposerQueryData) => void

/**
 * Helper hook used to open IdeaCreationDialog and IdeaEditDialog correctly.
 * It redirects to the correct route, manages the query params,
 * and stores the returnTo location for closing the composer.
 *
 * @example
 * const { editIdeaWithComposer } = useIdeaComposer()
 *
 * // Open IdeaCreationDialog with preselected tag
 * editIdeaWithComposer({
 *   source:'create-board-ideaCard-card-1',
 *   ideaId: 'some-idea-id',
 * })
 *
 * const { createIdeaWithComposer } = useIdeaComposer()
 *
 * // Open IdeaCreationDialog with preselected tag
 * createIdeaWithComposer({
 *   source:'create-gallery-header-newButton-1',
 *   tags: ['some-tag-id'],
 * })
 *
 * // Open IdeaCreationDialog with AI generated text
 * createIdeaWithComposer({
 *   source:'create-gallery-ideaGenerator-newIdea-1'
 *   text: 'AI generated text',
 *   aiAssisted: 'true',
 * })
 *
 * // Open the IdeaCreationDialog with group and placeAfterId
 * createIdeaWithComposer({
 *   source:'create-board-group-newIdea-1'
 *
 *   groupId: 'Done',
 *   placeAfterId: 'some-idea-id',
 * })
 */
export const useIdeaComposer = (): UseIdeaComposerReturn => {
  const history = useHistory()
  const location = useLocation()
  const organizationId = useOrganizationId()

  const queryParams = React.useMemo(
    () => parseQueryParams(location.search),
    [location.search],
  )

  const createIdeaWithComposer = React.useCallback<CreateIdeaWithComposer>(
    (input) => {
      const search = serializeQueryParams({
        ...queryParams,
        ...input,
      })
      history.push({
        ...location,
        pathname: `${location.pathname}${ideaNewRoute.route}`,
        search,
        state: { returnTo: location },
      })
      BufferTrackerReact.ideaComposerOpened({
        clientName: 'publishWeb',
        organizationId,
        cta: input.source,
      })
    },
    [history, location, queryParams, organizationId],
  )

  const editIdeaWithComposer = React.useCallback(
    ({ ideaId, source }: { source: CTA; ideaId: string }): void => {
      const search = serializeQueryParams({
        ...queryParams,
        source,
      })

      const pathname = ideaEditRoute.getRoute({
        ideaId,
      })
      history.push({
        ...location,
        pathname: `${location.pathname}${pathname}`,
        search,
        state: { returnTo: location },
      })
      BufferTrackerReact.ideaComposerOpened({
        clientName: 'publishWeb',
        organizationId,
        ideaId,
        cta: source,
      })
    },
    [history, location, queryParams, organizationId],
  )

  return {
    createIdeaWithComposer,
    editIdeaWithComposer,
  }
}
