import React from 'react'

import ThumbsupIcon from '@bufferapp/ui/Icon/Icons/Thumbsup'
import MessageSquareOutlineIcon from '@bufferapp/ui/Icon/Icons/MessageSquareOutline'
import ShareArrowOutlineIcon from '@bufferapp/ui/Icon/Icons/ShareArrowOutline'
import WorldIcon from '@bufferapp/ui/Icon/Icons/World'
import Avatar from '@bufferapp/ui/Avatar'
import { SERVICE_FACEBOOK } from '~publish/legacy/constants'

import { LinkifiedText } from '~publish/legacy/shared-components/LinkifiedText'
import type {
  Image,
  Link,
  Video,
} from '~publish/legacy/composer/composer/entities/factories'
import { parseFacebookLinks } from '~publish/legacy/utils/channels/facebook/helpers'
import type { FacebookContent, ProfilePreview } from '../../../types'
import {
  draftContentPropType,
  profilePreviewPropType,
} from '../../../Common/propTypes'
import { PostWrapper, DotsIcon } from '../../../Common/styles'
import {
  DEFAULT_AVATAR,
  getMediaProperties,
  textExceedsCharLimit,
  truncateText,
  removeLinkFromEndOfText,
  FormatLayout,
  getPreviewCharLimit,
} from '../../../Common/utils'
import { formatImagesInRows, getImageLayout } from '../../utils'
import { VideoPlayer } from '../../../Common/VideoPlayer'
import { LinkPreview } from '../../../Common/LinkPreview'
import useLinkPreviewEffect from '../../../Common/hooks/useLinkPreviewEffect'
import * as Styles from './styles'

const getTextToDisplay = (
  hasLink: boolean,
  maxLength: number,
  text?: string,
  link?: string | null,
): string | undefined => {
  let textToDisplay = text
  if (hasLink && textToDisplay) {
    textToDisplay = removeLinkFromEndOfText(textToDisplay, link)
  }
  textToDisplay = truncateText(textToDisplay, maxLength)

  return textToDisplay
}

const FacebookPost = ({
  content,
  profile,
}: {
  content: FacebookContent
  profile: ProfilePreview
}): JSX.Element => {
  const { avatar, username } = profile
  const { text, images, gif, video, link: linkFromPost, entities } = content
  const [linkPreview, linkText, scrapedData] = useLinkPreviewEffect({
    linkFromPost,
    text,
    checkValidUrl: false,
    compareWithScraper: true,
    useScraperData: false,
  })
  const { hasImages, hasVideo, hasLink } = getMediaProperties(
    content,
    linkPreview,
  )
  const hasScrapedData = !!(scrapedData && scrapedData.thumbnail)
  const hasLinkData = !!(linkPreview && linkPreview.thumbnail)
  const thumbnailEdited =
    hasScrapedData &&
    hasLinkData &&
    scrapedData.thumbnail?.url !== linkPreview.thumbnail?.originalUrl

  const maxLength = getPreviewCharLimit(SERVICE_FACEBOOK)
  const textToDisplay = getTextToDisplay(hasLink, maxLength, text, linkText)
  const allImages = images?.length ? images : ([{ ...gif }] as Image[])

  return (
    <PostWrapper>
      <Styles.Header>
        <Avatar
          data-testid="avatar"
          src={avatar}
          alt={username}
          size="medium"
          fallbackUrl={DEFAULT_AVATAR}
        />
        <Styles.UsernameWrapper>
          <Styles.Username>{username}</Styles.Username>
          <Styles.FormattedUsername>
            {`Just Now · `}
            <WorldIcon />
          </Styles.FormattedUsername>
        </Styles.UsernameWrapper>
        <DotsIcon />
      </Styles.Header>
      <Styles.Body>
        <Styles.Content>
          <LinkifiedText
            links={parseFacebookLinks(textToDisplay, entities)}
            size="mini"
            whitespace="pre-wrap"
            newTab
          >
            {textToDisplay}
          </LinkifiedText>
          {text && textExceedsCharLimit(text, maxLength) && (
            <Styles.SeeMore>... See More</Styles.SeeMore>
          )}
        </Styles.Content>
      </Styles.Body>
      {hasImages && (
        <Styles.ImagesGrid
          images={allImages}
          isRowFormat={getImageLayout(allImages) === FormatLayout.Row}
          formatImages={formatImagesInRows}
        />
      )}
      {hasVideo && <VideoPlayer video={video as Video} showSoundOffIcon />}
      {hasLink && (
        <LinkPreview
          link={linkPreview as Link}
          showEmptyThumbnail
          showCompact={thumbnailEdited}
          clickable={false}
        />
      )}
      <Styles.Footer>
        <Styles.Action>
          <ThumbsupIcon />
          Like
        </Styles.Action>
        <Styles.Action>
          <MessageSquareOutlineIcon />
          Comment
        </Styles.Action>
        <Styles.Action>
          <ShareArrowOutlineIcon />
          Share
        </Styles.Action>
      </Styles.Footer>
    </PostWrapper>
  )
}

FacebookPost.propTypes = {
  content: draftContentPropType.isRequired,
  profile: profilePreviewPropType.isRequired,
}

export default FacebookPost
