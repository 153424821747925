export type VideoMetadata = {
  width: number | null
  height: number | null
  duration: number | null
  durationMs: number | null
  frameRate: number | null
  videoFormat: string | null
  videoBitrate: number | null
  audioCodec: string | null
  rotation: number
  format: string | null
  transconding: boolean
}

// this is the backend type
export type VideoDetails = {
  file_size: number
  file_extension: string
  duration: number | null
  duration_millis: number | null
  width: number | null
  height: number | null
  format: string | null
  video_format: string | null
  frame_rate: number | null
  video_bitrate: number | null
  audio_codec: string | null
  rotation: number
}

export const VideoMetadata = {
  newFromDetails(details: VideoDetails, transconding: boolean): VideoMetadata {
    return {
      width: details.width,
      height: details.height,
      duration: details.duration,
      durationMs: details.duration_millis,
      frameRate: details.frame_rate,
      videoFormat: details.video_format,
      videoBitrate: details.video_bitrate,
      audioCodec: details.audio_codec,
      rotation: details.rotation,
      format: details.format,
      transconding,
    }
  },
}
