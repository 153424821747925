import React, { forwardRef, useMemo, useState } from 'react'
import clsx from 'clsx'
import { useDroppable } from '@dnd-kit/core'
import type { Temporal } from '@js-temporal/polyfill'
import { Button } from '@buffer-mono/popcorn'

import { isPast, isToday, getZonedNow, format } from '~publish/helpers/temporal'

import { AddButton } from './AddButton'
import { useCalendarContext } from './context'
import { DraggableItem } from './DraggableItem'
import { Item } from './Item'

import styles from './Day.module.css'

const DEFAULT_ITEMS_LIMIT = 5
const FIVE_MINUTES = 300

interface DayProps {
  day: Temporal.ZonedDateTime
}

const Day = forwardRef<HTMLTableCellElement, DayProps>(({ day }, ref) => {
  const { items, readOnly, selectedDate, timezone, isFirstLoad } =
    useCalendarContext()

  const [limit, setLimit] = useState(DEFAULT_ITEMS_LIMIT)

  const now = getZonedNow(timezone).round('minute')
  const isCurrentMonth = day.month === selectedDate.month

  const dropDisabled = (isPast(day) && !isToday(day)) || readOnly

  const middayTimestamp = day.startOfDay().add({ hours: 12 }).epochMilliseconds
  const currentTimestamp = now.epochMilliseconds
  const timestamp =
    currentTimestamp > middayTimestamp
      ? currentTimestamp + FIVE_MINUTES
      : middayTimestamp

  const itemsToRender = useMemo(
    () =>
      items?.filter((item) => {
        return (
          item.timestamp >= day.startOfDay().epochMilliseconds &&
          item.timestamp < day.add({ days: 1 }).startOfDay().epochMilliseconds
        )
      }),
    [items, day],
  )

  const { setNodeRef, isOver, active } = useDroppable({
    id: day.toInstant().epochMilliseconds.toString(),
    disabled: dropDisabled,
    data: {
      type: 'hour',
      timestamp,
    },
  })

  const showAddButton = !dropDisabled && !active && !isFirstLoad

  return (
    <td
      data-testid={`monthly-day-${day}`}
      data-datetime={day.toString()}
      data-container-id={day.toInstant().epochMilliseconds.toString()}
      className={clsx(styles.day, {
        [styles.disabled]: dropDisabled,
        [styles.active]: isToday(day),
        [styles.loading]: isFirstLoad,
        [styles.draggingOver]: isOver,
        [styles.currentMonth]: isCurrentMonth,
        [styles.dragHappening]: Boolean(active),
      })}
      ref={ref}
    >
      {/* Hide day number during loading to communicate to the user that the data is not present yet */}
      {!isFirstLoad && (
        <span className={styles.dayNumber}>{format(day, 'd')}</span>
      )}
      {showAddButton && (
        <AddButton
          data-testid={`post-button-${day.toString()}`}
          labelDate={format(day, 'eeee, d MMMM y')}
          timestamp={timestamp}
          className={styles.addButton}
        />
      )}
      <div className={styles.dayContent} ref={setNodeRef}>
        {itemsToRender && itemsToRender.length > 0 && (
          <>
            <div className={styles.itemsWrapper}>
              {itemsToRender.slice(0, limit).map((item, index) => {
                return (
                  <DraggableItem
                    key={item.id}
                    id={item.id}
                    index={index}
                    timestamp={timestamp}
                    className={clsx(styles.item, {
                      [styles.activeItem]: active?.id === item.id,
                    })}
                    shouldDisableDrag={
                      readOnly || item.shouldDisableDrag === undefined
                        ? item.timestamp < now.epochSeconds
                        : item.shouldDisableDrag
                    }
                  >
                    <Item {...item} index={index} />
                  </DraggableItem>
                )
              })}
            </div>
            {itemsToRender.length > limit && (
              <Button
                variant="tertiary"
                size="small"
                onClick={(): void => setLimit((prev) => prev + 5)}
              >
                + More
              </Button>
            )}
          </>
        )}
      </div>
    </td>
  )
})

Day.displayName = 'Day'

const memoDay = React.memo(Day)
export { memoDay as Day }
