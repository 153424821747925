import React from 'react'
import PropTypes from 'prop-types'
import { MediaTypes } from '~publish/legacy/constants'
import Modal from '../shared-components/modal/modal'
import styles from './css/MediaZoomBox.module.css'
import ImageDescriptionInput from './ImageDescriptionInput'
import {
  calculateAspectRatio,
  getHumanReadableSize,
} from '../utils/StringUtils'
import type { Media } from '../entities/factories'
import { Document } from '../entities/Document'

interface MediaZoomBoxProps {
  media: Media
  draftId: string
  showImageDescription: boolean
}
const MediaZoomBox = ({
  media,
  draftId,
  showImageDescription,
}: MediaZoomBoxProps): JSX.Element => {
  const modalClassNames = {
    modal: styles.modalStyles,
    closeButton: styles.closeButton,
  }

  const aspectRatio =
    !Document.isDocument(media) &&
    media.width &&
    media.height &&
    calculateAspectRatio(media.width, media.height)

  return (
    // @ts-expect-error TS(2322) FIXME: Type '{ children: (false | Element)[]; classNames:... Remove this comment to see the full error message
    <Modal classNames={modalClassNames}>
      {media.mediaType === MediaTypes.GIF && (
        <div className={styles.mediaContainer}>
          <img className={styles.media} alt="" src={media.url} />
        </div>
      )}
      {media.mediaType === MediaTypes.IMAGE && (
        <div className={styles.mediaContainer}>
          <img className={styles.media} alt="" src={media.url} />
          {showImageDescription && (
            <ImageDescriptionInput draftId={draftId} mediaAttachment={media} />
          )}
          {aspectRatio && <div className={styles.fileSize}>{aspectRatio}</div>}
        </div>
      )}
      {media.mediaType === MediaTypes.VIDEO && (
        <div className={styles.mediaContainer}>
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <video
            className={styles.media}
            // @ts-expect-error TS(2339) FIXME: Property 'thumbnail' does not exist on type 'Media... Remove this comment to see the full error message
            poster={media.thumbnail}
            controls
            src={media.url}
          />
          <div className={styles.fileSize}>
            {/* @ts-expect-error TS(2339) FIXME: Property 'size' does not exist on type 'Media'. */}
            {getHumanReadableSize(media.size)}
            {aspectRatio && (
              <>
                {' | '}
                {aspectRatio}
              </>
            )}
          </div>
        </div>
      )}
    </Modal>
  )
}

MediaZoomBox.propTypes = {
  media: PropTypes.object.isRequired,
  draftId: PropTypes.string.isRequired,
  showImageDescription: PropTypes.bool.isRequired,
}

export default MediaZoomBox
