import { getDebugLogger } from '~publish/legacy/utils/getDebugLogger'

const debug = getDebugLogger('env')

const IS_WITH_PROD = import.meta.env.MODE === 'dev-with-prod'
const IS_WITH_STAGING = import.meta.env.MODE === 'dev-with-staging'

type EnvName = 'production' | 'staging' | 'development' | 'test'

function getEnvFromHostname(): EnvName {
  if (window.location.hostname.includes('.dev')) {
    return 'staging'
  }
  if (window.location.hostname.includes('.local')) {
    return 'development'
  }
  return 'production'
}

export type Env = {
  VITE_NODE_ENV: EnvName
  VITE_COMMIT_HASH: string
  VITE_SPLIT_ENV: string
  VITE_STRIPE_PUBLISHABLE_KEY: string
  VITE_SEGMENT_KEY: string
  VITE_GRAPHQL_API: string
  VITE_GRAPHQL_MOCK_API: string
  VITE_NAVIGATOR_URL: string
  VITE_CANVA_KEY: string
  VITE_UPLOADCARE_KEY: string
  VITE_SC_ATTR: string
  VITE_UNSPLASH_APP_ID: string
  VITE_X_BUFFER_CLIENT_ID: string
  VITE_FULLSTORY_ORG_ID: string
}

const COMMON_VARS: Pick<
  Env,
  | 'VITE_COMMIT_HASH'
  | 'VITE_SC_ATTR'
  | 'VITE_X_BUFFER_CLIENT_ID'
  | 'VITE_UPLOADCARE_KEY'
> = {
  VITE_COMMIT_HASH: `${import.meta.env.VITE_COMMIT_HASH}`,
  VITE_SC_ATTR: 'publish-styles',
  VITE_X_BUFFER_CLIENT_ID: 'webapp-publish',
  VITE_UPLOADCARE_KEY: '12767249c4399a123c3b',
}

const PRODUCTION_VARS: Env = {
  ...COMMON_VARS,
  VITE_NODE_ENV: 'production',
  VITE_SPLIT_ENV: 'production',
  VITE_STRIPE_PUBLISHABLE_KEY: 'pk_qOmHaWApLCX5OoeWKQ3NrArhoyWEi',
  VITE_SEGMENT_KEY: '9Plsiyvw9NEgXEN7eSBwiAGlHD3DHp0A',
  VITE_GRAPHQL_API: 'https://graph.buffer.com',
  VITE_GRAPHQL_MOCK_API: 'https://mock-graph.dev.buffer.com/',
  VITE_NAVIGATOR_URL: 'https://components.buffer.com/navigator.js',
  VITE_CANVA_KEY: 'd2eHqmeho9SIHaD1as32ItVj',
  VITE_UNSPLASH_APP_ID: '381197',
  // 9F6GW is the main FullStory account for Buffer
  // we use o-1VJQD8-na1 when we run out of sessions
  VITE_FULLSTORY_ORG_ID: '9F6GW',
}

const STAGING_VARS: Env = {
  ...COMMON_VARS,
  VITE_NODE_ENV: 'staging',
  VITE_SPLIT_ENV: 'staging',
  VITE_STRIPE_PUBLISHABLE_KEY: 'pk_dGKqAIFsUQonSYGPBM9Rek71IHOcL',
  VITE_SEGMENT_KEY: 'qsP2UfgODyoJB3px9SDkGX5I6wDtdQ6a',
  VITE_GRAPHQL_API: 'https://graph.dev.buffer.com',
  VITE_GRAPHQL_MOCK_API: 'https://mock-graph.dev.buffer.com/',
  VITE_NAVIGATOR_URL: 'https://components.buffer.com/navigator-staging.js',
  VITE_CANVA_KEY: 'DRx4tY1x9JRv8nsvFgP3wyQe',
  VITE_UPLOADCARE_KEY: '12767249c4399a123c3b',
  VITE_UNSPLASH_APP_ID: '380646',
  VITE_FULLSTORY_ORG_ID: 'mock',
}

const DEVELOPMENT_VARS: Env = {
  ...STAGING_VARS,
  ...COMMON_VARS,
  VITE_NODE_ENV: 'development',
  VITE_SPLIT_ENV: 'local',
  VITE_GRAPHQL_API: 'https://graph.local.buffer.com',
  VITE_GRAPHQL_MOCK_API: 'https://graph.local.buffer.com',
  VITE_NAVIGATOR_URL: 'https://components.buffer.com/navigator.js',
  // VITE_NAVIGATOR_URL:
  //   'https://appshell.local.buffer.com:4713/navigator.iife.js',
}

function selectEnv(): Env {
  const envName = getEnvFromHostname()

  if (IS_WITH_PROD) {
    return {
      ...PRODUCTION_VARS,
      VITE_NODE_ENV: 'development',
    }
  }
  if (IS_WITH_STAGING) {
    return {
      ...STAGING_VARS,
      VITE_NODE_ENV: 'development',
    }
  }
  if (envName === 'development') {
    return DEVELOPMENT_VARS
  }
  if (envName === 'staging') {
    return STAGING_VARS
  }

  return PRODUCTION_VARS
}

export const env = selectEnv()

debug('env', { env, IS_WITH_PROD, IS_WITH_STAGING })
