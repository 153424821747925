import React from 'react'
import { Banner } from '@bufferapp/ui'
import { profileTabPages } from '~publish/legacy/routes'
import { useAppDispatch, useAppSelector } from '~publish/legacy/store'
import styled from 'styled-components'
import { selectCurrentProfileId } from '~publish/legacy/profile-sidebar/selectors'
import { selectFailedPostsCount } from '../selectors'

const COUNT_THRESHOLD = 4

const Wrapper = styled.div`
  > div {
    width: auto;
  }
`

export const CleanUpPrompt = () => {
  const dispatch = useAppDispatch()
  const profileId = useAppSelector(selectCurrentProfileId)
  const count = useAppSelector(selectFailedPostsCount)
  if (count < COUNT_THRESHOLD) return <></>

  const bannerButton = {
    label: 'Go to settings',
    action: () => {
      dispatch(profileTabPages.goTo({ profileId, tabId: 'settings' }))
    },
  }

  return (
    <Wrapper>
      <Banner
        // @ts-expect-error TS(2322) FIXME: Type '{ text: string; actionButton: { label: strin... Remove this comment to see the full error message
        text="There are several failed posts in this queue, you can bulk delete them on your settings page."
        actionButton={bannerButton}
        themeColor="orange"
      />
    </Wrapper>
  )
}
