import React from 'react'
import CharacterCount from './CharacterCount'
import styles from './css/Composer.module.css'
import AppStore from '../stores/AppStore'
import { DraftMethods } from '../entities/Draft/DraftMethods'
import type Draft from '../entities/Draft/Draft'

const CharacterHashtagCount = ({ draft }: { draft: Draft }) => {
  const shouldShowHashtagCount =
    AppStore.getExpandedComposerId() === draft.id &&
    draft.service.isInstagram() &&
    draft.service.maxHashtags !== null &&
    draft.service.maxHashtags - draft.getNumberOfHashtags() <= 10

  const shouldShowCharacterCount =
    AppStore.getExpandedComposerId() === draft.id &&
    draft.canType() &&
    draft.canTypeMoreCharacters()

  const hasCount = shouldShowCharacterCount || shouldShowHashtagCount
  const combinedClassName = `${styles.characterCountWrapper} ${draft.service.name}-composer-character-counter`

  if (hasCount) {
    return (
      <div className={combinedClassName}>
        {shouldShowCharacterCount && (
          <CharacterCount
            count={draft.characterCount}
            maxCount={DraftMethods.getCharLimit(draft) || 0}
          />
        )}
        {shouldShowHashtagCount && draft.service.maxHashtags && (
          <CharacterCount
            count={draft.getNumberOfHashtags()}
            maxCount={draft.service.maxHashtags}
            type="hashtag"
          />
        )}
      </div>
    )
  }

  return null
}

export default CharacterHashtagCount
