import AppDispatcher from '../dispatcher'
import { NotificationTypes } from '../AppConstants'
import { ActionTypes } from '../state/ActionTypes'
import type {
  ErrorNotification,
  InfoNotification,
  NotificationScopes,
  SuccessNotification,
} from '../values/Notification'

export interface InfoNotificationAction
  extends Omit<InfoNotification, 'type' | 'id'> {
  isUnique?: boolean
}

export interface SuccessNotificationAction
  extends Omit<SuccessNotification, 'type' | 'id'> {
  isUnique?: boolean
}

export interface ErrorNotificationAction
  extends Omit<ErrorNotification, 'type' | 'id'> {
  isUnique?: boolean
}

const NotificationActionCreators = {
  queueError: ({
    scope,
    message,
    errorCode = 0,
    onlyCloseOnce = false,
    showSoftAndHardCloseOptions = false,
    isUnique = false,
    data = {},
  }: ErrorNotificationAction): void => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.QUEUE_NOTIFICATION,
      type: NotificationTypes.ERROR,
      scope,
      errorCode,
      message,
      onlyCloseOnce,
      showSoftAndHardCloseOptions,
      isUnique,
      data,
    })
  },

  queueSuccess: ({
    scope,
    message,
    onlyCloseOnce,
    showSoftAndHardCloseOptions,
    isUnique,
    data,
  }: SuccessNotificationAction): void => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.QUEUE_NOTIFICATION,
      type: NotificationTypes.SUCCESS,
      scope,
      message,
      onlyCloseOnce,
      showSoftAndHardCloseOptions,
      isUnique,
      data,
    })
  },

  queueInfo: ({
    scope,
    message,
    onlyCloseOnce,
    showSoftAndHardCloseOptions,
    isUnique,
    data,
    cta = undefined,
  }: InfoNotificationAction): void => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.QUEUE_NOTIFICATION,
      type: NotificationTypes.INFO,
      scope,
      message,
      onlyCloseOnce,
      showSoftAndHardCloseOptions,
      isUnique,
      data,
      cta,
    })
  },

  removeNotification: (id: string, data = {}): void => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.REMOVE_NOTIFICATION,
      id,
      data,
    })
  },

  removeNotificationCookie: (id: string): void => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.REMOVE_NOTIFICATION_COOKIE,
      id,
    })
  },

  removeAllNotificationsByScope: (
    scope: NotificationScopes,
    comesFromDirectUserAction = false,
  ): void => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.REMOVE_ALL_NOTIFICATIONS_BY_SCOPE,
      scope,
      comesFromDirectUserAction,
    })
  },

  removeComposerOmniboxNotices: (draftId: string): void => {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.REMOVE_COMPOSER_NOTICES,
      draftId,
    })
  },
}

export default NotificationActionCreators
