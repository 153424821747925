import { gray, grayLighter } from '@bufferapp/ui/style/colors'
import { fontSizeSmall } from '@bufferapp/ui/style/fonts'
import React from 'react'
import styled from 'styled-components'
import { InfoIcon } from '@buffer-mono/popcorn'

export const ProcessingBadgeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: ${grayLighter};
  font-size: ${fontSizeSmall};
  border: 1px solid ${gray};
  border-radius: 4px 4px 0 0;
  border-bottom: none;
  padding: 8px;
  width: 100%;
`

export const ProcessingBadge = (): JSX.Element => {
  return (
    <ProcessingBadgeWrapper>
      <InfoIcon /> Your post is being processed and will go live soon. No action
      needed. Larger video files may take more time.
    </ProcessingBadgeWrapper>
  )
}
