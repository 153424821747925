import React from 'react'

import { Button, CoachMark } from '@buffer-mono/popcorn'

import { useDismissableBanner } from '~publish/hooks/useDismissableBanner'
import AppStore from '../../stores/AppStore'

export const ThreadsHashtagCoachmark = ({
  children,
  serviceName,
}: {
  children: JSX.Element
  serviceName: string
}): JSX.Element => {
  const banner = useDismissableBanner('threads-hashtag-coachmark')

  if (
    !banner.isActive ||
    serviceName !== 'threads' ||
    !AppStore.isTransitionFinished()
  ) {
    return children
  }

  return (
    <CoachMark defaultOpen dismissTimeout={null} onDismiss={banner.dismiss}>
      {children}
      <CoachMark.Overlay>
        <CoachMark.Spotlight
          style={{
            '--spotlight-top': '20px',
            '--spotlight-left': '-20px',
          }}
        ></CoachMark.Spotlight>
      </CoachMark.Overlay>

      <CoachMark.Content>
        <CoachMark.Title>Threads and topics ✨</CoachMark.Title>
        <CoachMark.Description>
          On Threads, hashtags are called <i>topics</i>. Unlike hashtags,
          Threads allows one topic per post, making it easier for your audience
          to find your content. Choose a topic carefully to maximize visibility
          and impact.
        </CoachMark.Description>
        <CoachMark.Footer>
          <CoachMark.Dismiss>
            <Button>Got it</Button>
          </CoachMark.Dismiss>
        </CoachMark.Footer>
      </CoachMark.Content>
    </CoachMark>
  )
}
