import { PostEntity } from '~publish/legacy/post/PostEntity'
import { getPostActionString } from '~publish/legacy/post/helpers'
import type { RpcUpdate } from '~publish/legacy/post/types'
import {
  selectProfileTimezone,
  selectCurrentProfile,
} from '~publish/legacy/profile-sidebar/selectors'
import { useAppSelector } from '~publish/legacy/store'
import {
  selectHasTwentyFourHourTimeFormat,
  selectUserId,
} from '~publish/legacy/user/selectors'
import type { HeaderDetails } from '~publish/legacy/campaign/types'

type UsePostDetailsResponse = {
  postAction?: string
} & HeaderDetails

export const usePostDetails = (
  post: RpcUpdate,
  isCampaign: boolean,
  isReminder: boolean,
): UsePostDetailsResponse => {
  const userId = useAppSelector(selectUserId)
  const profileId = post?.profileId
  const profileTimezone = useAppSelector((state) =>
    selectProfileTimezone(state, profileId),
  )
  const twentyFourHourTime: boolean = useAppSelector(
    selectHasTwentyFourHourTimeFormat,
  )

  const currentProfile = useAppSelector(selectCurrentProfile)
  const currentChannel = {
    type: currentProfile?.service ?? '',
    avatarUrl: currentProfile?.avatarUrl ?? '',
    handle: currentProfile?.serviceUsername ?? '',
  }

  if (!post)
    return {
      postAction: '',
      creatorName: '',
      creatorEmail: '',
      avatarUrl: '',
      createdAt: '',
      channel: undefined,
      hideCreatorDetails: false,
      showHeader: true,
    }

  const createdAtString = PostEntity.getFormatedCreatedAt(post, {
    twentyFourHourTime,
    timezone: profileTimezone,
  })

  return {
    postAction: getPostActionString({
      post,
      profileTimezone,
      twentyFourHourTime,
      isReminder,
    }),
    avatarUrl: post?.user?.avatar ?? post?.user?.gravatar ?? '',
    createdAt: createdAtString,
    creatorEmail: post.user?.id === userId ? 'You' : post.user?.email ?? '',
    channel: isCampaign
      ? post.headerDetails?.channel || currentChannel
      : undefined,
    hideCreatorDetails: post?.headerDetails?.hideCreatorDetails,
    showHeader: post.via === 'Buffer' || isCampaign,
  }
}
