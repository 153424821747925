// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'uuid... Remove this comment to see the full error message
import uuid from 'uuid/v4'

export enum NotificationTypes {
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
}

export enum NotificationScopes {
  BOARD_CREATION = 'BOARD_CREATION',
  FILE_UPLOAD = 'FILE_UPLOAD',
  UPDATE_SAVING_AGGREGATE = 'UPDATE_SAVING_AGGREGATE',
  UPDATE_SAVING = 'UPDATE_SAVING',
  AUTOCOMPLETE = 'AUTOCOMPLETE',
  COMPOSER_NOTICE_NOT_PREFILLED = 'COMPOSER_NOTICE_NOT_PREFILLED',
  MC_OMNIBOX_EDIT_NOTICE = 'MC_OMNIBOX_EDIT_NOTICE',
  MC_ROLLOUT_INFO = 'MC_ROLLOUT_INFO',
  TWITTER_MAX_ONE_PROFILE_SELECTED = 'TWITTER_MAX_ONE_PROFILE_SELECTED',
  TWITTER_DUPLICATE_CONTENT_WARNING = 'TWITTER_DUPLICATE_CONTENT_WARNING',
  COMPOSER_FACEBOOK_AUTOCOMPLETE_DISABLED = 'COMPOSER_FACEBOOK_AUTOCOMPLETE_DISABLED',
  PROFILE_QUEUE_LIMIT = 'PROFILE_QUEUE_LIMIT',
  FB_IG_URL_NO_LINK_PREVIEW = 'FB_IG_URL_NO_LINK_PREVIEW',
}

export interface ErrorNotification {
  type: NotificationTypes.ERROR
  id: string
  scope: NotificationScopes
  errorCode?: number
  message: string
  onlyCloseOnce?: boolean
  showSoftAndHardCloseOptions?: boolean
  data?: Record<string, string>
}

export interface InfoNotification {
  type: typeof NotificationTypes.INFO
  id: string
  scope: `${NotificationScopes}-${string}` | NotificationScopes
  message: string
  onlyCloseOnce?: boolean
  showSoftAndHardCloseOptions?: boolean
  data?: Record<string, string>
  cta?: {
    label: string
    isUpgradePath: boolean
    action: () => void
  }
}

export interface SuccessNotification {
  type: NotificationTypes.SUCCESS
  id: string
  scope: NotificationScopes
  message: string
  onlyCloseOnce: boolean
  showSoftAndHardCloseOptions: boolean
  data: Record<string, string>
}

export type Notification =
  | ErrorNotification
  | InfoNotification
  | SuccessNotification

export const Notification = {
  new: ({
    type = NotificationTypes.INFO,
    // @ts-expect-error TS(7031) FIXME: Binding element 'scope' implicitly has an 'any' ty... Remove this comment to see the full error message
    scope,
    // @ts-expect-error TS(7031) FIXME: Binding element 'errorCode' implicitly has an 'any... Remove this comment to see the full error message
    errorCode,
    // @ts-expect-error TS(7031) FIXME: Binding element 'message' implicitly has an 'any' ... Remove this comment to see the full error message
    message,
    onlyCloseOnce = false,
    showSoftAndHardCloseOptions = false,
    data = {},
  }): Notification => ({
    id: uuid(),
    type,
    scope,
    errorCode,
    message,
    onlyCloseOnce,
    showSoftAndHardCloseOptions,
    data,
  }),
  newFileError: ({ message }: { message: string }): Notification => {
    return Notification.new({
      type: NotificationTypes.ERROR,
      scope: NotificationScopes.FILE_UPLOAD,
      errorCode: 1200,
      message,
    })
  },
  getNewErrorNotification: (
    // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
    id,
    {
      // @ts-expect-error TS(7031) FIXME: Binding element 'scope' implicitly has an 'any' ty... Remove this comment to see the full error message
      scope,
      // @ts-expect-error TS(7031) FIXME: Binding element 'errorCode' implicitly has an 'any... Remove this comment to see the full error message
      errorCode,
      message = '',
      onlyCloseOnce = false,
      showSoftAndHardCloseOptions = false,
      data = {},
    },
  ): ErrorNotification => ({
    type: NotificationTypes.ERROR,
    id,
    scope,
    errorCode,
    message,
    onlyCloseOnce,
    showSoftAndHardCloseOptions,
    data,
  }),

  getNewSuccessNotification: (
    // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
    id,
    {
      // @ts-expect-error TS(7031) FIXME: Binding element 'scope' implicitly has an 'any' ty... Remove this comment to see the full error message
      scope,
      message = '',
      onlyCloseOnce = false,
      showSoftAndHardCloseOptions = false,
      data = {},
    },
  ): SuccessNotification => ({
    type: NotificationTypes.SUCCESS,
    id,
    scope,
    message,
    onlyCloseOnce,
    showSoftAndHardCloseOptions,
    data,
  }),

  getNewInfoNotification: (
    // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
    id,
    {
      // @ts-expect-error TS(7031) FIXME: Binding element 'scope' implicitly has an 'any' ty... Remove this comment to see the full error message
      scope,
      message = '',
      onlyCloseOnce = false,
      showSoftAndHardCloseOptions = false,
      data = {},
      // @ts-expect-error TS(7031) FIXME: Binding element 'cta' implicitly has an 'any' type... Remove this comment to see the full error message
      cta,
    },
  ) => ({
    type: NotificationTypes.INFO,
    id,
    scope,
    message,
    onlyCloseOnce,
    showSoftAndHardCloseOptions,
    data,
    cta,
  }),
}
