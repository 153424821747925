/* eslint-disable prefer-regex-literals */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import SparklesIcon from '@bufferapp/ui/Icon/Icons/Sparkles'

import { useContentGeneration } from '~publish/legacy/ai/hooks/useContentGeneration'
import { useAppSelector } from '~publish/legacy/store'
import { Label } from '~publish/legacy/ai/components/AIAssistant/components/Label'
import { Title } from '~publish/legacy/ai/components/AIAssistant/components/Title'
import { PromptTextarea } from '~publish/legacy/ai/components/AIAssistant/components/PromptTextarea'
import { ActionButton } from '~publish/legacy/ai/components/AIAssistant/components/ActionButton'
import { GenerationResult } from '~publish/legacy/ai/components/AIAssistant/components/GenerationResult'
import { Prompt } from '~publish/legacy/ai/components/AIAssistant/components/Prompt'
import { AIAssistantContext } from '~publish/legacy/ai/components/AIAssistant/AIAssistant'
import { selectContentGenerationStatus } from '~publish/legacy/ai/state/slice'

import * as Styled from './AIWrite.styles'

/**
 *
 */
const TITLE_CONTENT = 'What do you want to write about?'

const MAX_PROMPT_LENGTH = 10_000

/**
 *
 */
function AIWrite({
  initAction,
  initPrompt,
  onClear,
}: {
  initAction: string | null
  initPrompt: string | null
  onClear: () => void
}): JSX.Element {
  const [editing, setEditing] = React.useState<boolean>(true)
  const [currentPrompt, setCurrentPrompt] = React.useState<string | null>(
    initPrompt,
  )

  const { channel } = React.useContext(AIAssistantContext)

  const { generateContent } = useContentGeneration()

  const {
    contentGenerationInProgress,
    contentGenerationError,
    contentGenerationResults,
  } = useAppSelector((state) => selectContentGenerationStatus(state))

  React.useEffect(() => {
    const editing =
      contentGenerationResults?.length <= 0 &&
      !contentGenerationInProgress &&
      !contentGenerationError

    setEditing(editing)
  }, [
    contentGenerationInProgress,
    contentGenerationError,
    contentGenerationResults,
  ])

  const onPromptChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ): void => {
    setCurrentPrompt(event.target.value)
  }

  const onSubmit = (event: React.KeyboardEvent | React.MouseEvent): void => {
    event.preventDefault()
    onGenerateContent()
  }

  const onGenerateContent = (): void => {
    const action = initAction || 'generate'
    if (!currentPrompt) return

    generateContent({
      prompt: currentPrompt,
      action,
      service: channel,
      tone: null,
    })
  }

  const onToggleEditing = (): void => {
    if (contentGenerationInProgress) return
    setEditing(!editing)
  }

  const onClearPrompt = (): void => {
    setCurrentPrompt(null)
    onClear()
  }

  const showPrompt = currentPrompt && !editing
  const showConfigurationForm = !currentPrompt || editing

  return (
    <Styled.Wrapper>
      {showConfigurationForm && (
        <Configuration
          prompt={currentPrompt}
          onChange={onPromptChange}
          onSubmit={onSubmit}
          onCancel={onToggleEditing}
        />
      )}
      {showPrompt && (
        <Styled.PromptWrapper>
          <Title>{TITLE_CONTENT}</Title>
          <Prompt
            prompt={currentPrompt}
            expanded={false}
            onToggle={onToggleEditing}
            onClear={onClearPrompt}
          />
        </Styled.PromptWrapper>
      )}
      <GenerationResult disabled={editing} />
    </Styled.Wrapper>
  )
}

/**
 *
 */
function Configuration({
  prompt,
  onChange,
  onSubmit,
  onCancel,
}: {
  prompt: string | null
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
  onSubmit: (event: React.KeyboardEvent | React.MouseEvent) => void
  onCancel?: () => void
}): JSX.Element {
  const { contentGenerationResults } = useAppSelector((state) =>
    selectContentGenerationStatus(state),
  )
  const hasResults = contentGenerationResults.length > 0
  const label = hasResults ? 'Generate New' : 'Generate'
  const showURLWarning = prompt && containsURL(prompt)

  const isPromptTooLong = prompt && prompt.length > MAX_PROMPT_LENGTH
  const isSubmitDisabled = isPromptTooLong || !prompt

  return (
    <Styled.Form withBorder={hasResults}>
      <Label htmlFor="prompt">{TITLE_CONTENT}</Label>
      <PromptTextarea
        id="prompt"
        testId="ai-assistant-textarea"
        name="prompt"
        value={prompt || ''}
        placeholder="Eg. Promote my photography course to get new signups. Registration closes in 3 days."
        tip="Include key points, your target audience and your desired outcome for this post"
        errorMessage={
          isPromptTooLong
            ? `Your prompt exceeds ${MAX_PROMPT_LENGTH.toLocaleString()} characters, please shorten it`
            : undefined
        }
        onChange={onChange}
      />

      {showURLWarning && (
        <Styled.Warning>
          <SparklesIcon />
          <p>
            <span>For best results:</span>
            Copy-paste the URL content into the field above. The AI Assistant
            does not have internet access and can&lsquo;t view URLs you share.
          </p>
        </Styled.Warning>
      )}
      <Styled.ButtonWrapper>
        {hasResults && (
          <Styled.CancelButton onClick={onCancel}>Cancel</Styled.CancelButton>
        )}
        <ActionButton
          action="generate"
          label={label}
          target={prompt}
          disabled={isSubmitDisabled}
          type="submit"
          onClick={onSubmit}
          testId="ai-assistant-generate-button"
        />
      </Styled.ButtonWrapper>
    </Styled.Form>
  )
}

/**
 *
 */
function containsURL(str: string): boolean {
  return new RegExp(
    '([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?',
  ).test(str)
}

export { AIWrite }
