import styled from 'styled-components'
import Masonry from 'react-masonry-css'

export const GRID_GAP = 24
export const GRID_PADDING = 40

export const MasonryStyled = styled(Masonry)`
  display: flex;
  width: auto;
  padding: 20px 40px 8px ${GRID_PADDING - GRID_GAP}px;
  gap: 16px;

  .ideas-masonry-column {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`
