import React, { useState } from 'react'
import CopyIcon from '@bufferapp/ui/Icon/Icons/Copy'
import Tooltip from '@bufferapp/ui/Tooltip'

import { useAppSelector } from '~publish/legacy/store'

import { selectContentGenerationStatus } from '~publish/legacy/ai/state/slice'

import * as Styled from './CopyButton.styles'

function CopyButton({
  disabled,
}: {
  disabled: boolean | undefined
}): JSX.Element {
  const [isCopied, setIsCopied] = useState(false)
  const { contentGenerationResults } = useAppSelector((state) =>
    selectContentGenerationStatus(state),
  )

  function handleFeedbackOnClick(): void {
    setIsCopied(true)
    // Reset state in case user clicks copy button again
    setTimeout(() => {
      setIsCopied(false)
    }, 1500)
  }

  return (
    <Styled.Wrapper className={disabled ? 'disabled' : ''}>
      {isCopied && <Styled.Toast>Copied!</Styled.Toast>}
      {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element; label: string; position... Remove this comment to see the full error message */}
      <Tooltip label="Copy text" position="top">
        <Styled.Button disabled={disabled}>
          <CopyIcon
            onClick={(): void => {
              navigator.clipboard.writeText(contentGenerationResults[0])
              handleFeedbackOnClick()
            }}
          />
        </Styled.Button>
      </Tooltip>
    </Styled.Wrapper>
  )
}

export { CopyButton }
