export const getStillDataUriFromGif = (gifUrl: string): Promise<string> =>
  new Promise((resolve, reject) => {
    const image = new Image()

    const getStillDataUri = () => {
      const canvas = document.createElement('canvas')

      canvas.width = image.naturalWidth
      canvas.height = image.naturalHeight
      canvas.getContext('2d')?.drawImage(image, 0, 0)

      const stillDataUri = canvas.toDataURL('image/png')
      resolve(stillDataUri)
    }

    image.addEventListener('load', getStillDataUri)
    image.addEventListener('error', () => {
      // TODO: Monitor still gif creation error events in Datadog
      // eslint-disable-next-line prefer-promise-reject-errors
      reject()
    })
    image.crossOrigin = 'anonymous'
    image.src = gifUrl
  })

const isEdge = () => window.navigator.userAgent.indexOf('Edge') > -1

export const generateThumbnailFromGif = (file: File | Blob): Promise<File> =>
  new Promise((resolve, reject) => {
    const image = new Image()

    const getStillDataUri = () => {
      const canvas = document.createElement('canvas')

      canvas.width = image.naturalWidth
      canvas.height = image.naturalHeight
      canvas.getContext('2d')?.drawImage(image, 0, 0)
      canvas.toBlob(
        (blob) => {
          let imageFile
          if (isEdge()) {
            // send as blob because Edge doesn't support file constructors. Currently
            // not resizing images sents as blobs because they appear incorrectly
            imageFile = blob
          } else {
            // TODO: use file name for image file
            const thumbnailName = 'image.jpeg'
            // const thumbnailName = file.name.replace(/\.[^/.]+$/, '.jpeg');
            if (blob) {
              imageFile = new File([blob], thumbnailName, { type: blob.type })
            }
          }
          // @ts-expect-error TS(2345) FIXME: Argument of type 'Blob | null | undefined' is not ... Remove this comment to see the full error message
          resolve(imageFile)
        },
        'image/jpeg',
        0.75 /* quality */,
      )
    }

    image.addEventListener('load', getStillDataUri)
    image.addEventListener('error', () => {
      // TODO: Monitor still gif creation error events in Datadog
      // eslint-disable-next-line prefer-promise-reject-errors
      reject()
    })
    image.crossOrigin = 'anonymous'
    image.src = URL.createObjectURL(file)
  })
