import * as Dialog from '@radix-ui/react-dialog'
import styled, { css } from 'styled-components'

import { white, gray, grayDarker } from '@bufferapp/ui/style/colors'
import CrossIcon from '@bufferapp/ui/Icon/Icons/Cross'

export const StyledOverlay = styled(Dialog.Overlay)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(50, 59, 67, 0.8);
  opacity: 0.9;
`

export const ModalWrapper = styled(Dialog.Content)`
  position: fixed;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
  width: 854px;
  height: 590px;
  background-color: ${gray};
  border-radius: 4px;
`

export const InnerWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
`

export const MediaContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
  max-width: 425px;
  align-self: center;
`
export const FileSize = styled.div`
  position: absolute;
  left: 10px;
  bottom: 10px;
  background: ${grayDarker};
  opacity: 0.8;
  color: ${white};
  border-radius: 3px;
  font-size: 12px;
  padding: 3px;
  font-weight: bold;
`

export const Media = css`
  max-height: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: contain;
`

export const Image = styled.img`
  ${Media}
`

export const Video = styled.video`
  ${Media}
`

export const CloseIcon = styled(CrossIcon)`
  position: absolute;
  fill: ${white};
  top: -2px;
  right: -24px;
  cursor: pointer;
`
