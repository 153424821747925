import styled from 'styled-components'
import {
  grayDarker,
  grayLight,
  white,
  orangeLightest,
} from '@bufferapp/ui/style/colors'
import { previewBlack } from './previews/Common/styles'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  font-family: Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5em;
  color: ${previewBlack};
`

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 6px 32px 24px;
`

export const ToggleWrapper = styled.div`
  display: flex;
  border-radius: 4px;
  background: ${white};
`

export const ToggleButton = styled.button<{ selected: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  background: ${white};
  padding: 6px 12px;
  cursor: pointer;
  border: 1px solid ${grayLight};

  svg {
    max-height: 16px;
    max-width: 16px;
  }

  &#reminders-preview {
    border-radius: 4px 0 0 4px;
    border-right: 1px solid ${grayLight};
  }

  &#default-preview {
    border-radius: 0 4px 4px 0;
    border-left: none;
  }

  ${({ selected }: { selected: boolean }): string | false =>
    selected &&
    `
    background: #FFF0EA;
    border-color: ${orangeLightest}
  `}
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const Title = styled.h4`
  color: ${grayDarker};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin: 0px 4px 0px 0px;
`

export const Icon = styled.div`
  display: flex;
  cursor: pointer;
  margin-left: 3px;
`
