import { useLazyQuery } from '@apollo/client'

import { PusherEvent, usePusherEvent } from '~publish/services/pusher'
import { graphql } from '~publish/gql'
import type {
  PostsFiltersInput,
  RealTimeUpdates_PostFragment,
} from '~publish/gql/graphql'
import { wait } from '~publish/helpers/wait'
import { client } from '~publish/legacy/apollo-client'
import { isPostSatisfyingFilters } from '~publish/helpers/posts/isPostSatisfyingFilters'

const RealTimeUpdates_Post = graphql(/* GraphQL */ `
  fragment RealTimeUpdates_Post on Post {
    id
    status
    dueAt
    tags {
      id
    }
    channel {
      id
    }
  }
`)

export const GetPostForRealTimeUpdate = graphql(/* GraphQL */ `
  query GetPostForRealTimeUpdate($postId: PostId!) {
    post(input: { id: $postId }) {
      id
      dueAt
      ...PostCard_Post
      ...RealTimeUpdates_Post
    }
  }
`)

/**
 * Pusher returns RpcUpdate data, but we only need the id
 * so we can use this id to refetch the post in the cache
 */
type PusherEventData = {
  update_id: string
  draft_id: string
}

const postEvents = [
  PusherEvent.POST_UPDATED,
  PusherEvent.POST_TAG_ADDED,
  PusherEvent.POST_TAG_REMOVED,
  PusherEvent.POST_NOTE_ADDED,
  PusherEvent.POST_NOTE_DELETED,
  PusherEvent.POST_NOTE_UPDATED,
  PusherEvent.DRAFT_UPDATED,
  PusherEvent.POST_SENT,
  PusherEvent.DRAFT_MOVED,
  PusherEvent.DRAFT_APPROVED,
  PusherEvent.POST_CREATED,
]

/**
 * Evicts post from cache if it exists
 */
const evictPost = (id: string): void => {
  const normalizedId = client.cache.identify({
    id,
    __typename: 'Post',
  })

  // it is important to check that post exists in cache
  // if we try to evict non-existing post, queries that depend on it will reload
  const existingPost = client.cache.readFragment({
    id: normalizedId,
    fragment: graphql(/* GraphQL */ `
      fragment Post_evicting on Post {
        id
      }
    `),
  })

  if (!existingPost) {
    return
  }

  client.cache.evict({ id: normalizedId })
}

/**
 * Highlights post as being removed
 */
const highlightPostAsBeingRemoved = async (id: string): Promise<void> => {
  const normalizedId = client.cache.identify({
    id,
    __typename: 'Post',
  })

  if (!normalizedId) {
    return
  }

  client.cache.writeFragment({
    id: normalizedId,
    fragment: graphql(/* GraphQL */ `
      fragment Post_isProcessing on Post {
        isProcessing @client
      }
    `),
    data: {
      isProcessing: true,
    },
  })

  // next tick, to make sure apollo cache is updated and there is time
  // to trigger react update before refetching
  await wait(0)
}

/**
 * Removes highlight from post
 */
const removePostHighlight = (id: string): void => {
  const normalizedId = client.cache.identify({
    id,
    __typename: 'Post',
  })

  if (!normalizedId) {
    return
  }

  client.cache.writeFragment({
    id: normalizedId,
    fragment: graphql(/* GraphQL */ `
      fragment Post_isProcessing on Post {
        isProcessing @client
      }
    `),
    data: {
      isProcessing: false,
    },
  })
}

type UseRealTimePusherUpdatesOptions = {
  filters: PostsFiltersInput
  refetch: (options?: { mode?: 'remove' | 'insert' }) => Promise<void>
  isPostVisible: (id: string) => boolean
}

export const useRealTimePusherUpdates = ({
  filters,
  refetch,
  isPostVisible,
}: UseRealTimePusherUpdatesOptions): void => {
  const [getPost] = useLazyQuery(GetPostForRealTimeUpdate, {
    fetchPolicy: 'no-cache',
  })

  const updatePost = async (id: string): Promise<void> => {
    const existingPost = client.cache.readFragment({
      id: client.cache.identify({
        __typename: 'Post',
        id,
      }),
      fragment: RealTimeUpdates_Post,
    })
    const wasPostVisibleBeforeUpdate = isPostVisible(id)

    // fetching one post to get its data after update
    const { data } = await getPost({
      variables: {
        postId: id,
      },
    })

    // compare to updated and refetch if status or due date has changed
    const updatedPost = data?.post
    if (!updatedPost) {
      return
    }

    const { satisfies: shouldUpdatedPostBeVisible } = isPostSatisfyingFilters(
      updatedPost as RealTimeUpdates_PostFragment,
      filters,
    )

    // case 1: post was visible but should not be visible anymore after update (due to filters)
    // -> highlight post as being removed, refetch the list and remove highlight
    if (wasPostVisibleBeforeUpdate && !shouldUpdatedPostBeVisible) {
      await highlightPostAsBeingRemoved(id)
      await refetch({ mode: 'remove' })
      removePostHighlight(id)
      return
    }

    // case 2: post was not visible before, but should be visible now
    // -> refetch the list, because order is not clear
    if (!wasPostVisibleBeforeUpdate && shouldUpdatedPostBeVisible) {
      await refetch({ mode: 'insert' })
      return
    }

    // case 3: post was visble but its due date was changed which impacts its order in the list
    // -> refetch the list, because order is likely to change
    if (
      wasPostVisibleBeforeUpdate &&
      updatedPost &&
      existingPost &&
      existingPost?.dueAt !== updatedPost?.dueAt
    ) {
      await refetch()
      return
    }

    // case 4: all other cases, likely just post content has changed
    // -> update post data in apollo cache
    client.cache.writeQuery({
      query: GetPostForRealTimeUpdate,
      variables: {
        postId: id,
      },
      data,
    })
  }

  usePusherEvent(
    PusherEvent.POST_DELETED,
    async (data: PusherEventData): Promise<void> => {
      const id = data.update_id

      // highlight post as being removed, wait a bit and then remove it from cache
      await highlightPostAsBeingRemoved(id)
      await wait(500)
      evictPost(id)
    },
  )

  usePusherEvent(
    PusherEvent.POSTS_REORDERED,
    async (data?: {
      profile_id?: string
      update_ids?: Array<string>
    }): Promise<void> => {
      const hasChanges = Number(data?.update_ids?.length) > 0
      const isProfileIncluded =
        // no filters means all channles are included
        filters?.channelIds?.length === 0 ||
        filters?.channelIds?.includes(data?.profile_id ?? '')
      if (!hasChanges || !isProfileIncluded) {
        return
      }

      if (data?.update_ids?.length === 1) {
        updatePost(data.update_ids[0])
      } else {
        refetch()
      }
    },
  )

  usePusherEvent(
    PusherEvent.QUEUE_CHANGED,
    async (data?: { profile_id?: string }): Promise<void> => {
      const isProfileIncluded =
        // no filters means all channles are included
        filters?.channelIds?.length === 0 ||
        filters?.channelIds?.includes(data?.profile_id ?? '')
      if (!isProfileIncluded) {
        return
      }

      refetch()
    },
  )

  usePusherEvent(postEvents, (data: PusherEventData): void => {
    updatePost(data.update_id ?? data.draft_id)
  })
}
