import {
  type PlateEditor,
  unwrapNodes,
  type Value,
  withoutNormalizing,
} from '@udecode/plate'
import type { Path } from 'slate'

export const removeEmojiSearchNode = <V extends Value>(
  editor: PlateEditor<V>,
  path: Path,
) =>
  withoutNormalizing(editor, () => {
    unwrapNodes(editor, {
      at: path,
    })
  })
