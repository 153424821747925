import styled from 'styled-components'
import { grayDark, grayDarker, grayLighter } from '@bufferapp/ui/style/colors'
import { fontFamily } from '@bufferapp/ui/style/fonts'

export const Button = styled.button`
  cursor: pointer;
  background: none;
  color: ${grayDark};
  border: none;
  user-select: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
  font-family: ${fontFamily};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  padding: 6px;
  border-radius: 4px;

  svg {
    color: ${grayDark};
    fill: ${grayDark};
  }

  span {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    padding: 2px 6px;
  }

  &:hover {
    color: ${grayDarker};
    background: ${grayLighter};

    svg {
      color: ${grayDarker};
      fill: ${grayDarker};
    }
  }
`
