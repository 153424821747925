import { Node, Editor } from 'slate'

export type TPrompt = {
  prompt: string
  isHighlighted: boolean
}

// @ts-expect-error TS(7031) FIXME: Binding element 'editor' implicitly has an 'any' t... Remove this comment to see the full error message
export function getPrompt({ editor }): TPrompt {
  // @ts-expect-error TS(7006) FIXME: Parameter 'node' implicitly has an 'any' type.
  const texts = editor.children?.map((node) => {
    return node.type === 'p' ? `${Node.string(node)}` : ''
  })

  const highlighted = editor.selection
    ? Editor.string(editor, editor.selection)
    : null

  const prompt = highlighted ? highlighted.trim() : texts.join(' ').trim()

  return { prompt, isHighlighted: !!highlighted }
}
