import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { LeanTag } from '~publish/legacy/campaign/types'

type TagFormState = {
  tagId: string | null
  tag: LeanTag | null
  isLoading: boolean
  editMode: boolean
  isFromSelector: boolean
}

const initialState: TagFormState = {
  tagId: null,
  tag: null,
  isLoading: false,
  editMode: false,
  isFromSelector: false,
}

const tagFormSlice = createSlice({
  name: 'TagForm',
  initialState,
  reducers: {
    openTagForm: (
      state,
      action: PayloadAction<{
        editMode: boolean
        isFromSelector: boolean
        tag: LeanTag
      }>,
    ) => {
      const { editMode, isFromSelector, tag } = action.payload
      state.editMode = editMode
      state.isFromSelector = isFromSelector || false
      state.tag = tag
    },

    setLoader: (state, action: PayloadAction<{ isLoading: boolean }>) => {
      state.isLoading = action.payload.isLoading
    },

    closeTagForm: (state) => {
      state.isLoading = false
      state.isFromSelector = false
      state.tag = null
    },
  },
})

const { openTagForm, setLoader, closeTagForm } = tagFormSlice.actions

export { tagFormSlice, openTagForm, setLoader, closeTagForm }
