import { useState, useEffect } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import { useAccount } from '~publish/legacy/accountContext'

export const QUERY_IDEAS_COUNT_BY_TAG = gql`
  query ideasCountByTag($input: IdeasCountByTagInput!) {
    ideasCountByTag(input: $input) {
      ... on IdeasCount {
        count
      }
      ... on CoreWebAppCommonError {
        code
        error
      }
    }
  }
`

type useIdeasCountByTagReturn = {
  ideasCount: number
}

export const useIdeasCountByTag = (tagId: string): useIdeasCountByTagReturn => {
  const [ideasCount, setIdeasCount] = useState<number>(0)
  const { account } = useAccount()
  const organizationId = account?.currentOrganization?.id

  // @ts-expect-error TS(7006) FIXME: Parameter 'res' implicitly has an 'any' type.
  const onFetchComplete = (res): void => {
    if (res.ideasCountByTag?.count) {
      setIdeasCount(res.ideasCountByTag.count)
    }
  }

  const [fetchIdeasCount] = useLazyQuery(QUERY_IDEAS_COUNT_BY_TAG, {
    variables: { input: { organizationId, tagId } },
    onCompleted: onFetchComplete,
  })

  useEffect(() => {
    fetchIdeasCount()
  }, [])

  return { ideasCount }
}
