import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import {
  InfoIcon,
  Button,
  Tooltip,
  Label,
  Flex,
  Textarea,
} from '@buffer-mono/popcorn'

import { uploadAltTextChanged } from '~publish/legacy/uploads/state/slice'
import CharacterCount from '~publish/legacy/composer/composer/components/CharacterCount'
import type { Upload } from '../../entities/Upload'

import styles from './ImageDescription.module.css'

const altExplanation = (
  <>
    <p>
      A description, sometimes called alt-text, makes your images and videos
      accessible to people unable to see them.
    </p>
    <p>The text will also appear in place of the image if it fails to load.</p>
    <p>
      Good descriptions are descriptive and specific. Add context to help people
      create a precise picture of your image.
    </p>
  </>
)

const MAX_ALT_LENGTH = 1_000

const ImageDescription = ({
  upload,
  onClose,
}: {
  upload: Upload
  onClose: () => void
}): JSX.Element => {
  const dispatch = useDispatch()
  const [altText, setAltText] = useState('')

  useEffect(() => {
    if (upload.alt) {
      setAltText(upload.alt)
    }
  }, [upload.alt])

  const onSaveDescription = (): void => {
    dispatch(uploadAltTextChanged({ id: upload.id, altText }))
    onClose()
  }

  return (
    <form className={styles.form} onSubmit={onSaveDescription}>
      <Label className={styles.label} htmlFor="description">
        Add a Description{' '}
        <Tooltip content={altExplanation}>
          <InfoIcon size="xsmall" />
        </Tooltip>
      </Label>
      <div className={styles.textareaContainer}>
        <Textarea
          id="description"
          name="description"
          placeholder="Add a description to your photos so they're accessible to people unable to see them."
          onChange={(e): void => setAltText(e.target.value)}
          value={altText}
        />
        <CharacterCount
          className={styles.charCount}
          count={altText?.length || 0}
          maxCount={MAX_ALT_LENGTH}
        />
      </div>
      <Flex justify="end">
        <Button
          className={styles.submitBtn}
          size="large"
          type="submit"
          disabled={altText.length > MAX_ALT_LENGTH}
        >
          Save
        </Button>
      </Flex>
    </form>
  )
}

export default ImageDescription
