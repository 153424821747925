import {
  createSlice,
  type Slice,
  type SliceCaseReducers,
} from '@reduxjs/toolkit'

type IntegrationBarState = {
  uploadInProgress: boolean
}

const initialState: IntegrationBarState = {
  uploadInProgress: false,
}

export const integrationBarSlice: Slice<
  IntegrationBarState,
  SliceCaseReducers<IntegrationBarState>,
  'integrationBar'
> = createSlice({
  name: 'integrationBar',
  initialState,
  reducers: {
    integrationUploadStarted: (state) => {
      state.uploadInProgress = true
    },
    integrationUploadFinished: (state) => {
      state.uploadInProgress = false
    },
  },
})

export const { integrationUploadStarted, integrationUploadFinished } =
  integrationBarSlice.actions
