import React from 'react'
import PropTypes from 'prop-types'
import { ComposerSidepanel } from '~publish/legacy/shared-components'
import { PostPreview } from '../post-preview/PostPreview'
import {
  draftPreviewPropType,
  profilePreviewPropType,
} from '../post-preview/previews/Common/propTypes'
import type {
  DraftPreview,
  ProfilePreview,
} from '../post-preview/previews/types'

export const PostPreviewSidepanel = ({
  isVisible,
  draftPreview,
  profilePreview,
}: {
  isVisible: boolean
  draftPreview: DraftPreview
  profilePreview: ProfilePreview
}): JSX.Element => (
  <ComposerSidepanel customStyles={{ height: '100%' }} isVisible={isVisible}>
    <PostPreview draftPreview={draftPreview} profilePreview={profilePreview} />
  </ComposerSidepanel>
)

PostPreviewSidepanel.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  draftPreview: draftPreviewPropType.isRequired,
  profilePreview: profilePreviewPropType.isRequired,
}
