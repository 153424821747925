import {
  addDays,
  addHours,
  addMinutes,
  isAfter,
  startOfDay,
  max,
  setMinutes,
  setHours,
} from 'date-fns'
import { toZonedTime, fromZonedTime } from 'date-fns-tz'
import type { PostCard_PostFragment, ScheduleV2 } from '~publish/gql/graphql'
import { memoize } from '~publish/helpers/memoize'

export type Slot = { date: string }
export type SlotOrPost = Slot | (PostCard_PostFragment & { dueAt: string })

/**
 * Generate slots according to the posting schedule until a certain date
 * @param postingSchedule - The schedule for the channel
 * @param endDate - The date until which slots should be generated
 * @param timezone - The timezone of the channel
 * @returns
 */
const generatePostingSlotsUntilDate = memoize(function ({
  postingSchedule,
  timezone,
  endDate: endDateISO,
}: {
  postingSchedule: ScheduleV2[]
  timezone: string
  endDate: string
}): Slot[] {
  const slots: Slot[] = []
  if (!postingSchedule.length) {
    return slots
  }

  const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']

  const now = new Date()
  const startDate = toZonedTime(now, timezone)
  const endDate = toZonedTime(endDateISO, timezone)
  let currentDate = startOfDay(now)

  while (!isAfter(currentDate, endDate)) {
    const daySchedule = postingSchedule.find(
      (schedule) => schedule.day === days[currentDate.getDay()],
    )

    if (daySchedule) {
      for (const time of daySchedule.times) {
        const [hours, minutes] = time.split(':').map(Number)
        const slotDate = addHours(addMinutes(currentDate, minutes), hours)

        if (isAfter(slotDate, startDate) && !isAfter(slotDate, endDate)) {
          slots.push({
            date: fromZonedTime(slotDate, timezone).toISOString(),
          })
        }
      }
    }

    currentDate = startOfDay(addDays(currentDate, 1))
  }

  return slots
})

/**
 * Generate a certain number of slots according to the posting schedule and the provided numberOfSlots
 * @param postingSchedule - The schedule for the channel
 * @param numberOfSlots - The number of slots to generate
 * @param timezone - The timezone of the channel
 * @returns
 */
const generatePostingSlotsByNumber = memoize(function ({
  postingSchedule,
  timezone,
  numberOfSlots,
}: {
  postingSchedule: ScheduleV2[]
  timezone: string
  numberOfSlots: number
}): Slot[] {
  const slots: Slot[] = []
  const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']
  const now = new Date()
  const startDate = toZonedTime(now, timezone)
  let currentDate = startOfDay(startDate)

  while (slots.length < numberOfSlots) {
    const dayOfWeek = days[currentDate.getDay()]
    const daySchedule = postingSchedule.find(
      (schedule) => schedule.day === dayOfWeek,
    )

    if (daySchedule && !daySchedule.paused) {
      for (const time of daySchedule.times) {
        if (slots.length >= numberOfSlots) break

        const [hours, minutes] = time.split(':').map(Number)
        const slotDate = setHours(setMinutes(currentDate, minutes), hours)

        if (isAfter(slotDate, startDate)) {
          slots.push({
            date: fromZonedTime(slotDate, timezone).toISOString(),
          })
        }
      }
    }

    currentDate = addDays(currentDate, 1)
  }

  return slots
})

type GeneratePostingSlotsArgsBase = {
  postingSchedule: ScheduleV2[]
  timezone: string
}

type GeneratePostingSlotsArgs = GeneratePostingSlotsArgsBase &
  (
    | { endDate: string; numberOfSlots?: number }
    | { endDate?: string; numberOfSlots: number }
  )

/**
 * Generate posting slots according to the provided arguments.
 * Slots will be generated until the later of endDate or the calculated date of the slot of numberOfSlots.
 */
const generatePostingSlots = memoize(function (
  args: GeneratePostingSlotsArgs,
): Slot[] {
  const { postingSchedule, timezone, endDate, numberOfSlots } = args
  const enabledPostingSchedule = postingSchedule.filter((day) => !day.paused)

  const isPostingScheduleEmpty =
    enabledPostingSchedule.length === 0 ||
    enabledPostingSchedule.every((slot) => !slot.times.length)

  if (isPostingScheduleEmpty) {
    return []
  }

  const slotsByNumber = numberOfSlots
    ? generatePostingSlotsByNumber({
        postingSchedule: enabledPostingSchedule,
        timezone,
        numberOfSlots,
      })
    : []

  // Determine the effective end date for slot generation
  // This ensures we generate enough slots to include all scheduled posts,
  // preventing "ghost posts" from appearing out of place
  const lastSlotDate = slotsByNumber.at(-1)?.date
  let effectiveEndDate = endDate ? new Date(endDate) : undefined
  if (lastSlotDate) {
    if (!effectiveEndDate) {
      effectiveEndDate = new Date(lastSlotDate)
    } else if (endDate) {
      effectiveEndDate = max([endDate, lastSlotDate])
    }
  }

  if (!effectiveEndDate) {
    return []
  }

  const result = generatePostingSlotsUntilDate({
    postingSchedule: enabledPostingSchedule,
    timezone,
    endDate: effectiveEndDate.toISOString(),
  })
  return result
})

/**
 * Merge provided posts and slots into a single array.
 * The resulting array is sorted by date.
 * Posts that are not custom scheduled will take up a slot with the same date.
 * Posts that are custom scheduled will not take up a slot with the same date.
 */
function mergePostsAndSlots({
  posts,
  slots,
  callback,
}: {
  posts: Array<PostCard_PostFragment & { dueAt: string }>
  slots: Slot[]
  callback?: (items: SlotOrPost[]) => void
}): SlotOrPost[] {
  const postsAndSlots: SlotOrPost[] = []
  let i = 0
  let j = 0

  // Merge the two sorted arrays
  while (i < posts.length && j < slots.length) {
    const postDate = posts[i].dueAt
    const slotDate = slots[j].date

    if (!postDate) {
      i++
      continue
    }
    if (!slotDate) {
      j++
      continue
    }

    const postTakesUpSlot = postDate === slotDate && !posts[i].isCustomScheduled

    if (postTakesUpSlot) {
      postsAndSlots.push(posts[i])
      i++
      // Skip the current slot because it's occupied by a post
      j++
    } else if (postDate < slotDate) {
      // This only works if date and dueAt are both datetime ISO strings
      postsAndSlots.push(posts[i])
      i++
    } else {
      postsAndSlots.push(slots[j])
      j++
    }
  }

  // Add any remaining elements from posts
  while (i < posts.length) {
    postsAndSlots.push(posts[i])
    i++
  }

  // Add any remaining elements from slots
  while (j < slots.length) {
    postsAndSlots.push(slots[j])
    j++
  }
  callback?.(postsAndSlots)
  return postsAndSlots
}

export { generatePostingSlots, mergePostsAndSlots }
