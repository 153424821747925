import React from 'react'

import * as Styled from './styles'

export function Heading({ text }: { text: string }) {
  return (
    <Styled.Wrapper>
      <span>{text}</span>
    </Styled.Wrapper>
  )
}
