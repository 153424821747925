/**
 * Get the width of the floating Refine button that appears when user selects text
 *
 * @returns {number} - width of the refine button
 */

const getRefineButtonWidth = (): number => {
  const selector = '#ai-assistant-refine-button'
  const refineBtn = document.querySelector(selector) as HTMLElement

  if (refineBtn) {
    const refineBtnStyle = window.getComputedStyle(refineBtn)
    const refineBtnWidth = parseInt(refineBtnStyle.width) || 0

    return refineBtnWidth
  }

  return 0
}

export { getRefineButtonWidth }
