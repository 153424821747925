import React from 'react'
import styled from 'styled-components'
import { Image, MultipleImages } from '@buffer-mono/legacy-bufferapp-components'
import { grayLighter, gray } from '@bufferapp/ui/style/colors'
import { borderRadius } from '@bufferapp/ui/style/borders'

import IdTag from '../IdTag'
import { getQueueResizedImageUrl } from '../../utils/bufferImages'
import { Lightbox, type LightboxProps } from '@buffer-mono/popcorn'
import type {
  RpcUpdateVideo,
  RpcUpdate,
  RpcUpdateImage,
  RpcUpdateMedia,
  RpcUpdateDocument,
} from '~publish/legacy/post/types'
import { isNotNull } from '~publish/legacy/utils/isNotNull'
import { isNotUndefined } from '~publish/legacy/utils/isNotUndefined/isNotUndefined'

const MediaWrapper = styled.div`
  position: relative;
  cursor: pointer;
`

const ImageWrapper = styled.div<{
  shouldIncreaseHeight?: boolean
  isShortPost?: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 9rem;
  height: ${({ shouldIncreaseHeight }): string =>
    shouldIncreaseHeight ? '16rem' : '9rem'};
  border-radius: ${borderRadius};
  border: 1px solid ${gray};
  background: ${grayLighter};
  ${({ isShortPost }): string | false | undefined =>
    isShortPost &&
    `overflow: hidden;
  `}
`

const DocumentWrapper = styled(ImageWrapper)`
  overflow: hidden;
  align-items: flex-start;
  height: auto;
`

const ThumbnailTag = styled.span`
  position: absolute;
  bottom: 0.7rem;
  left: 0.7rem;
`

type Props = {
  imageSrc?: string
  videoSrc?: string
  imageUrls: string[]
  type: string
  profileService: string
  isReelPost: boolean
  isFacebookReelPost: boolean
  isFacebookStoryPost: boolean
  isStoryPost: boolean
  isShortPost: boolean
  via: string
  media?: RpcUpdate['media']
  extraMedia: RpcUpdate['extra_media']
}

const isDocument = (media?: unknown): media is RpcUpdateDocument => {
  return !!(media as RpcUpdateDocument)?.document
}

const isVideo = (media?: unknown): media is RpcUpdateVideo => {
  return !!(media as RpcUpdateVideo)?.video
}

const isImage = (media?: unknown): media is RpcUpdateImage => {
  return !!(media as RpcUpdateImage)?.picture
}

const parseMediaToSlides = (
  media: RpcUpdateMedia[],
): LightboxProps['slides'] => {
  return media
    .map((mediaItem) => {
      if (isVideo(mediaItem)) {
        const poster =
          mediaItem?.thumbnail ?? mediaItem.video?.thumbnails?.[0] ?? ''
        const { width, height } = mediaItem.video.details
        const src = mediaItem.video.details.location
        return {
          type: 'video' as const,
          width,
          height,
          poster,
          sources: [
            {
              src,
              type: 'video/mp4',
            },
          ],
        }
      }

      if (isImage(mediaItem)) {
        return {
          type: 'image' as const,
          src: mediaItem.picture,
          width: parseFloat(mediaItem.width),
          height: parseFloat(mediaItem.height),
          thumbnail: mediaItem.thumbnail,
          sources: [],
        }
      }
      return null
    })
    .filter(isNotNull)
}

const UpdateMediaContent = ({
  imageSrc,
  imageUrls,
  type,
  profileService,
  isReelPost,
  isFacebookReelPost,
  isFacebookStoryPost,
  isStoryPost,
  isShortPost,
  via,
  media: postMedia,
  extraMedia,
}: Props): JSX.Element => {
  // State
  const document = isDocument(postMedia) ? postMedia.document : null

  const isVideo = type === 'video'
  const isMultipleImage = type === 'multipleImage'

  const isLinkedinCarouselPost = !!document

  const isSingleImage = type === 'image' || isVideo
  const imagesArray = isMultipleImage ? imageUrls : [imageSrc]

  const resizedImagesArray = imagesArray?.map((url) => {
    if (!url) {
      return null
    }

    return getQueueResizedImageUrl(url)
  })

  const isFetchedShortPort = isShortPost && via === 'Youtube'

  const shouldIncreaseHeight =
    profileService === 'tiktok' || (profileService === 'youtube' && isShortPost)

  const media = [postMedia, ...(extraMedia || [])]
    .filter(isNotNull)
    .filter(isNotUndefined)
    .filter((asset) => !isDocument(asset))
  const mediaSlides = parseMediaToSlides(media)

  return (
    <Lightbox slides={mediaSlides}>
      <Lightbox.Trigger>
        <MediaWrapper
          onClick={(e): void => {
            if (document) {
              e.stopPropagation()
              window.open(document.details.location, '_blank')
            }
          }}
        >
          {isMultipleImage && (
            <MultipleImages
              border="rounded"
              height="9rem"
              urls={resizedImagesArray}
              width="9rem"
            />
          )}
          {isSingleImage && (
            <ImageWrapper
              shouldIncreaseHeight={shouldIncreaseHeight}
              isShortPost={isShortPost}
            >
              <Image
                // @ts-expect-error
                src={resizedImagesArray[0]}
                width="9rem"
                height="auto"
                maxHeight={shouldIncreaseHeight ? '16rem' : '9rem'}
                minHeight="7rem"
                objectFit="cover"
                maxWidth={isFetchedShortPort ? 'unset' : '9rem'}
                minWidth={isFetchedShortPort ? 'unset' : '7rem'}
              />
            </ImageWrapper>
          )}
          {document && (
            <DocumentWrapper>
              <Image
                // @ts-expect-error
                src={postMedia?.thumbnail}
                width="9rem"
                height="auto"
                maxHeight="16rem"
                minHeight="7rem"
                objectFit="cover"
                maxWidth="9rem"
                minWidth="7rem"
              />
            </DocumentWrapper>
          )}

          {isVideo && (isReelPost || isFacebookReelPost) && (
            <ThumbnailTag>
              <IdTag>Reel</IdTag>
            </ThumbnailTag>
          )}
          {isVideo &&
            !isReelPost &&
            !isStoryPost &&
            !isShortPost &&
            !isFacebookReelPost &&
            !isFacebookStoryPost && (
              <ThumbnailTag>
                <IdTag>Video</IdTag>
              </ThumbnailTag>
            )}
          {(isStoryPost || isFacebookStoryPost) && (
            <ThumbnailTag>
              <IdTag>Story</IdTag>
            </ThumbnailTag>
          )}
          {isShortPost && (
            <ThumbnailTag>
              <IdTag>Short</IdTag>
            </ThumbnailTag>
          )}
          {isLinkedinCarouselPost && (
            <ThumbnailTag>
              <IdTag>Document</IdTag>
            </ThumbnailTag>
          )}
        </MediaWrapper>
      </Lightbox.Trigger>
    </Lightbox>
  )
}

export default UpdateMediaContent
