import once from 'lodash/once'
import { combineReducers } from 'redux'
import { store, injectReducers } from '~publish/legacy/store'
import type { RootState } from '~publish/legacy/store'
import { logError } from '~publish/legacy/utils/logError'
import AppDispatcher from '../dispatcher'
import { ActionTypes } from './ActionTypes'
import { draftsSlice } from './slices/draftsSlice'
import { notificationsSlice } from './slices/notificationsSlice'

// combine the different slices (notifications, modal, ...) in a single reducer
//  so that each slice is nested under state.composer[sliceName]
const composerReducers = combineReducers({
  [notificationsSlice.name]: notificationsSlice.reducer,
  [draftsSlice.name]: draftsSlice.reducer,
  // add others like modals or drafts
})

// This special root reducer simplifies resetting the initial state
// https://stackoverflow.com/a/51831112/891465
// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
const composerRootReducer = (state, action) =>
  composerReducers(
    action.type === ActionTypes.APP_RESET ? undefined : state,
    action,
  )

export type ComposerSlice = typeof notificationsSlice

export type RootStateWithComposer = RootState & {
  composer: ReturnType<typeof composerReducers>
}

/**
 * Used to help us migrate the composer to redux. It performs these actions:
 *   - inject the composer reducers into the store lazyly, when the composer is loaded
 *   - dispatch to reducs all flux actions dispatches with the shape { type, payload }
 */
export const getWiredComposerStore = once(() => {
  injectReducers({
    composer: composerRootReducer,
  })

  // @ts-expect-error TS(7031) FIXME: Binding element 'action' implicitly has an 'any' t... Remove this comment to see the full error message
  AppDispatcher.register(function ({ action, source }) {
    // send all flux actions to redux store
    const { actionType, ...payload } = action
    if (!actionType) {
      logError(new Error('Missing ActionType'), {
        metaData: {
          source,
          payload,
        },
      })
      return
    }

    store.dispatch({
      type: actionType,
      payload,
    })
  })

  return store
})
