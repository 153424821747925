import { createAsyncThunk } from '@reduxjs/toolkit'
import type { RootState } from '~publish/legacy/store'
import callRpc from '~publish/legacy/utils/call-rpc'

export const changeLinkShortener = createAsyncThunk<
  boolean,
  { profileId: string; domain: string },
  { state: RootState }
>('generalSettings/changeLinkShortener', async ({ profileId, domain }) => {
  const response = await callRpc('changeLinkShortener', {
    profileId,
    domain: domain.trim(),
  })

  if (!response.linkShorteners) {
    throw new Error('No link shorteners found in the response')
  }

  return response.linkShorteners
})
