import { useQuery } from '@apollo/client'
import { graphql } from '~publish/gql'
import { useTimezone } from '~publish/hooks/useTimezone'
import { useTwentyFourHourTimeFormat } from '~publish/pages/Calendar/hooks/useTwentyFourHourTimeFormat'
import { postsReceived } from '~publish/legacy/post/slice'
import { useDispatch } from 'react-redux'
import { getCalendarViewPostFragment } from './usePostFragment'

import type {
  GetLegacyCalendarPostForCalendarPostCardQuery,
  GetLegacyCalendarPostForCalendarPostCardQueryVariables,
  PublishingPost,
} from '~publish/gql/graphql'
import { useEffect } from 'react'

export const GetLegacyCalendarPostForCalendarPostCard = graphql(/* GraphQL */ `
  query GetLegacyCalendarPostForCalendarPostCard(
    $id: ID!
    $timezone: String!
    $use24HourTime: Boolean!
  ) {
    calendarPost(
      input: { id: $id, timezone: $timezone, use24HourTime: $use24HourTime }
    ) {
      ...CalendarView_Post
    }
  }
`)

type UseQueryResponse = ReturnType<
  typeof useQuery<
    GetLegacyCalendarPostForCalendarPostCardQuery,
    GetLegacyCalendarPostForCalendarPostCardQueryVariables
  >
>

export const useLoadLegacyCalendarPostCard = (id: string): UseQueryResponse => {
  const [timezone] = useTimezone()
  const use24HourTime = useTwentyFourHourTimeFormat()
  const dispatch = useDispatch()
  const data = useQuery(GetLegacyCalendarPostForCalendarPostCard, {
    variables: { id, timezone, use24HourTime },
  })

  useEffect(() => {
    const post = getCalendarViewPostFragment(data.data?.calendarPost)
    if (post) {
      dispatch(postsReceived([post as PublishingPost]))
    }
  }, [data, dispatch])

  return data
}
