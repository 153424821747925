import React from 'react'
import ComposerActionCreators from '../../action-creators/ComposerActionCreators'
import type { Draft } from '~publish/legacy/composer/composer/entities/Draft'
import Toggle from '~publish/legacy/reminders/components/new-reminders/components/forms/toggle/Toggle'

const ShareToFeedToggle = ({ draft }: { draft: Draft }): React.JSX.Element => {
  const [shareToFeed, setShareToFeed] = React.useState(
    String(draft?.channelData?.instagram?.share_to_feed) === 'true',
  )

  if (!draft.isReelsPost()) {
    return <></>
  }

  return (
    <Toggle
      labelText="Share to Feed"
      id="share_to_feed"
      name="share_to_feed"
      onChange={(): void => {
        setShareToFeed(!shareToFeed)
        ComposerActionCreators.updateDraftInstagramData({
          ...draft?.channelData?.instagram,
          share_to_feed: !shareToFeed,
        })
      }}
      isOn={shareToFeed}
      disabled={false}
    />
  )
}

export default ShareToFeedToggle
