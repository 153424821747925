import keyWrapper from '~publish/helpers/keywrapper'
import defaultTranslations from './translations/en-us.json'

export const actionTypes = keyWrapper('I18N', {
  SET_LOCALE: 0,
  SET_TRANSLATIONS: 0,
})

const initialState = {
  locale: 'en-US',
  translations: defaultTranslations,
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_LOCALE:
      return {
        ...state,
        locale: action.locale,
      }
    case actionTypes.SET_TRANSLATIONS:
      return {
        ...state,
        translations: action.translations,
      }
    default:
      return state
  }
}

export const actions = {
  // @ts-expect-error TS(7031) FIXME: Binding element 'locale' implicitly has an 'any' t... Remove this comment to see the full error message
  setLocale: ({ locale }) => ({
    type: actionTypes.SET_LOCALE,
    locale,
  }),
  // @ts-expect-error TS(7031) FIXME: Binding element 'translations' implicitly has an '... Remove this comment to see the full error message
  setTranslations: ({ translations }) => ({
    type: actionTypes.SET_TRANSLATIONS,
    translations,
  }),
}
