import React, { useState } from 'react'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'

import CheckmarkIcon from '@bufferapp/ui/Icon/Icons/Checkmark'

import {
  StyledDropdown,
  StyledListItem,
  DropdownButton,
  StyledLabel,
  ChevronDownIconStyled,
} from './styles'

const ORIENTATIONS = [
  { id: 0, label: 'Any orientation', value: '' },
  { id: 1, label: 'Landscape', value: 'landscape' },
  { id: 2, label: 'Portrait', value: 'portrait' },
  { id: 3, label: 'Square', value: 'squarish' },
]

type OrientationDropdownMenuProps = {
  // @ts-expect-error TS(7051) FIXME: Parameter has a name but no type. Did you mean 'ar... Remove this comment to see the full error message
  onChangeOrientation: (string) => void
}

/**
 *  COMPONENT: ORIENTATION DROPDOWN MENU
 */
export const OrientationDropdownMenu = ({
  onChangeOrientation,
}: OrientationDropdownMenuProps) => {
  const [selectedOrientationLabel, setSelectedOrientationLabel] = useState(
    ORIENTATIONS[0].label,
  )

  // @ts-expect-error TS(7006) FIXME: Parameter 'orientation' implicitly has an 'any' ty... Remove this comment to see the full error message
  const handleOrientationChange = (orientation) => {
    onChangeOrientation(orientation.value)
    setSelectedOrientationLabel(orientation.label)
  }

  return (
    <DropdownMenu.Root>
      <DropdownButton>
        <StyledLabel>{selectedOrientationLabel}</StyledLabel>
        <ChevronDownIconStyled data-testid="dropdownmenu-icon" />
      </DropdownButton>

      <StyledDropdown align="start">
        {ORIENTATIONS.map((orientation) => (
          <StyledListItem
            onSelect={() => handleOrientationChange(orientation)}
            key={orientation.id}
            selected={selectedOrientationLabel === orientation.label}
            aria-selected={selectedOrientationLabel === orientation.label}
          >
            <CheckmarkIcon />
            {orientation.label}
          </StyledListItem>
        ))}
      </StyledDropdown>
    </DropdownMenu.Root>
  )
}
