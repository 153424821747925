import type { BufferEditor } from '~publish/legacy/editor/BufferEditor/types.plate'

export const hasOnlyFacebookGroupsSelected = (
  editor: BufferEditor,
): boolean => {
  const editorComposerData = editor.getEditorComposerData?.(editor)
  const selectedProfiles = editorComposerData?.selectedProfilesForService

  if (!selectedProfiles || !selectedProfiles.length) return false
  return selectedProfiles.every((profile) => profile.serviceType === 'group')
}
