import { createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from '@buffer-mono/popcorn'

import callRpc from '~publish/legacy/utils/call-rpc'
import type { AppDispatch, RootState } from '~publish/legacy/store'

export const createGroup = createAsyncThunk<
  boolean,
  { groupName: string; selectedChannels: string[] },
  { state: RootState; dispatch: AppDispatch }
>(
  'channelGroups/createGroup',
  async ({ groupName, selectedChannels }, { rejectWithValue }) => {
    const newGroup = {
      name: groupName,
      profile_ids: selectedChannels,
    }

    try {
      const response = await callRpc('createProfileGroup', {
        group: newGroup,
      })

      if (!response.success) {
        throw new Error(response.message)
      }

      toast.success('Channel group created successfully')

      return true
    } catch (error) {
      const message = 'Error creating channel group'
      toast.error(message)

      return rejectWithValue(message)
    }
  },
)
