import { getFileNameFromPath } from './getFileNameFromPath'

export function getFileExtension(fileName: string): string {
  fileName = getFileNameFromPath(fileName)
  const lastDot = fileName.lastIndexOf('.')
  // these count as no extension: "no-dot", "trailing-dot."
  if (lastDot === -1 || lastDot === fileName.length - 1) {
    return ''
  }
  return fileName.slice(lastDot + 1)
}
