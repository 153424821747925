import React from 'react'
import { blue } from '@bufferapp/ui/style/colors'
import CheckmarkIcon from '@bufferapp/ui/Icon/Icons/Checkmark'

import { BulletPointItem, BulletPointText, StyledList } from './styles'

type BulletPointsProps = {
  items: string[]
}

// @TODO: Good candidate to be included in Popcorn library
const BulletPoints = ({ items }: BulletPointsProps): JSX.Element => {
  return (
    <StyledList>
      {items.map((text: string, index: number) => (
        <BulletPointItem key={index}>
          <CheckmarkIcon size="smedium" color={blue} />
          <BulletPointText>{text}</BulletPointText>
        </BulletPointItem>
      ))}
    </StyledList>
  )
}

export default BulletPoints
