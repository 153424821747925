import { getEditorString, getRangeBefore } from '@udecode/plate'
import type { BufferEditor } from '~publish/legacy/editor/BufferEditor/types.plate'

export const isPreviousCharacterWhitespaceOrStart = (
  editor: BufferEditor,
): boolean => {
  if (!editor.selection) return false

  const range = getRangeBefore(editor, editor.selection, { unit: 'character' })
  const previousChar = getEditorString(editor, range, { voids: true })
  return previousChar === '' || !!previousChar.match(/\s/)
}
