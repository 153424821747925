import React from 'react'

import * as Slides from './AllSlides'

function SlideView({ currentSlide }: { currentSlide: number }): JSX.Element {
  return (
    <>
      {currentSlide === 1 && <Slides.Slide1 />}
      {currentSlide === 2 && <Slides.Slide2 />}
      {currentSlide === 3 && <Slides.Slide3 />}
      {currentSlide === 4 && <Slides.Slide4 />}
    </>
  )
}

export { SlideView }
