import React from 'react'

import { LinkifiedText } from '~publish/legacy/shared-components/LinkifiedText'
import { AttachmentTypes } from '~publish/legacy/constants'
import type {
  Gif,
  Image,
  Link,
} from '~publish/legacy/composer/composer/entities/factories'
import { ImagesGrid } from '../../../Common/ImagesGrid'
import { LinkPreview } from '../../../Common/LinkPreview'
import { parseBlueskyLinks } from '~publish/legacy/utils/channels/bluesky/helpers'
import type { ProfilePreview, ThreadsContent } from '../../../types'
import useLinkPreviewEffect from '../../../Common/hooks/useLinkPreviewEffect'
import styles from './BlueskyPost.module.css'
import { RepostIcon } from '@buffer-mono/popcorn'
import HeartOutlineIcon from '@bufferapp/ui/Icon/Icons/HeartOutline'
import { DotsIcon } from '../../../Common/styles'
import commentSvg from './comment.svg'
import { truncateText } from '../../../Common/utils'

interface GetMediaPropertiesResult {
  hasImages: boolean | number | undefined | null | Gif
  hasLink: boolean
}

const getMediaProperties = (
  content: ThreadsContent,
  linkPreview: Link | null,
): GetMediaPropertiesResult => {
  const { images, gif, enabledAttachmentType } = content

  const hasMedia = enabledAttachmentType === AttachmentTypes.MEDIA
  const hasImages = hasMedia && (images?.length || gif)
  const hasLink = !!(linkPreview && !hasImages)

  return { hasImages, hasLink }
}

export const BlueskyPost = ({
  content,
  profile,
  index,
}: {
  content: ThreadsContent
  profile: ProfilePreview
  index: number
}): JSX.Element => {
  const { text, images, gif, link: linkFromPost } = content
  const { avatar, username, displayName, formattedUsername } = profile
  const [linkPreview] = useLinkPreviewEffect({
    linkFromPost,
    text,
  })

  const { hasImages, hasLink } = getMediaProperties(content, linkPreview)

  const allImages = images?.length ? images : ([{ ...gif }] as Image[])

  const usernameToDisplay = displayName || username

  return (
    <div className={styles.wrapper} data-thread-id={index}>
      <div className={styles.avatarWrapper}>
        <img
          className={styles.avatar}
          data-testid="avatar"
          src={avatar}
          alt="user avatar"
        />
      </div>
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.username}>{usernameToDisplay}</div>
          <div className={styles.formattedUserName}>{formattedUsername}</div>
          <div className={styles.ago}>· 21h</div>
        </div>

        {text ? (
          <LinkifiedText
            links={parseBlueskyLinks(text)}
            whitespace="pre-wrap"
            className={styles.text}
          >
            {truncateText(text, 300)}
          </LinkifiedText>
        ) : (
          <span />
        )}

        {hasImages && (
          <ImagesGrid
            className={styles.imagesGrid}
            images={allImages}
            isRowFormat={false}
            maxHeight="180px"
            shouldShowGifTag={false}
            roundedCorners
          />
        )}

        {hasLink && (
          <LinkPreview
            className={styles.linkPreview}
            link={linkPreview as Link}
            clickable={false}
            imageCover
            showDescription
          />
        )}

        <div className={styles.footer}>
          <img src={commentSvg} alt="" className={styles.icon} />

          <div className={styles.icon}>
            <RepostIcon className={styles.repostIcon} />
          </div>
          <div className={styles.icon}>
            <HeartOutlineIcon />
          </div>
          <div className={styles.icon}>
            <DotsIcon color="#6e869f" />
          </div>
        </div>
      </div>
    </div>
  )
}
