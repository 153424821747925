import React from 'react'

import * as Styled from './Label.styles'

/**
 *
 */
function Label({
  htmlFor,
  children,
}: {
  htmlFor?: string
  children: React.ReactNode | JSX.Element
}): JSX.Element {
  return <Styled.Label htmlFor={htmlFor}>{children}</Styled.Label>
}

export { Label }
