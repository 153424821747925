/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react'
import { Text } from '@bufferapp/ui'
import { TitleWrapper } from './styles'

const PostExtraDataTitle = ({
  title,
}: {
  title: string | null
}): JSX.Element | null => {
  if (!title || title === null) {
    return <></>
  }

  return (
    <TitleWrapper>
      <Text type="p">{title}</Text>
    </TitleWrapper>
  )
}

export default PostExtraDataTitle
