import { Plate } from '@udecode/plate'
import React, { type ReactNode, useCallback, useEffect, useMemo } from 'react'
import {
  type Draft,
  DraftMethods,
} from '~publish/legacy/composer/composer/entities/Draft'
import type { GetEditorComposerData } from '~publish/legacy/editor/BufferEditor/types'
import type {
  BufferEditor as TBufferEditor,
  BufferValue,
} from '~publish/legacy/editor/BufferEditor/types.plate'
import { EditorErrorBoundary } from '~publish/legacy/editor/components/EditorErrorBoundary'
import { BarButton } from '~publish/legacy/integrations-bar'
import { EmojiPicker } from '~publish/legacy/editor/plugins'
import { usePlateCharacterLimits } from '~publish/legacy/editor/plugins/character-limit-highlight'
import {
  EmojiCombobox,
  EmojiPickerIcon,
} from '~publish/legacy/editor/plugins/emoji'
import { MentionComboboxes } from './MentionComboboxes'
import { ComposerEditorWrapper, ComposerTextArea } from './styles'
import AIContextualMenu from '~publish/legacy/ai/components/Triggers/AIContextualMenu'
import { renderPlaceholderWithAIButton } from '~publish/legacy/ai/helpers/renderPlaceholderWithAIButton'
import ComposerStore from '../../stores/ComposerStore'

interface ComposerEditorProps {
  draft: Draft
  editor: TBufferEditor
  shouldAutoFocus: boolean
  isComposerExpanded: boolean
  attachmentGlanceHasNoThumbnail: boolean
  getEditorComposerData: GetEditorComposerData
  onEditorStateChange: (editor: TBufferEditor) => void
  setEmojiContainer: (emojiContainer: ReactNode) => void
}

export const ComposerEditor = ({
  draft,
  editor,
  shouldAutoFocus,
  isComposerExpanded,
  getEditorComposerData,
  onEditorStateChange,
  setEmojiContainer,
}: ComposerEditorProps): JSX.Element => {
  const getTextCharacterCount = useCallback(
    (text: string) => DraftMethods.getTextCharacterCount(draft, text),
    [draft],
  )

  const getAdditionalCharacterCount = useCallback(
    (text: string) => DraftMethods.getAdditionalCharacterCount(draft, text),
    [draft],
  )

  const decorate = usePlateCharacterLimits(
    editor,
    DraftMethods.getCharLimit(draft),
    getTextCharacterCount,
    getAdditionalCharacterCount,
  )
  editor.getEditorComposerData = getEditorComposerData

  const editableProps = useMemo(() => {
    return {
      spellCheck: true,
      readOnly: false,
      placeholder:
        draft.canHaveThread() && ComposerStore.getActiveThreadId() > 0
          ? 'Say more or'
          : 'Start writing or',
      renderPlaceholder: renderPlaceholderWithAIButton({
        // marginTop: '-7px',
        placement: 'publishComposer',
      }),

      autoFocus: shouldAutoFocus,
      'data-testid': 'composer-text-area',
      'data-draftid': draft.id,
      'aria-label': 'composer textbox',
      decorate,
      style: {
        height: '100%',
        outline: 'none',
      },
    }
  }, [decorate, shouldAutoFocus, draft])

  // We need to portal any popovers to the composer wrapper level
  // to ensure it breaks out of the composer boundaries when necessary
  const container = document.getElementById('composer-root') ?? undefined
  const handleEditorStateChange = useCallback(() => {
    onEditorStateChange(editor)
  }, [editor, onEditorStateChange])

  useEffect(() => {
    if (setEmojiContainer && isComposerExpanded) {
      setEmojiContainer(
        <EmojiPicker editor={editor} parentContainer={container}>
          <BarButton
            data-testid="emoji-picker-button"
            aria-label="emoji picker"
          >
            <EmojiPickerIcon />
          </BarButton>
        </EmojiPicker>,
      )
    }
  }, [setEmojiContainer, isComposerExpanded, editor, container])

  const showImageFirstLayout =
    isComposerExpanded && draft.isImageFirst() && draft.service.isYoutube()

  const showAIAssistantContextualMenu = isComposerExpanded
  return (
    <EditorErrorBoundary editor={editor}>
      <ComposerEditorWrapper $showImageFirstLayout={showImageFirstLayout}>
        <ComposerTextArea
          $isComposerExpanded={isComposerExpanded}
          $hasLinkAttachment={draft.hasLinkAttachmentEnabled()}
          $showImageFirstLayout={showImageFirstLayout}
        >
          <Plate<BufferValue>
            id={editor.id}
            editableProps={editableProps}
            editor={editor}
            plugins={editor.plugins}
            onChange={handleEditorStateChange}
          >
            <MentionComboboxes portalElement={container} />
            <EmojiCombobox portalElement={container} />
            {showAIAssistantContextualMenu && (
              <AIContextualMenu editor={editor} placement="publishComposer" />
            )}
          </Plate>
        </ComposerTextArea>
      </ComposerEditorWrapper>
    </EditorErrorBoundary>
  )
}
