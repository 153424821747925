import gql from 'graphql-tag'

export const DISMISS_BANNER = gql`
  mutation DismissBanner($banner: String!) {
    dismissBanner(banner: $banner) {
      ... on DismissBannerResponse {
        success
      }
      ... on DismissBannerError {
        cause
      }
    }
  }
`
