import styled from 'styled-components'
import { fontFamily } from '@bufferapp/ui/style/fonts'
import { grayDark } from '@bufferapp/ui/style/colors'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: center;
  align-items: center;
  padding: 16px;
  max-width: 300px;
  margin: 0 auto;

  p {
    color: ${grayDark};
    margin: 0;
    padding: 0;
    text-align: center;
  }
`

export const Title = styled.p`
  font-family: ${fontFamily};
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
`

export const Message = styled.p`
  font-family: ${fontFamily};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`
