import React from 'react'
import type { PlateRenderElementProps, Value } from '@udecode/plate'
import { blue } from '@bufferapp/ui/style/colors'
import styled from 'styled-components'
import { LinkElement } from '../nodes/LinkElement'

const Link = styled.a`
  text-decoration: none;
  color: ${blue};
`

export const SimpleLinkElementUI = ({
  attributes,
  children,
  element,
}: PlateRenderElementProps<Value, LinkElement>) => {
  const href = LinkElement.getHref(element)

  return (
    <Link
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...attributes}
      contentEditable={false}
      href={href}
      target="_blank"
      rel="noopener nofollow"
    >
      {children}
    </Link>
  )
}
