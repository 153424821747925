import {
  type AnyObject,
  type PlateEditor,
  createPluginFactory,
  getNodeEntries,
} from '@udecode/plate'

import ComposerActionCreators from '~publish/legacy/composer/composer/action-creators/ComposerActionCreators'
import ComposerStore from '~publish/legacy/composer/composer/stores/ComposerStore'
import type { BufferValue } from '../BufferEditor/types.plate'

export const THREADS_PLUGIN_KEY = 'threads-plugin'

function calculateAbsoluteOffset(editor: PlateEditor<BufferValue>): number {
  const [pathX, pathY] = editor.selection?.focus.path || [0, 0]
  // get current position within current node
  let offset = editor.selection?.focus.offset || 0

  for (const node of getNodeEntries(editor, {
    at: [],
    voids: false,
    match: (entry, path) => {
      if (
        path.length >= 2 &&
        // get all nodes that are before the current selection
        (path[0] < pathX || (path[0] === pathX && path[1] < pathY))
      ) {
        return !!entry.text
      }
      return false
    },
  })) {
    if (typeof node[0].text === 'string') {
      offset += node[0].text.length
    }
  }

  // add all new lines before the current selection
  return offset + pathX
}

export const createNewThreadPostPlugin = createPluginFactory<
  AnyObject,
  BufferValue
>({
  key: THREADS_PLUGIN_KEY,
  handlers: {
    onKeyDown:
      (editor) =>
      (event): void | boolean => {
        if (event.key === 'Backspace') {
          const currentThreadId = ComposerStore.getActiveThreadId()
          const numberOfThreads =
            ComposerStore.getNumberOfThreads(editor.id) || 0

          if (
            editor.selection?.anchor &&
            editor.isStart(editor.selection?.anchor, []) &&
            numberOfThreads > 1
          ) {
            ComposerActionCreators.deleteThreadedDraft(
              editor.id,
              currentThreadId,
              true,
            )

            event.preventDefault()

            return true
          }
        } else if (event.key === 'Enter' && (event.metaKey || event.ctrlKey)) {
          ComposerActionCreators.updateDraftAddPostToThread(
            editor.id,
            calculateAbsoluteOffset(editor),
          )

          event.preventDefault()

          return true
        } else if (event.key === 'ArrowUp') {
          if (
            editor.selection?.anchor &&
            editor.isStart(editor.selection?.anchor, [])
          ) {
            const currentThreadId = ComposerStore.getActiveThreadId()

            if (currentThreadId > 0) {
              ComposerActionCreators.switchActiveThreadEditor(
                editor.id,
                currentThreadId - 1,
              )
              event.preventDefault()
            }
          }

          return true
        } else if (event.key === 'ArrowDown') {
          if (
            editor.selection?.anchor &&
            editor.isEnd(editor.selection?.anchor, [])
          ) {
            const numberOfThreads =
              ComposerStore.getNumberOfThreads(editor.id) || 0
            const nextActiveThreadId = ComposerStore.getActiveThreadId() + 1

            if (nextActiveThreadId < numberOfThreads) {
              ComposerActionCreators.switchActiveThreadEditor(
                editor.id,
                nextActiveThreadId,
              )
              event.preventDefault()
            }
          }

          return true
        }
      },
  },
})
