import React from 'react'
import { useMutation } from '@apollo/client'
import { graphql } from '~publish/gql'
import type { Idea } from '~publish/pages/Create/types'
import { logError } from '~publish/legacy/utils/logError'

export const DeleteIdeaMutation = graphql(/* GraphQL */ `
  mutation DeleteIdeaMutation($input: IdeasDeletionInput!) {
    ideasRemove(input: $input) {
      ... on IdeasDeletionSuccess {
        success
        message
        ideas {
          id
        }
      }
      ... on CoreWebAppCommonError {
        message
      }
    }
  }
`)

type DeleteIdeaOptions = {
  source?: string
}

function useDeleteIdea(): {
  deleteIdea: (idea: Idea, options?: DeleteIdeaOptions) => Promise<void>
  deleteIdeas: (ideaIds: string[], options?: DeleteIdeaOptions) => Promise<void>
} {
  const [deleteMutation] = useMutation(DeleteIdeaMutation, {
    update: (cache, { data }, { variables }) => {
      if (
        !data ||
        !data.ideasRemove ||
        !variables ||
        data.ideasRemove.__typename !== 'IdeasDeletionSuccess'
      ) {
        return
      }

      // evict ideas from cache
      for (const idea of data.ideasRemove.ideas) {
        const id = cache.identify({
          id: idea.id,
          __typename: 'Idea',
        })
        cache.evict({ id })
      }

      cache.gc()
    },
  })

  const deleteIdeas = React.useCallback(
    async (ideaIds: string[], options?: DeleteIdeaOptions): Promise<void> => {
      const { data, errors } = await deleteMutation({
        variables: {
          input: {
            ids: ideaIds,
            cta: options?.source,
          },
        },
      })

      if (!data || !data.ideasRemove) {
        throw new Error('No data returned from server')
      }

      if (data.ideasRemove.__typename === 'IdeasDeletionSuccess') {
        return
      }

      if (data.ideasRemove.message) {
        throw new Error(data.ideasRemove.message)
      }

      const unexpectedError = new Error(
        errors?.[0]?.message || 'Unexpected API response',
      )

      if (errors?.length) {
        logError(unexpectedError, { metaData: { data, errors } })
      }

      throw unexpectedError
    },
    [deleteMutation],
  )

  const deleteIdea = React.useCallback(
    async (idea: Idea, options?: DeleteIdeaOptions): Promise<void> => {
      return deleteIdeas([idea.id], options)
    },
    [deleteIdeas],
  )

  return { deleteIdea, deleteIdeas }
}

export { useDeleteIdea }
