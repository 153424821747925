import {
  ArrowUpIcon,
  IconButton,
  Textarea,
  VisuallyHidden,
} from '@buffer-mono/popcorn'
import React from 'react'
import styles from '../NotesWidget.module.css'

type Props = {
  notesLimitReached?: boolean
  onSubmit: (text: string) => void
}

export const NewNoteForm = ({
  notesLimitReached = false,
  onSubmit,
}: Props): JSX.Element => {
  const textareaId = React.useId()

  const textareaRef = React.useRef<HTMLTextAreaElement>(null)
  const [value, setValue] = React.useState('')

  const isFormEmpty = value.trim() === ''
  const isDisabled = notesLimitReached || isFormEmpty

  const addNoteTooltip = notesLimitReached
    ? 'You have reached the maximum number of notes'
    : isFormEmpty
    ? 'Enter some text to add a note'
    : null

  const handleSubmit = (event?: React.FormEvent<HTMLFormElement>): void => {
    event?.preventDefault()
    if (isDisabled) return
    onSubmit(value)
    setValue('')
  }

  const handleEdit = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>): void => {
      setValue(event.target.value)
    },
    [],
  )

  const onKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>): void => {
    if (event.key === 'Enter' && (event.metaKey || event.ctrlKey)) {
      event.preventDefault()
      event.stopPropagation()
      handleSubmit()
    }

    if (event.key === 'Escape') {
      event.preventDefault()
      event.stopPropagation()
      textareaRef?.current?.blur()
    }
  }

  return (
    <form onSubmit={handleSubmit} autoComplete="off" className={styles.form}>
      <VisuallyHidden as="label" htmlFor={textareaId}>
        Add note
      </VisuallyHidden>
      <Textarea
        id={textareaId}
        ref={textareaRef}
        key={textareaId}
        className={styles.addTextarea}
        name="addNote"
        placeholder="Add a note..."
        rows={1}
        value={value}
        maxLength={500}
        onChange={handleEdit}
        onKeyDown={onKeyDown}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
      />
      <IconButton
        label="Add note"
        className={styles.submitButton}
        disabled={isDisabled}
        tooltip={addNoteTooltip}
        variant="primary"
        type="submit"
      >
        <ArrowUpIcon />
      </IconButton>
    </form>
  )
}
