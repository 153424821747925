import React, { useImperativeHandle, useRef } from 'react'

import { DragSource, DropTarget } from 'react-dnd'
import MediaAttachmentThumbnail, {
  type MediaAttachmentThumbnailProps,
} from './MediaAttachmentThumbnail'

const mediaAttachmentSource = {
  beginDrag(props: { id: string; index: number }): {
    id: string
    index: number
  } {
    return {
      id: props.id,
      index: props.index,
    }
  },
}

const mediaAttachmentTarget = {
  // @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
  drop(props) {
    props.onDropMediaAttachment({ commit: true })
  },

  // @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
  hover(props, monitor, component) {
    if (!component) {
      return null
    }
    // node = HTML Div element from imperative API
    const node = component.getNode()
    if (!node) {
      return null
    }

    const { index: dragIndex } = monitor.getItem()
    const { index: hoverIndex, onDropMediaAttachment } = props

    // Don't replace media attachment with itself...
    if (dragIndex === hoverIndex) {
      return
    }

    // Determine rectangle on screen
    const hoverBoundingRect = node.getBoundingClientRect()

    // Get vertical middle
    const hoverMiddleX = (hoverBoundingRect.left - hoverBoundingRect.right) / 2

    // Determine mouse position
    const clientOffset = monitor.getClientOffset()

    // Get pixels to the right
    const hoverClientX = clientOffset.x - hoverBoundingRect.right

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%

    if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
      return
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
      return
    }

    // Drop!
    onDropMediaAttachment({ dragIndex, hoverIndex })

    // We need to directly mutate the monitor state here
    // to ensure the currently dragged item index is updated.
    monitor.getItem().index = hoverIndex
  },
}

const MediaAttachmentDragWrapper = React.forwardRef(
  (
    {
      connectDragSource,
      connectDropTarget,

      ...mediaAttachmentProps
    }: {
      connectDragSource: any
      connectDropTarget: any
      onDropMediaAttachment: (params: {
        dragIndex: number
        hoverIndex: number
        commit?: boolean
      }) => void
      index: number
    } & MediaAttachmentThumbnailProps,
    ref,
  ): JSX.Element => {
    const elementRef = useRef(null)

    connectDragSource(elementRef)
    connectDropTarget(elementRef)
    useImperativeHandle(ref, () => ({
      getNode: () => elementRef.current,
    }))

    return (
      <div
        aria-dropeffect="move"
        ref={elementRef}
        draggable
        // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
        tabIndex={0}
        style={{ outline: 'none' }}
      >
        <MediaAttachmentThumbnail {...mediaAttachmentProps} />
      </div>
    )
  },
)

MediaAttachmentDragWrapper.displayName = 'MediaAttachmentDragWrapper'

export default DropTarget(
  'mediaAttachment',
  mediaAttachmentTarget,
  (connect) => ({
    connectDropTarget: connect.dropTarget(),
  }),
)(
  DragSource('mediaAttachment', mediaAttachmentSource, (connect) => ({
    connectDragSource: connect.dragSource(),
  }))(MediaAttachmentDragWrapper),
)
