import { useEffect } from 'react'

/**
 * Attempts to scroll to the threaded post with the given ID.
 *
 * Threaded posts are identified by a `data-thread-id` attribute.
 *
 * @param activeThreadId
 */
export function useScrollToThreadedPost(activeThreadId?: number | null): void {
  useEffect(() => {
    if (!Number.isInteger(activeThreadId)) {
      return
    }

    const threadedPost = document.querySelector(
      `[data-thread-id="${activeThreadId}"]`,
    )

    if (!threadedPost) {
      return
    }

    threadedPost.scrollIntoView({
      behavior: 'smooth',
    })
  }, [activeThreadId])
}
