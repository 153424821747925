import React, { useMemo, useState } from 'react'
import {
  DndContext,
  DragOverlay,
  KeyboardSensor,
  PointerSensor,
  type DragCancelEvent,
  type DragEndEvent,
  type DragOverEvent,
  type DragStartEvent,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import { restrictToFirstScrollableAncestor } from '@dnd-kit/modifiers'

import { ChannelItem } from './ChannelItem'
import { useChannels } from './useChannels'
import { LinkedInPromoCoachmarkWrapper } from './LinkedInPromoCoachmark'

interface DraggableChannelListProps {
  filteredChannels: ReturnType<typeof useChannels>['channels']
  onReorder: (dragIndex: number, hoverIndex: number) => void
}

export function DraggableChannelList({
  filteredChannels,
  onReorder,
}: DraggableChannelListProps): JSX.Element {
  const { channels } = useChannels()
  const [draggedChannel, setDraggedChannel] = useState<
    ReturnType<typeof useChannels>['channels'][0] | undefined
  >(undefined)

  const handleDragStart = (event: DragStartEvent): void => {
    const { active } = event
    if (!active.id) return
    setDraggedChannel(channels.find((channel) => channel.id === active.id))
  }

  const handleDragEnd = ({ active, over }: DragEndEvent): void => {
    if (!active.id || !over?.id) return
    const dragIndex = channels.findIndex((channel) => channel.id === active.id)
    const hoverIndex = channels.findIndex((channel) => channel.id === over.id)

    onReorder(dragIndex, hoverIndex)
  }

  const disableDragAndDrop = useMemo(() => {
    return filteredChannels.length !== channels.length || channels.length === 0
  }, [channels, filteredChannels])

  const sensors = useSensors(
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
    useSensor(PointerSensor),
  )

  const getPosition = (id: string): number =>
    channels.findIndex((channel) => channel.id === id) + 1
  const channelCount = channels.length

  const getChannelInfo = (id: string): string => {
    const channel = channels.find((c) => c.id === id)
    return channel
      ? `${channel.name} from ${channel.service}`
      : 'Unknown channel'
  }

  const announcements = {
    onDragStart({ active }: DragStartEvent): string {
      const channelInfo = getChannelInfo(active.id as string)
      return `Picked up channel ${channelInfo}. Channel is in position ${getPosition(
        active.id as string,
      )} of ${channelCount}`
    },
    onDragOver({ active, over }: DragOverEvent): string {
      if (over) {
        const channelInfo = getChannelInfo(active.id as string)
        return `Channel ${channelInfo} was moved into position ${getPosition(
          over.id as string,
        )} of ${channelCount}`
      }
      return `Channel ${getChannelInfo(
        active.id as string,
      )} is no longer over a droppable area`
    },
    onDragEnd({ active, over }: DragEndEvent): string {
      if (over) {
        const channelInfo = getChannelInfo(active.id as string)
        return `Channel ${channelInfo} was dropped at position ${getPosition(
          over.id as string,
        )} of ${channelCount}`
      }
      return `Channel ${getChannelInfo(active.id as string)} was dropped`
    },
    onDragCancel({ active }: DragCancelEvent): string {
      return `Dragging was cancelled. Channel ${getChannelInfo(
        active.id as string,
      )} was dropped.`
    },
  }

  // TEMP - for https://buffer.atlassian.net/browse/GROWTH-898
  // remove this once the experiment is over
  const firstLinkedInChannel = channels.find(
    (channel) => channel.service === 'linkedin',
  )

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      sensors={sensors}
      modifiers={[restrictToFirstScrollableAncestor]}
      accessibility={{
        announcements,
        screenReaderInstructions: {
          draggable: `
            To pick up a channel, press space or enter.
            Use the up and down arrow keys to change the channel's position in the list.
            Press space or enter again to drop the channel in its new position, or press escape to cancel.
          `,
        },
      }}
    >
      <SortableContext
        items={channels}
        strategy={verticalListSortingStrategy}
        disabled={disableDragAndDrop}
      >
        {filteredChannels.map((channel) => {
          const item = (
            <ChannelItem
              key={channel.id}
              channel={channel}
              dragDisabled={disableDragAndDrop}
            />
          )
          const isFirstLinkedInChannel = channel.id === firstLinkedInChannel?.id
          if (isFirstLinkedInChannel) {
            return (
              <LinkedInPromoCoachmarkWrapper channel={channel} key={channel.id}>
                {item}
              </LinkedInPromoCoachmarkWrapper>
            )
          }
          return item
        })}
      </SortableContext>
      <DragOverlay>
        {draggedChannel && <ChannelItem channel={draggedChannel} dragged />}
      </DragOverlay>
    </DndContext>
  )
}
