import { useEffect, useMemo, useState } from 'react'

// Function to set the Slate Editor height
const calculateEditorHeight = (): number | '100%' => {
  const wrapper = document.getElementById('ideas-content-wrapper')
  const mediaManager = document.getElementById('ideas-media-manager')
  const actions = document.getElementById('ideas-actions')
  if (wrapper && mediaManager && actions) {
    return (
      wrapper.offsetHeight -
      mediaManager.offsetHeight -
      actions.offsetHeight -
      80 // Fixed padding
    )
  }
  return '100%' // Default value
}

export function useVariableHeight({
  completedCount,
}: {
  completedCount: number
}): {
  editorHeightStyles: {
    height: string | number
  }
} {
  const [editorHeight, setEditorHeight] = useState<number | string>(
    calculateEditorHeight(),
  )

  // Add window resize event listener
  // Recalculate the size of SlateJS editor when images are added or removed
  useEffect(() => {
    const refreshEditorHeight = (): void => {
      setEditorHeight(calculateEditorHeight())
    }
    window.addEventListener('resize', refreshEditorHeight)
    refreshEditorHeight()

    // clean up function
    return (): void => {
      window.removeEventListener('resize', refreshEditorHeight)
    }
  }, [completedCount])

  return useMemo(() => {
    return {
      editorHeightStyles: { height: editorHeight },
    }
  }, [editorHeight])
}
