import type { RSSFeedResult, RSSFeedItem } from '../../types'

const isNotNullUndef = <T>(str: T | null | undefined): str is T => {
  return str !== null && str !== undefined
}

// This custom parser is intentended for use only in this initial
// PoC. We will migrate to using rssapi.net rather than a direct
// xml query.
export const parseRSS = (rawXml: string): RSSFeedResult => {
  const parser = new DOMParser()
  const xmlDoc = parser.parseFromString(rawXml, 'text/xml')

  const root = xmlDoc.querySelector('rss')
  if (!root) {
    return {
      kind: 'error',
      message: 'Invalid RSS XML',
    }
  }

  if (root.getAttribute('version') !== '2.0') {
    return {
      kind: 'error',
      message: 'Invalid RSS XML version',
    }
  }

  const title = root.querySelector('channel > title')?.textContent
  const description = root.querySelector('channel > description')?.textContent
  const link = root.querySelector('channel > link')?.textContent
  const imageUrl =
    root.querySelector('channel > image > url')?.textContent ?? undefined

  if (!isNotNullUndef(title)) {
    return {
      kind: 'error',
      message: 'Invalid RSS XML - missing title',
    }
  }

  if (!isNotNullUndef(description)) {
    return {
      kind: 'error',
      message: 'Invalid RSS XML - missing description',
    }
  }

  if (!isNotNullUndef(link)) {
    return {
      kind: 'error',
      message: 'Invalid RSS XML - missing link',
    }
  }

  const itemNodes = Array.from(root.querySelectorAll('item'))
  const items = itemNodes.map((item): RSSFeedItem => {
    const articleTitle =
      item.querySelector('title')?.textContent ?? 'MISSING TITLE'
    const description =
      item.querySelector('description')?.textContent ?? 'MISSING DESCRIPTION'
    const link =
      item.querySelector('link')?.textContent ?? 'data:text/html,Missing%20Link'
    const imgUrl = item
      .getElementsByTagName('media:content')[0]
      ?.getAttribute('url')
    const rawDate = item.querySelector('pubDate')?.textContent
    const pubDate = rawDate ? new Date(rawDate) : new Date()

    return {
      title: articleTitle,
      description,
      url: link,
      imgUrl,
      pubDate,
      source: {
        title,
        imageUrl,
      },
    }
  })

  return {
    kind: 'success',
    title,
    description,
    url: link,
    items,
  }
}
