import { EventEmitter } from 'events'
import { createNextState } from '@reduxjs/toolkit'

import AppDispatcher from '../dispatcher'
import { getWiredComposerStore } from '../state/getWiredComposerStore'
import { notificationsSlice } from '../state/slices/notificationsSlice'
import { notificationReducer } from './notificationReducer'

const CHANGE_EVENT = 'change'

getWiredComposerStore()

let _state = notificationsSlice.getInitialState()

export const NotificationStore = Object.assign({}, EventEmitter.prototype, {
  emitChange: () => NotificationStore.emit(CHANGE_EVENT),
  // @ts-expect-error TS(7006) FIXME: Parameter 'callback' implicitly has an 'any' type.
  addChangeListener: (callback) => NotificationStore.on(CHANGE_EVENT, callback),
  // @ts-expect-error TS(7006) FIXME: Parameter 'callback' implicitly has an 'any' type.
  removeChangeListener: (callback) =>
    NotificationStore.removeListener(CHANGE_EVENT, callback),

  getState: () => _state,
  getVisibleNotifications: () => _state.visibleNotifications,
  // @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
  selectVisibleNotifications: (state) => state.visibleNotifications,
})

AppDispatcher.register((payload) => {
  const newState = createNextState(_state, (draft) =>
    notificationReducer(draft, payload.action),
  )
  const isPayloadInteresting = newState !== _state

  _state = newState

  if (isPayloadInteresting) NotificationStore.emitChange()
})
