// This is temporary and will be made to be
// queried via the API Gateway so that it can
// be shared by Core and Apps.
export const YOUTUBE_CATEGORIES_US_REGION = [
  { id: '2', title: 'Autos & Vehicles' },
  { id: '23', title: 'Comedy' },
  { id: '27', title: 'Education' },
  { id: '24', title: 'Entertainment' },
  { id: '20', title: 'Gaming' },
  { id: '26', title: 'Howto & Style' },
  { id: '1', title: 'Film & Animation' },
  { id: '10', title: 'Music' },
  { id: '25', title: 'News & Politics' },
  { id: '29', title: 'Nonprofits & Activism' },
  { id: '22', title: 'People & Blogs' },
  { id: '15', title: 'Pets & Animals' },
  { id: '28', title: 'Science & Technology' },
  { id: '17', title: 'Sports' },
  { id: '19', title: 'Travel & Events' },
]
