import NotificationActionCreators from '~publish/legacy/composer/composer/action-creators/NotificationActionCreators'
import { NotificationScopes } from '~publish/legacy/composer/composer/AppConstants'
import { EditorStateProxy } from '~publish/legacy/composer/composer/entities/EditorStateProxy'
import type { BufferEditor } from '~publish/legacy/editor/BufferEditor/types.plate'
import { Service } from '~publish/legacy/constants/services/ServiceDefinitions'

export const areMentionsSupported = (editor: BufferEditor) => {
  const selectedProfilesForService =
    editor?.getEditorComposerData?.(editor)?.selectedProfilesForService

  if (editor.id === Service.Facebook) {
    const hasFacebookGroups = selectedProfilesForService?.some((prof) => {
      return prof.serviceType === 'group'
    })
    const hasFacebookPages = selectedProfilesForService?.some((prof) => {
      return prof.serviceType === 'page'
    })

    const mentionsNotSupported =
      hasFacebookGroups &&
      hasFacebookPages &&
      !!EditorStateProxy.getFacebookAutocompleteEntities(editor)?.length

    if (mentionsNotSupported) {
      const message =
        'We currently only support mentions for Facebook Pages. The mention will be removed from the Group post when added to the queue.'
      NotificationActionCreators.queueInfo({
        scope: NotificationScopes.COMPOSER_FACEBOOK_AUTOCOMPLETE_DISABLED,
        message,
        onlyCloseOnce: true,
        isUnique: true,
      })
      return false
    }
  }
  return true
}
