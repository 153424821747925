import React from 'react'

import { PostCardActions } from '~publish/components/PostCard/PostCardActions'
import styles from './CalendarPostHoverFooter.module.css'

export const CalendarPostHoveredFooter = (): JSX.Element => {
  return (
    <div className={styles.footerWrapper}>
      <PostCardActions />
    </div>
  )
}
