import type { Schedule } from '~publish/legacy/profile-sidebar/types'
import type { Day } from '~publish/legacy/posting-schedule/types'
import { dayMap } from './transformSchedule'

/**
 * getPausedSchedule()
 *
 * Generates the paused schedules array
 *
 * @param  {Array} unformattedSchedule
 * @param  {Array} days
 * @return {Array}
 */
const getPausedSchedule = (
  unformattedSchedule: Schedule[],
  days: Day[],
): Schedule[] => {
  if (!Array.isArray(unformattedSchedule)) return []
  const pausedDays = days
    .filter((day) => day.paused)
    .map((pausedDay) => pausedDay.key)
  const formattedSchedule = [...unformattedSchedule]
  return formattedSchedule.filter((scheduleItem) =>
    pausedDays.includes(scheduleItem.days[0]),
  )
}

/**
 * addDayToPausedSchedulesForApi()
 *
 * Adds a day to the paused schedules array
 *
 * @param  {String} dayName
 * @param  {Array} mergedSchedules
 * @param  {Array} days
 * @return {Array}
 */
const addDayToPausedSchedulesForApi = (
  dayName: string,
  mergedSchedules: Schedule[],
  days: Day[],
): Schedule[] => {
  if (!Array.isArray(mergedSchedules)) return []
  const pausedDays = days
    .filter((day) => day.paused)
    .map((pausedDay) => pausedDay.key)
  const formattedSchedule = [...mergedSchedules]
  return formattedSchedule.filter(
    (scheduleItem) =>
      scheduleItem.days.includes(dayName) ||
      pausedDays.includes(scheduleItem.days[0]),
  )
}

/**
 * removeDayFromPausedSchedulesForApi()
 *
 * Removes a day from the paused schedules array, when unpausing
 *
 * @param  {String}  dayName
 * @param  {Array} unformattedSchedule
 * @param  {Array} days
 * @return {Array}
 */
const removeDayFromPausedSchedulesForApi = (
  dayName: string,
  unformattedSchedule: Schedule[],
  days: Day[],
): Schedule[] => {
  if (!Array.isArray(unformattedSchedule)) return []
  const pausedDays = days
    .filter((day) => day.paused && day.key !== dayName)
    .map((pausedDay) => pausedDay.key)
  const formattedSchedule = [...unformattedSchedule]
  return formattedSchedule.filter((scheduleItem) =>
    pausedDays.includes(scheduleItem.days[0]),
  )
}

/**
 * addPausedDayBackToScheduleForApi()
 *
 * Adds a paused day and it's times back into the schedule
 *
 * @param  {String}  dayName
 * @param  {Array} unformattedSchedule
 * @param  {Array} mergedSchedules
 * @return {Array}
 */
const addPausedDayBackToScheduleForApi = (
  dayName: string,
  unformattedSchedule: Schedule[],
  mergedSchedules: Schedule[],
): Schedule[] => {
  if (!Array.isArray(unformattedSchedule)) return []
  const pausedDay = mergedSchedules.filter(
    (sched) => sched.days[0] === dayName,
  )[0]
  return unformattedSchedule.map((item) => ({
    ...item,
    times: item.days.includes(pausedDay.days[0]) ? pausedDay.times : item.times,
  }))
}

/**
 * removePausedDaysFromScheduleForApi()
 *
 * Removes paused days from the schedules array
 *
 * @param  {String}  dayName
 * @param  {Array} unformattedSchedule
 * @return {Array}
 */
const removePausedDaysFromScheduleForApi = (
  dayNames: string[],
  unformattedSchedule: Schedule[],
): Schedule[] => {
  if (!Array.isArray(unformattedSchedule)) return []
  return unformattedSchedule.map((item) => ({
    ...item,
    times: dayNames.includes(item.days[0]) ? [] : item.times,
  }))
}

type UpdateScheduleTimeForApiAction = {
  dayName: string
  timeIndex: number
  hours: string
  minutes: string
}
const updateScheduleTimeForApi = (
  unformattedSchedule: Schedule[],
  action: UpdateScheduleTimeForApiAction,
): Schedule[] => {
  if (!Array.isArray(unformattedSchedule)) return []
  const formattedSchedule = structuredClone(unformattedSchedule)
  formattedSchedule.forEach((scheduleItem) => {
    if (scheduleItem.days.includes(action.dayName)) {
      scheduleItem.times[action.timeIndex] = `${action.hours}:${action.minutes}`
      scheduleItem.times.sort()
    }
  })

  return formattedSchedule
}

/**
 * updatePausedSchedulesForApi()
 *
 * Edits a time in the paused schedules array
 *
 * @param  {Array} unformattedSchedule
 * @param  {Array} days
 * @param  {object} action
 * @return {Array}
 */
const updatePausedSchedulesForApi = (
  unformattedSchedule: Schedule[],
  days: Day[],
  action: UpdateScheduleTimeForApiAction,
): Schedule[] => {
  const newSchedule = getPausedSchedule(unformattedSchedule, days)
  return updateScheduleTimeForApi(newSchedule, action)
}

type DeleteTimeFromScheduleAction = {
  dayName: string
  timeIndex: number
}
const deleteTimeFromSchedule = (
  unformattedSchedule: Schedule[],
  action: DeleteTimeFromScheduleAction,
): Schedule[] => {
  if (!Array.isArray(unformattedSchedule)) return []
  const formattedSchedule = structuredClone(unformattedSchedule)
  formattedSchedule.forEach((scheduleItem) => {
    if (scheduleItem.days.includes(action.dayName)) {
      const removedTime = scheduleItem.times[action.timeIndex]
      scheduleItem.times = scheduleItem.times.filter(
        (time) => time !== removedTime,
      )
    }
  })
  return formattedSchedule
}

const deleteTimeFromPausedSchedulesForApi = (
  unformattedSchedule: Schedule[],
  days: Day[],
  action: DeleteTimeFromScheduleAction,
): Schedule[] =>
  deleteTimeFromSchedule(getPausedSchedule(unformattedSchedule, days), action)

const deleteAllTimesFromSchedule = (
  unformattedSchedule: Schedule[],
): Schedule[] => {
  if (!Array.isArray(unformattedSchedule)) return []
  const formattedSchedule = [...unformattedSchedule]
  formattedSchedule.forEach((scheduleItem) => {
    scheduleItem.times = []
  })
  return formattedSchedule
}

interface DaysMap {
  [key: string]: string[]
}

interface AddTimeToSchedulesForApiReturn {
  pausedSchedules: Schedule[]
  schedules: Schedule[]
}

const addTimeToSchedulesForApi = (
  action: {
    hours: string
    minutes: string
    dayName: string
  },
  schedules: Schedule[],
  pausedSchedules: Schedule[],
): AddTimeToSchedulesForApiReturn => {
  const { hours, minutes, dayName } = action
  const multDays = ['weekends', 'weekdays', 'everyday']
  const everyday = Object.keys(dayMap)
  const weekdays = everyday.slice(0, 5)
  const weekends = everyday.slice(-2)
  const daysMap: DaysMap = {
    everyday,
    weekdays,
    weekends,
  }

  const newTime = `${hours}:${minutes}`
  const pausedDays = pausedSchedules.map((day) => day.days[0])
  const daysToAdd = multDays.includes(dayName) ? daysMap[dayName] : [dayName]
  const updatedSchedules: AddTimeToSchedulesForApiReturn = {
    pausedSchedules: [],
    schedules: [],
  }

  daysToAdd.forEach((dayToAdd) => {
    if (pausedDays.includes(dayToAdd)) {
      pausedSchedules.forEach((daySchedule) => {
        if (daySchedule.days.includes(dayToAdd)) {
          daySchedule.times.push(newTime)
          daySchedule.times = [...new Set(daySchedule.times)]
          daySchedule.times.sort()
        }
      })
    } else {
      schedules.forEach((daySchedule) => {
        if (daySchedule.days.includes(dayToAdd)) {
          daySchedule.times.push(newTime)
          daySchedule.times = [...new Set(daySchedule.times)]
          daySchedule.times.sort()
        }
      })
    }
  })

  updatedSchedules.pausedSchedules = pausedSchedules
  updatedSchedules.schedules = removePausedDaysFromScheduleForApi(
    pausedDays,
    schedules,
  )
  return updatedSchedules
}

const convertHourFrom12To24HourFormat = (
  hours: number,
  period: 'AM' | 'PM',
): number => {
  if (period === 'AM') {
    return hours === 12 ? 0 : hours // Early return for AM case
  }

  // At this point, we know it's PM
  return hours === 12 ? 12 : hours + 12 // Early return for PM case
}

export {
  addTimeToSchedulesForApi,
  convertHourFrom12To24HourFormat,
  deleteTimeFromPausedSchedulesForApi,
  deleteTimeFromSchedule,
  updatePausedSchedulesForApi,
  updateScheduleTimeForApi,
  removePausedDaysFromScheduleForApi,
  addPausedDayBackToScheduleForApi,
  removeDayFromPausedSchedulesForApi,
  addDayToPausedSchedulesForApi,
  deleteAllTimesFromSchedule,
}
