import React from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import {
  ButtonWithSkeleton,
  TextWithSkeleton,
} from '~publish/legacy/shared-components'
import { Tooltip } from '@bufferapp/ui'
import ClockIcon from '@bufferapp/ui/Icon/Icons/Clock'
import ListIcon from '@bufferapp/ui/Icon/Icons/List'
import DraftIcon from '@bufferapp/ui/Icon/Icons/Drafts'
import CalendarIcon from '@bufferapp/ui/Icon/Icons/Calendar'
import { campaignTab } from '~publish/legacy/routes'
import {
  CampaignTab,
  type CampaignListItem,
} from '~publish/legacy/campaign/types'

import { useAccount } from '~publish/legacy/accountContext'
import UserEntity from '~publish/legacy/user/UserEntity'
import truncateText from '../../../../utils/truncateText'

import {
  ButtonWrapper,
  Color,
  Container,
  Group,
  Icon,
  LeftWrapper,
  NameContainer,
  StyledLink,
} from './style'

type PropType = {
  campaign: CampaignListItem
  displaySkeleton?: boolean
  // @ts-expect-error TS(7031) FIXME: Binding element 'campaignId' implicitly has an 'an... Remove this comment to see the full error message
  onViewCampaignClick: ({ campaignId }) => void
  // @ts-expect-error TS(7006) FIXME: Parameter 'campaign' implicitly has an 'any' type.
  onDeleteCampaignClick: (campaign) => void
  onEditCampaignClick: (tag: {
    id: string
    name: string
    color: string
  }) => void
  // @ts-expect-error TS(7006) FIXME: Parameter 'campaign' implicitly has an 'any' type.
  goToAnalyzeReport: (campaign) => void
  showCampaignActions: boolean
  hideAnalyzeReport: boolean
}

type SelectItem = {
  title: string
  selectedItemClick: () => void
  disabled?: boolean
}

const ListItem = ({
  campaign,
  showCampaignActions,
  onEditCampaignClick,
  onDeleteCampaignClick,
  onViewCampaignClick,
  goToAnalyzeReport,
  displaySkeleton = false,
  hideAnalyzeReport,
}: PropType): JSX.Element => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const campaignId = campaign.id
  const account = useAccount()
  const isAdmin = UserEntity.isAdmin(account)
  let selectItems: SelectItem[] = [
    {
      title: t('campaigns.viewCampaign.editTag'),
      selectedItemClick: (): void => {
        onEditCampaignClick({
          id: campaignId,
          name: campaign.name,
          color: campaign.color as string,
        })
      },
    },
    {
      title: t('campaigns.viewCampaign.deleteTag'),
      selectedItemClick: () => {
        onDeleteCampaignClick(campaign)
      },
      disabled: !isAdmin,
    },
  ]

  if (!hideAnalyzeReport) {
    selectItems = [
      {
        title: t('campaigns.viewCampaign.viewReport'),
        selectedItemClick: () => {
          goToAnalyzeReport(campaign)
        },
        disabled: !campaign.dateRange,
      },
      ...selectItems,
    ]
  }

  const campaignRoute = campaignTab.getRoute({
    campaignId: campaign.id,
    tab: CampaignTab.Scheduled,
  })

  const goToSelectedCampaign = () =>
    dispatch(
      campaignTab.goTo({
        campaignId: campaign.id,
        tab: CampaignTab.Scheduled,
      }),
    )

  const NameWrapper: React.ElementType = displaySkeleton
    ? NameContainer
    : StyledLink

  return (
    <Container onClick={goToSelectedCampaign}>
      <LeftWrapper>
        <NameWrapper to={campaignRoute}>
          <Color color={campaign.color} displaySkeleton={displaySkeleton} />
          {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element; label: string; position... Remove this comment to see the full error message */}
          <Tooltip label={campaign.name} position="top">
            <TextWithSkeleton
              type="h3"
              displaySkeleton={displaySkeleton}
              aria-label={displaySkeleton ? t('common.loading') : null}
              color="grayDarker"
            >
              {truncateText(campaign.name, 25)}
            </TextWithSkeleton>
          </Tooltip>
        </NameWrapper>
        <TextWithSkeleton
          type="p"
          displaySkeleton={displaySkeleton}
          aria-label={displaySkeleton ? t('common.loading') : null}
          color="grayDark"
        >
          {campaign.lastUpdated}
        </TextWithSkeleton>
      </LeftWrapper>
      <Group>
        {campaign.dateRange ? (
          <>
            <Icon displaySkeleton={displaySkeleton}>
              <CalendarIcon size="medium" />
            </Icon>
            <TextWithSkeleton type="p" displaySkeleton={displaySkeleton}>
              {campaign.dateRange}
            </TextWithSkeleton>
          </>
        ) : (
          ''
        )}
      </Group>
      <Group>
        <Icon displaySkeleton={displaySkeleton}>
          <ClockIcon size="medium" />
        </Icon>
        <TextWithSkeleton type="p" displaySkeleton={displaySkeleton}>
          {campaign.scheduled}
          {displaySkeleton
            ? 'loading'
            : ` ${t('campaigns.viewCampaign.scheduled')}`}
        </TextWithSkeleton>
      </Group>
      <Group>
        <Icon displaySkeleton={displaySkeleton}>
          <ListIcon size="medium" />
        </Icon>
        <TextWithSkeleton type="p" displaySkeleton={displaySkeleton}>
          {campaign.sent}
          {displaySkeleton ? 'loading' : ` ${t('campaigns.viewCampaign.sent')}`}
        </TextWithSkeleton>
      </Group>
      <Group>
        <Icon displaySkeleton={displaySkeleton}>
          <DraftIcon size="medium" />
        </Icon>
        <TextWithSkeleton type="p" displaySkeleton={displaySkeleton}>
          {campaign.approvalsCount}
          {displaySkeleton
            ? 'loading'
            : ` ${t('campaigns.viewCampaign.approvals')}`}
        </TextWithSkeleton>
      </Group>
      <Group>
        <Icon displaySkeleton={displaySkeleton}>
          <DraftIcon size="medium" />
        </Icon>
        <TextWithSkeleton type="p" displaySkeleton={displaySkeleton}>
          {campaign.draftsCount}
          {displaySkeleton
            ? 'loading'
            : ` ${t('campaigns.viewCampaign.drafts')}`}
        </TextWithSkeleton>
      </Group>
      <ButtonWrapper>
        <ButtonWithSkeleton
          onClick={(): void => {
            onViewCampaignClick({ campaignId })
          }}
          type="secondary"
          label={t('campaigns.viewCampaign.viewTag')}
          disabled={displaySkeleton}
          displaySkeleton={displaySkeleton}
          // @ts-expect-error TS(7006) FIXME: Parameter 'selectedItem' implicitly has an 'any' t... Remove this comment to see the full error message
          onSelectClick={(selectedItem) => {
            selectedItem.selectedItemClick()
          }}
          isSplit={showCampaignActions}
          items={selectItems}
        />
      </ButtonWrapper>
    </Container>
  )
}

ListItem.defaultProps = {
  displaySkeleton: false,
  onViewCampaignClick: () => undefined,
  onDeleteCampaignClick: () => undefined,
  onEditCampaignClick: () => undefined,
  goToAnalyzeReport: () => undefined,
}

export default ListItem
