import {
  AlertDialog,
  Button,
  Flex,
  Label,
  Link,
  Paragraph,
  Switch,
  toast,
} from '@buffer-mono/popcorn'
import React, { useState } from 'react'
import { pauseQueue } from '~publish/legacy/general-settings/thunks/pauseQueue'
import { selectCurrentProfile } from '~publish/legacy/profile-sidebar/selectors'
import { useAppDispatch, useAppSelector } from '~publish/legacy/store'
import { HC_UTM_PARAMS } from '~publish/legacy/utils/contants'
import commonStyles from '../../../GeneralSettings.module.css'

const PauseQueueAction = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const profile = useAppSelector(selectCurrentProfile)
  const [isLoading, setLoading] = useState(false)
  const [isModalShowing, setModalShowing] = useState(false)

  const showModal = (): void => setModalShowing(true)
  const hideModal = (): void => setModalShowing(false)

  // Do not show Clean Queue action if there is no profile loaded
  if (!profile) return <></>

  const paused = !!profile.paused
  const profileId = profile.id

  const onPauseClick = async (): Promise<void> => {
    setLoading(true)
    const pausingCopy = paused ? 'pausing' : 'unpausing'
    const result = await dispatch(pauseQueue({ profileId, paused: !paused }))
    if (pauseQueue.fulfilled.match(result)) {
      toast.success(result.payload.message)
    } else {
      toast.error(
        `Sorry! Something went wrong while ${pausingCopy} your queue: ${result.error.message}. Would you be up for trying again?`,
      )
    }
    hideModal()
    setLoading(false)
  }

  return (
    <>
      <Flex
        justify="between"
        className={commonStyles.fullWidth}
        align="center"
        gap="xl"
      >
        <Flex direction="column">
          <Label>Pause Queue</Label>
          <Paragraph className={commonStyles.settingsParagraph}>
            Stop all posts from being sent on this channel?{' '}
            <Link
              href={`https://support.buffer.com/article/515-pausing-your-queue?${HC_UTM_PARAMS}`}
              external={true}
            >
              Learn more
            </Link>
          </Paragraph>
        </Flex>
        <Flex
          className={commonStyles.actionsWrapper}
          justify="end"
          gap="xs"
          align="center"
        >
          <Label>{paused ? 'On' : 'Off'}</Label>
          <Switch
            disabled={isLoading}
            onClick={paused ? onPauseClick : showModal}
            checked={paused}
            data-testid="pause-queue-toggle"
          />
        </Flex>
      </Flex>
      <AlertDialog open={isModalShowing}>
        <AlertDialog.Content>
          <AlertDialog.Title>Pause Queue?</AlertDialog.Title>
          <AlertDialog.Description>
            All posts on <strong>{profile.serviceUsername}</strong> will be
            stopped from being sent. This action cannot be undone.
          </AlertDialog.Description>
          <AlertDialog.Separator />
          <AlertDialog.Actions>
            <AlertDialog.Cancel>
              <Button onClick={hideModal} variant="tertiary">
                Cancel
              </Button>
            </AlertDialog.Cancel>
            <AlertDialog.Action>
              <Button
                onClick={onPauseClick}
                loading={isLoading}
                variant="critical"
                data-testid="pause-queue-confirm"
              >
                Pause Queue
              </Button>
            </AlertDialog.Action>
          </AlertDialog.Actions>
        </AlertDialog.Content>
      </AlertDialog>
    </>
  )
}

export { PauseQueueAction }
