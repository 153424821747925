import {
  orangeDarker,
  orangeLighter,
  orangeLightest,
} from '@bufferapp/ui/style/colors'
import { fontSizeSmall } from '@bufferapp/ui/style/fonts'
import React from 'react'
import styled from 'styled-components'
import { RemindersIcon } from './RemindersIcon'

export const ReminderBadgeWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${orangeDarker};
  background-color: ${orangeLightest};
  font-size: ${fontSizeSmall};
  border: 1px solid ${orangeLighter};
  border-radius: 4px 4px 0 0;
  border-bottom: none;
  padding: 8px;
`

export const ReminderBadge = ({
  isSent = false,
}: {
  isSent?: boolean
}): JSX.Element => {
  const copy = isSent ? 'Notification Sent' : 'Notification'
  return (
    <ReminderBadgeWrapper data-testid="reminder-badge">
      <RemindersIcon /> {copy}
    </ReminderBadgeWrapper>
  )
}
