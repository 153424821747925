/* eslint-disable react/no-array-index-key */
import React from 'react'
import { ThreadPost } from './components/ThreadPost'
import { Empty } from '../Common/Empty'
import * as Styles from './styles'
import type { ProfilePreview, TweetType, BaseDraftWithThread } from '../types'
import {
  draftPreviewPropType,
  profilePreviewPropType,
} from '../Common/propTypes'
import { useScrollToThreadedPost } from '../Common/hooks/useScrollToThreadedPost'

const getPosts = (draft: BaseDraftWithThread): TweetType[] => {
  if (draft.thread) {
    const { activeThreadId } = draft

    return draft.thread.map((threadedDraft, i) => ({
      content:
        activeThreadId === i
          ? {
              text: draft.text,
              enabledAttachmentType: draft.enabledAttachmentType,
              images: draft.images,
              video: draft.video,
              gif: draft.gif,
              link: draft.link,
              inThread: true,
            }
          : {
              ...threadedDraft,
              inThread: true,
            },
    }))
  } else {
    return [
      {
        content: {
          ...draft,
          text: draft.text,
          inThread: false,
        },
      },
    ]
  }
}

export const ThreadsPreview = ({
  draft,
  profile,
}: {
  draft: BaseDraftWithThread
  profile: ProfilePreview
}): JSX.Element => {
  const { isEmpty, hasThread, activeThreadId } = draft

  const posts = getPosts(draft)

  useScrollToThreadedPost(activeThreadId)

  if (isEmpty && !hasThread) {
    return <Empty />
  }

  return (
    <Styles.Wrapper data-testid="threads-preview">
      {posts.map((post, index) => (
        <ThreadPost
          key={index}
          content={post.content}
          profile={profile}
          index={index}
        />
      ))}
    </Styles.Wrapper>
  )
}

ThreadsPreview.propTypes = {
  draft: draftPreviewPropType.isRequired,
  profile: profilePreviewPropType.isRequired,
}
