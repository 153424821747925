import styled from 'styled-components'
import { fontFamily } from '@bufferapp/ui/style/fonts'
import {
  grayLight,
  grayDarker,
  grayDark,
  blue,
  blueLighter,
  redDark,
  red,
  redLighter,
} from '@bufferapp/ui/style/colors'

export const Textarea = styled.textarea<{ error?: boolean }>`
  display: block;
  box-sizing: border-box;
  padding: 12px 8px;
  height: 170px;
  font-family: ${fontFamily};
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  resize: none;
  border-radius: 4px;
  border: 1px solid ${(props): string => (props.error ? red : grayLight)};
  outline: none;
  color: ${grayDarker};

  &:focus-visible {
    outline: 4px solid
      ${(props): string => (props.error ? redLighter : blueLighter)};
    border: 1px solid ${(props): string => (props.error ? red : blue)};
  }

  &::placeholder {
    color: ${grayLight};
  }
`

export const Tip = styled.p`
  font-family: ${fontFamily};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: ${grayDark};
  margin: 0;

  strong {
    font-weight: 700;
  }
`

export const Error = styled.p`
  font-family: ${fontFamily};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: ${redDark};
  margin: 0;

  // icon
  svg {
    margin-right: 4px;
    vertical-align: middle;
  }
`
