import React from 'react'
import PostTypeBar from '~publish/legacy/composer/composer/components/channel-bar/PostTypeBar'
import { PostTypeShort } from '~publish/legacy/post/constants'
import { draftPropType } from '../ComposerPropTypes'

// @ts-expect-error TS(7031) FIXME: Binding element 'draft' implicitly has an 'any' ty... Remove this comment to see the full error message
const YoutubePostTypeBar = ({ draft }) => {
  if (!draft.service.isYoutube()) {
    return null
  }

  return (
    <PostTypeBar
      postTypes={[{ value: PostTypeShort, name: 'Short' }]}
      defaultPostType={PostTypeShort}
      draft={draft}
    />
  )
}

YoutubePostTypeBar.propTypes = {
  draft: draftPropType.isRequired,
}

export default YoutubePostTypeBar
