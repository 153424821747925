import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Text, Button } from '@bufferapp/ui'
import { gray, white, red, transparent } from '@bufferapp/ui/style/colors'
import { Warning } from '@bufferapp/ui/Icon'

const ErrorBannerCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 40px 24px 40px;
  border: 1px solid ${gray};
  box-sizing: border-box;
  border-radius: 2px;
  text-align: center;
  margin-bottom: 24px;
  position: relative;
`

const Triangle = styled.div`
  position: absolute;
  border-top: 74px solid ${red};
  border-right: 74px solid ${transparent};
  top: 0;
  left: 0;
`

const WarningIcon = styled(Warning)`
  position: absolute;
  top: 16px;
  left: 16px;
  color: ${white};
`

const Title = styled(Text)`
  margin: 0 0 8px;
`

const ButtonWithStyles = styled(Button)`
  margin-top: 16px;
`

// @ts-expect-error TS(7031) FIXME: Binding element 'title' implicitly has an 'any' ty... Remove this comment to see the full error message
const ErrorBanner = ({ title, children, onClick, actionLabel }) => (
  <ErrorBannerCard>
    <Triangle />
    <WarningIcon />
    <Title type="h3">{title}</Title>
    {children}
    {onClick && actionLabel && (
      <ButtonWithStyles type="primary" onClick={onClick} label={actionLabel} />
    )}
  </ErrorBannerCard>
)

ErrorBanner.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  actionLabel: PropTypes.string,
}

ErrorBanner.defaultProps = {
  onClick: () => {},
  actionLabel: '',
}

export default ErrorBanner
