import { type Location, Range } from 'slate'
import type { BufferEditor } from '~publish/legacy/editor/BufferEditor/types.plate'

export const locationInCharacterLimitRange = (
  editor: BufferEditor,
  location?: Location,
): boolean => {
  const characterLimitRange = editor.decorateRanges?.[0]
  if (characterLimitRange && location) {
    return Range.includes(characterLimitRange, location)
  }
  return false
}
