import React from 'react'
import {
  CalendarClockIcon,
  CheckCircleIcon,
  DraftIcon,
  EmptyState,
  SendIcon,
} from '@buffer-mono/popcorn'

import type { PostTab } from './PostTabs'

export const PostEmptyStateByTab = ({
  tab,
  children,
}: {
  tab: PostTab
  children?: React.ReactNode
}): JSX.Element => {
  if (tab === 'approvals') {
    return (
      <EmptyState size="large" variant="success">
        <EmptyState.Icon>
          <CheckCircleIcon />
        </EmptyState.Icon>
        <EmptyState.Heading>Good job. You are all done!</EmptyState.Heading>
        <EmptyState.Description>
          This is where drafts awaiting approval will appear
        </EmptyState.Description>
        <EmptyState.Actions>{children}</EmptyState.Actions>
      </EmptyState>
    )
  }

  if (tab === 'queue') {
    return (
      <EmptyState size="large" variant="primary">
        <EmptyState.Icon>
          <CalendarClockIcon />
        </EmptyState.Icon>
        <EmptyState.Heading>No posts scheduled</EmptyState.Heading>
        <EmptyState.Description>
          Schedule some posts and they will appear here
        </EmptyState.Description>
        <EmptyState.Actions>{children}</EmptyState.Actions>
      </EmptyState>
    )
  }

  if (tab === 'sent') {
    return (
      <EmptyState size="large" variant="primary">
        <EmptyState.Icon>
          <SendIcon />
        </EmptyState.Icon>
        <EmptyState.Heading>No sent posts</EmptyState.Heading>
        <EmptyState.Description>
          You have not sent any posts yet, once you do they will appear here
        </EmptyState.Description>
        <EmptyState.Actions>{children}</EmptyState.Actions>
      </EmptyState>
    )
  }

  return (
    <EmptyState size="large" variant="primary">
      <EmptyState.Icon>
        <DraftIcon />
      </EmptyState.Icon>
      <EmptyState.Heading>No drafts</EmptyState.Heading>
      <EmptyState.Description>
        You have not saved any drafts yet, once you do they will appear here
      </EmptyState.Description>
      <EmptyState.Actions>{children}</EmptyState.Actions>
    </EmptyState>
  )
}
