import type {
  ValidationRule,
  ValidationRuleModule,
  ValidationRuleValidator,
} from '../types'

/**
 * Factory function for creating validation rules
 *
 * It reduces the amount of boilerplate by providing the guards and define functions
 *
 * @param param0
 * @returns
 */
export function createRuleHandler<RULE extends ValidationRule>({
  ruleName,
  validate,
  messages,
}: {
  ruleName: RULE['ruleName']
  validate: ValidationRuleValidator<RULE>
  messages: RULE['messages']
}): ValidationRuleModule<RULE> {
  return {
    ruleName,

    canHandle(obj: unknown): obj is RULE {
      return (
        !!obj &&
        typeof obj === 'object' &&
        'ruleName' in obj &&
        obj.ruleName === ruleName
      )
    },

    define(args: RULE['ruleConfig'], overrides = {}): RULE {
      const generic = overrides.messages?.generic || messages.generic
      const specific = overrides.messages
        ? overrides.messages.specific || generic
        : messages.specific || generic
      return {
        ruleName,
        ruleConfig: args,
        messages: {
          generic,
          specific,
        },
      } as RULE
    },

    validate,
  }
}
