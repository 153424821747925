import { createAsyncThunk } from '@reduxjs/toolkit'
import type { Schedule } from '~publish/legacy/profile-sidebar/types'
import type { RootState } from '~publish/legacy/store'
import callRpc from '~publish/legacy/utils/call-rpc'

export const updateSchedules = createAsyncThunk<
  {
    message: string
    success: true
    schedules: Schedule[]
  },
  {
    profileId: string
    schedules: Schedule[]
  },
  { state: RootState }
>('postingSchedule/updateSchedules', async ({ profileId, schedules }) => {
  const response = await callRpc('updateSchedule', {
    profileId,
    schedules,
  })

  if (!response.success) {
    throw new Error(response.message)
  }
  return response
})
