import { PostEntity } from '~publish/legacy/post/PostEntity'
import type { RpcUpdate } from '~publish/legacy/post/types'
import { selectCurrentProfileId } from '~publish/legacy/profile-sidebar/selectors'
import type { RootState } from '~publish/legacy/store'

export const selectProfileIdByPostId =
  (postId: string) =>
  (state: RootState): string | null => {
    const foundId = Object.keys(state.queue.byProfileId || {}).find(
      (profileId) => {
        // find the profileId that has this postId in it
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        return Object.keys(state.queue.byProfileId[profileId].posts).some(
          (id) => {
            return id === postId
          },
        )
      },
    )

    return foundId || null
  }

export const selectPostById = (postId: string) => (state: RootState) => {
  const profileId = selectProfileIdByPostId(postId)(state)

  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return profileId ? state.queue.byProfileId[profileId].posts[postId] : null
}

export const selectFailedPostsCount = (state: RootState): number => {
  const profileId = selectCurrentProfileId(state)

  const posts: Record<string, RpcUpdate> =
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    state.queue.byProfileId[profileId]?.posts || {}

  return Object.keys(posts).reduce((acc, key) => {
    const post = posts[key]
    if (PostEntity.hasError(post)) {
      acc += 1
    }

    return acc
  }, 0)
}

export const selectPostsCount = (state: RootState): number => {
  const profileId = selectCurrentProfileId(state)
  // @ts-expect-error TS(7053)
  const posts = state.queue.byProfileId[profileId]?.posts || {}
  return Object.keys(posts).length
}
