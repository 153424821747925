/**
 * Configuration specific to publish app - how fields map to components, etc.
 */

import { PostFields } from '@buffer-mono/reminders-config'
import ShopgridComposerBar from '~publish/legacy/composer/composer/components/ShopgridComposerBar'
import LocationComposerBar from '~publish/legacy/composer/composer/components/location-bar/LocationComposerBar'
import FirstCommentInstagramComposerBar from '~publish/legacy/composer/composer/components/first-comment-instagram-bar'
import type { Draft } from '~publish/legacy/composer/composer/entities/Draft'
import ShareToFeedToggle from '~publish/legacy/composer/composer/components/share-to-feed-toggle/ShareToFeedToggle'
import { EmptyField } from '~publish/legacy/reminders/components/new-reminders/field-configuration/EmptyField'

export type FieldConfigItem = {
  field: 'text' | 'toggle'
  label: string
  placeholder: string
  direct_component?: React.ComponentType<{ draft: Draft }>
}

type FieldConfigurationType = {
  [key in PostFields | string]: FieldConfigItem
}

export const FieldConfiguration: FieldConfigurationType = {
  [PostFields.MUSIC]: {
    field: 'text',
    label: 'Music',
    placeholder: 'Add placeholder text for the music you’d like to include',
  },
  [PostFields.TEXT]: {
    field: 'text',
    label: 'Text',
    placeholder: 'Add text',
  },
  [PostFields.TITLE]: {
    field: 'text',
    label: 'Title',
    placeholder: 'Add title',
  },
  [PostFields.TOPICS]: {
    field: 'text',
    label: 'Topics',
    placeholder: 'Add topics',
  },
  [PostFields.PRODUCTS]: {
    field: 'text',
    label: 'Tag Products',
    placeholder: 'Add products',
  },
  [PostFields.OTHER]: {
    field: 'text',
    label: 'Other',
    placeholder: 'Add other',
  },
  [PostFields.LINK]: {
    field: 'text',
    label: 'Link',
    placeholder: 'Add link',
    direct_component: ShopgridComposerBar,
  },
  [PostFields.FIRST_COMMENT]: {
    field: 'text',
    label: 'First Comment',
    placeholder: 'Add first comment',
    direct_component: FirstCommentInstagramComposerBar,
  },
  [PostFields.LOCATION_NAME]: {
    field: 'text',
    label: 'Location',
    placeholder: 'Add location',
    direct_component: LocationComposerBar,
  },
  [PostFields.LOCATION_ID]: {
    field: 'text',
    label: 'Location',
    placeholder: 'Add location',
    direct_component: EmptyField,
  },
  [PostFields.SHARE_TO_FEED]: {
    field: 'toggle',
    label: 'Share to Feed',
    placeholder: 'Share to Feed',
    direct_component: ShareToFeedToggle,
  },
}
