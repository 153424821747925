/**
 * Component that displays a profile
 */
import React from 'react'
import PropTypes from 'prop-types'
import ReactDOM from 'react-dom'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import { networkIconMap } from '~publish/legacy/shared-components'
import AppActionCreators from '../action-creators/AppActionCreators'
import { Services } from '../AppConstants'
import BoardSelector from './BoardSelector'
import Button from './shared/Button'
import Dropdown, { DropdownContent, DropdownTrigger } from './Dropdown'
import { scrollIntoView } from '../utils/DOMUtils'
import {
  getCurrentPage,
  getSegmentChannelMetadata,
} from '../utils/TrackingUtils'
import { visibleNotificationsPropType } from './ComposerPropTypes'
import styles from './css/Profile.module.css'
import { TempTooltip } from '~publish/legacy/shared-components/TempTooltip/TempTooltip'
import AppStore from '~publish/legacy/composer/composer/stores/AppStore'
import isMatch from 'lodash/isMatch'
import matches from 'lodash/matches'
import { ProfileAvatar } from './ProfileAvatar'

class Profile extends React.Component {
  static propTypes = {
    // @ts-expect-error TS(2339) FIXME: Property 'isRequired' does not exist on type '<P e... Remove this comment to see the full error message
    profile: PropTypes.shape.isRequired,
    organizationId: PropTypes.string,
    expandedProfileSubprofileDropdownId: PropTypes.string,
    visibleNotifications: visibleNotificationsPropType.isRequired,
    addContainerScrollHandler: PropTypes.func.isRequired,
    removeContainerScrollHandler: PropTypes.func.isRequired,
    className: PropTypes.string,
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
  // eslint-disable-next-line no-useless-constructor
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    if (this.hasSubprofiles()) {
      const {
        // @ts-expect-error TS(2339) FIXME: Property 'addContainerScrollHandler' does not exis... Remove this comment to see the full error message
        addContainerScrollHandler,
        // @ts-expect-error TS(2339) FIXME: Property 'profile' does not exist on type 'Readonl... Remove this comment to see the full error message
        profile,
        // @ts-expect-error TS(2339) FIXME: Property 'expandedProfileSubprofileDropdownId' doe... Remove this comment to see the full error message
        expandedProfileSubprofileDropdownId,
      } = this.props
      addContainerScrollHandler(this.onContainerScroll)

      const wasSubprofileDropdownJustExpanded =
        profile.id === expandedProfileSubprofileDropdownId

      if (wasSubprofileDropdownJustExpanded) this.scrollProfileIntoView()
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'prevProps' implicitly has an 'any' type... Remove this comment to see the full error message
  componentDidUpdate(prevProps) {
    if (this.hasSubprofiles()) {
      const {
        // @ts-expect-error TS(2339) FIXME: Property 'profile' does not exist on type 'Readonl... Remove this comment to see the full error message
        profile: { id },
      } = this.props
      const wasSubprofileDropdownExpanded =
        prevProps.expandedProfileSubprofileDropdownId === id
      const wasSubprofileDropdownJustExpanded =
        !wasSubprofileDropdownExpanded && this.isSubprofileDropdownExpanded()

      if (wasSubprofileDropdownJustExpanded) this.scrollProfileIntoView()
    }
  }

  componentWillUnmount() {
    if (this.hasSubprofiles()) {
      // @ts-expect-error TS(2339) FIXME: Property 'removeContainerScrollHandler' does not e... Remove this comment to see the full error message
      const { removeContainerScrollHandler } = this.props
      removeContainerScrollHandler(this.onContainerScroll)
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  onContainerScroll = (e, scrollTop) => {
    // eslint-disable-next-line react/no-find-dom-node, react/no-string-refs
    const dropdownContent = ReactDOM.findDOMNode(this.refs.dropdownContent)
    const spacingFromProfileContainer = 5

    const yTranslation =
      this.profileContainerTopOffset +
      this.profileContainerHeight +
      spacingFromProfileContainer -
      scrollTop

    // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
    dropdownContent.style.top = 0 // Anchor to top of parent on scroll, until then keep natural pos
    // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
    dropdownContent.style.transform = `translateY(${yTranslation}px)` // And translate accordingly
    // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
    dropdownContent.style.webkitTransform = `translateY(${yTranslation}px)` // Manual prefixing
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
  onClick = (e) => {
    // @ts-expect-error TS(2339) FIXME: Property 'organizationId' does not exist on type '... Remove this comment to see the full error message
    const { organizationId, profile } = this.props
    const metadata = getSegmentChannelMetadata({
      profile,
      organizationId,
      page: getCurrentPage(),
    })

    if (profile.isSelected) {
      // prevent dropdown from being expanded
      if (this.hasSubprofiles()) e.stopPropagation()
      AppActionCreators.unselectProfile(profile.id)
      BufferTracker.channelDeselected(metadata)
    } else {
      if (this.hasSubprofiles()) return
      AppActionCreators.selectProfile(profile.id)
      BufferTracker.channelSelected(metadata)
    }

    e.preventDefault()
  }

  // Give DOM events that could possibly be causing this method to run an
  // opportunity to propagate to all handlers before collapsing the dropdown
  onSubprofileDropdownCollapsed = () =>
    window.queueMicrotask(() => {
      const {
        // @ts-expect-error TS(2339) FIXME: Property 'profile' does not exist on type 'Readonl... Remove this comment to see the full error message
        profile: { id },
      } = this.props
      AppActionCreators.collapseProfileSubprofileDropdown(id)
    })

  // Give DOM events that could possibly be causing this method to run an
  // opportunity to propagate to all handlers before expanding the dropdown
  onSubprofileDropdownExpanded = () =>
    window.queueMicrotask(() => {
      // @ts-expect-error TS(2339) FIXME: Property 'profile' does not exist on type 'Readonl... Remove this comment to see the full error message
      const { profile } = this.props

      AppActionCreators.expandProfileSubprofileDropdown(profile.id)
      AppActionCreators.refreshSubprofileData(profile.id)
    })

  hasSubprofiles = () => {
    const {
      // @ts-expect-error TS(2339) FIXME: Property 'profile' does not exist on type 'Readonl... Remove this comment to see the full error message
      profile: {
        service: { name },
      },
    } = this.props
    const service = Services.get(name)
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    return service.hasSubprofiles
  }

  isSubprofileDropdownExpanded = () => {
    const {
      // @ts-expect-error TS(2339) FIXME: Property 'profile' does not exist on type 'Readonl... Remove this comment to see the full error message
      profile: { id },
      // @ts-expect-error TS(2339) FIXME: Property 'expandedProfileSubprofileDropdownId' doe... Remove this comment to see the full error message
      expandedProfileSubprofileDropdownId,
    } = this.props
    return expandedProfileSubprofileDropdownId === id
  }

  get profileContainerTopOffset() {
    // @ts-expect-error TS(2551) FIXME: Property '_profileContainerTopOffset' does not exi... Remove this comment to see the full error message
    if (!this._profileContainerTopOffset) {
      // @ts-expect-error TS(2551) FIXME: Property '_profileContainerTopOffset' does not exi... Remove this comment to see the full error message
      // eslint-disable-next-line react/no-string-refs
      this._profileContainerTopOffset = this.refs.profileContainer.offsetTop // Cache
    }

    // @ts-expect-error TS(2551) FIXME: Property '_profileContainerTopOffset' does not exi... Remove this comment to see the full error message
    return this._profileContainerTopOffset
  }

  get profileContainerHeight() {
    // @ts-expect-error TS(2551) FIXME: Property '_profileContainerHeight' does not exist ... Remove this comment to see the full error message
    if (!this._profileContainerHeight) {
      // @ts-expect-error TS(2551) FIXME: Property '_profileContainerHeight' does not exist ... Remove this comment to see the full error message
      // eslint-disable-next-line react/no-string-refs
      this._profileContainerHeight = this.refs.profileContainer.offsetHeight // Cache
    }

    // @ts-expect-error TS(2551) FIXME: Property '_profileContainerHeight' does not exist ... Remove this comment to see the full error message
    return this._profileContainerHeight
  }

  scrollProfileIntoView = () => {
    scrollIntoView({
      elOffsets: [
        this.profileContainerTopOffset,
        this.profileContainerTopOffset + this.profileContainerHeight,
      ],
      // @ts-expect-error TS(2339) FIXME: Property 'parentElement' does not exist on type 'R... Remove this comment to see the full error message
      // eslint-disable-next-line react/no-string-refs
      ref: this.refs.profileContainer.parentElement,
      padding: 25,
    })
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'profile' does not exist on type 'Readonl... Remove this comment to see the full error message
    const { profile, visibleNotifications, organizationId, className } =
      this.props
    const hasSubprofiles = this.hasSubprofiles()
    const formattedServiceType =
      profile.serviceType.charAt(0).toUpperCase() + profile.serviceType.slice(1)

    const isInstagramProfile = isMatch(profile, {
      serviceName: 'instagram',
      serviceType: 'profile',
    })
    const isInstagramBusiness = isMatch(profile, {
      serviceName: 'instagram',
      serviceType: 'business',
    })

    const isFacebookGroup = isMatch(profile, {
      serviceName: 'facebook',
      serviceType: 'group',
    })
    const isFacebookPage = isMatch(profile, {
      serviceName: 'facebook',
      serviceType: 'page',
    })

    const selectedProfiles = AppStore.getSelectedProfiles()
    const hasFacebookGroupSelected = selectedProfiles.some(
      matches({ serviceName: 'facebook', serviceType: 'group' }),
    )
    const hasFacebookPageSelected = selectedProfiles.some(
      matches({ serviceName: 'facebook', serviceType: 'page' }),
    )
    const hasInstagramProfileSelected = selectedProfiles.some(
      matches({ serviceName: 'instagram', serviceType: 'profile' }),
    )
    const hasInstagramBusinessSelected = selectedProfiles.some(
      matches({ serviceName: 'instagram', serviceType: 'business' }),
    )

    const isLocked = profile.isDisabled

    const isFacebookSelectionDisabled =
      (isFacebookGroup && hasFacebookPageSelected) ||
      (isFacebookPage && hasFacebookGroupSelected)

    const isInstagramSelectionDisabled =
      (isInstagramProfile && hasInstagramBusinessSelected) ||
      (isInstagramBusiness && hasInstagramProfileSelected)

    const isSelected =
      AppStore.getSelectedProfiles().filter((p) => p.id === profile.id).length >
      0

    // TODO: introduce `classnames` package
    const profileContainerClassName = [
      isLocked || isFacebookSelectionDisabled || isInstagramSelectionDisabled
        ? styles.lockedProfileContainer
        : null,
      // "bi bi-lock" sets a bootstrap icon right now
      isLocked || isFacebookSelectionDisabled || isInstagramSelectionDisabled
        ? 'bi bi-lock'
        : null,
      className,
    ]
      .filter((item) => !!item)
      .join(' ')

    const profileTooltipBaseContents = profile.isFake
      ? `Preview ${formattedServiceType}`
      : `${profile.service.username} - ${formattedServiceType}`
    let profileTooltipContents = isLocked
      ? `${profileTooltipBaseContents} (${profile.disabledMessage || 'locked'})`
      : profileTooltipBaseContents

    if (isFacebookSelectionDisabled) {
      profileTooltipContents =
        'You cannot schedule a post for Facebook Pages and Facebook Groups at the same time.'
    }

    if (isInstagramSelectionDisabled) {
      profileTooltipContents =
        'You cannot schedule a post for Instagram Personal Account and Instagram Business at the same time.'
    }

    // Extract social network icon & color
    const iconInfo = networkIconMap.get(profile.service.name)
    const iconColor = iconInfo ? iconInfo.color : 'grey'

    const profileButton = (
      // TODO: Replace TempTooltip with Popcorn Tooltip once Popcorn is ready to be used
      <TempTooltip content={profileTooltipContents} arrow>
        <Button
          role="switch"
          name={`${profile.service.name}-profile-button`}
          className={[
            styles.profileButton,
            profile.isFake ? 'profileButtonFake' : '',
          ].join(' ')}
          // profile color is set dynamically here with CSS custom property which is used in CSS
          // "as" is necessary, because TS does not recognise custom properties
          style={{ '--profile-color': iconColor } as React.CSSProperties}
          disabled={
            isLocked ||
            ((isFacebookSelectionDisabled || isInstagramSelectionDisabled) &&
              !isSelected)
          }
          aria-label={`${profile.service.username} ${profile.service.name} ${formattedServiceType}`}
          aria-checked={profile.isSelected}
          onClick={this.onClick}
        >
          <ProfileAvatar profile={profile} isSelected={profile.isSelected} />
        </Button>
      </TempTooltip>
    )

    return (
      // eslint-disable-next-line react/no-string-refs
      <div className={profileContainerClassName} ref="profileContainer">
        {hasSubprofiles ? (
          <Dropdown
            isDropdownExpanded={this.isSubprofileDropdownExpanded()}
            onHide={this.onSubprofileDropdownCollapsed}
            onShow={this.onSubprofileDropdownExpanded}
          >
            <DropdownTrigger
              // DropDown trigger uses <a> element for trigger, which is confusing for SR users
              // resetting it here explicitly to "group"
              role="group"
              aria-label="Select boards"
              // button is acting as a trigger and is focusable, so this element does not need to be
              // needs to be set explicitly, because DropdownTrigger sets tabindex
              tabIndex={-1}
            >
              {profileButton}
            </DropdownTrigger>
            <DropdownContent className={styles.dropdownContent}>
              <BoardSelector
                subprofiles={profile.subprofiles}
                profile={profile}
                subprofilesCount={profile.subprofiles.length}
                visibleNotifications={visibleNotifications}
                organizationId={organizationId}
              />
            </DropdownContent>
          </Dropdown>
        ) : (
          profileButton
        )}
      </div>
    )
  }
}

export default Profile
