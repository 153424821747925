import type ValidationFail from '~publish/legacy/validation/ValidationFail'
import type ValidationSuccess from '~publish/legacy/validation/ValidationSuccess'
import type { AspectRatio } from '../aspect-ratio/types'
import type { Media } from '../types'

export enum ValidationRuleName {
  MaxSize = 'maxSize',
  MinSize = 'minSize',
  AspectRatioMatch = 'aspectRatioMatch',
  AspectRatioRange = 'aspectRatioRange',
}

export type ValidationRuleConfig = Record<string, unknown>

export type ValidationRule<
  NAME extends ValidationRuleName = ValidationRuleName,
  CONF extends ValidationRuleConfig = ValidationRuleConfig,
> = {
  ruleName: NAME
  ruleConfig: Readonly<CONF>
  messages: {
    generic: string
    specific?: string
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyValidationRule = ValidationRule<any, any>

export type ValidationRuleGuard<T extends ValidationRule> = (
  rule: unknown,
) => rule is T

export type ValidationRuleValidator<R extends ValidationRule> = (
  rule: R,
  media: Media,
) => ValidationFail | ValidationSuccess | null

/**
 * RULES
 */

export type MaxSizeRule = ValidationRule<
  ValidationRuleName.MaxSize,
  {
    max: number
  }
>

export type MinSizeRule = ValidationRule<
  ValidationRuleName.MinSize,
  {
    min: number
  }
>

export type AspectRatioRangeRule = ValidationRule<
  ValidationRuleName.AspectRatioRange,
  {
    min: AspectRatio
    max: AspectRatio
  }
>

export type AspectRatioMatchRule = ValidationRule<
  ValidationRuleName.AspectRatioMatch,
  {
    expected: AspectRatio | AspectRatio[]
  }
>

export type CommonValidationRules = AspectRatioRangeRule | AspectRatioMatchRule

export type ValidationRuleModule<RULE extends ValidationRule> = {
  ruleName: RULE['ruleName']
  canHandle: ValidationRuleGuard<RULE>
  validate: ValidationRuleValidator<RULE>
  define: (
    args: RULE['ruleConfig'],
    opts?: Partial<Pick<RULE, 'messages'>>,
  ) => RULE
}
