import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import type { Video } from '~publish/legacy/composer/composer/entities/factories'
import { VideoPlayer as VideoPlayerCommon } from '../../../Common/VideoPlayer'
import { getOrientation } from '../../../Common/utils'

import * as Styles from './styles'

const StyledVideoPlayer = styled(VideoPlayerCommon)`
  ${Styles.Wrapper}
`

const VideoPlayer = ({ video }: { video: Video }) => {
  const { height, width } = video
  const orientation = getOrientation({ height, width })

  return (
    <StyledVideoPlayer
      video={video}
      orientation={orientation}
      showSoundOffIcon
    />
  )
}

VideoPlayer.propTypes = {
  video: PropTypes.shape({
    thumbnail: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
}

export { VideoPlayer }
