import React from 'react'
import type { PlateRenderElementProps, Value } from '@udecode/plate'
import styled from 'styled-components'
import type { GenerateContentElement } from '../nodes/GenerateContentElement'

const Highlight = styled.div`
  span {
    background-color: #d9f3d6;
  }
`

export const GenerateContentElementUI = ({
  children,
  attributes,
}: PlateRenderElementProps<Value, GenerateContentElement>) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Highlight {...attributes}>
      <span className="generated-content-value">{children}</span>
    </Highlight>
  )
}
