import React, { type ElementRef } from 'react'
import clsx from 'clsx'

import { WarningIcon, InfoIcon, CriticalIcon } from '@buffer-mono/popcorn'

import styles from './PostCardNotice.module.css'

const iconByVariant = {
  info: InfoIcon,
  warning: WarningIcon,
  critical: CriticalIcon,
}

type PostCardNoticeProps = {
  variant: 'info' | 'critical' | 'warning'
  children?: React.ReactNode
}

const PostCardNotice = ({
  variant,
  children,
}: PostCardNoticeProps): JSX.Element => {
  const Icon = iconByVariant[variant]

  return (
    <section className={clsx(styles.notice, styles[variant])}>
      <Icon className={styles.icon} aria-hidden />
      {children}
    </section>
  )
}

PostCardNotice.displayName = 'PostCardNotice'

type PostCardNoticeActionProps = {
  children: React.ReactNode
}

const PostCardNoticeAction = React.forwardRef<
  ElementRef<'div'>,
  PostCardNoticeActionProps
>((props, forwardedRef) => {
  return (
    <div className={styles.action} ref={forwardedRef}>
      {props.children}
    </div>
  )
})

PostCardNoticeAction.displayName = 'PostCardNotice.Action'

const PostCardNoticeObject = Object.assign(PostCardNotice, {
  Action: PostCardNoticeAction,
})

export { PostCardNoticeObject as PostCardNotice }
