import type {
  Announcements,
  DragCancelEvent,
  DragEndEvent,
  DragOverEvent,
  DragStartEvent,
  UniqueIdentifier,
} from '@dnd-kit/core'

import type {
  GetIdeasBoardQuery,
  IdeaCard_IdeaFragment as Idea,
} from '~publish/gql/graphql'
import { fromNullable, UNASSIGNED_GROUP_NAME } from './helpers'

type IdeaGroup = GetIdeasBoardQuery['ideaGroups'][0]

const getGroupName = (
  id: UniqueIdentifier,
  ideaGroups: IdeaGroup[],
): string => {
  return (
    ideaGroups.find((group) => group.id === id)?.name ?? UNASSIGNED_GROUP_NAME
  )
}

const getCardTitle = (
  id: UniqueIdentifier,
  ideaNodes: Idea[],
): string | undefined => {
  return fromNullable(ideaNodes.find((idea) => idea.id === id)?.content?.title)
}

// TODO: Write a11y messages for column drag and drop
export function createAnnouncements(
  ideaGroups: IdeaGroup[],
  ideaNodes: Idea[],
): Announcements {
  return {
    onDragStart({ active }: DragStartEvent): string {
      const groupName = getGroupName(
        active.data.current?.sortable?.containerId,
        ideaGroups,
      )
      const cardTitle = getCardTitle(active.id, ideaNodes)
      const position = active.data.current?.sortable?.index + 1 // Adjust from 0-based to 1-based indexing
      const description = cardTitle || `the card in position ${position}`

      return `Started dragging ${description} from ${groupName}.`
    },
    onDragOver({ active, over }: DragOverEvent): string {
      const groupName = getGroupName(
        active.data.current?.sortable?.containerId,
        ideaGroups,
      )
      const overGroupName = over
        ? getGroupName(over.data.current?.sortable?.containerId, ideaGroups)
        : null
      const cardTitle = getCardTitle(active.id, ideaNodes)
      const startPosition = active.data.current?.sortable?.index + 1
      const endPosition = over
        ? over.data.current?.sortable?.index + 1
        : startPosition
      const description = cardTitle || `the card from position ${startPosition}`

      if (over) {
        if (groupName === overGroupName) {
          return `${description} moved from position ${startPosition} to position ${endPosition} within ${groupName}.`
        } else {
          return `Moved ${description} from ${groupName} to position ${endPosition} in ${overGroupName}.`
        }
      }
      return `${description} is now outside any group.`
    },
    onDragEnd({ active, over }: DragEndEvent): string {
      const groupName = getGroupName(
        active.data.current?.sortable?.containerId,
        ideaGroups,
      )
      const overGroupName = over
        ? getGroupName(over.data.current?.sortable.containerId, ideaGroups)
        : null
      const cardTitle = getCardTitle(active.id, ideaNodes)
      const startPosition = active.data.current?.sortable?.index + 1
      const endPosition = over
        ? over.data.current?.sortable.index + 1
        : startPosition
      const description = cardTitle || `the card from position ${startPosition}`

      if (over) {
        if (groupName === overGroupName) {
          return `${description} was sorted to position ${endPosition} within ${groupName}.`
        } else {
          // Ensure the message correctly states the card was dropped into a different group
          return `Dropped ${description} into ${overGroupName} at position ${endPosition}.`
        }
      }
      return `${description} was dropped outside any group.`
    },
    onDragCancel({ active }: DragCancelEvent): string {
      const cardTitle = getCardTitle(active.id, ideaNodes)
      const position = active.data.current?.sortable
        ? active.data.current.sortable.index + 1
        : 'an unknown position'
      const description = cardTitle || `The card in position ${position}`
      return `Dragging was cancelled. ${description} was not moved.`
    },
  }
}
