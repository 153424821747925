import React from 'react'

// @ts-expect-error TS(7006) FIXME: Parameter 'insertInto' implicitly has an 'any' typ... Remove this comment to see the full error message
const insertReplacer = (insertInto, replaceString, replaceWith) => {
  // @ts-expect-error TS(7034) FIXME: Variable 'completeString' implicitly has type 'any... Remove this comment to see the full error message
  let completeString = []
  if (typeof insertInto === 'string') {
    const replacedString = insertInto.split(replaceString)
    if (replacedString.length === 1) {
      return insertInto
    }
    replacedString.forEach((elem, index) => {
      completeString.push(elem)
      if (index !== replacedString.length - 1) {
        completeString.push(replaceWith)
      }
    })
  } else {
    // @ts-expect-error TS(7006) FIXME: Parameter 'elem' implicitly has an 'any' type.
    insertInto.forEach((elem) => {
      if (React.isValidElement(elem)) {
        completeString.push(elem)
      } else {
        const newString = insertReplacer(elem, replaceString, replaceWith)
        if (typeof newString === 'string') {
          completeString.push(newString)
        } else {
          // @ts-expect-error TS(7005) FIXME: Variable 'completeString' implicitly has an 'any[]... Remove this comment to see the full error message
          completeString = completeString.concat(newString)
        }
      }
    })
  }
  // @ts-expect-error TS(7005) FIXME: Variable 'completeString' implicitly has an 'any[]... Remove this comment to see the full error message
  return completeString
}

// @ts-expect-error TS(7006) FIXME: Parameter 'insertInto' implicitly has an 'any' typ... Remove this comment to see the full error message
const insertIntoTranslation = (insertInto, replacements) => {
  let result = insertInto
  // @ts-expect-error TS(7031) FIXME: Binding element 'replaceString' implicitly has an ... Remove this comment to see the full error message
  replacements.forEach(({ replaceString, replaceWith }) => {
    result = insertReplacer(result, replaceString, replaceWith)
  })

  return result
}

export default insertIntoTranslation
// @ts-expect-error TS(7006) FIXME: Parameter 'insertInto' implicitly has an 'any' typ... Remove this comment to see the full error message
export const stringTokenizer = (insertInto, replaceString, replaceWith) => {
  const tokenizedString = insertReplacer(insertInto, replaceString, replaceWith)

  if (typeof tokenizedString !== 'string') {
    return tokenizedString.join('')
  }

  return tokenizedString
}
