import { getEditorString, getNodeEntries, getPointBefore } from '@udecode/plate'
import { getUnicodeAwareLength } from '~publish/legacy/composer/composer/utils/StringUtils'
import { serialize } from '../../../BufferEditor'
import type { BufferEditor } from '../../../BufferEditor/types.plate'
import {
  type FacebookMentionDbEntry,
  FacebookMentionInputElement,
} from '../nodes'

// Find all facebook mention elements in the editor
// and returns them in the format they're stored in the db.
// The returned entities are used again when loading the post to re-convert
// the mention text into facebook mention elements.
export const getAllFacebookMentions = (
  editor: BufferEditor,
): FacebookMentionDbEntry[] => {
  const nodeEntries = getNodeEntries<FacebookMentionInputElement>(editor, {
    at: [],
    match: (n) => FacebookMentionInputElement.is(n),
  })

  return Array.from(nodeEntries).reduce<FacebookMentionDbEntry[]>(
    (result, [node, path]) => {
      const { autocompleted, valid } = node

      if (!getEditorString(editor, path)) return result

      const pointBefore = getPointBefore(editor, path)
      const entity = FacebookMentionInputElement.toEntity(node)

      // Only return valid, autocompleted Facebook mentions
      // pointBefore can't be null or undefined, so adding it to condition here
      if (autocompleted && valid && entity && pointBefore) {
        const range = {
          anchor: { path: [0, 0], offset: 0 },
          focus: pointBefore,
        }

        const { text } = serialize(editor, {
          at: range,
        })
        const unicodeAwareStart = getUnicodeAwareLength(
          text.substring(0, text.length),
        )
        const { length } = getEditorString(editor, path)

        return [
          ...result,
          {
            content: entity.id,
            text: entity.name,
            url: entity.url,
            indices: [unicodeAwareStart, unicodeAwareStart + length],
          },
        ]
      }
      return result
    },
    [],
  )
}
