export const CAMPAIGNS = 'campaigns'
export const CUSTOM_BITLY = 'customBitly'
export const SENT_POSTS_30_DAYS = 'sentPosts30Days'
export const CALENDAR = 'calendar'
export const CUSTOMIZE_UTM_PARAM = 'customizeUTMParam'
export const APPROVAL_FLOW = 'approvalFlow'
export const DRAFTS = 'drafts'
export const SHOP_GRID = 'shopGrid'
export const STORIES = 'stories'
export const TWITTER_IMPRESSIONS = 'twitterImpressions'
export const ANALYTICS_ON_POSTS = 'analyticsOnPosts'
export const FIRST_COMMENT = 'firstComment'
export const SHARE_NEXT = 'shareNext'
export const TAG_USERS_IN_IMAGES = 'tagUsersInImages'
export const PINTEREST = 'pinterest'
export const CUSTOM_VIDEO_THUMBNAIL_INSTAGRAM = 'customVideoThumbnailInstagram'
export const HASHTAG_MANAGER = 'hashtagManager'
export const CHANNEL_GROUPS_IN_COMPOSER = 'channelGroupsInComposer'
export const TEAMS_PANEL = 'teamsPanel'
export const CONTENT = 'content'

export const FIRST_COMMENT_LINKEDIN = 'firstCommentLinkedin'
export default {
  CAMPAIGNS,
  CUSTOM_BITLY,
  SENT_POSTS_30_DAYS,
  CALENDAR,
  CUSTOMIZE_UTM_PARAM,
  APPROVAL_FLOW,
  DRAFTS,
  SHOP_GRID,
  STORIES,
  TWITTER_IMPRESSIONS,
  ANALYTICS_ON_POSTS,
  FIRST_COMMENT,
  SHARE_NEXT,
  TAG_USERS_IN_IMAGES,
  PINTEREST,
  CUSTOM_VIDEO_THUMBNAIL_INSTAGRAM,
  HASHTAG_MANAGER,
  CHANNEL_GROUPS_IN_COMPOSER,
  FIRST_COMMENT_LINKEDIN,
  TEAMS_PANEL,
  CONTENT,
}
