import React from 'react'
import PropTypes from 'prop-types'
import InstagramPostMetaBar from '../InstagramPostMetaBar'
import PinterestPostMetaBar from '../PinterestPostMetaBar'

const RenderPostMetaBar = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'profileService' implicitly has an... Remove this comment to see the full error message
  profileService,
  // @ts-expect-error TS(7031) FIXME: Binding element 'locationName' implicitly has an '... Remove this comment to see the full error message
  locationName,
  // @ts-expect-error TS(7031) FIXME: Binding element 'sourceUrl' implicitly has an 'any... Remove this comment to see the full error message
  sourceUrl,
  // @ts-expect-error TS(7031) FIXME: Binding element 'subprofileID' implicitly has an '... Remove this comment to see the full error message
  subprofileID,
  // @ts-expect-error TS(7031) FIXME: Binding element 'subprofiles' implicitly has an 'a... Remove this comment to see the full error message
  subprofiles,
  // @ts-expect-error TS(7031) FIXME: Binding element 'dragging' implicitly has an 'any'... Remove this comment to see the full error message
  dragging,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isSent' implicitly has an 'any' t... Remove this comment to see the full error message
  isSent,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isPastReminder' implicitly has an... Remove this comment to see the full error message
  isPastReminder,
}) => {
  if (profileService === 'instagram' && locationName) {
    return (
      <InstagramPostMetaBar
        dragging={dragging}
        locationName={locationName}
        isSent={isSent}
        isPastReminder={isPastReminder}
      />
    )
  } else if (profileService === 'pinterest' && subprofileID) {
    /*  having a subprofileID is required, sourceUrl is not */
    const subprofile = subprofiles
      ? // @ts-expect-error TS(7006) FIXME: Parameter 'profile' implicitly has an 'any' type.
        subprofiles.find((profile) => profile.id === subprofileID)
      : null
    return (
      <PinterestPostMetaBar
        dragging={dragging}
        boardName={subprofile ? subprofile.name : null}
        boardAvatarUrl={subprofile ? subprofile.avatar : null}
        sourceUrl={sourceUrl}
        isSent={isSent}
      />
    )
  }
  return null
}

RenderPostMetaBar.propTypes = {
  profileService: PropTypes.string,
  locationName: PropTypes.string,
  sourceUrl: PropTypes.string,
  subprofileID: PropTypes.string,
  subprofiles: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
    }),
  ),
  dragging: PropTypes.bool,
  isSent: PropTypes.bool,
  isPastReminder: PropTypes.bool,
}

export default RenderPostMetaBar
