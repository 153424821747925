import React from 'react'
import { format } from 'date-fns'

import PercentageUpIcon from '@bufferapp/ui/Icon/Icons/PercentageUp'

import { SERVICE_STARTPAGE } from '~publish/legacy/constants'
import type { Image } from '~publish/legacy/composer/composer/entities/factories'
import { draftContentPropType } from '../../../Common/propTypes'
import type { StartPageContent } from '../../../types'
import { PostWrapper } from '../../../Common/styles'
import {
  getPreviewCharLimit,
  textExceedsCharLimit,
  truncateText,
  getMediaProperties,
} from '../../../Common/utils'

import * as Styles from './styles'

const maxLength = getPreviewCharLimit(SERVICE_STARTPAGE)
const getTextToDisplay = (text?: string): string | undefined => {
  let textToDisplay = text
  textToDisplay = truncateText(textToDisplay, maxLength, true)

  return textToDisplay
}

const ImagePreview = ({ images }: { images: Image[] }): JSX.Element => {
  const firstImage = images[0]
  return <Styles.Image data-testid="preview-images" src={firstImage.url} />
}

const StartPagePost = ({
  content,
}: {
  content: StartPageContent
}): JSX.Element => {
  const { text, images, gif, channelData } = content
  const currentDate = format(new Date(), 'LLL dd')
  const link = channelData?.startPage?.link
  const allImages = images?.length ? images : ([{ ...gif }] as Image[])
  const { hasImages } = getMediaProperties(content, null)

  return (
    <PostWrapper>
      <Styles.Body>
        <Styles.Content>
          <Styles.TextWrapper>
            <Styles.Date>{currentDate}</Styles.Date>
            {text && (
              <Styles.Text>
                {getTextToDisplay(text)}
                {textExceedsCharLimit(text, maxLength) && (
                  <Styles.SeeMore> Read more</Styles.SeeMore>
                )}
              </Styles.Text>
            )}
          </Styles.TextWrapper>
          {hasImages && <ImagePreview images={allImages} />}
        </Styles.Content>
        {link && (
          <Styles.Link href={link} target="_blank">
            Find out more <PercentageUpIcon size="smedium" />
          </Styles.Link>
        )}
      </Styles.Body>
    </PostWrapper>
  )
}

StartPagePost.propTypes = {
  content: draftContentPropType.isRequired,
}

export default StartPagePost
