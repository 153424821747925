import { connect } from 'react-redux'
import DateTimePreferences from './components/DateTimePreferences'
import actions from './actions'

// default export = container
export default connect(
  (state) => ({
    initialValues: {
      // @ts-expect-error TS(2339) FIXME: Property 'user' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
      format: state.user.hasTwentyFourHourTimeFormat ? '24' : '12',
      // @ts-expect-error TS(2339) FIXME: Property 'user' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
      dayToStartTheWeek: state.user.week_starts_monday ? 'Monday' : 'Sunday',
    },
  }),
  (dispatch) => ({
    // @ts-expect-error TS(7031) FIXME: Binding element 'format' implicitly has an 'any' t... Remove this comment to see the full error message
    changeTwentyFourHourFormat: ({ format }) =>
      dispatch(actions.changeTwentyFourHourFormat(Number(format) === 24)),
    // @ts-expect-error TS(7031) FIXME: Binding element 'dayToStartTheWeek' implicitly has... Remove this comment to see the full error message
    changeStartOfWeek: ({ dayToStartTheWeek }) =>
      dispatch(actions.changeStartOfWeek(dayToStartTheWeek === 'Monday')),
  }),
)(DateTimePreferences)

export { default as middleware } from './middleware'
export * from './actions'
