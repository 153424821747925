import styled, { css } from 'styled-components'
import { black, twitter, grayLight } from '@bufferapp/ui/style/colors'

import { LinkifiedText as LinkifiedTextCommon } from '~publish/legacy/shared-components/LinkifiedText'
import HeartOutlineIcon from '@bufferapp/ui/Icon/Icons/HeartOutline'
import CommentRoundOutlineIcon from '@bufferapp/ui/Icon/Icons/CommentRoundOutline'
import MessageOutlineIcon from '@bufferapp/ui/Icon/Icons/MessageOutline'
import BookmarkOutlineIcon from '@bufferapp/ui/Icon/Icons/BookmarkOutline'
import {
  previewBlack,
  previewBlue,
  previewBlueDark,
  previewGrayMedium,
} from '../../../Common/styles'

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 8px 16px;
`

export const UsernameWrapper = styled.div`
  display: flex;
  flex-direction column;
  align-items: flex-start;
  margin-left: 8px;
`

export const Username = styled.span`
  display: inline-block;
  color: ${previewBlack};
  max-width: 185px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 16px;
`

const DetailStyles = css`
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  color: ${previewBlack};
  font-weight: 400;
  max-width: 185px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const Location = styled.span`
  ${DetailStyles}
`

export const Music = styled.span`
  ${DetailStyles}
  margin-top: 4px;

  svg {
    height: 10px;
    width: 10px;
    margin-right: 4px;
  }

  animation: fadeInSlideDown 0.2s ease;

  @keyframes fadeInSlideDown {
    from {
      opacity: 0;
      transform: translateY(-4px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

export const Body = styled.div`
  margin: 0;
`

export const ActionsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 11px 12px;
  gap: 16px;
`

export const Action = styled.div`
  display: flex;
  align-items: center;
  line-height: 16px;

  svg {
    margin-right: 8px;
  }
`

export const Caption = styled.div`
  margin: 0 12px 16px 12px;

  a {
    color: ${previewBlue};
    &:hover {
      color: ${previewBlueDark} !important;
    }
  }
`

export const CaptionUsername = styled.span`
  color: ${black};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  margin-right: 4px;
`

export const SeeMore = styled.div`
  display: inline;
  color: ${previewGrayMedium};
  font-size: 14px;
  line-height: 17px;
`

export const EllipsesWrapper = styled.div<{ visible: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 132px;
  gap: 4px;
  ${({ visible }) => !visible && 'visibility: hidden;'}
  margin-left: 14px;
`

const EllipseCss = css`
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: ${grayLight};
`

export const EllipseSelected = styled.div`
  ${EllipseCss};
  background: ${twitter};
`

export const Ellipse = styled.div<{ size: string }>`
  ${EllipseCss};
  ${({ size }) => {
    if (size === 'small') {
      return `
        width: 4px;
        height: 4px;
      `
    }
    if (size === 'smaller') {
      return `
        width: 2px;
        height: 2px;
      `
    }
    if (size === 'medium') {
      return `
        width: 6px;
        height: 6px;
      `
    }
    return ''
  }};
`

export const CommentIcon = styled(CommentRoundOutlineIcon)`
  width: 23px;
  height: 23px;
`

export const HeartIcon = styled(HeartOutlineIcon)`
  width: 22px;
  height: 22px;
`

export const MessageIcon = styled(MessageOutlineIcon)`
  width: 20px;
  height: 20px;
`

export const BookmarkIcon = styled(BookmarkOutlineIcon)`
  width: 21px;
  height: 21px;
`

export const FirstComment = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${previewGrayMedium};
  margin-top: 4px;
`

export const LinkifiedText = styled(LinkifiedTextCommon)`
  color: ${previewBlack} !important;
  word-wrap: break-word;

  > a {
    cursor: default !important;
  }
`
