import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useQueryParam } from '~publish/hooks/useQueryParam'
import { useUploader } from '~publish/legacy/uploads/hooks/useUploader'
import { UploadSource } from '~publish/legacy/uploads/values/UploadSource'
import { IDEAS_UPLOADER_ID } from '../IdeaComposer'
import { IdeaComposer, type NewIdea } from '../IdeaComposer/IdeaComposer'
import { createPage } from '~publish/legacy/routes'
import { useAppSelector } from '~publish/legacy/store'
import { selectAllTags } from '~publish/legacy/campaign/slice'
import type { Tag } from '~publish/pages/Create/types'

/**
 * Component used to open IdeaComposer with preselected data.
 * Renders when corresponding route is matched.
 *
 * @param parent - If no returnTo location can be found in location state,
 * use the provided parent route to return to when dialog is closed.
 */
export function IdeaCreationDialog({
  parent = createPage.route,
}: {
  parent?: string
}): JSX.Element | null {
  const [open, setOpen] = React.useState(true)
  const history = useHistory()
  const location = useLocation<{ returnTo: Location }>()

  const [text = undefined] = useQueryParam('text')
  const [title = undefined] = useQueryParam('title')
  const [groupId] = useQueryParam('groupId')

  // We select tags for prefilling from both the underlying page's tag filters
  // and any tags passed in as query params
  // We handle these separately because sharing the same parameter name
  // causes the underlying page's tag filters to be overwritten
  const allTags: Tag[] = useAppSelector(selectAllTags)
  // Tags selected in the underlying page's tag filters
  const [tagFilterTagIds = []] = useQueryParam<string[]>('tagIds')
  const [prefillTagIds = []] = useQueryParam<string[]>('tags')

  // Tags provided as query params specifically to be preselected
  const mergedTagIds = [...new Set([...tagFilterTagIds, ...prefillTagIds])]

  // Map tags down to required properties to omit unnecessary data
  const prefillTags = allTags
    .filter((tag) => mergedTagIds.includes(tag.id))
    .map((tag) => ({
      id: tag.id,
      name: tag.name,
      color: tag.color,
    }))

  const initialIdea: NewIdea = {
    groupId,
    content: { title, text, tags: prefillTags },
  }

  // Preload any media passed in query params
  const [media = []] = useQueryParam<string[]>('media')
  const { uploader, onFileReady, removeUpload } = useUploader({
    id: IDEAS_UPLOADER_ID,
  })
  React.useEffect(() => {
    if (media) {
      uploader.addFilesFromUrls(
        media.map((url) => ({
          remoteUrl: url,
          useResizer: true,
        })),
        UploadSource.extension(),
      )
    }
  }, [media, uploader])

  const handleOpenChange = (open: boolean): void => {
    setOpen(open)
    if (!open) {
      history.push(location.state?.returnTo ?? parent)
    }
  }

  return (
    <IdeaComposer
      initialIdea={initialIdea}
      open={open}
      onOpenChange={handleOpenChange}
      onFileReady={onFileReady}
      removeUpload={removeUpload}
      uploader={uploader}
    />
  )
}
