import {
  createRangeRef,
  getAboveNode,
  getPointAfter,
  isCollapsed,
  isVoid,
  setSelection,
} from '@udecode/plate'
import { editorDebug } from '../editorDebug'

const debug = editorDebug.extend('withoutLosingSelection')

// @ts-expect-error TS(7006) FIXME: Parameter 'editor' implicitly has an 'any' type.
export const withoutLosingSelection = (editor, fn) => {
  const { selection } = editor
  const selectionRef = selection && createRangeRef(editor, selection)

  fn()

  if (selectionRef?.current) {
    let newSelection = { ...selectionRef.current }

    if (isCollapsed(newSelection)) {
      /**
       * Some operations leave the selection at the end of the text inside a void element and
       * this would prevent the user to keep typing because void elements are non editable.
       * The code below tries to set the selection in the point after that which should be
       * an empty text node (see https://docs.slatejs.org/concepts/11-normalizing)
       */
      const entry = getAboveNode(editor, {
        at: newSelection,
        voids: true,
      })
      const isAncestorVoid = !!entry && isVoid(editor, entry[0])
      const pointAfter = isAncestorVoid && getPointAfter(editor, newSelection)

      newSelection = pointAfter
        ? { anchor: pointAfter, focus: pointAfter }
        : newSelection
    }

    debug('resetting selection', newSelection)
    setSelection(editor, newSelection)

    selectionRef.unref()
  }
}
