import React, { useState } from 'react'
import clsx from 'clsx'
import { PlusIcon, UnstyledButton } from '@buffer-mono/popcorn'

import { IdeaBoardGroupLimitPaywall } from './IdeaBoardGroupLimitPaywall'
import { ColumnNameForm } from './ColumnNameForm'

import styles from './NewColumn.module.css'

const NewColumn = ({
  onCreate,
  limitReached = false,
}: {
  onCreate: (name: string) => void | Promise<void>
  limitReached?: boolean
}): JSX.Element => {
  const [isCreating, setIsCreating] = useState(false)

  const handleSubmit = async (name: string): Promise<void> => {
    if (name.trim() !== '') {
      await onCreate(name)
    }
    setIsCreating(false)
  }

  const handleCreateGroup = (): void => {
    if (limitReached) {
      return
    }
    setIsCreating(true)
  }

  const createButton = (
    <UnstyledButton className={styles.button} onClick={handleCreateGroup}>
      <PlusIcon /> New Group
    </UnstyledButton>
  )

  return (
    <section
      data-testid="ideas-column-create"
      className={clsx(styles.base, isCreating && styles.creating)}
    >
      {limitReached ? (
        <IdeaBoardGroupLimitPaywall
          cta={{ location: 'board', button: 'newColumn' }}
        >
          {createButton}
        </IdeaBoardGroupLimitPaywall>
      ) : (
        createButton
      )}

      {isCreating && (
        <ColumnNameForm
          onSubmit={handleSubmit}
          onCancel={(): void => setIsCreating(false)}
        />
      )}
    </section>
  )
}

NewColumn.displayName = 'NewColumn'

export { NewColumn }
