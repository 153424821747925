import React from 'react'
import { channelDataPropType } from '~publish/legacy/composer/composer/components/ComposerPropTypes'
import PostExtraDataTitle from './PostExtraDataTitle'

// @ts-expect-error TS(7031) FIXME: Binding element 'channelData' implicitly has an 'a... Remove this comment to see the full error message
const PostExtraData = ({ channelData }) => {
  const channelContainsExtraData =
    channelData?.googlebusiness || channelData?.youtube

  const getTitle = () => {
    if (channelData?.googlebusiness) {
      return channelData?.googlebusiness?.title
    }

    if (channelData?.youtube) {
      return channelData?.youtube?.title
    }

    return null
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  if (!channelContainsExtraData) return <></>

  return <PostExtraDataTitle title={getTitle()} />
}

PostExtraData.propTypes = {
  channelData: channelDataPropType,
}
PostExtraData.defaultProps = {
  channelData: null,
}
export default PostExtraData
