import React from 'react'
import { AlertDialog, Button } from '@buffer-mono/popcorn'

type DeleteIdeaGroupAlertDialogProps = {
  name: string
  ideasCount: number
  children: React.ReactNode
  onDelete: () => void
}

export function DeleteIdeaGroupAlertDialog({
  name,
  ideasCount,
  children,
  onDelete,
}: DeleteIdeaGroupAlertDialogProps): JSX.Element {
  return (
    <AlertDialog>
      <AlertDialog.Trigger>{children}</AlertDialog.Trigger>
      <AlertDialog.Content>
        <AlertDialog.Title>
          {ideasCount > 0
            ? `Delete "${name}" group and move ${ideasCount} ideas?`
            : `Delete "${name}" group?`}
        </AlertDialog.Title>
        <AlertDialog.Description>
          {ideasCount > 0
            ? `This group will be permanately deleted and ${ideasCount} ideas will
              be moved to "Unassigned". This action cannot be undone.`
            : `This group will be permanently deleted. This action cannot be
                undone.`}
        </AlertDialog.Description>
        <AlertDialog.Actions>
          <AlertDialog.Cancel>
            <Button variant="tertiary">Cancel</Button>
          </AlertDialog.Cancel>
          <AlertDialog.Action>
            <Button variant="critical" onClick={onDelete}>
              Delete
            </Button>
          </AlertDialog.Action>
        </AlertDialog.Actions>
      </AlertDialog.Content>
    </AlertDialog>
  )
}
