import {
  withoutSavingHistory,
  setNodes,
  withoutMergingHistory,
} from '@udecode/plate'
import type { BufferEditor } from '~publish/legacy/editor/BufferEditor/types.plate'
import { editorDebug } from '~publish/legacy/editor/editorDebug'
import { getAllLinkNodes } from '../../link/nodes/getAllLinkNodes'
import {
  type LinkedInEntityDetails,
  LinkedInAnnotationElement,
} from '../nodes/LinkedInAnnotationElement'

const debug = editorDebug.extend('plugins:linkedin-annotations')

export const insertLinkedInAnnotation = (
  editor: BufferEditor,
  annotation: LinkedInEntityDetails,
): void => {
  const nodesWithEntityUrl = getAllLinkNodes(editor, {
    reverse: true,
  }).filter(([n]) => n.url === annotation.link && !n.isAnnotationProcessed)

  Array.from(nodesWithEntityUrl).forEach(([node, path]) => {
    debug('wrapping LinkedIn annotation: %s at %o', node.url, path)

    withoutSavingHistory(editor, () => {
      setNodes(
        editor,
        { isAnnotationProcessed: true },
        {
          at: path,
          voids: true,
        },
      )
    })

    withoutMergingHistory(editor, () => {
      setNodes<LinkedInAnnotationElement>(
        editor,
        LinkedInAnnotationElement.new(annotation),
        {
          at: path,
          voids: true,
        },
      )
    })
  })
}
