import React from 'react'
import styled from 'styled-components'

import { Button, Tooltip } from '@bufferapp/ui'
import { getColorContrast } from '~publish/legacy/shared-components/ColorPicker/utils/HexValidations'
import { ConditionalWrapper } from '~publish/legacy/shared-components'

import truncateText from '../../utils/truncateText'

type TagButtonProps = {
  dragging: boolean
  color: string
  textColor: string
  isTag: boolean
}

const TagButton = styled(Button)`
  margin-left: 8px;
  margin-bottom: 8px;
  height: initial;
  opacity: ${(props: TagButtonProps): number => (props.dragging ? 0 : 1)};
  background-color: ${(props: TagButtonProps): string =>
    props.color} !important;
  border-color: ${(props: TagButtonProps): string => props.color} !important;
  :hover {
    background-color: ${(props: TagButtonProps): string =>
      props.color} !important;
    color: ${(props: TagButtonProps): string => props.textColor} !important;
    border-color: ${(props: TagButtonProps): string => props.color} !important;
    opacity: 0.9;
  }
  color: ${(props: TagButtonProps): string => props.textColor} !important;
  button {
    padding: 2px 8px;
    line-height: 1;
    height: unset;
  }
`

type PostTagProps = {
  isTag?: boolean
  name: string
  color: string
  dragging: boolean
  onPostTagClick: () => void
}

const PostTag = ({
  name,
  color,
  dragging,
  onPostTagClick,
  isTag = false,
}: PostTagProps): JSX.Element => {
  const wasTruncated = name !== truncateText(name, 25)
  return (
    <div>
      <ConditionalWrapper
        condition={wasTruncated}
        // @ts-expect-error TS(7006) FIXME: Parameter 'children' implicitly has an 'any' type.
        wrapper={(children) => (
          // @ts-expect-error TS(2322) FIXME: Type '{ children: any; label: string; position: st... Remove this comment to see the full error message
          <Tooltip label={name} position="top">
            {children}
          </Tooltip>
        )}
      >
        <TagButton
          size="small"
          type="primary"
          onClick={onPostTagClick}
          label={truncateText(name, 25)}
          dragging={dragging}
          color={color}
          textColor={getColorContrast(color)}
          isTag={isTag}
        />
      </ConditionalWrapper>
    </div>
  )
}

export default PostTag
