import styled from 'styled-components'
import { blue } from '@bufferapp/ui/style/colors'

export const Button = styled.button<{ disabled?: boolean; color?: string }>`
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.color || blue};
  background-color: transparent;
  border: none;
  user-select: none;
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  padding: 10px 0px 10px 12px;

  svg {
    color: ${(props) => props.color || blue};
    fill: ${(props) => props.color || blue};
    margin-right: 4px;
  }

  span {
    margin-left: 4px;
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    padding: 2px 4px;
    border-radius: 32px;
  }
`
