import React from 'react'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import PostTypeBar from '~publish/legacy/composer/composer/components/channel-bar/PostTypeBar'
import ComposerActionCreators from '~publish/legacy/composer/composer/action-creators/ComposerActionCreators'
import { draftPropType } from '~publish/legacy/composer/composer/components/ComposerPropTypes'

dayjs.extend(utc)
dayjs.extend(timezone)

// @ts-expect-error TS(7031) FIXME: Binding element 'draft' implicitly has an 'any' ty... Remove this comment to see the full error message
const GBPPostTypeBar = ({ draft }) => {
  if (!draft.service.isGoogleBusiness()) {
    return null
  }

  const onPostTypeChangeCallback = () => {
    const dataToUpdate = { ...draft.channelData.googlebusiness }
    if (draft.updateType === 'offer' || draft.updateType === 'event') {
      dataToUpdate.start_date =
        dataToUpdate.start_date ?? dayjs.utc().startOf('day').unix()
      dataToUpdate.end_date =
        dataToUpdate.end_date ?? dayjs.utc().add(7, 'day').startOf('day').unix()
    }

    ComposerActionCreators.updateDraftGoogleBusinessData(dataToUpdate)
  }

  return (
    <PostTypeBar
      postTypes={[
        { value: 'whats_new', name: "What's New" },
        { value: 'offer', name: 'Offer' },
        { value: 'event', name: 'Event' },
      ]}
      defaultPostType="whats_new"
      draft={draft}
      onPostTypeChangeCallback={onPostTypeChangeCallback}
      tooltip={
        <div>
          <p>
            <strong>
              <span role="img" aria-label="Explosion">
                💥
              </span>{' '}
              What&apos;s New
            </strong>
          </p>{' '}
          <p>Share news, information, and updates about your business.</p>
          <p>
            <strong>
              <span role="img" aria-label="Shopping Bags">
                🛍
              </span>{' '}
              Offer
            </strong>
          </p>
          <p>
            Use it for promotional sales or offers. A &quot;View offer&quot;
            action button is automatically added to the post.
          </p>{' '}
          <p>
            <strong>
              <span role="img" aria-label="Calendar">
                🗓
              </span>{' '}
              Event
            </strong>
          </p>
          <p>
            Promote an event. Events that meet certain criteria may also show on
            the &quot;Overview&quot; tab of your Business Profile so
            they&apos;re more visible to customers.
          </p>
        </div>
      }
    />
  )
}

GBPPostTypeBar.propTypes = {
  draft: draftPropType.isRequired,
}

export default GBPPostTypeBar
