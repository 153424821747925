import type { TComboboxItemWithData } from '@udecode/plate'
import type { EmojiSearchData } from './types'

export const emojisToComboboxItems = (
  emojis: EmojiSearchData[],
): Array<TComboboxItemWithData<EmojiSearchData>> => {
  return emojis.map((emoji) => ({
    key: emoji.id,
    text: emoji.id,
    data: emoji,
  }))
}
