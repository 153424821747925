import React from 'react'

import styles from './PostCard.module.css'

type PostCardSlabProps = {
  children?: React.ReactNode
}

export const PostCardSlab = ({ children }: PostCardSlabProps): JSX.Element => {
  return <div className={styles.slab}>{children}</div>
}

PostCardSlab.displayName = 'PostCardSlab'
