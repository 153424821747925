import styled from 'styled-components'
import { borderRadius } from '@bufferapp/ui/style/borders'
import {
  black,
  grayDark,
  grayDarker,
  grayLight,
  grayLighter,
  white,
} from '@bufferapp/ui/style/colors'
import { TextWithSkeleton } from '~publish/legacy/shared-components'
import { fontWeightMedium } from '@bufferapp/ui/style/fonts'
import { Link, Text } from '@bufferapp/ui'

export const Wrapper = styled.div`
  padding: 20px 56px 20px 56px;
  height: 100%;
  overflow-y: auto;
  background: ${grayLighter};
`

export const Header = styled.header`
  display: flex;
  align-items: center;
`

export const Main = styled.main`
  min-width: 800px;
  border-radius: ${borderRadius};
  border: 1px solid ${grayLight};
  background-color: ${white};
  margin-top: 10px;
`

export const TextWithSkeletonStyled = styled(TextWithSkeleton)`
  flex-grow: 1;
  margin: 20px 0 12px 0;
`

export const StyledTable = styled.div`
  box-shadow: 0px 0px 10px 0px rgba(48, 71, 89, 0.05);
`

export const StyledList = styled.ul`
  padding-left: 0px;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
`

export const ListHeader = styled.div`
  display: grid;
  padding: 16px 24px;
  grid-template-columns: minmax(250px, 2fr) 1fr 1fr 1fr 1fr 1fr 0.5fr;
  grid-column-gap: 20px;
  transition: background-color 250ms ease-in-out;
  :last-of-type {
    border-radius: 0 0 4px 4px;
  }
  font-size: 14px;
  font-weight: ${fontWeightMedium};
  line-height: 16px;
  color: ${grayDarker};
`

export const ListHeaderItem = styled.div`
  display: flex;
  width: 100%;
  font-weight: ${fontWeightMedium};
  color: ${grayDarker};
`

export const PaidPlanNoticeText = styled(Text)`
  margin: 0 0 0 8px;
`

export const FreeNotice = styled.div`
  color: ${grayDark};
  font-size: 14px;
  font-weight: ${fontWeightMedium};
  line-height: 20px;
  margin: 0 0 20px 2px;
`

export const EmptyStateWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const StyledImage = styled.img`
  width: 439px;
  margin: 72px auto 0 auto;
`

export const EmptyStateTextHeader = styled(Text)`
  margin: auto;
  color: ${black};
`

export const EmptyStateTextBody = styled(Text)`
  margin: auto;
  font-size: 14px;
  font-weight: ${fontWeightMedium};
  line-height: 20px;
  color: ${grayDark};
`

export const EmptyStateBody = styled.div`
  margin: 8px auto 24px auto;
  display: flex;
  text-align: center;
  width: 400px;
`

export const EmptyStateLink = styled(Link)`
  margin: auto;
`
