import React, { useState } from 'react'
import { Button } from '@bufferapp/ui'
import { DeleteButton } from './styles'

type ConfirmDeleteButtonProps = {
  isLoading?: boolean
  onDeleteClick: () => void
}

const ConfirmDeleteButton = ({
  isLoading,
  onDeleteClick,
}: ConfirmDeleteButtonProps): JSX.Element => {
  const [markedForDelete, setMarkedForDelete] = useState(false)

  return (
    <>
      {!markedForDelete ? (
        <DeleteButton
          type="text"
          label="Delete"
          size="small"
          disabled={isLoading}
          onClick={(): void => setMarkedForDelete(true)}
        />
      ) : (
        <>
          {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; size: string;... Remove this comment to see the full error message */}
          <Button
            type="text"
            label="Cancel"
            size="small"
            disabled={isLoading}
            onClick={(): void => setMarkedForDelete(false)}
          />
          <DeleteButton
            type="text"
            label="Confirm"
            size="small"
            disabled={isLoading}
            onClick={onDeleteClick}
          />
        </>
      )}
    </>
  )
}

ConfirmDeleteButton.defaultProps = {
  isLoading: false,
}

export default ConfirmDeleteButton
