import { getEditorString, getPointAfter, getRange } from '@udecode/plate'
import type { BufferEditor } from '~publish/legacy/editor/BufferEditor/types.plate'

export const isNextCharacterWhitespaceOrEnd = (
  editor: BufferEditor,
): boolean => {
  if (!editor.selection) return false

  const nextChar = getEditorString(
    editor,
    getRange(editor, editor.selection, getPointAfter(editor, editor.selection)),
  )

  return nextChar === '' || nextChar === ' '
}
