import type { RootState } from '~publish/legacy/store'
import { tagFormSlice } from './slice'
import type { LeanTag } from '~publish/legacy/campaign/types'

export const selectTagFormProperties = (
  state: RootState,
): { editMode: boolean; isFromSelector: boolean; isLoading: boolean } => {
  const { editMode, isFromSelector, isLoading } = state[tagFormSlice.name]
  return { editMode, isFromSelector, isLoading }
}

export const selectTagInfo = (state: RootState): LeanTag | null => {
  const { tag } = state[tagFormSlice.name]
  return tag
}
