import styled from 'styled-components'
import Text from '@bufferapp/ui/Text'

import { white, grayDark } from '@bufferapp/ui/style/colors'
import * as Dialog from '@radix-ui/react-dialog'
import CrossIcon from '@bufferapp/ui/Icon/Icons/Cross'

export const StyledOverlay = styled(Dialog.Overlay)`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgb(50, 59, 67, 0.8);
  opacity: 0.9;
  z-index: 4;
`

export const ModalWrapper = styled(Dialog.Content)`
  position: fixed;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
  width: 514px;
  height: 167px;
  background-color: ${white};
  border-radius: 4px;
`

export const InnerWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  padding: 0px 18px;
`

export const MediaContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
`

export const ButtonWrapper = styled.div`
  margin-bottom: 18px;
  display: flex;
  align-self: flex-end;
  align-items: center;
  width: 100%;
  justify-content: end;
  margin-top: auto;
  gap: 8px;
`

export const StyledText = styled(Text)`
  margin: 0;
`

export const CloseIcon = styled(CrossIcon)`
  position: absolute;
  fill: ${white};
  top: -2px;
  right: -24px;
  cursor: pointer;
`

export const CloseDialogButton = styled(Dialog.Close)`
  top: 15px;
  right: 15px;
  fill: ${grayDark};
`
