/* eslint-disable camelcase */
import type { Dispatch } from 'redux'
import { actionTypes as orgActionTypes } from '~publish/legacy/organizations'
import type { RpcUpdate } from '~publish/legacy/post/types'
import type { PublishingPost } from '~publish/gql/graphql'
import callRpc from '~publish/legacy/utils/call-rpc'

import type { ExtendedIdea } from '~publish/pages/Create/types'

export const actionTypes = {
  OPEN_COMPOSER: 'OPEN_COMPOSER',
  HIDE_COMPOSER: 'HIDE_COMPOSER',
  ADD_SPLIT: 'ADD_SPLIT',
} as const

export interface ComposerPopoverState {
  showComposer: boolean
  environment: string
  editMode?: boolean
  shareAgainMode?: boolean
  duplicatedFrom?: string | null
  emptySlotMode?: boolean
  hasDefaultTime?: boolean
  editingPostId?: string

  shouldResetComposerData?: boolean
  selectedProfileId?: string
  selectedProfileIds?: string[]
  post?: Partial<RpcUpdate | PublishingPost>
  splits: Record<string, boolean>
  withAIPanelOpen?: boolean
  draftMode?: boolean
  preSelectedTags?: string[]
  sentPost?: boolean
  idea?: ExtendedIdea
}

export interface ComposerPopoverAction extends Partial<ComposerPopoverState> {
  type: (typeof actionTypes)[keyof typeof actionTypes]
  updateId?: string
  profileId?: string
  isDiscarded?: boolean
  cta?: string
}

export type ComposerPopoverActionInput = Omit<ComposerPopoverAction, 'type'>

export const initialState: ComposerPopoverState = {
  showComposer: false,
  environment: 'production',
  editMode: false,
  shareAgainMode: false,
  duplicatedFrom: null,
  emptySlotMode: false,
  hasDefaultTime: false,
  editingPostId: '',
  shouldResetComposerData: false,
  selectedProfileId: undefined, // Provided when in the context of a single profile (e.g. queue)
  selectedProfileIds: [], // Provided when in the context of multiple profiles (e.g. calendar)
  post: undefined,
  splits: {},
  withAIPanelOpen: false,
  draftMode: false,
  preSelectedTags: [],
  sentPost: false,
  idea: undefined,
}

export default (
  state: ComposerPopoverState = initialState,
  action:
    | ComposerPopoverAction
    | { type: typeof orgActionTypes.ORGANIZATION_SELECTED },
): ComposerPopoverState => {
  switch (action.type) {
    case orgActionTypes.ORGANIZATION_SELECTED:
      return {
        ...state,
        shouldResetComposerData: true,
      }

    case actionTypes.OPEN_COMPOSER:
      return {
        ...state,
        showComposer: true,
        post: action.post,
        draftMode: action.draftMode,
        editMode: action.editMode,
        shareAgainMode: action.shareAgainMode,
        duplicatedFrom: action.duplicatedFrom,
        editingPostId: action.updateId,
        emptySlotMode: action.emptySlotMode,
        hasDefaultTime: action.hasDefaultTime,
        selectedProfileId: action.selectedProfileId,
        selectedProfileIds: action.selectedProfileIds,
        shouldResetComposerData: action.shouldResetComposerData,
        preSelectedTags: action.preSelectedTags,
        idea: action.idea,
      }
    case actionTypes.HIDE_COMPOSER:
      return {
        ...state,
        shouldResetComposerData: false,
        showComposer: false,
        editMode: false,
        emptySlotMode: false,
        hasDefaultTime: false,
        selectedProfileId: undefined,
        selectedProfileIds: [],
        post: undefined,
        draftMode: false,
        preSelectedTags: [],
        idea: undefined,
      }
    case actionTypes.ADD_SPLIT:
      return {
        ...state,
        splits: {
          ...state.splits,
          ...action.splits,
        },
      }
    default:
      return state
  }
}

export const actions = {
  openComposer: ({
    cta,
    post,
    editMode = false,
    shareAgainMode = false,
    duplicatedFrom = null,
    editingPostId,
    emptySlotMode = false,
    hasDefaultTime = false,
    selectedProfileId,
    selectedProfileIds = [],
    shouldResetComposerData = false,
    withAIPanelOpen = false,
    draftMode = false,
    preSelectedTags = [],
    idea = undefined,
  }: ComposerPopoverActionInput): ComposerPopoverAction => ({
    type: actionTypes.OPEN_COMPOSER,
    cta,
    post,
    editMode,
    shareAgainMode,
    duplicatedFrom,
    editingPostId,
    emptySlotMode,
    hasDefaultTime,
    selectedProfileId,
    selectedProfileIds,
    shouldResetComposerData,
    withAIPanelOpen,
    draftMode,
    preSelectedTags,
    idea,
  }),
  handleCreatePostClick: ({
    selectedProfileId,
    selectedProfileIds,
    cta,
    post,
    shouldResetComposerData,
    hasDefaultTime = false,
    withAIPanelOpen = false,
    idea = undefined,
  }: ComposerPopoverActionInput): ComposerPopoverAction =>
    actions.openComposer({
      selectedProfileId,
      selectedProfileIds,
      cta,
      post,
      shouldResetComposerData,
      hasDefaultTime,
      withAIPanelOpen,
      idea,
    }),

  handleEmptySlotClick: ({
    profileId,
    post,
    cta,
  }: ComposerPopoverActionInput): ComposerPopoverAction => ({
    type: actionTypes.OPEN_COMPOSER,
    emptySlotMode: true,
    post,
    selectedProfileId: profileId,
    profileId,
    cta,
  }),

  hideComposer: (isDiscarded = false): ComposerPopoverAction => ({
    type: actionTypes.HIDE_COMPOSER,
    isDiscarded,
  }),

  handleEditClick:
    (
      postId: string,
      options?: {
        cta?: string
        withAIPanelOpen?: boolean
        draftMode?: boolean
      },
    ) =>
    async (dispatch: Dispatch): Promise<void> => {
      const post = await callRpc('getPost', { updateId: postId })

      dispatch({
        type: actionTypes.OPEN_COMPOSER,
        selectedProfileId: post.profileId,
        post,
        updateId: post.id,
        editMode: true,
        cta: options?.cta,
        withAIPanelOpen: options?.withAIPanelOpen,
        draftMode: options?.draftMode,
      })
    },

  handleShareAgainClick:
    (postId: string) =>
    async (dispatch: Dispatch): Promise<void> => {
      const post = await callRpc('getPost', { updateId: postId })

      dispatch({
        type: actionTypes.OPEN_COMPOSER,
        updateId: postId,
        selectedProfileId: post.profileId,
        shouldResetComposerData: true,
        shareAgainMode: true,
        duplicatedFrom: null,
        sentPost: true,
        editMode: false,
        post: {
          ...post,
          // override some properties
          scheduled_at: undefined,
          scheduledAt: undefined,
          pinned: undefined,
          due_at: undefined,
        },
      })
    },

  handleShareLinkClick:
    (post: RpcUpdate | PublishingPost, selectedProfileIds: string[]) =>
    async (dispatch: Dispatch): Promise<void> => {
      dispatch({
        type: actionTypes.OPEN_COMPOSER,
        selectedProfileIds,
        post: {
          text: `${post.serviceLink}`,
        },
      })
    },

  handleOpenComposerFromQueryParams: ({
    selectedProfileIds,
    cta,
    post,
    shouldResetComposerData,
    hasDefaultTime = false,
  }: ComposerPopoverActionInput): ComposerPopoverAction => {
    return actions.openComposer({
      selectedProfileIds,
      cta,
      post,
      shouldResetComposerData,
      hasDefaultTime,
    })
  },

  addSplit: (splits: Record<string, boolean>): ComposerPopoverAction => ({
    type: actionTypes.ADD_SPLIT,
    splits,
  }),
}
