/* eslint-disable camelcase */
import React, { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import { Warning } from '@bufferapp/ui/Icon'
import { redDark } from '@bufferapp/ui/style/colors'
import { Tooltip } from '@buffer-mono/popcorn'

import { usePostPermissions } from '~publish/legacy/post/hooks'
import { ChannelIcon } from '~publish/legacy/shared-components'
import { isDraft as checkIsDraft } from '~publish/pages/Calendar/util/isDraft'
import { PostMediaAsset } from '~publish/components/PostCard/PostMedia/PostMediaAsset'
import { MediaThumbnailWrapper } from '../../../MediaThumbnail/styles'

import type { CalendarPostCard_PostFragment } from '~publish/gql/graphql'
import { RemindersIcon } from '~publish/legacy/reminders/components/RemindersIcon'
import {
  ApprovalTag,
  DraftTag,
  EndContent,
  Error,
  ErrorCard,
  MiddleContent,
  PostContentWrapper,
  PostText,
  StartContent,
  TagContainer,
  Time,
  RemindersWrapper,
} from './styles'
import { Temporal } from '@js-temporal/polyfill'
import { useTimezone } from '~publish/hooks/useTimezone'
import { useTwentyFourHourTimeFormat } from '~publish/pages/Calendar/hooks/useTwentyFourHourTimeFormat'
import { format } from '~publish/helpers/temporal'

const PostContent = ({
  small,
  post,
}: {
  small: boolean
  post: CalendarPostCard_PostFragment
}): JSX.Element => {
  const { t } = useTranslation()
  const [timeZone] = useTimezone()
  const is24Hour = useTwentyFourHourTimeFormat()
  const {
    status,
    text,
    schedulingType,
    channelService,
    dueAt,
    metadata,
    assets: passedAssets,
  } = post
  const needsApproval = post.status === 'needs_approval'
  const isReminder = schedulingType === 'notification'
  let googleBusinessTitle: string | null | undefined = null
  if (channelService === 'googlebusiness' && metadata && 'title' in metadata) {
    googleBusinessTitle = metadata.title
  }
  const formattedTime = useMemo(() => {
    if (!dueAt) return ''
    const zonedDueAt = Temporal.Instant.from(dueAt).toZonedDateTimeISO(timeZone)

    return format(zonedDueAt, is24Hour ? 'HH:mm' : 'h:mm a')
  }, [dueAt, is24Hour, timeZone])
  const { isManager } = usePostPermissions(post)
  const hasError = status === 'error'
  const isDraft = checkIsDraft(status)
  const showMediaPreview = !small || !hasError

  const renderDraftTag = isDraft && !needsApproval
  const renderApprovalTag = isDraft && needsApproval
  const renderTime = small && !isDraft
  const renderSmallError = hasError && small
  const renderError = hasError && !small

  return (
    <PostContentWrapper>
      {/* Social Channel and Reminder Icon */}
      <StartContent small={small}>
        <ChannelIcon
          network={channelService}
          circular
          aria-label={`${channelService} post`}
        />
      </StartContent>
      {/* Tags, Time, Post Text */}
      <MiddleContent small={small}>
        {renderDraftTag && (
          <TagContainer>
            <DraftTag>Draft</DraftTag>
          </TagContainer>
        )}
        {renderApprovalTag && (
          <TagContainer>
            <ApprovalTag>
              {isManager ? 'Awaiting Approval' : 'Pending Approval'}
            </ApprovalTag>
          </TagContainer>
        )}
        {renderTime && <Time type="p">{formattedTime}</Time>}

        {/* GBP Post Title */}
        {!small && googleBusinessTitle && (
          <PostText isDraft={isDraft}>{googleBusinessTitle}</PostText>
        )}

        {/* Post Text */}
        {/* Do not show text when there is a GBP Post Title */}
        {!small && !googleBusinessTitle && (
          <PostText isDraft={isDraft}>{text}</PostText>
        )}
        {/* Show reminder icon here if small item */}
      </MiddleContent>

      {/* Media Thumbnail */}
      {showMediaPreview && passedAssets?.length > 0 && (
        <EndContent>
          <MediaThumbnailWrapper size="small">
            <PostMediaAsset asset={passedAssets[0]} />
          </MediaThumbnailWrapper>
        </EndContent>
      )}
      {isReminder && (
        <EndContent>
          <Tooltip content={t('remindersGeneral.tooltip')}>
            <RemindersWrapper small={small}>
              <RemindersIcon />
            </RemindersWrapper>
          </Tooltip>
        </EndContent>
      )}
      {/* Error flags */}
      {renderError && (
        <Error>
          <Warning color={redDark} />
        </Error>
      )}
      {renderSmallError && (
        <EndContent>
          <ErrorCard>
            <Warning color="white" />
          </ErrorCard>
        </EndContent>
      )}
    </PostContentWrapper>
  )
}

export default PostContent
