import React from 'react'
import { Empty } from '../Common/Empty'
import {
  draftPreviewPropType,
  profilePreviewPropType,
} from '../Common/propTypes'
import type { GoogleBusinessDraft, PostType, ProfilePreview } from '../types'
import * as Styles from '../Common/styles'
import { GoogleBusinessPost } from './components/Post'

export const GoogleBusinessPreview = ({
  draft,
  profile,
}: {
  draft: GoogleBusinessDraft
  profile: ProfilePreview
}): JSX.Element => {
  const containerRef = React.useRef<HTMLDivElement | null>(null)
  const { updateType } = draft

  if (
    (updateType === 'whats_new' && draft.isEmpty) ||
    (updateType !== 'whats_new' && !draft?.channelData?.googlebusiness?.title)
  )
    return <Empty />

  const draftGoogleBusiness = {
    content: {
      ...draft,
      text: draft.text,
    },
  } as PostType

  return (
    <Styles.Wrapper data-testid="googlebusiness-preview" ref={containerRef}>
      <GoogleBusinessPost
        content={draftGoogleBusiness.content}
        profile={profile}
      />
    </Styles.Wrapper>
  )
}

GoogleBusinessPreview.propTypes = {
  draft: draftPreviewPropType.isRequired,
  profile: profilePreviewPropType.isRequired,
}
