import { Button } from '@bufferapp/ui'
import { grayDarker } from '@bufferapp/ui/style/colors'
import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  padding: 20px;
`

export const Title = styled.span`
  /* H4 Heading */
  font-size: 16px;
  font-weight: 700;
  line-height: 24px; /* 150% */
  color: ${grayDarker};
`

export const MigrationButton = styled(Button)`
  width: fit-content;
  align-self: center;
`
