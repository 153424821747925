import keyWrapper from '~publish/helpers/keywrapper'

export const actionTypes = keyWrapper('thirdparty', {
  BUGSNAG: 0,
})

const initialState = {}

export default (state = initialState) => {
  return state
}

export const actions = {}
