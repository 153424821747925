import React from 'react'

import { ApprovalsIcon, DraftIcon } from '@buffer-mono/popcorn'

import { usePostData } from './PostCardContext'
import { PostCardSlab } from './PostCardSlab'

export function PostCardSlabs(): JSX.Element {
  const { status, allowedActions } = usePostData()

  if (status === 'needs_approval') {
    return (
      <PostCardSlab>
        <ApprovalsIcon />
        Approval Pending
      </PostCardSlab>
    )
  }

  if (status === 'draft' && allowedActions.includes('requestPostApproval')) {
    return (
      <PostCardSlab>
        <ApprovalsIcon />
        Needs Approval
      </PostCardSlab>
    )
  }

  if (status === 'draft' && !allowedActions.includes('requestPostApproval')) {
    return (
      <PostCardSlab>
        <DraftIcon />
        Draft
      </PostCardSlab>
    )
  }

  return <></>
}
