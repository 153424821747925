import { Button, Notice } from '@bufferapp/ui'
import Input from '@bufferapp/ui/Input'
import { fontFamily } from '@bufferapp/ui/style/fonts'

import CrossIcon from '@bufferapp/ui/Icon/Icons/Cross'
import ChevronDownIcon from '@bufferapp/ui/Icon/Icons/ChevronDown'

import {
  black,
  gray,
  grayDark,
  grayDarker,
  grayLight,
  white,
} from '@bufferapp/ui/style/colors'

import Masonry from 'react-masonry-css'
import Text from '@bufferapp/ui/Text'
import styled, { css } from 'styled-components'

import * as DropdownMenu from '@radix-ui/react-dropdown-menu'

export const SearchButtonContainer = styled.form`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${gray};
`

export const IntegrationsFormInnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 90%;
  max-width: 1050px;
  height: 90%;
  max-height: 660px;
  background-color: ${white};
  border-radius: 8px;
  position: relative;
`

export const MasonryContainer = styled.div`
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  margin-top: 16px;
  padding-right: 5px;
`

export const MasonryUnsplashStyled = styled(Masonry)`
  display: flex;
  width: auto;
  margin-left: -16px;
  .unsplah-masonry-column {
    padding-left: 16px;
    background-clip: padding-box;

    .gallery-item {
      position: relative;
      border-radius: 4px;
      margin-bottom: 16px;
      font-size: 0;

      &:hover {
        .unsplash-attribution {
          .unsplash-link {
            opacity: 1;
          }
        }
      }

      img {
        width: 100%;
        display: block;
        transition: all 0.8s;
        cursor: pointer;
        border-radius: 4px;
        pointer-events: all;
        &:hover {
          opacity: 0.6;
        }
      }
      .unsplash-attribution {
        margin-top: 8px;
        .unsplash-author,
        .unsplash-link {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: ${grayDark};
          font-family: ${fontFamily};
          font-size: 12px;
          a {
            color: ${grayDark};
            cursor: pointer;
          }
        }
        .unsplash-link {
          transition: all 0.8s;
          opacity: 0;
        }
      }
    }
  }
`

export const InvisibleButton = styled.button`
  background-color: ${black};
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  border-radius: 4px;
  width: 100%;
`

export const SpanWithMarginLeft = styled.span`
  margin-left: 9px;
`

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`

export const ButtonWihMarginLeft = styled(Button)`
  margin-left: 8px;
`

export const InputSearchWrapper = styled.div`
  width: 100%;
  position: relative;
`

export const InputSearch = styled(Input)`
  margin: 0;
  font-weight: 500;

  &::placeholder {
    color: ${gray};
  }
`

export const StyledNotice = styled(Notice)`
  border-radius: 8px;
  width: calc(100% - 18px);
  position: absolute;
  left: 0;
  top: -8px;
  transform: translateY(-100%);
`

export const NoResultsLayout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 16px;
`

export const TextWithoutMarginTop = styled(Text)`
  margin-top: 0;
  margin-bottom: 9px;
`

export const FooterForm = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 12px 0;
`

export const CleanIcon = styled(CrossIcon)`
  cursor: pointer;
  fill: ${grayDarker};
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
`

export const DropdownButton = styled(DropdownMenu.Trigger)`
  background: #ffffff;
  border: none;
  text-align: left;
  width: 200px;
  display: flex;
  align-items: center;
  padding: 0;
  margin-top: 8px;

  &:hover,
  &[data-state='open'] {
    span {
      color: ${grayDarker};
    }
    svg {
      fill: ${grayDarker};
    }
  }
`

export const StyledDropdown = styled(DropdownMenu.Content)`
  width: 200px;
  padding: 8px;
  background: #ffffff;
  border: 1px solid ${gray};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  z-index: 5000;
`

export const StyledListItem = styled(DropdownMenu.Item)<{ selected: boolean }>`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  padding: 8px;
  letter-spacing: -0.2px;
  color: ${grayDarker};
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  transition: background-color 0.5s ease;
  svg {
    margin-right: 8px;
    visibility: hidden;
  }

  &:hover {
    background-color: ${grayLight};
    border-radius: 4px;
  }

  ${(props) =>
    props.selected &&
    css`
      svg {
        visibility: visible;
      }
    `}
}
`

export const StyledLabel = styled.span`
  color: ${grayDark};
  cursor: pointer;
  font-family: ${fontFamily};
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: -0.25px;
  margin-right: 4px;
`

export const ChevronDownIconStyled = styled(ChevronDownIcon)`
  cursor: pointer;
  fill: ${grayDark};
  padding-top: 2px;
`
