import React, { useState, useEffect } from 'react'

import { LoaderAnimation } from './LoaderAnimation'

import * as Styled from './Loader.styles'

function getDefaultRandomMessage(): string {
  const messageOptions = [
    'Faxing content wizards',
    'Crafting your content',
    'Unleashing AI',
    'Your post is taking shape',
  ]
  const randomIndex = Math.floor(Math.random() * messageOptions.length)
  const randomMessage = messageOptions[randomIndex]
  return randomMessage
}

function Loader(): JSX.Element {
  const [loadingMessage, setLoadingMessage] = useState(
    getDefaultRandomMessage(),
  )

  useEffect(() => {
    const timeout = setTimeout(
      () => setLoadingMessage('Still working...'),
      15_000,
    )

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  return (
    <Styled.Wrapper data-testid="ai-assistant-loading-animation">
      <LoaderAnimation />
      <Styled.Message data-testid="ai-assistant-loader-message">
        {loadingMessage}
      </Styled.Message>
    </Styled.Wrapper>
  )
}

export { Loader }
