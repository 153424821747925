import {
  createEntityAdapter,
  createSlice,
  type PayloadAction,
  nanoid,
  type Slice,
  type SliceCaseReducers,
  type EntityState,
  type AnyAction,
} from '@reduxjs/toolkit'
import type { RootState } from '~publish/legacy/store'

type Notification = {
  id: string
  message: string
  duration: number
}

const notificationsAdapter = createEntityAdapter<Notification>()

// Radix UI Toast has no way to leave the notification permanent
// so we make the duration last one day
const DAY_DURATION = 1440000
const DEFAULT_DURATION = 3000

const POST_ACTIONS = ['saved-drafts']

const shouldShowUpdateNotification = (action: AnyAction): boolean =>
  POST_ACTIONS.includes(action.eventType) && action.data.fromIdeas

export const notificationsSlice: Slice<
  EntityState<Notification>,
  SliceCaseReducers<EntityState<Notification>>,
  'notifications-new'
> = createSlice({
  name: 'notifications-new',
  initialState: notificationsAdapter.getInitialState(),
  reducers: {
    addNotification: (state, action: PayloadAction<string>) => {
      const notification = {
        id: nanoid(),
        message: action.payload,
        duration: DEFAULT_DURATION,
      }
      notificationsAdapter.addOne(state, notification)
    },
    addPermanentNotification: (state, action: PayloadAction<string>) => {
      const notification = {
        id: nanoid(),
        message: action.payload,
        duration: DAY_DURATION,
      }
      notificationsAdapter.addOne(state, notification)
    },
    removeNotification: notificationsAdapter.removeOne,
  },
  extraReducers: (builder) => {
    builder.addMatcher(shouldShowUpdateNotification, (state, action) => {
      const notification = {
        id: nanoid(),
        message: `✅ ${action.data.message}`,
        duration: DEFAULT_DURATION,
      }
      notificationsAdapter.addOne(state, notification)
    })
  },
})

export const { addNotification, removeNotification, addPermanentNotification } =
  notificationsSlice.actions

export const { selectAll: selectAllNotifications } =
  notificationsAdapter.getSelectors(
    (state: RootState) => state[notificationsSlice.name],
  )
