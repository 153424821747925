import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import * as Styles from './styles'

import { VideoPlayer } from '../../../Common/VideoPlayer'

const StyledVideoPlayer = styled(VideoPlayer)`
  ${Styles.Wrapper}
`

// @ts-expect-error TS(7031) FIXME: Binding element 'video' implicitly has an 'any' ty... Remove this comment to see the full error message
const AttachmentVideo = ({ video }) => <StyledVideoPlayer video={video} />

AttachmentVideo.propTypes = {
  video: PropTypes.shape({
    thumbnail: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
}

export { AttachmentVideo }
