import React from 'react'

import { draftPreviewPropType } from '../Common/propTypes'
import { Empty } from '../Common/Empty'
import type { PostType, DraftPreview } from '../types'
import { StartPagePost } from './components/Post'

import * as Styles from '../Common/styles'

export const StartPagesPreview = ({ draft }: { draft: DraftPreview }) => {
  const containerRef = React.useRef<HTMLDivElement | null>(null)

  if (draft.isEmpty) return <Empty />

  const draftStartPage = {
    content: {
      ...draft,
      text: draft.text,
    },
  } as PostType

  return (
    <Styles.Wrapper data-testid="startpages-preview" ref={containerRef}>
      <StartPagePost content={draftStartPage.content} />
    </Styles.Wrapper>
  )
}

StartPagesPreview.propTypes = {
  draft: draftPreviewPropType.isRequired,
}
