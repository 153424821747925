import React from 'react'
import { getCurrentTimeZone } from '~publish/helpers/dateFormatters'

type TimeZoneContextType = {
  timeZone: string
  setTimeZone: (timeZone: string) => void
}

const TimeZoneContext = React.createContext<TimeZoneContextType>({
  timeZone: getCurrentTimeZone(),
  setTimeZone: () => {
    /** noop */
  },
})

export const useTimezone = (): [string, (timeZone: string) => void] => {
  const { timeZone, setTimeZone } = React.useContext(TimeZoneContext)
  return [timeZone, setTimeZone]
}

export const TimezoneProvider: React.FC<{
  children: React.ReactNode
  timeZone?: string
}> = ({ children, timeZone: initialTimeZone = getCurrentTimeZone() }) => {
  const [timeZone, setTimeZone] = React.useState(initialTimeZone)

  const value = React.useMemo(
    () => ({
      timeZone,
      setTimeZone,
    }),
    [timeZone],
  )

  return (
    <TimeZoneContext.Provider value={value}>
      {children}
    </TimeZoneContext.Provider>
  )
}
