import React from 'react'
import {
  STATISTICS_BY_CHANNEL,
  STATISTICS_BY_CHANNEL_ANALYTICS_V2,
  CHANNELS_TO_FILTER_NULLABLE_STATS,
} from './consts'
import { StatLabel, StatValue, StatsWrapper, StatWrapper } from './style'
import { useHasEntitlement } from '~publish/legacy/accountContext'
import { useSplitEnabled } from '@buffer-mono/features'
import type { PostStatistics } from '~publish/gql/graphql'

type StatLabel = {
  singularLabel: string
  pluralLabel: string
}

type StatProps = {
  statLabel: StatLabel
  value: number | string
}

const Stat = ({ statLabel, value }: StatProps): JSX.Element => {
  const isSingularValue = value === 1
  const parsedLabel = isSingularValue
    ? statLabel.singularLabel
    : statLabel.pluralLabel

  return (
    <StatWrapper>
      <StatLabel style={{ color: 'shuttleGray' }}>{parsedLabel}</StatLabel>
      <StatValue style={{ fontWeight: 'bold', color: 'black' }}>
        {value || 0}
      </StatValue>
    </StatWrapper>
  )
}
type AnalyticsProps = {
  statistics: PostStatistics
  service: string
}
const Analytics = ({ statistics, service }: AnalyticsProps): JSX.Element => {
  const isEntitledToAnalytics = useHasEntitlement('analytics')
  // We display different metrics for customers with analytics entitlement than customers on a the Free plan (freemium)
  const { isEnabled: isPinterestV2Enabled } = useSplitEnabled(
    'pinterest-sent-posts-analytics',
  )

  const analyticsV2ServiceSuffix = isEntitledToAnalytics
    ? 'analytics'
    : 'freemium'
  const statisticsFromService =
    service === 'twitter' ||
    service === 'linkedin' ||
    service === 'tiktok' ||
    service === 'facebook' ||
    service === 'threads' ||
    (service === 'pinterest' && isPinterestV2Enabled)
      ? STATISTICS_BY_CHANNEL_ANALYTICS_V2[
          `${service}_${analyticsV2ServiceSuffix}`
        ]
      : STATISTICS_BY_CHANNEL[service]

  const shouldDisplayStat = (key: keyof PostStatistics): boolean => {
    // We hide or show some service stats based on data returned from the API.
    const disallowedNullFields = CHANNELS_TO_FILTER_NULLABLE_STATS[service]
    if (disallowedNullFields && disallowedNullFields.includes(key)) {
      return statistics[key] !== null && statistics[key] !== undefined
    }
    return true
  }

  if (!statisticsFromService) {
    return <></>
  }

  const statKeys = Object.keys(
    statisticsFromService,
  ) as (keyof PostStatistics)[]
  const definedStats = statKeys.filter((key) => shouldDisplayStat(key))

  return (
    <StatsWrapper>
      {definedStats.map((stat) => {
        return (
          <Stat
            statLabel={statisticsFromService[stat]}
            value={statistics[stat] || 0}
            key={stat}
          />
        )
      })}
    </StatsWrapper>
  )
}

export default Analytics
