import React from 'react'
// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'redu... Remove this comment to see the full error message
import { Field, reduxForm } from 'redux-form'
import { Text } from '@bufferapp/ui'
import { Row } from '~publish/legacy/shared-components'
import { useTranslation } from 'react-i18next'

const TimeFormatPreferences = () => {
  const { t } = useTranslation()

  return (
    <Row>
      <Text type="h3">{t('preferences.general.timeFormat')}</Text>
      <form>
        <Field
          component="input"
          type="radio"
          id="twelveHourFormat"
          name="format"
          value="12"
        />
        <label htmlFor="twelveHourFormat" style={{ marginRight: '.5rem' }}>
          <Text type="label">
            12
            {t('preferences.general.hour')}
          </Text>
        </label>
        <Field
          component="input"
          type="radio"
          id="twentyFourHourFormat"
          name="format"
          value="24"
        />
        <label htmlFor="twentyFourHourFormat">
          <Text type="label">
            24
            {t('preferences.general.hour')}
          </Text>
        </label>
      </form>
    </Row>
  )
}

export default reduxForm({
  form: 'time-format-preferences',
})(TimeFormatPreferences)
