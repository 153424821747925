type Bytes = number

export type UploadProgress = {
  bytesTotal: Bytes
  bytesUploaded: Bytes
}

const Bytes = (size: unknown): Bytes =>
  typeof size === 'number' && Number.isFinite(size) && size > 0
    ? Math.round(size) // avoid floats
    : 0

export const UploadProgress = {
  new(size: number | unknown, uploaded: number | unknown = 0): UploadProgress {
    return {
      bytesTotal: Bytes(size),
      bytesUploaded: Bytes(uploaded),
    }
  },

  getPercentage(progress: UploadProgress | UploadProgress[]): number {
    const { bytesTotal, bytesUploaded } = Array.isArray(progress)
      ? UploadProgress.reduceProgress(progress)
      : progress

    if (!bytesTotal) return 0

    return progress ? Math.floor((bytesUploaded / bytesTotal) * 100) : 0
  },

  reduceProgress(items: UploadProgress[]): UploadProgress {
    return items
      .filter((item) => !!item.bytesTotal)
      .reduce((acc: UploadProgress, item) => {
        acc.bytesTotal += item.bytesTotal
        acc.bytesUploaded += item.bytesUploaded
        return acc
      }, UploadProgress.new(0))
  },
}
