import MagicWandIcon from '@bufferapp/ui/Icon/Icons/MagicWand'
import RephraseIcon from '@bufferapp/ui/Icon/Icons/TextRephrase'
import SubtractIcon from '@bufferapp/ui/Icon/Icons/Subtract'
import AddIcon from '@bufferapp/ui/Icon/Icons/Add'
import ShirtIcon from '@bufferapp/ui/Icon/Icons/Shirt'
import TShirtIcon from '@bufferapp/ui/Icon/Icons/TShirt'

const ACTIONS = {
  generate: {
    id: 'generate',
    label: 'Generate',
    mutation: 'generateText',
    IconComponent: MagicWandIcon,
  },
  expand: {
    id: 'expand',
    label: 'Expand',
    mutation: 'expandText',
    IconComponent: AddIcon,
  },
  shorten: {
    id: 'shorten',
    label: 'Shorten',
    mutation: 'summarizeText',
    IconComponent: SubtractIcon,
  },
  rephrase: {
    id: 'rephrase',
    label: 'Rephrase',
    mutation: 'rephraseText',
    IconComponent: RephraseIcon,
  },
}

const TONES = {
  casual: {
    id: 'casual',
    label: 'More Casual',
    IconComponent: TShirtIcon,
  },
  formal: {
    id: 'formal',
    label: 'More Formal',
    IconComponent: ShirtIcon,
  },
}

const MODULES = {
  AIWrite: {
    id: 'AIWrite',
    component: 'AIWrite',
    name: 'Write',
  },
  AIRefine: {
    id: 'AIRefine',
    component: 'AIRefine',
    name: 'Refine',
  },
}

export { ACTIONS, MODULES, TONES }
