export const actionTypes = {
  POST_CREATED: 'QUEUE__POST_CREATED',
  POST_UPDATED: 'QUEUE__POST_UPDATED',
  POST_DELETED: 'QUEUE__POST_DELETED',
  POST_SENT: 'QUEUE__POST_SENT',
  POST_CONFIRMED_DELETE: 'QUEUE__POST_CONFIRMED_DELETE',
  POST_ERROR: 'QUEUE__POST_ERROR',
  POST_SHARE_NOW: 'QUEUE__POST_SHARE_NOW',
  POST_DROPPED: 'QUEUE__POST_DROPPED',
  POSTS_SWAPPED: 'QUEUE__POSTS_SWAPPED',
  REORDERED_UPDATES: 'QUEUE__REORDERED_UPDATES',
  POST_REQUEUE: 'QUEUE__POST_REQUEUE',
  VIEW_CAMPAIGN_PAGE: 'QUEUE__VIEW_CAMPAIGN_PAGE',
  PROFILE_CAN_QUEUE_THREADS_CHANGED: 'QUEUE__PROFILE_CAN_QUEUE_THREADS_CHANGED',
  SET_COMPOSER_TYPE: 'QUEUE__SET_COMPOSER_TYPE',
}
