import type { Location } from 'history'
import type { match } from 'react-router-dom'

import {
  channel,
  channelSettings,
  profileChildTabPages,
  profileTabPages,
} from '~publish/legacy/routes'

export function getProfileSettingsLocationFromChannel({
  location,
  match,
}: {
  location: Location<unknown>
  match: match<{ [x: string]: string | undefined }>
}): Location {
  const channelId = match.params.id
  const tabQuery = new URLSearchParams(location.search).get('tab')

  const childTabId =
    tabQuery === 'posting-schedule' ? 'postingSchedule' : undefined

  // new search without tab param
  const searchParams = new URLSearchParams(location.search)
  searchParams.delete('tab')

  const search = searchParams.toString()

  let pathname = '/profile'

  // We don't know the channel so redirect to "default" profile pages
  if (channelId && childTabId) {
    pathname = profileChildTabPages.getRoute({
      profileId: channelId,
      childTabId,
      tabId: 'settings',
    })
  } else if (channelId) {
    pathname = profileTabPages.getRoute({
      profileId: channelId,
      tabId: 'settings',
    })
  }

  return { ...location, search, pathname }
}

export function getProfileLocationFromChannel({
  location,
  match,
}: {
  location: Location<unknown>
  match: match<{ [x: string]: string | undefined }>
}): Location {
  const channelId = match.params.id
  const tabQuery = new URLSearchParams(location.search).get('tab') ?? 'queue'
  let pathname = '/profile'

  const tabId = tabQuery === 'sent' ? 'sentPosts' : tabQuery
  const searchParams = new URLSearchParams(location.search)
  searchParams.delete('tab')

  const search = searchParams.toString()
  // We don't know the channel so redirect to "default" profile pages
  if (channelId && tabId) {
    pathname = profileTabPages.getRoute({
      profileId: channelId,
      tabId,
    })
  }

  return { ...location, pathname, search }
}

export function getChannelLocationFromProfile({
  location,
  match,
}: {
  location: Location<unknown>
  match: match<{ [x: string]: string | undefined }>
}): Location {
  const { profileId, tabId, childTabId = 'general' } = match.params
  const searchParams = new URLSearchParams(location.search)
  let pathname = '/channels'
  if (profileId) {
    pathname = channel.getRoute(profileId)
  }

  if (profileId && tabId === 'settings') {
    pathname = channelSettings.getRoute({ channelId: profileId })

    searchParams.set(
      'tab',
      childTabId === 'postingSchedule' ? 'posting-schedule' : 'general',
    )
  } else if (profileId && tabId) {
    searchParams.set('tab', tabId)
  }

  const search = searchParams.toString()

  return { ...location, search, pathname }
}
