type MetricWithLabel = {
  [key: string]: {
    singularLabel: string
    pluralLabel: string
  }
}

type StatisticsByChannel = {
  [key: string]: MetricWithLabel
}

export const STATISTICS_BY_CHANNEL: StatisticsByChannel = {
  facebook: {
    likes: {
      singularLabel: 'Like',
      pluralLabel: 'Likes',
    },
    comments: {
      singularLabel: 'Comment',
      pluralLabel: 'Comments',
    },
    shares: {
      singularLabel: 'Share',
      pluralLabel: 'Shares',
    },
    clicks: {
      singularLabel: 'Click',
      pluralLabel: 'Clicks',
    },
    reach: {
      singularLabel: 'Reach',
      pluralLabel: 'Reach',
    },
  },
  instagram: {
    likes: {
      singularLabel: 'Like',
      pluralLabel: 'Likes',
    },
    comments: {
      singularLabel: 'Comment',
      pluralLabel: 'Comments',
    },
  },
  pinterest: {
    repins: {
      singularLabel: 'Save',
      pluralLabel: 'Saves',
    },
  },
  startPage: {
    linkClicks: {
      singularLabel: 'Link Click',
      pluralLabel: 'Link Clicks',
    },
  },
  youtube: {
    views: {
      singularLabel: 'View',
      pluralLabel: 'Views',
    },
    likes: {
      singularLabel: 'Like',
      pluralLabel: 'Likes',
    },
    comments: {
      singularLabel: 'Comment',
      pluralLabel: 'Comments',
    },
  },
  mastodon: {
    shares: {
      singularLabel: 'Share',
      pluralLabel: 'Shares',
    },
    favorites: {
      singularLabel: 'Favorite',
      pluralLabel: 'Favorites',
    },
    comments: {
      singularLabel: 'Comment',
      pluralLabel: 'Comments',
    },
  },
  bluesky: {
    comments: {
      singularLabel: 'Comment',
      pluralLabel: 'Comments',
    },
    likes: {
      singularLabel: 'Like',
      pluralLabel: 'Likes',
    },
    reposts: {
      singularLabel: 'Repost',
      pluralLabel: 'Reposts',
    },
  },
}

export const STATISTICS_BY_CHANNEL_ANALYTICS_V2: StatisticsByChannel = {
  twitter_analytics: {
    likes: {
      singularLabel: 'Like',
      pluralLabel: 'Likes',
    },
    retweets: {
      singularLabel: 'Retweet',
      pluralLabel: 'Retweets',
    },
    reach: {
      singularLabel: 'Impression',
      pluralLabel: 'Impressions',
    },
    clicks: {
      singularLabel: 'Click',
      pluralLabel: 'Clicks',
    },
  },
  twitter_freemium: {
    likes: {
      singularLabel: 'Like',
      pluralLabel: 'Likes',
    },
    retweets: {
      singularLabel: 'Retweet',
      pluralLabel: 'Retweets',
    },
    reach: {
      singularLabel: 'Impression',
      pluralLabel: 'Impressions',
    },
  },
  tiktok_analytics: {
    likes: {
      singularLabel: 'Like',
      pluralLabel: 'Likes',
    },
    comments: {
      singularLabel: 'Comment',
      pluralLabel: 'Comments',
    },
    views: {
      singularLabel: 'View',
      pluralLabel: 'Views',
    },
    shares: {
      singularLabel: 'Share',
      pluralLabel: 'Shares',
    },
    reach: {
      singularLabel: 'Impression',
      pluralLabel: 'Impressions',
    },
  },
  tiktok_freemium: {
    likes: {
      singularLabel: 'Like',
      pluralLabel: 'Likes',
    },
    comments: {
      singularLabel: 'Comment',
      pluralLabel: 'Comments',
    },
    views: {
      singularLabel: 'View',
      pluralLabel: 'Views',
    },
  },
  linkedin_analytics: {
    likes: {
      singularLabel: 'Like',
      pluralLabel: 'Likes',
    },
    comments: {
      singularLabel: 'Comment',
      pluralLabel: 'Comments',
    },
    reach: {
      singularLabel: 'Impression',
      pluralLabel: 'Impressions',
    },
    shares: {
      singularLabel: 'Share',
      pluralLabel: 'Shares',
    },
    engagement_rate: {
      singularLabel: 'Eng. Rate',
      pluralLabel: 'Eng. Rate',
    },
  },
  linkedin_freemium: {
    likes: {
      singularLabel: 'Like',
      pluralLabel: 'Likes',
    },
    comments: {
      singularLabel: 'Comment',
      pluralLabel: 'Comments',
    },
    reach: {
      singularLabel: 'Impression',
      pluralLabel: 'Impressions',
    },
  },
  facebook_analytics: {
    likes: {
      singularLabel: 'Like',
      pluralLabel: 'Likes',
    },
    comments: {
      singularLabel: 'Comment',
      pluralLabel: 'Comments',
    },
    shares: {
      singularLabel: 'Share',
      pluralLabel: 'Shares',
    },
    clicks: {
      singularLabel: 'Click',
      pluralLabel: 'Clicks',
    },
    reach: {
      singularLabel: 'Reach',
      pluralLabel: 'Reach',
    },
  },
  facebook_freemium: {
    likes: {
      singularLabel: 'Like',
      pluralLabel: 'Likes',
    },
    comments: {
      singularLabel: 'Comment',
      pluralLabel: 'Comments',
    },
    shares: {
      singularLabel: 'Share',
      pluralLabel: 'Shares',
    },
  },
  pinterest_analytics: {
    repins: {
      singularLabel: 'Save',
      pluralLabel: 'Saves',
    },
    comments: {
      singularLabel: 'Comment',
      pluralLabel: 'Comments',
    },
    clicks: {
      singularLabel: 'Click',
      pluralLabel: 'Clicks',
    },
    reactions: {
      singularLabel: 'Reaction',
      pluralLabel: 'Reactions',
    },
    impressions: {
      singularLabel: 'Impression',
      pluralLabel: 'Impressions',
    },
    views: {
      singularLabel: 'View',
      pluralLabel: 'Views',
    },
  },
  pinterest_freemium: {
    repins: {
      singularLabel: 'Save',
      pluralLabel: 'Saves',
    },
    comments: {
      singularLabel: 'Comment',
      pluralLabel: 'Comments',
    },
    clicks: {
      singularLabel: 'Click',
      pluralLabel: 'Clicks',
    },
  },
  threads_analytics: {
    likes: {
      singularLabel: 'Like',
      pluralLabel: 'Likes',
    },
    replies: {
      singularLabel: 'Reply',
      pluralLabel: 'Replies',
    },
    views: {
      singularLabel: 'View',
      pluralLabel: 'Views',
    },
    quotes: {
      singularLabel: 'Quote',
      pluralLabel: 'Quotes',
    },
    reposts: {
      singularLabel: 'Repost',
      pluralLabel: 'Reposts',
    },
  },
  threads_freemium: {
    likes: {
      singularLabel: 'Like',
      pluralLabel: 'Likes',
    },
    replies: {
      singularLabel: 'Reply',
      pluralLabel: 'Replies',
    },
    views: {
      singularLabel: 'View',
      pluralLabel: 'Views',
    },
  },
  bluesky_analytics: {
    comments: {
      singularLabel: 'Comment',
      pluralLabel: 'Comments',
    },
    likes: {
      singularLabel: 'Like',
      pluralLabel: 'Likes',
    },
    reposts: {
      singularLabel: 'Repost',
      pluralLabel: 'Reposts',
    },
  },
  bluesky_freemium: {
    comments: {
      singularLabel: 'Comment',
      pluralLabel: 'Comments',
    },
    likes: {
      singularLabel: 'Like',
      pluralLabel: 'Likes',
    },
    reposts: {
      singularLabel: 'Repost',
      pluralLabel: 'Reposts',
    },
  },
}

/**
 * Some properties should not be shown for some networks if they hold no value.
 */
export const CHANNELS_TO_FILTER_NULLABLE_STATS: Record<
  string,
  Array<string>
> = {
  pinterest: ['views', 'impressions'],
}
