import { connect } from 'react-redux'

import TrialCompleteModal from './components/TrialCompleteModal'
import { actions } from './reducer'

export default connect(
  (state) => ({
    // @ts-expect-error TS(2339) FIXME: Property 'i18n' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
    translations: state.i18n.translations['trial-complete-modal'],
    // @ts-expect-error TS(2339) FIXME: Property 'trialCompleteModal' does not exist on ty... Remove this comment to see the full error message
    hasExpiredBusinessTrial: state.trialCompleteModal.hasExpiredBusinessTrial,
    // @ts-expect-error TS(2339) FIXME: Property 'trialCompleteModal' does not exist on ty... Remove this comment to see the full error message
    hasExpiredProTrial: state.trialCompleteModal.hasExpiredProTrial,
    // @ts-expect-error TS(2339) FIXME: Property 'trialCompleteModal' does not exist on ty... Remove this comment to see the full error message
    isPremiumBusiness: state.trialCompleteModal.isPremiumBusiness,
  }),
  (dispatch) => ({
    cancelTrial: () => dispatch(actions.cancelTrial()),
    completeAndUpgrade: () => dispatch(actions.completeAndUpgrade()),
  }),
)(TrialCompleteModal)

export { default as reducer, actions, actionTypes } from './reducer'
export { default as middleware } from './middleware'
