/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'

import { Image } from '@buffer-mono/legacy-bufferapp-components'
import {
  BufferTrackerReact as BufferTracker,
  Client,
  Product,
} from '@buffer-mono/tracking-plan'
import { useTranslation } from 'react-i18next'
import {
  useAccount,
  useOrganizationBilling,
  useOrganizationLimits,
} from '~publish/legacy/accountContext'
import { SEGMENT_NAMES } from '~publish/legacy/constants'

import { CloseIcon, IconButton } from '@buffer-mono/popcorn'
import * as Dialog from '@radix-ui/react-dialog'
import styles from './IdeasLimitReachedModal.module.css'
import {
  ButtonStyled,
  CenteredText,
  CheckmarkIconStyled,
  FooterStyled,
  HeaderStyled,
  HeroImage,
  ListItemStyled,
  ListStyled,
  ModalActionsStyled,
} from './styles'

interface Props {
  onClose: () => void
}

export const IdeasLimitReachedModal = ({ onClose }: Props): JSX.Element => {
  const { t } = useTranslation()
  const { canStartTrial, subscription } = useOrganizationBilling()

  const modalType = React.useMemo(() => {
    const planId = subscription?.plan?.id

    if (planId && planId !== 'free') {
      return 'paid'
    }
    return canStartTrial ? 'trial' : 'upgrade'
  }, [subscription, canStartTrial])

  const accountContext = useAccount()
  const account = accountContext?.account
  const organizationId = account?.currentOrganization?.id
  const { ideas: ideasLimit } = useOrganizationLimits()

  useEffect(() => {
    if (modalType !== 'paid') {
      const eventData = {
        product: Product.Publish,
        upgradePathName: `ideasLimit-${modalType}`,
        organizationId: organizationId || '',
        clientName: Client.PublishWeb,
      }

      BufferTracker.upgradePathViewed(eventData)
    }
  }, [])

  useEffect(() => {
    if (modalType === 'trial') {
      const { COMPONENTS, actions } = window?.appshell || {}
      actions.renderComponent({
        containerId: 'ideasReachLimitCta',
        componentKey: COMPONENTS.startTrialButton,
        options: {
          ctaView: 'ideasLimit',
          ctaLocation: 'content',
          ctaButton: 'startTrial',
          cta: SEGMENT_NAMES.IDEAS_LIMIT_TRIAL,
          upgradePathName: 'ideasLimit-trial',
        },
      })
    }
  }, [])

  const paidContent = (): JSX.Element => {
    return (
      <>
        <HeaderStyled type="h2">
          {t('content.modals.ideasLimitReached.paid.title', {
            limit: ideasLimit,
          })}
        </HeaderStyled>
        <CenteredText type="p">
          {t('content.modals.ideasLimitReached.paid.description')}
        </CenteredText>
      </>
    )
  }

  const modalActionButtons = (modalType: string): JSX.Element => {
    return (
      <ModalActionsStyled>
        {modalType === 'trial' && <div id="ideasReachLimitCta" />}
        {modalType === 'upgrade' && (
          <ButtonStyled
            type="primary"
            label={t('content.modals.ideasLimitReached.free.upgrade.button')}
            onClick={(): void => {
              onClose()
              const { MODALS, actions } = window?.appshell || {}
              actions.openModal(MODALS.planSelector, {
                cta: SEGMENT_NAMES.IDEAS_LIMIT_UPGRADE,
                upgradePathName: 'ideasLimit-upgrade',
              })
            }}
          />
        )}
      </ModalActionsStyled>
    )
  }

  const freeContent = (modalType: string): JSX.Element => {
    return (
      <>
        <HeaderStyled type="h2">
          {t(`content.modals.ideasLimitReached.free.title`, {
            limit: ideasLimit,
          })}
        </HeaderStyled>
        <CenteredText type="p">
          {t(`content.modals.ideasLimitReached.free.description`)}
        </CenteredText>
        <ListStyled>
          <ListItemStyled>
            <CheckmarkIconStyled />
            {t(`content.modals.ideasLimitReached.free.${modalType}.listItem1`)}
          </ListItemStyled>
          <ListItemStyled>
            <CheckmarkIconStyled />
            {t(`content.modals.ideasLimitReached.free.${modalType}.listItem2`)}
          </ListItemStyled>
          <ListItemStyled>
            <CheckmarkIconStyled />
            {t(`content.modals.ideasLimitReached.free.${modalType}.listItem3`)}
          </ListItemStyled>
        </ListStyled>
        {modalActionButtons(modalType)}
        {modalType === 'trial' && (
          <FooterStyled type="p">
            {t('content.modals.ideasLimitReached.free.trial.footer')}
          </FooterStyled>
        )}
      </>
    )
  }

  const getModalContent = (modalType: string): JSX.Element => {
    switch (modalType) {
      case 'paid':
        return paidContent()
      case 'trial':
      case 'upgrade':
        return freeContent(modalType)
      default:
        return paidContent()
    }
  }

  return (
    <Dialog.Content className={styles.content}>
      <Dialog.Close asChild>
        <IconButton
          className={styles.closeButton}
          variant="tertiary"
          label="dismiss"
        >
          <CloseIcon />
        </IconButton>
      </Dialog.Close>
      <div className={styles.body}>
        <HeroImage>
          <Image
            src="https://buffer-publish.s3.amazonaws.com/images/consumption-limit-banner2x.jpg"
            alt="Consumption limits reached"
            width="198px"
          />
        </HeroImage>
        {getModalContent(modalType)}
      </div>
    </Dialog.Content>
  )
}
