import React from 'react'

import { LinkifiedText } from '~publish/legacy/shared-components/LinkifiedText'
import { AttachmentTypes } from '~publish/legacy/constants'
import type {
  Gif,
  Image,
  Link,
  Video,
} from '~publish/legacy/composer/composer/entities/factories'
import { parseThreadsLinks } from '~publish/legacy/utils/channels/threads/helpers'
import { AttachmentVideo } from '../AttachmentVideo'
import type { ProfilePreview, ThreadsContent } from '../../../types'
import useLinkPreviewEffect from '../../../Common/hooks/useLinkPreviewEffect'
import * as Styles from './styles'
import styles from './ThreadPost.module.css'
import MoreIcon from '@bufferapp/ui/Icon/Icons/More'

interface GetMediaPropertiesResult {
  hasImages: boolean | number | undefined | null | Gif
  hasVideo: boolean
  hasLink: boolean
  hasRetweet: boolean
}

function ShareIcon(): JSX.Element {
  return (
    <svg width="20px" height="20px" viewBox="0 0 16 16">
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7 15.77c.35.3.9.3 1.24 0 .35-.3.35-.8 0-1.12l-1.5-1.35h3.88c1.29 0 2.18-.5 2.72-1.18.5-.63.66-1.36.66-1.87V7.1c0-.44-.4-.79-.88-.79s-.87.35-.87.79v3.15c0 .25-.09.64-.34.95-.2.27-.57.52-1.29.52H6.75l1.5-1.36c.35-.3.35-.8 0-1.11a.94.94 0 0 0-1.23 0L4.7 11.3a1.58 1.58 0 0 0 0 2.4L7 15.76ZM3.5 5.75c0-.25.09-.64.34-.95.2-.27.57-.52 1.29-.52H9L7.5 5.64c-.35.3-.35.8 0 1.11.34.31.9.31 1.23 0l2.3-2.06c.73-.66.73-1.73 0-2.4L8.74.24a.94.94 0 0 0-1.23 0c-.35.3-.35.8 0 1.12L9 2.7H5.13c-1.29 0-2.18.5-2.72 1.18-.5.63-.66 1.36-.66 1.87V8.9c0 .44.4.79.88.79s.87-.35.87-.79V5.75Z"
        clipRule="evenodd"
      />
    </svg>
  )
}

const getMediaProperties = (
  content: ThreadsContent,
  linkPreview: Link | null,
): GetMediaPropertiesResult => {
  const { text, images, gif, video, enabledAttachmentType } = content

  const hasText = !!text
  const hasMedia = enabledAttachmentType === AttachmentTypes.MEDIA
  const hasImages = hasMedia && (images?.length || gif)
  const hasVideo = !!(hasMedia && video)
  const hasLink = !!(linkPreview && !hasImages && !hasVideo)
  const hasContent = hasText || hasMedia || hasLink
  // If there is no content, we display the whole tweet as a retweet.
  const hasRetweet =
    enabledAttachmentType === AttachmentTypes.RETWEET && hasContent

  return { hasImages, hasVideo, hasLink, hasRetweet }
}

export const ThreadPost = ({
  content,
  profile,
  index,
}: {
  content: ThreadsContent
  profile: ProfilePreview
  index: number
}): JSX.Element => {
  const { text, images, gif, video, link: linkFromPost, inThread } = content
  const { avatar, username, displayName } = profile
  const [linkPreview] = useLinkPreviewEffect({
    linkFromPost,
    text,
    returnFirstUrl: true,
  })

  const { hasImages, hasVideo, hasLink } = getMediaProperties(
    content,
    linkPreview,
  )

  const allImages = images?.length ? images : ([{ ...gif }] as Image[])

  const usernameToDisplay = displayName || username

  return (
    <>
      <Styles.Wrapper
        withBorder={!!inThread}
        withTwirl={!!text && (index + 1) % 3 === 0}
        data-thread-id={index}
      >
        <Styles.AvatarWrapper>
          <Styles.Avatar data-testid="avatar" src={avatar} />
        </Styles.AvatarWrapper>
        <Styles.Content>
          <Styles.Header>
            <Styles.Username>{usernameToDisplay}</Styles.Username>
            <Styles.Ago>21h</Styles.Ago>
            <Styles.Dots>
              <MoreIcon />
            </Styles.Dots>
          </Styles.Header>

          {text ? (
            <LinkifiedText
              links={parseThreadsLinks(text)}
              whitespace="pre-wrap"
              className={styles.text}
            >
              {text}
            </LinkifiedText>
          ) : (
            <Styles.EmptyText />
          )}

          {hasImages && allImages.length === 1 && (
            <Styles.ImagesGrid
              images={allImages}
              isRowFormat={false}
              maxHeight="180px"
              shouldShowGifTag={false}
            />
          )}

          {hasImages && allImages.length >= 2 && (
            <Styles.ImagesCarousel images={allImages} maxHeight="180px" />
          )}
          {hasVideo && (
            <AttachmentVideo video={video as Video} showSoundOffIcon />
          )}
          {hasLink && (
            <Styles.LinkPreview
              link={linkPreview as Link}
              clickable={false}
              imageCover
            />
          )}

          <Styles.Footer>
            <Styles.HeartIcon size="large" />
            <Styles.CommentIcon size="large" />
            <ShareIcon />
            <Styles.MessageIcon size="large" />
          </Styles.Footer>
        </Styles.Content>
      </Styles.Wrapper>
    </>
  )
}
