// TODO: It's an established best practice to name the fragment after with an underscore,
// so disabling eslint errors for it for now. We need to figure out a better way to handle these more globally
/* eslint-disable camelcase, @typescript-eslint/naming-convention */
import type {
  PublishingPost,
  PostCard_PostFragment,
} from '~publish/gql/graphql'
import type { RpcUpdate } from '~publish/legacy/post/types'
import { useAppSelector } from '~publish/legacy/store'
import { selectPostById } from '~publish/legacy/post/slice'
import { PostCardContext } from '~publish/components/PostCard/PostCardContext'
import { useContext } from 'react'

// HACK: this component hooks into post card context to get post data
// TODO: needs to be removed, we are implementing new tagging widget,
// so hooking into internal context should not be needed going forward
export const usePostData = (
  postId: string,
): PublishingPost | RpcUpdate | PostCard_PostFragment | undefined => {
  const postCardContext = useContext(PostCardContext)
  const reduxPost = useAppSelector((state) => selectPostById(state, postId))

  return reduxPost || postCardContext?.post
}
