import { useQuery, useMutation } from '@apollo/client'
import { useCallback } from 'react'

import {
  useAccount,
  useCurrentOrganization,
  useOrganizationLimits,
} from '~publish/legacy/accountContext'
import UserEntity from '~publish/legacy/user/UserEntity'
import { graphql } from '~publish/gql'
import type { MutationError, Tag } from '~publish/gql/graphql'

type TagId = Tag['id']

export const GetTags = graphql(/* GraphQL */ `
  query GetTags($input: TagsInput!) {
    tags(input: $input) {
      color
      id
      isLocked
      name
    }
  }
`)

export const CreateTag = graphql(/* GraphQL */ `
  mutation CreateTag($input: CreateTagInput!) {
    createTag(input: $input) {
      ... on TagActionSuccess {
        tag {
          id
          name
          color
          isLocked
        }
      }
      ... on MutationError {
        message
      }
    }
  }
`)

export const UpdateTag = graphql(/* GraphQL */ `
  mutation UpdateTag($input: UpdateTagInput!) {
    updateTag(input: $input) {
      ... on TagActionSuccess {
        tag {
          id
          name
          color
          isLocked
        }
      }
      ... on MutationError {
        message
      }
    }
  }
`)

export const DeleteTag = graphql(/* GraphQL */ `
  mutation DeleteTag($input: DeleteTagInput!) {
    deleteTag(input: $input) {
      ... on DeleteTagActionSuccess {
        id
      }
      ... on MutationError {
        message
      }
    }
  }
`)

export type CreateTag = (tag: { name: string; color: string }) => Promise<Tag>
export type DeleteTag = (id: TagId) => Promise<TagId>
export type UpdateTag = (tag: Omit<Tag, 'isLocked'>) => Promise<Tag>
export type UseTagsQueryReturn = {
  tags: Tag[]
  error: string | null
  loading: boolean
}

export type UseTagsReturn = UseTagsQueryReturn & {
  hasCreatePermission: boolean
  createTag: CreateTag
  updateTag: UpdateTag
  deleteTag: DeleteTag
}

/**
 * Hook to manage tags including fetching, creating, updating, and deleting tags.
 * @returns {UseTagsReturn} The tags, loading state, error state, and functions for CRUD operations on tags.
 * @example
 * const { tags, error, loading, createTag, updateTag, deleteTag, hasCreatePermission } = useTags();
 *
 * useEffect(() => {
 *   if (!loading && !error) {
 *     console.log(tags);
 *   }
 * }, [tags, loading, error]);
 *
 * const handleCreateTag = async () => {
 *   try {
 *     const newTagId = await createTag({ name: 'New Tag', color: '#ff0000' });
 *     console.log('Tag created with ID:', newTagId);
 *   } catch (err) {
 *     console.error(err);
 *   }
 * };
 *
 * const handleUpdateTag = async (tag) => {
 *   try {
 *     const updatedTag = await updateTag(tag);
 *     console.log('Tag updated:', updatedTag);
 *   } catch (err) {
 *     console.error(err);
 *   }
 * };
 *
 * const handleDeleteTag = async (id) => {
 *   try {
 *     const deletedTagId = await deleteTag(id);
 *     console.log('Tag deleted with ID:', deletedTagId);
 *   } catch (err) {
 *     console.error(err);
 *   }
 * };
 */
export function useTags(): UseTagsReturn {
  const account = useAccount()
  const isAdmin = UserEntity.isAdmin(account)

  const { tags, error, loading } = useTagsQuery()
  const { tags: tagsLimit } = useOrganizationLimits()

  const hasCreatePermission = isAdmin && tags.length < tagsLimit

  const createTag = useCreateTag()
  const deleteTag = useDeleteTag()
  const updateTag = useUpdateTag()

  return {
    tags,
    error,
    loading,
    createTag,
    deleteTag,
    updateTag,
    hasCreatePermission,
  }
}

/**
 * Hook to fetch tags for the current organization.
 * @returns {UseTagsQueryReturn} The tags, loading state, and error state.
 * @example
 * const { tags, error, loading } = useTagsQuery();
 *
 * useEffect(() => {
 *   if (!loading && !error) {
 *     console.log(tags);
 *   }
 * }, [tags, loading, error]);
 */
export function useTagsQuery(): UseTagsQueryReturn {
  const organizationId = useCurrentOrganization().id
  const { loading, data, error } = useQuery(GetTags, {
    variables: {
      input: { organizationId },
    },
  })

  if (loading) return { tags: [], error: null, loading: true }

  let _error: string | null = null

  if (error) {
    _error = 'Error loading tags'
  }

  return { tags: data?.tags ?? [], error: _error, loading }
}

/**
 * Hook to create a new tag.
 * @returns {CreateTag} Function to create a new tag.
 * @example
 * const createTag = useCreateTag();
 *
 * const handleCreateTag = async () => {
 *   try {
 *     const newTag = await createTag({ name: 'New Tag', color: '#ff0000' });
 *     console.log('Tag created with ID:', newTag.id);
 *   } catch (err) {
 *     console.error(err);
 *   }
 * };
 */
export function useCreateTag(): CreateTag {
  const organizationId = useCurrentOrganization().id

  const [createTagMutation] = useMutation(CreateTag, {
    refetchQueries: ['GetTags'],
  })

  const createTag = useCallback(
    async ({ name, color }: { name: string; color: string }): Promise<Tag> => {
      const { data, errors } = await createTagMutation({
        variables: {
          input: {
            organizationId,
            tag: { name, color },
          },
        },
      })

      if (errors || !data) {
        throw new Error(errors?.[0]?.message ?? 'Failed to create tag')
      }

      const result = data.createTag
      if (result.__typename !== 'TagActionSuccess') {
        throw new Error(
          (result as MutationError).message ?? 'Failed to create tag',
        )
      }

      return result.tag
    },
    [createTagMutation, organizationId],
  )

  return createTag
}

/**
 * Hook to delete a tag.
 * @returns {DeleteTag} Function to delete a tag.
 * @example
 * const deleteTag = useDeleteTag();
 *
 * const handleDeleteTag = async (id) => {
 *   try {
 *     const deletedTagId = await deleteTag(id);
 *     console.log('Tag deleted with ID:', deletedTagId);
 *   } catch (err) {
 *     console.error(err);
 *   }
 * };
 */
export function useDeleteTag(): DeleteTag {
  const organizationId = useCurrentOrganization().id

  const [deleteTagMutation] = useMutation(DeleteTag, {
    refetchQueries: ['GetTags'],
  })

  const deleteTag = useCallback(
    async (id: TagId): Promise<TagId> => {
      const { data, errors } = await deleteTagMutation({
        variables: {
          input: {
            id,
          },
        },
        optimisticResponse: {
          deleteTag: {
            __typename: 'DeleteTagActionSuccess',
            id,
          },
        },
        update: (cache, { data }) => {
          if (data?.deleteTag?.__typename === 'DeleteTagActionSuccess') {
            const existingTags = cache.readQuery({
              query: GetTags,
              variables: { input: { organizationId } },
            })

            if (existingTags) {
              cache.writeQuery({
                query: GetTags,
                data: {
                  tags: existingTags.tags.filter((t: Tag) => t.id !== id),
                },
              })
            }
          }
        },
      })

      if (errors || !data) {
        throw new Error(errors?.[0]?.message ?? 'Failed to delete tag')
      }

      const result = data.deleteTag
      if (result.__typename !== 'DeleteTagActionSuccess') {
        throw new Error(
          (result as MutationError).message ?? 'Failed to delete tag',
        )
      }

      return result.id
    },
    [deleteTagMutation, organizationId],
  )

  return deleteTag
}

/**
 * Hook to update a tag.
 * @returns {UpdateTag} Function to update a tag.
 * @example
 * const updateTag = useUpdateTag();
 *
 * const handleUpdateTag = async (tag) => {
 *   try {
 *     const updatedTag = await updateTag(tag);
 *     console.log('Tag updated:', updatedTag);
 *   } catch (err) {
 *     console.error(err);
 *   }
 * };
 */
export function useUpdateTag(): UpdateTag {
  const organizationId = useCurrentOrganization().id

  const [updateTagMutation] = useMutation(UpdateTag, {
    refetchQueries: ['GetTags'],
  })

  const updateTag = useCallback(
    async (tag: Omit<Tag, 'isLocked'>): Promise<Tag> => {
      const { data, errors } = await updateTagMutation({
        variables: {
          input: {
            id: tag.id,
            tag: {
              name: tag.name,
              color: tag.color,
            },
          },
        },
        optimisticResponse: {
          updateTag: {
            __typename: 'TagActionSuccess',
            tag: {
              ...tag,
              // TODO: we should not need to set this here
              isLocked: false,
              __typename: 'Tag',
            },
          },
        },
        update: (cache, { data }) => {
          if (data?.updateTag?.__typename === 'TagActionSuccess') {
            const updatedTag = data.updateTag.tag
            const existingTags = cache.readQuery({
              query: GetTags,
              variables: { input: { organizationId } },
            })

            if (existingTags) {
              cache.writeQuery({
                query: GetTags,
                data: {
                  tags: existingTags.tags.map((t: Tag) =>
                    t.id === updatedTag.id ? updatedTag : t,
                  ),
                },
              })
            }
          }
        },
      })

      if (errors || !data) {
        throw new Error(errors?.[0]?.message ?? 'Failed to update tag')
      }

      const result = data.updateTag
      if (result.__typename !== 'TagActionSuccess') {
        throw new Error(
          (result as MutationError).message ?? 'Failed to update tag',
        )
      }

      return result.tag
    },
    [updateTagMutation, organizationId],
  )

  return updateTag
}
