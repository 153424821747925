import React from 'react'
import type { Image } from '~publish/legacy/composer/composer/entities/factories'

import { getOrientation } from '../../../Common/utils'

import * as Styles from './styles'

export const ImagePreview = ({ image }: { image?: Image | null }) => {
  const height = image?.height
  const width = image?.width
  const orientation = getOrientation({ height, width })

  return (
    <Styles.ImageWrapper data-testid="preview-image" orientation={orientation}>
      <Styles.Image src={image?.url} orientation={orientation} />
    </Styles.ImageWrapper>
  )
}
