import React from 'react'
import { useTranslation } from 'react-i18next'
import { shallowEqual, useSelector } from 'react-redux'
import { useIdeasCountByTag } from '~publish/legacy/campaign/hooks/useIdeasCountByTag'
import {
  selectApprovalEntitlement,
  selectCampaignCounts,
} from '~publish/legacy/campaign/selectors'
import { CampaignTab } from '~publish/legacy/campaign/types'
import { tagTab } from '~publish/legacy/routes'
import { Nav, NavLink, NavTag } from '~publish/legacy/shared-components'
import { TagStyled } from './styles'

type Props = { campaignId: string; showIdeas: boolean }

export const NavItems = ({
  campaignId,
  showIdeas = true,
}: Props): JSX.Element => {
  const { t } = useTranslation()
  const { sent, scheduled, draftsCount, approvalsCount } = useSelector(
    selectCampaignCounts,
    shallowEqual,
  )

  const hasApprovalEntitlement = useSelector(selectApprovalEntitlement)

  const tagId = campaignId
  const { ideasCount } = useIdeasCountByTag(tagId)
  const showPaywallIcon = !hasApprovalEntitlement

  return (
    <Nav customPadding={'0 40px'} showBorderOnParent={true}>
      {showIdeas && (
        <NavLink
          testId="ideas-tab"
          to={tagTab.getRoute({ tagId, tab: CampaignTab.Ideas })}
        >
          {t('campaigns.viewCampaign.ideasTitle')}
          <TagStyled color="grayLighter" textColor="grayDark">
            {ideasCount}
          </TagStyled>
        </NavLink>
      )}
      <NavLink
        testId="scheduled-tab"
        to={tagTab.getRoute({ tagId, tab: CampaignTab.Scheduled })}
      >
        {t('campaigns.viewCampaign.scheduledTitle')}
        <TagStyled color="grayLighter" textColor="grayDark">
          {scheduled}
        </TagStyled>
      </NavLink>
      <NavLink
        testId="drafts-tab"
        to={tagTab.getRoute({ tagId, tab: CampaignTab.Drafts })}
      >
        {t('campaigns.viewCampaign.draftsTitle')}
        <TagStyled color="grayLighter" textColor="grayDark">
          {draftsCount}
        </TagStyled>
      </NavLink>
      <NavLink
        testId="approvals-tab"
        to={tagTab.getRoute({ tagId, tab: CampaignTab.Approvals })}
      >
        {t('campaigns.viewCampaign.approvalsTitle')}
        {showPaywallIcon ? (
          <NavTag type="paywall" />
        ) : (
          <TagStyled color="grayLighter" textColor="grayDark">
            {approvalsCount}
          </TagStyled>
        )}
      </NavLink>
      <NavLink
        testId="sent-tab"
        to={tagTab.getRoute({ tagId, tab: CampaignTab.Sent })}
      >
        {t('campaigns.viewCampaign.sentTitle')}
        <TagStyled color="grayLighter" textColor="grayDark">
          {sent}
        </TagStyled>
      </NavLink>
    </Nav>
  )
}
