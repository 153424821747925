import { type RefObject, useEffect, useRef, useState } from 'react'

const INITIAL_HEIGHT = 1000
const FINAL_MAX_HEIGHT = 900

export interface UseAnimatedCollapseReturn {
  // The ref to be passed to the element to expand/collapse
  collapseElementRef: RefObject<HTMLDivElement>
  // The max-height of the element to expand/collapse
  height: number
}

export const useAnimatedExpandCollapse = (
  isExpanded: boolean,
): UseAnimatedCollapseReturn => {
  const collapseElementRef = useRef<HTMLDivElement | null>(null)
  // Set the initial height to a rather large number
  // to animate the element within until actual height
  // can be determined
  const [height, setHeight] = useState<number>(INITIAL_HEIGHT)
  const isHeightSet = useRef<boolean>(false)

  useEffect(() => {
    // If the element is open and the height has not been set,
    // check that the element has a height greater than 0,
    // then update the local state height and set isHeightSet
    // to true so this clause isn't run again
    if (isExpanded && collapseElementRef.current && !isHeightSet.current) {
      const offsetHeight = collapseElementRef.current.offsetHeight
      if (offsetHeight > 0) {
        // Setting a fixed final max height to solve a bug for GBP fields
        // not rendering correctly
        // TODO: Replace this with actual solution
        setHeight(FINAL_MAX_HEIGHT)
        isHeightSet.current = true
      }
    }
  }, [isExpanded])

  return {
    collapseElementRef,
    height: isExpanded ? height : 0,
  }
}
