import React from 'react'
import { init, isInitialized, FullStory } from '@fullstory/browser'
import { useAccount } from '~publish/legacy/accountContext'
import { env } from '~publish/env'

export const useFullStory = (
  args: {
    onlyBetaUsers?: boolean
    trackFreePlan?: boolean
  } = {},
): { initFullStory: () => void } => {
  const { account } = useAccount()
  const { onlyBetaUsers = false, trackFreePlan = false } = args

  const initFullStory = React.useCallback((): void => {
    const plan = account?.currentOrganization?.billing?.subscription?.plan

    if (!account || !plan) {
      return
    }

    if (onlyBetaUsers && !account.currentOrganization.isBetaEnabled) {
      return
    }

    const isPaidPlan = plan.id !== 'free'
    const isProduction = env.VITE_NODE_ENV === 'production'
    const freeConditions = isProduction && !isInitialized()
    const paidConditions = isProduction && isPaidPlan && !isInitialized()
    const shouldInitFullStory = trackFreePlan ? freeConditions : paidConditions

    if (!shouldInitFullStory) {
      return
    }

    init({
      orgId: env.VITE_FULLSTORY_ORG_ID,
    })

    FullStory('setProperties', {
      type: 'user',
      properties: {
        uid: account.id,
        email: account.email,
        account_id: account.id,
        organization_id: account.currentOrganization?.id,
        subscription_plan: plan.id,
      },
    })
  }, [account, onlyBetaUsers])

  return { initFullStory }
}
