import type ValidationFail from './ValidationFail'
import type ValidationSuccess from './ValidationSuccess'

class ValidationResult {
  isValid: boolean

  constructor(isValid: boolean) {
    this.isValid = isValid
  }

  isInvalid(): boolean {
    return !this.isValid
  }

  isValidationFail(): this is ValidationFail {
    return !this.isValid
  }

  isValidationSuccess(): this is ValidationSuccess {
    return this.isValid
  }
}

export default ValidationResult
