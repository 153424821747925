import { setNodes, type TNodeEntry } from '@udecode/plate'
import type { BufferEditor } from '~publish/legacy/editor/BufferEditor/types.plate'
import type {
  AutocompleteInputElement,
  AutocompleteInputElementInterface,
} from '../nodes'
import { mentionContainsInvalidCharacter } from '../transforms/mentionContainsInvalidCharacter'

export const setNodeValidityAgainstRegex = <E extends AutocompleteInputElement>(
  editor: BufferEditor,
  elementInterface: AutocompleteInputElementInterface<E>,
  nodeEntry: TNodeEntry,
) => {
  const [node, path] = nodeEntry
  const containsInvalidCharacter = mentionContainsInvalidCharacter(
    editor,
    elementInterface,
    nodeEntry,
  )

  if (elementInterface.is(node)) {
    const { valid, autocompleted } = node
    let nodeIsValid = valid
    let nodeWasAutocompleted = autocompleted

    if (containsInvalidCharacter && valid) nodeIsValid = false
    if (!containsInvalidCharacter && !valid) nodeIsValid = true
    if (autocompleted && !nodeIsValid) nodeWasAutocompleted = false

    if (valid !== nodeIsValid) {
      setNodes(
        editor,
        { valid: nodeIsValid, autocompleted: nodeWasAutocompleted },
        { at: path, match: (n) => elementInterface.is(n) },
      )
      return true
    }
  }
  return false
}
