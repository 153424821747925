import TwitterIcon from '@bufferapp/ui/Icon/Icons/Twitter'
import FacebookIcon from '@bufferapp/ui/Icon/Icons/Facebook'
import LinkedinIcon from '@bufferapp/ui/Icon/Icons/LinkedIn'
import InstagramIcon from '@bufferapp/ui/Icon/Icons/Instagram'
import TiktokIcon from '@bufferapp/ui/Icon/Icons/Tiktok'
import PinterestIcon from '@bufferapp/ui/Icon/Icons/Pinterest'
import ThreadsIcon from '@bufferapp/ui/Icon/Icons/Threads'
import GbpIcon from '@bufferapp/ui/Icon/Icons/Gbp'
import React from 'react'

export const ChannelIcon = (channelName: string): React.ReactElement => {
  switch (channelName) {
    case 'twitter':
      return <TwitterIcon />
    case 'facebook':
      return <FacebookIcon />
    case 'linkedin':
      return <LinkedinIcon />
    case 'instagram':
      return <InstagramIcon />
    case 'tiktok':
      return <TiktokIcon />
    case 'pinterest':
      return <PinterestIcon />
    case 'googlebusiness':
      return <GbpIcon />
    case 'threads':
      return <ThreadsIcon />
    default:
      return <></>
  }
}
