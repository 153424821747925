import React from 'react'

import { useAppSelector } from '~publish/legacy/store'
import { selectGenerateContentLimits } from '~publish/legacy/ai/state/aiLimits/slice'
import WarningIcon from '@bufferapp/ui/Icon/Icons/Warning'

import * as Styled from './styles'

/**
 *
 */
const Credits = (): JSX.Element | null => {
  const { generateContentTokensLeft, limitsLoaded } = useAppSelector((state) =>
    selectGenerateContentLimits(state),
  )

  return limitsLoaded && generateContentTokensLeft === 0 ? (
    <Styled.Wrapper>
      <Styled.NoCreditsLeft>
        <WarningIcon />
        You&apos;ve used all your AI credits. Please reach out to support to get
        more.
      </Styled.NoCreditsLeft>
    </Styled.Wrapper>
  ) : null
}

export { Credits }
