import {
  createSlice,
  type PayloadAction,
  type Slice,
  type SliceCaseReducers,
} from '@reduxjs/toolkit'
import { Notification } from '../../values/Notification'
import { ActionTypes } from '../ActionTypes'

type NotificationsState = {
  visibleNotifications: Notification[]
}

const initialState: NotificationsState = {
  visibleNotifications: [],
}

export const notificationsSlice: Slice<
  NotificationsState,
  SliceCaseReducers<NotificationsState>,
  'notifications'
> = createSlice({
  // available at state.composer.notificaitons
  name: 'notifications',
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<Notification>) => {
      state.visibleNotifications.push(action.payload)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(ActionTypes.QUEUE_NOTIFICATION, (state, action) => {
      state.visibleNotifications.push(Notification.new((action as any).payload))
    })
  },
})

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
export const selectVisibleNotifications = (state) => {
  if (!state.composer) {
    // old state, remove when NotificationStore is gone
    return state.visibleNotifications
  }

  return state.composer[notificationsSlice.name].visibleNotifications
}

export const { addNotification } = notificationsSlice.actions
