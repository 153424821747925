import styled, { css } from 'styled-components'
import { white, black } from '@bufferapp/ui/style/colors'

import StarOutlineIcon from '@bufferapp/ui/Icon/Icons/StarOutline'
import ThumbsupIcon from '@bufferapp/ui/Icon/Icons/Thumbsup'
import CommentRoundOutlineIcon from '@bufferapp/ui/Icon/Icons/CommentRoundOutline'
import ShareArrowOutlineIcon from '@bufferapp/ui/Icon/Icons/ShareArrowOutline'
import SearchIcon from '@bufferapp/ui/Icon/Icons/Search'
import WorldIcon from '@bufferapp/ui/Icon/Icons/World'

import {
  Video as CommonVideo,
  PlayButton as CommonPlayButton,
} from '../../../Common/VideoPlayer/styles'
import { VideoPlayer as VideoPlayerCommon } from '../../../Common/VideoPlayer'
import {
  DotsIcon,
  PostWrapper as PostWrapperCommon,
  previewGrayMedium,
} from '../../../Common/styles'

export const Header = styled.div`
  position: absolute;
  top: 20px;
  width: calc(100% - 16px);
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const SearchBox = styled.div`
  position: absolute;
  bottom: 7px;
  margin-left: 12px;
  width: calc(100% - 12px);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
  color: ${previewGrayMedium};
`

export const fontCss = css`
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: ${white};
`

export const AvatarCss = css`
  width: 20px;
  height: 20px;
  border-radius: 100%;
  -webkit-mask-size: cover;
`

export const Video = css`
  ${CommonVideo}
`

export const PlayButton = css`
  ${CommonPlayButton}
  background-color: rgba(255, 255, 255, 0.8);

  svg {
    color: rgba(0, 0, 0, 0.8);
    fill: rgba(0, 0, 0, 0.8);
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
    svg {
      color: rgba(0, 0, 0, 0.9);
      fill: rgba(0, 0, 0, 0.9);
    }
  }
`

export const PostWrapper = styled(PostWrapperCommon)`
  border: none;
  border-radius: 8px;
  height: 626px;
  background-color: ${black};
`

export const MetadataWrapper = styled.div`
  position: absolute;
  bottom: 18px;
  width: calc(100% - 62px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 12px;
  z-index: 1;
`

export const User = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`

export const Avatar = styled.div<{ src: string; fallbackUrl: string }>`
  ${AvatarCss}
  background: url(${({ src }) => src}) center no-repeat,
    url(${({ fallbackUrl }) => fallbackUrl}) center no-repeat;
  background-size: 20px;
`

export const Caption = styled.div`
  ${fontCss};
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: 6px;
`

export const BoldText = styled.span`
  ${fontCss};
  font-weight: bold;
`

export const More = styled.div`
  ${fontCss};
  display: inline;
  margin-left: 8px;
`

export const UsernameWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Username = styled.span`
  display: inline-block;
  ${fontCss};
  font-weight: 600;
  line-height: 12px;
  margin-left: 6px;
  font-size: 12px;
  margin-right: 6px;
`

export const VideoWrapper = css`
  position: relative;
  width: 100%;
  height: 580px;

  video {
    ${Video}
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.2) 8.17%,
      rgba(0, 0, 0, 0) 20.79%,
      rgba(0, 0, 0, 0) 71.68%,
      rgba(0, 0, 0, 0.2) 83.07%
    );
    pointer-events: none;
  }

  button {
    ${PlayButton}
  }
`

export const VideoPlayer = styled(VideoPlayerCommon)`
  ${VideoWrapper}
`

export const ReelWrapper = styled.div`
  position: relative;
  top: 12px;
`

export const IconsSideSection = styled.div`
  position: absolute;
  right: 12px;
  bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 29px;
  align-items: center;
`

export const IconCss = css`
  margin-left: inherit;
  color: ${white};
  width: 20px;
  height: 20px;
`

export const Dots = styled(DotsIcon)`
  ${IconCss};
`

export const StarOutline = styled(StarOutlineIcon)`
  ${IconCss};
  rotate: 45deg;
`

export const Thumbsup = styled(ThumbsupIcon)`
  ${IconCss};
`

export const CommentRoundOutline = styled(CommentRoundOutlineIcon)`
  ${IconCss};
`

export const ShareArrowOutline = styled(ShareArrowOutlineIcon)`
  ${IconCss};
`

export const Search = styled(SearchIcon)`
  ${IconCss};
  transform: scalex(-1);
  margin-right: 16px;
`

export const World = styled(WorldIcon)`
  margin-left: inherit;
  color: ${white};
  width: 8px;
  height: 8px;
`

export const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 536px;
  top: 12px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`

export const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
`
