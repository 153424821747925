import * as Tooltip from '@radix-ui/react-tooltip'
import React from 'react'
import {
  NotesVisibilityWrapper,
  TooltipArrow,
  TooltipContent,
  WorldIconStyled,
} from './styles'

export const NotesVisibilityIcon = (): JSX.Element => {
  return (
    <Tooltip.Root delayDuration={400}>
      <Tooltip.Trigger asChild>
        <NotesVisibilityWrapper>
          <WorldIconStyled />
        </NotesVisibilityWrapper>
      </Tooltip.Trigger>
      <Tooltip.Portal>
        <TooltipContent side="top" sideOffset={5}>
          <span>
            Notes are shared with all team members who have access to this
            channel
          </span>
          <TooltipArrow />
        </TooltipContent>
      </Tooltip.Portal>
    </Tooltip.Root>
  )
}
