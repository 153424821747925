import React from 'react'
import ComposerActionCreators from '../../../action-creators/ComposerActionCreators'
import { type Draft, DraftMethods } from '../../../entities/Draft'
import {
  ChannelFieldCharacterCount,
  FieldWrapper,
  Input,
  Label,
  Wrapper,
} from '../styles'
import { FirstComment } from './FirstComment'

const LINKEDIN_DOCUMENT_TITLE_MAX_LENGTH = 58

export const LinkedInChannelFields = ({
  draft,
}: {
  draft: Draft
}): JSX.Element => {
  const onTitleChange = (val: string): void => {
    ComposerActionCreators.setLinkedInCarouselTitle(val)
  }

  const title = DraftMethods.getDocumentAttachmentTitle(draft)

  if (!draft.service.isLinkedin()) {
    return <></>
  }

  return (
    <Wrapper>
      {DraftMethods.isLinkedInDocument(draft) && (
        <FieldWrapper>
          <Label className="outer-label" htmlFor="linkedin-document-title">
            Document Title
          </Label>
          <Input
            id="linkedin-document-title"
            name="linkedin-document-title"
            placeholder="Add a descriptive title to make your document more discoverable"
            onChange={(e: React.ChangeEvent<{ value: string }>): void =>
              onTitleChange(e.target.value)
            }
            required
            value={title}
          />
          {title?.length > LINKEDIN_DOCUMENT_TITLE_MAX_LENGTH - 10 && (
            <ChannelFieldCharacterCount
              count={title.length}
              maxCount={LINKEDIN_DOCUMENT_TITLE_MAX_LENGTH}
            />
          )}
        </FieldWrapper>
      )}
      <FirstComment draft={draft} />
    </Wrapper>
  )
}
