import React from 'react'

import { BufferLogo } from './assets/BufferLogo'
import { AITitle } from './assets/AITitle'
import { Wand } from './assets/Wand'
import { Star } from './assets/Star'

import * as Styled from './WelcomeAnimation.styles'

export function WelcomeAnimation({
  beginTransition,
}: {
  beginTransition: boolean
}): JSX.Element {
  return (
    <Styled.OuterWrapper className={beginTransition ? 'fade-out' : ''}>
      <Styled.LogoWrapper>
        <BufferLogo />
      </Styled.LogoWrapper>
      <Styled.TitleWrapper>
        <Styled.AITitleWrapper>
          <Styled.WandGroupWrapper>
            <Star className="star1" />
            <Star className="star2" />
            <Star className="star3" />
            <Wand />
          </Styled.WandGroupWrapper>
          <AITitle />
        </Styled.AITitleWrapper>
      </Styled.TitleWrapper>
      <Styled.GradientBackground />
    </Styled.OuterWrapper>
  )
}
