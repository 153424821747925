import * as Tooltip from '@radix-ui/react-tooltip'
import React from 'react'
import * as Styles from './styles'

type Props = {
  children: React.ReactNode
  content: React.ReactNode
  container?: HTMLElement
  side?: 'top' | 'right' | 'bottom' | 'left'
  sideOffset?: number
  arrow?: boolean
  delay?: number
}

/**
 * Temporary Tooltip component that mimics new Popcorn Tooltip.
 * To be replaced with Popcorn Tooltip once Popcorn is ready to be used.
 * Use this Tooltip component instead of buffer-ui or ReactTooltip tooltips.
 */
export const TempTooltip = ({
  children,
  content,
  container,
  side = 'top',
  sideOffset = 5,
  arrow = false,
  delay = 300,
}: Props): JSX.Element => {
  return (
    <Tooltip.Root delayDuration={delay}>
      <Tooltip.Trigger asChild>{children}</Tooltip.Trigger>
      <Tooltip.Portal container={container}>
        {content && (
          <Styles.Content side={side} sideOffset={sideOffset}>
            {content}
            {arrow && <Styles.Arrow />}
          </Styles.Content>
        )}
      </Tooltip.Portal>
    </Tooltip.Root>
  )
}
