import React, { useState, useEffect } from 'react'
import { Message } from '../../CardFooter/styles'

const SharingStatusText: React.FC = () => {
  const [time, setTime] = useState(30)
  const [progressText, setProgressText] = useState('Uploading your video...')

  useEffect(() => {
    const interval = setInterval(() => {
      setTime((prevTime) => prevTime - 1)
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  }, [])

  useEffect(() => {
    if (time >= 30) {
      setProgressText('Uploading your video...')
    } else if (time >= 25) {
      setProgressText('Illuminating the pixels...')
    } else if (time >= 20) {
      setProgressText('Polishing the frames...')
    } else if (time >= 15) {
      setProgressText('Winding up the Reel...')
    } else if (time >= 10) {
      setProgressText('Checking for blemishes...')
    } else if (time >= 5) {
      setProgressText('Some final touches...')
    }
  }, [time])

  return <Message type="p">{progressText}</Message>
}

export default SharingStatusText
