import { isElement, type TNode } from '@udecode/plate'
import type { PlainText } from '~publish/legacy/editor/BufferEditor/types.plate'
import type { AutocompleteData } from '../../autocomplete'
import type {
  AutocompleteInputElement,
  AutocompleteInputElementInterface,
} from '../../autocomplete/nodes'

export const TWITTER_HASHTAG_INPUT_ELEMENT_TYPE = 'twitter-hashtag-input'
export const TWITTER_HASHTAG_INPUT_ELEMENT_TRIGGER = '#'
export const TWITTER_HASHTAG_INPUT_VALID_TEXT_REGEX = /([a-zA-Z0-9]+)/

export interface TwitterHashtagInputElement extends AutocompleteInputElement {
  type: typeof TWITTER_HASHTAG_INPUT_ELEMENT_TYPE
  trigger: typeof TWITTER_HASHTAG_INPUT_ELEMENT_TRIGGER
  children: [PlainText]
  valid: boolean
  autocompleted: boolean
  data?: AutocompleteData
}

export const TwitterHashtagInputElement: AutocompleteInputElementInterface<TwitterHashtagInputElement> =
  {
    type: TWITTER_HASHTAG_INPUT_ELEMENT_TYPE,
    trigger: TWITTER_HASHTAG_INPUT_ELEMENT_TRIGGER,
    validTextRegex: TWITTER_HASHTAG_INPUT_VALID_TEXT_REGEX,
    validMentionRegex: RegExp(
      `${TWITTER_HASHTAG_INPUT_ELEMENT_TRIGGER}${TWITTER_HASHTAG_INPUT_VALID_TEXT_REGEX.source}`,
    ),
    is(node: TNode): node is TwitterHashtagInputElement {
      return isElement(node) && node.type === TwitterHashtagInputElement.type
    },
    new: (text = '') => {
      return {
        type: TwitterHashtagInputElement.type,
        children: [{ text: `${TwitterHashtagInputElement.trigger}${text}` }],
        valid: true,
        autocompleted: false,
        trigger: TwitterHashtagInputElement.trigger,
      }
    },
  }
