import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import UpdateMediaContent from '../UpdateMediaContent'
import UpdateAttachmentContent from '../UpdateAttachmentContent'
import UpdateTextContent from '../UpdateTextContent'
import RetweetPanel from '../RetweetPanel'

const ContentWrapper = styled.div<{
  isLink: boolean
}>`
  display: flex;
  flex-direction: ${({ isLink }): string => (isLink ? 'column' : 'row')};
`

/* eslint-disable react/prop-types */
// @ts-expect-error TS(7031) FIXME: Binding element 'isLink' implicitly has an 'any' t... Remove this comment to see the full error message
const Content = ({ isLink, isMedia, ...props }) => (
  <ContentWrapper isLink={isLink} className="update-content">
    {/* @ts-expect-error TS(2741) FIXME: Property 'text' is missing in type '{}' but requir... Remove this comment to see the full error message */}
    <UpdateTextContent {...props} />
    {isLink && <UpdateAttachmentContent {...props} />}
    {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{}' but requir... Remove this comment to see the full error message */}
    {isMedia && <UpdateMediaContent {...props} />}
  </ContentWrapper>
)
/* eslint-enable react/prop-types */

const UpdateContent = ({ ...props }) => {
  const { type, retweetProfile } = props
  const isMedia =
    type === 'image' ||
    type === 'video' ||
    type === 'multipleImage' ||
    type === 'document'
  const isLink = type === 'link'

  if (retweetProfile) {
    return (
      // @ts-expect-error TS(2741) FIXME: Property 'retweetProfile' is missing in type '{ ch... Remove this comment to see the full error message
      <RetweetPanel {...props}>
        <Content {...props} isLink={isLink} isMedia={isMedia} />
      </RetweetPanel>
    )
  }

  return <Content {...props} isLink={isLink} isMedia={isMedia} />
}

UpdateContent.propTypes = {
  type: PropTypes.oneOf([
    'text',
    'image',
    'multipleImage',
    'link',
    'video',
    'document',
  ]).isRequired,
  retweetProfile: PropTypes.shape({
    avatarUrl: PropTypes.string,
    handle: PropTypes.string,
    name: PropTypes.string,
  }),
  profileService: PropTypes.string,
  channelData: PropTypes.shape({
    share_to_feed: PropTypes.string,
  }),
}

UpdateContent.defaultProps = {
  retweetProfile: undefined,
  profileService: '',
  channelData: null,
}

export default UpdateContent
