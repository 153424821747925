import {
  purple,
  pink,
  pinkLighter,
  orange,
  yellow,
  green,
  teal,
  blueDark,
  black,
  red,
  purpleLighter,
  redLighter,
  orangeLighter,
  yellowLighter,
  greenLighter,
  tealLighter,
  boxShadow,
  gray,
} from '@bufferapp/ui/style/colors'
import type { Tag } from '~publish/pages/Create/types'

export const colors = [
  { color: purple, colorName: 'purple' },
  { color: pink, colorName: 'pink' },
  { color: red, colorName: 'red' },
  { color: orange, colorName: 'orange' },
  { color: yellow, colorName: 'yellow' },
  { color: green, colorName: 'green' },
  { color: teal, colorName: 'teal' },
  { color: blueDark, colorName: 'blue dark' },
  { color: black, colorName: 'black' },
  { color: purpleLighter, colorName: 'purple lighter' },
  { color: pinkLighter, colorName: 'pink lighter' },
  { color: redLighter, colorName: 'red lighter' },
  { color: orangeLighter, colorName: 'orange lighter' },
  { color: yellowLighter, colorName: 'yellow lighter' },
  { color: greenLighter, colorName: 'green lighter' },
  { color: tealLighter, colorName: 'teal lighter' },
  { color: boxShadow, colorName: 'lighter blue' },
  { color: gray, colorName: 'gray' },
]

const getColorsFromTags = (tags: Tag[]): Record<string, boolean> => {
  const colorMap: Record<string, boolean> = {}
  tags.forEach((tag) => {
    colorMap[tag.color] = true
  })
  return colorMap
}

export const getColorToPreselect = (tags: Tag[]): string => {
  const usedColors = getColorsFromTags(tags)
  const unusedColor = colors.find((color) => {
    const colorValue = color.color
    return !usedColors[colorValue]
  })
  if (unusedColor) {
    return unusedColor.color
  }
  const randomIndex = Math.floor(Math.random() * colors.length)
  const randomColor = colors[randomIndex]
  return randomColor.color
}
