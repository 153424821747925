import * as ValidatorFactory from '../lib/validation/ValidatorFactory'
import type { Draft } from '../entities/Draft'
import type { ValidationFeedback } from './types'

type Input = { drafts: Draft[]; isDraft: boolean; activeThreadId?: number }
export const getDraftsFeedback = ({
  drafts,
  isDraft = false,
  activeThreadId = undefined,
}: Input): ValidationFeedback[] => {
  return drafts.reduce<ValidationFeedback[]>((invalidDraftsFeedback, draft) => {
    const validationErrors = ValidatorFactory.validate(
      draft,
      isDraft,
      activeThreadId,
    ).toObject()

    if (validationErrors.length > 0) {
      return [...invalidDraftsFeedback, ...validationErrors]
    }
    return invalidDraftsFeedback
  }, [])
}

export const isASmallScreen = (): boolean => {
  return window.innerWidth <= 1464
}
