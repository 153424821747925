import { connect } from 'react-redux'
import { actions as modalActions } from '~publish/legacy/modals/reducer'
import DeleteCampaignModal from './components/DeleteCampaignModal'
import { actions } from './reducer'

export default connect(
  (state) => ({
    // @ts-expect-error TS(2339) FIXME: Property 'deleteCampaignModal' does not exist on t... Remove this comment to see the full error message
    loading: state.deleteCampaignModal.loading,
    // @ts-expect-error TS(2339) FIXME: Property 'deleteCampaignModal' does not exist on t... Remove this comment to see the full error message
    campaign: state.deleteCampaignModal.campaign,
  }),
  (dispatch) => ({
    hideModal: () => dispatch(modalActions.hideDeleteCampaignModal()),
    deleteCampaign: () => dispatch(actions.deleteCampaign()),
  }),
)(DeleteCampaignModal)
export { default as reducer, actions, actionTypes } from './reducer'
export { default as middleware } from './middleware'
