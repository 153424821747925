/**
 * Creates an array of numbers (positive and/or negative) progressing from start up to,
 * but not including, end. If end is not specified, it's set to start with start then set to 0.
 * @example
 * ```tsx
 * // will render 5 stars
 * range(5).map(i => <Star key={i} />)
 * ```
 */
export const range = (start = 0, end?: number, step = 1): number[] => {
  const output: number[] = []

  if (typeof end === 'undefined') {
    end = start
    start = 0
  }

  for (let i = start; i < end; i += step) {
    output.push(i)
  }

  return output
}
