import { type AnyObject, createPluginFactory } from '@udecode/plate'
import type { BufferValue } from '~publish/legacy/editor/BufferEditor/types.plate'
import {
  ERROR_REPORTING_PLUGIN_KEY,
  withErrorReporting,
} from '~publish/legacy/editor/plugins/error-reporting/withErrorReporting'

export const createErrorReportingPlugin = createPluginFactory<
  AnyObject,
  BufferValue
>({
  key: ERROR_REPORTING_PLUGIN_KEY,
  withOverrides: withErrorReporting,
})
