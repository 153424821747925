import React from 'react'
import PropTypes from 'prop-types'
import { NavLink as Link, useRouteMatch } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { blue, grayDarker, grayDefault } from '@bufferapp/ui/style/colors'
import {
  fontSize,
  fontSizeSmall,
  fontWeightMedium,
  fontWeight,
} from '@bufferapp/ui/style/fonts'

const NavItemWrapper = styled.li<{ selected?: boolean; secondary?: boolean }>`
  transform: translate(0, 1px);
  margin: 0 22px 0 0;
  display: inline-block;
  min-width: 60px;
  text-align: center;
  border-bottom-color: ${(props): string => (props.selected ? blue : 'unset')};
  border-bottom-style: ${(props): string =>
    props.selected ? 'solid' : 'none'};
  border-bottom-width: ${(props): string => (props.secondary ? '1px' : '2px')};
`

const navItemStyles = css<{
  selected?: boolean
  $secondary?: boolean
  disabled?: boolean
}>`
  height: ${(props): string => (props.$secondary ? '38px' : '52px')};
  padding: 0 13px;
  justify-content: center;
  display: flex;
  align-items: center;
  text-decoration: none;
  pointer-events: ${(props): string => (props.disabled ? 'none' : 'auto')};
  color: ${(props): string => (props.selected ? grayDarker : grayDefault)};
  font-size: ${(props): string =>
    props.$secondary ? fontSizeSmall : fontSize};
  font-weight: ${(props): number =>
    props.selected ? fontWeightMedium : fontWeight};
  :hover {
    color: ${grayDarker};
  }
`

const NavRouteItem = styled(Link)`
  ${navItemStyles}
`

const FakeItem = styled.span`
  ${navItemStyles}
`

const NavExternalItem = styled.a`
  ${navItemStyles}
`

type NavLinkProps = {
  children: React.ReactNode
  to?: string
  activeOnlyWhenExact?: boolean
  disabled?: boolean
  secondary?: boolean
  href?: string
  hasSubMenu?: boolean
  forceSelect?: boolean
  testId?: string
  id?: string
}

const NavLink = ({
  children,
  to,
  activeOnlyWhenExact,
  disabled,
  secondary,
  href,
  hasSubMenu,
  forceSelect,
  testId,
  id,
}: NavLinkProps): JSX.Element => {
  const match = useRouteMatch({
    path: to,
    exact: activeOnlyWhenExact,
  })

  const NavItem = (): JSX.Element => {
    // NavItem opening external page
    if (href && !to)
      return (
        <NavExternalItem $secondary={secondary} href={href} id={id}>
          {children}
        </NavExternalItem>
      )
    // Disabled/Fake NavItem, for disabled queue
    if (disabled)
      return (
        <FakeItem selected={forceSelect} disabled={disabled} id={id}>
          {children}
        </FakeItem>
      )
    // Regular NavItem
    return (
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      <NavRouteItem
        selected={match}
        $secondary={secondary}
        to={to}
        aria-haspopup={hasSubMenu ? true : undefined}
        data-testid={testId}
      >
        {children}
      </NavRouteItem>
    )
  }

  return (
    // @ts-expect-error TS(2769) FIXME: No overload matches this call.
    <NavItemWrapper id={id} selected={forceSelect || match}>
      <NavItem />
    </NavItemWrapper>
  )
}

NavLink.propTypes = {
  children: PropTypes.node.isRequired,
  to: PropTypes.string,
  activeOnlyWhenExact: PropTypes.bool,
  disabled: PropTypes.bool,
  secondary: PropTypes.bool,
  href: PropTypes.string,
  hasSubMenu: PropTypes.bool,
  forceSelect: PropTypes.bool,
  testId: PropTypes.string,
}

NavLink.defaultProps = {
  to: '',
  href: '',
  activeOnlyWhenExact: true,
  disabled: false,
  secondary: false,
  hasSubMenu: false,
  forceSelect: false,
  testId: undefined,
  id: undefined,
}

export default NavLink
