import React, { useCallback, useState } from 'react'
import { formatDistance } from 'date-fns'
import { Link as RRLink } from 'react-router-dom'

import {
  toast,
  Heading,
  Text,
  Separator,
  Flex,
  Image,
  IconButton,
  ExternalLinkIcon,
  IdeasIcon,
  FileTextIcon,
  SettingsIcon,
  Link,
} from '@buffer-mono/popcorn'
import { useCreateIdea } from '~publish/components/IdeaComposer/useCreateIdea'
import { usePostComposer } from '~publish/hooks/usePostComposer'

import type { RSSFeedItem } from '../../types'
import { useFeed } from '../../hooks/useFeed'
import { getFeedGroupById } from '../../hooks/mockFeedGroups'
import { EditFeedGroup } from '../EditFeedGroup/EditFeedGroup'
import styles from './ContentFeed.module.css'

type ContentFeedProps = {
  feedGroupId: string
}

export const ContentFeed = (props: ContentFeedProps): JSX.Element => {
  const [showFeedGroupSettings, setShowFeedGroupSettings] = useState(false)
  const feed = useFeed(props.feedGroupId)
  const feedGroup = getFeedGroupById(props.feedGroupId)

  const { createIdea } = useCreateIdea({
    onCompleted: (data) => {
      const idea = data?.createIdea
      if (idea && 'id' in idea) {
        toast.success(
          <>
            <div>Your idea has been saved.</div>
            <Link as={RRLink} to={`/create/ideas/${idea.id}/edit`}>
              View idea
            </Link>
          </>,
        )
      } else {
        toast.error('Something went wrong creating the idea')
      }
    },
    onError: (): void => {
      toast.error('Something went wrong creating the idea')
    },
  })
  const { createNewPostInComposer } = usePostComposer()

  const handleCreatePost = useCallback(
    (item: RSSFeedItem): void => {
      createNewPostInComposer({
        cta: 'publish-composer-createPost-default-1',
        channels: 'all',
        prefillPostData: {
          text: item.description + ' ' + item.url,
        },
      })
    },
    [createNewPostInComposer],
  )

  const handleSaveAsIdea = useCallback(
    (item: RSSFeedItem): void => {
      // TODO: add media
      createIdea({
        content: {
          title: item.title,
          text: `${item.description}\n\n${item.url}`,
          aiAssisted: false,
          tags: [],
        },
      })
    },
    [createIdea],
  )

  if (!feed) {
    return <div>Feed not found</div>
  }

  for (const item of feed.content) {
    try {
      item.pubDate.toISOString()
    } catch (e) {
      console.error('Error parsing pubDate', item.source, item.pubDate)
    }
  }

  return (
    <div className={styles.container}>
      <EditFeedGroup
        open={showFeedGroupSettings}
        onClose={(): void => setShowFeedGroupSettings(false)}
        feedGroup={feedGroup}
      />
      <div className={styles.scrollContainer}>
        <Flex gap="md" align="center">
          <Heading size="large">{feed.group.name}</Heading>
          <IconButton
            variant="tertiary"
            label="Settings"
            tooltip="Settings"
            onClick={(): void => setShowFeedGroupSettings(true)}
          >
            <SettingsIcon />
          </IconButton>
        </Flex>
        <ul className={styles.contentList}>
          {feed.content.map((item, index) => (
            <li key={item.url}>
              {index !== 0 && (
                <div className={styles.dividerContainer}>
                  <Separator />
                </div>
              )}
              <article className={styles.articleLayout}>
                {item.imgUrl && (
                  <Image
                    className={styles.articleThumbnail}
                    src={item.imgUrl ?? ''}
                    alt={item.title}
                  />
                )}
                <div className={styles.articleHeader}>
                  <div className={styles.headerContainer}>
                    <header className={styles.articleHeader}>
                      <Heading size="small">{item.title}</Heading>
                      <Text size="sm">
                        <Flex gap="xs" align="center">
                          {item.source.imageUrl && (
                            <Image
                              className={styles.sourceImage}
                              src={item.source.imageUrl}
                              alt={item.source.title}
                            />
                          )}
                          <span>{item.source.title}</span>
                          <span>|</span>
                          <time dateTime={item.pubDate.toISOString()}>
                            {formatDistance(item.pubDate, new Date(), {
                              addSuffix: true,
                            }).replace('about ', '')}
                          </time>
                        </Flex>
                      </Text>
                    </header>
                    <Flex gap="xs" align="center">
                      <IconButton
                        tooltip="Open Link"
                        label="Open Link"
                        variant="tertiary"
                        as="a"
                        href={item.url}
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        <ExternalLinkIcon />
                      </IconButton>
                      <IconButton
                        tooltip="Create New Post"
                        label="Create New Post"
                        variant="tertiary"
                        onClick={(): void => handleCreatePost(item)}
                      >
                        <FileTextIcon />
                      </IconButton>
                      <IconButton
                        tooltip="Save as Idea"
                        label="Save as Idea"
                        variant="tertiary"
                        onClick={(): void => handleSaveAsIdea(item)}
                      >
                        <IdeasIcon />
                      </IconButton>
                    </Flex>
                  </div>
                  <Text className={styles.articleDescription}>
                    {item.description}
                  </Text>
                </div>
              </article>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
