import { createAsyncThunk } from '@reduxjs/toolkit'
import { client, gql } from '~publish/legacy/apollo-client'
import type { AppDispatch, RootState } from '~publish/legacy/store'

import { selectCurrentOrganizationId } from '~publish/legacy/organizations/selectors'
import { selectGenerateContentLimits } from '~publish/legacy/ai/state/aiLimits/slice'

type TGenerateIdeasProps = {
  prompt: string
  cta: string
}

type TGenerateIdeasVariables = {
  organizationId: string
  config: {
    prompt: string
    cta: string
  }
}

type TSuggestion = {
  content: string
}

type TResponse = {
  suggestions: TSuggestion[]
  code?: string
  success: boolean
  message: string
}

const GENERATE_IDEAS_MUTATION = gql`
  mutation generateIdeas(
    $organizationId: String!
    $config: ContentGenerationInput!
  ) {
    generateIdeas(organizationId: $organizationId, config: $config) {
      ... on TextGenerated {
        suggestions {
          content
        }
      }
      ... on CommonError {
        code
        success
        message
        error
      }
    }
  }
`

const generateIdeas = createAsyncThunk<
  unknown,
  TGenerateIdeasProps,
  { state: RootState; dispatch: AppDispatch }
>(
  'ai/generateIdeas',
  async ({ prompt, cta }, { rejectWithValue, getState }) => {
    const state = getState()

    const { canGenerateContent } = selectGenerateContentLimits(state)
    if (!canGenerateContent) return

    try {
      const response = await client.mutate<
        {
          generateIdeas: TResponse
        },
        TGenerateIdeasVariables
      >({
        mutation: GENERATE_IDEAS_MUTATION,
        variables: {
          organizationId: selectCurrentOrganizationId(state),
          config: {
            prompt,
            cta,
          },
        },
      })

      return response.data?.generateIdeas
    } catch (error) {
      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      throw rejectWithValue(error.message)
    }
  },
)

export { GENERATE_IDEAS_MUTATION, generateIdeas, TSuggestion, TResponse }
