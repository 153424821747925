import React from 'react'

import { useSplitEnabled } from '@buffer-mono/features'

import Draft from '~publish/legacy/shared-components/Draft'
import { PostEntity } from '~publish/legacy/post/PostEntity'
import type { RpcPostContent, RpcUpdate } from '~publish/legacy/post/types'
import { ProfilesDraftFooter } from '../ProfilesDraftFooter'
import PostTags from '~publish/legacy/shared-components/PostTags'
import { usePostDetails } from '~publish/legacy/post/hooks/usePostDetails'

interface ProfilesDraftProps {
  view: string
  post: RpcUpdate
  basic: boolean
  geolocationName: string
  postContent: RpcPostContent
  profileService: string
  isConfirmingDelete: boolean
  isDeleting: boolean
  isWorking: boolean
  isMoving: boolean
  isCampaign: boolean
  draftDetails: {
    draftId: string
    postAction: string
    creatorName: string
    avatarUrl: string
    createdAt: string
  }
  campaignDetails: {
    id: string
    name: string
    color: string
  }
  channelData: {
    googlebusiness: {
      cta: string
      link: string
      title: string
      start_date: number
      end_date: number
      start_time: string
      end_time: string
      code: string
      terms: string
    }
    instagram: {
      share_to_feed: boolean
    }
    startPage?: {
      cta: string
      link: string
      title: string
      start_date: number
      end_date: number
      start_time: string
      end_time: string
    }
  }
  onDeleteConfirmClick: () => void
  onApproveClick: () => void
  onRevertApprovalClick: () => void
  onRequestApprovalClick: () => void
  onRescheduleClick: () => void
  onEditClick: () => void
  hasCampaignsFeature: boolean
}

export const ProfilesDraft = ({
  view,
  basic,
  geolocationName,
  profileService,
  postContent,
  isDeleting,
  isConfirmingDelete,
  isMoving,
  isWorking,
  isCampaign,
  onApproveClick,
  onDeleteConfirmClick,
  onEditClick,
  onRevertApprovalClick,
  onRequestApprovalClick,
  onRescheduleClick,
  channelData,
  post,
}: ProfilesDraftProps): JSX.Element => {
  const { isEnabled: isRemindersEnabled } = useSplitEnabled('CORE-reminders')

  // There is not perfect way to know if an old reminder is effectively a reminder.
  // For future reminders, we can use the field isReminder
  const isReminder = isRemindersEnabled
    ? PostEntity.isReminder(post) ?? PostEntity.isInstagramReminder(post)
    : PostEntity.isInstagramReminder(post)

  const { postAction, ...headerDetails } = usePostDetails(
    post,
    isCampaign,
    isReminder,
  )

  return (
    <Draft
      postId={post.id}
      isReminder={isReminder}
      basic={basic}
      geolocationName={geolocationName}
      postContent={postContent}
      profileService={profileService}
      headerDetails={headerDetails}
      channelData={channelData}
      annotations={post?.annotations}
      notes={post.notes}
    >
      <PostTags tags={post.tags || []} dragging={false} isSent={false} />
      <ProfilesDraftFooter
        view={view}
        draft={post}
        postAction={postAction}
        isDeleting={isDeleting}
        isConfirmingDelete={isConfirmingDelete}
        isMoving={isMoving}
        isWorking={isWorking}
        onApproveClick={onApproveClick}
        onDeleteConfirmClick={onDeleteConfirmClick}
        onEditClick={onEditClick}
        onRevertApprovalClick={onRevertApprovalClick}
        onRequestApprovalClick={onRequestApprovalClick}
        onRescheduleClick={onRescheduleClick}
        isReminder={isReminder}
      />
    </Draft>
  )
}

ProfilesDraft.defaultProps = {
  basic: false,
  geolocationName: null,
  isConfirmingDelete: false,
  isDeleting: false,
  isWorking: false,
  isMoving: false,
  isCampaign: false,
  channelData: null,
  onDeleteConfirmClick: null,
  onApproveClick: null,
  onRevertApprovalClick: null,
  onRequestApprovalClick: null,
  onRescheduleClick: null,
  onEditClick: null,
  hasCampaignsFeature: false,
}
