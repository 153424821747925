import { comboboxActions, findNode, getEditorString } from '@udecode/plate'
import type {
  BufferEditor,
  BufferValue,
} from '~publish/legacy/editor/BufferEditor/types.plate'
import type { AutocompleteItem } from '../../autocomplete'
import { FacebookMentionInputElement } from '../nodes'
import {
  type FacebookMentionResponseItem,
  getFacebookPrivateSuggestions,
} from './getFacebookPrivateSuggestions'

export function parseSuggestions(
  suggestions: FacebookMentionResponseItem[],
): AutocompleteItem[] {
  return suggestions?.map((mention) => ({
    key: mention.id,
    text: mention.name,
    data: {
      id: mention.id,
      handle: `@${mention.id}`,
      name: mention.name,
      avatar: `https://graph.facebook.com/${mention.id}/picture?type=square`,
      url: mention.link,
    },
  }))
}

/**
 * Callback executed to fetch new Twitter mention suggestions.
 *
 * Updates combobox items on success.
 */
export const getNewSuggestionsOnMentionUpdate = (editor: BufferEditor) => {
  const mentionEntry = findNode<FacebookMentionInputElement, BufferValue>(
    editor,
    { match: (n) => FacebookMentionInputElement.is(n) },
  )

  const editorComposerData = editor.getEditorComposerData?.(editor)
  const profileId = editorComposerData?.selectedProfilesForService[0]?.id

  if (mentionEntry && profileId) {
    const searchString = getEditorString(editor, mentionEntry[1]).replace(
      FacebookMentionInputElement.trigger,
      '',
    )

    comboboxActions.items([])
    getFacebookPrivateSuggestions(searchString, profileId).then(
      (searchResults) => {
        if (searchResults) {
          const suggestions = parseSuggestions(searchResults)
          comboboxActions.items(suggestions)
        }
      },
    )
  }
}
