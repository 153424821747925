import React, { useEffect, useMemo, useState } from 'react'

import { ChannelNavItem } from './ChannelNavItem'
import type { ChannelNavChannel } from '../useChannels'
import {
  ChannelIcon,
  ChevronDownIcon,
  EmptyState,
  SearchIcon,
  Sidebar,
  Skeleton,
  SkeletonText,
} from '@buffer-mono/popcorn'
import { AllChannelsItem } from './AllChannelsItem'
import { useIsAdmin } from '~publish/legacy/accountContext'
import type { Service } from '~publish/gql/graphql'
import { getChannelColor, getChannelName } from '~publish/helpers/channels'
import { useCta } from '~publish/hooks/useCta'
import { useConnectChannel } from '~publish/hooks/useConnectChannel'

import {
  filterChannels,
  getChannelSuggestions,
  useSearchTracking,
} from './helpers'
import { NewChannelButton } from './NewChannelButton'
import { ChannelsDndProvider } from './ChannelsDndProvider'

import styles from './ChannelNav.module.css'

interface ChannelNavProps {
  channels: ChannelNavChannel[]
  searching: boolean
  searchQuery: string
  onReorder: (dragIndex: number, hoverIndex: number) => void
}

export const ChannelNavSkeleton = (): JSX.Element => {
  return (
    <Sidebar.List>
      {Array.from({ length: 5 }).map((_, index) => (
        <Sidebar.ListItem key={index}>
          <Sidebar.ButtonSkeleton
            prefix={<Skeleton width={32} height={32} rounded />}
            size="large"
          >
            <SkeletonText lines={1} width={86} />
          </Sidebar.ButtonSkeleton>
        </Sidebar.ListItem>
      ))}
    </Sidebar.List>
  )
}

export function ChannelNav({
  channels,
  searching,
  searchQuery,
  onReorder,
}: ChannelNavProps): JSX.Element {
  const isAdmin = useIsAdmin()
  const disableDragAndDrop = !!searchQuery

  const channelConnectionSuggestions = getChannelSuggestions(channels)
  useSearchTracking(searchQuery, channels)

  const displayedChannels = useMemo(() => {
    if (!searchQuery) {
      return []
    }
    return filterChannels(channels, searchQuery)
  }, [channels, searchQuery])

  if (searching) {
    return (
      <ChannelsDndProvider
        channels={channels}
        disabled={disableDragAndDrop}
        onReorder={onReorder}
      >
        <Sidebar.List>
          {displayedChannels.map((channel) => (
            <ChannelNavItem channel={channel} key={channel.id} dragDisabled />
          ))}
        </Sidebar.List>
        {!searchQuery && (
          <EmptyState size="small">
            <EmptyState.Description color="subtle">
              Start typing to search channels.
            </EmptyState.Description>
          </EmptyState>
        )}
        {searchQuery && displayedChannels.length === 0 && (
          <EmptyState size="small">
            <EmptyState.Icon>
              <SearchIcon />
            </EmptyState.Icon>
            <EmptyState.Heading>No channels found</EmptyState.Heading>
            <EmptyState.Description>
              Try searching for a different channel name.
            </EmptyState.Description>
          </EmptyState>
        )}
      </ChannelsDndProvider>
    )
  }

  return (
    <ChannelsDndProvider
      channels={channels}
      disabled={disableDragAndDrop}
      onReorder={onReorder}
    >
      <Sidebar.List>
        <AllChannelsItem />
        {channels.map((channel) => (
          <ChannelNavItem
            channel={channel}
            key={channel.id}
            dragDisabled={disableDragAndDrop}
          />
        ))}
      </Sidebar.List>
      {isAdmin && channelConnectionSuggestions ? (
        <ChannelSuggestions services={channelConnectionSuggestions} />
      ) : null}
      {isAdmin && !channelConnectionSuggestions ? (
        <Sidebar.List>
          <NewChannelButton />
        </Sidebar.List>
      ) : null}
    </ChannelsDndProvider>
  )
}

const ChannelSuggestions = ({
  services,
}: {
  services: Service[]
}): JSX.Element => {
  const [showAll, setShowAll] = useState(false)

  const connectChannel = useConnectChannel({
    cta: 'publish-sidebar-channels-addChannelSuggestions-1',
  })

  const { viewed: ctaViewed, clicked: ctaClicked } = useCta(
    'publish-sidebar-channels-addChannelSuggestions-1',
    {
      upgradePathName: 'addChannel-upgrade',
    },
  )

  useEffect(() => {
    ctaViewed()
  }, [ctaViewed])

  const onChannelClick = (channelService: Service): void => {
    ctaClicked()
    connectChannel(channelService)
  }

  const displayServices = showAll ? services : services.slice(0, 3)
  const showMore = services.length > displayServices.length && !showAll

  return (
    <>
      <Sidebar.List>
        {displayServices.map((service) => (
          <Sidebar.ListItem key={service}>
            <Sidebar.Button
              size="large"
              className={styles.channelSuggestion}
              style={
                {
                  '--channel-color': getChannelColor(service),
                } as React.CSSProperties
              }
              prefix={
                <span className={styles.icon}>
                  <ChannelIcon type={service} />
                </span>
              }
              onClick={(): void => onChannelClick(service)}
            >
              Connect {getChannelName(service)}
            </Sidebar.Button>
          </Sidebar.ListItem>
        ))}
      </Sidebar.List>
      {showMore ? (
        <Sidebar.List>
          <Sidebar.ListItem>
            <Sidebar.Button
              size="large"
              prefix={
                <span className={styles.icon}>
                  <ChevronDownIcon />
                </span>
              }
              className={styles.showMoreSuggestionsButton}
              onClick={(): void => setShowAll(!showAll)}
            >
              Show more channels
            </Sidebar.Button>
          </Sidebar.ListItem>
        </Sidebar.List>
      ) : null}
    </>
  )
}
