import { Button } from '@bufferapp/ui'
import PropTypes from 'prop-types'
import React from 'react'
import { useAccount } from '../../../accountContext'
import { navigateToMigrationHub } from '../../helpersUpgradePaths'
import { URLS } from '~publish/legacy/missing-access-page/utils'

interface UpgradeClickProps {
  cta: string
  ctaMPMigration?: string
  upgradePathName?: string
}
interface UpgradePathProps {
  children: (onUpgradeClick: (props: UpgradeClickProps) => void) => JSX.Element
  shouldShowUpgradeButton: boolean
  shouldPickModalOnOrganizationState: boolean
}

const UpgradePath = ({
  children,
  shouldShowUpgradeButton = false,
  shouldPickModalOnOrganizationState = false,
}: UpgradePathProps): JSX.Element => {
  // Get user data from account query
  const user = useAccount()
  const { isOneBufferOrganization } = user?.account?.currentOrganization || {}

  if (!shouldShowUpgradeButton)
    return (
      // @ts-expect-error TS(2740)
      <Button
        type="primary"
        label="Contact support to upgrade your plan"
        onClick={(): void => {
          window.open(URLS.CONTACT_SUPPORT_URL, '_blank')
        }}
        fullWidth
      />
    )

  const onUpgradeClick = ({
    cta,
    ctaMPMigration,
    upgradePathName,
  }: UpgradeClickProps): void => {
    if (isOneBufferOrganization) {
      const { MODALS, actions } = window?.appshell || {}
      actions.openModal(MODALS.subscriptionUpdate, {
        cta,
        shouldPickModalOnOrganizationState,
        upgradePathName,
      })
    } else {
      navigateToMigrationHub(ctaMPMigration)
    }
  }

  return <div>{children(onUpgradeClick)}</div>
}

UpgradePath.propTypes = {
  children: PropTypes.func.isRequired,
  shouldShowUpgradeButton: PropTypes.bool,
  shouldPickModalOnOrganizationState: PropTypes.bool,
}

UpgradePath.defaultProps = {
  shouldShowUpgradeButton: false,
  shouldPickModalOnOrganizationState: false,
}

export default UpgradePath
