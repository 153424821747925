import styled, { type CSSProp, css } from 'styled-components'
import {
  grayDark,
  grayDarker,
  grayLight,
  purpleLightest,
  purpleLight,
  purpleDarker,
} from '@bufferapp/ui/style/colors'
import { fontFamily } from '@bufferapp/ui/style/fonts'

import { VerticalWrapperStyles } from '~publish/legacy/ai/styles/VerticalWrapper'

export const Wrapper = styled.div`
  ${VerticalWrapperStyles}
  padding: 0;
  height: 100%;
`

export const Form = styled.form<{ withBorder?: boolean }>`
  ${VerticalWrapperStyles}

  ${(props): CSSProp | null =>
    props.withBorder
      ? css`
          padding-bottom: 24px;
          border-bottom: 1px solid ${grayLight};
          margin-bottom: 8px;
        `
      : null}
`

export const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`

export const CancelButton = styled.button`
  font-family: ${fontFamily};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: ${grayDark};
  border: none;
  background: none;
  cursor: pointer;

  &:hover {
    color: ${grayDarker};
  }
`

export const Warning = styled.div`
  padding: 8px;
  margin: 8px 0;
  border-radius: 4px;
  border: 1px solid ${purpleLightest};
  background: #f6edff;
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;

  svg {
    color: ${purpleLight};
    fill: ${purpleLight};
  }

  p {
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: ${purpleDarker};
    margin: 0;
    padding: 0;
  }

  span {
    font-weight: 700;
    margin-right: 4px;
  }
`

export const PromptWrapper = styled.div`
  ${VerticalWrapperStyles}
  margin-bottom: 16px;
`
