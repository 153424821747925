import {
  getFileMimeType,
  getMediaTypeFromMime,
} from '~publish/legacy/uploads/utils'
import { MediaType } from '../../../constants'

export const hasMixedMediaTypes = (files: File[]): boolean => {
  const fileMediaTypes = files.map((file) =>
    getMediaTypeFromMime(getFileMimeType(file)),
  )

  const uniqueFileMediaTypes = new Set(fileMediaTypes)
  uniqueFileMediaTypes.delete(MediaType.UNSUPPORTED)

  return uniqueFileMediaTypes.size > 1
}
