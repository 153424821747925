import { Flex, Heading } from '@buffer-mono/popcorn'
import React from 'react'
import { CleanQueueAction } from './components/CleanQueueAction'
import { EmptyQueueAction } from './components/EmptyQueueAction'
import { PauseQueueAction } from './components/PauseQueueAction'
import { ShuffleQueueAction } from './components/ShuffleQueueAction'
import styles from './QueueActions.module.css'

const QueueActions = (): JSX.Element => (
  <Flex
    direction="column"
    gap="lg"
    className={styles.queueActionsWrapper}
    data-testid="queue-actions-wrapper"
  >
    <Heading size="small" as="h3">
      Queue Actions
    </Heading>
    <PauseQueueAction />
    <ShuffleQueueAction />
    <CleanQueueAction />
    <EmptyQueueAction />
  </Flex>
)

export { QueueActions }
