import { gql, useMutation } from '@apollo/client'
import { GET_ACCOUNT } from '~publish/legacy/accountContext'

const ACCOUNT_SET_SETTINGS = gql`
  mutation accountSetSettings($settings: SettingsInput!) {
    accountSetSettings(settings: $settings) {
      ... on AccountSetSettingsResponse {
        success
        settings {
          aiSettings {
            onboarded
          }
        }
      }
      ... on AccountSetSettingsError {
        userFriendlyMessage
        cause
      }
    }
  }
`

export const useAccountSetSettings = () => {
  const [
    accountSetSettings,
    {
      data: accountSetSettingsData,
      error: accountSetSettingsError,
      loading: accountSetSettingsLoading,
    },
  ] = useMutation(ACCOUNT_SET_SETTINGS, {
    refetchQueries: [GET_ACCOUNT],
  })

  return {
    accountSetSettings,
    accountSetSettingsData,
    accountSetSettingsError,
    accountSetSettingsLoading,
  }
}
