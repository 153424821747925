import styled, { css, type CSSProp } from 'styled-components'
import { gray, grayDark, grayDarker } from '@bufferapp/ui/style/colors'
import { fontFamily } from '@bufferapp/ui/style/fonts'

export const Button = styled.button<{ withBorder?: boolean }>`
  background: none;
  border-radius: 4px;
  fontfamily: ${fontFamily};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  display: flex;
  flex-direction: row;
  gap: 4px;
  padding: 8px;
  transition-property: color;
  transition-duration: 0.1s;
  transition-timing-function: ease-in;
  border: ${({ withBorder }): string =>
    withBorder ? `1px solid ${gray}` : 'none'};

  &:hover:enabled {
    border-color: ${grayDark};
  }

  ${({ disabled }): CSSProp => getStyles({ disabled })};
`

function getStyles({ disabled }: { disabled?: boolean }): CSSProp {
  const fontColor = disabled ? gray : grayDark

  return css`
    color: ${fontColor};
    cursor: ${disabled ? 'not-allowed' : 'pointer'};

    svg {
      color: ${fontColor};
      fill: ${fontColor};
    }

    &:hover:enabled {
      color: ${grayDarker};
    }

    svg:hover:enabled {
      color: ${grayDarker};
      fill: ${grayDarker};
    }
  `
}
