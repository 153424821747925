import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Cross } from '@bufferapp/ui/Icon'
import { StyledWrapper, CloseButton } from './styles'

const ComposerSidepanel = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'isVisible' implicitly has an 'any... Remove this comment to see the full error message
  isVisible,
  // @ts-expect-error TS(7031) FIXME: Binding element 'showCloseButton' implicitly has a... Remove this comment to see the full error message
  showCloseButton,
  // @ts-expect-error TS(7031) FIXME: Binding element 'closeButtonPosition' implicitly h... Remove this comment to see the full error message
  closeButtonPosition,
  // @ts-expect-error TS(7031) FIXME: Binding element 'customStyles' implicitly has an '... Remove this comment to see the full error message
  customStyles,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onClose' implicitly has an 'any' ... Remove this comment to see the full error message
  onClose,
  // @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
  children,
  // @ts-expect-error TS(7031) FIXME: Binding element 'animated' implicitly has an 'any'... Remove this comment to see the full error message
  animated,
  // @ts-expect-error TS(7031) FIXME: Binding element 'state' implicitly has an 'any'... Remove this comment to see the full error message
  state,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onAnimationEnd' implicitly has an 'any'... Remove this comment to see the full error message
  onAnimationEnd,
  // @ts-expect-error TS(7031) FIXME: Binding element 'side' implicitly has an 'any'... Remove this comment to see the full error message
  side,
}) => {
  const [animation, setAnimation] = useState('')

  const onAnimationEndCallback = useCallback(() => {
    onAnimationEnd && onAnimationEnd()
  }, [onAnimationEnd])

  useEffect(() => {
    if (animated && state) setAnimation(state)
    const timeoutId = setTimeout(onAnimationEndCallback, 300)
    return (): void => clearTimeout(timeoutId)
  }, [state])

  return (
    <StyledWrapper
      data-testid="composer-sidepanel"
      className={`${side} ${animation}`}
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      $isVisible={isVisible}
      $customStyles={customStyles}
    >
      {showCloseButton && (
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        <CloseButton position={closeButtonPosition}>
          <Cross size="medium" onClick={onClose} data-testid="close-button" />
        </CloseButton>
      )}
      {children}
    </StyledWrapper>
  )
}

ComposerSidepanel.propTypes = {
  isVisible: PropTypes.bool,
  // Pass any additional style properties you want applied
  customStyles: PropTypes.shape({}),
  showCloseButton: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
  closeButtonPosition: PropTypes.shape({
    top: PropTypes.string,
    right: PropTypes.string,
  }),
  animated: PropTypes.bool,
  state: PropTypes.string,
  onAnimationEnd: PropTypes.func,
  side: PropTypes.string,
}

ComposerSidepanel.defaultProps = {
  isVisible: false,
  customStyles: {},
  showCloseButton: false,
  closeButtonPosition: null,
  onClose: (): void => {},
  animated: false,
  state: '',
  onAnimationEnd: (): void => {},
  side: '',
}

export default ComposerSidepanel
