import {
  CheckCircleIcon,
  CopyPlusIcon,
  DropdownMenu,
  EllipsisIcon,
  IconButton,
  TrashIcon,
} from '@buffer-mono/popcorn'
import clsx from 'clsx'
import React from 'react'
import type { IdeaCard_IdeaFragment as IdeaFragment } from '~publish/gql/graphql'
import type { Idea } from '~publish/pages/Create/types'

import { useRouteMatch } from 'react-router-dom'
import { useIdeaComposer } from '~publish/components/IdeaManagementRouter'
import { useQueryParam } from '~publish/hooks/useQueryParam'
import { tagTab } from '~publish/legacy/routes'
import { DeleteIdeasDialog } from '~publish/components/DeleteIdeasDialog'
import styles from './IdeaCardActions.module.css'
import { VIEWS } from '~publish/pages/Create/IdeasPage'

export const DuplicateIdeaMenuItem = ({
  idea,
}: {
  idea: Idea | IdeaFragment
}): JSX.Element => {
  const { createIdeaWithComposer } = useIdeaComposer()

  const isTagsPage = !!useRouteMatch(tagTab.route)
  const [view] = useQueryParam('view')

  const viewSource = React.useMemo(() => {
    if (view === VIEWS.BOARD) {
      return 'board'
    }
    if (isTagsPage) {
      return 'tagsPage'
    }
    return 'gallery'
  }, [view, isTagsPage])

  const handleDuplicateIdea = React.useCallback((): void => {
    createIdeaWithComposer({
      source: `publish-${viewSource}-ideaCard-duplicateButton-1`,
      text: idea?.content?.text ?? '',
      media: idea?.content?.media?.map((media) => media.url),
      title: idea?.content?.title ?? '',
      aiAssisted: idea?.content?.aiAssisted ? 'true' : 'false',
      tags: idea?.content?.tags?.map((tag) => tag.id),
      groupId: idea?.groupId ?? undefined,
      placeAfterId: idea?.groupId ? idea?.id : undefined, // If the idea is in a group, place the duplicate after it
    })
  }, [createIdeaWithComposer, idea, viewSource])

  return (
    <DropdownMenu.Item
      onClick={(e): void => {
        e.stopPropagation()
      }}
      onSelect={handleDuplicateIdea}
    >
      <CopyPlusIcon />
      Duplicate
    </DropdownMenu.Item>
  )
}

export const SelectIdeaMenuItem = ({
  onSelect,
}: {
  onSelect: () => void
}): JSX.Element => {
  return (
    <DropdownMenu.Item
      onClick={(e): void => {
        e.stopPropagation()
      }}
      onSelect={onSelect}
    >
      <CheckCircleIcon />
      Select
    </DropdownMenu.Item>
  )
}

export const DeleteCardMenuItem = ({
  onDelete,
}: {
  onDelete: () => Promise<void>
}): JSX.Element => {
  const [opened, setOpened] = React.useState(false)

  const handlePropagation = (e: React.MouseEvent): void => {
    e.stopPropagation()
  }

  const handleDelete = async (): Promise<void> => {
    await onDelete()
  }

  return (
    <DeleteIdeasDialog
      open={opened}
      onOpenChange={setOpened}
      onDelete={handleDelete}
    >
      <DropdownMenu.Item
        variant="critical"
        onClick={handlePropagation}
        onSelect={(e): void => {
          e.preventDefault()
        }}
      >
        <TrashIcon />
        Delete
      </DropdownMenu.Item>
    </DeleteIdeasDialog>
  )
}

export type IdeaCardActionsProps = {
  idea: Idea | IdeaFragment
  onSelect: () => void
  onDelete: () => Promise<void>
  className?: string
}

export const IdeaCardActions = ({
  onSelect,
  idea,
  onDelete,
  className,
}: IdeaCardActionsProps): JSX.Element => {
  return (
    <DropdownMenu
      trigger={
        <IconButton
          label="Card actions"
          className={clsx(styles.menu, className)}
        >
          <EllipsisIcon />
        </IconButton>
      }
    >
      <SelectIdeaMenuItem onSelect={onSelect} />
      <DuplicateIdeaMenuItem idea={idea} />
      <DropdownMenu.Separator />
      <DeleteCardMenuItem onDelete={onDelete} />
    </DropdownMenu>
  )
}
