import { createAsyncThunk } from '@reduxjs/toolkit'
import type { AppDispatch, RootState } from '~publish/legacy/store'
import { client, gql } from '~publish/legacy/apollo-client'
import { NoteType } from '~publish/legacy/notes/constants'
import type {
  AddEntityNoteInput,
  AddEntityNoteResponse,
} from '~publish/gql/graphql'

const ADD_ENTITY_NOTE = gql`
  mutation addEntityNote($input: AddEntityNoteInput!) {
    addEntityNote(input: $input) {
      ... on AddEntityNoteResponse {
        entityId
        entityType
        noteId
      }
    }
  }
`

type AddEntityNoteResult =
  | {
      addEntityNote: AddEntityNoteResponse
    }
  | null
  | undefined

export const addEntityNote = createAsyncThunk<
  AddEntityNoteResult,
  AddEntityNoteInput,
  { state: RootState; dispatch: AppDispatch }
>(
  'posts/addEntityNote',
  async ({
    entityId,
    entityType,
    text,
    noteType = NoteType.UserGenerated,
  }): Promise<AddEntityNoteResult> => {
    const response = await client.mutate<
      AddEntityNoteResult,
      { input: AddEntityNoteInput }
    >({
      mutation: ADD_ENTITY_NOTE,
      variables: { input: { entityId, entityType, text, noteType } },
    })

    return response.data
  },
)
