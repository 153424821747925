import React from 'react'
import WhatsNewBar from './WhatsNewBar/WhatsNewBar'
import OfferBar from './OfferBar/OfferBar'
import EventBar from './EventBar/EventBar'
import { draftPropType } from '../ComposerPropTypes'
import styles from '../css/Composer.module.css'
import AppStore from '../../stores/AppStore'
import type Draft from '../../entities/Draft/Draft'
import type { GoogleBusinessChannelData } from '../post-preview/previews/types'

function isGoogleBusiness(draft: Draft): draft is Draft & {
  channelData: { googlebusiness: GoogleBusinessChannelData }
} {
  return draft.service.isGoogleBusiness()
}

const GoogleBusinessComposerBar = ({
  draft,
}: {
  draft: Draft
}): JSX.Element => {
  if (!isGoogleBusiness(draft)) {
    return <></>
  }

  const data = draft.channelData.googlebusiness
  const className = draft.hasAttachment()
    ? [
        styles.googleBusinessComposerBarWithAttachment,
        styles.googleBusinessComposerBar,
      ].join(' ')
    : styles.googleBusinessComposerBar
  const { uses24hTime } = AppStore.getUserData()

  return (
    <div className={className}>
      {draft.updateType === 'whats_new' && <WhatsNewBar data={data} />}
      {draft.updateType === 'offer' && (
        <OfferBar data={data} uses24hTime={uses24hTime} />
      )}
      {draft.updateType === 'event' && (
        <EventBar draftId={draft.id} data={data} uses24hTime={uses24hTime} />
      )}
    </div>
  )
}

GoogleBusinessComposerBar.propTypes = {
  draft: draftPropType.isRequired,
}

export default GoogleBusinessComposerBar
