import { useAppSelector } from '~publish/legacy/store'
import { selectProfiles } from '~publish/legacy/profile-sidebar/selectors'
import type { PublishingPost } from '~publish/gql/graphql'
import type { RpcUpdate } from '~publish/legacy/post/types'

/**
 * Deciding if we need to show Threads upgrade modal - it's the case
 * when there's a Twitter draft enabled, which has a Thread, and there's
 * at least one profile with canQueueMoreThreads = false
 */
export const useShowThreadsUpgrade = (
  post: RpcUpdate | PublishingPost | undefined,
): {
  shouldShowThreadsMigrationModal: boolean
  service: string
} => {
  const profiles = useAppSelector((state) => selectProfiles(state))

  const canQueueMoreThreadsForService = (service: string): boolean =>
    profiles
      .filter((p) => p.service === service)
      .reduce(
        (canQueueMoreThreads, p) =>
          p.canQueueMoreThreads && canQueueMoreThreads,
        true,
      )

  const services = ['twitter', 'threads', 'bluesky', 'mastodon']

  const canQueueMoreThreadsForAllServices = services.reduce(
    (acc, service) => ({
      ...acc,
      [service]: canQueueMoreThreadsForService(service),
    }),
    {} as Record<string, boolean>,
  )

  const canQueueMoreThreadsForAllProfiles = services.every(
    (service) => canQueueMoreThreadsForAllServices[service],
  )

  const hasThread = post?.updateType === 'thread'

  const service =
    services.find((service) => !canQueueMoreThreadsForAllServices[service]) ||
    'omni'

  return {
    shouldShowThreadsMigrationModal:
      !canQueueMoreThreadsForAllProfiles && hasThread,
    service,
  }
}
