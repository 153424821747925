import { connect } from 'react-redux'
import AppsAndExtras from './components/AppsAndExtras'
import { actions } from './reducer'

export default connect(
  (state) => ({
    // @ts-expect-error TS(2339) FIXME: Property 'manageAppsExtras' does not exist on type... Remove this comment to see the full error message
    showModalAppId: state.manageAppsExtras.showModalAppId,
    // @ts-expect-error TS(2339) FIXME: Property 'manageAppsExtras' does not exist on type... Remove this comment to see the full error message
    showModalAppName: state.manageAppsExtras.showModalAppName,
    // @ts-expect-error TS(2339) FIXME: Property 'manageAppsExtras' does not exist on type... Remove this comment to see the full error message
    connectedApps: state.manageAppsExtras.connectedApps,
    // @ts-expect-error TS(2339) FIXME: Property 'manageAppsExtras' does not exist on type... Remove this comment to see the full error message
    submitting: state.manageAppsExtras.submitting,
  }),
  (dispatch) => ({
    // @ts-expect-error TS(7031) FIXME: Binding element 'appId' implicitly has an 'any' ty... Remove this comment to see the full error message
    onRequestOpenModal: ({ appId, appName }) => {
      dispatch(actions.requestOpenModal({ appId, appName }))
    },
    onRequestCloseModal: () => {
      dispatch(actions.requestCloseModal())
    },
    // @ts-expect-error TS(7031) FIXME: Binding element 'appId' implicitly has an 'any' ty... Remove this comment to see the full error message
    onConfirmRevokeApp: ({ appId }) => {
      dispatch(actions.requestRevokeApp({ appId }))
    },
  }),
)(AppsAndExtras)

export { default as reducer, actions, actionTypes } from './reducer'
export { default as middleware } from './middleware'
