import type { BufferEditor } from '~publish/legacy/editor/BufferEditor/types.plate'
import { updateSlateErrorData } from '~publish/legacy/editor/error'
import { logError } from '~publish/legacy/utils/logError'

export const ERROR_REPORTING_PLUGIN_KEY = 'error-reporting'

type Callback<T extends unknown[], R = unknown> = (...args: T) => R

const handleErrorCallback =
  <T extends unknown[], R>(callback: Callback<T, R>, editor: BufferEditor) =>
  (...args: T): R => {
    try {
      return callback.apply(null, args) // eslint-disable-line prefer-spread
    } catch (error) {
      logError(error as Error, {
        beforeSend: updateSlateErrorData({ context: 'SlateError', editor }),
      })
      throw error
    }
  }

export const withErrorReporting = (editor: BufferEditor): BufferEditor => {
  Object.entries(editor).forEach(([key, value]) => {
    if (typeof value === 'function') {
      editor[key] = handleErrorCallback(
        value as Callback<unknown[], unknown>,
        editor,
      )
    }
  })

  return editor
}
