function updateHelpScoutBeacon(currentPath = '/'): void {
  if (window.Beacon) {
    window.Beacon('event', {
      type: 'page-viewed',
      url: document.location.href,
      title: `${document.title}: ${currentPath}`,
    })
    window.Beacon('suggest')
  }
}

function getBeaconStyle(): string {
  if (window.Beacon) {
    const info: any = window.Beacon('info')
    return info?.config?.display?.style || ''
  }
  return ''
}

function isBeaconVisible(): boolean {
  return getBeaconStyle() !== 'manual'
}

export const HelpScoutBeacon = {
  updateHelpScoutBeacon,
  getBeaconStyle,
  isBeaconVisible,
}
