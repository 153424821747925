import {
  Flex,
  InfoIcon,
  Text,
  Tooltip,
  UnstyledButton,
} from '@buffer-mono/popcorn'
import clsx from 'clsx'
import React from 'react'
import { NewNoteForm } from './components/NewNoteForm'
import { Note, type NoteProps } from './components/Note'
import styles from './NotesWidget.module.css'

interface NotesWidgetProps {
  className?: string
  children: React.ReactNode
  isVisibleToTeamMembers?: boolean
}

const NotesWidget = ({
  className,
  children,
  isVisibleToTeamMembers = false,
}: NotesWidgetProps): JSX.Element => {
  const tooltipContent = isVisibleToTeamMembers
    ? 'Notes are visible to all the team members who have access to this channel'
    : 'Notes are visible only to you.'
  return (
    <Flex
      direction="column"
      gap="md"
      align="stretch"
      className={clsx(styles.notesContainer, className)}
    >
      <Flex align="center" gap="xs" className={styles.visibilityNoticeWrapper}>
        <Text as="h1" color="subtle" size="md" weight="bold">
          Notes
        </Text>
        {/* TODO: Replace Tooltip with Toggletip once implemented */}
        <Tooltip content={tooltipContent}>
          <UnstyledButton className={styles.visibilityNoticeButton}>
            <InfoIcon color="subtle" />
          </UnstyledButton>
        </Tooltip>
      </Flex>
      {children}
    </Flex>
  )
}

interface NotesListProps {
  className?: string
  children: React.ReactNode
}

export const NoteList = ({
  className,
  children,
}: NotesListProps): JSX.Element => (
  <Flex
    direction="column-reverse"
    className={clsx(styles.scrollContainer, className)}
  >
    <ul className={styles.notesList}>{children}</ul>
  </Flex>
)

const NotesWidgetObject = Object.assign(NotesWidget, {
  NoteList,
  Note,
  NewNoteForm,
})

export { NotesWidgetObject as NotesWidget }
export type { NotesWidgetProps, NotesListProps, NoteProps }
