import React from 'react'
import { AlertDialog, Button, toast } from '@buffer-mono/popcorn'

type DeleteIdeasDialogProps = {
  open: boolean
  onOpenChange: (open: boolean) => void
  onDelete: () => Promise<void>
  children: React.ReactNode
  count?: number
}

export const DeleteIdeasDialog = ({
  open,
  onOpenChange,
  onDelete,
  children,
  count = 1,
}: DeleteIdeasDialogProps): JSX.Element => {
  const [isDeleting, setIsDeleting] = React.useState(false)

  const handlePropagation = (e: React.MouseEvent): void => {
    e.stopPropagation()
  }

  const handleDelete = async (e: React.MouseEvent): Promise<void> => {
    e.stopPropagation()
    setIsDeleting(true)

    try {
      await onDelete()
      const message =
        count === 1
          ? 'The idea was deleted!'
          : `The ${count} ideas were deleted!`
      toast.success(message)
    } catch (error) {
      const message =
        count === 1
          ? 'The idea could not be deleted. Please try again!'
          : 'The ideas could not be deleted. Please try again!'
      toast.error(message)
    }

    setIsDeleting(false)
    onOpenChange(false)
  }

  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialog.Trigger>{children}</AlertDialog.Trigger>
      <AlertDialog.Content onClick={handlePropagation}>
        <AlertDialog.Title>
          {count === 1 ? 'Delete Idea?' : `Delete ${count} Ideas?`}
        </AlertDialog.Title>
        <AlertDialog.Description>
          {count === 1
            ? 'This idea will be permanently deleted.'
            : `These ${count} ideas will be permanently deleted.`}{' '}
          This action cannot be undone.
        </AlertDialog.Description>
        <AlertDialog.Actions>
          <AlertDialog.Cancel>
            <Button variant="tertiary" onClick={handlePropagation}>
              Cancel
            </Button>
          </AlertDialog.Cancel>
          <AlertDialog.Action>
            <Button
              variant="critical"
              onClick={handleDelete}
              disabled={isDeleting}
            >
              {isDeleting ? 'Deleting...' : 'Delete'}
            </Button>
          </AlertDialog.Action>
        </AlertDialog.Actions>
      </AlertDialog.Content>
    </AlertDialog>
  )
}
