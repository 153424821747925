import { connect } from 'react-redux'
import { getEnvironment } from '~publish/legacy/store/selectors'
import { actions as profileSidebarActions } from '~publish/legacy/profile-sidebar/reducer'
import { actions as composerPopoverActions } from '~publish/legacy/composer-popover'
import { SEGMENT_NAMES } from '~publish/legacy/constants'
import { getURL } from '~publish/legacy/utils/formatters'
import type { RootState } from '~publish/legacy/store'

import { actions } from './reducer'
import { formatPostLists, isScheduleSlotsAvailable } from './util'
import QueuedPosts from './components/QueuedPosts'
import { selectCurrentProfilePostingDays } from '../profile-sidebar/selectors'

export default connect(
  (state: RootState, ownProps) => {
    // @ts-expect-error TS(2339) FIXME: Property 'profileId' does not exist on type '{}'.
    const { profileId } = ownProps
    // @ts-expect-error TS(2339) FIXME: Property 'queue' does not exist on type 'DefaultRo... Remove this comment to see the full error message
    const queue = state.queue.byProfileId[profileId]
    const profileData = state.profileSidebar.profiles.find(
      // @ts-expect-error TS(7006) FIXME: Parameter 'p' implicitly has an 'any' type.
      (p) => p.id === ownProps.profileId,
    )
    const days = selectCurrentProfilePostingDays(state)
    const shouldDisplaySingleSlots =
      days.length > 0 &&
      days.every((day) => day.times.length === 0 || day.paused)

    const pausedDays = days
      .filter((day) => day.paused === true)
      .map((day) => day.dayName)

    if (queue && profileData) {
      const { isDisconnected } = state.profileSidebar.selectedProfile ?? {}

      return {
        loading: queue.loading,
        loadingMore: queue.loadingMore,
        moreToLoad: queue.moreToLoad,
        page: queue.page,
        items: formatPostLists({
          isManager: profileData.isManager,
          posts: queue.posts,
          scheduleSlotsEnabled: true,
          schedules: profileData.schedules,
          profileTimezone: profileData.timezone,
          weekStartsOnMonday: state.user.week_starts_monday,
          weeksToShow: queue.page + 1,
          hasTwentyFourHourTimeFormat: state.user.hasTwentyFourHourTimeFormat,
          profileService: profileData.service,
          shouldDisplaySingleSlots,
          pausedDays,
        }),
        scheduleSlotsIsAvailable: isScheduleSlotsAvailable(
          profileData.schedules,
        ),
        draggingEnabled: !profileData.paused,
        showEmptyQueueMessage: false, // @todo: Show this if they have no slots?
        environment: getEnvironment(state),
        editingPostId: state.composerPopover.editingPostId,
        subprofiles: profileData.subprofiles || [],
        paused: profileData.paused,
        isManager: profileData.isManager,
        hasPushNotifications: profileData.hasPushNotifications,
        hasFirstCommentFlip:
          state.organizations.selected?.hasFirstCommentFeature,
        hasCampaignsFeature: state.organizations.selected?.hasCampaignsFeature,
        shouldRenderCalendarButtons: true,
        shouldDisplaySingleSlots,
        shouldDisplayTimezone: profileData.isManager,
        profileTimezone: profileData.timezone,
        composerType: state.queue.composerType,
        isProfileDisconnected: isDisconnected,
        profileData,
        profileId,
      }
    }
    return {
      items: [],
    }
  },

  (dispatch, ownProps) => ({
    onEditClick: ({ postId }: { postId: string }) => {
      // @ts-expect-error TS(2345) FIXME: Argument of type '(dispatch: Dispatch) => Promise<... Remove this comment to see the full error message
      dispatch(composerPopoverActions.handleEditClick(postId))
    },
    // @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
    onEmptySlotClick: (post) => {
      dispatch(
        composerPopoverActions.handleEmptySlotClick({
          post,
          // @ts-expect-error TS(2339) FIXME: Property 'profileId' does not exist on type '{}'.
          profileId: ownProps.profileId,
          cta: SEGMENT_NAMES.CREATE_A_POST_SLOT,
        }),
      )
    },
    // @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
    onDeleteConfirmClick: (post) => {
      dispatch(
        actions.handleDeleteConfirmClick({
          post: post.post,
          // @ts-expect-error TS(2339) FIXME: Property 'profileId' does not exist on type '{}'.
          profileId: ownProps.profileId,
        }),
      )
    },
    // @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
    onRequeueClick: (post) => {
      dispatch(
        actions.handleRequeue({
          post: post.post,
          // @ts-expect-error TS(2339) FIXME: Property 'profileId' does not exist on type '{}'.
          profileId: ownProps.profileId,
        }),
      )
    },
    // @ts-expect-error TS(7006) FIXME: Parameter 'campaignId' implicitly has an 'any' typ... Remove this comment to see the full error message
    onCampaignTagClick: (campaignId) => {
      dispatch(
        actions.handleCampaignTagClick({
          campaignId,
        }),
      )
    },
    // @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
    onShareNowClick: (post) => {
      dispatch(
        actions.handleShareNowClick({
          post: post.post,
          // @ts-expect-error TS(2339) FIXME: Property 'profileId' does not exist on type '{}'.
          profileId: ownProps.profileId,
        }),
      )
    },
    // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
    onDropPost: (id, timestamp, day) => {
      // @ts-expect-error TS(2339) FIXME: Property 'profileId' does not exist on type '{}'.
      dispatch(actions.onDropPost(id, timestamp, day, ownProps.profileId))
    },
    // @ts-expect-error TS(7006) FIXME: Parameter 'postSource' implicitly has an 'any' typ... Remove this comment to see the full error message
    onSwapPosts: (postSource, postTarget) => {
      // @ts-expect-error TS(2339) FIXME: Property 'profileId' does not exist on type '{}'.
      dispatch(actions.onSwapPosts(postSource, postTarget, ownProps.profileId))
    },
    onUnpauseClick: () => {
      dispatch(
        // @ts-expect-error TS(2339) FIXME: Property 'profileId' does not exist on type '{}'.
        profileSidebarActions.onUnpauseClick({ profileId: ownProps.profileId }),
      )
    },
    onCreateThreadClick: () => {
      dispatch(
        composerPopoverActions.handleCreatePostClick({
          cta: 'publish-queue-banner-startThread-1',
          // @ts-expect-error TS(2339) FIXME: Property 'profileId' does not exist on type '{}'.
          selectedProfileId: ownProps.profileId,
          shouldResetComposerData: true,
        }),
      )
    },
    // @ts-expect-error TS(7031) FIXME: Binding element 'selectedProfileIds' implicitly ha... Remove this comment to see the full error message
    onAutoOpenComposer: ({ selectedProfileIds }) => {
      dispatch(
        composerPopoverActions.handleOpenComposerFromQueryParams({
          cta: SEGMENT_NAMES.CREATE_A_POST_VIA_AUTO_OPEN_COMPOSER,
          // @ts-expect-error TS(2339) FIXME: Property 'profileId' does not exist on type '{}'.
          selectedProfileIds: selectedProfileIds || [ownProps.profileId],
          shouldResetComposerData: true,
        }),
      )
    },
    onHideInstagramModal: () => {
      // @ts-expect-error TS(2339) FIXME: Property 'handleHideInstagramModal' does not exist... Remove this comment to see the full error message
      dispatch(actions.handleHideInstagramModal())
    },
    // @ts-expect-error TS(7031) FIXME: Binding element 'type' implicitly has an 'any' typ... Remove this comment to see the full error message
    onSetRemindersClick: ({ type }) => {
      let cta = ''
      if (type === 'banner') {
        cta = SEGMENT_NAMES.REMINDERS_BANNER
      }
      if (type === 'post') {
        cta = SEGMENT_NAMES.REMINDERS_POST
      }
      window.location.assign(
        `${getURL.getRemindersURL({
          // @ts-expect-error TS(2339) FIXME: Property 'profileId' does not exist on type '{}'.
          profileId: ownProps.profileId,
          cta,
          // @ts-expect-error TS(2339) FIXME: Property 'profileId' does not exist on type '{}'.
          nextUrl: `profile/${ownProps.profileId}/tab/queue`,
        })}`,
      )
    },
  }),
)(QueuedPosts)

export { default as reducer, actions } from './reducer'
export { default as middleware } from './middleware'
