import React from 'react'

import type { Draft } from '../../entities/Draft'
import AppStore from '../../stores/AppStore'
import ComposerStore from '../../stores/ComposerStore'
import { Notice } from '../shared/Notice'

function IGVideosAsReelsNotice({
  draft,
}: {
  draft: Draft
}): JSX.Element | null {
  const shouldRender =
    draft.service.isInstagram() &&
    ComposerStore.getMeta().updateTypeChanged &&
    AppStore.getExpandedComposerId() === draft.id &&
    draft.isReelsPost()

  return shouldRender ? (
    <Notice
      id="IG-videos-as-reels"
      message="Instagram no longer supports standard video posts. Videos will be published as Reels."
      dismissible
    />
  ) : null
}

export { IGVideosAsReelsNotice }
