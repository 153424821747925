import React from 'react'
import type Draft from '../../entities/Draft/Draft'
import type { TiktokChannelData } from '~publish/legacy/composer/composer/entities/Draft'
import ComposerActionCreators from '~publish/legacy/composer/composer/action-creators/ComposerActionCreators'
import {
  FieldWrapper,
  Input,
  Label,
  Wrapper,
} from '~publish/legacy/composer/composer/components/channel-fields/styles'

const TiktokComposerBar = ({ draft }: { draft: Draft }): JSX.Element => {
  if (
    !draft.service.isTiktok() ||
    !draft.hasImagesAttachment() ||
    draft.isReminder
  ) {
    return <></>
  }

  const data = draft.channelData?.tiktok

  const onInputChange = (val: string, attr: keyof TiktokChannelData): void => {
    const updatedData = { ...data }
    updatedData[attr] = val

    ComposerActionCreators.updateDraftTiktokData(updatedData)
  }

  return (
    <Wrapper>
      <FieldWrapper>
        <Label htmlFor="tiktok-post-title">Title</Label>
        <Input
          id="tiktok-post-title"
          onChange={(e): void => onInputChange(e.target.value, 'title')}
          value={data?.title ?? ''}
          placeholder="Your post title"
        />
      </FieldWrapper>
    </Wrapper>
  )
}

export default TiktokComposerBar
