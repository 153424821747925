import React from 'react'
import { useAppSelector } from '~publish/legacy/store'

import * as Styles from './styles'

import { selectInstagramNotificationsComposerGuideScreen } from '~publish/legacy/guides/selectors'

import { Empty } from '../../previews/Common/Empty'

export function InstagramNotificationsPreview() {
  const currentScreen = useAppSelector(
    selectInstagramNotificationsComposerGuideScreen,
  )

  const screenMapping: { [key: number]: JSX.Element } = {
    0: <Empty />,
    1: (
      // eslint-disable-next-line jsx-a11y/alt-text
      <img src="https://buffer-publish.s3.amazonaws.com/images/reminders/notification_preview.jpg" />
    ),
    2: (
      // eslint-disable-next-line jsx-a11y/alt-text
      <img src="https://buffer-publish.s3.amazonaws.com/images/reminders/sticker_preview.jpg" />
    ),
  }

  return <Styles.Wrapper>{screenMapping[currentScreen]}</Styles.Wrapper>
}
