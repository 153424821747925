import React from 'react'
import PropTypes from 'prop-types'
import Button from '../../components/shared/Button'
import styles from './css/modal.module.css'
import ActionCreators from './actionCreators'
import Store from './store'
import {
  OVERLAY,
  BUTTON,
  ESCAPE,
  TAB_KEY,
  ESCAPE_KEY,
  ENTER_KEY,
} from './constants'
import scopeTab from './scopeTab'

const getState = () => Store.getCurrentState()

// Method to retrieve a DOM element from a ref callback, that's compatible
// with both React 0.13.3 (legacy) and React 15+ (newer independent React apps),
// since this component is currently used in both codebases.
// @ts-expect-error TS(7006) FIXME: Parameter 'node' implicitly has an 'any' type.
const getDOMNodeLegacy = (node) => {
  if (!node) return null

  if (Object.prototype.hasOwnProperty.call(node, 'getDOMNode'))
    return node.getDOMNode()
  return node
}

class Modal extends React.Component {
  // @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
  constructor(props) {
    super(props)
    this.state = Store.getInitialState()
  }

  componentDidMount = () => {
    // @ts-expect-error TS(2339) FIXME: Property '_trigger' does not exist on type 'Modal'... Remove this comment to see the full error message
    this._trigger = document.activeElement
    // @ts-expect-error TS(2339) FIXME: Property '_modal' does not exist on type 'Modal'.
    this._modal.focus()
    Store.addChangeListener(this.onChange)
  }

  componentWillUnmount = () => {
    Store.removeChangeListener(this.onChange)
    // restore focus to element that triggered modal
    setTimeout(() => {
      // @ts-expect-error TS(2339) FIXME: Property '_trigger' does not exist on type 'Modal'... Remove this comment to see the full error message
      this._trigger.focus()
    }, 50)
  }

  onChange = () => this.setState(getState())

  // @ts-expect-error TS(7006) FIXME: Parameter 'ev' implicitly has an 'any' type.
  handleKeyDown = (ev) => {
    /* handles focus in the modal for accessibility */
    if (ev.keyCode === TAB_KEY) {
      // @ts-expect-error TS(2339) FIXME: Property '_modal' does not exist on type 'Modal'.
      scopeTab(this._modal, ev)
    }
    if (ev.keyCode === ESCAPE_KEY) {
      // @ts-expect-error TS(2339) FIXME: Property 'hideCloseButton' does not exist on type ... Remove this comment to see the full error message
      const { hideCloseButton } = this.props
      if (hideCloseButton) {
        return
      }
      ev.preventDefault()
      ev.stopPropagation()
      ActionCreators.closeModal(ESCAPE)
    }
    if (ev.keyCode === ENTER_KEY && ev.target.id === 'closeButton') {
      ActionCreators.closeModal(BUTTON)
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'ev' implicitly has an 'any' type.
  close = (ev) => {
    const node = ev.target

    if (node.id === 'overlay') {
      // @ts-expect-error TS(2339) FIXME: Property 'preventCloseOnOverlayClick' does not exi... Remove this comment to see the full error message
      const { preventCloseOnOverlayClick } = this.props
      if (preventCloseOnOverlayClick) {
        return
      }
      ActionCreators.closeModal(OVERLAY)
    }

    if (node.id === 'closeButton' || node.parentElement.id === 'closeButton') {
      ActionCreators.closeModal(BUTTON)
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'node' implicitly has an 'any' type.
  storeModalReference = (node) => {
    // @ts-expect-error TS(2339) FIXME: Property '_modal' does not exist on type 'Modal'.
    this._modal = getDOMNodeLegacy(node)
  }

  renderCloseButton() {
    // @ts-expect-error TS(2339) FIXME: Property 'classNames' does not exist on type 'Read... Remove this comment to see the full error message
    const { classNames } = this.props
    const hasCloseButtonCustomClass = classNames && classNames.closeButton
    const closeClass = [
      hasCloseButtonCustomClass ? classNames.closeButton : '',
      styles.close,
    ].join(' ')
    const closeIconClass = ['bi bi-x', styles.closeIcon].join(' ')

    return (
      <Button
        className={closeClass}
        id="closeButton"
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        href="#close"
        onClick={this.close}
        title="Close"
      >
        <i aria-hidden="true" className={closeIconClass} />
      </Button>
    )
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'classNames' does not exist on type 'Read... Remove this comment to see the full error message
    const { classNames, hideCloseButton, children, modalCustomStyle } =
      this.props
    // @ts-expect-error TS(2339) FIXME: Property 'open' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    const { open } = this.state
    const overlayClass = styles.overlay
    const hasCustomModalClass = classNames && classNames.modal
    const modalClass = [
      hasCustomModalClass ? classNames.modal : '',
      styles.modal,
    ].join(' ')

    if (open) {
      return (
        <div
          className={overlayClass}
          id="overlay"
          onMouseDown={this.close}
          role="none"
        >
          <div
            className={modalClass}
            onKeyDown={this.handleKeyDown.bind(this)}
            ref={this.storeModalReference}
            style={modalCustomStyle}
            role="none"
          >
            {hideCloseButton ? null : this.renderCloseButton()}
            {children}
          </div>
        </div>
      )
    }

    return null
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
Modal.propTypes = {
  children: PropTypes.node.isRequired,
  hideCloseButton: PropTypes.bool,
  preventCloseOnOverlayClick: PropTypes.bool,
  classNames: PropTypes.shape({
    modal: PropTypes.string,
    closeButton: PropTypes.string,
  }),
  modalCustomStyle: PropTypes.object, // eslint-disable-line
}

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
Modal.defaultProps = {
  hideCloseButton: false,
  preventCloseOnOverlayClick: false,
  classNames: {
    modal: null,
    closeButton: null,
  },
}

export default Modal
