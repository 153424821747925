import React, { useCallback } from 'react'
import clsx from 'clsx'
import { Text, UnstyledButton } from '@buffer-mono/popcorn'
import { useQueryParam } from '~publish/hooks/useQueryParam'

import styles from './QueueSlot.module.css'

type OccupiedQueueSlotProps = {
  date: string
}

export const OccupiedQueueSlot = ({
  date,
}: OccupiedQueueSlotProps): JSX.Element => {
  const [, setTagFilter] = useQueryParam<string[]>('tags')
  const onRemoveFilterClick = useCallback(() => {
    setTagFilter([])
  }, [setTagFilter])

  return (
    <div
      className={clsx(styles.slot, styles.occupied)}
      data-datetime={date}
      data-testid="occupied-queue-slot"
    >
      <Text className={styles.occupied}>
        Post hidden by filters.{' '}
        <UnstyledButton
          className={styles.clearFiltersButton}
          onClick={onRemoveFilterClick}
        >
          Clear filters
        </UnstyledButton>
      </Text>
    </div>
  )
}

OccupiedQueueSlot.displayName = 'OccupiedQueueSlot'
