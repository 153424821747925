import Bugsnag, { type Event } from '@bugsnag/browser'

interface LogErrorOptions {
  context?: string
  metaData?: Record<string, unknown>
  beforeSend?: (event: Event) => Event
}

export function logError(error: Error, opts: LogErrorOptions = {}): void {
  const options = {
    context: 'composer',
    ...opts,
  }

  Bugsnag.notify(error, (event) => {
    event.context = options.context
    if (options.metaData) {
      event.addMetadata('metaData', options.metaData)
    }
    if (options.beforeSend) {
      event = options.beforeSend(event)
    }
  })

  // todo: consider debug library
  // eslint-disable-next-line no-console
  console.error(error, options)
}
