import React, { useEffect } from 'react'

import { useLocalStorage } from '@buffer-mono/popcorn'

import { TimeZoneSelect } from '~publish/components/TimeZoneSelect'
import { useTimezone } from '~publish/hooks/useTimezone'

export function AllChannelsTimeZoneSelect({
  disabled,
}: {
  disabled?: boolean
}): JSX.Element {
  const [_timezone, onTimeZoneChange] = useTimezone()
  const [timezone, setTimezone] = useLocalStorage(
    'all-channels-timezone',
    _timezone,
  )

  useEffect(() => {
    onTimeZoneChange?.(timezone)
  }, [timezone, onTimeZoneChange])

  const handleTimeZoneChange = (timezone: string): void => {
    setTimezone(timezone)
    onTimeZoneChange(timezone)
  }

  return (
    <TimeZoneSelect
      disabled={disabled}
      value={timezone}
      onChange={handleTimeZoneChange}
      variant="tertiary"
    />
  )
}
