import { selectCampaignPostById } from '~publish/legacy/campaign/selectors'
import { selectPostById as selectSlicePostById } from '~publish/legacy/post/slice'
import type { RpcUpdate } from '~publish/legacy/post/types'
import type { PublishingPost } from '~publish/gql/graphql'
import {
  selectCurrentProfile,
  selectProfileById,
} from '~publish/legacy/profile-sidebar/selectors'
import type { Profile } from '~publish/legacy/profile-sidebar/types'
import { selectPostById as selectQueuePostById } from '~publish/legacy/queue/selectors'
import { selectPostById as selectSentPostById } from '~publish/legacy/sent/selectors'
import type { RootState } from '~publish/legacy/store'
import { SupportedNotesPages } from './hooks'

export const getPostSelector = ({
  entityId,
  notesPage,
}: {
  entityId: string
  notesPage: SupportedNotesPages | undefined
}): ((state: RootState) => RpcUpdate | PublishingPost | undefined) => {
  switch (notesPage) {
    case SupportedNotesPages.Queue:
      return selectQueuePostById(entityId)
    case SupportedNotesPages.SentPosts:
      return selectSentPostById(entityId)
    case SupportedNotesPages.Campaigns:
      return (state: RootState): RpcUpdate | undefined =>
        selectCampaignPostById(state, entityId)

    default:
      return (state: RootState): RpcUpdate | PublishingPost | undefined =>
        selectSlicePostById(state, entityId)
  }
}

export const getChannelSelector = ({
  profileId,
  notesPage,
}: {
  profileId: string
  notesPage: SupportedNotesPages | undefined
}): ((state: RootState) => Profile | undefined) => {
  if (notesPage === SupportedNotesPages.Campaigns) {
    return (state: RootState) => selectProfileById(state, profileId)
  }
  return selectCurrentProfile
}
