import React, { useMemo } from 'react'
import {
  Avatar,
  ChannelAvatar,
  type ChannelAvatarProps,
  Text,
} from '@buffer-mono/popcorn'
import { channel as channelPage } from '~publish/legacy/routes'
import { mapPostStatusToTab } from '~publish/helpers/post'

import { usePostData } from '../PostCardContext'

import styles from './ChannelInfo.module.css'
import { Link } from 'react-router-dom'
import { isOfType } from '~publish/helpers/typeGuards'

export const ChannelInfo = (): JSX.Element | null => {
  const { status, channel, metadata } = usePostData()

  const channelLink = channelPage.getRoute(channel.id, {
    tab: mapPostStatusToTab(status),
  })

  const channelInfo = useMemo(() => {
    const serverUrl = channel.serverUrl

    if (serverUrl || channel.service !== 'facebook') {
      return serverUrl
    }

    if (channel.locationData?.location) {
      return channel.locationData.location
    }

    return null
  }, [channel])

  const pinterestBoard =
    isOfType(metadata, 'PinterestPostMetadata') && metadata.board

  const hasSecondaryInfo = channelInfo || pinterestBoard

  return (
    <div className={styles.container}>
      <Link to={channelLink} className={styles.avatar}>
        <ChannelAvatar
          size="small"
          alt={`${channel.name}, ${channel.service}`}
          src={channel.avatar ?? undefined}
          channel={channel.service as ChannelAvatarProps['channel']}
        />
      </Link>

      <div className={styles.info}>
        <Text weight="bold" className={styles.name} lineHeight="tight" truncate>
          {channel.name}
        </Text>
        {hasSecondaryInfo && (
          <Text
            color="subtle"
            size="sm"
            className={styles.secondaryInfo}
            truncate
          >
            {channelInfo}
            {channelInfo && pinterestBoard && <span aria-hidden>•</span>}
            {pinterestBoard && (
              <span className={styles.pinterestBoard}>
                <Avatar
                  size="xsmall"
                  src={metadata.board?.avatar ?? undefined}
                  alt={metadata.board?.name || ''}
                />
                {metadata.board?.name}
              </span>
            )}
          </Text>
        )}
      </div>
    </div>
  )
}
