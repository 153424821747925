import { useEffect, useState } from 'react'

type useAnimatedVisibilityProps = {
  visibilityCondition: boolean
}

type useAnimatedVisibilityReturn = {
  isVisibleInDOM: boolean
  shouldBeVisible: boolean
  onAnimationEnd: () => void
}

/**
 * Hook used to animate elements that are rendered conditionally.
 * It essentially defers the unmounting of the element until the animation is complete.
 *
 * To control if your element should be rendered or not, pass the original boolean to this hook
 * and replace it with the returned isVisibleInDOM.
 *
 * In between condition changes, you can use shouldBeVisible to trigger in/out animations.
 *
 * It's useful to note that onAnimationEnd will run for both in and out animations,
 * but will only update visibility state on out animation.
 *
 * @param visibilityCondition The condition that determines whether the element should be visible.
 */
export const useAnimatedVisibility = ({
  visibilityCondition = false,
}: useAnimatedVisibilityProps): useAnimatedVisibilityReturn => {
  // Whether the element is currently visible.
  // This is the value you use to conditionally render the element.
  const [isVisibleInDOM, setIsVisibleInDOM] = useState(false)

  // Whether the element should become visible/invisible based on the visibilityCondition changing.
  // This is the value you use to trigger the different in/out animations.
  //
  // shouldBeVisible = true -> in animation
  // shouldBeVisible = false -> out animation
  const [shouldBeVisible, setShouldBeVisible] = useState(false)

  // Update the shouldBeVisible state when the condition changes.
  useEffect(() => {
    setShouldBeVisible(visibilityCondition)
  }, [visibilityCondition])

  // If shouldBeVisible is true, set isVisibleInDOM to true.
  // Once the condition says it should be visible, we can update isVisibleInDOM
  // because mounting the element will run the in animation.
  useEffect(() => {
    if (shouldBeVisible) setIsVisibleInDOM(true)
  }, [shouldBeVisible])

  // On animation end, if shouldBeVisible is false, set isVisibleInDOM to false.
  // This means the element will be unmounted after the animation is complete.
  const onAnimationEnd = (): void => {
    if (!shouldBeVisible) setIsVisibleInDOM(false)
  }

  return {
    isVisibleInDOM,
    shouldBeVisible,
    onAnimationEnd,
  }
}
