import React from 'react'
import styled from 'styled-components'

import * as Styles from './styles'

import { VideoPlayer, type PlayerProps } from '../../../Common/VideoPlayer'

const StyledVideoPlayer = styled(VideoPlayer)`
  ${Styles.Wrapper}

  margin-top: 6px;
`

const AttachmentVideo = (props: PlayerProps): JSX.Element => (
  <StyledVideoPlayer hidePlayButton autoPlay loop {...props} />
)

export { AttachmentVideo }
