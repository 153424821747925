import styled from 'styled-components'
import { SidebarListItem } from '@bufferapp/ui'
import AddIcon from '@bufferapp/ui/Icon/Icons/Add'
import {
  grayLight,
  grayDark,
  transparent,
  white,
  blue,
} from '@bufferapp/ui/style/colors'
import { borderRadius } from '@bufferapp/ui/style/borders'

export const CircleIcon = styled.div`
  border-radius: 100px;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${grayLight};
  transition: 0.1s all ease-in;
`

export const SidebarItem = styled(SidebarListItem)`
  transition: 0.1s all ease-in;
  border: 1px solid ${grayLight};
  box-sizing: border-box;
  height: 48px;
  > span:first-child {
    width: 32px;
    height: 32px;
  }
`

export const ListItemContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 8px;
  border-radius: ${borderRadius};
  background-color: ${transparent};

  :hover {
    span div${CircleIcon} {
      background-color: ${blue};
    }
  }
`

// From https://styled-components.com/docs/advanced#referring-to-other-components,
// We can use a ref of a parent with a state to define css on a child
// Css below will be apply to StyledAddIcon and not on ListItemContainer
export const StyledAddIcon = styled(AddIcon)`
  transition: 0.1s all ease-in;
  fill: ${grayDark} !important;

  ${ListItemContainer}:hover &&& {
    fill: ${white} !important;
    color: ${white} !important;
  }
`

// This above is similar to this below
// ListItemContainer:hover {
//   StyledAddIcon {
//     fill: ${white} !important;
//     color: ${white} !important;
//   }
// }
//
// Though this is not working, because we cannot define StyledAddIcon before ListItemContainer due to a bug in the ui library
// Ref: https://github.com/bufferapp/buffer-publish/blob/8c22a8185601824e7f14d0da72c32aeac95788b9/packages/profile-sidebar/components/AddChannelButton/style.ts#L57-L59
//
