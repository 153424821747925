import React from 'react'

import Avatar from '@bufferapp/ui/Avatar'
import type { Video } from '~publish/legacy/composer/composer/entities/factories'
import { parseTextToElements } from '~publish/legacy/utils/parseTextToElements'
import { parseFacebookLinks } from '~publish/legacy/utils/channels/facebook/helpers'

import type { InstagramContent, ProfilePreview } from '../../../types'
import {
  DEFAULT_AVATAR,
  textExceedsCharLimit,
  truncateText,
} from '../../../Common/utils'

import * as Styles from './styles'

const MAX_LENGTH = 30

const getText = (text: string) => {
  const truncatedText = truncateText(text, MAX_LENGTH, true)

  if (truncatedText) {
    return parseTextToElements(
      truncatedText,
      parseFacebookLinks(truncatedText),
      (element, key) => (
        <Styles.BoldText key={key} data-testid="tiktok-preview-hashtag">
          {element.displayString}
        </Styles.BoldText>
      ),
    )
  }

  return ''
}

const Text = ({ text }: { text: string }): JSX.Element => {
  const exceedsCharLimit = textExceedsCharLimit(text, MAX_LENGTH)
  const textToDisplay = getText(text)

  return textToDisplay ? (
    <Styles.Caption>
      {textToDisplay}
      {exceedsCharLimit && <Styles.More>more</Styles.More>}
    </Styles.Caption>
  ) : (
    <></>
  )
}

const Reel = ({
  content,
  profile,
}: {
  content: InstagramContent
  profile: ProfilePreview
}): JSX.Element => {
  const { avatar, username } = profile
  const { text, video } = content

  return (
    <Styles.PostWrapper data-testid="reels">
      {video && (
        <Styles.ReelWrapper data-testid="reel-wrapper">
          <Styles.VideoPlayer video={video as Video} />
          <Styles.MetadataWrapper>
            <Styles.User>
              <Avatar
                data-testid="avatar"
                src={avatar}
                alt={username}
                size="small"
                fallbackUrl={DEFAULT_AVATAR}
              />
              <Styles.UsernameWrapper>
                <Styles.Username>{username}</Styles.Username>
                <Styles.World />
              </Styles.UsernameWrapper>
            </Styles.User>
            <Text text={text as string} />
          </Styles.MetadataWrapper>
          <Styles.IconsSideSection>
            <Styles.StarOutline />
            <Styles.Thumbsup />
            <Styles.CommentRoundOutline />
            <Styles.ShareArrowOutline />
            <Styles.Dots />
          </Styles.IconsSideSection>
        </Styles.ReelWrapper>
      )}
      <Styles.Header>
        <Styles.Search />
        <Styles.Avatar
          data-testid="avatar-square"
          src={avatar}
          fallbackUrl={DEFAULT_AVATAR}
        />
      </Styles.Header>
      <Styles.SearchBox>Add comment...</Styles.SearchBox>
    </Styles.PostWrapper>
  )
}

export default Reel
