import keyWrapper from '~publish/helpers/keywrapper'

export const actionTypes = keyWrapper('DATE_TIME_PREFERENCES', {
  CHANGE_TWENTY_FOUR_HOUR_FORMAT: 0,
  CHANGE_START_OF_WEEK: 0,
})

export default {
  // @ts-expect-error TS(7006) FIXME: Parameter 'twentyFourHourFormat' implicitly has an... Remove this comment to see the full error message
  changeTwentyFourHourFormat: (twentyFourHourFormat) => ({
    type: actionTypes.CHANGE_TWENTY_FOUR_HOUR_FORMAT,
    twentyFourHourFormat,
  }),
  // @ts-expect-error TS(7006) FIXME: Parameter 'weekStartsOnMonday' implicitly has an '... Remove this comment to see the full error message
  changeStartOfWeek: (weekStartsOnMonday) => ({
    type: actionTypes.CHANGE_START_OF_WEEK,
    weekStartsOnMonday,
  }),
}
