import { useSplitEnabled } from '@buffer-mono/features'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { selectProfileService } from '~publish/legacy/profile-sidebar/selectors'
import { profileChildTabPages, profileTabPages } from '~publish/legacy/routes'
import {
  Nav,
  NavLink,
  NavSubMenu,
  NavTag,
} from '~publish/legacy/shared-components'
import { useAppSelector } from '~publish/legacy/store'

type NavCounterTagProps = { labelName: string }

const NavCounterTag = ({ labelName }: NavCounterTagProps): JSX.Element => (
  <NavTag type="counter" labelName={labelName} />
)

type TagProps = {
  showPaywallTag: boolean
  labelName: string
}

const Tag = ({ showPaywallTag, labelName }: TagProps): JSX.Element =>
  showPaywallTag ? (
    <NavTag type="paywall" />
  ) : (
    <NavCounterTag labelName={labelName} />
  )

type TabNavigationProps = {
  profileNavTabs: string[]
  profileId: string
  draftsNeedApprovalCount: number
  draftsCount: number
  hideStoriesTabs: boolean
  showNestedSettingsTab: boolean
  showApprovalPaywallTag: boolean
}

const TabNavigation = ({
  profileNavTabs,
  profileId,
  draftsNeedApprovalCount,
  draftsCount,
  hideStoriesTabs,
  showNestedSettingsTab,
  showApprovalPaywallTag,
}: TabNavigationProps): JSX.Element | null => {
  const { t } = useTranslation()
  const { isEnabled: isRemindersEnabled } = useSplitEnabled('CORE-reminders')
  const profileService = useAppSelector(selectProfileService(profileId))

  const shouldHideStoriesTabs = isRemindersEnabled && hideStoriesTabs

  if (!profileId) return null

  return (
    <Nav>
      {profileNavTabs.includes('queue') && (
        <NavLink
          id={`${profileService}-profile-queue`}
          to={profileTabPages.getRoute({
            profileId,
            tabId: 'queue',
          })}
        >
          {t('tabs.queue')}
        </NavLink>
      )}
      {!shouldHideStoriesTabs && profileNavTabs.includes('stories') && (
        <NavLink
          id={`${profileService}-profile-stories`}
          to={profileTabPages.getRoute({
            profileId,
            tabId: 'stories',
          })}
        >
          {t('tabs.stories')}
        </NavLink>
      )}
      {profileNavTabs.includes('pendingTiktok') && (
        <NavLink
          id={`${profileService}-profile-pending-tiktok`}
          to={profileTabPages.getRoute({
            profileId,
            tabId: 'pendingTiktok',
          })}
        >
          {t('tabs.pendingTiktok')}
        </NavLink>
      )}
      {!shouldHideStoriesTabs && profileNavTabs.includes('pastReminders') && (
        <NavLink
          id={`${profileService}-profile-past-reminders`}
          to={profileTabPages.getRoute({
            profileId,
            tabId: 'pastReminders',
          })}
        >
          {isRemindersEnabled ? t('tabs.pastStories') : t('tabs.pastReminders')}
        </NavLink>
      )}
      {profileNavTabs.includes('sentPosts') && (
        <NavLink
          id={
            isRemindersEnabled
              ? `${profileService}-profile-sent-reminders-beta`
              : `${profileService}-profile-sent`
          }
          to={profileTabPages.getRoute({
            profileId,
            tabId: 'sentPosts',
          })}
          activeOnlyWhenExact={false}
          hasSubMenu
        >
          {t('tabs.sentPosts')}
        </NavLink>
      )}
      {profileNavTabs.includes('approvals') && (
        <NavLink
          id={`${profileService}-profile-approvals`}
          to={profileTabPages.getRoute({
            profileId,
            tabId: 'approvals',
          })}
          testId="awaiting-approval-tab"
        >
          Approvals
          <Tag
            showPaywallTag={showApprovalPaywallTag}
            labelName={`${draftsNeedApprovalCount}`}
          />
        </NavLink>
      )}
      {profileNavTabs.includes('drafts') && (
        <NavLink
          id={`${profileService}-profile-drafts`}
          to={profileTabPages.getRoute({
            profileId,
            tabId: 'drafts',
          })}
        >
          <span data-testid="drafts-tab">
            {t('tabs.drafts')}
            <NavCounterTag labelName={`${draftsCount}`} />
          </span>
        </NavLink>
      )}
      {profileNavTabs.includes('grid') && (
        <NavLink
          id={`${profileService}-profile-grid`}
          to={profileTabPages.getRoute({
            profileId,
            tabId: 'grid',
          })}
        >
          {t('tabs.shopGrid')}
        </NavLink>
      )}
      {profileNavTabs.includes('settings') && (
        <NavLink
          id={`${profileService}-profile-settings`}
          to={profileTabPages.getRoute({
            profileId,
            tabId: 'settings',
          })}
          hasSubMenu
          activeOnlyWhenExact={false}
        >
          <span data-testid="settings-tab">{t('tabs.settings')}</span>
        </NavLink>
      )}
      {showNestedSettingsTab && (
        <NavSubMenu type="settings">
          <NavLink
            to={profileChildTabPages.getRoute({
              profileId,
              tabId: 'settings',
              childTabId: 'postingSchedule',
            })}
            secondary
          >
            {t('tabs.postingSchedule')}
          </NavLink>
          <NavLink
            to={profileTabPages.getRoute({ profileId, tabId: 'settings' })}
            secondary
          >
            {t('tabs.general')}
          </NavLink>
        </NavSubMenu>
      )}
    </Nav>
  )
}

TabNavigation.defaultProps = {
  profileNavTabs: ['queue'],
  profileId: null,
  draftsNeedApprovalCount: 0,
  draftsCount: 0,
  showNestedSettingsTab: false,
  showApprovalPaywallTag: false,
}

export default TabNavigation
