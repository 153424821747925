export function getThreadedUpdatesUpgradePath(service: string): string {
  let threadedUpdatesUpgradePath = 'omniThreadsLimit-upgrade'
  if (service === 'threads') {
    threadedUpdatesUpgradePath = 'metaThreadsLimit-upgrade'
  }
  if (service === 'bluesky') {
    threadedUpdatesUpgradePath = 'blueskyThreadsLimit-upgrade'
  }
  if (service === 'mastodon') {
    threadedUpdatesUpgradePath = 'mastodonThreadsLimit-upgrade'
  }
  if (service === 'twitter') {
    threadedUpdatesUpgradePath = 'threadsLimit-upgrade'
  }

  return threadedUpdatesUpgradePath
}
