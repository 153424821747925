import keyWrapper from '~publish/helpers/keywrapper'

export const actionTypes = keyWrapper('PUSHER', {
  EVENT_VIDEO_TRANSCODED: 0,
})

export const initialState = {}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action) => {
  switch (action.type) {
    default:
      return state
  }
}

export const actions = {}
