import React, { useContext } from 'react'
import { useDispatch } from 'react-redux'
import { actions as composerPopoverActions } from '~publish/legacy/composer-popover'
import { usePostActions } from '~publish/legacy/post/hooks'
import type { PublishingPost } from '~publish/gql/graphql'
import CreateCalendarPostContext from '../../hooks/createCalendarPostContext'
import { ButtonStyled } from './styles'

export const SentPostActions = ({
  post,
}: {
  post: PublishingPost
}): JSX.Element => {
  const dispatch = useDispatch()
  const { selectedProfileIds } = useContext(CreateCalendarPostContext)
  const onShareLinkClick = (): void => {
    dispatch(
      composerPopoverActions.handleShareLinkClick(post, selectedProfileIds),
    )
  }

  const onShareAgainClick = (): void => {
    dispatch(composerPopoverActions.handleShareAgainClick(post.id))
  }

  const { stackedActions, executePostActionById } = usePostActions({
    post,
    onShareLinkClick,
    onShareAgainClick,
  })

  const [firstAction, ...restStackedActions] = stackedActions
  if (!firstAction) return <></>

  return (
    <ButtonStyled
      size="small"
      type="secondary"
      isSplit={!!restStackedActions && restStackedActions.length > 0}
      items={restStackedActions}
      label={firstAction.title}
      onClick={firstAction.callback}
      onSelectClick={executePostActionById}
    />
  )
}
