import { VALIDATION_CODE } from '~publish/legacy/validation/constants'
import ValidationFail from '~publish/legacy/validation/ValidationFail'
import {
  getAspectRatio,
  getAspectRatioString,
} from '../../aspect-ratio/aspect-ratio'
import { type AspectRatioMatchRule, ValidationRuleName } from '../types'
import { createRuleHandler } from './createRuleHandler'
import { getRuleMessages } from './getRuleMessages'

export const aspectRatioMatch = createRuleHandler<AspectRatioMatchRule>({
  ruleName: ValidationRuleName.AspectRatioMatch,

  messages: {
    specific: 'This image does not meet the expected aspect ratio ({expected})',
    generic:
      "One or more images aren't matching the expected aspect ratio ({expected})",
  },

  validate: (rule, media) => {
    const ratio = getAspectRatio({
      width: Number(media.width),
      height: Number(media.height),
    })

    const expected = Array.isArray(rule.ruleConfig.expected)
      ? rule.ruleConfig.expected
      : [rule.ruleConfig.expected]

    if (!expected.map((r) => getAspectRatio(r)).includes(ratio)) {
      const messages = getRuleMessages(rule, {
        toString: getAspectRatioString,
      })

      return new ValidationFail(
        messages.generic,
        VALIDATION_CODE.ASPECT_RATIO,
        messages,
        {},
      )
    }

    return null
  },
})
