import React from 'react'
import WarningIcon from '@bufferapp/ui/Icon/Icons/Warning'
import { Text } from '@bufferapp/ui'
import { HC_UTM_PARAMS } from '~publish/legacy/utils/contants'
import { draftPropType } from './ComposerPropTypes'
import AppStore from '../stores/AppStore'
import styles from './css/InstagramFeedback.module.css'

type FeedbackItem = {
  message: string
  composerId: string
  code: string
}

type Feedback = FeedbackItem[]

const getLinkUrl = (feedback: Feedback): string => {
  if (feedback.some((f) => f.code === 'ASPECT_RATIO')) {
    return `https://support.buffer.com/article/622-instagrams-accepted-aspect-ratio-ranges?${HC_UTM_PARAMS}`
  }
  return `https://support.buffer.com/article/554-using-instagram-with-buffer?${HC_UTM_PARAMS}`
}

// @ts-expect-error TS(7031) FIXME: Binding element 'draft' implicitly has an 'any' ty... Remove this comment to see the full error message
const InstagramFeedback = ({ draft }): JSX.Element => {
  if (
    AppStore.getExpandedComposerId() !== draft.id ||
    !draft.service.isInstagram() ||
    draft.instagramFeedback.length === 0
  ) {
    return <></>
  }

  const feedback = draft.instagramFeedback
  return (
    <div className={styles.container}>
      <div className={styles.iconContainer}>
        <WarningIcon size="medium" />
      </div>
      <div>
        {/* @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type. */}
        {feedback.map((item) => (
          // @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: st... Remove this comment to see the full error message
          <Text key={item.code} className={styles.item}>
            {`${item.message} `}
          </Text>
        ))}
        <a
          className={styles.link}
          target="_blank"
          rel="noopener noreferrer"
          href={getLinkUrl(feedback)}
        >
          Learn more
        </a>
      </div>
    </div>
  )
}

InstagramFeedback.propTypes = {
  draft: draftPropType.isRequired,
}

export default InstagramFeedback
