import styled, { css, type FlattenSimpleInterpolation } from 'styled-components'
import {
  grayLighter,
  grayDark,
  gray,
  blue,
  white,
  grayLight,
  blueLightest,
} from '@bufferapp/ui/style/colors'
import { Button } from '@bufferapp/ui'
import { fontSize, fontWeightMedium } from '@bufferapp/ui/style/fonts'
import { AddUpdateButton } from '../AddButton/style'

type CSSRule = string | number | false | FlattenSimpleInterpolation | undefined

export const DayContent = styled.div`
  min-height: calc(100% - 22px);
`

export const PostsWrapper = styled.div`
  margin-top: 30px;
`

export const Day = styled.td<{
  isActive: boolean
  isPast: boolean
  isDraggingOver: boolean
  dayNumber: number | string
  isPastMonth: boolean
}>`
  padding: 8px;
  text-align: right;
  height: 24px;
  vertical-align: top;
  background-color: ${(props): CSSRule =>
    !props.isActive && props.isPast ? grayLighter : white};
  position: relative;
  border-bottom: 1px solid ${grayLight};
  border-right: 1px solid ${grayLight};

  ${(props): CSSRule =>
    props.isDraggingOver &&
    css`
      background-color: ${blueLightest};
      opacity: 50%;
    `}

  &:first-child {
    border-left: 1px solid ${grayLight};
  }

  &:before {
    content: "${(props): CSSRule => props.dayNumber}";
    position: absolute;
    color: ${(props): CSSRule =>
      (props.isPastMonth && gray) || (props.isActive && white) || grayDark};
    font-size: ${fontSize};
    font-weight: ${fontWeightMedium};
    left: 12px;
    top: 12px;
    ${(props): CSSRule =>
      props.isActive &&
      css`
        border-radius: 50%;
        background: ${blue};
        height: 24px;
        width: 24px;
        line-height: 24px;
        text-align: center;
        left: 4px;
        top: 8px;
      `}
    }
  }
  &:hover {
    ${AddUpdateButton} {
      opacity: 1;
    }
  }


`

export const ShowMoreButton = styled(Button)`
  float: left;
`
