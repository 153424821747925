import React from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { PostContainer, PostContainerClone } from './styles'

type DraggablePostItemProps = {
  postId: string
  index: number
  shouldDisableDrag: boolean
  isDraggingOver: boolean
  children: React.ReactNode
}

const DraggablePostItem = ({
  postId,
  index,
  shouldDisableDrag,
  isDraggingOver,
  children,
}: DraggablePostItemProps): JSX.Element => {
  return (
    <Draggable
      draggableId={postId}
      index={index}
      isDragDisabled={shouldDisableDrag}
    >
      {(provided, snapshot): JSX.Element => (
        <>
          <PostContainer
            isDragging={snapshot.isDragging}
            isDraggingOver={isDraggingOver}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
            aria-roledescription="Press space bar to lift the post"
          >
            {children}
          </PostContainer>
          {snapshot.isDragging && (
            <PostContainerClone>{children}</PostContainerClone>
          )}
        </>
      )}
    </Draggable>
  )
}

export default DraggablePostItem
