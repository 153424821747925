import React from 'react'
import States from '@bufferapp/ui/States'
import Button from '@bufferapp/ui/Button'
import styled from 'styled-components'

const Wrapper = styled.div<{ height: string | null }>`
  display: flex;
  justify-content: center;
  height: ${(props): string => (props.height ? props.height : '100%')};
`

const CTAWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  > :nth-child(2) {
    margin-top: 6px;
  }
`

type GlobalEmptyStateProps = {
  imageSrc: string
  altText: string
  header: string
  description: string | JSX.Element
  secondaryAction?: {
    onClick: () => void
    label: string
  } | null
  primaryAction?: {
    onClick: () => void
    label: string
  } | null
  textAction?: {
    onClick: () => void
    label: string
  } | null
  height?: string | null
}

export default function GlobalEmptyState({
  imageSrc,
  altText,
  header,
  description,
  secondaryAction = null,
  primaryAction = null,
  textAction = null,
  height = null,
}: GlobalEmptyStateProps): JSX.Element {
  return (
    <Wrapper height={height}>
      <States>
        <States.Image src={imageSrc} alt={altText} />
        <States.Header>{header}</States.Header>
        <States.Description>{description}</States.Description>
        <CTAWrapper>
          {secondaryAction && (
            // @ts-expect-error TS(2740) FIXME: Type '{ type: string; onClick: any; label: any; }'... Remove this comment to see the full error message
            <Button
              type="secondary"
              onClick={secondaryAction.onClick}
              label={secondaryAction.label}
            />
          )}
          {primaryAction && (
            // @ts-expect-error TS(2740) FIXME: Type '{ type: string; onClick: any; label: any; }'... Remove this comment to see the full error message
            <Button
              type="primary"
              onClick={primaryAction.onClick}
              label={primaryAction.label}
            />
          )}
          {textAction && (
            // @ts-expect-error TS(2740) FIXME: Type '{ type: string; onClick: any; label: any; }'... Remove this comment to see the full error message
            <Button
              type="text"
              onClick={textAction.onClick}
              label={textAction.label}
            />
          )}
        </CTAWrapper>
      </States>
    </Wrapper>
  )
}
