import type { FeedGroup } from '../types'

export const mockFeedGroups: FeedGroup[] = [
  {
    id: '1',
    name: 'Economic Trends',
    description: 'Insights into global economic trends and analysis',
    feeds: [
      {
        id: '11',
        name: 'The Economist - Economic Trends',
        description: 'Economic news and insights',
        url: 'https://www.economist.com/the-world-this-week/rss.xml',
      },
      {
        id: '12',
        name: 'NPR - Economy',
        description: 'Economic news and analysis',
        url: 'https://www.npr.org/rss/rss.php?id=1006',
      },
      {
        id: '13',
        name: 'Brookings - Economic Studies',
        description: 'Research and insights into economic issues',
        url: 'https://www.brookings.edu/topic/economic-studies/feed/',
      },
      {
        id: '14',
        name: 'World Bank Blogs - Economy',
        description: 'World Bank blog on global economic topics',
        url: 'https://blogs.worldbank.org/voices/feed',
      },
      {
        id: '15',
        name: 'NYT - Economy',
        description: 'The New York Times on global economic topics',
        url: 'https://rss.nytimes.com/services/xml/rss/nyt/Economy.xml',
      },
    ],
  },
  {
    id: '2',
    name: 'Financial News',
    description: 'Latest updates and news from the financial markets',
    feeds: [
      {
        id: '21',
        name: 'Seeking Alpha - Apple',
        description: 'News on global financial markets',
        url: 'https://seekingalpha.com/api/sa/combined/AAPL.xml',
      },
      {
        id: '22',
        name: 'CNBC - Finance',
        description: 'Breaking finance news and analysis',
        url: 'https://www.cnbc.com/id/10000664/device/rss/rss.html',
      },
      {
        id: '23',
        name: 'Reuters - Business News',
        description: 'Latest business news from Reuters',
        url: 'https://ir.thomsonreuters.com/rss/news-releases.xml?items=15',
      },
      {
        id: '24',
        name: 'Bloomberg - Markets',
        description: 'Updates on stock markets and economic trends',
        url: 'https://news.google.com/rss/search?q=when:24h+allinurl:bloomberg.com&hl=en-US&gl=US&ceid=US:en',
      },
    ],
  },
  {
    id: '3',
    name: 'Investment Strategy',
    description: 'Insights and strategies for investors',
    feeds: [
      {
        id: '31',
        name: 'Morningstar - Investing Insights',
        description: 'Expert insights into investment trends',
        url: 'http://rss.morningstar.com/rssnet/MorningstarAdvisorRss.xml',
      },
      {
        id: '32',
        name: 'The Motley Fool - Investing News',
        description: 'Investment news and analysis',
        url: 'https://www.fool.com/feeds/index.aspx',
      },
      {
        id: '33',
        name: 'ValueWalk - Investment',
        description: 'News and updates on investment opportunities',
        url: 'https://www.valuewalk.com/feed/',
      },
      {
        id: '34',
        name: 'Kiplinger - Investing',
        description: 'Investment tips and news',
        url: 'https://www.kiplinger.com/kiplinger.xml',
      },
    ],
  },
  {
    id: '4',
    name: 'Personal Finance Tips',
    description: 'Advice and tips for managing personal finances',
    feeds: [
      {
        id: '41',
        name: 'NerdWallet - Personal Finance',
        description: 'Financial advice and personal finance tips',
        url: 'https://www.nerdwallet.com/blog/finance/feed/',
      },
      {
        id: '42',
        name: 'Bankrate - Financial Advice',
        description: 'Guides and tips for financial planning',
        url: 'https://www.bankrate.com/rss/',
      },
      {
        id: '43',
        name: 'Money - Personal Finance',
        description: 'Personal finance advice and news',
        url: 'https://money.com/feed/',
      },
      {
        id: '44',
        name: 'The Simple Dollar',
        description: 'Simple financial advice for everyday needs',
        url: 'https://www.thesimpledollar.com/feed/',
      },
    ],
  },
  {
    id: '5',
    name: 'Retirement Planning',
    description: 'News and tips on planning for retirement',
    feeds: [
      {
        id: '51',
        name: 'Retirement Income Journal',
        description: 'Insights into retirement income and planning',
        url: 'https://retirementincomejournal.com/feed/',
      },
      {
        id: '52',
        name: 'TIAA - Retirement Insights',
        description: 'Retirement planning resources and tips',
        url: 'https://www.tiaa.org/public/feeds/tiaa-news-rss.xml',
      },
      {
        id: '53',
        name: 'SSA - Retirement',
        description: 'Social Security insights on retirement planning',
        url: 'https://blog.ssa.gov/feed/',
      },
      {
        id: '54',
        name: 'AARP - Retirement',
        description: 'Advice and news for retirement planning',
        url: 'https://www.aarp.org/rss/aarp_rr/',
      },
    ],
  },
]
