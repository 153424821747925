import styled, { css } from 'styled-components'
import {
  previewBlack,
  previewGrayMedium,
  previewGrayLight,
  previewGrayLighter,
} from '../styles'

export const Wrapper = styled.div<{
  sideThumbnail: boolean
}>`
  ${(props) =>
    props.sideThumbnail &&
    css`
      display: flex;
    `}
  border-top: 0.5px solid ${previewGrayLight};
  border-bottom: 0.5px solid ${previewGrayLight};
  overflow: hidden;
  margin-top: 8px;
`

export const CompactBox = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  border-top: 0.5px solid ${previewGrayLight};
  border-bottom: 0.5px solid ${previewGrayLight};
  background-color: ${previewGrayLighter};
  padding: 12px;

  .title {
    margin-bottom: 0;
  }
`

export const EmptyImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 88px;
  width: 84px;
  flex-shrink: 0;
  background-color: ${previewGrayLighter};

  svg {
    color: ${previewGrayMedium};
  }
`

export const Image = styled.img<{ imageCover: boolean }>`
  display: block;
  width: 100%;
  max-height: 168px;
  object-fit: ${({ imageCover }) => (imageCover ? 'cover' : 'contain')};
`

export const Content = styled.div`
  padding: 12px;
  background-color: ${previewGrayLighter};
  flex: 1;
`

export const Url = styled.a<{ clickable: boolean }>`
  display: block;
  margin-bottom: 4px;
  color: ${previewGrayMedium};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${(props) => !props.clickable && 'pointer-events: none;'}
`

export const Title = styled.p`
  color: ${previewBlack};
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  margin: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

export const Description = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin: 4px 0 0 0;
`
