import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions as modalsActions } from '~publish/legacy/modals'

import { CommentCharacterCount } from './styles'
import type { Draft } from '../../entities/Draft'
import AppStore from '../../stores/AppStore'
import ComposerActionCreators from '../../action-creators/ComposerActionCreators'
import AppActionCreators from '../../action-creators/AppActionCreators'
import UpgradeIconCTA from '../UpgradeIconCTA'
import * as ReminderFieldsStyled from '~publish/legacy/reminders/components/new-reminders/components/forms/field/styles'
import { selectHasFirstCommentEntitlement } from '~publish/legacy/organizations/selectors'
import { FirstCommentValidationNotice } from '~publish/legacy/composer/composer/components/channel-fields/components/FirstComment'

const FirstCommentInstagramComposerBar = ({
  draft,
}: {
  draft: Draft
}): React.JSX.Element | null => {
  const [hasCheckedForFacebookPermission, setHasCheckedForFacebookPermission] =
    React.useState(false)
  const dispatch = useDispatch()
  const comment = draft.getCommentText()
  const hasSelectedSomeInstagramDirectProfiles =
    AppStore.getSelectedProfiles().some(
      (profile) => profile.instagramDirectEnabled,
    )
  const hasFirstCommentEntitlement = useSelector(
    selectHasFirstCommentEntitlement,
  )
  const isOverCharacterLimit =
    comment.length > (draft.service.commentCharLimit || 0)

  const shouldShowUpgradePaywallIcon = !hasFirstCommentEntitlement
  const isInstagramEligibleForFirstComment =
    AppStore.areAllSelectedProfilesIG() &&
    hasSelectedSomeInstagramDirectProfiles &&
    draft.service.isInstagram() &&
    AppStore.getExpandedComposerId() === draft.id &&
    !draft.service.isOmni &&
    !draft.isStoryPost()

  const shouldDisplayFirstComment = (): boolean =>
    isInstagramEligibleForFirstComment

  if (!shouldDisplayFirstComment()) {
    return null
  }

  const onCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    ComposerActionCreators.updateDraftInstagramData({
      ...draft?.channelData?.instagram,
      comment_text: e.target.value,
    })
    ComposerActionCreators.updateDraftCommentCharacterCount(draft.id)
  }

  const onCommentClick = (e: React.MouseEvent<HTMLTextAreaElement>): void => {
    e.preventDefault()
    if (hasFirstCommentEntitlement && isInstagramEligibleForFirstComment) {
      /** This will trigger, if necessary, a modal to re-auth facebook for the commenting permission */
      if (!hasCheckedForFacebookPermission) {
        AppActionCreators.triggerInteraction({
          message: {
            id: draft.id,
            ids: AppStore.getSelectedProfiles().map((profile) => profile.id),
            action: 'COMMENT_ENABLED',
          },
        })
        setHasCheckedForFacebookPermission(true)
      }
    }
  }

  const handleClick = (editDisabled: boolean): void => {
    if (editDisabled && shouldShowUpgradePaywallIcon) {
      dispatch(
        modalsActions.showFirstCommentPaywallModal({
          service: draft.service.name,
        }),
      )
    }
  }

  const instagramFeedback = draft.instagramFeedback || []
  const editDisabled =
    instagramFeedback.some(
      (f: { code: string }) => f.code === 'ASPECT_RATIO',
    ) || shouldShowUpgradePaywallIcon

  return (
    <ReminderFieldsStyled.Wrapper>
      <ReminderFieldsStyled.LabelWrapper>
        <ReminderFieldsStyled.Label>First Comment</ReminderFieldsStyled.Label>
      </ReminderFieldsStyled.LabelWrapper>
      <ReminderFieldsStyled.InputWrapper
        onClick={(): void => handleClick(editDisabled)}
      >
        <ReminderFieldsStyled.TextAreaInput
          disabled={editDisabled}
          data-testid={
            editDisabled ? 'first-comment-disabled' : 'first-comment'
          }
          placeholder="Your comment"
          value={comment}
          onChange={onCommentChange}
          onClick={onCommentClick}
          rows={1}
          aria-invalid={isOverCharacterLimit}
        />
        {isOverCharacterLimit && <FirstCommentValidationNotice />}
        {shouldShowUpgradePaywallIcon && <UpgradeIconCTA />}
        {draft.service.commentCharLimit &&
          draft.canTypeMoreCommentCharacters() && (
            <CommentCharacterCount
              count={comment.length}
              maxCount={draft.service.commentCharLimit}
            />
          )}
      </ReminderFieldsStyled.InputWrapper>
    </ReminderFieldsStyled.Wrapper>
  )
}

export default FirstCommentInstagramComposerBar
