import { borderRadius } from '@bufferapp/ui/style/borders'
import { blue, gray, grayShadow, white } from '@bufferapp/ui/style/colors'
import PropTypes from 'prop-types'
import React from 'react'
import styled from 'styled-components'

interface CardState {
  faded: boolean
  dragging: boolean
  isOver: boolean
}

interface CardProps {
  children: React.ReactNode
  state: CardState
  isReminderBadgeVisible?: boolean
}

const CardWrapper = styled.div<
  Pick<CardProps, 'state' | 'isReminderBadgeVisible'>
>`
  display: flex;
  flex-direction: column;
  background: ${white};
  box-sizing: border-box;
  border-radius: ${borderRadius};
  width: 100%;
  opacity: ${(props): number => (props.state?.faded ? 0.5 : 1)};
  box-shadow: ${(props): string => (props.state?.dragging ? '' : grayShadow)};
  border-width: 1px;
  border-style: ${(props): string =>
    props.state?.dragging ? 'dashed' : 'solid'};
  border-color: ${(props): string =>
    !props.state?.dragging && props.state?.isOver ? blue : gray};
  ${({ isReminderBadgeVisible }): string | undefined =>
    isReminderBadgeVisible ? 'border-top-left-radius: 0px;' : ''};
`

const Card = ({
  children,
  state,
  isReminderBadgeVisible,
}: CardProps): JSX.Element => (
  <CardWrapper
    state={state}
    isReminderBadgeVisible={isReminderBadgeVisible}
    className="Card"
  >
    {children}
  </CardWrapper>
)

Card.propTypes = {
  children: PropTypes.node,
  state: PropTypes.shape({
    faded: PropTypes.bool,
    dragging: PropTypes.bool,
    isOver: PropTypes.bool,
  }),
}

Card.defaultProps = {
  children: null,
  state: {},
}

export default Card
