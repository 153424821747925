import dayjs from 'dayjs'
import { getDateString, isInThePast } from '../formatters/index'
import {
  parseFacebookEntities,
  parseThreadsLinks,
  parseTwitterLinks,
} from './linkParsing'
import { channelDataParser } from './channelDataParser'

const getImageUrls = (post: any) => {
  if (!(post.media && post.media.picture && post.extra_media)) return []
  const imageUrls = post.extra_media.map((media: any) => media.photo)
  imageUrls.unshift(post.media.picture)
  return imageUrls
}

const isFacebookReelPost = ({
  post,
}: {
  post: { update_type: string }
}): boolean => post.update_type === 'facebook_reel'
const isFacebookStoryPost = ({
  post,
}: {
  post: { update_type: string }
}): boolean => post.update_type === 'facebook_story'
const isReelPost = ({ post }: any) => post.update_type === 'reels'
const isShortPost = ({ post }: any) => post.update_type === 'short'
const isStoryPost = ({ post }: any) => post.update_type === 'story'
const getElementName = ({ post }: any) => {
  let name = 'post'
  if (isReelPost({ post })) {
    name = 'reel'
  }
  if (isFacebookReelPost({ post })) {
    name = 'facebook_reel'
  }
  if (isFacebookStoryPost({ post })) {
    name = 'facebook_story'
  }
  if (isStoryPost({ post })) {
    name = 'story'
  }
  return name
}

const getPostActionString = ({ post }: any) => {
  const timestampToConvert = post.sent_at || post.due_at
  const element = getElementName({ post })
  // due_at set to 0 when user has no scheduled posting times
  if (timestampToConvert === 0) {
    return 'No Time Set'
  }
  const dateString = getDateString(timestampToConvert, post.profile_timezone, {
    twentyFourHourTime: post.twentyfour_hour_time,
  })
  if (post.profile_service === 'tiktok' && post.status === 'pending_tiktok') {
    return `This post was sent to Tiktok on ${dateString}`
  }
  // to run in every situation except when can_send_direct is explicitly false.
  // if pinned is explicitly set to true, then post is not custom scheduled
  if (
    post.scheduled_at &&
    post.can_send_direct !== false &&
    post.pinned !== true
  ) {
    return `This ${element} ${
      post.sent_at ? 'was' : 'is'
    } custom scheduled for ${dateString}.`
  }
  if (
    post.profile_service === 'instagram' &&
    !post.can_send_direct &&
    !post.sent_at
  ) {
    return `You will receive a reminder on ${dateString} when it's time to post.`
  }
  return `This ${element} ${
    post.sent_at ? 'was' : 'will be'
  } sent ${dateString}.`
}

const getPostError = ({ error, status }: any) => {
  if (status !== 'error') {
    return null
  }
  const isObject = typeof error === 'object' && error !== null
  return isObject ? error.text || '' : error || ''
}

const getPostDetails = ({ post }: any) => ({
  postAction: getPostActionString({ post }),
  isRetweet: post.retweet !== undefined,
  error: getPostError({ error: post.error, status: post.status }),
  errorLink:
    post.status === 'error' && post.error && post.error.link
      ? post.error.link
      : null,
  errorLabel:
    post.status === 'error' && post.error_object && post.error_object.link_text
      ? post.error_object.link_text
      : null,
  isCustomScheduled: !!post.scheduled_at,
  isInstagramReminder:
    post.profile_service === 'instagram' && !post.can_send_direct,
  isPendingTiktokPost: post.status === 'pending_tiktok',
})

const getRetweetProfileInfo = (post: any) => {
  const { retweet } = post
  if (!retweet) {
    return undefined
  }
  return {
    name: retweet.profile_name || retweet.display_name || 'UNKNOWN',
    handle: `@${retweet.username || retweet.user_name || 'UNKNOWN'}`,
    avatarUrl:
      (retweet.avatars && retweet.avatars.https) ||
      retweet.avatar_https ||
      'https://static.buffer.com/images/app/placeholder-twitter.png',
  }
}

const getPostType = ({ post }: any) => {
  if (!post.media || post.retweet) {
    return 'text'
  }
  if (
    post.media &&
    post.media.picture &&
    !post.extra_media &&
    !post.media.document
  ) {
    return 'image'
  }
  if (post.media && post.media.picture && post.extra_media) {
    return 'multipleImage'
  }
  if (post.media && post.media.video) {
    return 'video'
  }
  if (post.media && post.media.document) {
    return 'document'
  }
  if (post.media && post.media.link) {
    return 'link'
  }
  return 'text'
}

const getUser = (post: any) => {
  if (!post.user) return
  return {
    email: post.user.email,
    name: post.user.name,
    gravatar: post.user.gravatar,
    avatar: post.user.avatar,
    id: post.user_id,
  }
}

const removeDuplicates = (arr: any, prop: any) => {
  const obj: { [key: string]: any } = {}
  return Object.keys(
    arr.reduce((prev: any, next: any) => {
      if (!obj[next[prop]]) obj[next[prop]] = next
      return obj
    }, obj),
  ).map((i) => obj[i])
}

// TEMPORARY FIX
// TODO: Remove this once problem has been solved
// In a few rare cases, posts or drafts exist with
// a scheduled_at date set, without a due_at date set.
// This should never happen as the due_at property is
// used to actually publish posts to their channels.
// As a temporary patch for the frontend, if a post
// has a scheduled_at date, but no due_at date,
// set the due_at date equal to the scheduled_at date.
const getPostDueAt = (post: any) => {
  if (post.due_at) return post.due_at
  if (post.scheduled_at) return post.scheduled_at
}

const parseVia = (via: any) => {
  // if via is falsy
  if (!via) {
    return null
  }
  if (via === 'api') {
    return 'Buffer'
  }
  return via.charAt(0).toUpperCase() + via.slice(1)
}

const parseAnnotations = (annotations: any) => {
  return annotations?.map((annotation: any) => {
    const { start, length } = annotation
    return { ...annotation, start: Number(start), length: Number(length) }
  })
}

const parseNotes = (notes: any[] = []): any[] => {
  return notes.map((note) => {
    const createdAt =
      typeof note.created_at === 'number'
        ? note.created_at
        : dayjs(note.created_at).unix()
    return {
      id: note._id,
      createdAt,
      editedAt: note.edited_at,
      text: note.text,
      type: note?.type,
      author: {
        email: note.author.email,
        name: note.author.name,
        avatar: note.author?.avatar,
        id: note.author._id,
      },
    }
  })
}

const postParser = (post: any) => {
  const media = post.media || {}
  const isVideo = media.video
  let retweetComment
  let text
  if (post.retweet) {
    text = post.retweet.text
    retweetComment = post.retweet.comment
  } else {
    text = post.text
  }
  const canHaveLinks =
    post.profile_service === 'twitter' ||
    post.profile_service === 'facebook' ||
    post.profile_service === 'threads'
  const facebookLinks =
    post.profile_service === 'facebook'
      ? parseFacebookEntities(text, post.entities)
      : []
  let otherLinks = []
  if (post.profile_service === 'threads') {
    otherLinks = parseThreadsLinks(text)
  } else {
    otherLinks = parseTwitterLinks(text)
  }
  const safeOtherLinks = otherLinks.filter((link) => {
    if (!link) {
      return false
    }
    const startIdx = link.indices[0]
    const endIdx = link.indices[1]
    const hasClash = facebookLinks.some((facebookLink: any) => {
      const facebookStartIdx = facebookLink.indices[0]
      const facebookEndIdx = facebookLink.indices[1]
      const safe = endIdx < facebookStartIdx || startIdx > facebookEndIdx
      return !safe
    })
    return !hasClash
  })
  const linksCreator = facebookLinks
    .concat(safeOtherLinks)
    .sort(
      (
        { indices: [startIdxA] }: { indices: [number] },
        { indices: [startIdxB] }: { indices: [number] },
      ) => startIdxA - startIdxB,
    )
  const links = removeDuplicates(linksCreator, 'indices')
  const retweetCommentLinks = canHaveLinks
    ? parseTwitterLinks(retweetComment)
    : []
  const isFixed = Boolean(post.error)
  const isPastDue = isInThePast(post.scheduled_at)
  let videoSrc
  if (isVideo && media?.video?.details?.transcoded_location) {
    videoSrc = media?.video?.details?.transcoded_location
  }

  return {
    day: post.day,
    id: post.id || post._id,
    status: post?.status,
    createdAt: post.created_at,
    entities: post.entities,
    profileId: post.profile_id,
    isConfirmingDelete: post.isDeleting && !post.requestingDraftAction,
    isDeleting: post.isDeleting && post.requestingDraftAction,
    isWorking: !post.isDeleting && post.requestingDraftAction,
    isMoving: post.isMoving,
    isPastDue,
    imageSrc: isVideo ? media.thumbnail : media.picture,
    videoSrc,
    imageUrls: getImageUrls(post),
    links: canHaveLinks ? links : [],
    profileTimezone: post.profile_timezone,
    linkAttachment: {
      title: media.title,
      url: media.expanded_link,
      description: media.description,
      thumbnailUrl: media.preview,
    },
    needsApproval: post.needs_approval,
    postDetails: getPostDetails({ post }),
    profile_service: post.profile_service,
    retweet: post.retweet,
    retweetComment,
    retweetCommentLinks,
    retweetProfile: getRetweetProfileInfo(post),
    isSent: post.status === 'sent',
    isPastReminder: post.status === 'notified',
    isReminder:
      post.scheduling_type === 'reminder'
        ? true
        : post.scheduling_type === 'direct'
        ? false
        : null,
    isDraft: post.status === 'draft',
    title: post.title,
    source_url: post.source_url,
    text,
    type: getPostType({ post }),
    media,
    extra_media: post.extra_media,
    subprofile_id: post.subprofile_id,
    due_at: getPostDueAt(post),
    scheduled_at: post.scheduled_at,
    scheduledAt: post.scheduled_at,
    sharedNext: post.shared_next,
    pinned: post.pinned,
    isFixed,
    statistics: post.statistics ?? [],
    service_user_tags: post.service_user_tags,
    annotations: parseAnnotations(post?.annotations),
    user: getUser(post),
    serviceLink: post.status === 'pending_tiktok' ? null : post.service_link,
    channelData: channelDataParser(post),
    serviceUpdateId: post.service_update_id,
    dueTime: post.due_time,
    sharedBy: post.shared_by,
    campaignId: post?.campaign_id,
    campaignDetails: post.campaign_details ? post.campaign_details : null,
    notes: parseNotes(post?.notes),
    postContent: {
      imageSrc: isVideo ? media.thumbnail : media.picture,
      videoSrc,
      imageUrls: getImageUrls(post),
      linkAttachment: {
        title: media.title,
        url: media.expanded_link,
        description: media.description,
        thumbnailUrl: media.preview,
      },
      isReelPost: isReelPost({ post }),
      isFacebookReelPost: isFacebookReelPost({ post }),
      isFacebookStoryPost: isFacebookStoryPost({ post }),
      isStoryPost: isStoryPost({ post }),
      isShortPost: isShortPost({ post }),
      links: canHaveLinks ? links : [],
      retweetComment,
      retweetCommentLinks,
      retweetProfile: getRetweetProfileInfo(post),
      text,
      type: getPostType({ post }),
    },
    thread: post.thread,
    updateType: post.update_type,
    via: parseVia(post.via) || null,
    tags: post.tags || [],
  }
}

export { postParser }
export { getPostActionString }
export { parseNotes }
export default {
  postParser,
  getPostActionString,
  parseComments: parseNotes,
}
