import styled from 'styled-components'
import { grayLight, purpleLighter, white } from '@bufferapp/ui/style/colors'

export const Wrapper = styled.div`
  padding: 0 32px;
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;

  svg {
    fill: ${purpleLighter};
    margin: 0 4px 0;
  }
`

export const BodyWrapper = styled.div`
  animation: SlideAndFadeIn 0.3s ease;

  @keyframes SlideAndFadeIn {
    0% {
      opacity: 0;
      transform: translateY(1rem);
    }

    100% {
      opacity: 100%;
      transform: translateY(0);
    }
  }
`

export const ContentWrapper = styled.div`
  padding: 16px;
  border-radius: 4px;
  border: 1px solid ${grayLight};
  background-image: linear-gradient(to right, #faf5ff, #edefff);

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    display: flex;
    align-items: center;
    margin-bottom: 6px;
  }

  .list-icon {
    fill: ${purpleLighter};
    margin-right: 8px;
  }
`

export const Title = styled.h4`
  font-family: 'Roboto';
  font-size: 16px;
  font-weight: 700;
  margin-top: 0;
`

export const Subtitle = styled.h5`
  font-family: 'Roboto';
  font-size: 14px;
  font-weight: 700;
  margin: 0 0 12px 0;
`

export const ContentText = styled.p`
  text-align: center;
  margin: 0;
`

export const ProgressWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 16px 0;
`

export const ProgressCircle = styled.div<{
  empty?: boolean
}>`
  width: 6px;
  height: 6px;
  margin: 0 5px;
  border: 2px solid ${grayLight};
  background: ${({ empty }): string => (empty ? white : grayLight)};
  border-radius: 100px;
`
