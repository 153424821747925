import React, { useState } from 'react'

import { Button } from '../Button'
import { SlideView } from './SlideView'
import { Progress } from './Progress'
import { LoaderAnimation } from '../LoaderAnimation'
import { Wrapper as LoaderAnimationWrapper, Message } from '../Loader.styles'

import { useAccountSetSettings } from '~publish/legacy/ai/hooks/useAccountSetSettings'

import * as Styled from './Onboarding.styles'

function OnboardingBody(): JSX.Element {
  const [currentSlide, setCurrentSlide] = useState(1)
  const [isSavingSettings, setIsSavingSettings] = useState(false)

  const { accountSetSettings } = useAccountSetSettings()

  const viewingFinalSlide = currentSlide === 4

  if (isSavingSettings) {
    return (
      <LoaderAnimationWrapper>
        <LoaderAnimation />
        <Message>Setting things up</Message>
      </LoaderAnimationWrapper>
    )
  }

  return (
    <Styled.BodyWrapper>
      <SlideView currentSlide={currentSlide} />
      <Progress currentSlide={currentSlide} />
      <Button
        disabled={false}
        secondary={false}
        onClick={(): void => {
          setCurrentSlide(currentSlide + 1)

          if (viewingFinalSlide) {
            // Save account settings...
            accountSetSettings({
              variables: {
                settings: {
                  aiSettings: {
                    onboarded: true,
                  },
                },
              },
            })

            setIsSavingSettings(true)
          }
        }}
        fullWidth
      >
        {viewingFinalSlide ? 'Got It' : 'Next'}
      </Button>
    </Styled.BodyWrapper>
  )
}

export { OnboardingBody }
