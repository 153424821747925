import React from 'react'

import {
  draftPreviewPropType,
  profilePreviewPropType,
} from '../Common/propTypes'
import { Empty } from '../Common/Empty'
import { getMediaProperties } from '../Common/utils'
import { PinterestPost } from './components/Post'
import type { DraftPreview, PinterestPostType, ProfilePreview } from '../types'

import * as Styles from './styles'

export const PinterestPreview = ({
  draft,
  profile,
}: {
  draft: DraftPreview
  profile: ProfilePreview
}): JSX.Element => {
  const containerRef = React.useRef<HTMLDivElement | null>(null)

  const draftPinterest = {
    content: {
      ...draft,
      text: draft.text,
    },
  } as PinterestPostType

  const { hasImages, hasVideo } = getMediaProperties(
    draftPinterest.content,
    null,
  )
  const hasMedia = hasImages || hasVideo

  if (draft.isEmpty || !hasMedia) return <Empty />

  return (
    <Styles.Wrapper data-testid="pinterest-preview" ref={containerRef}>
      <PinterestPost content={draftPinterest.content} profile={profile} />
    </Styles.Wrapper>
  )
}

PinterestPreview.propTypes = {
  draft: draftPreviewPropType.isRequired,
  profile: profilePreviewPropType.isRequired,
}
