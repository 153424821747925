import styled from 'styled-components'

import {
  previewBlack,
  PostWrapper as PostWrapperCommon,
} from '../../../Common/styles'

export const PostWrapper = styled(PostWrapperCommon)`
  border: none;
`

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0px 8px 12px 8px;
`

export const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: ${previewBlack};
  margin: 0px 8px 6px 8px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const UsernameWrapper = styled.div`
  display: flex;
  flex-direction column;
  align-items: flex-start;
  margin-left: 8px;
`

export const Username = styled.span`
  display: inline-block;
  color: ${previewBlack};
  max-width: 185px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`

export const ImageWrapper = styled.div`
  position: relative;
  height: auto;
  width: 100%;
  margin-bottom: 10px;
`

export const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  border-radius: 16px;
`
