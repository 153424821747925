/**
 * Accept string time in format like 01:00pm or 13:00 and return object { hours:number; minutes: number; }
 * @param input
 * @constructor
 */
const transformStringTo24HrTime = (
  input: string,
): { hours: number; minutes: number } => {
  const transformedInput = input.split(/\s+/)?.join('').toLowerCase()

  if (transformedInput.includes('am') && transformedInput.startsWith('12:')) {
    return {
      hours: 0,
      minutes: parseInt(transformedInput.split(':')[1].split(/am/)[0], 10),
    }
  }
  if (transformedInput.includes('pm') && transformedInput.startsWith('12:')) {
    return {
      hours: 12,
      minutes: parseInt(transformedInput.split(':')[1].split(/am/)[0], 10),
    }
  }

  if (transformedInput.includes('am') || transformedInput.includes('pm')) {
    return {
      hours: transformedInput.includes('am')
        ? parseInt(transformedInput.split(':')[0], 10)
        : parseInt(transformedInput.split(':')[0], 10) + 12,
      minutes: parseInt(transformedInput.split(':')[1].split(/am|pm/)[0], 10),
    }
  }

  return {
    hours: parseInt(transformedInput.split(':')[0], 10),
    minutes: parseInt(transformedInput.split(':')[1], 10),
  }
}

export { transformStringTo24HrTime }
