import { getEndPoint, getNodeEntry, type TNodeEntry } from '@udecode/plate'
import { Range } from 'slate'
import type { BufferEditor } from '../BufferEditor/types.plate'

/**
 * Check whether the current selection is at the end of the current node.
 * If no node is provided, the current node under selection is used.
 */
export const isSelectionAtEndOfCurrentNode = (
  editor: BufferEditor,
  nodeEntry?: TNodeEntry,
): boolean => {
  if (!editor.selection) return false

  const currentNode = nodeEntry ?? getNodeEntry(editor, editor.selection)
  if (!currentNode) return false

  const [, path] = currentNode
  const end = getEndPoint(editor, path)
  return Range.equals(editor.selection, {
    anchor: end,
    focus: end,
  })
}
