import React from 'react'

import * as Styled from './styles'

export function Field({
  placeholder,
  labelText,
  id,
  name,
  onChange,
  value,
}: {
  labelText: string
  id: string
  name: string
  placeholder: string
  onChange: (e: any) => void
  value: string
}): React.JSX.Element {
  return (
    <Styled.Wrapper>
      <Styled.LabelWrapper>
        <Styled.Label htmlFor={id}>{labelText}</Styled.Label>
      </Styled.LabelWrapper>
      <Styled.InputWrapper>
        <Styled.Input
          type="text"
          id={id}
          value={value}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
        />
      </Styled.InputWrapper>
    </Styled.Wrapper>
  )
}
