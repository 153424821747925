import { createAsyncThunk } from '@reduxjs/toolkit'
import type { AppDispatch, RootState } from '~publish/legacy/store'
import { client, gql } from '~publish/legacy/apollo-client'
import type {
  DeleteEntityNoteInput,
  DeleteEntityNotePayload,
} from '~publish/gql/graphql'

const DELETE_ENTITY_NOTE = gql`
  mutation deleteEntityNote($input: DeleteEntityNoteInput!) {
    deleteEntityNote(input: $input) {
      ... on DeleteEntityNoteResponse {
        entityId
        entityType
        noteId
      }
    }
  }
`

type DeleteEntityNoteResult =
  | {
      deleteEntityNote: DeleteEntityNotePayload
    }
  | null
  | undefined

export const deleteEntityNote = createAsyncThunk<
  DeleteEntityNoteResult,
  DeleteEntityNoteInput,
  { state: RootState; dispatch: AppDispatch }
>(
  'posts/deleteEntityNote',
  async ({ entityId, entityType, noteId }): Promise<DeleteEntityNoteResult> => {
    const response = await client.mutate<
      DeleteEntityNoteResult,
      { input: DeleteEntityNoteInput }
    >({
      mutation: DELETE_ENTITY_NOTE,
      variables: { input: { entityId, entityType, noteId } },
    })

    return response.data
  },
)
