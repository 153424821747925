import { type AnyObject, createPluginFactory } from '@udecode/plate'

import ComposerActionCreators from '~publish/legacy/composer/composer/action-creators/ComposerActionCreators'
import type { BufferValue } from '../BufferEditor/types.plate'
import { getPlainText } from '../BufferEditor/getPlainText'

export const TEXT_SYNC_PLUGIN_KEY = 'text-sync-plugin'

export const createTextSyncPlugin = createPluginFactory<AnyObject, BufferValue>(
  {
    key: TEXT_SYNC_PLUGIN_KEY,
    handlers: {
      onChange: (editor) => () => {
        ComposerActionCreators.updatePostText(editor.id, getPlainText(editor))
      },
    },
  },
)
