/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'

import { useContentGeneration } from '~publish/legacy/ai/hooks/useContentGeneration'
import { ACTIONS } from '~publish/legacy/ai/components/AIAssistant/AIAssistant.constants'
import { Title } from '~publish/legacy/ai/components/AIAssistant/components/Title'
import { GenerationResult } from '~publish/legacy/ai/components/AIAssistant/components/GenerationResult'
import { Prompt } from '~publish/legacy/ai/components/AIAssistant/components/Prompt'
import { AIAssistantContext } from '~publish/legacy/ai/components/AIAssistant/AIAssistant'

import * as Styled from './AIRefine.styles'

/**
 *
 */
function AIRefine({
  initAction,
  initPrompt,
  onClear,
}: {
  initAction: string | null
  initPrompt: string | null
  onClear: () => void
}): JSX.Element | null {
  const [expanded, setExpanded] = React.useState<boolean>(false)

  const { channel } = React.useContext(AIAssistantContext)

  const { generateContent } = useContentGeneration()

  React.useEffect(() => {
    onGenerateContent()
  }, [])

  const onGenerateContent = (): void => {
    if (!initAction || !initPrompt) return

    generateContent({
      prompt: initPrompt,
      action: initAction,
      service: channel,
      tone: null,
    })
  }

  const onToggle = (): void => {
    setExpanded(!expanded)
  }

  const actionConfig = ACTIONS[initAction as keyof typeof ACTIONS]

  return initPrompt ? (
    <Styled.Wrapper>
      <Styled.PromptWrapper withBorder={expanded}>
        {actionConfig && (
          <Title testId={`ai-assistant-refine-title`}>
            {actionConfig.label}:
          </Title>
        )}
        <Prompt
          prompt={initPrompt}
          expanded={expanded}
          onToggle={onToggle}
          onClear={onClear}
          lines={2}
          withBackground
        />
      </Styled.PromptWrapper>
      <GenerationResult disabled={expanded} />
    </Styled.Wrapper>
  ) : null
}

export { AIRefine }
