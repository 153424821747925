import React from 'react'

import { RetryButton } from '~publish/legacy/ai/components/AIAssistant/components/RetryButton'

import * as Styled from './Error.styles'
import { useAppSelector } from '~publish/legacy/store'
import { selectContentGenerationStatus } from '~publish/legacy/ai/state/slice'

/**
 *
 */
function Error(): JSX.Element {
  const { contentGenerationError: errorMessage } = useAppSelector((state) =>
    selectContentGenerationStatus(state),
  )
  return (
    <Styled.Wrapper>
      <Styled.Title>Well this is embarrassing...</Styled.Title>
      <Styled.Message>{errorMessage}</Styled.Message>
      <RetryButton withBorder>Try Again</RetryButton>
    </Styled.Wrapper>
  )
}

export { Error }
