import React, { type ReactNode } from 'react'
import styled from 'styled-components'
import { white, grayLight } from '@bufferapp/ui/style/colors'

const NavList = styled.ul<{ $hasBorder?: boolean }>`
  margin: 0;
  padding: 0;
  position: relative;
  border-bottom: ${({ $hasBorder }): string =>
    $hasBorder ? `1px solid ${grayLight}` : 'none'};
  box-sizing: border-box;
`

const NavWrapper = styled.nav<{
  $customPadding?: string
  $hasBorder?: boolean
}>`
  background-color: ${white};
  padding: ${({ $customPadding }): string => $customPadding || '0 0.5rem'};
  border-bottom: ${({ $hasBorder }): string =>
    $hasBorder ? `1px solid ${grayLight}` : 'none'};
`

const Nav = ({
  children,
  disabled = false,
  customPadding = undefined,
  showBorderOnParent = false,
}: {
  children: ReactNode
  disabled?: boolean
  customPadding?: string
  showBorderOnParent?: boolean
}): JSX.Element => (
  <NavWrapper
    aria-label={disabled ? 'Connect a channel to explore the tabs' : undefined}
    aria-disabled={disabled}
    $customPadding={customPadding}
    $hasBorder={showBorderOnParent}
  >
    <NavList $hasBorder={!showBorderOnParent}>{children}</NavList>
  </NavWrapper>
)

export default Nav
