import { connect } from 'react-redux'
import { actions as modalsActions } from '~publish/legacy/modals/reducer'

import { getURL } from '~publish/legacy/utils/formatters'
import { actions as profilesActions } from '~publish/legacy/profiles/reducer'

import InstagramFirstCommentModal from './components/InstagramFirstCommentModal'

export default connect(
  (state) => {
    // @ts-expect-error TS(2339) FIXME: Property 'profileSidebar' does not exist on type '... Remove this comment to see the full error message
    const profile = state.profileSidebar.selectedProfile
    // @ts-expect-error TS(2339) FIXME: Property 'modals' does not exist on type 'DefaultR... Remove this comment to see the full error message
    const firstCommentIds = state.modals && state.modals.firstCommentIds
    const channelsWithFirstCommentIds = firstCommentIds
      ? // @ts-expect-error TS(2339) FIXME: Property 'profileSidebar' does not exist on type '... Remove this comment to see the full error message
        state.profileSidebar.profiles.filter((p) =>
          firstCommentIds.includes(p.id),
        )
      : []
    const selectedProfiles = firstCommentIds
      ? channelsWithFirstCommentIds
      : [profile]

    return {
      // @ts-expect-error TS(2339) FIXME: Property 'profileSidebar' does not exist on type '... Remove this comment to see the full error message
      profile: state.profileSidebar.selectedProfile,
      selectedProfiles,
      firstCommentIds,
      // @ts-expect-error TS(2339) FIXME: Property 'clientAccess' does not exist on type 'De... Remove this comment to see the full error message
      appId: state.clientAccess && state.clientAccess.appId,
      // @ts-expect-error TS(2339) FIXME: Property 'i18n' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
      translations: state.i18n.translations['instagram-first-comment-modal'],
    }
  },
  (dispatch) => ({
    hideModal: () => dispatch(modalsActions.hideInstagramFirstCommentModal()),
    loadFacebook: () => {
      ;(function (d, s, id) {
        if (d.getElementById(id)) return
        const fjs = d.getElementsByTagName(s)[0]
        const js = d.createElement(s)
        js.id = id
        // @ts-expect-error TS(2339) FIXME: Property 'src' does not exist on type 'HTMLElement... Remove this comment to see the full error message
        js.src = '//connect.facebook.net/en_US/sdk.js'
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        fjs.parentNode.insertBefore(js, fjs)
      })(document, 'script', 'facebook-jssdk')
    },
    // @ts-expect-error TS(7031) FIXME: Binding element 'profileId' implicitly has an 'any... Remove this comment to see the full error message
    launchRequestMorePermission: ({ profileId }) => {
      const permissionsURL = getURL.getAccountChannelsURL()
      const popup = window.open(permissionsURL)

      const interval = setInterval(() => {
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        if (popup.closed) {
          clearInterval(interval)
          dispatch(
            // @ts-expect-error TS(2345) FIXME: Argument of type '{ profileId: any; }' is not assi... Remove this comment to see the full error message
            profilesActions.fetchSingleProfile({
              profileId,
            }),
          )
          dispatch(modalsActions.hideInstagramFirstCommentModal())
        }
      }, 150)
    },
  }),
)(InstagramFirstCommentModal)
