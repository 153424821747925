import {
  getNodeString,
  isElement,
  type TElement,
  type TNode,
} from '@udecode/plate'
import type { SerializedNode } from '~publish/legacy/editor/BufferEditor/types'
import type { PlainText } from '~publish/legacy/editor/BufferEditor/types.plate'

const LINKED_IN_ANNOTATION_TYPE = 'linkedin-annotation' as const

export enum SupportedEntity {
  Company = 'company',
  School = 'school',
  Showcase = 'showcase',
}

export type LinkedInEntityDetails = {
  id: number
  entity: string
  vanityName: string
  localizedName: string
  link: string
}

const supportedEntitiesString = Object.values(SupportedEntity).join('|')
export const LINKEDIN_ENTITY_URL_REGEX = new RegExp(
  `(?:(?:https|http)://(?:www.)?linkedin.com/(?<entityType>${supportedEntitiesString})/)(?<vanityName>[a-zA-Z0-9-&'%.]*)`,
)

export interface LinkedInAnnotationElement
  extends TElement,
    LinkedInEntityDetails {
  type: typeof LINKED_IN_ANNOTATION_TYPE
  children: [PlainText]
}

export const LinkedInAnnotationElement = {
  type: LINKED_IN_ANNOTATION_TYPE,

  is(node: TNode | undefined): node is LinkedInAnnotationElement {
    return isElement(node) && node.type === LinkedInAnnotationElement.type
  },

  serialize(
    node: LinkedInAnnotationElement,
  ): SerializedNode<LinkedInEntityDetails> {
    return {
      text: getNodeString(node),
      data: LinkedInAnnotationElement.toEntity(node),
    }
  },

  new(details: LinkedInEntityDetails): LinkedInAnnotationElement {
    return {
      type: LinkedInAnnotationElement.type,
      children: [{ text: details.localizedName }],
      id: details.id,
      link: details.link,
      entity: details.entity,
      vanityName: details.vanityName,
      localizedName: details.localizedName,
    }
  },

  toEntity(node: LinkedInAnnotationElement): LinkedInEntityDetails {
    return {
      id: node.id,
      link: node.link,
      entity: node.entity,
      vanityName: node.vanityName,
      localizedName: node.localizedName,
    }
  },
}
