import React from 'react'
import styled from 'styled-components'

type SetupAppDetailsParams = {
  initKey: string
  email: string
}

const StyledList = styled.ul`
  list-style-type: none;
  padding: 0;
`

const SetupAppDetails = ({
  initKey,
  email,
}: SetupAppDetailsParams): JSX.Element => (
  <p>
    <StyledList>
      <li>
        <b>Issuer: </b>Buffer
      </li>
      <li>
        <b>Account: </b>
        {email}
      </li>
      <li>
        <b>Secret Key: </b>
        {initKey}
      </li>
    </StyledList>
  </p>
)

export default SetupAppDetails
