import {
  type Slice,
  createSlice,
  type SliceCaseReducers,
  type AnyAction,
} from '@reduxjs/toolkit'

import { actionTypes as draftActionTypes } from '~publish/legacy/drafts/reducer'
import { actionTypes as queueActionTypes } from '~publish/legacy/queue/actionTypes'

type AllPostsState = {
  shouldRefetch: boolean
}

const initialState: AllPostsState = {
  shouldRefetch: false,
}

const POST_ACTIONS = [
  draftActionTypes.DRAFT_CREATED,
  draftActionTypes.DRAFT_UPDATED,
  draftActionTypes.DRAFT_DELETED,
  draftActionTypes.DRAFT_APPROVED,
  draftActionTypes.DRAFT_MOVED,
  queueActionTypes.POST_CREATED,
  queueActionTypes.POST_UPDATED,
  queueActionTypes.POST_DELETED,
  queueActionTypes.POST_SENT,
]

const shouldRefetchPosts = (action: AnyAction): boolean =>
  POST_ACTIONS.includes(action.type)

export const allPostsSlice: Slice<
  AllPostsState,
  SliceCaseReducers<AllPostsState>,
  'allPosts'
> = createSlice({
  name: 'allPosts',
  initialState,
  reducers: {
    shouldRefetch(state) {
      state.shouldRefetch = true
    },
    clearShouldRefetch(state) {
      state.shouldRefetch = false
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(shouldRefetchPosts, (state) => {
      state.shouldRefetch = true
    })
  },
})

export const { shouldRefetch, clearShouldRefetch } = allPostsSlice.actions

export default allPostsSlice.reducer
