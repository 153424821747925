import { getURL } from '~publish/legacy/utils/formatters'
import { actionTypes } from './reducer'

// @ts-expect-error TS(7006) FIXME: Parameter 'next' implicitly has an 'any' type.
export default () => (next) => (action) => {
  // eslint-disable-line
  next(action)
  switch (action.type) {
    case actionTypes.RECONNECT_PROFILE: {
      const reconnectURL = getURL.getAccountChannelsURL()
      if (action.service === 'instagram') {
        /**
         * This silly looking code loads an 'img' with the
         * Instagram logout URL, which ensures the user is
         * logged out of Instagram before we send them to
         * reconnect.
         */
        const img = new Image()
        img.onerror = () => {
          window.location.assign(reconnectURL)
        }
        img.src = 'https://www.instagram.com/accounts/logoutin'
        document.getElementsByTagName('head')[0].appendChild(img)
      } else {
        window.location.assign(reconnectURL)
      }
      break
    }
    default:
      break
  }
}
