import React, { useState } from 'react'

import { Button, ChevronDownIcon, Select, Text } from '@buffer-mono/popcorn'

import ComposerActionCreators from '../../../../action-creators/ComposerActionCreators'
import { buttonOptions, initialButtonOption } from './constants'
import type { GoogleBusinessChannelData } from '../../../post-preview/previews/types'

const ButtonSelect = ({
  data,
  onSetShouldShowLink,
}: {
  data: GoogleBusinessChannelData
  onSetShouldShowLink: (shouldShowLink: boolean) => void
}): JSX.Element => {
  const [isSelectOpen, setIsSelectOpen] = useState(false)
  const selectedOption =
    buttonOptions.filter((option) => option.value === data.cta)[0] ||
    initialButtonOption

  const onSelectOptionChange = (selectedItemValue: string): void => {
    setIsSelectOpen(false)
    onSetShouldShowLink(selectedItemValue !== 'none')

    ComposerActionCreators.updateDraftGoogleBusinessData({
      ...data,
      cta: selectedItemValue,
    })
  }

  return (
    <Select
      value={selectedOption.value}
      onValueChange={onSelectOptionChange}
      open={isSelectOpen}
      onOpenChange={setIsSelectOpen}
    >
      <Select.Trigger>
        <Button variant="secondary">
          <Text size="sm">{selectedOption.title}</Text>
          <ChevronDownIcon />
        </Button>
      </Select.Trigger>

      <Select.Content>
        {buttonOptions.map((item) => (
          <Select.Item key={item.value} value={item.value}>
            {item.title}
          </Select.Item>
        ))}
      </Select.Content>
    </Select>
  )
}

export default ButtonSelect
