import { graphql } from '~publish/gql'

import { useTypedMutation } from './useTypedMutation'

export const RevertPostApprovalRequest = graphql(/* GraphQL */ `
  mutation RevertPostApprovalRequest($id: PostId!) {
    revertPostApprovalRequest(input: { id: $id }) {
      __typename
      ... on MutationError {
        message
      }
      ... on PostActionSuccess {
        post {
          id
          status
          dueAt
        }
      }
    }
  }
`)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export const useRevertPostApprovalRequest = () =>
  useTypedMutation(
    RevertPostApprovalRequest,
    (data) => data.revertPostApprovalRequest,
    {
      refetchQueries: ['GetPostList'],
      successTypename: 'PostActionSuccess',
    },
  )
