import { getEditorString, type TNodeEntry, unwrapNodes } from '@udecode/plate'
import type { BufferEditor } from '~publish/legacy/editor/BufferEditor/types.plate'
import { setNodeValidityAgainstRegex } from './setNodeValidityAgainstRegex'
import type {
  AutocompleteInputElement,
  AutocompleteInputElementInterface,
} from '../nodes'

export const normalizeMention = <E extends AutocompleteInputElement>(
  editor: BufferEditor,
  elementInterface: AutocompleteInputElementInterface<E>,
  nodeEntry: TNodeEntry,
): boolean => {
  const [node, path] = nodeEntry

  if (elementInterface.is(node)) {
    const currentMentionText = getEditorString(editor, path)

    if (!currentMentionText.startsWith(elementInterface.trigger)) {
      unwrapNodes(editor, {
        at: path,
        match: (n) => elementInterface.is(n),
      })
      return true
    }

    if (setNodeValidityAgainstRegex(editor, elementInterface, nodeEntry)) {
      return true
    }
  }

  return false
}
