// component vs. container https://medium.com/@dan_abramov/smart-and-dumb-components-7ca2f9a7c7d0
import { connect } from 'react-redux'
import { actions as analyticsActions } from '~publish/legacy/analytics-middleware/actions'
// load the presentational component
import { actions } from './reducer'
import GridPosts from './components/GridPosts'
import { getChannelProperties, isValidURL, urlHasProtocol } from './util'

// @ts-expect-error TS(7006) FIXME: Parameter 'posts' implicitly has an 'any' type.
const orderPostLists = (posts) => {
  // @ts-expect-error TS(7034) FIXME: Variable 'postLists' implicitly has type 'any[]' i... Remove this comment to see the full error message
  const postLists = []
  const orderedPosts =
    posts && typeof posts === 'object'
      ? // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
        Object.values(posts).sort((a, b) => Number(b.due_at) - Number(a.due_at))
      : []

  orderedPosts.forEach((post) => {
    postLists.push(post)
  })

  // @ts-expect-error TS(7005) FIXME: Variable 'postLists' implicitly has an 'any[]' typ... Remove this comment to see the full error message
  return postLists
}

// @ts-expect-error TS(7006) FIXME: Parameter 'item' implicitly has an 'any' type.
const modifyItem = (item) => {
  return item
    ? {
        ...item,
        url: urlHasProtocol(item.url) ? item.url : `https://${item.url}`,
      }
    : item
}

export default connect(
  (state, ownProps) => {
    // @ts-expect-error TS(2339) FIXME: Property 'profileId' does not exist on type '{}'.
    const { profileId } = ownProps
    // @ts-expect-error TS(2339) FIXME: Property 'grid' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
    const currentProfile = state.grid.byProfileId[profileId]
    if (currentProfile) {
      const gridPosts = orderPostLists(currentProfile.gridPosts)
      // @ts-expect-error TS(2339) FIXME: Property 'profileSidebar' does not exist on type '... Remove this comment to see the full error message
      const profile = state.profileSidebar.selectedProfile

      return {
        loading: currentProfile.loading,
        page: currentProfile.page,
        gridPosts,
        total: gridPosts.length,
        profile,
        isDisconnectedProfile: profile.isDisconnected,
        customLinksDetails: currentProfile.customLinksDetails,
        maxCustomLinks: currentProfile.maxCustomLinks,
        publicGridUrl: `https://shopgr.id/${profile.serviceUsername}`,
        previewGridUrl: `https://buffer.com/p/${profile.serviceUsername}`,
        hasWriteAccess: profile.permissions?.includes?.('buffer_write'),
      }
    }
    return {}
  },
  (dispatch, ownProps) => ({
    // @ts-expect-error TS(7031) FIXME: Binding element 'item' implicitly has an 'any' typ... Remove this comment to see the full error message
    onSaveNewLinkClick: ({ item }) => {
      dispatch(
        actions.handleAddNewGridLink({
          // @ts-expect-error TS(2339) FIXME: Property 'profileId' does not exist on type '{}'.
          profileId: ownProps.profileId,
          item: modifyItem(item),
        }),
      )
    },
    // @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
    onChangePostUrl: (post, link) => {
      dispatch(
        actions.handleChangePostUrl({
          post,
          // @ts-expect-error TS(2339) FIXME: Property 'profileId' does not exist on type '{}'.
          profileId: ownProps.profileId,
          link,
          oldLink: post.link,
        }),
      )
    },
    // @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
    onSavePostUrl: (post, link) => {
      dispatch(
        actions.handleSavePostUrl({
          post,
          // @ts-expect-error TS(2339) FIXME: Property 'profileId' does not exist on type '{}'.
          profileId: ownProps.profileId,
          link,
        }),
      )
    },
    // @ts-expect-error TS(7031) FIXME: Binding element 'copySuccess' implicitly has an 'a... Remove this comment to see the full error message
    handleCopyToClipboard: ({ copySuccess, publicGridUrl }) => {
      dispatch(
        actions.handleCopyToClipboardResult({
          copySuccess,
          publicGridUrl,
        }),
      )
    },
    // @ts-expect-error TS(7006) FIXME: Parameter 'channel' implicitly has an 'any' type.
    trackPagePreviewed: (channel) => {
      const metadata = getChannelProperties(channel)
      dispatch(
        analyticsActions.trackEvent('Shop Grid Page Previewed', metadata),
      )
    },
    // @ts-expect-error TS(7031) FIXME: Binding element 'item' implicitly has an 'any' typ... Remove this comment to see the full error message
    onUpdateSingleCustomLink: ({ item }) => {
      dispatch(
        actions.handleUpdateSingleCustomLink({
          // @ts-expect-error TS(2339) FIXME: Property 'profileId' does not exist on type '{}'.
          profileId: ownProps.profileId,
          linkId: item._id,
          item: modifyItem(item),
        }),
      )
    },
    // @ts-expect-error TS(7031) FIXME: Binding element 'customLinks' implicitly has an 'a... Remove this comment to see the full error message
    onUpdateCustomLinks: ({ customLinks, linkText, linkUrl, item }) => {
      dispatch(
        actions.handleUpdateCustomLinks({
          // @ts-expect-error TS(2339) FIXME: Property 'profileId' does not exist on type '{}'.
          profileId: ownProps.profileId,
          customLinks,
          customLinkColor: null,
          customLinkContrastColor: null,
          customLinkButtonType: null,
          linkText,
          linkUrl:
            urlHasProtocol(linkUrl) || !linkUrl
              ? linkUrl
              : `https://${linkUrl}`,
          item: modifyItem(item),
        }),
      )
    },
    onUpdateCustomLinksColor: ({
      // @ts-expect-error TS(7031) FIXME: Binding element 'customLinkColor' implicitly has a... Remove this comment to see the full error message
      customLinkColor,
      // @ts-expect-error TS(7031) FIXME: Binding element 'customLinkContrastColor' implicit... Remove this comment to see the full error message
      customLinkContrastColor,
    }) => {
      dispatch(
        actions.handleUpdateCustomLinks({
          // @ts-expect-error TS(2339) FIXME: Property 'profileId' does not exist on type '{}'.
          profileId: ownProps.profileId,
          customLinks: false,
          customLinkColor,
          customLinkContrastColor,
          customLinkButtonType: null,
        }),
      )
    },
    // @ts-expect-error TS(7031) FIXME: Binding element 'customLinkButtonType' implicitly ... Remove this comment to see the full error message
    onUpdateCustomLinksButtonType: ({ customLinkButtonType }) => {
      dispatch(
        actions.handleUpdateCustomLinks({
          // @ts-expect-error TS(2339) FIXME: Property 'profileId' does not exist on type '{}'.
          profileId: ownProps.profileId,
          customLinks: false,
          customLinkColor: null,
          customLinkContrastColor: null,
          customLinkButtonType,
        }),
      )
    },
    // @ts-expect-error TS(7031) FIXME: Binding element 'customLinkId' implicitly has an '... Remove this comment to see the full error message
    onDeleteCustomLink: ({ customLinkId }) => {
      dispatch(
        actions.handleDeleteCustomLink({
          // @ts-expect-error TS(2339) FIXME: Property 'profileId' does not exist on type '{}'.
          profileId: ownProps.profileId,
          customLinkId,
        }),
      )
    },
    // @ts-expect-error TS(7031) FIXME: Binding element 'item' implicitly has an 'any' typ... Remove this comment to see the full error message
    onUpdateLinkText: ({ item, value }) => {
      dispatch(
        actions.handleEditCustomLinkText({
          // @ts-expect-error TS(2339) FIXME: Property 'profileId' does not exist on type '{}'.
          profileId: ownProps.profileId,
          item,
          value,
          prop: 'text',
        }),
      )
    },
    // @ts-expect-error TS(7031) FIXME: Binding element 'item' implicitly has an 'any' typ... Remove this comment to see the full error message
    onSaveCustomLinkText: ({ item }) => {
      dispatch(
        actions.handleSaveCustomLink({
          // @ts-expect-error TS(2339) FIXME: Property 'profileId' does not exist on type '{}'.
          profileId: ownProps.profileId,
          item,
        }),
      )
    },
    // @ts-expect-error TS(7031) FIXME: Binding element 'item' implicitly has an 'any' typ... Remove this comment to see the full error message
    onUpdateLinkUrl: ({ item, value }) => {
      dispatch(
        actions.handleEditCustomLinkUrl({
          // @ts-expect-error TS(2339) FIXME: Property 'profileId' does not exist on type '{}'.
          profileId: ownProps.profileId,
          item,
          value,
          prop: 'url',
        }),
      )
    },
    // @ts-expect-error TS(7031) FIXME: Binding element 'item' implicitly has an 'any' typ... Remove this comment to see the full error message
    onToggleEditMode: ({ item, editing }) => {
      dispatch(
        actions.handleToggleEditMode({
          // @ts-expect-error TS(2339) FIXME: Property 'profileId' does not exist on type '{}'.
          profileId: ownProps.profileId,
          item,
          editing,
        }),
      )
    },
    // @ts-expect-error TS(7031) FIXME: Binding element 'item' implicitly has an 'any' typ... Remove this comment to see the full error message
    onCancelCustomLinkEdit: ({ item }) => {
      dispatch(
        actions.handleOnCancelCustomLinkEdit({
          // @ts-expect-error TS(2339) FIXME: Property 'profileId' does not exist on type '{}'.
          profileId: ownProps.profileId,
          item,
        }),
      )
    },
    // @ts-expect-error TS(7031) FIXME: Binding element 'customLinkSource' implicitly has ... Remove this comment to see the full error message
    onSwapCustomLinks: ({ customLinkSource, customLinkTarget }) => {
      dispatch(
        actions.handleSwapCustomLinks({
          // @ts-expect-error TS(2339) FIXME: Property 'profileId' does not exist on type '{}'.
          profileId: ownProps.profileId,
          customLinkSource,
          customLinkTarget,
        }),
      )
    },
    // @ts-expect-error TS(7031) FIXME: Binding element 'item' implicitly has an 'any' typ... Remove this comment to see the full error message
    isValidItem: ({ item }) => {
      const itemText = (item && item.text) || ''
      const itemUrl = (item && item.url) || ''
      const cleanItemText = itemText.replace(/ /g, '')
      return cleanItemText !== '' && isValidURL(itemUrl)
    },
  }),
)(GridPosts)

// export reducer, actions and action types
export { default as reducer, actions, actionTypes } from './reducer'
export { default as middleware } from './middleware'
