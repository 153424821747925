import BaseValidator from '~publish/legacy/composer/composer/lib/validation/BaseValidator'
import type ValidationResult from '~publish/legacy/validation/ValidationResult'
import ValidationSuccess from '~publish/legacy/validation/ValidationSuccess'
import ValidationFail from '~publish/legacy/validation/ValidationFail'
import { REQUIRES_TEXT_OR_ATTACHMENT } from '~publish/legacy/composer/composer/lib/validation/utils/validationErrorMessages'
import {
  type Draft,
  DraftMethods,
} from '~publish/legacy/composer/composer/entities/Draft'
import type { Retweet } from '~publish/legacy/composer/composer/entities/factories'
import { VALIDATION_CODE } from '~publish/legacy/validation/constants'
import type { Post } from '../../entities/Draft/Draft'

export default class TwitterValidator extends BaseValidator {
  protected validateAttachmentOrTextPresence(
    draft: Post = this.draft,
    index: number | null = null,
    activeThreadId: number | null = null,
  ): ValidationResult {
    if (
      !DraftMethods.hasText(draft as Draft) &&
      !DraftMethods.hasAttachment(draft as Draft)
    ) {
      return new ValidationFail(
        index !== null
          ? `${REQUIRES_TEXT_OR_ATTACHMENT} for Tweet #${index + 1}`
          : `${REQUIRES_TEXT_OR_ATTACHMENT}`,
        VALIDATION_CODE.MISSING_VALUE,
      )
    }

    const charLimit = DraftMethods.getCharLimit(this.draft) || 0
    const characterCount = draft.characterCount

    if (characterCount && characterCount > charLimit) {
      return new ValidationFail(
        `Tweet ${
          activeThreadId !== null && index !== null ? `#${index + 1} ` : ' '
        }can have maximum ${charLimit} characters`,
        VALIDATION_CODE.CHARACTER_LIMIT_REACHED,
      )
    }

    return new ValidationSuccess()
  }

  protected validateFirstThreadedTweetIsNotNativeRetweet(draft: {
    text?: string
    retweet: Retweet | null
  }): ValidationResult {
    if (draft?.retweet?.tweetId && draft.text?.trim() === '') {
      return new ValidationFail(
        'Threads cannot be started by Retweet without text. Please add content to your first Tweet',
        VALIDATION_CODE.MISSING_VALUE,
      )
    }

    return new ValidationSuccess()
  }

  /**
   * Validate the actual draft, that is when isDraft == true
   * @protected
   */
  protected validateDraft(): ValidationFail[] {
    // non-thread draft
    if (!this.draft.hasThread()) {
      return [this.validateAttachmentOrTextPresence()].filter(
        (validation): validation is ValidationFail =>
          validation.isValidationFail(),
      )
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    const threadValidation = this.draft.thread?.map((threadedUpdate, i) => {
      return this.validateAttachmentOrTextPresence(
        this.activeThreadId === i ? this.draft : threadedUpdate,
        i,
        this.activeThreadId,
      )
    })

    const parentRetweetValidation =
      this.validateFirstThreadedTweetIsNotNativeRetweet(
        this.activeThreadId === 0
          ? {
              text: this.draft.text,
              retweet: this.draft.retweet,
            }
          : this.draft.thread[0],
      )

    threadValidation?.push(parentRetweetValidation)

    return (
      threadValidation?.filter((validation): validation is ValidationFail =>
        validation.isValidationFail(),
      ) ?? []
    )
  }

  /**
   * Validate the actual update, that is when isDraft == false
   * @protected
   */
  protected validateUpdate(): ValidationFail[] {
    return this.validateDraft()
  }
}
