import styled from 'styled-components'

import { Text } from '@bufferapp/ui'
import { gray } from '@bufferapp/ui/style/colors'

export const HeadingText = styled(Text)`
  width: 380px;
`

export const TextStyled = styled(Text)`
  margin: 0;
`

export const CloseWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 16px;
  display: flex;
`

export const BodyModal = styled.div<{ background: string }>`
  width: 100%;
  box-sizing: border-box;
  background: ${(props) => props.background};
  background-size: 50% auto;
  background-position: right;
  padding: 0 24px;
`

export const TextWrapper = styled.div`
  width: 100%;
  margin-bottom: 32px;
`

export const IconWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  right: 16px;
  svg {
    fill: ${gray};
  }
`

export const Check = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
  svg {
    margin-right: 8px;
  }
`

export const CTAWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 320px;
  justify-content: space-between;
`
