import React from 'react'
import {
  YtShortIcon,
  IgStoryIcon,
  IgReelIcon,
  Badge,
  GbpEventIcon,
  GbpOfferIcon,
  GbpWhatsNewIcon,
  type IconProps,
} from '@buffer-mono/popcorn'

import type { PostType } from '~publish/gql/graphql'

import { usePostData } from '../PostCardContext'
import { ChannelInfo } from './ChannelInfo'

import styles from './PostCardHeader.module.css'

const postTypeMap: Partial<
  Record<PostType, { icon: React.ComponentType<IconProps>; label: string }>
> = {
  story: { icon: IgStoryIcon, label: 'Story' },
  reel: { icon: IgReelIcon, label: 'Reel' },
  short: { icon: YtShortIcon, label: 'Short' },
  event: { icon: GbpEventIcon, label: 'Event' },
  offer: { icon: GbpOfferIcon, label: 'Offer' },
  whats_new: { icon: GbpWhatsNewIcon, label: "What's New" },
}

const PostTypeDisplay = (): JSX.Element => {
  const { metadata } = usePostData()
  const type = metadata?.type

  if (type && postTypeMap[type] !== undefined) {
    const { icon: Icon, label } = postTypeMap[type]

    return (
      <Badge>
        <Icon color="subtle" />
        {label}
      </Badge>
    )
  }

  return <></>
}

export const PostCardHeader = (): JSX.Element | null => {
  return (
    <header className={styles.header}>
      <ChannelInfo />
      <PostTypeDisplay />
    </header>
  )
}

PostCardHeader.displayName = 'PostCardHeader'
