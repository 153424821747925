import styled from 'styled-components'
import { gray, white, grayDark } from '@bufferapp/ui/style/colors'

import { twitterBlack, twitterBlueishGray } from '../../styles'
import { LinkPreview as Link } from '../../../Common/LinkPreview'
import { ImagesGrid as ImagesGridCommon } from '../../../Common/ImagesGrid'

export const Wrapper = styled.div<{
  withBorder: boolean
}>`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: 15px;
  padding: 0px 0px 16px 27px;

  & > * {
    margin-bottom: 8px;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  ${(props): string | false =>
    props.withBorder &&
    `
    &::after {
      content: '';
      position: absolute;
      left: -7px;
      width: 2px;
      height: 100%;
      opacity: 0.5;
      border-radius: 8px 8px 0px 0px;
      background: ${gray};
      z-index: 0;
    }
  `}

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-12px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  animation: fadeIn 0.3s;
`

export const Avatar = styled.img`
  position: absolute;
  left: -21px;
  top: -10px;
  width: 32px;
  height: 32px;
  border-radius: 100px;
  border: solid 6px #ffffff;
  z-index: 1;
`

export const UsernameWrapper = styled.div`
  display: flex;
  flex-direction row;
  align-items: center;
  margin-bottom: 4px;
`

export const Username = styled.span`
  display: inline-block;
  color: ${twitterBlack};
  margin-right: 4px;
  max-width: 160px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
`

export const FormattedUsername = styled.span`
  display: inline-block;
  color: ${grayDark};
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const Content = styled.div`
  img {
    width: 100%;
    border-radius: 12px;
  }

  span {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: ${twitterBlack} !important;
    white-space: pre-wrap !important;
    overflow-wrap: break-word !important;
    hyphens: manual !important;
  }

  p {
    margin-top: 0;
  }

  a {
    cursor: default !important;
  }
`

export const RetweetLabel = styled.p`
  width: 100%;
  padding: 0 20px;
  color: ${twitterBlueishGray};
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  margin: 0 0 8px 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  svg {
    color: ${twitterBlueishGray};
    fill: ${twitterBlueishGray};
    margin-right: 4px;
  }
`

export const LinkPreview = styled(Link)`
  border-radius: 12px;
  border: 1px solid ${gray};
  margin-top: 0;

  .content {
    background-color: ${white};
    display: flex;
    flex-direction: column;
    min-width: 0;
  }

  .title {
    display: block;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 4px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

export const ImagesGrid = styled(ImagesGridCommon)`
  border-radius: 12px;
  margin-top: 0;
`
