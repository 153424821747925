import React, { createContext, useContext, useState } from 'react'
import { MultiSelectMenu } from './MultiSelectMenu'
import { useDeleteIdea } from '~publish/pages/Create/hooks/useDeleteIdea'

type IdeasMultiSelectContextType = {
  selectedIdeas: Set<string>
  isMultiSelectMode: boolean
  selectIdea: (ideaId: string) => void
}

const IdeasMultiSelectContext = createContext<
  IdeasMultiSelectContextType | undefined
>(undefined)

export const IdeasMultiSelectProvider = ({
  children,
}: {
  children: React.ReactNode
}): JSX.Element => {
  const [selectedIdeas, setSelectedIdeas] = useState<Set<string>>(new Set())
  const [isMultiSelectMode, setIsMultiSelectMode] = useState(false)
  const { deleteIdeas } = useDeleteIdea()

  const selectIdea = (ideaId: string): void => {
    if (!isMultiSelectMode) {
      setIsMultiSelectMode(true)
      setSelectedIdeas(new Set([ideaId]))
      return
    }

    setSelectedIdeas((prev) => {
      const next = new Set(prev)
      if (next.has(ideaId)) {
        next.delete(ideaId)
      } else {
        next.add(ideaId)
      }
      return next
    })
  }

  const clearSelections = (): void => {
    setSelectedIdeas(new Set())
  }

  const exitMultiSelect = (): void => {
    setIsMultiSelectMode(false)
    clearSelections()
  }

  const deleteSelected = async (): Promise<void> => {
    const ideaIds = Array.from(selectedIdeas)
    await deleteIdeas(ideaIds)

    exitMultiSelect()
  }

  return (
    <IdeasMultiSelectContext.Provider
      value={{
        selectedIdeas,
        isMultiSelectMode,
        selectIdea,
      }}
    >
      {children}
      {isMultiSelectMode && (
        <MultiSelectMenu
          selectedCount={selectedIdeas.size}
          onCancel={exitMultiSelect}
          onDelete={deleteSelected}
        />
      )}
    </IdeasMultiSelectContext.Provider>
  )
}

export const useIdeasMultiSelect = (): IdeasMultiSelectContextType => {
  const context = useContext(IdeasMultiSelectContext)
  if (!context) {
    throw new Error(
      'useIdeasMultiSelect must be used within IdeasMultiSelectProvider',
    )
  }
  return context
}
