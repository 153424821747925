type Primitive = string | number | boolean | null | undefined
// eslint-disable-next-line no-use-before-define -- Recursive type
type JSONValue = Primitive | JSONObject | JSONArray
interface JSONObject {
  [key: string]: JSONValue
}
type JSONArray = Array<JSONValue>

function generateCacheKey(args: readonly unknown[]): string {
  return args
    .map((arg) =>
      typeof arg === 'object' && arg !== null
        ? JSON.stringify(
            Object.keys(arg)
              .sort()
              .reduce<JSONObject>((result, key) => {
                result[key] = arg[key as keyof typeof arg] as JSONValue
                return result
              }, {}),
          )
        : String(arg),
    )
    .join('|')
}

export function memoize<Args extends readonly unknown[], Result>(
  fn: (...args: Args) => Result,
): (...args: Args) => Result {
  const cache = new Map<string, Result>()

  const memoized = (...args: Args): Result => {
    // In tests, we don't want to use the cache
    if (process.env.NODE_ENV === 'test') {
      return fn(...args)
    }

    const key = generateCacheKey(args)

    const cachedResult = cache.get(key)
    if (cachedResult !== undefined) {
      return cachedResult
    }

    const result = fn(...args)
    cache.set(key, result)
    return result
  }

  return memoized
}
