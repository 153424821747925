import React from 'react'
import { type FragmentType, getFragmentData } from '~publish/gql'
import type { ChannelPage_ChannelFragment } from '~publish/gql/graphql'
import { ChannelPage_Channel } from './ChannelPage'

interface ChannelContextType {
  selectedChannel: FragmentType<typeof ChannelPage_Channel>
}

const ChannelContext = React.createContext<ChannelContextType | undefined>(
  undefined,
)

/**
 * ChannelProvider provides a context for managing the selected channel.
 */
const ChannelProvider: React.FC<{
  children: React.ReactNode
  channel: FragmentType<typeof ChannelPage_Channel>
}> = ({ children, channel }) => {
  return (
    <ChannelContext.Provider value={{ selectedChannel: channel }}>
      {children}
    </ChannelContext.Provider>
  )
}

/**
 * Hook to get the currently selected channel.
 * @returns the currently selected channel
 *
 * @example
 * const channel = useSelectedChannel()
 */
const useSelectedChannel = (): ChannelPage_ChannelFragment => {
  const context = React.useContext(ChannelContext)
  if (!context) {
    throw new Error('useSelectedChannel must be used within a ChannelProvider')
  }
  return getFragmentData(ChannelPage_Channel, context.selectedChannel)
}

export { ChannelProvider, useSelectedChannel }
