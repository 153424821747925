import styled from 'styled-components'
import { Notice } from '@bufferapp/ui'

export const Content = styled.div``

export const CampaignNotice = styled(Notice)`
  margin-top: 16px;
  width: calc(100% - 52px);
`

export const StyledMain = styled.main`
  padding: 0px 40px;
  max-width: 864px;
`

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 1rem 0;
`
