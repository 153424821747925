import { useSplitEnabled } from '@buffer-mono/features'
import { Button, Notice, Text } from '@bufferapp/ui'
import { white, yellowDarker } from '@bufferapp/ui/style/colors'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import styled from 'styled-components'
import {
  useDismissedBanners,
  useHasEntitlement,
} from '~publish/legacy/accountContext'
import { actions as composerPopoverActions } from '~publish/legacy/composer-popover'

import { selectHasFeature } from '~publish/legacy/organizations/selectors'
import type { RpcUpdate } from '~publish/legacy/post/types'
import {
  BannerTypes,
  useDismissBanner,
} from '~publish/legacy/profile-page/hooks/useDismissBanner'
import {
  isSelectedProfileDisconnected,
  selectIsManagerInChannel,
  selectProfileService,
  selectProfileServiceType,
  selectShouldShowLinkedinProfileAnalyticsUpgradeBanner,
} from '~publish/legacy/profile-sidebar/selectors'
import ProfilesDisconnectedBanner from '~publish/legacy/profiles-disconnected-banner'
import { LinkedinProfileRefreshBanner } from '~publish/legacy/queue/components/Banners/LinkedinProfileRefreshBanner'
import {
  selectLoading,
  selectMoreToLoad,
  selectPage,
  selectSentPostItems,
  selectTotal,
} from '~publish/legacy/sent/selectors'
import {
  BufferLoading,
  EmptyState,
  QueueItems,
} from '~publish/legacy/shared-components'
import { useAppDispatch, useAppSelector } from '~publish/legacy/store'
import getErrorBoundary from '~publish/legacy/web/components/ErrorBoundary'
import {
  SERVICE_LINKEDIN,
  SERVICE_TIKTOK,
  SERVICE_TWITTER,
  SERVICE_YOUTUBE,
} from '../../../constants'
import { actions } from '../../reducer'

const ErrorBoundary = getErrorBoundary(true)

const loadingContainerStyle: React.CSSProperties = {
  width: '100%',
  height: '100%',
  textAlign: 'center' as const,
  paddingTop: '5rem',
}

const loadMoreButtonStyle: React.CSSProperties = {
  textAlign: 'center',
  paddingBottom: '3rem',
}

export const ButtonStyled = styled(Button)`
  && {
    color: ${white};
    background-color: ${yellowDarker};
    border: none;

    &:hover {
      color: ${white};
      background-color: #46360b;
      border: none;
    }
  }
`

const NoPostsPublished = ({
  total,
  has30DaySentPostsLimitFeature,
  profileService,
}: {
  total: number
  has30DaySentPostsLimitFeature: boolean
  profileService: string
}): JSX.Element | null => {
  if (typeof total === 'undefined' || total > 0) {
    return null
  }

  let title = 'You haven’t published any posts with this account!'
  let subtitle =
    'Once a post has gone live via Buffer, you can track its performance here to learn what works best with your audience!'

  if (has30DaySentPostsLimitFeature) {
    title =
      'You haven’t published any posts with this account in the past 30 days!'
  }

  if (profileService === SERVICE_TIKTOK) {
    title = 'Publish a new TikTok post to see live analytics here!'
    subtitle =
      'We can only pull in and analyze recent posts when a new channel is connected. Once a post has gone live via Buffer, you can track its performance here!'
  }

  return (
    <EmptyState
      height="initial"
      title={title}
      subtitle={subtitle}
      heroImg="https://s3.amazonaws.com/buffer-publish/images/empty-sent2x.png"
      heroImgSize={{ width: '270px', height: '150px' }}
    />
  )
}

NoPostsPublished.propTypes = {
  total: PropTypes.number,
  has30DaySentPostsLimitFeature: PropTypes.bool,
  profileService: PropTypes.string.isRequired,
}

NoPostsPublished.defaultProps = {
  total: undefined,
  has30DaySentPostsLimitFeature: true,
}

interface SentPostsProps {
  profileId: string
  loadMore: (args: {
    profileId: string
    page: number
    tabId: string
    includeReminders: boolean
    includeDirect: boolean
  }) => void
}

export const SentPosts = ({
  profileId,
  loadMore,
}: SentPostsProps): JSX.Element => {
  const dispatch = useAppDispatch()

  const loading = useAppSelector(selectLoading(profileId))
  const moreToLoad = useAppSelector(selectMoreToLoad(profileId))
  const page = useAppSelector(selectPage(profileId))
  const total = useAppSelector(selectTotal(profileId))

  const isManager = useAppSelector(selectIsManagerInChannel(profileId))
  const isDisconnectedProfile = useAppSelector(isSelectedProfileDisconnected)
  const profileService = useAppSelector(selectProfileService(profileId))
  const profileServiceType = useAppSelector(selectProfileServiceType(profileId))
  const shouldShowLinkedinProfileAnalyticsUpgradeBanner = useAppSelector(
    selectShouldShowLinkedinProfileAnalyticsUpgradeBanner(profileId),
  )

  const items = useAppSelector((state) => selectSentPostItems(state, profileId))

  const hasFirstCommentFeature = useAppSelector((state) =>
    selectHasFeature(state, 'hasFirstCommentFeature'),
  )
  const hasCampaignsFeature = useAppSelector((state) =>
    selectHasFeature(state, 'hasCampaignsFeature'),
  )
  const has30DaySentPostsLimitFeature = useAppSelector((state) =>
    selectHasFeature(state, 'has30DaySentPostsLimitFeature'),
  )
  const hasAnalyticsOnPosts = useAppSelector((state) =>
    selectHasFeature(state, 'hasAnalyticsOnPosts'),
  )

  const { isEnabled: isRemindersEnabled } = useSplitEnabled('CORE-reminders')
  // TODO: change this value based on filter UI
  const showOnlyReminders = false
  const { onDismissBanner: onYoutubeNoticeDismiss } = useDismissBanner({
    banner: BannerTypes.youtubeSentPostAnalyticsNotice,
  })
  const dismissedBanners = useDismissedBanners()
  const isEntitledToAnalytics = useHasEntitlement('analytics')

  const isYoutubeAnalyticsNoticeDismissed =
    dismissedBanners &&
    dismissedBanners.includes(BannerTypes.youtubeSentPostAnalyticsNotice)

  const onShareAgainClick = ({ post }: { post: RpcUpdate }): void => {
    dispatch(composerPopoverActions.handleShareAgainClick(post.id))
  }

  const onShareLinkClick = ({
    post,
    selectedProfileIds,
  }: {
    post: RpcUpdate
    selectedProfileIds: string[]
  }): void => {
    dispatch(
      composerPopoverActions.handleShareLinkClick(post, selectedProfileIds),
    )
  }

  useEffect(() => {
    dispatch(
      actions.fetchSentPosts({
        profileId,
        includeReminders: isRemindersEnabled,
        includeDirect: !showOnlyReminders,
      }),
    )
  }, [dispatch, profileId, isRemindersEnabled])

  if (loading) {
    return (
      <div style={loadingContainerStyle}>
        <BufferLoading size={64} />
      </div>
    )
  }

  const loadMorePosts = (): void => {
    loadMore({
      profileId,
      page,
      tabId: 'sentPosts',
      includeReminders: isRemindersEnabled,
      includeDirect: !showOnlyReminders,
    })
  }

  const shouldShowYoutubeNotice =
    profileService === SERVICE_YOUTUBE && !isYoutubeAnalyticsNoticeDismissed
  const shouldShowTwitterNotice =
    profileService === SERVICE_TWITTER && !isEntitledToAnalytics
  const shouldShowLinkedinProfileNotice =
    profileService === SERVICE_LINKEDIN &&
    profileServiceType === 'profile' &&
    shouldShowLinkedinProfileAnalyticsUpgradeBanner

  return (
    <ErrorBoundary>
      {isDisconnectedProfile && <ProfilesDisconnectedBanner />}
      {shouldShowLinkedinProfileNotice && <LinkedinProfileRefreshBanner />}
      <NoPostsPublished
        has30DaySentPostsLimitFeature={has30DaySentPostsLimitFeature}
        total={total}
        profileService={profileService}
      />

      {total > 0 ? (
        <>
          <div>
            {shouldShowYoutubeNotice && (
              // @ts-expect-error TS(2741) FIXME: Property 'className' is missing in type '{ childre... Remove this comment to see the full error message
              <Notice
                dismiss={onYoutubeNoticeDismiss}
                type="note"
                disableAnimation
              >
                {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: st... Remove this comment to see the full error message */}
                <Text>
                  We update your data once a day, so you might see different
                  real-time data on YouTube.{' '}
                </Text>
              </Notice>
            )}
            {shouldShowTwitterNotice && (
              // @ts-expect-error TS(2741) FIXME: Property 'className' is missing in type '{ childre... Remove this comment to see the full error message
              <Notice type="warning" disableAnimation>
                {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: st... Remove this comment to see the full error message */}
                <Text>
                  {
                    'As of October 2023, we no longer support analytics for X/Twitter on our free plan. You will still be able to see metrics for posts that were created before this date but they will not be updated.'
                  }
                </Text>
              </Notice>
            )}
            <QueueItems
              items={items}
              onShareAgainClick={onShareAgainClick}
              onShareLinkClick={onShareLinkClick}
              isManager={isManager}
              hasFirstCommentFlip={hasFirstCommentFeature}
              hasCampaignsFeature={hasCampaignsFeature}
              profileServiceType={profileServiceType}
              profileService={profileService}
              hasAnalyticsOnPosts={hasAnalyticsOnPosts}
              shouldRenderCalendarButtons
              shouldDisplayTimezone={true}
            />
          </div>
          {moreToLoad && (
            <div style={loadMoreButtonStyle}>
              {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: () =... Remove this comment to see the full error message */}
              <Button
                type="primary"
                label="Load More"
                onClick={loadMorePosts}
              />
            </div>
          )}
        </>
      ) : null}
    </ErrorBoundary>
  )
}

SentPosts.propTypes = {
  profileId: PropTypes.string,
  loadMore: PropTypes.func,
}

SentPosts.defaultProps = {
  loadMore: (): void => undefined,
}
