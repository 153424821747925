type DayMap = {
  [key: string]: string
}

export const DAYSMAP: DayMap = {
  monday: 'mon',
  tuesday: 'tue',
  wednesday: 'wed',
  thursday: 'thu',
  friday: 'fri',
  saturday: 'sat',
  sunday: 'sun',
}
