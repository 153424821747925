import React from 'react'
import type { AutocompleteItem } from '../../types'
import {
  AutocompleteSuggestionsEntryAvatar,
  AutocompleteSuggestionsEntryName,
  AutocompleteSuggestionsEntryText,
} from './styles'

export const AutocompleteSuggestion = ({
  mention,
}: {
  mention: AutocompleteItem
}): JSX.Element => {
  const { avatar, name, handle } = mention.data

  return (
    <>
      {avatar && <AutocompleteSuggestionsEntryAvatar src={avatar} alt="" />}

      <AutocompleteSuggestionsEntryText>
        {name && (
          <AutocompleteSuggestionsEntryName>
            {`${name} `}
          </AutocompleteSuggestionsEntryName>
        )}
        {handle}
      </AutocompleteSuggestionsEntryText>
    </>
  )
}
