import type { PostCard_PostFragment } from '~publish/gql/graphql'

const [, ...domainParts] = window.location.hostname.split('.')
const domain = domainParts.join('.')

export const getEngageUrl = (
  channel: PostCard_PostFragment['channel'],
): string | null => {
  if (!channel.products?.includes('engage')) return null

  return `https://engage.${domain}`
}

export const getAnalyzeUrl = (
  channel: PostCard_PostFragment['channel'],
): string | null => {
  if (!channel.products?.includes('analyze')) return null

  return `https://analyze.${domain}/${channel.service}/overview/${channel.id}`
}
