import { createAsyncThunk } from '@reduxjs/toolkit'
import type { Schedule } from '~publish/legacy/profile-sidebar/types'
import type { RootState } from '~publish/legacy/store'
import callRpc from '~publish/legacy/utils/call-rpc'

export const updatePausedSchedules = createAsyncThunk<
  {
    message: string
    success: boolean
    schedules: Schedule[]
    pausedSchedules: Schedule[]
  },
  {
    profileId: string
    schedules: Schedule[]
    pausedSchedules: Schedule[]
    emptyPausedSchedules?: boolean
  },
  { state: RootState }
>(
  'postingSchedule/updatePausedSchedules',
  async ({ profileId, schedules, pausedSchedules, emptyPausedSchedules }) => {
    const response = await callRpc('updatePausedSchedules', {
      profileId,
      pausedSchedules,
      schedules,
      emptyPausedSchedules,
    })

    if (!response.success) {
      throw new Error(response.message)
    }

    return response
  },
)
