import React from 'react'
import * as RadixSelect from '@radix-ui/react-select'
import { Tooltip } from '@buffer-mono/popcorn'

import PhoneIcon from '@bufferapp/ui/Icon/Icons/Phone'
import GearIcon from '@bufferapp/ui/Icon/Icons/Gear'
import ChevronDownIcon from '@bufferapp/ui/Icon/Icons/ChevronDown'

import type { Draft } from '~publish/legacy/composer/composer/entities/Draft'

import * as Styled from './styles'

import { orange, blue } from '@bufferapp/ui/style/colors'

export function SelectTrigger({
  id,
  draft,
  disabled,
  isAutomaticPostingPossible,
}: {
  id: string
  draft: Draft
  disabled: boolean | undefined
  isAutomaticPostingPossible: boolean
}) {
  return (
    <Tooltip
      content={
        draft.isReminder || !isAutomaticPostingPossible
          ? "You'll receive a mobile notification to post yourself"
          : 'Buffer will post automatically for you'
      }
      align="end"
    >
      <Styled.SelectTrigger
        className="SelectTrigger"
        aria-label="reminders"
        disabled={disabled}
        id={id}
      >
        {draft.isReminder || !isAutomaticPostingPossible ? (
          <PhoneIcon color={orange} className="icon" />
        ) : (
          <GearIcon color={blue} className="icon" />
        )}
        <RadixSelect.Value
          placeholder={draft.isReminder ? 'Notify Me' : 'Automatic'}
        />
        <ChevronDownIcon className="chevron" />
      </Styled.SelectTrigger>
    </Tooltip>
  )
}
