/**
 * Type guard to check if a value is not null
 */
export function isNotNull<T>(x: T | null): x is T {
  return x !== null
}

/**
 * Type guard to filter array for nullable values
 */
export function filterNotNull<T>(arr: Array<T | null>): Array<T> {
  return arr.filter(isNotNull)
}

/**
 * Sanitize nullable array, removing null and undefined values
 * and returning an empty array if the input is null or undefined
 */
export function sanitizeNullableArray<T>(
  arr: Array<T | null> | null | undefined,
): Array<T> {
  return arr ? filterNotNull(arr) : []
}

/**
 * Use it to type guard specific typename in GraphQl responses
 * @example
 * ```
 * if (isOfType(data, 'TwitterMetadata')) {
 *   const threadCount = data.threads.length
 * }
 * ```
 */
export function isOfType<
  TData extends { __typename?: string } | null | undefined,
  TTypename extends string,
>(
  data: TData,
  typename: TTypename,
): data is Extract<NonNullable<TData>, { __typename: TTypename }> {
  return data !== null && data !== undefined && data.__typename === typename
}

/**
 * Use it to exclude specific typename in GraphQl responses
 * @example
 * ```
 * if (isNotOfType(data, 'SuccessResponse')) {
 *   const errorMessage = data.message
 * }
 * ```
 */
export function isNotOfType<
  TData extends { __typename?: string } | null | undefined,
  TTypename extends string,
>(
  date: TData,
  typename: TTypename,
): date is Exclude<NonNullable<TData>, { __typename: TTypename }> {
  return date !== null && date !== undefined && date.__typename !== typename
}
