import React, { useCallback } from 'react'
import { ComposerEditor } from '~publish/legacy/composer/composer/editor/ComposerEditor'
import ComposerActionCreators from '../action-creators/ComposerActionCreators'
import AppStore from '../stores/AppStore'
import type { BufferEditor } from '~publish/legacy/editor/BufferEditor/types.plate'
import type Draft from '../entities/Draft/Draft'

const EditorWrapperComponent = ({
  draft,
  shouldAutoFocus,
  attachmentGlanceHasNoThumbnail,
  setEmojiContainer,
}: {
  draft: Draft
  shouldAutoFocus: boolean
  attachmentGlanceHasNoThumbnail: boolean
  setEmojiContainer: (emojiContainer: React.ReactNode) => void
}): JSX.Element => {
  const onEditorStateChange = useCallback(
    (editorState: BufferEditor) => {
      ComposerActionCreators.updateDraftEditorState(draft.id, editorState)
      ComposerActionCreators.parseDraftTextLinks(draft.id)
      // TODO: we should delete this one when we implement autocomplete for Mastodon
      if (draft.service.isMastodon()) {
        ComposerActionCreators.parseMastodonText()
      }
    },
    [draft.id, draft.service],
  )

  const getComposerData = useCallback((editor: BufferEditor) => {
    const selectedProfilesForService = AppStore.getSelectedProfilesForService(
      editor.id,
    )
    return {
      selectedProfilesForService,
    }
  }, [])
  return (
    <ComposerEditor
      draft={draft}
      editor={draft.editorState}
      shouldAutoFocus={shouldAutoFocus}
      isComposerExpanded={AppStore.getExpandedComposerId() === draft.id}
      attachmentGlanceHasNoThumbnail={attachmentGlanceHasNoThumbnail}
      getEditorComposerData={getComposerData}
      onEditorStateChange={onEditorStateChange}
      setEmojiContainer={setEmojiContainer}
    />
  )
}

export const EditorWrapper = EditorWrapperComponent
