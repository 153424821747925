import { isThursday, isFriday, isSaturday, isSunday } from 'date-fns'

type CommonOptions = {
  weekStartsOn?: number
}

type AnyDateType = Date | number | null | undefined

export const isLastTwoDaysOfWeek = (
  date: AnyDateType,
  { weekStartsOn = 0 }: CommonOptions = {},
): boolean => {
  if (date === null || date === undefined) return false
  const startsOnMonday = weekStartsOn !== 0

  if (startsOnMonday) {
    return isSaturday(date) || isSunday(date)
  }

  return isFriday(date) || isSaturday(date)
}

export const isLastThirdDayOfWeek = (
  date: AnyDateType,
  { weekStartsOn = 0 }: CommonOptions = {},
): boolean => {
  if (date === null || date === undefined) return false
  const startsOnMonday = weekStartsOn !== 0

  return startsOnMonday ? isFriday(date) : isThursday(date)
}
