import keyWrapper from '~publish/helpers/keywrapper'

export const actionTypes = keyWrapper('PROFILES', {
  FETCH_SINGLE_PROFILE: 0,
})

export const actions = {
  // @ts-expect-error TS(7031) FIXME: Binding element 'profileId' implicitly has an 'any... Remove this comment to see the full error message
  fetchSingleProfile: ({ profileId, message }) => ({
    type: actionTypes.FETCH_SINGLE_PROFILE,
    profileId,
    message,
  }),
}
