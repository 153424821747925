import React from 'react'
import clsx from 'clsx'
import { Text, Tooltip } from '@buffer-mono/popcorn'

import {
  useTimeFormatter,
  useDateTimeFormatter,
  useDateFormatter,
} from '~publish/hooks/useDateTimeFormatter'

import { useTimezone } from '~publish/hooks/useTimezone'

import styles from './PostingTime.module.css'

type PostTimeProps = {
  date?: string | null
  muted?: boolean
  includeDate?: boolean
}

export const PostingTime = ({
  date,
  muted = false,
  includeDate = false,
}: PostTimeProps): JSX.Element => {
  const [timeZone] = useTimezone()
  const dateTimeFormatter = useDateTimeFormatter()
  const timeFormatter = useTimeFormatter()
  const dateFormatter = useDateFormatter()

  if (!date) {
    return (
      <Text color="subtle" weight="medium">
        No time
      </Text>
    )
  }

  return (
    <Tooltip
      content={
        <p className={styles.tooltipContent}>
          {timeZone && <span>Channel Local Time:</span>}
          {dateTimeFormatter(
            date,
            timeZone
              ? {
                  timeZone,
                }
              : {},
          )}
        </p>
      }
    >
      <Text
        weight="medium"
        className={clsx(styles.label, {
          [styles.muted]: muted,
        })}
        as="time"
        dateTime={date}
      >
        {includeDate && (
          <span className={styles.date}>
            {dateFormatter(date, {
              includeTimeZone: false,
              dateFormat: 'MMM d',
            })}
          </span>
        )}
        <span className={styles.time}>{timeFormatter(date)}</span>
      </Text>
    </Tooltip>
  )
}
