import { type RpcUpdate, UpdateStatus } from '~publish/legacy/post/types'

export const mapStatusToTab = (
  post: RpcUpdate,
  isRemindersEnabled?: boolean,
): string => {
  const statusToTabIdMap: Partial<Record<UpdateStatus | '', string>> = {
    [UpdateStatus.Buffer]: 'queue',
    [UpdateStatus.Pending]: 'queue',
    [UpdateStatus.Library]: 'queue',
    [UpdateStatus.Processing]: 'queue',
    [UpdateStatus.PendingTiktok]: 'sentPosts',
    [UpdateStatus.Sent]: 'sentPosts',
    [UpdateStatus.Service]: 'sentPosts',
    [UpdateStatus.Analytics]: 'sentPosts',
    [UpdateStatus.ServiceReply]: 'sentPosts',
    [UpdateStatus.ProcessingAnalytics]: 'sentPosts',
    [UpdateStatus.Notified]: isRemindersEnabled ? 'sentPosts' : 'pastReminders',
  }

  // Separate conditions for Draft status
  if (post.status === UpdateStatus.Draft) {
    return post.needsApproval ? 'approvals' : 'drafts'
  }

  // Returns mapped value or default to 'queue' if status not present in the map
  return statusToTabIdMap[post.status] || 'queue'
}
