import React from 'react'
import PropTypes from 'prop-types'

// @ts-expect-error TS(7031) FIXME: Binding element 'children' implicitly has an 'any'... Remove this comment to see the full error message
const SensitiveData = ({ children }) =>
  children ? <span className="sensitiveData">{children}</span> : null

SensitiveData.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default SensitiveData
