import { createAsyncThunk } from '@reduxjs/toolkit'
import { client, gql } from '~publish/legacy/apollo-client'
import type {
  EditEntityNoteInput,
  EditEntityNotePayload,
} from '~publish/gql/graphql'

const EDIT_ENTITY_NOTE = gql`
  mutation editEntityNote($input: EditEntityNoteInput!) {
    editEntityNote(input: $input) {
      ... on EditEntityNoteResponse {
        entityId
        entityType
        noteId
      }
    }
  }
`

type EditEntityNoteResult =
  | {
      editEntityNote: EditEntityNotePayload
    }
  | null
  | undefined

export const editEntityNote = createAsyncThunk<
  EditEntityNoteResult,
  EditEntityNoteInput
>(
  'posts/editEntityNote',
  async ({
    entityId,
    entityType,
    text,
    noteId,
  }): Promise<EditEntityNoteResult> => {
    const response = await client.mutate<
      EditEntityNoteResult,
      { input: EditEntityNoteInput }
    >({
      mutation: EDIT_ENTITY_NOTE,
      variables: { input: { entityId, text, entityType, noteId } },
    })

    return response.data
  },
)
