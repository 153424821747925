import { useEffect } from 'react'

export default function useDraggingCursor(enabled: boolean): void {
  useEffect(() => {
    if (!enabled) return
    const style = self.document.createElement('style')
    style.innerHTML = `* { cursor: grabbing !important; }`
    self.document.body.appendChild(style)

    return (): void => {
      style.remove()
    }
  }, [enabled])
}
