import { EventEmitter } from 'events'
import AppDispatcher from '../../dispatcher'
import { ActionTypes } from '../../state/ActionTypes'

const CHANGE_EVENT = 'change'

const getInitialState = () => ({
  open: true,
  modalName: '',
  modalProps: {},
  appNotificationMessage: '',
})

const state = getInitialState()

const Store = Object.assign({}, EventEmitter.prototype, {
  emitChange: () => Store.emit(CHANGE_EVENT),
  // @ts-expect-error TS(7006) FIXME: Parameter 'callback' implicitly has an 'any' type.
  addChangeListener: (callback) => Store.on(CHANGE_EVENT, callback),
  // @ts-expect-error TS(7006) FIXME: Parameter 'callback' implicitly has an 'any' type.
  removeChangeListener: (callback) =>
    Store.removeListener(CHANGE_EVENT, callback),
  getCurrentState: () => state,
  getInitialState,
})

const closeModal = () => {
  state.open = false
}

const openModal = () => {
  state.open = true
}

// @ts-expect-error TS(7006) FIXME: Parameter 'modalName' implicitly has an 'any' type... Remove this comment to see the full error message
const setModal = (modalName) => {
  state.modalName = modalName
}

// @ts-expect-error TS(7006) FIXME: Parameter 'modalProps' implicitly has an 'any' typ... Remove this comment to see the full error message
const setModalProps = (modalProps) => {
  state.modalProps = modalProps
}

// @ts-expect-error TS(7006) FIXME: Parameter 'message' implicitly has an 'any' type.
const setAppNotificationMessage = (message) => {
  state.appNotificationMessage = message
}

AppDispatcher.register((payload) => {
  switch (payload.action.actionType) {
    case ActionTypes.CLOSE_MODAL:
      closeModal()
      Store.emitChange()
      break
    case ActionTypes.OPEN_MODAL:
      setModal(payload.action.name)
      setModalProps(payload.action.props)
      openModal()
      Store.emitChange()
      break
    case ActionTypes.SET_APP_NOTIFICATION_MESSAGE:
      setAppNotificationMessage(payload.action.message)
      Store.emitChange()
      break
    default:
      break
  }
})

export default Store
