import React, { useEffect, useState } from 'react'
import type { Draft } from '~publish/legacy/composer/composer/entities/Draft'
import { HeaderGroup } from '~publish/legacy/reminders/components/new-reminders/components/forms/header-group/HeaderGroup'
import { Heading } from '~publish/legacy/reminders/components/new-reminders/components/forms/heading/Heading'
import {
  RemindersComposerBox,
  RemindersWrapper,
  StickersWrapper,
} from '~publish/legacy/reminders/components/new-reminders/components/forms/header-group/styles'
import { StickerSelect } from '~publish/legacy/reminders/components/new-reminders/components/forms/sticker-select/StickerSelect'
import { FieldConfiguration } from '~publish/legacy/reminders/components/new-reminders/field-configuration/PublishFieldsConfiguration'
import { ChannelField } from '~publish/legacy/reminders/components/new-reminders/components/channel-fields/ChannelField'
import { InstagramNotificationsComposerGuide } from '~publish/legacy/guides/instagram-notifications-composer-guide/InstagramNotificationsComposerGuide'
import { Select } from '~publish/legacy/reminders/components/new-reminders/components/select'
import useStickers from '~publish/legacy/reminders/components/new-reminders/hooks/useStickers'
import styled, {
  css,
  type FlattenSimpleInterpolation,
  keyframes,
} from 'styled-components'
import AppStore from '~publish/legacy/composer/composer/stores/AppStore'
import { Service } from '~publish/legacy/constants/services/ServiceDefinitions'

const slideDownAndFadeOut = keyframes`
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(12px);
  }
`

const ChannelFieldWrapper = styled.div`
  ${(props: { fadeOut: boolean }): false | FlattenSimpleInterpolation =>
    props.fadeOut &&
    css`
      animation: ${slideDownAndFadeOut} 0.3s ease-out forwards;
    `}
`

export function RemindersWithStickers({
  draft,
}: {
  draft: Draft
}): React.JSX.Element | null {
  const { stickersToDisplay, fieldsToDisplay, handleStickerChange } =
    useStickers(draft)
  const [animatedFields, setAnimatedFields] = useState(fieldsToDisplay)
  const isAnyInstagramPersonalProfileSelected =
    AppStore.getSelectedProfiles().some(
      (profile) =>
        profile.serviceName === Service.Instagram &&
        profile.serviceType === 'profile',
    )
  const doesNotSupportAutomaticScheduling =
    (draft.isStoryPost() && draft?.images?.length > 1) ||
    isAnyInstagramPersonalProfileSelected

  // Effect to detect removal and animate
  useEffect(() => {
    const removedFields = animatedFields.filter(
      (field) => !fieldsToDisplay.includes(field),
    )
    const newFields = fieldsToDisplay.filter(
      (field) => !animatedFields.includes(field),
    )

    if (removedFields.length === 1) {
      // Apply fade-out animation with a delay
      const timeoutId = setTimeout(() => {
        setAnimatedFields((currentFields) =>
          currentFields.filter((f) => !removedFields.includes(f)),
        )
      }, 300) // Match animation duration

      // Cleanup timeout on component unmount or update
      return () => {
        clearTimeout(timeoutId)
      }
    } else if (removedFields.length > 1) {
      // Directly update animatedFields without delay or animation if more than one field is removed (e.g. post type was changed)
      setAnimatedFields((currentFields) =>
        currentFields.filter((f) => !removedFields.includes(f)),
      )
    } else if (newFields.length > 0) {
      // Immediately add new fields without animation, as it's handled in css
      setAnimatedFields(fieldsToDisplay)
    }
    // it is expected to depend ONLY on fieldsToDisplay, adding animatedFields would cause infinite loop
    // eslint-disable-next-line
  }, [fieldsToDisplay])

  return (
    <RemindersComposerBox>
      {draft.service.isInstagram() && (
        <InstagramNotificationsComposerGuide isReminder={draft.isReminder} />
      )}
      <HeaderGroup>
        {stickersToDisplay.length > 0 && <Heading text="Add Stickers" />}
        <RemindersWrapper>
          <StickersWrapper id="stickers-group">
            {stickersToDisplay.map((field) => (
              <StickerSelect
                key={`button-${draft.id}-${field}`}
                name={`${field}Sticker`}
                text={FieldConfiguration[field].label}
                onChange={(e): void => {
                  handleStickerChange(field, e.target.checked)
                }}
                checked={
                  draft.selectedStickers.includes(field) && !!draft.isReminder
                }
                icon={field}
              />
            ))}
          </StickersWrapper>
          <Select
            id="posting-method-trigger"
            draft={draft}
            isAutomaticPostingPossible={!doesNotSupportAutomaticScheduling}
            disableOptionDescription={
              isAnyInstagramPersonalProfileSelected
                ? 'Instagram personal profiles do not support automatic scheduling.'
                : 'Stories with more than one media can only be shared via mobile notification.'
            }
          />
        </RemindersWrapper>
      </HeaderGroup>
      {animatedFields.map((field) => (
        <ChannelFieldWrapper
          key={`field-${field}`}
          fadeOut={!fieldsToDisplay.includes(field)}
        >
          <ChannelField field={field} draft={draft} />
        </ChannelFieldWrapper>
      ))}
    </RemindersComposerBox>
  )
}
