// @ts-expect-error TS(7006) FIXME: Parameter 'mentions' implicitly has an 'any' type.
export const concatMentionsToEntities = (mentions, entities) => {
  if (mentions) {
    // @ts-expect-error TS(7006) FIXME: Parameter 'a' implicitly has an 'any' type.
    return entities.concat(mentions).sort((a, b) => {
      if (a !== null && b !== null) return a.indices[0] - b.indices[0]
      return 0
    })
  }

  return entities
}

export interface MentionEntity {
  displayString: string
  indices: [number, number]
  rawString?: string
  className?: string
  url?: string
}

export * from './facebook/helpers'
export * from './googlebusiness/helpers'
export * from './instagram/helpers'
export * from './linkedin/helpers'
export * from './mastodon/helpers'
export * from './tiktok/helpers'
export * from './twitter/helpers'
export * from './youtube/helpers'
