import {
  type AnyObject,
  createComboboxPlugin,
  createPlateEditor,
  type CreatePlateEditorOptions,
  createPlugins,
} from '@udecode/plate'
import type {
  BufferEditor,
  BufferValue,
} from '../../../legacy/editor/BufferEditor/types.plate'
import { createEmojiSearchPlugin } from '../../../legacy/editor/plugins/emoji'
import { createLinkPlugin } from '../../../legacy/editor/plugins/link'
import { SimpleLinkElementUI } from '../../../legacy/editor/plugins/link/ui/SimpleLinkElementUI'
import { createGenerateContentPlugin } from '../../../legacy/editor/plugins/generate-content'
import { createContentGenerationPlugin } from '~publish/legacy/ai/plugins/content-generation'

export const IDEA_EDITOR_ID = 'idea-editor'

export const createIdeaEditor = (
  options: CreatePlateEditorOptions<BufferValue, BufferEditor> = {},
): BufferEditor => {
  return createPlateEditor<BufferValue, BufferEditor>({
    id: IDEA_EDITOR_ID,
    plugins: createPlugins<BufferValue, BufferEditor>([
      createComboboxPlugin<AnyObject, BufferValue>(),
      createEmojiSearchPlugin(),
      createLinkPlugin({
        component: SimpleLinkElementUI,
      }),
      createGenerateContentPlugin(),
      createContentGenerationPlugin(),
      // createTextGenerationHotKeyPlugin(),
    ]),
    ...options,
  })
}
