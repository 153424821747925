import {
  selectCurrentProfileId,
  selectNotificationsEnabled,
} from '~publish/legacy/profile-sidebar/selectors'
import { useAppSelector } from '~publish/legacy/store'

export function useNotificationsEnabled(): boolean {
  const profileId = useAppSelector(selectCurrentProfileId)
  const notificationsEnabled = useAppSelector((state) =>
    selectNotificationsEnabled(state, [profileId]),
  )

  return !!notificationsEnabled
}
