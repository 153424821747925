const REFINE_BUTTON_WIDTH = 130
const GAP = 10

/**
 * Move the Grammarly button to the top of the seleted text. In this case,
 * we can keep the Refine button on the button what is safer for us because
 * avoiding unnecessary listeners and weird behaviors in some cases.
 */

const moveGrammarlyButton = (): void => {
  // Get the Shadow DOM host element
  const shadowHosts = document.querySelectorAll('grammarly-popups')

  // Access the Shadow DOM
  if (shadowHosts && shadowHosts.length > 0) {
    shadowHosts.forEach((shadowHost) => {
      const shadowRoot = shadowHost.shadowRoot

      // This is the Grammarly button
      const grammarlyBtn = shadowRoot?.querySelector(
        '[data-grammarly-part="cheetah-entry-button"]',
      ) as HTMLElement

      if (grammarlyBtn) {
        // Change the position of the button moving it to the top and left of the selected text
        /* It would be better if we could use the width of the Refine button, but
        it is not possible because it is not rendered yet sometimes, for example when
        user uses the keys to select the text. That selection happens really fast */
        grammarlyBtn.style.left = REFINE_BUTTON_WIDTH + GAP + 'px'
      }
    })
  }
}

export default moveGrammarlyButton
