import API from '~publish/legacy/composer/composer/utils/API'
import type { Blueprint } from '~publish/legacy/blueprints/types'
import dayjs from 'dayjs'

const makeAPICall = (args: {
  channelId: string
  channelName: string
  content: string
  timestamp: number
}) => {
  const { channelId, channelName, content, timestamp } = args
  // @ts-expect-error TS(2339) FIXME: Property 'post' does not exist on type 'typeof API... Remove this comment to see the full error message
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  const responsePromise = API.post('updates/create.json', {
    now: false,
    top: false,
    is_draft: true,
    shorten: false,
    // When editing updates, the API expects only the text field to be used (not fb_text)
    text: content,
    fb_text: channelName === 'facebook' ? content : '',
    entities: null,
    annotations: [],
    profile_ids: [channelId],
    attachment: false,
    via: null,
    source: null,
    version: null,
    created_source: 'blueprints',
    channel_data: null,
    scheduled_at: timestamp,
    is_blueprint: true,
  })

  return Promise.resolve(responsePromise)
}

export const createDrafts = (args: {
  channelId: string
  channelName: string
  blueprint: Blueprint
  draftCreationCallback: (response: any) => void
}) => {
  const { channelId, channelName, blueprint, draftCreationCallback } = args

  blueprint.updates.map((update) => {
    const timestamp = dayjs()
      .startOf('day')
      .add(update.day, 'day')
      .add(parseInt(update.time.split(':')[0], 10), 'hour')
      .add(parseInt(update.time.split(':')[1], 10), 'minute')
      .unix()

    const draft = makeAPICall({
      channelId,
      channelName,
      content: update.content,
      timestamp,
    }).then((response) => {
      draftCreationCallback(response)
    })

    return draft
  })
}
