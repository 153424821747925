import type { Campaign, Tag } from '~publish/legacy/campaign/types'

const performSorting = (campaigns: Campaign[] | Tag[]): Campaign[] | Tag[] => {
  return campaigns.sort((first, second) => {
    if (first.name.toLowerCase() < second.name.toLowerCase()) {
      return -1
    }
    if (first.name.toLowerCase() > second.name.toLowerCase()) {
      return 1
    }
    return 0
  })
}

export const sortCampaignsAlphabetically = (
  campaigns: Campaign[] | Tag[],
): Campaign[] | Tag[] => {
  const unlockedCampaigns = campaigns.filter((campaign) => !campaign.locked)
  const lockedCampaigns = campaigns.filter((campaign) => campaign.locked)
  const sortedUnlockedCampaigns = performSorting(unlockedCampaigns)
  const sortedLockedCampaigns = performSorting(lockedCampaigns)
  return [...sortedUnlockedCampaigns, ...sortedLockedCampaigns]
}
