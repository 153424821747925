import React from 'react'

import WarningIcon from '@bufferapp/ui/Icon/Icons/Warning'

import * as Styled from './PromptTextarea.styles'

/**
 *
 */
function PromptTextarea({
  id,
  name,
  value,
  errorMessage,
  tip,
  placeholder,
  testId,
  onChange,
}: {
  id?: string
  name?: string
  value?: string
  errorMessage?: React.ReactNode
  tip?: React.ReactNode
  placeholder?: string
  testId?: string
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
}): JSX.Element {
  const accessibleDescriptionId = `${id}-description`
  const isInvalid = !!errorMessage

  return (
    <>
      <Styled.Textarea
        id={id}
        name={name}
        value={value}
        placeholder={placeholder}
        error={isInvalid}
        onChange={onChange}
        data-testid={testId}
        aria-invalid={isInvalid}
        aria-describedby={accessibleDescriptionId}
      />

      {errorMessage && (
        <Styled.Error id={accessibleDescriptionId}>
          <WarningIcon size="smedium" />
          {errorMessage}
        </Styled.Error>
      )}

      {tip && !isInvalid && (
        <Styled.Tip>
          <strong>Pro tip:</strong> {tip}
        </Styled.Tip>
      )}
    </>
  )
}

export { PromptTextarea }
