import callRpc from '~publish/legacy/utils/call-rpc'
import { type BufferUpload, Upload } from '../entities/Upload'
import { type VideoDetails, VideoMetadata } from '../values/VideoMetadata'
import { ImageDimensions } from '../values/ImageDimensions'
import { MediaType, UploadStatus } from '../constants'

type MediaResponse = {
  success: boolean
  upload_id: string
  location: string
  type: 'photo' | 'video' | 'document'
  message?: string
}

type ImageResponse = MediaResponse & {
  type: 'photo'
  width: number | null
  height: number | null
}

type VideoResponse = MediaResponse & {
  type: 'video'
  transcodeVideo: boolean
  details: VideoDetails
}

type DocumentResponse = MediaResponse & {
  type: 'document'
  details: {
    // TODO: make title non-optional
    title?: string
    pageCount: number | null
  }
  thumbnail: string
}

export const BufferUploadsApi = {
  async create(
    upload: Upload,
    { forceTranscode = false }: { forceTranscode?: boolean } = {},
  ): Promise<BufferUpload> {
    const response: ImageResponse | VideoResponse | DocumentResponse =
      await callRpc('composerApiProxy', {
        url: `/i/uploads/upload_media.json`,
        args: {
          key: upload.key,
          serviceForceTranscodeVideo: forceTranscode,
        },
      })

    if (!response.success) {
      throw new Error(response.message || 'Unknown error calling upload_media')
    }

    const url = response.location
    const uploadId = response.upload_id
    const isPhotoResponse = response.type === 'photo'
    const isVideResponse = response.type === 'video'
    // FIXME: upload cannot be any of the final types because we are currently creating them
    //        we should simply check that the response matches the upload type
    //        using the guard functions here will is misleading
    const isPhotoUpload = Upload.isImage(upload) || Upload.isGif(upload)
    const isVideoUpload = Upload.isVideo(upload)

    // FIX: this guard could replace the above FIXME
    // const isMatchingReponse = (
    //   response: MediaResponse,
    //   uploadType: MediaType,
    // ): boolean => {
    //   const isMatchingGifResponse =
    //     response.type === 'photo' && uploadType === MediaType.GIF
    //   const isMatchingImageResponse =
    //     response.type === 'photo' && uploadType === MediaType.IMAGE
    //   const isMatchingVideoResponse =
    //     response.type === 'video' && uploadType === MediaType.VIDEO
    //   const isMatchingDocumentResponse =
    //     response.type === 'document' && uploadType === MediaType.DOCUMENT

    //   return (
    //     isMatchingGifResponse ||
    //     isMatchingImageResponse ||
    //     isMatchingVideoResponse ||
    //     isMatchingDocumentResponse
    //   )
    // }
    //
    // if (!isMatchingReponse(response, upload.mediaType)) {
    //   throw new Error('Upload failed: unexpected response type')
    // }

    if (!url || !uploadId) {
      throw new Error(
        'Upload failed: response is missing url Upload failed: response is missing url or uploadId',
      )
    }

    if (isPhotoResponse && isPhotoUpload) {
      const { width, height } = response
      const dimensions = ImageDimensions.new({ width, height })

      return {
        ...upload,
        status: UploadStatus.Completed,
        url,
        uploadId,
        dimensions,
      }
    }

    if (
      response.type === 'document' &&
      upload.mediaType.toString().toLowerCase() === response.type
    ) {
      const { details } = response

      return {
        ...upload,
        status: UploadStatus.Completed,
        mediaType: MediaType.DOCUMENT,
        url,
        uploadId,
        details: {
          title: 'Untitled Document ¯\\_(ツ)_/¯',
          ...details,
        },
      }
    }

    if (isVideResponse && isVideoUpload) {
      const { transcodeVideo, details } = response

      const videoMetadata = VideoMetadata.newFromDetails(
        details,
        transcodeVideo,
      )

      return {
        ...upload,
        status: UploadStatus.Completed,
        url,
        uploadId,
        videoMetadata,
      }
    }

    throw new Error(
      `Upload failed: backend type was ${response.type} and upload type was ${upload.mediaType}`,
    )
  },
}
