import React from 'react'
import styled from 'styled-components'
import { fontSizeSmall, fontWeightMedium } from '@bufferapp/ui/style/fonts'
import { Flash } from '@bufferapp/ui/Icon'
import {
  white,
  green,
  grayDarker,
  grayLight,
  purple,
  purpleLighter,
} from '@bufferapp/ui/style/colors'

const Tag = styled.span<{ type: string }>`
  display: ${({ type }): string =>
    type === 'paywall' ? 'flex' : 'inline-block'};
  padding: ${({ type }): string =>
    type === 'paywall' ? '4px 8px' : '2px 8px'};
  font-weight: ${fontWeightMedium};
  font-size: ${fontSizeSmall};
  margin-left: 8px;
  border-radius: 100px;
  color: ${({ type }): string => (type === 'counter' ? grayDarker : white)};
  background-color: ${({ type }): string => {
    if (type === 'counter') {
      return grayLight
    }
    if (type === 'paywall') {
      return purpleLighter
    }
    return green
  }};
`

type NavTagProps = {
  type: string
  labelName?: string
}
const NavTag = ({ type, labelName }: NavTagProps): JSX.Element => (
  <Tag type={type}>
    {type === 'paywall' ? <Flash color={purple} /> : labelName}
  </Tag>
)

export default NavTag
