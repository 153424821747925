import React from 'react'

type CalendarPostContext = {
  hasPermissions: boolean
  selectedProfileIds: string[]
}

const createCalendarPostContext = React.createContext<CalendarPostContext>({
  hasPermissions: false,
  selectedProfileIds: [],
})

export default createCalendarPostContext
