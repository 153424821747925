import React from 'react'
import {
  ArrowUpIcon,
  Text,
  TrendingUpIcon,
  type IconProps,
  ImpressionsIcon,
  MessageSquareIcon,
  ThumbsUpIcon,
  ViewIcon,
  SmileIcon,
  RepostIcon,
  VisuallyHidden,
  Link,
  CommentSquareQuoteIcon,
  StarIcon,
  Share2Icon,
  MousePointerClickIcon,
  BookmarkIcon,
  ActivityIcon,
} from '@buffer-mono/popcorn'

import type { PostMetricType, PostMetricUnit } from '~publish/gql/graphql'

import styles from './PostMetric.module.css'

const formatMetricNumber = (
  value: number,
  unit: PostMetricUnit,
): string | null => {
  const roundedValue = Math.round(value)

  if (unit === 'percentage') {
    return `${roundedValue}%`
  }

  return roundedValue.toLocaleString('en-US')
}

const iconByType: Partial<
  Record<PostMetricType, React.ComponentType<IconProps>>
> = {
  engagementRate: ActivityIcon,
  favorites: StarIcon,
  impressions: ImpressionsIcon,
  likes: ThumbsUpIcon,
  reach: ArrowUpIcon,
  shares: Share2Icon,
  views: ViewIcon,
  reactions: SmileIcon,
  replies: MessageSquareIcon,
  comments: CommentSquareQuoteIcon,
  reblogs: RepostIcon,
  repins: RepostIcon,
  retweets: RepostIcon,
  link_clicks: MousePointerClickIcon,
  clicks: MousePointerClickIcon,
  saves: BookmarkIcon,
}

type PostMetricProps = {
  name: string
  type: PostMetricType
  value: number | null | undefined
  unit: PostMetricUnit
  engageUrl: string | null
}

const PostMetric = ({
  name,
  type,
  value,
  unit,
  engageUrl,
}: PostMetricProps): JSX.Element => {
  const Icon = iconByType[type] ?? TrendingUpIcon
  const linkToEngage =
    (type === 'replies' || type === 'comments') &&
    Number(value) > 0 &&
    engageUrl

  const noData = (
    <>
      <VisuallyHidden>no data available</VisuallyHidden>
      <span aria-hidden>-</span>
    </>
  )

  const hasData = value !== null && value !== undefined

  const displayValue = hasData ? formatMetricNumber(value, unit) : noData

  return (
    <div className={styles.wrapper}>
      <div className={styles.label}>
        <Icon className={styles.icon} aria-hidden={true} color="subtle" />
        <Text weight="medium">{name}</Text>
      </div>
      <Text className={styles.metric} weight="bold" size="md">
        {linkToEngage ? (
          <Link external href={linkToEngage} data-testid="engage-cta">
            {displayValue}
          </Link>
        ) : (
          displayValue
        )}
      </Text>
    </div>
  )
}

PostMetric.displayName = 'PostMetric'

export { PostMetric }
