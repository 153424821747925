import { useState, useEffect } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import { useAccount } from '~publish/legacy/accountContext'

export const QUERY_IDEAS_COUNT = gql`
  query ideasCount($input: OrganizationIdInput!) {
    ideasCount(input: $input) {
      ... on IdeasCount {
        count
      }
      ... on CoreWebAppCommonError {
        code
        error
      }
    }
  }
`

export const useIdeasCount = (): {
  ideasCount: number
} => {
  const [ideasCount, setIdeasCount] = useState<number>(0)
  const { account } = useAccount()
  const organizationId = account?.currentOrganization?.id

  const onFetchComplete = (res: { ideasCount: { count: number } }): void => {
    if (res.ideasCount?.count) {
      setIdeasCount(res.ideasCount.count)
    }
  }

  const [fetchIdeasCount] = useLazyQuery(QUERY_IDEAS_COUNT, {
    variables: { input: { organizationId } },
    onCompleted: onFetchComplete,
  })

  useEffect(() => {
    fetchIdeasCount()
  }, [])

  return { ideasCount }
}
