import React from 'react'
import styled from 'styled-components'
import ImageIcon from '@bufferapp/ui/Icon/Icons/Image'
import LinkIcon from '@bufferapp/ui/Icon/Icons/Link'
import { blue, grayDark } from '@bufferapp/ui/style/colors'

import Button from '../shared/Button'
import { AttachmentTypes } from '../../AppConstants'
import ComposerActionCreators from '../../action-creators/ComposerActionCreators'

import type { Draft } from '../../entities/Draft'

const AttachmentSwitchButton = styled(Button)`
  display: flex;
  align-items: center;
  padding: 0;
  border: 0;
  background: transparent;
  font-size: 14px;
  line-height: 21px;

  &:hover {
    color: ${blue};
  }
`

// @ts-expect-error TS(7006) FIXME: Parameter 'icon' implicitly has an 'any' type.
const AttachmentSwitchIcon = (icon) => styled(icon)`
  display: inline-block;
  height: 15px;
  margin-right: 6px;
  line-height: 15px;
  font-size: 14px;
  vertical-align: text-top;
`

const AttachmentSwitchIconImage = AttachmentSwitchIcon(ImageIcon)
const AttachmentSwitchIconLink = AttachmentSwitchIcon(LinkIcon)

const SwitchWrapper = styled.div`
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  color: ${grayDark};
`

export const AttachmentSwitch = ({ draft }: { draft: Draft }) => {
  const onLinkAttachmentSwitchClick = () => {
    ComposerActionCreators.toggleAttachment(draft.id, AttachmentTypes.LINK)
  }

  const onRetweetAttachmentSwitchClick = () => {
    ComposerActionCreators.toggleAttachment(draft.id, AttachmentTypes.RETWEET)
  }

  const onMediaAttachmentSwitchClick = () => {
    ComposerActionCreators.toggleAttachment(draft.id, AttachmentTypes.MEDIA)
  }

  const mediaAttachmentSwitchCopyWithMediaAttachment =
    !draft.hasMediaAttachmentEnabled() ? 'Add image or video' : ''
  const mediaAttachmentSwitchCopy = draft.hasLinkAttachment()
    ? 'Replace link attachment with image or video'
    : mediaAttachmentSwitchCopyWithMediaAttachment

  if (!draft.hasAttachmentSwitch()) {
    return <></>
  }

  return (
    <SwitchWrapper>
      {draft.hasMediaAttachmentSwitch() && (
        <AttachmentSwitchButton
          onClick={onMediaAttachmentSwitchClick}
          data-testid="media-attachment-switch"
        >
          <AttachmentSwitchIconImage />
          {mediaAttachmentSwitchCopy}
        </AttachmentSwitchButton>
      )}

      {draft.hasLinkAttachmentSwitch() && (
        <AttachmentSwitchButton onClick={onLinkAttachmentSwitchClick}>
          <AttachmentSwitchIconLink />
          Replace with link attachment
        </AttachmentSwitchButton>
      )}

      {draft.hasRetweetAttachmentSwitch() && (
        <AttachmentSwitchButton onClick={onRetweetAttachmentSwitchClick}>
          <AttachmentSwitchIconImage />
          Replace with retweet
        </AttachmentSwitchButton>
      )}
    </SwitchWrapper>
  )
}
