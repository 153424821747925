import { getEditorString, getRange, insertNodes, select } from '@udecode/plate'
import type { Range } from 'slate'
import type { BufferEditor } from '~publish/legacy/editor/BufferEditor/types.plate'
import type {
  AutocompleteInputElement,
  AutocompleteInputElementInterface,
} from '../nodes'

const getTextRangeAfterSelection = <E extends AutocompleteInputElement>(
  editor: BufferEditor,
  elementInterface: AutocompleteInputElementInterface<E>,
): Range | undefined => {
  if (!editor.selection) return

  const { anchor } = editor.selection
  let { focus } = editor.selection

  if (anchor && elementInterface?.validTextRegex) {
    const { path, offset } = anchor
    // Update regex to check from start of string
    const validCharactersRegex = RegExp(
      `^${elementInterface.validTextRegex.source}`,
    )

    // Get the text between the current selection and the first whitespace
    const text = getEditorString(editor, path)
    const textFromSelection = text.slice(offset)
    const firstSpaceIndex = textFromSelection.search(/\s/)
    const textBeforeSpace =
      firstSpaceIndex === -1
        ? textFromSelection
        : textFromSelection.slice(0, firstSpaceIndex)

    // Check whether the text is valid
    const match = validCharactersRegex.exec(textBeforeSpace)
    if (match) focus = { path, offset: offset + match[0].length }
  }

  if (anchor && focus) return getRange(editor, anchor, focus)
}

export const replaceTextAfterTriggerWithMention = <
  E extends AutocompleteInputElement,
>(
  editor: BufferEditor,
  elementInterface: AutocompleteInputElementInterface<E>,
) => {
  const rangeToReplace = getTextRangeAfterSelection(editor, elementInterface)

  if (rangeToReplace) {
    const textToReplace = getEditorString(editor, rangeToReplace)
    const element = elementInterface.new(textToReplace)
    insertNodes(editor, element, {
      at: rangeToReplace,
    })

    // After inserting the node, the location is different and
    // selection is now at end of mention node.
    // Set the selection to right after the trigger of the mention
    if (editor.selection) {
      const { path } = editor.selection.anchor

      select(editor, {
        anchor: { path, offset: 1 },
        focus: { path, offset: 1 },
      })
    }
    return
  }

  // If cannot replace text, insert mention without it
  const element = elementInterface.new()
  insertNodes(editor, element)
}
