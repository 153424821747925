import {
  createSlice,
  type PayloadAction,
  type Slice,
  type SliceCaseReducers,
} from '@reduxjs/toolkit'
import type { RootState } from '~publish/legacy/store'

// NOTE: This slice will be deprecated in favor of the one in packages/ai/state/slice.ts
// The deprecation will happen as part of the removal of the old 'AI Assistant' code once
// v2 is out.

type TContentGenerationSliceState = {
  contentGenerationInProgress: boolean
  contentGenerationError: string | null
}

const initialState: TContentGenerationSliceState = {
  contentGenerationInProgress: false,
  contentGenerationError: null,
}

const contentGenerationSlice: Slice<
  TContentGenerationSliceState,
  SliceCaseReducers<TContentGenerationSliceState>,
  'contentGeneration'
> = createSlice({
  name: 'contentGeneration',
  initialState,
  reducers: {
    contentGenerationStarted: (state) => {
      state.contentGenerationInProgress = true
    },
    contentGenerationFinished: (
      state,
      action?: PayloadAction<string | undefined>,
    ) => {
      state.contentGenerationInProgress = false
      state.contentGenerationError = action?.payload || null
    },
  },
})

const selectContentGenerationStatus = (
  state: RootState,
): TContentGenerationSliceState => {
  const { contentGenerationInProgress, contentGenerationError } =
    state[contentGenerationSlice.name]

  return {
    contentGenerationInProgress,
    contentGenerationError,
  }
}

const { contentGenerationStarted, contentGenerationFinished } =
  contentGenerationSlice.actions

export {
  contentGenerationSlice,
  contentGenerationStarted,
  contentGenerationFinished,
  selectContentGenerationStatus,
}
