import { Text } from '@bufferapp/ui'
import React from 'react'
import { getMigrationHubURL } from '~publish/legacy/utils/formatters/getURL'
import { useNotesMigrationCta } from '../../hooks'
import { NotesFeedContainer } from '../styles'
import { Content, MigrationButton, Title } from './styles'

export const MigrationBanner = (): JSX.Element => {
  const migrationHubUrl = getMigrationHubURL()
  const cta = useNotesMigrationCta()

  return (
    <NotesFeedContainer $isMigrationPopover={true} $showUpgradePath={false}>
      <img
        src="https://buffer-publish.s3.amazonaws.com/images/notes-mp-illustration.svg"
        alt="Notes Illustration"
      />
      <Content>
        <Title>Collaborate with Notes 💬</Title>
        <Text type="p">
          Capture important details and leave notes for your team.
        </Text>
        <MigrationButton
          type="primary"
          onClick={(): void =>
            window.location.assign(`${migrationHubUrl}/?cta=${cta}`)
          }
          label="Explore New Pricing Plans"
        />
      </Content>
    </NotesFeedContainer>
  )
}
