import { getEditorString, getPointAfter, insertNodes } from '@udecode/plate'
import type { Range } from 'slate'
import { EditorStateProxy } from '~publish/legacy/composer/composer/entities/EditorStateProxy'
import { makeUnicodeAwareIndexUnaware } from '~publish/legacy/composer/composer/utils/StringUtils'
import { withoutLosingSelection } from '~publish/legacy/editor/helpers/withoutLosingSelection'
import type { BufferEditor } from '../../../BufferEditor/types.plate'
import type { WithPosition } from '../../../helpers/WithPosition'
import { findCharacterLimitPoint } from '../../character-limit-highlight'
import {
  type FacebookMentionEntityDetails,
  FacebookMentionInputElement,
} from '../nodes/FacebookMentionInputElement'

/**
 * Given an editor and a facebook mention entity,
 * find its location in the editor and insert
 * a FacebookMentionInputElement
 */
export const insertFacebookMention = (
  editor: BufferEditor,
  data: WithPosition<FacebookMentionEntityDetails>,
): Range | undefined => {
  const { start, length, ...mention } = data
  // The start index returned from the server will not
  // match exactly as it's been made unicode aware.
  // Get the entire text of the editor to get the
  // unicode unaware start index of the mention.
  const plainText = EditorStateProxy.getPlainText(editor)
  const unicodeUnawareStart = makeUnicodeAwareIndexUnaware(plainText, start)

  const anchor = findCharacterLimitPoint(editor, unicodeUnawareStart)
  const focus =
    anchor &&
    getPointAfter(editor, anchor, {
      distance: length,
    })

  if (!anchor || !focus) return

  const { name } = mention

  // Only insert mention if text at saved indices matches expected mention text
  // NOTE - This will result in the mention not being picked up at save time
  // so it will essentially be forgotten.
  // Slack conversation: https://buffer.slack.com/archives/C05N3DJ5K7G/p1712242666626179
  const textAtIndices = getEditorString(editor, { anchor, focus })
  if (textAtIndices !== name) return

  const element = FacebookMentionInputElement.new(name, mention)

  withoutLosingSelection(editor, () => {
    insertNodes(editor, element, {
      at: { anchor, focus },
    })
  })
}
