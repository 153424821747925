import { css } from 'styled-components'
import { twitter, white } from '@bufferapp/ui/style/colors'

import { twitterDarker } from '../../styles'
import {
  Video as CommonVideo,
  PlayButton as CommonPlayButton,
} from '../../../Common/VideoPlayer/styles'

export const Video = css`
  ${CommonVideo}
  border-radius: 12px;
`

export const PlayButton = css`
  ${CommonPlayButton}
  width: 40px;
  height: 40px;
  background-color: ${twitter};
  border: 2px solid ${white};

  &:hover {
    background-color: ${twitterDarker};
    svg {
      fill: ${white};
      color: ${white};
    }
  }

  svg {
    fill: ${white};
    color: ${white};
    width: 30px;
    height: 30px;
  }
`

export const Wrapper = css`
  width: 100%;
  position: relative;

  video {
    ${Video}
  }

  button {
    ${PlayButton}
  }
`
