import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import SimpleModal from '@bufferapp/ui/SimpleModal'
import Text from '@bufferapp/ui/Text'
import Button from '@bufferapp/ui/Button'
import CheckmarkIcon from '@bufferapp/ui/Icon/Icons/Checkmark'
import { blue, grayDark } from '@bufferapp/ui/style/colors'
import ModalActionCreators from '../shared-components/modal/actionCreators'

export const ContentWrapper = styled.div`
  width: 720px;
  height: 432px;
  display: flex;

  h2 {
    margin: 0;
  }

  p {
    line-height: 24px;
    color: ${grayDark};
  }
`

export const Left = styled.div`
  margin: 56px 0 56px 48px;
`

export const StyledImage = styled.img`
  height: 100%;
  width: 50%;
`

export const BlueCheckIcon = styled(CheckmarkIcon)`
  color: ${blue};
  flex-shrink: 0;
  margin-right: 8px;
`

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 16px 0 32px;
`

export const Item = styled.li`
  display: flex;
  align-items: center;
  color: ${grayDark};
`

export const ThreadsFreeOBPaywall = ({
  closeFirstModal,
  ctaString,
  service,
}: {
  closeFirstModal: () => void
  ctaString: string
  service: string
}): JSX.Element => {
  let upgradePathName = 'unknownServiceThreadsLimit-upgrade'
  let paywallImage =
    'https://buffer-publish.s3.amazonaws.com/images/threads-upgrade%402x.png'

  if (service === 'omni') {
    upgradePathName = 'omniThreadsLimit-upgrade'
  }
  if (service === 'threads') {
    upgradePathName = 'metaThreadsLimit-upgrade'
    paywallImage =
      'https://buffer-publish.s3.amazonaws.com/images/meta-threads-upgrade.png'
  }
  if (service === 'bluesky') {
    upgradePathName = 'blueskyThreadsLimit-upgrade'
    paywallImage =
      'https://buffer-publish.s3.amazonaws.com/images/bluesky-threads-upgrade.png'
  }
  if (service === 'twitter') {
    upgradePathName = 'threadsLimit-upgrade'
    paywallImage =
      'https://buffer-publish.s3.amazonaws.com/images/threads-upgrade%402x.png'
  }

  return (
    <SimpleModal
      closeAction={(): void => {
        if (closeFirstModal) {
          closeFirstModal()
        } else {
          ModalActionCreators.closeModal(undefined)
        }
      }}
    >
      <ContentWrapper>
        <Left>
          <Text type="h2">Upgrade to schedule more threaded posts</Text>
          <Text type="p">
            You’ve reached the free plan limit of{' '}
            <strong>one scheduled threaded post at a time.</strong> This limit
            resets once the thread is published. Upgrade your plan to:
          </Text>
          <List>
            <Item>
              <BlueCheckIcon />
              Schedule up to 2,000 posts at a time.
            </Item>
            <Item>
              <BlueCheckIcon />
              Get in-depth social analytics.
            </Item>
            <Item>
              <BlueCheckIcon />
              And much more.
            </Item>
          </List>
          {/* @ts-expect-error TS(2740) FIXME: Type '{ "data-testid": string; type: string; label... Remove this comment to see the full error message */}
          <Button
            data-testid="threads-free-upgrade-button"
            type="primary"
            label="See Upgrade Options"
            onClick={(): void => {
              ModalActionCreators.closeModal(undefined)
              if (closeFirstModal) closeFirstModal()
              const { MODALS, actions } = window?.appshell || {}
              actions.openModal(MODALS.planSelector, {
                cta: ctaString,
                upgradePathName,
              })
            }}
          />
        </Left>
        <StyledImage src={paywallImage} />
      </ContentWrapper>
    </SimpleModal>
  )
}

ThreadsFreeOBPaywall.propTypes = {
  closeFirstModal: PropTypes.func,
  ctaString: PropTypes.string,
}

ThreadsFreeOBPaywall.defaultProps = {
  closeFirstModal: null,
  ctaString: null,
}
