import type { OnErrorCallback } from '@bugsnag/browser'
import React from 'react'

import { env } from '~publish/env'
import PublishBugsnagClient from './PublishBugsnagClient'
import BoundaryFallback from './errorComponent'
import FittedFallbackComponent from './fallbackComponent'
import SimpleErrorBoundary from './simpleErrorBoundary'

const BugsnagErrorBoundary =
  env.VITE_NODE_ENV !== 'test'
    ? PublishBugsnagClient.getPlugin('react')?.createErrorBoundary(React)
    : undefined

type BoundaryProps = React.PropsWithChildren<{
  fallbackComponent?: React.FunctionComponent | typeof React.Component
  onError?: OnErrorCallback
  beforeSend?: (evt: Event) => Event
}>

type AnyComponent =
  | typeof React.Component<BoundaryProps>
  | React.FunctionComponent<BoundaryProps>

const getErrorBoundary = (fit = false): AnyComponent => {
  if (BugsnagErrorBoundary) {
    return function BugsnagErrBoundary({
      children,
      fallbackComponent,
      onError,
    }: BoundaryProps) {
      return (
        <BugsnagErrorBoundary
          FallbackComponent={
            fallbackComponent || fit
              ? FittedFallbackComponent
              : BoundaryFallback
          }
          onError={onError}
        >
          {children}
        </BugsnagErrorBoundary>
      )
    }
  }

  return function SimpleErrBoundary({
    children,
    fallbackComponent,
  }: BoundaryProps) {
    return (
      <SimpleErrorBoundary
        fallbackComponent={fallbackComponent}
        defaultFallbackComponent={
          fit ? FittedFallbackComponent : BoundaryFallback
        }
      >
        {children}
      </SimpleErrorBoundary>
    )
  }
}

export default getErrorBoundary
