import { getDateString } from '~publish/legacy/duplicate-server/formatters'
import { getPostActionString } from '~publish/legacy/post/helpers'

export const getDraftDetails = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'draft' implicitly has an 'any' ty... Remove this comment to see the full error message
  draft,
  // @ts-expect-error TS(7031) FIXME: Binding element 'profileTimezone' implicitly has a... Remove this comment to see the full error message
  profileTimezone,
  // @ts-expect-error TS(7031) FIXME: Binding element 'twentyFourHourTime' implicitly ha... Remove this comment to see the full error message
  twentyFourHourTime,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isDraftsView' implicitly has an '... Remove this comment to see the full error message
  isDraftsView,
}) => {
  const { createdAt } = draft
  const servicesWithCommentFeature = ['instagram']
  const createdAtString = getDateString(createdAt, profileTimezone, {
    createdAt,
    twentyFourHourTime,
  })
  let avatarUrl = ''
  if (draft.user) {
    avatarUrl = draft.user.avatar || draft.user.gravatar
  }

  return {
    draftId: draft.id,
    via: draft.via,
    creatorName: draft.user ? draft.user.name : '',
    email: draft.user ? draft.user.email : '',
    avatarUrl,
    createdAt: createdAtString,
    postAction: getPostActionString({
      post: draft,
      profileTimezone,
      twentyFourHourTime,
      // @ts-expect-error TS(2345) FIXME: Argument of type '{ post: any; profileTimezone: an... Remove this comment to see the full error message
      isDraftsView,
    }),
    isRetweet: draft.retweet !== undefined,
    commentText: draft.commentText,
    hasCommentEnabled:
      servicesWithCommentFeature.indexOf(draft.profile_service) !== -1,
  }
}

// Could export this to utils and then pull it in and pass tab depending on which package uses it
export const formatPostLists = (
  // @ts-expect-error TS(7006) FIXME: Parameter 'profile' implicitly has an 'any' type.
  profile,
  // @ts-expect-error TS(7006) FIXME: Parameter 'drafts' implicitly has an 'any' type.
  drafts,
  // @ts-expect-error TS(7006) FIXME: Parameter 'user' implicitly has an 'any' type.
  user,
  // @ts-expect-error TS(7006) FIXME: Parameter 'tabId' implicitly has an 'any' type.
  tabId,
  // @ts-expect-error TS(7006) FIXME: Parameter 'hasApprovalFeature' implicitly has an '... Remove this comment to see the full error message
  hasApprovalFeature,
) => {
  const profileTimezone = profile.timezone
  const { isManager } = profile
  const twentyFourHourTime = user.twentyfour_hour_time

  // Drafts tab only displays drafts that don't need approval.
  // Approval tabs only display drafts that need approval.
  const isDraftsView = tabId === 'drafts'
  const draftsList = Object.values(drafts).filter(
    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    (draft) => draft.needsApproval !== isDraftsView,
  )
  const typeOfTab = isDraftsView ? 'drafts' : 'approval'

  return draftsList.reduce((acc, draft, index) => {
    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    acc.push({
      queueItemType: 'post',
      hasPermission:
        // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
        isManager || user.id === (draft.user ? draft.user.id : draft.id),
      role: profile.organizationRole,
      manager: isManager,
      draftDetails: getDraftDetails({
        draft,
        profileTimezone,
        twentyFourHourTime,
        isDraftsView,
        // @ts-expect-error TS(2345) FIXME: Argument of type '{ draft: unknown; profileTimezon... Remove this comment to see the full error message
        hasApprovalFeature,
      }),
      view: typeOfTab,
      index,
      // @ts-expect-error TS(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
      ...draft,
    })
    return acc
  }, [])
}
