import twitter from 'twitter-text'
import type { FacebookMentionDbEntry } from '~publish/legacy/editor/plugins/facebook-mention/nodes'
import { isNotNull } from '~publish/legacy/utils/isNotNull'
import { type MentionEntity, concatMentionsToEntities } from '../common'

export const getFacebookMentions = (
  fbEntities?: FacebookMentionDbEntry[] | null,
): MentionEntity[] | null => {
  if (!fbEntities || fbEntities.length === 0) return null

  return fbEntities.map((entity) => ({
    displayString: entity.text,
    indices: entity.indices,
    rawString: entity.text,
  }))
}

const getEntities = (
  entities: MentionEntity[],
  fbEntities?: FacebookMentionDbEntry[] | null,
): MentionEntity[] => {
  const mentions = getFacebookMentions(fbEntities)

  return concatMentionsToEntities(mentions, entities)
}

export const parseFacebookLinks = (
  text?: string | null,
  fbEntities?: FacebookMentionDbEntry[] | null,
): MentionEntity[] => {
  const entities = text
    ? twitter
        .extractEntitiesWithIndices(text)
        .map((entity) => {
          if ('url' in entity && entity.url) {
            return {
              displayString: entity.url,
              indices: entity.indices,
              rawString: entity.url,
              url: entity.url,
            }
          }
          if ('hashtag' in entity && entity.hashtag) {
            return {
              displayString: `#${entity.hashtag}`,
              indices: entity.indices,
              rawString: `#${entity.hashtag}`,
            }
          }
          return null
        })
        .filter(isNotNull)
    : []

  return getEntities(entities, fbEntities)
}
