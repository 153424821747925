import React from 'react'
import { Empty } from '../Common/Empty'
import {
  draftPreviewPropType,
  profilePreviewPropType,
} from '../Common/propTypes'
import type { PostType, DraftPreview, ProfilePreview } from '../types'
import { LinkedInPost } from './components/Post'
import * as Styles from '../Common/styles'

export const LinkedInPreview = ({
  draft,
  profile,
}: {
  draft: DraftPreview
  profile: ProfilePreview
}): JSX.Element => {
  const containerRef = React.useRef<HTMLDivElement | null>(null)

  if (draft.isEmpty) return <Empty />

  const draftLinkedIn = {
    content: {
      ...draft,
      text: draft.text,
    },
  } as PostType

  return (
    <Styles.Wrapper data-testid="linkedin-preview" ref={containerRef}>
      <LinkedInPost content={draftLinkedIn.content} profile={profile} />
    </Styles.Wrapper>
  )
}

LinkedInPreview.propTypes = {
  draft: draftPreviewPropType.isRequired,
  profile: profilePreviewPropType.isRequired,
}
