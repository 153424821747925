import type { ComboboxItemProps } from '@udecode/plate'
import React from 'react'
import type { EmojiSearchData } from '../../types'
import { EmojiSuggestionLi } from './styles'

export const EmojiSuggestion = ({
  item: emoji,
}: ComboboxItemProps<EmojiSearchData>): JSX.Element => {
  // If no emoji is provided, don't render anything
  if (!emoji?.data) return <></>

  const { name, skins } = emoji.data

  // TODO: Add functionality to change skin tones
  // For now, always pick the default skin tone
  const defaultSkin = skins[0]
  const nativeEmoji = defaultSkin.native
  const shortcode = defaultSkin?.shortcodes
  const text = shortcode ?? name

  return (
    <EmojiSuggestionLi data-testid="emoji-suggestion-entry">
      <span>{nativeEmoji}</span>
      <span>{text}</span>
    </EmojiSuggestionLi>
  )
}
