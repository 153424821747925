import React, { useCallback, useEffect, useRef } from 'react'
import {
  useHasEntitlement,
  useOrganizationMembersTotal,
} from '~publish/legacy/accountContext'
import { deleteEntityNote, editEntityNote } from '~publish/legacy/post/thunks'
import type {
  EntityWithNotes,
  PublishingNote as NoteType,
} from '~publish/gql/graphql'

import { useAppDispatch } from '~publish/legacy/store'
import { AddNote } from '../AddNote'
import { Note } from '../Note/Note'
import { NotesVisibilityIcon } from './NotesVisibilityIcon/NotesVisibilityIcon'
import {
  NotesFeedContainer,
  NotesFeedScrollContainer,
  NotesWrapper,
} from './styles'
import { UpgradePathNotes } from '../UpgradePathNotes'

type NotesFeedProps = {
  entityId: string
  entityType: EntityWithNotes
  scrollToNoteId?: string
  notes: NoteType[]
}

const SCOLL_TOP_OFFSET = 30

export const NotesFeed = ({
  entityId,
  entityType,
  scrollToNoteId,
  notes,
}: NotesFeedProps): JSX.Element => {
  const dispatch = useAppDispatch()
  const scrollContainerRef = useRef<HTMLDivElement | null>(null)
  const scrollToNoteRef = useRef<HTMLLIElement | null>(null)

  const totalMembers = useOrganizationMembersTotal()

  useEffect(() => {
    if (!scrollContainerRef.current || !scrollToNoteRef.current) {
      return
    }

    const scrollContainerRect =
      scrollContainerRef.current.getBoundingClientRect()
    const noteRect = scrollToNoteRef.current.getBoundingClientRect()

    // Calculate the scroll distance and add an offset for visual spacing
    scrollContainerRef.current.scrollTop =
      noteRect.top - scrollContainerRect.top - SCOLL_TOP_OFFSET
  }, [scrollContainerRef, scrollToNoteRef])

  const handleDeleteNote = useCallback(
    (noteId: string): void => {
      dispatch(
        deleteEntityNote({
          entityId,
          entityType,
          noteId,
        }),
      )
    },
    [dispatch, entityId, entityType],
  )

  const handleEditNote = useCallback(
    (noteId: string, text: string): void => {
      dispatch(editEntityNote({ entityId, entityType, noteId, text }))
    },
    [dispatch, entityId, entityType],
  )

  const hasAccessTeamPanel = useHasEntitlement('teamsPanel')
  const showUpgradePath = !hasAccessTeamPanel

  return (
    <NotesFeedContainer $showUpgradePath={showUpgradePath}>
      <NotesFeedScrollContainer ref={scrollContainerRef}>
        <NotesWrapper>
          {notes.map((note) => {
            const isHighlighted = scrollToNoteId === note.id

            return (
              <Note
                key={note.id}
                note={note}
                highlighted={isHighlighted}
                ref={isHighlighted ? scrollToNoteRef : null}
                onDelete={(): void => handleDeleteNote(note.id)}
                onEdit={handleEditNote}
              />
            )
          })}
        </NotesWrapper>
      </NotesFeedScrollContainer>
      <AddNote
        entityId={entityId}
        entityType={entityType}
        notesCount={notes.length}
      />
      {showUpgradePath && <UpgradePathNotes />}
      {totalMembers && totalMembers > 1 && <NotesVisibilityIcon />}
    </NotesFeedContainer>
  )
}
