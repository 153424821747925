import React from 'react'

import { useAppSelector } from '~publish/legacy/store'
import { selectGenerateContentLimits } from '~publish/legacy/ai/state/aiLimits/slice'

import * as Styled from './styles'

/**
 *
 */
const UpgradeOptions = (): JSX.Element => {
  const { generateContentTokensLeft } = useAppSelector((state) =>
    selectGenerateContentLimits(state),
  )

  return (
    <>
      <Styled.Title>
        {generateContentTokensLeft > 0
          ? 'Get more AI Credits'
          : 'You’ve used up all your AI Credits'}
      </Styled.Title>
      <Styled.Text>Please reach out to Support to get more.</Styled.Text>
    </>
  )
}

export { UpgradeOptions }
