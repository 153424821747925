import React from 'react'
import clsx from 'clsx'

import styles from './PageSidebar.module.css'

export type ItemProps = {
  children: React.ReactNode
  disabled?: boolean
} & React.HTMLAttributes<HTMLLIElement>

export const SideNavItem = React.forwardRef(
  (
    { children, className, disabled, ...props }: ItemProps,
    ref: React.ForwardedRef<HTMLLIElement>,
  ): JSX.Element => {
    return (
      <li
        ref={ref}
        className={clsx(styles.item, disabled && styles.disabled, className)}
        {...props}
      >
        {children}
      </li>
    )
  },
)

SideNavItem.displayName = 'Item'
