import React from 'react'

import * as Styles from './LoaderAnimation.styles'

export function LoaderAnimation(): JSX.Element {
  return (
    <Styles.Wrapper>
      <svg
        width="39"
        height="39"
        viewBox="0 0 39 39"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_2684_10350"
          style={{ maskType: 'alpha' }}
          maskUnits="userSpaceOnUse"
          x="14"
          y="27"
          width="10"
          height="11"
        >
          <path
            d="M19.6652 30.96L22.9456 31.0276L20.1385 32.7263L20.0353 35.9485L18.3348 33.2096L15.0544 33.142L17.8615 31.4433L17.9647 28.2211L19.6652 30.96Z"
            fill="#BA6BFF"
          >
            <animate
              attributeName="d"
              values="M19.6652 30.96L22.9456 31.0276L20.1385 32.7263L20.0353 35.9485L18.3348 33.2096L15.0544 33.142L17.8615 31.4433L17.9647 28.2211L19.6652 30.96Z;M15.9456 16.2836L12.979 26.5081L10.0123 16.2836L0 13.2541L10.0123 10.2246L12.979 0L15.9456 10.2246L25.9579 13.2541L15.9456 16.2836Z;M11.2817 11.6802L6.79969 27.1276L2.31769 11.6802L-12.8091 7.10324L2.31769 2.52625L6.79969 -12.9211L11.2817 2.52625L26.4084 7.10324L11.2817 11.6802Z"
              begin="0s"
              dur="1.8s"
              repeatCount="indefinite"
              calcMode="spline"
              keySplines="0 0 0 1; 0 0 0 1"
              keyTimes="0;0.5;1"
            />
            <animate
              attributeName="fill-opacity"
              values="1;0.01;0.01"
              begin="0s"
              dur="1.8s"
              repeatCount="indefinite"
              calcMode="spline"
              keySplines="0 0 0 1; 0 0 0 1"
              keyTimes="0;0.5;1"
            />
          </path>
          <path
            d="M18.5514 33.7591L21.9103 37.0057L20.7097 32.5429L23.9809 29.2783L19.4486 30.4105L16.0897 27.1639L17.2902 31.6267L14.0191 34.8913L18.5514 33.7591Z"
            fill="#BA6BFF"
          >
            <animate
              attributeName="d"
              values="M18.5514 33.7591L21.9103 37.0057L20.7097 32.5429L23.9809 29.2783L19.4486 30.4105L16.0897 27.1639L17.2902 31.6267L14.0191 34.8913L18.5514 33.7591Z;M14.4972 32.933L17.6493 27.2527L12.0869 30.4715L6.52444 27.2527L9.67648 32.933L6.52444 38.6133L12.0869 35.3945L17.6493 38.6133L14.4972 32.933Z;M9.09351 36.8344L13.8556 28.2525L5.45189 33.1156L-2.95186 28.2525L1.81026 36.8344L-2.95186 45.4162L5.45189 40.5532L13.8556 45.4162L9.09351 36.8344Z"
              begin="0s"
              dur="1.8s"
              repeatCount="indefinite"
              calcMode="spline"
              keySplines="0 0 0 1; 0 0 0 1"
              keyTimes="0;0.5;1"
            />
            <animate
              attributeName="fill-opacity"
              values="1;0.01;0.01"
              begin="0s"
              dur="1.8s"
              repeatCount="indefinite"
              calcMode="spline"
              keySplines="0 0 0 1; 0 0 0 1"
              keyTimes="0;0.5;1"
            />
          </path>
          <path
            d="M14.0191 29.2783L17.2245 32.5605L16.0897 37.0057L19.4659 33.8234L23.9809 34.8913L20.7755 31.609L21.9103 27.1639L18.5341 30.3461L14.0191 29.2783Z"
            fill="#BA6BFF"
          >
            <animate
              attributeName="d"
              values="M14.0191 29.2783L17.2245 32.5605L16.0897 37.0057L19.4659 33.8234L23.9809 34.8913L20.7755 31.609L21.9103 27.1639L18.5341 30.3461L14.0191 29.2783Z;M38.2785 18.9344L30.8619 23.0999L23.4453 18.9344L27.5244 26.5081L23.4453 34.0819L30.8619 29.9163L38.2785 34.0819L34.1993 26.5081L38.2785 18.9344Z;M45.0224 15.6851L33.8174 21.9785L22.6124 15.6851L28.7751 27.1276L22.6124 38.5701L33.8174 32.2767L45.0224 38.5701L38.8596 27.1276L45.0224 15.6851Z"
              begin="0s"
              dur="1.8s"
              repeatCount="indefinite"
              calcMode="spline"
              keySplines="0 0 0 1; 0 0 0 1"
              keyTimes="0;0.5;1"
            />
            <animate
              attributeName="fill-opacity"
              values="1;0.01;0.01"
              begin="0s"
              dur="1.8s"
              repeatCount="indefinite"
              calcMode="spline"
              keySplines="0 0 0 1; 0 0 0 1"
              keyTimes="0;0.5;1"
            />
          </path>
          <animate
            attributeName="x"
            values="14;0;-13"
            begin="0s"
            dur="1.8s"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="0 0 0 1; 0 0 0 1"
            keyTimes="0;0.5;1"
          />
          <animate
            attributeName="y"
            values="27;0;-13"
            begin="0s"
            dur="1.8s"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="0 0 0 1; 0 0 0 1"
            keyTimes="0;0.5;1"
          />
          <animate
            attributeName="width"
            values="10;39;59"
            begin="0s"
            dur="1.8s"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="0 0 0 1; 0 0 0 1"
            keyTimes="0;0.5;1"
          />
          <animate
            attributeName="height"
            values="11;39;59"
            begin="0s"
            dur="1.8s"
            repeatCount="indefinite"
            calcMode="spline"
            keySplines="0 0 0 1; 0 0 0 1"
            keyTimes="0;0.5;1"
          />
        </mask>
        <g mask="url(#mask0_2684_10350)">
          <rect
            x="-0.350571"
            y="-0.0741959"
            width="39"
            height="39"
            fill="url(#paint0_linear_2684_10350)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_2684_10350"
            x1="-0.350571"
            y1="-0.0741959"
            x2="38.6494"
            y2="-0.0741959"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#BA6BFF" />
            <stop offset="1" stopColor="#0113FF" />
          </linearGradient>
        </defs>
      </svg>
    </Styles.Wrapper>
  )
}
