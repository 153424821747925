import React from 'react'
import {
  PrimaryButton,
  ActionDivider,
  SecondaryButton,
  Actions,
} from '../styles'

type NoteActionsProps = {
  isEditing: boolean
  canEdit: boolean
  canDelete: boolean
  handlePrimaryClick: () => void
  handleSecondaryClick: () => void
}

export const NoteActions = ({
  isEditing,
  canEdit,
  canDelete,
  handlePrimaryClick,
  handleSecondaryClick,
}: NoteActionsProps): JSX.Element => {
  return (
    <Actions>
      {canEdit && (
        <PrimaryButton
          isEditing={isEditing}
          role="button"
          tabIndex={0}
          onClick={handlePrimaryClick}
        >
          {!isEditing ? 'Edit' : 'Save'}
        </PrimaryButton>
      )}
      {canEdit && (
        <ActionDivider aria-hidden="true">&nbsp;•&nbsp;</ActionDivider>
      )}
      {canDelete && (
        <SecondaryButton
          isEditing={isEditing}
          role="button"
          tabIndex={0}
          onClick={handleSecondaryClick}
        >
          {!isEditing ? 'Delete' : 'Cancel'}
        </SecondaryButton>
      )}
    </Actions>
  )
}
