import React, {
  type ElementRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import Select from '@bufferapp/ui/Select'
import { IconButton } from '@buffer-mono/popcorn'
import {
  type RootState,
  useAppDispatch,
  useAppSelector,
} from '~publish/legacy/store'
import { selectAllTags } from '~publish/legacy/campaign/slice'
import type {
  CampaignColor,
  SelectedTag,
  Tag,
} from '~publish/legacy/campaign/types'
import {
  SelectWrapper,
  Color,
  LabelWrapper,
  TagLabelWrapper,
  TagCheckboxWrapper,
} from './styles'
import truncateText from '~publish/legacy/utils/truncateText'
import ReactDOMServer from 'react-dom/server'
import LockedIcon from '@bufferapp/ui/Icon/Icons/Locked'
import {
  useAccount,
  useOrganizationLimits,
} from '~publish/legacy/accountContext'
import UserEntity from '../../user/UserEntity'
import { actions as modalActions } from '~publish/legacy/modals/reducer'
import { sortCampaignsAlphabetically } from '~publish/legacy/utils/sortTags'
import { getColorToPreselect } from '~publish/legacy/tag-form-modal/components/colors'
import { CustomMessage } from './components/CustomMessage'

type SelectItem = {
  id: string
  name: string
  color: CampaignColor | string
  selected: boolean
  disabled: boolean
  icon?: JSX.Element
  iconEnd?: boolean
  component: (tag: SelectItem) => string
  selectedItemClick: (tag: SelectItem, selected: SelectedTag[]) => void
}

type Props = {
  onSelectTags: (selectedTag: SelectedTag[]) => void
  preSelectedTags?: SelectedTag[]
  initialLabel?: string
  disabled?: boolean
  onClose?: () => void
  source: string
  iconButton?: boolean
  horizontalOffset?: number | string
}

const TagItem = (tag: SelectItem): string => {
  return ReactDOMServer.renderToString(
    <TagLabelWrapper>
      <TagCheckboxWrapper
        type="checkbox"
        defaultChecked={tag.selected}
        disabled={tag.disabled}
      />
      <LabelWrapper title={tag.name}>
        {tag.color && <Color color={tag.color} $smallMargin />}
      </LabelWrapper>
    </TagLabelWrapper>,
  )
}

export const TagsSelector = ({
  onSelectTags,
  preSelectedTags,
  initialLabel,
  disabled = false,
  iconButton = false,
  horizontalOffset,
  onClose,
  source,
}: Props): JSX.Element => {
  const [selectedTags, setSelectedTags] = useState<SelectedTag[]>([])
  const [tagCreated, setTagCreated] = useState<boolean>(false)
  const tagsFromSelector: Tag[] = useAppSelector((state: RootState) =>
    selectAllTags(state),
  )
  const tags: Tag[] = sortCampaignsAlphabetically(tagsFromSelector)
  const account = useAccount()
  const isAdmin = UserEntity.isAdmin(account)
  const dispatch = useAppDispatch()
  const ref = useRef<ElementRef<'div'>>(null)

  const { tags: tagsLimit } = useOrganizationLimits()
  const isAtTagsLimit = tagsLimit && tags && tags.length >= tagsLimit

  const filterAvailableTags = useCallback(
    (postTags: SelectedTag[], allTags: Tag[]): SelectedTag[] => {
      if (!allTags.length) {
        return postTags
      }

      const availableTags = allTags
        .filter((tag: Tag) => !tag.locked)
        .map((tag: Tag) => tag.id)
      return postTags.filter((tag: SelectedTag) =>
        availableTags.includes(tag.id),
      )
    },
    [],
  )

  useEffect(() => {
    if (preSelectedTags) {
      // filter out tags that are locked or not available
      const availableTags = filterAvailableTags(
        preSelectedTags,
        tagsFromSelector,
      )
      setSelectedTags([...availableTags])
    }
  }, [preSelectedTags, tagsFromSelector, filterAvailableTags])

  const getItems = (selected: SelectedTag[]): SelectItem[] => {
    const selectedTagCount = selected.length

    const maxSelectableItemsExceeded = (tag: Tag): boolean =>
      !isTagSelected(tag.id, selected) && selectedTagCount >= 10

    const shouldDisableItem = (tag: Tag): boolean =>
      maxSelectableItemsExceeded(tag) || tag.locked

    const shouldShowLockIcon = (tag: Tag): boolean =>
      tag.locked && !isTagSelected(tag.id, selected)

    return tags.map((tag) => ({
      id: tag.id,
      name: tag.name,
      color: tag.color,
      icon: shouldShowLockIcon(tag) ? <LockedIcon /> : undefined,
      iconEnd: true,
      selected: isTagSelected(tag.id, selected),
      component: TagItem,
      selectedItemClick: (
        tag: SelectItem,
        selectedTags: SelectedTag[],
      ): void => {
        onSelectItem(tag, selectedTags)
      },
      disabled: shouldDisableItem(tag),
    }))
  }

  const isTagSelected = (id: string, selectedTags: SelectedTag[]): boolean => {
    return !!selectedTags.find((el: SelectedTag) => el.id === id)
  }

  const onSelectItem = (
    item: SelectItem,
    selectedTags: SelectedTag[],
  ): void => {
    const { id, name, color } = item
    let updatedTags: SelectedTag[] = []
    if (isTagSelected(item.id, selectedTags)) {
      updatedTags = selectedTags.filter((el: SelectedTag) => el.id !== id)
      setSelectedTags(updatedTags)
    } else {
      updatedTags = [...selectedTags, { id, name, color }]
      setSelectedTags(updatedTags)
    }
    onSelectTags(updatedTags)
  }

  const scrollToBottom = (): void => {
    // this selector relies on the markup of the `Select` component in BDS
    // where items are rendered as a `li` under an `ul`
    const scrollContainer = ref.current?.querySelector('ul')

    // if scrollContainer is a scrollable element, scroll to the bottom of
    // the `ul` element where the new item is added
    if (scrollContainer && scrollContainer.scrollHeight) {
      scrollContainer.scrollTo({
        top: scrollContainer.scrollHeight,
        behavior: 'smooth',
      })
    }
  }

  useEffect(() => {
    // TODO: We are temporarily using component state to trigger a scroll to the bottom
    // but will replace this with a useImperativeHandle hook in the future
    if (tagCreated) {
      scrollToBottom()
      setTagCreated(false)
    }
  }, [selectedTags.length, tagCreated])

  const showCreateTagModal = (name: string): void => {
    dispatch(
      modalActions.openTagFormModal({
        editMode: false,
        isFromSelector: true,
        tag: { name, color: getColorToPreselect(tags) },
      }),
    )
  }

  const getLabel = (): string => {
    let labelLength = 21
    let label = ''
    let addedLabels = 0
    let unAddedLabels = 0
    if (selectedTags.length < 1) {
      label = 'Add Tags'
    } else {
      // Return names of tags in the format `tag1 tag..+2`
      const selected: Array<{ title: string; color: string }> = []
      for (const selectedTag of selectedTags) {
        const tagLength = selectedTag.name.length
        const tag =
          tagLength > labelLength
            ? truncateText(selectedTag.name, labelLength)
            : selectedTag.name
        selected.push({ title: tag, color: selectedTag.color })
        addedLabels++
        labelLength -= tag.length
        if (labelLength < 1) {
          unAddedLabels = selectedTags.length - addedLabels
          break
        }
      }
      return ReactDOMServer.renderToString(
        <TagLabelWrapper>
          {selected.map((tag) => (
            <LabelWrapper key={tag.title}>
              {tag.color && <Color color={tag.color} $smallMargin />}
              <span>{tag.title}</span>
            </LabelWrapper>
          ))}
          {!!unAddedLabels && <span>+ {unAddedLabels}</span>}
        </TagLabelWrapper>,
      )
    }
    return label
  }
  const label = initialLabel || getLabel()

  const customButton = (onButtonClick: () => void): JSX.Element => (
    <IconButton
      onClick={onButtonClick}
      label={label}
      tooltip={label}
      size="small"
      style={{
        padding: 0,
        height: 'var(--control-size-sm)',
      }}
    >
      <AddTagIcon />
    </IconButton>
  )

  return (
    <SelectWrapper data-testid="tag-selector" ref={ref}>
      <Select
        // TODO: Kaikaku ts-expect-error - Buffer UI
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        fullWidth
        textToLeft
        multiSelect
        label={label}
        disabled={disabled}
        keyMap={{ id: 'id', title: 'name' }}
        onSelectClick={(selectedItem: SelectItem): boolean | void => {
          selectedItem.selectedItemClick(selectedItem, selectedTags)
        }}
        items={getItems(selectedTags)}
        clearSearchOnBlur
        searchInputProps={{
          clearSearchOnBlur: false,
        }}
        customButton={iconButton ? customButton : undefined}
        searchPlaceholder={isAdmin ? 'Search or create tag' : 'Search'}
        showCheckmark={false}
        onClose={onClose}
        hasCustomAction={!isAtTagsLimit && isAdmin}
        onCustomItemClick={isAdmin ? showCreateTagModal : undefined}
        customItemLabel={isAdmin ? '+ Create' : undefined}
        hideNoResultsMessage={isAdmin}
        noResultsCustomMessage={!isAdmin ? 'Tag not found' : undefined}
        hasCustomMessage={!!isAtTagsLimit && isAdmin}
        horizontalOffset={horizontalOffset}
        customMessage={
          <CustomMessage
            isAdmin={isAdmin}
            isAtTagsLimit={!!isAtTagsLimit}
            tagsLimit={tagsLimit}
            source={source}
          />
        }
        capitalizeItemLabel={false}
      />
    </SelectWrapper>
  )
}

// TODO: This icon is not the definitive design, since this component needs to be
// refactored, we are keeping this icon here for now.
const AddTagIcon = React.forwardRef<SVGSVGElement>((props, forwardedRef) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      fill="none"
      style={{
        width: 'var(--space-md, 16px)',
        height: 'var(--space-md, 16px)',
      }}
      {...props}
      ref={forwardedRef}
    >
      <g
        clipPath="url(#clip0_8478_103)"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M14.667 8V1.335H8L1.807 7.527a1.618 1.618 0 000 2.28l4.386 4.387a1.617 1.617 0 002.28 0M11.333 4.666h-.006M12 8.666v5.333M9.333 11.334h5.334" />
      </g>
      <defs>
        <clipPath id="clip0_8478_103">
          <path
            fill="#fff"
            transform="matrix(-1 0 0 1 16 0)"
            d="M0 0H16V16H0z"
          />
        </clipPath>
      </defs>
    </svg>
  )
})

AddTagIcon.displayName = 'AddTagIcon'
