import { createAsyncThunk } from '@reduxjs/toolkit'
import type { RootState } from '~publish/legacy/store'
import callRpc from '~publish/legacy/utils/call-rpc'
import type { TrackingSettings } from '../reducer'

export const getGoogleAnalyticsTrackingSettings = createAsyncThunk<
  TrackingSettings,
  {
    profileId: string
  },
  { state: RootState }
>(
  'generalSettings/getGoogleAnalyticsTrackingSettings',
  async ({ profileId }) => {
    const response = await callRpc('getGATrackingSettings', {
      profileId,
    })

    if (!response.trackingSettings) {
      throw new Error('No tracking settings found in the response')
    }
    return {
      utmCampaign: response.trackingSettings.utm_campaign,
      utmMedium: response.trackingSettings.utm_medium,
      utmSource: response.trackingSettings.utm_source,
    }
  },
)
