import type { MessageOptions, ValidationCode } from './types'
import ValidationResult from './ValidationResult'

class ValidationFail extends ValidationResult {
  isValid = false
  message: string
  messageOptions?: MessageOptions
  code: ValidationCode
  composerId?: string
  metadata?: Record<string, unknown>

  constructor(
    message: string,
    code: ValidationCode,
    messageOptions?: MessageOptions,
    metadata?: Record<string, unknown>,
  ) {
    super(false)
    this.message = message
    this.messageOptions = messageOptions
    this.code = code
    this.metadata = metadata
  }

  public addMetadata(metadata: Record<string, unknown>): void {
    this.metadata = {
      ...this.metadata,
      ...metadata,
    }
  }
}

export default ValidationFail
