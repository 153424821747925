import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'

import ProfilesDisconnectedModal from '~publish/legacy/profiles-disconnected-modal'
import InstagramFirstCommentModal from '~publish/legacy/ig-first-comment-modal'
import TrialCompleteModal from '~publish/legacy/trial-complete-modal'
import DeleteCampaignModal from '~publish/legacy/delete-campaign-modal'
import { TagFormModal } from '~publish/legacy/tag-form-modal/components'
import { FirstCommentPaywallModal } from '~publish/legacy/composer/composer/components/first-comment-paywall-modal/FirstCommentPaywallModal'
import { HashTagManagerPaywallModal } from '~publish/legacy/composer/composer/components/hashtag-manager-paywall-modal/HashTagManagerPaywallModal'
import { TagsUpgradeModal } from '~publish/legacy/campaigns-list/components/modals/TagsUpgradeModal'
import { createPage } from '~publish/legacy/routes'

import TrialCollaborationModal from '../../../trial-collaboration-modal'
import { ConnectAChannelModal } from '../ConnectAChannelModal'

const AppModals = (): JSX.Element => {
  const {
    showProfilesDisconnectedModal,
    showInstagramFirstCommentModal,
    showFirstCommentPaywallModal,
    showHashTagManagerPaywallModal,
    showTrialCompleteModal,
    showDeleteCampaignModal,
    showTrialCollaborationModal,
    showTagFormModal,
    showTagsLimitUpgradeModal,
    firstCommentPaywallService,
    showConnectAChannelModal,
    serviceToConnect,
    // @ts-expect-error TS(2339) FIXME: Property 'modals' does not exist on type 'DefaultR... Remove this comment to see the full error message
  } = useSelector((state) => state.modals)
  const location = useLocation()
  const isContentPath = location.pathname.startsWith(createPage.route)

  return (
    <>
      {showProfilesDisconnectedModal && !isContentPath && (
        // @ts-expect-error TS(2739) FIXME: Type '{}' is missing the following properties from... Remove this comment to see the full error message
        <ProfilesDisconnectedModal />
      )}
      {showInstagramFirstCommentModal && <InstagramFirstCommentModal />}
      {showFirstCommentPaywallModal && (
        <FirstCommentPaywallModal service={firstCommentPaywallService} />
      )}
      {showHashTagManagerPaywallModal && <HashTagManagerPaywallModal />}
      {showTrialCompleteModal && <TrialCompleteModal />}
      {showDeleteCampaignModal && <DeleteCampaignModal />}
      {showTrialCollaborationModal && <TrialCollaborationModal />}
      {showTagFormModal && <TagFormModal />}
      {showTagsLimitUpgradeModal && <TagsUpgradeModal />}
      {showConnectAChannelModal && (
        <ConnectAChannelModal service={serviceToConnect} />
      )}
    </>
  )
}

export default AppModals
