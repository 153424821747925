import React from 'react'

export const LinkShortenerIcon = (props: {
  isDisabled: boolean
}): JSX.Element => {
  return (
    <>
      {props.isDisabled && (
        <svg
          width="17"
          height="16"
          viewBox="0 0 17 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.1081 9.03211L11.4053 6.66706C10.9188 5.99171 10.2254 5.51887 9.44545 5.3306C8.6655 5.14233 7.84821 5.25051 7.1354 5.63637C6.41976 6.02499 6.21455 6.53366 6.68118 7.18178C7.00608 7.63228 7.31014 7.59501 7.71548 7.46516L8.01944 7.3631C8.11243 7.33213 8.21035 7.30186 8.31552 7.27394C8.60942 7.19675 8.9181 7.21246 9.20373 7.31915C9.48936 7.42585 9.73952 7.61889 9.92355 7.87462L11.6263 10.2397C11.8751 10.5883 11.984 11.0275 11.9291 11.4613C11.8742 11.8951 11.6601 12.2881 11.3335 12.5543C11.007 12.8205 10.5946 12.9383 10.1866 12.8818C9.77865 12.8253 9.40833 12.5992 9.15668 12.2529L8.5883 11.4642C8.51461 11.3582 8.42181 11.2689 8.31531 11.2014C8.20882 11.134 8.09077 11.0897 7.96804 11.0714C7.84531 11.053 7.72037 11.0608 7.60051 11.0943C7.48064 11.1278 7.36826 11.1864 7.2699 11.2666C7.17155 11.3469 7.0892 11.4471 7.02765 11.5616C6.96611 11.676 6.92661 11.8024 6.91146 11.9333C6.8963 12.0641 6.9058 12.1969 6.93939 12.3238C6.97298 12.4508 7.02999 12.5693 7.1071 12.6725L7.67434 13.4604C7.9464 13.8436 8.28702 14.1657 8.67659 14.4082C9.06615 14.6508 9.49696 14.8089 9.94422 14.8736C10.3915 14.9383 10.8463 14.9082 11.2826 14.7851C11.7189 14.662 12.128 14.4482 12.4863 14.1562C12.8446 13.8642 13.1451 13.4997 13.3705 13.0836C13.5958 12.6675 13.7416 12.2081 13.7994 11.7318C13.8572 11.2556 13.8258 10.7718 13.7072 10.3085C13.5885 9.84516 13.3849 9.41138 13.1081 9.03211Z"
            fill="#636363"
          />
          <path
            d="M8.75642 2.98793L8.18919 2.20008C7.91712 1.8169 7.57658 1.49411 7.18702 1.25156C6.79745 1.00902 6.36664 0.850857 5.91939 0.786182C5.47213 0.721507 5.01727 0.751597 4.58099 0.874721C4.14472 0.997844 3.73564 1.21157 3.37732 1.50359C3.01899 1.79561 2.7185 2.16015 2.49315 2.57624C2.26779 2.99232 2.12202 3.45172 2.06423 3.92799C2.00644 4.40425 2.03777 4.88798 2.15641 5.35131C2.27506 5.81464 2.47868 6.24843 2.75555 6.62769L4.45832 8.99274C4.94482 9.6681 5.63821 10.1409 6.41815 10.3292C7.1981 10.5175 8.01539 10.4093 8.7282 10.0234C9.44384 9.63481 9.64905 9.12615 9.18242 8.47802C8.85813 8.02761 8.55346 8.0648 8.14812 8.19464L7.84416 8.29671C7.75117 8.32767 7.65325 8.35794 7.54808 8.38586C7.25419 8.46306 6.9455 8.44735 6.65987 8.34065C6.37424 8.23396 6.12408 8.04091 5.94005 7.78519L4.23728 5.42014C4.10889 5.24798 4.014 5.05034 3.95817 4.83875C3.90233 4.62717 3.88666 4.40587 3.91207 4.18777C3.93749 3.96967 4.00347 3.75914 4.10617 3.56846C4.20888 3.37778 4.34625 3.21077 4.51027 3.07718C4.67429 2.94359 4.86167 2.84609 5.06148 2.79037C5.2613 2.73465 5.46954 2.72183 5.67407 2.75265C5.87859 2.78347 6.07529 2.85732 6.2527 2.96989C6.43011 3.08246 6.58468 3.23149 6.70737 3.40829L7.27522 4.19623C7.42749 4.40008 7.64892 4.53215 7.89182 4.56399C8.13473 4.59582 8.37967 4.52489 8.57389 4.36645C8.76811 4.20801 8.89607 3.97476 8.93021 3.71692C8.96435 3.45908 8.90194 3.19729 8.75642 2.98793Z"
            fill="#636363"
          />
          <path
            d="M14.5168 4.64643C14.534 5.09204 14.8875 5.43843 15.3063 5.42011C15.7252 5.4018 16.0507 5.02572 16.0335 4.58012L15.9097 1.37396C15.8933 0.951432 15.5734 0.613864 15.1761 0.600033L12.1887 0.496023C11.7697 0.481437 11.419 0.830959 11.4053 1.2767C11.3916 1.72244 11.7201 2.09561 12.1391 2.1102L13.3766 2.15329L11.6141 4.02839C11.3177 4.34375 11.3177 4.85504 11.6141 5.1704C11.9106 5.48576 12.3911 5.48575 12.6875 5.1704L14.464 3.28034L14.5168 4.64643Z"
            fill="#636363"
          />
          <path
            d="M1.54181 10.5608C1.96064 10.5425 2.31413 10.8889 2.33134 11.3345L2.38411 12.7006L4.1606 10.8105C4.45701 10.4951 4.93758 10.4951 5.23399 10.8105C5.5304 11.1259 5.5304 11.6371 5.23399 11.9525L3.47155 13.8276L4.70908 13.8707C5.12804 13.8853 5.45656 14.2584 5.44285 14.7042C5.42914 15.1499 5.07839 15.4995 4.65943 15.4849L1.672 15.3809C1.27474 15.367 0.95479 15.0295 0.938468 14.6069L0.814617 11.4008C0.797403 10.9552 1.12298 10.5791 1.54181 10.5608Z"
            fill="#636363"
          />
        </svg>
      )}

      {!props.isDisabled && (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.1081 9.03203L11.4053 6.66698C10.9188 5.99163 10.2254 5.51879 9.4455 5.33052C8.66555 5.14225 7.84826 5.25043 7.13545 5.63629C6.41981 6.02491 6.2146 6.53358 6.68123 7.1817C7.00614 7.6322 7.31019 7.59493 7.71553 7.46508L8.01949 7.36302C8.11248 7.33205 8.21041 7.30178 8.31557 7.27386C8.60947 7.19667 8.91815 7.21238 9.20378 7.31908C9.48941 7.42577 9.73957 7.61881 9.9236 7.87454L11.6264 10.2396C11.8752 10.5882 11.984 11.0274 11.9291 11.4612C11.8743 11.895 11.6601 12.288 11.3336 12.5542C11.007 12.8204 10.5946 12.9382 10.1867 12.8817C9.7787 12.8252 9.40838 12.5991 9.15674 12.2528L8.58835 11.4642C8.51466 11.3581 8.42186 11.2688 8.31536 11.2013C8.20887 11.1339 8.09082 11.0897 7.96809 11.0713C7.84536 11.0529 7.72042 11.0607 7.60056 11.0942C7.48069 11.1277 7.36831 11.1863 7.26995 11.2666C7.1716 11.3468 7.08925 11.4471 7.0277 11.5615C6.96616 11.676 6.92666 11.8023 6.91151 11.9332C6.89635 12.0641 6.90585 12.1968 6.93944 12.3238C6.97303 12.4507 7.03004 12.5692 7.10715 12.6725L7.67439 13.4603C7.94645 13.8435 8.28707 14.1656 8.67664 14.4082C9.0662 14.6507 9.49701 14.8089 9.94427 14.8735C10.3915 14.9382 10.8464 14.9081 11.2827 14.785C11.7189 14.6619 12.128 14.4482 12.4863 14.1561C12.8447 13.8641 13.1452 13.4996 13.3705 13.0835C13.5959 12.6674 13.7416 12.208 13.7994 11.7317C13.8572 11.2555 13.8259 10.7717 13.7072 10.3084C13.5886 9.84508 13.385 9.4113 13.1081 9.03203Z"
            fill="#2C4BFF"
          />
          <path
            d="M8.75647 2.98785L8.18924 2.2C7.91717 1.81682 7.57663 1.49403 7.18707 1.25149C6.7975 1.00894 6.36669 0.850778 5.91944 0.786103C5.47218 0.721428 5.01732 0.751519 4.58104 0.874642C4.14477 0.997766 3.73569 1.21149 3.37737 1.50351C3.01904 1.79553 2.71855 2.16007 2.4932 2.57616C2.26784 2.99224 2.12207 3.45164 2.06428 3.92791C2.00649 4.40417 2.03782 4.8879 2.15646 5.35123C2.27511 5.81456 2.47873 6.24835 2.7556 6.62761L4.45837 8.99266C4.94487 9.66802 5.63826 10.1409 6.4182 10.3291C7.19815 10.5174 8.01544 10.4092 8.72825 10.0234C9.44389 9.63473 9.6491 9.12607 9.18247 8.47794C8.85818 8.02753 8.55351 8.06472 8.14817 8.19456L7.84421 8.29663C7.75122 8.32759 7.6533 8.35787 7.54813 8.38578C7.25424 8.46298 6.94555 8.44727 6.65992 8.34057C6.3743 8.23388 6.12413 8.04083 5.9401 7.78511L4.23733 5.42006C4.10894 5.2479 4.01405 5.05026 3.95822 4.83867C3.90238 4.62709 3.88671 4.40579 3.91212 4.18769C3.93754 3.96959 4.00352 3.75906 4.10622 3.56838C4.20893 3.3777 4.3463 3.21069 4.51032 3.0771C4.67434 2.94351 4.86172 2.84601 5.06154 2.79029C5.26135 2.73457 5.46959 2.72175 5.67412 2.75257C5.87864 2.7834 6.07534 2.85724 6.25275 2.96981C6.43016 3.08238 6.58473 3.23141 6.70742 3.40821L7.27527 4.19615C7.42754 4.4 7.64897 4.53207 7.89187 4.56391C8.13478 4.59575 8.37972 4.52481 8.57394 4.36637C8.76816 4.20793 8.89612 3.97468 8.93026 3.71684C8.9644 3.459 8.90199 3.19721 8.75647 2.98785Z"
            fill="#2C4BFF"
          />
          <path
            d="M12.2907 1.92C12.2735 1.47439 11.92 1.12801 11.5012 1.14632C11.0824 1.16463 10.7568 1.54071 10.774 1.98632L10.8979 5.19247C10.9142 5.615 11.2341 5.95257 11.6314 5.9664L14.6188 6.07041C15.0378 6.08499 15.3885 5.73547 15.4022 5.28973C15.416 4.84399 15.0874 4.47082 14.6685 4.45623L13.431 4.41315L15.1934 2.53804C15.4898 2.22268 15.4898 1.71139 15.1934 1.39603C14.897 1.08068 14.4164 1.08068 14.12 1.39603L12.3435 3.28609L12.2907 1.92Z"
            fill="#2C4BFF"
          />
          <path
            d="M4.2933 14.4795C4.31051 14.9251 4.66399 15.2715 5.08282 15.2532C5.50166 15.2349 5.82723 14.8588 5.81002 14.4132L5.68617 11.2071C5.66985 10.7845 5.34989 10.447 4.95263 10.4331L1.96521 10.3291C1.54625 10.3145 1.1955 10.6641 1.18179 11.1098C1.16808 11.5555 1.4966 11.9287 1.91556 11.9433L3.15308 11.9864L1.39064 13.8615C1.09423 14.1768 1.09423 14.6881 1.39064 15.0035C1.68705 15.3189 2.16762 15.3189 2.46403 15.0035L4.24052 13.1134L4.2933 14.4795Z"
            fill="#2C4BFF"
          />
        </svg>
      )}
    </>
  )
}
