import { connect } from 'react-redux'
import { getURL } from '~publish/legacy/utils/formatters'
import ProfilesDisconnectedBanner from './components/ProfilesDisconnectedBanner'

export default connect(
  (state) => {
    let displayExtraMessage = false
    // @ts-expect-error TS(2339) FIXME: Property 'profileSidebar' does not exist on type '... Remove this comment to see the full error message
    const selectedProfile = state.profileSidebar?.selectedProfile

    if (
      selectedProfile?.service === 'instagram' &&
      selectedProfile?.service_type === 'profile'
    ) {
      displayExtraMessage = true
    }

    return {
      profileId: selectedProfile?.id,
      service: selectedProfile?.service,
      displayExtraMessage,
      // @ts-expect-error TS(2339) FIXME: Property 'organizations' does not exist on type 'D... Remove this comment to see the full error message
      canReconnectChannels: state.organizations.selected?.canReconnectChannels,
      // @ts-expect-error TS(2339) FIXME: Property 'organizations' does not exist on type 'D... Remove this comment to see the full error message
      ownerEmail: state.organizations.selected?.ownerEmail,
    }
  },
  () => ({
    onReconnectProfileClick: () => {
      window.location.assign(getURL.getAccountChannelsURL())
    },
  }),
)(ProfilesDisconnectedBanner)
