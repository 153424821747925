import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useLocation, useParams } from 'react-router-dom'

import { useSplitEnabled } from '@buffer-mono/features'

import { Text } from '@bufferapp/ui'
import { actions as composerPopoverActions } from '~publish/legacy/composer-popover'
import { BufferLoading, QueueItems } from '~publish/legacy/shared-components'

import {
  selectCampaignCounts,
  selectCampaignLoaderDetails,
} from '~publish/legacy/campaign/selectors'
import {
  type Campaign,
  CampaignTab,
  type LeanTag,
} from '~publish/legacy/campaign/types'
import type { RpcUpdate } from '~publish/legacy/post/types'
import {
  formatPostLists,
  type FormatPostListsArgs,
} from '~publish/legacy/queue/util'
import { tagsPage } from '~publish/legacy/routes'
import {
  DraftsNotInReportsBanner,
  useDismissibleBanner,
} from '~publish/legacy/utils/hooks/useDismissibleBanner'
import { actions as campaignActions } from '../../reducer'

import EmptyStateCampaign from './EmptyState'
import Header from './Header'
import { NavItems } from './NavItems'
import SkeletonPosts from './SkeletonPosts'
import { CampaignNotice, Content, LoaderWrapper, StyledMain } from './styles'

import { useAppSelector } from '../../../store'
import { IdeasListContent } from './IdeasListContent'
import { ScrollableContainer } from './ScrollableContainer'
import type { QueueItem } from '~publish/legacy/shared-components/QueueItems/types'
import { useAccount } from '~publish/legacy/accountContext'
import { SEGMENT_NAMES } from '~publish/legacy/constants'

/* Styles */

const countMapping = {
  [CampaignTab.Scheduled]: 'scheduled',
  [CampaignTab.Sent]: 'sent',
  [CampaignTab.Drafts]: 'draftsCount',
  [CampaignTab.Approvals]: 'approvalsCount',
  [CampaignTab.Ideas]: 'ideas',
}

type ViewCampaignProps = {
  campaign: Campaign
  campaignPosts: RpcUpdate[]
  hideAnalyzeReport: boolean
  showCampaignActions: boolean
  isLoading: boolean
  hideSkeletonHeader: boolean
  campaignId: string
  actions: {
    onDeleteCampaignClick: (campaign: Campaign) => void
    onEditCampaignClick: (tag: LeanTag) => void
    goToAnalyzeReport: (campaign: Campaign) => void
  }
  hasAnalyticsOnPosts?: boolean
  postActions: {
    onDeleteConfirmClick: () => void
    onSetRemindersClick: () => void
    openRemindersWizard: () => void
    onShareNowClick: () => void
    onRequeueClick: () => void
    onApproveClick: () => void
    onRequestApprovalClick: () => void
    onRevertApprovalClick: () => void
    onMovePostToDraftsClick: () => void
  }
}

type CampaignCounts = {
  scheduled: number
  sent: number
  draftsCount: number
  approvalsCount: number
}

/* Component */
const ViewCampaign = ({
  campaign,
  campaignPosts,
  isLoading,
  hideSkeletonHeader,
  hideAnalyzeReport,
  showCampaignActions,
  campaignId,
  actions,
  postActions,
  hasAnalyticsOnPosts,
}: ViewCampaignProps): JSX.Element | null => {
  const dispatch = useDispatch()
  const { tab } = useParams() as { tab?: CampaignTab }
  const counts: CampaignCounts = useSelector(selectCampaignCounts)
  const { pathname } = useLocation()
  const { loadingMore, moreToLoad, pageNumber } = useAppSelector(
    selectCampaignLoaderDetails,
  )
  const { account } = useAccount()
  const currentOrganization = account?.currentOrganization
  const showIdeas = currentOrganization?.isOneBufferOrganization

  // splits
  const { isEnabled: isWizardRemindersEnabled } =
    useSplitEnabled('CORE-reminders')

  const queueType =
    tab === CampaignTab.Scheduled || tab === CampaignTab.Sent
      ? 'post'
      : 'drafts'

  const { isDismissed, setDismissed } = useDismissibleBanner(
    DraftsNotInReportsBanner,
  )

  const items = useMemo(() => {
    // Passing just these two args with orderBy as undefined
    // is a hack that seems to work so we cast the
    // args to stop TS from complaining about it
    const args = {
      posts: campaignPosts,
      orderBy: 'due_at',
      sortOrder: tab === CampaignTab.Sent ? 'desc' : 'asc',
    } as FormatPostListsArgs
    return queueType === 'post'
      ? formatPostLists(args)
      : (campaignPosts.map((d) => ({
          ...d,
          queueItemType: 'post',
        })) as QueueItem[])
  }, [queueType, campaignPosts])

  const sentView = tab === CampaignTab.Sent

  const isIdeasTab = tab === CampaignTab.Ideas && showIdeas

  // Fetch Data
  // Adding campaignPosts.length to the dependency list will
  // unfortunately trigger a refetch after the initial fetch
  // as length will go from 0 to >0, but we need this check
  // to trigger count updates when edits to campaign items
  // happen
  // TODO: improve refetches by using state updates/pusher events

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (tab) {
      const params = {
        campaignId,
        status: tab,
        fullItems: !isIdeasTab,
        past: sentView,
        count: 100,
        pageNumber: 1,
        sortDirection: tab === CampaignTab.Sent ? 'desc' : 'asc',
        sortField: 'due_at',
      }
      dispatch(campaignActions.fetchCampaign(params))
    }
  }, [campaignId, tab])

  if (campaign && campaign.locked) {
    return <Redirect to={tagsPage.route} />
  }

  if (pathname.includes('campaigns')) {
    return <Redirect to={`/tags/${campaignId}/${tab}`} />
  }

  // Conditions
  const countName = tab ? countMapping[tab] : null
  const count = counts[countName as keyof CampaignCounts]
  const campaignHasPosts = count != null && count > 0

  const onDraftsTab = queueType === 'drafts'
  const showWarningBanner = onDraftsTab && campaignHasPosts && !isDismissed

  const onReachBottom = (): void => {
    if (moreToLoad && !loadingMore) {
      const params = {
        campaignId,
        status: tab,
        fullItems: true,
        past: sentView,
        pageNumber,
        isFetchingMore: true,
        count: 100,
        sortDirection: tab === CampaignTab.Sent ? 'desc' : 'asc',
        sortField: 'due_at',
      }
      dispatch(campaignActions.fetchCampaign(params))
    }
  }

  const onCreatePostClick = (): void => {
    dispatch(
      composerPopoverActions.openComposer({
        editMode: false,
        preSelectedTags: [campaignId],
        cta: SEGMENT_NAMES.CAMPAIGNS_CREATE_POST_CAMPAIGN_HEADER,
      }),
    )
  }

  const onEditClick = ({ postId }: { postId: string }): void => {
    dispatch(composerPopoverActions.handleEditClick(postId))
  }

  const onRescheduleClick = (draft: RpcUpdate): void => {
    dispatch(
      composerPopoverActions.handleEditClick(draft.id, { draftMode: true }),
    )
  }

  const handleShareAgainClick = (args: { post: RpcUpdate }): void => {
    dispatch(composerPopoverActions.handleShareAgainClick(args.post.id))
  }

  return (
    <ScrollableContainer
      onReachBottom={onReachBottom}
      loadingMore={loadingMore}
      pageNumber={pageNumber}
      moreToLoad={moreToLoad}
      tab={tab}
    >
      <Content>
        {/* Header */}
        <Header
          campaignDetails={campaign}
          hideAnalyzeReport={hideAnalyzeReport}
          showCampaignActions={showCampaignActions}
          onCreatePostClick={onCreatePostClick}
          onDeleteCampaignClick={actions.onDeleteCampaignClick}
          onEditCampaignClick={actions.onEditCampaignClick}
          goToAnalyzeReport={actions.goToAnalyzeReport}
          isLoading={isLoading && !hideSkeletonHeader}
        />

        {/* Navigation */}
        <NavItems campaignId={campaignId} showIdeas={showIdeas} />

        {/* Content */}
        {isLoading && !isIdeasTab && (
          <StyledMain>
            <SkeletonPosts />
          </StyledMain>
        )}

        {!isIdeasTab ? (
          <StyledMain id="main">
            {!isLoading && showWarningBanner && (
              <CampaignNotice
                className=""
                type="warning"
                dismiss={(): void => setDismissed()}
                disableAnimation
              >
                <Text type="p">Drafts won&apos;t be shared on reports.</Text>
              </CampaignNotice>
            )}
            {!isLoading && (
              <EmptyStateCampaign hideAnalyzeReport={hideAnalyzeReport} />
            )}
            {!isLoading && campaignHasPosts && (
              <QueueItems
                items={items}
                onDeleteConfirmClick={postActions.onDeleteConfirmClick}
                onSetRemindersClick={
                  isWizardRemindersEnabled
                    ? postActions.openRemindersWizard
                    : postActions.onSetRemindersClick
                }
                onEditClick={onEditClick}
                onShareNowClick={postActions.onShareNowClick}
                onRequeueClick={postActions.onRequeueClick}
                onRescheduleClick={onRescheduleClick}
                onApproveClick={postActions.onApproveClick}
                onRequestApprovalClick={postActions.onRequestApprovalClick}
                onRevertApprovalClick={postActions.onRevertApprovalClick}
                onMovePostToDraftsClick={postActions.onMovePostToDraftsClick}
                type={queueType}
                hasAnalyticsOnPosts={hasAnalyticsOnPosts}
                onShareAgainClick={handleShareAgainClick}
                shouldHideCreatePostButton
                isCampaign
                pinned={false}
                shouldRenderCalendarButtons={false}
              />
            )}
            {loadingMore && (
              <LoaderWrapper>
                <BufferLoading size={32} />
              </LoaderWrapper>
            )}
          </StyledMain>
        ) : (
          <IdeasListContent campaignId={campaignId} />
        )}
      </Content>
    </ScrollableContainer>
  )
}

ViewCampaign.defaultProps = {
  hasAnalyticsOnPosts: false,
}

export default ViewCampaign
