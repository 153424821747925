import styled, { css } from 'styled-components'
import { grayLight } from '@bufferapp/ui/style/colors'
import { previewBlack, previewGrayMedium } from '../../../Common/styles'

const fontCss = css`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 13px;
`

export const Body = styled.div`
  margin: 24px 16px;
`

export const Date = styled.div`
  ${fontCss}
  color: ${previewGrayMedium};
  margin-bottom: 8px;
`

export const Content = styled.div`
  display: flex;
  align-items: flex-start;
`

export const TextWrapper = styled.div`
  ${fontCss}
  flex: 1;
  font-size: 15px;
  line-height: 23px;
  color: ${previewBlack};
`

export const Text = styled.div`
  word-wrap: break-word;
`

export const SeeMore = styled.div`
  display: inline;
  color: ${previewGrayMedium};
  font-size: 14px;
  line-height: 17px;
`

export const Link = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  gap: 10px;

  background: ${grayLight};
  color: ${previewBlack};
  border-radius: 4px;
  text-decoration: none;
  margin-top: 12px;
`

export const ImageOld = styled.img`
  object-fit: cover;
  width: 30%;
  border-radius: 4px;
`

export const Image = styled.div<{ src: string }>`
  border-radius: 4px;
  margin-left: 16px;
  width: 30%;
  height: 0;
  padding-bottom: 30%;
  object-fit: cover;
  float: right;
  background: url(${({ src }) => src}) center no-repeat;
  background-size: cover;
`
