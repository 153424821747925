import { pdfjs } from 'react-pdf'

type CanvasAndContext = {
  canvas: HTMLCanvasElement | null
  context: CanvasRenderingContext2D | null
}

class NodeCanvasFactory {
  create(width: number, height: number): CanvasAndContext {
    const canvas = document.createElement('canvas')
    canvas.width = width
    canvas.height = height
    const context = canvas.getContext('2d')
    if (!context) {
      throw new Error('Canvas Context is not defined')
    }
    return {
      canvas,
      context,
    }
  }

  reset(
    canvasAndContext: CanvasAndContext,
    width: number,
    height: number,
  ): void {
    if (!canvasAndContext.canvas) {
      return
    }
    canvasAndContext.canvas.width = width
    canvasAndContext.canvas.height = height
  }

  destroy(canvasAndContext: CanvasAndContext): void {
    // Zeroing the width and height cause Firefox to release graphics
    // resources immediately, which can greatly reduce memory consumption.
    if (canvasAndContext.canvas) {
      canvasAndContext.canvas.width = 0
      canvasAndContext.canvas.height = 0
    }
    canvasAndContext.canvas = null
    canvasAndContext.context = null
  }
}

export async function generateThumbnailFromPDF(
  pdfFile: Blob | File,
): Promise<File> {
  const canvasFactory = new NodeCanvasFactory()

  const data = new Uint8Array(await pdfFile.arrayBuffer())

  const loadingTask = pdfjs.getDocument({
    data,
    canvasFactory,
  })

  const pdfDocument = await loadingTask.promise
  // Get the first page.
  const page = await pdfDocument.getPage(1)
  // Render the page on a Node canvas with 100% scale.
  const THUMBNAIL_WIDTH = 300
  let viewport = page.getViewport({ scale: 1 })
  viewport = page.getViewport({
    scale: THUMBNAIL_WIDTH / viewport.width,
  })
  const canvasAndContext = canvasFactory.create(viewport.width, viewport.height)

  if (!canvasAndContext.context) {
    throw new Error('Canvas Context is not defined')
  }
  const safeRenderContext = {
    canvasContext: canvasAndContext.context,
    viewport,
  }

  const renderTask = page.render(safeRenderContext)
  await renderTask.promise

  return new Promise((resolve, reject) => {
    if (!canvasAndContext.canvas) {
      reject(new Error('Canvas Context is not defined'))
      return
    }
    canvasAndContext.canvas.toBlob(
      (blob) => {
        if (!blob) {
          reject(new Error('Blob is not defined'))
          return
        }
        const thumbnailName = pdfFile.name.replace(/\.[^/.]+$/, '.jpeg')
        const thumbnail = new File([blob], thumbnailName, {
          type: blob.type,
        })
        page.cleanup()
        resolve(thumbnail)
      },
      'image/jpeg',
      0.75 /* quality */,
    )
  })
}
