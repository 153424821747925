import React from 'react'

import * as Styles from './styles'

export const Empty = () => (
  <Styles.Wrapper>
    <Styles.ImageWrapper>
      <Styles.Image
        src="https://buffer-publish.s3.amazonaws.com/images/post-preview-generic-inv.png"
        aria-hidden="true"
      />
      <Styles.Message>See your post&apos;s preview here</Styles.Message>
    </Styles.ImageWrapper>
  </Styles.Wrapper>
)
