import React, { useMemo, useState } from 'react'

import {
  Avatar,
  Badge,
  Button,
  ChannelIcon,
  ChevronDownIcon,
  Combobox,
  EmptyState,
  Flex,
  GlobeIcon,
  MapPinIcon,
  SearchIcon,
  Text,
  Tooltip,
  useControllableState,
  VisuallyHidden,
} from '@buffer-mono/popcorn'

import { getCurrentTimeZone } from '~publish/helpers/dateFormatters'

import { useChannels } from '../PageSidebar/useChannels'
import filterTimezones, { TIMEZONES } from './timezone-filter'

import styles from './TimeZoneSelect.module.css'

const TIMEZONES_LIST = Object.values(TIMEZONES)
  .sort((a, b) => a.city.localeCompare(b.city))
  .map(({ id }) => id)

export type TimeZoneSelectProps = {
  value?: string
  onChange?: (value: string) => void
  defaultValue?: string
  disabled?: boolean
  style?: React.CSSProperties
  className?: string
  size?: React.ComponentProps<typeof Button>['size']
  variant?: React.ComponentProps<typeof Button>['variant']
}

export function TimeZoneSelect({
  disabled,
  onChange,
  value,
  defaultValue,
  size,
  variant,
  ...props
}: TimeZoneSelectProps): React.ReactElement {
  const [internalValue, setInternalValue] = useControllableState({
    prop: value ?? getCurrentTimeZone(),
    defaultProp: defaultValue,
    onChange,
  })
  const { channels } = useChannels()
  const [inputValue, setInputValue] = useState('')
  const timeZoneSuggestions = useMemo(() => {
    if (inputValue) return []

    return [
      getCurrentTimeZone(),
      ...(channels ?? [])
        .map(({ timezone }) => timezone)
        .filter((timezone) => TIMEZONES_LIST.includes(timezone))
        .sort((a, b) => TIMEZONES[a].city.localeCompare(TIMEZONES[b].city)),
    ].filter((timezone, index, self) => self.indexOf(timezone) === index) // Remove duplicates
  }, [channels, inputValue])

  const timeZones = useMemo(() => {
    return TIMEZONES_LIST.filter((id) => !timeZoneSuggestions.includes(id))
  }, [timeZoneSuggestions])

  const handleComboboxChange = (value: string): void => {
    setInternalValue(value)
  }

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    setInputValue(event.target.value)
  }

  const handleOpenChange = (open: boolean): void => {
    if (!open) {
      setInputValue('')
    }
  }

  return (
    <Combobox
      onChange={handleComboboxChange}
      multiple={false}
      value={internalValue}
      filter={filterTimezones}
      onOpenChange={handleOpenChange}
      {...props}
    >
      <Combobox.Trigger>
        <Button
          disabled={disabled}
          size={size}
          variant={variant}
          data-testid="timezone-form-button"
        >
          <GlobeIcon color="subtle" />
          <VisuallyHidden>Timezone</VisuallyHidden>
          {TIMEZONES[internalValue ?? ''].city}
          <ChevronDownIcon />
        </Button>
      </Combobox.Trigger>
      <Combobox.Content className={styles.content}>
        <Combobox.Input
          placeholder="Search cities or timezones"
          prefix={<SearchIcon />}
          onChange={handleInputChange}
          value={inputValue}
        />
        <Combobox.List>
          <Combobox.Empty>
            <EmptyState size="small">
              <EmptyState.Icon>
                <SearchIcon />
              </EmptyState.Icon>
              <EmptyState.Description>
                No matching time zones found
              </EmptyState.Description>
            </EmptyState>
          </Combobox.Empty>
          <Combobox.Group heading="Suggestions">
            {timeZoneSuggestions.length > 0 &&
              timeZoneSuggestions.map((id) => (
                <TimeZoneSelectItem timezone={id} key={id} />
              ))}
          </Combobox.Group>
          <Combobox.Separator />
          <Combobox.Group heading={inputValue ? undefined : 'All timezones'}>
            {timeZones.map((id) => (
              <TimeZoneSelectItem timezone={id} key={id} />
            ))}
          </Combobox.Group>
        </Combobox.List>
      </Combobox.Content>
    </Combobox>
  )
}

function TimeZoneSelectItem({
  timezone,
}: {
  timezone: string
}): React.ReactElement {
  const { channels } = useChannels()
  const { id, longOffset, city } = TIMEZONES[timezone]

  const channelsInTimezone = useMemo(
    () => channels?.filter(({ timezone }) => timezone === id),
    [channels, id],
  ).reverse()

  const isBrowserTimezone = id === getCurrentTimeZone()

  const tooltipContent = (
    <Flex direction="column" gap="xs">
      {isBrowserTimezone && (
        <Flex gap="xs" align="center">
          <MapPinIcon size="xsmall" />
          <Text>Browser detected timezone</Text>
        </Flex>
      )}
      {channelsInTimezone.slice(0, 5).map((channel) => (
        <Flex gap="xs" key={channel.id} align="center">
          <ChannelIcon
            type={channel.service}
            size="xsmall"
            style={{ color: 'currentColor' }}
          />
          {channel.name}
        </Flex>
      ))}
      {channelsInTimezone.length > 5 && (
        <Text size="sm">+{channelsInTimezone.length - 5} more...</Text>
      )}
    </Flex>
  )

  const TooltipWrapper = ({
    children,
    ...props
  }: React.ComponentPropsWithoutRef<typeof Tooltip>): React.ReactElement => {
    if (!isBrowserTimezone && channelsInTimezone.length === 0) {
      return <>{children}</>
    }
    return (
      <Tooltip {...props} side="left" sideOffset={50}>
        {children}
      </Tooltip>
    )
  }

  // Remove leading zero from offset
  const offset = longOffset.replace(/([+-])0+/, '$1')

  return (
    <Combobox.Item key={id} value={id}>
      <TooltipWrapper content={tooltipContent}>
        <Flex
          justify="between"
          align="center"
          gap="2xs"
          className={styles.fullWidth}
        >
          {city}{' '}
          <Text color="subtle" size="sm">
            ({offset})
          </Text>
          <Flex className={styles.badgesList} gap="sm">
            {isBrowserTimezone && (
              <Badge size="xsmall" className={styles.browserBadge}>
                <MapPinIcon />
              </Badge>
            )}
            {channelsInTimezone.length > 0 && (
              <Flex direction="row-reverse">
                {channelsInTimezone.length > 2 && (
                  <Badge size="xsmall" className={styles.moreChannelsBadge}>
                    +{channelsInTimezone.length - 2}
                  </Badge>
                )}
                {channelsInTimezone.slice(0, 2).map((channel) => (
                  <div className={styles.channelAvatar} key={channel.id}>
                    <Avatar
                      alt={channel.service}
                      src={channel.avatar}
                      size="xsmall"
                      key={channel.id}
                    />
                  </div>
                ))}
              </Flex>
            )}
          </Flex>
        </Flex>
      </TooltipWrapper>
    </Combobox.Item>
  )
}
