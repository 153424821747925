import React from 'react'

import styles from './PageHeader.module.css'

type HeaderProps = {
  children: React.ReactNode
}

/**
 * @deprecated Use `PageLayout` instead
 */
const PageHeader = ({ children }: HeaderProps): JSX.Element => {
  return (
    <div className={styles.headerWrapper}>
      <header className={styles.header}>{children}</header>
    </div>
  )
}

const FirstRow = ({ children }: { children: React.ReactNode }): JSX.Element => (
  <div className={styles.firstRow}>{children}</div>
)

const Title = ({ children }: { children: React.ReactNode }): JSX.Element => (
  <div className={styles.headerTitle}>{children}</div>
)

const SecondRow = ({
  children,
}: {
  children: React.ReactNode
}): JSX.Element => <div className={styles.secondRow}>{children}</div>

const HeaderObject = Object.assign(PageHeader, {
  FirstRow,
  Title,
  SecondRow,
})

export { HeaderObject as PageHeader }
