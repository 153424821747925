import { createDraftSafeSelector } from '@reduxjs/toolkit'
import { createSelector } from 'reselect'
import type { RootState } from '../store'
import type { Profile, Schedule } from './types'
import {
  mergeSchedules,
  transformSchedules,
} from '../posting-schedule/utils/transformSchedule'
import type { Day } from '../posting-schedule/types'

// @ts-expect-error TS(7006) FIXME: Parameter 'channelId' implicitly has an 'any' type... Remove this comment to see the full error message
export const selectIsManagerInChannel = (channelId) => (state) =>
  state?.profileSidebar?.profiles.some(
    // @ts-expect-error TS(7006) FIXME: Parameter 'profile' implicitly has an 'any' type.
    (profile) => profile.id === channelId && profile.isManager,
  )

export const selectIsManagerInProfileById = (
  // @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
  state,
  profileId: string,
): boolean => {
  return state?.profileSidebar?.profiles.some(
    // @ts-expect-error TS(7006) FIXME: Parameter 'profile' implicitly has an 'any' type.
    (profile) => profile.id === profileId && profile.isManager,
  )
}

export const selectCurrentProfileId = (state: RootState): string =>
  state?.profileSidebar?.selectedProfileId

export const selectCurrentProfile = (state: RootState): Profile | undefined => {
  const profileId = selectCurrentProfileId(state)
  return state.profileSidebar.profiles.find((prof) => prof.id === profileId)
}

export const selectProfiles = (state: RootState): Profile[] => {
  return state?.profileSidebar?.profiles || []
}

export const selectProfileById = createSelector(
  [selectProfiles, (_: RootState, profileId: string): string => profileId],
  (profiles, profileId): Profile | undefined => {
    return profiles?.find((prof) => prof.id === profileId)
  },
)

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
export const isSelectedProfileDisconnected = (state): boolean => {
  return state.profileSidebar.selectedProfile.isDisconnected
}

export const selectProfileService =
  (profileId: string) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
  (state): string => {
    const profile = state?.profileSidebar?.profiles?.find(
      // @ts-expect-error TS(7006) FIXME: Parameter 'p' implicitly has an 'any' type.
      (p) => p.id === profileId,
    )
    return profile?.service
  }

export const selectProfileServiceType =
  (profileId: string) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
  (state): string => {
    const profile = state?.profileSidebar?.profiles?.find(
      // @ts-expect-error TS(7006) FIXME: Parameter 'p' implicitly has an 'any' type.
      (p) => p.id === profileId,
    )
    return profile?.service_type
  }

export const selectShouldShowLinkedinProfileAnalyticsUpgradeBanner =
  (profileId: string) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
  (state): string => {
    const profile = state?.profileSidebar?.profiles?.find(
      // @ts-expect-error TS(7006) FIXME: Parameter 'p' implicitly has an 'any' type.
      (p) => p.id === profileId,
    )
    return profile?.shouldShowLinkedinProfileAnalyticsUpgradeBanner
  }

export const selectedProfileServiceName = (state: RootState): string => {
  return state?.profileSidebar?.selectedProfile?.service ?? ''
}

export const selectHasProfiles = (state: RootState): boolean => {
  return selectProfiles(state).length !== 0
}

type NotificationSelector = (state: RootState, profileIds: string[]) => boolean
export const selectNotificationsEnabled: NotificationSelector =
  createDraftSafeSelector(
    (state: RootState) => selectProfiles(state),
    (state: RootState, profileIds?: string[]) => profileIds,
    (profiles, profileIds) => {
      if (!profileIds) return false

      const selectedProfiles = profiles.filter((prof) =>
        profileIds.includes(prof.id),
      )

      return selectedProfiles.some(
        (profile) => !profile?.isDisconnected && profile?.hasPushNotifications,
      )
    },
  )

export const selectDefaultToReminders = createSelector(
  selectProfileById,
  (profile): boolean => {
    return profile?.defaultToReminders ?? false
  },
)

export const selectIsTwitterPremium = createSelector(
  selectProfileById,
  (profile): boolean => {
    return profile?.isTwitterPremium ?? false
  },
)

export const selectProfileTimezone = createSelector(
  selectProfileById,
  (profile): string | undefined => {
    return profile?.timezone
  },
)

export const selectCurrentProfilePausedSchedules = createSelector(
  selectCurrentProfile,
  (profile): Schedule[] => {
    if (!profile) return []
    return profile.pausedSchedules
  },
)

export const selectCurrentProfileMergedSchedules = createSelector(
  selectCurrentProfile,
  (profile): Schedule[] => {
    if (!profile) return []
    const mergedSchedules = mergeSchedules(
      profile.schedules,
      profile.pausedSchedules,
    )
    return mergedSchedules
  },
)

export const selectCurrentProfilePostingDays = createSelector(
  [selectCurrentProfile, selectCurrentProfileMergedSchedules],
  (profile, mergedSchedules): Day[] => {
    if (!profile) return []
    const days = transformSchedules(mergedSchedules, profile.pausedSchedules)
    return days
  },
)

export const selectCurrentProfilePostingDaysIsEmpty = createSelector(
  selectCurrentProfilePostingDays,
  (days): boolean => {
    return days.filter((day) => day.postingTimesTotal > 0).length < 1
  },
)

export const selectCurrentProfileTimezone = createSelector(
  selectCurrentProfile,
  (profile): string | undefined => {
    return profile?.timezone
  },
)
