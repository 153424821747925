import { connect } from 'react-redux'
import HashtagGroupWrapper from './components/HashtagGroupWrapper'
import { actions } from './reducer'

export default connect(
  (state) => ({
    // @ts-expect-error TS(2339) FIXME: Property 'hashtagGroups' does not exist on type 'D... Remove this comment to see the full error message
    hashtagGroups: state.hashtagGroups.groups,
    // @ts-expect-error TS(2339) FIXME: Property 'hashtagGroups' does not exist on type 'D... Remove this comment to see the full error message
    name: state.hashtagGroups.name,
    // @ts-expect-error TS(2339) FIXME: Property 'hashtagGroups' does not exist on type 'D... Remove this comment to see the full error message
    text: state.hashtagGroups.text,
    // @ts-expect-error TS(2339) FIXME: Property 'hashtagGroups' does not exist on type 'D... Remove this comment to see the full error message
    snippetId: state.hashtagGroups.snippetId,
    // @ts-expect-error TS(2339) FIXME: Property 'profileSidebar' does not exist on type '... Remove this comment to see the full error message
    organizationId: state.profileSidebar.selectedProfile.organizationId,
  }),
  (dispatch, ownProps) => ({
    onCancelHashtagGroup: () => {
      dispatch(actions.handleCancelHashtagGroupClick())
    },
    onSaveHashtagGroup: () => {
      dispatch(actions.handleSaveHashtagGroupClick())
    },
    onUpdateHashtagGroup: () => {
      dispatch(actions.handleUpdateHashtagGroupClick())
    },
    // @ts-expect-error TS(7006) FIXME: Parameter 'name' implicitly has an 'any' type.
    onDeleteHashtagGroup: (name, text, snippetId) => {
      dispatch(
        actions.handleDeleteHashtagGroup({
          name,
          text,
          snippetId,
        }),
      )
    },
    // @ts-expect-error TS(7006) FIXME: Parameter 'name' implicitly has an 'any' type.
    onEditHashtagGroup: (name, text, snippetId) => {
      dispatch(
        actions.handleEditHashtagGroup({
          name,
          text,
          snippetId,
        }),
      )
    },
    // @ts-expect-error TS(7006) FIXME: Parameter 'name' implicitly has an 'any' type.
    onChangeGroupName: (name) => {
      dispatch(
        actions.handleChangeGroupName({
          name,
        }),
      )
    },
    // @ts-expect-error TS(7006) FIXME: Parameter 'text' implicitly has an 'any' type.
    onChangeGroupText: (text) => {
      dispatch(
        actions.handleChangeGroupText({
          text,
        }),
      )
    },
    // @ts-expect-error TS(7006) FIXME: Parameter 'name' implicitly has an 'any' type.
    onHandleInsertHashtagGroupClick: (name, text) => {
      // @ts-expect-error TS(2339) FIXME: Property 'onInsertHashtagGroupClick' does not exis... Remove this comment to see the full error message
      if (ownProps.onInsertHashtagGroupClick) {
        // @ts-expect-error TS(2339) FIXME: Property 'onInsertHashtagGroupClick' does not exis... Remove this comment to see the full error message
        ownProps.onInsertHashtagGroupClick(text)
      }
      dispatch(actions.handleInsertHashtagGroupClick({ name, text }))
    },
  }),
)(HashtagGroupWrapper)

export { default as reducer, actions, actionTypes } from './reducer'
export { default as middleware } from './middleware'
