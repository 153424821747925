import { useCallback } from 'react'

import { useMutation } from '@apollo/client'

import { logError } from '~publish/legacy/utils/logError'
import { graphql } from '~publish/gql'
import type { UpdateIdeaGroupMutation } from '~publish/gql/graphql'

export const UpdateIdeaGroup = graphql(/* GraphQL */ `
  mutation UpdateIdeaGroup($id: ID!, $name: String!) {
    updateIdeaGroup(input: { id: $id, name: $name }) {
      ... on IdeaGroup {
        id
        name
      }
      ... on MutationError {
        message
      }
    }
  }
`)

const useUpdateIdeaGroup = (id: string): ((name: string) => Promise<void>) => {
  const [updateIdeaGroup] = useMutation(UpdateIdeaGroup, {
    optimisticResponse: (variables) => ({
      updateIdeaGroup: {
        __typename: 'IdeaGroup' as const,
        id,
        name: variables.name,
      },
    }),
  })

  return useCallback(
    async (name: string) => {
      try {
        const { data } = await updateIdeaGroup({
          variables: { id, name: name.trim() },
        })
        if (isError(data?.updateIdeaGroup)) {
          throw new Error(
            data?.updateIdeaGroup.message ?? 'Unexpected API response',
          )
        }
      } catch (error) {
        logError(error as Error, {
          metaData: { variables: { id, name: name.trim() } },
        })
        throw error
      }
    },
    [id, updateIdeaGroup],
  )
}

const isError = (
  response: UpdateIdeaGroupMutation['updateIdeaGroup'] | null | undefined,
): response is Exclude<
  UpdateIdeaGroupMutation['updateIdeaGroup'],
  { __typename?: 'IdeaGroup' }
> => {
  return !response || response?.__typename !== 'IdeaGroup'
}

export default useUpdateIdeaGroup
