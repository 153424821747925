/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from 'react'
import { PostEntity } from '~publish/legacy/post/PostEntity'
import { usePostDetails } from '~publish/legacy/post/hooks/usePostDetails'

import { useSplitEnabled } from '@buffer-mono/features'
import Button from '@bufferapp/ui/Button'
import { Flash } from '@bufferapp/ui/Icon'
import { useAccount } from '~publish/legacy/accountContext'
import { Services } from '~publish/legacy/constants'
import Card from '../Card'
import CardHeader from '../CardHeader'
import PostErrorBanner from '../PostErrorBanner'
import PostFooter from '../PostFooter'
import PostPendingBanner from '../PostPendingBanner'
import PostStats from '../PostStats'
import PostTags from '../PostTags'
import UpdateContent from '../UpdateContent'
import RenderPostMetaBar from './RenderPostMetaBar'
import {
  PostContainer,
  PostContent,
  PostSubContainer,
  StatsContainer,
  UpgradeCtaStyled,
} from './style'
import type { RpcUpdate, RpcUpdateStatistics } from '~publish/legacy/post/types'
import type { ChannelData } from '~publish/legacy/composer/composer/entities/Draft'
import type { CampaignDetails } from '~publish/legacy/campaign/types'

const FREE_POST_ANALYTICS_SERVICES = [
  'youtube',
  'mastodon',
  'twitter',
  'tiktok',
  'linkedin',
]

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const UpgradeCta = () => {
  const { account } = useAccount()
  const [canStartTrial, setCanStartTrial] = useState(false)
  const [isFreeUser, setIsFreeUser] = useState(false)
  useEffect(() => {
    if (account?.currentOrganization?.billing) {
      const isFree =
        account?.currentOrganization?.billing?.subscription?.plan?.id === 'free'
      setCanStartTrial(account?.currentOrganization?.billing?.canStartTrial)
      setIsFreeUser(isFree)
    }
  }, [account.currentOrganization.billing])

  if (isFreeUser) {
    return (
      <UpgradeCtaStyled>
        {/* @ts-expect-error TS(2740) FIXME: Type '{ fullWidth: true; type: string; icon: Eleme... Remove this comment to see the full error message */}
        <Button
          fullWidth
          type="secondary"
          icon={<Flash />}
          label={
            canStartTrial
              ? 'Start a trial to unlock your insights'
              : 'Upgrade to unlock your insights'
          }
          // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
          onClick={() => {
            const { actions, MODALS } = window?.appshell || {}
            actions.openModal(
              canStartTrial ? MODALS.startTrial : MODALS.planSelector,
              {
                cta: canStartTrial
                  ? 'sentPosts-advMetrics-startTrial-1'
                  : 'sentPosts-advMetrics-upgrade-1',
                ctaButton: canStartTrial
                  ? 'sentPosts-advMetrics-startTrial-1'
                  : 'sentPosts-advMetrics-upgrade-1',
                upgradePathName: canStartTrial
                  ? 'sentPosts-trial'
                  : 'sentPosts-upgrade',
              },
            )
          }}
        />
      </UpgradeCtaStyled>
    )
  }

  return null
}

export interface PostProps {
  isConfirmingDelete: boolean
  isDeleting: boolean
  isWorking: boolean
  onRequeueClick: () => void
  onDeleteConfirmClick: () => void
  onEditClick: () => void
  onShareNowClick: () => void
  onShareAgainClick: () => void
  onShareLinkClick: () => void
  onMovePostToDraftsClick?: () => void
  draggable: boolean
  dragging: boolean
  isOver: boolean
  onDropPost: () => void
  serviceLink: string
  isManager: boolean
  day: string
  post: RpcUpdate & { workingMessage?: string }
  basic: boolean
  shouldShowEditButton: boolean
  hasAnalyticsOnPosts?: boolean
  channelData: ChannelData
  isCampaign: boolean
  locationName: string
  sourceUrl: string
  subprofileID: string
  subprofiles: Array<Record<string, unknown>>
  statistics: RpcUpdateStatistics
  profileService: string
  profileServiceType: string
  commentEnabled: boolean
  commentText: string
  hasCommentEnabled: boolean
  hasPushNotifications: boolean
  onSetRemindersClick: () => void
  hasUserTags: boolean
  campaignDetails: CampaignDetails
  onCampaignTagClick: () => void
  hasCampaignsFeature: boolean
  postContent: Record<string, unknown>
  annotations: Array<Record<string, unknown>>
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
const Post = ({
  isConfirmingDelete,
  isDeleting,
  isWorking,
  onDeleteConfirmClick,
  onEditClick,
  onShareNowClick,
  onShareAgainClick,
  onShareLinkClick,
  onRequeueClick,
  locationName,
  sourceUrl,
  subprofileID,
  subprofiles,
  draggable,
  dragging,
  isOver,
  statistics,
  profileService,
  profileServiceType,
  serviceLink,
  isManager,
  basic,
  commentText,
  hasCommentEnabled,
  hasPushNotifications,
  onSetRemindersClick,
  hasUserTags,
  hasCampaignsFeature,
  postContent,
  annotations,
  shouldShowEditButton,
  hasAnalyticsOnPosts,
  channelData,
  isCampaign,
  post,
}: PostProps): JSX.Element => {
  const { isEnabled: isRemindersEnabled } = useSplitEnabled('CORE-reminders')

  // There is not perfect way to know if an old reminder is effectively a reminder.
  // For future reminders, we can use the field isReminder
  const isReminder = isRemindersEnabled
    ? PostEntity.isReminder(post) ?? PostEntity.isInstagramReminder(post)
    : PostEntity.isInstagramReminder(post)
  const isReminderBadgeVisible = isRemindersEnabled && isReminder

  const isSent = PostEntity.isSent(post)
  const isPastReminder = PostEntity.isPastReminder(post)
  const { postAction, ...headerDetails } = usePostDetails(
    post,
    isCampaign,
    isReminder,
  )
  const hasError = PostEntity.hasError(post)
  const hasReminderError =
    !isSent &&
    !hasError &&
    !hasPushNotifications &&
    isReminder &&
    !isPastReminder
  const showPendingTiktokBanner =
    isCampaign && !hasError && PostEntity.isPendingTiktok(post)
  const cardDetails = {
    faded: isDeleting,
    dragging,
    isOver,
  }

  // StartPage is our only native service at the moment.
  // It does not require an paid plan for unlocking the post insights. They are available in all plans.
  const isNativeService = profileService === 'startPage'
  const hasFreeAnalytics = FREE_POST_ANALYTICS_SERVICES.some(
    (service) => profileService === service,
  )

  hasAnalyticsOnPosts =
    isNativeService || hasAnalyticsOnPosts || hasFreeAnalytics
  return (
    <PostContainer data-cy="post" data-testid="post" draggable={draggable}>
      <PostSubContainer>
        <Card
          state={cardDetails}
          isReminderBadgeVisible={isReminderBadgeVisible}
        >
          {hasReminderError && (
            <PostErrorBanner
              dragging={dragging}
              errorLabel="Set Up Reminders"
              error="We aren't able to publish this post until you set up mobile notifications. Want to set them up now?"
              errorAction={onSetRemindersClick}
            />
          )}
          {showPendingTiktokBanner && (
            <PostPendingBanner
              // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
              serviceName={Services.get(profileService).formattedName}
            />
          )}
          {hasError && (
            <PostErrorBanner
              dragging={dragging}
              error={PostEntity.getError(post)}
              errorLabel={PostEntity.getErrorLabel(post)}
              errorLink={PostEntity.getErrorLink(post)}
            />
          )}

          <CardHeader
            postId={post.id}
            headerDetails={headerDetails}
            notes={post.notes}
          />

          <PostContent dragging={dragging}>
            <UpdateContent
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...postContent}
              profileService={profileService}
              basic={basic}
              channelData={channelData}
              annotations={annotations}
              via={post?.via || 'Buffer'}
              media={post.media}
              extraMedia={post.extra_media}
            />
          </PostContent>
          <PostTags
            tags={post.tags || []}
            dragging={dragging}
            isSent={isSent}
          />
          <RenderPostMetaBar
            profileService={profileService}
            dragging={dragging}
            locationName={locationName}
            sourceUrl={sourceUrl}
            subprofileID={subprofileID}
            subprofiles={subprofiles}
            isSent={isSent}
            isPastReminder={isPastReminder}
          />
          {isSent &&
            !PostEntity.isRetweet(post) &&
            post.updateType !== 'facebook_story' &&
            PostEntity.getChannelName(post) !== 'googlebusiness' && (
              <StatsContainer>
                {!hasFreeAnalytics &&
                  !hasAnalyticsOnPosts &&
                  !isNativeService && <UpgradeCta />}
                <PostStats
                  hasAnalyticsOnPosts={hasAnalyticsOnPosts}
                  statistics={statistics}
                  profileService={profileService}
                  profileServiceType={profileServiceType}
                  profileId={PostEntity.getChannelId(post)}
                />
              </StatsContainer>
            )}
          <PostFooter
            post={post}
            postId={PostEntity.getId(post)}
            profileId={PostEntity.getChannelId(post)}
            isManager={isManager}
            isDeleting={isDeleting}
            isConfirmingDelete={isConfirmingDelete}
            isWorking={isWorking}
            onDeleteConfirmClick={onDeleteConfirmClick}
            onEditClick={onEditClick}
            onShareAgainClick={onShareAgainClick}
            onShareLinkClick={onShareLinkClick}
            onShareNowClick={onShareNowClick}
            postAction={postAction}
            dragging={dragging}
            onRequeueClick={onRequeueClick}
            threadCounter={Array.isArray(post.thread) ? post.thread.length : 0}
            serviceLink={serviceLink}
            isSent={isSent}
            isPastReminder={isPastReminder}
            commentText={commentText}
            hasCommentEnabled={hasCommentEnabled}
            hasCampaignsFeature={hasCampaignsFeature}
            hasUserTags={hasUserTags}
            shouldShowEditButton={shouldShowEditButton}
            isReminder={isReminder}
          />
        </Card>
      </PostSubContainer>
    </PostContainer>
  )
}

export default Post
