import styled from 'styled-components'

import { VerticalWrapperStyles } from '~publish/legacy/ai/styles/VerticalWrapper'

export const Wrapper = styled.div`
  padding: 24px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  box-sizing: border-box;
  height: 100%;
  overflow-y: none;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding: 0px 48px 0px 32px;
  flex-shrink: 0;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 24px;
  flex-grow: 1;
  overflow-y: auto;
`

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
  margin: 16px 0px;
`

export const SelectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 8px;
`

export const WriteMoreWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 16px;
`

export const IdleWrapper = styled.div`
  ${VerticalWrapperStyles}
`
