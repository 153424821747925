import { insertText as insertTextTransform } from '@udecode/plate'

import type { BufferEditor } from '~publish/legacy/editor/BufferEditor/types.plate'
import { withoutLosingSelection } from '~publish/legacy/editor/helpers/withoutLosingSelection'
import { Url } from '../../helpers/Url'
import { replaceLinkWithShortLink } from './commands/replaceLinkWithShortLink'
import { replaceLinkWithUnshortenedLink } from './commands/replaceLinkWithUnshortenedLink'
import { getAllLinkNodes } from './nodes/getAllLinkNodes'
import { LinkElement } from './nodes/LinkElement'
import { findAndWrapNewLinks } from './transforms/findAndWrapNewLinks'
import { toggleShortening } from './transforms/toggleShortening'
import { unwrapLink } from './transforms/unwrapLink'

export const withLinks = (editor: BufferEditor): BufferEditor => {
  const {
    insertText,
    insertBreak,
    insertSoftBreak,
    insertData,
    normalizeNode,
    deleteBackward,
  } = editor

  editor.replaceLinkWithShortLink = replaceLinkWithShortLink
  editor.replaceLinkWithUnshortenedLink = replaceLinkWithUnshortenedLink
  editor.findAndWrapNewLinks = findAndWrapNewLinks
  editor.getAllLinkNodes = getAllLinkNodes

  editor.insertBreak = () => {
    const selectionBeforeBreak = editor.selection || undefined
    insertBreak()
    findAndWrapNewLinks(editor, { at: selectionBeforeBreak })
  }

  editor.insertSoftBreak = () => {
    const selectionBeforeBreak = editor.selection || undefined
    insertSoftBreak()
    findAndWrapNewLinks(editor, { at: selectionBeforeBreak })
  }

  editor.insertText = (text) => {
    if (text === ' ') {
      insertText(text)
      findAndWrapNewLinks(editor)

      return
    }

    insertText(text)
  }

  editor.deleteBackward = (unit) => {
    deleteBackward(unit)

    // handling backspace is tricky, we should only try to wrap links when
    // the deleted character is a space which could "form" a new link
    // i.e. google .es -> google.es
    // doing it with other characters breaks typing a link with a typo and trying to fix it
    // i.e. google.cot -> google.co should not yet be highlighted
    // if (editor.selection) findAndWrapNewLinks(editor, { at: editor.selection });
  }

  editor.normalizeNode = ([node, path]) => {
    if (LinkElement.is(node)) {
      if (!Url.isValid(node.url) || LinkElement.isEmpty(node)) {
        // we no longer want a link node if the url is not valid
        unwrapLink(editor, path)

        return
      }

      if (LinkElement.requiresTextUpdate(node)) {
        withoutLosingSelection(editor, () => {
          insertTextTransform(editor, LinkElement.getText(node), {
            at: path,
          })
        })

        return
      }

      if (LinkElement.hasTextMismatch(node)) {
        // The child text node of a link can be edited because it's not void
        // There are two options:
        //  - If the link is short, we unshorte because any new text would make the short link invalid
        //  - If the link is original, we update the node.url value which will trigger normalization again
        if (LinkElement.canBeUnshortened(node)) {
          toggleShortening(editor, [node, path])
        } else {
          withoutLosingSelection(editor, () => {
            unwrapLink(editor, path)
          })
        }

        return
      }
    }

    normalizeNode([node, path])
  }

  editor.insertData = (data: DataTransfer) => {
    // Add extra whitespace at end of pasted text if it does not end in whitspace already
    // this is to ensure processing of pasted links don't affect text that comes after the pasted text
    const text = data.getData('text/plain')
    if (text && !text.match(/\s+$/)) {
      const newData = new DataTransfer()
      data.types.forEach((type) => {
        newData.setData(type, `${data.getData(type)} `)
      })
      data = newData
    }

    insertData(data)

    findAndWrapNewLinks(editor, { at: [] })
  }

  return editor
}
