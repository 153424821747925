import * as Plate from '@udecode/plate'
import { GenerateContentElement } from '../nodes/GenerateContentElement'

// @ts-expect-error TS(7031) FIXME: Binding element 'editor' implicitly has an 'any' t... Remove this comment to see the full error message
export function removeGeneratedContent({ editor }) {
  Plate.removeNodes(editor, {
    at: [],
    match: (n) => GenerateContentElement.is(n),
  })

  editor.hasGeneratedContent = false
}
