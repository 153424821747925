import type {
  ChannelData,
  Draft,
  InstagramChannelData,
  TiktokChannelData,
} from '~publish/legacy/composer/composer/entities/Draft'
import type { PostFields } from '@buffer-mono/reminders-config'
import type { FieldConfigItem } from '~publish/legacy/reminders/components/new-reminders/field-configuration/PublishFieldsConfiguration'
import { Field } from '~publish/legacy/reminders/components/new-reminders/components/forms/field/Field'
import React from 'react'
import ComposerActionCreators from '~publish/legacy/composer/composer/action-creators/ComposerActionCreators'
import { SERVICE_INSTAGRAM, SERVICE_TIKTOK } from '~publish/legacy/constants'

function updateFieldValue<
  K extends keyof (InstagramChannelData | TiktokChannelData),
>({
  draft,
  attribute,
  value,
}: {
  draft: Draft
  value: InstagramChannelData[K] | TiktokChannelData[K]
  attribute: K
}): void {
  if (draft.id === SERVICE_TIKTOK) {
    const updatedChannelData = { ...draft?.channelData?.tiktok }
    updatedChannelData[attribute] = value
    ComposerActionCreators.updateDraftTiktokData(updatedChannelData)
  }

  if (draft.id === SERVICE_INSTAGRAM) {
    const updatedChannelData = { ...draft?.channelData?.instagram }
    updatedChannelData[attribute] = value

    ComposerActionCreators.updateDraftInstagramData(updatedChannelData)
  }
}

export const ReminderFieldComponent = ({
  draft,
  field,
  fieldConfig,
}: {
  draft: Draft
  field: PostFields
  fieldConfig: FieldConfigItem
}): JSX.Element => {
  const channelSpecificData =
    draft?.channelData?.[draft.id as keyof ChannelData]

  return (
    <Field
      key={`field-${draft.id}-${field}`}
      labelText={fieldConfig.label}
      id={field}
      name={field}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      value={channelSpecificData?.[field] || ''}
      placeholder={fieldConfig.placeholder}
      onChange={(e): void => {
        updateFieldValue({
          draft,
          value: e.target.value,
          attribute: field as keyof (InstagramChannelData | TiktokChannelData),
        })
      }}
    />
  )
}
