import type { TNode } from '@udecode/plate'
import { Node } from 'slate'

/**
 *
 */
function getContent({ editor }: { editor: any }): string {
  const texts = editor.children?.map((node: TNode) => {
    return node.type === 'p' ? `${Node.string(node)}` : ''
  })

  const content = texts.join(' ').trim()

  return content
}

export { getContent }
