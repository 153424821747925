import * as Dialog from '@radix-ui/react-dialog'

import { grayDarker, grayDefault, white } from '@bufferapp/ui/style/colors'

import styled from 'styled-components'

export const StyledOverlay = styled(Dialog.Overlay)`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgb(50, 59, 67, 0.8);
  opacity: 0.9;
  z-index: 4000;
`

export const ModalWrapper = styled(Dialog.Content)`
  position: fixed;
  display: flex;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4000;
  height: 90%;
  width: 90%;
  max-width: 1050px;
  max-height: 660px;
  background-color: ${white};
  border-radius: 8px;
`

export const InnerWrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  padding: 12px 32px 0 32px;
  justify-content: flex-start;
  background-color: ${white};
  border-radius: 8px;
  position: relative;
`

export const CloseDialogButton = styled.button`
  position: absolute;
  border: none;
  background-color: white;
  top: 11px;
  right: 19px;
  width: 20px;
  height: 20px;
  svg {
    fill: ${grayDefault};
    width: 20px;
    height: 20px;
  }
  &:hover {
    svg {
      fill: ${grayDarker};
    }
    cursor: pointer;
  }
`
