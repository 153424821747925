import styled from 'styled-components'

import { LinkifiedText as LinkifiedTextComponent } from '~publish/legacy/shared-components/LinkifiedText'

import { twitterBlack, twitterBlueishGray, retweetBorder } from '../../styles'

export const Wrapper = styled.div`
  border: 1px solid ${retweetBorder};
  border-radius: 12px;
  padding: 12px;
`

export const UserWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 8px;
`

export const Avatar = styled.img`
  width: 16px;
  height: 16px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 4px;
`

export const DisplayName = styled.span`
  color: ${twitterBlack};
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
  height: 16px;
  margin-right: 4px;
  max-width: 95px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const Username = styled.span`
  color: ${twitterBlueishGray};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  height: 16px;
  max-width: 95px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const Text = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${twitterBlack};
  margin: 0;
`

export const LinkifiedText = styled(LinkifiedTextComponent)`
  word-wrap: break-word;
`
