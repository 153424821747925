import React from 'react'
import CrossIcon from '@bufferapp/ui/Icon/Icons/Cross'

import { useAppSelector } from '~publish/legacy/store'
import { selectContentGenerationStatus } from '~publish/legacy/ai/state/slice'

import * as Styled from './Prompt.styles'

/**
 *
 */
function Prompt({
  expanded,
  lines,
  prompt,
  withBackground,
  onToggle,
  onClear,
}: {
  expanded: boolean
  prompt: string
  lines?: number
  withBackground?: boolean
  onToggle: () => void
  onClear: () => void
}): JSX.Element {
  const { contentGenerationInProgress } = useAppSelector((state) =>
    selectContentGenerationStatus(state),
  )

  const onClearClick = (
    event: React.MouseEvent | React.KeyboardEvent,
  ): void => {
    event.preventDefault()
    event.stopPropagation()
    onClear()
  }

  return (
    <Styled.Wrapper
      expanded={expanded}
      withBackground={withBackground}
      lines={lines}
      disabled={contentGenerationInProgress}
      onClick={contentGenerationInProgress ? undefined : onToggle}
    >
      <p data-testid="ai-assistant-prompt">{prompt}</p>
      {!contentGenerationInProgress && (
        <Styled.ClearButton
          onClick={onClearClick}
          aria-label="Clear prompt"
          withBackground={withBackground}
          data-testid="ai-assistant-clear-prompt-button"
        >
          <CrossIcon />
        </Styled.ClearButton>
      )}
    </Styled.Wrapper>
  )
}

export { Prompt }
