import React, { useEffect } from 'react'
import Joyride from 'react-joyride'

import { useAppDispatch } from '~publish/legacy/store'
import { actions as guideActions } from '~publish/legacy/guides'
import { actions as remindersActions } from '~publish/legacy/reminders'

import { Step } from './components/step'

import { useDismissableBanner } from '~publish/hooks/useDismissableBanner'

export function InstagramNotificationsComposerGuide({
  isReminder,
}: {
  isReminder: boolean | undefined
}): JSX.Element | null {
  const dispatch = useAppDispatch()
  const guide = useDismissableBanner('instagramRemindersComposerGuide')

  useEffect(() => {
    if (guide.isActive) {
      dispatch(
        guideActions.setInstagramNotificationsComposerGuideStep({
          step: 0,
        }),
      )
    }
  }, [guide, dispatch])

  if (!isReminder || !guide.isActive) {
    return null
  }

  const steps = [
    {
      target: '#stickers-group',
      title: 'Customize your Post, Story or Reel in Buffer',
      content:
        'Add multiple images to Stories, draft music ideas, stickers and more in Buffer.',
      disableBeacon: true,
      placement: 'top',
      offset: 0,
      callback: () =>
        dispatch(remindersActions.showInstagramNotificationsPreview(true)),
    },
    {
      target: '#post-preview',
      title: "Get a mobile notification when it's time to post",
      content:
        "Tap it to finish your post on Instagram. If you don't have the Buffer mobile app, we'll help you set it up.",
      disableBeacon: true,
      placement: 'left',
      offset: 0,
    },
    {
      target: '#post-preview',
      title: 'Finish on Instagram',
      content:
        'Copy your content from the Buffer mobile app to Instagram and add the final touches to your post.',
      disableBeacon: true,
      placement: 'left',
      offset: 0,
      callback: (): void => {
        dispatch(remindersActions.showInstagramNotificationsPreview(false))
        dispatch(
          guideActions.setInstagramNotificationsComposerGuideStepAfterViewed(),
        )
        guide.dismiss()
      },
    },
  ]

  return (
    <Joyride
      // steps eventually gets passed as a prop to our custom
      // tooltip component. The library's types do a check for what they
      // expect. Passing additional properties like title and callback doesn't
      // break anything, just gives a typescript error because it's not expected.
      // @ts-ignore
      steps={steps}
      run={isReminder}
      continuous
      disableScrolling
      // the library passes a lot of props automatically to this custom
      // component, but we only make use of a few, which are typed in Step
      // @ts-ignore
      tooltipComponent={Step}
      styles={{
        options: {
          arrowColor: 'none',
          zIndex: 100000,
        },
      }}
    />
  )
}
