import { MediaTypes } from '~publish/legacy/constants'
import {
  type BufferUpload,
  Upload,
} from '~publish/legacy/uploads/entities/Upload'
import AppDispatcher from '../dispatcher'
import type { Document } from '../entities/Document'
import { ActionTypes } from '../state/ActionTypes'
import ComposerActionCreators from './ComposerActionCreators'

export const handleUploadFinished = ({
  upload,
  draftId,
}: {
  upload: BufferUpload
  draftId: string
}): void => {
  if (Upload.isImage(upload)) {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_ADD_DRAFT_UPLOADED_IMAGE,
      id: draftId,
      url: upload.url,
      width: upload.dimensions?.width,
      height: upload.dimensions?.height,
      source: Upload.getSource(upload),
    })
  }

  if (Upload.isVideo(upload)) {
    // @TODO: Add support for transcoding videos (i.e. hevc)
    //        this is not really necessary at the moment, but it will be when we add
    //        suport for HEVC videos because we can't generate a thumbnail directly in the browser
    //

    /** <--- this is the code that needs to be added and tested
          if (upload.videoMetadata.transconding === true) {
            AppDispatcher.handleViewAction({
              actionType: ActionTypes.COMPOSER_ADD_DRAFT_UPLOADED_VIDEO,
              id: draft.id,
              uploadId: upload.uploadId,
            });

            return;
          }
          */

    // no transcoding
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_UPDATE_DRAFT_THUMBNAIL_GENERATED,
      uploadId: upload.uploadId,
      name: upload.name,
      draftId,
      thumbnailURL: upload.thumbnailUrl,
      videoMetadata: {
        duration: upload.videoMetadata.duration,
        durationMs: upload.videoMetadata.durationMs,
        frameRate: upload.videoMetadata.frameRate,
        size: upload.size,
        width: upload.videoMetadata.width,
        height: upload.videoMetadata.height,
        url: upload.url,
      },
    })
  }

  if (Upload.isGif(upload)) {
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.COMPOSER_ADD_DRAFT_UPLOADED_GIF,
      id: draftId,
      url: upload.url,
      stillGifUrl: upload.thumbnailUrl,
      width: upload.dimensions?.width,
      height: upload.dimensions?.height,
    })
  }

  if (Upload.isDocumentUpload(upload)) {
    const document: Document = {
      id: upload.uploadId,
      url: upload.url,
      title: '',
      pageCount: upload.details?.pageCount,
      fileSizeBytes: upload.details?.fileSizeBytes,
      thumbnailUrl: upload.thumbnailUrl,
      mediaType: MediaTypes.DOCUMENT,
    }

    ComposerActionCreators.addDraftDocument(document, draftId)
  }
}
