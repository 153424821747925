import type { LinkedInAnnotation } from '~publish/legacy/post/types'
import {
  type FacebookMentionEntityDetails,
  FacebookMentionInputElement,
  type FacebookMentionEntity,
} from '../plugins/facebook-mention/nodes'
import { LinkElement, type LinkEntity } from '../plugins/link/nodes/LinkElement'
import { LinkedInAnnotationElement } from '../plugins/linkedin-annotations/nodes/LinkedInAnnotationElement'
import { WithPosition } from './WithPosition'

/**
 * There are two possible structures to represent editor entity data.
 * These are some types and helpers to abstract the differences
 *
 * - the Composer/Rest strucutre which is used when loading an update
 *   and when saving an update
 * - the Slate strucutre which is produced automatically by the plugin system
 *   produced by serialize which uses the plugin keys to nest the different entities
 */
export type EditorEntityData = SlateEntityData | ComposerEntityData

export type SlateEntityData = {
  [LinkElement.type]?: WithPosition<LinkEntity>[]
  [LinkedInAnnotationElement.type]?: WithPosition<LinkedInAnnotation>[]
  [FacebookMentionInputElement.type]?: WithPosition<FacebookMentionEntityDetails>[]
}

export type ComposerEntityData = {
  annotations?: WithPosition<LinkedInAnnotation>[]
  facebookMentionEntities?: FacebookMentionEntity[]
}

export const EditorEntityData = {
  isSlateEntityData(data: EditorEntityData): data is SlateEntityData {
    const res =
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      data[LinkElement.type] ||
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      data[FacebookMentionInputElement.type] ||
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      data[LinkedInAnnotationElement.type]

    return !!res
  },

  getLinks(data: EditorEntityData | undefined): WithPosition<LinkEntity>[] {
    if (!data || !EditorEntityData.isSlateEntityData(data)) return []

    return data.a || []
  },

  getFacebookMentions(
    data: EditorEntityData | undefined,
  ): WithPosition<FacebookMentionEntityDetails>[] {
    if (!data) return []

    const entities = EditorEntityData.isSlateEntityData(data)
      ? data[FacebookMentionInputElement.type]
      : data.facebookMentionEntities?.map(WithPosition.fromWithIndices)

    return entities || []
  },

  getFacebookMentionsWithIndices(
    data: EditorEntityData | undefined,
  ): FacebookMentionEntity[] {
    if (!data) return []

    const entities = EditorEntityData.isSlateEntityData(data)
      ? data[FacebookMentionInputElement.type]?.map(WithPosition.toWithIndices)
      : data.facebookMentionEntities

    return entities || []
  },

  getLinkedInAnnotations(
    data: EditorEntityData | undefined,
  ): WithPosition<LinkedInAnnotation>[] {
    if (!data) return []

    const entities = EditorEntityData.isSlateEntityData(data)
      ? data[LinkedInAnnotationElement.type]
      : data.annotations

    return entities || []
  },
}
