import type { ExtendedMedia } from '../types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function isExtendedMedia(media: any): media is ExtendedMedia {
  return (
    media.width !== undefined &&
    media.height !== undefined &&
    media.duration !== undefined &&
    media.durationMs !== undefined &&
    media.size !== undefined
  )
}
