/* eslint-disable camelcase, @typescript-eslint/naming-convention */
import React, { forwardRef, type JSX, type MouseEventHandler } from 'react'
import { graphql } from '~publish/gql'
import type { IdeaCard_IdeaFragment as IdeaFragment } from '~publish/gql/graphql'

import clsx from 'clsx'

import {
  IconButton,
  TrashIcon,
  Flex,
  Tag,
  Paragraph,
  VisuallyHidden,
  Heading,
  Checkbox,
} from '@buffer-mono/popcorn'
import { useSplitEnabled } from '@buffer-mono/features'

import { type Idea, type Media, MediaType } from '~publish/pages/Create/types'

import { useSanitizedAndHighlightedText } from './helpers'

import { MediaCombinedPreview } from '~publish/components/MediaCombinedPreview'
import {
  type MediaMarkerType,
  MediaTypeMarker,
} from '~publish/components/MediaMarker'
import { IdeaCardActions } from './IdeaCardActions'
import { DeleteIdeasDialog } from '~publish/components/DeleteIdeasDialog'
import { useIdeasMultiSelect } from '~publish/components/IdeasMultiSelect'

import styles from './IdeaCard.module.css'

// This fragment is exported but not used yet
// eslint-disable-next-line camelcase, @typescript-eslint/naming-convention
export const IdeaCard_Idea = graphql(/* GraphQL */ `
  fragment IdeaCard_Idea on Idea {
    id
    content {
      title
      text
      aiAssisted
      media {
        id
        url
        alt
        thumbnailUrl
        type
        size
        source {
          name
          id
        }
      }
      tags {
        id
        name
        color
      }
    }
    organizationId
    groupId
    position
    createdAt
    updatedAt
  }
`)

export type IdeaCardProps = {
  idea: Idea | IdeaFragment
  variant?: 'default' | 'compact'
  // TODO: we should just pass id instead of the whole entity
  // for nowm keeping idea object for backward compatibility
  // after usage is refactored, we can remove update it
  onDelete?: (idea: Idea | IdeaFragment) => Promise<void>
  onOpen?: (idea: Idea | IdeaFragment) => void
}

const IdeaCard = forwardRef<HTMLDivElement, IdeaCardProps>(
  ({ variant = 'default', idea, onDelete, onOpen }, forwardedRef) => {
    const { isEnabled: isIdeasRevampEnabled } =
      useSplitEnabled('CT-ideas-revamp')
    const { selectedIdeas, isMultiSelectMode, selectIdea } =
      useIdeasMultiSelect()
    const isSelected = selectedIdeas.has(idea.id)

    const tags = idea?.content?.tags ?? []
    const hasTags = tags.length > 0

    const media = idea?.content?.media ?? []
    const hasMedia = media.length > 0

    const handleClick: MouseEventHandler = (e): void => {
      e.stopPropagation()
      if (isMultiSelectMode) {
        selectIdea(idea.id)
      } else {
        onOpen?.(idea)
      }
    }

    const handleDelete = React.useCallback(async (): Promise<void> => {
      await onDelete?.(idea)
    }, [idea, onDelete])

    const handleSelect = (): void => {
      selectIdea(idea.id)
    }

    return (
      /* eslint-disable jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events */
      <article
        ref={forwardedRef}
        data-testid="idea-card"
        data-idea-id={idea.id}
        data-idea-position={idea.position}
        data-selected={isSelected}
        data-multiselect={isMultiSelectMode}
        className={clsx(styles.card, styles[variant])}
        onClick={handleClick}
      >
        {hasMedia && (
          <div
            className={styles.mediaContainer}
            data-media-count={media.length}
          >
            <MediaCombinedPreview>
              {media.map((media) => (
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error null is not assignable to type
                <IdeaMedia key={media.id} media={media} />
              ))}
            </MediaCombinedPreview>
          </div>
        )}

        <div className={styles.content}>
          {idea.content.title && (
            <Heading as="h4" size="small" className={styles.title}>
              {idea.content.title}
            </Heading>
          )}
          {idea.content.text && <SanitizedIdeaText text={idea.content.text} />}
          {hasTags ? (
            <Flex wrap="wrap" gap="xs" className={styles.tags}>
              {tags.map((tag) => (
                <Tag key={tag.id} color={tag.color}>
                  {tag.name}
                </Tag>
              ))}
            </Flex>
          ) : null}
        </div>

        {/* visually hidden button to open idea via tab */}
        <VisuallyHidden
          as="button"
          className={styles.openButton}
          onClick={handleClick}
        >
          {isMultiSelectMode ? 'Select idea' : 'Open idea'}
        </VisuallyHidden>

        {isMultiSelectMode && (
          <div className={styles.selectionIndicator}>
            <Checkbox checked={isSelected} name="selectIdea">
              <VisuallyHidden>Select idea</VisuallyHidden>
            </Checkbox>
          </div>
        )}

        {!isMultiSelectMode &&
          (isIdeasRevampEnabled ? (
            <IdeaCardActions
              idea={idea}
              onSelect={handleSelect}
              onDelete={handleDelete}
              className={styles.ideaCardMenu}
            />
          ) : (
            <DeleteIdeaIconButton onDelete={handleDelete} />
          ))}
      </article>
      /* eslint-enable */
    )
  },
)

IdeaCard.displayName = 'IdeaCard'

type SanitizedIdeaTextProps = {
  text?: string | null
}

const SanitizedIdeaText = ({
  text,
}: SanitizedIdeaTextProps): JSX.Element | null => {
  const htmlText = useSanitizedAndHighlightedText(text)

  if (!text) {
    return null
  }

  return (
    <Paragraph
      className={styles.text}
      dangerouslySetInnerHTML={{ __html: htmlText }}
    />
  )
}

type IdeaMediaProps = {
  media: Media | NonNullable<Idea['content']['media']>[number]
}

const ideaTypeToMarker: Partial<Record<MediaType, MediaMarkerType>> = {
  [MediaType.video]: 'video',
  [MediaType.gif]: 'gif',
  [MediaType.document]: 'pdf',
}

const IdeaMedia = ({ media }: IdeaMediaProps): JSX.Element | null => {
  // TODO: API should support resizing, this is a temporary solution
  // to alleviate performance concerns
  const optimizedThumbnail = `https://safeimage.buffer.com/500,fit/${
    media.thumbnailUrl || media.url
  }`

  const markerType = ideaTypeToMarker[media.type]

  return (
    <div className={styles.mediaWrapper}>
      <img
        src={optimizedThumbnail}
        alt={media.alt ?? 'Media attachment'}
        className={styles.media}
        loading="lazy"
      />
      {markerType && (
        <MediaTypeMarker
          className={styles.mediaTypeIndicator}
          type={markerType}
        />
      )}
    </div>
  )
}

const DeleteIdeaIconButton = ({
  onDelete,
}: {
  onDelete: () => Promise<void>
}): JSX.Element => {
  const [opened, setOpened] = React.useState(false)

  const handlePropagation = (e: React.MouseEvent): void => {
    e.stopPropagation()
  }

  return (
    <DeleteIdeasDialog
      open={opened}
      onOpenChange={setOpened}
      onDelete={onDelete}
    >
      <IconButton
        variant="critical"
        label="Delete idea"
        tooltip="Delete idea"
        className={styles.deleteButton}
        onClick={handlePropagation}
      >
        <TrashIcon />
      </IconButton>
    </DeleteIdeasDialog>
  )
}

DeleteIdeaIconButton.displayName = 'DeleteIdeaIconButton'

export type IdeaCardSkeletonProps = {
  variant: 1 | 2 | 3 | 4
}

const IdeaCardSkeleton = React.forwardRef<
  React.ElementRef<'div'>,
  IdeaCardSkeletonProps
>(({ variant = 1 }, ref): JSX.Element => {
  return (
    <article
      className={clsx(styles.skeleton, styles[`variant${variant}`])}
      aria-hidden
      ref={ref}
    >
      <div className={styles.media} />
    </article>
  )
})

IdeaCardSkeleton.displayName = 'IdeaCard.Skeleton'

const IdeaCardObject = Object.assign(IdeaCard, {
  Skeleton: IdeaCardSkeleton,
})

export { IdeaCardObject as IdeaCard }
