import React from 'react'

import IgProductTag from '@bufferapp/ui/Icon/Icons/IgProductTag'
import MusicIcon from '@bufferapp/ui/Icon/Icons/Music'

import type {
  Image,
  Video,
} from '~publish/legacy/composer/composer/entities/factories'
import { parseInstagramLinks } from '~publish/legacy/utils/channels/instagram/helpers'
import { PostTypeReel } from '~publish/legacy/post/constants'

import type { InstagramContent, ProfilePreview } from '../../../types'
import { DEFAULT_AVATAR } from '../../../Common/utils'

import * as Styles from './styles'
import * as StylesInstagram from '../../styles'
import { PostFields } from '@buffer-mono/reminders-config'

const ImagePreview = ({ image }: { image: Image }): React.JSX.Element => (
  <Styles.ImageWrapper data-testid="preview-images">
    <Styles.Image src={image.url} />
  </Styles.ImageWrapper>
)

const Reel = ({
  content,
  profile,
}: {
  content: InstagramContent
  profile: ProfilePreview
}): JSX.Element => {
  const { avatar, username } = profile
  const { text, video, gif } = content

  let locationName =
    content.isReminder &&
    content.selectedStickers?.includes(PostFields.LOCATION_NAME)
      ? content?.channelData?.instagram?.service_geolocation_name
      : ''
  if (!content.isReminder) {
    locationName = content?.channelData?.instagram?.service_geolocation_name
  }
  const stickerProducts =
    content.isReminder &&
    content.selectedStickers?.includes(PostFields.PRODUCTS)
      ? content?.channelData?.instagram?.products
      : ''
  const stickerMusic =
    content.isReminder && content.selectedStickers?.includes(PostFields.MUSIC)
      ? content?.channelData?.instagram?.music
      : ''
  const stickerText =
    content.isReminder && content.selectedStickers?.includes(PostFields.TEXT)
      ? content?.channelData?.instagram?.text
      : ''

  return (
    <Styles.PostWrapper data-testid="reels">
      {video && <Styles.VideoPlayer video={video as Video} />}
      {gif && <ImagePreview image={gif as Image} />}
      {stickerText && (
        <StylesInstagram.TextStickerWrapper>
          <StylesInstagram.TextStickerContent>
            {stickerText}
          </StylesInstagram.TextStickerContent>
        </StylesInstagram.TextStickerWrapper>
      )}
      <Styles.MetadataWrapper>
        <Styles.User>
          <StylesInstagram.Avatar
            data-testid="avatar"
            src={avatar}
            fallbackUrl={DEFAULT_AVATAR}
          />
          <Styles.UsernameWrapper>
            <Styles.Username>{username}</Styles.Username>
          </Styles.UsernameWrapper>
        </Styles.User>
        {text && (
          <Styles.Caption>
            <Styles.LinkifiedText
              links={parseInstagramLinks(PostTypeReel, text)}
              size="mini"
              whitespace="pre-wrap"
              newTab
            >
              {text}
            </Styles.LinkifiedText>
          </Styles.Caption>
        )}
        <Styles.ExtraData>
          {stickerMusic && (
            <Styles.ExtraDataItem>
              <MusicIcon />
              <Styles.WrappedText>
                {`${username} · Original Audio`}
              </Styles.WrappedText>
            </Styles.ExtraDataItem>
          )}
          {stickerProducts && (
            <Styles.ExtraDataItem>
              <Styles.WrappedText>
                <IgProductTag />
                Product
              </Styles.WrappedText>
            </Styles.ExtraDataItem>
          )}
          {locationName && (
            <Styles.Location>
              <Styles.LocationPin />
              <Styles.WrappedText>{locationName}</Styles.WrappedText>
            </Styles.Location>
          )}
        </Styles.ExtraData>
      </Styles.MetadataWrapper>
      <Styles.IconsSideSection>
        <StylesInstagram.HeartIcon />
        <StylesInstagram.CommentIcon />
        <StylesInstagram.MessageIcon />
        <Styles.Dots />
        <Styles.AvatarSquare
          data-testid="avatar-square"
          src={avatar}
          fallbackUrl={DEFAULT_AVATAR}
        />
      </Styles.IconsSideSection>
    </Styles.PostWrapper>
  )
}

export default Reel
