import type { AspectRatio, AspectRatioObject, AspectRatioRange } from './types'

const isObject = (input: unknown): input is Record<string, unknown> => {
  return typeof input === 'object' && input !== null && !Array.isArray(input)
}

// ratio can be defined as a non-zero number or an object with non-zero width and height
// we use this helper for ratio, width and height
export const isPositiveNumber = (value: unknown): value is number => {
  if (typeof value !== 'number' || value <= 0) {
    return false
  }

  return true
}

export const isAspectRatioObject = (
  value: unknown,
): value is AspectRatioObject => {
  if (!isObject(value)) return false

  const { width, height } = value as AspectRatioObject

  return isPositiveNumber(width) && isPositiveNumber(height)
}

export const isAspectRatio = (value: unknown): value is AspectRatio => {
  return isPositiveNumber(value) || isAspectRatioObject(value)
}

export const isAspectRatioRange = (
  value: unknown,
): value is AspectRatioRange => {
  if (!isObject(value)) return false

  const { minRatio, maxRatio } = value as AspectRatioRange

  return isAspectRatio(minRatio) && isAspectRatio(maxRatio)
}
