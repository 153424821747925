import twitterText from 'twitter-text'
import BaseValidator from '~publish/legacy/composer/composer/lib/validation/BaseValidator'
import { VALIDATION_CODE } from '~publish/legacy/validation/constants'
import ValidationFail from '~publish/legacy/validation/ValidationFail'
import type ValidationResult from '~publish/legacy/validation/ValidationResult'
import ValidationSuccess from '~publish/legacy/validation/ValidationSuccess'
import {
  getMaxCharactersForTitleMessage,
  PINTEREST_INVALID_DESTINATION_LINK,
  PINTEREST_SHORTENED_LINK,
} from './utils/validationErrorMessages'

export default class PinterestValidator extends BaseValidator {
  protected isValidSourceUrl(): ValidationResult {
    const isSourceUrlUnrequiredOrValid =
      !this.draft.service.canHaveSourceUrl ||
      this.draft.sourceLink === null ||
      twitterText.isValidUrl(this.draft.sourceLink.url, true, false)
    if (!isSourceUrlUnrequiredOrValid) {
      return new ValidationFail(
        PINTEREST_INVALID_DESTINATION_LINK,
        VALIDATION_CODE.INVALID_VALUE,
      )
    }
    return new ValidationSuccess()
  }

  protected linkWithoutShortener(): ValidationResult {
    const isPinterest = this.draft.service.name === 'pinterest'
    const isPinterestSourceUrlUsingLinkShortener =
      isPinterest &&
      (this.draft.sourceLink?.url?.includes('bit.ly/') ||
        this.draft.sourceLink?.url?.includes('buff.ly/') ||
        this.draft.sourceLink?.url?.includes('j.mp/'))

    const contentText = this.draft.text
    const linksInText = twitterText.extractUrls(contentText)

    const hasPinterestLinkWithoutSourceUrl =
      isPinterest && linksInText.length > 0 && !this.draft.sourceLink
    if (
      hasPinterestLinkWithoutSourceUrl ||
      isPinterestSourceUrlUsingLinkShortener
    ) {
      return new ValidationFail(
        PINTEREST_SHORTENED_LINK,
        VALIDATION_CODE.MISSING_VALUE,
      )
    }
    return new ValidationSuccess()
  }

  protected maxCharactersInTitle(): ValidationResult {
    if (
      this.draft.service.titleCharLimit !== null &&
      (this.draft.characterTitleCount ?? 0) > this.draft.service.titleCharLimit
    ) {
      return new ValidationFail(
        getMaxCharactersForTitleMessage(this.draft.service.titleCharLimit),
        VALIDATION_CODE.MAX_LIMIT_REACHED,
      )
    }
    return new ValidationSuccess()
  }

  protected validateForChannel(): ValidationResult[] {
    return [
      this.linkWithoutShortener(),
      this.isValidSourceUrl(),
      this.maxCharactersInTitle(),
    ]
  }
}
