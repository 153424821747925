import BaseValidator from '~publish/legacy/composer/composer/lib/validation/BaseValidator'
import ValidationFail from '~publish/legacy/validation/ValidationFail'
import ValidationSuccess from '~publish/legacy/validation/ValidationSuccess'
import type ValidationResult from '~publish/legacy/validation/ValidationResult'
import {
  getReelsVideoAspectRatioMessage,
  getReelsVideoTooLongMessage,
  getReelsVideoTooShortMessage,
  getReelsVideoTooSmallDimensionsMessage,
  getVideoFrameRateMessage,
  getVideoResolutionTooSmallMessage,
  getVideoTooLargeMessage,
} from './utils/validationErrorMessages'
import { validateMedia } from '~publish/legacy/media/validation/validateMedia'
import { getVideoRestrictionsForDraft } from '~publish/legacy/media/config/posts-media-restrictions'
import { VALIDATION_CODE } from '~publish/legacy/validation/constants'

export default class FacebookValidator extends BaseValidator {
  protected isVideoTooLong(maxDuration: number): boolean {
    return (
      this.draft.video !== null &&
      !!maxDuration &&
      this.draft.video.durationMs > maxDuration
    )
  }

  protected isVideoTooShort(minDuration: number): boolean {
    return (
      this.draft.video !== null &&
      !!minDuration &&
      this.draft.video.durationMs < minDuration
    )
  }

  protected isVideoTooBig(maxSize: number): boolean {
    return (
      this.draft.video !== null && !!maxSize && this.draft.video.size > maxSize
    )
  }

  protected isVideoUnderDimensions(
    minWidth: number,
    minHeight: number,
  ): boolean {
    return (
      this.draft.video !== null &&
      !!minWidth &&
      !!minHeight &&
      (this.draft.video.width < minWidth || this.draft.video.height < minHeight)
    )
  }

  protected isVideoWithinAspectRatio(
    minAspectRatio: number,
    maxAspectRatio: number,
  ): boolean {
    return (
      this.draft.video !== null &&
      !!minAspectRatio &&
      !!maxAspectRatio &&
      (this.draft.video.width / this.draft.video.height < minAspectRatio ||
        this.draft.video.width / this.draft.video.height > maxAspectRatio)
    )
  }

  protected isVideoTooSmall(minWidth: number): boolean {
    return this.draft.video !== null && this.draft.video.width < minWidth
  }

  protected isFrameRateIncorrectForVideo(
    minFrameRate: number,
    maxFrameRate: number,
  ): boolean {
    if (this.draft.video !== null && !!this.draft.video.frameRate) {
      const { frameRate } = this.draft.video
      return frameRate < minFrameRate || frameRate > maxFrameRate
    }
    return false
  }

  protected validateAttachmentOrText(): ValidationResult {
    if (this.draft.isFacebookStoryPost()) {
      if (this.draft.hasAttachment()) {
        return new ValidationSuccess()
      } else {
        return new ValidationFail(
          'Stories must contain an attachment',
          VALIDATION_CODE.MISSING_VALUE,
        )
      }
    }

    return super.validateAttachmentOrText()
  }

  protected shouldValidateVideo(): boolean {
    const shouldValidateVideo =
      !!this.draft.service?.videoMaxSizeReels ||
      !!this.draft.service?.videoMinFrameRateReels ||
      !!this.draft.service?.videoMaxFrameRateReels ||
      !!this.draft.service?.videoMinDurationMsReels ||
      !!this.draft.service?.videoMaxDurationMsReels

    return super.shouldValidateVideo() || shouldValidateVideo
  }

  protected validateVideo(): ValidationResult {
    if (this.draft.isFacebookReelPost()) {
      return this.validateReelsVideo()
    }

    if (this.draft.isFacebookStoryPost()) {
      return this.validateStoriesVideo()
    }

    return super.validateVideo()
  }

  protected validateReelsVideo(): ValidationResult {
    const rules = getVideoRestrictionsForDraft(
      this.draft.service.name,
      this.draft.updateType,
    )

    if (!this.draft.video) {
      return new ValidationFail(
        'Reels post must contain a video',
        VALIDATION_CODE.MISSING_VALUE,
      )
    }

    if (rules) {
      const error = validateMedia(rules, this.draft.video)

      if (error) {
        return error
      }
    }

    if (
      this.draft.service.videoMaxDurationMsReels &&
      this.isVideoTooLong(this.draft.service.videoMaxDurationMsReels)
    ) {
      const messageOptions = getReelsVideoTooLongMessage(
        (this.draft.service.videoMaxDurationMsReels ?? 0) / 1000,
      )
      return new ValidationFail(
        messageOptions.generic,
        VALIDATION_CODE.VIDEO_DURATION,
        messageOptions,
        {
          mediaUrl: this.draft.video?.url,
          mediaType: this.draft.video?.mediaType,
        },
      )
    }

    if (
      this.draft.service.videoMinDurationMsReels &&
      this.isVideoTooShort(this.draft.service.videoMinDurationMsReels)
    ) {
      const messageOptions = getReelsVideoTooShortMessage(
        (this.draft.service.videoMinDurationMsReels ?? 0) / 1000,
      )

      return new ValidationFail(
        messageOptions.generic,
        VALIDATION_CODE.VIDEO_DURATION,
        messageOptions,
        {
          mediaUrl: this.draft.video?.url,
          mediaType: this.draft.video?.mediaType,
        },
      )
    }

    if (
      this.draft.service?.videoMaxSizeReels &&
      this.isVideoTooBig(this.draft.service?.videoMaxSizeReels)
    ) {
      const maxFileSizeInMb =
        (this.draft.service.videoMaxSizeReels ?? 0) / 1024 / 1024

      const messageOptions = getVideoTooLargeMessage(maxFileSizeInMb)

      return new ValidationFail(
        messageOptions.generic,
        VALIDATION_CODE.VIDEO_SIZE,
        messageOptions,
        { mediaUrl: this.draft.video?.url },
      )
    }

    if (
      this.draft.service?.videoMinWidthReels &&
      this.draft.service?.videoMinHeightReels &&
      this.isVideoUnderDimensions(
        this.draft.service.videoMinWidthReels,
        this.draft.service.videoMinHeightReels,
      )
    ) {
      const messageOptions = getReelsVideoTooSmallDimensionsMessage(
        this.draft.service.videoMinWidthReels,
        this.draft.service.videoMinHeightReels,
      )

      return new ValidationFail(
        messageOptions.generic,
        VALIDATION_CODE.ASPECT_RATIO,
        messageOptions,
        { mediaUrl: this.draft.video?.url },
      )
    }

    if (
      this.draft.service?.videoMinAspectRatioReels &&
      this.draft.service?.videoMaxAspectRatioReels &&
      this.isVideoWithinAspectRatio(
        this.draft.service.videoMinAspectRatioReels,
        this.draft.service.videoMaxAspectRatioReels,
      )
    ) {
      const messageOptions = getReelsVideoAspectRatioMessage('9/16', '16/9')

      return new ValidationFail(
        messageOptions.generic,
        VALIDATION_CODE.ASPECT_RATIO,
        messageOptions,
        { mediaUrl: this.draft.video?.url },
      )
    }

    if (
      this.isFrameRateIncorrectForVideo(
        this.draft.service.videoMinFrameRateReels as number,
        this.draft.service.videoMaxFrameRateReels as number,
      )
    ) {
      const messageOptions = getVideoFrameRateMessage(
        this.draft.service.videoMinFrameRateReels as number,
        this.draft.service.videoMaxFrameRateReels as number,
        this.draft.video?.frameRate as number,
      )

      return new ValidationFail(
        messageOptions.generic,
        VALIDATION_CODE.VIDEO_FRAME_RATE,
        messageOptions,
        { mediaUrl: this.draft.video?.url },
      )
    }

    return new ValidationSuccess()
  }

  protected validateStoriesVideo(): ValidationResult {
    if (
      this.draft.service.videoMaxDurationMsStories &&
      this.isVideoTooLong(this.draft.service.videoMaxDurationMsStories)
    ) {
      const messageOptions = getReelsVideoTooLongMessage(
        (this.draft.service.videoMaxDurationMsStories ?? 0) / 1000,
      )
      return new ValidationFail(
        messageOptions.generic,
        VALIDATION_CODE.VIDEO_DURATION,
        messageOptions,
        {
          mediaUrl: this.draft.video?.url,
          mediaType: this.draft.video?.mediaType,
        },
      )
    }

    if (
      this.draft.service?.videoMinWidthStories &&
      this.draft.service?.videoMinHeightStories &&
      this.isVideoUnderDimensions(
        this.draft.service.videoMinWidthStories,
        this.draft.service.videoMinHeightStories,
      )
    ) {
      const messageOptions = getReelsVideoTooSmallDimensionsMessage(
        this.draft.service.videoMinWidthStories,
        this.draft.service.videoMinHeightStories,
      )

      return new ValidationFail(
        messageOptions.generic,
        VALIDATION_CODE.ASPECT_RATIO,
        messageOptions,
        { mediaUrl: this.draft.video?.url },
      )
    }

    if (
      this.isFrameRateIncorrectForVideo(
        this.draft.service.videoMinFrameRateStories as number,
        this.draft.service.videoMaxFrameRateStories as number,
      )
    ) {
      const messageOptions = getVideoFrameRateMessage(
        this.draft.service.videoMinFrameRateStories as number,
        this.draft.service.videoMaxFrameRateStories as number,
        this.draft.video?.frameRate as number,
      )
      return new ValidationFail(
        messageOptions.generic,
        VALIDATION_CODE.VIDEO_FRAME_RATE,
        messageOptions,
        { mediaUrl: this.draft.video?.url },
      )
    }

    if (
      this.draft.service.videoMinResolution &&
      this.isVideoTooSmall(this.draft.service.videoMinResolution)
    ) {
      const message = getVideoResolutionTooSmallMessage(
        this.draft.service.videoMinResolution,
      )

      return new ValidationFail(
        message,
        VALIDATION_CODE.VIDEO_SIZE,
        {
          generic: message,
          specific: message,
        },
        { mediaUrl: this.draft.video?.url },
      )
    }

    if (
      this.draft.service.videoMinAspectRatioStories &&
      this.draft.service.videoMaxAspectRatioStories &&
      this.isVideoWithinAspectRatio(
        this.draft.service.videoMinAspectRatioStories,
        this.draft.service.videoMaxAspectRatioStories,
      )
    ) {
      const message = getReelsVideoAspectRatioMessage(`6/16`, `9/16`)

      return new ValidationFail(
        message.generic,
        VALIDATION_CODE.VIDEO_SIZE,
        message,
        { mediaUrl: this.draft.video?.url },
      )
    }

    if (
      this.draft.service.videoMinDurationMsStories &&
      this.isVideoTooShort(this.draft.service.videoMinDurationMsStories)
    ) {
      const messageOptions = getReelsVideoTooShortMessage(
        (this.draft.service.videoMinDurationMsStories ?? 0) / 1000,
      )
      return new ValidationFail(
        messageOptions.generic,
        VALIDATION_CODE.VIDEO_DURATION,
        messageOptions,
        {
          mediaUrl: this.draft.video?.url,
          mediaType: this.draft.video?.mediaType,
        },
      )
    }

    return new ValidationSuccess()
  }
}
