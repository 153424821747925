import { Text } from '@bufferapp/ui'
import { grayLight, grayDark, white } from '@bufferapp/ui/style/colors'
import styled from 'styled-components'

import SharedModal from '../../shared-components/modal/modal'
import InputSource from '../Input'
import { ImageOrientation } from '../post-preview/previews/Common/utils'

export const Wrapper = styled.div`
  & > div > div {
    display: flex;
    width: 560px;
    border-radius: 4px;
  }
`

export const Modal = styled(SharedModal)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  margin: 0;
  background: none;
  box-shadow: none;
  width: 120px;
`

export const Card = styled.div`
  max-height: 80vh;
  overflow: auto;
  border-radius: 8px;
  background: ${white};
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-basis: 100%;
`

export const Header = styled.div`
  width: 100%;
`

export const HeaderTitle = styled(Text).attrs({ type: 'h3' })`
  margin: 0px 0px 5px;
`

export const TitleText = styled.span`
  font-size: 14px;
  color: ${grayDark};
  display: block;
`

export const Title = styled.label`
  display: block;
  margin: 16px 0px 8px;
  font-size: 14px;
  font-weight: 600;
`

export const Input = styled(InputSource)`
  display: block;
  box-sizing: border-box;
  width: 100%;
  height: 40px;
  margin-bottom: 8px;
  padding: 0 8px;
  border: 1px solid ${grayLight};
  border-radius: 4px;
`

export const MediaContainer = styled.div<{ orientation?: string }>`
  position: relative;
  text-align: center;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 16px 0px;

  width: ${({ orientation }) => {
    if (orientation === ImageOrientation.Portrait) {
      return '260px'
    }
    if (orientation === ImageOrientation.Landscape) {
      return '100%'
    }
  }};
`

export const Video = styled.video`
  width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  border-radius: 4px;
`

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
`
