import React from 'react'
import PropTypes from 'prop-types'
import {
  blueLightest,
  redLightest,
  red,
  blue,
} from '@bufferapp/ui/style/colors'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type, @typescript-eslint/explicit-module-boundary-types
const Donut = ({
  percentage,
  warning,
}: {
  percentage: number
  warning: boolean
}) => {
  const radius = 6
  const strokeWidth = 3.5
  const viewBoxSize = 16
  const viewBoxCenter = viewBoxSize / 2
  const circumference = 2 * Math.PI * radius

  // Calculate the length of the red loader donut dash array based on the given percentage
  const redDonutDasharray = (percentage / 100) * circumference

  return (
    <svg
      width={viewBoxSize}
      height={viewBoxSize}
      viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* Grey Donut */}
      <circle
        cx={viewBoxCenter}
        cy={viewBoxCenter}
        r={radius}
        strokeWidth={strokeWidth}
        fill="transparent"
        stroke={warning ? redLightest : blueLightest}
      />

      {/* Red Donut */}
      <circle
        cx={viewBoxCenter}
        cy={viewBoxCenter}
        r={radius}
        strokeWidth={strokeWidth}
        fill="transparent"
        stroke={warning ? red : blue}
        // Don't use rounded corners if the donut is almost full, it looks already filled
        strokeLinecap={warning ? 'butt' : 'round'}
        strokeDasharray={`${redDonutDasharray} ${circumference}`}
        transform={`rotate(-90 ${viewBoxCenter} ${viewBoxCenter})`}
      />
    </svg>
  )
}

Donut.propTypes = {
  percentage: PropTypes.number.isRequired,
  warning: PropTypes.bool.isRequired,
}

export default Donut
