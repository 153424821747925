import styled from 'styled-components'
import { grayDarker } from '@bufferapp/ui/style/colors'

export const Title = styled.h4`
  margin: 0 32px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: ${grayDarker};
`

export const ButtonWrapper = styled.div`
  padding: 0px 32px;
`

export const EmptyWrapper = styled.div`
  padding: 0px 32px;
  flex: 1;
  text-align: center;
`
