import { Link, Text } from '@bufferapp/ui'
import { borderRadius } from '@bufferapp/ui/style/borders'
import {
  blue,
  blueDark,
  blueLightest,
  grayDark,
  grayDarker,
  red,
  white,
} from '@bufferapp/ui/style/colors'
import styled from 'styled-components'

export const NoteWrapper = styled.li<{ $highlighted: boolean }>`
  position: relative;
  display: flex;
  padding: 5px 8px;
  margin: 5px 8px;
  border-radius: ${borderRadius};
  background: ${(props): string => (props.$highlighted ? blueLightest : white)};
`

export const AvatarWrapper = styled.div`
  margin: 3px 8px 0px 0px;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  min-width: 0;
  gap: 3px;
  flex-grow: 1;
`

export const Label = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const AuthorText = styled(Text).attrs({
  type: 'label',
})`
  margin-right: 5px;
  font-weight: 500;
`

export const CreatedAtText = styled(Text).attrs({ type: 'label' })`
  font-size: 12px;
  flex-shrink: 0;
`

export const NoteText = styled.span`
  margin: 0;
  font-size: 14px;
  color: ${grayDarker};
  word-break: break-word;
`

export const EditedText = styled(Text).attrs({ type: 'label', color: 'gray' })`
  margin: 0;
  font-size: 12px;
  flex-shrink: 0;
`

export const EditInput = styled.input`
  border: none;

  &:focus-visible {
    outline: none;
  }
`

export const Actions = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

type ActionButtonProps = {
  isEditing?: boolean
}

export const PrimaryButton = styled.span<ActionButtonProps>`
  font-size: 12px;
  font-weight: 400;
  color: ${(props): string => (!props.isEditing ? grayDark : blue)};
  cursor: pointer;

  &:hover {
    color: ${blue};
  }
`

export const ActionDivider = styled.span`
  color: ${grayDark};
`

export const SecondaryButton = styled(PrimaryButton)`
  color: ${(props): string => (!props.isEditing ? red : blue)};
`

export const StyledLink = styled(Link)`
  font-weight: 400;
`

export const ShowMoreTextButton = styled.button`
  background: none;
  border: none;
  padding: 0;
  color: ${blue};
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${blueDark};
  }

  &:focus-visible {
    outline: none;
  }
`
