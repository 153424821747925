import styled, {
  css,
  type FlattenSimpleInterpolation,
  type FlattenInterpolation,
  keyframes,
  type ThemeProps,
} from 'styled-components'
import {
  transparent,
  grayDarker,
  grayDark,
  grayLight,
  grayLighter,
  white,
  blue,
} from '@bufferapp/ui/style/colors'
import { Link } from 'react-router-dom'

type StyleProp = {
  color?: string
  displaySkeleton: boolean
}

const pulse = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
`

const animation = (): FlattenSimpleInterpolation => css`
  ${pulse} 2s ease-in-out infinite alternate;
`

export const skeletonStyles = css`
  border: ${transparent};
  fill: ${transparent};
  *:before {
    background-color: ${transparent};
  }
  border-color: ${transparent};
  background-color: ${transparent};
  color: ${transparent};
  cursor: auto;
  background-image: linear-gradient(
    270deg,
    ${grayLighter} 18.75%,
    ${grayLight} 100%
  );
  animation: ${animation};
  user-select: none;
`

export const Color = styled.div`
  height: 12px;
  max-width: 12px;
  width: 100%;
  border-radius: 50%;
  position: absolute;
  background-color: ${(props: StyleProp): string | undefined =>
    props.displaySkeleton ? grayLighter : props.color};
  margin: 8px 10px 0px 0px;
`

// using grid layout to align the tag list details
export const Container = styled.li<{ locked?: boolean }>`
  display: grid;
  padding: 10px 24px;
  grid-template-columns: minmax(250px, 2fr) 1fr 1fr 1fr 1fr 1fr 0.5fr;
  grid-column-gap: 20px;
  border-top: 1px solid ${grayLight};
  transition: background-color 250ms ease-in-out;
  cursor: ${({ locked }): string => (locked ? 'not-allowed' : 'pointer')};
  pointer-events: ${({ locked }): string => (locked ? 'none' : 'initial')};
  background: ${({ locked }): string => (locked ? grayLighter : white)};
  :hover {
    background-color: ${grayLighter};
  }
`

export const NameContainer = styled.span<{ to: string }>`
  display: inline-flex;
  position: relative;
  h3 {
    margin: 0px 0px 0px 20px;
  }
`

export const StyledLink = styled(Link)`
  display: inline-flex;
  text-decoration: none;
  position: relative;
  color: ${grayDarker};
  :hover {
    transition: color 150ms ease-in;
    color: ${blue};
  }
  h3 {
    margin: 0px 0px 0px 25px;
    color: inherit;
  }
`

export const Group = styled.div`
  display: flex;
  width: 100%;
`

export const Icon = styled.p`
  margin-right: 8px;
  svg {
    color: ${(props: StyleProp): string =>
      props.displaySkeleton ? transparent : grayDarker};
  }
`

export const LeftWrapper = styled.div`
  p {
    margin: 4px 0px 0px 20px;
  }
  display: flex;
  align-items: center;
`

export const ButtonWrapper = styled.div<{ showViewReportSkeleton: boolean }>`
  cursor: pointer;
  pointer-events: initial;

  p[class*='SelectItemTitle'] {
    margin: 0;
  }

  li:nth-child(3) {
    ${({
      showViewReportSkeleton,
    }): FlattenInterpolation<ThemeProps<any>> | '' =>
      showViewReportSkeleton ? skeletonStyles : ''}
  }
`

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  > svg {
    color: ${grayDark};
  }
`
