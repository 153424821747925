import type { NewIdea } from '~publish/components/IdeaComposer/IdeaComposer'
import type { Idea, IdeaContent } from '../types'

type UpdateIdeaFunction = (idea: Idea, options?: { source?: string }) => void
type CreateIdeaFunction = (
  idea: { content: IdeaContent; groupId?: string },
  options: { source?: string },
) => void

type CreateOrUpdateIdeaArgs = {
  idea: Idea | NewIdea | null
  updateIdea: UpdateIdeaFunction
  createIdea: CreateIdeaFunction
  source?: string | undefined
}

export const createOrUpdateIdea = (args: CreateOrUpdateIdeaArgs): void => {
  if (!args.idea) return

  const { idea, updateIdea, createIdea, source } = args
  if (idea.id) {
    updateIdea(idea as Idea, { source })
  } else {
    createIdea(idea, { source })
  }
}
