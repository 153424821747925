import type { TText } from '@udecode/plate'
import type { NodeEntry, Range } from 'slate'
import twitterText from 'twitter-text'

type UrlWithRange = {
  url: string
  range: Range
}

type UrlWithIndices = {
  url: string
  indices: [number, number]
}

export const Url = {
  isValid(str: string): boolean {
    const [first] = Url.extractUrlsWithIndices(str)
    return !!first && first.url === str
  },

  hasUrl(str: string): boolean {
    return !!Url.extractUrlsWithIndices(str).length
  },

  extractUrlsWithIndices(text: string): UrlWithIndices[] {
    return twitterText.extractUrlsWithIndices(text)
  },

  extractUrlsWithRange([node, path]: NodeEntry<TText>): UrlWithRange[] {
    return Url.extractUrlsWithIndices(node?.text)
      ?.reverse()
      .map(({ url, indices }) => {
        const range = {
          anchor: { path, offset: indices[0] },
          focus: { path, offset: indices[1] },
        }

        return {
          url,
          range,
        }
      })
  },
}
