import styled, {
  keyframes,
  css,
  type FlattenSimpleInterpolation,
} from 'styled-components'
import Notice from '@bufferapp/ui/Notice'

type CSSRule = string | number | false | FlattenSimpleInterpolation | undefined

export const CalendarPage = styled.main<{ useNewCalendar: boolean }>`
  ${(props): CSSRule =>
    !props.useNewCalendar &&
    css`
      padding: 24px;
    `}
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
`

const navigateRight = keyframes`
  0% {
    margin-left: 25px;
  }

  100% {
    margin-left: 0;
  }
`

const navigateLeft = keyframes`
  0% {
    margin-left: -25px;
  }

  100% {
    margin-left: 0;
  }
`

export const CalendarGridWrapper = styled.div`
  overflow: auto;
  height: 100%;
  box-sizing: border-box;
`

export const CalendarTable = styled.table<{
  showRightAnimation: boolean
  showLeftAnimation: boolean
}>`
  position: relative;
  height: 100%;
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
  box-sizing: border-box;
  ${(props): CSSRule =>
    props.showRightAnimation &&
    css`
      animation: ${navigateRight} 0.2s;
    `}
  ${(props): CSSRule =>
    props.showLeftAnimation &&
    css`
      animation: ${navigateLeft} 0.2s;
    `}
`

export const CalendarHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`

export const CalendarHeaderRightSide = styled.span`
  display: flex;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 8px;
  }
`

export const CalendarNotice = styled(Notice)`
  margin-bottom: 16px;
`
