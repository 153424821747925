import React from 'react'

import { SERVICE_GOOGLEBUSINESS } from '~publish/legacy/constants'
import {
  getFormattedDateAndTime,
  parseGoogleBusinessLinks,
} from '~publish/legacy/utils/channels/googlebusiness/helpers'
import type { GoogleBusinessContent } from '../../../types'
import * as Styles from './styles'
import {
  getPreviewCharLimit,
  getPreviewTitleCharLimit,
  textExceedsCharLimit,
  truncateText,
} from '../../../Common/utils'
import { ImagePreview } from '../ImagePreview'

const maxLength = getPreviewCharLimit(SERVICE_GOOGLEBUSINESS)
const maxLengthTitleAndCode = getPreviewTitleCharLimit(SERVICE_GOOGLEBUSINESS)

const getTextToDisplay = ({
  length,
  text,
}: {
  length: number
  text?: string
}): string | undefined => {
  let textToDisplay = text

  textToDisplay = truncateText(textToDisplay, length)

  return textToDisplay
}

const PostBody = ({
  content,
}: {
  content: GoogleBusinessContent
}): JSX.Element => {
  const { channelData, images, text, updateType } = content
  const googlebusiness = channelData?.googlebusiness

  const textToDisplay = getTextToDisplay({ length: maxLength, text })
  const titleToDisplay = getTextToDisplay({
    length: maxLengthTitleAndCode,
    text: googlebusiness?.title || '',
  })
  const codeToDisplay = getTextToDisplay({
    length: maxLengthTitleAndCode,
    text: googlebusiness?.code || '',
  })

  const isWhatsNew = updateType === 'whats_new'
  const isEvent = updateType === 'event'
  const isOffer = updateType === 'offer'

  const shouldShowImage = images && images.length > 0
  const shouldShowTitleAndDate = (isEvent || isOffer) && titleToDisplay
  const shouldShowDate = googlebusiness?.start_date && googlebusiness?.end_date
  const shouldShowOfferLink =
    isOffer && googlebusiness?.link && googlebusiness?.terms

  const image = images && images[0]

  return (
    <Styles.Body>
      {shouldShowImage && <ImagePreview image={image} />}
      <Styles.Content hasImage={!!shouldShowImage} isWhatsNew={isWhatsNew}>
        {shouldShowTitleAndDate && (
          <>
            <Styles.TitleWrapper>
              <Styles.Title isOffer={isOffer} data-testid="title">
                {titleToDisplay}
              </Styles.Title>
              {isOffer && <Styles.Tag size="large" data-testid="offer-tag" />}
            </Styles.TitleWrapper>
            {shouldShowDate && (
              <Styles.Date>
                {getFormattedDateAndTime({
                  startDate: googlebusiness?.start_date,
                  endDate: googlebusiness?.end_date,
                  startTime: googlebusiness?.start_time,
                  endTime: googlebusiness?.end_time,
                })}
              </Styles.Date>
            )}
          </>
        )}
        <Styles.LinkifiedText
          links={parseGoogleBusinessLinks(textToDisplay)}
          size="mini"
          whitespace="pre-wrap"
          newTab
        >
          {textToDisplay}
        </Styles.LinkifiedText>
        {text && textExceedsCharLimit(text, maxLength) && (
          <Styles.More>... More</Styles.More>
        )}
        {isOffer && googlebusiness?.code && (
          <Styles.OfferDetails>
            <Styles.OfferDirections>
              Show this code at the store
            </Styles.OfferDirections>
            <Styles.OfferCode data-testid="offer-code">
              {codeToDisplay}
            </Styles.OfferCode>
            <Styles.OfferDates>
              Valid{' '}
              {getFormattedDateAndTime({
                startDate: googlebusiness?.start_date,
                endDate: googlebusiness?.end_date,
                startTime: googlebusiness?.start_time,
                endTime: googlebusiness?.end_time,
                format: 'M/D/YYYY',
              })}
            </Styles.OfferDates>
          </Styles.OfferDetails>
        )}
        {shouldShowOfferLink && (
          <Styles.OfferLink>Redeem offer</Styles.OfferLink>
        )}
      </Styles.Content>
    </Styles.Body>
  )
}

export default PostBody
