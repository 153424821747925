import React from 'react'
import PropTypes from 'prop-types'
import { Checkmark } from '@bufferapp/ui/Icon'

import {
  CircleColor,
  ColorSwatchesContainer,
  CheckmarkWrapper,
  CircleColorWrapper,
  colorSwatches,
} from '../../styles'

import { getColorContrast } from '../../utils/HexValidations'

// @ts-expect-error TS(7031) FIXME: Binding element 'colorSelected' implicitly has an ... Remove this comment to see the full error message
const ColorSwatches = ({ colorSelected, onColorChange, onChange }) => (
  <ColorSwatchesContainer>
    {Object.keys(colorSwatches).map((key) => (
      <CircleColorWrapper key={key.toString()}>
        <CircleColor
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          color={colorSwatches[key]}
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          colorContrast={getColorContrast(colorSwatches[key])}
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          onClick={() => onColorChange(colorSwatches[key], onChange)}
          selectable
          aria-label={`link color ${key}`}
        >
          {/* @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
          {colorSelected === colorSwatches[key] && (
            <CheckmarkWrapper>
              <Checkmark />
            </CheckmarkWrapper>
          )}
        </CircleColor>
      </CircleColorWrapper>
    ))}
  </ColorSwatchesContainer>
)

ColorSwatches.propTypes = {
  onChange: PropTypes.func,
  onColorChange: PropTypes.func,
  colorSelected: PropTypes.string,
}

ColorSwatches.defaultProps = {
  onChange: () => {},
  onColorChange: () => {},
  colorSelected: '',
}

export default ColorSwatches
