import styled, { css, type CSSProp } from 'styled-components'
import { fontFamily } from '@bufferapp/ui/style/fonts'
import {
  white,
  grayLight,
  gray,
  grayDark,
  purpleLightest,
  purple,
  purpleDark,
} from '@bufferapp/ui/style/colors'

export const Button = styled.button<{
  disabled?: boolean
  secondary?: boolean
  align?: 'left' | 'center'
  fullWidth?: boolean
}>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ align }): string =>
    align === 'left' ? 'flex-start' : 'center'};
  align-items: center;
  gap: 8px;
  padding: 8px;
  cursor: ${({ disabled }): string => (disabled ? 'not-allowed' : 'pointer')};
  border-radius: 4px;
  font-family: ${fontFamily};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition-property: background-color, border-color, color;
  transition-duration: 0.1s;
  transition-timing-function: ease-in;
  user-select: none;
  width: ${({ fullWidth }): string => (fullWidth ? '100%' : 'initial')};

  svg {
    width: 12px;
    height: 12px;
  }

  ${({ secondary, disabled }): CSSProp => {
    return secondary
      ? getSecondaryStyles({ disabled })
      : getPrimaryStyles({ disabled })
  }};
`

function getPrimaryStyles({ disabled }: { disabled?: boolean }): CSSProp {
  return css`
    background: ${disabled ? purpleLightest : purple};
    border: 1px solid ${disabled ? purpleLightest : purple};
    color: ${white};

    svg {
      color: ${white};
      fill: ${white};
    }

    &:hover:enabled {
      background: ${purpleDark};
      border-color: ${purpleDark};
    }

    &:focus:enabled,
    &:active:enabled {
      background: ${purpleDark};
      border-color: ${purpleDark};
    }
  `
}

function getSecondaryStyles({ disabled }: { disabled?: boolean }): CSSProp {
  return css`
    background: ${white};
    border: 1px solid ${disabled ? grayLight : gray};
    color: ${disabled ? gray : grayDark};

    svg {
      color: ${disabled ? purpleLightest : purple};
      fill: ${disabled ? purpleLightest : purple};
    }

    &:hover:enabled {
      border-color: ${grayDark};
    }
  `
}
