import dayjs from 'dayjs'
import twitter from 'twitter-text'

const parseGoogleBusinessLinks = (text?: string | null) =>
  twitter
    .extractEntitiesWithIndices(text || '')
    .map((entity) => {
      if ('url' in entity && entity.url) {
        return {
          displayString: entity.url,
          indices: entity.indices,
          rawString: entity.url,
        }
      }
      return null
    })
    .filter((entity) => entity !== null)

export { parseGoogleBusinessLinks }

// Date Formatting
export const getPostDate = (scheduledAt?: number | null) => {
  return scheduledAt
    ? dayjs.unix(scheduledAt).utc().format('MMM D, YYYY')
    : dayjs.utc().format('MMM D, YYYY')
}

const formatDate = (date: number, format = 'MMM D') => {
  return dayjs.unix(date).utc().format(format)
}

export const getFormattedDateAndTime = ({
  startDate,
  endDate,
  startTime,
  endTime,
  format,
}: {
  startDate?: number | null
  endDate?: number | null
  startTime?: string | null
  endTime?: string | null
  format?: string
}) => {
  const formattedStartDate = startTime
    ? `${formatDate(Number(startDate), format)}, ${startTime}`
    : formatDate(Number(startDate), format)
  const formattedEndDate = endTime
    ? `${formatDate(Number(endDate), format)}, ${endTime}`
    : formatDate(Number(endDate), format)

  return `${formattedStartDate} - ${formattedEndDate}`
}

// CTA
export const getCtaText = (cta?: string | null) => {
  switch (cta) {
    case 'book':
      return 'Book'
    case 'order':
      return 'Order online'
    case 'shop':
      return 'Buy'
    case 'learn_more':
      return 'Learn more'
    case 'signup':
      return 'Sign up'
    default:
      return ''
  }
}
