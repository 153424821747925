import { useParams, useRouteMatch } from 'react-router-dom'
import { capitalize } from '~publish/helpers/capitalize'
import { useQueryParam } from '~publish/hooks/useQueryParam'
import {
  allChannels,
  tagTab,
  profileTabPages,
  channel,
} from '~publish/legacy/routes'

export const useNotesCtaView = (): string => {
  const { tabId, tab } = useParams<{
    tabId?: string
    tab?: string
  }>()
  const [queryTab] = useQueryParam('tab')
  const isAllChannelsPage = !!useRouteMatch(allChannels.route)
  const isChannelPage = !!useRouteMatch(channel.route)
  const isTagsPage = useRouteMatch(tagTab.route)
  const isProfilePage = !!useRouteMatch(profileTabPages.route)

  if (isAllChannelsPage) {
    return `allChannels${capitalize(queryTab || 'queue')}`
  }

  if (isChannelPage) {
    return `channel${capitalize(queryTab || 'queue')}`
  }

  if (isTagsPage && tab) {
    return `tags${capitalize(tab)}`
  }

  if (isProfilePage && tabId) {
    return `profile${capitalize(tabId)}`
  }

  return 'unknown'
}
