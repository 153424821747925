import type { BufferEditor } from '~publish/legacy/editor/BufferEditor/types.plate'
import { EditorEntityData } from '~publish/legacy/editor/helpers/EditorEntityData'
import { wrapNodeAtPosition } from '~publish/legacy/editor/transforms/wrapNodeAtPosition'
import type { TextToNodeOptions } from '../../types'
import { LinkElement } from '../nodes/LinkElement'
import { findAndWrapNewLinks } from './findAndWrapNewLinks'

export const textToLinkNodes = (
  editor: BufferEditor,
  { entities }: TextToNodeOptions = {},
) => {
  // first wrap the links provided by serialize
  EditorEntityData.getLinks(entities).forEach((linkData) => {
    const linkElement = LinkElement.fromEntity(linkData)
    if (linkElement) {
      wrapNodeAtPosition(editor, linkElement, {
        start: linkData.start,
        length: linkData.length,
      })
    }
  })

  // and then wrap any other links
  findAndWrapNewLinks(editor)
}
