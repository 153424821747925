import type { ParagraphElement } from '../plugins/paragraph/nodes/ParagraphElement'

const isFirstLetterLowerCase = (str: string): boolean => {
  const firstLetter = str.charAt(0)
  return firstLetter === firstLetter.toLowerCase()
}

export const capitalizeTextAsSelection = (
  node: ParagraphElement,
  text: string,
): ParagraphElement => {
  const nodeText: string = (node?.children[0]?.text || '').toString()
  const firstLetter: string = nodeText.charAt(0)

  if (isFirstLetterLowerCase(text)) {
    node.children[0].text = firstLetter.toLowerCase() + nodeText.slice(1)
  } else {
    node.children[0].text = firstLetter.toUpperCase() + nodeText.slice(1)
  }

  return node
}
