import React, { useCallback, useState } from 'react'
import {
  AlertDialog,
  Button,
  IconButton,
  TrashIcon,
} from '@buffer-mono/popcorn'

import { useAppDispatch } from '~publish/legacy/store'
import { deleteGroup as deleteGroupThunk } from './thunks'

export function DeleteGroupButton({
  groupId,
  onDeleted,
}: {
  groupId: string
  onDeleted: () => void
}): JSX.Element | null {
  const dispatch = useAppDispatch()
  const [dialogOpen, setDialogOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const deleteGroup = React.useCallback(
    async (groupId: string): Promise<void> => {
      await dispatch(deleteGroupThunk({ groupId }))
      setLoading(true)
      onDeleted()
      setLoading(false)
    },
    [dispatch, onDeleted],
  )

  const handleConfirmDelete: React.MouseEventHandler = useCallback(
    async (e) => {
      e.preventDefault()

      await deleteGroup(groupId)

      setDialogOpen(false)
      setLoading(false)
    },
    [groupId, deleteGroup],
  )

  return (
    <AlertDialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <AlertDialog.Trigger>
        <IconButton label="Delete" tooltip="Delete" variant="tertiary">
          <TrashIcon cursor="pointer" />
        </IconButton>
      </AlertDialog.Trigger>
      <AlertDialog.Content>
        <AlertDialog.Title>Delete group?</AlertDialog.Title>
        <AlertDialog.Description>
          This group will be permanently deleted. This action cannot be undone.
        </AlertDialog.Description>
        <AlertDialog.Actions>
          <AlertDialog.Cancel>
            <Button variant="tertiary">Cancel</Button>
          </AlertDialog.Cancel>
          <AlertDialog.Action>
            <Button
              variant="critical"
              loading={loading}
              onClick={handleConfirmDelete}
            >
              {loading ? 'Deleting...' : 'Delete'}
            </Button>
          </AlertDialog.Action>
        </AlertDialog.Actions>
      </AlertDialog.Content>
    </AlertDialog>
  )
}
