import React from 'react'

import { white } from '@bufferapp/ui/style/colors'

export function Wand(): JSX.Element {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="wand"
    >
      <path
        d="M27.4876 5.1533L22.8472 0.512979C22.506 0.170628 22.0581 0 21.6102 0C21.1623 0 20.7144 0.170628 20.3726 0.512979L0.512979 20.3726C-0.170628 21.0562 -0.170628 22.1642 0.512979 22.8472L5.1533 27.4876C5.49511 27.8294 5.94301 28 6.39036 28C6.83826 28 7.28616 27.8294 7.62796 27.4876L27.4876 7.62741C28.1712 6.9449 28.1712 5.83636 27.4876 5.1533ZM19.6578 11.1269L16.8736 8.34274L21.6096 3.60671L24.3938 6.39091L19.6578 11.1269Z"
        fill={white}
      />
    </svg>
  )
}
