import React from 'react'

import type { Draft } from '../../entities/Draft'
import VideoTitle from './VideoTitle'
import { Wrapper } from './styles'

const VideoTitleBar = ({ draft }: { draft: Draft }): JSX.Element => {
  if (
    !draft.hasVideoAttachment() ||
    draft.service.name !== 'facebook' ||
    draft.isFacebookReelPost() ||
    draft.isFacebookStoryPost()
  ) {
    return <></>
  }

  return (
    <Wrapper>
      <VideoTitle draftId={draft.id} title={draft.video?.name as string} />
    </Wrapper>
  )
}

export default VideoTitleBar
