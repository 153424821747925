import React, { type FormEvent, useState } from 'react'
import { Button, Input, Tooltip } from '@bufferapp/ui'
import { VisuallyHidden } from '@buffer-mono/popcorn'
import { addEntityNote } from '~publish/legacy/post/thunks/addEntityNote'
import type { EntityWithNotes } from '~publish/gql/graphql'
import { useAppDispatch } from '~publish/legacy/store'
import { AddNoteForm, InputWrapper } from './styles'
import { NOTES_LIMIT, NoteType } from '../constants'

type Props = {
  entityId: string
  entityType: EntityWithNotes
  notesCount: number
}

export const AddNote = ({
  entityId,
  entityType,
  notesCount,
}: Props): JSX.Element => {
  const dispatch = useAppDispatch()
  const [newNote, setNewNote] = useState<string>('')

  const isEmpty = notesCount === 0
  const notesLimitReached = notesCount === NOTES_LIMIT
  const isDisabled = notesLimitReached || !newNote?.length

  const dataTip = notesLimitReached
    ? 'You have reached the maximum number of notes'
    : 'Enter some text to add a note'

  const handleSaveNote = (e: FormEvent): void => {
    if (isDisabled) return

    dispatch(
      addEntityNote({
        entityId,
        entityType,
        text: newNote,
        noteType: NoteType.UserGenerated,
      }),
    )
    setNewNote('')
    e.preventDefault()
  }

  return (
    <AddNoteForm
      onSubmit={handleSaveNote}
      autoComplete="off"
      $isEmpty={isEmpty}
    >
      <InputWrapper>
        <VisuallyHidden as="label" htmlFor="newNoteInputId">
          Note
        </VisuallyHidden>
        <Input
          // @ts-expect-error TS(2322) FIXME: Type '{ type: string; value: string; onChange: (e:... Remove this comment to see the full error message
          type="input"
          id="newNoteInputId"
          value={newNote}
          onChange={(e: InputEvent): void =>
            setNewNote((e?.target as HTMLInputElement)?.value)
          }
          name="Add note"
          placeholder="Add a note..."
          maxLength={'500'}
        />
      </InputWrapper>
      {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element; label: string; }' is no... Remove this comment to see the full error message */}
      <Tooltip label={dataTip}>
        {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; size: string; onClick: (e: F... Remove this comment to see the full error message */}
        <Button
          type="primary"
          size="small"
          onClick={handleSaveNote}
          label="Add"
          disabled={isDisabled}
          aria-label="Add note"
        />
      </Tooltip>
    </AddNoteForm>
  )
}
