import { useSelector } from 'react-redux'
import {
  startOfWeek,
  startOfMonth,
  endOfMonth,
  endOfWeek,
  getWeeksInMonth,
} from 'date-fns'
import { selectWeekStartsMonday } from '~publish/legacy/user/selectors'

export function useMonthlyDates(aDate: Date): {
  monthStart: Date
  monthEnd: Date
  calendarStart: Date
  calendarEnd: Date
  numberOfWeeks: number
  weekStartsOn: 0 | 1
} {
  const weekStartsOn = useSelector(selectWeekStartsMonday) ? 1 : 0

  const monthStart = startOfMonth(aDate)
  const calendarStart = startOfWeek(monthStart, {
    weekStartsOn,
  })

  const monthEnd = endOfMonth(aDate)
  const calendarEnd = endOfWeek(monthEnd, {
    weekStartsOn,
  })

  const numberOfWeeks = getWeeksInMonth(aDate, {
    weekStartsOn,
  })

  return {
    monthStart,
    monthEnd,
    calendarStart,
    calendarEnd,
    numberOfWeeks,
    weekStartsOn,
  }
}
