import React from 'react'
import { AnimatedList } from '~publish/legacy/shared-components'
import ExamplePost from '../ExamplePost'

const SkeletonPosts = () => {
  return (
    <AnimatedList>
      <ExamplePost displaySkeleton />
      <ExamplePost displaySkeleton />
    </AnimatedList>
  )
}

export default SkeletonPosts
