import React from 'react'

import Avatar from '@bufferapp/ui/Avatar'
import MoreIcon from '@bufferapp/ui/Icon/Icons/More'
import CrossIcon from '@bufferapp/ui/Icon/Icons/Cross'
import MessengerIcon from '@bufferapp/ui/Icon/Icons/Messenger'
import ShareArrowFilledIcon from '@bufferapp/ui/Icon/Icons/ShareArrowFilled'

import { DEFAULT_AVATAR } from '../../../Common/utils'
import type { FacebookContent, ProfilePreview } from '../../../types'
import styles from './Story.module.css'

import { VideoPlayer as VideoPlayerCommon } from '../../../Common/VideoPlayer'
import type { Video } from '~publish/legacy/composer/composer/entities/factories'

export function Story({
  content,
  profile,
}: {
  content: FacebookContent
  profile: ProfilePreview
}): JSX.Element {
  const { avatar, username } = profile
  const { images, video } = content

  return (
    <div data-testid="story" className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.info}>
          <Avatar
            data-testid="avatar"
            src={avatar}
            alt={username}
            size="small"
            fallbackUrl={DEFAULT_AVATAR}
          />
          <div className={styles.username}>{profile.username}</div>
          <div className={styles.ago}>21h</div>
          <div className={styles.dots}>
            <MoreIcon />
          </div>
          <div className={styles.close}>
            <CrossIcon />
          </div>
        </div>
      </div>
      <div className={styles.content}>
        {images && images?.length > 0 ? (
          <div
            className={styles.image}
            style={{ backgroundImage: `url(${images[0].url})` }}
          ></div>
        ) : (
          <VideoPlayerCommon video={video as Video} />
        )}
      </div>
      <div className={styles.footer}>
        <span className={styles.share}>
          <ShareArrowFilledIcon />
        </span>
        {/* <div className={styles.like}>Share</div> */}
        <div className={styles.message}>
          <MessengerIcon />
          <span>Send message...</span>
        </div>
      </div>
    </div>
  )
}
