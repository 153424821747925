import React, { memo } from 'react'

import * as Styled from './FunFact.styles'

// We need to memoize this component because the Composer listens to and
// re-renders on every keystroke event. By memoizing, we avoid re-rendering
// the FunFact component when a user types into the Composer.
export const FunFact = memo(function FunFactComponent(): JSX.Element {
  const randomFactOptions = [
    'The AI Assistant will tailor its suggestions depending on the channel you are using in the Buffer composer!',
    'Our AI Assistant supports multiple languages (e.g Spanish, Korean, Japanese, Chinese, Arabic and more!)',
    "You can use the AI Assistant to summarize text, articles and long form content  (just add the text and use the 'summarize' action)",
    "You can ask the AI Assistant to give you ideas for posts (e.g 'Give me 5 ideas for a Thanksgiving posts for Instagram')",
    "Buffer's AI Assistant runs on an AI Model with more than 175 million parameters, making it one of the largest AI models in existence of its kind 🤯",
    "Buffer's AI Assistant takes into consideration each channel's character limits to make sure suggestions don't exceed the limits",
    "Buffer's AI Assistant uses OpenAI model which was trained with more than 570 gigabytes of text",
    'GPT in chatGPT stands for "Generative Pre-trained Transformer," which refers to the underlying model architecture (a type of deep learning model)',
    "Buffer's AI Assistant can help you create Twitter / X Threads and automatically add them to the composer",
    'When you have one network or channel selected, AI Assistant suggests content tailored for that specific channel (e.g Mastodon).',
    "AI Assistant can tell you a joke if you ask it to (we can't guarantee it won't be cheesy though) 😅",
    "Buffer's AI Assistant was first launched to the public in Feb, 2023 (just two months after we started work on it)",
    'You can tell the AI Assistant to write using a certain style or tone—like professional, or friendly, jovial or serious, rude or kind, and in the style of Hulk Hogan or Albert Einstein',
    'Giving more information about your goals and target audience will help improve the AI Assistants suggestions',
    "The AI Assistant can also write poetry (e.g 'write a poem for a small business that sells used cars')",
    'AI can impersonate the tone of some well known writers. Try telling AI to write in the style of William Shakespeare or Jane Austen',
  ]

  const randomFact =
    randomFactOptions[Math.floor(Math.random() * randomFactOptions.length)]

  return (
    <Styled.Wrapper>
      🤖 <strong>Fun Fact</strong>: {randomFact}
    </Styled.Wrapper>
  )
})
