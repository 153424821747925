import styled, { keyframes } from 'styled-components'
import { fontFamily } from '@bufferapp/ui/style/fonts'
import {
  blue,
  white,
  grayLighter,
  gray,
  grayDark,
  grayDarker,
} from '@bufferapp/ui/style/colors'

const moveAnimation = keyframes`
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(8px);
  }
  50% {
    transform: translateX(-8px);
  }
  75% {
    transform: translateX(8px);
  }
  100% {
    transform: translateX(0);
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`

export const IdeaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: ${white};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
  border-radius: 6px;
  max-width: 343px;
  width: 100%;
`

export const Idea = styled.p<{ loading: boolean }>`
  font-family: ${fontFamily};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  padding: 20px 16px;
  margin: 0;
  color: ${(props) => (props.loading ? gray : grayDarker)};
  border-bottom: 1px solid #edebed;
  overflow-wrap: break-word;
  width: 100%;
  box-sizing: border-box;
`

export const CustomizeButton = styled.button`
  width: fit-content;
  font-family: ${fontFamily};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${grayDark};
  background: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    color: ${grayDarker};
  }

  svg {
    margin-right: 4px;
  }
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 9px 16px;
  box-sizing: border-box;
`

export const ErrorMessageContainer = styled.div<{ animate: boolean }>`
  animation: ${(props) => props.animate && moveAnimation} 0.25s ease-in-out 4;
`

export const Button = styled.button<{
  disabledStyles?: boolean
}>`
  border: none;
  background: none;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 12px 20px;
  font-family: ${fontFamily};
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  border-radius: 4px;
  color: ${(props) => (props.disabledStyles ? gray : grayDarker)};

  svg {
    margin-right: 8px;
  }

  &:hover {
    background-color: ${grayLighter};
  }
`

export const ButtonPrimary = styled(Button)`
  color: ${blue};

  &:hover {
    background-color: #eff1ff;
  }
`

export const Divider = styled.div`
  width: 2px;
  height: 26px;
  background-color: #edebed;
  margin: 0 16px;
`

export const LoadingMessage = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: ${fontFamily};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: ${grayDarker};
  margin: 0;
  padding: 10px 0;

  svg {
    margin-right: 8px;
  }
`

const shimmer = keyframes`
  100% {
    transform: translateX(100%);
  }
`

export const SkeletonBoxWrapper = styled.div`
  width: 91%;
  padding: 20px 16px;
  border-bottom: 1px solid #edebed;
`

export const SkeletonBox = styled.span`
  display: inline-block;
  height: 12px;
  position: relative;
  overflow: hidden;
  background-color: #dddbdd;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0,
      rgba(255, 255, 255, 0.2) 20%,
      rgba(255, 255, 255, 0.5) 60%,
      rgba(255, 255, 255, 0)
    );
    animation: ${shimmer} 2s infinite;
    content: '';
  }
`
