import { ChannelIcon, type ChannelType } from '@buffer-mono/popcorn'
import React from 'react'
import type { ComposerProfile } from '../stores/types'
import { escapeParens } from '../utils/StringUtils'
import styles from './css/Profile.module.css'

export const ProfileAvatar = (args: {
  profile: ComposerProfile
  isSelected: boolean
}): JSX.Element => {
  const { profile, isSelected = false } = args

  if (profile.isFake) {
    return (
      <span className={styles.fakeProfilePicture}>
        <ChannelIcon
          size={20}
          color={isSelected ? 'inverted' : 'subtle'}
          type={profile.service.name as ChannelType}
        />
      </span>
    )
  }

  return (
    <>
      <span
        className={styles.profilePicture}
        style={{
          backgroundImage: `url(${escapeParens(profile.images.avatar)})`,
        }}
      />
      <span className={styles.profileIcon}>
        <ChannelIcon
          size={12}
          color="inverted"
          type={profile.service.name as ChannelType}
        />
      </span>
    </>
  )
}
