import type { RpcUpdate } from '~publish/legacy/post/types'
import type { RootState } from '~publish/legacy/store'
import type { Campaign, CampaignTab } from './types'

export const selectCampaignTab = (
  state: RootState,
): CampaignTab | undefined => {
  return state.campaign.page
}

export const selectCurrentCampaign = (
  state: RootState,
): Campaign | undefined => {
  return state.campaign.campaign
}

export const selectCampaignPostById = (
  state: RootState,
  postId: string,
): RpcUpdate | undefined => {
  return state.campaign?.campaignPosts.find((post) => post.id === postId)
}

export const selectCampaignCounts = (
  state: RootState,
): {
  scheduled: number
  sent: number
  draftsCount: number
  approvalsCount: number
} => {
  const { campaign } = state.campaign
  return {
    scheduled: campaign?.scheduled ?? 0,
    sent: campaign?.sent ?? 0,
    draftsCount: campaign?.draftsCount ?? 0,
    approvalsCount: campaign?.approvalsCount ?? 0,
  }
}

export const selectCampaignLoaderDetails = (
  state: RootState,
): {
  pageNumber: number
  loadingMore: boolean
  moreToLoad: boolean
} => {
  return {
    pageNumber: state.campaign.pageNumber ?? 1,
    loadingMore: state.campaign.loadingMore,
    moreToLoad: state.campaign.moreToLoad,
  }
}

export const selectCanViewTagReport = (
  state: RootState,
): boolean | undefined => {
  const { campaign } = state.campaign
  return campaign?.canViewReport
}

export const selectApprovalEntitlement = (state: RootState): boolean => {
  return !!state.organizations?.selected?.hasApprovalFeature
}
