import React, { useCallback, useEffect, useState } from 'react'
import { Cross } from '@bufferapp/ui/Icon'
import { Wrapper, CloseButton } from './styles'
import { useAppSelector } from '~publish/legacy/store'
import { selectAIAssistantResponsive } from '~publish/legacy/ai/state/slice'

/**
 * Sidepanel component for the IdeaComposer.
 * Used to render things like the AIAssistant.
 */
const IdeaComposerSidepanel = ({
  onClose,
  children,
  state,
  onAnimationEnd,
}: {
  onClose: () => void
  children: JSX.Element
  state?: string
  onAnimationEnd?: () => void
}): JSX.Element => {
  const [animation, setAnimation] = useState('')

  const AIAssistantResponsive = useAppSelector((state) =>
    selectAIAssistantResponsive(state),
  )

  const side = AIAssistantResponsive ? 'right' : 'left'

  const onAnimationEndCallback = useCallback(() => {
    onAnimationEnd && onAnimationEnd()
  }, [onAnimationEnd])

  useEffect(() => {
    if (state) setAnimation(state)
    const timeoutId = setTimeout(onAnimationEndCallback, 300)
    return (): void => clearTimeout(timeoutId)
  }, [state, onAnimationEndCallback])

  return (
    <Wrapper data-testid="ideas-sidepanel" className={`${side} ${animation}`}>
      <CloseButton>
        <Cross size="medium" onClick={onClose} data-testid="close-button" />
      </CloseButton>
      {children}
    </Wrapper>
  )
}

export default IdeaComposerSidepanel
