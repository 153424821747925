import styled from 'styled-components'

import { Dialog } from '@buffer-mono/popcorn'
import Button from '@bufferapp/ui/Button'
import CheckmarkIcon from '@bufferapp/ui/Icon/Icons/Checkmark'
import { blue, gray, grayDark, grayDarker } from '@bufferapp/ui/style/colors'
import {
  fontFamily,
  fontSize,
  fontWeightMedium,
} from '@bufferapp/ui/style/fonts'
import Text from '@bufferapp/ui/Text'

export const ModalWrapperStyled = styled(Dialog.Content)`
  padding: 3.5rem 4.5rem 4rem 4.5rem;
  width: 520px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const HeroImage = styled.div`
  justify-content: center;
`

export const HeaderStyled = styled(Text)`
  margin: 0;
  padding: 0;
  text-align: center;
  color: ${grayDarker};
`

export const ListStyled = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
`

export const ListItemStyled = styled.li`
  font-family: ${fontFamily};
  font-size: ${fontSize};
  color: ${grayDark};
  margin-bottom: 0.5rem;
  line-height: 20px;
  display: flex;
  align-items: center;
  column-gap: 4px;
  &:last-child {
    margin-bottom: 0;
  }
`

export const CheckmarkIconStyled = styled(CheckmarkIcon)`
  color: ${blue};
`

export const CenteredText = styled(Text)`
  text-align: center;
  color: ${grayDark};
  margin: 16px 0;
`

export const ModalActionsStyled = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`

export const ButtonStyled = styled(Button)`
  display: flex;
  padding: 0 20px;
`

export const FooterStyled = styled(Text)`
  text-align: center;
  color: ${gray};
  font-weight: ${fontWeightMedium};
  margin-top: 1rem;
  margin-bottom: 0;
`
