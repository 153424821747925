import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import type { Video } from '~publish/legacy/composer/composer/entities/factories'
import { VideoPlayer as VideoPlayerCommon } from '../../../Common/VideoPlayer'

import * as PinterestStyles from '../../styles'
import * as Styles from './styles'

const StyledVideoPlayer = styled(VideoPlayerCommon)`
  ${Styles.Wrapper}
`

function formatDuration(ms: number): string {
  const totalSeconds = Math.floor(ms / 1000)
  const hours = Math.floor(totalSeconds / 3600)
  const minutes = Math.floor((totalSeconds % 3600) / 60)
  const seconds = totalSeconds % 60

  const formattedHours = String(hours).padStart(2, '0')
  const formattedMinutes = String(minutes).padStart(2, '0')
  const formattedSeconds = String(seconds).padStart(2, '0')

  return hours > 0
    ? `${formattedHours}:${formattedMinutes}:${formattedSeconds}`
    : `${formattedMinutes}:${formattedSeconds}`
}

const VideoPlayer = ({ video }: { video: Video }) => {
  const duration = formatDuration(Number(video.durationMs ?? 0))

  return (
    <Styles.VideoWrapper>
      <StyledVideoPlayer video={video} />
      <PinterestStyles.Tag data-testid="duration-tag">
        {duration}
      </PinterestStyles.Tag>
    </Styles.VideoWrapper>
  )
}

VideoPlayer.propTypes = {
  video: PropTypes.shape({
    thumbnail: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
}

export { VideoPlayer }
