// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
export const selectHasApprovalFeature = (state) =>
  state?.organizations?.selected?.hasApprovalFeature

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
export const selectHasFirstCommentEntitlement = (state) =>
  state?.organizations?.selected?.hasFirstCommentFeature

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
export const selectHasFirstCommentForLinkedinEntitlement = (state) =>
  state?.organizations?.selected?.hasFirstCommentFeatureForLinkedin

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
export const selectCurrentOrganizationId = (state) =>
  state?.organizations?.selected?.id

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
export const selectHasFeature = (state, feature) =>
  state?.organizations?.selected?.[feature] || false

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
export const selectHasContentFeature = (state) =>
  state?.organizations?.selected?.hasContentFeature

// @ts-expect-error TS(7006) FIXME: Parameter 'state' implicitly has an 'any' type.
export const selectShouldShowNBMigration = (state) =>
  state?.organizations?.selected?.shouldShowNBMigrationModal
