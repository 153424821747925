import type { RootState } from '~publish/legacy/store'
import { createSelector } from '@reduxjs/toolkit'
import type { Media } from '~publish/pages/Create/types'
import { UploadProgress } from '../values/UploadProgress'
import { uploadsAdapter } from './uploadsAdapter'
import { uploadsSlice } from './slice'
import { type BufferUpload, Upload } from '../entities/Upload'

type UploaderSelector<T> = (state: RootState, uploaderId: string) => T

export const { selectAll: selectAllUploads, selectById: selectUploadById } =
  uploadsAdapter.getSelectors<RootState>((state) => state[uploadsSlice.name])

export const selectRemovedUploads = (state: RootState): Upload[] =>
  state[uploadsSlice.name].removedUploads

const selectUploaderId = (_state: RootState, uploaderId: string): string =>
  uploaderId

export const selectUploaderFiles: UploaderSelector<Upload[]> = createSelector(
  selectAllUploads,
  selectUploaderId,
  (uploads, uploaderId) => uploads.filter((u) => u.uploaderId === uploaderId),
)

export const selectPendingUploads: UploaderSelector<Upload[]> = createSelector(
  selectUploaderFiles,
  (uploads) => uploads.filter(Upload.isPending),
)

export const selectIsUploading: UploaderSelector<boolean> = createSelector(
  selectPendingUploads,
  (uploads) => uploads.length > 0,
)

export const selectPendingCount: UploaderSelector<number> = createSelector(
  selectPendingUploads,
  (uploads) => uploads.length,
)

export const selectPendingProgress: UploaderSelector<number> = createSelector(
  selectPendingUploads,
  (uploads) =>
    UploadProgress.getPercentage(uploads.map((upload) => upload.progress)),
)

export const selectCompletedUploads: UploaderSelector<BufferUpload[]> =
  createSelector(selectUploaderFiles, (uploads) =>
    uploads.filter(Upload.isCompleted),
  )

export const selectCompletedCount: UploaderSelector<number> = createSelector(
  selectCompletedUploads,
  (uploads) => uploads.length,
)

export const selectCurrentFiles: UploaderSelector<Upload[]> = createSelector(
  selectPendingUploads,
  selectCompletedUploads,
  (pending, completed) => [...pending, ...completed],
)

export const selectCurrentFileCount: UploaderSelector<number> = createSelector(
  selectCurrentFiles,
  (files) => files.length,
)

export const selectIncludedMedia: UploaderSelector<Media[]> = createSelector(
  selectCompletedUploads,
  (uploads) => uploads.map(Upload.toMedia),
)

export const selectUploadToReplace = (
  state: RootState,
  upload: Upload,
): Upload | undefined => {
  const canvaId = Upload.getCanvaId(upload)

  if (!canvaId) return

  return selectUploaderFiles(state, upload.uploaderId).find(
    (u) => u.id !== upload.id && Upload.getCanvaId(u) === canvaId,
  )
}
