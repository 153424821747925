import styled from 'styled-components'
import { grayDarker, white, yellowDarker } from '@bufferapp/ui/style/colors'
import { borderRadius } from '@bufferapp/ui/style/borders'
import * as Tooltip from '@radix-ui/react-tooltip'
import { fontFamily, fontWeight } from '@bufferapp/ui/style/fonts'

export const StyledIconWrapper = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
`

export const StyledTagWrapper = styled.button`
  color: white;
  display: flex;
  padding: 4px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: ${borderRadius};
  border: none;
  cursor: pointer;
  margin-left: 4px;
`

export const ValidationWarningWrapper = styled.div`
  position: absolute;
  top: -6px;
  left: -4px;
  background: ${white};
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    transform: scale(1.15);
  }
`
export const ValidationWarningIcon = styled.div`
  color: ${yellowDarker};
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
  }
`

export const HoverEditButtonWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const HoverEditButton = styled.button`
  color: white;
  display: flex;
  padding: 5px 10px;
  background: rgba(0, 0, 0, 0.8);
  border-radius: ${borderRadius};
  border: none;
  cursor: pointer;
`

export const VideoTag = styled.span`
  position: absolute;
  left: 8px;
  bottom: 8px;

  box-sizing: border-box;
  width: 24px;
  height: 24px;
  padding: 0 4px;
  color: ${white};
  border-radius: 50%;
  background-color: rgb(245 245 245 / 38%);
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 12px;
    height: 12px;
  }
`

export const ThumbnailOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0.239, 0.239, 0.239, 0.15);
`

export const TooltipContent = styled(Tooltip.Content)`
  font-family: ${fontFamily};
  font-size: 12px;
  line-height: 14px;
  font-weight: ${fontWeight};
  border-radius: 4px;
  border: none;
  padding: 6px 10px;
  color: white;
  background-color: ${grayDarker};
  z-index: 5000;
  max-width: 300px;
  box-shadow: 2px 2px 10px hsl(0deg 0% 0% / 10%);
`
