import styled from 'styled-components'
import TagIcon from '@bufferapp/ui/Icon/Icons/Tag'

import { LinkifiedText as LinkifiedTextCommon } from '~publish/legacy/shared-components/LinkifiedText'
import {
  previewBlack,
  previewGrayDark,
  previewGrayMedium,
} from '../../../Common/styles'

const offerTagYellow = '#EAB63E'
const googlebusinessBlue = '#4A7DE2'

export const Body = styled.div`
  margin: 0;
`

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const getContentTopMargin = (props) => {
  if (props.hasImage && props.isWhatsNew) {
    return 32
  }
  if (props.hasImage) {
    return 16
  }
  return 24
}

export const Content = styled.div<{ hasImage: boolean; isWhatsNew: boolean }>`
  margin: ${(props) => getContentTopMargin(props)}px 16px 16px 16px;
  span {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    white-space: pre-wrap !important;
    overflow-wrap: break-word !important;
    hyphens: manual !important;
    color: ${(props) =>
      props.isWhatsNew ? previewBlack : previewGrayDark} !important;
  }
`

export const TitleWrapper = styled.div`
  display: flex;
`

export const Title = styled.div<{ isOffer: boolean }>`
  font-size: 24px;
  font-weight: 400;
  line-height: 26px;
  color: ${previewBlack};
  overflow-wrap: break-word;
  width: ${(props) => (props.isOffer ? 80 : 100)}%;
`

export const Tag = styled(TagIcon)`
  margin-left: auto;
  color: ${offerTagYellow};
  height: 22px;
  transform: rotate(-90deg);
`

export const Date = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: ${previewBlack};
  margin-top: 4px;
  margin-bottom: 16px;
`

export const LinkifiedText = styled(LinkifiedTextCommon)`
  > a {
    color: ${googlebusinessBlue} !important;
    cursor: default !important;
  }
`

export const More = styled.div`
  display: inline;
  color: ${previewGrayMedium};
`

export const OfferDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 8px;
  gap: 16px;
  background: #f7f8fa;
  border: 2px dashed #dadce0;
  border-radius: 4px;
  margin: 16px 0;
`

export const OfferDirections = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  color: ${previewGrayDark};
  margin: 0 auto;
`

export const OfferCode = styled.div`
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  color: ${previewBlack};
  overflow-wrap: break-word;
  width: 260px;
  text-align: center;
`

export const OfferDates = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: ${previewGrayDark};
  margin: 0 8px;
  text-align: center;
`

export const OfferLink = styled.div`
  margin-top: 16px;
  font-weight: 400 !important;
  font-size: 14px !important;
  color: ${googlebusinessBlue} !important;
`
