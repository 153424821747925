import { useMutation } from '@apollo/client'
import { useCallback } from 'react'
import { graphql } from '~publish/gql'
import type {
  AttributionInput,
  BillingInterval,
  ObPlanId,
} from '~publish/gql/graphql'
import { GET_ACCOUNT } from '~publish/legacy/accountContext'
import type { CurrentOrganization } from '~publish/legacy/accountContext/types'

type SuggestedPlanData = {
  planId: ObPlanId
  planInterval: BillingInterval
  isRecommended?: boolean
}

export function getSuggestedPlan(
  currentOrganization: CurrentOrganization,
): SuggestedPlanData {
  const plan = {
    planId: 'team' as ObPlanId,
    planInterval: 'month' as BillingInterval,
  }

  const suggestedPlan = currentOrganization?.billing?.changePlanOptions?.find(
    (p) => p.isRecommended,
  )
  if (suggestedPlan) {
    return {
      planId: suggestedPlan.planId as ObPlanId,
      planInterval: suggestedPlan.planInterval as BillingInterval,
    }
  }

  return plan
}

export type UseStartTrialInput = {
  organizationId: string
  currentOrganization: CurrentOrganization
  attribution: AttributionInput
}

export const StartTrial = graphql(/* GraphQL */ `
  mutation StartTrial(
    $organizationId: String
    $plan: OBPlanId
    $interval: BillingInterval
    $attribution: AttributionInput
  ) {
    billingStartTrial(
      organizationId: $organizationId
      plan: $plan
      interval: $interval
      attribution: $attribution
    ) {
      ... on BillingResponse {
        success
      }
      ... on BillingError {
        userFriendlyMessage
      }
    }
  }
`)

export type StartTrial = (args: UseStartTrialInput) => Promise<void>

export const useStartTrial = (): StartTrial => {
  const [startTrial] = useMutation(StartTrial, {
    refetchQueries: [GET_ACCOUNT],
    awaitRefetchQueries: true,
    onCompleted: () => {
      const { MODALS, actions } = window?.appshell || {}
      if (actions && MODALS) {
        window?.appshell?.actions?.refetchAccount()
      }
    },
  })

  const startTrialCallback = useCallback(
    async ({
      organizationId,
      attribution,
      currentOrganization,
    }: UseStartTrialInput): Promise<void> => {
      const suggestedPlan = getSuggestedPlan(currentOrganization)
      const { data, errors } = await startTrial({
        variables: {
          organizationId,
          plan: suggestedPlan.planId,
          interval: suggestedPlan.planInterval,
          attribution,
        },
      })

      if (errors || !data) {
        throw new Error(errors?.[0]?.message ?? 'Failed to start trial')
      }

      const result = data.billingStartTrial
      if (result.__typename === 'BillingError') {
        throw new Error(result.userFriendlyMessage ?? 'Failed to start trial')
      }

      const isSuccess =
        result.__typename === 'BillingResponse' && result.success

      if (isSuccess) {
        window?.appshell.actions.openModal(window?.appshell.MODALS.success, {
          startedTrial: true,
          selectedPlan: getSuggestedPlan(currentOrganization),
        })
      }
    },
    [startTrial],
  )

  return startTrialCallback
}
