import { createContext } from 'react'

type UserDataContext = {
  uses24hTime: boolean | undefined
  weekStartsMonday: boolean | undefined
  hasGlobalDraftsFeature: boolean | undefined
}

export const mapUserDataToContext = <
  T extends {
    uses24hTime: boolean
    weekStartsMonday: boolean
    features: string[]
  },
>(
  userData: T,
): UserDataContext => {
  return {
    uses24hTime: userData.uses24hTime,
    weekStartsMonday: userData.weekStartsMonday,
    hasGlobalDraftsFeature: userData?.features?.includes('global_drafts_v0'),
  }
}

export const UserDataContext = createContext<UserDataContext>({
  uses24hTime: undefined,
  weekStartsMonday: undefined,
  hasGlobalDraftsFeature: undefined,
})
