import React from 'react'

import {
  Flex,
  Heading,
  Paragraph,
  SimpleSpinner,
  toast,
} from '@buffer-mono/popcorn'

import {
  selectCurrentProfile,
  selectCurrentProfileTimezone,
} from '~publish/legacy/profile-sidebar/selectors'
import { useAppDispatch, useAppSelector } from '~publish/legacy/store'
import { TimeZoneSelect } from '~publish/components/TimeZoneSelect'

import { updateTimezone } from '../../thunks/updateTimezone'

import commonStyles from '../../PostingSchedule.module.css'

const TimezoneInputForm = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const profile = useAppSelector(selectCurrentProfile)
  const profileTimezone = useAppSelector(selectCurrentProfileTimezone)
  const [loading, setLoading] = React.useState(false)

  if (!profile) return <></>

  const disabled = !profile.isManager
  const profileId = profile.id

  const onComboboxChange = async (timezone: string): Promise<void> => {
    setLoading(true)
    const result = await dispatch(
      updateTimezone({ profileId, timezone, city: timezone }),
    )
    if (updateTimezone.fulfilled.match(result)) {
      toast.success(result.payload.message)
    } else {
      toast.error(
        `Sorry! Something went wrong while updating your timezone: ${result.error.message}. Would you be up for trying again?`,
      )
    }
    setLoading(false)
  }

  return (
    <Flex
      justify="between"
      align="center"
      style={{ width: '100%' }}
      data-testid="timezone-form-wrapper"
    >
      <Flex direction="column">
        <Heading size="small" as="h3">
          Timezone
        </Heading>
        <Paragraph className={commonStyles.postingScheduleParagraph}>
          Used for scheduling queue for this channel
        </Paragraph>
      </Flex>
      <Flex direction="row" align="center" gap="xs">
        {loading && <SimpleSpinner />}
        <TimeZoneSelect
          value={profileTimezone}
          onChange={onComboboxChange}
          disabled={disabled}
          size="large"
          variant="secondary"
        />
      </Flex>
    </Flex>
  )
}

export { TimezoneInputForm }
