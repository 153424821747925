import React from 'react'
import { useDispatch } from 'react-redux'
import { actions as modalsActions } from '~publish/legacy/modals'
import { Hashtag } from '@bufferapp/ui/Icon'
import { useAppSelector } from '~publish/legacy/store'
import { selectHasFeature } from '~publish/legacy/organizations/selectors'
import { NavTag } from '~publish/legacy/shared-components'

import type { Draft } from '../../composer/composer/entities/Draft'
import ComposerActionCreators from '../../composer/composer/action-creators/ComposerActionCreators'
import { BarButton } from '../styles'

export const HashTagManagerButton = ({
  draft,
}: {
  draft: Draft
}): JSX.Element | null => {
  const dispatch = useDispatch()

  const hasHashtagManagerFeature = useAppSelector((state) =>
    selectHasFeature(state, 'hasHashtagManagerFeature'),
  )
  const canStartProTrial = useAppSelector((state) =>
    selectHasFeature(state, 'canStartProTrial'),
  )

  const onToggleHashtagManager = (event: React.MouseEvent): void => {
    event.preventDefault()

    if (hasHashtagManagerFeature) {
      ComposerActionCreators.updateToggleSidebarVisibility(null, true)
      ComposerActionCreators.updateSidebarOnTop(draft.id, 'hashtagmanager')
    } else {
      dispatch(modalsActions.showHashTagManagerPaywallModal())
    }
  }

  const shouldDisplayHashtagManager =
    (hasHashtagManagerFeature || canStartProTrial) &&
    draft.canType() &&
    !draft.service.isOmni

  if (!shouldDisplayHashtagManager) {
    return null
  }

  return hasHashtagManagerFeature ? (
    <BarButton
      className="hashtag-manager-icon"
      data-testid="hashtag-manager-icon"
      onClick={onToggleHashtagManager}
      data-tip="Open Hashtag Manager"
      aria-label="hashtag manager"
    >
      <Hashtag size="medium" />
    </BarButton>
  ) : (
    <BarButton
      className="upgrade-plan-cta"
      data-testid="hashtag-paywall-icon"
      onClick={onToggleHashtagManager}
      data-tip="Upgrade plan to use the Hashtag Manager"
      aria-label="Hashtag manager"
    >
      <NavTag type="paywall" />
      &nbsp;
      <Hashtag size="medium" />
    </BarButton>
  )
}
