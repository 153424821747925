import type { ChannelAccessLevel } from '~publish/gql/graphql'

function isViewOnly({
  channelsSelected,
  listOfChannels,
}: {
  channelsSelected: string[] | void
  listOfChannels: readonly { id: string; accessLevel: ChannelAccessLevel }[]
}): boolean {
  if (!channelsSelected && !listOfChannels) return true

  if (!channelsSelected || channelsSelected.length === 0) {
    return listOfChannels.some(
      (channel) => channel.accessLevel !== 'fullPosting',
    )
  }

  return channelsSelected.some((channelId) => {
    const channel = listOfChannels.find((channel) => channel.id === channelId)
    return channel?.accessLevel !== 'fullPosting'
  })
}

export default isViewOnly
