import styled from 'styled-components'
import CheckmarkIcon from '@bufferapp/ui/Icon/Icons/Checkmark'
import Text from '@bufferapp/ui/Text'
import Button from '@bufferapp/ui/Button'
import {
  grayLighter,
  white,
  purpleLightest,
  grayDark,
  grayDarker,
  blue,
} from '@bufferapp/ui/style/colors'
import { fontFamily, fontSize } from '@bufferapp/ui/style/fonts'

export const Layout = styled.section`
  background-color: ${grayLighter};
  padding: 24px 0 0 0;
  flex: auto;
  position: relative;
`

export const ModalOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  padding: 0;
`

export const ModalBackground = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(50, 59, 67, 0.8);
  top: 0;
  opacity: 0.9;
`

export const ModalWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
`

export const ModalContent = styled.div`
  background: ${white};
  padding: 3rem 1.5rem 3.5rem 3rem;
  border-radius: 8px 0 0 8px;
`

export const ModalImage = styled.div`
  border-radius: 0 8px 8px 0;
  background: ${purpleLightest};
  width: 313px;
  position: relative;

  img {
    position: absolute;
    height: 494px;
    width: auto;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
  }
`

export const HeaderStyled = styled(Text)`
  font-size: 24px;
  line-height: 34px;
  color: ${grayDarker};
  font-weight: 700;
  margin: 0 0 1rem;
`

export const TextStyled = styled(Text)`
  margin-top: 0;
  font-size: 14px;
  line-height: 24px;
  color: ${grayDark};
  max-width: 313px;
  a {
    color: ${blue};
    text-decoration: none;
  }
`

export const ListStyled = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  width: 360px;
`

export const ListItemStyled = styled.li`
  font-family: ${fontFamily};
  font-size: ${fontSize};
  color: ${grayDark};
  margin-bottom: 0.5rem;
  line-height: 20px;
  display: flex;
  align-items: center;
  column-gap: 4px;
  &:last-child {
    margin-bottom: 0;
  }
`

export const CheckmarkIconStyled = styled(CheckmarkIcon)`
  color: ${blue};
`

export const ButtonStyled = styled(Button)`
  margin-top: 32px;
`
