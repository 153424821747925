export const formatTimezone = (str = ''): string => {
  return (
    str
      ?.replace(/_/g, ' ')
      // this is a papercut change:
      // https://www.notion.so/buffer/Changing-Kiev-to-Kyiv-in-the-timezone-selector-9ef0e783adf84a16a1bba5ce090d2815
      .replace('Europe/Kiev', 'Europe/Kyiv')
  )
}

export const getTimezoneFromBrowser = (): string =>
  Intl.DateTimeFormat().resolvedOptions().timeZone

export const getFormattedTimezoneFromBrowser = (): string =>
  formatTimezone(getTimezoneFromBrowser())
