import React from 'react'
import PropTypes from 'prop-types'
import styles from './css/ProfileGroups.module.css'
import { Badge, Button, Text } from '@buffer-mono/popcorn'
import { channelGroups } from '~publish/legacy/routes'
import AppActionCreators from '../action-creators/AppActionCreators'

type ChannelGroup = {
  id: string
  name: string
  profileIds: string[]
}

const ChannelItem = ({
  group,
  selectedChannelGroupsIds,
}: {
  group: ChannelGroup
  selectedChannelGroupsIds: string[]
}): JSX.Element => {
  const isSelected = (): boolean => selectedChannelGroupsIds.includes(group.id)

  const selectChannelGroup = (): void => {
    if (isSelected()) {
      AppActionCreators.unselectGroupProfiles(
        group.id,
        selectedChannelGroupsIds,
      )
    } else {
      AppActionCreators.selectGroupProfiles(group.id)
    }
  }

  const channelsCount = group.profileIds.length

  return (
    <Button onClick={selectChannelGroup} variant="secondary">
      <Text>{group.name}</Text>
      <Badge size="xsmall" variant={isSelected() ? 'brand' : 'neutral'}>
        {channelsCount}
      </Badge>
    </Button>
  )
}

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const ProfileGroups = (props) => {
  const { groups, selectedProfilesIds } = props

  const hasGroups = groups.length > 0

  // @ts-expect-error TS(7006) FIXME: Parameter 'group' implicitly has an 'any' type.
  const profileGroupsWithAllProfilesSelected = groups.filter((group) =>
    // @ts-expect-error TS(7006) FIXME: Parameter 'profileId' implicitly has an 'any' type... Remove this comment to see the full error message
    group.profileIds.every((profileId) =>
      selectedProfilesIds.includes(profileId),
    ),
  )

  const selectedProfileGroupsIds = profileGroupsWithAllProfilesSelected.map(
    // @ts-expect-error TS(7006) FIXME: Parameter 'group' implicitly has an 'any' type.
    (group) => group.id,
  )

  return (
    <div className={styles.profileGroups}>
      {/* @ts-expect-error TS(7006) FIXME: Parameter 'group' implicitly has an 'any' type. */}
      {groups.map((group) => (
        <ChannelItem
          key={group.id}
          group={group}
          selectedChannelGroupsIds={selectedProfileGroupsIds}
        />
      ))}
      <Button
        as="a"
        href={channelGroups.route}
        variant="secondary"
        target="_blank"
      >
        {hasGroups ? 'Edit Groups' : 'Create a Group'}
      </Button>
    </div>
  )
}

ProfileGroups.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      profileIds: PropTypes.arrayOf(PropTypes.string),
    }),
  ).isRequired,
  selectedProfilesIds: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default ProfileGroups
