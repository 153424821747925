import { useSplitEnabled } from '@buffer-mono/features'
import {
  useOrganizationBilling,
  useOrganizationLimits,
} from '~publish/legacy/accountContext'
import { useAppSelector } from '~publish/legacy/store'

const START_SHOWING_AT = 7
const START_SHOWING_AT_EXPERIMENT = 3

function useQueueLimitBar(): {
  postLimit: number
  numberOfScheduledPosts: number
  shouldShow: boolean
} {
  const numberOfScheduledPosts = useAppSelector(
    (state) =>
      state.profileNav.pendingCount + state.profileNav.draftsScheduledCount,
  )
  const { isEnabled: isQueueLimitUpgradeEnabled } = useSplitEnabled(
    'geid-queue-limit-upgrade',
  )

  const START_SHOWING_AT_VALUE = isQueueLimitUpgradeEnabled
    ? START_SHOWING_AT_EXPERIMENT
    : START_SHOWING_AT

  const { scheduledPosts: postLimit } = useOrganizationLimits()
  const { subscription } = useOrganizationBilling()
  const isFree = subscription?.plan?.id === 'free'
  const shouldShow = isFree && numberOfScheduledPosts >= START_SHOWING_AT_VALUE

  return {
    numberOfScheduledPosts,
    postLimit: postLimit ?? 10,
    shouldShow,
  }
}

export default useQueueLimitBar
