import styled from 'styled-components'
import * as Popover from '@radix-ui/react-popover'
import { white, grayDark, purpleLight } from '@bufferapp/ui/style/colors'
import { Button } from '~publish/legacy/ai/styles/Button'

export const PopoverTrigger = styled(Popover.Trigger)`
  border: none;
  background: none;
  display: flex;
  flex-shrink: 0;
  flex-basis: fit-content;
  padding: 0;
`

export const PopoverContent = styled(Popover.Content)`
  position: relative;
  box-sizing: border-box;
  background: ${white};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  flex-grow: 0;
  padding: 20px 16px;
  width: 375px;
  box-shadow:
    hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  background-color: ${white};
  z-index: 5000;
  background-image: linear-gradient(79.75deg, #f4ffe7 1.26%, #e0e5ff 100.73%);
  background-size: 100% 25%;
  background-repeat: no-repeat;
`

export const Background = styled.div``

export const PopoverArrow = styled(Popover.Arrow)`
  fill: #eaf2f4;
  width: 40px;
  height: 20px;
  filter: drop-shadow(2px 4px 6px #e0e0e0);
`

export const PopoverClose = styled(Popover.Close)`
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 20px;

  svg {
    color: ${grayDark};
    fill: ${grayDark};
  }
`

export const TriggerButton = styled(Button)`
  padding-left: 0;
  padding-right: 0;
  font-weight: 500;
  background: -webkit-linear-gradient(left, #ba6bff, #0113ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  svg {
    fill: ${purpleLight};
  }

  span {
    -webkit-text-fill-color: ${white};
  }
`
