import { DEFAULT_COLOR } from '../styles'

// @ts-expect-error TS(7006) FIXME: Parameter 'hex' implicitly has an 'any' type.
export const getColorContrast = (hex) => {
  const hexColor = hex.replace('#', '')
  const r = parseInt(hexColor.substr(0, 2), 16)
  const g = parseInt(hexColor.substr(2, 2), 16)
  const b = parseInt(hexColor.substr(4, 2), 16)
  const yiq = (r * 299 + g * 587 + b * 114) / 1000

  return yiq >= 160 ? '#000000' : '#ffffff'
}

// @ts-expect-error TS(7006) FIXME: Parameter 'hex' implicitly has an 'any' type.
export const isHexValid = (hex) => /^#[0-9A-F]{6}$/i.test(hex)

const MAX_HEX_LENGTH = 6
// @ts-expect-error TS(7006) FIXME: Parameter 'selectedHex' implicitly has an 'any' ty... Remove this comment to see the full error message
export const repeatInputHex = (selectedHex, lastValidColor) => {
  const repeatBy = MAX_HEX_LENGTH / selectedHex.length
  const repeatedHex = `#${selectedHex.repeat(repeatBy)}`

  return isHexValid(repeatedHex) ? repeatedHex : lastValidColor
}

// @ts-expect-error TS(7006) FIXME: Parameter 'hexColor' implicitly has an 'any' type.
export const getValidHex = (hexColor, lastValidColor) => {
  const color = hexColor.replace('#', '')
  const validColor = lastValidColor || DEFAULT_COLOR

  if (!isHexValid(hexColor)) {
    const shouldRepeat = color.length > 0 && color.length <= 3
    return shouldRepeat ? repeatInputHex(color, validColor) : validColor
  }

  return hexColor
}

// @ts-expect-error TS(7006) FIXME: Parameter 'selectedHex' implicitly has an 'any' ty... Remove this comment to see the full error message
export const onColorChange = (selectedHex, onChange) => {
  const selectedColor = (
    selectedHex.charAt(0) !== '#' ? `#${selectedHex}` : selectedHex
  ).toUpperCase()
  const textColor = getColorContrast(selectedColor)

  if (typeof onChange === 'function') {
    onChange(selectedColor, textColor)
  }
}
