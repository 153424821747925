import styled from 'styled-components'
import { grayDark } from '@bufferapp/ui/style/colors'

export const EmptyStateContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: 45px;
  margin-top: 20px;
  align-items: flex-start;
  max-width: 785px;
  width: 100%;
`

export const Content = styled.div`
  margin-top: 43px;
  flex: 1;
`

export const Image = styled.img`
  object-fit: contain;
  overflow: hidden;
  align-self: center;
  max-width: 337px;
  margin-left: 50px;
  margin-right: -40px;
`

export const CTAWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  #collab-start-trial-cta {
    height: 40px;
    margin-right: 8px;
  }

  > p {
    font-size: 12px;
    color: ${grayDark};
  }
`
