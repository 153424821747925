import type {
  AddFileOptions,
  FailedUppyFile as BaseFailedUppyFile,
  UploadedUppyFile as BaseUploadedUppyFile,
  UppyFile as BaseUppyFile,
} from '@uppy/core'
import type { LocalFile } from '../values/LocalFile'
import { UploadMetadata } from '../values/UploadMetadata'
import {
  getFileMimeType,
  getFileExtension,
  getMediaTypeFromMime,
} from '../utils'
import type { S3Upload, Upload } from '../entities/Upload'
import { MediaType, UploadStatus } from '../constants'
import { UploadProgress } from '../values/UploadProgress'

export type S3Response = {
  location: string
  key: string
  bucket: string
}

type TMeta = Record<string, unknown> & UploadMetadata
type TBody = Record<string, unknown>

export type UppyFile = BaseUppyFile<TMeta, TBody>
export type UploadedUppyFile = BaseUploadedUppyFile<TMeta, TBody>
export type FailedUppyFile = BaseFailedUppyFile<TMeta, TBody>
export type UppyFileDescriptor = AddFileOptions<
  UploadMetadata & { relativePath?: string }
>

export const UppyFile = {
  isUploadedUppyFile(file: UppyFile): file is UploadedUppyFile {
    return 'uploadURL' in file
  },

  isFailedUppyFile(file: UppyFile): file is FailedUppyFile {
    return 'error' in file
  },

  shouldGenerateThumbnail(file: UppyFile): boolean {
    if (file.preview) return false

    const mediaType = getMediaTypeFromMime(file.type)

    return (
      mediaType === MediaType.GIF ||
      mediaType === MediaType.VIDEO ||
      mediaType === MediaType.DOCUMENT
    )
  },

  toLocalFile(file: UppyFile): LocalFile {
    return {
      name: file.name,
      type: getFileMimeType(file),
      extension: getFileExtension(file.name),
      size: file.data.size,
      data: file.data,
      meta: UploadMetadata.new(file.meta),
    }
  },

  toUpload(file: UppyFile, status?: UploadStatus): Upload {
    const isCompleted = status === UploadStatus.Completed
    const isCompletedUppyFile = UppyFile.isUploadedUppyFile(file)
    if (isCompleted && !isCompletedUppyFile) {
      throw new Error('Cannot create completed upload from non-uploaded file')
    }

    const mimeType = getFileMimeType(file)

    return {
      id: file.id,
      name: file.name,
      status: status || UploadStatus.Added,
      type: mimeType,
      extension: getFileExtension(file.name),
      mediaType: getMediaTypeFromMime(mimeType),
      uploaderId: file.meta.uploaderId,
      thumbnailUrl: file.preview,
      progress: UploadProgress.new(
        file.progress?.bytesTotal,
        file.progress?.bytesUploaded,
      ),
      url: isCompleted && isCompletedUppyFile ? file.uploadURL : undefined,
      size: file.size,
      alt: '',
      meta: UploadMetadata.new(file.meta),
    }
  },

  mapToS3Upload(files: UploadedUppyFile[]): S3Upload[] {
    return files.filter((f) => !!f).map(UppyFile.toS3Upload)
  },

  toS3Upload(file: UploadedUppyFile): S3Upload {
    const { location, key, bucket } = file.response?.body as S3Response
    if (!location || !key || !bucket) {
      throw new Error('Cannot create S3Upload without required data')
    }

    return {
      ...UppyFile.toUpload(file),
      status: UploadStatus.UploadedToS3,
      url: location,
      key,
      bucket,
    }
  },

  toCompletedUpload(file: UploadedUppyFile): Upload {
    return UppyFile.toUpload(file, UploadStatus.Completed)
  },

  toFailedUpload(file: UppyFile, error: string): Upload {
    return {
      ...UppyFile.toUpload(file),
      status: UploadStatus.Failed,
      error,
    }
  },
}
