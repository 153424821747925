import {
  getWeekOfMonth,
  getMonth,
  getWeeksInMonth,
  isSameYear,
  type Day,
} from 'date-fns'

type GetWeekIndexFromDayArgs = {
  currentDate: Date
  day: Date
  weekStartsOn?: Day
}

function getWeekIndexFromDay({
  currentDate,
  day,
  weekStartsOn = 0,
}: GetWeekIndexFromDayArgs): number {
  const currentCalendarMonth = getMonth(currentDate)
  const monthOfDay = getMonth(day)
  const numberWeeksCurrentMonth = getWeeksInMonth(currentDate, {
    weekStartsOn,
  })
  const datesAreInSameYear = isSameYear(currentDate, day)

  const dayBelongsToPreviousMonth =
    datesAreInSameYear && monthOfDay < currentCalendarMonth
  const dayBelongsToPreviousYear =
    !datesAreInSameYear && monthOfDay > currentCalendarMonth
  const dayBelongsToNextMonth =
    datesAreInSameYear && monthOfDay > currentCalendarMonth
  const dayBelongsToNextYear =
    !datesAreInSameYear && monthOfDay < currentCalendarMonth

  if (dayBelongsToPreviousYear || dayBelongsToPreviousMonth) {
    return 0
  }
  if (dayBelongsToNextYear || dayBelongsToNextMonth) {
    return numberWeeksCurrentMonth - 1
  }
  return (
    getWeekOfMonth(day, {
      weekStartsOn,
    }) - 1
  )
}

export default getWeekIndexFromDay
