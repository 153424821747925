import { useState, useEffect } from 'react'
import { getFeedGroups, onFeedGroups } from './mockFeedGroups'
import type { FeedGroup } from '../types'

export function useFeedGroups(): FeedGroup[] {
  const [feedGroups, setFeedGroups] = useState<FeedGroup[]>(() =>
    getFeedGroups(),
  )
  useEffect(() => {
    return onFeedGroups(setFeedGroups)
  }, [setFeedGroups])
  return feedGroups
}
