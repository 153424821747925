import { nanoid } from '@reduxjs/toolkit'
import {
  setNodes,
  withoutMergingHistory,
  withoutNormalizing,
  withoutSavingHistory,
} from '@udecode/plate'
import type { BufferEditor } from '../../../BufferEditor/types.plate'
import { editorDebug } from '../../../editorDebug'
import { LinkElement } from '../nodes/LinkElement'

export function replaceLinkWithShortLink(
  editor: BufferEditor,
  link: string,
  shortLink: string,
): void {
  // The shortLinkId allows for undoing shortlinks.
  // Without it, the Composer would re-shorten the link.
  const shortLinkId = nanoid()

  // @ts-expect-error TS(7006) FIXME: Parameter 'n' implicitly has an 'any' type.
  const match = (n) => LinkElement.is(n) && n.url === link

  editorDebug('replaceLinkWithShortLink %s %s', link, shortLink)

  withoutNormalizing(editor, () => {
    withoutSavingHistory(editor, () => {
      setNodes<LinkElement>(
        editor,
        {
          shortUrl: shortLink,
          isShortened: false,
          shortLinkId,
        },
        {
          at: [],
          match: (n) => {
            return match(n) && !n.isShortened
          },
        },
      )
    })

    withoutMergingHistory(editor, () => {
      setNodes<LinkElement>(
        editor,
        {
          isShortened: true,
        },
        {
          at: [],
          match: (n) => match(n) && n.shortLinkId === shortLinkId,
        },
      )
    })
  })
}
