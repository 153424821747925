import React, { useState, useEffect } from 'react'

import { WelcomeAnimation } from './welcome-animation/WelcomeAnimation'
import { OnboardingBody } from './OnboardingBody'

import * as Styled from './Onboarding.styles'

function Onboarding(): JSX.Element {
  const [showWelcomeAnimation, setShowWelcomeAnimation] = useState(true)
  const [beginTransition, setBeginTransition] = useState(false)

  useEffect(() => {
    setTimeout(() => setBeginTransition(true), 3700)
    setTimeout(() => setShowWelcomeAnimation(false), 4000)
  }, [])

  return (
    <Styled.Wrapper data-testid="ai-assistant-onboarding-panel">
      {showWelcomeAnimation ? (
        <WelcomeAnimation beginTransition={beginTransition} />
      ) : (
        <OnboardingBody />
      )}
    </Styled.Wrapper>
  )
}

export { Onboarding }
