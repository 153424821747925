import { createAsyncThunk } from '@reduxjs/toolkit'
import { Services } from '~publish/legacy/constants/services'
import { selectProfileService } from '~publish/legacy/profile-sidebar/selectors'
import type { Profile } from '~publish/legacy/profile-sidebar/types'
import { profileParser } from '~publish/legacy/duplicate-server/parsers'
import type { AppDispatch, RootState } from '~publish/legacy/store'
import callRpc from '~publish/legacy/utils/call-rpc'
import i18next from 'i18next'
import { toast } from '@buffer-mono/popcorn'

export const toggleReminders = createAsyncThunk<
  Profile,
  { profileId: string; allowReminders: boolean },
  { state: RootState; dispatch: AppDispatch }
>(
  'generalSettings/toggleReminders',
  async (
    { profileId, allowReminders },
    { getState, dispatch, rejectWithValue },
  ) => {
    try {
      const profileService = selectProfileService(profileId)(getState())
      const serviceDefinition = Services.get(profileService)

      const response = await callRpc('toggleInstagramReminders', {
        profileId,
        defaultToReminders: allowReminders,
      })

      if (!response.success) {
        throw new Error(response.message)
      }
      const parsedProfile: Profile = profileParser(response.profile)

      const message =
        allowReminders === true
          ? i18next.t('remindersSettings.notitications.enabled', {
              service: serviceDefinition?.formattedName,
            })
          : i18next.t('remindersSettings.notitications.disabled')
      toast.success(message)

      return parsedProfile
    } catch (error) {
      const message = `Sorry! Something went wrong while updating your reminders settings. Would you be up for trying again?`
      toast.error(message)

      return rejectWithValue(message)
    }
  },
)
