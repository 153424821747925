import React from 'react'
import type { Draft } from '~publish/legacy/composer/composer/entities/Draft'

import { RemindersWithStickers } from '../new-reminders/RemindersWithStickers'
import { OnlyRemindersBar } from '~publish/legacy/reminders/components/new-reminders/OnlyRemindersBar'
import { hasOnlyFacebookGroupsSelected } from '~publish/legacy/reminders/components/reminders-bar/hasOnlyFacebookGroupsSelected'
import { useSplitEnabled } from '@buffer-mono/features'

type Props = {
  draft: Draft
}

export const RemindersBar = ({ draft }: Props): JSX.Element => {
  const { isEnabled: isTiktokRemindersEnabled } =
    useSplitEnabled('tiktok-reminders')
  const supportsMobileReminders =
    draft?.service?.name === 'instagram' ||
    (draft?.service?.name === 'tiktok' && isTiktokRemindersEnabled) ||
    (draft?.service?.name === 'youtube' && isTiktokRemindersEnabled)

  const shouldShowNewRemindersForFacebookGroups =
    hasOnlyFacebookGroupsSelected(draft)

  return (
    <>
      {supportsMobileReminders && <RemindersWithStickers draft={draft} />}

      {shouldShowNewRemindersForFacebookGroups && (
        <OnlyRemindersBar
          draft={draft}
          disabledAutomaticDescription={
            'Facebook groups’ posts can only be shared via mobile notification.'
          }
        />
      )}
    </>
  )
}
