import type { AutocompleteItem } from '../../autocomplete'
import type { FacebookMentionResponseItem } from '../api/getFacebookPrivateSuggestions'
import {
  type FacebookMentionDbEntry,
  type FacebookMentionDbEntryDetails,
  type FacebookMentionEntity,
  type FacebookMentionEntityDetails,
  FacebookMentionInputElement,
} from '../nodes'

export const bufferMentionData: FacebookMentionEntityDetails = {
  name: 'Buffer',
  id: '108311429241313',
  url: 'https://www.facebook.com/108311429241313',
}

export const bufferMentionItem: AutocompleteItem = {
  key: '108311429241313',
  text: 'Buffer',
  data: bufferMentionData,
}

export const bufferMentionElement = FacebookMentionInputElement.new(
  '',
  bufferMentionData,
)

export const bufferMentionDbEntryDetails: FacebookMentionDbEntryDetails = {
  text: 'Buffer',
  content: '108311429241313',
  url: 'https://www.facebook.com/108311429241313',
}

export const cocaColaMentionData: FacebookMentionEntityDetails = {
  name: 'Coca-Cola',
  id: '1545297492377124',
  url: 'https://www.facebook.com/1545297492377124',
}

export const cocaColaMentionItem = {
  key: '1545297492377124',
  text: 'Coca-Cola',
  data: cocaColaMentionData,
}

export const cocaColaMentionElement = FacebookMentionInputElement.new(
  '',
  cocaColaMentionData,
)

export const cocaColaMentionDbEntryDetails: FacebookMentionDbEntryDetails = {
  text: 'Coca-Cola',
  content: '1545297492377124',
  url: 'https://www.facebook.com/1545297492377124',
}

export const disneyMentionData: FacebookMentionEntityDetails = {
  name: 'Disney',
  id: '277866969252',
  url: 'https://www.facebook.com/277866969252',
}

export const disneyMentionItem = {
  key: '277866969252',
  text: 'Disney',
  data: disneyMentionData,
}

export const disneyMentionElement = FacebookMentionInputElement.new(
  '',
  disneyMentionData,
)

export const disneyMentionDbEntryDetails: FacebookMentionDbEntryDetails = {
  text: 'Disney',
  content: '277866969252',
  url: 'https://www.facebook.com/277866969252',
}

export const textWithMultipleFacebookMentions =
  'Chonky bork heckin many pupper Buffer many borkdrive puggo snoot borkdrive ruff puggorino Coca-Cola bois pupperino.\n\nDisney \n\nWags floofs shoob doggo woofs hand borkdrive boof shibe floof cute borking heckin.'

export const multipeMentionsWithIndices: FacebookMentionEntity[] = [
  {
    ...bufferMentionData,
    indices: [31, 37],
  },
  {
    ...cocaColaMentionData,
    indices: [90, 99],
  },
  {
    ...disneyMentionData,
    indices: [117, 123],
  },
]

export const multipeMentionDbEntriesWithIndices: FacebookMentionDbEntry[] = [
  {
    ...bufferMentionDbEntryDetails,
    indices: [31, 37],
  },
  {
    ...cocaColaMentionDbEntryDetails,
    indices: [90, 99],
  },
  {
    ...disneyMentionDbEntryDetails,
    indices: [117, 123],
  },
]

export const facebookMentionResponseItem: FacebookMentionResponseItem = {
  id: '108311429241313',
  name: 'Buffer',
  link: 'https://www.facebook.com/108311429241313',
}

export const emptyApiResponse = []
export const validApiResponse = [facebookMentionResponseItem]

export const facebookMentionTestEntity = {
  id: '108311429241313',
  handle: '@108311429241313',
  name: 'Buffer',
  avatar: 'https://graph.facebook.com/108311429241313/picture?type=square',
  url: 'https://www.facebook.com/108311429241313',
}

export const facebookMentionItem: AutocompleteItem = {
  key: '108311429241313',
  text: 'Buffer',
  data: facebookMentionTestEntity,
}
