import React, { useEffect } from 'react'
import * as Dialog from '@radix-ui/react-dialog'
import { Button } from '@bufferapp/ui'

import { useAccount } from '~publish/legacy/accountContext'
import {
  PaywallWrapper,
  PaywallContent,
  CtaContainer,
  StyledOverlay,
  ModalWrapper,
  CloseIcon,
} from './styles'

import UpgradePath from '~publish/legacy/upgrade-paths'
import { CTA_CLICKED, CTA_VIEWED, sendPaywallEvent } from './tracking'
import { grayDarker } from '@bufferapp/ui/style/colors'

type PayWallProps = {
  children: React.ReactNode | JSX.Element
  labelForCtaButton?: string
  cta: string
  upgradePathName: string
  ctaForUgradePath: string
  ctaMPMigration: string
  onClosePaywall: () => void
}

const PayWallModal = ({
  children,
  labelForCtaButton = 'See Upgrade Options',
  cta,
  upgradePathName,
  ctaForUgradePath,
  ctaMPMigration,
  onClosePaywall,
}: PayWallProps): JSX.Element => {
  const { account } = useAccount()
  const commonTrackingProps =
    account?.currentOrganization?.commonTrackingProperties || null

  /**
   * When the paywall modal is opened, we sent the event CTA_VIEWED to Segment
   */
  useEffect(() => {
    sendPaywallEvent(CTA_VIEWED, cta, upgradePathName, commonTrackingProps)
  }, [cta, upgradePathName, commonTrackingProps])

  /**
   * Manages the click event for the CTA button
   *
   * @param onUpgradeClick - method that opens the upgrade modal or migration hub
   * depending on if the user is a newBuffer user or not
   */
  const handleOnClick = (onUpgradeClick: any): void => {
    sendPaywallEvent(CTA_CLICKED, cta, upgradePathName, commonTrackingProps)
    onUpgradeClick({
      cta: ctaForUgradePath,
      ctaMPMigration,
      upgradePathName,
    })
    onClosePaywall()
  }

  const clickOutsideModal = (e: any): void => {
    e.preventDefault()
    onClosePaywall()
  }

  return (
    <Dialog.Root open={true} onOpenChange={onClosePaywall}>
      <Dialog.Portal>
        <StyledOverlay />
        <ModalWrapper onPointerDownOutside={(e): void => clickOutsideModal(e)}>
          <PaywallWrapper>
            <PaywallContent data-test-id="paywall-content">
              {children}
              <CtaContainer>
                <UpgradePath>
                  {(onUpgradeClick): JSX.Element => (
                    // @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: () =... Remove this comment to see the full error message
                    <Button
                      type="primary"
                      label={labelForCtaButton}
                      onClick={(): void => handleOnClick(onUpgradeClick)}
                      fullWidth
                    />
                  )}
                </UpgradePath>
              </CtaContainer>
            </PaywallContent>
          </PaywallWrapper>
          <Dialog.Close asChild>
            <CloseIcon size="large" color={grayDarker} />
          </Dialog.Close>
        </ModalWrapper>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default PayWallModal
