import {
  createSlice,
  type PayloadAction,
  type Slice,
  type SliceCaseReducers,
} from '@reduxjs/toolkit'
import type { RootState } from '~publish/legacy/store'

type TContentGenerationConfig = {
  action: string | null
  prompt: string | null
  tone: string | null
}

type TAISliceState = {
  AIAssistantOpened: boolean
  AIAssistantResponsive: boolean
  AIAssistantPlacement: 'publishComposer' | 'ideasEditor' | null
  contentGenerationInProgress: boolean
  contentGenerationRequestId: string | null
  contentGenerationError: string | null
  contentGenerationResults: string[]
  contentGenerationSelection: string | null
  contentGenerationConfig: TContentGenerationConfig
}

const initialState: TAISliceState = {
  AIAssistantOpened: false,
  AIAssistantResponsive: false,
  AIAssistantPlacement: null,
  contentGenerationInProgress: false,
  contentGenerationRequestId: null,
  contentGenerationError: null,
  contentGenerationResults: [],
  contentGenerationSelection: null,
  contentGenerationConfig: {
    action: null,
    prompt: null,
    tone: null,
  },
}

/**
 *
 */
function resetStatus(state: TAISliceState): void {
  state.contentGenerationInProgress = false
  state.contentGenerationError = null
  state.contentGenerationResults = []
  state.contentGenerationRequestId = null
}

function resetContentGenerationConfig(state: TAISliceState): void {
  state.contentGenerationConfig = {
    action: null,
    prompt: null,
    tone: null,
  }
}

/**
 *
 */
function validResult(state: TAISliceState, requestId: string): boolean {
  // In order for a result to be valid, the AI Assistant must be opened,
  // the request must be the latest request, and the request must still be in progress.
  return (
    state.AIAssistantOpened &&
    state.contentGenerationInProgress &&
    state.contentGenerationRequestId === requestId
  )
}

/**
 *
 */
const AISlice: Slice<
  TAISliceState,
  SliceCaseReducers<TAISliceState>,
  'AI'
> = createSlice({
  name: 'AI',
  initialState,
  reducers: {
    toggleAIAssistant: (state, action?: PayloadAction<boolean | undefined>) => {
      const { payload } = action || {}
      state.AIAssistantOpened =
        payload !== undefined ? payload : !state.AIAssistantOpened

      state.AIAssistantResponsive = window.innerWidth <= 1464

      if (!state.AIAssistantOpened) {
        resetStatus(state)
        resetContentGenerationConfig(state)
        state.contentGenerationSelection = null
      }
    },

    setAIAssistantPlacement: (
      state,
      action: PayloadAction<{
        placement: 'publishComposer' | 'ideasEditor' | null
      }>,
    ) => {
      const { placement } = action?.payload || null
      state.AIAssistantPlacement = placement
    },

    contentGenerationStart: (
      state,
      action: PayloadAction<{ requestId: string } | undefined>,
    ) => {
      const { requestId } = action?.payload || {}
      resetStatus(state)
      state.contentGenerationInProgress = true
      state.contentGenerationRequestId = requestId || null
    },

    contentGenerationSuccess: (
      state,
      action: PayloadAction<
        | {
            result: string
            requestId: string
          }
        | undefined
      >,
    ) => {
      const { result, requestId } = action?.payload || {}
      if (!result || !requestId) return

      if (!validResult(state, requestId)) {
        resetStatus(state)
        return
      }

      state.contentGenerationInProgress = false
      // In the future we will add support for having multiple results (history feature)
      // but for now we just replace the previous.
      state.contentGenerationResults[0] = result || ''
    },

    contentGenerationError: (
      state,
      action: PayloadAction<
        | {
            error: string
            requestId: string
          }
        | undefined
      >,
    ) => {
      const { error, requestId } = action?.payload || {}

      if (!requestId) return

      if (!validResult(state, requestId)) {
        resetStatus(state)
        return
      }

      state.contentGenerationInProgress = false
      state.contentGenerationError = error || null
    },

    contentGenerationCancelled: (state) => {
      state.contentGenerationRequestId = null
    },

    resetContentGeneration: (state) => {
      resetStatus(state)
      resetContentGenerationConfig(state)
    },

    resetSelection: (state) => {
      state.contentGenerationSelection = null
    },

    setContentGenerationConfig: (
      state,
      action: PayloadAction<TContentGenerationConfig>,
    ) => {
      const { action: generationAction, prompt, tone } = action?.payload || {}
      state.contentGenerationConfig = { action: generationAction, prompt, tone }
    },

    setContentGenerationSelection: (
      state,
      action: PayloadAction<string | null>,
    ) => {
      const value = action?.payload || null
      state.contentGenerationSelection = value
    },
  },
  extraReducers: (builder) => {
    builder.addCase('OPEN_COMPOSER', (state, action) => {
      const { withAIPanelOpen } = action as unknown as {
        withAIPanelOpen: boolean
      }
      if (withAIPanelOpen === true) {
        state.AIAssistantOpened = true
        state.AIAssistantResponsive = window.innerWidth <= 1464
      }
    })
  },
})

/**
 *
 */
const selectAIAssistantOpened = (state: RootState): boolean => {
  const { AIAssistantOpened } = state[AISlice.name as keyof typeof state]
  return AIAssistantOpened
}

/**
 *
 */
const selectAIAssistantResponsive = (state: RootState): boolean => {
  const { AIAssistantResponsive } = state[AISlice.name as keyof typeof state]
  return AIAssistantResponsive
}

/**
 *
 */
const selectContentGenerationStatus = (
  state: RootState,
): {
  contentGenerationInProgress: boolean
  contentGenerationError: string | null
  contentGenerationResults: string[]
} => {
  const {
    contentGenerationInProgress,
    contentGenerationError,
    contentGenerationResults,
  } = state[AISlice.name as keyof typeof state]

  return {
    contentGenerationInProgress,
    contentGenerationError,
    contentGenerationResults,
  }
}

const selectContentGenerationConfig = (
  state: RootState,
): TContentGenerationConfig => {
  const { contentGenerationConfig } = state[AISlice.name as keyof typeof state]
  return contentGenerationConfig
}

const selectContentGenerationSelection = (state: RootState): string | null => {
  const { contentGenerationSelection } =
    state[AISlice.name as keyof typeof state]
  return contentGenerationSelection
}

const selectAIAssistantPlacement = (state: RootState): string | null => {
  const { AIAssistantPlacement } = state[AISlice.name as keyof typeof state]
  return AIAssistantPlacement
}

const {
  toggleAIAssistant,
  contentGenerationStart,
  contentGenerationSuccess,
  contentGenerationError,
  contentGenerationCancelled,
  resetContentGeneration,
  resetSelection,
  setContentGenerationConfig,
  setContentGenerationSelection,
  setAIAssistantPlacement,
} = AISlice.actions

export {
  AISlice,
  selectAIAssistantOpened,
  selectAIAssistantResponsive,
  setAIAssistantPlacement,
  selectAIAssistantPlacement,
  selectContentGenerationStatus,
  selectContentGenerationConfig,
  selectContentGenerationSelection,
  toggleAIAssistant,
  contentGenerationStart,
  contentGenerationSuccess,
  contentGenerationError,
  contentGenerationCancelled,
  resetContentGeneration,
  resetSelection,
  setContentGenerationConfig,
  setContentGenerationSelection,
}
