import { grayDarker, white } from '@bufferapp/ui/style/colors'
import { fontFamily, fontWeight } from '@bufferapp/ui/style/fonts'
import * as Tooltip from '@radix-ui/react-tooltip'
import styled from 'styled-components'

export const Content = styled(Tooltip.Content)`
  font-family: ${fontFamily};
  font-size: 12px;
  line-height: 14px;
  font-weight: ${fontWeight};
  border-radius: 4px;
  border: none;
  padding: 6px 10px;
  color: ${white};
  background-color: ${grayDarker};
  z-index: 5000;
  max-width: 200px;
  box-shadow: 2px 2px 10px hsl(0deg 0% 0% / 10%);

  &[data-state='delayed-open'][data-side='top'] {
    animation-name: slideUpAndFade;
  }
  &[data-state='delayed-open'][data-side='right'] {
    animation-name: slideRightAndFade;
  }
  &[data-state='delayed-open'][data-side='bottom'] {
    animation-name: slideDownAndFade;
  }
  &[data-state='delayed-open'][data-side='left'] {
    animation-name: slideLeftAndFade;
  }

  @keyframes slideUpAndFade {
    from {
      opacity: 0;
      transform: translateY(4px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideRightAndFade {
    from {
      opacity: 0;
      transform: translateX(-4px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }

  @keyframes slideDownAndFade {
    from {
      opacity: 0;
      transform: translateY(-4px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes slideLeftAndFade {
    from {
      opacity: 0;
      transform: translateX(4px);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
`

export const Arrow = styled(Tooltip.Arrow)`
  fill: ${grayDarker};
`
