import React from 'react'
import type { GraphImage } from '../../types'
import { env } from '~publish/env'

export const UnsplashLinks = ({ imageLinked }: { imageLinked: GraphImage }) => {
  return (
    <div data-testid="unsplash-attribution" className="unsplash-attribution">
      <span className="unsplash-author">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={imageLinked.authorUrl}
        >
          {imageLinked.author}
        </a>
      </span>
      <span className="unsplash-link">
        {' '}
        for{' '}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://unsplash.com/?utm_source=${env.VITE_UNSPLASH_APP_ID}&utm_medium=referral`}
        >
          Unsplash
        </a>
      </span>
    </div>
  )
}
