import styled from 'styled-components'
import CharacterCount from '../CharacterCount'

export const CommentCharacterCount = styled(CharacterCount)`
  position: absolute;
  bottom: ${(props) => (props.count > props.maxCount ? '30px' : '10px')};
  right: 6px;
  border-radius: 4px;
  padding: 3px 3px;
  line-height: 12px;
`
