import React, { useState } from 'react'

import getErrorBoundary from '~publish/legacy/web/components/ErrorBoundary'
import { ScheduleTable } from './components/ScheduleTable'

import {
  AlertDialog,
  Button,
  Flex,
  Heading,
  Paragraph,
  Separator,
  toast,
  TrashIcon,
} from '@buffer-mono/popcorn'
import {
  selectCurrentProfile,
  selectCurrentProfilePostingDays,
  selectCurrentProfilePostingDaysIsEmpty,
} from '~publish/legacy/profile-sidebar/selectors'
import { useAppDispatch, useAppSelector } from '~publish/legacy/store'
import { selectWeekStartsMonday } from '../user/selectors'
import { PostingTimeForm } from './components/PostingTimeForm'
import { TimezoneInputForm } from './components/TimezoneInputForm'
import styles from './PostingSchedule.module.css'
import { updatePausedSchedules } from './thunks/updatePausedSchedules'
import {
  BufferTrackerReact as BufferTracker,
  Client,
  Product,
} from '@buffer-mono/tracking-plan'
import { useCurrentOrganization } from '../accountContext'

const ErrorBoundary = getErrorBoundary(true)

const PostingSchedule = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const [showClearAllModal, setShowClearAllModal] = useState(false)
  const [isClearingPostingTimes, setIsClearingPostingTimes] = useState(false)
  const profile = useAppSelector(selectCurrentProfile)
  const currentOrganization = useCurrentOrganization()
  const currentOrganizationId = currentOrganization.id

  const days = useAppSelector(selectCurrentProfilePostingDays)
  const emptySchedule = useAppSelector(selectCurrentProfilePostingDaysIsEmpty)
  const weekStartsMonday = useAppSelector(selectWeekStartsMonday) ? 1 : 0

  const hideClearAllModal = (): void => setShowClearAllModal(false)
  const openClearAllModal = (): void => setShowClearAllModal(true)

  if (!profile) return <></>

  const isManager = profile.isManager

  const onClearAllPostingTimesClick = async (): Promise<void> => {
    const profileId = profile.id
    setIsClearingPostingTimes(true)
    BufferTracker.postingScheduleCleared({
      product: Product.Publish,
      organizationId: currentOrganizationId ?? '',
      clientName: Client.PublishWeb,
      channel: profile.service,
      channelType: profile.service_type,
      channelId: profile.id,
      channelServiceId: profile.serviceId,
      channelUsername: profile.username,
    })
    const result = await dispatch(
      updatePausedSchedules({
        profileId,
        schedules: [],
        pausedSchedules: [],
        emptyPausedSchedules: true,
      }),
    )
    if (updatePausedSchedules.fulfilled.match(result)) {
      toast.success(result.payload.message)
    } else {
      toast.error(
        `Sorry! Something went wrong while updating your posting times: ${result.error.message}. Would you be up for trying again?`,
      )
    }
    setIsClearingPostingTimes(false)
    hideClearAllModal()
  }

  return (
    <ErrorBoundary>
      <Flex direction="column" data-testid="posting-schedule-wrapper">
        <TimezoneInputForm />
        <Separator />
        <Flex
          align="normal"
          direction="column"
          gap="lg"
          style={{ width: '100%' }}
        >
          <Flex align="end" justify="between" style={{ width: '100%' }}>
            <Flex direction="column">
              <Heading size="small" as="h3">
                Posting times
              </Heading>
              <Paragraph className={styles.postingScheduleParagraph}>
                Your posting times tells Buffer when to send out posts in your
                Queue. For example, the next 5 posts you add to your Queue will
                be sent in the next 5 upcoming time slots you choose below.
              </Paragraph>
            </Flex>
            {!emptySchedule && isManager && (
              <Button
                size="large"
                variant="tertiary"
                onClick={openClearAllModal}
                data-testid="posting-schedule-clear-all-trigger"
              >
                <TrashIcon /> Clear All times
              </Button>
            )}
          </Flex>

          <AlertDialog open={showClearAllModal}>
            <AlertDialog.Content>
              <AlertDialog.Title>Clear all times?</AlertDialog.Title>
              <AlertDialog.Description>
                All your posting times will be removed. This action cannot be
                undone.
              </AlertDialog.Description>
              <AlertDialog.Actions>
                <AlertDialog.Cancel>
                  <Button
                    size="large"
                    onClick={hideClearAllModal}
                    variant="tertiary"
                  >
                    Cancel
                  </Button>
                </AlertDialog.Cancel>
                <AlertDialog.Action>
                  <Button
                    size="large"
                    onClick={onClearAllPostingTimesClick}
                    loading={isClearingPostingTimes}
                    variant="critical"
                    data-testid="posting-schedule-clear-all-confirm"
                  >
                    Clear
                  </Button>
                </AlertDialog.Action>
              </AlertDialog.Actions>
            </AlertDialog.Content>
          </AlertDialog>

          <Flex style={{ width: '100%' }}>
            <ScheduleTable
              disabled={!isManager}
              days={
                weekStartsMonday
                  ? days
                  : days.slice(-1).concat(days.slice(0, -1))
              }
            />
          </Flex>
          {isManager && <PostingTimeForm />}
        </Flex>
      </Flex>
    </ErrorBoundary>
  )
}

export { PostingSchedule }
