import React from 'react'
import type { DraggableAttributes } from '@dnd-kit/core'

export type ColumnDragContextType = {
  dragging: boolean
  draggable: boolean
  setNodeRef: (node: HTMLElement | null) => void
  setActivatorNodeRef: (element: HTMLElement | null) => void
  style: React.CSSProperties
  listeners: {
    onMouseDown: (event: React.MouseEvent) => void
    onKeyDown: (event: React.KeyboardEvent) => void
    onTouchStart: (event: React.TouchEvent) => void
  }
  attributes: DraggableAttributes
}

export const ColumnDragContext = React.createContext<
  ColumnDragContextType | undefined
>(undefined)

export const useDraggableColumn = (): ColumnDragContextType => {
  const context = React.useContext(ColumnDragContext)
  if (context === undefined) {
    throw new Error(
      'useDraggableColumn must be used within a ColumnDragContextProvider',
    )
  }
  return context
}
