import keyWrapper from '~publish/helpers/keywrapper'

export const actionTypes = keyWrapper('PUBLISH_ANALYTICS', {
  INIT: 'INIT',
  TRACK_EVENT: 'TRACK_EVENT',
  PAGE_CHANGE: 'PAGE_CHANGE',
})

export const actions = {
  // @ts-expect-error TS(7006) FIXME: Parameter 'userId' implicitly has an 'any' type.
  init(userId, payload) {
    return {
      type: actionTypes.INIT,
      userId,
      payload,
    }
  },
  /** @deprecated in favour of BufferTracker direct calls */
  // @ts-expect-error TS(7006) FIXME: Parameter 'eventName' implicitly has an 'any' type... Remove this comment to see the full error message
  trackEvent(eventName, payload) {
    return {
      type: actionTypes.TRACK_EVENT,
      eventName,
      payload,
    }
  },
  // @ts-expect-error TS(7006) FIXME: Parameter 'pageName' implicitly has an 'any' type.
  pageChange(pageName, payload) {
    return {
      type: actionTypes.PAGE_CHANGE,
      pageName,
      payload,
    }
  },
}

export default actions
