import { isElement, type TNode } from '@udecode/plate'
import type { PlainText } from '~publish/legacy/editor/BufferEditor/types.plate'
import type { AutocompleteData } from '../../autocomplete'
import type {
  AutocompleteInputElement,
  AutocompleteInputElementInterface,
} from '../../autocomplete/nodes'

export const TWITTER_MENTION_INPUT_ELEMENT_TYPE = 'twitter-mention-input'
export const TWITTER_MENTION_INPUT_ELEMENT_TRIGGER = '@'
export const VALID_TWITTER_MENTION_TEXT_REGEX = /([a-zA-Z0-9_]+)/

export interface TwitterMentionInputElement extends AutocompleteInputElement {
  type: typeof TWITTER_MENTION_INPUT_ELEMENT_TYPE
  trigger: typeof TWITTER_MENTION_INPUT_ELEMENT_TRIGGER
  children: [PlainText]
  valid: boolean
  autocompleted: boolean
  data?: AutocompleteData
}

export const TwitterMentionInputElement: AutocompleteInputElementInterface<TwitterMentionInputElement> =
  {
    type: TWITTER_MENTION_INPUT_ELEMENT_TYPE,
    trigger: TWITTER_MENTION_INPUT_ELEMENT_TRIGGER,
    validTextRegex: VALID_TWITTER_MENTION_TEXT_REGEX,
    validMentionRegex: RegExp(
      `${TWITTER_MENTION_INPUT_ELEMENT_TRIGGER}${VALID_TWITTER_MENTION_TEXT_REGEX.source}`,
    ),
    is(node: TNode): node is TwitterMentionInputElement {
      return isElement(node) && node.type === TwitterMentionInputElement.type
    },
    new: (text = ''): TwitterMentionInputElement => {
      return {
        type: TwitterMentionInputElement.type,
        children: [{ text: `${TwitterMentionInputElement.trigger}${text}` }],
        valid: true,
        autocompleted: false,
        trigger: TwitterMentionInputElement.trigger,
      }
    },
  }
