import type { ApolloCache, Reference, StoreObject } from '@apollo/client'

import { graphql } from '~publish/gql'
import type {
  DropPostInput,
  DropPostMutation,
  GetPostListQuery,
} from '~publish/gql/graphql'
import { useTypedMutation } from '~publish/components/PostCard/useTypedMutation'

/**
 * GraphQL mutation for dropping a post to a new date.
 */
const DropPost = graphql(/* GraphQL */ `
  mutation DropPost($input: DropPostInput!) {
    dropPost(input: $input) {
      __typename
      ... on PostActionSuccess {
        post {
          id
          dueAt
        }
      }
      ... on MutationError {
        message
      }
    }
  }
`)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export const useDropPostMutation = () =>
  useTypedMutation(DropPost, (data) => data.dropPost, {
    successTypename: 'PostActionSuccess',
    optimisticResponse: buildOptimisticResponse,
    update: (cache: ApolloCache<any>, { data }, { variables }) => {
      if (data?.dropPost.__typename === 'PostActionSuccess' && variables) {
        updateCache(cache, variables.input.id, variables.input.dueAt)
      }
    },
  })

function buildOptimisticResponse({
  input,
}: {
  input: DropPostInput
}): DropPostMutation {
  return {
    __typename: 'Mutation',
    dropPost: {
      __typename: 'PostActionSuccess',
      post: {
        __typename: 'Post',
        id: input.id,
        dueAt: input.dueAt,
      },
    },
  }
}

function updateCache(
  cache: ApolloCache<any>,
  id: string,
  newDueAt: string,
): void {
  cache.modify({
    fields: {
      posts: (
        existingPosts: Reference | StoreObject,
        { readField, toReference },
      ) => {
        const edges = readField(
          'edges',
          existingPosts,
        ) as GetPostListQuery['posts']['edges']
        if (!edges) return existingPosts

        const newEdges = edges.map((edge) => {
          const postId = readField('id', edge.node)
          if (postId === id) {
            return {
              ...edge,
              node: toReference({
                __typename: 'Post',
                id: postId,
                dueAt: newDueAt,
              }),
            }
          }
          return edge
        })

        // Sort the edges based on dueAt
        newEdges.sort((a, b) => {
          const aDueAt = readField('dueAt', a.node) as string
          const bDueAt = readField('dueAt', b.node) as string
          return new Date(aDueAt).getTime() - new Date(bDueAt).getTime()
        })

        return {
          ...existingPosts,
          edges: newEdges,
        }
      },
    },
  })
}
