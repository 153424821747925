import type {
  AttachmenTypeStrings,
  MediaAttachmenTypeStrings,
  ServiceDefinition,
} from './types'

export const BaseService: Omit<ServiceDefinition, 'name' | 'formattedName'> = {
  charLimit: null,
  allowedPostTypes: [],
  titleCharLimit: null,
  commentCharLimit: null,
  unavailableAttachmentTypes: [],
  requiredAttachmentType: null,
  unavailableMediaAttachmentTypes: [],
  maxAttachableImagesCount: () => 1,
  maxHashtags: null, // No maximum by default
  maxMentions: null, // No maximum by default
  nativeVideoSizeLimit: null,
  nativeVideoDurationLimit: null,
  attachmentMinSize: null,
  videoMaxSize: null,
  videoMinResolution: null,
  videoMinDurationMs: null,
  videoMaxDurationMs: null,
  videoMaxWidth: null,
  videoMinFrameRate: null,
  videoMaxFrameRate: null,
  videoMaxSizeReels: null,
  gifMaxSize: null,
  imageMaxSize: null,
  imageMaxWidth: null,
  imageMaxHeight: null,
  imageMinWidth: null,
  imageMinHeight: null,
  imageMaxPixelCount: null,
  hasSubprofiles: false,
  canHaveSourceUrl: false,
  requiresText: false,
  requiresSourceUrl: false,
  canEditLinkAttachment: false,
  canEditVideoAttachment: false,
  canHaveLocation: false,
  canHaveUserTags: false,
  usesImageFirstLayout: false,
  isOmni: false,
  transcodeVideo: false,
  canEditVideoTitle: false,
  onlyAllowsVerticalVideo: false,
  shouldShowDuplicateContentWarning: false,
  // Reels
  videoMinDurationMsReels: null,
  videoMaxDurationMsReels: null,
  videoMinFrameRateReels: null,
  videoMaxFrameRateReels: null,
  videoMinWidthReels: null,
  videoMinHeightReels: null,
  videoMinAspectRatioReels: null,
  videoMaxAspectRatioReels: null,
  // Stories
  videoMinDurationMsStories: null,
  videoMaxDurationMsStories: null,
  videoMinWidthStories: null,
  videoMinHeightStories: null,
  videoMinAspectRatioStories: null,
  videoMaxAspectRatioStories: null,
  videoMaxSizeStories: null,
  imageMaxSizeStories: null,
  videoMinFrameRateStories: null,
  videoMaxFrameRateStories: null,
  // Threads
  supportsThread: false,
  nameOfPost: 'Post',

  // Should shorten links
  isShorteningLinksAvailable: true,

  // Supports Mobile Reminders
  supportsMobileReminders: false,

  canHaveAttachmentType(this: ServiceDefinition, type: AttachmenTypeStrings) {
    return !this.unavailableAttachmentTypes.includes(type)
  },

  canHaveSomeAttachmentType(this: ServiceDefinition, types) {
    return types.some((type) => this.canHaveAttachmentType(type))
  },

  canHaveMediaAttachmentType(
    this: ServiceDefinition,
    type: MediaAttachmenTypeStrings,
  ) {
    return !this.unavailableMediaAttachmentTypes.includes(type)
  },

  canHaveSomeMediaAttachmentTypes(this: ServiceDefinition, types) {
    return types.some((type) => this.canHaveMediaAttachmentType(type))
  },

  isTiktok(this: ServiceDefinition) {
    return this.name === 'tiktok'
  },

  isInstagram(this: ServiceDefinition) {
    return this.name === 'instagram'
  },

  isFacebook(this: ServiceDefinition) {
    return this.name === 'facebook'
  },

  isTwitter(this: ServiceDefinition) {
    return this.name === 'twitter'
  },

  isPinterest(this: ServiceDefinition) {
    return this.name === 'pinterest'
  },

  isLinkedin(this: ServiceDefinition) {
    return this.name === 'linkedin'
  },

  isGoogleBusiness(this: ServiceDefinition) {
    return this.name === 'googlebusiness'
  },

  isStartPage(this: ServiceDefinition) {
    return this.name === 'startPage'
  },

  isMastodon(this: ServiceDefinition) {
    return this.name === 'mastodon'
  },

  isYoutube(this: ServiceDefinition) {
    return this.name === 'youtube'
  },

  isThreads(this: ServiceDefinition) {
    return this.name === 'threads'
  },

  isBluesky(this: ServiceDefinition) {
    return this.name === 'bluesky'
  },

  hasPostTypes(this: ServiceDefinition): boolean {
    return !!this.allowedPostTypes?.length
  },

  getDefaultPostType(this: ServiceDefinition): string | null {
    return this.allowedPostTypes[0] ?? null
  },
}
