import { Avatar } from '@bufferapp/ui'
import React from 'react'
import { useAccountPrivileges } from '~publish/legacy/accountContext'
import type { PublishingNote as NoteType } from '~publish/gql/graphql'

import { AuthorLabel, NoteActions, NoteInput } from './components'
import { useIsAuthor, useNoteActions } from './hooks'
import { AvatarWrapper, Content, NoteWrapper } from './styles'

const DEFAULT_AVATAR = 'https://s3.amazonaws.com/buffer-ui/Default+Avatar.png'

type Props = {
  note: NoteType
  highlighted?: boolean
  onEdit: (noteId: string, text: string) => void
  onDelete: () => void
}

export const Note = React.forwardRef<HTMLLIElement, Props>(
  (
    { note, highlighted = false, onEdit, onDelete }: Props,
    noteRef,
  ): JSX.Element => {
    const { text, createdAt, author, editedAt } = note
    const { id: authorId, email, avatar: authorAvatar } = author

    const privileges = useAccountPrivileges()

    const avatar = authorAvatar ?? DEFAULT_AVATAR

    const isAuthor = useIsAuthor(authorId)
    const authorName = isAuthor ? 'You' : email

    const {
      isEditing,
      editedText,
      setEditedText,
      handleEditOrSave,
      handleDeleteOrCancel,
    } = useNoteActions({ note, onEdit, onDelete })

    return (
      <NoteWrapper ref={noteRef} $highlighted={highlighted}>
        <AvatarWrapper>
          <Avatar src={avatar} alt="Avatar" size="small" />
        </AvatarWrapper>
        <Content>
          <AuthorLabel createdAt={createdAt} authorName={authorName} />
          <NoteInput
            editedText={editedText}
            text={text}
            setEditedText={setEditedText}
            isEdited={!!editedAt}
            isEditing={isEditing}
            onSave={handleEditOrSave}
            onCancel={handleDeleteOrCancel}
          />
          <NoteActions
            isEditing={isEditing}
            canEdit={isAuthor}
            canDelete={isAuthor || !!privileges?.canManageAllNotes}
            handlePrimaryClick={handleEditOrSave}
            handleSecondaryClick={handleDeleteOrCancel}
          />
        </Content>
      </NoteWrapper>
    )
  },
)

Note.displayName = 'Note'
