import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import ProfileSidebar from '~publish/legacy/profile-sidebar'
import { PageSidebar } from '~publish/components/PageSidebar'
import { PublishSidebar } from '~publish/components/PublishSidebar'

import { usePublishRevamp } from '~publish/hooks/usePublishRevamp'
import { Sidebar } from '@buffer-mono/popcorn'
import { useSplitEnabled } from '@buffer-mono/features'

const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  height: 100%;
  overflow: hidden;
`

const ProfileSidebarStyle = styled.div`
  flex-basis: 16rem;
  width: 16rem;
  min-width: 16rem;
`

const ContentStyle = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
`

const PageWithSidebarWrapper = ({
  children,
  profileId,
  tabId,
  className,
}: {
  children: any
  profileId?: string
  tabId?: string
  className?: string
}): JSX.Element => {
  const [publishRevamp] = usePublishRevamp()
  const { isEnabled: isNewSidebarEnabled } = useSplitEnabled(
    'CT-collapsible-sidebar',
  )

  const newSidebar = publishRevamp && isNewSidebarEnabled

  if (newSidebar) {
    return (
      <Sidebar.Provider>
        <PublishSidebar />
        <ContentStyle>{children}</ContentStyle>
      </Sidebar.Provider>
    )
  }

  return (
    <Wrapper className={className}>
      {publishRevamp ? (
        <PageSidebar />
      ) : (
        <ProfileSidebarStyle>
          {/* @ts-expect-error TS(2322) FIXME: Type '{ profileId: string | undefined; tabId: stri... Remove this comment to see the full error message */}
          <ProfileSidebar profileId={profileId} tabId={tabId} />
        </ProfileSidebarStyle>
      )}
      <ContentStyle>{children}</ContentStyle>
    </Wrapper>
  )
}

PageWithSidebarWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  profileId: PropTypes.string,
  tabId: PropTypes.string,
  className: PropTypes.string,
}

PageWithSidebarWrapper.defaultProps = {
  profileId: null,
  tabId: null,
  className: '',
}

export default PageWithSidebarWrapper
