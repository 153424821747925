import styled, { css, type FlattenSimpleInterpolation } from 'styled-components'

import { grayLighter, gray, white } from '@bufferapp/ui/style/colors'
import { borderRadius } from '@bufferapp/ui/style/borders'
import { Button } from '@bufferapp/ui'

export const AddUpdateButton = styled(Button)<{ fullWidth: boolean }>`
  opacity: 0;
  transition: opacity 0.1s ease-in-out;

  &:focus-within {
    opacity: 1;
  }

  ${(props): FlattenSimpleInterpolation | false =>
    !props.fullWidth &&
    css`
      button {
        padding: 6px;
        height: 24px;
        width: 24px;
        justify-content: center;
      }
    `}

  ${(props): FlattenSimpleInterpolation | false =>
    props.fullWidth &&
    css`
      background-color: ${grayLighter};

      &:hover {
        border-color: ${gray};
      }

      button {
        svg {
          border: 1px solid ${gray};
          padding: 2px;
          border-radius: ${borderRadius};
          background: ${white};
        }
      }
    `}
`

// Fixes position of tooltip
export const ButtonWrapper = styled.div`
  float: right;
`
