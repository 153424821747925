import styled from 'styled-components'
import {
  grayLight,
  yellowLightest,
  yellowDarker,
} from '@bufferapp/ui/style/colors'

/**
 *
 */
export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 8px 16px;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  border-bottom: 1px solid ${grayLight};
  background: ${yellowLightest};

  svg {
    color: ${yellowDarker};
    fill: ${yellowDarker};
  }
`

export const Message = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  p {
    padding: 0;
    margin: 0;
    color: ${yellowDarker};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
`

export const Dismiss = styled.div`
  padding: 0;
  margin: 0;
  display: flex;
  cursor: pointer;
`
