/**
 * Component that displays visible notifications, with the ability to only
 * pay attention to a subset of notification scopes.
 *
 * Its styling can be customized at call site using either props.className or
 * props.classNames: the first to only extend the container's classname with another
 * classname, the second to extend both the container's and the notifications'
 * classnames with a map of classnames (see propTypes for shape).
 *
 * This component accepts children, which will then be used as children of the
 * individual notifications the component displays.
 */

import React from 'react'
import PropTypes from 'prop-types'
import { NotificationTypes } from '../AppConstants'
import Notification from './Notification'
import styles from './css/NotificationContainer.module.css'
import { visibleNotificationsPropType } from './ComposerPropTypes'

class NotificationContainer extends React.Component {
  static propTypes = {
    visibleNotifications: visibleNotificationsPropType.isRequired,
    scope: PropTypes.string,
    notScopes: PropTypes.array,
    type: PropTypes.string,
    notTypes: PropTypes.array,
    className: PropTypes.string,
    classNames: PropTypes.shape({
      container: PropTypes.string,
      notification: PropTypes.string,
      successNotification: PropTypes.string,
      errorNotification: PropTypes.string,
      notificationCloseButton: PropTypes.string,
    }),
    style: PropTypes.object,
    showCloseIcon: PropTypes.bool,
    children: PropTypes.node,
    onClick: PropTypes.func,
    onClose: PropTypes.func,
  }

  static defaultProps = {
    scope: null,
    notScopes: [],
    type: null,
    notTypes: [],
    className: null,
    classNames: {
      container: null,
      notification: null,
      successNotification: null,
      errorNotification: null,
      notificationCloseButton: null,
    },
    style: {},
    showCloseIcon: false,
    onClick: () => {},
    onClose: () => {},
  }

  render() {
    const {
      // @ts-expect-error TS(2339) FIXME: Property 'scope' does not exist on type 'Readonly<... Remove this comment to see the full error message
      scope,
      // @ts-expect-error TS(2339) FIXME: Property 'notScopes' does not exist on type 'Reado... Remove this comment to see the full error message
      notScopes,
      // @ts-expect-error TS(2339) FIXME: Property 'type' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      type,
      // @ts-expect-error TS(2339) FIXME: Property 'notTypes' does not exist on type 'Readon... Remove this comment to see the full error message
      notTypes,
      // @ts-expect-error TS(2339) FIXME: Property 'className' does not exist on type 'Reado... Remove this comment to see the full error message
      className,
      // @ts-expect-error TS(2339) FIXME: Property 'classNames' does not exist on type 'Read... Remove this comment to see the full error message
      classNames,
      // @ts-expect-error TS(2339) FIXME: Property 'showCloseIcon' does not exist on type 'R... Remove this comment to see the full error message
      showCloseIcon,
      // @ts-expect-error TS(2339) FIXME: Property 'children' does not exist on type 'Re... Remove this comment to see the full error message
      children,
    } = this.props

    // @ts-expect-error TS(2339) FIXME: Property 'visibleNotifications' does not exist on ... Remove this comment to see the full error message
    let { visibleNotifications: notificationsToDisplay } = this.props

    if (scope !== null) {
      notificationsToDisplay = notificationsToDisplay.filter(
        // @ts-expect-error TS(7006) FIXME: Parameter 'notif' implicitly has an 'any' type.
        (notif) => notif.scope === scope,
      )
    }

    if (notScopes.length > 0) {
      notificationsToDisplay = notificationsToDisplay.filter(
        // @ts-expect-error TS(7006) FIXME: Parameter 'notif' implicitly has an 'any' type.
        (notif) => !notScopes.includes(notif.scope),
      )
    }

    if (type !== null) {
      notificationsToDisplay = notificationsToDisplay.filter(
        // @ts-expect-error TS(7006) FIXME: Parameter 'notif' implicitly has an 'any' type.
        (notif) => notif.type === type,
      )
    }

    if (notTypes.length > 0) {
      notificationsToDisplay = notificationsToDisplay.filter(
        // @ts-expect-error TS(7006) FIXME: Parameter 'notif' implicitly has an 'any' type.
        (notif) => !notTypes.includes(notif.type),
      )
    }

    if (notificationsToDisplay.length === 0) return null

    const containerStyles = [
      styles.notificationContainer,
      classNames.container || className,
    ].join(' ')

    const notificationClassNameMap = {
      [NotificationTypes.ERROR]: [
        classNames.notification,
        classNames.errorNotification,
      ].join(' '),

      [NotificationTypes.SUCCESS]: [
        classNames.notification,
        classNames.successNotification,
      ].join(' '),

      [NotificationTypes.INFO]: [
        classNames.notification,
        classNames.infoNotification,
      ].join(' '),
    }

    return (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <div
        className={containerStyles}
        // @ts-expect-error TS(2339) FIXME: Property 'onClick' does not exist on type 'Readonl... Remove this comment to see the full error message
        onClick={this.props.onClick}
        // @ts-expect-error TS(2339) FIXME: Property 'style' does not exist on type 'Readonly<... Remove this comment to see the full error message
        style={this.props.style}
      >
        {/* @ts-expect-error TS(7006) FIXME: Parameter 'notif' implicitly has an 'any' type. */}
        {notificationsToDisplay.map((notif) => (
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          <Notification
            id={notif.id}
            type={notif.type}
            message={notif.message}
            showCloseIcon={showCloseIcon}
            showSoftAndHardCloseOptions={notif.showSoftAndHardCloseOptions}
            // @ts-expect-error TS(2339) FIXME: Property 'onClose' does not exist on type 'Readonl... Remove this comment to see the full error message
            onClose={this.props.onClose}
            classNames={{
              // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              notification: notificationClassNameMap[notif.type],
              closeButton: classNames.notificationCloseButton,
            }}
            cta={notif.cta}
            key={notif.id}
          >
            {children}
          </Notification>
        ))}
      </div>
    )
  }
}

export default NotificationContainer
