import { graphql } from '~publish/gql'
import { useTypedMutation } from './useTypedMutation'

export const AddPostToQueue = graphql(/* GraphQL */ `
  mutation AddPostToQueue($id: PostId!) {
    addPostToQueue(input: { id: $id }) {
      __typename
      ... on PostActionSuccess {
        post {
          id
          status
          dueAt
        }
      }
      ... on MutationError {
        message
      }
    }
  }
`)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export const useAddPostToQueue = () =>
  useTypedMutation(AddPostToQueue, (data) => data.addPostToQueue, {
    refetchQueries: ['GetPostList'],
    successTypename: 'PostActionSuccess',
  })
