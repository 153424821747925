import { Slot } from '@buffer-mono/popcorn'
import React from 'react'
import { type CtaProperties, useCta } from '~publish/hooks/useCta'

type CtaButtonWrapperProps = {
  cta: CTA
  options?: CtaProperties
  children: React.ReactNode
}

/**
 * Use this component to easily track CTA views and clicks.
 *
 * @example
 * ```tsx
 * <CtaButtonWrapper cta="publish-allChannels-betaOptIn-joinBeta-1">
 *  <Button>Open</Button>
 * </CtaButtonWrapper>
 * ````
 *
 * @example
 * ```tsx
 * <CtaButtonWrapper cta="publish-allChannels-header-upgrade-1" options={{
 *  upgradePathName: 'allChannels-upgrade',
 * }}>
 *  <Button>Open</Button>
 * </CtaButtonWrapper>
 * ````
 */
export const CtaButtonWrapper = ({
  cta,
  options,
  children,
}: CtaButtonWrapperProps): JSX.Element => {
  const { viewed: ctaViewed, clicked: ctaClicked } = useCta(cta, options)

  React.useEffect(() => {
    ctaViewed()
  }, [])

  return <Slot onClick={ctaClicked}>{children}</Slot>
}
