import React from 'react'
import PropTypes from 'prop-types'
import UserTags from '~publish/legacy/composer-user-tags'

import Modal from '../shared-components/modal/modal'
import ModalActionCreators from '../shared-components/modal/actionCreators'
import ComposerActionCreators from '../action-creators/ComposerActionCreators'
import style from './css/InstagramUserTags.module.css'
import AppStore from '../stores/AppStore'

// @ts-expect-error TS(7031) FIXME: Binding element 'media' implicitly has an 'any' ty... Remove this comment to see the full error message
const InstagramUserTags = ({ media, draftId, userTags = [] }) => {
  const modalClassNames = {
    modal: style.modal,
  }
  const selectedIgProfiles = AppStore.getSelectedProfilesForService('instagram')
  // @ts-expect-error TS(7006) FIXME: Parameter 'tags' implicitly has an 'any' type.
  const saveGlobalTags = (tags) => {
    ComposerActionCreators.updateDraftImageUserTags(draftId, tags, media.url)
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
    ModalActionCreators.closeModal()
  }

  // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0.
  const onCancel = () => ModalActionCreators.closeModal()

  return (
    // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; classNames: { modal: an... Remove this comment to see the full error message
    <Modal
      classNames={modalClassNames}
      hideCloseButton
      preventCloseOnOverlayClick
    >
      <UserTags
        media={media}
        userTags={userTags}
        saveGlobalTags={saveGlobalTags}
        onCancel={onCancel}
        selectedChannels={selectedIgProfiles}
      />
    </Modal>
  )
}

InstagramUserTags.propTypes = {
  media: PropTypes.shape({
    url: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
  }).isRequired,
  draftId: PropTypes.string.isRequired,
  userTags: PropTypes.arrayOf(
    PropTypes.shape({
      username: PropTypes.string,
      x: PropTypes.string,
      y: PropTypes.string,
    }),
  ),
}

InstagramUserTags.defaultProps = {
  userTags: [],
}

export default InstagramUserTags
