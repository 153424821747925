import removeEmptyValuesFromObject from '~publish/legacy/utils/removeEmptyValuesFromObject'

export type AutoComposerData = {
  shouldAutoOpenComposer: boolean
  selectedProfileIds?: string[]
  cta?: string
}

/**
 * Construct valid composer data object from a URL query search string
 * @param queryParams - Query search string
 * @returns {AutoComposerData}
 */
const getQueryParamsFromURL = (queryParams: URLSearchParams) => {
  if (!queryParams) return {}

  const query = new URLSearchParams(queryParams)
  const shouldAutoOpenComposer = query.get('auto_open_composer')
  const selectedProfileIds = query.getAll('profile_id')
  const cta = query.get('cta')

  const data = {
    shouldAutoOpenComposer: shouldAutoOpenComposer === 'true',
    selectedProfileIds,
    cta,
  }

  // Remove null values
  return removeEmptyValuesFromObject(data)
}

export default getQueryParamsFromURL
