// @ts-expect-error TS(7006) FIXME: Parameter 'el' implicitly has an 'any' type.
const getElRefOffset = (el, dir = 'top', ref = document.body) => {
  const offsetPosMethodName = dir !== 'left' ? 'offsetTop' : 'offsetLeft'
  let offset = el[offsetPosMethodName]

  // eslint-disable-next-line no-cond-assign
  while (el.offsetParent !== null && (el = el.offsetParent) !== ref) {
    offset += el[offsetPosMethodName]
  }

  return offset
}

const isEdge = () => window.navigator.userAgent.indexOf('Edge') > -1

// @ts-expect-error TS(7006) FIXME: Parameter 'file' implicitly has an 'any' type.
const generateThumbnailFromVideo = (file) => {
  return new Promise((resolve, reject) => {
    // load the file to a video player
    const videoPlayer = document.createElement('video')
    videoPlayer.setAttribute('src', URL.createObjectURL(file))
    videoPlayer.load()
    videoPlayer.addEventListener('error', (ex) => {
      console.error('error generating thumbnail', Object.keys(ex), ex)
      const error = new Error('Thumbnail could not be generated')
      // @ts-expect-error TS(2339) FIXME: Property 'error' does not exist on type 'EventTarg... Remove this comment to see the full error message
      error.cause = ex?.target?.error
      reject(error)
    })
    // load metadata of the video to get video duration and dimensions
    videoPlayer.addEventListener('loadedmetadata', () => {
      // delay seeking or else 'seeked' event won't fire on Safari
      setTimeout(() => {
        videoPlayer.currentTime = 0
      }, 200)
      // extract video thumbnail once seeking is complete
      videoPlayer.addEventListener('seeked', () => {
        // define a canvas to have the same dimension as the video
        const canvas = document.createElement('canvas')
        canvas.width = videoPlayer.videoWidth
        canvas.height = videoPlayer.videoHeight
        // draw the video frame to canvas
        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
        canvas
          .getContext('2d')
          .drawImage(videoPlayer, 0, 0, canvas.width, canvas.height)
        canvas.toBlob(
          (blob) => {
            const thumbnailName = file.name.replace(/\.[^/.]+$/, '.jpeg')
            // @ts-expect-error TS(2322) FIXME: Type 'Blob | null' is not assignable to type 'Blob... Remove this comment to see the full error message
            const imageFile = new File([blob], thumbnailName, {
              // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
              type: blob.type,
            })
            resolve({
              imageFile,
            })
          },
          'image/jpeg',
          0.75 /* quality */,
        )
      })
    })
  })
}

/**
 * Note: reused from other project, will be good the refactor the logic and make it clearer
 *
 * Scroll elements into view, horizontally or vertically, when Element.scrollIntoView()
 * doesn't do exactly what we want (e.g. it doesn't always make an el entirely visible
 * if it already partly is).
 *
 * Takes parameters as an object:
 * - Mandatory:
 *   - el: the element to scroll into view (can optionally be replaced with
 *     param.offsets if these numbers are already known)
 *   - ref: the reference element (i.e. the one with the scrollbar) (must be
 *     a valid offset parent – body, table, th, td, or any positioned parent – when
 *     param.el isn't replaced with param.offsets)
 * - Optional:
 *   - axis: 'horizontal' or 'vertical' (default)
 *   - padding: padding to be left around param.el after scrolling (default: 0)
 */
const scrollIntoView = (() => {
  // @ts-expect-error TS(7006) FIXME: Parameter 'ref' implicitly has an 'any' type.
  const scrollElIntoView = function (ref, axis, elOffsets, elSize, padding) {
    const scrollPosPropName = axis === 'vertical' ? 'scrollTop' : 'scrollLeft'
    const refSize = axis === 'vertical' ? ref.offsetHeight : ref.offsetWidth
    const elOuterSize = elSize + padding * 2

    // Too large to fit in the ref? Position it so as to fill the ref
    if (elOuterSize > refSize) {
      ref[scrollPosPropName] = elOffsets[0] + (elOuterSize - refSize) / 2
      return
    }

    const refScrollPos = ref[scrollPosPropName]

    // Align to top/left?
    let diff = refScrollPos - elOffsets[0] + padding
    if (diff > 0) {
      ref[scrollPosPropName] -= diff
      return
    }

    // Or align to bottom/right?
    diff = elOffsets[1] - (refScrollPos + refSize) + padding
    if (diff > 0) ref[scrollPosPropName] += diff

    // Or do nothing
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'param' implicitly has an 'any' type.
  return function (param) {
    param.padding = param.padding || 0
    param.axis = param.axis === 'horizontal' ? 'horizontal' : 'vertical'

    let firstOffset

    if (param.el) {
      param.elSize =
        param.axis === 'vertical' ? param.el.offsetHeight : param.el.offsetWidth

      firstOffset = getElRefOffset(
        param.el,
        param.axis === 'vertical' ? 'top' : 'left',
        param.ref,
      )
      param.elOffsets = [firstOffset, firstOffset + param.elSize]
    } else {
      // If param.el not set, param.elOffsets shoud be set instead
      param.elSize = param.elOffsets[1] - param.elOffsets[0]
    }

    scrollElIntoView(
      param.ref,
      param.axis,
      param.elOffsets,
      param.elSize,
      param.padding,
    )
  }
})()

// @ts-expect-error TS(7006) FIXME: Parameter 'maxSize' implicitly has an 'any' type.
const resizeImageIfNeeded = (maxSize, sizeObj) => {
  let { width, height } = sizeObj
  if (maxSize) {
    if (width > height && width > maxSize) {
      height *= maxSize / width
      width = maxSize
    } else if (height > width && height > maxSize) {
      width *= maxSize / height
      height = maxSize
    }
  }
  return { width, height }
}

const isSafari = () =>
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

// Internet Explorer 6-11
// @ts-expect-error TS(2551) FIXME: Property 'documentMode' does not exist on type 'Do... Remove this comment to see the full error message
const isIE = () => /* @cc_on!@ */ false || !!document.documentMode

export {
  scrollIntoView,
  generateThumbnailFromVideo,
  resizeImageIfNeeded,
  isSafari,
  isEdge,
  isIE,
}
