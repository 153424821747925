import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import SegmentedControl from '@bufferapp/ui/SegmentedControl'

const Wrapper = styled.div`
  margin-left: auto;
`

type CalendarButtonsProps = {
  onCalendarClick: (type: string) => void
}

const options: Record<string, string> = {
  day: 'Day',
  week: 'Week',
  month: 'Month',
}

const CalendarButtons = ({
  onCalendarClick,
}: CalendarButtonsProps): JSX.Element => {
  const [selected, setSelected] = useState('day')

  const handleSelect = (value: string): void => {
    setSelected(value)
    onCalendarClick(value)
  }

  return (
    <Wrapper>
      <SegmentedControl>
        {Object.entries(options).map(([value, label]) => (
          <SegmentedControl.Option
            key={label}
            label={label}
            value={value}
            optionType="text"
            selected={selected === value}
            onClick={handleSelect}
          />
        ))}
      </SegmentedControl>
    </Wrapper>
  )
}

CalendarButtons.propTypes = {
  onCalendarClick: PropTypes.func.isRequired,
}

export default CalendarButtons
