import styled from 'styled-components'
import { white, green, grayLighter } from '@bufferapp/ui/style/colors'
import Button from '../../shared/Button'

type BoardButtonProps = {
  $isBoardSelected: boolean
  $canUnselectSubprofiles: boolean
}

export const BoardButton = styled(Button)<BoardButtonProps>`
  position: relative;
  box-sizing: border-box;
  height: 50px;
  padding: 8px 16px 8px 56px;
  line-height: 34px;
  border-top: 1px solid ${grayLighter};
  cursor: ${(props) => {
    return props.$isBoardSelected && !props.$canUnselectSubprofiles
      ? 'default'
      : 'pointer'
  }};
  outline: none;
  background-color: ${(props) =>
    props.$isBoardSelected && props.$canUnselectSubprofiles
      ? grayLighter
      : white};
  border-bottom: none;
  border-left: none;
  display: block;
  border-right: none;
  text-align: left;
  width: 100%;

  &:hover {
    background-color: #f8f8f8;
    box-shadow: inset 0 1px 0 0 #f4f7f9;
  }

  &:focus {
    background-color: #f8f8f8;
    box-shadow: inset 0 1px 0 0 #f4f7f9;
    outline: none;
  }
`

export const IconsContainer = styled.span`
  float: right;
  line-height: 30px;
`

export const BoardThumbnail = styled.img`
  position: absolute;
  top: 8px;
  left: 16px;
  width: 32px;
  height: 32px;
  border-radius: 4px;
`

export const SharedBoardIcon = styled.div`
  float: left;
  margin-left: 4px;
  color: #ced7df;
  font-size: 22px;
`

export const BoardIcon = styled.span<{ $isBoardSelected: boolean }>`
  display: inline-block;
  margin-left: 7px;
  font-size: 16px;
  visibility: hidden;
  color: ${green};

  ${BoardButton}:hover & {
    visibility: visible;
  }
`

export const BoardName = styled.span`
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
`
