import React, { type ReactNode } from 'react'

import PropTypes from 'prop-types'
import AppStore from '~publish/legacy/composer/composer/stores/AppStore'
import { Tooltip } from '@bufferapp/ui'
import InfoIcon from '@bufferapp/ui/Icon/Icons/Info'
import {
  PostTypes,
  PostTypeWrapper,
  RadioInput,
  RadioLabel,
  TooltipWrapper,
} from './styles'
import ComposerActionCreators from '../../action-creators/ComposerActionCreators'
import { draftPropType } from '../ComposerPropTypes'
import type { Draft } from '../../entities/Draft'
import { TempTooltip } from '~publish/legacy/shared-components/TempTooltip/TempTooltip'

interface PostTypeBarProps {
  draft: Draft
  defaultPostType: string
  postTypes: {
    value: string
    name: string
    disabledCallback?: () => boolean
    disabledMessage?: string
  }[]
  onPostTypeChangeCallback: (newValue: string, oldValue: string) => void
  tooltip: ReactNode
}

const PostTypeBar = ({
  draft,
  defaultPostType,
  postTypes,
  onPostTypeChangeCallback,
  tooltip,
}: PostTypeBarProps): JSX.Element | null => {
  const [postTypeSelected, setPostTypeSelected] = React.useState(
    draft.updateType ?? defaultPostType,
  )

  React.useEffect(() => {
    if (!draft.updateType) return
    setPostTypeSelected(draft.updateType)
  }, [draft.updateType])

  const onPostTypeChange = (newValue: string): void => {
    const oldValue = draft.updateType ?? 'post'
    ComposerActionCreators.updateDraftUpdateType(draft.id, newValue)

    onPostTypeChangeCallback(newValue, oldValue)

    /**
     * It's done here with the next tick in order for React to properly reflect
     * the checked/unchecked state of the component in the view. If it's not here,
     * there's a bug where the state is changed, but the view remains immutable.
     * That is, without it, if you select other checkbox, then you see the initial
     * one still selected although the state has changed.
     */
    setTimeout(() => {
      setPostTypeSelected(newValue)
    })
  }

  if (AppStore.getExpandedComposerId() !== draft.id) {
    return null
  }

  return (
    <PostTypeWrapper>
      <PostTypes>
        {postTypes.map((postType, i) => (
          // TODO: Replace TempTooltip with Popcorn Tooltip once Popcorn is ready to be used
          <TempTooltip
            key={`${i}-tooltip-${postType.name}`}
            content={
              postType.disabledCallback?.() ? postType.disabledMessage : ''
            }
            arrow
          >
            <RadioLabel
              key={`${i}-radio-button-${postType.name}`}
              htmlFor={postType.value}
              onClick={(): void | null =>
                postType.disabledCallback instanceof Function &&
                !postType.disabledCallback()
                  ? onPostTypeChange(postType.value)
                  : null
              }
              disabled={postType.disabledCallback?.()}
            >
              <RadioInput
                type="radio"
                id={postType.value}
                name={`${draft.service.name}postType`}
                value={postType.value}
                checked={postTypeSelected === postType.value}
                onChange={(e): void => onPostTypeChange(e.target.value)}
                disabled={postType.disabledCallback?.()}
              />
              {postType.name}
            </RadioLabel>
          </TempTooltip>
        ))}
      </PostTypes>
      <TooltipWrapper>
        {tooltip && (
          // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; verticalAlign: string; ... Remove this comment to see the full error message
          <Tooltip verticalAlign="top" position="left" label={tooltip}>
            <InfoIcon className="info-icon-wrapper" />
          </Tooltip>
        )}
      </TooltipWrapper>
    </PostTypeWrapper>
  )
}

PostTypeBar.propTypes = {
  draft: draftPropType.isRequired,
  defaultPostType: PropTypes.string.isRequired,
  postTypes: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      disabledCallback: PropTypes.func,
      disabledMessage: PropTypes.string,
    }),
  ).isRequired,
  onPostTypeChangeCallback: PropTypes.func,
  tooltip: PropTypes.node,
}

PostTypeBar.defaultProps = {
  onPostTypeChangeCallback: (): void => undefined,
  tooltip: null,
}

export default PostTypeBar
