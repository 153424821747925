import { useState } from 'react'

export type PostLimit = {
  postsLimit: number[]
  showMorePosts: (index: number) => void
}

function usePostLimit(numberOfRows: number): PostLimit {
  // Set default to 5 and reset state values when navigate to another month
  const [postsLimit, setPostsLimit] = useState(() =>
    Array(numberOfRows).fill(5),
  )

  const showMorePosts = (index: number): void => {
    const array = postsLimit.map((item, i) => {
      if (i === index) {
        return item + 5
      }
      return item
    })
    setPostsLimit(array)
  }

  return {
    postsLimit,
    showMorePosts,
  }
}

export default usePostLimit
