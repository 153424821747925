import { useState, useCallback, useMemo } from 'react'
import { addMonths, addWeeks } from 'date-fns'
import { useQueryParam } from '~publish/hooks/useQueryParam'
import { useLocation, useParams, useHistory } from 'react-router-dom'
import {
  calendarWeek,
  calendarMonth,
  getMatch,
  newCalendarAllChannels,
  newCalendarSingleChannel,
} from '~publish/legacy/routes'

export type CalendarGranularity = 'week' | 'month'

export type UseNavigationReturn = {
  leftAnimation: boolean
  rightAnimation: boolean
  currentDate: Date
  setCurrentDate: (date: Date) => void
  setLeftAnimation: (leftAnimation: boolean) => void
  setRightAnimation: (rightAnimation: boolean) => void
  onNavigate: (
    direction: 'forwards' | 'backwards',
    granulaity: CalendarGranularity,
  ) => void
  granularity: CalendarGranularity
  navigateGranularity: (granulaity: CalendarGranularity) => void
}

const useGranularity = (): [
  CalendarGranularity,
  (granulaity: CalendarGranularity) => void,
] => {
  const history = useHistory()
  const location = useLocation<{
    channel?: string
  }>()
  const isWeekView = !!getMatch({
    pathname: location.pathname,
    route: calendarWeek.route,
  })
  const isAllChannels = !!getMatch({
    pathname: location.pathname,
    route: newCalendarAllChannels.route,
  })
  const isSingleChannel = !!getMatch({
    pathname: location.pathname,
    route: newCalendarSingleChannel.route,
  })
  const granularity = useParams<{ granularity?: CalendarGranularity }>()
    .granularity
  const channelId = useParams<{ id?: string }>().id
  const isMergedView = isAllChannels || isSingleChannel
  const isWeekly = isWeekView || (isMergedView && granularity === 'week')

  const navigateGranularity = useCallback(
    (granulaity: CalendarGranularity) => {
      const nextRoute = ((): string => {
        if (isAllChannels) {
          return newCalendarAllChannels.getRoute(granulaity, {}, true)
        }
        if (isSingleChannel) {
          if (channelId) {
            return newCalendarSingleChannel.getRoute(
              channelId,
              granulaity,
              {},
              true,
            )
          }
          return newCalendarAllChannels.getRoute(granulaity, {}, true)
        }
        if (granulaity === 'week') {
          return calendarWeek.getRoute({}, true)
        }
        return calendarMonth.getRoute({}, true)
      })()

      history.push(nextRoute)
    },
    [history, isAllChannels, isSingleChannel, channelId],
  )

  return [isWeekly ? 'week' : 'month', navigateGranularity]
}

const useInnerNavigation = (
  dateParam: string | undefined,
  setDateParam: (date: string) => void,
): Omit<UseNavigationReturn, 'granularity' | 'navigateGranularity'> => {
  const currentDate = useMemo(() => {
    if (dateParam) {
      const unitxTime = parseInt(dateParam, 10)
      return new Date(unitxTime)
    }
    return new Date()
  }, [dateParam])
  const setCurrentDate = useCallback(
    (date: Date) => {
      setDateParam(date.getTime().toString())
    },
    [setDateParam],
  )
  const [leftAnimation, setLeftAnimation] = useState(false)
  const [rightAnimation, setRightAnimation] = useState(false)

  const onNavigate = useCallback(
    (
      direction: 'forwards' | 'backwards',
      granulaity: CalendarGranularity,
    ): void => {
      const delta = direction === 'forwards' ? 1 : -1
      const newDate =
        granulaity === 'week'
          ? addWeeks(currentDate, delta)
          : addMonths(currentDate, delta)
      setCurrentDate(newDate)
      if (direction === 'forwards') {
        setRightAnimation(true)
      } else {
        setLeftAnimation(true)
      }
    },
    [currentDate, setCurrentDate, setRightAnimation, setLeftAnimation],
  )

  return {
    leftAnimation,
    rightAnimation,
    currentDate,
    setCurrentDate,
    setLeftAnimation,
    setRightAnimation,
    onNavigate,
  }
}

export const useNavigation = (): UseNavigationReturn => {
  const [dateParam, setDateParam] = useQueryParam<string>('date')
  const coreNavigation = useInnerNavigation(dateParam, setDateParam)
  const [granularity, navigateGranularity] = useGranularity()
  return useMemo(() => {
    return {
      ...coreNavigation,
      granularity,
      navigateGranularity,
    }
  }, [coreNavigation, granularity, navigateGranularity])
}

export const testUseNavigation = useInnerNavigation
