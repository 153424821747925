import React, { useState } from 'react'
import styled from 'styled-components'
import { grayLighter, greenLight } from '@bufferapp/ui/style/colors'

import { Redirect } from 'react-router-dom'
import { generic } from '~publish/legacy/routes'
import { useSplitEnabled } from '@buffer-mono/features'
import { List } from '~publish/legacy/blueprints/components/List'
import Text from '@bufferapp/ui/Text'
import Notice from '@bufferapp/ui/Notice'
import { Header } from '../Header'

const Layout = styled.section`
  display: flex;
  flex-direction: column;
  background-color: ${grayLighter};
  height: 100vh;
  padding: 24px 0 0 0;
  overflow: hidden;
  align-items: center;
`

const Wrapper = styled.div`
  width: 100%;
  max-width: 860px;
  margin: 0 auto;
  overflow: hidden;
  align-items: center;
  position: relative;
  height: 100%;
`

const Description = styled.div`
  padding: 0 16px;
`

const NoticeStyled = styled(Notice)`
  margin: 0 16px;
  background: ${greenLight};
`

const Blueprints: React.FC = () => {
  const [blueprintNotice, setBlueprintNotice] = useState('')
  const { isEnabled: isBlueprintsEnabled } = useSplitEnabled(
    'build-week-blueprints',
  )

  if (!isBlueprintsEnabled) {
    return <Redirect to={generic.route} />
  }

  return (
    <Layout data-testid="blueprints_section">
      <Wrapper>
        <Header />
        <Description>
          <p>Struggling to come up with engaging post ideas? We all do!</p>
          <p>Buffer Recipes are prescriptions for social media success.</p>

          <p>
            Select a Recipe that matches your current goals, follow the prompts*
            and create original, authentic posts that are sequenced & designed
            to help you reach your goal.
          </p>

          <p>
            When you start a Recipe the prompts will be added as Drafts to your
            Buffer calendar and queue, so you can easily create a post and
            schedule it.
          </p>
        </Description>
        {blueprintNotice !== '' && (
          // @ts-expect-error TS(2769) FIXME: No overload matches this call.
          <NoticeStyled dismiss={() => ({})} type="note">
            {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: El... Remove this comment to see the full error message */}
            <Text>
              <span dangerouslySetInnerHTML={{ __html: blueprintNotice }} />
            </Text>
          </NoticeStyled>
        )}
        <List setBlueprintNotice={setBlueprintNotice} />
      </Wrapper>
    </Layout>
  )
}

Blueprints.propTypes = {}

Blueprints.defaultProps = {}

export default Blueprints
