export const VALIDATION_CODE = {
  FACEBOOK_DIFFERENT_PROFILE_TYPES: 'FACEBOOK_DIFFERENT_PROFILE_TYPES',
  CHARACTER_LIMIT_REACHED: 'CHARACTER_LIMIT_REACHED',
  MAX_LIMIT_REACHED: 'MAX_LIMIT_REACHED',
  ASPECT_RATIO: 'ASPECT_RATIO',
  VIDEO_DURATION: 'VIDEO_DURATION',
  VIDEO_SIZE: 'VIDEO_SIZE',
  VIDEO_FRAME_RATE: 'VIDEO_FRAME_RATE',
  IMAGE_SIZE: 'IMAGE_SIZE',
  FILE_SIZE: 'FILE_SIZE',
  MISSING_VALUE: 'MISSING_VALUE',
  INVALID_VALUE: 'INVALID_VALUE',
  DATE_TIME_ERROR: 'DATE_TIME_ERROR',
  REQUIRES_TEXT: 'REQUIRES_TEXT',
} as const
