/* eslint-disable react/jsx-props-no-spreading */
import type { StyledElementProps, Value } from '@udecode/plate'
import React from 'react'
import type { EmojiSearchElement } from '../../nodes'

export const EmojiSearchElementUI = <V extends Value>({
  attributes,
  children,
  element,
}: StyledElementProps<V, EmojiSearchElement>) => {
  return (
    <span {...attributes}>
      {element.trigger}
      {children}
    </span>
  )
}
