import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { BufferLoading } from '~publish/legacy/shared-components'

import formatPostFromQuery from './formatPostFromQuery'
import { usePostComposer } from '~publish/hooks/usePostComposer'

const StyledLoader = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  height: inherit;
  align-items: center;
`

const ComposePage = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'initComposer' implicitly has an '... Remove this comment to see the full error message
  initComposer,
  // @ts-expect-error TS(7031) FIXME: Binding element 'queryParams' implicitly has an 'a... Remove this comment to see the full error message
  queryParams,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isLoading' implicitly has an 'any... Remove this comment to see the full error message
  isLoading,
}) => {
  // We keep track of this state to let us know when the composer has been hidden, then we should close the window
  const [composerWasOpened, setComposerWasOpened] = useState<boolean>(false)
  const { isOpen } = usePostComposer()

  useEffect(() => {
    const post = formatPostFromQuery(queryParams)
    initComposer({
      shouldResetComposerData: true,
      post,
    })
  }, [])

  useEffect(() => {
    if (isOpen) {
      setComposerWasOpened(true)
    }

    if (!isOpen && composerWasOpened) {
      setComposerWasOpened(false)
      window.close()
    }
  }, [isOpen, composerWasOpened])

  return (
    <>
      {isLoading && (
        <StyledLoader>
          <BufferLoading size={64} />
        </StyledLoader>
      )}
    </>
  )
}

ComposePage.propTypes = {
  queryParams: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  initComposer: PropTypes.func.isRequired,
}

ComposePage.defaultProps = {
  queryParams: '',
}

export default ComposePage
