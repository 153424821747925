import React from 'react'
import WarningIcon from '@bufferapp/ui/Icon/Icons/Warning'

import * as Styled from './styles'

/**
 *
 */
const ErrorMessage = ({ message }: { message: string }) => {
  return (
    <Styled.Error>
      <WarningIcon />
      {message}
    </Styled.Error>
  )
}

export { ErrorMessage }
