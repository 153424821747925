import { connect } from 'react-redux'
import TwoFactorAuth from './components/TwoFactorAuth'
import { actions } from './reducer'

export default connect(
  (state) => ({
    // @ts-expect-error TS(2339) FIXME: Property 'twoFactorAuth' does not exist on type 'D... Remove this comment to see the full error message
    machineState: state.twoFactorAuth.machineState,
    // @ts-expect-error TS(2339) FIXME: Property 'twoFactorAuth' does not exist on type 'D... Remove this comment to see the full error message
    isEnabled: state.twoFactorAuth.isEnabled,
    // @ts-expect-error TS(2339) FIXME: Property 'twoFactorAuth' does not exist on type 'D... Remove this comment to see the full error message
    editMode: state.twoFactorAuth.editMode,
    // @ts-expect-error TS(2339) FIXME: Property 'twoFactorAuth' does not exist on type 'D... Remove this comment to see the full error message
    method: state.twoFactorAuth.method,
    // @ts-expect-error TS(2339) FIXME: Property 'twoFactorAuth' does not exist on type 'D... Remove this comment to see the full error message
    phoneNumber: state.twoFactorAuth.phoneNumber,
    // @ts-expect-error TS(2339) FIXME: Property 'twoFactorAuth' does not exist on type 'D... Remove this comment to see the full error message
    recoveryCode: state.twoFactorAuth.recoveryCode,
    // @ts-expect-error TS(2339) FIXME: Property 'twoFactorAuth' does not exist on type 'D... Remove this comment to see the full error message
    loading: state.twoFactorAuth.loading,
    // @ts-expect-error TS(2339) FIXME: Property 'twoFactorAuth' does not exist on type 'D... Remove this comment to see the full error message
    error: state.twoFactorAuth.error,
    // @ts-expect-error TS(2339) FIXME: Property 'twoFactorAuth' does not exist on type 'D... Remove this comment to see the full error message
    qrCode: state.twoFactorAuth.qrCode,
    // @ts-expect-error TS(2339) FIXME: Property 'twoFactorAuth' does not exist on type 'D... Remove this comment to see the full error message
    updateMethod: state.twoFactorAuth.updateMethod,
    // @ts-expect-error TS(2339) FIXME: Property 'twoFactorAuth' does not exist on type 'D... Remove this comment to see the full error message
    updatePhoneNumber: state.twoFactorAuth.updatePhoneNumber,
    // @ts-expect-error TS(2339) FIXME: Property 'twoFactorAuth' does not exist on type 'D... Remove this comment to see the full error message
    initKey: state.twoFactorAuth.initKey,
    // @ts-expect-error TS(2339) FIXME: Property 'user' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
    email: state.user.email,
  }),
  (dispatch) => ({
    // @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
    transition: (action, params) =>
      dispatch(actions.transition(action, params)),
    setPhoneNumber: (value: string) => dispatch(actions.setPhoneNumber(value)),
    submitPhoneNumber: () => dispatch(actions.submitPhoneNumber()),
    setupApp: () => dispatch(actions.setupApp()),
    submitCode: (code: string) => dispatch(actions.submitCode(code)),
    handleRecoveryCodeSelect: () => dispatch(actions.recoveryCodeSelected()),
  }),
)(
  // @ts-expect-error TS(2345) FIXME: Argument of type '({ machineState, isEnabled, meth... Remove this comment to see the full error message
  TwoFactorAuth,
)

// export reducer, actions and action types
export { default as reducer, actions, actionTypes } from './reducer'
export { default as middleware } from './middleware'
