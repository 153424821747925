import React from 'react'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import SourceUrl from '../SourceUrl/index'
import PinterestSelectedBoards, {
  type Profile,
} from '../PinterestSelectedBoards/index'
import BoardSelector from '../../BoardSelector'
import PinTitle from './PinTitle'
import styles from './PinterestComposerBar.module.css'
import FooterField from '../../shared/FooterField'
import { draftPropType } from '../../ComposerPropTypes'
import AppStore from '../../../stores/AppStore'
import { NotificationStore } from '../../../stores/NotificationStore'
import type { Draft } from '~publish/legacy/composer/composer/entities/Draft'
import { Button } from '@buffer-mono/popcorn'

const BoardsSection = ({
  profiles,
  shouldShowInlineSubprofileDropdown,
  hasUniqueSelectedProfile,
  uniqueSelectedProfile,
  visibleNotifications,
}: {
  profiles: Profile[]
  shouldShowInlineSubprofileDropdown: boolean
  hasUniqueSelectedProfile: boolean
  uniqueSelectedProfile: any
  visibleNotifications: { type: string; message: string }[]
}): JSX.Element => {
  const profileHasBoards = hasUniqueSelectedProfile
    ? uniqueSelectedProfile.subprofiles.length > 0
    : false

  return (
    <>
      <PinterestSelectedBoards profiles={profiles} />

      {shouldShowInlineSubprofileDropdown && hasUniqueSelectedProfile && (
        <>
          <DropdownMenu.Root>
            <DropdownMenu.Trigger asChild>
              <Button variant="secondary" size="small">
                {profileHasBoards ? 'Change' : 'Create'}
              </Button>
            </DropdownMenu.Trigger>
            <DropdownMenu.Portal>
              <DropdownMenu.Content
                className={styles.inlineSubprofileDropdownContent}
                side="top"
              >
                <BoardSelector
                  profile={uniqueSelectedProfile}
                  subprofiles={uniqueSelectedProfile.subprofiles}
                  subprofilesCount={uniqueSelectedProfile.subprofiles.length}
                  visibleNotifications={visibleNotifications}
                  canUnselectSubprofiles={false}
                  organizationId={AppStore.getOrganizationsData().selected.id}
                  isDropdownItem
                  needsTriangle={true}
                />
              </DropdownMenu.Content>
            </DropdownMenu.Portal>
          </DropdownMenu.Root>
        </>
      )}
    </>
  )
}

const PinterestComposerBar = ({
  draft,
}: {
  draft: Draft
}): React.JSX.Element => {
  if (!draft.service.isPinterest()) {
    return <></>
  }

  const profiles = AppStore.getProfiles()
  const isOmniboxEnabled = AppStore.getIsOmniboxEnabled()
  const shouldShowInlineSubprofileDropdown =
    !AppStore.getOptions().canSelectProfiles ||
    (profiles.filter((profile) => profile.isSelected).length === 1 &&
      draft.id === 'pinterest') ||
    !isOmniboxEnabled

  const selectedPinterestProfiles = profiles.filter(
    (profile) => profile.isSelected && profile.service.name === 'pinterest',
  )
  const uniqueSelectedProfile =
    selectedPinterestProfiles.length === 1 ? selectedPinterestProfiles[0] : null
  const hasUniqueSelectedProfile = uniqueSelectedProfile !== null
  const sourceUrl = draft.sourceLink !== null ? draft.sourceLink.url : null
  const visibleNotifications = NotificationStore.getVisibleNotifications()

  return (
    <div className={styles.pinterestComposerBar}>
      <PinTitle draftId={draft.id} title={draft.title} />
      <SourceUrl sourceUrl={sourceUrl} draftId={draft.id} />

      <FooterField
        ariaLabel="pinningTo"
        label="Pinning To"
        fieldContent={
          <BoardsSection
            profiles={profiles as unknown as Profile[]}
            shouldShowInlineSubprofileDropdown={
              shouldShowInlineSubprofileDropdown
            }
            hasUniqueSelectedProfile={hasUniqueSelectedProfile}
            uniqueSelectedProfile={uniqueSelectedProfile}
            visibleNotifications={visibleNotifications}
          />
        }
      />
    </div>
  )
}

PinterestComposerBar.propTypes = {
  draft: draftPropType.isRequired,
}

export default PinterestComposerBar
