import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { AnimatedList } from '~publish/legacy/shared-components'
import ListItem from '../ListItem'

const StyledList = styled(AnimatedList)`
  padding-left: 0px;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
`

const skeletonCampaign = {
  color: null,
  id: null,
  name: 'Loading Name',
  sent: 0,
  scheduled: 0,
  lastUpdated: 'Loading date',
  dateRange: null,
}

const numberItems = [1, 2, 3, 4, 5]

// @ts-expect-error TS(7031) FIXME: Binding element 'showCampaignActions' implicitly h... Remove this comment to see the full error message
const SkeletonList = ({ showCampaignActions }) => {
  const listItems = numberItems.map((number) => (
    <ListItem
      key={`skeleton-${number}`}
      // @ts-expect-error TS(2322) FIXME: Type '{ color: null; id: null; name: string; sent:... Remove this comment to see the full error message
      campaign={skeletonCampaign}
      showCampaignActions={showCampaignActions}
      displaySkeleton
      hideAnalyzeReport
    />
  ))
  return <StyledList>{listItems}</StyledList>
}

SkeletonList.propTypes = {
  showCampaignActions: PropTypes.bool.isRequired,
}

export default SkeletonList
