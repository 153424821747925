import { findNode, unwrapNodes } from '@udecode/plate'
import type { BufferEditor } from '~publish/legacy/editor/BufferEditor/types.plate'
import { FacebookMentionInputElement } from '../nodes/FacebookMentionInputElement'

export const removeAutocompletedMentionOnUpdate = (
  editor: BufferEditor,
): boolean => {
  const mentionEntry = findNode<FacebookMentionInputElement>(editor, {
    match: (n) => FacebookMentionInputElement.is(n),
  })

  if (mentionEntry) {
    const [node, path] = mentionEntry
    const { autocompleted, valid, data } = node

    if (valid && autocompleted && data) {
      unwrapNodes(editor, {
        at: path,
        match: (n) => FacebookMentionInputElement.is(n),
      })
    }
  }
  return false
}
