import { generateThumbnailFromGif } from '~publish/legacy/utils/image/getStillDataUriFromGif'
import { generateThumbnailFromVideo } from '~publish/legacy/composer/composer/utils/DOMUtils'
import { generateThumbnailFromPDF } from '~publish/legacy/composer/composer/utils/generateThumbnailFromPDF'
import type { UppyFile } from '../uppy/UppyFile'
import { getMediaTypeFromMime } from '../utils'
import { MediaType } from '../constants'

export async function generateThumbnail(file: UppyFile): Promise<File> {
  const mediaType = getMediaTypeFromMime(file.type)
  if (mediaType === MediaType.GIF) {
    return generateThumbnailFromGif(file.data)
  }

  if (mediaType === MediaType.VIDEO) {
    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    return (await generateThumbnailFromVideo(file.data)).imageFile
  }

  if (mediaType === MediaType.DOCUMENT) {
    return await generateThumbnailFromPDF(file.data)
  }

  throw new Error(`Cannot generate thumbnail for file type: ${file.type}`)
}
