import type { Upload } from '~publish/legacy/uploads/entities/Upload'
import { MediaType } from '~publish/legacy/uploads/constants'
import { SERVICE_LINKEDIN } from '~publish/legacy/constants'
import ComposerActionCreators from './ComposerActionCreators'

export const handleUploadStarted = ({
  upload,
  draftId,
}: {
  upload: Upload
  draftId: string
}): void => {
  if (draftId === SERVICE_LINKEDIN && upload.mediaType === MediaType.DOCUMENT) {
    ComposerActionCreators.setLinkedInCarouselTitle()
  }
}
