import React from 'react'

import { usePostData } from './PostCardContext'
import { PostCardNotice } from './PostCardNotice'

export const PostCardProcessingNotice = (): JSX.Element | null => {
  const { status, channel } = usePostData()

  if (status !== 'sending') {
    return null
  }

  return (
    <PostCardNotice variant="info">
      Your post is being processed and will go live soon. No action needed.
      {channel?.service === 'tiktok' &&
        ' Larger video files may take more time.'}
    </PostCardNotice>
  )
}

PostCardProcessingNotice.displayName = 'PostCardProcessingNotice'
