const isEmpty = (
  value:
    | Array<unknown>
    | null
    | undefined
    | typeof NaN
    | string
    | Record<string, unknown>,
): boolean => {
  if (!value) return true
  if (Array.isArray(value) && !value.length) return true
  return (
    typeof value === 'object' &&
    value.constructor === Object &&
    Object.keys(value).length === 0
  )
}

export default isEmpty
