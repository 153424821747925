import React from 'react'
import PropTypes from 'prop-types'

import { Button, Text } from '@bufferapp/ui'
import Avatar from '@bufferapp/ui/Avatar'

const profileBadgeStyle = {
  paddingRight: '10px',
}

const middleContainerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}

const containerStyle = {
  backgroundColor: 'white',
  padding: '20px',
  maxWidth: '355px',
}

const buttonsStyle = {
  marginTop: '20px',
  display: 'flex',
  justifyContent: 'flex-end',
}

const textContainerStyle = {
  marginRight: '15px',
}

const ConfirmModal = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'profileService' implicitly has an... Remove this comment to see the full error message
  profileService,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onConfirmClick' implicitly has an... Remove this comment to see the full error message
  onConfirmClick,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onCloseModal' implicitly has an '... Remove this comment to see the full error message
  onCloseModal,
  // @ts-expect-error TS(7031) FIXME: Binding element 'avatar' implicitly has an 'any' t... Remove this comment to see the full error message
  avatar,
  // @ts-expect-error TS(7031) FIXME: Binding element 'heading' implicitly has an 'any' ... Remove this comment to see the full error message
  heading,
  // @ts-expect-error TS(7031) FIXME: Binding element 'body' implicitly has an 'any' typ... Remove this comment to see the full error message
  body,
  // @ts-expect-error TS(7031) FIXME: Binding element 'btnText' implicitly has an 'any' ... Remove this comment to see the full error message
  btnText,
}) => (
  <div style={containerStyle}>
    <Text type="h3">{heading}</Text>
    <div style={middleContainerStyle}>
      {/* @ts-expect-error TS(2741) FIXME: Property 'children' is missing in type '{ type: st... Remove this comment to see the full error message */}
      <Text
        type="p"
        style={textContainerStyle}
        dangerouslySetInnerHTML={{ __html: body }}
      />
      <div style={profileBadgeStyle}>
        <Avatar
          src={avatar}
          fallbackUrl="https://s3.amazonaws.com/buffer-ui/Default+Avatar.png"
          alt="avatar for social network"
          size="small"
          type="social"
          network={profileService}
        />
      </div>
    </div>
    <div style={buttonsStyle}>
      {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; onClick: any; label: string;... Remove this comment to see the full error message */}
      <Button
        type="text"
        onClick={onCloseModal}
        label="Cancel"
        data-testid="confirmation-modal-cancel"
      />
      {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; onClick: any; label: any; "d... Remove this comment to see the full error message */}
      <Button
        type="primary"
        onClick={onConfirmClick}
        label={btnText}
        data-testid="confirmation-modal-confirm"
      />
    </div>
  </div>
)

ConfirmModal.propTypes = {
  profileService: PropTypes.string.isRequired,
  onConfirmClick: PropTypes.func.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  avatar: PropTypes.string.isRequired,
  heading: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  btnText: PropTypes.string.isRequired,
}

export default ConfirmModal
