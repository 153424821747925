export const AttachmentTypes = {
  LINK: 'LINK',
  MEDIA: 'MEDIA',
  RETWEET: 'RETWEET',
  REELS: 'REELS',
} as const

export const MediaAttachmentTypes = {
  WEBM: 'WEBM',
  JPG: 'JPG',
  JPEG: 'JPEG',
  PNG: 'PNG',
  HEIC: 'HEIC',
  GIF: 'GIF',
  AVI: 'AVI',
  MP4: 'MP4',
  M4V: 'M4V',
  MOV: 'MOV',
  WEBP: 'WEBP',
  MPG: 'MPG',
  MPEG: 'MPEG',
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  PDF: 'PDF',
} as const

export const ImageDimensions = {
  large: {
    width: 306,
    height: 544,
  },
  regular: {
    width: 180,
    height: 320,
  },
  thumbnail: {
    width: 110,
    height: 198,
  },
  queueImage: {
    width: 288, // Shown in 144px width but this accounts for retina
  },
} as const

export const UploadTypes = {
  LINK_THUMBNAIL: 'LINK_THUMBNAIL',
  MEDIA: 'MEDIA',
} as const

export const MediaTypes = {
  IMAGE: 'IMAGE',
  VIDEO: 'VIDEO',
  GIF: 'GIF',
  DOCUMENT: 'DOCUMENT',
} as const
