import React from 'react'
import { Warning } from '@bufferapp/ui/Icon'
import { grayDarker, white } from '@bufferapp/ui/style/colors'
import { ParagraphText } from '../styles'
import { PendingTiktokBannerWrapper } from './style'

const PendingTiktokBanner = (): JSX.Element => {
  return (
    <PendingTiktokBannerWrapper background={grayDarker}>
      <Warning color={white} />
      <ParagraphText type="p" color="white">
        TikTok is processing this post
      </ParagraphText>
    </PendingTiktokBannerWrapper>
  )
}

export default PendingTiktokBanner
