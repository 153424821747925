import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'

import { Button } from '@bufferapp/ui'
import { useCallbackRef } from '@buffer-mono/popcorn'

import type {
  PostCard_PostFragment,
  PublishingPost,
} from '~publish/gql/graphql' // eslint-disable-line camelcase
import { assignTagsToPost } from '~publish/legacy/post/thunks/assignTagsToPost'
import { useAppDispatch } from '~publish/legacy/store'
import { TagsCombobox } from '~publish/components/TagsCombobox'
import type { RpcUpdate } from '~publish/legacy/post/types'
import { useTagsQuery } from '~publish/hooks/useTags'

import { usePostData } from './usePostData'

const ButtonStyled = styled(Button)`
  height: 32px;
`

type PropType = {
  postId: string
  saveOnClose?: boolean
}

/**
 * @deprecated Use TagsCombobox instead
 */
export const PostTagsSelector = ({
  postId,
  saveOnClose,
}: PropType): JSX.Element | null => {
  const post = usePostData(postId)
  const dispatch = useAppDispatch()
  const { tags: allTags } = useTagsQuery()

  const [selectedTags, setPostTags] = useState<string[]>(
    post?.tags.map(({ id }) => id) ?? [],
  )

  const updatePostTags = useCallback(
    (tags: string[], notify = false): void => {
      if (!post) return
      dispatch(
        assignTagsToPost({
          postId: post.id,
          tags: allTags.filter((tag) => tags.includes(tag.id)),
          disableNotification: !notify,
        }),
      )
    },
    [allTags, dispatch, post],
  )

  // The new TagsCombobox returns a list of tag IDs, but the assignTagsToPost expects a list of tags
  // So we need to convert the tag IDs to tags before calling assignTagsToPost.
  // This effect will run whenever the postTags or allTags change, and will update the post tags if needed.
  useEffect(() => {
    if (!post || saveOnClose) return

    if (needsUpdate(post, selectedTags)) {
      updatePostTags(selectedTags)
    }
  }, [allTags, dispatch, post, saveOnClose, selectedTags, updatePostTags])

  const onClose = useCallbackRef((): void => {
    if (!saveOnClose || !post) return
    if (needsUpdate(post, selectedTags)) {
      updatePostTags(selectedTags, true)
    }
  })

  useEffect(() => {
    return () => onClose()
    // onClose is a ref, so it won't change
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <TagsCombobox selectedTags={selectedTags} onChange={setPostTags}>
      {/* HACK: ButtonStyled does not work as Trigger, wrapping around span does the trick */}
      <span>
        <ButtonStyled
          type="secondary"
          size="small"
          label={selectedTags.length ? 'Edit Tags' : 'Add Tags'}
          isSelect={true}
        />
      </span>
    </TagsCombobox>
  )
}

function needsUpdate(
  post: RpcUpdate | PublishingPost | PostCard_PostFragment, // eslint-disable-line camelcase
  selectedTags: string[],
): boolean {
  const currentPostTagIds = new Set(post.tags.map(({ id }) => id))
  const newPostTagIds = new Set(selectedTags)
  return (
    currentPostTagIds.size !== newPostTagIds.size ||
    ![...currentPostTagIds].every((value) => newPostTagIds.has(value))
  )
}
