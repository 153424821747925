import { nanoid } from '@reduxjs/toolkit'

export const generateKeyName = ({
  file,
  userId,
  nonce = `${Date.now()}/${nanoid()}`,
}: {
  file: { name: string }
  userId: string
  nonce?: string
}): string => {
  const prefix = `${userId}/uploads/${nonce}`

  return `${prefix}-${encodeURIComponent(file.name)}`
}
