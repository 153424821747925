import { useCallback, useEffect } from 'react'
import { actions as modalActions } from '~publish/legacy/modals/reducer'
import { useAppDispatch } from '~publish/legacy/store'
import {
  getQueryParamFromURL,
  removeQueryParamsFromUrl,
} from '~publish/legacy/utils/manageQueryParamsInURL'

export function useConnectChannelModal(): void {
  const dispatch = useAppDispatch()

  const showConnectAChannelModal = useCallback((): void => {
    // check if connectChannel query parm is in the url to show the Connect a Channel Modal
    const channelToConnect = getQueryParamFromURL('connectChannel')

    if (channelToConnect) {
      dispatch(modalActions.showConnectAChannelModal(channelToConnect))
      // remove the query param to avoid showing the modal again
      removeQueryParamsFromUrl(['connectChannel'])
    }
  }, [dispatch])

  useEffect(() => {
    /* if the url contains the param connectChannel
      we will show the connectAChannel Modal */
    showConnectAChannelModal()
  }, [showConnectAChannelModal])
}
