import { useState, useEffect } from 'react'
import type { FeedGroup, RSSFeedItem } from '../types'
import { loadFeedsByGroupId, getFeedGroupById } from './mockFeedGroups'

export const useFeed = (
  id: string,
): null | { content: RSSFeedItem[]; group: FeedGroup } => {
  const [feedContent, setFeedContent] = useState<RSSFeedItem[]>([])
  useEffect(() => {
    setFeedContent([])
    loadFeedsByGroupId(id).then((content) => {
      setFeedContent(content)
    })
  }, [id, setFeedContent])

  const group = getFeedGroupById(id)
  if (!group) {
    return null
  }

  return {
    content: feedContent,
    group,
  }
}
