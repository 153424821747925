import styled from 'styled-components'

import { purpleLighter } from '@bufferapp/ui/style/colors'

const TAG_PADDING_VERTICAL = 3
const TAG_PADDING_HORIZONTAL = 3

export const TagWrapper = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  cursor: pointer;
`

export const Tag = styled.span`
  display: flex;
  justify-content: center;
  padding: ${TAG_PADDING_VERTICAL}px ${TAG_PADDING_HORIZONTAL}px;
  border-radius: 50px;
  background-color: ${purpleLighter};
  margin-right: 6px;
`
