import WorldIcon from '@bufferapp/ui/Icon/Icons/World'
import { grayDarker, white } from '@bufferapp/ui/style/colors'
import * as Tooltip from '@radix-ui/react-tooltip'
import styled, { type Keyframes } from 'styled-components'

export const TooltipContent = styled(Tooltip.Content).attrs({
  side: 'top',
  sideOffset: 5,
})`
  display: flex;
  align-items: center;
  background: ${grayDarker};
  color: ${white};
  max-width: 216px;
  font-size: 12px;
  padding: 5px 8px;
  border-radius: 4px;
  animation-duration: ${(props): string => props.theme.animation.duration};
  z-index: 2;

  &[data-state='delayed-open'][data-side='top'] {
    animation-name: ${(props): Keyframes =>
      props.theme.animation.keyframes.slideDownAndFade};
  }
  &[data-state='delayed-open'][data-side='right'] {
    animation-name: ${(props): Keyframes =>
      props.theme.animation.keyframes.slideLeftAndFade};
  }
  &[data-state='delayed-open'][data-side='bottom'] {
    animation-name: ${(props): Keyframes =>
      props.theme.animation.keyframes.slideUpAndFade};
  }
  &[data-state='delayed-open'][data-side='left'] {
    animation-name: ${(props): Keyframes =>
      props.theme.animation.keyframes.slideRightAndFade};
  }
`

export const NotesVisibilityWrapper = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
`

export const WorldIconStyled = styled(WorldIcon)`
  color: ${grayDarker};
`

export const TooltipArrow = styled(Tooltip.Arrow)`
  fill: ${grayDarker};
`
