import {
  findNode,
  type GetNodeEntriesOptions,
  getStartPoint,
  insertData,
  isEditor,
  isInline,
  moveSelection,
  select,
  withoutSavingHistory,
} from '@udecode/plate'
import {
  getPlainText as getSlatePlainText,
  resetEditorText,
  serialize,
} from '~publish/legacy/editor/BufferEditor'
import { parseTextToNodes } from '~publish/legacy/editor/BufferEditor/parseTextToNodes'
import type {
  BufferEditor,
  BufferValue,
} from '~publish/legacy/editor/BufferEditor/types.plate'
import type { LinkedInEntityDetails } from '~publish/legacy/editor/plugins/linkedin-annotations/nodes/LinkedInAnnotationElement'

import type { EditorEntityData } from '~publish/legacy/editor/helpers/EditorEntityData'
import { getAllFacebookMentions } from '~publish/legacy/editor/plugins/facebook-mention/commands/getAllFacebookMentions'
import type { FacebookMentionDbEntry } from '~publish/legacy/editor/plugins/facebook-mention/nodes'
import { getAllLinkNodes } from '~publish/legacy/editor/plugins/link/nodes/getAllLinkNodes'
import { LinkElement } from '~publish/legacy/editor/plugins/link/nodes/LinkElement'
import { isSelectionAtEndOfCurrentNode } from '~publish/legacy/editor/queries/isSelectionAtEndOfNode'
import type { SlateDraft } from '../Draft'

function isEditorOrState(
  editorOrState: BufferEditor | unknown,
): editorOrState is BufferEditor {
  return isEditor(editorOrState)
}

function createStateFromText(
  editor: BufferEditor,
  text = '',
  data?: EditorEntityData,
): BufferEditor {
  withoutSavingHistory(editor, () => {
    resetEditorText(editor)
    parseTextToNodes(editor, text, data)
  })

  return editor
}

export type GetPlainTextOptions = GetNodeEntriesOptions<BufferValue>
function getPlainText(
  editor: BufferEditor,
  options: GetPlainTextOptions = {},
): string {
  return getSlatePlainText(editor, options)
}

function hasText(
  editor: BufferEditor,
  options: GetNodeEntriesOptions<BufferValue> = {},
): boolean {
  return !!getSlatePlainText(editor, options).trim()
}

function hasUnshortenedLinks(editor: BufferEditor): boolean {
  return getAllLinkNodes(editor).some(([node]) =>
    LinkElement.hasBeenUnshortened(node),
  )
}

function parseLinks(editor: BufferEditor): string[] {
  const nodes = getAllLinkNodes(editor)

  return nodes.map(([node]) => LinkElement.getText(node))
}

function copyDraftEditorState(
  draftFrom: SlateDraft,
  draftTo: SlateDraft,
): BufferEditor {
  const { text, data } = serialize(draftFrom.editorState)
  return createStateFromText(draftTo.editorState, text, data)
}

function getFacebookAutocompleteEntities(
  editor: BufferEditor,
): FacebookMentionDbEntry[] {
  return getAllFacebookMentions(editor)
}

export const SlateJsProxy = {
  isEditorOrState,
  createStateFromText,
  getPlainText,
  hasText,
  hasUnshortenedLinks,
  parseLinks,
  copyDraftEditorState,
  getFacebookAutocompleteEntities,

  // @ts-expect-error TS(7006) FIXME: Parameter 'editor' implicitly has an 'any' type.
  getAllLinkedinAnnotations: (editor) => {
    return editor.getAllLinkedInAnnotations?.(editor)
  },
  // @ts-expect-error TS(7006) FIXME: Parameter 'editor' implicitly has an 'any' type.
  insertLinkedInAnnotation: (editor, annotation: LinkedInEntityDetails) => {
    editor.insertLinkedInAnnotation?.(editor, annotation)

    return editor
  },

  replaceLinkWithShortLink: (
    editor: BufferEditor,
    link: string,
    shortLink: string,
  ): BufferEditor => {
    editor.replaceLinkWithShortLink?.(editor, link, shortLink)
    return editor
  },
  replaceLinkWithUnshortenedLink: (
    editor: BufferEditor,
    unshortenedLink: string,
    shortLink: string,
  ): BufferEditor => {
    editor.replaceLinkWithUnshortenedLink?.(editor, unshortenedLink, shortLink)
    return editor
  },

  insertSnippet: (editor: BufferEditor, text: string): BufferEditor => {
    const currentInlineNode = findNode(editor, {
      match: (node) => !isEditor(node) && isInline(editor, node),
    })
    // const parent = getNodeParent(editor, currentNode)
    const isSelectionAtEndOfNode = isSelectionAtEndOfCurrentNode(
      editor,
      currentInlineNode,
    )

    // if the current node is an inline node and the selection is not at the end of the node,
    // don't do anything.
    // TODO: review what should happen when trying to insert inside an inline node like a link or mention
    if (currentInlineNode && !isSelectionAtEndOfNode) {
      return editor
    }

    // if the current node is an inline node and the selection is at the end of the node,
    // we need to move the selection by 1 to ensure the selection is outside the inline node
    if (currentInlineNode && isSelectionAtEndOfNode) {
      moveSelection(editor, { distance: 1, unit: 'offset' })
    }

    const data = new DataTransfer()
    data.setData('text/plain', text)
    insertData(editor, data)

    return editor
  },
  moveCursorToStart: (editor: BufferEditor): BufferEditor => {
    const editorStart = getStartPoint(editor, [])
    select(editor, {
      anchor: editorStart,
      focus: editorStart,
    })
    return editor
  },
}

export type SlateJsProxyType = typeof SlateJsProxy
