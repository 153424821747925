import type { UppyFile as BaseUppyFile } from '@uppy/core'
import type { UppyFile } from './UppyFile'

export function assertUppyMetadata(
  file: BaseUppyFile,
): asserts file is UppyFile {
  if (!file.meta.uploaderId) {
    throw new Error('Missing metadata in file')
  }
}
