import hashtagRegex from 'hashtag-regex'

const HASHTAG_LIMIT = 30
const regex = hashtagRegex()

const countHashtagsInText = (text: string): number => {
  let counter = 0

  while (regex.exec(text)) {
    counter += 1
  }

  return HASHTAG_LIMIT - counter
}

export default countHashtagsInText
