import React, {
  type ReactElement,
  useEffect,
  useRef,
  type MutableRefObject,
} from 'react'
import styled from 'styled-components'
import { grayLighter } from '@bufferapp/ui/style/colors'
type ScrollableContainerProps = {
  growthSpace?: number
  tagsLayout?: boolean
  pageNumber: number
  tab?: string
  moreToLoad: boolean
  loadingMore: boolean
  children: ReactElement
  onReachBottom: () => void
}
const StyledContainer = styled.div<{
  growthSpace?: number
}>`
  min-height: 100%;
  overflow-y: auto;
  margin: 0;
  max-width: 100%;
  background: ${grayLighter};
`

export const ScrollableContainer = ({
  children,
  tab,
  moreToLoad,
  loadingMore,
  pageNumber,
  onReachBottom,
}: ScrollableContainerProps): ReactElement => {
  const containerEl: MutableRefObject<HTMLDivElement | null> = useRef(null)
  const handleScroll = (): void => {
    if (containerEl && containerEl.current) {
      const reachedBottom =
        Math.floor(
          containerEl.current.scrollHeight - containerEl.current.scrollTop,
        ) === containerEl.current.clientHeight
      if (reachedBottom) {
        onReachBottom()
      }
    }
  }

  useEffect(() => {
    if (containerEl.current) {
      containerEl.current.scrollTop = 0
    }
  }, [tab])

  useEffect(() => {
    const container = containerEl.current
    if (container) {
      container.addEventListener('scroll', handleScroll)
      return () => {
        container.removeEventListener('scroll', handleScroll)
      }
    }
  }, [tab, moreToLoad, loadingMore, pageNumber])
  return <StyledContainer ref={containerEl}>{children}</StyledContainer>
}
