import { createAsyncThunk } from '@reduxjs/toolkit'
import type { RootState } from '~publish/legacy/store'
import callRpc from '~publish/legacy/utils/call-rpc'

export const saveGoogleAnalyticsTrackingSettings = createAsyncThunk<
  {
    message: string
    utmCampaign: string
    utmMedium: string
    utmSource: string
  },
  {
    profileId: string
    utmCampaign: string
    utmMedium: string
    utmSource: string
  },
  { state: RootState }
>(
  'generalSettings/saveGoogleAnalyticsTrackingSettings',
  async ({ profileId, utmCampaign, utmMedium, utmSource }) => {
    const response = await callRpc('saveGATrackingSettings', {
      profileId,
      utmCampaign,
      utmMedium,
      utmSource,
    })

    if (!response.showNotification) {
      throw new Error(response.message)
    }
    return {
      message: response.message,
      utmCampaign,
      utmMedium,
      utmSource,
    }
  },
)
