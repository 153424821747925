import styled from 'styled-components'
import MoreIcon from '@bufferapp/ui/Icon/Icons/More'
import ShareIcon from '@bufferapp/ui/Icon/Icons/Share'

import {
  previewBlack,
  previewGrayDark,
  previewGrayMedium,
  previewHighlightLight,
} from '../../../Common/styles'

const googlebusinessBlue = '#4A7DE2'

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 14px 16px 0 16px;
`

export const AvatarWrapper = styled.div`
  position: relative;
`

export const VerifiedBadge = styled.img`
  display: block;
  position: absolute;
  bottom: -1px;
  right: -6px;
  width: 13.51px;
  height: 16.67px;
  z-index: 1;
`

export const UsernameWrapper = styled.div`
  display: flex;
  flex-direction column;
  align-items: flex-start;
  margin-left: 12px;
`

export const Username = styled.span`
  display: inline-block;
  color: ${previewBlack};
  max-width: 185px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 16px;
`

export const PostDate = styled.span`
  display: flex;
  color: ${previewGrayDark};
  max-width: 185px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`

export const DotsIcon = styled(MoreIcon)`
  margin-left: auto;
  color: ${previewGrayMedium};
`

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 16px 26px 16px;
`

export const Cta = styled.div`
  font-weight: 400 !important;
  font-size: 14px !important;
  color: ${googlebusinessBlue} !important;
`

export const TermsAndConditions = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${previewGrayMedium};
`

export const Share = styled(ShareIcon)`
  margin: 0 6px 0 auto;
  color: ${previewHighlightLight};
  width: 20px;
  height: 20px;
`
