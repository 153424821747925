import { connect } from 'react-redux'
import { actions as campaignActions } from '~publish/legacy/campaign'
import { campaignsPage, goTo } from '~publish/legacy/routes'
import { actions } from './reducer'
import CampaignForm from './components/CampaignForm'

export default connect(
  (state, ownProps) => {
    return {
      // @ts-expect-error TS(2339) FIXME: Property 'campaignForm' does not exist on type 'De... Remove this comment to see the full error message
      campaign: state.campaignForm.campaign,
      // @ts-expect-error TS(2339) FIXME: Property 'campaignForm' does not exist on type 'De... Remove this comment to see the full error message
      isLoading: state.campaignForm.isLoading,
      // @ts-expect-error TS(2339) FIXME: Property 'editMode' does not exist on type '{}'.
      editMode: ownProps.editMode,
      campaignId:
        // @ts-expect-error TS(2339) FIXME: Property 'match' does not exist on type '{}'.
        ownProps?.match?.params?.id || state?.campaignForm?.campaignId,
      // @ts-expect-error TS(2339) FIXME: Property 'organizations' does not exist on type 'D... Remove this comment to see the full error message
      hasCampaignsFlip: state.organizations.selected?.hasCampaignsFeature,
    }
  },
  (dispatch, ownProps) => ({
    onCancelClick: () => {
      // @ts-expect-error TS(2339) FIXME: Property 'history' does not exist on type '{}'.
      const fromPath = ownProps.history.location?.state?.from
      if (fromPath) {
        dispatch(goTo(fromPath))
      } else {
        dispatch(campaignsPage.goTo())
      }
    },
    onCreateOrUpdateCampaignClick: ({
      // @ts-expect-error TS(7031) FIXME: Binding element 'campaignId' implicitly has an 'an... Remove this comment to see the full error message
      campaignId,
      // @ts-expect-error TS(7031) FIXME: Binding element 'colorSelected' implicitly has an ... Remove this comment to see the full error message
      colorSelected,
      // @ts-expect-error TS(7031) FIXME: Binding element 'campaignName' implicitly has an '... Remove this comment to see the full error message
      campaignName,
      // @ts-expect-error TS(7031) FIXME: Binding element 'orgId' implicitly has an 'any' ty... Remove this comment to see the full error message
      orgId,
      // @ts-expect-error TS(7031) FIXME: Binding element 'editMode' implicitly has an 'any'... Remove this comment to see the full error message
      editMode,
    }) => {
      if (editMode) {
        dispatch(
          actions.handleEditCampaignClick({
            id: campaignId,
            name: campaignName,
            color: colorSelected,
            orgId,
          }),
        )
      } else {
        dispatch(
          actions.handleCreateCampaignClick({
            name: campaignName,
            color: colorSelected,
          }),
        )
      }
    },
    // @ts-expect-error TS(7031) FIXME: Binding element 'campaignId' implicitly has an 'an... Remove this comment to see the full error message
    fetchCampaign: ({ campaignId }) => {
      // @ts-expect-error TS(2345) FIXME: Argument of type '{ campaignId: any; }' is not ass... Remove this comment to see the full error message
      dispatch(campaignActions.fetchCampaign({ campaignId }))
    },
  }),
)(CampaignForm)

export { default as reducer, actions, actionTypes } from './reducer'
export { default as middleware } from './middleware'
