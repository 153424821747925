import React from 'react'

import { SERVICE_YOUTUBE } from '~publish/legacy/constants'
import type { Video } from '~publish/legacy/composer/composer/entities/factories'
import { parseYoutubeLinks } from '~publish/legacy/utils/channels/youtube/helpers'
import type { ProfilePreview, YoutubeContent } from '../../../types'
import * as Styles from './styles'
import {
  DEFAULT_AVATAR,
  getOrientation,
  getPreviewTitleCharLimit,
  textExceedsCharLimit,
  truncateText,
} from '../../../Common/utils'

const maxLengthTitle = getPreviewTitleCharLimit(SERVICE_YOUTUBE)
const maxLengthUsername = 17

export const YoutubePost = ({
  content,
  profile,
}: {
  content: YoutubeContent
  profile: ProfilePreview
}): JSX.Element => {
  const { avatar, username } = profile
  const { video, channelData } = content
  const youtube = channelData?.youtube

  const videoHeight = video?.height
  const videoWidth = video?.width
  const orientation = getOrientation({
    height: videoHeight,
    width: videoWidth,
  })

  const titleToDisplay = truncateText(youtube?.title, maxLengthTitle)
  const usernameToDisplay = truncateText(username, maxLengthUsername)

  return (
    <Styles.PostWrapper>
      <Styles.VideoPlayer video={video as Video} orientation={orientation} />
      <Styles.MetadataWrapper>
        <Styles.LinkifiedText
          links={parseYoutubeLinks(titleToDisplay)}
          size="small"
          newTab
        >
          {titleToDisplay}
        </Styles.LinkifiedText>
        <Styles.UserDataWrapper>
          <Styles.Avatar src={avatar} fallbackUrl={DEFAULT_AVATAR} />
          <Styles.Username data-testid="username">
            @{usernameToDisplay}
            {username &&
              textExceedsCharLimit(username, maxLengthUsername) &&
              '...'}
          </Styles.Username>
          <Styles.SubscribeButton>Subscribe</Styles.SubscribeButton>
        </Styles.UserDataWrapper>
      </Styles.MetadataWrapper>
      <Styles.IconsSideSection orientation={orientation}>
        <Styles.IconWrapper>
          <Styles.ThumbsUpIcon />
          <Styles.IconLabel>Like</Styles.IconLabel>
        </Styles.IconWrapper>
        <Styles.IconWrapper>
          <Styles.ThumbsDownIcon />
          <Styles.IconLabel>Dislike</Styles.IconLabel>
        </Styles.IconWrapper>
        <Styles.IconWrapper>
          <Styles.CommentIcon />
          <Styles.IconLabel>Comment</Styles.IconLabel>
        </Styles.IconWrapper>
        <Styles.IconWrapper>
          <Styles.ShareIcon />
          <Styles.IconLabel>Share</Styles.IconLabel>
        </Styles.IconWrapper>
        <Styles.AudioAvatar src={avatar} fallbackUrl={DEFAULT_AVATAR} />
      </Styles.IconsSideSection>
    </Styles.PostWrapper>
  )
}
