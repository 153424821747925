import * as Plate from '@udecode/plate'

/**
 *
 */
export function removeSelection({ editor }: { editor: any }): void {
  const selection = window?.getSelection?.()
  selection?.removeAllRanges()

  Plate.deselect(editor)
}
