import React from 'react'
import PropTypes from 'prop-types'
import { Input, Button } from '@bufferapp/ui'

import {
  EditingMyLinksItem,
  ActionsWrapper,
  LinkUrlInput,
  LinkTextInput,
  StyledButton,
} from '../styles'

const EditingLinkForm = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'item' implicitly has an 'any' typ... Remove this comment to see the full error message
  item,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onUpdateLinkText' implicitly has ... Remove this comment to see the full error message
  onUpdateLinkText,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onUpdateLinkUrl' implicitly has a... Remove this comment to see the full error message
  onUpdateLinkUrl,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onCancelClick' implicitly has an ... Remove this comment to see the full error message
  onCancelClick,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onSaveClick' implicitly has an 'a... Remove this comment to see the full error message
  onSaveClick,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isValidItem' implicitly has an 'a... Remove this comment to see the full error message
  isValidItem,
}) => {
  return (
    <>
      <EditingMyLinksItem>
        <LinkTextInput>
          <Input
            // @ts-expect-error TS(2322) FIXME: Type '{ label: string; type: string; onChange: (e:... Remove this comment to see the full error message
            label="Link Text"
            type="text"
            // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
            onChange={(e) => onUpdateLinkText({ item, value: e.target.value })}
            name="text"
            value={item.text}
            placeholder="Describe link (e.g. “Shop Sale”)"
            maxLength="35"
          />
        </LinkTextInput>
        <LinkUrlInput>
          <Input
            // @ts-expect-error TS(2322) FIXME: Type '{ label: string; type: string; onChange: (e:... Remove this comment to see the full error message
            label="Link URL"
            type="text"
            // @ts-expect-error TS(7006) FIXME: Parameter 'e' implicitly has an 'any' type.
            onChange={(e) => onUpdateLinkUrl({ item, value: e.target.value })}
            name="url"
            value={item.url}
            placeholder="Your website or URL"
          />
        </LinkUrlInput>
      </EditingMyLinksItem>
      <ActionsWrapper>
        <StyledButton
          label="Cancel"
          type="text"
          size="small"
          onClick={() => onCancelClick({ item })}
        />
        {/* @ts-expect-error TS(2740) FIXME: Type '{ label: string; type: string; size: string;... Remove this comment to see the full error message */}
        <Button
          label="Save Link"
          type="primary"
          size="small"
          disabled={!isValidItem({ item })}
          onClick={() => onSaveClick({ item })}
        />
      </ActionsWrapper>
    </>
  )
}

EditingLinkForm.propTypes = {
  onUpdateCustomLinks: PropTypes.func,
  onUpdateLinkText: PropTypes.func,
  onUpdateLinkUrl: PropTypes.func,
  onCancelClick: PropTypes.func,
  onSaveClick: PropTypes.func,
  isValidItem: PropTypes.func,
  item: PropTypes.shape({
    text: PropTypes.string,
    url: PropTypes.string,
  }),
  customLinksDetails: PropTypes.shape({
    customLinks: PropTypes.array,
  }),
}

EditingLinkForm.defaultProps = {
  onUpdateCustomLinks: () => {},
  onUpdateLinkText: () => {},
  onUpdateLinkUrl: () => {},
  onCancelClick: () => {},
  onSaveClick: () => {},
  isValidItem: () => {},
  item: {
    text: null,
    url: null,
  },
  customLinksDetails: {
    customLinks: [],
  },
}

export default EditingLinkForm
