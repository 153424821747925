import twitterText from 'twitter-text'

const hasMentionsWithoutServer = (text: string): boolean => {
  const mentions = twitterText.extractMentions(text)
  return mentions.length > 0
}

const hasLinksWithoutProtocol = (text: string): boolean => {
  const allLinks = twitterText.extractUrlsWithIndices(text, {
    extractUrlsWithoutProtocol: true,
  })
  const linksWithProtocols = twitterText.extractUrlsWithIndices(text, {
    extractUrlsWithoutProtocol: false,
  })
  return allLinks.length > linksWithProtocols.length
}

const extractMentionsWithServer = (text: string): string[] => {
  const mentions = text.match(/@\w+@\w+\.\w+(\s|$)/g)
  return mentions ?? []
}

const getCharCount = (text: string): number => {
  let charCount = (text && text.length) || 0

  const urlsWithIndices = twitterText.extractUrlsWithIndices(text, {
    extractUrlsWithoutProtocol: false,
  })

  // If there are urls in the text, subtract the url length
  // from the charCount and add back 24 chars for each url
  if (urlsWithIndices.length > 0) {
    let totalUrlLength = 0

    urlsWithIndices.forEach((urlWithIndices) => {
      totalUrlLength += urlWithIndices.url.length
    })

    const additionalCharCount = 23
    charCount -= totalUrlLength
    charCount += urlsWithIndices.length * additionalCharCount
  }

  const mentions = extractMentionsWithServer(text)

  mentions.forEach((mention) => {
    // for mention@server.com it's ['', 'mention', 'server.com']
    const server = mention.trim().split('@')[2]
    // substract server part and additional char for '@'
    charCount = charCount - server.length - 1
  })

  return charCount
}

export const MastodonParser = {
  hasMentionsWithoutServer,
  hasLinksWithoutProtocol,
  extractMentionsWithServer,
  getCharCount,
}
