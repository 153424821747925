import React, { useMemo, useCallback } from 'react'
import { Select } from '@bufferapp/ui'
import styled from 'styled-components'
import {
  useMenuState,
  useMenuItems,
  useActiveFilter,
  type CalendarFilterItem,
  type CalendarFilter,
} from '~publish/pages/Calendar/CalendarHeader/TypeFilter/useFilterState'

export { useActiveFilter, CalendarFilter }

const FilterWrapper = styled.div`
  width: 128px;

  > div {
    width: 128px;

    button {
      justify-content: left;

      div:first-child {
        max-width: 76px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      div:nth-child(2) {
        margin-left: auto;
      }
    }
  }
`

export const TypeFilter = (): React.ReactElement => {
  const [items] = useMenuItems()

  const { selectedItem, setFilterValue } = useMenuState()

  const onSelect = useCallback(
    (selected: CalendarFilterItem): void => {
      setFilterValue(selected.id)
    },
    [setFilterValue],
  )

  // "Select" component expects an object with a boolean "selected" property
  // which tells it which one is selected
  const itemsWithSelect = useMemo(() => {
    return items.map((item) => ({
      ...item,
      selected: item.id === selectedItem.id,
    }))
  }, [items, selectedItem])

  return (
    <FilterWrapper>
      <Select
        // @ts-expect-error TS(2769) FIXME: No overload matches this call.
        onSelectClick={onSelect}
        label={items.find((i) => i.id === selectedItem.id)?.title}
        items={itemsWithSelect}
        hideSearch
        fullWidth
      />
    </FilterWrapper>
  )
}
