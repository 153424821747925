import { useCallback } from 'react'
import { useMutation } from '@apollo/client'

import { logError } from '~publish/legacy/utils/logError'
import type { DeleteIdeaGroupMutation, Idea } from '~publish/gql/graphql'
import { graphql } from '~publish/gql'

export const DeleteIdeaGroup = graphql(/* GraphQL */ `
  mutation DeleteIdeaGroup($id: ID!) {
    deleteIdeaGroup(input: { id: $id }) {
      ... on MutationError {
        message
      }
      ... on DeleteIdeaGroupResponse {
        updatedIdeas {
          id
          groupId
          position
        }
      }
    }
  }
`)

const useDeleteIdeaGroup = (): ((id: string) => Promise<void>) => {
  const [deleteIdeaGroupMutation] = useMutation(DeleteIdeaGroup, {
    update: (cache, { data }, { variables }) => {
      if (variables?.id) {
        const normalizedId = cache.identify({
          id: variables.id,
          __typename: 'IdeaGroup',
        })
        cache.evict({ id: normalizedId })
        cache.gc()
      }
      if (data?.deleteIdeaGroup.__typename === 'DeleteIdeaGroupResponse') {
        data.deleteIdeaGroup.updatedIdeas.forEach((idea: Partial<Idea>) => {
          cache.modify({
            id: cache.identify(idea),
            fields: {
              groupId: () => idea.groupId ?? null,
              position: () => idea.position ?? null,
            },
          })
        })
      }
    },
  })

  return useCallback(
    async (id: string) => {
      try {
        const { data } = await deleteIdeaGroupMutation({
          variables: { id },
        })

        if (isError(data?.deleteIdeaGroup)) {
          throw new Error(
            data?.deleteIdeaGroup.message ?? 'Unexpected API response',
          )
        }
      } catch (error) {
        logError(error as Error, {
          metaData: { variables: { id } },
        })
        throw error
      }
    },
    [deleteIdeaGroupMutation],
  )
}

const isError = (
  response: DeleteIdeaGroupMutation['deleteIdeaGroup'] | null | undefined,
): response is Exclude<
  DeleteIdeaGroupMutation['deleteIdeaGroup'],
  { __typename?: 'DeleteIdeaGroupResponse' }
> => {
  return !response || response?.__typename !== 'DeleteIdeaGroupResponse'
}

export default useDeleteIdeaGroup
