import styled from 'styled-components'

import { grayDark, grayDarker, gray, white } from '@bufferapp/ui/style/colors'

export const Button = styled.button`
  background: none;
  border: none;
  cursor: pointer;

  svg {
    fill: ${grayDark};

    &:hover {
      fill: ${grayDarker};
    }
  }

  &:disabled {
    svg {
      path {
        fill: ${gray};
      }
    }
  }
`

export const Wrapper = styled.div`
  position: relative;

  /* Disable the wrapping tool tip on hover if button is disabled */
  &.disabled {
    pointer-events: none;
  }
`

export const Toast = styled.div`
  background: ${grayDarker};
  color: ${white};
  top: -37px;
  left: -12px;
  border-radius: 4px;
  position: absolute;
  padding: 4px 8px;
  animation: SlideAndFadeIn 0.25s ease;

  @keyframes SlideAndFadeIn {
    0% {
      opacity: 0;
      transform: translateY(10px);
    }

    100% {
      opacity: 100%;
      transform: translateY(0);
    }
  }

  label {
    font-size: 13px;
  }
`
