import styled from 'styled-components'

import * as CommonStyles from '../Common/styles'

export const threadsBlack = '#101318'
export const threadsBlueishGray = '#53636f'
export const threadsDarker = '#1a8cd8'

export const Wrapper = styled(CommonStyles.Wrapper)`
  padding: 5px 24px 0 32px;
`
