import {
  findNode,
  getEndPoint,
  isElement,
  type KeyboardEventHandler,
  moveSelection,
} from '@udecode/plate'
import isHotkey from 'is-hotkey'
import type { KeyboardEvent } from 'react'
import { Range } from 'slate'
import type { BufferEditor } from '~publish/legacy/editor/BufferEditor/types.plate'
import { AutocompleteInputElement } from '../nodes/AutocompleteInputElement'

/**
 * A key handler function for our autocomplete plugin.
 * Handles things like moving outside an input on escape and
 * performing the right actions on arrow presses.
 */
export const autocompleteOnKeyDownHandler =
  (
    {
      type,
      exitOnWhitespace,
    }: {
      type: string
      exitOnWhitespace?: boolean
    } = {
      type: AutocompleteInputElement.type,
      exitOnWhitespace: false,
    },
  ) =>
  (editor: BufferEditor): KeyboardEventHandler =>
  (event: KeyboardEvent) => {
    if (isHotkey('escape', event)) {
      event.preventDefault()
      const currentMentionInput = findNode(editor, {
        match: (n) => isElement(n) && n.type === type,
      })
      if (currentMentionInput) {
        moveSelection(editor, { distance: 1, unit: 'offset' })
      }
      return true
    }

    // TODO: Review Slate upgrades. It seems there's some event handling
    // happening internally that prevents us from doing this in the with overrides.
    // When trying to take over insetion of a whitespace, the event seems to update
    // the DOM with an extra whitespace before we manage to implement custom
    // functionality which results in the actual DOM and the Slate node tree not matching,
    // giving us a phantom whitespace.
    // NOTE: This works in conjunction with the insertText override in withAutocomplete.
    // Don't ask me how, I can only tell you why.
    //
    // If the selection is in a mention input and exitOnWhitespace is true,
    // move the selection to the next node before insert the space
    if (isHotkey('space', event)) {
      const currentMentionInput = findNode(editor, {
        match: (n) => isElement(n) && n.type === type,
      })
      if (currentMentionInput && exitOnWhitespace && editor.selection) {
        const end = getEndPoint(editor, currentMentionInput[1])
        const endOfMentionRange = {
          anchor: end,
          focus: end,
        }
        const selectionIsAtEndOfMention = Range.equals(
          editor.selection,
          endOfMentionRange,
        )

        if (selectionIsAtEndOfMention) {
          moveSelection(editor, { distance: 1, unit: 'offset' })
        }
      }
    }
  }
