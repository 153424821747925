import * as Dialog from '@radix-ui/react-dialog'
import styled from 'styled-components'
import { Text } from '@bufferapp/ui'
import CrossIcon from '@bufferapp/ui/Icon/Icons/Cross'
import { grayDarker, white, red } from '@bufferapp/ui/style/colors'

export const StyledOverlay = styled(Dialog.Overlay)`
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgb(50, 59, 67, 0.8);
  opacity: 0.9;
  z-index: 4000;
`

export const ModalWrapper = styled(Dialog.Content)`
  position: fixed;
  display: flex;
  top: 50%;
  left: 50%;
  z-index: 4000;
  max-width: 1050px;
  max-height: 660px;
  background-color: ${white};
  border-radius: 8px;
  transform: translate(-50%, -50%);
`

export const CloseIcon = styled(CrossIcon)`
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  max-width: 20px;
  max-height: 20px;
  transition: transform 0.15s ease-out 0s;

  &:hover {
    fill: ${red};
  }
`

export const PaywallWrapper = styled.div`
  margin: 0;
  padding: 24px 32px;
`

export const PaywallContent = styled.div`
  height: calc(100% - 250px);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`

export const PaywallImage = styled.img`
  display: block;
  font-size: 0;
  margin-bottom: 16px;
  object-fit: contain;
  width: 100%;
`

export const PaywallTitle = styled(Text)`
  margin: 0;
`

export const PaywallSubHeading = styled.p<{ bold?: boolean }>`
  margin: 16px 0 12px 0;
  font-size: 14px;
  color: ${grayDarker};
  font-weight: ${(props): string => (props.bold ? '700' : '400')};
`

export const PaywallMainContent = styled.div`
  box-sizing: border-box;
  width: 300px;
`
export const CtaContainer = styled.div`
  margin: auto;
  width: 300px;
`
