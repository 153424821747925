import type { ParagraphElement } from './BufferEditor/types.plate'

type ExtraProps = Record<string, string | boolean | number>

export const createParagraphElement = (
  text: string,
  extraProps: ExtraProps = {},
): ParagraphElement => {
  return {
    type: 'p',
    children: [
      {
        text,
        ...extraProps,
      },
    ],
  }
}

export const createParagraphsFromText = (
  text: string,
  extraProps: ExtraProps = {},
): Array<ParagraphElement> => {
  return text
    .split('\n')
    .map((line) => createParagraphElement(line, extraProps))
}
