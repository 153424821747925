import twitter from 'twitter-text'

const parseYoutubeLinks = (text?: string | null) =>
  text
    ? twitter
        .extractEntitiesWithIndices(text)
        .map((entity) => {
          if ('hashtag' in entity && entity.hashtag) {
            return {
              displayString: `#${entity.hashtag}`,
              indices: entity.indices,
              rawString: `#${entity.hashtag}`,
            }
          }
          if ('screenName' in entity && entity.screenName) {
            return {
              displayString: `@${entity.screenName}`,
              indices: entity.indices,
              rawString: `@${entity.screenName}`,
            }
          }
          return null
        })
        .filter((entity) => entity !== null)
    : []

export { parseYoutubeLinks }
