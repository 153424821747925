import styled from 'styled-components'

const A = styled.a`
  background-color: transparent;
  :active,
  :hover {
    outline: 0;
  }
`

export default A
