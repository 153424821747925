import type { AnyObject } from '@udecode/plate'
import React from 'react'

type Element = string | JSX.Element

export interface TextToElementEntity extends AnyObject {
  displayString: string
  indices: [number, number]
  metadata?: AnyObject
}

export const defaultEntityWrapper = (
  entity: TextToElementEntity,
  key: string | number,
): Element => {
  return <span key={key}>{entity.displayString}</span>
}

export const parseTextToElements = (
  text: string,
  entities?: TextToElementEntity[],
  wrapEntity: (
    entity: TextToElementEntity,
    key: number,
  ) => Element = defaultEntityWrapper,
): Element[] => {
  const elements: Element[] = []
  let currentIndex = 0

  if (!entities || entities.length === 0) {
    elements.push(text)
    return elements
  }

  entities.forEach((entity) => {
    const startIndex = entity.indices[0]
    const endIndex = entity.indices[1]

    if (startIndex < text.length) {
      if (currentIndex !== startIndex) {
        elements.push(text.substring(currentIndex, startIndex))
      }

      elements.push(wrapEntity(entity, elements.length))
      currentIndex = endIndex
    }
  })

  if (currentIndex !== text.length) {
    elements.push(text.substring(currentIndex))
  }

  return elements
}
