/**
 * Component that displays a character count based on two numbers:
 * the current number of characters, and the max.
 */

import React from 'react'
import styles from './css/CharacterCount.module.css'

const CharacterCount = ({
  maxCount,
  count,
  type = 'character',
  className,
}: {
  maxCount: number
  count: number
  type?: 'hashtag' | 'character'
  className?: string
}): JSX.Element => {
  const left = maxCount - count
  const isAboveMax = left < 0
  const ariaLabel =
    type === 'hashtag' ? `${left} Hashtags left` : `${left} Characters left`

  const counterClassName = [
    styles.counter,
    isAboveMax ? styles.aboveMaxCharacterCount : null,
    className,
  ].join(' ')

  return (
    <span
      className={counterClassName}
      aria-label={ariaLabel}
      data-testid="composer-character-count"
    >
      {type === 'hashtag' ? `# Remaining: ${left}` : left}
    </span>
  )
}

export default CharacterCount
