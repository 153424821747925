import {
  findNode,
  type FindNodeOptions,
  getPluginType,
  isElement,
  type TElement,
  type TNode,
  type TNodeEntry,
} from '@udecode/plate'
import type { SerializedNode } from '~publish/legacy/editor/BufferEditor/types'
import type {
  BufferEditor,
  BufferValue,
  PlainText,
} from '~publish/legacy/editor/BufferEditor/types.plate'

export const EMOJI_SEARCH_ELEMENT_TYPE = 'emoji-search'
export const EMOJI_SEARCH_ELEMENT_TRIGGER = ':'

export interface EmojiSearchElement extends TElement {
  trigger: string
  children: [PlainText]
}

export const EmojiSearchElement = {
  type: EMOJI_SEARCH_ELEMENT_TYPE,
  trigger: EMOJI_SEARCH_ELEMENT_TRIGGER,
  is(node: TNode | undefined): node is EmojiSearchElement {
    return !!node && isElement(node) && node.type === EmojiSearchElement.type
  },
  serialize(node: TNode): SerializedNode {
    return { text: `${node.trigger}` }
  },
  findEmojiSearch(
    editor: BufferEditor,
    options?: Omit<FindNodeOptions<BufferValue>, 'match'>,
  ): TNodeEntry<EmojiSearchElement> | undefined {
    return findNode<EmojiSearchElement>(editor, {
      ...options,
      match: { type: getPluginType(editor, EmojiSearchElement.type) },
    })
  },
  isSelectionInEmojiSearch(editor: BufferEditor): boolean {
    return EmojiSearchElement.findEmojiSearch(editor) !== undefined
  },
}
