import React from 'react'
import { format } from 'date-fns'
import { Button, Text } from '@bufferapp/ui'
import { ArrowLeft, ArrowRight } from '@bufferapp/ui/Icon'

import { useWeeklyDates } from '~publish/pages/Calendar/hooks/useWeeklyDates'
import {
  DateNavigation,
  DateRangeText,
  IconButton,
  NavigationButtons,
} from './styles'

type CalendarNavigationProps = {
  currentDate: Date
  isWeekly: boolean
  loading: boolean
  onPreviousClick: () => void
  onNextClick: () => void
  onTodayClick: () => void
}

const CalendarNavigation = ({
  currentDate,
  isWeekly,
  onPreviousClick,
  onNextClick,
  onTodayClick,
  loading,
}: CalendarNavigationProps): JSX.Element => {
  const { weekLabel } = useWeeklyDates(currentDate)

  const label = isWeekly ? weekLabel : format(currentDate, 'MMM yyyy')
  const period = isWeekly ? 'week' : 'month'

  return (
    <DateNavigation>
      <NavigationButtons>
        <IconButton
          type="secondary"
          icon={<ArrowLeft />}
          hasIconOnly
          onClick={onPreviousClick}
          label={`Previous ${period}`}
          disabled={loading}
        />
        {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; onClick: () => void; label: ... Remove this comment to see the full error message */}
        <Button type="text" onClick={onTodayClick} label="Today" />
        <IconButton
          type="secondary"
          icon={<ArrowRight />}
          hasIconOnly
          onClick={onNextClick}
          label={`Next ${period}`}
          disabled={loading}
        />
      </NavigationButtons>
      <DateRangeText isWeekly={isWeekly}>
        <Text type="h2" as="span">
          {label}
        </Text>
      </DateRangeText>
    </DateNavigation>
  )
}

CalendarNavigation.defaultProps = {
  isWeekly: false,
  loading: false,
}

export default CalendarNavigation
