import styled from 'styled-components'

type WrapperProps = {
  maxHeight?: string
}

export const Wrapper = styled.div<WrapperProps>`
  height: ${(props): string => {
    return props.maxHeight || '300px'
  }};
`

export const CarouselContainer = styled.div`
  overflow: auto;
  scrollbar-width: none;
  position: relative;
  padding-left: 46px;
  height: 100%;
`

export const CarouselWrapper = styled.div`
  display: flex;
  gap: 5px;
  height: 100%;
`

export const CarouselImage = styled.img`
  max-width: 220px;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  vertical-align: bottom;
`
