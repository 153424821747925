/* eslint-disable react/jsx-props-no-spreading */
import { Combobox, type ComboboxProps } from '@udecode/plate'
import React, { useCallback } from 'react'
import { EmojiElement, EmojiSearchElement } from '../../nodes'
import { createEmojiOnSelectItem } from '../../transforms/createEmojiOnSelectItem'
import type { EmojiSearchData } from '../../types'
import { EmojiSuggestion } from '../EmojiSuggestion/EmojiSuggestion'

export interface EmojiComboboxProps
  extends Partial<ComboboxProps<EmojiSearchData>> {
  pluginKey?: string
  maxSuggestions?: number
}

export const EmojiCombobox = ({
  pluginKey = EmojiSearchElement.type,
  id = pluginKey,
  maxSuggestions,
  ...props
}: EmojiComboboxProps) => {
  // Override the filter function to always return true for now
  // as we use emoji-mart's SearchIndex for filtering
  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  const handleFilter = useCallback(() => (value) => !!value, [])

  return (
    <Combobox<EmojiSearchData>
      id={id}
      trigger={EmojiSearchElement.trigger}
      controlled
      maxSuggestions={maxSuggestions}
      filter={handleFilter}
      onSelectItem={createEmojiOnSelectItem(EmojiElement.type)}
      onRenderItem={EmojiSuggestion}
      {...props}
    />
  )
}
