import twitter, { type EntityWithIndices } from 'twitter-text'

import { removeUrlProtocol } from '~publish/legacy/composer/composer/utils/StringUtils'

import { concatMentionsToEntities } from '../common'

import styles from './helpers.module.css'

type ThreadLink = {
  displayString: string
  indices: [number, number]
  rawString: string
  url: string
  className?: string
}
const hashTagLimit = 1

/**
 * Extracts mentions from the given text that match the Threads mention format.
 *
 * The regex used for matching:
 * /(^|\s)(@[a-zA-Z0-9._]+[a-zA-Z0-9_])([^a-zA-Z0-9_]|$)/gm
 *
 * This regex does the following:
 * 1. Matches mentions that start at the beginning of the text or after a space.
 * 2. Captures mentions starting with '@' followed by letters, numbers, dots, and underscores.
 * 3. Ensures the mention ends with a letter, number, or underscore (not a dot).
 * 4. Verifies that the mention is followed by a non-alphanumeric character (except underscore) or the end of the line.
 * 5. Matches globally and supports multiline text.
 *
 * @param text - The input text to search for mentions
 * @returns An array of ThreadLink objects representing the found mentions, or null if no mentions are found
 */
const getThreadsMentions = (text: string): ThreadLink[] | null => {
  const regex = /(^|\s)(@[a-zA-Z0-9._]+[a-zA-Z0-9_])([^a-zA-Z0-9_]|$)/gm
  const matches = text.matchAll(regex)

  if (!matches) {
    return null
  }

  const mentions: ThreadLink[] = []

  for (const match of matches) {
    const mention = match[2]
    const handle = mention?.slice(1)

    if (mention && handle) {
      const start = (match.index ?? 0) + match[1].length

      mentions.push({
        displayString: mention,
        rawString: mention,
        url: `https://threads.net/search?q=${handle}&serp_type=tags`,
        className: styles.link,
        indices: [start, start + mention.length],
      })
    }
  }

  return mentions
}

/**
 * Combines extracted mentions with other entities from the text.
 *
 * @param text - The original text to extract mentions from
 * @param entities - An array of entities (like URLs, hashtags) extracted from the text
 * @returns An array of ThreadLink objects representing all entities, including mentions
 */
const getEntities = (
  text: string,
  entities: EntityWithIndices[],
): ThreadLink[] => {
  const mentions = getThreadsMentions(text)

  return concatMentionsToEntities(mentions, entities)
}

/**
 * Parses the given text and extracts URLs, hashtags, and mentions as ThreadLink objects.
 *
 * @param text - The input text to parse for links, hashtags, and mentions
 * @returns An array of ThreadLink objects representing all extracted entities
 */
const parseThreadsLinks = (text: string): ThreadLink[] => {
  let hashtags = 0

  const entities = twitter
    .extractEntitiesWithIndices(text)
    .map((entity: EntityWithIndices): ThreadLink | null => {
      if ('url' in entity && entity.url) {
        return {
          displayString: removeUrlProtocol(entity.url),
          indices: entity.indices,
          rawString: entity.url,
          url: entity.url,
          className: styles.link,
        }
      }

      if ('hashtag' in entity && entity.hashtag && hashtags < hashTagLimit) {
        hashtags += 1

        return {
          displayString: `${entity.hashtag}`,
          indices: entity.indices,
          rawString: `#${entity.hashtag}`,
          url: `https://threads.net/search?q=${entity.hashtag}&serp_type=tags`,
          className: styles.link,
        }
      }

      return null
    })
    .filter(Boolean) as ThreadLink[]

  return text ? getEntities(text, entities) : []
}

export { parseThreadsLinks }
