import React from 'react'
import styled from 'styled-components'
import { Tooltip } from '@bufferapp/ui'
import { Canva } from '@bufferapp/ui/Icon'
import { white } from '@bufferapp/ui/style/colors'
import { openCanvaModal } from '~publish/legacy/canva/handlers'
import { OmniCanvaDesignConfig } from '~publish/legacy/canva/constants'
import { Upload } from '../entities/Upload'
import { UploadSource } from '../values/UploadSource'
import { useUploader } from '../hooks/useUploader'

const CanvaTag = styled(Canva)`
  position: absolute;
  cursor: pointer;
  fill: ${white};
  background-color: rgba(0, 0, 0, 0.8);
  padding: 4px;
  bottom: 8px;
  left: 8px;
  width: 16px;
  height: 16px;
  border-radius: 4px;
`

export const CanvaEditTag = ({ upload }: { upload: Upload }) => {
  const { onFileReady } = useUploader({
    id: upload.uploaderId,
  })

  const source = Upload.getSource(upload)

  if (!UploadSource.isCanva(source)) return null

  const onClick = () => {
    openCanvaModal({
      onFileReady,
      designConfig: OmniCanvaDesignConfig,
      designId: source.id,
    })
  }

  return (
    // @ts-expect-error TS(2322) FIXME: Type '{ children: Element; label: string; }' is no... Remove this comment to see the full error message
    <Tooltip label="Edit Canva Design">
      <CanvaTag onClick={onClick} role="button" tabIndex={0} />
    </Tooltip>
  )
}
