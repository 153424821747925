import React, { useEffect, useMemo, useRef, useState } from 'react'
import * as Popover from '@radix-ui/react-popover'
import ReactTooltip from 'react-tooltip'
import { useSplitEnabled } from '@buffer-mono/features'

import { useAccount, useHasEntitlement } from '~publish/legacy/accountContext'
import type {
  EntityWithNotes,
  PublishingNote as NoteType,
} from '~publish/gql/graphql'
import { useNotesFeedViewedTracking } from '../hooks'
import { MigrationBanner } from './MigrationBanner/MigrationBanner'
import { NotesFeed } from './NotesFeed'
import {
  NotesBadgeWrapper,
  NotesIconFilled,
  NotesIconOutlined,
  PopoverArrow,
} from './styles'
import { useQueryParam } from '~publish/hooks/useQueryParam'
import { useOnScreen } from '~publish/hooks/useOnScreen'
import { isFreeUser } from '~publish/helpers/user'
import { NotesPaywall } from '~publish/components/NotesPaywall'

export interface NotesProps {
  entityId: string
  entityType: EntityWithNotes
  notes: NoteType[]
}

export const Notes = ({
  entityId,
  entityType,
  notes,
}: NotesProps): JSX.Element => {
  const { isEnabled: isNotesPaywallEnabled } =
    useSplitEnabled('geid-notes-paywall')

  const hasNotesEntitlement = useHasEntitlement('notes')
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const [noteIdQueryParam] = useQueryParam<string>('noteId')

  const notesTriggerRef = useRef<HTMLDivElement>(null)

  // Make sure the notes trigger is on screen before opening the popover
  const isOnScreen = useOnScreen(notesTriggerRef)

  // Check that the note id belongs to a note in this feed
  const isQueryNoteInFeed = notes.some((note) => note.id === noteIdQueryParam)

  const { account } = useAccount()
  const isOnFreePlan = isFreeUser(account)

  const shouldShowPaywall = isOnFreePlan && isNotesPaywallEnabled

  useEffect(() => {
    if (isQueryNoteInFeed && isOnScreen && !shouldShowPaywall) {
      setIsPopoverOpen(true)
    }
  }, [isQueryNoteInFeed, isOnScreen, isOnFreePlan])

  const tooltip = useMemo(() => {
    if (notes?.length) {
      return `${notes.length} note${notes.length > 1 ? 's' : ''}`
    }
    return 'Add a note'
  }, [notes])

  const onTriggerClick = useNotesFeedViewedTracking({
    entityId,
    entityType,
  })

  if (shouldShowPaywall) {
    return (
      <NotesPaywall
        ctaProperties={{
          product: 'publish',
          view: 'queue',
          location: 'queueCard',
          button: 'addNote',
        }}
      >
        <NotesBadgeWrapper
          data-tip={tooltip}
          data-padding="8px 8px 10px 8px"
          role="button"
          tabIndex={0}
          onClick={onTriggerClick}
          ref={notesTriggerRef}
          aria-label="Notes"
        >
          {notes?.length ? <NotesIconFilled /> : <NotesIconOutlined />}
          <ReactTooltip effect="solid" />
        </NotesBadgeWrapper>
      </NotesPaywall>
    )
  }

  return (
    <Popover.Root open={isPopoverOpen} onOpenChange={setIsPopoverOpen}>
      <Popover.Trigger asChild>
        <NotesBadgeWrapper
          data-tip={tooltip}
          data-padding="8px 8px 10px 8px"
          role="button"
          tabIndex={0}
          onClick={onTriggerClick}
          ref={notesTriggerRef}
          aria-label="Notes"
        >
          {notes?.length ? <NotesIconFilled /> : <NotesIconOutlined />}
          <ReactTooltip effect="solid" />
        </NotesBadgeWrapper>
      </Popover.Trigger>
      <Popover.Portal>
        <Popover.Content
          side="right"
          sideOffset={32}
          align="start"
          // Prevents arrow from disappearing when reaching border radius
          alignOffset={-10}
          // Prevents arrow from seeming to detach due to border radius
          arrowPadding={10}
        >
          <PopoverArrow />
          <div>
            {hasNotesEntitlement ? (
              <NotesFeed
                entityId={entityId}
                entityType={entityType}
                notes={notes}
                scrollToNoteId={noteIdQueryParam}
              />
            ) : (
              <MigrationBanner />
            )}
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  )
}
