import {
  createSlice,
  type EntityState,
  type PayloadAction,
  type Slice,
  type SliceCaseReducers,
} from '@reduxjs/toolkit'
import { UploadStatus } from '../constants'
import { uploadsLogger } from '../loggers'
import { type BufferUpload, Upload } from '../entities/Upload'
import type { UploadProgress } from '../values/UploadProgress'
import { uploadsAdapter } from './uploadsAdapter'

// use local selectors in the slice
const { selectAll, selectById } = uploadsAdapter.getSelectors()

export const uploadsSlice: Slice<
  EntityState<Upload> & { removedUploads: Upload[] },
  SliceCaseReducers<EntityState<Upload> & { removedUploads: Upload[] }>,
  'uploads'
> = createSlice({
  name: 'uploads',
  initialState: uploadsAdapter.getInitialState<{ removedUploads: Upload[] }>({
    removedUploads: [],
  }),
  reducers: {
    uploadAdded: uploadsAdapter.addOne,
    uploadsPreloaded: uploadsAdapter.addMany,
    thumbnailGenerated: (
      state,
      action: PayloadAction<Pick<Upload, 'id' | 'thumbnailUrl'>>,
    ) => {
      const { id, thumbnailUrl } = action.payload

      uploadsAdapter.updateOne(state, {
        id,
        changes: {
          thumbnailUrl,
        },
      })
    },
    uploadToS3Succeeded: (
      state,
      action: PayloadAction<Pick<Upload, 'id' | 'url' | 'key' | 'bucket'>>,
    ) => {
      const { id, url, key, bucket } = action.payload

      uploadsAdapter.updateOne(state, {
        id,
        changes: {
          url,
          key,
          bucket,
          status: UploadStatus.UploadedToS3,
        },
      })
    },
    uploadCompleted: (state, action: PayloadAction<BufferUpload>) => {
      const newUpload = action.payload
      const existingUpload = selectById(state, newUpload.id)
      if (!existingUpload || !Upload.isPending(existingUpload)) {
        uploadsLogger(
          'Tried to comple a failed (or non-existing) upload: %s',
          newUpload.id,
        )
        return state
      }

      const uploadToReplace = selectAll(state).find((otherUpload) =>
        Upload.shouldReplace(newUpload, otherUpload),
      )

      if (uploadToReplace) {
        uploadsAdapter.removeOne(state, newUpload.id)
      }

      uploadsAdapter.updateOne(state, {
        id: uploadToReplace ? uploadToReplace.id : newUpload.id,
        changes: newUpload,
      })
    },
    uploadFailed: (
      state,
      action: PayloadAction<{ id: string; error: string }>,
    ) => {
      uploadsAdapter.updateOne(state, {
        id: action.payload.id,
        changes: {
          error: action.payload.error,
          status: UploadStatus.Failed,
        },
      })
    },
    uploadAltTextChanged: (
      state,
      action: PayloadAction<{ id: string; altText: string }>,
    ) => {
      uploadsAdapter.updateOne(state, {
        id: action.payload.id,
        changes: {
          alt: action.payload.altText,
        },
      })
    },
    uploadProgress: (
      state,
      action: PayloadAction<{
        id: string
        progress: UploadProgress
      }>,
    ) => {
      uploadsAdapter.updateOne(state, {
        id: action.payload.id,
        changes: {
          status: UploadStatus.Uploading,
          progress: action.payload.progress,
        },
      })
    },
    uploadRemoved: (state, action: PayloadAction<Pick<Upload, 'id'>>) => {
      const upload = selectById(state, action.payload.id)
      if (upload) {
        state.removedUploads.push(upload)
      }
      uploadsAdapter.removeOne(state, action.payload.id)
    },
    resetUploads: (state, action: PayloadAction<{ uploaderId: string }>) => {
      const ids = selectAll(state)
        .filter((file) => file.uploaderId === action.payload.uploaderId)
        .map((file) => file.id)

      uploadsAdapter.removeMany(state, ids)
    },
    reorderUploads: (
      state,
      action: PayloadAction<{
        activeId: string
        targetId: string
      }>,
    ) => {
      // state.ids has all uploads and is used by selectors to return the uploads in the correct order
      const activeIndex = state.ids.indexOf(action.payload.activeId)
      const targetIndex = state.ids.indexOf(action.payload.targetId)

      if (activeIndex !== -1 && activeIndex !== targetIndex) {
        // update in place is safe because immer is used in slice reducers
        state.ids.splice(targetIndex, 0, state.ids.splice(activeIndex, 1)[0])
      }
    },
  },
})

export const {
  thumbnailGenerated,
  uploadsPreloaded,
  resetUploads,
  uploadAdded,
  uploadCompleted,
  uploadFailed,
  uploadProgress,
  uploadRemoved,
  uploadToS3Succeeded,
  uploadAltTextChanged,
  reorderUploads,
} = uploadsSlice.actions
