import React from 'react'
import PropTypes from 'prop-types'
import { Text, Button } from '@bufferapp/ui'
import { fontWeightBold } from '@bufferapp/ui/style/fonts'
import { gray, grayDark } from '@bufferapp/ui/style/colors'
import HashtagGroupList from '../HashtagGroupList'

import * as Styles from './styles'

const emptyHeaderStyle = {
  paddingTop: '130px',
  paddingBottom: '8px',
  fontWeight: fontWeightBold,
  color: grayDark,
}

const emptyParagraphStyle = {
  color: gray,
}

const HashtagGroupManager = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'onCreateHashtagGroup' implicitly ... Remove this comment to see the full error message
  onCreateHashtagGroup,
  // @ts-expect-error TS(7031) FIXME: Binding element 'hashtagGroups' implicitly has an ... Remove this comment to see the full error message
  hashtagGroups,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onInsertHashtagGroupClick' implic... Remove this comment to see the full error message
  onInsertHashtagGroupClick,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onEditHashtagGroupClick' implicit... Remove this comment to see the full error message
  onEditHashtagGroupClick,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onDeleteHashtagGroupClick' implic... Remove this comment to see the full error message
  onDeleteHashtagGroupClick,
}) => (
  <>
    <Styles.Title>Hashtag Manager</Styles.Title>
    {!hashtagGroups ||
      (hashtagGroups.length === 0 && (
        <Styles.EmptyWrapper>
          <div style={emptyHeaderStyle}>
            {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: st... Remove this comment to see the full error message */}
            <Text>No hashtag groups yet!</Text>
          </div>
          <div style={emptyParagraphStyle}>
            {/* @ts-expect-error TS(2741) FIXME: Property 'type' is missing in type '{ children: st... Remove this comment to see the full error message */}
            <Text>
              Save and reuse your favorite hashtags by creating a hashtag group.
              Click the button below to get started.
            </Text>
          </div>
        </Styles.EmptyWrapper>
      ))}
    {hashtagGroups?.length > 0 && (
      <HashtagGroupList
        hashtagGroups={hashtagGroups}
        onInsertHashtagGroupClick={onInsertHashtagGroupClick}
        onEditHashtagGroupClick={onEditHashtagGroupClick}
        onDeleteHashtagGroupClick={onDeleteHashtagGroupClick}
      />
    )}
    <Styles.ButtonWrapper>
      {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: any;... Remove this comment to see the full error message */}
      <Button
        type="secondary"
        label="Create Hashtag Group"
        onClick={onCreateHashtagGroup}
        fullWidth
      />
    </Styles.ButtonWrapper>
  </>
)

HashtagGroupManager.propTypes = {
  onCreateHashtagGroup: PropTypes.func.isRequired,
  hashtagGroups: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      id: PropTypes.string,
    }),
  ).isRequired,
  onInsertHashtagGroupClick: PropTypes.func.isRequired,
  onEditHashtagGroupClick: PropTypes.func.isRequired,
  onDeleteHashtagGroupClick: PropTypes.func.isRequired,
}

export default HashtagGroupManager
