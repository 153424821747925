import { createAsyncThunk } from '@reduxjs/toolkit'
import type { RootState } from '~publish/legacy/store'
import callRpc from '~publish/legacy/utils/call-rpc'

export const updateTimezone = createAsyncThunk<
  { message: string; success: boolean; newTimezone: string },
  { profileId: string; timezone: string; city: string },
  { state: RootState }
>('postingSchedule/updateTimezone', async ({ profileId, timezone, city }) => {
  const response = await callRpc('updateTimezone', {
    profileId,
    timezone,
    city,
  })

  if (!response.success) {
    throw new Error(response.message)
  }
  return response
})
