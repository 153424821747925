import { useEffect, useCallback } from 'react'

// TODO: This hook listens for the event at a window level, but is designed to
// be used withing a textarea context. This is a bad practice and should be
// refactored to be used within a textarea context.
export const useCMDEnterKeys = (
  handleClose: () => void,
  actionIsEnabled: boolean,
): void => {
  const handleCMDEnter = useCallback(
    (event: KeyboardEvent) => {
      if (event.metaKey && event.key === 'Enter' && actionIsEnabled) {
        event.preventDefault()
        handleClose()
      }
    },
    [handleClose, actionIsEnabled],
  )
  useEffect(() => {
    window.addEventListener('keydown', handleCMDEnter)

    return () => {
      window.removeEventListener('keydown', handleCMDEnter)
    }
  }, [handleCMDEnter])
}
