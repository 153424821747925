import styled from 'styled-components'

import { white } from '@bufferapp/ui/style/colors'

export const OuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: ${white};
  overflow: hidden;

  &.fade-out {
    transition: opacity 0.3s ease, transform 0.3s ease;
    transform: translateY(-150px);
    opacity: 0;
  }
`

export const GradientBackground = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 32px;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(
    45deg,
    rgba(60, 79, 192, 1) 0%,
    rgba(194, 129, 254, 1) 100%
  );
  background-size: 300% 300%;
  animation: BackgroundAnimation 3.25s ease forwards;

  @keyframes BackgroundAnimation {
    0% {
      background-position: 0% 50%;
      opacity: 100%;
    }

    50% {
      background-position: 50% 0%;
    }

    75% {
      opacity: 100%;
    }

    100% {
      background-position: 0% 50%;
      opacity: 0;
    }
  }
`

export const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 999;
  left: 0;
  right: 0;
  top: -60px;
  bottom: 0;
  margin: auto;
  opacity: 0;
  z-index: 10;
  animation: SlideIn 1.2s ease forwards 0.5s;

  @keyframes SlideIn {
    0% {
      opacity: 0;
      transform: translateY(10rem);
    }

    100% {
      opacity: 100%;
      transform: translateY(0);
    }
  }
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 999;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  opacity: 0;
  animation: SlideIn 1.5s ease forwards 0.6s;

  @keyframes SlideIn {
    0% {
      opacity: 0;
      transform: translateY(3rem);
    }

    100% {
      opacity: 100%;
      transform: translateY(0);
    }
  }

  .start {
    animation: FadeStopColorsStart 1.5s ease forwards 1.5s;
  }

  @keyframes FadeStopColorsStart {
    0% {
      stop-color: ${white};
    }

    100% {
      stop-color: #ba6bff;
    }
  }

  .end {
    animation: FadeStopColorsEnd 1.5s ease forwards 1.5s;
  }

  @keyframes FadeStopColorsEnd {
    0% {
      stop-color: ${white};
    }

    100% {
      stop-color: #0113ff;
    }
  }
`

export const WandGroupWrapper = styled.div`
  position: relative;
  margin-right: 8px;

  .wand,
  .star {
    path {
      animation: FillWandGroup 1.5s ease forwards 1.5s;
    }
  }

  @keyframes FillWandGroup {
    0% {
      fill: ${white};
    }

    100% {
      fill: #ba6bff;
    }
  }

  .star1 {
    position: absolute;
    transform: scale(0);
    top: -8px;
    left: -13px;
    animation: Star1 1.5s ease infinite 0.2s;
  }

  @keyframes Star1 {
    0% {
      opacity: 0%;
      transform: scale(0) rotate(-10deg);
    }

    50% {
      opacity: 100%;
      transform: scale(0.4);
    }

    100% {
      opacity: 0;
      transform: scale(0) rotate(100deg);
    }
  }

  .star2 {
    position: absolute;
    transform: scale(0);
    top: -11px;
    left: -3px;
    animation: Star2 1.25s ease infinite;
  }

  @keyframes Star2 {
    0% {
      opacity: 0;
      transform: scale(0) rotate(-20deg);
    }

    50% {
      opacity: 100%;
      transform: scale(0.3);
    }

    100% {
      opacity: 0;
      transform: scale(0) rotate(135deg);
    }
  }

  .star3 {
    position: absolute;
    transform: scale(0);
    right: -11px;
    bottom: 1px;
    animation: Star3 1.5s ease infinite 0.4s;
  }

  @keyframes Star3 {
    0% {
      opacity: 0;
      transform: scale(0) rotate(-35deg);
    }

    50% {
      opacity: 100%;
      transform: scale(0.4);
    }

    100% {
      opacity: 0;
      transform: scale(0) rotate(140deg);
    }
  }
`

export const AITitleWrapper = styled.div`
  display: flex;
  margin-top: 8px;
`
