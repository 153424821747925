import type { BufferEditor } from '~publish/legacy/editor/BufferEditor/types.plate'
import { SlateJsProxy, type SlateJsProxyType } from './SlateJsProxy'
import type Draft from '../Draft/Draft'

function getProxy(editorOrState: BufferEditor | unknown): SlateJsProxyType {
  if (SlateJsProxy.isEditorOrState(editorOrState)) {
    return SlateJsProxy
  }

  throw new Error(
    'EditorStateProxy must be called with the editor or state as the first argument',
  )
}

export const EditorStateProxy = {
  createStateFromText: (
    ...params: Parameters<SlateJsProxyType['createStateFromText']>
  ): BufferEditor => getProxy(params[0]).createStateFromText(...params),
  getPlainText: (e: BufferEditor): string => getProxy(e).getPlainText(e),
  copyDraftEditorState: (draftFrom: Draft, draftTo: Draft): BufferEditor =>
    getProxy(draftFrom.editorState).copyDraftEditorState(draftFrom, draftTo),
  hasUnshortenedLinks: (e: BufferEditor): boolean =>
    getProxy(e).hasUnshortenedLinks(e),
  parseLinks: (e: BufferEditor): string[] => getProxy(e).parseLinks(e),
  getAllLinkedinAnnotations: (
    e: BufferEditor,
  ): ReturnType<SlateJsProxyType['getAllLinkedinAnnotations']> =>
    getProxy(e).getAllLinkedinAnnotations(e),
  getFacebookAutocompleteEntities: (
    e: BufferEditor,
  ): ReturnType<SlateJsProxyType['getFacebookAutocompleteEntities']> =>
    getProxy(e).getFacebookAutocompleteEntities(e),
  insertLinkedInAnnotation: (
    ...params: Parameters<SlateJsProxyType['insertLinkedInAnnotation']>
  ): ReturnType<SlateJsProxyType['insertLinkedInAnnotation']> =>
    getProxy(params[0]).insertLinkedInAnnotation(...params),

  replaceLinkWithShortLink: (
    ...params: Parameters<SlateJsProxyType['replaceLinkWithShortLink']>
  ): ReturnType<SlateJsProxyType['replaceLinkWithShortLink']> =>
    getProxy(params[0]).replaceLinkWithShortLink(...params),
  replaceLinkWithUnshortenedLink: (
    ...params: Parameters<SlateJsProxyType['replaceLinkWithUnshortenedLink']>
  ): ReturnType<SlateJsProxyType['replaceLinkWithUnshortenedLink']> =>
    getProxy(params[0]).replaceLinkWithUnshortenedLink(...params),
  insertSnippet: (e: BufferEditor, text: string): BufferEditor =>
    getProxy(e).insertSnippet(e, text),
  moveCursorToStart: (e: BufferEditor): BufferEditor =>
    getProxy(e).moveCursorToStart(e),
}
