import { SimpleSpinner } from '@buffer-mono/popcorn'
import React from 'react'
import styles from './DocumentPreview.module.css'

export const DocumentPreviewControls = ({
  title,
  pageCount,
  pageNumber,
  changePage,
}: {
  title: string
  pageCount: number
  pageNumber: number
  changePage: (offset: number) => void
}): JSX.Element => {
  const isFirstPage = pageNumber === 1
  const isLastPage = pageNumber === pageCount

  function previousPage(): void {
    changePage(-1)
  }

  function nextPage(): void {
    changePage(1)
  }

  return (
    <>
      <div className={styles.titleArea}>
        <span className={styles.title} data-testid="document-preview-title">
          {title}
        </span>{' '}
        &middot; {pageCount} pages
      </div>
      <div className={styles.controls}>
        <button
          onClick={previousPage}
          className={styles.arrowLeft}
          aria-label="go to previous slide"
          disabled={isFirstPage}
        >
          &lt;
        </button>
        <button
          onClick={nextPage}
          className={styles.arrowRight}
          aria-label="go to next slide"
          disabled={isLastPage}
        >
          &gt;
        </button>
        <footer className={styles.progressContainer}>
          <label className={styles.progressLabel}>
            <span>
              {pageNumber} / {pageCount}
            </span>
            <progress
              aria-labelledby="page-progress-label"
              value={pageNumber - 1}
              max={pageCount - 1}
            />
          </label>
        </footer>
      </div>
    </>
  )
}

export const LoadingSpinner = (): JSX.Element => (
  <div className={styles.loading} data-testid="document-preview-loading">
    <SimpleSpinner size="large" />
  </div>
)
