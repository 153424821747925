import React, { useState } from 'react'
import styled from 'styled-components'
import {
  white,
  grayLighter,
  gray,
  grayDark,
  grayDarker,
  boxShadow,
} from '@bufferapp/ui/style/colors'
import {
  fontSize,
  fontFamily,
  fontWeightMedium,
} from '@bufferapp/ui/style/fonts'

import { networkIconMap } from '../ChannelIcon'

const getBgStyle = (isHovering: boolean, focus: boolean): string => {
  if (isHovering || focus) return white
  return grayLighter
}

const EmptySlot = styled.div<{
  isHovering: boolean
  focus: boolean
  service: string
}>`
  background: ${(props): string => getBgStyle(props.isHovering, props.focus)};
  cursor: ${(props): string =>
    props.service === 'noProfile' ? 'auto' : 'pointer'};
  border: ${(props): string =>
    props.isHovering ? `1px solid ${gray}` : '1px solid transparent'};
  border-radius: 4px;
  font-family: ${fontFamily};
  font-style: normal;
  font-weight: ${fontWeightMedium};
  line-height: normal;
  font-size: ${fontSize};
  color: ${(props): string => (props.focus ? grayDarker : grayDark)};
  height: 48px;
  display: flex;
  justify-content: center;
  transition: all 0.1s ease-out;
  margin-bottom: 8px;
  box-shadow: ${(props): string =>
    props.focus ? `0 0 0 3px ${boxShadow}` : 'none'};
  position: relative;
`

const MessageWrapper = styled.div`
  display: flex;
`

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 15px;
`

const IconWrapper = styled.span`
  display: flex;
  margin-right: 5px;
`

const Message = ({ message }: { message: string }): JSX.Element => (
  <span>{message}</span>
)

interface HoverMessageProps {
  service: string
  customHoverMessage?: string
}

const HoverMessage = ({
  service,
  customHoverMessage,
}: HoverMessageProps): JSX.Element => {
  if (customHoverMessage) {
    return <Message message={customHoverMessage} />
  }

  if (service === 'noProfile') {
    return (
      <Message message="Connect a channel to schedule posts to your queue" />
    )
  }

  const message = 'Schedule a Post'
  const icon = networkIconMap.get(service)
  if (icon) {
    const { component: IconComponent } = icon
    return (
      <MessageWrapper>
        {icon && (
          <IconWrapper>
            <IconComponent />
          </IconWrapper>
        )}
        <Message message={message} />
      </MessageWrapper>
    )
  }
  return (
    <MessageWrapper>
      <Message message={message} />
    </MessageWrapper>
  )
}

interface PostEmptySlotProps {
  time: string
  service: string
  customLabel?: string
  customHoverMessage?: string
  onClick: () => void
  focus?: boolean
}

const PostEmptySlot = ({
  time,
  service,
  customLabel,
  customHoverMessage,
  onClick,
  focus = false,
}: PostEmptySlotProps): JSX.Element => {
  const [isHovering, setIsHovering] = useState(false)

  const onMouseEnter = (): void => {
    setIsHovering(true)
  }

  const onMouseLeave = (): void => {
    setIsHovering(false)
  }

  const message = customLabel || time

  return (
    <EmptySlot
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      onKeyPress={onClick}
      isHovering={isHovering}
      focus={focus}
      service={service}
      role="button"
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      tabIndex="0"
    >
      <LabelWrapper>
        {isHovering ? (
          <HoverMessage
            service={service}
            customHoverMessage={customHoverMessage}
          />
        ) : (
          <Message message={message} />
        )}
      </LabelWrapper>
    </EmptySlot>
  )
}

export default PostEmptySlot
