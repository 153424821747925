import type { RpcUpdate } from '~publish/legacy/post/types'

export type CampaignChannel = {
  serviceId: string
  serviceType: string
  channelType: string
  channelId: string
  serviceAvatar: string
  serviceUsername: string
  isManager: boolean
  hasPushNotifications: boolean
}

export enum CampaignColor {
  Purple = '#9C2BFF',
  Pink = '#D925AC',
  PinkLighter = '#F0A8DE',
  Orange = '#FF702C',
  Yellow = '#FADE2A',
  Green = '#87C221',
  Teal = '#00C8CF',
  BlueDark = '#1F35B3',
  Black = '#000000',
}

export type CampaignDetails = {
  id: string
  name: string
  color: CampaignColor
  startDate?: number
  endDate?: number
  /** Human readable date of campaign. E.g., 'Oct 11-13' */
  formattedDateRange?: string
}

export type HeaderDetailsChannel = {
  avatarUrl: string
  handle: string
  type: string
  serviceLocation?: { location: string }
}

export type HeaderDetails = {
  showHeader: boolean
  channel?: HeaderDetailsChannel
  creatorName?: string
  creatorEmail: string
  avatarUrl: string
  createdAt: string
  hideCreatorDetails?: boolean
  onPreviewClick?: () => void
}

export type CampaignItem = RpcUpdate & {
  headerDetails: HeaderDetails
}

export enum CampaignTab {
  Scheduled = 'scheduled',
  Sent = 'sent',
  Drafts = 'draft',
  Approvals = 'draft_pending',
  Ideas = 'ideas',
}

export enum CtaLocation {
  EmptyDraftsTab = 'emptyDraftsTab',
  EmptyScheduledTab = 'emptyScheduledTab',
  CampaignHeader = 'campaignHeader',
}

export type CampaignListItem = {
  color?: CampaignColor
  id: string
  name: string
  sent: number
  scheduled: number
  lastUpdated: string
  dateRange?: string
  draftsCount?: number
  approvalsCount?: number
}

export type Campaign = {
  id: string
  name: string
  color: CampaignColor

  globalOrganizationId: string
  lastUpdated: string
  updatedAt: number
  createdAt: number
  startDate: number
  endDate: number
  dateRange: string | null
  sent: number
  scheduled: number
  draftsCount: number
  approvalsCount: number
  isAggregation?: boolean
  channels: CampaignChannel[] | []
  items: CampaignItem[] | []
  locked: boolean
  canViewReport?: boolean
}

export type Tag = {
  id: string
  name: string
  color: CampaignColor | string
  scheduled: number
  draftsCount: number
  approvalsCount: number
  sent: number
  locked: boolean
  dateRange: string | null
  canViewReport?: boolean
}

export type LeanTag = {
  id?: string
  name: string
  color: string
  locked?: boolean
}

export type SelectedTag = Pick<Tag, 'id' | 'name' | 'color'>
