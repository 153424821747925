type ActionList = Record<string, number | string>

/**
 * Generates a new type where each key from the ActionList is prefixed with a namespace,
 * creating a string literal that incorporates the namespace and key.
 *
 * @template T - The type of the action list (derived from ActionList).
 * @template S - The type of the namespace string.
 */
type WrappedKeys<T extends ActionList, S extends string> = {
  [K in keyof T]: `${S}__${Extract<K, string>}`
}

/**
 * Wraps each key of an action list with a specified namespace prefix followed by double underscores.
 * This function is useful for avoiding key collisions in larger applications or libraries.
 *
 * @param {S} namespace - A string representing the namespace to prefix the keys with.
 * @param {T} actions - An object containing keys that represent action types.
 * @returns {WrappedKeys<T, S>} - An object with the same keys as the input 'actions' but each key
 *                                transformed into a namespaced string literal.
 */
export function keyWrapper<T extends ActionList, S extends string>(
  namespace: S,
  actions: T,
): WrappedKeys<T, S> {
  const keys = Object.keys(actions) as (keyof T)[]
  const result: Partial<WrappedKeys<T, S>> = {}

  for (const key of keys) {
    const value = `${namespace}__${String(key)}` as WrappedKeys<
      T,
      S
    >[keyof WrappedKeys<T, S>]
    result[key as keyof WrappedKeys<T, S>] = value
  }

  return result as WrappedKeys<T, S>
}

export default keyWrapper
