import styled, { css } from 'styled-components'

import HeartOutlineIcon from '@bufferapp/ui/Icon/Icons/HeartOutline'
import MessageOutlineIcon from '@bufferapp/ui/Icon/Icons/MessageOutline'
import BookmarkOutlineIcon from '@bufferapp/ui/Icon/Icons/BookmarkOutline'
import CommentRoundOutlineIcon from '@bufferapp/ui/Icon/Icons/CommentRoundOutline'
import { white } from '@bufferapp/ui/style/colors'

import * as Styles from '../Common/styles'

export const Wrapper = styled(Styles.Wrapper)`
  padding: 0 31px;
`

export const fontCss = css`
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: ${white};
`

export const AvatarCss = css`
  width: 24px;
  height: 24px;
  border-radius: 100%;
  -webkit-mask-size: cover;
`

export const Avatar = styled.div<{ src: string; fallbackUrl: string }>`
  ${AvatarCss}
  background: url(${({ src }) => src}) center no-repeat,
    url(${({ fallbackUrl }) => fallbackUrl}) center no-repeat;
  background-size: 24px;
`

export const IconCss = css`
  width: 20px;
  height: 20px;
  color: ${white};
`

export const MessageIcon = styled(MessageOutlineIcon)`
  ${IconCss};
`

export const HeartIcon = styled(HeartOutlineIcon)`
  ${IconCss};
`

export const BookmarkIcon = styled(BookmarkOutlineIcon)`
  ${IconCss};
`

export const CommentIcon = styled(CommentRoundOutlineIcon)`
  ${IconCss};
`

export const TextStickerWrapper = styled.div`
  position: absolute;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 100px;
  left: 12px;
  right: 12px;
  overflow: hidden;

  /* Display animation */
  animation: fadeInSlideDown 0.2s ease;

  @keyframes fadeInSlideDown {
    from {
      opacity: 0;
      transform: translateY(-4px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

export const TextStickerContent = styled.span`
  background-color: rgba(255, 255, 255, 0.75);
  border-radius: 4px;
  padding: 8px 4px;
  text-align: center;
  display: block;
  word-wrap: break-word;
  max-width: 100%;
  max-height: 180px;
  font-size: 22px;
  font-family: 'Barlow Condensed', sans-serif;
  font-weight: 500;
`
