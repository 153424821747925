/**
 * Component that displays toggles for attachments.
 */

import React from 'react'
import {
  Play,
  Image,
  Retweet,
  Link as LinkIcon,
  Thread,
} from '@bufferapp/ui/Icon'
import { FileTextIcon } from '@buffer-mono/popcorn'

import AppStore from '../stores/AppStore'
import type { Draft } from '../entities/Draft'
import { AttachmentTypes } from '../AppConstants'
import { escapeParens } from '../utils/StringUtils'
import styles from './css/AttachmentGlance.module.css'
import ComposerActionCreators from '../action-creators/ComposerActionCreators'
import styled from 'styled-components'
import { white } from '@bufferapp/ui/style/colors'

const PlayIcon = styled(Play)`
  fill: ${white};
  background-color: rgba(99, 99, 99, 0.62);
  padding: 4px;
  border-radius: 50%;
  flex-shrink: 0;
  width: 8px;
  height: 8px;
}`

function AttachmentGlance({ draft }: { draft: Draft }): JSX.Element {
  const attachmentThumbnails = draft.getAttachmentThumbnails()

  if (AppStore.getExpandedComposerId() === draft.id) {
    return <></>
  }

  const attachmentType = draft.enabledAttachmentType
  let iconClassName = styles.attachmentIcon
  let attachmentIcon

  if (!draft.hasAttachment() && !draft.hasThread()) {
    iconClassName = styles.attachmentIconThreads
  } else if (draft.hasThread()) {
    attachmentIcon = <Thread />
  } else if (attachmentType === AttachmentTypes.MEDIA && draft.video !== null) {
    attachmentIcon = <PlayIcon />
  } else if (attachmentType === AttachmentTypes.MEDIA && draft.gif !== null) {
    attachmentIcon = <Image />
  } else if (
    attachmentType === AttachmentTypes.MEDIA &&
    draft.images?.length > 0
  ) {
    attachmentIcon = <Image />
  } else if (attachmentType === AttachmentTypes.LINK && draft.link !== null) {
    attachmentIcon = <LinkIcon />
  } else if (
    attachmentType === AttachmentTypes.RETWEET &&
    draft.retweet !== null
  ) {
    attachmentIcon = <Retweet />
  } else if (draft.document) {
    attachmentIcon = <FileTextIcon />
  }
  const hasThumbnail = attachmentThumbnails !== null

  const containerClass = [
    hasThumbnail ? styles.containerWithThumbnail : styles.containerNoThumbnail,
    styles.container,
  ].join(' ')

  return (
    <button
      className={containerClass}
      onClick={(e): void => {
        e.preventDefault()

        ComposerActionCreators.expand(draft.id)
      }}
    >
      <div className={iconClassName}>{attachmentIcon}</div>
      {hasThumbnail && (
        <div className={styles.thumbnailContainer}>
          {attachmentThumbnails.slice(0, 4).map((thumbnail) => (
            <div
              key={thumbnail}
              className={styles.thumbnail}
              role="presentation"
              style={{ backgroundImage: `url(${escapeParens(thumbnail)})` }}
            />
          ))}
        </div>
      )}
    </button>
  )
}

export default AttachmentGlance
