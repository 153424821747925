import { serialize } from '~publish/legacy/editor/BufferEditor'
import type { BufferEditor } from '~publish/legacy/editor/BufferEditor/types.plate'
import type { WithPosition } from '~publish/legacy/editor/helpers/WithPosition'
import type { LinkedInAnnotation } from '../LinkedInAnnotations'
import { LinkedInAnnotationElement } from '../nodes/LinkedInAnnotationElement'

export const getAllLinkedInAnnotations = (
  editor: BufferEditor,
): WithPosition<LinkedInAnnotation>[] => {
  const { data } = serialize(editor)

  const entities = data[LinkedInAnnotationElement.type] || []

  return entities
}
