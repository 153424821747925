import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Text } from '@buffer-mono/popcorn'
import { actions as modalsActions } from '~publish/legacy/modals'
import { selectHasFirstCommentForLinkedinEntitlement } from '~publish/legacy/organizations/selectors'

import {
  ChannelFieldCharacterCount,
  FieldWrapper,
  FirstCommentWrapper,
  FirstCommentTextAreaWrapper,
  StyledCriticalIcon,
} from '~publish/legacy/composer/composer/components/channel-fields/styles'
import ComposerActionCreators from '../../../action-creators/ComposerActionCreators'
import type { Draft } from '../../../entities/Draft'
import AppStore from '../../../stores/AppStore'
import { draftPropType } from '../../ComposerPropTypes'
import UpgradeIconCTA from '../../UpgradeIconCTA'
import { Label } from '../styles'
import { SERVICE_INSTAGRAM, SERVICE_LINKEDIN } from '~publish/legacy/constants'

export const FirstCommentValidationNotice = (): JSX.Element | null => (
  <Text size="sm" as="div" color="critical" style={{ display: 'flex' }}>
    <StyledCriticalIcon size="xsmall" />
    You have exceeded the maximum character limit
  </Text>
)

export const FirstComment = ({
  draft,
}: {
  draft: Draft
}): JSX.Element | null => {
  const dispatch = useDispatch()
  const hasFirstCommentEntitlement = useSelector(
    selectHasFirstCommentForLinkedinEntitlement,
  )
  const shouldShowUpgradePaywallIcon = !hasFirstCommentEntitlement
  const comment = draft.getCommentText()
  const shouldDisplayFirstComment =
    draft.service.isLinkedin() &&
    AppStore.getExpandedComposerId() === draft.id &&
    !draft.service.isOmni
  const editDisabled = shouldShowUpgradePaywallIcon
  const isOverCharacterLimit =
    comment.length > (draft.service.commentCharLimit || 0)

  if (!shouldDisplayFirstComment) {
    return <></>
  }

  const onCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    if (draft.service.name === SERVICE_INSTAGRAM) {
      ComposerActionCreators.updateDraftInstagramData({
        ...draft?.channelData?.instagram,
        comment_text: e.target.value,
      })
    }
    if (draft.service.name === SERVICE_LINKEDIN) {
      ComposerActionCreators.updateDraftLinkedinData({
        ...draft?.channelData?.linkedin,
        comment_text: e.target.value,
      })
    }
    ComposerActionCreators.updateDraftCommentCharacterCount(draft.id)
  }

  const handleClick = (editDisabled: boolean): void => {
    if (editDisabled && shouldShowUpgradePaywallIcon) {
      dispatch(
        modalsActions.showFirstCommentPaywallModal({
          service: draft.service.name,
        }),
      )
    }
  }

  return (
    <FieldWrapper>
      <Label className="outer-label" htmlFor="linkedin-first-comment">
        First Comment
      </Label>
      <FirstCommentWrapper onClick={(): void => handleClick(editDisabled)}>
        <FirstCommentTextAreaWrapper
          id="linkedin-first-comment"
          name="linkedin-first-comment"
          placeholder="Your comment"
          disabled={editDisabled}
          value={comment}
          onChange={onCommentChange}
          data-test-id="linkedin-first-comment"
          rows={1}
          aria-invalid={isOverCharacterLimit}
        />
        {isOverCharacterLimit && <FirstCommentValidationNotice />}
        {draft.service.commentCharLimit &&
          draft.canTypeMoreCommentCharacters() && (
            <ChannelFieldCharacterCount
              count={comment.length}
              maxCount={draft.service.commentCharLimit}
            />
          )}
        {shouldShowUpgradePaywallIcon && <UpgradeIconCTA />}
      </FirstCommentWrapper>
    </FieldWrapper>
  )
}

FirstComment.propTypes = {
  draft: draftPropType.isRequired,
}
