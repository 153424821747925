import styled from 'styled-components'
import * as Popover from '@radix-ui/react-popover'
import { grayDarker } from '@bufferapp/ui/style/colors'

export const NoResultsFound = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
`

export const PopoverContent = styled(Popover.Content)`
  z-index: 26;
`

export const StyledArrow = styled(Popover.Arrow).attrs({
  fill: grayDarker,
})``

export const EmojiMartPickerStyleOverrides = styled.div`
  em-emoji-picker {
    --background-rgb: 61 61 61;
    --rgb-background: 61 61 61;
    --font-family: 'Roboto', sans-serif;
    --font-size: 14px;
    --rgb-accent: 107 129 255;
    --border-radius: 8px;
    --preview-placeholder-size: 14px;
  }
`

export const EmojiPickerIconCircle = styled.div`
  margin: 0;
  padding: 0;
  width: 1.5em;
  height: 1.5em;
  box-sizing: border-box;
  line-height: 1.2em;
  font-size: 1.5em;
  border: 1px solid #ddd;
  border-radius: 1.5em;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
`

export { default as EmojiPickerIcon } from '@bufferapp/ui/Icon/Icons/Emoji'
