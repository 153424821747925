import type React from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import {
  type Active,
  type CollisionDetection,
  type DragCancelEvent,
  type DragEndEvent,
  type DragOverEvent,
  type DragStartEvent,
  type Over,
  type UniqueIdentifier,
  closestCenter,
  getFirstCollision,
  pointerWithin,
  rectIntersection,
} from '@dnd-kit/core'
import { arrayMove } from '@dnd-kit/sortable'

import { UNASSIGNED_GROUP_ID } from '../helpers/helpers'
import { useCustomSensors } from '~publish/hooks/useCustomSensors'

type Items = Record<UniqueIdentifier, UniqueIdentifier[]>
type BoardHandlers = {
  activeId: UniqueIdentifier | null
  items: Items
  columns: UniqueIdentifier[]
  lockOverlayId: UniqueIdentifier | null
  updateItems: React.Dispatch<React.SetStateAction<Items>>
  contextProps: {
    sensors: ReturnType<typeof useCustomSensors>
    collisionDetection: CollisionDetection
    onDragStart: (event: DragStartEvent) => void
    onDragEnd: (event: DragEndEvent) => void
    onDragOver: (event: DragOverEvent) => void
    onDragCancel: (event?: DragCancelEvent) => void
  }
}

type ItemMovedCallback = ({
  groupId,
  ideaId,
  placeAfterId,
}: {
  groupId?: string
  ideaId: string
  placeAfterId?: string
}) => Promise<void>

type ColumnMovedCallback = ({
  id,
  placeAfterId,
}: {
  id: string
  placeAfterId?: string
}) => Promise<void>

/**
 * Custom hook to setup a "Kanban" like board with drag and drop functionality.
 *
 * @param {Items} initialItems - Initial state of the board's items.
 * @param {Function} onItemMoved - Callback to be called when an item is moved.
 * @returns {BoardHandlers} Hook state and handlers for drag and drop events.
 */
export function useDndBoard(
  initialItems: Items,
  onItemMoved: ItemMovedCallback,
  onColumnMoved: ColumnMovedCallback,
  isLocked: (id: UniqueIdentifier) => boolean,
): BoardHandlers {
  const [items, setItems] = useState<Items>(initialItems ?? {})
  const [columns, setColumns] = useState<UniqueIdentifier[]>(
    Object.keys(initialItems ?? {}),
  )
  const [activeItemId, setActiveItemId] = useState<UniqueIdentifier | null>(
    null,
  )
  const [clonedItems, setClonedItems] = useState<Items | null>(null)

  const recentlyMovedToNewContainer = useRef(false)
  const lastOverId = useRef<UniqueIdentifier | null>(null)

  const sensors = useCustomSensors()
  const [lockOverlayId, setLockOverlayId] = useState<UniqueIdentifier | null>(
    null,
  )

  useEffect(
    function resetRecentlyMovedToNewContainer() {
      requestAnimationFrame(() => {
        recentlyMovedToNewContainer.current = false
      })
      setColumns(Object.keys(items))
    },
    [items],
  )

  /**
   * Custom collision detection strategy optimized for multiple containers.
   * When dragging containers, resolve using rectIntersection.
   *
   * Steps:
   * 1. Prioritize droppable containers intersecting with the pointer.
   * 2. If none, fallback to containers intersecting with the draggable.
   * 3. Use the last matched intersection if no current intersections are found.
   */
  const collisionDetection: CollisionDetection = useCallback(
    (args) => {
      if (args.active?.data?.current?.type === 'container') {
        return rectIntersection(args)
      }

      // First, attempt to find droppables intersecting with the pointer.
      let intersections = pointerWithin(args)
      if (intersections.length === 0) {
        // If no pointer intersections, check for rectangle intersections.
        intersections = rectIntersection(args)
      }

      let overId = getFirstCollision(intersections, 'id')

      // Adjust overId for containers with items, aiming for closest center within.
      if (overId && overId in items && items[overId].length > 0) {
        const containerItems = items[overId]
        overId = closestCenter({
          ...args,
          droppableContainers: args.droppableContainers.filter(
            (container) =>
              container.id !== overId && containerItems.includes(container.id),
          ),
        })[0]?.id
      }

      // Cache the last overId, or update it based on recent movements.
      if (overId) {
        lastOverId.current = overId
      } else if (recentlyMovedToNewContainer.current && activeItemId) {
        // When a draggable item moves to a new container, the layout may shift
        // and the `overId` may become `null`. We manually set the cached `lastOverId`
        // to the id of the draggable item that was moved to the new container, otherwise
        // the previous `overId` will be returned which can cause items to incorrectly shift positions
        lastOverId.current = activeItemId
      }

      // Return the current or last known overId, if any.
      return overId
        ? [{ id: overId }]
        : lastOverId.current
        ? [{ id: lastOverId.current }]
        : []
    },
    [activeItemId, items],
  )

  function onDragStart({ active }: DragStartEvent): void {
    setActiveItemId(active.id)
    setClonedItems(items)
  }

  function onDragOver({ active, over }: DragOverEvent): void {
    const overId = over?.id

    if (overId == null || active.data?.current?.type === 'container') {
      return
    }

    const overContainer = findContainer(overId)
    const activeContainer = findContainer(active.id)
    const previousContainer = findContainer(active.id, clonedItems ?? undefined)

    setLockOverlayId(isLocked(overContainer) ? overContainer : null)

    if (
      !overContainer ||
      !activeContainer ||
      activeContainer === overContainer ||
      (isLocked(overContainer) && overContainer !== previousContainer)
    ) {
      return
    }

    // Use a setTimeout to avoid 'Maximum update depth exceeded' error
    // see more: https://github.com/clauderic/dnd-kit/issues/496#issuecomment-1789098099
    setTimeout(
      () =>
        setItems((items) => {
          const activeItem = items[activeContainer].find(
            (item) => item === active.id,
          )
          const overItemIndex = items[overContainer].indexOf(overId)

          if (activeItem === undefined) return items

          let newIndex: number = overItemIndex

          // Calculate new index based on position relative to over item
          if (active?.rect?.current?.translated) {
            const isBelowOverItem =
              over &&
              active.rect.current.translated.top >
                over.rect.top + over.rect.height
            newIndex += isBelowOverItem ? 1 : 0
          } else {
            // Default to adding to the end if no translated position is available
            newIndex = items[overContainer].length
          }

          recentlyMovedToNewContainer.current = true

          return {
            ...items,
            // Remove the active item from the active container
            [activeContainer]: items[activeContainer].filter(
              (item) => item !== active.id,
            ),
            // Add the active item to the over container at the new index
            [overContainer]: [
              ...items[overContainer].slice(0, newIndex),
              activeItem,
              ...items[overContainer].slice(
                newIndex,
                items[overContainer].length,
              ),
            ],
          }
        }),
      0,
    )
  }

  function onDragEnd({ active, over }: DragEndEvent): void {
    setLockOverlayId(null)

    if (
      active?.data?.current?.type === 'container' &&
      over?.data?.current?.type === 'container'
    ) {
      handleOnContainerDragEnd(active, over)
    } else if (active?.data?.current?.type === 'item') {
      handleOnItemDragEnd(active, over)
    }

    setActiveItemId(null)
  }

  function onDragCancel(): void {
    if (clonedItems) {
      // Reset items to their original state in case items have been
      // Dragged across containers
      setItems(clonedItems)
    }

    setActiveItemId(null)
    setClonedItems(null)
    setLockOverlayId(null)
  }

  return {
    activeId: activeItemId,
    items,
    columns,
    updateItems: setItems,
    lockOverlayId,
    contextProps: {
      collisionDetection,
      sensors,
      onDragStart,
      onDragOver,
      onDragEnd,
      onDragCancel,
    },
  }

  function findContainer(
    id: UniqueIdentifier,
    _items: Items = items,
  ): UniqueIdentifier {
    if (Object.hasOwn(_items, id)) return id

    return Object.keys(_items).find((key) =>
      _items[key].includes(id),
    ) as UniqueIdentifier
  }

  function handleOnContainerDragEnd(active: Active, over: Over): void {
    if (isLocked(active.id) || isLocked(over.id)) return

    const newColumns = arrayMove(
      columns,
      columns.indexOf(active.id),
      columns.indexOf(over.id),
    )
    setColumns(newColumns)
    const activeIndex = newColumns.indexOf(active.id)
    onColumnMoved({
      id: active.id as string,
      placeAfterId: newColumns[activeIndex - 1] as string,
    }).catch(() => setColumns(columns))
  }

  function handleOnItemDragEnd(active: Active, over: Over | null): void {
    const activeContainer = findContainer(active.id)
    const overItemId = over?.id

    if (!activeContainer || overItemId == null) return

    const overContainer = findContainer(overItemId)

    if (isLocked(overContainer)) {
      // If the over container is locked, do not allow the item to be moved
      // to that container and reset the items to their original state.
      setItems(clonedItems ?? items)
      return
    }

    // onDragEnd the active item should be already on the same container as the
    // over item, this happened because of the onDragOver event. So we are only
    // "sorting" on the same container.
    sortItemsWithinContainer(activeContainer, active, overItemId)
  }

  function sortItemsWithinContainer(
    activeContainer: UniqueIdentifier,
    active: Active,
    overItemId: UniqueIdentifier,
  ): void {
    const activeItemIndex = items[activeContainer].indexOf(active.id)
    const overItemIndex = items[activeContainer].indexOf(overItemId)

    setItems((currentItems) => {
      const updatedItems = {
        ...currentItems,
        [activeContainer]: arrayMove(
          currentItems[activeContainer],
          activeItemIndex,
          overItemIndex,
        ),
      }

      const currentIndex = updatedItems[activeContainer].indexOf(active.id)
      const placeAfterId = updatedItems[activeContainer][currentIndex - 1]

      const previousContainer = findContainer(active.id, clonedItems ?? items)
      const previousIndex = (clonedItems ?? items)[previousContainer].indexOf(
        active.id,
      )

      // Only call onItemMoved if the item has moved to a new container or
      // if the item has moved within the same container but to a different position
      if (
        activeContainer !== previousContainer ||
        previousIndex !== currentIndex
      ) {
        onItemMoved({
          groupId:
            activeContainer !== UNASSIGNED_GROUP_ID
              ? (activeContainer as string)
              : undefined,
          ideaId: active.id as string,
          placeAfterId: placeAfterId as string,
        })
      }

      return updatedItems
    })
  }
}
