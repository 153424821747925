import { useState } from 'react'

import Scraper from '~publish/legacy/composer/composer/utils/Scraper'
import ComposerStore from '~publish/legacy/composer/composer/stores/ComposerStore'
import type { Link } from '~publish/legacy/composer/composer/entities/factories'
import { env } from '~publish/env'

type ScrapedDataResultType = {
  url: string
  title?: string
  description?: string
  cannonicalUrl?: string
  images?: {
    url: string
    width: number
    height: number
    type: string
    opengraph: boolean
  }[]
}

export const useLinkPreview = (isTwitterPreview: boolean) => {
  const [linkPreview, setLinkPreview] = useState<Link | null>(null)
  const [scrapedResult, setScrapedData] = useState<Link | null>(null)
  const [isDataLoaded, setIsDataLoaded] = useState<boolean>(false)

  const scrapeUrl = (link: string | null): void => {
    if (link && link !== '') {
      const environment = env.VITE_NODE_ENV
      const canonicalUrl = ComposerStore.getCanonicalUrl(link)

      Scraper.scrape(canonicalUrl, environment, isTwitterPreview)
        .then((scrapedData: ScrapedDataResultType) => ({
          url: canonicalUrl,
          title: scrapedData.title || '',
          description: scrapedData.description || '',
          thumbnail:
            Array.isArray(scrapedData.images) && scrapedData.images.length > 0
              ? scrapedData.images[0]
              : '',
        }))
        .then((scrapedData: Link) => {
          if (
            scrapedData.title ||
            scrapedData.description ||
            scrapedData.thumbnail
          ) {
            setLinkPreview(scrapedData)
            setScrapedData(scrapedData)
            setIsDataLoaded(true)
          }
        })
    }
  }

  return [
    linkPreview,
    scrapeUrl,
    setLinkPreview,
    setIsDataLoaded,
    isDataLoaded,
    scrapedResult,
  ] as const
}
