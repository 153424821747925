import type { Campaign } from '~publish/legacy/campaign/types'
import type { RootState } from '~publish/legacy/store'

export const selectCampaignsListNames = (state: RootState): string[] => {
  if (!state.campaignsList?.campaigns) return []

  return state.campaignsList?.campaigns?.map(
    (campaign: Campaign) => campaign.name,
  )
}
