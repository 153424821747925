import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Text, Input, Button, Link } from '@bufferapp/ui'
import { SimpleColorPicker } from '~publish/legacy/shared-components'
import { borderRadius } from '@bufferapp/ui/style/borders'
import { View } from '@bufferapp/ui/Icon'
import { useTranslation } from 'react-i18next'
import {
  grayLight,
  grayLighter,
  grayDark,
  white,
  purple,
  pink,
  pinkLighter,
  orange,
  yellow,
  green,
  teal,
  blueDark,
  grayShadow,
  black,
  red,
  purpleLighter,
  redLighter,
  orangeLighter,
  yellowLighter,
  greenLighter,
  tealLighter,
  boxShadow,
  gray,
} from '@bufferapp/ui/style/colors'
import { HC_UTM_PARAMS } from '~publish/legacy/utils/contants'
import { Redirect, useLocation } from 'react-router-dom'
import { tagCreate, tagsPage } from '~publish/legacy/routes'
import { useSelector } from 'react-redux'
import { selectCampaignsListNames } from '~publish/legacy/campaigns-list/selectors'

/* Styles */
const Wrapper = styled.div`
  background-color: ${grayLighter};
  height: 100%;
`

const Main = styled.main`
  width: 402px;
  margin: 53px 0 0 283px;
`

const Card = styled.div`
  display: flex;
  flex-direction: column;
  background: ${white};
  border: 1px solid ${grayLight};
  box-sizing: border-box;
  border-radius: ${borderRadius};
  box-shadow: ${grayShadow};
  width: 100%;
  padding: 0 16px;
`

const Headline = styled(Text)`
  text-align: center;
`

const NoticeCard = styled(Card)`
  flex-direction: row;
  border: 1px solid ${grayLight};
  background: ${white};
  box-shadow: none;
  margin: 16px 0 24px;
  padding: 16px;
`

const Notice = styled.div`
  flex: 1;
  align-items: baseline;
`

const NoticeText = styled(Text)`
  margin: 0 0 0 8px;
  color: ${grayDark};
  a {
    display: inline-block;
  }
`

type StyleProps = {
  addMargin: boolean
}

const ColorLabel = styled(Text)<{ addMargin: boolean }>`
  margin-top: ${(props: StyleProps): string =>
    props.addMargin ? '24px' : '0'};
`

/* List of colors for the color picker */
const colors = [
  { color: purple, colorName: 'purple' },
  { color: pink, colorName: 'pink' },
  { color: red, colorName: 'red' },
  { color: orange, colorName: 'orange' },
  { color: yellow, colorName: 'yellow' },
  { color: green, colorName: 'green' },
  { color: teal, colorName: 'teal' },
  { color: blueDark, colorName: 'blue dark' },
  { color: black, colorName: 'black' },
  { color: purpleLighter, colorName: 'purple lighter' },
  { color: pinkLighter, colorName: 'pink lighter' },
  { color: redLighter, colorName: 'red lighter' },
  { color: orangeLighter, colorName: 'orange lighter' },
  { color: yellowLighter, colorName: 'yellow lighter' },
  { color: greenLighter, colorName: 'green lighter' },
  { color: tealLighter, colorName: 'teal lighter' },
  { color: boxShadow, colorName: 'lighter blue' },
  { color: gray, colorName: 'gray' },
]

type CampaignFormProps = {
  campaignId: string
  onCreateOrUpdateCampaignClick: (args: {
    campaignId: string
    colorSelected: string
    campaignName: string
    editMode: boolean
    orgId: string
  }) => void
  onCancelClick: () => void
  isLoading: boolean
  editMode: boolean
  campaign: {
    id: string
    name: string
    color: string
    globalOrganizationId: string
    locked: boolean
  }
  fetchCampaign: (args: { campaignId: string }) => void
  hasCampaignsFlip: boolean
}

/* Component */
const CampaignForm = ({
  campaignId,
  onCreateOrUpdateCampaignClick,
  onCancelClick,
  isLoading,
  editMode,
  campaign,
  fetchCampaign,
}: CampaignFormProps): JSX.Element | null => {
  // autofocus name input
  const inputElement = useRef(null)
  const { t } = useTranslation()
  const { pathname } = useLocation()

  // State
  const [campaignName, setName] = useState('')
  const [colorSelected, setColor] = useState(purple)
  const [isSubmitButtonDisabled, disableSubmit] = useState(true)
  const [showError, setShowError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const campaignsListNames = useSelector(selectCampaignsListNames)

  // Fetch the Data
  useEffect(() => {
    if (editMode) {
      fetchCampaign({ campaignId })
    }
  }, [campaignId, editMode, fetchCampaign])

  useEffect(() => {
    if (editMode) {
      setName(campaign?.name)
      setColor(campaign?.color)
    }
  }, [campaign, editMode])

  useEffect(() => {
    if (inputElement.current) {
      // @ts-expect-error TS(2339) FIXME: Property 'focus' does not exist on type 'never'.
      inputElement.current.focus()
    }
  }, [])

  useEffect(() => {
    let hasError = false
    let errorMessage = ''

    if (campaignName && !isUniqueName(campaignName)) {
      hasError = true
      errorMessage = t('campaigns.campaignForm.errorText')
    } else if (campaignName && campaignName.length > 100) {
      hasError = true
      errorMessage = t('campaigns.campaignForm.validationErrorText')
    }

    if (editMode && campaignName && campaignName === campaign?.name) {
      hasError = false
      errorMessage = ''
    }

    setShowError(hasError)
    setErrorMessage(errorMessage)
  }, [campaignName, campaign?.name, editMode])

  if (pathname.includes('campaigns')) {
    return (
      <Redirect to={editMode ? `/tags/${campaignId}/edit` : tagCreate.route} />
    )
  }

  if (editMode && campaign && campaign.locked) {
    return <Redirect to={tagsPage.route} />
  }

  // State modifiers
  type DisableCampaignSubmitButtonArgs = { name: string; color: string }
  const disableCampaignSubmitButton = ({
    name,
    color,
  }: DisableCampaignSubmitButtonArgs): void => {
    const isSaveButtonDisabled = !name.trim() || !color.trim()
    disableSubmit(isSaveButtonDisabled)
  }

  const campaignsListNamesLowercase = campaignsListNames.map((name) =>
    name.toLowerCase(),
  )

  const isUniqueName = (name: string): boolean => {
    return !campaignsListNamesLowercase.includes(name.toLowerCase())
  }

  const setCampaignName = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const { value } = event.target
    disableCampaignSubmitButton({ name: value, color: colorSelected })
    setName(value)
  }

  const setCampaignColor = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const { value } = event.target
    disableCampaignSubmitButton({ name: campaignName, color: value })
    setColor(value)
  }

  const isCampaignInfoUnchanged = (): boolean => {
    return (
      editMode &&
      campaignName === campaign?.name &&
      colorSelected === campaign?.color
    )
  }

  const getEditTitle = (): string => t('campaigns.campaignForm.editTagTitle')

  const getCreateTitle = (): string =>
    t('campaigns.campaignForm.createTagTitle')

  return (
    <Wrapper>
      <Main id="main">
        <Card>
          <Headline type="h2">
            {editMode ? getEditTitle() : getCreateTitle()}
          </Headline>
          <Input
            // @ts-expect-error TS(2322) FIXME: Type '{ id: string; type: string; value: string; o... Remove this comment to see the full error message
            id="campaignName"
            type="text"
            value={campaignName || ''}
            onChange={setCampaignName}
            required
            name={t('campaigns.campaignForm.name')}
            label={t('campaigns.campaignForm.name')}
            placeholder={t('campaigns.campaignForm.tagPlaceholder')}
            ref={inputElement}
            hasError={showError}
            help={errorMessage}
          />
          <ColorLabel htmlFor="colorPicker" type="label" addMargin={showError}>
            {t('campaigns.campaignForm.color')}
          </ColorLabel>
          <SimpleColorPicker
            id="colorPicker"
            colors={colors}
            colorSelected={colorSelected}
            onColorClick={setCampaignColor}
          />
          {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; size: string; label: any; on... Remove this comment to see the full error message */}
          <Button
            type="primary"
            size="large"
            label={t('campaigns.campaignForm.saveTag')}
            onClick={(): void =>
              onCreateOrUpdateCampaignClick({
                campaignId,
                colorSelected,
                campaignName,
                editMode,
                orgId: campaign?.globalOrganizationId,
              })
            }
            disabled={
              isSubmitButtonDisabled ||
              isLoading ||
              showError ||
              isCampaignInfoUnchanged()
            }
            fullWidth
          />
          {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; size: string; label: any; on... Remove this comment to see the full error message */}
          <Button
            type="text"
            size="large"
            label={t('campaigns.campaignForm.cancel')}
            onClick={onCancelClick}
            fullWidth
          />
        </Card>
        <NoticeCard>
          <View color={grayDark} />
          <Notice>
            <NoticeText type="p" color={grayDark}>
              {t('campaigns.campaignForm.tagNotice1')}
              <Link
                href={`https://support.buffer.com/article/585-creating-and-managing-campaigns?${HC_UTM_PARAMS}`}
                newTab
              >
                {t('campaigns.campaignForm.notice2')}
              </Link>
              {t('campaigns.campaignForm.notice3')}
            </NoticeText>
          </Notice>
        </NoticeCard>
      </Main>
    </Wrapper>
  )
}

export default CampaignForm
