import React, { useCallback } from 'react'
import { Button } from '@bufferapp/ui'
import FlashIcon from '@bufferapp/ui/Icon/Icons/Flash'

import type { PublishingPost, Service } from '~publish/gql/graphql'
import { PostEntity } from '../../../../post/PostEntity'
import { usePostPermissions } from '../../../../post/hooks'

import { PostPreviewFooter } from '../Footer/styles'
import { EditDeleteActions } from './EditDeleteActions'
import { PostTagsSelector } from '~publish/legacy/shared-components/PostFooter/PostTagsSelector'

import { SentFooterWrapper } from './styles'
import { useShowThreadsUpgrade } from '~publish/legacy/utils/canQueueMoreThreadsForAllProfiles'
import { SentPostActions } from './SentPostActions'
import type { RpcUpdate } from '~publish/legacy/post/types'
import { usePostComposer } from '~publish/hooks/usePostComposer'
import callRpc from '~publish/legacy/utils/call-rpc'
import { CopyPlusIcon, IconButton, toast, Tooltip } from '@buffer-mono/popcorn'
import { omitFields } from '~publish/helpers/object'

const omittedFields: (keyof RpcUpdate)[] = [
  'id',
  'due_at',
  'day',
  'scheduled_at',
  'scheduledAt',
  'serviceUpdateId',
  'createdAt',
  'statistics',
  'status',
  'serviceLink',
]

type OmittedField = (typeof omittedFields)[number]

type RpcUpdateWithoutOmittedFields = Omit<RpcUpdate, OmittedField>

const omitDuplicatedFields = (
  post: RpcUpdate,
): RpcUpdateWithoutOmittedFields => {
  const newPost = omitFields(post, omittedFields)

  return {
    ...newPost,
    isSent: false,
    isPastDue: false,
    isDraft: false,
  }
}

const CHANNELS_DISALLOW_DUPLICATION_WITH_MEDIA: Service[] = [
  'facebook',
  'instagram',
  'tiktok',
]

const DuplicateIconMenu = ({
  post,
}: {
  post: RpcUpdate | PublishingPost
}): JSX.Element => {
  const { duplicatePostInComposer } = usePostComposer()
  const cta = 'publish-calendar-postCard-duplicatePost-1'
  const { via, media } = post

  let channel: Service | undefined
  if (PostEntity.isPublishingPost(post)) {
    channel = post.channel.service as Service
  } else {
    channel = post.profile_service as Service
  }

  const isDuplicateUnavailable =
    channel &&
    CHANNELS_DISALLOW_DUPLICATION_WITH_MEDIA.includes(channel) &&
    via?.toLowerCase() === channel &&
    media &&
    Object.keys(media).length > 0

  const handleDuplicatePost = useCallback(async () => {
    try {
      const rpcPost = await callRpc('getPost', { updateId: post.id })
      const prefillPostData = omitDuplicatedFields(rpcPost) as RpcUpdate
      duplicatePostInComposer({
        cta,
        channels: [rpcPost.profileId],
        prefillPostData,
        duplicatedFrom: post.id,
      })
    } catch (err: unknown) {
      const error = err as Error
      toast.error(`Failed to duplicate post: ${error.message}`)
    }
  }, [duplicatePostInComposer, post])

  if (isDuplicateUnavailable) {
    return (
      <Tooltip
        content="This channel currently does not support duplicating media files. We're working on a solution!"
        side="right"
        sideOffset={4}
      >
        {/* HACK: `div` is necessary for tooltip to work, this is not ideal and we should look into a different implementation of disabled menu items */}
        <IconButton label="Duplicate" onClick={handleDuplicatePost} disabled>
          <CopyPlusIcon />
        </IconButton>
      </Tooltip>
    )
  }

  return (
    <IconButton
      label="Duplicate"
      tooltip="Duplicate post"
      onClick={handleDuplicatePost}
    >
      <CopyPlusIcon />
    </IconButton>
  )
}

export const PostPreviewActions = ({
  post,
  setOpenModal,
}: {
  post: PublishingPost
  setOpenModal: (openModal: {
    open: boolean
    ctaString: string
    service: string
  }) => void
}): JSX.Element | null => {
  const isSent = PostEntity.isSent(post)
  const { canEditPost } = usePostPermissions(post)
  const { serviceLink } = post

  const {
    shouldShowThreadsMigrationModal,
    service: threadsMigrationModalService,
  } = useShowThreadsUpgrade(post)

  if (!canEditPost && !isSent) return null

  const onUpgradeToScheduleThreadClick = (): void => {
    setOpenModal({
      open: true,
      ctaString: 'calendarSent-upgradeButton-upgradeToScheduleThread-1',
      service: threadsMigrationModalService,
    })
  }

  return (
    <PostPreviewFooter alignContentToRight>
      {canEditPost && (
        <EditDeleteActions post={post} setOpenModal={setOpenModal} />
      )}
      {isSent && (
        <SentFooterWrapper>
          {serviceLink && (
            // @ts-expect-error TS(2740) FIXME: Type '{ "data-testid": string; type: string; size:... Remove this comment to see the full error message
            <Button
              type="text"
              size="small"
              label="View Post"
              onClick={(): void => {
                window.open(serviceLink, '_blank')
              }}
            />
          )}
          <PostTagsSelector postId={post.id} saveOnClose={true} />

          {shouldShowThreadsMigrationModal && (
            // @ts-expect-error TS(2740) FIXME: Type '{ "data-testid": string; type: string; size:... Remove this comment to see the full error message
            <Button
              data-testid="threads-free-paywall-button"
              type="primary"
              size="small"
              icon={<FlashIcon color="white" />}
              label="Upgrade to Schedule Thread"
              onClick={onUpgradeToScheduleThreadClick}
            />
          )}

          <SentPostActions post={post} />
          <DuplicateIconMenu post={post} />
        </SentFooterWrapper>
      )}
    </PostPreviewFooter>
  )
}
