import type { PlateEditor, TDescendant, TElement } from '@udecode/plate'
import type { ParagraphElement } from '~publish/legacy/editor/plugins/paragraph/nodes/ParagraphElement'

// Recursively unset a property from nodes
const unsetPropertyRecursively = (
  node: TElement | TDescendant,
  property: string,
): void => {
  if (node[property]) {
    // Unset the property from the current node
    delete node[property]
  }

  // Recursively process child nodes if present
  if (node.children && Object.keys(node.children).length > 0) {
    for (const childNode of node.children as ParagraphElement[]) {
      unsetPropertyRecursively(childNode, property)
    }
  }
}

// Function to unset the property within the entire editor content
const unsetPropertyInEditor = (editor: PlateEditor, property: string): void => {
  for (const node of editor.children) {
    unsetPropertyRecursively(node, property)
  }
}

export default unsetPropertyInEditor
