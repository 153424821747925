import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const StyledImageLabel = styled.div`
  position: absolute;
  left: ${({
    // @ts-expect-error TS(2339) FIXME: Property 'tag' does not exist on type 'Omit<Detail... Remove this comment to see the full error message
    tag,
  }) => `${tag.clientX}%`};
  top: ${({
    // @ts-expect-error TS(2339) FIXME: Property 'tag' does not exist on type 'Omit<Detail... Remove this comment to see the full error message
    tag,
  }) => `${tag.clientY}%`};
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  padding: 2px 8px;
  display: ${({
    // @ts-expect-error TS(2339) FIXME: Property 'showTags' does not exist on type 'Omit<D... Remove this comment to see the full error message
    showTags,
  }) => (showTags ? 'block' : 'none')};
  transform: translateX(-50%);
`

// @ts-expect-error TS(7031) FIXME: Binding element 'tag' implicitly has an 'any' type... Remove this comment to see the full error message
const ImageLabel = ({ tag, showTags }) => (
  // @ts-expect-error TS(2769) FIXME: No overload matches this call.
  <StyledImageLabel tag={tag} showTags={showTags}>
    {tag.username}
  </StyledImageLabel>
)

ImageLabel.propTypes = {
  tag: PropTypes.shape({
    username: PropTypes.string,
    x: PropTypes.string,
    y: PropTypes.string,
  }).isRequired,
  showTags: PropTypes.bool.isRequired,
}

export default ImageLabel
