import React from 'react'
import styled from 'styled-components'
import { blueprints } from '../../blueprints'
import { Blueprint } from '../Blueprint'

const ListStyled = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
`

const BlueprintWrapper = styled.div`
  flex-basis: 49%;
  padding: 16px 0;
`

export const List: React.FC<{
  setBlueprintNotice: (notice: string) => void
}> = ({ setBlueprintNotice }) => {
  return (
    <ListStyled id="blueprints_list">
      {blueprints.map((blueprint) => (
        <BlueprintWrapper key={blueprint.id}>
          <Blueprint
            blueprint={blueprint}
            setBlueprintNotice={setBlueprintNotice}
          />
        </BlueprintWrapper>
      ))}
    </ListStyled>
  )
}
