import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  padding: 8px;
  border-radius: 100%;
  border: 1px dashed var(--Gray-Dark, #636363);
  color: #636363;
  transition: all 0.1s;
`

export const Label = styled.div`
  color: var(--Gray-Dark, #636363);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 114.286% */
`

export const ConnectableChannelButton = styled.a<{ hoverIconColor: string }>`
  display: flex;
  text-decoration: none;
  align-items: center;
  padding: 8px 0px 8px 8px;
  border: none;
  border-radius: 4px;
  background: none;
  margin-bottom: 8px;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-bottom: 4px;

  &:hover {
    background: var(--Gray-Lighter, #f5f5f5);
    ${Label} {
      color: #3d3d3d;
    }
    ${IconWrapper} {
      color: #fff;
      background: ${({ hoverIconColor }): string => hoverIconColor};
      border: 1px solid transparent;
    }
  }
`

export const ShowAllChannelsButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border: 1px solid var(--Gray-Light, #e0e0e0);
  border-radius: 4px;
  background: none;
  cursor: pointer;
  transition: background-color 0.2s;

  color: var(--Gray-Darker, #3d3d3d);

  font-size: 13px;
  font-style: normal;
  font-weight: 500;

  svg {
    margin-right: 16px;
  }

  &:hover {
    background: var(--Gray-Lighter, #f5f5f5);
  }
`
