import { type Idea, type Media, MediaType } from '~publish/pages/Create/types'
import type { DraftIdea } from './IdeaComposer'

export type PostMediaValidity = {
  valid: boolean
  message: string | null
}

export const hasMultipleVideos = (media?: Media[]): boolean => {
  let videoCount = 0
  media?.forEach((media) => {
    if (media.type === MediaType.video) {
      videoCount += 1
    }
  })
  return videoCount > 1
}

export const hasMixedMediaTypes = (media?: Media[]): boolean => {
  let hasMixedMedia = false
  let lastMediaType: MediaType | null = null
  media?.forEach((media) => {
    if (lastMediaType && media.type !== lastMediaType) {
      hasMixedMedia = true
      return
    }
    lastMediaType = media.type
  })
  return hasMixedMedia
}

export const isMediaConvertibleToPost = (
  media?: Media[],
): PostMediaValidity => {
  if (hasMultipleVideos(media)) {
    return {
      valid: false,
      message:
        'Multiple videos aren’t supported on posts at this time. Remove video(s) to create a post',
    }
  }

  if (hasMixedMediaTypes(media)) {
    return {
      valid: false,
      message:
        'Multiple media types aren’t supported on posts at this time. Remove a media type to create a post',
    }
  }
  return {
    valid: true,
    message: null,
  }
}

export function isExistingIdea(idea: DraftIdea | Idea): idea is Idea {
  return 'id' in idea
}
