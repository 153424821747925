import { actionTypes as queueActionTypes } from '~publish/legacy/queue/actionTypes'
import { actionTypes as draftActionTypes } from '~publish/legacy/drafts/reducer'
import keyWrapper from '~publish/helpers/keywrapper'

export const actionTypes = keyWrapper('CALENDAR', {
  SHOULD_REFETCH: 0,
  CLEAR_REFETCH: 0,
  UPDATE_FILTERS: 0,
})

export const initialState = {
  shouldRefetch: false,
  filters: [],
}

// @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_FILTERS: {
      return { filters: action.filters }
    }
    case actionTypes.CLEAR_REFETCH: {
      return { shouldRefetch: false }
    }
    case actionTypes.SHOULD_REFETCH:
    case draftActionTypes.DRAFT_CREATED:
    case draftActionTypes.DRAFT_UPDATED:
    case draftActionTypes.DRAFT_DELETED:
    case draftActionTypes.DRAFT_APPROVED:
    case draftActionTypes.DRAFT_MOVED:
    case queueActionTypes.POST_DELETED:
    case queueActionTypes.POST_UPDATED:
    case queueActionTypes.POST_SENT:
    case queueActionTypes.POST_CREATED: {
      return { shouldRefetch: true }
    }
    default:
      return state
  }
}

export const actions = {
  shouldRefetch: () => ({
    type: actionTypes.SHOULD_REFETCH,
  }),
  clearShouldRefetch: () => ({
    type: actionTypes.CLEAR_REFETCH,
  }),
  // @ts-expect-error TS(7031) FIXME: Binding element 'filters' implicitly has an 'any' ... Remove this comment to see the full error message
  updateFilters: ({ filters }) => ({
    type: actionTypes.UPDATE_FILTERS,
    filters,
  }),
}
