import type { RootState } from '~publish/legacy/store'

export const selectShowComposer = (state: RootState): boolean =>
  state?.composerPopover?.showComposer

export const selectEditMode = (state: RootState): boolean | undefined =>
  state?.composerPopover?.editMode

export const selectSplits = (state: RootState): Record<string, boolean> =>
  state?.composerPopover?.splits
