import {
  findNodePath,
  type PlateRenderNodeProps,
  type TRenderElementProps,
} from '@udecode/plate'
import React, { useCallback, useMemo } from 'react'
import { useFocused, useSelected } from 'slate-react'
import type {
  BufferEditor,
  BufferValue,
} from '~publish/legacy/editor/BufferEditor/types.plate'
import {
  EditorTooltip,
  TooltipButton,
  TooltipText,
} from '~publish/legacy/editor/components/EditorTooltip'
import { VoidText } from '~publish/legacy/editor/styles'
import { locationInCharacterLimitRange } from '../../character-limit-highlight'
import type { LinkedInAnnotationElement } from '../nodes/LinkedInAnnotationElement'
import { Wrapper } from './styles'

type LinkedInAnnotationUIProps = PlateRenderNodeProps<
  BufferValue,
  BufferEditor
> &
  TRenderElementProps<BufferValue, LinkedInAnnotationElement>

export const LinkedInAnnotationUI = ({
  attributes,
  children,
  element,
  editor,
}: LinkedInAnnotationUIProps) => {
  const selected = useSelected()
  const focused = useFocused()

  const { link, localizedName } = element

  const onOpenClick = useCallback(() => {
    window.open(link, '_blank')
  }, [link])

  const path = findNodePath(editor, element)
  const isPastCharacterLimit = useMemo(
    () => locationInCharacterLimitRange(editor, path),
    [editor, path],
  )

  return (
    <Wrapper
      id={`${element.id}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...attributes}
      contentEditable={false}
      $selected={selected}
      $focused={focused}
    >
      {children}
      <EditorTooltip
        trigger={
          <VoidText $isPastCharacterLimit={isPastCharacterLimit}>
            {localizedName}
          </VoidText>
        }
      >
        <TooltipText title={link}>{link}</TooltipText>
        <TooltipButton onClick={onOpenClick}>Open</TooltipButton>
      </EditorTooltip>
    </Wrapper>
  )
}
