import { useState, useEffect } from 'react'
import { gql, useLazyQuery } from '@apollo/client'
import { useAccount } from '~publish/legacy/accountContext'

export const QUERY_IDEAS_COUNT_BY_TAGS = gql`
  query ideasCountByTags($input: OrganizationIdInput!) {
    ideasCountByTags(input: $input) {
      ... on IdeasCountByTags {
        counts {
          tagId
          count
        }
      }
      ... on CoreWebAppCommonError {
        code
        error
      }
    }
  }
`

type IdeasCountByTagsEntity = {
  tagId: string
  count: number
}

type QueryResponse = {
  ideasCountByTags: {
    counts: Array<IdeasCountByTagsEntity>
  }
}

type useIdeasCountByTagsReturn = {
  counts: Array<IdeasCountByTagsEntity>
}

export const useIdeasCountByTags = (): useIdeasCountByTagsReturn => {
  const [counts, setCounts] = useState<Array<IdeasCountByTagsEntity>>([])
  const { account } = useAccount()
  const organizationId = account?.currentOrganization?.id

  const onFetchComplete = (res: QueryResponse): void => {
    if (res.ideasCountByTags?.counts) {
      setCounts(res.ideasCountByTags.counts)
    }
  }

  const [fetchIdeasCount] = useLazyQuery(QUERY_IDEAS_COUNT_BY_TAGS, {
    variables: { input: { organizationId } },
    onCompleted: onFetchComplete,
  })

  useEffect(() => {
    fetchIdeasCount()
  }, [])

  return { counts }
}
