import { createAsyncThunk } from '@reduxjs/toolkit'
import type { RootState } from '~publish/legacy/store'
import callRpc from '~publish/legacy/utils/call-rpc'

export const toggleGoogleAnalyticsTrackingSettings = createAsyncThunk<
  boolean,
  {
    profileId: string
    utmTrackingChoice: string
  },
  { state: RootState }
>(
  'generalSettings/toggleGoogleAnalyticsTrackingSettings',
  async ({ profileId, utmTrackingChoice }) => {
    const response = await callRpc('toggleGoogleAnalytics', {
      profileId,
      utmTrackingChoice,
    })
    if (!response.isEnabled) {
      throw new Error('No values found in the response')
    }
    return response
  },
)
