import styled from 'styled-components'
import { Textarea, Input as PopcornInput } from '@buffer-mono/popcorn'
import { gray, grayDarker, grayLight } from '@bufferapp/ui/style/colors'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
  padding: 0 16px;

  @keyframes slideUpAndFadeIn {
    from {
      opacity: 0;
      transform: translateY(12px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  animation: slideUpAndFadeIn 0.3s ease;
`

export const LabelWrapper = styled.div`
  flex: 0 1 115px;
`

export const Label = styled.label`
  color: ${grayDarker};
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  display: flex;
  justify-content: space-between;
  min-width: 130px;
`

export const InputWrapper = styled.div`
  flex: 1 1 auto;
  position: relative;
`

export const Input = styled(PopcornInput)`
  border: 1px solid ${grayLight};
  font-size: 12px;
  height: 32px;

  &::placeholder {
    color: ${gray};
  }
`

export const TextAreaInput = styled(Textarea)`
  max-height: 144px;
  padding-right: 15px;
  font-size: 12px;

  border: 1px solid ${grayLight};

  &::placeholder {
    color: ${gray};
  }
`
