import type { PointerEvent } from 'react'
import {
  type DroppableContainer,
  KeyboardCode,
  type KeyboardCoordinateGetter,
  KeyboardSensor,
  TouchSensor,
  type UniqueIdentifier,
  useSensor,
  useSensors,
  PointerSensor,
} from '@dnd-kit/core'

import { sortableKeyboardCoordinates } from '@dnd-kit/sortable'

export function useCustomSensors(): ReturnType<typeof useSensors> {
  const mouseSensor = useSensor(SmartPointerSensor, {
    // Require the mouse to move by 4 pixels before activating
    activationConstraint: { distance: 4 },
  })
  const touchSensor = useSensor(TouchSensor, {
    // Press delay of 250ms, with tolerance of 5px of movement
    activationConstraint: { delay: 250, tolerance: 5 },
  })
  const keyboardSensor = useSensor(KeyboardSensor, {
    coordinateGetter: customSortableKeyboardCoordinates,
  })

  return useSensors(mouseSensor, touchSensor, keyboardSensor)
}

type Identifier = UniqueIdentifier | null | undefined

// This class comes from the dnd-kit library, but it's not exported.
// https://github.com/clauderic/dnd-kit/blob/694dcc2f62e5269541fc941fa6c9af46ccd682ad/packages/core/src/store/constructors.ts#L6
class DroppableContainersMap extends Map<UniqueIdentifier, DroppableContainer> {
  get(id: Identifier): DroppableContainer | undefined {
    return id != null ? super.get(id) ?? undefined : undefined
  }

  toArray(): DroppableContainer[] {
    return Array.from(this.values())
  }

  getEnabled(): DroppableContainer[] {
    return this.toArray().filter(({ disabled }) => !disabled)
  }

  getNodeFor(id: Identifier): HTMLElement | undefined {
    return this.get(id)?.node.current ?? undefined
  }
}

const directions: string[] = [
  KeyboardCode.Down,
  KeyboardCode.Right,
  KeyboardCode.Up,
  KeyboardCode.Left,
]

// Extend the original sortableKeyboardCoordinates function to filter the
// droppable containers to ignore non empty containers when the active item is
// not a container. So when the active item is a "card", for non empty
// containers, we only consider the its children as droppable targets.
const customSortableKeyboardCoordinates: KeyboardCoordinateGetter = (
  event,
  args,
) => {
  const { active, collisionRect, droppableContainers, ...restContext } =
    args.context

  if (!directions.includes(event.code)) return

  event.preventDefault()

  if (!active || !collisionRect) {
    return
  }

  const filteredDroppableContainers = new DroppableContainersMap(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    Array.from(droppableContainers.entries()).filter(([_, container]) => {
      return !(
        container.data.current?.type === 'container' &&
        container.data.current.children?.length > 0 &&
        active.data.current?.type !== 'container'
      )
    }),
  )

  return sortableKeyboardCoordinates(event, {
    ...args,
    context: {
      active,
      collisionRect,
      ...restContext,
      droppableContainers: filteredDroppableContainers,
    },
  })
}

/**
 * An extended "PointerSensor" that prevent some
 * interactive html element(button, input, textarea, select, option...) from dragging
 */
export class SmartPointerSensor extends PointerSensor {
  static activators = [
    {
      eventName: 'onPointerDown' as const,
      handler: ({ nativeEvent: event }: PointerEvent): boolean => {
        if (
          !event.isPrimary ||
          event.button !== 0 ||
          isDraggingDisabled(event.target as HTMLElement) ||
          isInteractiveElement(event.target as HTMLElement)
        ) {
          return false
        }

        return true
      },
    },
  ]
}

function isDraggingDisabled(element: HTMLElement | null): boolean {
  return !!element?.closest('[data-no-dnd]')
}

const interactiveElements = new Set([
  'a',
  'button',
  'input',
  'textarea',
  'select',
  'option',
])

const interactiveRoles = new Set([
  'button',
  'checkbox',
  'link',
  'menuitem',
  'menuitemcheckbox',
  'menuitemradio',
  'option',
  'radio',
  'slider',
  'switch',
  'tab',
  'textbox',
])

function isInteractiveElement(element: HTMLElement | null): boolean {
  if (!element) return false

  function checkElement(el: HTMLElement): boolean {
    if (el.hasAttribute('data-dnd')) {
      return false // Allow drag-and-drop for elements with data-dnd attribute
    }

    // Check tag name first (fastest check)
    if (interactiveElements.has(el.tagName.toLowerCase())) {
      return true
    }

    // Check tabindex next (relatively fast)
    const tabindex = el.getAttribute('tabindex')
    if (tabindex !== null && tabindex !== '-1') {
      return true
    }

    // Check role last (slowest check)
    const role = el.getAttribute('role')
    return role ? interactiveRoles.has(role.toLowerCase()) : false
  }

  // Use a do-while loop to check the element and its parents
  let currentElement: HTMLElement | null = element
  do {
    if (checkElement(currentElement)) {
      return true
    }
    currentElement = currentElement.parentElement
  } while (currentElement)

  return false
}
