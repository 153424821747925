import React from 'react'
import { Flash } from '@bufferapp/ui/Icon'
import { purple } from '@bufferapp/ui/style/colors'

import { TagWrapper, Tag } from './style'

const UpgradeIconCTA = (): JSX.Element => (
  <TagWrapper data-testid="upgrade-icon-cta">
    <Tag>
      <Flash color={purple} size="smedium" />
    </Tag>
  </TagWrapper>
)

export default UpgradeIconCTA
