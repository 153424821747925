import { createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from '@buffer-mono/popcorn'

import callRpc from '~publish/legacy/utils/call-rpc'
import type { AppDispatch, RootState } from '~publish/legacy/store'

export const deleteGroup = createAsyncThunk<
  boolean,
  { groupId: string },
  { state: RootState; dispatch: AppDispatch }
>('channelGroups/deleteGroup', async ({ groupId }, { rejectWithValue }) => {
  try {
    const response = await callRpc('deleteProfileGroup', { groupId })

    if (!response.success) {
      throw new Error(response.message)
    }

    toast.success('Channel group deleted successfully')

    return true
  } catch (error) {
    const message = 'Error deleting channel group'
    toast.error(message)

    return rejectWithValue(message)
  }
})
