import React from 'react'
import { Text, Button } from '@bufferapp/ui'
import RefreshIcon from '@bufferapp/ui/Icon/Icons/Refresh'
import style from './style'

const BoundaryFallback = (): JSX.Element => {
  const handleRefresh = (): void => {
    location.reload()
  }

  return (
    <div style={style.errorBoundary as React.CSSProperties}>
      <Text type="h2">Well this is embarrassing...</Text>
      <div style={style.errorBoundaryMessage}>
        Something’s gone wrong. I’ve notified my human
        <br />
        creators who will fix this up shortly.
      </div>
      {/* @ts-expect-error */}
      <Button
        type="secondary"
        label="Refresh"
        icon={<RefreshIcon />}
        onClick={handleRefresh}
      />
    </div>
  )
}

export default BoundaryFallback
