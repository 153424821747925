import { useMemo } from 'react'
import {
  addHours,
  addDays,
  differenceInCalendarDays,
  endOfDay,
  startOfDay,
  startOfHour,
  endOfHour,
} from 'date-fns'
import { toZonedTime, fromZonedTime } from 'date-fns-tz'

export type Interval = {
  /** ISO formatted Date string */
  startTimestamp: string
  /** ISO formatted Date string */
  endTimestamp: string
}

export type DayInterval = Interval & {
  hours: Interval[]
}

export type IntervalsResponse = {
  days: DayInterval[]
}

/**
 * Returns a list of days, each with nested hours, containing start and end timestamps within the provided date range.
 *
 * @param {string} startDate - The start date in ISO format.
 * @param {string} endDate - The end date in ISO format.
 * @param {string} timezone - The timezone string (e.g., 'America/New_York').
 * @returns {Object} - Object containing list of days with nested hours, each having start and end timestamps.
 */
export const generatePostIntervals = (
  startDate: string,
  endDate: string,
  timezone: string,
): IntervalsResponse => {
  const startDateObj = new Date(startDate)
  const endDateObj = new Date(endDate)

  // Convert dates to appropriate timezone to cater for daylight savings
  const zonedStartDate = toZonedTime(startDateObj, timezone) // note utc diff
  const zonedEndDate = toZonedTime(endDateObj, timezone)

  const numberOfDays =
    differenceInCalendarDays(zonedEndDate, zonedStartDate) + 1

  if (numberOfDays <= 0) return { days: [] }

  const days = [...Array(numberOfDays)].map((_, index) => {
    const day = addDays(zonedStartDate, index)
    const dayStartTimestamp = fromZonedTime(
      startOfDay(day),
      timezone,
    ).toISOString()
    const dayEndTimestamp = fromZonedTime(endOfDay(day), timezone).toISOString()

    // Generate hours for the current day
    const hours: Interval[] = []
    let currentHour = startOfDay(day)
    const endHour = endOfDay(day)
    while (currentHour < endHour) {
      const hourStartTimestamp = fromZonedTime(
        startOfHour(currentHour),
        timezone,
      ).toISOString()
      const hourEndTimestamp = fromZonedTime(
        endOfHour(currentHour),
        timezone,
      ).toISOString()
      hours.push({
        startTimestamp: hourStartTimestamp,
        endTimestamp: hourEndTimestamp,
      })
      currentHour = addHours(currentHour, 1)
    }

    return {
      startTimestamp: dayStartTimestamp,
      endTimestamp: dayEndTimestamp,
      hours,
    }
  })

  return { days }
}

export const usePostIntervals = (
  startDate: string,
  endDate: string,
  timezone: string,
): IntervalsResponse => {
  return useMemo(
    () => generatePostIntervals(startDate, endDate, timezone),
    [startDate, endDate, timezone],
  )
}

interface FilterablePost {
  dueAt?: string | null | undefined
}

export const filterPostsWithInterval = <Post extends FilterablePost>(
  interval: Interval,
  posts: (Post | null | undefined)[] | null | undefined,
): Post[] => {
  if (!posts) {
    return []
  }
  const nonNullPosts = posts.filter(
    (post) => post !== null && post !== undefined,
  ) as Post[]
  return nonNullPosts.filter((post) => {
    if (!post.dueAt) {
      return false
    }
    const postDueAt = new Date(post.dueAt).getTime()
    return (
      postDueAt &&
      postDueAt >= new Date(interval.startTimestamp).getTime() &&
      postDueAt <= new Date(interval.endTimestamp).getTime()
    )
  })
}

export const useFilteredPostsWithInterval = <Post extends FilterablePost>(
  interval: Interval,
  posts: Post[],
): Post[] => {
  return useMemo(
    () => filterPostsWithInterval(interval, posts),
    [interval, posts],
  )
}
