import styled, { css } from 'styled-components'
import * as Select from '@radix-ui/react-select'

import {
  grayLight,
  gray,
  white,
  grayDark,
  grayLighter,
} from '@bufferapp/ui/style/colors'

export const SelectTrigger = styled(Select.Trigger)<{
  disabled: boolean | undefined
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${white};
  border: none;
  cursor: pointer;
  border-radius: 6px;
  transition: background 0.3s ease;
  font-weight: 500;
  color: ${grayDark};

  &:hover {
    background: ${grayLighter};
    transition: background 0.3s ease;
  }

  .icon {
    margin-right: 4px;
  }

  .chevron {
    margin-left: 8px;
    fill: ${grayDark};
  }

  ${(props) =>
    props.disabled &&
    css`
      background: ${grayLighter};
      cursor: not-allowed;

      &:hover {
        background: ${grayLighter};
      }

      .icon,
      .chevron {
        fill: ${gray};
      }
    `}
`

export const SelectContent = styled(Select.Content)`
  z-index: 3000;
  background: ${white};
  padding: 8px;
  width: 320px;
  border: 1px solid ${grayLight};
  border-radius: 6px;
  font-size: 14px;
  animation: fadeInAndSlideUp 0.3s ease;

  @keyframes fadeInAndSlideUp {
    from {
      opacity: 0;
      transform: translateY(4px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`

export const SelectOption = styled(Select.Item)<{ disabled?: boolean }>`
  cursor: pointer;
  padding: 12px 8px;
  border-radius: 4px;
  background: ${white};
  transition: background 0.3s ease;

  &:focus {
    /* Removes default focus styling in favor of focus styling below */
    outline: none;
  }

  &:hover,
  &:focus {
    background: ${grayLighter};
    transition: background 0.3s ease;
  }

  .item-indicator {
    min-width: 24px;
  }

  ${(props) =>
    props.disabled &&
    css`
      background: ${white};
      color: ${gray};
      cursor: not-allowed;
      user-select: none;

      &:hover {
        background: ${white};
      }

      span.description {
        color: ${gray};
      }

      svg {
        fill: ${gray};
      }
    `}
`

export const SelectOptionContentWrapper = styled.div`
  display: flex;
`

export const SelectOptionIcon = styled.div<{
  selected: boolean
  color: string
}>`
  display: flex;
  margin-right: 8px;

  ${({ selected, color }) =>
    selected &&
    css`
      svg {
        fill: ${color};
      }
    `}
`

export const SelectOptionText = styled.div`
  display: flex;
  flex-direction: column;

  .option-title {
    font-weight: 500;
    color: ${grayDark};
  }

  .description {
    color: ${grayDark};
    font-size: 12px;
    font-style: normal;
    margin-top: 4px;
  }
`

export const SelectOptionIndicator = styled.div`
  width: 24px;
`
