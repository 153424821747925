import styled from 'styled-components'
import RetweetIcon from '@bufferapp/ui/Icon/Icons/Retweet'
import ThumbsupIcon from '@bufferapp/ui/Icon/Icons/Thumbsup'
import MessageSquareOutlineIcon from '@bufferapp/ui/Icon/Icons/MessageSquareOutline'
import Avatar from '@bufferapp/ui/Avatar'

import { LinkifiedText as LinkifiedTextCommon } from '~publish/legacy/shared-components/LinkifiedText'
import { ImagesGrid as ImagesGridCommon } from '../../../Common/ImagesGrid'
import { LinkPreview as LinkPreviewCommon } from '../../../Common/LinkPreview'

import {
  previewBlack,
  previewGrayDark,
  previewGrayLight,
  previewGrayMedium,
  grayMedium,
  previewBlue,
  previewGrayLighter,
} from '../../../Common/styles'

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 16px 8px 16px;
`

export const UsernameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 12px;
`

export const Username = styled.span`
  display: inline-block;
  color: ${previewBlack};
  max-width: 185px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
`

export const FormattedUsername = styled.span`
  font-size: 12px;
  display: flex;
  align-items: center;
  color: ${previewGrayMedium};
  font-weight: 400;
  max-width: 185px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  svg {
    height: 10px;
    width: 10px;
    margin-left: 4px;
  }
`

export const Body = styled.div`
  margin: 0 16px;
`

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 26px;
`

export const Content = styled.div`
  img {
    width: 100%;
    border-radius: 12px;
  }

  span {
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 20px !important;
    color: ${previewBlack} !important;
    white-space: pre-wrap !important;
    overflow-wrap: break-word !important;
    hyphens: manual !important;
  }

  p {
    margin-top: 0;
  }
`

export const Separator = styled.div`
  width: calc(100% - 16px);
  background-color: ${previewGrayLight};
  height: 1px;
  margin-left: 8px;
  margin-top: 8px;
`

export const Action = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${previewGrayDark};
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;

  svg {
    margin-bottom: 8px;
  }
`

export const ReshareIcon = styled(RetweetIcon)`
  rotate: 90deg;
`

export const MessageIcon = styled(MessageSquareOutlineIcon)`
  transform: scaleX(-1);
`

export const LikeIcon = styled(ThumbsupIcon)`
  transform: scaleX(-1);
`

export const SeeMore = styled.div`
  display: inline;
  color: ${grayMedium};
`

export const AvatarIcon = styled(Avatar)`
  border-radius: 0;
  ${(props) =>
    // @ts-expect-error TS(2339) FIXME: Property 'showAsSquare' does not exist on type 'Th... Remove this comment to see the full error message
    props.showAsSquare && 'border-radius: 0'})}

  & > div[type="default"] {
    border-radius: 0;
  }
`

export const LinkifiedText = styled(LinkifiedTextCommon)`
  > a {
    color: ${previewBlue} !important;
    cursor: default !important;
  }
`

export const LinkPreview = styled(LinkPreviewCommon)`
  border: none;

  .title {
    line-height: 18px;
    margin-bottom: 12px;
  }
`

export const ImagesGrid = styled(ImagesGridCommon)`
  div.show-more {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }
`

export const FirstCommentIndicator = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: ${previewGrayDark};
  margin-top: 8px;
  margin-right: 10px;
  text-align: right;
`

export const SingleCommentSection = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px 12px 8px 12px;
`

export const CommentHeader = styled.div`
  display: flex;
  align-items: center;
`

export const CommentUsernameWrapper = styled.span`
  display: flex;
  align-items: center;
  color: ${previewBlack};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 15px;
`

export const AuthorTag = styled.span`
  color: ${previewGrayLighter};
  background: #56687a;
  margin-left: 4px;
  border-radius: 4px;
  font-size: 10px;
  line-height: 14px;
  font-weight: 600;
  padding: 0 4px;
`

export const CommentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background: ${previewGrayLighter};
  flex-grow: 1;
  margin-left: 8px;
  padding: 8px;
`

export const LinkifiedCommentText = styled(LinkifiedTextCommon)`
  margin-top: 4px;
  font-size: 13px !important;
  color: ${previewBlack} !important;

  > a {
    color: ${previewBlue} !important;
    font-size: 13px !important;
  }
`

export const SeeMoreComments = styled.div`
  font-size: 13px !important;
  display: inline;
  color: ${previewGrayDark};
`
