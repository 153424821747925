import { TwitterPreview } from './TwitterPreview'
import { MastodonPreview } from './MastodonPreview'
import { FacebookPreview } from './FacebookPreview'
import { LinkedInPreview } from './LinkedInPreview'
import { InstagramPreview } from './InstagramPreview'
import { GoogleBusinessPreview } from './GoogleBusinessPreview'
import { StartPagesPreview } from './StartPagesPreview'
import { TikTokPreview } from './TikTokPreview'
import { PinterestPreview } from './PinterestPreview'
import { YoutubePreview } from './YoutubePreview'
import { ThreadsPreview } from './ThreadsPreview'
import { BlueskyPreview } from './BlueskyPreview/BlueskyPreview'

const helpText =
  'This is an approximation of how your posts will look like live. You might see some differences across devices.'

export const PREVIEWS_MAP = {
  twitter: {
    title: 'Twitter / X Preview',
    helpText,
    Preview: TwitterPreview,
  },
  mastodon: {
    title: 'Mastodon Preview',
    helpText,
    Preview: MastodonPreview,
  },
  bluesky: {
    title: 'Bluesky Preview',
    helpText,
    Preview: BlueskyPreview,
  },
  facebook: {
    title: 'Facebook Preview',
    helpText,
    Preview: FacebookPreview,
  },
  linkedin: {
    title: 'LinkedIn Preview',
    helpText,
    Preview: LinkedInPreview,
  },
  instagram: {
    title: 'Instagram Preview',
    helpText,
    Preview: InstagramPreview,
  },
  googlebusiness: {
    title: 'Google Business Profile Preview',
    helpText,
    Preview: GoogleBusinessPreview,
  },
  startPage: {
    title: 'Start Page Preview',
    helpText,
    Preview: StartPagesPreview,
  },
  tiktok: {
    title: 'TikTok Preview',
    helpText,
    Preview: TikTokPreview,
  },
  pinterest: {
    title: 'Pinterest Preview',
    helpText,
    Preview: PinterestPreview,
  },
  youtube: {
    title: 'YouTube Short Preview',
    helpText,
    Preview: YoutubePreview,
  },
  threads: {
    title: 'Threads Preview',
    helpText,
    Preview: ThreadsPreview,
  },
}

export const PREVIEWS_SUPPORTED_SERVICES = Object.keys(PREVIEWS_MAP)
