import React, { useState } from 'react'
import PropTypes from 'prop-types'
import LinksHeader from './LinksHeader'
import EditingLinkForm from './EditingLinkForm'
import LinkDragWrapper from './LinkDragWrapper'

import {
  DEFAULT_COLOR,
  DEFAULT_CONTRAST_COLOR,
  MyLinksSection,
  MyLinksBody,
  Separator,
} from './styles'

const EditingLinkItem = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'customLinkItem' implicitly has an... Remove this comment to see the full error message
  customLinkItem,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onUpdateCustomLinks' implicitly h... Remove this comment to see the full error message
  onUpdateCustomLinks,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onCancelCustomLinkEdit' implicitl... Remove this comment to see the full error message
  onCancelCustomLinkEdit,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isValidItem' implicitly has an 'a... Remove this comment to see the full error message
  isValidItem,
}) => {
  const [linkItem, updateLinkItem] = useState(customLinkItem)

  return (
    <EditingLinkForm
      item={linkItem}
      onUpdateLinkText={({ item, value }) => {
        updateLinkItem({ ...item, text: value })
      }}
      onUpdateLinkUrl={({ item, value }) => {
        updateLinkItem({ ...item, url: value })
      }}
      onCancelClick={onCancelCustomLinkEdit}
      onSaveClick={() => {
        return onUpdateCustomLinks({
          item: linkItem,
        })
      }}
      isValidItem={isValidItem}
    />
  )
}

EditingLinkItem.propTypes = {
  customLinkItem: PropTypes.any,
  customLinksDetails: PropTypes.shape({
    customLinks: PropTypes.array,
    maxCustomLinks: PropTypes.number,
    buttonColor: PropTypes.string,
    buttonContrastColor: PropTypes.string,
  }),
  onUpdateCustomLinks: PropTypes.func,
  onCancelCustomLinkEdit: PropTypes.func,
  isValidItem: PropTypes.func,
}

EditingLinkItem.defaultProps = {
  customLinkItem: {},
  onUpdateCustomLinks: () => {},
  onCancelCustomLinkEdit: () => {},
  isValidItem: () => {},
  customLinksDetails: {
    customLinks: [],
    maxCustomLinks: 0,
    buttonColor: null,
    buttonContrastColor: null,
  },
}

const CustomLinks = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'customLinksDetails' implicitly ha... Remove this comment to see the full error message
  customLinksDetails,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onUpdateCustomLinksColor' implici... Remove this comment to see the full error message
  onUpdateCustomLinksColor,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onDeleteCustomLink' implicitly ha... Remove this comment to see the full error message
  onDeleteCustomLink,
  // @ts-expect-error TS(7031) FIXME: Binding element 'maxCustomLinks' implicitly has an... Remove this comment to see the full error message
  maxCustomLinks,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onToggleEditMode' implicitly has ... Remove this comment to see the full error message
  onToggleEditMode,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onSwapCustomLinks' implicitly has... Remove this comment to see the full error message
  onSwapCustomLinks,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onSaveNewLinkClick' implicitly ha... Remove this comment to see the full error message
  onSaveNewLinkClick,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isValidItem' implicitly has an 'a... Remove this comment to see the full error message
  isValidItem,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onUpdateSingleCustomLink' implici... Remove this comment to see the full error message
  onUpdateSingleCustomLink,
  // @ts-expect-error TS(7031) FIXME: Binding element 'hasWriteAccess' implicitly has an... Remove this comment to see the full error message
  hasWriteAccess,
}) => {
  const [colorButtons, setColorButton] = useState(
    customLinksDetails.buttonColor || DEFAULT_COLOR,
  )
  const [textColor, setTextColor] = useState(
    customLinksDetails.buttonContrastColor || DEFAULT_CONTRAST_COLOR,
  )
  const [newLinks, addNewLink] = useState([])

  // @ts-expect-error TS(7031) FIXME: Binding element 'item' implicitly has an 'any' typ... Remove this comment to see the full error message
  const onCancelClick = ({ item }) => {
    addNewLink(
      // @ts-expect-error TS(2339) FIXME: Property 'order' does not exist on type 'never'.
      newLinks.filter((currentItem) => currentItem.order !== item.order),
    )
  }

  // @ts-expect-error TS(7031) FIXME: Binding element 'item' implicitly has an 'any' typ... Remove this comment to see the full error message
  const onUpdateNewLinkValue = ({ item, value, type }) => {
    addNewLink(
      newLinks.map((currentItem) => {
        // @ts-expect-error TS(2339) FIXME: Property 'order' does not exist on type 'never'.
        if (currentItem.order === item.order) {
          item[type] = value
        }
        return currentItem
      }),
    )
  }

  const totalLinks =
    newLinks.length +
    ((customLinksDetails.customLinks &&
      customLinksDetails.customLinks.length) ||
      0)

  const onAddLinkClick = () => {
    addNewLink([
      // @ts-expect-error TS(2322) FIXME: Type '{ text: string; url: string; order: number; ... Remove this comment to see the full error message
      ...newLinks,
      // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type 'never'.
      {
        text: '',
        url: '',
        // @ts-expect-error TS(2322) FIXME: Type 'number' is not assignable to type 'never'.
        order: Math.max(0, ...newLinks.map((l) => l.order)) + 1,
      },
    ])
  }
  const { customLinks = [] } = customLinksDetails

  return (
    <MyLinksSection>
      <LinksHeader
        customLinksDetails={customLinksDetails}
        maxCustomLinks={maxCustomLinks}
        onAddLinkClick={onAddLinkClick}
        setColorButton={setColorButton}
        setTextColor={setTextColor}
        colorButtons={colorButtons}
        textColor={textColor}
        onUpdateCustomLinksColor={onUpdateCustomLinksColor}
        addLinkDisabled={totalLinks >= maxCustomLinks}
        hasWriteAccess={hasWriteAccess}
      />
      {customLinks.length === 0 && <Separator />}
      {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
      <MyLinksBody total={customLinks.length}>
        {customLinksDetails.customLinks &&
          // @ts-expect-error TS(7006) FIXME: Parameter 'customLinkItem' implicitly has an 'any'... Remove this comment to see the full error message
          customLinksDetails.customLinks.map((customLinkItem, index) => {
            return (
              <React.Fragment key={`link_${customLinkItem._id}`}>
                {!customLinkItem.editing && (
                  <LinkDragWrapper
                    // @ts-expect-error TS(2322) FIXME: Type '{ item: any; totalLinks: any; index: any; te... Remove this comment to see the full error message
                    item={customLinkItem}
                    totalLinks={customLinksDetails.customLinks.length}
                    index={index}
                    textColor={textColor}
                    bgColor={colorButtons}
                    onSwapCustomLinks={onSwapCustomLinks}
                    onToggleEditMode={onToggleEditMode}
                    onDeleteCustomLink={onDeleteCustomLink}
                    hasWriteAccess={hasWriteAccess}
                  />
                )}
                {customLinkItem.editing && (
                  <EditingLinkItem
                    key={customLinkItem.order}
                    customLinkItem={customLinkItem}
                    customLinksDetails={customLinksDetails}
                    onUpdateCustomLinks={onUpdateSingleCustomLink}
                    onCancelCustomLinkEdit={onToggleEditMode}
                    isValidItem={isValidItem}
                  />
                )}
              </React.Fragment>
            )
          })}

        {newLinks.map((newLinkItem) => {
          return (
            <EditingLinkForm
              // @ts-expect-error TS(2339) FIXME: Property 'order' does not exist on type 'never'.
              key={newLinkItem.order}
              item={newLinkItem}
              customLinksDetails={customLinksDetails}
              onUpdateLinkText={({ item, value }) => {
                onUpdateNewLinkValue({ item, value, type: 'text' })
              }}
              onUpdateLinkUrl={({ item, value }) => {
                onUpdateNewLinkValue({ item, value, type: 'url' })
              }}
              onSaveClick={({ item }) => {
                onSaveNewLinkClick({ item })
                onCancelClick({ item })
              }}
              onCancelClick={onCancelClick}
              isValidItem={isValidItem}
            />
          )
        })}
      </MyLinksBody>
    </MyLinksSection>
  )
}

CustomLinks.propTypes = {
  maxCustomLinks: PropTypes.number,
  onUpdateLinkUrl: PropTypes.func,
  onUpdateLinkText: PropTypes.func,
  onToggleEditMode: PropTypes.func,
  onDeleteCustomLink: PropTypes.func,
  onUpdateCustomLinks: PropTypes.func,
  onSwapCustomLinks: PropTypes.func,
  onCancelCustomLinkEdit: PropTypes.func,
  onUpdateCustomLinksColor: PropTypes.func,
  onUpdateSingleCustomLink: PropTypes.func,
  onSaveNewLinkClick: PropTypes.func,
  isValidItem: PropTypes.func,
  customLinksDetails: PropTypes.shape({
    customLinks: PropTypes.array,
    maxCustomLinks: PropTypes.number,
    buttonColor: PropTypes.string,
    buttonContrastColor: PropTypes.string,
  }),
}

CustomLinks.defaultProps = {
  maxCustomLinks: 3,
  onUpdateLinkUrl: () => {},
  onUpdateLinkText: () => {},
  onToggleEditMode: () => {},
  onDeleteCustomLink: () => {},
  onUpdateCustomLinks: () => {},
  onSwapCustomLinks: () => {},
  onCancelCustomLinkEdit: () => {},
  onSaveNewLinkClick: () => {},
  onUpdateCustomLinksColor: () => {},
  isValidItem: () => {},
  customLinksDetails: {
    customLinks: [],
    maxCustomLinks: 0,
    buttonColor: null,
    buttonContrastColor: null,
  },
}

export default CustomLinks
