import { isElement, type TElement, type TNode } from '@udecode/plate'
import type { SerializedNode } from '~publish/legacy/editor/BufferEditor/types'
import type { PlainText } from '~publish/legacy/editor/BufferEditor/types.plate'
import type { AutocompleteData } from '~publish/legacy/editor/plugins/autocomplete/types'

export const AUTOCOMPLETE_INPUT_ELEMENT_TYPE = 'autocomplete-input-element'
export const AUTOCOMPLETE_INPUT_ELEMENT_TRIGGER = '@'
export const AUTOCOMPLETE_INPUT_VALID_TEXT_REGEX = /([a-zA-Z0-9_.-]+)/

export interface AutocompleteInputElement extends TElement {
  type: string
  trigger: string
  children: [PlainText]
  valid: boolean
  autocompleted: boolean
  data?: AutocompleteData
}

export interface AutocompleteInputElementInterface<
  Element extends AutocompleteInputElement,
> {
  // The type of the node and the key of the plugin
  type: Element['type']

  // The trigger text used to trigger the plugin
  trigger: Element['trigger']

  // Regex for all valid mention text characters excluding trigger.
  validTextRegex?: RegExp

  // Regex for checking validity of full mention including trigger.
  // If not defined, defaults to all characters.
  validMentionRegex?: RegExp

  // Type predicate to check if input matches the element type
  is(node: TNode | undefined): node is Element

  // Used to create a new node of the element
  new: (text?: string, data?: Element['data']) => Element

  toEntity?: (node: Element) => Element['data']

  serialize?: (node: Element) => SerializedNode<Element['data']>
}

export const AutocompleteInputElement: AutocompleteInputElementInterface<AutocompleteInputElement> =
  {
    type: AUTOCOMPLETE_INPUT_ELEMENT_TYPE,
    trigger: AUTOCOMPLETE_INPUT_ELEMENT_TRIGGER,
    validTextRegex: AUTOCOMPLETE_INPUT_VALID_TEXT_REGEX,
    validMentionRegex: RegExp(
      `${AUTOCOMPLETE_INPUT_ELEMENT_TRIGGER}${AUTOCOMPLETE_INPUT_VALID_TEXT_REGEX.source}`,
    ),
    is(node: TNode | undefined): node is AutocompleteInputElement {
      // If the node is an element, the plugin of the provided key has
      // a matching 'autocompleteInputKey' defined, and the node type
      // also matches the provided key, we can safely confirm the node
      // is of the correct type
      return (
        !!node && isElement(node) && node.type === AutocompleteInputElement.type
      )
    },

    new(text = '', data): AutocompleteInputElement {
      return {
        type: AutocompleteInputElement.type,
        children: [{ text: `${AutocompleteInputElement.trigger}${text}` }],
        valid: true,
        autocompleted: false,
        trigger: AutocompleteInputElement.trigger,
        data,
      }
    },
  }
