import {
  type GetNodeEntriesOptions,
  getPlugins,
  type PlateEditor,
  type Value,
} from '@udecode/plate'
import type {
  BufferEditor,
  BufferValue,
} from '~publish/legacy/editor/BufferEditor/types.plate'
import type { EditorEntityData } from '../helpers/EditorEntityData'

type ParseTextToNodesOptions = Pick<GetNodeEntriesOptions, 'at' | 'reverse'> & {
  entities?: EditorEntityData
}

// todo: we can probably inline this function, options is not used anywhere
const executeTextToNodesCallbacks = <V extends Value>(
  editor: PlateEditor<V>,
  options?: ParseTextToNodesOptions,
): void => {
  const plugins = getPlugins<V>(editor)

  plugins.forEach((plugin) => {
    const textToNodes = plugin?.options?.textToNodes
    if (textToNodes) {
      textToNodes(editor, options)
    }
  })
}

export const parseTextToNodes = (
  editor: BufferEditor,
  text = '',
  data?: EditorEntityData,
): void => {
  editor.insertText(text)

  executeTextToNodesCallbacks<BufferValue>(editor, { entities: data })
}
