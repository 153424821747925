import { unwrapNodes } from '@udecode/plate'
import type { Location } from 'slate'
import { LinkElement } from '../nodes/LinkElement'

// @ts-expect-error TS(7006) FIXME: Parameter 'editor' implicitly has an 'any' type.
export const unwrapLink = (editor, at: Location) => {
  unwrapNodes(editor, {
    match: { type: LinkElement.type },
    at,
  })
}
