import { MentionCombobox } from '@udecode/plate'
import React, { useCallback } from 'react'
import type {
  AutocompleteData,
  AutocompleteItem,
} from '~publish/legacy/editor/plugins/autocomplete'
import { FacebookMentionInputElement } from '~publish/legacy/editor/plugins/facebook-mention/nodes'
import { TwitterHashtagInputElement } from '~publish/legacy/editor/plugins/twitter-hashtag/nodes'
import { TwitterMentionInputElement } from '~publish/legacy/editor/plugins/twitter-mention/nodes'

import { selectAndInsertSuggestion } from '../../../../../editor/plugins/autocomplete/transforms/selectAndInsertSuggestion'
import { AutocompleteSuggestion } from '../../../../../editor/plugins/autocomplete/ui/AutocompleteSuggestion'
import {
  CustomComboboxHighlightedItem,
  CustomComboboxItem,
  CustomComboboxRoot,
} from './styles'

type MentionComboboxesProps = {
  portalElement?: HTMLElement
}

const styles = {
  root: CustomComboboxRoot,
  item: CustomComboboxItem,
  highlightedItem: CustomComboboxHighlightedItem,
}

export const MentionComboboxes = ({
  portalElement,
}: MentionComboboxesProps) => {
  const handleOnRenderItem = useCallback(
    ({ item }: { item: AutocompleteItem }) => (
      <AutocompleteSuggestion mention={item} />
    ),
    [],
  )

  // @ts-expect-error TS(7006) FIXME: Parameter 'value' implicitly has an 'any' type.
  const handleFilter = useCallback(() => (value) => !!value, [])

  return (
    <>
      <MentionCombobox<AutocompleteData>
        pluginKey={TwitterMentionInputElement.type}
        portalElement={portalElement}
        onRenderItem={handleOnRenderItem}
        onSelectItem={selectAndInsertSuggestion<TwitterMentionInputElement>(
          TwitterMentionInputElement,
        )}
        // override interal filter as we're already filtering
        filter={handleFilter}
        styles={styles}
      />

      <MentionCombobox<AutocompleteData>
        pluginKey={TwitterHashtagInputElement.type}
        portalElement={portalElement}
        onRenderItem={handleOnRenderItem}
        onSelectItem={selectAndInsertSuggestion<TwitterHashtagInputElement>(
          TwitterHashtagInputElement,
        )}
        // override interal filter as we're already filtering
        filter={handleFilter}
        styles={styles}
      />

      <MentionCombobox<AutocompleteData>
        pluginKey={FacebookMentionInputElement.type}
        portalElement={portalElement}
        onRenderItem={handleOnRenderItem}
        onSelectItem={selectAndInsertSuggestion<FacebookMentionInputElement>(
          FacebookMentionInputElement,
          true,
        )}
        // override interal filter as we're already filtering
        filter={handleFilter}
        styles={styles}
      />
    </>
  )
}
