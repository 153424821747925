import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { postParser } from './postParser'
import { getDateString } from '../formatters/date'

dayjs.extend(relativeTime)

const parseLastUpdated = (updatedAt: any) => {
  const updatedDate = new Date(updatedAt * 1000)
  const diff = dayjs(updatedDate).fromNow(true)
  return `Updated ${diff} ago`
}

const getYear = (momentDate: any) => momentDate.format('YYYY')
const getMonth = (momentDate: any) => momentDate.format('MM')
const getMomentDate = (date: any) => dayjs(new Date(date * 1000))
const isRangeFromSameYear = (start: any, end: any) =>
  getYear(start) === getYear(end)
const isRangeFromSameMonth = (start: any, end: any) =>
  getMonth(start) === getMonth(end)

const formatDateRange = ({
  momentStart,
  startFormat,
  momentEnd,
  endFormat,
}: any) => {
  const start = momentStart.format(startFormat)
  const end = momentEnd.format(endFormat)
  return `${start}-${end}`
}

const parseDateRange = (startDate: any, endDate: any) => {
  if (!startDate && !endDate) return null
  const momentStart = getMomentDate(startDate)
  const momentEnd = getMomentDate(endDate)
  let startFormat = 'MMM D YYYY'
  let endFormat = 'MMM D YYYY' // Dec 25 2019-Jan 5 2020
  if (isRangeFromSameYear(momentStart, momentEnd)) {
    startFormat = 'MMM D'
    endFormat = isRangeFromSameMonth(momentStart, momentEnd)
      ? 'D, YYYY' // Jan 25-31, 2020
      : 'MMM D, YYYY' // Mar 11-Apr 4, 2020
  }
  return formatDateRange({ momentStart, startFormat, momentEnd, endFormat })
}

const campaignItemParser = (item: any, channels: any, alreadyParsed: any) => {
  let itemContent: any = null
  let headerDetails: any = null
  let filteredChannel: any = null
  if (item.content && item.type === 'update') {
    itemContent = alreadyParsed ? item.content : postParser(item.content)
    // Get channel for item
    filteredChannel =
      channels &&
      itemContent &&
      channels.filter(
        (channel: any) => channel.channelId === itemContent.profileId,
      )[0]
    const { createdAt, profileTimezone, user, isSent } = itemContent
    // String with the date of the update creation
    const createdAtString =
      createdAt &&
      getDateString(createdAt, profileTimezone, {
        createdAt,
        twentyFourHourTime: false,
      })
    // Header details to be used in the CardHeader
    headerDetails = {
      channel: {
        avatarUrl: filteredChannel && filteredChannel.serviceAvatar,
        handle: filteredChannel && filteredChannel.serviceUsername,
        type: filteredChannel && filteredChannel.serviceType,
        serviceLocation: filteredChannel && filteredChannel.serviceLocation,
      },
      creatorName: user && user.name,
      avatarUrl: user && user.avatar,
      createdAt: createdAtString,
      hideCreatorDetails: isSent,
    }
  }
  const result = {
    ...itemContent,
    hasPushNotifications:
      filteredChannel && filteredChannel.hasPushNotifications,
    profileService: filteredChannel && filteredChannel.serviceType,
    profileServiceType: filteredChannel && filteredChannel.channelType,
    isManager: filteredChannel && filteredChannel.isManager,
    dueAt: itemContent && itemContent.due_at,
    headerDetails,
  }
  return result
}

const parseCampaignItems = (items: any, channels: any) => {
  if (!items) return null
  return items.map((item: any) => {
    // @ts-expect-error TS(2554) FIXME: Expected 3 arguments, but got 2.
    return campaignItemParser(item, channels)
  })
}

const parseChannels = (channels: any) => {
  if (!channels) return null
  return channels.map((channel: any) => {
    return {
      serviceId: channel.service_id,
      serviceType: channel.service_type,
      serviceLocation: channel.service_location,
      channelType: channel.channel_type,
      channelId: channel.channel_id,
      serviceAvatar: channel.service_avatar,
      serviceUsername: channel.service_username,
      isManager: channel.is_manager,
      hasPushNotifications: channel.has_push_notifications,
    }
  })
}

const campaignParser = (campaign: any) => {
  const parsedChannels = parseChannels(campaign.channels)
  return {
    _id: campaign._id,
    id: campaign._id,
    globalOrganizationId: campaign.global_organization_id,
    name: campaign.name,
    color: campaign.color,
    locked: campaign.locked,
    lastUpdated: parseLastUpdated(campaign.updated_at),
    updatedAt: campaign.updated_at,
    createdAt: campaign.created_at,
    startDate: campaign.start_date,
    endDate: campaign.end_date,
    dateRange: parseDateRange(campaign.start_date, campaign.end_date),
    sent: campaign.sent,
    scheduled: campaign.scheduled,
    draftsCount: campaign.draft_count,
    approvalsCount: campaign.draft_pending_count,
    total: campaign.total_count_pagination,
    isAggregation: campaign.isAggregation,
    channels: parsedChannels,
    items: parseCampaignItems(campaign.items, parsedChannels),
  }
}

export { campaignParser }
export { campaignItemParser }
export default {
  campaignParser,
  campaignItemParser,
}
