import Notice from '@bufferapp/ui/Notice'
import { yellowDarker, yellowLightest } from '@bufferapp/ui/style/colors'
import Text from '@bufferapp/ui/Text'
import styled from 'styled-components'

export const NoticeContainer = styled.div`
  padding: 8px 26px 0 8px;
`

export const Container = styled.div`
  font-size: 14px;
  line-height: 20px;
  color: #343e47;
  border-color: #f1cb3a;
  padding: 8px 14px 8px 14px;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  display: block;
  background-color: rgba(240, 203, 57, 0.2);
  margin-top: 10px;
  display: flex;
`

export const Item = styled(Text)`
  display: inline-flex;
`

export const IconContainer = styled.div`
  margin-right: 10px;
  top: 3px;
  position: relative;
  color: #5e656c;
  display: inline-flex;
`

export const ClickableIconContainer = styled(IconContainer)`
  &:hover {
    cursor: pointer;
  }
`

export const TwitterErrorContainer = styled(Notice)`
  margin: 10px 10px 0px 10px;
  padding: 8px;
  font-size: 12px;
  line-height: 16px;
  align-items: flex-start;

  svg {
    margin-right: 8px;
  }
`

export const CloseIconContainer = styled.div`
  cursor: pointer;
`

interface WarningWrapperProps {
  $maxHeight?: number
  $shouldBeVisible?: boolean
}
export const WarningWrapper = styled.div<WarningWrapperProps>`
  display: flex;
  padding: 8px 16px;
  gap: 8px;
  background: ${yellowLightest};
  color: ${yellowDarker};
`

interface HeightRefContainerProps {
  $maxHeight: number
  $shouldBeVisible?: boolean
}
export const HeightRefContainer = styled.div<HeightRefContainerProps>`
  overflow: hidden;
  max-height: ${(props): number => props.$maxHeight}px;
  opacity: ${(props): string => (props.$shouldBeVisible ? '1' : '0')};
  transition: max-height 400ms ease-in-out, opacity 400ms ease-in-out;
`

export const WarningIconWrapper = styled.span`
  margin-top: 5px;
`
