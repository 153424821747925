import styled, { css } from 'styled-components'

import {
  Video as CommonVideo,
  PlayButton as CommonPlayButton,
} from '../../../Common/VideoPlayer/styles'

export const Video = css`
  ${CommonVideo}
`

export const PlayButton = css`
  ${CommonPlayButton}
  background-color: rgba(255, 255, 255, 0.8);

  svg {
    color: rgba(0, 0, 0, 0.8);
    fill: rgba(0, 0, 0, 0.8);
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
    svg {
      color: rgba(0, 0, 0, 0.9);
      fill: rgba(0, 0, 0, 0.9);
    }
  }
`

export const Wrapper = css`
  position: relative;
  width: 100%;
  height: auto;

  video {
    ${Video}
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 16px;
  }

  button {
    ${PlayButton}
  }
`

export const VideoWrapper = styled.div`
  position: relative;
`
