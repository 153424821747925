import React from 'react'
import EmptyTextAlert from './EmptyTextAlert'
import MediaZoomBox from './MediaZoomBox'
import ThumbnailEditor from './thumbnail-editor/ThumbnailEditor'
import Store from '../shared-components/modal/store'
import InstagramUserTags from './InstagramUserTags'
import { ThreadsFreeOBPaywall } from './ThreadsFreeOBPaywall'
import { ThreadsMPPaywall } from './ThreadsMPPaywall'
import { AIAssistantMPUpgradePlan } from './ai-assistant/AIAssistantMPUpgradePlan'

const getState = () => Store.getCurrentState()

const ModalComponents = {
  AIAssistantMPUpgradePlan,
  EmptyTextAlert,
  MediaZoomBox,
  ThumbnailEditor,
  InstagramUserTags,
  ThreadsFreeOBPaywall,
  ThreadsMPPaywall,
}

class Modals extends React.Component {
  // @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
  constructor(props) {
    super(props)
    this.state = Store.getInitialState()
  }

  componentDidMount() {
    return Store.addChangeListener(this.onChange)
  }

  componentWillUnmount() {
    return Store.removeChangeListener(this.onChange)
  }

  onChange = () => this.setState(getState())

  render() {
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const ModalToShow = ModalComponents[this.state.modalName] || 'span'

    return (
      // @ts-expect-error TS(2339) FIXME: Property 'open' does not exist on type 'Readonly<{... Remove this comment to see the full error message
      <div>{this.state.open && <ModalToShow {...this.state.modalProps} />}</div>
    )
  }
}

export default Modals
