/* eslint-disable no-console */

import React, { useContext } from 'react'
import {
  format,
  isPast,
  setHours,
  isSameHour,
  addDays,
  startOfHour,
  endOfHour,
} from 'date-fns'
import { Button } from '@bufferapp/ui'
import { PostItem } from '../PostItem'
import type { GetCalendarAndPostListQuery } from '~publish/gql/graphql'
import { Droppable } from 'react-beautiful-dnd'
import type { PostLimit } from '~publish/pages/Calendar/hooks/usePostLimit'
import AddButton from '../AddButton'
import Hour from './style'
import createCalendarPostContext from '../hooks/createCalendarPostContext'
import { asPostsFragment } from '~publish/pages/Calendar/hooks/useCalendarAndPostsList'
import { filterPostsWithInterval } from '~publish/pages/Calendar/util/generatePostIntervals'

type HoursProps = {
  startDate: Date
  is24HourFormat: boolean
  numberDaysToDisplay: number
  hour: number
  data: GetCalendarAndPostListQuery | null | undefined
  postLimit: PostLimit
  setOpenModal:
    | ((openModal: {
        open: boolean
        ctaString: string
        service: string
      }) => void)
    | null
  selectedChannelIds: string[] | void
}

const Hours = ({
  startDate,
  is24HourFormat = false,
  numberDaysToDisplay = 7,
  hour,
  data,
  postLimit,
  setOpenModal = null,
  selectedChannelIds,
}: HoursProps): JSX.Element => {
  const { hasPermissions } = useContext(createCalendarPostContext)
  // Generates 7 cells in each of the 24 hour rows, one per day of the week
  return (
    <>
      {[...Array(numberDaysToDisplay)].map((_, index) => {
        const cellDay = addDays(startDate, index)
        const cellDayWithHour = setHours(cellDay, hour)

        let startOfHourTimestamp = cellDayWithHour.getTime() / 1000
        const currentTimestamp = new Date().getTime() / 1000
        // Use start of hour timestamp for later dates or add 5 mins to current timestamp
        const fiveMinutesFromCurrentTimestamp = currentTimestamp + 300
        startOfHourTimestamp =
          startOfHourTimestamp > currentTimestamp
            ? startOfHourTimestamp
            : fiveMinutesFromCurrentTimestamp

        // 2 PM or 14:00 hour label for first column
        const hoursWithLabels = [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22]
        const hourLabelFormat = is24HourFormat ? 'H:00' : 'h a'
        const hourLabel = format(cellDayWithHour, hourLabelFormat)
        const firstColumn = index === 0

        // Disables add buttons and changes styles if hours in the past
        const isCurrentHour = isSameHour(new Date(), cellDayWithHour)
        const isPastHour = isPast(cellDayWithHour)

        const showAddButton = (!isPastHour || isCurrentHour) && hasPermissions

        // Posts data
        const limit = postLimit?.postsLimit?.[hour]
        const inLastTwoRows = hour >= 22
        const allPosts = asPostsFragment(data)
        const filterInterval = {
          startTimestamp: startOfHour(cellDayWithHour).toISOString(),
          endTimestamp: endOfHour(cellDayWithHour).toISOString(),
        }
        const posts = filterPostsWithInterval(filterInterval, allPosts)

        return (
          <Droppable
            droppableId={cellDayWithHour.getTime().toString()}
            key={`slot-${cellDayWithHour}`}
            isDropDisabled={isPastHour && !isCurrentHour}
          >
            {(provided, snapshot): JSX.Element => (
              <Hour
                hourLabel={hourLabel}
                showTime={firstColumn && hoursWithLabels.includes(hour)}
                isPast={isPastHour}
                isActive={isCurrentHour}
                ref={provided.innerRef}
                {...provided.droppableProps}
                isDraggingOver={snapshot.isDraggingOver}
              >
                {posts.map((post, postIndex) => {
                  const legacyPostProps = {
                    small: false,
                    index: postIndex,
                    inLastTwoRows,
                    isDraggingOver: snapshot.isDraggingOver,
                    scheduledDay: format(cellDay, 'd MMMM EEEE yyyy'),
                    setOpenModal,
                  }
                  if (postIndex >= limit) {
                    return <></>
                  }

                  return (
                    <PostItem key={post.id} post={post} {...legacyPostProps} />
                  )
                })}
                {posts.length > limit && !snapshot.isDraggingOver && (
                  // @ts-expect-error TS(2740) FIXME: Type '{ type: string; tabIndex: string; label: str... Remove this comment to see the full error message
                  <Button
                    type="text"
                    tabIndex="0"
                    label="+ More"
                    onClick={(): void => postLimit?.showMorePosts(hour)}
                  />
                )}
                {showAddButton && !snapshot.isDraggingOver && (
                  <AddButton
                    cta="publish-calendar-hour-addPost-1"
                    fullWidth
                    defaultTimeslot={startOfHourTimestamp}
                    selectedChannelIds={selectedChannelIds}
                    labelDate={`${format(
                      cellDay,
                      'eeee, d MMMM y',
                    )} at ${hourLabel}`}
                  />
                )}
                {provided.placeholder}
              </Hour>
            )}
          </Droppable>
        )
      })}
    </>
  )
}

export default Hours
