import { calculateAspectRatioString, rountToTwo } from './helpers'
import { findCommonRatioString } from './CommonRatios'
import type { AspectRatio, AspectRatioRange } from './types'
import { isAspectRatioObject, isPositiveNumber } from './guards'

export const getAspectRatio = (ratio: AspectRatio): number => {
  if (isPositiveNumber(ratio)) {
    return rountToTwo(ratio)
  }

  if (!isAspectRatioObject(ratio)) {
    throw new Error(`Invalid ratio: ${JSON.stringify(ratio)}`)
  }

  return rountToTwo(ratio.width / ratio.height)
}

export const getAspectRatioString = (
  ratio: AspectRatio | AspectRatio[],
): string => {
  if (Array.isArray(ratio)) {
    return ratio.map(getAspectRatioString).join(', ')
  }

  if (isPositiveNumber(ratio)) {
    return findCommonRatioString(ratio) ?? `${ratio}:1`
  }

  // ratio is a { width, height, ratio } object
  return ratio.readable
    ? ratio.readable
    : calculateAspectRatioString(ratio.width, ratio.height)
}

export const isRatioWithinRange = ({
  ratio,
  range,
}: {
  ratio: number
  range: AspectRatioRange
}): boolean => {
  const { minRatio, maxRatio } = range

  return ratio >= getAspectRatio(minRatio) && ratio <= getAspectRatio(maxRatio)
}
