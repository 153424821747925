import React from 'react'

import Avatar from '@bufferapp/ui/Avatar'

import type {
  Image,
  Video,
} from '~publish/legacy/composer/composer/entities/factories'
import { MediaAttachmentTypes } from '~publish/legacy/constants'
import {
  draftContentPropType,
  profilePreviewPropType,
} from '../../../Common/propTypes'
import { VideoPlayer } from '../VideoPlayer'
import type { PinterestContent, ProfilePreview } from '../../../types'
import { DEFAULT_AVATAR, getMediaProperties } from '../../../Common/utils'

import * as PinterestStyles from '../../styles'
import * as Styles from './styles'

const ImagePreview = ({ images }: { images: Image[] }) => {
  const firstImage = images[0]

  return (
    <Styles.ImageWrapper data-testid="preview-images">
      <Styles.Image src={firstImage.url} />
      {firstImage.mediaType === MediaAttachmentTypes.GIF && (
        <PinterestStyles.Tag>GIF</PinterestStyles.Tag>
      )}
    </Styles.ImageWrapper>
  )
}

const PinterestPost = ({
  content,
  profile,
}: {
  content: PinterestContent
  profile: ProfilePreview
}): JSX.Element => {
  const { avatar, username } = profile
  const { images, gif, video, title } = content
  const allImages = images?.length ? images : ([{ ...gif }] as Image[])
  const { hasImages, hasVideo } = getMediaProperties(content, null)

  return (
    <Styles.PostWrapper>
      {hasImages && <ImagePreview images={allImages} />}
      {hasVideo && <VideoPlayer video={video as Video} />}
      {title && <Styles.Title>{title}</Styles.Title>}
      <Styles.Footer>
        <Avatar
          data-testid="avatar"
          src={avatar}
          alt={username}
          size="small"
          fallbackUrl={DEFAULT_AVATAR}
        />
        <Styles.UsernameWrapper>
          <Styles.Username>{username}</Styles.Username>
        </Styles.UsernameWrapper>
      </Styles.Footer>
    </Styles.PostWrapper>
  )
}

PinterestPost.propTypes = {
  content: draftContentPropType.isRequired,
  profile: profilePreviewPropType.isRequired,
}

export default PinterestPost
