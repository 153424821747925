import { useSelector } from 'react-redux'
import { selectUserId } from '~publish/legacy/user/selectors'
import { selectIsManagerInChannel } from '~publish/legacy/profile-sidebar/selectors'
import { PostEntity } from '../../PostEntity'

// @ts-expect-error TS(7006) FIXME: Parameter 'post' implicitly has an 'any' type.
export const usePostPermissions = (post) => {
  const userId = useSelector(selectUserId)
  const postChannelId = PostEntity.getChannelId(post)

  const isManager = useSelector(selectIsManagerInChannel(postChannelId))
  const isPostOwner = PostEntity.userIsPostOwner(post, userId)

  const hasEditPermission = isManager || isPostOwner
  const canEditPost = hasEditPermission && !PostEntity.isSent(post)

  return {
    isManager,
    isPostOwner,
    hasEditPermission,
    canEditPost,
  }
}
