import twitter from 'twitter-text'
import type { TextToElementEntity } from '~publish/legacy/utils/parseTextToElements'

export const parseTikTokLinks = (text: string | null): TextToElementEntity[] =>
  text
    ? twitter
        .extractEntitiesWithIndices(text)
        .reduce<TextToElementEntity[]>((result, entity) => {
          if ('hashtag' in entity && entity.hashtag) {
            return [
              ...result,
              {
                displayString: `#${entity.hashtag}`,
                indices: entity.indices,
                rawString: `#${entity.hashtag}`,
              },
            ]
          }
          return result
        }, [])
    : []
