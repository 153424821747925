import React from 'react'
import styled from 'styled-components'
import {
  SERVICE_BLUESKY,
  SERVICE_FACEBOOK,
  SERVICE_GOOGLEBUSINESS,
  SERVICE_INSTAGRAM,
  SERVICE_LINKEDIN,
  SERVICE_MASTODON,
  SERVICE_PINTEREST,
  SERVICE_STARTPAGE,
  SERVICE_THREADS,
  SERVICE_TIKTOK,
  SERVICE_TWITTER,
  SERVICE_YOUTUBE,
} from '~publish/legacy/constants'
import {
  Bluesky,
  Facebook,
  Gbp,
  Instagram,
  LinkedIn,
  Mastodon,
  Pinterest,
  StartPage,
  Threads,
  Tiktok,
  X,
  Youtube,
} from '@bufferapp/ui/Icon'
import {
  facebook,
  googlebusiness,
  instagram,
  linkedin,
  mastodon,
  pinterest,
  startpage,
  threads,
  tiktok,
  twitter,
  youtube,
} from '@bufferapp/ui/style/colors'

export const IconWrapper = styled.div<{ bgColor: string }>`
  bottom: 0;
  right: 0;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background: ${(props): string => (props.bgColor ? props.bgColor : '#777')};
  display: flex;
  justify-content: center;
  align-items: center;
`

export const networkIconMap = new Map([
  [SERVICE_TWITTER, { component: X, color: twitter }],
  [SERVICE_FACEBOOK, { component: Facebook, color: facebook }],
  [SERVICE_LINKEDIN, { component: LinkedIn, color: linkedin }],
  [SERVICE_PINTEREST, { component: Pinterest, color: pinterest }],
  [SERVICE_INSTAGRAM, { component: Instagram, color: instagram }],
  [SERVICE_TIKTOK, { component: Tiktok, color: tiktok }],
  [SERVICE_GOOGLEBUSINESS, { component: Gbp, color: googlebusiness }],
  [SERVICE_STARTPAGE, { component: StartPage, color: startpage }],
  [SERVICE_MASTODON, { component: Mastodon, color: mastodon }],
  [SERVICE_YOUTUBE, { component: Youtube, color: youtube }],
  [SERVICE_THREADS, { component: Threads, color: threads }],
  [SERVICE_BLUESKY, { component: Bluesky, color: 'var(--bluesky-color)' }],
])

interface ChannelIconProps {
  network:
    | 'facebook'
    | 'pinterest'
    | 'twitter'
    | 'linkedin'
    | 'instagram'
    | 'tiktok'
    | 'googlebusiness'
    | 'startPage'
    | 'mastodon'
    | 'youtube'
    | 'shopify'
    | 'threads'
    | 'bluesky'
  circular?: boolean
}

const ChannelIcon = ({
  network,
  circular = false,
}: ChannelIconProps): JSX.Element | null => {
  const icon = networkIconMap.get(network)
  if (icon) {
    const { component: IconComponent, color } = icon

    if (circular) {
      return (
        <IconWrapper role={'img'} bgColor={color}>
          <IconComponent size="small" color="white" />
        </IconWrapper>
      )
    }

    return <IconComponent role={'img'} color={color} />
  }

  return null
}

export default ChannelIcon
