import removeEmptyValuesFromObject from '~publish/legacy/utils/removeEmptyValuesFromObject'

/**
 * Construct a valid post object from a URL query search string
 * @param queryParams - Query search string
 * @returns {T}
 */
// @ts-expect-error TS(7006) FIXME: Parameter 'queryParams' implicitly has an 'any' ty... Remove this comment to see the full error message
const formatPostFromQuery = (queryParams) => {
  if (!queryParams) return {}

  // Note: via and title are fields that were available with button.buffer.com.
  // We added logic here to support pulling them in when we started redirecting
  // buffer.com/add to publish.buffer.com/compose in order to retain functionality.
  let text = ''

  const query = new URLSearchParams(queryParams)
  if (query.get('text')) {
    // @ts-expect-error TS(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
    text = query.get('text')
  }
  if (query.get('title')) {
    // @ts-expect-error TS(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
    text = query.get('title')
  }

  if (query.get('url')) {
    const url = query.get('url')
    // @ts-expect-error TS(2322) FIXME: Type 'string | null' is not assignable to type 'st... Remove this comment to see the full error message
    text = text === '' ? url : `${text} ${url}`
  }

  let postWithNullValues = {
    text,
  }

  if (query.get('picture')) {
    postWithNullValues = {
      ...postWithNullValues,
      // @ts-expect-error TS(2322) FIXME: Type '{ imageSrc: string | null; media: { picture:... Remove this comment to see the full error message
      imageSrc: query.get('picture'),
      media: {
        picture: query.get('picture'),
        photo: query.get('picture'),
        thumbnail: query.get('picture'),
      },
    }
  }

  if (query.get('via')) {
    const via = query.get('via')

    postWithNullValues = {
      ...postWithNullValues,
      text: `${postWithNullValues.text} via @${via}`,
    }
  }

  const tweetId = query.get('retweeted_tweet_id')
  if (tweetId) {
    const username = query.get('retweeted_user_name')

    postWithNullValues = {
      ...postWithNullValues,
      // @ts-expect-error TS(2322) FIXME: Type '{ retweeted_tweet_id: string; retweet: { use... Remove this comment to see the full error message
      retweeted_tweet_id: tweetId,
      retweet: {
        user_id: query.get('retweeted_user_id'),
        username,
        profile_name: query.get('retweeted_user_display_name'),
        tweet_id: tweetId,
        url: `https://twitter.com/${username}/status/${tweetId}`,
        avatars: {
          http: query.get('retweeted_user_avatar'),
          https: query.get('retweeted_user_avatar'),
        },
        text: query.get('text'),
      },
    }
  }

  // Remove null values
  return removeEmptyValuesFromObject(postWithNullValues)
}

export default formatPostFromQuery
