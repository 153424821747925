import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Button } from '@bufferapp/ui'

import {
  DEFAULT_COLOR,
  DEFAULT_CONTRAST_COLOR,
  PreviewWrapper,
  UrlPreview,
  LinkPreviewRow,
  StyledButton,
} from '../styles'

export const LinkPreviewButton = styled.div.attrs((props) => ({
  style: {
    // @ts-expect-error TS(2551) FIXME: Property 'bgColor' does not exist on type 'ThemedS... Remove this comment to see the full error message
    backgroundColor: `${props.bgColor || DEFAULT_COLOR}`,
    // @ts-expect-error TS(2339) FIXME: Property 'textColor' does not exist on type 'Theme... Remove this comment to see the full error message
    color: `${props.textColor || DEFAULT_CONTRAST_COLOR}`,
    border: `${
      // @ts-expect-error TS(2551) FIXME: Property 'bgColor' does not exist on type 'ThemedS... Remove this comment to see the full error message
      props.bgColor === DEFAULT_CONTRAST_COLOR
        ? `1px solid ${DEFAULT_COLOR}`
        : // @ts-expect-error TS(2551) FIXME: Property 'bgColor' does not exist on type 'ThemedS... Remove this comment to see the full error message
          `1px solid ${props.bgColor}`
    }`,
  },
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  width: 285px;
  min-width: 285px;
  height: 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: default;
  font-size: 14px;
  line-height: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const UrlWrapper = styled.div`
  flex: 1;
  display: flex;
  min-width: 0;
`

const LinkPreview = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'index' implicitly has an 'any' ty... Remove this comment to see the full error message
  index,
  // @ts-expect-error TS(7031) FIXME: Binding element 'totalLinks' implicitly has an 'an... Remove this comment to see the full error message
  totalLinks,
  // @ts-expect-error TS(7031) FIXME: Binding element 'item' implicitly has an 'any' typ... Remove this comment to see the full error message
  item,
  // @ts-expect-error TS(7031) FIXME: Binding element 'bgColor' implicitly has an 'any' ... Remove this comment to see the full error message
  bgColor,
  // @ts-expect-error TS(7031) FIXME: Binding element 'textColor' implicitly has an 'any... Remove this comment to see the full error message
  textColor,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onDeleteCustomLink' implicitly ha... Remove this comment to see the full error message
  onDeleteCustomLink,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onToggleEditMode' implicitly has ... Remove this comment to see the full error message
  onToggleEditMode,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isTarget' implicitly has an 'any'... Remove this comment to see the full error message
  isTarget,
  // @ts-expect-error TS(7031) FIXME: Binding element 'hasWriteAccess' implicitly has an... Remove this comment to see the full error message
  hasWriteAccess,
}) => {
  const [isConfirmingDelete, setConfirmingDelete] = useState(false)

  return (
    <PreviewWrapper
      // @ts-expect-error TS(2769) FIXME: No overload matches this call.
      isTarget={isTarget}
      totalLinks={totalLinks}
      index={index}
      hasWriteAccess={hasWriteAccess}
    >
      <LinkPreviewRow>
        {/* @ts-expect-error TS(2769) FIXME: No overload matches this call. */}
        <LinkPreviewButton bgColor={bgColor} textColor={textColor}>
          {item.text}
        </LinkPreviewButton>
        <UrlWrapper>
          <UrlPreview>{item.url}</UrlPreview>
        </UrlWrapper>
        {hasWriteAccess && (
          <>
            {onDeleteCustomLink && (
              <>
                <StyledButton
                  type="text"
                  label={isConfirmingDelete ? 'Cancel' : 'Delete'}
                  size="small"
                  onClick={() => setConfirmingDelete(!isConfirmingDelete)}
                />
                {isConfirmingDelete && (
                  // @ts-expect-error TS(2740) FIXME: Type '{ label: string; type: string; size: string;... Remove this comment to see the full error message
                  <Button
                    label="Delete"
                    type="text"
                    size="small"
                    onClick={() =>
                      onDeleteCustomLink({ customLinkId: item._id })
                    }
                  />
                )}
              </>
            )}
            {/* @ts-expect-error TS(2740) FIXME: Type '{ label: string; type: string; size: string;... Remove this comment to see the full error message */}
            <Button
              label="Edit"
              type="secondary"
              size="small"
              onClick={() => onToggleEditMode({ item, editing: true })}
            />
          </>
        )}
      </LinkPreviewRow>
    </PreviewWrapper>
  )
}

LinkPreview.propTypes = {
  index: PropTypes.number,
  totalLinks: PropTypes.number,
  isTarget: PropTypes.bool,
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  text: PropTypes.string,
  item: PropTypes.shape({
    _id: PropTypes.string,
    text: PropTypes.string,
    url: PropTypes.string,
  }),
  onToggleEditMode: PropTypes.func,
  onDeleteCustomLink: PropTypes.func,
}

LinkPreview.defaultProps = {
  index: 0,
  totalLinks: 0,
  isTarget: false,
  bgColor: DEFAULT_COLOR,
  textColor: '#FFFFFF',
  text: null,
  item: {
    text: null,
    url: null,
  },
  onToggleEditMode: () => {},
  onDeleteCustomLink: () => {},
}

export default LinkPreview
