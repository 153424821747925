import styled, { css, type CSSProp } from 'styled-components'
import {
  gray,
  grayLight,
  grayLighter,
  grayDark,
  grayDarker,
} from '@bufferapp/ui/style/colors'
import { fontFamily } from '@bufferapp/ui/style/fonts'

const LINE_HEIGHT = 20

export const Wrapper = styled.div<{
  expanded: boolean
  lines?: number
  disabled?: boolean
  withBackground?: boolean
}>`
  position: relative;
  padding: 12px 32px 12px 8px;
  margin: 0;
  border: 1px solid ${grayLight};
  border-radius: 4px;
  cursor: ${(props): string => (props.disabled ? 'default' : 'pointer')};
  background-color: ${({ withBackground }): string =>
    withBackground ? grayLighter : 'transparent'};

  p {
    padding: 0;
    margin: 0;
    font-family: ${fontFamily};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: ${LINE_HEIGHT}px;
    color: ${(props): string => (props.disabled ? grayDark : grayDarker)};

    ${(props): CSSProp | null =>
      props.expanded
        ? null
        : css`
            display: -webkit-box;
            -webkit-line-clamp: ${props.lines || 1};
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          `};
  }
`

export const ClearButton = styled.button<{
  withBackground?: boolean
  disabled?: boolean
}>`
  display: flex;
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 14px;
  right: 8px;
  cursor: pointer;

  svg {
    color: ${(props): string => getFontColor(props)};
    fill: ${(props): string => getFontColor(props)};
  }

  &:hover {
    svg {
      color: ${(props): string => getFontColorOnHover(props)};
      fill: ${(props): string => getFontColorOnHover(props)};
    }
  }
`

/**
 *
 */
function getFontColor({
  withBackground,
}: {
  withBackground?: boolean
}): string {
  return withBackground ? grayDark : gray
}

/**
 *
 */
function getFontColorOnHover({
  withBackground,
}: {
  withBackground?: boolean
}): string {
  return withBackground ? grayDarker : grayDark
}
