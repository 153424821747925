import React from 'react'
import PropTypes from 'prop-types'
import RetweetIcon from '@bufferapp/ui/Icon/Icons/Retweet'

import { LinkifiedText } from '~publish/legacy/shared-components/LinkifiedText'
import { AttachmentTypes } from '~publish/legacy/constants'
import type {
  Gif,
  Image,
  Link,
  Retweet,
  Video,
} from '~publish/legacy/composer/composer/entities/factories'
import { parseTwitterLinks } from '~publish/legacy/utils/channels/twitter/helpers'
import { AttachmentVideo } from '../AttachmentVideo'
import { AttachmentRetweet } from '../AttachmentRetweet'
import type { TwitterContent, TwitterProfile } from '../../../types'
import { removeLinkFromEndOfText, truncateText } from '../../../Common/utils'
import {
  draftContentPropType,
  profilePreviewPropType,
} from '../../../Common/propTypes'
import useLinkPreviewEffect from '../../../Common/hooks/useLinkPreviewEffect'
import * as Styles from './styles'

const getTextToDisplay = (
  hasLink: boolean,
  text?: string,
  link?: string | null,
): string | undefined => {
  let textToDisplay = text
  if (hasLink && textToDisplay) {
    textToDisplay = removeLinkFromEndOfText(textToDisplay, link)
  }
  textToDisplay = truncateText(textToDisplay, 280)

  return textToDisplay
}

interface GetMediaPropertiesResult {
  hasImages: boolean | number | undefined | null | Gif
  hasVideo: boolean
  hasLink: boolean
  hasRetweet: boolean
  isRetweet: boolean
}

const getMediaProperties = (
  content: TwitterContent,
  linkPreview: Link | null,
): GetMediaPropertiesResult => {
  const { text, images, gif, video, enabledAttachmentType } = content

  const hasText = !!text
  const hasMedia = enabledAttachmentType === AttachmentTypes.MEDIA
  const hasImages = hasMedia && (images?.length || gif)
  const hasVideo = !!(hasMedia && video)
  const hasLink = !!(linkPreview && !hasImages && !hasVideo)
  const hasContent = hasText || hasMedia || hasLink
  // If there is no content, we display the whole tweet as a retweet.
  const hasRetweet =
    enabledAttachmentType === AttachmentTypes.RETWEET && hasContent
  const isRetweet =
    enabledAttachmentType === AttachmentTypes.RETWEET && !hasContent

  return { hasImages, hasVideo, hasLink, hasRetweet, isRetweet }
}

export const Tweet = ({
  content,
  profile,
  index,
}: {
  content: TwitterContent
  profile: TwitterProfile
  index: number
}): JSX.Element => {
  const {
    text,
    images,
    gif,
    retweet,
    video,
    link: linkFromPost,
    inThread,
  } = content
  const { avatar, username, formattedUsername, displayName, isTwitterPremium } =
    profile
  const [linkPreview, linkText] = useLinkPreviewEffect({
    linkFromPost,
    text,
  })

  const { hasImages, hasVideo, hasLink, hasRetweet, isRetweet } =
    getMediaProperties(content, linkPreview)

  const {
    avatarUrl: retweetAvatar,
    text: retweetText,
    userDisplayName: retweetDisplayName,
    userName: retweetUsername,
  } = retweet || {}

  const originalText = isRetweet ? retweetText : text
  let textToDisplay = getTextToDisplay(hasLink, originalText, linkText)
  if (isTwitterPremium && (originalText?.length ?? 0) > 280) {
    textToDisplay = `${textToDisplay}... Read more`
  }
  const allImages = images?.length ? images : ([{ ...gif }] as Image[])

  const usernameToDisplay = displayName || username

  return (
    <>
      {isRetweet && (
        <Styles.RetweetLabel>
          <RetweetIcon size="medium" />
          {` ${usernameToDisplay} Retweeted`}
        </Styles.RetweetLabel>
      )}
      <Styles.Wrapper withBorder={!!inThread} data-thread-id={index}>
        <Styles.Avatar
          data-testid="avatar"
          src={isRetweet ? retweetAvatar : avatar}
        />
        <Styles.UsernameWrapper>
          <Styles.Username>
            {isRetweet ? retweetDisplayName : usernameToDisplay}
          </Styles.Username>
          <Styles.FormattedUsername>
            {isRetweet ? `@${retweetUsername}` : formattedUsername}
          </Styles.FormattedUsername>
        </Styles.UsernameWrapper>
        <Styles.Content>
          <LinkifiedText
            links={parseTwitterLinks(textToDisplay)}
            whitespace="pre-wrap"
          >
            {textToDisplay}
          </LinkifiedText>
        </Styles.Content>
        {hasImages && (
          <Styles.ImagesGrid
            images={allImages}
            isRowFormat={false}
            maxHeight="180px"
            shouldShowGifTag={false}
          />
        )}
        {hasVideo && <AttachmentVideo video={video as Video} />}
        {hasLink && !hasRetweet && (
          <Styles.LinkPreview
            link={linkPreview as Link}
            clickable={false}
            imageCover
          />
        )}
        {hasRetweet && <AttachmentRetweet data={retweet as Retweet} />}
      </Styles.Wrapper>
    </>
  )
}

Tweet.propTypes = {
  content: Object.assign(draftContentPropType, {
    retweet: PropTypes.shape({
      avatarUrl: PropTypes.string,
    }),
    inThread: PropTypes.bool,
  }).isRequired,
  profile: profilePreviewPropType.isRequired,
}
