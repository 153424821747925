import styled from 'styled-components'
import { fontFamily } from '@bufferapp/ui/style/fonts'
import {
  white,
  blue,
  blueDark,
  gray,
  grayLight,
  grayDark,
} from '@bufferapp/ui/style/colors'

export const Wrapper = styled.div`
  padding: 24px 16px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  border-top: 1px solid ${grayLight};
  background: ${white};
  flex-shrink: 0;
  margin-top: 24px;

  svg {
    width: 16px;
    height: 16px;
    color: ${gray};
    fill: ${gray};
  }
`

export const Tip = styled.p`
  font-family: ${fontFamily};
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  color: ${grayDark};
  margin: 0;
  white-space: pre-wrap;
  padding: 0;

  span {
    font-weight: 700;
  }

  a {
    color: ${blue};
    cursor: pointer;
    text-decoration: none;
    display: inline;
  }

  a:hover {
    color: ${blueDark};
  }
`
