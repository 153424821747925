import { createPluginFactory } from '@udecode/plate'

import type {
  BufferEditor,
  BufferValue,
} from '~publish/legacy/editor/BufferEditor/types.plate'
import {
  AutocompleteInputElementUI,
  type AutocompletePluginOptions,
} from '../autocomplete'
import { autocompleteOnKeyDownHandler } from '../autocomplete/handlers'
import { replaceTextAfterTriggerWithMention } from '../autocomplete/transforms/replaceTextAfterTriggerWithMention'
import { withAutocomplete } from '../autocomplete/withAutocomplete'
import { getNewSuggestionsOnMentionUpdate } from './api/getNewSuggestionsOnMentionUpdate'
import { findAndInsertFacebookMentions } from './commands/findAndInsertFacebookMentions'
import { normalizeFacebookMentionNode } from './commands/normalizeFacebookMentionNode'
import { FacebookMentionInputElement } from './nodes/FacebookMentionInputElement'
import { removeAutocompletedMentionOnUpdate } from './commands/removeAutocompletedMentionOnUpdate'
import { shouldExitOnWhitespace } from './queries/shouldExitOnWhitespace'
import { hasOnlyFacebookGroupsSelected } from './queries/hasOnlyFacebookGroupsSelected'
import { areMentionsSupported } from './commands/areMentionsSupported'

export const createFacebookMentionPlugin = createPluginFactory<
  AutocompletePluginOptions<FacebookMentionInputElement>,
  BufferValue,
  BufferEditor
>({
  key: FacebookMentionInputElement.type,
  isElement: true,
  isInline: true,
  component: AutocompleteInputElementUI,
  options: {
    textToNodes: findAndInsertFacebookMentions,
    preventMentionInsert: hasOnlyFacebookGroupsSelected,
    fetchNewSuggestions: getNewSuggestionsOnMentionUpdate,
    normalizeNode: normalizeFacebookMentionNode,
    shouldExitOnWhitespace,
    onInsertTriggerBeforeText: replaceTextAfterTriggerWithMention,
    onMentionUpdated: removeAutocompletedMentionOnUpdate,
    areMentionsSupported,
  },
  withOverrides: withAutocomplete<FacebookMentionInputElement>(
    FacebookMentionInputElement,
  ),
  handlers: {
    onKeyDown: autocompleteOnKeyDownHandler({
      type: FacebookMentionInputElement.type,
    }),
  },
})
