import { actions as composerPopoverActions } from '~publish/legacy/composer-popover/reducer'
import { actionTypes } from './reducer'

// @ts-expect-error TS(7031) FIXME: Binding element 'dispatch' implicitly has an 'any'... Remove this comment to see the full error message
export default ({ dispatch, getState }) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'next' implicitly has an 'any' type.
  (next) =>
  // @ts-expect-error TS(7006) FIXME: Parameter 'action' implicitly has an 'any' type.
  (action) => {
    next(action)
    const { page } = getState().modals

    switch (action.type) {
      case actionTypes.CLOSE_COMPOSER_AND_CONFIRMATION_MODAL:
        switch (page) {
          case 'campaigns':
          case 'sent':
          case 'pendingTiktok':
          case 'calendar':
          case 'queue':
            dispatch(composerPopoverActions.hideComposer())
            break
          default:
            break
        }
        break
      default:
        break
    }
  }
