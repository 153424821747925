import styled from 'styled-components'
import { grayDarker } from '@bufferapp/ui/style/colors'

export const AutocompleteSuggestionsEntryAvatar = styled.img`
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  width: 16px;
  height: 16px;
  margin-right: 5px;
`

export const AutocompleteSuggestionsEntryText = styled.span`
  color: ${grayDarker};
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 12px;
`

export const AutocompleteSuggestionsEntryName = styled.span`
  font-size: 12px;
  font-weight: 600;
`
