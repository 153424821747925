import styled from 'styled-components'
import { gray, white, grayDark } from '@bufferapp/ui/style/colors'
import twirlSvg from './twirl.svg'

import { LinkPreview as Link } from '../../../Common/LinkPreview'
import { ImagesGrid as ImagesGridCommon } from '../../../Common/ImagesGrid'
import { ImagesCarousel as ImagesCarouselCommon } from '../../../Common/ImagesCarousel'
import HeartOutlineIcon from '@bufferapp/ui/Icon/Icons/HeartOutline'
import CommentRoundOutlineIcon from '@bufferapp/ui/Icon/Icons/CommentRoundOutline'
import MessageOutlineIcon from '@bufferapp/ui/Icon/Icons/MessageOutline'
import RetweetIcon from '@bufferapp/ui/Icon/Icons/Retweet'

export const Wrapper = styled.div<{
  withBorder: boolean
  withTwirl: boolean
}>`
  display: flex;
  gap: 8px;
  position: relative;
  margin-bottom: 16px;

  & > * {
    margin-bottom: 8px;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-12px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  animation: fadeIn 0.3s;

  ${(props): false | string =>
    props.withBorder &&
    `&::before {
      content: '';
      position: absolute;
      left: 17px;
      width: 2px;
      height: calc(100% - 40px);
      border-radius: 2px;
      ${
        props.withTwirl
          ? 'background: linear-gradient(0deg, rgb(229, 229, 229) 0px, rgb(229, 229, 229) 5px, transparent 5px, transparent 28px, rgb(229, 229, 229) 28px);'
          : 'background: rgb(229, 229, 229);'
      }
      margin-top: 44px;
      box-sizing: border-box;
    }
  `}

  ${(props): false | string =>
    props.withBorder &&
    props.withTwirl &&
    `&::after {
      content: '';
      background-image: url("${twirlSvg}");
      position: absolute;
      left: 3px;
      width: 16px;
      height: 25px;
      bottom: 0px;
      background-repeat: no-repeat;
    }
  `}
`

export const AvatarWrapper = styled.div`
  position: relative;
  align-self: start;

  &::after {
    font-family: monospace;
    content: '+';
    position: absolute;
    right: -3px;
    bottom: -3px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: black;
    border: 2px solid white;
    color: white;
    text-align: center;
    line-height: 16px;
    font-size: 16px;
  }
`

export const Avatar = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border: solid 6px #ffffff;
  margin: 0;
  border: 0;
  vertical-align: bottom;
`

export const Header = styled.div`
  display: flex;
  gap: 8px;
  flex-direction row;
  align-items: center;
`

export const Username = styled.span`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 15px;
  font-weight: 500;
`

export const FormattedUsername = styled.span`
  display: inline-block;
  color: ${grayDark};
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const Content = styled.div`
  width: calc(300px - 36px - 8px);
  flex: 1;
  display: flex;
  flex-direction: column;
`

export const LinkPreview = styled(Link)`
  border-radius: 12px;
  border: 1px solid ${gray};
  margin-top: 0;

  .content {
    background-color: ${white};
    display: flex;
    flex-direction: column;
    min-width: 0;
  }

  .title {
    display: block;
    font-weight: 400;
    font-size: 14px;
    margin-bottom: 4px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`

export const ImagesGrid = styled(ImagesGridCommon)`
  border-radius: 12px;
  margin-top: 8px;
`

export const ImagesCarousel = styled(ImagesCarouselCommon)`
  margin-top: 8px;
  margin-left: -46px;
`

export const Ago = styled.div`
  color: ${grayDark};
`

export const Footer = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 14px;
`

export const Dots = styled.div`
  margin-left: auto;
  display: inline-flex;

  svg {
    transform: rotate(90deg);
  }
`

export const CommentIcon = styled(CommentRoundOutlineIcon)`
  width: 23px;
  height: 23px;
`

export const HeartIcon = styled(HeartOutlineIcon)`
  width: 22px;
  height: 22px;
`

export const MessageIcon = styled(MessageOutlineIcon)`
  width: 20px;
  height: 20px;
`

export const ShareIcon = styled(RetweetIcon)`
  rotate: 90deg;
  width: 20px;
  height: 20px;
`

export const EmptyText = styled.div`
  display: inline-block;
`
