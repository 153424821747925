/*
 * UTILS METHODS FOR ConnectAChannelMethod
 */

// Common base for generating path strings
const basePath = (channel: string): string => {
  const app = 'publish'
  const view = 'calendar' // Remember to include new views as needed
  const location = 'channelConnectOnboardingModal'

  return `${app}-${view}-${location}-${channel}`
}

// Generates a CTA value string including button and version
export const getCTAValue = (channel: string, btn: string): string => {
  const version = '1'
  return `${basePath(channel)}-${btn}-${version}`
}

// Generates an upgrade path name string
export const getUpgradePathName = (channel: string): string => {
  return basePath(channel)
}
