import { formatDistanceToNow } from 'date-fns'
import React from 'react'
import { AuthorText, CreatedAtText, Label } from '../styles'

type AuthorLabelProps = {
  authorName: string
  createdAt: number
}

export const AuthorLabel = ({
  authorName,
  createdAt,
}: AuthorLabelProps): JSX.Element => (
  <Label>
    <AuthorText type="label">{authorName}</AuthorText>
    <CreatedAtText type="label" color="gray">
      {formatDistanceToNow(createdAt * 1000)}
    </CreatedAtText>
  </Label>
)
