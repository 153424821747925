import React from 'react'
import { Empty } from '../Common/Empty'
import {
  draftPreviewPropType,
  profilePreviewPropType,
} from '../Common/propTypes'
import type { PostType, ProfilePreview, YoutubeDraft } from '../types'
import { YoutubePost } from './components/Post'

import { getMediaProperties } from '../Common/utils'

import * as Styles from './styles'

export const YoutubePreview = ({
  draft,
  profile,
}: {
  draft: YoutubeDraft
  profile: ProfilePreview
}): JSX.Element => {
  const containerRef = React.useRef<HTMLDivElement | null>(null)

  const draftYoutube: PostType = {
    content: {
      ...draft,
      text: draft.text,
    },
  }

  const { hasVideo } = getMediaProperties(draftYoutube.content, null)

  if (!hasVideo) return <Empty />

  return (
    <Styles.Wrapper data-testid="youtube-preview" ref={containerRef}>
      <YoutubePost content={draftYoutube.content} profile={profile} />
    </Styles.Wrapper>
  )
}

YoutubePreview.propTypes = {
  draft: draftPreviewPropType.isRequired,
  profile: profilePreviewPropType.isRequired,
}
