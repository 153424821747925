import React from 'react'
import AppsManager from '../AppsManager'
import ExtrasLinks from '../ExtrasLinks'

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const AppsAndExtras = (props) => (
  <div>
    <ExtrasLinks />
    <AppsManager {...props} />
  </div>
)

export default AppsAndExtras
