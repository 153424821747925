import styled from 'styled-components'

export const AddNoteForm = styled.form<{ $isEmpty?: boolean }>`
  display: flex;
  align-items: center;
  margin: ${(props): string => (props.$isEmpty ? '28px 16px 0' : '8px 16px 0')};
  justify-content: space-between;
`

export const InputWrapper = styled.div`
  margin-right: 8px;
  flex: 1;
`
