import type { PostFields } from '@buffer-mono/reminders-config'
import React from 'react'
import { FieldConfiguration } from '~publish/legacy/reminders/components/new-reminders/field-configuration/PublishFieldsConfiguration'
import { ReminderFieldComponent } from '~publish/legacy/reminders/components/new-reminders/components/channel-fields/ReminderField'
import type { Draft } from '~publish/legacy/composer/composer/entities/Draft'

export const ChannelField = ({
  field,
  draft,
}: {
  field: PostFields
  draft: Draft
}): React.JSX.Element => {
  const fieldConfig = FieldConfiguration[field]
  const DirectComponent = !draft.isReminder && fieldConfig.direct_component
  return DirectComponent ? (
    <DirectComponent key={field} draft={draft} />
  ) : (
    <ReminderFieldComponent
      key={field}
      field={field}
      fieldConfig={fieldConfig}
      draft={draft}
    />
  )
}
