import styled from 'styled-components'
import { grayDark, grayDarker } from '@bufferapp/ui/style/colors'
import { fontFamily } from '@bufferapp/ui/style/fonts'

export const Title = styled.p`
  font-family: ${fontFamily};
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
  padding: 0;
  color: ${grayDarker};
`

export const Text = styled.p`
  font-family: ${fontFamily};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: ${grayDark};
  padding: 8px 0;
  margin: 0 0 8px 0;
`

export const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 8px;

  & > * {
    flex: 1;
    width: 100%;
  }

  button {
    justify-content: center;
  }
`
