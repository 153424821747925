import React, { type RefObject, useState } from 'react'
import './css/InstagramThumbnailSlider.css'

const MAX = 10000

function InstagramThumbnailSlider({
  videoRef,
}: {
  videoRef: RefObject<HTMLVideoElement>
}): JSX.Element | null {
  const [value, setValue] = useState(0)

  return (
    <div id="videoControls">
      <input
        onChange={(event): void => {
          if (!videoRef.current) {
            return
          }

          // moves the video & seek bar to correct place
          const time =
            videoRef.current.duration * (event.target.valueAsNumber / MAX)

          if (isFinite(time)) {
            videoRef.current.currentTime = time
          }

          setValue(event.target.valueAsNumber)
        }}
        value={value}
        type="range"
        min="0"
        max={MAX}
      />
    </div>
  )
}

export default InstagramThumbnailSlider
