import React from 'react'
import HashtagGroupWrapper from '~publish/legacy/hashtag-group-manager'
import { ComposerSidepanel } from '~publish/legacy/shared-components'

interface HashtagManagerSidepanelProps {
  onInsertHashtagGroupClick: (text: string) => void
  onClose: () => void
  onTop?: boolean
}

const HashtagManagerSidepanel: React.FC<HashtagManagerSidepanelProps> = ({
  onInsertHashtagGroupClick,
  onClose,
  onTop,
}) => {
  return (
    <ComposerSidepanel
      isVisible={true}
      customStyles={{
        height: '100%',
        zIndex: onTop ? '1' : '0',
      }}
      showCloseButton
      onClose={onClose}
    >
      <HashtagGroupWrapper
        // @ts-expect-error TS(2322) FIXME: Type '{ viewMode: string; onInsertHashtagGroupClick: () => void; }' is not assignable to type 'IntrinsicAttributes & Omit<ClassAttributes<HashtagGroupWrapper>, never>'.
        viewMode="manageHashtag"
        onInsertHashtagGroupClick={onInsertHashtagGroupClick}
      />
    </ComposerSidepanel>
  )
}

export default HashtagManagerSidepanel
