// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module 'uuid... Remove this comment to see the full error message
import uuid from 'uuid/v4'
import Cookies from 'js-cookie'
import { ActionTypes } from '../state/ActionTypes'
import { notificationsSlice } from '../state/slices/notificationsSlice'

import {
  Notification,
  NotificationScopes,
  NotificationTypes,
} from '../values/Notification'

const CLOSED_NOTIFICATIONS_COOKIE_KEY = 'mc-closed-notifications'

const getClosedNotifications = () =>
  Cookies.getJSON(CLOSED_NOTIFICATIONS_COOKIE_KEY) || []
// @ts-expect-error TS(7006) FIXME: Parameter 'notifs' implicitly has an 'any' type.
const setClosedNotifications = (notifs) =>
  Cookies.set(CLOSED_NOTIFICATIONS_COOKIE_KEY, notifs, { expires: 365 * 4 })

// @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
const getRemovedNotificationAndIndex = (id, _state) => {
  const removedNotificationIndex = _state.visibleNotifications.findIndex(
    // @ts-expect-error TS(7006) FIXME: Parameter 'notif' implicitly has an 'any' type.
    (notif) => notif.id === id,
  )
  const removedNotification =
    _state.visibleNotifications[removedNotificationIndex]
  return { removedNotificationIndex, removedNotification }
}

// @ts-expect-error TS(7006) FIXME: Parameter 'removedNotification' implicitly has an ... Remove this comment to see the full error message
const isNotificationAlreadyInCookie = (removedNotification) => {
  const closedNotifications = getClosedNotifications()
  const index = closedNotifications.indexOf(removedNotification.scope)
  // check if notification is already added to cookies
  return index !== -1
}

const removeNotification = (
  // @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
  id,
  {
    comesFromDirectUserAction = true,
    isHardCloseCheckboxChecked = false,
    shouldCloseVisibleNotification = true,
  } = {},
  // @ts-expect-error TS(7006) FIXME: Parameter '_state' implicitly has an 'any' type.
  _state,
) => {
  const { removedNotification, removedNotificationIndex } =
    getRemovedNotificationAndIndex(id, _state)
  /**
   * Sometimes `removeNotification()` called right after we've reset the app state
   * (this happens in `AppActionCreators.saveDrafts`) and so `state.visibleNotifications`
   * is empty. This check ensures we don't try and remove a non-existant notification.
   */
  if (!removedNotification) {
    return
  }

  if (shouldCloseVisibleNotification) {
    _state.visibleNotifications.splice(removedNotificationIndex, 1)
  }

  const rememberClosedNotification =
    removedNotification.onlyCloseOnce &&
    (!removedNotification.showSoftAndHardCloseOptions ||
      (removedNotification.showSoftAndHardCloseOptions &&
        isHardCloseCheckboxChecked)) &&
    comesFromDirectUserAction &&
    !isNotificationAlreadyInCookie(removedNotification)

  if (rememberClosedNotification) {
    const closedNotifications = getClosedNotifications()
    setClosedNotifications([...closedNotifications, removedNotification.scope])
  }
}

const removeAllNotificationsByScope = (
  // @ts-expect-error TS(7006) FIXME: Parameter 'scope' implicitly has an 'any' type.
  scope,
  // @ts-expect-error TS(7006) FIXME: Parameter 'comesFromDirectUserAction' implicitly h... Remove this comment to see the full error message
  comesFromDirectUserAction,
  // @ts-expect-error TS(7006) FIXME: Parameter '_state' implicitly has an 'any' type.
  _state,
) => {
  const notifications = _state.visibleNotifications.filter(
    // @ts-expect-error TS(7006) FIXME: Parameter 'notif' implicitly has an 'any' type.
    (notif) => notif.scope === scope,
  )
  // @ts-expect-error TS(7006) FIXME: Parameter 'notif' implicitly has an 'any' type.
  notifications.forEach((notif) =>
    removeNotification(notif.id, { comesFromDirectUserAction }, _state),
  )
}

// @ts-expect-error TS(7006) FIXME: Parameter 'id' implicitly has an 'any' type.
const removeNotificationCookie = (id, _state) => {
  const { removedNotification } = getRemovedNotificationAndIndex(id, _state)
  if (!removedNotification) {
    return
  }
  const closedNotifications = getClosedNotifications()
  const index = closedNotifications.indexOf(removedNotification.scope)
  if (isNotificationAlreadyInCookie(removedNotification)) {
    closedNotifications.splice(index, 1)
    setClosedNotifications(closedNotifications)
  }
}
// @ts-expect-error TS(7006) FIXME: Parameter 'draftId' implicitly has an 'any' type.
const removeComposerOmniboxNotices = (draftId, _state) => {
  const notifications = _state.visibleNotifications.filter(
    // @ts-expect-error TS(7006) FIXME: Parameter 'notif' implicitly has an 'any' type.
    (notif) =>
      notif.data.id === draftId &&
      notif.scope === NotificationScopes.MC_OMNIBOX_EDIT_NOTICE,
  )
  // @ts-expect-error TS(7006) FIXME: Parameter 'notif' implicitly has an 'any' type.
  notifications.forEach((notif) => removeNotification(notif.id, {}, _state))
}
// @ts-expect-error TS(7006) FIXME: Parameter 'notification' implicitly has an 'any' t... Remove this comment to see the full error message
const shouldShowNotification = (notification) => {
  if (!notification.onlyCloseOnce) return true

  const closedNotifications = getClosedNotifications()
  const wasAlreadyClosed = closedNotifications.includes(notification.scope)

  return !wasAlreadyClosed
}

// @ts-expect-error TS(7006) FIXME: Parameter 'draft' implicitly has an 'any' type.
export const notificationReducer = (draft, action) => {
  switch (action.actionType) {
    case ActionTypes.QUEUE_NOTIFICATION:
      {
        const { isUnique, ...actionData } = action
        // Remove prev notifications that share the same scope when a scope is identified as unique
        // eslint-disable-next-line no-use-before-define
        if (isUnique)
          removeAllNotificationsByScope(actionData.scope, false, draft)

        const id = uuid()
        let notification

        switch (actionData.type) {
          case NotificationTypes.ERROR:
            notification = Notification.getNewErrorNotification(id, actionData)
            break

          case NotificationTypes.SUCCESS:
            notification = Notification.getNewSuccessNotification(
              id,
              actionData,
            )
            break

          case NotificationTypes.INFO:
            notification = Notification.getNewInfoNotification(id, actionData)
            break

          default:
            return
        }

        if (shouldShowNotification(notification))
          draft.visibleNotifications.push(notification)
      }
      break

    case ActionTypes.REMOVE_NOTIFICATION:
      removeNotification(action.id, action.data, draft)
      break

    case ActionTypes.REMOVE_NOTIFICATION_COOKIE:
      removeNotificationCookie(action.id, draft)
      break

    case ActionTypes.REMOVE_ALL_NOTIFICATIONS_BY_SCOPE:
      removeAllNotificationsByScope(
        action.scope,
        action.comesFromDirectUserAction,
        draft,
      )
      break

    case ActionTypes.REMOVE_COMPOSER_NOTICES:
      removeComposerOmniboxNotices(action.draftId, draft)
      break

    case ActionTypes.APP_RESET:
      // we need to return the new state here because
      // doing `draft = notificationsSlice.getInitialState()` would replace the
      // object reference and won't work
      return notificationsSlice.getInitialState()
    default:
  }
}
