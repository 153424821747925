import { getPointAfter, wrapNodes } from '@udecode/plate'
import type { Range } from 'slate'
import type { BufferEditor } from '~publish/legacy/editor/BufferEditor/types.plate'
import { editorDebug } from '~publish/legacy/editor/editorDebug'
import { findCharacterLimitPoint } from '../../character-limit-highlight'
import {
  LinkedInAnnotationElement,
  type LinkedInEntityDetails,
} from '../nodes/LinkedInAnnotationElement'

const debug = editorDebug.extend('wrapLinkedInAnnotation')

export const wrapLinkedInAnnotation = (
  editor: BufferEditor,
  annotation: LinkedInEntityDetails & {
    start: number
    length: number
  },
): Range | undefined => {
  const anchor = findCharacterLimitPoint(editor, annotation.start)
  const focus =
    anchor &&
    getPointAfter(editor, anchor, {
      distance: annotation.length,
    })

  if (!anchor || !focus) {
    debug('skipping annotation: %s', annotation.entity, {
      start: annotation.start,
      length: annotation.length,
    })
    return
  }

  debug('wrappin annotation: %s', annotation.entity, { anchor, focus })
  wrapNodes(editor, LinkedInAnnotationElement.new(annotation), {
    at: { anchor, focus },
    split: true,
  })
}
