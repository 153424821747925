import { createAsyncThunk } from '@reduxjs/toolkit'
import { client, gql } from '~publish/legacy/apollo-client'
import type { AppDispatch, RootState } from '~publish/legacy/store'

type TAccount = {
  currentOrganization: {
    limits: {
      generateContent: number
    }
    billing: {
      canGenerateContent: boolean
      generateContentTokensLeft: number
    }
  }
}

const QUERY_LIMITS = gql`
  query queryLimits {
    account {
      id
      currentOrganization {
        id
        limits {
          generateContent
        }
        billing {
          ... on OBBilling {
            id
            canGenerateContent
            generateContentTokensLeft
          }
        }
      }
    }
  }
`

const queryLimits = createAsyncThunk<
  TAccount,
  void,
  { state: RootState; dispatch: AppDispatch }
>('ai/queryTokens', async (_, { rejectWithValue }) => {
  try {
    const response = await client.query<{ account: TAccount }>({
      query: QUERY_LIMITS,
    })

    return response.data?.account
  } catch (error) {
    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    return rejectWithValue(error.message)
  }
})

export { queryLimits }
