import { setNodes } from '@udecode/plate'
import type { BufferEditor } from '../../../BufferEditor/types.plate'
import { editorDebug } from '../../../editorDebug'
import { LinkElement } from '../nodes/LinkElement'

export function replaceLinkWithUnshortenedLink(
  editor: BufferEditor,
  unshortenedLink: string,
  shortLink: string,
): void {
  editorDebug(
    'replaceLinkWithUnshortenedLink %s %s',
    unshortenedLink,
    shortLink,
  )

  setNodes<LinkElement>(
    editor,
    {
      url: unshortenedLink,
      isShortened: false,
      shortUrl: shortLink,
    },
    {
      at: [],
      match: (n) =>
        LinkElement.is(n) && (n.shortUrl === shortLink || n.url === shortLink),
    },
  )
}
