import styled from 'styled-components'
import { fontFamily } from '@bufferapp/ui/style/fonts'
import { grayDarker } from '@bufferapp/ui/style/colors'

export const Text = styled.p`
  font-family: ${fontFamily};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  background: none;
  margin: 0;
  color: ${grayDarker};
`
