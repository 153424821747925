import type { RpcUpdate } from '~publish/legacy/post/types'

export type DailySlots = string[]

export interface SlotDay {
  text: string
  date: string
  dayOfWeek: string
  dayIndex: number
  dayUnixTime: number
}

export interface SlotItem {
  name: string
  label: string
  timestamp: number
  dayText: string
}

export enum QueueItemType {
  Header = 'header',
  Post = 'post',
  Slot = 'slot',
}

export interface QueueHeaderItem {
  queueItemType: QueueItemType.Header
  id: string
  text: string
  dayOfWeek?: string
  date?: string
}

export interface QueueSlotItem {
  queueItemType: QueueItemType.Slot
  id: string
  slot: SlotItem
  profileService: string
}

export interface BaseQueuePostItem {
  id: string
  day: string
  queueItemType: QueueItemType.Post
  hasCommentEnabled: boolean
  draggable?: boolean
  index?: number
}

export type QueueRpcUpdateItem = BaseQueuePostItem & RpcUpdate

export type QueuePostItem = QueueRpcUpdateItem

export type QueueItem = QueueHeaderItem | QueuePostItem | QueueSlotItem
