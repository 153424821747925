import { getEndPoint, type TNodeEntry } from '@udecode/plate'
import { useCallback } from 'react'
import type { Range } from 'slate'
import type { BufferEditor } from '../../BufferEditor/types.plate'
import { findCharacterLimitPoint } from '../../helpers/findCharacterLimitPoint'
import { CHARACTER_LIMIT_HIGHLIGHT_KEY } from './createCharacterLimitHighlightPlugin'

export type CharacterLimitRange = Range & {
  [CHARACTER_LIMIT_HIGHLIGHT_KEY]: boolean
}

const defaultCharacterCounter = (text: string) => text?.length

type usePlateCharacterLimits = (
  /**
   * A Plate editor instance
   */
  editor: BufferEditor,

  /**
   * The total amount of allowed characters in the editor
   */
  characterLimit: number | null,

  /**
   * A callback to calculate the current character count of
   * a given text string.
   * Defaults to (text) => text.length
   */
  getTextCharacterCount: (text: string) => number,

  /**
   * An optional callback to calculate any additional characters
   * to be added, for example, if attachments change the character count
   */
  getAdditionalCharacterCount?: (text: string) => number,
) => (nodeEntry: TNodeEntry) => CharacterLimitRange[]

export const usePlateCharacterLimits: usePlateCharacterLimits = (
  editor,
  characterLimit,
  getTextCharacterCount = defaultCharacterCounter,
  getAdditionalCharacterCount = () => 0,
) => {
  return useCallback(
    ([, path]: TNodeEntry) => {
      const newPointWhereLimitIsExceeded = findCharacterLimitPoint(
        editor,
        characterLimit,
        getTextCharacterCount,
        getAdditionalCharacterCount,
      )
      if (!newPointWhereLimitIsExceeded || path.length !== 0) {
        // TODO: Remove once LinkedInAnnotations are no longer void elements
        editor.decorateRanges = []
        return []
      }

      const characterLimitRanges = [
        {
          anchor: newPointWhereLimitIsExceeded,
          focus: getEndPoint(editor, []),
          [CHARACTER_LIMIT_HIGHLIGHT_KEY]: true,
        },
      ]
      // We store the ranges here everytime they're computed so we can update
      // the editor state with them. This is to accomodate void elements
      // that can't have ranges applied to them.
      // TODO: Remove once LinkedInAnnotations are no longer void elements
      editor.decorateRanges = characterLimitRanges

      return characterLimitRanges
    },
    [
      editor,
      characterLimit,
      getTextCharacterCount,
      getAdditionalCharacterCount,
    ],
  )
}
