import type { ServiceDefinition } from '~publish/legacy/constants/services/types'
import type { BufferEditorOptions } from '~publish/legacy/editor/BufferEditor/types'
import { createNewEditor } from '../../editor'
import { Draft } from './Draft'

export const getNewDraft = (
  service: ServiceDefinition,
  options?: BufferEditorOptions,
): Draft => {
  const editor = createNewEditor(service, options)

  return new Draft(service, editor)
}
