import React from 'react'

import { Empty } from '../Common/Empty'
import {
  draftPreviewPropType,
  profilePreviewPropType,
} from '../Common/propTypes'
import type { DraftPreview, PostType, ProfilePreview } from '../types'
import { TikTokPost } from './components/Post'

import { getMediaProperties } from '../Common/utils'

import * as Styles from './styles'

export const TikTokPreview = ({
  draft,
  profile,
}: {
  draft: DraftPreview
  profile: ProfilePreview
}): JSX.Element => {
  const containerRef = React.useRef<HTMLDivElement | null>(null)

  const draftTikTok: PostType = {
    content: {
      ...draft,
      text: draft.text,
    },
  }

  const { hasVideo, hasImages } = getMediaProperties(draftTikTok.content, null)

  if (!hasVideo && !hasImages) return <Empty />

  return (
    <Styles.Wrapper data-testid="tiktok-preview" ref={containerRef}>
      <TikTokPost content={draftTikTok.content} profile={profile} />
    </Styles.Wrapper>
  )
}

TikTokPreview.propTypes = {
  draft: draftPreviewPropType.isRequired,
  profile: profilePreviewPropType.isRequired,
}
