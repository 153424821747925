import React from 'react'
import MagicWandIcon from '@bufferapp/ui/Icon/Icons/MagicWand'

import * as Styled from './AIAssistantTrigger.styles'

/**
 *
 */
function AIAssistantTrigger({ onClick }: { onClick: () => void }): JSX.Element {
  return (
    <Styled.Button
      onClick={onClick}
      id="button-ai-assistant-v2"
      data-testid="button-ai-assistant"
    >
      <MagicWandIcon />
      AI Assistant
    </Styled.Button>
  )
}

export { AIAssistantTrigger }
