import React from 'react'
import { Avatar } from '@bufferapp/ui'

import { ChannelName, PostText } from '../styles'
import type { PublishingPostRetweet } from '~publish/gql/graphql'
import {
  ChannelWrapper,
  ChannelUsername,
  AttachmentWrapper,
  RetweetContent,
  RetweetHeader,
} from './style'

type RetweetItemProps = {
  retweet: PublishingPostRetweet
}
const RetweetItem = ({ retweet }: RetweetItemProps): JSX.Element => {
  return (
    <AttachmentWrapper>
      <RetweetHeader>
        <Avatar
          src={retweet.avatar ?? ''}
          fallbackUrl="https://s3.amazonaws.com/buffer-ui/Default+Avatar.png"
          alt={retweet.username}
          size="small"
          type="social"
          network="twitter"
        />
        <ChannelWrapper>
          <ChannelName type="p" color="grayDark">
            {retweet.accountName}
          </ChannelName>
          <ChannelUsername type="p" color="grayDark">
            @{retweet.username}
          </ChannelUsername>
        </ChannelWrapper>
      </RetweetHeader>
      <RetweetContent>
        <PostText type="p" color="grayDark">
          {retweet.text}
        </PostText>
      </RetweetContent>
    </AttachmentWrapper>
  )
}

export default RetweetItem
