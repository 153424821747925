import { borderRadius } from '@bufferapp/ui/style/borders'
import styled from 'styled-components'

export const ChannelContainer = styled.button<{
  isLocked: boolean
  isSelected: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: left;
  padding: 5px 10px;
  margin: 0 0 8px;
  gap: 3px;
  width: 100%;
  border-radius: ${borderRadius};
  border: none;
  background: none;
  background-color: ${({
    isLocked,
    isSelected,
  }: {
    isLocked: boolean
    isSelected: boolean
  }): string =>
    isLocked || isSelected ? 'var(--color-bg-brand-subtle)' : 'none'};
  cursor: ${({ isLocked }: { isLocked: boolean }): string =>
    !isLocked ? 'pointer' : 'default'};

  .new-post-button {
    display: none;
  }

  .default-view-item,
  status-view-item {
    min-width: 16px;
    margin-left: auto;
    text-align: right;
  }

  span,
  label {
    text-align: left;
  }

  &:hover {
    background-color: ${({
      isLocked,
      isSelected,
    }: {
      isLocked: boolean
      isSelected: boolean
    }): string =>
      !isLocked && !isSelected
        ? 'var(--color-bg-subtle)'
        : 'var(--color-bg-brand-subtle)'};

    .new-post-button {
      display: flex;
      margin-left: 5px;
    }

    .default-view-item {
      display: none;
    }
  }
`

export const ChannelName = styled.span`
  flex: 2;
  display: flex;
  flex-direction: column;
  margin-left: 12px;

  overflow: hidden;

  span,
  label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`
