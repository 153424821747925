import { useAppSelector } from '~publish/legacy/store'

export function useIsAtPlanLimit(): boolean {
  return useAppSelector((state) => {
    if (!state.organizations.selected) return false
    return (
      state.organizations.selected.profileLimit <=
      state.organizations.selected.profilesCount
    )
  })
}
