import React, { useCallback, useState } from 'react'
import {
  AlertDialog,
  Button,
  DropdownMenu,
  toast,
  TrashIcon,
} from '@buffer-mono/popcorn'

import { useDeletePost } from '../useDeletePost'
import { usePostData, useTrackActionClicked } from '../PostCardContext'

export function DeletePostMenuItem(): JSX.Element | null {
  const { id, allowedActions } = usePostData()
  const [deletePost, { loading }] = useDeletePost()
  const [dialogOpen, setDialogOpen] = useState(false)
  const trackPostAction = useTrackActionClicked()

  const handleConfirmDelete: React.MouseEventHandler = useCallback(
    async (e) => {
      e.preventDefault()

      const { success, error } = await deletePost({ variables: { id } })
      trackPostAction('deletePost-1')

      if (!success) {
        toast.error(`Failed to delete post: ${error.message}`)
        return
      }

      setDialogOpen(false)
      toast.success('Post was deleted')
    },
    [deletePost, id, trackPostAction],
  )

  if (!allowedActions.includes('deletePost')) {
    return null
  }

  return (
    <AlertDialog open={dialogOpen} onOpenChange={setDialogOpen}>
      <AlertDialog.Trigger>
        <DropdownMenu.Item
          variant="critical"
          // preventDefault is necessary so that dialog can pass onClick
          onSelect={(e): void => {
            e.preventDefault()
          }}
        >
          <TrashIcon />
          Delete
        </DropdownMenu.Item>
      </AlertDialog.Trigger>
      <AlertDialog.Content>
        <AlertDialog.Title>Delete post?</AlertDialog.Title>
        <AlertDialog.Description>
          This post will be permanently deleted. This action cannot be undone.
        </AlertDialog.Description>
        <AlertDialog.Actions>
          <AlertDialog.Cancel>
            <Button variant="tertiary">Cancel</Button>
          </AlertDialog.Cancel>
          <AlertDialog.Action>
            <Button
              variant="critical"
              loading={loading}
              onClick={handleConfirmDelete}
            >
              {loading ? 'Deleting...' : 'Delete'}
            </Button>
          </AlertDialog.Action>
        </AlertDialog.Actions>
      </AlertDialog.Content>
    </AlertDialog>
  )
}
