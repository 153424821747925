import React from 'react'
import dayjs from 'dayjs'
import * as DropdownMenu from '@radix-ui/react-dropdown-menu'
import { DateTimeInput } from '../styles'
import styles from '../../css/GoogleBusiness.module.css'
import DateTimeSlotPicker from '../../DateTimeSlotPicker'

const DatePicker = ({
  dateToRender,
  onDateChange,
  uses24hTime,
  inputClassName,
  placeholder,
}: {
  inputClassName?: string
  placeholder?: string
  dateToRender: dayjs.Dayjs
  onDateChange: (date: dayjs.Dayjs) => void
  uses24hTime: boolean
}): JSX.Element => {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>
        <DateTimeInput
          value={
            dateToRender.unix() === dayjs.utc().startOf('day').unix()
              ? 'Today'
              : dateToRender.format('MMM D, YYYY')
          }
          placeholder={placeholder}
          type="text"
          className={inputClassName}
        />
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content className={styles.dropdownContent}>
          <DateTimeSlotPicker
            onChange={(selectedDateTime): void =>
              onDateChange(selectedDateTime)
            }
            onSubmit={(): void => {}}
            shouldUse24hTime={uses24hTime}
            weekStartsMonday={false}
            initialDateTime={dateToRender}
            isDateOnly
            submitButtonCopy="Done"
            isDropdownItem
          />
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  )
}

export default DatePicker
