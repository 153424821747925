import { createAsyncThunk } from '@reduxjs/toolkit'
import type { Post } from '~publish/legacy/composer/composer/entities/Draft/Draft'
import type { RootState } from '~publish/legacy/store'
import callRpc from '~publish/legacy/utils/call-rpc'

export const shuffleQueue = createAsyncThunk<
  {
    updates: Post[]
  },
  { profileId: string; count: number },
  { state: RootState }
>('generalSettings/shuffleQueue', async ({ profileId, count }) => {
  const response = await callRpc('shuffleQueue', {
    profileId,
    count,
  })

  if (!response.updates) {
    throw new Error('No updates found in the response')
  }
  return response.updates
})
