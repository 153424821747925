import { useCallback } from 'react'
import { useMutation } from '@apollo/client'

import { useAppSelector } from '~publish/legacy/store'
import { selectCurrentOrganizationId } from '~publish/legacy/organizations/selectors'
import { logError } from '~publish/legacy/utils/logError'
import { graphql } from '~publish/gql'
import type { CreateIdeaGroupMutation } from '~publish/gql/graphql'

export const CreateIdeaGroup = graphql(/* GraphQL */ `
  mutation CreateIdeaGroup($organizationId: ID!, $name: String!) {
    createIdeaGroup(input: { organizationId: $organizationId, name: $name }) {
      ... on IdeaGroup {
        __typename
        id
        name
        isLocked
      }
      ... on MutationError {
        message
      }
    }
  }
`)

export const useCreateIdeaGroup = (): ((name: string) => Promise<void>) => {
  const organizationId: string = useAppSelector(selectCurrentOrganizationId)
  const [createIdeaGroupMutation] = useMutation(CreateIdeaGroup, {
    optimisticResponse: ({ name }) => ({
      createIdeaGroup: {
        __typename: 'IdeaGroup' as const,
        id: 'optimistic' + Math.random(),
        name: name.trim(),
        isLocked: false,
      },
    }),
    update: (cache, { data }) => {
      if (data?.createIdeaGroup.__typename === 'IdeaGroup') {
        cache.modify({
          fields: {
            ideaGroups(existingGroups = []) {
              const newGroupRef = cache.writeFragment({
                data: data.createIdeaGroup,
                fragment: graphql(/* GraphQL */ `
                  fragment NewIdeaGroup on IdeaGroup {
                    __typename
                    id
                    name
                    isLocked
                  }
                `),
              })
              return [...existingGroups, newGroupRef]
            },
          },
        })
      }
    },
  })

  return useCallback(
    async (name: string) => {
      try {
        const { data } = await createIdeaGroupMutation({
          variables: {
            organizationId,
            name: name.trim(),
          },
        })

        if (isError(data?.createIdeaGroup)) {
          throw new Error(
            data?.createIdeaGroup.message ?? 'Unexpected API response',
          )
        }
      } catch (error) {
        logError(error as Error, {
          metaData: { variables: { organizationId, name: name.trim() } },
        })
        throw error
      }
    },
    [createIdeaGroupMutation, organizationId],
  )
}

const isError = (
  response: CreateIdeaGroupMutation['createIdeaGroup'] | null | undefined,
): response is Exclude<
  CreateIdeaGroupMutation['createIdeaGroup'],
  { __typename?: 'IdeaGroup' }
> => {
  return !response || response?.__typename !== 'IdeaGroup'
}
