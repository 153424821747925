import ValidationSuccess from '~publish/legacy/validation/ValidationSuccess'
import ValidationFail from '~publish/legacy/validation/ValidationFail'
import {
  GBP_END_DATE_IN_PAST,
  GBP_INVALID_FORMAT_CTA_LINK,
  GBP_INVALID_FORMAT_REDEEM_LINK,
  GBP_INVALID_FORMAT_START_END_TIME,
  GBP_INVALID_HOUR,
  GBP_INVALID_MINUTES,
  GBP_MAX_CHAR_COUPON_CODE,
  GBP_MAX_CHAR_TERMS_CONDITIONS,
  GBP_MAX_CHAR_TITLE,
  GBP_MISSING_CTA_LINK,
  GBP_MISSING_EVENT_TITLE,
  GBP_MISSING_OFFER_TITLE,
  GBP_MISSING_START_END_DATE,
  GBP_MISSING_START_END_TIME,
  GBP_PHONE_NUMBER_RESTRICTED,
  GBP_START_DATE_AFTER_END_DATE,
  GBP_START_DATE_IN_PAST,
  GBP_START_TIME_BEFORE_END_TIME,
} from '~publish/legacy/composer/composer/lib/validation/utils/validationErrorMessages'
import dayjs from 'dayjs'
import { transformStringTo24HrTime } from '~publish/legacy/composer/composer/utils/StringTimeTransformer'
import { isValidHttpUrl } from '~publish/legacy/composer/composer/lib/validation/utils/isValidHttpUrl'
import { isValidURL } from '~publish/legacy/grid/util'
import type ValidationResult from '~publish/legacy/validation/ValidationResult'
import type { GoogleBusinessChannelData } from '~publish/legacy/composer/composer/entities/Draft'
import { VALIDATION_CODE } from '~publish/legacy/validation/constants'

const validateGBPTitle = (
  updateType: string | null,
  gbpData?: GoogleBusinessChannelData,
): ValidationResult => {
  // validation only for Event and Offer GBP posts
  if (updateType === 'whats_new') {
    return new ValidationSuccess()
  }

  if (!gbpData?.title) {
    return new ValidationFail(
      updateType === 'offer'
        ? GBP_MISSING_OFFER_TITLE
        : GBP_MISSING_EVENT_TITLE,
      VALIDATION_CODE.MISSING_VALUE,
    )
  }

  if (gbpData?.title.length > 58) {
    return new ValidationFail(
      GBP_MAX_CHAR_TITLE,
      VALIDATION_CODE.MAX_LIMIT_REACHED,
    )
  }

  return new ValidationSuccess()
}

const validateGBPCouponCode = (
  updateType: string | null,
  gbpData?: GoogleBusinessChannelData,
): ValidationResult => {
  // validation only for Offer GBP posts
  if (updateType !== 'offer') {
    return new ValidationSuccess()
  }

  if (!gbpData?.code) {
    return new ValidationSuccess()
  }

  if (gbpData.code.length > 58) {
    return new ValidationFail(
      GBP_MAX_CHAR_COUPON_CODE,
      VALIDATION_CODE.MAX_LIMIT_REACHED,
    )
  }

  return new ValidationSuccess()
}

const validateGBPDateTime = (
  updateType: string | null,
  gbpData?: GoogleBusinessChannelData,
): ValidationResult => {
  // validation only for Offer and Event GBP posts containing dates
  if (updateType === 'whats_new') {
    return new ValidationSuccess()
  }

  if (!gbpData?.start_date || !gbpData?.end_date) {
    return new ValidationFail(
      GBP_MISSING_START_END_DATE,
      VALIDATION_CODE.MISSING_VALUE,
    )
  }

  if (
    gbpData.start_date > gbpData.end_date ||
    (!gbpData?.start_time &&
      !gbpData?.end_time &&
      gbpData.start_date === gbpData.end_date)
  ) {
    return new ValidationFail(
      GBP_START_DATE_AFTER_END_DATE,
      VALIDATION_CODE.DATE_TIME_ERROR,
    )
  }

  if (
    (gbpData?.start_time && !gbpData?.end_time) ||
    (!gbpData?.start_time && gbpData?.end_time)
  ) {
    return new ValidationFail(
      GBP_MISSING_START_END_TIME,
      VALIDATION_CODE.DATE_TIME_ERROR,
    )
  }

  if (
    parseInt(gbpData.start_date.toString(), 10) + 86400 <
    dayjs().startOf('day').unix()
  ) {
    return new ValidationFail(
      GBP_START_DATE_IN_PAST,
      VALIDATION_CODE.DATE_TIME_ERROR,
    )
  }

  if (!gbpData?.start_time && !gbpData?.end_time) {
    return new ValidationSuccess()
  }

  const pattern = /^\d?\d:?\d?\d?\s*([AaPp][mM])?$/
  if (
    gbpData.start_time &&
    gbpData.end_time &&
    (!pattern.test(gbpData.start_time.trim()) ||
      !pattern.test(gbpData.end_time.trim()))
  ) {
    return new ValidationFail(
      GBP_INVALID_FORMAT_START_END_TIME,
      VALIDATION_CODE.DATE_TIME_ERROR,
    )
  }

  const startTime = transformStringTo24HrTime(gbpData.start_time as string)
  const endTime = transformStringTo24HrTime(gbpData.end_time as string)

  if (
    gbpData.start_date === gbpData.end_date &&
    startTime.hours > endTime.hours
  ) {
    return new ValidationFail(
      GBP_START_TIME_BEFORE_END_TIME,
      VALIDATION_CODE.DATE_TIME_ERROR,
    )
  }

  if (
    gbpData.start_date === gbpData.end_date &&
    startTime.hours === endTime.hours &&
    startTime.minutes >= endTime.minutes
  ) {
    return new ValidationFail(
      GBP_START_TIME_BEFORE_END_TIME,
      VALIDATION_CODE.DATE_TIME_ERROR,
    )
  }

  if (
    startTime.hours > 23 ||
    endTime.hours > 23 ||
    startTime.hours < 0 ||
    endTime.hours < 0
  ) {
    return new ValidationFail(GBP_INVALID_HOUR, VALIDATION_CODE.DATE_TIME_ERROR)
  }

  if (
    startTime.minutes > 59 ||
    endTime.minutes > 59 ||
    startTime.minutes < 0 ||
    endTime.minutes < 0
  ) {
    return new ValidationFail(
      GBP_INVALID_MINUTES,
      VALIDATION_CODE.DATE_TIME_ERROR,
    )
  }

  if (
    parseInt(gbpData.end_date.toString(), 10) ===
      dayjs().utc().startOf('day').unix() &&
    dayjs().unix() >
      dayjs()
        .startOf('day')
        .add(endTime.hours, 'hour')
        .add(endTime.minutes, 'minute')
        .unix()
  ) {
    return new ValidationFail(
      GBP_END_DATE_IN_PAST,
      VALIDATION_CODE.DATE_TIME_ERROR,
    )
  }

  return new ValidationSuccess()
}

const validateGBPCTA = (
  updateType: string | null,
  gbpData?: GoogleBusinessChannelData,
): ValidationResult => {
  // validation only for What's New and Event GBP posts
  if (updateType === 'offer') {
    return new ValidationSuccess()
  }

  if (gbpData?.cta && gbpData.cta !== 'none' && !gbpData.link) {
    return new ValidationFail(
      GBP_MISSING_CTA_LINK,
      VALIDATION_CODE.MISSING_VALUE,
    )
  }

  if (
    gbpData?.cta &&
    gbpData.cta !== 'none' &&
    !isValidHttpUrl(gbpData.link as string)
  ) {
    return new ValidationFail(
      GBP_INVALID_FORMAT_CTA_LINK,
      VALIDATION_CODE.INVALID_VALUE,
    )
  }

  return new ValidationSuccess()
}

const validateGBPRedeemLink = (
  updateType: string | null,
  gbpData?: GoogleBusinessChannelData,
): ValidationResult => {
  // validation only for Offer GBP posts
  if (updateType !== 'offer') {
    return new ValidationSuccess()
  }

  if (gbpData?.link && !isValidURL(gbpData.link)) {
    return new ValidationFail(
      GBP_INVALID_FORMAT_REDEEM_LINK,
      VALIDATION_CODE.INVALID_VALUE,
    )
  }

  return new ValidationSuccess()
}

const validateGBPTerms = (
  updateType: string | null,
  gbpData?: GoogleBusinessChannelData,
): ValidationResult => {
  // validation only for Offer GBP posts
  if (updateType !== 'offer') {
    return new ValidationSuccess()
  }

  if (gbpData?.terms && gbpData.terms.length > 5000) {
    return new ValidationFail(
      GBP_MAX_CHAR_TERMS_CONDITIONS,
      VALIDATION_CODE.MAX_LIMIT_REACHED,
    )
  }

  return new ValidationSuccess()
}

const validateGBPChannelData = (
  updateType: string | null,
  gbpData?: GoogleBusinessChannelData,
): ValidationFail[] => {
  return [
    validateGBPCTA(updateType, gbpData),
    validateGBPTitle(updateType, gbpData),
    validateGBPDateTime(updateType, gbpData),
    validateGBPRedeemLink(updateType, gbpData),
    validateGBPTerms(updateType, gbpData),
    validateGBPCouponCode(updateType, gbpData),
  ].filter((validation): validation is ValidationFail =>
    validation.isValidationFail(),
  )
}

const validateGBPContainsPhoneNumber = (text = ''): ValidationResult => {
  const regexMatch = text.match(
    /(?:\d{1,2}[-\s]?)?\d{3}[-\s]?\d{3}[-\s]?\d{3}/g,
  )

  if (regexMatch) {
    return new ValidationFail(
      GBP_PHONE_NUMBER_RESTRICTED,
      VALIDATION_CODE.INVALID_VALUE,
    )
  }

  return new ValidationSuccess()
}

export const gbpChannelDataValidation = {
  validateGBPCTA,
  validateGBPCouponCode,
  validateGBPDateTime,
  validateGBPRedeemLink,
  validateGBPTerms,
  validateGBPTitle,
  validateGBPChannelData,
  validateGBPContainsPhoneNumber,
}
