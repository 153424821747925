import React from 'react'
import styled from 'styled-components'

import { borderRadius } from '@bufferapp/ui/style/borders'
import { gray, grayDark, white } from '@bufferapp/ui/style/colors'
import * as HoverCard from '@radix-ui/react-hover-card'

export const PopoverContent = styled(HoverCard.Content)`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  border-radius: ${borderRadius};
  background: ${grayDark};
  z-index: 26;
`

export const StyledArrow = styled(HoverCard.Arrow).attrs({
  fill: grayDark,
})``

export const TooltipButton = styled.button`
  border: none;
  background: ${grayDark};
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: ${white};
  padding: 8px 12px;
  cursor: pointer;
  width: 90px;
  border-radius: ${borderRadius};
`

export const TooltipText = styled.span`
  max-width: 260px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: normal;
  display: inline-block;
  padding: 8px 12px;
  background: ${grayDark};
  color: ${white};
  font-size: 14px;
  line-height: 16px;
  cursor: default;
  border-right: 1px solid ${gray};
  border-radius: ${borderRadius} 0 0 ${borderRadius};
`

export const EditorTooltip = ({
  children,
  trigger,
}: {
  children: React.ReactNode
  trigger: React.ReactNode
}) => {
  // The container does what it's expected, but the type Element
  // doesn't exactly match the expected type HTMLElement required
  // by Radix so we cast it.
  // @ts-expect-error TS(2488) FIXME: Type 'HTMLCollectionOf<Element>' must have a '[Sym... Remove this comment to see the full error message
  const [container] = document.getElementsByClassName('editor-container')

  return (
    <HoverCard.Root>
      <HoverCard.Trigger asChild>{trigger}</HoverCard.Trigger>
      <HoverCard.Portal container={container as HTMLElement}>
        <PopoverContent>
          {children}
          <StyledArrow />
        </PopoverContent>
      </HoverCard.Portal>
    </HoverCard.Root>
  )
}
