import type { InitialAccountContext } from './types'

// TODO: We should not be using this as a default value. An account either
// exists with all its proper values, or it doesn't exist at all.
// Having this kind of initialization leads to misleading types on
// `InitialAccountContext` type definition.
// JIRA: https://buffer.atlassian.net/browse/EPD-3075
export const initialAccountContextState: InitialAccountContext = {
  account: {
    id: null,
    email: null,
    createdAt: null,
    dismissedBanners: [''],
    currentOrganization: {
      // id of the organization is not nullable
      id: '',
      isOneBufferOrganization: true,
      isBetaEnabled: false,
      createdAt: null,
      channels: [],
      members: {
        totalCount: 0,
      },
      privileges: {},
      commonTrackingProperties: {
        atEventBillingState: null,
        atEventBillingPlan: null,
        atEventBillingCycle: null,
        atEventBillingGateway: null,
        atEventIsNewBuffer: null,
        atEventChannelQty: null,
      },
      entitlements: [],
      dismissedObjects: [],
      billing: {
        canStartTrial: false,
        subscription: {
          interval: null,
          plan: {
            id: null,
          },
        },
        changePlanOptions: [
          {
            planId: null,
            planInterval: null,
            isRecommended: false,
          },
        ],
        gateway: {
          gatewayType: null,
        },
        paymentDetails: {
          hasPaymentDetails: false,
        },
      },
      role: '',
      limits: {
        ideas: 0,
        channels: 0,
        tags: 0,
      },
      ownerEmail: null,
    },
  },
}
