import keyWrapper from '~publish/helpers/keywrapper'

type RemindersState = {
  showInstagramNotificationsPreview: boolean
}

export const initialState: RemindersState = {
  showInstagramNotificationsPreview: false,
}

export const actionTypes = keyWrapper('GUIDES', {
  SET_SHOW_INSTAGRAM_NOTIFICATIONS_PREVIEW: 0,
})

type RemindersAction = {
  type: (typeof actionTypes)[keyof typeof actionTypes]
  showInstagramNotificationsPreview: boolean
}

export default (
  state: RemindersState = initialState,
  action: RemindersAction,
) => {
  switch (action.type) {
    case actionTypes.SET_SHOW_INSTAGRAM_NOTIFICATIONS_PREVIEW:
      return {
        ...state,
        showInstagramNotificationsPreview:
          action.showInstagramNotificationsPreview,
      }
    default:
      return state
  }
}

export const actions = {
  showInstagramNotificationsPreview: (arg: boolean) => ({
    type: actionTypes.SET_SHOW_INSTAGRAM_NOTIFICATIONS_PREVIEW,
    showInstagramNotificationsPreview: arg,
  }),
}
