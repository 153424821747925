import styled from 'styled-components'
import { Button } from '~publish/legacy/ai/components/AIAssistant/components/Button.styles'
import { purple, white, grayDark, grayLight } from '@bufferapp/ui/style/colors'
import { fontFamily } from '@bufferapp/ui/style/fonts'

export const MenuButton = styled(Button)<{
  top: number
  left: number
  secondary: boolean
}>`
  width: 130px;
  top: ${({ top }): number => top}px;
  left: ${({ left }): number => left}px;
  position: absolute;
  z-index: 9999;
  background-color: ${white};

  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  border: 1px solid ${grayLight};
  padding: 0;

  transition: left 0.3s ease;
`

export const ButtonContent = styled.p`
  margin: 0;
  padding: 8px 7px;
  color: ${grayDark};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  font-family: ${fontFamily};
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
  gap: 8px;

  &:hover {
    background: linear-gradient(90deg, #ba6bff 0%, #0113ff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  svg {
    color: ${purple};
    fill: ${purple};
  }
`
