import AppDispatcher from '../../dispatcher'
import { ActionTypes } from '../../state/ActionTypes'

const ActionCreators = {
  // @ts-expect-error TS(7006) FIXME: Parameter 'element' implicitly has an 'any' type.
  closeModal: (element) =>
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.CLOSE_MODAL,
      element,
    }),

  // @ts-expect-error TS(7006) FIXME: Parameter 'name' implicitly has an 'any' type.
  openModal: (name, props) =>
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.OPEN_MODAL,
      name,
      props,
    }),

  // @ts-expect-error TS(7006) FIXME: Parameter 'message' implicitly has an 'any' type.
  setAppNotificationMessage: (message) =>
    AppDispatcher.handleViewAction({
      actionType: ActionTypes.SET_APP_NOTIFICATION_MESSAGE,
      message,
    }),
}

export default ActionCreators
