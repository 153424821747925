import { useCallback, useMemo, useState } from 'react'

type DismissableBanner = {
  key: string
  // todo: we can later check the ttl and show again the value
  ttl?: number
}

export const DraftsNotInReportsBanner: DismissableBanner = {
  key: 'drafts_not_in_reports_dismissable_banner',
}

export const useDismissibleBanner = (
  banner: DismissableBanner,
): { isDismissed: boolean; setDismissed: CallableFunction } => {
  const [dismissedValue, setDismissedValue] = useState(
    localStorage.getItem(banner.key),
  )

  const isDismissed = useMemo(() => dismissedValue !== null, [dismissedValue])

  const setDismissed = useCallback(() => {
    const nowString = new Date().toISOString()
    localStorage.setItem(banner.key, nowString)
    setDismissedValue(nowString)
  }, [])

  return { isDismissed, setDismissed }
}
