/**
 * See the code in `server/index.js` to see how the modal
 * to show on load is passed via a query param.
 */

const getShowModalKey = () => {
  // @ts-expect-error TS(2339) FIXME: Property '_showModal' does not exist on type 'Wind... Remove this comment to see the full error message
  if (window._showModal && window._showModal.key) {
    // @ts-expect-error TS(2339) FIXME: Property '_showModal' does not exist on type 'Wind... Remove this comment to see the full error message
    return window._showModal.key
  }
  return ''
}

export const resetShowModalKey = () => {
  // @ts-expect-error TS(2339) FIXME: Property '_showModal' does not exist on type 'Wind... Remove this comment to see the full error message
  if (window._showModal && window._showModal.key) {
    // @ts-expect-error TS(2339) FIXME: Property '_showModal' does not exist on type 'Wind... Remove this comment to see the full error message
    window._showModal = null
  }
}

export const shouldShowInstagramFirstCommentModal = () =>
  getShowModalKey() === 'instagram-first-comment-modal'

/**
 * Extracts a source from the modal key in the URL
 * 'upgrade-to-pro--profile_limit' => 'profile_limit'
 */
export const getSourceFromKey = () => {
  const key = getShowModalKey()
  return key.split('--')[1] || 'unknown'
}
