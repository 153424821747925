import hashtagRegex from 'hashtag-regex'

const regex = hashtagRegex()

const countHashtagsInText = (text: string): number => {
  let counter = 0

  while (regex.exec(text)) {
    counter += 1
  }

  return counter
}

export default countHashtagsInText
