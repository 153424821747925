import type { UserS3UploadSignature } from '~publish/legacy/user/types'
import { type ApolloQueryResult, gql, useApolloClient } from '@apollo/client'
import { useCallback, useEffect } from 'react'

const TTL_IN_MS = 5 * 60 * 1000

export const GET_S3_SIGNATURE = gql`
  query GetS3Signature($input: S3SignatureInput!) {
    s3Signature(input: $input) {
      bucket
      signature
      base64policy
      actionStatus
      algorithm
      credentials
      date
      expires
      temporarySecurityToken
    }
  }
`

export function useS3Signature(): () => Promise<
  ApolloQueryResult<{ s3Signature: UserS3UploadSignature }>
> {
  const client = useApolloClient()

  useEffect(() => {
    const interval = setInterval(() => {
      client.cache.evict({ fieldName: 's3Signature' })
    }, TTL_IN_MS)

    return () => {
      client.cache.evict({ fieldName: 's3Signature' })
      clearInterval(interval)
    }
  }, [client.cache])

  return useCallback(
    () =>
      client
        .query({
          query: GET_S3_SIGNATURE,
          variables: {
            input: {
              bucket: 'buffer-media-uploads',
            },
          },
        })
        .then((result) => {
          return result.data.s3Signature
        }),
    [client],
  )
}
