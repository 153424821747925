import React from 'react'

import * as Styled from './Button.styles'

/**
 *
 */
function Button({
  disabled,
  secondary,
  type,
  align,
  onClick,
  children,
  fullWidth,
  testId,
}: {
  disabled?: boolean
  secondary?: boolean
  align?: 'left' | 'center'
  type?: 'button' | 'submit' | 'reset'
  fullWidth?: boolean
  testId?: string
  onClick: (event: React.MouseEvent) => void
  children: React.ReactNode | JSX.Element
}): JSX.Element {
  return (
    <Styled.Button
      onClick={onClick}
      disabled={disabled}
      secondary={secondary}
      type={type || 'button'}
      align={align || 'center'}
      fullWidth={fullWidth}
      data-testid={testId}
    >
      {children}
    </Styled.Button>
  )
}

export { Button }
