import React from 'react'
import { ComposerSidepanel } from '~publish/legacy/shared-components'
import { AIAssistant } from '~publish/legacy/ai/components/AIAssistant/AIAssistant'
import type { BufferEditor } from '~publish/legacy/editor/BufferEditor/types.plate'
import { useAppDispatch, useAppSelector } from '~publish/legacy/store'
import {
  selectAIAssistantResponsive,
  toggleAIAssistant,
} from '~publish/legacy/ai/state/slice'

export const AIAssistantSidePanel = ({
  editor,
  channel,
  onTop,
  animated,
  state,
  onAnimationEnd,
}: {
  editor: BufferEditor
  channel?: string
  onTop?: boolean
  animated?: boolean
  state?: string
  onAnimationEnd?: () => void
}): JSX.Element => {
  const AIAssistantResponsive = useAppSelector((state) =>
    selectAIAssistantResponsive(state),
  )
  const dispatch = useAppDispatch()

  const onCloseSidebar = (): void => {
    dispatch(toggleAIAssistant(false))
  }

  return (
    <ComposerSidepanel
      isVisible={true}
      showCloseButton
      customStyles={{
        left: AIAssistantResponsive ? 'auto' : '0',
        width: '364px',
        height: '100%',
        overflowY: 'auto',
        zIndex: onTop ? '1' : '0',
      }}
      closeButtonPosition={{
        top: '27px',
        right: '27px',
      }}
      onClose={onCloseSidebar}
      animated={animated}
      state={state}
      onAnimationEnd={onAnimationEnd}
      side={AIAssistantResponsive ? 'right' : 'left'}
    >
      <AIAssistant editor={editor} channel={channel} />
    </ComposerSidepanel>
  )
}
