import { MediaTypes } from '~publish/legacy/constants'

type Asset = {
  id: string
  // let's consider source or sourceURL as the name of this property
  url: string
  // let's consider assetType as the name of this property
  mediaType: string
}

export type DocumentTitle = {
  title: string
  mediaType: typeof MediaTypes.DOCUMENT
}

export type Document = Asset &
  DocumentTitle & {
    pageCount: number | null
    thumbnailUrl?: string
    fileSizeBytes?: number
  }

export type PostMediaWithDocument = {
  progress: 100
  uploaded: true
  uploading_type: 'document'
  document: {
    id: string
    title: Document['title']
    details: Pick<Document, 'pageCount' | 'fileSizeBytes'> & {
      location: Document['url']
    }
  }
  thumbnail?: string
}

export const Document = {
  toPostMedia: (document: Document): PostMediaWithDocument => {
    return {
      progress: 100,
      uploaded: true,
      uploading_type: 'document',
      document: {
        id: document.id,
        title: document.title,
        details: {
          location: document.url,
          pageCount: document.pageCount,
          fileSizeBytes: document.fileSizeBytes,
        },
      },
      thumbnail: document.thumbnailUrl,
    }
  },

  fromPostMedia: (postMedia?: PostMediaWithDocument): Document | null => {
    if (!postMedia?.document) return null

    return {
      id: postMedia.document.id,
      url: postMedia.document.details.location,
      mediaType: MediaTypes.DOCUMENT,
      title: postMedia.document.title ?? '',
      pageCount: postMedia.document.details.pageCount,
      thumbnailUrl: postMedia.thumbnail,
    }
  },

  isDocument: (media: unknown): media is Document => {
    return !!(
      media &&
      media !== null &&
      typeof media === 'object' &&
      'id' in media &&
      'mediaType' in media &&
      media.mediaType === MediaTypes.DOCUMENT
    )
  },

  isUploadingDocument: (media: unknown): media is DocumentTitle => {
    return !!(
      media &&
      media !== null &&
      typeof media === 'object' &&
      'mediaType' in media &&
      media.mediaType === MediaTypes.DOCUMENT &&
      !('id' in media)
    )
  },
}
