import styled, { type FlattenSimpleInterpolation } from 'styled-components'

type CSSRule = string | number | false | FlattenSimpleInterpolation | undefined

// eslint-disable-next-line import/prefer-default-export
export const PendingTiktokBannerWrapper = styled.div<{
  background: string
}>`
  display: flex;
  align-items: center;
  background-color: ${(props): CSSRule => props.background};
  padding: 8px;
`
