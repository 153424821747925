import type React from 'react';
import { useCallback, useEffect, useState } from 'react'

type CallableInitialState<T> = () => T
type InitialState<T> = T | CallableInitialState<T>

const isCallableInitialState = <T>(
  initialState: InitialState<T>,
): initialState is CallableInitialState<T> => typeof initialState === 'function'

type CallableSetState<T> = (value: T) => T
type SetState<T> = T | CallableSetState<T>
const isCallableSetState = <T>(
  setState: T | CallableSetState<T>,
): setState is CallableSetState<T> => typeof setState === 'function'

type HookType<T> = () => [T, React.Dispatch<SetState<T>>]
export const makeUseGlobalStateHook = <T>(
  initialState: InitialState<T>,
): HookType<T> => {
  let state: T = isCallableInitialState(initialState)
    ? initialState()
    : initialState
  let listeners: ((update: T) => void)[] = []
  const useGlobalState = (): [T, React.Dispatch<SetState<T>>] => {
    const [localState, setLocalState] = useState<T>(state)
    const setState = useCallback((value: SetState<T>) => {
      if (isCallableSetState(value)) {
        state = value(state)
      } else {
        state = value
      }
      setLocalState(value)
      listeners.forEach((listener) => listener(state))
    }, [])
    useEffect(() => {
      listeners.push(setLocalState)
      return () => {
        listeners = listeners.filter((listener) => listener !== setState)
      }
    }, [setState])
    return [localState, setState]
  }
  return useGlobalState
}
