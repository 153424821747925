import React from 'react'
import PropTypes from 'prop-types'
import PostMetaBar from '../../PostMetaBar'

const InstagramPostMetaBar = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'dragging' implicitly has an 'any'... Remove this comment to see the full error message
  dragging,
  // @ts-expect-error TS(7031) FIXME: Binding element 'locationName' implicitly has an '... Remove this comment to see the full error message
  locationName,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isSent' implicitly has an 'any' t... Remove this comment to see the full error message
  isSent,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isPastReminder' implicitly has an... Remove this comment to see the full error message
  isPastReminder,
}) => {
  const leftContent = { title: 'Location:', text: locationName }
  return (
    <PostMetaBar
      dragging={dragging}
      leftContent={leftContent}
      isSent={isSent}
      // @ts-expect-error TS(2322) FIXME: Type '{ dragging: any; leftContent: { title: strin... Remove this comment to see the full error message
      isPastReminder={isPastReminder}
    />
  )
}

InstagramPostMetaBar.propTypes = {
  locationName: PropTypes.string,
  dragging: PropTypes.bool,
  isSent: PropTypes.bool,
  isPastReminder: PropTypes.bool,
}

export default InstagramPostMetaBar
