import MusicIcon from '@bufferapp/ui/Icon/Icons/Music'
import { gray, grayLighter, red, white } from '@bufferapp/ui/style/colors'
import styled, { css } from 'styled-components'

import AddIcon from '@bufferapp/ui/Icon/Icons/Add'
import BookmarkFilledIcon from '@bufferapp/ui/Icon/Icons/BookmarkFilled'
import UICaptionIcon from '@bufferapp/ui/Icon/Icons/Caption'
import CommentRoundFilledIcon from '@bufferapp/ui/Icon/Icons/CommentRoundFilled'
import HeartFilledIcon from '@bufferapp/ui/Icon/Icons/HeartFilled'
import UiSearchIcon from '@bufferapp/ui/Icon/Icons/Search'
import UIImageIcon from '@bufferapp/ui/Icon/Icons/Image'
import ShareArrowFilledIcon from '@bufferapp/ui/Icon/Icons/ShareArrowFilled'
import { ImagesCarousel as ImagesCarouselCommon } from '../../../Common/ImagesCarousel'
import { ImagesGrid as ImagesGridCommon } from '../../../Common/ImagesGrid'
import { PostWrapper as PostWrapperCommon } from '../../../Common/styles'
import { VideoPlayer as VideoPlayerCommon } from '../../../Common/VideoPlayer'
import {
  PlayButton as CommonPlayButton,
  Video as CommonVideo,
} from '../../../Common/VideoPlayer/styles'

export const fontCss = css`
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 14px;
  color: ${white};
`

export const IconCss = css`
  width: 26px;
  height: 26px;
  color: ${white};
`

export const CaptionIcon = styled(UICaptionIcon)`
  ${IconCss};
  width: 18px;
  height: 18px;
  color: ${gray};
`

export const ImageIcon = styled(UIImageIcon)`
  ${IconCss};
  width: 18px;
  height: 18px;
  color: ${white};
  margin-right: 1px;
  vertical-align: middle !important;
`

export const PhotoLabel = styled.div`
  ${fontCss};
  font-weight: bold;
  line-height: 16px;
  font-size: 11px;
  display: inline;
  vertical-align: middle;
`

export const IconImageWrapper = styled.div`
  background-color: rgba(224, 224, 224, 0.25);
  border-radius: 5px;
  display: inline;
  margin-left: 6px;
  padding: 5px 6px;
`

export const ShareIcon = styled(ShareArrowFilledIcon)`
  ${IconCss};
  width: 24px !important;
  height: 24px !important;
`

export const HeartIcon = styled(HeartFilledIcon)`
  ${IconCss};
  width: 24px !important;
  height: 24px !important;
`

export const BookmarkIcon = styled(BookmarkFilledIcon)`
  ${IconCss};
  width: 24px !important;
  height: 24px !important;
`

export const CommentIcon = styled(CommentRoundFilledIcon)`
  ${IconCss};
  width: 24px !important;
  height: 24px !important;
`

export const Video = css`
  ${CommonVideo}
`

export const PlayButton = css`
  ${CommonPlayButton}
  background-color: rgba(255, 255, 255, 0.8);

  svg {
    color: rgba(0, 0, 0, 0.8);
    fill: rgba(0, 0, 0, 0.8);
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
    svg {
      color: rgba(0, 0, 0, 0.9);
      fill: rgba(0, 0, 0, 0.9);
    }
  }
`

export const PostWrapper = styled(PostWrapperCommon)`
  border: none;
  border-radius: 0;
`

export const MetadataWrapper = styled.div`
  position: absolute;
  bottom: 18px;
  width: calc(100% - 62px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 12px;
`

export const Caption = styled.div`
  ${fontCss};
  width: 100%;
  overflow-wrap: break-word;
  white-space: break-spaces;
  text-overflow: ellipsis;
  margin-top: 6px;
`

export const SeeMore = styled.div`
  ${fontCss};
  font-weight: bold;
  line-height: 16px;
  display: inline;
`

export const UsernameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 6px;
`

export const Username = styled.span`
  display: inline-block;
  ${fontCss};
  font-size: 12px;
  font-weight: 600;
  line-height: 12px;
`

export const VideoWrapper = css`
  position: relative;
  width: 100%;
  height: 588px;

  video {
    ${Video}
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.2) 8.17%,
      rgba(0, 0, 0, 0) 20.79%,
      rgba(0, 0, 0, 0) 71.68%,
      rgba(0, 0, 0, 0.2) 83.07%
    );
    pointer-events: none;
  }

  button {
    ${PlayButton}
  }
`

export const VideoPlayer = styled(VideoPlayerCommon)`
  ${VideoWrapper}
`

const textWrapCss = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 12px;
  color: ${white};
`

export const ExtraData = styled.div`
  display: flex;
  width: 100%;
  margin-top: 10px;
  align-items: center;
`

export const Audio = styled.div`
  display: flex;
  align-items: center;
  margin-right: 4px;
  ${textWrapCss};
`

export const WrappedText = styled.span`
  ${fontCss};
  display: block;
  ${textWrapCss};
  margin-right: 6px;
`

export const Music = styled(MusicIcon)`
  width: 10px;
  height: 10px;
  margin-right: 4px;
`

export const TopBarText = styled.div`
  position: absolute;
  top: 36px;
  left: 0px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 13px;
  letter-spacing: 0.5px;
`

export const FollowingText = styled.span`
  ${fontCss};
  font-size: 11px;
  color: ${grayLighter};
`

export const ForYouText = styled.span`
  ${fontCss};
  font-weight: 600;
  font-size: 11px;
`

export const SelectedTextBar = styled.div`
  position: absolute;
  top: 19px;
  right: 108px;

  width: 19px;
  height: 0px;

  border: 1px solid ${white};
  transform: rotate(180deg);
`

export const SearchIcon = styled(UiSearchIcon)`
  ${IconCss};
  position: absolute;
  width: 21px;
  height: 21px;
  top: 34px;
  right: 14px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
`

export const AudioAvatarWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const AudioAvatarBackground = styled.div`
  width: 31px;
  height: 31px;
  border-radius: 100%;

  background: linear-gradient(
    310.03deg,
    #363635 13.36%,
    #757575 49.18%,
    #101010 88.11%
  );
`

export const AudioAvatar = styled.div<{ src: string; fallbackUrl: string }>`
  border-radius: 100%;
  mask-size: cover;
  -webkit-mask-size: cover;
  width: 17px;
  height: 17px;
  position: absolute;
  background: url(${({ src }) => src}) center no-repeat,
    url(${({ fallbackUrl }) => fallbackUrl}) center no-repeat;
  background-size: 24px;
`

export const IconsSideSection = styled.div`
  position: absolute;
  right: 12px;
  bottom: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
`

export const AvatarWrapper = styled.div`
  position: relative;
  margin-bottom: 7px;
`

export const Avatar = styled.div<{ src: string; fallbackUrl: string }>`
  width: 35px;
  height: 35px;
  border-radius: 100%;
  mask-size: cover;
  -webkit-mask-size: cover;
  background: url(${({ src }) => src}) center no-repeat,
    url(${({ fallbackUrl }) => fallbackUrl}) center no-repeat;
  background-size: 36px;
  border: 1px solid ${white};
`

export const AvatarAddWrapper = styled.div`
  position: absolute;
  top: 29px;
  left: 11px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background-color: ${red};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const AvatarAddIcon = styled(AddIcon)`
  ${IconCss};
  width: 10px !important;
  height: 10px !important;
`

export const HashtagText = styled.span`
  ${fontCss};
  font-weight: bold;
`

export const ImagesGrid = styled(ImagesGridCommon)`
  border-radius: 12px;
  margin-top: 8px;
`

export const ImageWrapper = styled.div`
  background-color: black;
  width: 100%;
  height: 536px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

export const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  min-width: 100%;
  object-fit: contain;
`

export const ImagesCarousel = styled(ImagesCarouselCommon)`
  margin-top: 8px;
  margin-left: -46px;
`

export const EllipsesWrapper = styled.div<{
  visible: boolean
}>`
  ${({ visible }) => !visible && 'visibility: hidden;'}
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: start;
  justify-content: center;
  width: 100%;
  gap: 6px;
  position: absolute;
  bottom: 30px;
`

const EllipseCss = css`
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: ${white};
  opacity: 75%;
`

export const EllipseSelected = styled.div`
  ${EllipseCss};
  opacity: 1;
`

export const Ellipse = styled.div<{ size: string }>`
  ${EllipseCss};
  ${({ size }) => {
    if (size === 'small') {
      return `
        width: 4px;
        height: 4px;
      `
    }
    if (size === 'smaller') {
      return `
        width: 2px;
        height: 2px;
      `
    }
    if (size === 'medium') {
      return `
        width: 6px;
        height: 6px;
      `
    }
    return ''
  }};
`
