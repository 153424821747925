import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { toast } from '@buffer-mono/popcorn'
import { actions as composerPopoverActions } from '~publish/legacy/composer-popover'
import { useShowThreadsUpgrade } from '~publish/legacy/utils/canQueueMoreThreadsForAllProfiles'
import type { PublishingPost } from '~publish/gql/graphql'
import { usePostActions, usePostPermissions } from '~publish/legacy/post/hooks'
import { PostEntity } from '~publish/legacy/post/PostEntity'
import PostFooterButtons from '~publish/legacy/post/components/PostFooterButtons'
import { sharePostNow } from '~publish/legacy/post/thunks/sharePostNow'
import {
  approveDraft,
  changeDraftStatus,
  movePostToDrafts,
} from '~publish/legacy/post/thunks'
import { useDeletePost } from './hooks'
import type { ApolloError } from '@apollo/client'

export const EditDeleteActions = ({
  post,
  setOpenModal,
}: {
  post: PublishingPost
  setOpenModal: (openModal: {
    open: boolean
    ctaString: string
    service: string
  }) => void
}): JSX.Element => {
  const [isWorking, setIsWorking] = useState(false)
  const dispatch = useDispatch()
  // TODO: This is a temporary solution until we improve our Pusher handling
  // Instead of setting isWorking to false on dispatch complete
  // we do it if the hook reloads with an updated post
  // because we force refetches on pusher events
  useEffect(() => setIsWorking(false), [post])

  const postId = post?.id
  const isDraft = PostEntity.isDraft(post)
  const { hasEditPermission } = usePostPermissions(post)

  const onRescheduleClick = (): void => {
    dispatch(composerPopoverActions.handleEditClick(postId))
  }

  const onApproveClick = async (): Promise<void> => {
    setIsWorking(true)
    await dispatch(approveDraft({ postId }))
  }

  const onRequestApprovalClick = async (): Promise<void> => {
    setIsWorking(true)
    await dispatch(changeDraftStatus({ postId, needsApproval: true }))
  }

  const onMovePostToDraftsClick = async (): Promise<void> => {
    setIsWorking(true)
    await dispatch(movePostToDrafts({ postId }))
  }

  const onRevertApprovalClick = async (): Promise<void> => {
    setIsWorking(true)
    await dispatch(changeDraftStatus({ postId, needsApproval: false }))
  }

  const onSharePostNowClick = async (): Promise<void> => {
    setIsWorking(true)
    await dispatch(sharePostNow({ postId: post.id }))
  }

  const { singleActions, stackedActions, executePostActionById } =
    usePostActions({
      post,
      onRequestApprovalClick,
      onRescheduleClick,
      onApproveClick,
      onMovePostToDraftsClick,
      onRevertApprovalClick,
      onSharePostNowClick,
    })

  const onEditClick = (): void => {
    dispatch(composerPopoverActions.handleEditClick(postId))
  }

  const onDeleteSuccess = (): void => {
    toast.success(`Okay, we’ve deleted that ${isDraft ? 'draft' : 'post'}!`)
  }

  const onDeleteError = (error: ApolloError): void => {
    const maybeErrorMessage = error?.message
    const defaultMessage = `Whoops, we had some problems deleting that ${
      isDraft ? 'draft' : 'post'
    }!`

    toast.error(maybeErrorMessage || defaultMessage)
  }

  const { deletePost, processing } = useDeletePost({
    postId,
    onDeleteSuccess,
    onDeleteError,
  })

  const isLoading = processing || isWorking

  const {
    shouldShowThreadsMigrationModal: overLimit,
    service: threadsMigrationModalService,
  } = useShowThreadsUpgrade(post)

  // for the calendar, we can't only rely on the regular flag,
  // we check stackedActions has MOVE_POST_TO_DRAFTS to check if it's the 1 thread they are allowed
  // we check singleActions has 'SCHEDULE_POST' to check if it's a draft thread
  const showUpgradeButton =
    overLimit &&
    ((stackedActions && stackedActions[1]?.id === 'MOVE_POST_TO_DRAFTS') ||
      (singleActions && singleActions[0]?.id === 'SCHEDULE_POST'))

  return (
    <PostFooterButtons
      isLoading={isLoading}
      singleActions={singleActions}
      stackedActions={stackedActions}
      executePostActionById={executePostActionById}
      onDeleteClick={hasEditPermission ? deletePost : undefined}
      onEditClick={hasEditPermission ? onEditClick : undefined}
      shouldShowThreadsMigrationModal={showUpgradeButton}
      threadsMigrationModalService={threadsMigrationModalService}
      setOpenModal={setOpenModal}
      post={post}
    />
  )
}

EditDeleteActions.defaultProps = {}
