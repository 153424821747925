/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import type { HeaderDetails } from '~publish/legacy/campaign/types'
import type {
  LinkedInAnnotation,
  RpcPostContent,
} from '~publish/legacy/post/types'
import { useSplitEnabled } from '@buffer-mono/features'
import type { PublishingNote } from '~publish/gql/graphql'

import Card from '../Card'
import CardHeader from '../CardHeader'
import RenderPostMetaBar from '../Post/RenderPostMetaBar'
import UpdateContent from '../UpdateContent'

const postContentStyle = {
  padding: '1rem',
}

type DraftProps = {
  postId: string
  basic?: boolean
  geolocationName?: string
  postContent: RpcPostContent
  profileService: string
  children?: React.ReactNode
  headerDetails: HeaderDetails
  annotations?: LinkedInAnnotation[]
  notes?: PublishingNote[]
  isReminder?: boolean
  channelData: {
    googlebusiness: {
      cta: string
      link: string
      title: string
      start_date: number
      end_date: number
      start_time: string
      end_time: string
      code: string
      terms: string
    }
    instagram: {
      share_to_feed: boolean
    }
    startPage?: {
      link: string
    }
  }
}

const Draft = ({
  postId,
  isReminder,
  basic,
  geolocationName,
  postContent,
  profileService,
  headerDetails,
  children,
  channelData,
  annotations,
  notes,
}: DraftProps): JSX.Element => {
  const { isEnabled: isRemindersEnabled } = useSplitEnabled('CORE-reminders')

  return (
    <Card isReminderBadgeVisible={isRemindersEnabled && isReminder}>
      <CardHeader postId={postId} headerDetails={headerDetails} notes={notes} />
      {/* Draft Content */}
      <div style={postContentStyle}>
        <UpdateContent
          {...postContent}
          profileService={profileService}
          basic={basic}
          channelData={channelData}
          annotations={annotations}
        />
      </div>
      <RenderPostMetaBar
        profileService={profileService}
        locationName={geolocationName}
      />
      {children}
    </Card>
  )
}

Draft.defaultProps = {
  basic: false,
  geolocationName: null,
  children: null,
  annotations: [],
}

export default Draft
