import styled from 'styled-components'

import * as Styles from '../Common/styles'

export const Wrapper = styled(Styles.Wrapper)`
  padding: 0 31px;
`

export const Tag = styled.span`
  background-color: rgba(255, 255, 255, 0.8);
  padding: 4px 6px;
  border-radius: 100px;
  position: absolute;
  left: 8px;
  top: 8px;
  color: ${Styles.previewGrayDark};
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
`
