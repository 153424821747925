import { getEndPoint, select } from '@udecode/plate'
import { EditorEntityData } from '../../../helpers/EditorEntityData'
import type { BufferEditor } from '../../../BufferEditor/types.plate'
import { insertFacebookMention } from '../transforms/insertFacebookMention'
import { hasOnlyFacebookGroupsSelected } from '../queries/hasOnlyFacebookGroupsSelected'

// Serves as the `textToNodes` callback for the facebook-mention plugin.
// It uses the facebook mention entities stored and provided to convert
// the text at the given indices to facebook mention elements.
export const findAndInsertFacebookMentions = (
  editor: BufferEditor,
  { entities }: { entities?: EditorEntityData } = {},
): void => {
  if (hasOnlyFacebookGroupsSelected(editor)) return

  if (entities) {
    EditorEntityData.getFacebookMentions(entities).forEach(
      (facebookMention) => {
        insertFacebookMention(editor, facebookMention)
      },
    )

    // Move selection to end of editor content
    const editorEndPoint = getEndPoint(editor, [])
    select(editor, {
      anchor: editorEndPoint,
      focus: editorEndPoint,
    })
  }
}
