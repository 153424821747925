import { useUploader } from '~publish/legacy/uploads/hooks/useUploader'
import AppActionCreators from '../../action-creators/AppActionCreators'
import ComposerActionCreators from '../../action-creators/ComposerActionCreators'
import NotificationActionCreators from '../../action-creators/NotificationActionCreators'
import { NotificationScopes } from '../../AppConstants'
import AppDispatcher from '../../dispatcher'
import ModalActionCreators from '../../shared-components/modal/actionCreators'
import { ActionTypes } from '../../state/ActionTypes'

export const useUpdateInstagramThumbnail = () => {
  const { uploader } = useUploader({
    id: 'instagram-thumbnail',
  })

  const updateThumbnail = ({
    imageFile,
    draftId,
    videoTitle,
  }: {
    imageFile: File
    draftId: string
    videoTitle?: string
  }): void => {
    const video = document.getElementById('thumbnailVideo') as HTMLVideoElement

    uploader
      .uploadDirect(imageFile)
      .then((result) => {
        ComposerActionCreators.updateDraftVideoTitle(draftId, videoTitle)
        AppDispatcher.handleViewAction({
          actionType: ActionTypes.COMPOSER_UPDATE_INSTAGRAM_DRAFT_THUMBNAIL,
          id: draftId,
          thumbOffset: video.currentTime * 1000,
          thumbnail: result.url,
        })

        // allows you to add to queue once upload is complete
        AppActionCreators.setThumbnailLoading(false)
        ModalActionCreators.closeModal('overlay')
      })
      .catch((err) => {
        uploader.reportError(err, imageFile)
        NotificationActionCreators.queueError({
          scope: NotificationScopes.FILE_UPLOAD,
          message:
            'Uh oh! It looks like we had an issue saving the thumbnail. Up for trying again?',
        })
        AppActionCreators.setThumbnailLoading(false)
        ModalActionCreators.closeModal('overlay')
      })
  }

  return { updateThumbnail }
}
