import styled from 'styled-components'
import { Button } from '@bufferapp/ui'

export const ButtonWrapper = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
`

export const ButtonStyled = styled(Button)``

export const RequeueButton = styled(ButtonStyled)``

export const SingleActionButton = styled(ButtonStyled)`
  height: 32px;
`

export const StackedActionButton = styled(ButtonStyled)`
  height: 32px;
`
