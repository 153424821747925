import React from 'react'
import { Button, Flex, Text, TrashIcon } from '@buffer-mono/popcorn'

import { DeleteIdeasDialog } from '~publish/components/DeleteIdeasDialog'

import styles from './MultiSelect.module.css'

interface MultiSelectMenuProps {
  selectedCount: number
  onCancel: () => void
  onDelete: () => Promise<void>
}

export const MultiSelectMenu = ({
  selectedCount,
  onCancel,
  onDelete,
}: MultiSelectMenuProps): JSX.Element => {
  const [opened, setOpened] = React.useState(false)

  const handlePropagation = (e: React.MouseEvent): void => {
    e.stopPropagation()
  }
  return (
    <Flex gap="md" className={styles.menu}>
      <Text className={styles.title} weight="bold">
        {selectedCount} Ideas Selected
      </Text>
      <Button variant="secondary" onClick={onCancel} size="large">
        Cancel
      </Button>
      <DeleteIdeasDialog
        open={opened}
        onOpenChange={setOpened}
        onDelete={onDelete}
        count={selectedCount}
      >
        <Button
          variant="critical"
          onClick={handlePropagation}
          disabled={selectedCount === 0}
          size="large"
        >
          <TrashIcon />
          Delete {selectedCount} Ideas
        </Button>
      </DeleteIdeasDialog>
    </Flex>
  )
}
