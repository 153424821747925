import { createSlice } from '@reduxjs/toolkit'

const initialState: {
  drafts: []
} = {
  drafts: [],
}

export const draftsSlice = createSlice({
  // available at state.composer.notificaitons
  name: 'drafts',
  initialState,
  reducers: {},
})
