/* eslint-disable react/jsx-props-no-spreading */
import type { StyledElementProps } from '@udecode/plate'
import React from 'react'
import type { BufferValue } from '~publish/legacy/editor/BufferEditor/types.plate'
import type { AutocompleteInputElement } from '../../nodes'
import { MentionText } from './styles'

export const AutocompleteInputElementUI = (
  props: StyledElementProps<BufferValue, AutocompleteInputElement>,
) => {
  const { attributes, children, element } = props
  const { valid } = element

  return (
    <MentionText {...attributes} $valid={valid}>
      {children}
    </MentionText>
  )
}
