import styled from 'styled-components'

import { borderRadius } from '@bufferapp/ui/style/borders'
import { blue } from '@bufferapp/ui/style/colors'

export const Wrapper = styled.span`
  color: ${blue};
  vertical-align: baseline;
  border-radius: ${borderRadius};
  position: relative;
`
