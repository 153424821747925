import React from 'react'

import * as Styled from './Title.styles'

/**
 *
 */
function Title({
  children,
  testId,
}: {
  children: React.ReactNode | JSX.Element
  testId?: string
}): JSX.Element {
  return <Styled.Text data-testid={testId}>{children}</Styled.Text>
}

export { Title }
