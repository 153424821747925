import type { MutationBaseOptions } from '@apollo/client/core/watchQueryOptions'
import { graphql } from '~publish/gql'
import { client } from '~publish/legacy/apollo-client'
import { UpdatedNoteOnPost } from './useAddNoteToPost'
import { useTypedMutation } from './useTypedMutation'

export const DeleteNoteFromPost = graphql(/* GraphQL */ `
  mutation DeleteNoteFromPost($postId: PostId!, $noteId: NoteId!) {
    deleteNoteFromPost(input: { postId: $postId, noteId: $noteId }) {
      __typename
      ... on DeleteNoteFromPostSuccess {
        post {
          __typename
          id
          notes {
            ...PostCardNotes_Note
          }
        }
      }
      ... on MutationError {
        message
      }
    }
  }
`)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export const useDeleteNoteFromPost = () =>
  useTypedMutation(DeleteNoteFromPost, (data) => data.deleteNoteFromPost, {
    successTypename: 'DeleteNoteFromPostSuccess',
    optimisticResponse: (
      { postId, noteId },
      { IGNORE },
    ): MutationBaseOptions['optimisticResponse'] => {
      const existingPost = client.readFragment({
        id: client.cache.identify({ __typename: 'Post', id: postId }),
        fragment: UpdatedNoteOnPost,
      })

      if (!existingPost) {
        return IGNORE
      }

      return {
        __typename: 'Mutation',
        deleteNoteFromPost: {
          __typename: 'DeleteNoteFromPostSuccess',
          post: {
            id: postId,
            __typename: 'Post',
            notes: existingPost.notes.filter((note) => note.id !== noteId),
          },
        },
      }
    },
  })
