import {
  type Draft,
  DraftMethods,
} from '~publish/legacy/composer/composer/entities/Draft'
import { AttachmentTypes, MediaTypes } from '~publish/legacy/constants'
import { getVideoRestrictionsForDraft } from '~publish/legacy/media/config/posts-media-restrictions'
import { validateMedia } from '~publish/legacy/media/validation/validateMedia'
import ValidationFail from '~publish/legacy/validation/ValidationFail'
import type ValidationResult from '~publish/legacy/validation/ValidationResult'
import ValidationResults from '~publish/legacy/validation/ValidationResults'
import ValidationSuccess from '~publish/legacy/validation/ValidationSuccess'
import { VALIDATION_CODE } from '~publish/legacy/validation/constants'
import { validateVideo } from './ValidateVideo'
import {
  REQUIRES_TEXT,
  REQUIRES_TEXT_OR_ATTACHMENT,
  getMaxImageCountExceededMessage,
  getMissingAttachmentAndTextMessage,
  getMissingAttachmentMessage,
} from './utils/validationErrorMessages'

export default class BaseValidator {
  protected isDraft: boolean

  protected draft: Draft

  protected activeThreadId: number

  constructor(draft: Draft, isDraft: boolean, activeThreadId = 0) {
    this.draft = draft
    this.isDraft = isDraft
    this.activeThreadId = activeThreadId
  }

  protected validateTextCharacterLimit(): ValidationResult {
    const charLimit = DraftMethods.getCharLimit(this.draft)

    if (charLimit) {
      const { characterCount } = this.draft

      if (characterCount && characterCount > charLimit) {
        return new ValidationFail(
          `Text length exceeds the limit of ${charLimit}`,
          VALIDATION_CODE.CHARACTER_LIMIT_REACHED,
        )
      }
    }

    return new ValidationSuccess()
  }

  /**
   * Get copy for the missing attachment, used later to construct the message
   * @private
   */
  protected getRequiredAttachmentCopy(): string | null {
    const canHaveImageOrGifAttachment =
      this.draft.service?.canHaveSomeMediaAttachmentTypes([
        MediaTypes.IMAGE,
        MediaTypes.GIF,
      ])

    const canHaveVideoAttachment =
      this.draft.service?.canHaveMediaAttachmentType(MediaTypes.VIDEO)

    if (this.draft.service?.requiredAttachmentType === AttachmentTypes.LINK) {
      return 'a link preview'
    }

    if (
      this.draft.service?.requiredAttachmentType === AttachmentTypes.RETWEET
    ) {
      return 'a retweet'
    }

    if (this.draft.service?.requiredAttachmentType === AttachmentTypes.MEDIA) {
      if (this.draft.isReelsPost() || this.draft.isFacebookReelPost()) {
        return 'a video'
      }
      if (canHaveImageOrGifAttachment && canHaveVideoAttachment) {
        return 'an image or video'
      }
      if (canHaveImageOrGifAttachment) {
        return 'an image'
      }
      if (canHaveVideoAttachment) {
        return 'a video'
      }
    }

    return null
  }

  protected hasRequiredAttachmentAttached(): boolean {
    const requiredAttachmentType = this.draft.service?.requiredAttachmentType

    return (
      requiredAttachmentType === null ||
      (requiredAttachmentType === AttachmentTypes.LINK &&
        this.draft.hasLinkAttachment()) ||
      (requiredAttachmentType === AttachmentTypes.RETWEET &&
        this.draft.hasRetweetAttachment()) ||
      (requiredAttachmentType === AttachmentTypes.MEDIA &&
        this.draft.hasAttachment())
    )
  }

  protected hasRequiredText(): boolean {
    return !this.draft.service?.requiresText || this.draft.hasText()
  }

  protected validateAttachmentOrText(): ValidationResult {
    if (!this.hasRequiredAttachmentAttached() && !this.hasRequiredText()) {
      return new ValidationFail(
        getMissingAttachmentAndTextMessage(
          this.getRequiredAttachmentCopy() as string,
        ),
        VALIDATION_CODE.MISSING_VALUE,
      )
    }
    if (!this.hasRequiredAttachmentAttached()) {
      return new ValidationFail(
        getMissingAttachmentMessage(this.getRequiredAttachmentCopy() as string),
        VALIDATION_CODE.MISSING_VALUE,
      )
    }
    if (!this.hasRequiredText()) {
      return new ValidationFail(REQUIRES_TEXT, VALIDATION_CODE.MISSING_VALUE)
    }
    if (!this.draft.hasText() && !this.draft.hasAttachment()) {
      return new ValidationFail(
        REQUIRES_TEXT_OR_ATTACHMENT,
        VALIDATION_CODE.MISSING_VALUE,
      )
    }

    return new ValidationSuccess()
  }

  protected validateVideo(): ValidationResult {
    const rules = getVideoRestrictionsForDraft(
      this.draft.service.name,
      this.draft.updateType,
    )

    if (rules && this.draft.video) {
      const result = validateMedia(rules, this.draft.video)

      if (result?.isValidationFail()) {
        result.addMetadata(this.draft.video)
        return result
      }
    }

    return validateVideo(this.draft.video, this.draft.service)
  }

  protected shouldValidateVideo(): boolean {
    const videoRules = getVideoRestrictionsForDraft(
      this.draft.service.name,
      this.draft.updateType,
    )

    return (
      !!this.draft.service?.videoMinResolution ||
      !!this.draft.service?.videoMaxSize ||
      !!this.draft.service?.videoMinDurationMs ||
      !!this.draft.service?.videoMaxDurationMs ||
      !!videoRules?.length
    )
  }

  protected validateImage(): ValidationResult[] {
    const {
      imageMaxWidth,
      imageMaxHeight,
      imageMinWidth,
      imageMinHeight,
      imageMaxPixelCount,
    } = this.draft.service

    return this.draft.images.map((image) => {
      const imageWidth = image.width ?? 0
      const imageHeight = image.height ?? 0
      const imagePixelCount = imageWidth * imageHeight

      if (
        imageMaxWidth &&
        imageMaxHeight &&
        (imageWidth > imageMaxWidth || imageHeight > imageMaxHeight)
      ) {
        return new ValidationFail(
          `One of your images is above maximum supported width or height: ${imageMaxWidth}x${imageMaxHeight}px`,
          VALIDATION_CODE.IMAGE_SIZE,
        )
      }

      if (imageMaxWidth && imageWidth > imageMaxWidth) {
        return new ValidationFail(
          `One of your images is above maximum supported width: ${imageMaxWidth}`,
          VALIDATION_CODE.IMAGE_SIZE,
        )
      }

      if (imageMaxHeight && imageHeight > imageMaxHeight) {
        return new ValidationFail(
          `One of your images is above maximum supported height: ${imageMaxHeight}`,
          VALIDATION_CODE.IMAGE_SIZE,
        )
      }

      if (
        imageMinWidth &&
        imageMinHeight &&
        (imageWidth < imageMinWidth || imageHeight < imageMinHeight)
      ) {
        return new ValidationFail(
          `One of your images is below minimum supported width or height: ${imageMinWidth}x${imageMinHeight}px`,
          VALIDATION_CODE.IMAGE_SIZE,
        )
      }

      if (imageMinWidth && imageWidth < imageMinWidth) {
        return new ValidationFail(
          `One of your images is below minimum supported width: ${imageMinWidth}`,
          VALIDATION_CODE.IMAGE_SIZE,
        )
      }

      if (imageMinHeight && imageHeight < imageMinHeight) {
        return new ValidationFail(
          `One of your images is below minimum supported height: ${imageMinHeight}`,
          VALIDATION_CODE.IMAGE_SIZE,
        )
      }

      if (imageMaxPixelCount && imagePixelCount > imageMaxPixelCount) {
        return new ValidationFail(
          `One of your images is above the maximum supported resolution`,
          VALIDATION_CODE.IMAGE_SIZE,
        )
      }
      return new ValidationSuccess()
    })
  }

  protected shouldValidateImage(): boolean {
    return (
      !!this.draft.service?.imageMaxWidth ||
      !!this.draft.service?.imageMaxHeight ||
      !!this.draft.service?.imageMinWidth ||
      !!this.draft.service?.imageMinHeight ||
      !!this.draft.service?.imageMaxPixelCount
    )
  }

  protected shouldValidateImageCount(): boolean {
    return !!this.draft.service?.maxAttachableImagesCount(this.draft)
  }

  protected validateImageCount(): ValidationResult {
    if (DraftMethods.exceedsMaxImageCount(this.draft)) {
      return new ValidationFail(
        getMaxImageCountExceededMessage(
          this.draft.service.maxAttachableImagesCount(this.draft),
          this.draft.updateType,
        ),
        VALIDATION_CODE.MAX_LIMIT_REACHED,
      )
    }

    return new ValidationSuccess()
  }

  /**
   * This method is supposed to be overriden in every Validator extending after BaseValidator
   * @protected
   */
  protected validateForChannel(): ValidationResult[] {
    return []
  }

  /**
   * Validate the actual draft, that is when isDraft == true
   * @protected
   */
  protected validateDraft(): ValidationFail[] {
    if (
      (this.draft.hasText() || this.draft.hasAttachment()) &&
      !this.shouldValidateVideo()
    ) {
      return []
    }

    const videoValidationResults = this.shouldValidateVideo()
      ? this.validateVideo()
      : new ValidationSuccess()

    const imageValidationResults = this.shouldValidateImage()
      ? this.validateImage()
      : [new ValidationSuccess()]

    return [
      this.validateAttachmentOrText(),
      videoValidationResults,
      ...imageValidationResults,
    ].filter((validation): validation is ValidationFail =>
      validation.isValidationFail(),
    )
  }

  /**
   * Validate the actual update, that is when isDraft == false
   * @protected
   */
  protected validateUpdate(): ValidationFail[] {
    const videoValidationResults = this.shouldValidateVideo()
      ? this.validateVideo()
      : new ValidationSuccess()
    const imageValidationResults = this.shouldValidateImage()
      ? this.validateImage()
      : [new ValidationSuccess()]
    const maxImagesValidationResult = this.shouldValidateImageCount()
      ? this.validateImageCount()
      : new ValidationSuccess()

    return [
      this.validateAttachmentOrText(),
      this.validateTextCharacterLimit(),
      videoValidationResults,
      maxImagesValidationResult,
      ...imageValidationResults,
      ...this.validateForChannel(),
    ].filter((validation): validation is ValidationFail =>
      validation.isValidationFail(),
    )
  }

  public validate(): ValidationResults {
    const validationResults = this.isDraft
      ? this.validateDraft()
      : this.validateUpdate()

    // Filter out any remaining ValidationSuccess
    // and add composerId to all ValidationFail results
    const validationFeedback = validationResults.map((validation) => {
      validation.composerId = this.draft.id
      return validation
    }, [])

    return new ValidationResults(validationFeedback)
  }
}
