import { Flex, Notice, Text, VisuallyHidden } from '@buffer-mono/popcorn'
import Tooltip from '@bufferapp/ui/Tooltip'
import * as Dialog from '@radix-ui/react-dialog'
import { Plate, type TEditableProps } from '@udecode/plate'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import AIContextualMenu from '~publish/legacy/ai/components/Triggers/AIContextualMenu'
import { renderPlaceholderWithAIButton } from '~publish/legacy/ai/helpers/renderPlaceholderWithAIButton'
import { EmojiPicker } from '~publish/legacy/editor/plugins'

import { useCMDEnterKeys } from '~publish/hooks/useCMDEnterKeys'
import { AIAssistantTrigger } from '~publish/legacy/ai/components/Triggers'
import {
  selectContentGenerationStatus,
  setAIAssistantPlacement,
  toggleAIAssistant,
} from '~publish/legacy/ai/state/slice'
import ModalActionCreators from '~publish/legacy/composer/composer/shared-components/modal/actionCreators'
import type { BufferValue } from '~publish/legacy/editor/BufferEditor/types.plate'
import { EditorErrorBoundary } from '~publish/legacy/editor/components/EditorErrorBoundary'
import {
  EmojiCombobox,
  EmojiPickerIcon,
} from '~publish/legacy/editor/plugins/emoji'
import {
  IntegrationsBar,
  useIntegrationServices,
} from '~publish/legacy/integrations-bar'
import {
  BarButton,
  VerticalDivider,
} from '~publish/legacy/integrations-bar/styles'
import type { IntegrationsContext } from '~publish/legacy/integrations-bar/types'
import { selectShouldShowNBMigration } from '~publish/legacy/organizations/selectors'
import { TagsSelector } from '~publish/legacy/shared-components'
import { useAppDispatch, useAppSelector } from '~publish/legacy/store'
import {
  MAX_THUMBNAILS,
  MediaManager,
} from '~publish/legacy/uploads/components/MediaManager'
import {
  selectCompletedCount,
  selectIncludedMedia,
  selectPendingCount,
} from '~publish/legacy/uploads/state/selectors'
import { getRandomNumber } from '~publish/legacy/utils/numbers'

// Importing types from Create page is unavoidable for now
import { getPlainText } from '~publish/legacy/editor/BufferEditor'
import { IDEAS_UPLOADER_ID } from '../constants'
import { isMediaConvertibleToPost } from '../helpers'
import { useIdeaComposerState } from '../IdeaComposer'
import { IDEA_EDITOR_ID } from './createIdeaEditor'
import { useVariableHeight } from './hooks'
import styles from './IdeaEditor.module.css'
import { IdeaGroupSelector } from './IdeaGroupSelector'
import {
  ButtonWrapper,
  HeaderText,
  IdeaTitleInput,
  IdeatorContent,
  IdeatorHeader,
  IntegrationsWrapper,
  MediaManagerWrapper,
  StyledButton,
} from './styles'
import type { OnFileReadyCb } from '~publish/legacy/uploads/types'
import type { Upload } from '~publish/legacy/uploads/entities/Upload'
import type { Uploader } from '~publish/legacy/uploads/lib/Uploader'

const baseEditableProps: TEditableProps<BufferValue> = {
  placeholder: '',
}

/**
 * Component responsibile for rendering the core content of the IdeaComposer.
 * It manages most of the state updates to the idea being created or edited.
 * It syncs all changes back to the parent IdeaComposer through context
 * and handles media uploads through a dedicated uploader instance.
 *
 * Accepts a container prop used to portal the Dialog.Content to the desired location.
 *
 * @example
 * ```tsx
 * <IdeaEditor container={document.getElementById('portal-root')} />
 * ```
 */
export const IdeaEditor = ({
  container,
  onFileReady,
  removeUpload,
  uploader,
}: {
  container?: HTMLElement | null
  onFileReady: OnFileReadyCb
  removeUpload: (upload: Upload) => void
  uploader: Uploader
}): JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const {
    editor,
    draftIdea,
    saveDraftIdea,
    setGroupId,
    setTitle,
    setText,
    setSelectedTags,
    error,
    isLoading,
    createPostFromIdea,
  } = useIdeaComposerState()

  // This function is passed to Plate to be run on each editor update
  // to update the draftIdea text state
  const updateTextOnEditorValueChange = React.useCallback(
    (editorValue: BufferValue) => {
      const updatedText = getPlainText(editor)
      if (
        editorValue &&
        draftIdea?.content?.text?.trim() !== updatedText?.trim()
      ) {
        setText(updatedText)
      }
    },
    [draftIdea?.content?.text, editor, setText],
  )

  const [ctaPostTooltipLabel, setCtaPostTooltipLabel] = React.useState<
    string | null
  >(null)

  const { contentGenerationInProgress, contentGenerationError } =
    useAppSelector((state) => selectContentGenerationStatus(state))

  const context: IntegrationsContext = 'ideasEditor'

  /** Uploads and Integrations */
  const { services, activeService } = useIntegrationServices({
    onFileReady,
    context,
  })

  const pendingCount = useAppSelector((state) =>
    selectPendingCount(state, IDEAS_UPLOADER_ID),
  )
  const completedCount = useAppSelector((state) =>
    selectCompletedCount(state, IDEAS_UPLOADER_ID),
  )
  const isUploading = pendingCount > 0

  const media = useAppSelector((state) =>
    selectIncludedMedia(state, IDEAS_UPLOADER_ID),
  )

  /** Plate Editor */
  const { editorHeightStyles } = useVariableHeight({ completedCount })

  const placeholder = t(`content.editor.placeholder.${getRandomNumber(10)}`)

  baseEditableProps.placeholder = placeholder.concat(' or ')
  baseEditableProps.renderPlaceholder = renderPlaceholderWithAIButton({
    placement: 'ideasEditor',
  })

  const editableProps: TEditableProps<BufferValue> = useMemo(
    () => ({
      ...baseEditableProps,
      spellCheck: true,
      autoFocus: true,
      readOnly: false,
      'data-draftid': IDEA_EDITOR_ID,
      'data-testid': IDEA_EDITOR_ID,
      style: { height: '100%' },
    }),
    [],
  )

  const createPostButtonDisabled = useMemo(() => {
    const hasMedia = completedCount !== 0
    const hasTextOrMedia = draftIdea?.content?.text || hasMedia

    const { valid: isMediaConvertible, message } =
      isMediaConvertibleToPost(media)

    setCtaPostTooltipLabel(message)
    return (
      isUploading ||
      !hasTextOrMedia ||
      !isMediaConvertible ||
      contentGenerationInProgress ||
      isLoading
    )
  }, [
    draftIdea?.content?.text,
    media,
    contentGenerationInProgress,
    completedCount,
    isUploading,
    isLoading,
  ])

  const saveButtonDisabled = useMemo(() => {
    const hasMedia = completedCount !== 0
    // debugger
    return (
      isUploading ||
      (!draftIdea?.content?.text && !hasMedia && !draftIdea?.content?.title) ||
      contentGenerationInProgress ||
      isLoading
    )
  }, [
    draftIdea?.content?.text,
    isUploading,
    draftIdea?.content?.title,
    completedCount,
    contentGenerationInProgress,
    isLoading,
  ])

  const postTooltipLabel = createPostButtonDisabled
    ? 'Include copy or media to create a post from this Idea'
    : ''

  const saveTooltipLabel = saveButtonDisabled
    ? 'Include a title, copy, or media to save this Idea'
    : ''

  const shouldShowNBMigration = useAppSelector(selectShouldShowNBMigration)
  const onOpenAIAssistant = (): void => {
    // Sets the placement (source) for tracking
    // Must be set before the upgrade modal is triggered as the modal
    // relies on the placement for tracking.
    dispatch(setAIAssistantPlacement({ placement: 'ideasEditor' }))

    // Display a upgrade flow for multi-product users
    // Restrict AI Assistant feature to New Buffer users only
    if (shouldShowNBMigration) {
      ModalActionCreators.openModal('AIAssistantMPUpgradePlan', {
        ctaButton: 'integrationsBar',
      })
      return
    }
    dispatch(toggleAIAssistant(true))
  }

  useCMDEnterKeys(saveDraftIdea, !saveButtonDisabled)

  const errorMessage = contentGenerationError || error?.message

  return (
    <>
      {errorMessage && (
        <Notice variant="error" className={styles.errorNotice}>
          <Text>{errorMessage}</Text>
        </Notice>
      )}
      <Flex
        className={styles.ideaEditor}
        direction="column"
        justify="between"
        align="stretch"
      >
        <IdeatorHeader>
          <Dialog.DialogTitle asChild>
            <HeaderText>{draftIdea?.id ? 'Edit' : 'New'} Idea</HeaderText>
          </Dialog.DialogTitle>
          <Flex gap="xs" align="center">
            <IdeaGroupSelector
              selectedGroupId={draftIdea?.groupId}
              onGroupSelect={setGroupId}
            />
            <TagsSelector
              source="ideasComposer"
              preSelectedTags={draftIdea?.content?.tags ?? []}
              onSelectTags={setSelectedTags}
            />
          </Flex>
        </IdeatorHeader>
        <VisuallyHidden as="label" htmlFor="idea-title">
          Idea title
        </VisuallyHidden>
        <IdeaTitleInput
          id="idea-title"
          placeholder="Give your idea a title"
          type="text"
          value={draftIdea?.content?.title ?? ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            setTitle(e.target.value)
          }
        />
        <IdeatorContent style={editorHeightStyles}>
          <EditorErrorBoundary editor={editor}>
            <Plate<BufferValue>
              id="slate-idea-editor"
              editor={editor}
              editableProps={editableProps}
              onChange={updateTextOnEditorValueChange}
            >
              <EmojiCombobox maxSuggestions={8} />
              <AIContextualMenu editor={editor} placement="ideasEditor" />
            </Plate>
          </EditorErrorBoundary>
        </IdeatorContent>
        <MediaManagerWrapper id="ideas-media-manager">
          <MediaManager
            id="ideas"
            containerRef={container}
            uploader={uploader}
            removeUpload={removeUpload}
            onFileReady={onFileReady}
          />
        </MediaManagerWrapper>
        <IntegrationsWrapper>
          <IntegrationsBar
            context={context}
            services={services}
            activeService={activeService}
            disableBar={completedCount + pendingCount >= MAX_THUMBNAILS}
            container={container}
          >
            <VerticalDivider />
            <EmojiPicker editor={editor} parentContainer={container}>
              <BarButton
                data-testid="emoji-picker-button"
                aria-label="emoji picker"
              >
                <EmojiPickerIcon />
              </BarButton>
            </EmojiPicker>
          </IntegrationsBar>
          <VerticalDivider />
          <AIAssistantTrigger onClick={onOpenAIAssistant} />
        </IntegrationsWrapper>
        <ButtonWrapper id="ideas-actions">
          {/* FIXME: Use Popcorn Tooltip */}
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element; label: string | null; }... Remove this comment to see the full error message */}
          <Tooltip label={ctaPostTooltipLabel ?? postTooltipLabel}>
            <StyledButton
              type="secondary"
              label="Create Post"
              onClick={createPostFromIdea}
              disabled={createPostButtonDisabled}
            />
          </Tooltip>
          {/* FIXME: Use Popcorn Tooltip */}
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-expect-error TS(2322) FIXME: Type '{ children: Element; label: string; }' is no... Remove this comment to see the full error message */}
          <Tooltip label={saveTooltipLabel}>
            <StyledButton
              type="primary"
              label="Save Idea"
              onClick={saveDraftIdea}
              disabled={saveButtonDisabled}
            />
          </Tooltip>
        </ButtonWrapper>
      </Flex>
    </>
  )
}
