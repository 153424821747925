/**
 * Component that displays and allows to navigate among a provided list
 * of suggested media
 */

import React from 'react'
import throttle from 'lodash/throttle'
import WarningIcon from '@bufferapp/ui/Icon/Icons/Warning'

import { MediaTypes } from '~publish/legacy/constants'
import { CloseIcon } from '@buffer-mono/popcorn'
import { Services } from '../AppConstants'
import AppStore from '../stores/AppStore'
import ComposerActionCreators from '../action-creators/ComposerActionCreators'
import Button from './shared/Button'
import SuggestedMediaThumbnailInfo from './SuggestedMediaThumbnailInfo'
import styles from './css/SuggestedMediaBox.module.css'
import videoAttachmentThumbnailStyles from './css/VideoAttachmentThumbnail.module.css'
import {
  getHumanReadableSize,
  getHumanReadableTime,
} from '../utils/StringUtils'
import ComposerStore from '../stores/ComposerStore'
import type Draft from '../entities/Draft/Draft'

class SuggestedMediaBox extends React.Component<
  { draft: Draft; className: string },
  {
    canScrollLeft: boolean
    canScrollRight: boolean
  }
> {
  suggestionsScrollContainer: React.RefObject<HTMLDivElement>

  constructor(props: { draft: Draft; className: string }) {
    super(props)

    this.suggestionsScrollContainer = React.createRef<HTMLDivElement>()

    this.state = {
      canScrollLeft: false,
      canScrollRight: false,
    }
  }

  componentDidUpdate = (): void => {
    if (!this.shouldShowSuggestedMediaBox()) {
      return
    }
    this.updateScrollButtonsDisplay()
  }

  componentWillUnmount = (): void => this.updateScrollButtonsDisplay.cancel()

  // @ts-expect-error TS(7006) FIXME: Parameter 'media' implicitly has an 'any' type.
  onThumbnailClick = (media) => {
    const { draft } = this.props

    switch (media.mediaType) {
      case MediaTypes.IMAGE:
        ComposerActionCreators.addDraftImage(draft.id, media)
        break

      case MediaTypes.VIDEO:
        ComposerActionCreators.addDraftVideo(draft.id, media)
        break

      case MediaTypes.GIF:
        ComposerActionCreators.addDraftGif(draft.id, media)
        break

      default:
        break
    }
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'media' implicitly has an 'any' type.
  onThumbnailMouseOver = (media) => {
    const { draft } = this.props
    const tempImage =
      media.mediaType === MediaTypes.VIDEO ? media.thumbnail : media.url
    ComposerActionCreators.updateDraftTempImage(draft.id, tempImage)
  }

  onThumbnailMouseOut = (): void => {
    const { draft } = this.props

    ComposerActionCreators.removeDraftTempImage(draft.id)
  }

  // @ts-expect-error TS(7006) FIXME: Parameter 'suggestedItem' implicitly has an 'any' ... Remove this comment to see the full error message
  getSuggestedMediaItem = (suggestedItem) => {
    const { mediaType } = suggestedItem
    const { draft } = this.props
    let suggestedMediaItem
    const hasDimensionsData = suggestedItem.width && suggestedItem.height

    if (mediaType === MediaTypes.IMAGE) {
      suggestedMediaItem = (
        <Button
          className={styles.thumbnailContainer}
          onClick={this.onThumbnailClick.bind(this, suggestedItem)} // eslint-disable-line
          onMouseOver={this.onThumbnailMouseOver.bind(this, suggestedItem)} // eslint-disable-line
          onMouseMove={this.onThumbnailMouseOver.bind(this, suggestedItem)} // eslint-disable-line
          onMouseOut={this.onThumbnailMouseOut}
          key={suggestedItem.url}
        >
          <img
            src={suggestedItem.url}
            className={styles.thumbnail}
            alt="Thumbnail of Suggested Media"
          />

          {hasDimensionsData && (
            <SuggestedMediaThumbnailInfo
              width={suggestedItem.width}
              height={suggestedItem.height}
            />
          )}
        </Button>
      )
    } else if (mediaType === MediaTypes.GIF) {
      suggestedMediaItem = (
        <Button
          className={styles.thumbnailContainer}
          onClick={this.onThumbnailClick.bind(this, suggestedItem)} // eslint-disable-line
          onMouseOver={this.onThumbnailMouseOver.bind(this, suggestedItem)} // eslint-disable-line
          onMouseMove={this.onThumbnailMouseOver.bind(this, suggestedItem)} // eslint-disable-line
          onMouseOut={this.onThumbnailMouseOut}
          key={`${suggestedItem.url}-${draft.id}`}
        >
          <img
            src={suggestedItem.url}
            className={styles.thumbnail}
            alt="Thumbnail of Suggested GIF"
          />

          {hasDimensionsData && (
            <SuggestedMediaThumbnailInfo
              width={suggestedItem.width}
              height={suggestedItem.height}
            />
          )}
        </Button>
      )
    } else if (mediaType === MediaTypes.VIDEO) {
      const iconClassName = [
        'bi bi-video',
        videoAttachmentThumbnailStyles.videoIcon,
      ].join(' ')

      suggestedMediaItem = (
        <div
          className={styles.suggestedMediaItem}
          key={suggestedItem.thumbnail}
        >
          <Button
            className={styles.thumbnailContainer}
            onClick={this.onThumbnailClick.bind(this, suggestedItem)} // eslint-disable-line
            onMouseOver={this.onThumbnailMouseOver.bind(this, suggestedItem)} // eslint-disable-line
            onMouseMove={this.onThumbnailMouseOver.bind(this, suggestedItem)} // eslint-disable-line
            onMouseOut={this.onThumbnailMouseOut}
          >
            <img
              src={suggestedItem.thumbnail}
              className={styles.thumbnail}
              alt="Thumbnail of Suggested Video"
            />

            <span className={videoAttachmentThumbnailStyles.videoDataContainer}>
              <div className={iconClassName} />
              <div className={videoAttachmentThumbnailStyles.thumbnailInfo}>
                <div
                  className={videoAttachmentThumbnailStyles.thumbnailInfoText}
                >
                  <div className={videoAttachmentThumbnailStyles.videoSize}>
                    {getHumanReadableSize(suggestedItem.size)}
                  </div>
                  <div className={videoAttachmentThumbnailStyles.videoDuration}>
                    {getHumanReadableTime(suggestedItem.duration)}
                  </div>
                </div>
              </div>
            </span>
          </Button>
        </div>
      )
    }

    return suggestedMediaItem
  }

  scrollXBy = (delta: number): void => {
    if (!this.suggestionsScrollContainer.current) {
      return
    }

    this.suggestionsScrollContainer.current.scrollLeft += delta
  }

  scrollLeft = (event: React.UIEvent<HTMLElement>): void => {
    event.preventDefault()

    this.scrollXBy(-120)
    this.updateScrollButtonsDisplay()
  }

  scrollRight = (event: React.UIEvent<HTMLElement>): void => {
    event.preventDefault()

    this.scrollXBy(120)
    this.updateScrollButtonsDisplay()
  }

  updateScrollButtonsDisplay = throttle(() => {
    const container = this.suggestionsScrollContainer.current

    if (!container) {
      return
    }
    const canScrollLeft = container.scrollLeft > 0
    const canScrollRight =
      container.scrollLeft < container.scrollWidth - container.offsetWidth - 1

    if (
      canScrollLeft !== this.state.canScrollLeft ||
      canScrollRight !== this.state.canScrollRight
    ) {
      this.setState({ canScrollLeft, canScrollRight })
    }
  }, 100)

  onlyAllowsVerticalVideo = (draft: Draft): boolean => {
    const serviceConfig = Services.get(draft.service.name)

    return (
      (serviceConfig && serviceConfig.onlyAllowsVerticalVideo) ||
      draft.isReelsPost() ||
      draft.isFacebookReelPost() ||
      draft.isFacebookStoryPost()
    )
  }

  shouldShowSuggestedMediaBox(): boolean {
    if (ComposerStore.getIsSuggestedMediaBoxHidden()) {
      return false
    }

    const { draft } = this.props
    const suggestedMedia = ComposerStore.getSuggestedMediaForDraft(draft, true)

    return (
      AppStore.getExpandedComposerId() === draft.id &&
      draft.hasMediaAttachmentEnabled() &&
      suggestedMedia.length > 0
    )
  }

  render() {
    const { draft, className } = this.props
    const { canScrollLeft, canScrollRight } = this.state

    const suggestedMedia = ComposerStore.getSuggestedMediaForDraft(draft, true)

    if (!this.shouldShowSuggestedMediaBox()) {
      return <></>
    }

    const suggestedMediaBoxContainerClassName = [
      styles.suggestedMediaBoxContainer,
      className,
    ].join(' ')

    const scrollLeftButtonClassName = [
      styles.scrollControlsContainerLeft,
      styles.scrollButton,
      'bi bi-arrow-left',
    ].join(' ')

    const scrollRightButtonClassName = [
      styles.scrollControlsContainerRight,
      styles.scrollButton,
      'bi bi-arrow-right',
    ].join(' ')

    return (
      <div className={suggestedMediaBoxContainerClassName}>
        <div className={styles.suggestedMediaBox}>
          <div className={styles.header} role="heading" aria-level={4}>
            {`Suggested media (${suggestedMedia.length}):`}
          </div>

          <p className={styles.warning}>
            <WarningIcon size="smedium" />
            Make sure you have the rights to use the suggested media.
          </p>

          <div className={styles.suggestionsContainer}>
            <div
              className={styles.suggestionsScrollContainer}
              ref={this.suggestionsScrollContainer}
              onScroll={this.updateScrollButtonsDisplay}
            >
              {[...suggestedMedia].map((suggestedItem) =>
                this.getSuggestedMediaItem(suggestedItem),
              )}
            </div>

            {canScrollLeft && (
              <Button
                className={scrollLeftButtonClassName}
                onClick={this.scrollLeft}
                aria-label="Scroll suggested media left"
              />
            )}

            {canScrollRight && (
              <Button
                className={scrollRightButtonClassName}
                onClick={this.scrollRight}
                aria-label="Scroll suggested media right"
              />
            )}
          </div>
        </div>
        <button
          type="button"
          onClick={(): void => ComposerActionCreators.hideSuggestedMedia()}
          className={styles.hideSuggestedMediaButton}
          aria-label="Hide suggested media"
        >
          <CloseIcon />
        </button>
      </div>
    )
  }
}

export default SuggestedMediaBox
