import styled from 'styled-components'
import { grayDarker, white } from '@bufferapp/ui/style/colors'

export const PostPendingBannerWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${grayDarker};
  padding: 8px 16px;
  color: ${white};
  border-radius: 4px 4px 0 0;
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-right: 8px;
`
