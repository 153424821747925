/**
 * Accepts time in 24hr format and returns string time format like 01:00pm or 13:00
 * @param input
 * @param uses24hr
 * @constructor
 */
const transform24hrTimeToString = (
  input: string,
  uses24hr: boolean,
): string => {
  // eslint-disable-next-line prefer-regex-literals
  const pattern = new RegExp('^\\d?\\d:?\\d?\\d?\\s*$')
  if (input && !pattern.test(input.trim())) {
    return input
  }

  const hours = input.split(':')[0]
  const minutes = input.split(':')[1]
  const parsedHours = parseInt(hours, 10)

  if (uses24hr) {
    return `${hours}:${minutes.length === 1 ? `0${minutes}` : minutes}`
  }

  if (parsedHours === 0) {
    return `12:${minutes.length === 1 ? `0${minutes}` : minutes} AM`
  }

  if (parsedHours === 12) {
    return `12:${minutes.length === 1 ? `0${minutes}` : minutes} PM`
  }

  if (parsedHours > 12) {
    const newHours = parsedHours - 12
    return `${newHours}:${minutes.length === 1 ? `0${minutes}` : minutes} PM`
  }

  return `${parsedHours}:${minutes.length === 1 ? `0${minutes}` : minutes} AM`
}

export { transform24hrTimeToString }
