import React, { useState, useCallback, useEffect } from 'react'

import {
  Dialog,
  Button,
  Flex,
  Input,
  Textarea,
  Label,
  Heading,
  IconButton,
  TrashIcon,
} from '@buffer-mono/popcorn'
import {
  addFeedGroup,
  updateFeedGroup,
  deleteFeedGroup,
} from '../../hooks/mockFeedGroups'
import type { Feed, FeedGroup } from '../../types'
import styles from './EditFeedGroup.module.css'

const makeEmptyFeed = (): Feed => ({
  id: Math.random().toString(),
  name: '',
  description: '',
  url: '',
})

type EditFeedGroupProps = {
  open: boolean
  onClose: () => void
  feedGroup?: FeedGroup
}

export const EditFeedGroup = (props: EditFeedGroupProps): JSX.Element => {
  const { open, onClose, feedGroup } = props

  const [name, setName] = useState(feedGroup?.name ?? '')
  const [description, setDescription] = useState(feedGroup?.description ?? '')
  const [savedFeeds, setFeeds] = useState<Feed[]>(feedGroup?.feeds ?? [])
  const updateFeed = useCallback(
    <K extends keyof Feed>(id: string, key: K, value: Feed[K]): void => {
      setFeeds(
        savedFeeds.map((feed) =>
          feed.id === id ? { ...feed, [key]: value } : feed,
        ),
      )
    },
    [savedFeeds],
  )
  useEffect((): void => {
    const last = savedFeeds[savedFeeds.length - 1]
    if (savedFeeds.length === 0) {
      setFeeds([makeEmptyFeed()])
    } else if (
      [last.name, last.description, last.url].some((item) => item !== '')
    ) {
      setFeeds([...savedFeeds, makeEmptyFeed()])
    }
  }, [savedFeeds, setFeeds])

  const handleDelete = useCallback((): void => {
    if (feedGroup) {
      deleteFeedGroup(feedGroup.id)
      onClose()
    }
  }, [feedGroup, onClose])

  const handleSubmit = useCallback((): void => {
    const feedsToAdd = savedFeeds.filter(
      (feed) => feed.name !== '' && feed.description !== '' && feed.url !== '',
    )
    if (feedGroup) {
      updateFeedGroup({
        id: feedGroup.id,
        name,
        description,
        feeds: feedsToAdd,
      })
    } else {
      addFeedGroup({
        id: Math.random().toString(),
        name,
        description,
        feeds: feedsToAdd,
      })
    }
    onClose()
  }, [name, description, onClose, savedFeeds, feedGroup])

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <Dialog.Content size="xlarge">
        <Dialog.Header>
          <Dialog.Title>
            {feedGroup ? `Edit ${feedGroup.name}` : 'Create Feed Group'}
          </Dialog.Title>
        </Dialog.Header>
        <Dialog.Body>
          <Flex direction="column" gap="md">
            <div className={styles.inputContainer}>
              <Label htmlFor="new-feed-group-name">Name</Label>
              <Input
                id="new-feed-group-name"
                value={name}
                onChange={(e): void => setName(e.target.value)}
              />
            </div>
            <div className={styles.inputContainer}>
              <Label htmlFor="new-feed-group-description">Description</Label>
              <Textarea
                id="new-feed-group-description"
                value={description}
                onChange={(e): void => setDescription(e.target.value)}
              />
            </div>
            <Heading size="medium">Feeds</Heading>
            <table className={styles.table}>
              <thead>
                <tr className={styles.tableHeading}>
                  <th>Name</th>
                  <th>Description</th>
                  <th>URL</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {savedFeeds.map((feed) => (
                  <tr key={feed.id}>
                    <td>
                      <Input
                        id="new-feed-group-name"
                        value={feed.name}
                        onChange={(e): void =>
                          updateFeed(feed.id, 'name', e.target.value)
                        }
                      />
                    </td>
                    <td>
                      <Input
                        id="new-feed-group-descrtiption"
                        value={feed.description}
                        onChange={(e): void =>
                          updateFeed(feed.id, 'description', e.target.value)
                        }
                      />
                    </td>
                    <td>
                      <Input
                        id="new-feed-group-url"
                        value={feed.url}
                        onChange={(e): void =>
                          updateFeed(feed.id, 'url', e.target.value)
                        }
                      />
                    </td>
                    <td>
                      <IconButton
                        variant="tertiary"
                        label="Delete Feed"
                        tooltip="Delete Feed"
                        onClick={(): void =>
                          setFeeds(savedFeeds.filter((f) => f.id !== feed.id))
                        }
                      >
                        <TrashIcon />
                      </IconButton>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </Flex>
        </Dialog.Body>
        <Dialog.Footer>
          {feedGroup && (
            <Button variant="critical" onClick={handleDelete}>
              Delete Feed Group
            </Button>
          )}
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            {feedGroup ? 'Save' : 'Create'}
          </Button>
        </Dialog.Footer>
      </Dialog.Content>
    </Dialog>
  )
}
