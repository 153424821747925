import mime from 'mime/lite'
import { nanoid } from '@reduxjs/toolkit'
import { getFileMimeType } from './getFileMimeType'

export function getFileName(file: File) {
  if (file.name) return file.name

  const mimeType = getFileMimeType(file)

  return mimeType ? `file-${nanoid}.${mime.getExtension(mimeType)}` : 'file'
}
