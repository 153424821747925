import { rountToTwo } from './helpers'

export enum CommonRatios {
  '1:1' = 1,
  '1.91:1' = 1.91,
  '4:5' = 0.8,
  '16:9' = 1.78,
  '9:16' = 0.56,
  '2:3' = 0.67,
}

// look up the common ratio name from the ratio value
export const findCommonRatioString = (ratio: number): string | undefined => {
  const roundedRatio = rountToTwo(ratio)

  return Object.keys(CommonRatios)[
    Object.values(CommonRatios).indexOf(roundedRatio)
  ]
}
