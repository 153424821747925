import styled, {
  type FlattenSimpleInterpolation,
  type FlattenInterpolation,
  type DefaultTheme,
  type ThemeProps,
  css,
  keyframes,
} from 'styled-components'
import { Text, Select } from '@bufferapp/ui'
import { grayLight, grayLighter, transparent } from '@bufferapp/ui/style/colors'
import Button from '@bufferapp/ui/Button'

const pulse = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
`

const animation = (): FlattenSimpleInterpolation => css`
  ${pulse} 2s ease-in-out infinite alternate;
`

export const skeletonStyles = css`
  border: ${transparent};
  fill: ${transparent};
  *:before {
    background-color: ${transparent};
  }
  border-color: ${transparent};
  background-color: ${transparent};
  cursor: auto;
  background-image: linear-gradient(
    270deg,
    ${grayLighter} 18.75%,
    ${grayLight} 100%
  );
  animation: ${animation};
  user-select: none;
  // make text transprent so that there is no text displayed when loading
  color: ${transparent} !important;
`

type SkeletonProps = {
  displaySkeleton: boolean
}

export const ButtonWithSkeleton = styled(Button)`
  ${(
    props: SkeletonProps,
  ): false | FlattenInterpolation<ThemeProps<DefaultTheme>> =>
    props.displaySkeleton && skeletonStyles}
  ${(
    props: SkeletonProps,
  ): false | FlattenInterpolation<ThemeProps<DefaultTheme>> =>
    props.displaySkeleton &&
    css`
      border: 1px solid ${transparent};
    `}
`

export const SelectWithSkeleton = styled(Select)`
  ${(
    props: SkeletonProps,
  ): false | FlattenInterpolation<ThemeProps<DefaultTheme>> =>
    props.displaySkeleton && skeletonStyles}
  ${(
    props: SkeletonProps,
  ): false | FlattenInterpolation<ThemeProps<DefaultTheme>> =>
    props.displaySkeleton &&
    css`
      border: 1px solid ${transparent};
    `}
`

export const TextWithSkeleton = styled(Text)`
  ${(
    props: SkeletonProps,
  ): false | FlattenInterpolation<ThemeProps<DefaultTheme>> =>
    props.displaySkeleton && skeletonStyles}
  ${(
    props: SkeletonProps,
  ): false | FlattenInterpolation<ThemeProps<DefaultTheme>> =>
    props.displaySkeleton &&
    css`
      border-radius: 4px;
    `}
`
