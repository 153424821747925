import React from 'react'

import { Redirect, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { useSplitEnabled } from '@buffer-mono/features'
import QueuedPosts from '~publish/legacy/queue'
import { SentPosts } from '~publish/legacy/sent'
import GridPosts from '~publish/legacy/grid'
import DraftList from '~publish/legacy/drafts'
import { showPaywall } from '~publish/legacy/drafts/components/DraftList'
import { PostingSchedule } from '~publish/legacy/posting-schedule'
import { GeneralSettings } from '~publish/legacy/general-settings'
import TabNavigation from '~publish/legacy/profile-nav'
import {
  BufferLoading,
  ScrollableContainer,
} from '~publish/legacy/shared-components'
import PageWithSidebarWrapper from '~publish/legacy/app-pages/components/PageWithSidebarWrapper'

const loadingAnimationStyle = {
  textAlign: 'center' as const,
  margin: '1rem 0',
} as const

const Main = styled.main<{ fullWidth: boolean }>`
  max-width: ${(props): number => (props.fullWidth ? 1800 : 864)}px;
  height: 100%;
`

interface TabContentProps {
  tabId: string
  childTabId: string
  profileId: string
  onChangeTab: (args: { profileId: string }) => void
  loadMore: (args: {
    profileId: string
    page: number
    tabId: string
    includeReminders?: boolean
  }) => void
  profileNavTabs: string[]
}

const TabContent = ({
  tabId,
  profileId,
  childTabId,
  loadMore,
  onChangeTab,
  profileNavTabs,
}: TabContentProps): JSX.Element => {
  // if current tabId is not valid, redirect to the queue
  if (profileNavTabs?.length > 0 && !profileNavTabs?.includes(tabId)) {
    onChangeTab({ profileId })
  }

  switch (tabId) {
    case 'queue':
      // @ts-expect-error TS(2322) FIXME: Type '{ profileId: any; }' is not assignable to ty... Remove this comment to see the full error message
      return <QueuedPosts profileId={profileId} />
    case 'grid':
      // @ts-expect-error TS(2322) FIXME: Type '{ profileId: any; }' is not assignable to ty... Remove this comment to see the full error message
      return <GridPosts profileId={profileId} />
    case 'drafts':
    case 'approvals':
      // @ts-expect-error TS(2322) FIXME: Type '{ profileId: any; tabId: any; }' is not assi... Remove this comment to see the full error message
      return <DraftList profileId={profileId} tabId={tabId} />
    case 'sentPosts':
      return <SentPosts profileId={profileId} loadMore={loadMore} />
    case 'settings':
      switch (childTabId) {
        case 'postingSchedule':
          return <PostingSchedule />
        default:
          return <GeneralSettings />
      }
    default:
      return <Redirect to="/" />
  }
}

export interface ProfilePageProps {
  onLoadMore: (args: {
    profileId: string
    page: number
    tabId: string
    includeReminders?: boolean
  }) => void
  loadingMore: boolean
  moreToLoad: boolean
  page: number
  onChangeTab: (args: { profileId: string }) => void
  profileNavTabs: string[]
}

const ProfilePage = ({
  onLoadMore,
  loadingMore,
  moreToLoad,
  page,
  onChangeTab,
  profileNavTabs,
}: ProfilePageProps): JSX.Element => {
  const { profileId, tabId, childTabId } = useParams<{
    profileId: string
    tabId: string
    childTabId?: string
  }>()
  // We need to evaluate `includeReminders` here because `onReachBottom` is run
  // above the actual tab's component.
  const { isEnabled: isRemindersEnabled } = useSplitEnabled('CORE-reminders')

  // sentPosts/posts is a child tab, so check for that too (it's the default if not present)
  const isSentPostsTab =
    tabId === 'sentPosts' && (!childTabId || childTabId === 'posts')
  const includeReminders = isSentPostsTab && isRemindersEnabled

  // CLEANUP: Stories deprecation
  // Stories tab is being deprecated and only supports infinite load on previous version
  const isInfiniteLoadTab =
    tabId === 'queue' || (tabId === 'stories' && !isRemindersEnabled)

  const isOtherPostsTab =
    ['drafts', 'approvals', 'pastReminders', 'pendingTiktok', 'grid'].includes(
      tabId,
    ) ||
    isSentPostsTab ||
    (tabId === 'stories' && isRemindersEnabled)

  const onReachBottom = (): void => {
    // We don't check `moreToLoad` for the queue since it has infinite loading
    if (isInfiniteLoadTab && !loadingMore) {
      onLoadMore({ profileId, page, tabId })
    }

    if (isOtherPostsTab && moreToLoad && !loadingMore) {
      onLoadMore({ profileId, page, tabId, includeReminders })
    }
  }

  return (
    <PageWithSidebarWrapper profileId={profileId} tabId={tabId}>
      <TabNavigation />
      <ScrollableContainer
        profileId={profileId}
        tabId={tabId}
        growthSpace={1}
        loadingMore={loadingMore}
        moreToLoad={moreToLoad}
        page={page}
        onReachBottom={onReachBottom}
      >
        <Main id="main" fullWidth={showPaywall(tabId)}>
          <TabContent
            tabId={tabId}
            profileId={profileId}
            childTabId={childTabId || ''}
            loadMore={onLoadMore}
            profileNavTabs={profileNavTabs}
            onChangeTab={onChangeTab}
          />
          {loadingMore && (
            <div style={loadingAnimationStyle}>
              <BufferLoading size={32} />
            </div>
          )}
        </Main>
      </ScrollableContainer>
    </PageWithSidebarWrapper>
  )
}

export default ProfilePage
