import {
  getVideoResolutionTooSmallMessage,
  getVideoTooLargeMessage,
  getVideoTooShortMessage,
  getVideoTooLongMessage,
  getVideoTooWideMessage,
  getVideoFrameRateMessage,
} from './utils/validationErrorMessages'
import type { Video } from '../../entities/factories'
import type { ServiceDefinition } from '~publish/legacy/constants/services/types'
import type ValidationResult from '~publish/legacy/validation/ValidationResult'
import { VALIDATION_CODE } from '~publish/legacy/validation/constants'
import ValidationSuccess from '~publish/legacy/validation/ValidationSuccess'
import ValidationFail from '~publish/legacy/validation/ValidationFail'

export function validateVideo(
  video: Video | null,
  serviceDefinition: ServiceDefinition,
): ValidationResult {
  if (video === null) {
    return new ValidationSuccess()
  }

  if (
    serviceDefinition.videoMinResolution &&
    (video.height < serviceDefinition.videoMinResolution ||
      video.width < serviceDefinition.videoMinResolution)
  ) {
    return new ValidationFail(
      getVideoResolutionTooSmallMessage(serviceDefinition.videoMinResolution),
      VALIDATION_CODE.VIDEO_SIZE,
    )
  }

  if (
    serviceDefinition.videoMaxSize &&
    video.size > serviceDefinition.videoMaxSize
  ) {
    const maxFileSizeInMb = serviceDefinition.videoMaxSize / 1024 / 1024
    const messageOptions = getVideoTooLargeMessage(maxFileSizeInMb)

    return new ValidationFail(
      messageOptions.generic,
      VALIDATION_CODE.VIDEO_SIZE,
      messageOptions,
      { mediaUrl: video.url },
    )
  }

  if (
    serviceDefinition.videoMinDurationMs &&
    video.durationMs < serviceDefinition.videoMinDurationMs
  ) {
    const messageOptions = getVideoTooShortMessage(
      serviceDefinition.videoMinDurationMs / 1000,
    )
    return new ValidationFail(
      messageOptions.generic,
      VALIDATION_CODE.VIDEO_DURATION,
      messageOptions,
      {
        mediaUrl: video?.url,
        mediaType: video?.mediaType,
      },
    )
  }

  if (
    serviceDefinition.videoMaxDurationMs &&
    video.durationMs > serviceDefinition.videoMaxDurationMs
  ) {
    const messageOptions = getVideoTooLongMessage(
      serviceDefinition.videoMaxDurationMs / 1000,
    )
    return new ValidationFail(
      messageOptions.generic,
      VALIDATION_CODE.VIDEO_DURATION,
      messageOptions,
      {
        mediaUrl: video?.url,
        mediaType: video?.mediaType,
      },
    )
  }

  if (
    serviceDefinition.videoMaxWidth &&
    video.width > serviceDefinition.videoMaxWidth
  ) {
    const messageOptions = getVideoTooWideMessage(
      serviceDefinition.videoMaxWidth,
      video.width,
    )
    return new ValidationFail(
      messageOptions.generic,
      VALIDATION_CODE.VIDEO_SIZE,
      messageOptions,
      { mediaUrl: video.url },
    )
  }

  if (
    serviceDefinition.videoMinFrameRate &&
    serviceDefinition.videoMaxFrameRate &&
    video.frameRate &&
    (video.frameRate < serviceDefinition.videoMinFrameRate ||
      video.frameRate > serviceDefinition.videoMaxFrameRate)
  ) {
    const messageOptions = getVideoFrameRateMessage(
      serviceDefinition.videoMinFrameRate,
      serviceDefinition.videoMaxFrameRate,
      video.frameRate,
    )
    return new ValidationFail(
      messageOptions.generic,
      VALIDATION_CODE.VIDEO_FRAME_RATE,
      messageOptions,
      { mediaUrl: video.url },
    )
  }

  return new ValidationSuccess()
}
