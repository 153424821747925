import type { IgnoreModifier } from '@apollo/client/cache/core/types/common'
import type { MutationBaseOptions } from '@apollo/client/core/watchQueryOptions'
import { graphql } from '~publish/gql'
import type { AddNoteToPostInput } from '~publish/gql/graphql'
import { useAccount } from '~publish/legacy/accountContext'
import { client } from '~publish/legacy/apollo-client'
import { NoteType } from '~publish/legacy/notes/constants'
import { useTypedMutation } from './useTypedMutation'

export const UpdatedNoteOnPost = graphql(/* GraphQL */ `
  fragment UpdatedNoteOnPost on Post {
    __typename
    id
    notes {
      __typename
      id
      text
      type
      createdAt
      updatedAt
      author {
        __typename
        id
        email
        avatar
        isDeleted
      }
      allowedActions
    }
  }
`)

export const AddNoteToPost = graphql(/* GraphQL */ `
  mutation AddNoteToPost($postId: PostId!, $text: String!) {
    addNoteToPost(input: { postId: $postId, text: $text }) {
      __typename
      ... on AddNoteToPostSuccess {
        __typename
        post {
          __typename
          id
          ...UpdatedNoteOnPost
        }
      }
      ... on MutationError {
        message
      }
    }
  }
`)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export const useAddNoteToPost = () => {
  const { account } = useAccount()

  return useTypedMutation(AddNoteToPost, (data) => data.addNoteToPost, {
    successTypename: 'AddNoteToPostSuccess',
    optimisticResponse: (
      { postId, text }: AddNoteToPostInput,
      { IGNORE }: { IGNORE: IgnoreModifier },
    ): MutationBaseOptions['optimisticResponse'] => {
      const existingPost = client.readFragment({
        id: client.cache.identify({ __typename: 'Post', id: postId }),
        fragment: UpdatedNoteOnPost,
      })

      if (!existingPost) {
        return IGNORE
      }

      return {
        __typename: 'Mutation',
        addNoteToPost: {
          __typename: 'AddNoteToPostSuccess',
          post: {
            id: postId,
            __typename: 'Post',
            notes: [
              ...existingPost.notes,
              {
                __typename: 'Note',
                id: 'optimistic-note-id',
                text,
                type: NoteType.UserGenerated,
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString(),
                author: {
                  __typename: 'Author',
                  id: account.id,
                  email: account.email,
                  avatar: null,
                  isDeleted: false,
                },
                allowedActions: [],
              },
            ],
          },
        },
      }
    },
  })
}
