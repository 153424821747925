import { Button, Text } from '@bufferapp/ui'
import PropTypes from 'prop-types'
import React from 'react'
import ComposerSidepanel from '~publish/legacy/shared-components/ComposerSidepanel'
import { getURL } from '~publish/legacy/utils/formatters'
import { StyledWrapper, Tip } from './styles'

const ChannelConnectPopover = ({
  onClose,
}: {
  onClose: (e: React.MouseEvent) => void
}): JSX.Element => {
  const connectChannelUrl = `${getURL.getChannelConnectURL()}?utm_source=channel_connection_prompt`

  const onButtonClick = (): void => {
    window.location.assign(connectChannelUrl)
  }

  return (
    <ComposerSidepanel
      isVisible={true}
      showCloseButton={false}
      onClose={onClose}
      customStyles={{
        top: '50%',
        transform: 'translateY(-50%)',
        right: '-10px',
      }}
    >
      <Tip />
      <StyledWrapper data-testid="connect-channel-paywall">
        <div>
          <Text type="h3">
            Connect a Channel to Publish a Post{' '}
            <span role="img" aria-label="high voltage">
              ⚡️
            </span>
          </Text>
          <div>
            <Text type="p">
              Without a connected channel, you won&apos;t be able to experience
              all our features.
            </Text>
            <Text type="p">
              And don&apos;t worry! We&apos;ll never post anything unless you
              decide so.
            </Text>
          </div>
        </div>
        {/* @ts-expect-error TS(2740) FIXME: Type '{ type: string; label: string; onClick: () =... Remove this comment to see the full error message */}
        <Button
          type="primary"
          label="Connect a Channel"
          onClick={onButtonClick}
        />
      </StyledWrapper>
    </ComposerSidepanel>
  )
}

ChannelConnectPopover.propTypes = {
  onClose: PropTypes.func,
}

ChannelConnectPopover.defaultProps = {
  onClose: (): void => undefined,
}

export default ChannelConnectPopover
