import { getEditorString, type TNodeEntry } from '@udecode/plate'
import type { BufferEditor } from '~publish/legacy/editor/BufferEditor/types.plate'
import type {
  AutocompleteInputElement,
  AutocompleteInputElementInterface,
} from '../nodes'

export const mentionContainsInvalidCharacter = <
  E extends AutocompleteInputElement,
>(
  editor: BufferEditor,
  elementInterface: AutocompleteInputElementInterface<E>,
  nodeEntry: TNodeEntry,
) => {
  const [node, path] = nodeEntry
  if (elementInterface.is(node) && elementInterface?.validMentionRegex) {
    const nodeText = getEditorString(editor, path)

    if (nodeText === elementInterface.trigger) return false
    return nodeText !== nodeText.match(elementInterface.validMentionRegex)?.[0]
  }
  return false
}
