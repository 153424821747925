import styled, { type CSSProp, css } from 'styled-components'
import { grayLight } from '@bufferapp/ui/style/colors'

import { VerticalWrapperStyles } from '~publish/legacy/ai/styles/VerticalWrapper'

export const Wrapper = styled.div`
  ${VerticalWrapperStyles}
  padding: 0;
  height: 100%;
`

export const PromptWrapper = styled.div<{ withBorder?: boolean }>`
  ${VerticalWrapperStyles}
  margin-bottom: 8px;
  height: initial;

  ${(props): CSSProp | null =>
    props.withBorder
      ? css`
          padding-bottom: 24px;
          border-bottom: 1px solid ${grayLight};
          margin-bottom: 8px;
        `
      : null}
`
