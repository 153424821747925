import styled from 'styled-components'

export const CalendarPage = styled.main`
  padding: 24px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
`

export const CalendarHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
`

export const CalendarHeaderRightSide = styled.span`
  display: flex;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 8px;
  }
`
