import { Button } from '@bufferapp/ui'
import { red, redDark } from '@bufferapp/ui/style/colors'
import styled from 'styled-components'

export const DeleteButton = styled(Button)`
  color: ${red};

  :hover {
    color: ${redDark};
  }
`
