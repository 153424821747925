import styled from 'styled-components'

import { ImageOrientation } from '../../../Common/utils'

export const Wrapper = styled.div<{ orientation: string }>`
  position: relative;
  height: ${({ orientation }) => {
    if (orientation === ImageOrientation.Portrait) {
      return '350px'
    }
    if (orientation === ImageOrientation.Landscape) {
      return '161px'
    }
    return '300px'
  }};
  width: 100%;
`

export const Image = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
`

export const ProductsSticker = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  background: #3f352b;
  position: absolute;
  z-index: 1;
  border-radius: 100px;
  bottom: 10px;
  left: 10px;

  svg {
    height: 10px;
    width: 10px;
  }

  /* Display animation */
  animation: fadeInSlideUp 0.2s ease;

  @keyframes fadeInSlideUp {
    from {
      opacity: 0;
      transform: translateY(4px);
    }

    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`
