import { useSplitEnabled } from '@buffer-mono/features'
import { useLocalStorage } from '@buffer-mono/popcorn'
import { BufferTrackerReact as BufferTracker } from '@buffer-mono/tracking-plan'
import { debugLog } from '~publish/helpers/debugLog'

import { useAccount, useOrganizationId } from '~publish/legacy/accountContext'

export const NEW_PUBLISH_KEY = 'preference:single-channel-new-ui-v2'
export const NEW_PUBLISH_SPLIT_NAME = 'publish-revamp'

type PublishRevamp = [
  boolean,
  {
    setPreference: (value: 'enabled' | 'disabled') => void
    isReady: boolean
    isSplitEnabled: boolean
  },
]

export function usePublishRevamp(): PublishRevamp {
  const { account } = useAccount()
  const organizationId = useOrganizationId()
  const signUpDate = account?.createdAt
    ? new Date(account?.createdAt).getTime()
    : undefined

  const [savedPreference, setSavedPreference] = useLocalStorage(
    NEW_PUBLISH_KEY,
    'enabled',
  )

  const { isEnabled: isPublishRevampEnabled, isReady: isPublishRevampReady } =
    useSplitEnabled(NEW_PUBLISH_SPLIT_NAME, { signUpDate })

  const { isEnabled: isOptInEnabled } = useSplitEnabled(
    'publish-revamp-opt-in-banner',
  )

  const isEnabledThroughOptIn = isOptInEnabled && savedPreference === 'enabled'

  const isEnabledThroughSplit =
    isPublishRevampEnabled &&
    (savedPreference === 'enabled' || savedPreference === null)

  debugLog('🪓 savedPreference', savedPreference)
  debugLog('🪓 isPublishRevampEnabled', isPublishRevampEnabled)
  debugLog('🪓 isOptInEnabled', isOptInEnabled)

  const setPreference = (value: 'enabled' | 'disabled'): void => {
    setSavedPreference(value)
    BufferTracker.publishRevampToggle({
      organizationId,
      accountId: account?.id ?? '',
      status: value,
    })
  }

  const isEnabled = isEnabledThroughOptIn || isEnabledThroughSplit

  return [
    isEnabled,
    {
      setPreference,
      isReady: isPublishRevampReady,
      isSplitEnabled: isPublishRevampEnabled || isOptInEnabled,
    },
  ]
}
