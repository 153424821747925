/* eslint-disable @typescript-eslint/ban-ts-comment */
import { client } from '~publish/legacy/apollo-client'

import { useAppSelector, useAppDispatch } from '~publish/legacy/store'
import { selectCurrentOrganizationId } from '~publish/legacy/organizations/selectors'
import { ACTIONS } from '~publish/legacy/ai/components/AIAssistant/AIAssistant.constants'

import {
  contentGenerationStart,
  contentGenerationSuccess,
  contentGenerationError,
  setContentGenerationConfig,
  selectContentGenerationSelection,
  selectAIAssistantPlacement,
} from '~publish/legacy/ai/state/slice'

import {
  getContentGenerationMutation,
  type TContentGenerationMutationInput,
  type TContentGenerationMutationResult,
} from '~publish/legacy/ai/state/mutations'

type TGenerateTextParams = {
  prompt: string
  action: string
  service: string | null
  tone: string | null
  previousResult?: string | null
  retry?: boolean
}

// transform enum ErrorCodes in an array of strings
const handledErrors = [
  'LengthError',
  'Timeout',
  'InsufficientTokens',
  'ServiceError',
  'PromptError',
]

const ERROR_MESSAGE_DEFAULT =
  'Whoops! We are unable to generate content at this time. Please try again.'

/**
 *
 */
function useContentGeneration(): {
  generateContent: ({ action }: TGenerateTextParams) => Promise<void>
} {
  const organizationId = useAppSelector((state) =>
    selectCurrentOrganizationId(state),
  )

  const contentGenerationSelection = useAppSelector((state) =>
    selectContentGenerationSelection(state),
  )

  const placement = useAppSelector((state) => selectAIAssistantPlacement(state))

  const dispatch = useAppDispatch()

  const generateContent = async ({
    prompt,
    action,
    tone,
    service,
    previousResult,
    retry,
  }: TGenerateTextParams): Promise<void> => {
    const actionConfig = ACTIONS[action as keyof typeof ACTIONS]
    const mutationName = actionConfig?.mutation || 'generateContent'
    const requestId = Date.now().toString()
    const serviceValue =
      service && !contentGenerationSelection ? service : undefined

    dispatch(setContentGenerationConfig({ action, prompt, tone }))

    dispatch(contentGenerationStart({ requestId }))

    const status = retry ? 'Retry' : 'First'
    const cta =
      `publish-${placement}-${action}-${status}-1` ||
      'content-generation-ai-assistant-v2'

    await client
      .mutate<
        { action: TContentGenerationMutationResult },
        TContentGenerationMutationInput
      >({
        mutation: getContentGenerationMutation(mutationName),
        variables: {
          organizationId,
          config: {
            prompt,
            cta,
            service: serviceValue,
            tone: tone || undefined,
            previousGeneration: previousResult || undefined,
          },
        },
      })
      .then((response: any) => {
        const { data } = response || {}
        const {
          suggestions,
          code,
          error: errorMessage,
        } = data?.[mutationName] || []

        // An error happened
        if (code) {
          if (handledErrors.includes(code)) {
            dispatch(contentGenerationError({ error: errorMessage, requestId }))
          } else {
            dispatch(
              contentGenerationError({
                error: ERROR_MESSAGE_DEFAULT,
                requestId,
              }),
            )
          }
          return
        }

        const content = suggestions[0]?.content || ''
        dispatch(contentGenerationSuccess({ result: content, requestId }))
      })
      .catch(() => {
        dispatch(
          contentGenerationError({ error: ERROR_MESSAGE_DEFAULT, requestId }),
        )
      })
  }

  return {
    generateContent,
  }
}

export { useContentGeneration }
