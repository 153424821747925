import styled from 'styled-components'
import { purple } from '@bufferapp/ui/style/colors'

export const PostContainer = styled.div<{ draggable?: boolean }>`
  display: flex;
  width: 100%;
  cursor: ${(props): string => (props?.draggable ? 'grab' : 'inherit')};
`

export const PostSubContainer = styled.div`
  flex-grow: 1;
  min-width: 0;
`

export const PostContent = styled.div<{ dragging?: boolean }>`
  padding: 1rem;
  opacity: ${(props): number => (props.dragging ? 0 : 1)};
`

export const StatsContainer = styled.div`
  position: relative;
`

export const UpgradeCtaStyled = styled.div`
  position: absolute;
  display: flex;
  width: 490px;
  right: 16px;
  bottom: 6px;
  align-items: center;
  justify-content: center;

  button {
    font-weight: 600;
    height: 28px;
    font-size: 12px;

    svg {
      color: ${purple};
    }
  }
`
