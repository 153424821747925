import React, { useEffect } from 'react'
import { PlusIcon, Sidebar, UpgradeBadge } from '@buffer-mono/popcorn'

import {
  useCurrentOrganization,
  useIsAdmin,
  useIsFreeUser,
} from '~publish/legacy/accountContext'
import { getAccountURL } from '~publish/legacy/utils/formatters/getURL'
import { useIsAtPlanLimit } from '~publish/hooks/useIsAtPlanLimit'
import { useCta } from '~publish/hooks/useCta'

import styles from './ChannelNav.module.css'

export function NewChannelButton(): JSX.Element {
  const currentOrganization = useCurrentOrganization()
  const isAtPlanLimit = useIsAtPlanLimit()
  const isAdmin = useIsAdmin()
  const isFree = useIsFreeUser()

  const hasPaymentDetails =
    currentOrganization.billing?.paymentDetails?.hasPaymentDetails ?? null
  const currentChannelQuantity = currentOrganization?.channels?.length ?? 0

  const { viewed: ctaViewed, clicked: ctaClicked } = useCta(
    'publish-sidebar-channels-addChannels-1',
    {
      upgradePathName: 'addChannel-upgrade',
      extraProperties: {
        currentChannelQuantity,
        isAtPlanLimit,
        hasPaymentDetails,
      },
    },
  )

  useEffect(() => {
    if (isAdmin && isFree && isAtPlanLimit) {
      ctaViewed()
    }
  }, [isFree, isAtPlanLimit, ctaViewed, isAdmin])

  if (!isAdmin) {
    return <></>
  }

  return (
    <Sidebar.ListItem>
      <Sidebar.Button
        as="a"
        size="large"
        href={`${getAccountURL()}/channels/connect`}
        prefix={
          <span className={styles.icon}>
            <PlusIcon />
          </span>
        }
        suffix={isAtPlanLimit && <UpgradeBadge />}
        className={styles.newChannelButton}
        onClick={ctaClicked}
      >
        New Channel
      </Sidebar.Button>
    </Sidebar.ListItem>
  )
}
