import React from 'react'
import { useDispatch } from 'react-redux'
import { actions as modalsActions } from '~publish/legacy/modals'
import { SEGMENT_NAMES } from '~publish/legacy/constants'
import PayWallModal from '~publish/legacy/shared-components/PaywallModal/PaywallModal'
import { ContentForSimplePaywall } from '~publish/legacy/shared-components/PaywallModal/ContentForSimplePaywall'

export const HashTagManagerPaywallModal = (): JSX.Element => {
  const dispatch = useDispatch()

  const closeModal = (): any => {
    dispatch(modalsActions.hideHashTagManagerPaywallModal())
  }

  return (
    <PayWallModal
      cta="publish-hashtagManager-composer-seeUpgradeOptions"
      upgradePathName="hashtagManager-upgrade"
      ctaForUgradePath={SEGMENT_NAMES.HASHTAG_MANAGER_UPGRADE}
      ctaMPMigration={SEGMENT_NAMES.HASHTAG_MANAGER_UPGRADE_TO_MIGRATION_HUB}
      onClosePaywall={closeModal}
    >
      <ContentForSimplePaywall
        keyForTexts="hashtag-manager-paywall"
        paywalImage="https://buffer-publish.s3.amazonaws.com/images/hashtag-manager-paywall-v3.png"
      />
    </PayWallModal>
  )
}
