import React from 'react'

import IgProductTag from '@bufferapp/ui/Icon/Icons/IgProductTag'

import type { Image } from '~publish/legacy/composer/composer/entities/factories'

import { getOrientation } from '../../../Common/utils'

import * as Styles from './styles'

export const ImagePreview = ({
  images,
  stickerProducts,
}: {
  images: Image[]
  stickerProducts?: string
}) => {
  const firstImage = images[0]
  const { height, width } = firstImage
  const orientation = getOrientation({ height, width })

  return (
    <Styles.Wrapper data-testid="preview-images" orientation={orientation}>
      {stickerProducts && (
        <Styles.ProductsSticker>
          <IgProductTag color="white" />
        </Styles.ProductsSticker>
      )}
      <Styles.Image src={firstImage.url} />
    </Styles.Wrapper>
  )
}
