/**
 * Thin wrapper around @bufferapp/buffer-js-api
 */

// @ts-expect-error TS(7016) FIXME: Could not find a declaration file for module '@buf... Remove this comment to see the full error message
import BufferAPI from '@bufferapp/buffer-js-api'
import RPCClient from './RPCClient'

class API extends BufferAPI {
  // @ts-expect-error TS(7006) FIXME: Parameter 'url' implicitly has an 'any' type.
  static request(url, data = {}, settings) {
    return RPCClient.call('composerApiProxy', {
      url: `/1/${url}`,
      args: data,
      HTTPMethod: settings.method,
    })
  }
}

export default API
