import React from 'react'
import { AIAssistant } from '~publish/legacy/ai/components/AIAssistant/AIAssistant'
import {
  selectAIAssistantOpened,
  selectAIAssistantResponsive,
  toggleAIAssistant,
} from '../../../legacy/ai/state/slice'
import { useAppDispatch, useAppSelector } from '../../../legacy/store'
import IdeaComposerSidepanel from '../IdeaComposerSidePanel'
import { useAnimatedVisibility } from '../../../legacy/hooks/useAnimatedVisibility'
import { useIdeaComposerEditor } from '../IdeaComposer'

const AIAssistantSidePanel = ({
  state,
  onClose,
  onAnimationEnd,
}: {
  onClose: () => void
  state?: string
  onAnimationEnd?: () => void
}): JSX.Element => {
  const editor = useIdeaComposerEditor()

  return (
    <IdeaComposerSidepanel
      onClose={onClose}
      state={state}
      onAnimationEnd={onAnimationEnd}
    >
      <AIAssistant editor={editor} />
    </IdeaComposerSidepanel>
  )
}

/**
 * Sidepanel for the IdeaComposer that renders the AIAssistant.
 *
 * Uses Redux state to determine which side to render the AIAssistant on.
 * TODO: Should simplify conditional styling logic even more.
 */
export const AIAssistantIdeasSidePanel = (props: {
  children?: React.ReactNode
}): JSX.Element => {
  const { children } = props
  const dispatch = useAppDispatch()

  const AIAssistantOpened = useAppSelector((state) =>
    selectAIAssistantOpened(state),
  )
  const AIAssistantResponsive = useAppSelector((state) =>
    selectAIAssistantResponsive(state),
  )

  const onCloseAIAssistant = React.useCallback((): void => {
    dispatch(toggleAIAssistant(false))
  }, [dispatch])

  React.useEffect(() => {
    return (): void => {
      // close and reset content generation state
      onCloseAIAssistant()
    }
  }, [onCloseAIAssistant])

  const {
    isVisibleInDOM: AIPanelVisible,
    shouldBeVisible: AIPanelShouldBeVisible,
    onAnimationEnd: AIPanelAnimationEnd,
  } = useAnimatedVisibility({
    visibilityCondition: AIAssistantOpened,
  })

  // Determine which side to render the AIAssistant on based on AIAssistantSidePanel state
  return (
    <>
      {AIPanelVisible && !AIAssistantResponsive && (
        <AIAssistantSidePanel
          onClose={onCloseAIAssistant}
          state={AIPanelShouldBeVisible ? 'open' : 'closed'}
          onAnimationEnd={AIPanelAnimationEnd}
        />
      )}
      {children}
      {AIPanelVisible && AIAssistantResponsive && (
        <AIAssistantSidePanel
          onClose={onCloseAIAssistant}
          state={AIPanelShouldBeVisible ? 'open' : 'closed'}
          onAnimationEnd={AIPanelAnimationEnd}
        />
      )}
    </>
  )
}
