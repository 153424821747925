import BaseValidator from '~publish/legacy/composer/composer/lib/validation/BaseValidator'
import type ValidationFail from '~publish/legacy/validation/ValidationFail'
import type ValidationResult from '~publish/legacy/validation/ValidationResult'
import ValidationSuccess from '~publish/legacy/validation/ValidationSuccess'
import { gbpChannelDataValidation } from '~publish/legacy/composer/composer/lib/validation/utils/gbpChannelDataValidation'

export default class GoogleBusinessValidator extends BaseValidator {
  protected validateAttachmentOrText(): ValidationResult {
    if (
      this.draft.updateType === 'offer' ||
      this.draft.updateType === 'event'
    ) {
      return new ValidationSuccess()
    }

    return super.validateAttachmentOrText()
  }

  protected validateGBPTitle(): ValidationResult {
    return gbpChannelDataValidation.validateGBPTitle(
      this.draft.updateType,
      this.draft.channelData?.googlebusiness,
    )
  }

  protected validateGBPCouponCode(): ValidationResult {
    return gbpChannelDataValidation.validateGBPCouponCode(
      this.draft.updateType,
      this.draft.channelData?.googlebusiness,
    )
  }

  protected validateGBPDateTime(): ValidationResult {
    return gbpChannelDataValidation.validateGBPDateTime(
      this.draft.updateType,
      this.draft.channelData?.googlebusiness,
    )
  }

  protected validateGBPCTA(): ValidationResult {
    return gbpChannelDataValidation.validateGBPCTA(
      this.draft.updateType,
      this.draft.channelData?.googlebusiness,
    )
  }

  protected validateGBPRedeemLink(): ValidationResult {
    return gbpChannelDataValidation.validateGBPRedeemLink(
      this.draft.updateType,
      this.draft.channelData?.googlebusiness,
    )
  }

  protected validateGBPTerms(): ValidationResult {
    return gbpChannelDataValidation.validateGBPTerms(
      this.draft.updateType,
      this.draft.channelData?.googlebusiness,
    )
  }

  protected validateDraft(): ValidationFail[] {
    if (
      this.draft.updateType === 'whats_new' &&
      (this.draft.hasText() || this.draft.hasAttachment()) &&
      !this.shouldValidateVideo()
    ) {
      return []
    }

    const videoValidationResults = this.shouldValidateVideo()
      ? this.validateVideo()
      : new ValidationSuccess()

    return [
      this.validateAttachmentOrText(),
      videoValidationResults,
      this.validateGBPTitle(),
    ].filter((validation): validation is ValidationFail =>
      validation.isValidationFail(),
    )
  }

  protected validateGBPPhoneNumber(): ValidationResult {
    return gbpChannelDataValidation.validateGBPContainsPhoneNumber(
      this.draft.text,
    )
  }

  protected validateForChannel(): ValidationResult[] {
    return [
      this.validateGBPTitle(),
      this.validateGBPCouponCode(),
      this.validateGBPDateTime(),
      this.validateGBPCTA(),
      this.validateGBPRedeemLink(),
      this.validateGBPTerms(),
      this.validateGBPPhoneNumber(),
    ]
  }
}
