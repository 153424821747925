import styled from 'styled-components'
import { grayDark } from '@bufferapp/ui/style/colors'

export const Wrapper = styled.div`
  display: flex;
`

export const ContentWrapper = styled.div`
  max-width: 720px;
  flex: 1;
  padding: 48px;

  h2,
  p {
    margin-top: 0;
  }

  ul {
    padding: 0;
    margin: 0 0 32px 0;
  }

  li {
    display: flex;
    align-items: center;
    list-style: none;
    margin-bottom: 8px;
    color: ${grayDark};

    svg {
      margin-right: 4px;
    }
  }
`

export const ImageWrapper = styled.div`
  flex: 1;
  background-image: url('https://s3.amazonaws.com/static.buffer.com/images/ai-assistant-upgrade-plan@2x.png');
  background-size: cover;
  background-position-y: -50px;
`
