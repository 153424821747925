export const blueprints = [
  {
    id: '7_day_challenge',
    icon: 'https://apollin.com/wp-content/uploads/2022/08/grow.png',
    summary: 'Get off on the right foot and lay a strong foundation',
    badge: 'new',
    description: [
      {
        type: 'text',
        content:
          'Tell the story behind your brand, lay a strong foundation, and captivate your audience with these 10 creative, value-based, thoughtful social media prompts over the next 7 days. These are content ideas that have been proven to get you on the right foot and inspire authentic engagement.',
      },
      {
        type: 'image',
        content:
          'https://apollin.com/wp-content/uploads/2022/08/Screenshot-2022-08-23-at-13.54.34.png',
      },
      {
        type: 'text',
        content:
          'When you select the Find Your Voice 7 Day Plan, 10 prompts will be added to your Buffer calendar. From there you’ll be able to easily see, manage, edit, and schedule it. To see the prompt and easily create and schedule a post, just click on the Blueprint draft.',
      },
      {
        type: 'image',
        content: 'https://apollin.com/wp-content/uploads/2022/08/7_day_1.png',
      },
      {
        type: 'text',
        content:
          'All prompts have suggested media ideas, try to use them to increase your engagement. Visual content is always a plus for your audience!\n',
      },
      {
        type: 'image',
        content:
          'https://apollin.com/wp-content/uploads/2022/08/Screenshot-2022-08-23-at-13.57.04.png',
      },
    ],
    title: 'Find Your Voice 7 Day Challenge',
    is_premium: false,
    updates: [
      {
        content: `💡 Introduce yourself

Introduce yourself, share your story, and unveil what makes you and your brand unique in this social media post. This will lead to a much deeper human connection with your followers.

📷 Introduce yourself on camera or share a photo of you, your team, or a behind-the-scenes shot.`,
        day: 1,
        time: '07:00',
      },
      {
        content: `💡 Show a tutorial

Film a product tutorial on your phone of your best selling products. Don’t forget to mention your product’s value props and to add captions!

If you don’t have a product, then share a tutorial of something related to your industry. If you work at a real estate agency, you could film a tutorial of how to decorate a home for an open house, or if you work at a non profit, you could film a tutorial of all the ways people can make donations.

📷 Attach a step-by-step video or stop motion video showing how to use your product or learn something new.`,
        day: 1,
        time: '17:30',
      },
      {
        content: `💡 Share your “why”

Most people are really good at explaining the “what” and the “how” of their business, but the “why” is what will make a potential customer choose your business over another. The “why” is your differentiator.

In this social media post, share your “why” and your vision statement with your audience to form deeper connections and build a memorable brand.

📷 Accompany the post with a branded graphic that shares your “why” or a photo of your team.`,
        day: 2,
        time: '10:00',
      },
      {
        content: `💡 Spotlight a community member

Spotlight a community member by introducing them to your audience through a mini interview or by highlight their accomplishments and thanking them for being a member. We’d encourage highlighting members from all different backgrounds! 

Take a look at a community spotlight post we created here at Buffer: https://www.instagram.com/p/CKUmNK0LvR1/

📷 Share a photo of the community member or create a graphic that highlights a quote from them or an interesting fact–click on the Canva button in the composer below create it with our Canva integration.`,
        day: 3,
        time: '08:00',
      },
      {
        content: `💡 Attach GIFs when sharing blog posts

We found great success with using GIFs in our posts. Instead of sharing an image with a blog post, we choose a relevant (and often funny) GIF to go alongside the blog post. Share direct links to the blog posts whenever you can so your audience can easily access it!

📷 Click on the GIPHY button in the composer below, type in a relevant keyword, and add the GIF to your post.`,
        day: 3,
        time: '17:00',
      },
      {
        content: `💡 Answer common FAQs

What are the most frequent questions you receive from your customers? Film a video responding to those questions and share it on social media. Here are some FAQ's you could answer: "What services do you offer? How much is shipping and how long will it take? What is the story behind your business? ”

📷 Record a video of yourself on your phone responding to your most common FAQs.`,
        day: 4,
        time: '11:00',
      },
      {
        content: `💡 Memeify it

If you want to connect with your audience in a funny and relatable way, memes are the way to go–they’re a a great place to start if you want to boost engagement and show off some personality. 

📷 To create a highly shareable meme, think of pop culture moments and timely trends that are happening around the world (are the Oscars coming up? Tour de France? What movies just hit the theaters?), find a funny photo or video related to that moment, and overlay it with some humor and text that fits your brand’s personality. Click the Canva button below to connect to your Canva account and create a meme in minutes.

For some inspo, take a look at a meme we created here at Buffer: https://www.instagram.com/p/CZopOhFsHwr/`,
        day: 5,
        time: '07:00',
      },
      {
        content: `💡 Share a trending industry-related tip

Sharing educational content on your social media will encourage others to follow you for insights and educational purposes.

For example, if you sell clean beauty products, you could talk about the clean ingredients used for one of your products. Or if you work at an animal rescue center, you could share a step-by-step on how to take care of a newly adopted pet. Here at Buffer, we often share key learnings about trending social media topic.

📷 Share your tip and key learnings as a graphic or plain-text post or on camera on your Instagram Stories or TikTok.`,
        day: 6,
        time: '10:00',
      },
      {
        content: `💡 Curate user-generated content

Adding user-generated content (UGC) to your socials is a great way to fill your feed with community-made content, to show gratitude to your customers, and to attract new followers. In the caption, you could share a testimonial, if they happened to write one up.

📷 Look for UGC in your tagged photos, branded hashtags, customer reviews, or emails sent to you by customers.`,
        day: 7,
        time: '08:00',
      },
      {
        content: `💡 Behind-the-scenes content

Take your followers behind the scenes and show them the inner workings of your company and daily life. They’ll get to know you better and what you have to offer, which will only build more trust.

📷 Record a video or show a photo of your warehouse or office, the making of new products, a team work session, or your packages being shipped out.`,
        day: 7,
        time: '15:30',
      },
    ],
  },
  {
    id: 'first_1000_followers',
    icon: 'https://apollin.com/wp-content/uploads/2022/08/people.png',
    badge: 'coming_soon',
    title: 'Your First 1000 Followers',
    summary: 'How to find & engage your tribe quickly',
    description: [],
    is_premium: false,
    updates: [],
  },
  {
    id: 'build_a_fanbase',
    icon: 'https://apollin.com/wp-content/uploads/2022/08/song.png',
    badge: 'coming_soon',
    title: 'Build a Fanbase',
    summary: 'Connect with your fans with depth and authenticity',
    description: [],
    is_premium: false,
    updates: [],
  },
  {
    id: 'sell_more_products',
    icon: 'https://apollin.com/wp-content/uploads/2022/08/shop.png',
    badge: 'coming_soon',
    title: 'Sell More Products',
    summary: 'How to sell a lot via social without being salesy',
    description: [],
    is_premium: false,
    updates: [],
  },
  {
    id: 'inspired_instagram',
    icon: 'https://apollin.com/wp-content/uploads/2022/08/chart.png',
    badge: 'coming_soon',
    title: 'Inspired Instagram',
    channels: ['instagram'],
    summary: 'How the best brands use the gram',
    description: [],
    is_premium: false,
    updates: [],
  },
  {
    id: 'champion_dei',
    icon: 'https://apollin.com/wp-content/uploads/2022/08/idea.png',
    badge: 'coming_soon',
    title: 'Champion Diversity & Inclusion',
    summary: 'Make the world a better place with your brand',
    description: [],
    is_premium: false,
    updates: [],
  },
]
