import styled from 'styled-components'
import { gray } from '@bufferapp/ui/style/colors'

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const ImageWrapper = styled.div`
  margin-bottom: 52px;
`

export const Message = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;
  color: ${gray};
  margin: 0;
  text-align: center;
`

export const Image = styled.img`
  width: 230px;
  height: 260px;
  margin-bottom: 10px;
`
