import React from 'react'
import { Button, VisuallyHidden } from '@buffer-mono/popcorn'

import { usePostData } from './PostCardContext'
import { PostCardNotice } from './PostCardNotice'

export const PostCardErrorNotice = (): JSX.Element | null => {
  const { status, error } = usePostData()

  if (status !== 'error' || !error) {
    return null
  }

  return (
    <PostCardNotice variant="critical">
      <VisuallyHidden>Error: </VisuallyHidden>
      {error.message}
      {error.supportUrl && (
        <PostCardNotice.Action>
          <Button
            variant="critical"
            size="small"
            as="a"
            href={error.supportUrl ?? ''}
            rel="noopener noreferrer"
            target="_blank"
          >
            Learn More
          </Button>
        </PostCardNotice.Action>
      )}
    </PostCardNotice>
  )
}

PostCardErrorNotice.displayName = 'PostCardErrorNotice'
