import React, { useEffect, useId, useRef, useState } from 'react'
import clsx from 'clsx'

import { Input, VisuallyHidden } from '@buffer-mono/popcorn'

import styles from './ColumnNameForm.module.css'

type ColumnNameFormProps = {
  initialName?: string
  className?: string
  onSubmit: (newName: string) => void | Promise<void>
  onCancel?: () => void
}

export const ColumnNameForm: React.FC<ColumnNameFormProps> = ({
  initialName,
  className,
  onSubmit,
  onCancel,
}) => {
  const [name, setName] = useState(initialName ?? '')
  const inputRef = useRef<HTMLInputElement>(null)
  const inputId = useId()

  useEffect(() => {
    // Automatically focus the input, setTimeout is used to avoid
    // focusing the menu button when editing from there.
    setTimeout(() => inputRef.current?.focus(), 0)
  }, [])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setName(event.target.value)
  }

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
    event.target.form?.dispatchEvent(
      new Event('submit', { cancelable: true, bubbles: true }),
    )
  }

  const handleKeyDown: React.KeyboardEventHandler<HTMLFormElement> = (
    event,
  ): void => {
    if (event.key === 'Escape') {
      onCancel?.()
      event.currentTarget.form?.dispatchEvent(
        new Event('reset', { cancelable: true, bubbles: true }),
      )
    }
  }

  const handleFormSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
  ): Promise<void> => {
    event.preventDefault()
    await onSubmit(name.trim())
  }

  return (
    /* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
    <form
      onSubmit={handleFormSubmit}
      // TODO: remove when migrating to input from popcorn
      // BDS input does not support onKeyDown property
      // so having to specify it for the form
      onKeyDown={handleKeyDown}
      className={clsx(styles.form, className)}
    >
      <VisuallyHidden as="label" htmlFor={inputId}>
        Group name
      </VisuallyHidden>
      <Input
        id={inputId}
        name="group-name"
        autoComplete="off"
        minLength={1}
        maxLength={20}
        value={name}
        onBlur={handleBlur}
        onChange={handleChange}
        ref={inputRef}
        className={styles.input}
        aria-describedby={`${inputId}-description`}
      />
      <VisuallyHidden as="span" id={`${inputId}-description`}>
        Press <kbd>Enter</kbd> to save or <kbd>Esc</kbd> to cancel
      </VisuallyHidden>
    </form>
    /* eslint-enable */
  )
}
