import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Text, Button, Link } from '@bufferapp/ui'
import { gray, white } from '@bufferapp/ui/style/colors'
import { useTranslation } from 'react-i18next'
import { HC_UTM_PARAMS } from '~publish/legacy/utils/contants'
import { PlusIcon } from '@buffer-mono/popcorn'

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid ${gray};
  border-radius: 4px;
  padding: 28px 20px;
  background-image: url('https://s3.amazonaws.com/buffer-publish/images/campaigns-yellow-background.svg'),
    url('https://s3.amazonaws.com/buffer-publish/images/camapigns-green-background.svg');
  background-position:
    right bottom,
    left top;
  background-repeat: no-repeat, no-repeat;
  background-color: ${white};
`

const SubText = styled.div`
  max-width: 430px;
  p {
    margin: 0px 0px 24px;
    text-align: center;
  }
`

const LinkWithStyles = styled(Link)`
  display: inline-block;
  padding: 16px;
`

// @ts-expect-error TS(7031) FIXME: Binding element 'onCreatePostClick' implicitly has... Remove this comment to see the full error message
const EmptyCampaignBanner = ({ onCreatePostClick }) => {
  const { t } = useTranslation()

  return (
    <Container>
      <Text type="h1">
        {t('campaigns.viewCampaign.emptyCampaignBanner.tagTitle')}
      </Text>
      <SubText>
        <Text type="p">
          {t('campaigns.viewCampaign.emptyCampaignBanner.tagSubtext')}
        </Text>
      </SubText>
      {/* @ts-expect-error TS(2740) FIXME: Type '{ onClick: any; type: string; label: any; }'... Remove this comment to see the full error message */}
      <Button
        onClick={onCreatePostClick}
        type="primary"
        icon={<PlusIcon />}
        label={t('campaigns.viewCampaign.emptyCampaignBanner.createPosts')}
      />
      <LinkWithStyles
        href={`https://support.buffer.com/article/585-creating-and-managing-campaigns?${HC_UTM_PARAMS}`}
        newTab
      >
        {t('campaigns.viewCampaign.emptyCampaignBanner.learnMore')}
      </LinkWithStyles>
    </Container>
  )
}

EmptyCampaignBanner.propTypes = {
  onCreatePostClick: PropTypes.func.isRequired,
}

export default EmptyCampaignBanner
