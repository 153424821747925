import styled from 'styled-components'
import { Button } from '~publish/legacy/ai/components/AIAssistant/components/Button.styles'
import { grayDark, grayLight } from '@bufferapp/ui/style/colors'
import { fontFamily } from '@bufferapp/ui/style/fonts'

export const PlaceholderButton = styled(Button)<{
  secondary: boolean
}>`
  display: inline-block;
  background-color: white;
  color: ${grayDark};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  font-family: ${fontFamily};
  border-radius: 4px;
  border: 1px solid ${grayLight};
  padding: 8px 7px;
  margin-left: 6px;
  transition: all 0.3s ease-in-out;
  pointer-events: auto;
  cursor: pointer;
  opacity: 0.5;

  svg {
    margin-right: 8px;
  }

  &:hover {
    opacity: 1;
    background: linear-gradient(90deg, #ba6bff 0%, #0113ff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    border: 1px solid ${grayLight};
  }
`
