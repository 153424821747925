import type { ApolloCache, Reference, StoreObject } from '@apollo/client'
import { graphql } from '~publish/gql'
import type {
  SwapPostsInQueueInput,
  SwapPostsInQueueMutation,
  GetPostListQuery,
  Post,
} from '~publish/gql/graphql'
import { useTypedMutation } from '~publish/components/PostCard/useTypedMutation'
import { client } from '~publish/legacy/apollo-client'

const SwapPostsInQueue = graphql(/* GraphQL */ `
  mutation SwapPostsInQueue($input: SwapPostsInQueueInput!) {
    swapPostsInQueue(input: $input) {
      __typename
      ... on SwapPostsInQueueSuccess {
        posts {
          id
          dueAt
          status
          isCustomScheduled
          isPinned
        }
      }
      ... on MutationError {
        message
      }
    }
  }
`)

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/explicit-function-return-type
export const useSwapPostsMutation = () =>
  useTypedMutation(SwapPostsInQueue, (data) => data.swapPostsInQueue, {
    successTypename: 'SwapPostsInQueueSuccess',
    optimisticResponse: buildOptimisticResponse,
    update: updateCache,
  })

function buildOptimisticResponse({
  input,
}: {
  input: SwapPostsInQueueInput
}): SwapPostsInQueueMutation {
  const sourcePost = client.cache.readFragment<Post>({
    id: client.cache.identify({ __typename: 'Post', id: input.sourceId }),
    fragment: graphql(/* GraphQL */ `
      fragment SourcePost on Post {
        id
        dueAt
        status
        isCustomScheduled
        isPinned
      }
    `),
  })

  const targetPost = client.cache.readFragment<Post>({
    id: client.cache.identify({ __typename: 'Post', id: input.targetId }),
    fragment: graphql(/* GraphQL */ `
      fragment TargetPost on Post {
        id
        dueAt
        status
        isCustomScheduled
        isPinned
      }
    `),
  })

  if (!sourcePost || !targetPost) {
    throw new Error('Unable to find posts in cache')
  }

  return {
    __typename: 'Mutation',
    swapPostsInQueue: {
      __typename: 'SwapPostsInQueueSuccess',
      posts: [
        {
          __typename: 'Post',
          id: input.sourceId,
          dueAt: targetPost.dueAt,
          status: targetPost.status,
          isCustomScheduled: targetPost.isCustomScheduled,
          isPinned: targetPost.isPinned,
        },
        {
          __typename: 'Post',
          id: input.targetId,
          dueAt: sourcePost.dueAt,
          status: sourcePost.status,
          isCustomScheduled: sourcePost.isCustomScheduled,
          isPinned: sourcePost.isPinned,
        },
      ],
    },
  }
}

function updateCache(
  cache: ApolloCache<any>,
  { data }: { data?: SwapPostsInQueueMutation | null },
  { variables }: { variables?: { input: SwapPostsInQueueInput } | null },
): void {
  if (!variables) return

  const sourcePost = cache.readFragment<Post>({
    id: cache.identify({ __typename: 'Post', id: variables.input.sourceId }),
    fragment: graphql(/* GraphQL */ `
      fragment SourcePost on Post {
        id
        dueAt
        status
        isCustomScheduled
        isPinned
      }
    `),
  })

  const targetPost = cache.readFragment<Post>({
    id: cache.identify({ __typename: 'Post', id: variables.input.targetId }),
    fragment: graphql(/* GraphQL */ `
      fragment TargetPost on Post {
        id
        dueAt
        status
        isCustomScheduled
        isPinned
      }
    `),
  })

  if (!sourcePost || !targetPost) {
    console.error('Unable to find posts in cache')
    return
  }

  const swappedPosts =
    data?.swapPostsInQueue.__typename === 'SwapPostsInQueueSuccess'
      ? data.swapPostsInQueue.posts
      : [
          { ...sourcePost, dueAt: targetPost.dueAt },
          { ...targetPost, dueAt: sourcePost.dueAt },
        ]

  cache.modify({
    fields: {
      posts: (
        existingPosts: Reference | StoreObject,
        { readField, toReference },
      ) => {
        const edges = readField(
          'edges',
          existingPosts,
        ) as GetPostListQuery['posts']['edges']
        if (!edges) return existingPosts

        const newEdges = edges.map((edge) => {
          const postId = readField('id', edge.node)
          const swappedPost = swappedPosts.find((post) => post.id === postId)
          if (swappedPost) {
            return {
              ...edge,
              node: toReference({
                __typename: 'Post',
                id: postId,
                dueAt: swappedPost.dueAt,
                status: swappedPost.status,
                isCustomScheduled: swappedPost.isCustomScheduled,
                isPinned: swappedPost.isPinned,
              }),
            }
          }
          return edge
        })

        newEdges.sort((a, b) => {
          const aDueAt = readField('dueAt', a.node) as string
          const bDueAt = readField('dueAt', b.node) as string
          return new Date(aDueAt).getTime() - new Date(bDueAt).getTime()
        })

        return {
          ...existingPosts,
          edges: newEdges,
        }
      },
    },
  })
}
