import { ParagraphElement } from '../plugins/paragraph/nodes/ParagraphElement'

/**
 * Reset the provided editor's text and selection state
 */
// @ts-expect-error TS(7006) FIXME: Parameter 'editor' implicitly has an 'any' type.
export const resetEditorText = (editor) => {
  // Prepopulating an editor's children with an empty paragraph
  // is required to accommodate programatic selection and text insertion.
  // Text as the first child of the editor will be removed by
  // Slate's internal normalization rules.
  editor.children = [{ type: ParagraphElement.type, children: [{ text: '' }] }]
  editor.selection = {
    anchor: { path: [0, 0], offset: 0 },
    focus: { path: [0, 0], offset: 0 },
  }
  editor.prevSelection = null
}
