import { useState, useEffect, useRef } from 'react'

type UseHandleScrollReturn = {
  headerShadow: boolean
  childRef: React.RefObject<HTMLDivElement>
}

function useHandleScroll(): UseHandleScrollReturn {
  const [headerShadow, setHeaderShadow] = useState(false)
  const childRef = useRef<HTMLDivElement>(null)
  const current = childRef.current

  useEffect(() => {
    if (current) {
      const handleScroll = (): void => {
        const scrollTop = current?.scrollTop ?? 0
        const isScrolling = scrollTop > 0
        if (!!current !== isScrolling) {
          setHeaderShadow(isScrolling)
        }
      }

      current.addEventListener('scroll', handleScroll)
      return (): void => {
        current?.removeEventListener('scroll', handleScroll)
      }
    }
  }, [current])

  return {
    headerShadow,
    childRef,
  }
}

export default useHandleScroll
