import { useCallback, useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '~publish/legacy/store'
import { selectUserId } from '~publish/legacy/user/selectors'
import { selectCurrentOrganizationId } from '~publish/legacy/organizations/selectors'
import { Uploader, type UploaderEvents } from '../lib/Uploader'
import { bindUploaderToReduxSlice } from '../state/bindUploaderToReduxSlice'

import type { OnFileReadyCb } from '../types'
import { UploaderRestrictions } from '../values/UploaderRestrictions'
import { selectCurrentFileCount } from '../state/selectors'
import { useStore } from 'react-redux'
import type { Upload } from '../entities/Upload'
import { uploadRemoved } from '../state/slice'
import { useS3Signature } from '../clients/getS3Signature'
import { createUppy } from '../uppy/createUppy'
import { addUploaderTracking } from '../lib/uploader/addUploaderTracking'

const defaultFileRestrictions = UploaderRestrictions.new()
/**
 * Returns an uploader instance for the given id with
 * uploader events bound to the redux store.
 * It also returns the current state of the uploader
 * and some callbacks.
 */
export const useUploader = ({
  id,
  fileRestrictions = defaultFileRestrictions,
  eventHandlers,
  getCustomCount,
}: {
  id: string
  fileRestrictions?: UploaderRestrictions | (() => UploaderRestrictions)
  eventHandlers?: Partial<UploaderEvents>
  getCustomCount?: () => number
}): {
  uploader: Uploader
  onFileReady: OnFileReadyCb
  removeUpload: (upload: Upload) => void
} => {
  const dispatch = useAppDispatch()
  const { getState } = useStore()
  const userId = useAppSelector(selectUserId)
  const organizationId = useAppSelector(selectCurrentOrganizationId)
  const getCount = useCallback((): number => {
    if (getCustomCount) {
      return getCustomCount()
    }

    return selectCurrentFileCount(getState(), id)
  }, [getState, id, getCustomCount])

  const getS3Signature = useS3Signature()

  const uploader = useMemo(() => {
    const uppyInstance = createUppy({
      id,
      userId,
      getS3Signature,
    })

    const uploaderInstance = new Uploader(
      id,
      userId,
      organizationId,
      uppyInstance,
      fileRestrictions,
      getS3Signature,
      getCount,
    )

    if (eventHandlers) {
      Object.keys(eventHandlers).forEach((eventName) => {
        const handler = eventHandlers[eventName as keyof UploaderEvents]
        if (handler) {
          uploaderInstance.on(eventName as keyof UploaderEvents, handler)
        }
      })
    }

    bindUploaderToReduxSlice(uploaderInstance, { dispatch })
    addUploaderTracking(uploaderInstance)

    return uploaderInstance
  }, [
    id,
    userId,
    organizationId,
    fileRestrictions,
    getS3Signature,
    getCount,
    eventHandlers,
    dispatch,
  ])

  useEffect(() => {
    return () => {
      uploader.close()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- close uploader on unmount
  }, [])

  return useMemo(
    () => ({
      uploader: uploader as Uploader,

      onFileReady: (file, source): void => {
        if (file)
          uploader.addFiles([file], {
            source,
          })
      },

      removeUpload: (upload: Upload): void => {
        uploader.removeUpload(upload)

        if (upload) {
          dispatch(uploadRemoved(upload))
        }
      },
    }),
    [uploader, dispatch],
  )
}
