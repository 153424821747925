import {
  PostTypePost,
  PostTypeReel,
  PostTypeStory,
} from '~publish/legacy/post/constants'
import { CommonRatios } from '../aspect-ratio/CommonRatios'
import { aspectRatioRange } from '../validation/rules/aspectRatioRange'
import type { ServiceMediaRestrictions } from './types'

export const InstagramMediaRestrictions: ServiceMediaRestrictions = {
  [PostTypePost]: {
    images: [
      aspectRatioRange.define({
        min: CommonRatios['4:5'],
        max: CommonRatios['1.91:1'],
      }),
    ],
    videos: [
      aspectRatioRange.define(
        {
          min: CommonRatios['4:5'],
          max: CommonRatios['16:9'],
        },
        {
          messages: {
            generic: 'Your video is outside the aspect ratio of {min} to {max}',
          },
        },
      ),
    ],
  },
  [PostTypeStory]: {
    videos: [
      aspectRatioRange.define(
        {
          min: {
            width: 1,
            height: 10,
          },
          max: {
            width: 10,
            height: 1,
          },
        },
        {
          messages: {
            generic: 'Your video is outside the aspect ratio of {min} to {max}',
          },
        },
      ),
    ],
  },
  [PostTypeReel]: {
    videos: [
      aspectRatioRange.define(
        {
          min: {
            width: 1,
            height: 100,
          },
          max: {
            width: 10,
            height: 1,
          },
        },
        {
          messages: {
            generic: 'Your video is outside the aspect ratio of {min} to {max}',
          },
        },
      ),
    ],
  },
}
