import { Link, Text, UnstyledButton } from '@buffer-mono/popcorn'
import React from 'react'
import twitterText from 'twitter-text'
import {
  parseTextToElements,
  type TextToElementEntity,
} from '~publish/legacy/utils/parseTextToElements'

import styles from '../NotesWidget.module.css'

export const MAX_TEXT_LENGTH = 250

const parseLinks = (text: string): TextToElementEntity[] => {
  return twitterText.extractUrlsWithIndices(text).map(({ url, indices }) => {
    return {
      displayString: url,
      indices,
    }
  })
}

const wrapLink = (
  element: TextToElementEntity,
  key: string | number,
): JSX.Element => {
  const urlWithProtocol = /^(?:f|ht)tps?:\/\//.test(element.displayString)
    ? element.displayString
    : 'https://' + element.displayString
  return (
    <Link href={urlWithProtocol} key={key}>
      {element.displayString}
    </Link>
  )
}

type NoteContentProps = {
  text: string
  isEdited?: boolean
}

export const NoteContent = ({
  text,
  isEdited = false,
}: NoteContentProps): JSX.Element => {
  const [isExpanded, setIsExpanded] = React.useState(false)
  const canExpandText = text.length > MAX_TEXT_LENGTH && !isExpanded
  const truncatedText = canExpandText
    ? `${text.slice(0, MAX_TEXT_LENGTH).trim()}...`
    : text

  const elements = parseTextToElements(
    truncatedText,
    parseLinks(truncatedText),
    wrapLink,
  )

  return (
    <Text as="p" className={styles.noteText}>
      {elements}&nbsp;
      {canExpandText && (
        <UnstyledButton
          onClick={(): void => setIsExpanded(true)}
          className={styles.readMoreButton}
        >
          <Text color="subtle">more&nbsp;</Text>
        </UnstyledButton>
      )}
      {isEdited && <Text color="subtle">(edited)</Text>}
    </Text>
  )
}
