import React from 'react'
import WarningIcon from '@bufferapp/ui/Icon/Icons/Warning'
import CrossIcon from '@bufferapp/ui/Icon/Icons/Cross'

import * as Styled from './Notice.styles'

/**
 *
 */
function Notice({
  id,
  message,
  dismissible,
}: {
  id: string
  message: string
  dismissible?: boolean
}): JSX.Element | null {
  const [visible, setVisible] = React.useState(false)

  React.useEffect(() => {
    const dismissed = localStorage.getItem(`notice-${id}`) === 'dismissed'
    setVisible(!dismissed)
  }, [id])

  const onDismissNotice = (): void => {
    localStorage.setItem(`notice-${id}`, 'dismissed')
    setVisible(false)
  }

  /**
   * TODO: To see if we can replace this with Popcorn's Notice component.
   * - Do we want to add support for dismissing notices on Popcorn or we want to handle that outside?
   *   If we are OK with handling that on Popcorn then we can remove this component completely.
   * - Do we want to keep using local storage for this or we would prefer to save the status on the DB?
   */
  return visible ? (
    <Styled.Wrapper>
      <Styled.Message>
        <WarningIcon />
        <p>{message}</p>
      </Styled.Message>
      {dismissible && (
        <Styled.Dismiss
          role="button"
          aria-label="Dismiss notification"
          onClick={onDismissNotice}
        >
          <CrossIcon aria-hidden="true" focusable="false" />
        </Styled.Dismiss>
      )}
    </Styled.Wrapper>
  ) : null
}

export { Notice }
