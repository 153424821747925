import React, { type ChangeEvent } from 'react'
import styled from 'styled-components'
import { SimpleColorSwatch } from '~publish/legacy/shared-components'

const ColorPickerContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 16px 0 14px;

  label:not(:nth-child(9)) {
    margin-right: 8px;
    margin-bottom: 10px;
  }

  *:last-child {
    margin-right: 0 !important;
  }
`

export interface SimpleColorPickerProps
  extends React.HTMLAttributes<HTMLDivElement> {
  colors: {
    color: string
    colorName: string
  }[]
  colorSelected?: string
  onColorClick: (evt: ChangeEvent<HTMLInputElement>) => void
  className?: string
}

const SimpleColorPicker = ({
  colors,
  colorSelected = '',
  onColorClick,
  className,
  ...props
}: SimpleColorPickerProps): JSX.Element => (
  <ColorPickerContainer className={className} {...props}>
    {colors.map((item) => {
      const isColorSelected = colorSelected === item.color
      return (
        <SimpleColorSwatch
          key={item.color}
          color={item.color}
          colorName={item.colorName}
          selected={isColorSelected}
          onColorClick={onColorClick}
        />
      )
    })}
  </ColorPickerContainer>
)

export default SimpleColorPicker
