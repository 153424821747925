import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import DraftFooter from '~publish/legacy/shared-components/DraftFooter'
import { selectHasFirstCommentEntitlement } from '~publish/legacy/organizations/selectors'
import { useShowThreadsUpgrade } from '~publish/legacy/utils/canQueueMoreThreadsForAllProfiles'
import { usePostActions } from '../../../post/hooks/usePostActions'
import { PostEntity } from '../../../post/PostEntity'
import { usePostPermissions } from '../../../post/hooks'

export const ProfilesDraftFooter = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'view' implicitly has an 'any' typ... Remove this comment to see the full error message
  view,
  // @ts-expect-error TS(7031) FIXME: Binding element 'draft' implicitly has an 'any' ty... Remove this comment to see the full error message
  draft,
  // @ts-expect-error TS(7031) FIXME: Binding element 'postAction' implicitly has an 'an... Remove this comment to see the full error message
  postAction,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isDeleting' implicitly has an 'an... Remove this comment to see the full error message
  isDeleting,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isConfirmingDelete' implicitly ha... Remove this comment to see the full error message
  isConfirmingDelete,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isMoving' implicitly has an 'any'... Remove this comment to see the full error message
  isMoving,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isWorking' implicitly has an 'any... Remove this comment to see the full error message
  isWorking,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onApproveClick' implicitly has an... Remove this comment to see the full error message
  onApproveClick,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onDeleteConfirmClick' implicitly ... Remove this comment to see the full error message
  onDeleteConfirmClick,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onEditClick' implicitly has an 'a... Remove this comment to see the full error message
  onEditClick,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onRevertApprovalClick' implicitly... Remove this comment to see the full error message
  onRevertApprovalClick,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onRequestApprovalClick' implicitl... Remove this comment to see the full error message
  onRequestApprovalClick,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onRescheduleClick' implicitly has... Remove this comment to see the full error message
  onRescheduleClick,
  // @ts-expect-error TS(7031) FIXME: Binding element 'isReminder' implicitly has an 'an... Remove this comment to see the full error message
  isReminder,
}) => {
  const { isManager, isPostOwner, hasEditPermission } =
    usePostPermissions(draft)

  const hasFirstCommentEntitlement = useSelector(
    selectHasFirstCommentEntitlement,
  )

  const firstComment = PostEntity.getFirstComment(draft)
  const isPastDue = PostEntity.isPastDue(draft)
  const isScheduled = PostEntity.isScheduled(draft)

  const isApprovalsTab = view === 'approval'

  // Performing Actions
  const deletingMessage = (isDeleting || isConfirmingDelete) && 'Deleting...'
  const movingMessage = isMoving && isApprovalsTab && 'Moving...'
  const requestingMessage = isMoving && !isApprovalsTab && 'Requesting...'
  const approvingMessage =
    isWorking && isManager && isApprovalsTab && 'Approving...'
  const addingMessage = isWorking && isManager && !isApprovalsTab && 'Adding...'

  const isPerformingAction = isDeleting || isMoving || isWorking
  const actionMessage =
    deletingMessage ||
    movingMessage ||
    requestingMessage ||
    approvingMessage ||
    addingMessage ||
    ''

  const handleEditClick =
    isManager || (isPostOwner && !isApprovalsTab) ? onEditClick : null
  const onDeleteClick = hasEditPermission ? onDeleteConfirmClick : null

  const { singleActions, stackedActions, executePostActionById } =
    usePostActions({
      post: draft,
      onApproveClick,
      onRequestApprovalClick,
      onRescheduleClick,
      onRevertApprovalClick,
    })

  const {
    shouldShowThreadsMigrationModal,
    service: threadsMigrationModalService,
  } = useShowThreadsUpgrade(draft)

  return (
    <DraftFooter
      isScheduled={isScheduled}
      isPastDue={isPastDue}
      description={postAction}
      actionMessage={actionMessage}
      isPerformingAction={!!isPerformingAction}
      hasFirstComment={hasFirstCommentEntitlement && firstComment}
      hasEditPermission={hasEditPermission}
      singleActions={singleActions}
      stackedActions={stackedActions}
      executePostActionById={executePostActionById}
      onDeleteClick={onDeleteClick}
      onEditClick={handleEditClick}
      threadCounter={Array.isArray(draft.thread) ? draft.thread.length : 0}
      shouldShowThreadsMigrationModal={shouldShowThreadsMigrationModal}
      threadsMigrationModalService={threadsMigrationModalService}
      isReminder={isReminder}
      post={draft}
    />
  )
}

ProfilesDraftFooter.propTypes = {
  view: PropTypes.string.isRequired,
  draft: PropTypes.shape({
    updateType: PropTypes.string,
    thread: PropTypes.array,
  }),
  postAction: PropTypes.string,
  isDeleting: PropTypes.bool,
  isConfirmingDelete: PropTypes.bool,
  isMoving: PropTypes.bool,
  isWorking: PropTypes.bool,
  onApproveClick: PropTypes.func,
  onDeleteConfirmClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onRevertApprovalClick: PropTypes.func,
  onRequestApprovalClick: PropTypes.func,
  onRescheduleClick: PropTypes.func,
}

ProfilesDraftFooter.defaultProps = {
  draft: null,
  postAction: null,
  isDeleting: false,
  isMoving: false,
  isWorking: false,
  isConfirmingDelete: false,
  onApproveClick: null,
  onDeleteConfirmClick: null,
  onEditClick: null,
  onRevertApprovalClick: null,
  onRequestApprovalClick: null,
  onRescheduleClick: null,
}
