import { useMemo, useEffect, useState, type RefObject } from 'react'

/**
 * Hook to check if an element is on screen, using intersection observer,
 * returns a boolean if element is onscreen
 *
 * @param ref
 * @returns boolean
 * @example
 * ```tsx
 * const buttonVisible = useOnScreen(buttonRef)
 * ```
 */
const useOnScreen = (ref: RefObject<HTMLElement>): boolean => {
  const [isIntersecting, setIntersecting] = useState(false)

  const observer = useMemo(
    () =>
      new IntersectionObserver(
        ([entry]) => setIntersecting(entry.isIntersecting),
        { rootMargin: '0px' },
      ),
    [],
  )

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current)
      return (): void => observer.disconnect()
    }
  }, [ref, observer])

  return isIntersecting
}

export { useOnScreen }
