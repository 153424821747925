import React, { type ChangeEvent, type KeyboardEvent } from 'react'
import twitterText from 'twitter-text'
import { useExpandableText } from '~publish/legacy/hooks/useExpandableText'
import { ENTER_KEY, ESCAPE_KEY } from '~publish/legacy/utils/contants'
import {
  parseTextToElements,
  type TextToElementEntity,
} from '~publish/legacy/utils/parseTextToElements'

import {
  EditedText,
  EditInput,
  NoteText,
  ShowMoreTextButton,
  StyledLink,
} from '../styles'

const parseLinks = (text: string): TextToElementEntity[] => {
  return twitterText.extractUrlsWithIndices(text).map(({ url, indices }) => {
    return {
      displayString: url,
      indices,
      rawString: url,
    }
  })
}

const wrapLink = (
  element: TextToElementEntity,
  key: string | number,
): JSX.Element => {
  const urlWithProtocol = /^(?:f|ht)tps?:\/\//.test(element.displayString)
    ? element.displayString
    : 'http://' + element.displayString
  return (
    <StyledLink href={urlWithProtocol} key={key} newTab>
      {element.displayString}
    </StyledLink>
  )
}

type NoteInputProps = {
  isEditing: boolean
  isEdited: boolean
  editedText: string
  text: string
  setEditedText: (text: string) => void
  onSave: () => void
  onCancel: () => void
}

export const NoteInput = ({
  isEditing,
  isEdited,
  editedText,
  text,
  setEditedText,
  onSave,
  onCancel,
}: NoteInputProps): JSX.Element => {
  const { expandedText, isExpanded, toggleExpansion } = useExpandableText(text)
  const elements = parseTextToElements(
    expandedText,
    parseLinks(expandedText),
    wrapLink,
  )

  const onKeyDown = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === ENTER_KEY) {
      event.preventDefault()
      event.stopPropagation()
      onSave()
    }

    if (event.key === ESCAPE_KEY) {
      event.preventDefault()
      event.stopPropagation()
      onCancel()
    }
  }

  if (isEditing)
    return (
      <EditInput
        type="input"
        name="Edit note"
        maxLength={500}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        value={editedText}
        onKeyDown={onKeyDown}
        onChange={(e: ChangeEvent<HTMLInputElement>): void =>
          setEditedText(e.target.value)
        }
      />
    )
  return (
    <div>
      <NoteText>{elements}&nbsp;</NoteText>
      {text.length > 250 && (
        <ShowMoreTextButton
          onClick={toggleExpansion}
          aria-label={`${isExpanded ? 'Show less' : 'Show more'}`}
        >
          {isExpanded ? 'Show less' : 'Show more'}&nbsp;
        </ShowMoreTextButton>
      )}
      {isEdited && <EditedText>(edited)</EditedText>}
    </div>
  )
}
