import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useTranslation } from 'react-i18next'
import { ImagesLayout } from './ImagesLayout'

import {
  MasonryContainer,
  NoResultsLayout,
  TextWithoutMarginTop,
} from './styles'

import type { GraphImage, IntegrationsService } from '../../types'

const MAX_WINDOW_SIZE = 10

export const SearchImagesResults = ({
  images = [],
  getMore,
  loading,
  loader,
  service,
}: {
  images: GraphImage[]
  getMore: () => void
  loading: boolean
  loader: React.ReactElement
  service: IntegrationsService
}) => {
  const { t } = useTranslation()

  if (images?.length === 0 && loading) {
    return loader
  }

  if (images?.length === 0 && !loading) {
    return (
      <NoResultsLayout>
        <TextWithoutMarginTop type="h3">
          {t('content.modals.integrations.noResults')}
        </TextWithoutMarginTop>
        <TextWithoutMarginTop type="p">
          {t('content.modals.integrations.tryAgain')}
        </TextWithoutMarginTop>
      </NoResultsLayout>
    )
  }

  return (
    <MasonryContainer id="scrollableContainer">
      <InfiniteScroll
        dataLength={images ? images.length : 0}
        hasMore={images.length >= MAX_WINDOW_SIZE}
        next={getMore}
        loader={loader}
        scrollableTarget="scrollableContainer"
      >
        <ImagesLayout images={images} service={service} />
      </InfiniteScroll>
    </MasonryContainer>
  )
}
