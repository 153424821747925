import styled from 'styled-components'
import { grayDarker } from '@bufferapp/ui/style/colors'
import { fontFamily } from '@bufferapp/ui/style/fonts'

export const Label = styled.label`
  font-family: ${fontFamily};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: ${grayDarker};
`
