/**
 * Component that displays a thumbnail that fits the width of its container
 */

import React from 'react'
import PropTypes from 'prop-types'
import { escapeParens } from '../utils/StringUtils'
import styles from './css/LinkAttachmentThumbnail.module.css'

// @ts-expect-error TS(7006) FIXME: Parameter 'props' implicitly has an 'any' type.
const LinkAttachmentThumbnail = (props) => {
  const { thumbnail } = props
  const hasThumbnail = thumbnail !== null

  return (
    <div>
      {hasThumbnail ? (
        <div
          className={styles.thumbnail}
          style={{ backgroundImage: `url(${escapeParens(thumbnail.url)})` }}
        />
      ) : (
        <div className={styles.thumbnailPlaceholder} />
      )}
    </div>
  )
}

LinkAttachmentThumbnail.propTypes = {
  thumbnail: PropTypes.shape({
    url: PropTypes.string,
  }),
}

LinkAttachmentThumbnail.defaultProps = {
  thumbnail: null,
}

export default LinkAttachmentThumbnail
