import { connect } from 'react-redux'
import { actions as analyticsActions } from '~publish/legacy/analytics-middleware/actions'
import UserTags from './components/UserTags'

export default connect(
  (state) => ({
    // @ts-expect-error TS(2339) FIXME: Property 'i18n' does not exist on type 'DefaultRoo... Remove this comment to see the full error message
    translations: state.i18n.translations['user-tags'],
  }),
  (dispatch) => ({
    // @ts-expect-error TS(7031) FIXME: Binding element 'channel' implicitly has an 'any' ... Remove this comment to see the full error message
    trackTag: ({ channel, username }) => {
      const metadata = {
        channelId: channel.id,
        channelServiceId: channel.serviceId,
        channelUsername: channel.service ? channel.service.username : null,
        taggedUsername: username,
      }
      dispatch(analyticsActions.trackEvent('Instagram User Tagged', metadata))
    },
    // @ts-expect-error TS(7031) FIXME: Binding element 'channel' implicitly has an 'any' ... Remove this comment to see the full error message
    trackAllTags: ({ channel, tags }) => {
      // @ts-expect-error TS(7006) FIXME: Parameter 'tag' implicitly has an 'any' type.
      const getTaggedUsernames = () => tags.map((tag) => tag.username)
      const metadata = {
        channelId: channel.id,
        channelServiceId: channel.serviceId,
        channelUsername: channel.service ? channel.service.username : null,
        // @ts-expect-error TS(2554) FIXME: Expected 0 arguments, but got 1.
        taggedUsernames: getTaggedUsernames(tags),
        taggedUserCount: tags.length,
      }
      dispatch(analyticsActions.trackEvent('Instagram Tags Saved', metadata))
    },
  }),
)(UserTags)
