import {
  createSlice,
  type PayloadAction,
  type Slice,
  type SliceCaseReducers,
} from '@reduxjs/toolkit'
import { useReducer } from 'react'
import type { GraphImage } from '../../types'

/*
 * REDUCER TO MANAGE UNSPLASH ENDPOINTS
 */
type SearchImageState = {
  images: GraphImage[]
  query: string
  orientation: string | null
  currentPage: number
  error: string | null
}

export const initialState: SearchImageState = {
  images: [],
  query: '',
  orientation: null,
  currentPage: 1,
  error: null,
}

export const searchImagesSlice: Slice<
  SearchImageState,
  SliceCaseReducers<SearchImageState>,
  'searchImages'
> = createSlice({
  name: 'searchImages',
  reducers: {
    getMore: (state) => {
      state.currentPage = state?.currentPage ? state.currentPage + 1 : 1
    },
    search: (state, action: PayloadAction<string>) => {
      if (state.currentPage !== 1 || state.query !== action.payload) {
        state.images = []
      }

      state.error = null
      state.query = action.payload
      state.currentPage = 1
    },
    searchSucccess: (state, action: PayloadAction<GraphImage[]>) => {
      const newImages = Array.isArray(action.payload) ? action.payload : []
      state.images.push(...newImages)
    },
    searchError: (state, action: PayloadAction<string>) => {
      state.error = action.payload
    },
    cleanQuery: (state) => {
      state.images = state.query !== '' ? [] : state.images
      state.currentPage = state.query !== '' ? 1 : state.currentPage
      state.query = ''
      state.error = null
    },
    changeOrientation: (state, action: PayloadAction<string>) => {
      state.images = []
      state.error = null
      state.currentPage = 1
      state.orientation = action.payload
    },
  },
  initialState,
})

export const { actions } = searchImagesSlice

export const useSearchImagesReducer = () =>
  useReducer(searchImagesSlice.reducer, searchImagesSlice.getInitialState())
