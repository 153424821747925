import { Editor } from 'slate'
import { getSelectedText } from '~publish/legacy/ai/helpers/getSelectedText'

/**
 * We have two possible events to get the changes in selections (from window and from editor)
 * but both are not firing in all the cases, for example, when the user selects all the text
 * in the editor but a char and remove the selected text with Del key => window.onselectiononchange doesn’t fire,
 * editor.onchange fires but it is taking the window selection text as selected text, so,
 * it will continue showing the Refine button. Nevertheless, when all the text is selected and removed with
 * Del key, both events fires but the editor one fires first and it is taking the window selection text as selected text
 * when is null. To solve these scenarios, we will manage both events with this handler, get the selected text from both sources
 * and if they are the same, we will update the selected state, if not, we will reset the selected state.
 */

function getSelection({ editor }: { editor: any }): string | null {
  const selectedTextByEditor = editor.selection
    ? Editor.string(editor, editor.selection).trim()
    : null

  const selectedTextByWindow = getSelectedText()

  if (!selectedTextByWindow || !selectedTextByEditor) {
    return null
  }

  return selectedTextByWindow
}

export { getSelection }
