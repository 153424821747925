/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import PencilIcon from '@bufferapp/ui/Icon/Icons/Pencil'
import { useAccount } from '~publish/legacy/accountContext'
import type { BufferEditor } from '~publish/legacy/editor/BufferEditor/types.plate'
import { Channel } from '~publish/legacy/ai/components/AIAssistant/components/Channel'
import { Breadcrumb } from '~publish/legacy/ai/components/AIAssistant/components/Breadcrumb'
import { MODULES, ACTIONS } from './AIAssistant.constants'
import { Services } from '~publish/legacy/constants'
import { useAppDispatch, useAppSelector } from '~publish/legacy/store'
import { Prompt } from '~publish/legacy/ai/components/AIAssistant/components/Prompt'
import { Title } from '~publish/legacy/ai/components/AIAssistant/components/Title'
import { Button } from '~publish/legacy/ai/components/AIAssistant/components/Button'
import { Footer } from '~publish/legacy/ai/components/AIAssistant/components/Footer'
import { Onboarding } from '~publish/legacy/ai/components/AIAssistant/components/onboarding/Onboarding'

import * as Modules from './modules'

import {
  selectContentGenerationStatus,
  resetContentGeneration,
  resetSelection,
  contentGenerationCancelled,
  selectContentGenerationSelection,
} from '~publish/legacy/ai/state/slice'

import * as Styled from './AIAssistant.styles'

/**
 *
 */
const AIAssistantContext = React.createContext<{
  editor: BufferEditor | null
  channel: string | null
}>({
  editor: null,
  channel: null,
})

/**
 *
 */
function AIAssistant({
  editor,
  channel,
}: {
  editor: BufferEditor
  channel?: string
}): JSX.Element {
  const [initAction, setInitAction] = React.useState<string | null>(null)
  const [initPrompt, setInitPrompt] = React.useState<string | null>(null)
  const [activeModule, setActiveModule] = React.useState<string | null>(null)
  const [expanded, setExpanded] = React.useState<boolean>(false)
  const dispatch = useAppDispatch()

  const { account } = useAccount() as any

  const {
    contentGenerationInProgress,
    contentGenerationResults,
    contentGenerationError,
  } = useAppSelector((state) => selectContentGenerationStatus(state))

  const contentGenerationSelection = useAppSelector((state) =>
    selectContentGenerationSelection(state),
  )

  const hasResults = contentGenerationResults.length > 0

  React.useEffect(() => {
    // changes in the editor content shouldn't reset the error screen
    if (hasResults || contentGenerationInProgress || contentGenerationError)
      return
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0
    dispatch(resetContentGeneration())
    resetActiveModule()
  }, [editor?.children])

  React.useEffect(() => {
    resetActiveModule()
  }, [contentGenerationSelection])

  const resetActiveModule = (): void => {
    const content = editor?.getContent?.() || null
    const newActiveModule = content ? null : MODULES.AIWrite.id
    if (newActiveModule !== activeModule) {
      setActiveModule(newActiveModule)
    }
  }

  const onBack = (): void => {
    const isRefineModule = activeModule === MODULES.AIRefine.id

    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0
    dispatch(contentGenerationCancelled())
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0
    dispatch(resetContentGeneration())

    if (isRefineModule || (!hasResults && !contentGenerationInProgress)) {
      onReset()
    }
  }

  const onReset = (): void => {
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0
    dispatch(contentGenerationCancelled())
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0
    dispatch(resetContentGeneration())
    setInitAction(null)
    setInitPrompt(null)
    resetActiveModule()
  }

  const onClear = (): void => {
    editor?.removeSelection?.()
    // @ts-expect-error TS(2554) FIXME: Expected 1 arguments, but got 0
    dispatch(resetSelection())
    onReset()
  }

  const onWriteMore = (): void => {
    setInitAction('generate')
    setInitPrompt(null)
    setActiveModule(MODULES.AIWrite.id)
  }

  const onRefine = (action: string): void => {
    const content = editor?.getContent?.() || null
    const prompt = contentGenerationSelection || content

    setInitAction(action)
    setInitPrompt(prompt)
    setActiveModule(MODULES.AIRefine.id)
  }

  const onTogglePrompt = (): void => {
    setExpanded((expanded) => !expanded)
  }

  const serviceData = channel ? Services.get(channel) : null
  const serviceLabel = serviceData ? serviceData.formattedName : null

  const validChannel = channel && channel !== 'omni'
  const showChannel =
    validChannel && serviceLabel && !contentGenerationSelection

  const populatedEditor = !!editor?.getContent?.()

  const withBackButton: boolean =
    contentGenerationInProgress ||
    !!contentGenerationError ||
    contentGenerationResults.length > 0 ||
    (!!activeModule && populatedEditor)

  const ActiveModuleComponent =
    Modules[activeModule as keyof typeof Modules] || null

  const onboardingCompleted = account?.settings?.aiSettings?.onboarded

  return (
    <Styled.Wrapper data-testid="ai-assistant-side-panel">
      <Styled.Header>
        <Breadcrumb onGoBack={withBackButton ? onBack : null} />
        {onboardingCompleted && showChannel && <Channel channel={channel} />}
      </Styled.Header>
      <Styled.ContentWrapper>
        {!onboardingCompleted ? (
          <Onboarding />
        ) : activeModule ? (
          <AIAssistantContext.Provider
            value={{
              editor,
              channel: validChannel ? channel : null,
            }}
          >
            <ActiveModuleComponent
              initAction={initAction}
              initPrompt={initPrompt}
              onClear={onClear}
            />
          </AIAssistantContext.Provider>
        ) : (
          <Styled.IdleWrapper>
            {contentGenerationSelection ? (
              <Styled.SelectionWrapper>
                <Title>Using this text:</Title>
                <Prompt
                  prompt={contentGenerationSelection}
                  expanded={expanded}
                  onToggle={onTogglePrompt}
                  onClear={onClear}
                  lines={2}
                  withBackground
                />
              </Styled.SelectionWrapper>
            ) : (
              <Styled.WriteMoreWrapper>
                <Title>How can I help with this post?</Title>
                <Button
                  onClick={onWriteMore}
                  align="left"
                  secondary
                  testId="ai-assistant-write-more-button"
                >
                  <PencilIcon /> Write more
                </Button>
              </Styled.WriteMoreWrapper>
            )}
            <Styled.ActionsWrapper id="ai-assistant-fine-tuning-container">
              {['rephrase', 'shorten', 'expand'].map((action, index) => {
                const actionConfig = ACTIONS[action as keyof typeof ACTIONS]
                const ActionIcon = actionConfig?.IconComponent

                return actionConfig ? (
                  <Button
                    key={index}
                    onClick={(): void => onRefine(action)}
                    align="left"
                    secondary
                    testId={`ai-assistant-fine-tuning-${action}-button`}
                  >
                    <ActionIcon />
                    {`
                      ${actionConfig.label}
                      ${showChannel ? ` for ${serviceLabel}` : ''}
                    `}
                  </Button>
                ) : null
              })}
            </Styled.ActionsWrapper>
          </Styled.IdleWrapper>
        )}
      </Styled.ContentWrapper>
      {onboardingCompleted && <Footer />}
    </Styled.Wrapper>
  )
}

export { AIAssistant, AIAssistantContext }
