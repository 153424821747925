import type { Service } from '~publish/gql/graphql'
import { Service as ServiceEnum } from '~publish/legacy/constants/services/ServiceDefinitions'

import type { MediaRestrictionMap, MediaRestrictions } from './types'
import { InstagramMediaRestrictions } from './instagram-media-restrictions'
import { FacebookMediaRestrictions } from './facebook-media-restrictions'
import { PostType } from '~publish/legacy/constants'
import { YoutubeMediaRestrictions } from './youtube-media-restrictions'

export const POST_MEDIA_RESTRICTIONS: MediaRestrictionMap = {
  [ServiceEnum.Instagram]: InstagramMediaRestrictions,
  [ServiceEnum.Facebook]: FacebookMediaRestrictions,
  [ServiceEnum.Youtube]: YoutubeMediaRestrictions,
}

const getRestrictionsForDraft = (
  service: Service | 'omni',
  postType?: string | null,
): MediaRestrictions | undefined => {
  if (!postType) {
    postType = PostType.POST
  }

  return POST_MEDIA_RESTRICTIONS[service]?.[postType]
}

export const getVideoRestrictionsForDraft = (
  service: Service | 'omni',
  postType?: string | null,
): MediaRestrictions['videos'] => {
  return getRestrictionsForDraft(service, postType)?.videos
}

export const getImageRestrictionsForDraft = (
  service: Service | 'omni',
  postType?: string | null,
): MediaRestrictions['images'] => {
  return getRestrictionsForDraft(service, postType)?.images
}
