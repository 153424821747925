import { useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { getFragmentData } from '~publish/gql/fragment-masking'
import { useQueryParam } from '~publish/hooks/useQueryParam'
import { IdeaCard_Idea } from '~publish/pages/Create/components/IdeaCard/IdeaCard'

import type { Location } from 'history'
import { stripGqlTypenames } from '~publish/helpers/stripGqlTypenames'
import { createPage } from '~publish/legacy/routes'
import type { Idea } from '../../pages/Create/types'
import { IdeaComposer, IDEAS_UPLOADER_ID } from '../IdeaComposer'
import { GetIdea } from './IdeaDetailsDialog'
import { useUploader } from '~publish/legacy/uploads/hooks/useUploader'

/**
 * Component used to open IdeaComposer with existing idea.
 * Renders when corresponding route is matched.
 *
 * It will attempt to fetch the idea with the provided ideaId, returning it from cache first if available.
 *
 * @param parent - If no returnTo location can be found in location state,
 * use the provided parent route to return to when dialog is closed.
 */
export function IdeaEditDialog({
  parent = createPage.route,
}: {
  parent?: string
}): JSX.Element | null {
  const [isOpen, setIsOpen] = useState(true)
  const history = useHistory()
  const location = useLocation<{ returnTo: Location }>()

  const { ideaId } = useParams<{ ideaId: string; id: string }>()
  const [source] = useQueryParam('source')
  const { data, loading } = useQuery(GetIdea, {
    variables: {
      input: {
        id: ideaId,
      },
    },
    fetchPolicy: 'cache-first',
  })
  const { onFileReady, uploader, removeUpload } = useUploader({
    id: IDEAS_UPLOADER_ID,
  })

  const handleOpenChange = (open: boolean): void => {
    setIsOpen(open)
    if (!open) {
      history.push(location?.state?.returnTo ?? parent)
    }
  }

  useEffect(() => {
    if (data?.idea) {
      setIsOpen(true)
    }
  }, [data?.idea, history, source])

  if (loading) {
    return <></>
  }

  if (data?.idea?.__typename !== 'Idea' || !data?.idea) {
    handleOpenChange(false)
    return null
  }

  const idea = getFragmentData(IdeaCard_Idea, data.idea) as Idea

  return (
    <IdeaComposer
      initialIdea={stripGqlTypenames(idea) ?? undefined}
      open={isOpen}
      onOpenChange={handleOpenChange}
      removeUpload={removeUpload}
      onFileReady={onFileReady}
      uploader={uploader}
    />
  )
}
