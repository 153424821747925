import React from 'react'

import type { Image } from '~publish/legacy/composer/composer/entities/factories'

import * as Styles from './styles'

export const ImagesCarousel = ({
  images,
  maxHeight = '300px',
  className,
}: {
  images: Image[]
  maxHeight?: string
  className?: string
}): React.ReactElement => {
  if (!Array.isArray(images) || images.length <= 0) {
    return <></>
  }

  return (
    <Styles.Wrapper
      data-testid="preview-images"
      maxHeight={maxHeight}
      className={className}
    >
      <Styles.CarouselContainer>
        <Styles.CarouselWrapper>
          {images.map((image, index) => (
            <Styles.CarouselImage
              key={index}
              src={image.url}
              alt={`Slide ${index}`}
            />
          ))}
        </Styles.CarouselWrapper>
      </Styles.CarouselContainer>
    </Styles.Wrapper>
  )
}
