import React from 'react'
import { DEFAULT_AVATAR } from '../../../Common/utils'
import * as Styles from './styles'

type Props = {
  avatar: string
}

export const TikTokAvatar = ({ avatar }: Props) => {
  return (
    <Styles.AvatarWrapper>
      <Styles.Avatar
        data-testid="avatar"
        src={avatar}
        fallbackUrl={DEFAULT_AVATAR}
      />
      <Styles.AvatarAddWrapper>
        <Styles.AvatarAddIcon />
      </Styles.AvatarAddWrapper>
    </Styles.AvatarWrapper>
  )
}
