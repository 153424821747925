import { client } from '~publish/legacy/apollo-client'

import { getContentGenerationMutation } from '~publish/legacy/ai/state/mutations'
import { ACTIONS } from '~publish/legacy/ai/components/AIAssistant/AIAssistant.constants'

/**
 *
 */
export async function getGeneratedText({
  organizationId,
  prompt,
  previousGeneration,
  isHighlighted,
  action,
  service,
  metadata,
}: {
  organizationId: string
  prompt: string
  previousGeneration?: string
  isHighlighted: boolean
  action: string
  service?: string
  metadata: { placement: string; retry: boolean }
}): Promise<string> {
  const { placement, retry } = metadata

  let mutationName = action
  if (
    !Object.values(ACTIONS).find((action) => action.mutation === mutationName)
  ) {
    mutationName = 'generateText'
  }

  let generatedContent

  const status = retry ? 'Retry' : 'First'

  const cta = `publish-${placement}-${action}-${status}-${
    isHighlighted ? 'Highlighted-' : ''
  }1`

  await client
    .mutate<
      {
        action: { suggestions?: { content: string }[]; code?: string }
      },
      {
        organizationId: string
        config: {
          prompt: string
          service?: string
          cta: string
          previousGeneration?: string
        }
      }
    >({
      mutation: getContentGenerationMutation(mutationName),
      variables: {
        organizationId,
        config: {
          prompt,
          service,
          cta,
          previousGeneration: previousGeneration || undefined,
        },
      },
    })
    .then((response) => {
      // handles a returned error
      // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      if (response.data?.[action]?.code) {
        const errorMessage =
          // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          response.data?.[action]?.code === 'InsuffiecientTokens'
            ? "Whoops! Looks like you don't have any tokens left."
            : 'Whoops! We are unable to generate content at this time. Please try again.'
        throw new Error(errorMessage)
      } else {
        // handles a successful response
        // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        const generatedText = response.data?.[action].suggestions

        /**
         * for now we are just returning 1 object with a content property
         * but when we return more complex types, we will need to handle it
         * differently */
        generatedContent = generatedText && generatedText[0].content
      }
    })
    .catch(() => {
      const errorMessage =
        'Whoops! We are unable to generate content at this time. Please try again.'

      throw new Error(errorMessage)
    })
  // @ts-expect-error TS(2322) FIXME: Type 'undefined' is not assignable to type 'string... Remove this comment to see the full error message
  return generatedContent
}
