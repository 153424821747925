import styled from 'styled-components'

import * as CommonStyles from '../Common/styles'

export const twitterBlack = '#101318'
export const twitterBlueishGray = '#53636f'
export const retweetBorder = '#D9D9D9'
export const twitterDarker = '#1a8cd8'

export const Wrapper = styled(CommonStyles.Wrapper)`
  padding: 5px 24px 0 24px;
`
