import {
  PostTypeFacebookReel,
  PostTypePost,
} from '~publish/legacy/post/constants'
import { CommonRatios } from '../aspect-ratio/CommonRatios'
import { aspectRatioMatch } from '../validation/rules/aspectRatioMatch'
import { aspectRatioRange } from '../validation/rules/aspectRatioRange'
import type { ServiceMediaRestrictions } from './types'

export const FacebookMediaRestrictions: ServiceMediaRestrictions = {
  [PostTypePost]: {
    images: [
      aspectRatioRange.define({
        min: CommonRatios['4:5'],
        max: CommonRatios['1.91:1'],
      }),
    ],
  },
  [PostTypeFacebookReel]: {
    videos: [
      aspectRatioMatch.define(
        {
          expected: {
            width: 9,
            height: 16,
            readable: '9x16 (vertical)',
          },
        },
        {
          messages: {
            generic:
              "Your video doesn't have the correct aspect ratio of {expected}",
          },
        },
      ),
    ],
  },
}
