const PROFILE_PAGES_PATH_REGEX = /^\/(\w*)\/(\w*)\/(\w*)\/(\w*)\/?(.*)/
const PAGE_PATH_REGEX = /^\/(\w*)\/?(\w*)\/?$/

// @ts-expect-error TS(7031) FIXME: Binding element 'page' implicitly has an 'any' typ... Remove this comment to see the full error message
function getPageName({ page, subPage }) {
  let pageName = null
  let defaultSubPage = 'general-settings'
  switch (page) {
    case 'analytics':
      defaultSubPage = 'posts'
    // eslint-disable-next-line no-fallthrough
    case 'settings':
      // pages have default views even if sub-route name isnt present
      pageName = subPage ? `${page} ${subPage}` : `${page} ${defaultSubPage}`
      break
    default:
      pageName = page
      break
  }
  return pageName
}

// @ts-expect-error TS(7006) FIXME: Parameter 'path' implicitly has an 'any' type.
function getElementFromPath(path, element) {
  const match = path.match(PROFILE_PAGES_PATH_REGEX)
  if (match) {
    const [
      route, // eslint-disable-line no-unused-vars
      channel, // eslint-disable-line no-unused-vars
      channelId,
      tab,
      page,
      subPage,
    ] = match

    switch (element) {
      case 'channelId':
        return channelId
      case 'pageName':
        return getPageName({ page, subPage }) || null
      default:
        return null
    }
  }

  return null
}

// @ts-expect-error TS(7006) FIXME: Parameter 'channel' implicitly has an 'any' type.
function getChannelById(channel, channelId) {
  // @ts-expect-error TS(7006) FIXME: Parameter 'p' implicitly has an 'any' type.
  return channel.find((p) => p.id === channelId) || null
}

// @ts-expect-error TS(7006) FIXME: Parameter 'path' implicitly has an 'any' type.
function getChannelFromPath(path, channels) {
  const channelId = getElementFromPath(path, 'channelId')
  if (channelId) {
    return getChannelById(channels, channelId)
  }

  return null
}

// @ts-expect-error TS(7006) FIXME: Parameter 'path' implicitly has an 'any' type.
const getPageNameFromPath = (path) => {
  const name = getElementFromPath(path, 'pageName')
  if (name) {
    return name
  }

  const page = path.match(PAGE_PATH_REGEX)
  if (page && page[1] && page[1].length) {
    // check for subnames if preferences
    return page[2] && page[2].length ? `${page[1]} ${page[2]}` : page[1]
  }

  return path
}

// @ts-expect-error TS(7031) FIXME: Binding element 'path' implicitly has an 'any' typ... Remove this comment to see the full error message
const getChannelIfNeeded = ({ path, getState }) => {
  const match = path.match(PROFILE_PAGES_PATH_REGEX)
  if (match) {
    // Previously this code depended on the `profiles` reducer but
    // this was from the shared analyze code which is now lazy-loaded,
    // so we use the profileSidebar instead.
    const { profiles } = getState().profileSidebar
    if (!profiles || profiles.length === 0) {
      return null
    }
    const channel = getChannelFromPath(path, profiles)
    return channel ? channel.service : null
  }
  return null
}

export { getPageNameFromPath, getChannelIfNeeded }
