import { useCallback, useRef, useState } from 'react'

type UseIntersectionObserverOptions = {
  rootMargin?: string
  threshold?: number | number[]
  root?: HTMLElement
}

type UseIntersectionObserverReturn = [(node?: Element | null) => void, boolean]

const DEFAULT_THRESHOLD = [0]
const DEFAULT_ROOT_MARGIN = '0px'
/**
 * Hook to use IntersectionObserver to check if an element is on screen
 *
 * @param {UseIntersectionObserverOptions} options
 * @returns {UseIntersectionObserverReturn} [ref, isIntersecting]
 * @example
 * ```tsx
 * const [ref, isVisible] = useIntersectionObserver()
 * ```
 */
const useIntersectionObserver = (
  options: UseIntersectionObserverOptions = {},
): UseIntersectionObserverReturn => {
  const {
    rootMargin = DEFAULT_ROOT_MARGIN,
    threshold = DEFAULT_THRESHOLD,
    root,
  } = options
  const [isIntersecting, setIsIntersecting] = useState<boolean>(false)
  const observerRef = useRef<IntersectionObserver | null>(null)

  const ref = useCallback(
    (node?: Element | null) => {
      if (observerRef.current) {
        observerRef.current.disconnect()
        observerRef.current = null
      }

      if (node?.nodeType === Node.ELEMENT_NODE) {
        const observer = new IntersectionObserver(
          ([entry]) => {
            setIsIntersecting(entry.isIntersecting)
          },
          { threshold, root, rootMargin },
        )

        observer.observe(node)
        observerRef.current = observer
      }
    },
    [threshold, root, rootMargin],
  )

  return [ref, isIntersecting]
}

export { useIntersectionObserver }
export type { UseIntersectionObserverOptions, UseIntersectionObserverReturn }
