import type {
  YoutubeCategory,
  YoutubeChannelData,
} from '~publish/legacy/composer/composer/entities/Draft/types'
import { YOUTUBE_CATEGORIES_US_REGION } from '~publish/legacy/composer/composer/components/youtube/constants'

const DEFAULT_YOUTUBE_CHANNEL_DATA: YoutubeChannelData = {
  title: '',
  category: YOUTUBE_CATEGORIES_US_REGION[0],
  license: 'youtube',
  privacyStatus: 'public',
  notifySubscribers: true,
  embeddable: true,
  madeForKids: false,
}

const validateLicense = (value: unknown): 'youtube' | 'creativeCommon' => {
  return value === 'youtube' || value === 'creativeCommon'
    ? value
    : DEFAULT_YOUTUBE_CHANNEL_DATA.license
}

const validatePrivacyStatus = (
  value: unknown,
): 'public' | 'private' | 'unlisted' => {
  return value === 'public' || value === 'private' || value === 'unlisted'
    ? value
    : DEFAULT_YOUTUBE_CHANNEL_DATA.privacyStatus
}

const validateBoolean = (value: unknown): boolean => {
  return typeof value === 'boolean' ? value : true
}

function isYoutubeCategory(
  value: unknown,
): value is { id: string; title: string } {
  return (
    typeof value === 'object' &&
    value !== null &&
    (value as { id: string; title: string }).id.trim() !== '' &&
    (value as { id: string; title: string }).title.trim() !== ''
  )
}

const validateCategory = (value: unknown): YoutubeCategory => {
  if (isYoutubeCategory(value)) {
    // Check if the value matches one of the categories in the list
    const matchingCategory = YOUTUBE_CATEGORIES_US_REGION.find(
      (category) => category.id === value.id && category.title === value.title,
    )
    if (matchingCategory) {
      return matchingCategory
    }
  }

  // If the value is not valid, return the first category as default
  return DEFAULT_YOUTUBE_CHANNEL_DATA.category
}

// @ts-expect-error TS(7006) FIXME: Parameter 'drafts' implicitly has an 'any' type.
export const saveYoutubeDefaultsToStorage = (drafts) => {
  // @ts-expect-error TS(7006) FIXME: Parameter 'draft' implicitly has an 'any' type.
  const youtubeDrafts = drafts.filter((draft) => draft.id === 'youtube')
  const youtubeDraft =
    youtubeDrafts && youtubeDrafts.length > 0 ? youtubeDrafts[0] : null
  if (youtubeDraft) {
    const {
      channelData: { youtube },
    } = youtubeDraft
    localStorage.setItem('youtube_defaults', JSON.stringify(youtube))
  }
}

export const getYoutubeDefaultsFromStorage = (): YoutubeChannelData => {
  const defaults = localStorage.getItem('youtube_defaults')
  if (defaults) {
    const parsedDefaults = JSON.parse(defaults)

    // Validate parsedDefaults.
    return {
      title: '',
      category: validateCategory(parsedDefaults.category),
      license: validateLicense(parsedDefaults.license),
      privacyStatus: validatePrivacyStatus(parsedDefaults.privacyStatus),
      notifySubscribers: validateBoolean(parsedDefaults.notifySubscribers),
      embeddable: validateBoolean(parsedDefaults.embeddable),
      madeForKids: validateBoolean(parsedDefaults.madeForKids),
    }
  }

  return DEFAULT_YOUTUBE_CHANNEL_DATA
}
