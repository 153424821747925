import React from 'react'
import PropTypes from 'prop-types'

import PostEmptySlot from '../../PostEmptySlot/dropTarget'

// @ts-expect-error TS(7006) FIXME: Parameter 'service' implicitly has an 'any' type.
const getLabel = (service) =>
  service === 'twitter' ? 'Schedule a Tweet' : 'Schedule a Post'

const EmptySlot = ({
  // @ts-expect-error TS(7031) FIXME: Binding element 'item' implicitly has an 'any' typ... Remove this comment to see the full error message
  item,
  // @ts-expect-error TS(7031) FIXME: Binding element 'pinned' implicitly has an 'any' t... Remove this comment to see the full error message
  pinned,
  // @ts-expect-error TS(7031) FIXME: Binding element 'customLabel' implicitly has an 'a... Remove this comment to see the full error message
  customLabel,
  // @ts-expect-error TS(7031) FIXME: Binding element 'customHoverMessage' implicitly ha... Remove this comment to see the full error message
  customHoverMessage,
  // @ts-expect-error TS(7031) FIXME: Binding element 'onEmptySlotClick' implicitly has ... Remove this comment to see the full error message
  onEmptySlotClick,
}) => {
  const { id, slot, profileService } = item
  // if no posting time (unpinned), display custom label
  const unpinnedLabel = !pinned ? getLabel(profileService) : null
  return (
    <PostEmptySlot
      key={id}
      time={slot.label}
      timestamp={slot.timestamp}
      day={slot.dayText}
      service={profileService}
      customLabel={customLabel || unpinnedLabel}
      customHoverMessage={customHoverMessage}
      onClick={() =>
        onEmptySlotClick({
          profile_service: profileService,
          scheduled_at: slot.timestamp,
          scheduledAt: slot.timestamp,
          due_at: slot.timestamp,
          pinned,
        })
      }
    />
  )
}

EmptySlot.propTypes = {
  item: PropTypes.shape({
    slot: PropTypes.shape({
      dayText: PropTypes.string,
      label: PropTypes.string,
      timestamp: PropTypes.number,
    }),
    id: PropTypes.string,
    profileService: PropTypes.string,
  }).isRequired,
  onEmptySlotClick: PropTypes.func,
  pinned: PropTypes.bool,
  customLabel: PropTypes.string,
  customHoverMessage: PropTypes.string,
}

EmptySlot.defaultProps = {
  pinned: false,
  customLabel: null,
  customHoverMessage: null,
  onEmptySlotClick: () => {},
}

export default EmptySlot
