import React from 'react'

import { useAppSelector } from '~publish/legacy/store'
import { useContentGeneration } from '~publish/legacy/ai/hooks/useContentGeneration'
import { AIAssistantContext } from '~publish/legacy/ai/components/AIAssistant/AIAssistant'

import {
  selectContentGenerationStatus,
  selectContentGenerationConfig,
} from '~publish/legacy/ai/state/slice'

import * as Styled from './RetryButton.styles'

/**
 *
 */
function RetryButton({
  disabled,
  withBorder,
  children,
}: {
  disabled?: boolean
  withBorder?: boolean
  children: React.ReactNode
}): JSX.Element {
  const { channel } = React.useContext(AIAssistantContext)

  const { contentGenerationResults } = useAppSelector((state) =>
    selectContentGenerationStatus(state),
  )

  const { prompt, action, tone } = useAppSelector((state) =>
    selectContentGenerationConfig(state),
  )

  const { generateContent } = useContentGeneration()

  const onClick = (): void => {
    if (!prompt || !action) return

    const previousResult = contentGenerationResults.length
      ? contentGenerationResults[0]
      : null

    generateContent({
      prompt,
      action,
      tone,
      service: channel,
      previousResult,
      retry: true,
    })
  }

  return (
    <Styled.Button
      id="ai-assistant-retry-button"
      onClick={onClick}
      disabled={disabled}
      withBorder={withBorder}
    >
      {children}
    </Styled.Button>
  )
}

export { RetryButton }
