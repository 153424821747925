import { Dispatcher } from 'flux'
import type { ComposerDispatchPayload } from '../interfaces/types'

type CustomDispatcher = Dispatcher<ComposerDispatchPayload> & {
  handleApiAction: typeof Dispatcher.prototype.dispatch
  handleViewAction: typeof Dispatcher.prototype.dispatch
  handlePusherAction: typeof Dispatcher.prototype.dispatch
}

const appDispatcher: CustomDispatcher = new Dispatcher() as CustomDispatcher

const createHandler = function (source: string): (args: unknown) => void {
  return function (this: Dispatcher<unknown>, action: unknown): void {
    const dispatchOptions = {
      source,
      action,
    }

    // If the dispatcher is currently dispatching
    // then add the next dispatch into the event queue
    if (this.isDispatching()) {
      setTimeout(() => {
        this.dispatch(dispatchOptions)
      }, 0)
    } else {
      this.dispatch(dispatchOptions)
    }
  }
}

appDispatcher.handleApiAction = createHandler('API_ACTION')
appDispatcher.handlePusherAction = createHandler('PUSHER_ACTION')
appDispatcher.handleViewAction = createHandler('VIEW_ACTION')

export default appDispatcher
