import { createParagraphsFromText } from '~publish/legacy/editor/nodes'
import { type PlateEditor, getNodeString, insertNodes } from '@udecode/plate'
import { getSelection } from './getSelection'
import type { BufferInlineElement } from '~publish/legacy/editor/BufferEditor/types.plate'
import unsetPropertyInEditor from '~publish/legacy/ai/helpers/unsetPropertyInEditor'
import { capitalizeTextAsSelection } from '~publish/legacy/editor/helpers/capitalizeTextAsSelection'

export async function addGeneratedContent({
  editor,
  content,
}: {
  editor: PlateEditor
  content: string
}): Promise<void> {
  /* We use two properties to control the animation of the generated content and keep information
  about the nodes generated by AI Assistant */
  const paragraphs = createParagraphsFromText(content, {
    generatedByAI: true,
    shouldAnimateGeneration: true,
  })
  if (paragraphs.length === 0) return

  /* AI can Replace or Insert text. If it's a replacement, we need to insert the content inline,
   * but using insertNodes all the nodes will be considered inline, so we need to extract the first
   * node, adding as text to the editor, and then insert the rest of the nodes.
   */
  const selectedText = getSelection({ editor })
  const isAReplacement = !!selectedText
  const isEditorEmpty =
    editor.children.length === 1 && getNodeString(editor).trim() === ''

  if (isAReplacement || isEditorEmpty) {
    /* we extract the first paragraph and insert it as text along with t current text */
    const firstParagraph = paragraphs.shift()

    let nodeToInsert = firstParagraph
    if (selectedText && nodeToInsert) {
      nodeToInsert = capitalizeTextAsSelection(nodeToInsert, selectedText)
    }

    if (nodeToInsert) {
      insertNodes(editor, nodeToInsert.children as BufferInlineElement[])
    }
  }

  /* The rest of the paragpahs or all of them if it's not a replacement
  are inserted as nodes type p */
  if (paragraphs.length > 0) {
    insertNodes(editor, paragraphs, {
      at: selectedText ? undefined : [editor.children.length],
    })
  }

  /* We need to unset the property shouldAnimateGeneration from the editor content
  after it is executed to avoid be run when user inserts more content */
  setTimeout(() => {
    /* We need to unset the property shouldAnimateGeneration from the editor content
    recursively, not only for the nodes at top level */
    unsetPropertyInEditor(editor, 'shouldAnimateGeneration')
  }, 1000)
}
